export const CZStaff = [
    "add",
    "are-you-sure",
    "available",
    "cancel",
    "cant-undo",
    "capacity",
    "cleaning",
    "common-zones",
    "confirm-staff-pass-reset",
    "edit",
    "staff-confirm-deletion",
    "create-another",
    "delete",
    "edit-anothero",
    "email",
    "erase",
    "filter-by",
    "form-error",
    "items",
    "mutation-error",
    "error-staff-create",
    "name",
    "next",
    "newo",
    "no-actions",
    "please",
    "previous",
    "save",
    "save-before",
    "search",
    "showing-all",
    "showing-all-results",
    "staff",
    "staff-delete",
    "staff-edit",
    "staff-generate-password",
    "staff-member",
    "staff-members",
    "staff-roles",
    "zones",
    "select-zones",
    "see-zones",
    "assigned-zones",
    "no-assigned-zones",
    "error-assign-zones",
    "before-saving",
    "theres-not",
    "you-want",
    "and",
    "areas",
    "category",
    "hotel",
    "categories",
    "ofo",
    "quantity",
    "view-detail",
    "write-name",
    "new-user-created",
    "accept",
    "unspecified",
    "operation-successful",
    "success-create-staff",
    "success-staff-roles",
    "email-sent-asap",
    "email-sent-error",
    "this email is already registered in the system",
];
