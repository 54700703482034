import Cookies from "js-cookie";

export const SessionKibana = {
    open: (cookie) => {
        Cookies.set("sid", cookie);
    },
    close: () => {
        Cookies.remove("sid");
    },
};
