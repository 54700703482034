import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

//Actions
import { setActionInput } from "../../actions/globalActions";

const UseInputNumberArrows = ({
    inputData,
    validation,
    maxNumber,
    placeholder,
    onChange,
    onBlur,
    actionOnChange,
    phRight,
    disabled,
    value,
    minNumber,
    id = "input-number-arrows",
}) => {
    //Data
    const { name } = inputData;
    const firstRenderValue = useRef(false);
    const valueRef = useRef(value ? value : null);
    const [maxCapacity, setMaxCapacity] = useState(1000);
    const [idComponent] = useState(`${id}`);
    //Actions
    const dispatch = useDispatch();

    useEffect(() => {
        if (value && !firstRenderValue.current) {
            firstRenderValue.current = true;
            const dataToAction = { [inputData.name]: value };
            dispatch(setActionInput(dataToAction));
        } else if (Number(value) >= 0 && firstRenderValue.current) {
            const dataToAction = { [inputData.name]: value };
            dispatch(setActionInput(dataToAction));
        }
        valueRef.current = Number(value);
        // eslint-disable-next-line
    }, [value]);

    useEffect(() => {
        if (maxNumber) {
            setMaxCapacity(maxNumber);
        }
        // eslint-disable-next-line
    }, []);

    function handleDecrease() {
        if (value > 0) {
            onChange(Number(value) - 1);
        }
    }

    function handleIncrease() {
        onChange(Number(value) + 1);
    }

    return (
        <div
            className=" flex items-center "
            onBlur={() => {
                if (valueRef.current && typeof actionOnChange === "function") {
                    actionOnChange(valueRef.current);
                }
            }}
        >
            <div className=" relative ">
                <input
                    style={{ cursor: "text" }}
                    type="number"
                    name={name}
                    min="0"
                    value={value}
                    id={idComponent}
                    max={String(maxCapacity)}
                    disabled={disabled}
                    className={`t-filter-input ${disabled ? " bg-gray-300 text-gray-700" : ""} w-full p-2 ${
                        phRight ? " pr-10" : ""
                    } disable-browser-buttons-numbers ${!validation ? "border border-red-100 " : ""}${
                        !validation ? "border border-red-100" : ""
                    }`}
                    placeholder={placeholder ? placeholder : "0"}
                    onChange={(e) => {
                        const val = e.target.value;
                        if (Number(minNumber) > 0 && onChange && val !== "" && Number(val) <= Number(minNumber)) {
                            onChange(String(minNumber));
                        } else if (typeof onChange === "function") {
                            onChange(val);
                        }
                    }}
                ></input>
                {phRight ? (
                    <span
                        className={` absolute  text-gray-600 ${disabled ? "bg-gray-300" : "bg-gray-200"} `}
                        style={{ top: "50%", right: "8px", transform: "translateY(-50%)" }}
                    >
                        {phRight}
                    </span>
                ) : null}
            </div>
            <div className={` flex flex-col ml-2 items-center justify-items-center text-gray-900`}>
                <button
                    id={`${idComponent}_increase`}
                    className={`${
                        disabled ? " text-gray-400" : ""
                    } focus:outline-none cursor-pointer icon icon-chevron-up mb-2 text-sm`}
                    onClick={() => {
                        if (disabled) return;
                        if (typeof onChange === "function") {
                            handleIncrease();
                        }
                    }}
                ></button>
                <button
                    id={`${idComponent}_decrease`}
                    className={`${
                        disabled ? " text-gray-400" : ""
                    } focus:outline-none cursor-pointer icon icon-chevron text-sm`}
                    onClick={() => {
                        if (disabled) return;
                        if (typeof onChange === "function") {
                            handleDecrease();
                        }
                    }}
                ></button>
            </div>
        </div>
    );
};

export default UseInputNumberArrows;
