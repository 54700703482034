//Utils
import CleanUnnecessaryChars from "./CleanUnnecessaryChars";

export const getScheduleDisplay = (timetable, filterActive, langStrings, currentStep) => {
    let res = "";
    if (!filterActive && !currentStep) {
        res = getPunctualEventsDisplay(timetable, langStrings);
    } else if (filterActive || currentStep) {
        res = getPeriodicEventsDisplay(timetable, filterActive, langStrings, currentStep);
    }
    return res;
};

export const getModalScheduleDisplay = (timetable, filterActive, langStrings) => {
    let res;
    if (!filterActive) {
        res = getModalPunctualEventsDisplay(timetable, langStrings);
    } else {
        res = getModalPeriodicEventsDisplay(timetable, langStrings);
    }

    return res;
};

export const getModalPunctualEventsDisplay = (timetable, langStrings) => {
    return getModalPunctualSchedules(timetable, langStrings);
};

export const getModalPunctualSchedules = (timetable, langStrings) => {
    let res = `<div class="w-full flex items-start py-4">`;
    res += `<div class="w-3/12 inline-block">`;
    res += `<div class="first-capital">${langStrings[getDateType(timetable.dateType)]}</div></div>`;
    res += `<div class="w-9/12 inline-block ${isActiveStyle(timetable)}">`;
    // eslint-disable-next-line
    timetable.timetableDates.map((timetableDate) => {
        const cleanDates = CleanUnnecessaryChars(timetableDate.dateList);
        let formattedDateList;
        if (timetable.dateType === "DATE_RANGE") {
            formattedDateList = formatDateRange(cleanDates);
        } else {
            formattedDateList = formatDateSpecific(cleanDates);
        }
        res += `<div class="w-full flex items-start">`;
        res += `<div class="w-6/12 inline-block break-words ${isActiveStyle(
            timetableDate
        )}">${formattedDateList}</div>`;
        res += `<div class="w-6/12 inline-block ${isActiveStyle(timetableDate)}">${getScheduleHours(
            timetableDate.timetableDateRanges
        )}</div>`;
        res += `</div>`;
    });

    res += `</div>`;
    res += `</div>`;
    return res;
};

export const getPunctualEventsDisplay = (timetable, langStrings) => {
    return getPunctualSchedules(timetable, langStrings);
};

export const getModalPeriodicEventsDisplay = (timetable, langStrings) => {
    let res = ``;
    const timetableType = classifyPeriodicEvent([timetable]);
    if (timetableType.habitual.length > 0) {
        res += getModalCommonSchedules(timetable, langStrings);
    }

    if (timetableType.special.length > 0) {
        res += getModalSpecialSchedules(timetable, langStrings);
    }

    if (timetableType.festive.length > 0) {
        res += getModalFestiveSchedules(timetable, langStrings);
    }
    return res;
};

export const getPeriodicEventsDisplay = (timetable, filterActive, langStrings, currentStep) => {
    let res = ``;
    if (filterActive === "common-schedule" || currentStep === 1) {
        res += getCommonSchedules(timetable, langStrings);
    }
    if (filterActive === "special-schedule" || currentStep === 2) {
        res += getSpecialSchedules(timetable, langStrings);
    }

    if (filterActive === "closed-days" || currentStep === 3) {
        res += getFestiveSchedules(timetable, langStrings);
    }
    return res;
};

export const getPunctualSchedules = (timetable, langStrings) => {
    let res = ``;
    res += `<div class="w-3/12 inline-block"><div class="flex items-start first-capital">${
        langStrings[getDateType(timetable.dateType)]
    }</div></div>`;
    res += `<div class="w-9/12 inline-block">`;
    // eslint-disable-next-line
    timetable.timetableDates.map((timetableDate) => {
        const cleanDates = CleanUnnecessaryChars(timetableDate.dateList);
        let formattedDateList;
        if (timetable.dateType === "DATE_RANGE") {
            formattedDateList = formatDateRange(cleanDates);
        } else {
            formattedDateList = formatDateSpecific(cleanDates);
        }
        res += `<div class="w-full flex items-start">`;
        res += `<div class="w-4/12 inline-block">${formattedDateList}</div>`;
        res += `<div class="w-8/12 inline-block">${getScheduleHours(timetableDate.timetableDateRanges)}</div>`;
        res += `</div>`;
    });
    res += `</div>`;
    return res;
};

export const getModalCommonSchedules = (timetable, langStrings) => {
    let res = `<div class="w-full flex items-start">`;
    res += `<div class="w-4/12 inline-block ${isActiveStyle(timetable.timetableDates[0])}">${getScheduleHours(
        timetable.timetableDates[0].timetableDateRanges
    )}</div>`;
    res += `<div class="w-4/12 inline-block ${isActiveStyle(timetable.timetableDates[0])}">${getScheduleWeekdays(
        timetable.timetableDates[0].timetableDateRanges,
        false,
        langStrings
    )}</div></div>`;
    return res;
};

export const getCommonSchedules = (timetable, langStrings) => {
    let res = ``;
    res += `<div class="w-5/12 inline-block"><div class="flex items-start">${getScheduleHours(
        timetable.timetableDates[0].timetableDateRanges
    )}</div></div>`;
    res += `<div class="w-7/12 inline-block">${getScheduleWeekdays(
        timetable.timetableDates[0].timetableDateRanges,
        "long",
        langStrings
    )}</div>`;
    return res;
};

export const getModalSpecialSchedules = (timetable, langStrings) => {
    let res = `<div class="w-full first-capital font-bold py-4 ${isActiveStyle(timetable)}">${timetable.name}</div>`;
    res += `<div class="w-full block">`;
    // eslint-disable-next-line
    timetable.timetableDates.map((timetableDate) => {
        res += `<div class="w-full flex items-start ${isActiveStyle(timetableDate)}">`;
        const cleanDates = CleanUnnecessaryChars(timetableDate.dateList);
        let formattedDateList;
        if (timetable.dateType === "DATE_RANGE") {
            formattedDateList = formatDateRange(cleanDates);
        } else {
            formattedDateList = formatDateSpecific(cleanDates);
        }
        res += `<div class="w-4/12 inline-block">${formattedDateList}</div>`;
        res += `<div class="w-4/12 inline-block ${isActiveStyle(timetableDate.timetableDateRanges)}">${getScheduleHours(
            timetableDate.timetableDateRanges
        )}</div>`;
        res += `<div class="w-4/12 inline-block ${isActiveStyle(
            timetableDate.timetableDateRanges
        )}">${getScheduleWeekdays(timetableDate.timetableDateRanges, false, langStrings)}</div>`;
        res += `</div>`;
    });
    res += `</div>`;
    return res;
};

export const getSpecialSchedules = (timetable, langStrings) => {
    let res = ``;
    res += `<div class="w-3/12 inline-block"><div class="flex items-start">${
        langStrings[getDateType(timetable.dateType)]
    }</div></div>`;
    res += `<div class="w-9/12 inline-block">`;
    // eslint-disable-next-line
    timetable.timetableDates.map((timetableDate) => {
        res += `<div class="w-full flex items-start">`;
        const cleanDates = CleanUnnecessaryChars(timetableDate.dateList);
        let formattedDateList;
        if (timetable.dateType === "DATE_RANGE") {
            formattedDateList = formatDateRange(cleanDates);
        } else {
            formattedDateList = formatDateSpecific(cleanDates);
        }
        res += `<div class="w-4/12 inline-block">${formattedDateList}</div>`;
        res += `<div class="w-4/12 inline-block">${getScheduleHours(timetableDate.timetableDateRanges)}</div>`;
        res += `<div class="w-4/12 inline-block">${getScheduleWeekdays(
            timetableDate.timetableDateRanges,
            false,
            langStrings
        )}</div>`;
        res += `</div>`;
    });
    res += `</div>`;
    return res;
};

export const getModalFestiveSchedules = (timetable, langStrings) => {
    let res = `<div class="w-full first-capital font-bold py-4 ${isActiveStyle(timetable)}">${timetable.name}</div>`;
    res += `<div class="w-full flex items-start ${isActiveStyle(timetable.timetableDates[0])}">`;
    const cleanDates = CleanUnnecessaryChars(timetable.timetableDates[0].dateList);
    let formattedDateList;
    if (timetable.dateType === "DATE_RANGE") {
        formattedDateList = formatDateRange(cleanDates);
    } else {
        formattedDateList = formatDateSpecific(cleanDates);
    }
    res += `<div class="w-4/12 inline-block">${formattedDateList}</div>`;
    res += `<div class="w-4/12 inline-block"></div><div class="w-4/12 inline-block"></div>`;
    res += `</div>`;
    return res;
};

export const getFestiveSchedules = (timetable, langStrings) => {
    let res = ``;
    res += `<div class="w-3/12 inline-block"><div class="flex items-start">${
        langStrings[getDateType(timetable.dateType)]
    }</div></div>`;
    const cleanDates = CleanUnnecessaryChars(timetable.timetableDates[0].dateList);
    let formattedDateList;
    if (timetable.dateType === "DATE_RANGE") {
        formattedDateList = formatDateRange(cleanDates);
    } else {
        formattedDateList = formatDateSpecific(cleanDates);
    }
    res += `<div class="w-9/12 inline-block">${formattedDateList}</div>`;
    return res;
};

//Getters

export const getEventType = (timetables) => {
    let res;
    if (timetables && timetables.length > 0) {
        if (timetables[0].periodicityType === "HABITUAL") {
            res = "periodic-event";
        } else {
            res = "punctual-event";
        }
    } else {
        res = "";
    }
    return res;
};

export const getDateType = (dateType) => {
    let res;
    if (dateType === "DATE_RANGE") {
        res = "date-range";
    } else {
        res = "date-specific";
    }
    return res;
};

export const getScheduleHours = (timetableDateRanges) => {
    let res = ``;
    // eslint-disable-next-line
    timetableDateRanges.map((hour) => {
        if (hour.timeRanges !== "[]" && hour.timeRanges !== "") {
            const cleantimeRanges = CleanUnnecessaryChars(hour.timeRanges).split(",");
            // eslint-disable-next-line
            cleantimeRanges.map((cleanTimeRange, index) => {
                const timeRange = cleanTimeRange.split("-");
                if (index !== 0) {
                    res += ` / `;
                }
                res += `${timeRange[0]} - ${timeRange[1]}`;
            });
            res += `<br />`;
        } else {
            res = "";
        }
    });
    return res;
};

export const getScheduleWeekdays = (timetableDateRanges, long, langStrings) => {
    let res = ``;
    // eslint-disable-next-line
    timetableDateRanges.map((week) => {
        if (week.weekdayList !== "[]" && week.weekdayList !== "") {
            const cleanWeekdays = CleanUnnecessaryChars(week.weekdayList).split(",");
            // eslint-disable-next-line
            cleanWeekdays.map((cleanWeekday, index) => {
                if (index !== 0) {
                    res += `, `;
                }
                if (long) {
                    res += langStrings[`day-${cleanWeekday.trim()}`];
                } else {
                    res += langStrings[`day-${cleanWeekday.trim()}-short`];
                }
            });
        } else {
            res = "";
        }
        res += `<br />`;
    });
    return res;
};

//Formatters
export const formatDateRange = (dateList) => {
    const dates = dateList.split(",");
    const range = dates[0].split("-");
    return `${new window.ZDate(range[0]).toLocaleDateString([], {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    })} - ${new window.ZDate(range[1]).toLocaleDateString([], {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    })}`;
};

export const formatDateSpecific = (dateList) => {
    let res = ``;
    const dates = dateList.split(",");
    // eslint-disable-next-line
    dates.map((date, index) => {
        const skipDuplicate = date.split("-");
        if (index !== 0) {
            res += `, `;
        }
        res += `${new window.ZDate(skipDuplicate[0]).toLocaleDateString([], {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        })}`;
    });
    return res;
};

export const classifyPeriodicEvent = (schedules) => {
    let habitual = [];
    let special = [];
    let festive = [];
    let unique = [];
    //Classify events
    // eslint-disable-next-line
    schedules.map((schedule) => {
        if (schedule.periodicityType !== "UNIQUE") {
            if (schedule.type === "CLOSED") {
                festive.push(schedule);
            } else if (schedule?.timetableDates?.length>0 && (!schedule.timetableDates[0].dateList || schedule.timetableDates[0].dateList === "")) {
                habitual.push(schedule);
            } else {
                special.push(schedule);
            }
        } else {
            unique.push(schedule);
        }
    });
    return { habitual, special, festive, unique };
};

export const getScheduleStatus = (schedule, langStrings) => {
    let res;
    if (schedule.isEnabled) {
        res = langStrings["active"];
    } else {
        res = langStrings["disabled"];
    }
    return res;
};

export const isActiveStyle = (item) => {
    let res;
    if (item.isActive) {
        res = "text-green-100";
    } else {
        res = "";
    }
    return res;
};

export const showScheduleStatus = (isEnabled, langStrings) => {
    let res;
    if (isEnabled) {
        res = ` (${langStrings["active"]})`;
    } else {
        res = ` (${langStrings["disabled"]})`;
    }
    return res;
};

export const getStatusColor = (isEnabled) => {
    let res;
    if (isEnabled) {
        res = "text-green-100";
    } else {
        res = "text-orange-100";
    }
    return res;
};
