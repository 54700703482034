import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import SalesProvider from "contexts/Sales";
import ShopProvider from "contexts/Sales/Shop";

import UseSectionHeader from "components/useSectionHeader";
import ShopHeadMenu from "components/Section/Services/Sales/ShopHeadMenu";
import ShopSettingsPrinter from "components/Section/Services/Sales/components/ShopSettings/ShopSettingsPrinter";
import ShopSettingsPMS from "components/Section/Services/Sales/components/ShopSettings/ShopSettingsPMS";
import ErrorInfo from "components/ErrorInfo";

const ShopIntegrations = () => {
    const { id } = useParams();

    const hasProductGuests = useSelector((state) => state?.ui?.permissions?.product?.guest);

    const salesRef = useRef(null);
    const contextRef = useRef(null);
    const refresh = () => {
        if (contextRef?.current) {
            contextRef.current.refresh();
        }
    };

    const [error, setError] = useState(null);

    return (
        <SalesProvider ref={salesRef}>
            <ShopProvider
                ref={contextRef}
                id={id}
                onError={(error) => {
                    setError(error);
                }}
            >
                <ShopHeadMenu />
                {error ? (
                    <ErrorInfo retry={refresh}>{error}</ErrorInfo>
                ) : (
                    <>
                        <UseSectionHeader title={"shop-integrations"} hideBreadcrumbs={true} />
                        <div className="grid grid-cols-2 gap-4">
                            {hasProductGuests ? <ShopSettingsPMS /> : null}
                            <ShopSettingsPrinter />
                        </div>
                    </>
                )}
            </ShopProvider>
        </SalesProvider>
    );
};

export default ShopIntegrations;
