import React, { useState, useEffect, Fragment, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Components
import UseLoading from "../../../../Table/useLoading";
import { setSectionContent, setExecuteQuery } from "../../../../../actions/sectionActions";
import {
    PROPERTY_APPEARANCE,
    CORPORATE_APPEARANCE,
    NO_APPEARANCES,
    PROPERTY_APPEARANCE_TYPE,
    CORPORATE_APPEARANCE_TYPE,
    CORPORATE_REF,
    THEME_TYPE_DESIGN,
    THEME_TYPES_ARR,
    THEME_TYPE_MOBILE,
    findFirstAppearance,
    findLastAppearance,
    nullApperanceObj,
} from "../../../../../hooks/Utils/AppearancesUtils";
import UseItemActions from "../../../../useItemActions";
import AppearanceSearcher from "./AppearanceSearcher";
import { openModal, setModalContent } from "../../../../../actions/uiActions";
import { capitalizeFirst } from "hooks/Utils/Utils";

import { useAuth } from "hooks/Session/auth";

export default function AppearancesListSidebar({ themeType, setThemeType, loadingListAppearances }) {
    const dispatch = useDispatch();
    //states
    const { t } = useTranslation();
    const { project } = useAuth();

    const [actionsActive, setactionsActive] = useState(null);
    const { listAppearances, appearanceSelected, appearanceId, searchAppearance, executeQuery } = useSelector(
        (state) => state.sectionContent
    );

    const { permissions } = useSelector((state) => state.ui);
    const [menuItems, setMenuItems] = useState();
    const [noItems, setNoItems] = useState(false);
    const [active, setActive] = useState(null);
    const [showProperty, setShowProperty] = useState(project?.ref === CORPORATE_REF ? false : true);
    const [appearanceMenuOver, setAppearanceMenuOver] = useState(null);
    const [clickThemeTypeTab, setClickThemeTypeTab] = useState(false);
    const [selectLastAppearance, setSelectLastAppearance] = useState(null);
    const [projectsIdShown, setProjectsIdShown] = useState([]);
    const mobileProduct = permissions?.product?.mobile;

    let lastProyectGroup = null;
    let marginPryectAppearances = "pl-4";

    useEffect(() => {
        if (listAppearances === NO_APPEARANCES) {
            setNoItems(true);
        } else if (listAppearances) {
            setNoItems(false);
            setActive(listAppearances?.map((item) => item.name));
            setMenuItems(listAppearances);
            setactionsActive(listAppearances[0].name);
            if (
                project?.ref === CORPORATE_REF &&
                listAppearances?.[1]?.name === PROPERTY_APPEARANCE &&
                listAppearances?.[1]?.appearances.length > 0
            ) {
                setProjectsIdShown([listAppearances[1].appearances[0].projectId]);
            }
        }
        // eslint-disable-next-line
    }, [listAppearances]);

    const expandCollapseList = (item) => {
        let _arrayItemsList = [];
        if (typeof active === "string") {
            _arrayItemsList.push(active);
        } else {
            _arrayItemsList = [...active];
        }

        if (_arrayItemsList.includes(item)) {
            _arrayItemsList = _arrayItemsList.find((element) => element !== item);
        } else {
            _arrayItemsList.push(item);
        }
        if (!_arrayItemsList || _arrayItemsList === undefined) {
            _arrayItemsList = [];
        }

        setActive(_arrayItemsList);
    };

    const selectAppearance = (appearance) => {
        if (appearanceId !== appearance.id) {
            dispatch(
                setSectionContent({
                    appearanceSelected: {
                        id: appearance.id,
                        name: appearance.name,
                        type: appearance.type,
                        locks: null,
                    },
                    appearanceId: appearance.id,
                })
            );
        }
    };

    const addNewAppearance = (themeType) => {
        if (project?.ref === CORPORATE_REF) {
            dispatch(
                setExecuteQuery({
                    action: "add-new-appearance",
                    name: newname(),
                    themeType,
                    type: CORPORATE_APPEARANCE_TYPE,
                })
            );
        } else {
            dispatch(
                setExecuteQuery({
                    action: "add-new-appearance",
                    name: newname(),
                    themeType,
                    type: PROPERTY_APPEARANCE_TYPE,
                })
            );
        }
    };

    const newname = () => {
        let _newName = t("new-appearance");
        let _number = 1;
        if (
            listAppearances !== NO_APPEARANCES &&
            listAppearances.length > 0 &&
            listAppearances[0].appearances.length > 0
        ) {
            listAppearances[0].appearances.forEach((_appearance) => {
                if (_appearance.name === _newName) {
                    _newName = `${t("new-appearance")} (${_number})`;
                    _number++;
                }
            });
        }
        return _newName;
    };

    const noItemsDiv = () => {
        return (
            <div
                key={`no-items-div`}
                className="flex items-center w-full justify-between relative pb-3 min-h-4"
                style={{ width: "95%" }}
            >
                {searchAppearance ? (
                    <div className="text-gray-600 mx-6" id="no-results-message">
                        {t("{{capitalize, capitalize}}", { capitalize: t("no-search-results") })}
                    </div>
                ) : (
                    <>
                        <div className="flex items-center text-gray-800 cursor-pointer">
                            <div className=" inline-block ml-5 first-capital font-bold">
                                {t("{{capitalize, capitalize}}", {
                                    capitalize: t(
                                        `${
                                            project?.ref === CORPORATE_REF ? CORPORATE_APPEARANCE : PROPERTY_APPEARANCE
                                        }-appearances`
                                    ),
                                })}
                            </div>
                        </div>
                        <div
                            onClick={(e) => addNewAppearance(themeType)}
                            id={`appearances-list-bar-add-new-appearance`}
                            className="icon icon-add absolute sidebar-menu-link text-xl -mr-4 right-0  cursor-pointer"
                        ></div>
                    </>
                )}
            </div>
        );
    };

    const addProjectGroup = (proyectGroup, projectId) => {
        let response = [];
        if (proyectGroup) {
            if (proyectGroup !== lastProyectGroup) {
                response.push(
                    <p
                        key={proyectGroup}
                        className="font-normal text-gray-900 first-capital mt-1 mb-4 cursor-pointer"
                        onClick={() => changeVisibilityProject(projectId)}
                    >
                        <span
                            className={`${
                                projectsIdShown.find((item) => item === projectId) ? "icon-chevron-up" : "icon-chevron"
                            } mr-2`}
                        ></span>
                        {t(proyectGroup)}
                    </p>
                );
            }
            lastProyectGroup = proyectGroup;
        }
        return response;
    };
    const changeVisibilityProject = (id) => {
        if (projectsIdShown.find((item) => item === id)) {
            setProjectsIdShown(
                // eslint-disable-next-line
                projectsIdShown.map((item) => {
                    if (item !== id) {
                        return item;
                    }
                })
            );
        } else {
            setProjectsIdShown([...projectsIdShown, id]);
        }
    };

    const hiddenClassApperance = (typeAppearance, prjId) => {
        if (
            listAppearances?.[1]?.name === PROPERTY_APPEARANCE &&
            typeAppearance === PROPERTY_APPEARANCE_TYPE &&
            !projectsIdShown.includes(prjId)
        ) {
            return "hidden";
        } else {
            return "";
        }
    };

    useEffect(() => {
        if (executeQuery?.action === "add-new-appearance") {
            setSelectLastAppearance({ type: executeQuery.type || "LOCAL" });
        }
    }, [executeQuery]);

    useLayoutEffect(() => {
        if (!menuItems) return;
        let timerId;

        if (clickThemeTypeTab) {
            const firstAppearance = findFirstAppearance(menuItems);
            timerId = setTimeout(() => {
                selectAppearance(firstAppearance || { ...nullApperanceObj });
            }, 200);
        } else if (selectLastAppearance?.type) {
            const lastAppearance = findLastAppearance(menuItems, selectLastAppearance.type);
            if (lastAppearance) {
                timerId = setTimeout(() => {
                    selectAppearance(lastAppearance);
                }, 200);
            }
            setSelectLastAppearance(null);
        }
        setClickThemeTypeTab(false);
        return () => {
            if (timerId) clearTimeout(timerId);
        };
    }, [menuItems]);

    return (
        <>
            <div className=" w-full">
                {THEME_TYPES_ARR.map((type, index) => {
                    if (!mobileProduct) {
                        return null;
                    }
                    const isFullWidth = type.TYPE === THEME_TYPE_DESIGN && !mobileProduct;

                    return (
                        <button
                            id={`${type.TYPE.toLowerCase()}-theme-appearance-selected-button`}
                            onClick={() => {
                                if (typeof setThemeType === "function") {
                                    setThemeType(type.TYPE);
                                }
                                setClickThemeTypeTab(true);
                            }}
                            className={`focus:outline-none ${isFullWidth ? "w-full" : "w-1/2"} ${
                                index === 1 ? "border-l" : "border-r"
                            } ${
                                themeType === type.TYPE ? "bg-zafiro-600 text-white" : "bg-white text-gray-900"
                            } border-b font-bold border-gray-200 py-4`}
                        >
                            {capitalizeFirst(t(type.TEXT))}
                        </button>
                    );
                })}
            </div>

            <div className=" px-6 pt-4">
                {themeType === THEME_TYPE_MOBILE
                    ? t("these-appearances-are-used-in-the-mobile-app-general-set-up")
                    : t("these-appearances-are-used-in-the-design-screens")}
            </div>
            <AppearanceSearcher />

            <ul
                id="appearances_List"
                className="min-w-full list flex-col  overflow-y-scroll  overflow-x-hidden collapseAnimation"
                style={{ maxHeight: "70vh" }}
            >
                {loadingListAppearances ? <UseLoading /> : null}
                {noItems ? (
                    noItemsDiv()
                ) : !menuItems ? (
                    <UseLoading />
                ) : menuItems ? (
                    menuItems.map((item, index) => {
                        const itemDisplay = (
                            <div
                                key={`${item.name}_${index}`}
                                className="flex items-center w-full justify-between relative pb-3"
                                style={{ width: "95%" }}
                            >
                                <div
                                    className="flex items-center text-gray-800 cursor-pointer"
                                    onClick={(e) => expandCollapseList(item.name)}
                                >
                                    <div
                                        className={`${
                                            active.includes(item.name) ? "icon-chevron-up" : "icon-chevron"
                                        }  -mt-1 pl-4`}
                                    ></div>
                                    <div className={`${item.icon} text-35xl`}></div>
                                    <div className=" inline-block ml-5 first-capital font-bold">
                                        {t(`${item.name}-appearances`)}
                                    </div>
                                </div>

                                {item.editionMode === "EDITION" ? (
                                    <div
                                        id="appearances-list-bar-add-new-appearance"
                                        onClick={(e) => addNewAppearance(themeType)}
                                        className="icon icon-add absolute sidebar-menu-link text-xl -mr-4 right-0  cursor-pointer"
                                    ></div>
                                ) : null}
                            </div>
                        );
                        if (!showProperty && item.name === PROPERTY_APPEARANCE) {
                            return (
                                <div
                                    key="show-property-appearances"
                                    className="cursor-pointer text-blue-300 py-3 pl-4"
                                    onClick={() => {
                                        setShowProperty(true);
                                    }}
                                >
                                    {t("show-property-appearances")}
                                </div>
                            );
                        }

                        if (typeof item.appearances === "undefined") {
                            return (
                                <li key={item.to}>
                                    <NavLink
                                        className={({ isActive }) =>
                                            `sidebar-menu-link flex items-center ${isActive ? "active" : ""}`
                                        }
                                        to={"/"}
                                    >
                                        {itemDisplay}
                                    </NavLink>
                                </li>
                            );
                        } else {
                            const refSubmenu = React.createRef();

                            return (
                                <li key={index} className="py-3">
                                    {itemDisplay}
                                    {item.appearances.length === 0 ? (
                                        <div className="text-gray-600 mx-14" id="no-results-message">
                                            {t("{{capitalize, capitalize}}", { capitalize: t("no-search-results") })}
                                        </div>
                                    ) : (
                                        <ul
                                            ref={refSubmenu}
                                            className={`block pl-12  overflow-x-hidden  ${
                                                !active.includes(item.name) ? " hidden" : ""
                                            }${index === menuItems.length - 1 ? " pb-20" : ""}`}
                                        >
                                            {item.appearances.map((appearance, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        {addProjectGroup(appearance.projectName, appearance.projectId)}
                                                        <div
                                                            key={`appearance_${appearance.id}`}
                                                            className={hiddenClassApperance(
                                                                appearance.type,
                                                                appearance.projectId
                                                            )}
                                                        >
                                                            <li
                                                                key={appearance.id}
                                                                className={`m-2 ${
                                                                    projectsIdShown.find(
                                                                        (item) => item === appearance.projectId
                                                                    ) && marginPryectAppearances
                                                                }`}
                                                            >
                                                                <div
                                                                    className={`sidebar-submenu-link cursor-pointer ${
                                                                        appearanceSelected &&
                                                                        appearance.id === appearanceSelected.id
                                                                            ? `text-blue-300 font-black`
                                                                            : ""
                                                                    }`}
                                                                    onMouseEnter={(e) =>
                                                                        setAppearanceMenuOver(appearance.id)
                                                                    }
                                                                    onMouseLeave={(e) => setAppearanceMenuOver(null)}
                                                                    id={`appearance-${
                                                                        appearance.type === "LOCAL"
                                                                            ? "local"
                                                                            : "corporate"
                                                                    }-${index}`}
                                                                    onClick={() => {
                                                                        selectAppearance(appearance);
                                                                    }}
                                                                >
                                                                    <div className="inline-block text-left w-9/12">
                                                                        {appearance.name}
                                                                    </div>

                                                                    {parseInt(appearanceMenuOver) ===
                                                                        parseInt(appearance.id) &&
                                                                    item.name === actionsActive ? (
                                                                        <div
                                                                            className="flex float-right text-gray-900"
                                                                            onClick={(e) => e.stopPropagation()}
                                                                        >
                                                                            <UseItemActions
                                                                                dataType="appearance"
                                                                                id={`${String(index)}-${
                                                                                    appearance.type === "LOCAL"
                                                                                        ? "local"
                                                                                        : "corporate"
                                                                                }`}
                                                                                indexParent={String(index)}
                                                                                containerAdjust="w-48"
                                                                                executeSectionQuery={true}
                                                                                scrollParentId="appearances_List"
                                                                                actions={[
                                                                                    {
                                                                                        name: "edit-name",
                                                                                        action: "appearance-edit-name",
                                                                                        modal_title: "edit-name",
                                                                                        modal_value: appearance.name,
                                                                                        modal_btn_name: "rename",
                                                                                        mutationParams: {
                                                                                            appearanceID: appearance.id,
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                        name: "duplicate",
                                                                                        mutation:
                                                                                            "duplicate-appearance",
                                                                                        mutationParams: {
                                                                                            appearanceID: appearance.id,
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                        name: t("delete"),
                                                                                        disabled:
                                                                                            appearance.hasDesigns ||
                                                                                            (item.name ===
                                                                                                CORPORATE_APPEARANCE &&
                                                                                                !searchAppearance &&
                                                                                                item.appearances
                                                                                                    .length === 1) ||
                                                                                            appearance?.hasMobileApps,
                                                                                        disabledTooltip: t(
                                                                                            appearance.hasDesigns ||
                                                                                                appearance?.hasMobileApps
                                                                                                ? "appearance-in-use"
                                                                                                : "default appearance"
                                                                                        ),
                                                                                        action: "delete-appearance",
                                                                                        modal_title: "delete",
                                                                                        modal_value: appearance.name,
                                                                                        mutationParams: {
                                                                                            appearanceID: appearance.id,
                                                                                        },
                                                                                    },
                                                                                ]}
                                                                            />
                                                                        </div>
                                                                    ) : parseInt(appearanceMenuOver) ===
                                                                          parseInt(appearance.id) &&
                                                                      item.name === CORPORATE_APPEARANCE &&
                                                                      actionsActive === PROPERTY_APPEARANCE ? (
                                                                        <div
                                                                            className="t-actions-icon flex float-right font-normal icon-clone text-1xl"
                                                                            onClick={() => {
                                                                                if (appearance.isCopyableToProperty) {
                                                                                    dispatch(
                                                                                        setExecuteQuery({
                                                                                            action: "duplicate-appearance",
                                                                                            params: {
                                                                                                appearanceID:
                                                                                                    appearance.id,
                                                                                            },
                                                                                        })
                                                                                    );
                                                                                } else {
                                                                                    const modalCopyWarning = {
                                                                                        id: appearance.id,
                                                                                        bodyAdjust: "w-5/12",
                                                                                        title: t("duplication-error"),
                                                                                        value: t("general-appearances"),
                                                                                        inputs: [
                                                                                            {
                                                                                                type: "void",
                                                                                                text: `<div class="text-left text-xl"><span class="icon icon-warning text-orange-100 mr-2"></span>${t(
                                                                                                    "warning-copy-appearance"
                                                                                                )}</div>`,
                                                                                            },
                                                                                        ],
                                                                                        buttons: [
                                                                                            {
                                                                                                name: "cancel",
                                                                                                style: "white",
                                                                                                action: "close",
                                                                                            },
                                                                                        ],
                                                                                    };

                                                                                    dispatch(
                                                                                        setModalContent(
                                                                                            modalCopyWarning
                                                                                        )
                                                                                    );
                                                                                    dispatch(openModal());
                                                                                }
                                                                            }}
                                                                        ></div>
                                                                    ) : null}
                                                                </div>
                                                            </li>
                                                        </div>
                                                    </Fragment>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </li>
                            );
                        }
                    })
                ) : null}
            </ul>
        </>
    );
}
