import React, { useRef, useState } from "react";

import { Route, Routes, useParams } from "react-router-dom";

import SalesProvider from "contexts/Sales";
import ShopProvider from "contexts/Sales/Shop";

import ShopHeadMenu from "./ShopHeadMenu";
import ErrorInfo from "components/ErrorInfo";

import Settings from "sections/services/sales/shops/settings";
import Delivery from "sections/services/sales/shops/settings/Delivery";

const ShopSettings = () => {
    const { id } = useParams();

    const [error, setError] = useState(null);

    const salesRef = useRef(null);
    const contextRef = useRef(null);
    const refresh = () => {
        if (contextRef?.current) {
            contextRef.current.refresh();
        }
    };

    return (
        <SalesProvider ref={salesRef}>
            <ShopProvider
                ref={contextRef}
                id={id}
                onError={(error) => {
                    setError(error);
                }}
            >
                <ShopHeadMenu />
                {error ? (
                    <ErrorInfo retry={refresh}>{error}</ErrorInfo>
                ) : (
                    <Routes>
                        <Route index element={<Settings />} />
                        <Route path="delivery" element={<Delivery />} />
                    </Routes>
                )}
            </ShopProvider>
        </SalesProvider>
    );
};

export default ShopSettings;
