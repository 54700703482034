import { gql } from "apollo-boost";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { capitalizeFirst } from "../../../hooks/Utils/Utils";
import { changeGlobalAction } from "../../../actions/globalActions";
import { openModal, setModalContent } from "../../../actions/uiActions";
import UseButton from "../../useButton";
import UseComponentLoading from "../../useComponentLoading";
import NewHotspot from "../../../hooks/GraphqlCalls/Settings/modals/NewHotspot";
import MacExceptions from "../../../hooks/GraphqlCalls/Settings/modals/MacExcepcionts";
import { Session } from "../../../hooks/Utils/Session";
import UseItemActions from "../../useItemActions";

function UseHotspotCard({ data }) {
    const { name, link, exceptionsMacsNumber } = data;
    const { permissions } = useSelector((state) => state.ui);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [accessTypesNumber, setAccessTypesNumber] = useState(0);
    const [mobileApps, setMobileApps] = useState(null);
    const [loading, setLoading] = useState(true);
    const isSuperUser = Session.isSuperUser();
    const useProductMobile = permissions?.product?.mobile;

    const GET_ACCESS_TYPE = gql`
        {
            wifiAccessTypes: wifiAccessTypes(hotspot:"${name}"){
                results{
                    id
                    accesstypes_name
                    enable
                }
            }
            mobileApps: mobileApps{
                results{
                    name
                }
            }
        }
    `;

    const [executeQuery, { data: dataQuery, loading: loadingDataQuery }] = useLazyQuery(GET_ACCESS_TYPE, {
        onError(err) {
            console.log(err);
            toast.error(capitalizeFirst(t("mutation-error")));
        },
    });

    useEffect(() => {
        setLoading(true);
        setAccessTypesNumber(0);
        executeQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!loadingDataQuery && dataQuery) {
            setLoading(false);
            setAccessTypesNumber(
                dataQuery.wifiAccessTypes &&
                    dataQuery.wifiAccessTypes.results &&
                    dataQuery.wifiAccessTypes.results.length > 0
                    ? dataQuery.wifiAccessTypes.results.filter((wifiAccessType) => wifiAccessType.enable).length
                    : 0
            );
            setMobileApps(arrangeMobileApps(dataQuery.mobileApps));
        }
    }, [dataQuery, loadingDataQuery]);

    const arrangeMobileApps = (mobileApps) => {
        let response = [];
        if (mobileApps && mobileApps.results && mobileApps.results.length > 0) {
            // eslint-disable-next-line
            mobileApps.results.map((mobileApp) => {
                response.push(mobileApp.name);
            });
        }
        return response;
    };

    const handleEditRedirect = () => {
        const actionName = "edit-hotspot-link";
        const editData = {
            info: [
                {
                    value: name,
                },
                {
                    value: link,
                },
            ],
        };
        let modal = NewHotspot(editData, actionName, useProductMobile, mobileApps, t)[0];
        modal.title = "edit-redirection";
        dispatch(
            changeGlobalAction({
                actionName: actionName,
                itemsAffected: [name],
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    return (
        <div className="w-full">
            {loading ? (
                <UseComponentLoading />
            ) : (
                <>
                    <div className="font-bold text-gray-900 mb-4 relative">
                        <span className="text-2xl">{name}</span>
                        {isSuperUser ? (
                            <div className="float-right ml-1 mt-1 mr-3 z-10">
                                <UseItemActions
                                    dataType="hotspot"
                                    id={name}
                                    executeSectionQuery={true}
                                    containerAdjust="w-48"
                                    actions={[
                                        {
                                            name: "delete",
                                            action: "delete-hotspot",
                                            modal_title: "delete",
                                            modal_value: name,
                                            id: `delete-hotspot`,
                                            adjust: "flex items-center",
                                            icon: "superuser",
                                            iconAdjust: "text-3xl",
                                        },
                                    ]}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div className="w-full grid grid-cols-3">
                        <div className="col-span-1 text-center px-3">
                            <span className="block mb-6 truncate pb-1">
                                <span className="fieldLabel capitalize">{t("redirection")}:</span>
                                <span className="text-gray-800 pl-2">
                                    {link && link === "pwa" && mobileApps && mobileApps.length > 0
                                        ? `${t("pwa-name", { apps: mobileApps.join(", ") })}`
                                        : !link
                                        ? t("none")
                                        : link}
                                </span>
                            </span>
                            <span className="block" id="hot-spot-card-edit-redirection" onClick={handleEditRedirect}>
                                <UseButton
                                    buttonName={"edit-redirection"}
                                    buttonColor={"btn-blue"}
                                    adjust="w-56 m-auto"
                                />
                            </span>
                        </div>
                        <div className="col-span-1 text-center px-3">
                            <span className="block fieldLabel mb-5">
                                {t("x-exceptions-macs", { number: exceptionsMacsNumber })}
                            </span>
                            <span
                                className="block"
                                id="hot-spot-card-manage-exceptions-mac"
                                onClick={() => {
                                    const modal = MacExceptions({ id: name })[0];
                                    dispatch(setModalContent(modal));
                                    dispatch(openModal());
                                }}
                            >
                                <UseButton
                                    buttonName={"manage-exceptions-mac"}
                                    buttonColor={"btn-blue"}
                                    adjust="w-56 m-auto"
                                />
                            </span>
                        </div>
                        <div className="col-span-1 text-center px-3">
                            <span className="block fieldLabel mb-5">
                                {t("x-access-types", { number: accessTypesNumber })}
                            </span>
                            <span
                                className="block"
                                id="hot-spot-card-manage-access-type"
                                onClick={() => {
                                    Session.setSessionProp("accesstype-hotspot-name", name);
                                }}
                            >
                                <UseButton
                                    buttonName={"manage-access-type"}
                                    buttonColor={"btn-blue"}
                                    link="/hotel/property-settings/wifi/access-type"
                                    adjust="w-56 m-auto"
                                />
                            </span>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default UseHotspotCard;
