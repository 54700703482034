export const EditCapacityModal = (area) => {
    const { id, name, capacityLimit } = area;
    return [
        {
            id: id,
            value: name,
            title: "capacity-limit",
            inputs: [
                {
                    name: "people-number",
                    type: "number",
                    ph: "people-number",
                    currentValue: capacityLimit ? capacityLimit : null,
                    text: "enter-capacity-limit",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                { name: "save", style: "blue", action: "area-capacity-save" },
            ],
        },
    ];
};

export default EditCapacityModal;
