import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { GlobalContext } from "contexts/Global";
import ReactInputMask from "react-input-mask";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { parse } from "date-fns";
import { setActiveFilters, getFilterQuery, setPage } from "../../actions/tableActions";
import useComponentVisible from "../../components/useComponentVisible";
import DayPicker, { DateUtils } from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import { HourInput } from "components/Inputs/HourInput";
import { setRefreshData } from "../../actions/tableActions";

const UseFilterScheduling = ({ index }) => {
    //Data
    const { t } = useTranslation();
    const { lang } = useContext(GlobalContext);
    const numberOfMonths = 1;
    const getInitialState = () => {
        return {
            from: undefined,
            to: undefined,
        };
    };
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //States
    const [rangeState, setRangeState] = useState(getInitialState());
    const [hourFrom, setHourFrom] = useState("");
    const [hourTo, setHourTo] = useState("");
    const { from, to } = rangeState;
    const modifiers = { start: from, end: to };
    const [selectActive, setSelectActive] = useState({
        icon: "",
        container: "",
        text: "",
    });

    //Store
    const activeFilters = useSelector((state) => state.table.activeFilters);

    //Actions
    const dispatch = useDispatch();

    const handleDayClick = (day) => {
        const range = DateUtils.addDayToRange(day, rangeState);
        if (range.from) {
            dispatch(setActiveFilters("scheduleFrom", range.from.toISOString()));
        } else {
            dispatch(setActiveFilters("scheduleFrom", null));
        }
        if (range.to) {
            dispatch(setActiveFilters("scheduleTo", range.to.toISOString()));
        } else {
            dispatch(setActiveFilters("scheduleTo", null));
        }
        setRangeState(range);
    };

    const handleCleanInput = (type) => {
        if (type === "start") {
            if (rangeState?.to) {
                dispatch(setActiveFilters("scheduleTo", null));
            } else {
                dispatch(setActiveFilters("scheduleFrom", null));
            }
        } else {
            dispatch(setActiveFilters("scheduleTo", null));
        }
        dispatch(getFilterQuery(activeFilters));
        setRangeState((prevRange) => {
            return type === "start"
                ? {
                      from: null,
                      to: prevRange?.to,
                  }
                : {
                      from: prevRange?.from,
                      to: null,
                  };
        });
    };

    //Listeners
    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({
                icon: "",
                container: "",
                text: "",
            });
        } else {
            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
            });
        }
    }, [isComponentVisible]);

    useEffect(() => {
        dispatch(getFilterQuery(activeFilters));
        dispatch(setRefreshData(true));
        if (activeFilters?.scheduleFrom || activeFilters.scheduleTo) {
            dispatch(setPage(Number(1)));
        }
    }, [activeFilters]);

    useEffect(() => {
        if (rangeState.from && hourFrom) {
            let date = new Date(rangeState.from);
            const time = hourFrom.split(":");
            date.setHours(time[0]);
            date.setMinutes(time[1]);
            dispatch(setActiveFilters("scheduleFrom", date.toISOString()));
        }
    }, [hourFrom]);

    useEffect(() => {
        if (rangeState.to && hourTo) {
            let date = new Date(rangeState.to);
            const time = hourTo.split(":");
            date.setHours(time[0]);
            date.setMinutes(time[1]);
            dispatch(setActiveFilters("scheduleTo", date.toISOString()));
        }
    }, [hourTo]);

    return (
        <div ref={ref}>
            <div
                className={`w-full h-10 bg-gray-200 rounded relative flex items-center pr-12 ${selectActive.container}`}
            >
                <div
                    id={`day-picker-${index}`}
                    className={`bg-transparent w-full pl-2 first-capital overflow-hidden text-gray-900 ${selectActive.text}`}
                    onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                >
                    {rangeState && rangeState.from ? (
                        `${new window.ZDate(rangeState.from)
                            .toLocaleDateString([], {
                                month: "2-digit",
                                day: "2-digit",
                            })
                            .padStart(2, "0")} - ${
                            rangeState.to
                                ? new window.ZDate(rangeState.to)
                                      .toLocaleDateString([], {
                                          month: "2-digit",
                                          day: "2-digit",
                                      })
                                      .padStart(2, "0")
                                : ""
                        }`
                    ) : (
                        <span className="text-gray-600">{t("scheduling")}</span>
                    )}
                </div>
                <span
                    id={`day-picker-icon-${index}`}
                    className={`field-icon icon-chevron animated ${selectActive.icon}`}
                    onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                ></span>
                {isComponentVisible ? (
                    <div className="absolute left-0 w-auto bg-white shadow-lg rounded border border-gray-200 px-1 pt-2 z-100 t-checkboxes-container filterdate">
                        <div className="flex flex-col mb-2 gap-2">
                            <div className="grid grid-cols-12 items-center justify-center gap-1">
                                <span className=" col-span-2 text-center block font-bold">
                                    {capitalizeFirst(t("start_"))}
                                </span>
                                <div className=" col-span-6">
                                    <DateInputComponent
                                        date={from}
                                        range={rangeState}
                                        type={"start"}
                                        onDateChange={(v) => {
                                            if (v["from"]) {
                                                handleDayClick(v["from"]);
                                            }
                                        }}
                                        removeButtonAction={() => {
                                            handleCleanInput("start");
                                        }}
                                    />
                                </div>
                                <div className=" col-span-4">
                                    <HourInput
                                        validation={true}
                                        onChange={(value) => {
                                            setHourFrom(value);
                                        }}
                                    ></HourInput>
                                </div>
                            </div>
                            <div className="grid grid-cols-12 items-center justify-center gap-1">
                                <span className=" col-span-2 text-center block font-bold">
                                    {capitalizeFirst(t("end_"))}
                                </span>
                                <div className=" col-span-6">
                                    <DateInputComponent
                                        date={to}
                                        range={rangeState}
                                        type={"end"}
                                        onDateChange={(v) => {
                                            if (v["to"]) {
                                                handleDayClick(v["to"]);
                                            }
                                        }}
                                        removeButtonAction={() => {
                                            handleCleanInput("end");
                                        }}
                                    />
                                </div>
                                <div className=" col-span-4">
                                    <HourInput
                                        validation={true}
                                        onChange={(value) => {
                                            setHourTo(value);
                                        }}
                                    ></HourInput>
                                </div>
                            </div>
                        </div>

                        <DayPicker
                            firstDayOfWeek={1}
                            // navbarElement={<UseCalendarNavbar />}
                            className="Selectable"
                            numberOfMonths={numberOfMonths}
                            selectedDays={[from, { from, to }]}
                            modifiers={modifiers}
                            onDayClick={handleDayClick}
                            localeUtils={MomentLocaleUtils}
                            locale={lang}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default UseFilterScheduling;

const formatDateToDisplay = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

const DateInputComponent = ({ date, range, onDateChange, type, removeButtonAction }) => {
    const [inputValue, setInputValue] = useState(date ? formatDateToDisplay(date) : "");

    const { t } = useTranslation();

    const handleInputChange = (event) => {
        const newInputValue = event.target.value;
        setInputValue(newInputValue);
    };

    const handleInputBlur = () => {
        const parsedDate = parse(inputValue, "dd/MM/yyyy", new Date());
        if (isValidDate(parsedDate)) {
            if (type === "start") {
                onDateChange({ from: parsedDate }, "start");
            } else if (type === "end") {
                onDateChange({ to: parsedDate }, "end");
            }
            setInputValue(formatDateToDisplay(parsedDate));
        }
    };

    const handleRemoveClick = () => {
        if (removeButtonAction) {
            removeButtonAction();
        }
    };

    useEffect(() => {
        setInputValue(date ? formatDateToDisplay(date) : "");
    }, [date]);

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    return (
        <div
            id={`date-input-container-${type}`}
            className=" date-input-container overflow-hidden h-10 relative flex rounded bg-gray-100"
        >
            <i style={{ top: "0.6rem", left: "0.5rem" }} className=" absolute icon icon-booking "></i>
            {date ? (
                <button
                    id={`date-input-container-${type}-remove`}
                    onClick={handleRemoveClick}
                    style={{ top: "0.6rem", right: "0.5rem" }}
                    className=" absolute h-4 w-4 flex items-center focus:outline-none justify-center bg-zafiro-600 rounded-full"
                >
                    <i className="icon icon-remove text-white text-xxs "></i>
                </button>
            ) : null}
            <ReactInputMask
                id={`date-input-container-${type}-input`}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className=" pl-10 bg-gray-100 rounded w-full "
                mask={"99/99/9999"}
                value={inputValue}
                placeholder={t("date-abbreviation")}
            />
        </div>
    );
};
