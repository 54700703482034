import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "../../assets/css/videojs.css";
import "@videojs/http-streaming";
import { Session } from "../../hooks/Utils/Session";
import { useTranslation } from "react-i18next";
import ReactTooltip from "components/ReactTooltip";
import { checkShowTooltip } from "../../hooks/Utils/Utils";

const UseVideoPlayerCustom = ({
    videosRef,
    getSrc,
    includesVideoSelector = true,
    autoplay = true,
    hideButtonsNavigations = true,
}) => {
    const videoRef = useRef();
    const playerRef = useRef();
    const currentIndexRef = useRef(0);
    const prevButtonRef = useRef(null);
    const nextButtonRef = useRef(null);
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);
    const [showTooltip, setShowTooltip] = useState(false);

    const updatePrevButtonState = () => {
        if (prevButtonRef.current) {
            if (videosRef.length <= 1 || currentIndexRef.current === 0) {
                prevButtonRef.current.disable();
            } else {
                prevButtonRef.current.enable();
            }
        }

        if (playerRef.current) {
            const v = videosRef[currentIndexRef.current];
            const newPosterUrl = Session.getDasUrl(`thumb/${v.ref}-${v.defaultLanguage}?w=633&h=336`);
            playerRef.current.poster(newPosterUrl);
        }
    };

    const updateNextButtonState = () => {
        if (nextButtonRef.current) {
            if (videosRef.length <= 1 || currentIndexRef.current === videosRef.length - 1) {
                nextButtonRef.current.disable();
            } else {
                nextButtonRef.current.enable();
            }
        }

        if (playerRef.current) {
            const v = videosRef[currentIndexRef.current];
            const newPosterUrl = Session.getDasUrl(`thumb/${v.ref}-${v.defaultLanguage}?w=633&h=336`);
            playerRef.current.poster(newPosterUrl);
        }
    };

    useEffect(() => {
        if (!videosRef || videosRef.length === 0) {
            return;
        }
        const options = {
            controls: true,
            autoplay: autoplay,
            preload: "auto",
            // fluid: true,
            fill: true,
            controlBar: {
                children: [
                    "progressControl",
                    "volumePanel",
                    "playToggle",
                    // "remainingTimeDisplay",
                    // "currentTimeDisplay",
                ],
                volumePanel: {
                    inline: false,
                },
            },
            sources: [
                {
                    src: videosRef.length > 0 ? getSrc(videosRef, currentIndexRef) : null,
                    type: "application/x-mpegURL",
                },
            ],
        };
        console.log(videosRef);
        console.log(videosRef.length > 0 ? getSrc(videosRef, currentIndexRef) : null);

        const onReady = (player) => {
            const { customCurrentTimeDisplay, customDurationDisplay, customTooltip } = setupCustomElements(
                player,
                formatTimeAndSetContent
            );
            const playToggle = player.controlBar.playToggle;
            const muteButton = player.controlBar.getChild("VolumePanel").getChild("muteToggle");
            const bigPlayButton = player.el().getElementsByClassName("vjs-big-play-button")[0];
            bigPlayButton.classList.add("icon-Play");

            player.on("ended", () => {
                currentIndexRef.current = (currentIndexRef.current + 1) % videosRef.length;

                const newSrc = videosRef.length > 0 ? getSrc(videosRef, currentIndexRef) : null;

                player.src({
                    src: newSrc,
                    type: "application/x-mpegURL",
                });

                setRefresh((prev) => prev + 1);
                player.play();
            });

            player.on("loadedmetadata", () => {
                // muteButton.addClass("icon-audio");
                // formatTimeAndSetContent(player, customDurationDisplay);
                // console.log(Math.floor(player.duration()));
            });
            player.on("play", function () {
                playToggle.removeClass("icon-Play");
                playToggle.addClass("icon-Pause");
            });

            player.on("pause", function () {
                playToggle.removeClass("icon-Pause");
                playToggle.addClass("icon-Play");
            });
            updateMuteButtonClasses(player, muteButton);

            player.on("volumechange", function () {
                updateMuteButtonClasses(player, muteButton);
            });

            player.on("timeupdate", function () {
                formatTimeAndSetContent(player, customCurrentTimeDisplay, true);
                formatTimeAndSetContent(player, customTooltip, true);
                formatTimeAndSetContent(player, customDurationDisplay);

                // console.log(player.currentTime());
                // console.log(player.duration());
            });
        };

        const videoElement = document.createElement("video");
        videoElement.classList.add("video-js", "vjs-big-play-centered");
        videoRef.current.appendChild(videoElement);

        const player = videojs(videoElement, options, () => {
            onReady(player);
            createNavigationButtons({
                player,
                videosRef,
                currentIndexRef,
                getterSrc: getSrc,
                setRefresh,
                prevButtonRef,
                nextButtonRef,
                hideButtonsNavigations,
            });
        });

        playerRef.current = player;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
            }
        };
    }, [videosRef]);

    return (
        <div className=" w-full h-full flex ">
            <div
                className={` h-full ${includesVideoSelector ? "mr-10" : ""}  `}
                style={{ width: includesVideoSelector ? "80%" : "100%" }}
            >
                <div
                    data-vjs-player
                    className=" h-full"
                    id="container-reproductor"
                    style={{ display: videosRef.length > 0 ? "block" : "none" }}
                >
                    <div id="reproductor" className=" h-full" ref={videoRef}></div>
                </div>
            </div>
            {includesVideoSelector ? (
                <div className="  h-96 border border-gray-300 rounded" style={{ width: "20%" }}>
                    <div className=" border-b mx-2 py-2 font-bold border-gray-300">{t("videos-list")}</div>
                    <div style={{ height: "89%" }} className=" pt-2 mb-10 overflow-x-hidden overflow-y-scroll px-4">
                        {videosRef.map((v, i) => {
                            return (
                                <div
                                    onClick={() => {
                                        setRefresh((prev) => prev + 1);
                                        currentIndexRef.current = i;
                                        const newSrc = videosRef.length > 0 ? getSrc(videosRef, currentIndexRef) : null;

                                        if (playerRef.current) {
                                            playerRef.current.src({
                                                src: newSrc,
                                                type: "application/x-mpegURL",
                                            });
                                            playerRef.current.play();
                                            updateNextButtonState();
                                            updatePrevButtonState();
                                        }
                                    }}
                                    className=" flex mb-4 pt-2 pr-2 "
                                >
                                    <div className=" pr-4 flex items-start ">
                                        <div className=" h-24 flex items-center justify-center">{i + 1}</div>
                                    </div>
                                    <div className=" " style={{ minWidth: "90%", maxWidth: "90%" }}>
                                        <div
                                            style={{
                                                outline: i === currentIndexRef.current ? " 4px solid #2286EF" : "",
                                            }}
                                            className={` w-full  flex justify-center rounded overflow-hidden`}
                                        >
                                            <img
                                                className=" object-cover rounded h-24 w-full"
                                                src={Session.getDasUrl(
                                                    `thumb/${v.ref}-${v.defaultLanguage}?w=633&h=336`
                                                )}
                                            ></img>
                                        </div>
                                        <div
                                            onMouseOver={(e) => {
                                                checkShowTooltip(e.currentTarget, setShowTooltip);
                                            }}
                                            data-tip={showTooltip ? v.name : ""}
                                            className=" mt-1 block whitespace-no-wrap truncate"
                                        >
                                            {v.name}
                                        </div>
                                    </div>
                                    <ReactTooltip
                                        place="bottom"
                                        type="light"
                                        offset={{ top: -8, left: -8 }}
                                        html={true}
                                        border={true}
                                        borderColor="#D3DAE1"
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default UseVideoPlayerCustom;

function formatTimeAndSetContent(player, displayElement, currentTime = false) {
    let time = null;

    if (!currentTime) {
        time = player.duration();
    } else {
        time = player.currentTime();
    }

    let minutes = Math.floor(time / 60);
    let seconds = Math.floor(time % 60);

    // console.log(time % 60);
    // console.log(player.duration());

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    displayElement.textContent = `${minutes}:${seconds}`;
}

function setupCustomElements(player, formatTime) {
    // Crear elementos personalizados
    const progressControl = document.getElementsByClassName("vjs-progress-control")[0];
    const playProgress = document.querySelector(".vjs-play-progress");
    const customCurrentTimeDisplay = document.createElement("div");
    const customDurationDisplay = document.createElement("div");
    const customTooltip = document.createElement("div");

    customCurrentTimeDisplay.className = "vjs-custom-current-time-display";
    customDurationDisplay.className = "vjs-custom-duration-display";
    customTooltip.className = "vjs-custom-current-time-tooltip";

    if (customCurrentTimeDisplay && progressControl) {
        progressControl.appendChild(customCurrentTimeDisplay);
    }

    if (customDurationDisplay && progressControl) {
        progressControl.appendChild(customDurationDisplay);
    }

    if (playProgress) {
        playProgress.appendChild(customTooltip);
    }

    formatTime(player, customDurationDisplay);
    formatTime(player, customCurrentTimeDisplay);

    return { customCurrentTimeDisplay, customTooltip, customDurationDisplay };
}

function createNavigationButtons({
    player,
    videosRef,
    currentIndexRef,
    getterSrc,
    setRefresh,
    prevButtonRef,
    nextButtonRef,
    hideButtonsNavigations,
}) {
    const Button = videojs.getComponent("Button");

    const nextButton = videojs.extend(Button, {
        constructor: function () {
            Button.apply(this, arguments);
            this.addClass("vjs-next-control");
            this.addClass("icon-PlayOptions-next");
            this.controlText("Next");
            const el = this.el();
            // el.innerHTML = "<span>Next</span>";
        },
    });
    videojs.registerComponent("nextButton", nextButton);
    player.getChild("controlBar").addChild("nextButton", {});

    const prevButton = videojs.extend(Button, {
        constructor: function () {
            Button.apply(this, arguments);
            this.addClass("vjs-prev-control");
            this.addClass("icon-PlayOptions-previous");
            this.controlText("Previous");
            const el = this.el();
            // el.innerHTML = "<span>Previous</span>";
        },
    });
    videojs.registerComponent("prevButton", prevButton);
    player.getChild("controlBar").addChild("prevButton", {});

    prevButtonRef.current = player.getChild("controlBar").getChild("prevButton");
    nextButtonRef.current = player.getChild("controlBar").getChild("nextButton");
    const prevButtonComponent = player.getChild("controlBar").getChild("prevButton");
    const nextButtonComponent = player.getChild("controlBar").getChild("nextButton");

    const updateButtonAndPosterState = () => {
        // Actualizar el estado de los botones
        if (currentIndexRef.current === 0) {
            prevButtonComponent.disable();
        } else {
            prevButtonComponent.enable();
        }

        if (currentIndexRef.current === videosRef.length - 1) {
            nextButtonComponent.disable();
        } else {
            nextButtonComponent.enable();
        }

        // Cambiar el póster
        const v = videosRef[currentIndexRef.current];
        const newPosterUrl = Session.getDasUrl(`thumb/${v.ref}-${v.defaultLanguage}?w=633&h=336`);
        player.poster(newPosterUrl);
    };
    prevButtonComponent.on(["click"], () => {
        currentIndexRef.current = (currentIndexRef.current - 1 + videosRef.length) % videosRef.length;
        player.src({
            src: videosRef.length > 0 ? getterSrc(videosRef, currentIndexRef) : null,
            type: "application/x-mpegURL",
        });
        setRefresh((prev) => prev + 1);

        player.play();

        updateButtonAndPosterState();
    });

    nextButtonComponent.on(["click"], () => {
        currentIndexRef.current = (currentIndexRef.current + 1) % videosRef.length;
        // console.log(currentIndexRef);
        player.src({
            src: videosRef.length > 0 ? getterSrc(videosRef, currentIndexRef) : null,
            type: "application/x-mpegURL",
        });
        setRefresh((prev) => prev + 1);
        player.play();

        updateButtonAndPosterState();
    });

    if (hideButtonsNavigations) {
        nextButtonComponent.addClass("vjs-custom-hidden");
        prevButtonComponent.addClass("vjs-custom-hidden");
    }

    updateButtonAndPosterState();
    player.on("ended", function () {
        updateButtonAndPosterState();
    });
}

const updateMuteButtonClasses = (player, muteButton) => {
    if (player.muted() || player.volume() === 0) {
        muteButton.addClass("icon-mute");
        muteButton.removeClass("icon-audio");
    } else {
        muteButton.addClass("icon-audio");
        muteButton.removeClass("icon-mute");
    }
};
