import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { setScheduleToUpdate } from "../../actions/scheduleActions";
//Components
import DayPicker, { DateUtils } from "react-day-picker";
import UseCalendarNavbar from "./useCalendarNavbar";
//Utils
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/es";

const UseCalendarSpecificEditSchedule = ({ datesConverted, index, setDatesConverted }) => {
    //Store data
    const { scheduleToUpdate } = useSelector((state) => state.schedules);
    //States
    const [selectedDays, setSelectedDays] = useState(currentDates(datesConverted));

    //Actions
    const dispatch = useDispatch();

    const handleDayClick = (day, { selected }) => {
        let daysSelected;
        // eslint-disable-next-line
        if (selected) {
            daysSelected = [];
            // eslint-disable-next-line
            selectedDays.map((selectedDay) => {
                if (!DateUtils.isSameDay(selectedDay, day)) {
                    daysSelected.push(selectedDay);
                }
            });
        } else {
            daysSelected = selectedDays;
            daysSelected.push(day);
        }
        setSelectedDays(daysSelected);
        const newDateList = getNewDateList(daysSelected);
        //Create new scheduleToUpdateInfo
        const newScheduleInfo = scheduleToUpdate;
        newScheduleInfo.timetableDates[index].dateList = `${newDateList}`;
        //Update current store
        dispatch(setScheduleToUpdate(newScheduleInfo));
        setDatesConverted(daysSelected);
    };

    return (
        <div>
            {/*ISO 8601 standard Monday(1) Sunday (7)*/}
            <DayPicker
                firstDayOfWeek={1}
                navbarElement={<UseCalendarNavbar />}
                selectedDays={selectedDays}
                onDayClick={handleDayClick}
                localeUtils={MomentLocaleUtils}
                locale="es"
            />
        </div>
    );
};

const getNewDateList = (selectedDays) => {
    //Returns dateList string from array of date objects
    let res = `[`;
    // eslint-disable-next-line
    selectedDays.map((selectedDay, index) => {
        const dateFormatted = formatDate(selectedDay);
        if (index !== 0) {
            res += `,`;
        }
        res += `{${dateFormatted}-${dateFormatted}}`;
    });
    res += res = `]`;
    return res;
};

const formatDate = (date) => {
    //Returns valid mutation string from date object
    let month = `${date.getMonth() + 1}`;
    let day = `${date.getDate()}`;
    const year = date.getFullYear();

    if (month.length < 2) {
        month = `0${month}`;
    }
    if (day.length < 2) {
        day = `0${day}`;
    }

    return `${year}/${month}/${day}`;
};

const currentDates = (datesConverted) => {
    //Convert Strings to dates
    let tempArray = [];
    if (datesConverted) {
        // eslint-disable-next-line
        datesConverted.map((specificDate) => {
            tempArray.push(new window.ZDate(specificDate));
        });
    }
    return tempArray;
};

export default UseCalendarSpecificEditSchedule;
