import { gql } from "apollo-boost";
import { TopologyContext } from "contexts/Topology";
import { useContext } from "react";
import { useMutation } from "react-apollo";

export const useEditRoom = () => {
    const { tvs, location, tvsLengthWhileEditing, setLoadingRefresh } = useContext(TopologyContext);
    const filteredLocations = location.map((loc) => {
        const { __typename, id, cec, ...rest } = loc;
        return rest;
    });

    const EDIT_ROOM = gql`
        mutation EditRoom ($id: Int64!, $name: String!, $number: Int!, $pmsNumber: String){
            updateRoom(id: $id, name: $name, number: $number, pmsNumber: $pmsNumber){
                error
                ok
            }
            ${Array.from({ length: Math.max(tvs, location.length) }, (_, i) => {
                if (i < tvsLengthWhileEditing && i >= tvs) {
                    return `deleteRoomTV${i + 1}: deleteRoomTV(roomTVID: ${location[i]?.id}){
                                error
                                ok
                            }
                            `;
                } else if (i < tvsLengthWhileEditing && i < tvs && location[i]?.id) {
                    return `updateRoomTV${i + 1}: updateRoomTV(roomTvID: ${location[i]?.id}, location: {name: "${
                        filteredLocations[i]?.name
                    }", CEC: ${filteredLocations[i]?.CEC}}){
                                error
                                ok
                            }
                            `;
                } else if (i >= tvsLengthWhileEditing && i < tvs) {
                    return `createRoomTV${i + 1}: createRoomTV(roomID: $id, location: {name: "${
                        filteredLocations[i]?.name
                    }", CEC: ${filteredLocations[i]?.CEC || false}}){
                                error
                                ok
                            }
                            `;
                } else {
                    return "";
                }
            })}
        }
    `;

    const [editRoomQuery] = useMutation(EDIT_ROOM);

    const editRoom = (id, name, number, pmsNumber) => {
        editRoomQuery({
            variables: {
                id,
                name,
                number,
                pmsNumber,
            },
        }).then((response) => {
            if (response.data.updateRoom.ok) {
                setLoadingRefresh(true);
            }
            return response;
        });
    };

    return { editRoom };
};
