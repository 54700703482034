// SECTIONS

import { gql } from "apollo-boost";

export const LIST_MOBILE_APPS = "list-mobile-apps";

// ACTIONS

export const DELETE_MOBILE_APP = "delete-mobile-app";
export const DELETE_MOBILE_APPS = "delete-mobile-apps";
export const CREATE_MOBILE_APP = "create-mobile-app";

export const QUERY_MOBILE_APP_DOMAIN_AND_PROJECT_PERMISSIONS = ({ projectsCode }) => {
    let projectsCodeBuildQuery = "";

    if (projectsCode?.length > 0) {
        projectsCode.forEach((p) => {
            projectsCodeBuildQuery += `permissions${p} : permissions(projectCode: "${p}") `;
        });
    }

    return gql(`query {
        getSystemConfigs{
          mobileAppDomain
        }
          ${projectsCodeBuildQuery ? projectsCodeBuildQuery : ""}
      }`);
};

export const MUTATION_VALIDATE_CREATE_MOBILE = ({ url }) => {
    return gql(`mutation {
        validateCreateMobileApp(url: "${url}") {
            error
            ok
        }
    }`);
};

export const QUERY_MOBILE_APPS = () => {
    return gql(`query{
            mobileApps
            {
                info{
                count
                page
                size
                }
                results{
                id
                isMultiProperty
                name
                shortName
                url
                projects{
                    id
                    name
                    ref
                }
                }
            }
            }`);
};

export const QUERY_MOBILE_APPS_FILTER = (id) => {
    return gql(`
        query {
            mobileApps(filter: { id: ${id} }) {
                results {
                    name
                projects {
                    id
                    name
                    information {
                    appAvailable
                    chatNumber
                    chatNumberLada
                    picture
                    }
                    ref
                }
                }
            }
        }
`);
};
