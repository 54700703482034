import { latinise } from "hooks/Utils/Utils";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ChannelSelector = ({
    channelIndex,
    channel,
    channels,
    setChannels,
    closeAll,
    setCloseAll,
    predefinedChannels,
    tunedChannels,
    checkedChannels,
    setCheckedChannels,
}) => {
    const { t } = useTranslation();
    const [showOptions, setShowOptions] = useState(false);
    const [link, setLink] = useState();
    const [filter, setFilter] = useState();
    const [filteredPredefined, setFilteredPredefined] = useState(predefinedChannels);
    const [filteredTuned, setFilteredTuned] = useState(tunedChannels);

    const setNewLink = (channelLink, predefined) => {
        setLink(channelLink.Name);
        setShowOptions(false);
        let newChannels = channels;
        newChannels.forEach((ch) => {
            if (ch.Name === channel.Name) {
                setCheckedChannels([...checkedChannels, ch.Name]);
                ch.Tune = predefined ? null : channelLink;
                ch.PredefinedTvChannel = predefined ? channelLink : null;
            }
        });
        setChannels([...newChannels]);
    };

    const removeLink = (e) => {
        e.stopPropagation();
        setLink(null);
        setShowOptions(false);
        let newChannels = channels;
        newChannels.forEach((ch) => {
            if (ch.Name === channel.Name) {
                ch.Tune = null;
                ch.PredefinedTvChannel = null;
            }
        });
        setChannels([...newChannels]);
    };

    const updateFilter = (e) => {
        setFilter(e.target.value);
    };

    const sortChannels = (chList) => {
        if (!chList) {
            return null;
        }
        return chList.sort((a, b) => (a.Name > b.Name ? 1 : -1));
    };

    useEffect(() => {
        setShowOptions(false);
    }, [closeAll]);

    useEffect(() => {
        if (filter) {
            setFilteredPredefined(
                sortChannels(
                    predefinedChannels?.filter((pChannel) =>
                        latinise(pChannel.Name.toLowerCase()).includes(latinise(filter.toLowerCase()))
                    )
                )
            );
            setFilteredTuned(
                sortChannels(
                    tunedChannels?.filter((tChannel) =>
                        latinise(tChannel.Name.toLowerCase()).includes(latinise(filter.toLowerCase()))
                    )
                )
            );
        } else {
            setFilteredPredefined(sortChannels(predefinedChannels));
            setFilteredTuned(sortChannels(tunedChannels));
        }
    }, [filter, predefinedChannels, tunedChannels]);

    useEffect(() => {
        !showOptions && setFilter(null);
        const searchInput = document.getElementById("search_input");
        if (searchInput) {
            searchInput.focus();
        }
    }, [showOptions]);

    useEffect(() => {
        setLink(channel?.PredefinedTvChannel?.Name || channel?.Tune?.Name);
    }, []);
    useEffect(() => {
        channel && setLink(channel?.PredefinedTvChannel?.Name || channel?.Tune?.Name);
    }, [channel.PredefinedTvChannel || channel.Tune]);

    return (
        <>
            <div
                id={`input-channel-selector-${channelIndex}`}
                className={`t-filter-input w-9/12 bg-gray-200 block`}
                onClick={(e) => {
                    setCloseAll(new Date().getMilliseconds());
                    setTimeout(function () {
                        setShowOptions(!showOptions);
                    }, 1);
                    e.stopPropagation();
                }}
            >
                <div className={`t-filter-ph`}>
                    <div className={`first-capital ${link && "text-gray-900"} `}>
                        {link ? (
                            <div class="float-left z-20  bg-blue-200 text-white pl-2 mr-4 py-0.5 px-1 text-base my-0.5 rounded cursor-default">
                                {link}
                                <div
                                    id={`input-channel-selector-${channelIndex}-remove-button`}
                                    class="icon-remove inline-block ml-8 cursor-pointer"
                                    onClick={(e) => removeLink(e)}
                                ></div>
                            </div>
                        ) : null}
                        {!link && t("select-channel_")}
                    </div>
                </div>
                <span className={`field-icon icon-chevron animated text-gray-900`}></span>
                {showOptions ? (
                    <div className="absolute w-full mt-1 py-2 z-100 bg-white  rounded shadow-lg  border border-gray-200  ">
                        <div>
                            <div>
                                <input
                                    id={`search_input`}
                                    type="text"
                                    className="t-filter-search"
                                    style={{ width: "calc(100% - 1rem)" }}
                                    placeholder={t("search") + "..."}
                                    onClick={(e) => e.stopPropagation()}
                                    value={filter}
                                    onChange={(e) => updateFilter(e)}
                                ></input>
                                <span
                                    id={`search_input-icon`}
                                    className="field-icon icon-search table"
                                    style={{ height: "2rem", lineHeight: "4.5rem" }}
                                ></span>
                            </div>
                        </div>
                        <div className="mx-5 max-h-64 overflow-y-auto overflow-x-hidden">
                            {filteredPredefined?.length > 0 && (
                                <div
                                    className="font-bold py-2 border-b border-gray-200"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {t("preset-channels")}
                                </div>
                            )}
                            {filteredPredefined?.map((pChannel, index) => {
                                const channelCountry = pChannel?.CountryRef?.toLowerCase()
                                    ? t(`country:${pChannel?.CountryRef?.toLowerCase()}`)
                                    : null;

                                return index < 1000 ? (
                                    <div
                                        id={`btn-predefined-${index}`}
                                        key={`prdefined-${pChannel.Name}-${pChannel.ID}`}
                                        onClick={(e) => setNewLink(pChannel, true)}
                                        className="py-2 clickable"
                                    >
                                        <div>
                                            {pChannel.Name}
                                            <span className="text-gray-600 ml-2">({channelCountry})</span>
                                        </div>
                                    </div>
                                ) : null;
                            })}
                            {filteredTuned?.length > 0 && (
                                <div
                                    className="font-bold py-2 border-b border-gray-200"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {t("customised-channels")}
                                </div>
                            )}

                            {filteredTuned?.map((tChannel, index) => {
                                const channelCountryIso =
                                    tChannel?.PredefinedTVChannel?.CountryRef ||
                                    tChannel?.CountryRef ||
                                    tChannel?.CustomTVChannel?.CountryRef;
                                const channelCountry = channelCountryIso?.toLowerCase()
                                    ? t(`country:${channelCountryIso?.toLowerCase()}`)
                                    : null;
                                return index < 1000 ? (
                                    <div
                                        id={`btn-tuned-${index}`}
                                        key={`tuned-${tChannel.Name}-${tChannel.id}`}
                                        onClick={(e) => setNewLink(tChannel)}
                                        className="py-2 clickable"
                                    >
                                        <div>
                                            {tChannel.Name}
                                            <span className="text-gray-600 ml-2">({channelCountry})</span>
                                        </div>
                                    </div>
                                ) : null;
                            })}
                            {filteredTuned?.length === 0 && filteredPredefined?.length === 0 ? (
                                <div className="w-full text-center mt-6">{t("no-results-found")}</div>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default ChannelSelector;
