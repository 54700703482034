import { capitalizeFirst } from "../../../../hooks/Utils/Utils";

const MakeContentDefault = (t, title) => {
    return {
        titlePrefix: title,
        title: ["-", capitalizeFirst(t("make-default"))],
        executeSectionQuery: true,
        bodyAdjust: "w-3/12",
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "make-default-fem",
                style: "blue",
                action: "make-content-default",
            },
        ],
        inputs: [
            {
                text: `${t("make-default-text")}.`,
                type: "htmltext",
            },
        ],
    };
};

export default MakeContentDefault;
