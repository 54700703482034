import React, { useContext } from "react";
import { useNavigate as useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UseTitle from "components/useTitle";
import ZafiroTable from "components/ZafiroTable";
import Loading from "components/Loading";
import { formatDate } from "../Monitor/services/formatDate";
import { useGetCCsGuestNetworkFilterInfo } from "hooks/GraphqlCalls/Hotel/Monitoring/useGetCCsGuestNetworkFilterInfo";
import { useGetCCguestNetwork } from "hooks/GraphqlCalls/Hotel/Monitoring/useGetCCguestNetwork";
import { UserNetworkContext } from "contexts/NetworkWarnings/UserNetwork";
import Icon from "components/Icon";
import { useModal } from "components/Modal";
import Button from "components/Button";

export const ChromeCastWarnings = () => {
    const { open, close } = useModal();
    const { tableLength } = useGetCCguestNetwork();
    const { t } = useTranslation();

    return (
        <div className="flex items-center mr-1">
            <i className="icon icon-warning warning mr-1 text-2xl"></i>
            <p>
                {t("user-network")}:{" "}
                <span
                    className="text-blue-300 font-bold cursor-pointer"
                    id="open-cc-warnings"
                    onClick={() =>
                        open({
                            id: "ccs-warnings-modal",
                            title: (
                                <div className="flex items-center gap-3">
                                    <UseTitle text={t("ccs-guest-network")} />
                                    <Icon size="3xl" className="icon icon-info" tooltip={t("excluded-ccwgtv")} />
                                </div>
                            ),
                            info: t("excluded-ccwgtv"),
                            children: <ChromeCastUserNetwork t={t} />,
                            footer: (
                                <Button design="blue" id="modal-button-close" onClick={close}>
                                    {t("close")}
                                </Button>
                            ),
                        })
                    }
                >
                    {t("x chromecasts", { count: tableLength })}
                </span>
            </p>
        </div>
    );
};

const ChromeCastUserNetwork = ({ t }) => {
    const { serverError } = useSelector((state) => state.table);
    const { updatedData, isPending, update, updatedFilter } = useContext(UserNetworkContext);
    const { ccsGuestNetworkFilterInfo } = useGetCCsGuestNetworkFilterInfo();
    const history = useHistory();

    const lastUpdate = updatedData?.lastUpdate;
    const data = updatedData?.data;

    return (
        <>
            <p className="py-6">{t("cc-user-network-info")}</p>
            {!isPending ? (
                <>
                    {ccsGuestNetworkFilterInfo ? (
                        <ZafiroTable
                            id="ccs-guest-network"
                            search={true}
                            showCount={false}
                            filters={updatedFilter ?? ccsGuestNetworkFilterInfo}
                            maxHeigth={"max-h-30"}
                            cols={["name", "deviceModel", "tvLocation", "ip", "ownership"]}
                            topRightCorner={
                                <div className="flex items-center gap-3">
                                    <p>
                                        {t("last-update")}: {formatDate(lastUpdate)}
                                    </p>
                                    <Button
                                        id="update-cc-button"
                                        design="blue-outline"
                                        onClick={update}
                                        disabled={serverError}
                                        tooltip={serverError ? t("non-response-server") : t("take-few-minutes")}
                                    >
                                        {t("update")}
                                    </Button>
                                </div>
                            }
                            header={{
                                name: {
                                    title: t("name"),
                                    sortable: true,
                                },
                                deviceModel: {
                                    title: t("device-model"),
                                    sortable: true,
                                },
                                tvLocation: {
                                    title: t("tv-locations"),
                                    sortable: true,
                                },
                                ip: {
                                    title: t("ip"),
                                },
                                ownership: {
                                    title: t("ownership"),
                                    sortable: true,
                                },
                            }}
                            rows={
                                data
                                    ? data.map((item) => {
                                          return item
                                              ? {
                                                    type: item.type,
                                                    model: item.model,
                                                    roomID: item.roomID,
                                                    roomNumber: item.roomNumber,

                                                    name: (
                                                        <div alt={item.name + " " + item.mac}>
                                                            <p>{item.name}</p>
                                                            <span className="text-gray-700">{`MAC: ${item.mac.toUpperCase()}`}</span>{" "}
                                                        </div>
                                                    ),
                                                    deviceModel: (
                                                        <div alt={item.type + " " + item.model}>
                                                            <p>{item.type}</p>
                                                            {item.model ? (
                                                                <span className="text-gray-700">{item.model}</span>
                                                            ) : null}
                                                        </div>
                                                    ),
                                                    ...(item.roomID !== 0 && {
                                                        tvLocation: (
                                                            <Button
                                                                design="link"
                                                                id="room-detail"
                                                                onClick={() => {
                                                                    history(`/hotel/rooms/room/detail/${item.roomID}`);
                                                                }}
                                                                alt={item.roomID + " " + item.roomNumber}
                                                            >
                                                                {item.roomNumber}
                                                            </Button>
                                                        ),
                                                    }),
                                                    ip: item.ip,
                                                    ownership: item ? (
                                                        <div alt={item.isZafiroDevice}>
                                                            {item.isZafiroDevice ? "Zafiro" : "No Zafiro"}
                                                        </div>
                                                    ) : null,
                                                    isZafiroDevice: item.isZafiroDevice,
                                                }
                                              : null;
                                      })
                                    : null
                            }
                        />
                    ) : (
                        <div className="flex flex-col justify-center items-center gap-20 mt-16">
                            <Loading adjust={"block"} />
                        </div>
                    )}
                </>
            ) : (
                <div className="flex flex-col justify-center items-center gap-20 mt-16">
                    <p className="font-bold text-gray-800">{t("take-few-minutes")}</p>
                    <Loading adjust={"block"} />
                </div>
            )}
        </>
    );
};
