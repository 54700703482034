import { useState, useEffect, useContext } from "react";
import { useLazyQuery } from "react-apollo";
import { GET_DHCP_INFO } from "../services/getMonitorInfo";
import { NetworkAlertsContext } from "contexts/NetworkWarnings/NetworkAlerts";

export const useDhcpInfo = () => {
    const [dhcpInfo, setDhcpInfo] = useState();
    const { setDhcpCriticalWarning, setDhcpLowWarning } = useContext(NetworkAlertsContext);

    const [executeGetInfoQuery, { data, called, loading, networkStatus, refetch }] = useLazyQuery(GET_DHCP_INFO, {
        pollInterval: 200000,
    });

    useEffect(() => {
        executeGetInfoQuery();
    }, []);

    useEffect(() => {
        if (!loading && networkStatus === 8) {
            setDhcpInfo((prevNetworkInfo) => prevNetworkInfo);
        }
    }, [loading, networkStatus]);

    useEffect(() => {
        const info = data?.getMonitorDHCPInfo;
        const criticalWarning = info?.data?.otherServers?.length > 0;
        if (!loading && info && networkStatus === 7) {
            setDhcpInfo(dhcpModel(info));
            setDhcpCriticalWarning(criticalWarning);
            setDhcpLowWarning(info?.data?.dhcpLeaseWarning || info?.data?.dnsWarning);
        }
    }, [data]);

    return { dhcpInfo, called, loading, refetch };
};

const dhcpModel = (info) => {
    const { lastUpdate, updateTimeMin } = info || {};
    const { ip, ipsCount, otherServers, dhcpLeaseWarning, freeIpsCount, gw, startIp, endIp, dns, dnsWarning } =
        info.data || {};
    return {
        dhcpData: {
            ip: {
                value: ip,
                warning: otherServers,
            },
            "gateway-ip": gw,
            dns: {
                value: dns,
                warning: dnsWarning,
            },
            range: `${startIp} - ${endIp}`,
            "free-ips": {
                value: `${freeIpsCount} / ${ipsCount}`,
                warning: dhcpLeaseWarning,
            },
        },
        lastUpdate,
        updateTimeMin,
    };
};
