import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Icon from "components/Icon";
import Parser from "hooks/Utils/Parser";
import Loading from "components/Loading";
import { useMakeAsDefaultSequence } from "../graphql/useSignages";

const ModalMakeDefaultSequence = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { setAsDefault } = useMakeAsDefaultSequence({
        onCompleted: () => {
            setLoading(false);
            props.getSignages();
            props.close();
        },
    });

    const handleSaveClick = () => {
        setLoading(true);
        setAsDefault({ variables: { id: props.id } });
    };

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={`${props.name} - ${t("make-default")}`}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-add" disabled={loading} onClick={handleSaveClick}>
                        {t("make-default")}
                    </Button>
                </>
            }
            className={"w-5/12 p-10"}
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <div className="flex flex-col gap-2">
                    <p>
                        {Parser(
                            t("are-you-sure-to-make-x-the-default-sequence", {
                                name: `<span class="font-bold">${props.name}</span>`,
                            })
                        )}
                    </p>
                    <p>{t("this-sequence-will-be-displayed-as-default-in-all-common-zones")}</p>
                    <div className="flex gap-2">
                        <Icon type={"warning"} size={"2xl"}></Icon>
                        <p>{t("please-note-that-if-it-has-scheduling-or-filters-applied")}</p>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export const UseModalMakeDefaultSequence = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalMakeDefaultSequence {...newProps} />);
        },
    };
};
