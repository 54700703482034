const AssignedUser = ({ data, openModalDeleteAssignedUsers, usersAssigned, shopId }) => {
    const { id, name, email } = data;

    return {
        dataType: "assigned-user",
        id: id,
        adjust: "h-auto",
        actions: [
            {
                type: "assigned-user",
                value: id,
            },
        ],
        name: name,
        executeSectionQuery: true,
        actionComponentStyle: { left: "-160px" },
        info: [
            {
                cellContent: "text",
                value: name,
                specialWidth: "w-3/12 pl-1",
                wrap: "truncate",
            },
            {
                cellContent: "text",
                value: email,
                specialWidth: `w-8/12`,
                wrap: "truncate",
            },
            {
                cellContent: "icon",
                specialWidth: "w-1/12",
                adjust: "pl-10",
                onClickAction: () => {
                    openModalDeleteAssignedUsers({ name, id, usersAssigned, shopId });
                },
                isAction: true,
                disabledIcon: false,
                name: "user",
                icon: {
                    name: "delete",
                    adjust: `" text-2xl text-gray-900`,
                },
            },
        ],
    };
};

export default AssignedUser;
