import React, { useContext } from "react";

import { GlobalContext } from "contexts/Global";

export const UseModalAdditionalInformation = ({ guestInfo }) => {
    const { lang } = useContext(GlobalContext);

    const validateInfo = (data) => {
        return data ?? "-";
    };

    const additionalInformation = guestInfo.customFields
        .reduce((acc, curr) => {
            const title =
                curr.names.find((val) => val.lang == lang).name || curr.names.find((val) => val.name != "").name;
            const text = guestInfo.guestInfo.guestCustomFieldsInformation.find((val) => {
                return val.Ref === curr.ref;
            });
            acc.push({ title: validateInfo(title), text: validateInfo(text?.Value), order: curr.order });
            return acc;
        }, [])
        .sort((a, b) => {
            return a.order - b.order;
        });
    return (
        <div className=" grid grid-cols-2 gap-y-4">
            {additionalInformation.map((val) => {
                return (
                    <div>
                        <p>{val.title}</p>
                        <p>{val.text}</p>
                    </div>
                );
            })}
        </div>
    );
};
