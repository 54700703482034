import React from "react";

export const Warning = ({ color = "red", text, ml = 3 }) => {
    const textIsLong = text.length < 100;
    return (
        <div className={`flex gap-2 ml-${ml} ${textIsLong ? "items-center" : ""} text-${color}-100`}>
            <span className={`icon icon-warning ${textIsLong ? "" : "mt-1"}`}></span>
            <p className="text-gray-800">{text}</p>
        </div>
    );
};
