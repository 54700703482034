import React from "react";
import { useTranslation } from "react-i18next";
import UseRadio from "../../../useRadio";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import { generateContent, generateContent2, UPDATE_PASSCODE } from "./Utils";
import UseButton from "../../../useButton";
import AccessTypesCB from "./AccessTypesCB";
import AccessTypesSwitch from "./AccessTypesSwitch";
import Icon from "components/Icon";

const AccessTypesCode = ({
    codeData,
    setCardEdition,
    setCodeFields,
    codeFields,
    codeFieldsBackup,
    setKeepCurrentPreview,
    setSelectMutation,
}) => {
    const { t } = useTranslation();
    const sectionName = "access-type-code";
    const emailEnabled = codeFields && codeFields["code-email"] && codeFields["code-email"].enabled;
    const passcodeBreakconn = codeFields && codeFields["code-breakconn"];
    const passcodeCheckboxData = [
        {
            name: "email",
            dataField: codeFields,
            keyField: "code-email",
            singleValue: false,
            id: `${sectionName}-email-checkbox`,
        },
        {
            name: "name",
            dataField: codeFields,
            keyField: "code-name",
            singleValue: false,
            id: `${sectionName}-name-checkbox`,
        },
        {
            name: "surname",
            dataField: codeFields,
            keyField: "code-surname",
            singleValue: false,
            id: `${sectionName}-surname-checkbox`,
        },
        {
            name: "birthdate",
            dataField: codeFields,
            keyField: "code-date-of-birth",
            singleValue: false,
            id: `${sectionName}-date-of-birth-checkbox`,
        },
        {
            name: "country",
            dataField: codeFields,
            keyField: "code-country",
            singleValue: false,
            id: `${sectionName}-country-checkbox`,
        },
    ];

    const passcodeSwitchData = [
        {
            dataField: codeFields,
            keyField: "code-email",
            id: `${sectionName}-email-switch`,
        },
        {
            dataField: codeFields,
            keyField: "code-name",
            id: `${sectionName}-name-switch`,
        },
        {
            dataField: codeFields,
            keyField: "code-surname",
            id: `${sectionName}-surname-switch`,
        },
        {
            dataField: codeFields,
            keyField: "code-date-of-birth",
            id: `${sectionName}-date-of-birth-switch`,
        },
        {
            dataField: codeFields,
            keyField: "code-country",
            id: `${sectionName}-country-switch`,
        },
    ];

    const content = generateContent(emailEnabled, passcodeBreakconn);
    const content2 = generateContent2(emailEnabled, passcodeBreakconn);

    return (
        <div>
            <div>
                <div className=" pl-2 border-b text-left font-bold border-gray-400">
                    {capitalizeFirst(t("content"))}
                </div>
                <div className=" flex pl-2">
                    <div className=" w-1/2 mr-10">
                        {/* FIRST-STEP-PASSCODE */}
                        <div className="  mt-4 mb-4 text-gray-800 text-left">{capitalizeFirst(t("first-step"))}</div>
                        <div className=" text-left mb-4">
                            {capitalizeFirst(t("check-you-want-to-add-email-option"))}
                        </div>
                        <div className=" w-full flex  pr-1 ">
                            {/* EMAIL-CHECKBOX-PASSCODE */}
                            <div className="  w-1/2 ">
                                <AccessTypesCB data={passcodeCheckboxData[0]} setState={setCodeFields} />
                            </div>
                            <div
                                className={` flex justify-end  w-1/2 ${
                                    emailEnabled ? "text-gray-900" : "text-gray-600"
                                } `}
                            >
                                <span className=" mr-2 ">{capitalizeFirst(t("mandatory"))}</span>
                                <AccessTypesSwitch
                                    data={passcodeSwitchData[0]}
                                    setState={setCodeFields}
                                    forceDisabled={!emailEnabled}
                                />
                            </div>
                        </div>
                        <div className=" w-full flex">
                            {/* EMAIL-VALIDATION-PASSCODE */}
                            <div className="ml-8">
                                <div className={`text-left ${emailEnabled ? "text-gray-900" : "text-gray-600"}`}>
                                    {capitalizeFirst(t("email-validation-every-login"))}
                                    <Icon
                                        type="info"
                                        size="2xl"
                                        className="text-gray-700 inline-block ml-2"
                                        tooltip={`${t("by-checking-yes-user-does-not-validate-email")}`}
                                    />
                                </div>
                                <div className={`mt-2 ${emailEnabled ? "text-gray-900" : "text-gray-600"}`}>
                                    <div
                                        className=" mb-3"
                                        onClick={() => {
                                            if (emailEnabled) {
                                                setCodeFields({
                                                    ...codeFields,
                                                    "code-breakconn": true,
                                                });
                                            }
                                        }}
                                    >
                                        <div className=" relative">
                                            <UseRadio
                                                selected={codeFields && codeFields["code-breakconn"]}
                                                value={true}
                                                id={`${sectionName}-validation-required-radio-true`}
                                            />
                                            {content}
                                        </div>
                                        <div className=" ml-6 w-4 -mt-1">{capitalizeFirst(t("yes"))}</div>
                                    </div>
                                    <div
                                        className=" "
                                        onClick={() => {
                                            if (emailEnabled) {
                                                setCodeFields({
                                                    ...codeFields,
                                                    "code-breakconn": false,
                                                });
                                            }
                                        }}
                                    >
                                        <div className=" relative">
                                            <UseRadio
                                                selected={codeFields && codeFields["code-breakconn"]}
                                                value={false}
                                                id={`${sectionName}-validation-required-radio-false`}
                                            />
                                            {content2}
                                        </div>
                                        <div className=" ml-6 w-4  -mt-1">{capitalizeFirst(t("no"))}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" w-1/2">
                        {/* SECOND-STEP-PASSCODE */}
                        <div className="  mt-4 mb-4 text-gray-800 text-left">{capitalizeFirst(t("second-step"))}</div>
                        <div className={`text-left ${emailEnabled ? "text-gray-900" : "text-gray-600"}`}>
                            {capitalizeFirst(t("ask-for-aditional-information-requested-not-registered"))}
                        </div>
                        <div className=" text-right mr-14 text-gray-800 mt-2">{capitalizeFirst(t("mandatory"))}</div>
                        {/* CHECKBOXES-PASSCODE */}
                        <div className=" w-full flex">
                            <div className=" mt-3  w-1/2 ">
                                <AccessTypesCB
                                    data={passcodeCheckboxData[1]}
                                    setState={setCodeFields}
                                    disabled={!emailEnabled}
                                />
                                <AccessTypesCB
                                    data={passcodeCheckboxData[2]}
                                    setState={setCodeFields}
                                    disabled={!emailEnabled}
                                />
                                <AccessTypesCB
                                    data={passcodeCheckboxData[3]}
                                    setState={setCodeFields}
                                    disabled={!emailEnabled}
                                />
                                <AccessTypesCB
                                    data={passcodeCheckboxData[4]}
                                    setState={setCodeFields}
                                    disabled={!emailEnabled}
                                />
                            </div>
                            <div className=" mt-3 mr-14  w-1/2  ">
                                <AccessTypesSwitch
                                    data={passcodeSwitchData[1]}
                                    setState={setCodeFields}
                                    forceDisabled={!emailEnabled}
                                />
                                <AccessTypesSwitch
                                    data={passcodeSwitchData[2]}
                                    setState={setCodeFields}
                                    forceDisabled={!emailEnabled}
                                />
                                <AccessTypesSwitch
                                    data={passcodeSwitchData[3]}
                                    setState={setCodeFields}
                                    forceDisabled={!emailEnabled}
                                />
                                <AccessTypesSwitch
                                    data={passcodeSwitchData[4]}
                                    setState={setCodeFields}
                                    forceDisabled={!emailEnabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" flex space-x-3 justify-end mx-10 mb-6 mt-6">
                    <div
                        onClick={() => {
                            setCardEdition(null);
                            setCodeFields({ ...codeFieldsBackup });
                        }}
                    >
                        <UseButton buttonName="cancel" buttonColor="btn-white" id={`${sectionName}-cancel`} />
                    </div>
                    <div
                        onClick={() => {
                            setKeepCurrentPreview(true);
                            setSelectMutation(UPDATE_PASSCODE);
                        }}
                    >
                        <UseButton buttonName="save" buttonColor="btn-blue" id={`${sectionName}-save`} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccessTypesCode;
