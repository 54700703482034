import React, { useContext, Children } from "react";
import WidgetContainer from "./WidgetContainer";
import WidgetIcon from "./WidgetIcon";
import WidgetText from "./WidgetText";
import WidgetAlarm from "./WidgetAlarm";

import { WidgetContext } from "../../../../contexts/editor";
import WidgetMessages from "./WidgetMessages";

const WidgetZone = ({ children, style }) => {
    const { id } = useContext(WidgetContext);
    let allowOverflow = false;
    Children.toArray(children).map((ch) => {
        if (
            React.isValidElement(ch) &&
            (ch.type === WidgetContainer ||
                ch.type === WidgetIcon ||
                ch.type === WidgetText ||
                ch.type === WidgetAlarm ||
                ch.type === WidgetMessages)
        ) {
            allowOverflow = true;
        }
        return ch;
    });
    return (
        <div
            id={`widget_zone_${id}`}
            className={`${allowOverflow ? "" : "overflow-hidden"} w-full h-full`}
            style={style}
        >
            {children}
        </div>
    );
};

export default WidgetZone;
