import React, { useContext, useEffect } from "react";
import NumberInput from "components/NumberInput";
import { TopologyContext } from "contexts/Topology";
import { useTranslation } from "react-i18next";
import { AccessTypesContext } from "contexts/AccessTypes";

export const TopologyNumberInputs = ({ type, value }) => {
    const { setTvs, setRoomNumber, roomNumberValidation } = useContext(TopologyContext);
    const {
        setUploadMinBandwidth,
        setDownloadMinBandwidth,
        setUploadMaxBandwidth,
        setDownloadMaxBandwidth,
        uploadMinBandwidthValidation,
        downloadMinBandwidthValidation,
    } = useContext(AccessTypesContext);
    const { t } = useTranslation();

    useEffect(() => {
        if (value) {
            if (type === "tvs") {
                setTvs(value);
            } else if (type === "room-number") {
                setRoomNumber(value);
            } else if (type === "min-upload-bandwidth") {
                setUploadMinBandwidth(value);
            } else if (type === "min-download-bandwidth") {
                setDownloadMinBandwidth(value);
            } else if (type === "max-upload-bandwidth") {
                setUploadMaxBandwidth(value);
            } else if (type === "max-download-bandwidth") {
                setDownloadMaxBandwidth(value);
            }
        }
    }, [value]);

    return (
        <NumberInput
            placeholder={t(type)}
            onChange={(value) => {
                if (type === "tvs") {
                    setTvs(value);
                } else if (type === "room-number") {
                    setRoomNumber(value);
                } else if (type === "min-upload-bandwidth") {
                    setUploadMinBandwidth(value);
                } else if (type === "min-download-bandwidth") {
                    setDownloadMinBandwidth(value);
                } else if (type === "max-upload-bandwidth") {
                    setUploadMaxBandwidth(value);
                } else if (type === "max-download-bandwidth") {
                    setDownloadMaxBandwidth(value);
                }
            }}
            value={value}
            className={
                (type === "room-number" && roomNumberValidation) ||
                (type === "min-upload-bandwidth" && uploadMinBandwidthValidation) ||
                (type === "min-download-bandwidth" && downloadMinBandwidthValidation)
            }
        />
    );
};
