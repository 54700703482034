import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { setExecuteQuery } from "../../../../../actions/sectionActions";
import { useMSQuery } from "../../../../../hooks/GraphqlCalls/useMSQuery";
import { gql } from "apollo-boost";
import { setRefreshData } from "../../../../../actions/tableActions";
import UseSwitch from "../../../../Inputs/useSwitch";

function ChannelsGeneralSettingsAlwaysDisplayTv() {
    //Consts&states
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { permissions } = useSelector((state) => state.ui);
    const hasChannelPremium = permissions?.services?.multipleGrids;

    const { refreshData } = useSelector((state) => state.sectionContent);
    const [alwaysDisplayTvChannels, setAlwaysDisplayTvChannels] = useState(false);
    const action = "channel-update-always-display-tv";

    //queries&mutations
    const GET_DATA = gql`
        {
            tvSetting: highAvailabilityConfig {
                alwaysDisplayTvChannels
            }
        }
    `;

    //useQueries&useMutations
    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_DATA);

    //effects
    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (!loading && !error && networkStatus === 7 && data?.tvSetting) {
            setAlwaysDisplayTvChannels(arrangeData({ tvSetting: data.tvSetting }));
        }
    }, [data, networkStatus]);

    useEffect(() => {
        if (error) {
            toast.error(t("error-mutation"));
        }
    }, [error]);

    useEffect(() => {
        if (refreshData) {
            dispatch(setRefreshData(false));
            refetch();
        }
        // eslint-disable-next-line
    }, [refreshData]);

    //arrangeData
    const arrangeData = (props) => {
        const { tvSetting } = props;
        let response = false;
        if (tvSetting && tvSetting?.alwaysDisplayTvChannels) {
            response = tvSetting.alwaysDisplayTvChannels;
        }
        return response;
    };

    //handle&Functions
    const handleChangeAlwaysDisplayTvChannels = (value) => {
        console.log(value);
        dispatch(
            setExecuteQuery({
                action: action,
                params: {
                    value: value,
                },
            })
        );
    };

    //renders
    return (
        <div className="w-full">
            <div className="w-full flex items-center justify-between mb-5">
                <span className="font-bold text-gray-900 text-2xl">{t("always-display-tv")}</span>
                <UseSwitch
                    disabled={loading}
                    id={"alwaysDisplayTvChannels-switch"}
                    checked={alwaysDisplayTvChannels}
                    action={(value) => handleChangeAlwaysDisplayTvChannels(value)}
                    adjust={"flex"}
                />
            </div>
            <div className="w-full text-left mb-10">
                <span className="block">{t("always-display-tv-text-1")}</span>
                <span className="block">
                    {hasChannelPremium ? t("always-display-tv-text-2-premium") : t("always-display-tv-text-2-basic")}
                </span>
            </div>
        </div>
    );
}

export default ChannelsGeneralSettingsAlwaysDisplayTv;
