import React from "react";
import UseSectionHeader from "components/useSectionHeader";
import { useTranslation } from "react-i18next";
import UseTable from "components/Table/index";
import ListMobileApps from "./ListMobileApps";
import Button from "components/Button";
import { useSelector } from "react-redux";
import useAddModalApp from "../modals/useAddModalApp";
import UpdateMobileApps from "./UpdateMobileApps";

const ListMobileAppsComponent = () => {
    const { t } = useTranslation();
    const { open } = useAddModalApp();

    return (
        <>
            <UseSectionHeader title={t("mobile-apps")} />
            <div className=" relative">
                <div style={{ position: "absolute", top: "1.8rem", zIndex: "10", right: "1.8rem" }}>
                    <Button
                        onClick={() => {
                            open();
                        }}
                        design="blue"
                        id="add-mobile-app"
                    >
                        {t("add-app")}
                    </Button>
                </div>
                <UseTable />
            </div>

            <ListMobileApps />
            <UpdateMobileApps />
        </>
    );
};

export default ListMobileAppsComponent;
