import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WidgetContext } from "../../../../../../contexts/editor";
import Widget from "../../Widget";
import { setExecuteQuery } from "../../../../../../actions/sectionActions";
import { useTranslation } from "react-i18next";

const ResetTypographyButton = ({ actionKeys, stylesButton }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { gridItems } = useSelector((state) => state.design);
    const { id: widgetID } = useContext(WidgetContext);

    const displayKeys = actionKeys.slice(0, 3);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const currentStyles = widget ? widget.widgetStyle : null;

    const shouldShow = displayKeys.some((key) => {
        let parts = key.split(".");
        let value = currentStyles;
        for (let part of parts) {
            if (value) {
                value = value[part];
            } else {
                break;
            }
        }
        return value;
    });

    const resetDefaultStyles = (keyPaths) => {
        let resetValues = { ...currentStyles };

        const deleteNestedStyle = (obj, keys) => {
            if (!obj || !keys.length) return;
            if (keys.length === 1) {
                delete obj[keys[0]];
                return;
            }
            const [firstKey, ...remainingKeys] = keys;
            deleteNestedStyle(obj[firstKey], remainingKeys);
        };

        keyPaths.forEach((keyPath) => {
            const keys = keyPath.split(".");
            deleteNestedStyle(resetValues, keys);
        });

        dispatch(setExecuteQuery(Widget.UpdateStyle({ widget, value: resetValues })));
    };

    if (!shouldShow) return null;

    return (
        <button
            className="text-zafiro-600 focus:outline-none"
            style={stylesButton ? stylesButton : {}}
            onClick={() => resetDefaultStyles(actionKeys)}
        >
            {t("use-appearance-tipography")}
        </button>
    );
};

export default ResetTypographyButton;
