import React, { useContext, isValidElement } from "react";

import ErrorInfo from "components/ErrorInfo";
import Icon from "components/Icon";

import { GlobalContext } from "contexts/Global";

const Data = ({ children }) => {
    const { lang } = useContext(GlobalContext);

    if (children === null || children === undefined) {
        return null;
    }
    if (isValidElement(children)) {
        // If children is a valid react element, return it as is
        return children;
    }
    if (Array.isArray(children)) {
        // If children is an array, recursively call Data for each child
        return children.map((child) => <Data key={child?.key || child?.id}>{child}</Data>);
    }

    const dataType = typeof children;
    switch (dataType) {
        case "number":
        case "bigint":
        case "string":
            return children;
        case "boolean":
            return <Icon alt={String(children)} type={children ? "true" : "false"} />;
        case "object":
            if (children instanceof Date) {
                const date = children.toLocaleDateString(lang || navigator?.language, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                });
                const time = children.toLocaleTimeString(lang || navigator?.language, {
                    hour: "2-digit",
                    minute: "2-digit",
                });
                return (
                    <span alt={`${date} ${time}`}>
                        {date}
                        <br />
                        <small className="opacity-75">{time}</small>
                    </span>
                );
            }
            return (
                <pre alt={JSON.stringify(children)} className="bg-black text-green-400 p-1 rounded text-xs">
                    {JSON.stringify(children, null, 2)}
                </pre>
            );
        case "function":
        default:
            return (
                <ErrorInfo useDefault={false}>
                    <Icon type="error" /> data is {dataType}
                </ErrorInfo>
            );
    }
};

export default Data;
