import React, { useEffect, useState } from "react";
import { capitalizeFirst } from "../../../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import UseInputNumberArrows from "../../../../Table/UseInputNumberArrows";
import uuid from "react-uuid";
import UseEditPredefinedChannel from "../../../../Table/UseEditPredefinedChannel";
import UseButton from "../../../../useButton";
import { useDispatch } from "react-redux";
import { cleanAction } from "../../../../../actions/globalActions";
import { toast } from "react-toastify";
import { setExecuteQuery } from "../../../../../actions/sectionActions";
import { useNavigate as useHistory } from "react-router-dom";

const PredefinedBulkChannels = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [numberPredefinedChannels, setNumberPredefinedChannels] = useState(1);
    const [arrDataPredefinedChannels, setArrDataPredefinedChannels] = useState([]);
    const [bulkValidations, setBulkValidations] = useState({});

    useEffect(() => {
        if (numberPredefinedChannels === 1) {
            setArrDataPredefinedChannels((prevData) => [
                ...prevData,
                { id: uuid(), name: "", type: "tv", country: "", language: "", logo: "" },
            ]);
        }
    }, []);

    const updatePredefinedChannel = (idToUpdate, newData) => {
        const updatedArrData = arrDataPredefinedChannels.map((item) => {
            if (item.id === idToUpdate) {
                return { ...item, ...newData };
            }
            return item;
        });
        setArrDataPredefinedChannels(updatedArrData);
    };

    return (
        <div>
            <span className=" section-title">{t("add-bulk-channels")}</span>
            <div className=" w-full flex mt-4 items-center">
                <span className=" block mr-6">{t("enter-the-number-of-channel-you-want-to-add")}</span>
                <UseInputNumberArrows
                    minNumber={1}
                    value={numberPredefinedChannels}
                    validation={true}
                    onChange={(val) => {
                        const numericVal = parseInt(val, 10);

                        if (val === "" || (!isNaN(numericVal) && numericVal >= 1 && numericVal <= 20)) {
                            setNumberPredefinedChannels(val);
                            setArrDataPredefinedChannels((prevData) => {
                                if (val === "") {
                                    return prevData.slice(0, 1);
                                } else if (numericVal < prevData.length) {
                                    return prevData.slice(0, numericVal);
                                } else {
                                    const numToAdd = numericVal - prevData.length;
                                    const newData = Array.from({ length: numToAdd }, () => ({
                                        id: uuid(),
                                        name: "",
                                        type: "tv",
                                        country: "",
                                        language: "",
                                        logo: "",
                                    }));
                                    return [...prevData, ...newData];
                                }
                            });
                        }
                    }}
                    inputData={{ name: "number-bulk-channels" }}
                    id={`predefined-bulk-channels-number`}
                />
                <span className=" block ml-4">{t("channel")}</span>
            </div>
            <div className=" w-full p-8 bg-white rounded mt-4 ">
                <div className="w-full flex py-2 px-2 border-b border-gray-200">
                    <div id="predefined-bulk-channels-column-logo" className=" font-bold w-2/12 flex items-center">
                        <span className=" mr-2">{capitalizeFirst(t("logo"))}</span>{" "}
                        <span
                            data-tip={t("image-recommended-channel")}
                            data-for="dangerous-html-tooltip"
                            className=" icon icon-info icon-gray-800 text-2xl "
                        ></span>{" "}
                    </div>
                    <div id="predefined-bulk-channels-column-name" className=" font-bold w-2/12">
                        {capitalizeFirst(t("name"))}
                    </div>
                    <div id="predefined-bulk-channels-column-type" className=" font-bold w-2/12">
                        {capitalizeFirst(t("type"))}
                    </div>
                    <div id="predefined-bulk-channels-column-country" className=" font-bold w-3/12">
                        {capitalizeFirst(t("country"))}
                    </div>
                    <div id="predefined-bulk-channels-column-language" className=" font-bold w-3/12">
                        {capitalizeFirst(t("language"))}
                    </div>
                </div>
                <div className=" w-full" style={{ minHeight: "470px", maxHeight: "470px", overflowY: "scroll" }}>
                    {arrDataPredefinedChannels.map((el, idx) => (
                        <UseEditPredefinedChannel
                            key={el.id}
                            action="create-bulk"
                            bulkDeleteAction={() => {
                                const updatedArrData = arrDataPredefinedChannels.filter((c) => c.id !== el.id);
                                setNumberPredefinedChannels(updatedArrData.length);
                                setArrDataPredefinedChannels(updatedArrData);
                                dispatch(cleanAction());
                            }}
                            numberBulkCard={idx + 1}
                            bulkDeleteDisabled={numberPredefinedChannels === 1 ? true : false}
                            bulkValidations={bulkValidations}
                            idCard={el.id}
                            data={el}
                            updatePredefinedChannel={updatePredefinedChannel}
                        />
                    ))}
                </div>
            </div>
            <div className=" w-full flex justify-end space-x-4 mt-4">
                <UseButton
                    id={"create-bulk-predefined-channels-cancel"}
                    action={() => {
                        history("/services/tv-channels/preset-channels/");
                        dispatch(cleanAction());
                    }}
                    buttonColor={"btn-blue-outline"}
                    buttonName={"cancel"}
                />
                <UseButton
                    id={"create-bulk-predefined-channels-add"}
                    action={() => {
                        const { noneFilledChannels, incompleteFilledChannels } =
                            validateFilledChannels(arrDataPredefinedChannels);
                        if (noneFilledChannels) {
                            toast.error(t("you-need-to-fill-at-least-one-channel"));
                        } else {
                            setBulkValidations(incompleteFilledChannels);

                            if (Object.keys(incompleteFilledChannels).length > 0) {
                                toast.error(t("input error"));
                            } else {
                                dispatch(
                                    setExecuteQuery({
                                        action: "create-bulk-predefined-channels",
                                        params: {
                                            channels: convertArrayToTextWithKeys(
                                                filterOnlyFilledChannels(arrDataPredefinedChannels)
                                            ),
                                        },
                                    })
                                );
                            }
                        }
                    }}
                    buttonColor={"btn-blue"}
                    buttonName={"add"}
                />
            </div>
        </div>
    );
};

export default PredefinedBulkChannels;

const validateFilledChannels = (arr) => {
    const noneFilledChannels = !arr.some((obj) => {
        return (
            obj.name.trim() !== "" || obj.country.trim() !== "" || obj.language.trim() !== "" || obj.logo.trim() !== ""
        );
    });
    const incompleteFilledChannels = {};
    if (!noneFilledChannels) {
        arr.forEach((obj) => {
            const incompleteChannel = {};

            if (
                obj.logo.trim() !== "" ||
                obj.name.trim() !== "" ||
                obj.country.trim() !== "" ||
                obj.language.trim() !== ""
            ) {
                if (obj.name.trim() === "") {
                    incompleteChannel.name = true;
                }
                if (obj.country.trim() === "") {
                    incompleteChannel.country = true;
                }
                if (obj.language.trim() === "") {
                    incompleteChannel.language = true;
                }

                if (Object.keys(incompleteChannel).length > 0) {
                    incompleteFilledChannels[obj.id] = incompleteChannel;
                }
            }
        });
    }
    return { noneFilledChannels, incompleteFilledChannels };
};

const filterOnlyFilledChannels = (arr) => {
    return arr.filter((obj) => {
        return obj.name !== "" && obj.country !== "" && obj.language !== "";
    });
};

function convertArrayToTextWithKeys(data) {
    let result = "";

    for (const object of data) {
        let objectText = "{ ";

        for (const key in object) {
            if (key === "type") {
                objectText += `isRadio: ${object[key] === "tv" ? "false" : "true"}, `;
            } else if (key === "logo" && object[key] !== "") {
                objectText += `logoRef: "${object[key]}", `;
            } else if (key !== "id" && key !== "logo") {
                objectText += `${key}: "${object[key]}", `;
            }
        }

        objectText = objectText.slice(0, -2) + " }";
        result += objectText + ", ";
    }

    result = result.slice(0, -2);
    return result;
}
