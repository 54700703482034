import React from "react";
import { printOptions } from "../../../../../hooks/Utils/Design/SalesUtils";

const UseDropDownOption = ({ option }) => {
    const optionKeys = option ? Object.keys(option) : null;

    const layout = [];
    if (optionKeys) {
        optionKeys.forEach((key) => {
            const item = printOptions(key, option[key]);
            if (item) {
                layout.push(item);
            }
        });
    }

    return (
        <div className={`bg-white pb-6`}>
            {" "}
            {layout
                ? layout.map((item, index) => {
                      const items = [];
                      items.push(item);
                      if (index != layout.length - 1) {
                          items.push(<div className="border-b px-2 border-gray-300 pt-2"></div>);
                      }
                      return items;
                  })
                : null}
        </div>
    );
};

export default UseDropDownOption;
