import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

//Components
import AddImage from "./CommonOptions/AddImage";
import UseSelectWithSearch from "../../../../useSelectWithSearch";

//Actions
import { setWidgetToolbarVisible } from "../../../../../actions/designActions";
import { setExecuteQuery } from "../../../../../actions/sectionActions";

//Utils
import { Session } from "../../../../../hooks/Utils/Session";
import { toast } from "react-toastify";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Actions from "./CommonOptions/Actions";
import Widget from "../Widget";
import { EditorContext, WidgetContext } from "../../../../../contexts/editor";
import { SCREEN } from "constants/editor";
import { capitalizeFirst, uncapitalizeFirst } from "hooks/Utils/Utils";

const CarouselToolbar = ({ deviceSpecific }) => {
    const { deviceType, itemSelected } = useContext(EditorContext);
    const { id: widgetID } = useContext(WidgetContext);

    //Params
    const { lang, type: ScreenType } = useParams();

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { gridItems, widgetToolbarVisible } = useSelector((state) => state.design);
    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const sectionName = `toolbar`;
    const adjustPredefinedOptions = [
        {
            id: "cover",
            name: t("Fill container"),
        },
        {
            id: "cover-adjust",
            name: t("Stretch image"),
        },
        {
            id: "contain",
            name: t("Fit into container"),
        },
    ];

    //States
    const [adjust, setAdjust] = useState(null);
    const [durationError, setDurationError] = useState(false);
    const [images, setImages] = useState(["una", "dos"]);
    const [imageHover, setImageHover] = useState();

    //Listeners
    useEffect(() => {
        if (widgetToolbarVisible === "carousel-settings" && widgetID === itemSelected) {
            reloadFields();
        }
        // eslint-disable-next-line
    }, [widgetToolbarVisible]);

    useEffect(() => {
        if (widgetID === itemSelected) {
            reloadFields();
        }
        // eslint-disable-next-line
    }, [gridItems]);

    const reloadFields = () => {
        const gItem = gridItems.filter((gridItem) => gridItem.i === widgetID)[0];
        if (gItem && gItem.data && gItem.data.adjust) {
            setAdjust(gItem.data.adjust);
        } else {
            setAdjust("cover");
        }

        setImages(gItem && gItem.data && gItem.data.images ? gItem.data.images : null);

        let imageDuration = document.querySelector(`#image_duration_${widgetID}`);
        if (imageDuration) {
            imageDuration.value = gItem && gItem.data && gItem.data.duration ? gItem.data.duration : "30";
        }
    };

    const deleteImage = (e, index) => {
        e.stopPropagation();
        const gItem = gridItems.filter((gridItem) => gridItem.i === widgetID)[0];

        let newData = gItem.data.images.slice();
        newData.splice(index, 1);

        dispatch(setExecuteQuery(Widget.UpdateData({ widget, property: "images", value: newData })));
    };

    //Sortable
    const SortableItem = SortableElement(({ item, sortIndex }) => (
        <li
            className="relative h-20 w-auto cursor-move list-none"
            onClick={function (e) {
                setImageHover(imageHover !== sortIndex ? sortIndex : null);
            }}
        >
            <img
                alt=""
                className="mb-8 h-full w-full rounded-lg object-cover"
                src={item.externalUrl ? item.externalUrl : Session.getDasUrl(item.libraryRef + "?lang=" + lang)}
            ></img>
            <div
                className="icon icon-delete-editor  rounded absolute bottom-0 right-0 w-full p-1 text-center text-sm  text-white"
                style={{ fontSize: "1.2rem", backgroundColor: "rgba(0,0,0,0.5)" }}
                id={`delete-element-${sortIndex}`}
                onClick={(e) => deleteImage(e, sortIndex)}
            ></div>
        </li>
    ));
    const SortableList = SortableContainer(({ items }) => {
        return (
            <ul className="grid grid-cols-4 gap-2">
                {items.map((item, index) => (
                    <SortableItem
                        key={`item-${item.externalUrl}-${item.libraryRef}`}
                        index={index}
                        sortIndex={index}
                        item={item}
                    />
                ))}
            </ul>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) {
            return;
        }
        let imagesCp = images.slice();
        imagesCp.splice(newIndex < 0 ? imagesCp.length + newIndex : newIndex, 0, imagesCp.splice(oldIndex, 1)[0]);
        setImages(imagesCp);

        dispatch(setExecuteQuery(Widget.UpdateData({ widget, property: "images", value: imagesCp })));
    };

    //Actions
    const updateImageOptions = (value) => {
        dispatch(setExecuteQuery(Widget.UpdateData({ widget, property: "adjust", value })));
    };
    const updateImageDuration = (e) => {
        if (e.target.value > 500) {
            setDurationError(true);
            toast.error(t("value-under-x", { val: "500" }));
            return;
        } else {
            setDurationError(false);
        }

        dispatch(setExecuteQuery(Widget.UpdateData({ widget, property: "duration", value: parseInt(e.target.value) })));
    };

    return (
        <>
            <div
                id={`${sectionName}-settings`}
                className={`icon icon-settings-editor cursor-pointer p-2 ${
                    widgetToolbarVisible === "carousel-settings" ? "toolbarOptionSelected" : ""
                }`}
                onClick={(e) =>
                    dispatch(
                        setWidgetToolbarVisible(
                            widgetToolbarVisible === "carousel-settings" ? null : "carousel-settings"
                        )
                    )
                }
            ></div>
            {widgetToolbarVisible === "carousel-settings" ? (
                <div
                    id={`widgetOptionContainer_${widgetID}`}
                    className={`widgetOptionContainer min-w-120 invisible p-0`}
                    // style={{ width: "26rem" }}
                >
                    <AddImage
                        isCarousel={true}
                        disabled={images?.length >= 15}
                        disabledTooltip={capitalizeFirst(t("up-to-images", { num: "15" }))}
                    />

                    <div className="border-b border-gray-200 p-8" style={{ minWidth: "30rem", maxWidth: "30rem" }}>
                        <div className="flex">
                            <div className="first-capital mb-6 font-bold w-6/12">{t("organise images")}</div>
                            <div className="w-6/12 text-right text-gray-600">
                                {`${images?.length || 0}/15 ${uncapitalizeFirst(t("images"))}`}
                            </div>
                        </div>

                        {!images || (images && images.length === 0) ? (
                            <div className="first-capital">{t("no images")}</div>
                        ) : (
                            <SortableList items={images} axis={"xy"} onSortEnd={onSortEnd} distance={1} />
                        )}
                    </div>
                    <div className="p-8 ">
                        <div className="first-capital mb-8 font-bold">{t("carousel options")}</div>

                        <div className="flex ">
                            <div className="first-capital mt-2 w-8/12">{t("image duration")}</div>
                            <div className="mb-2 w-3/12 ">
                                <input
                                    type="number"
                                    step="1"
                                    min="0"
                                    max="500"
                                    id={`image_duration_${widgetID}`}
                                    className={`rounded h-10 bg-gray-200 px-2 py-2 ${
                                        durationError ? " border border-red-100" : ""
                                    } `}
                                    onBlur={(e) => updateImageDuration(e)}
                                    onKeyUp={(e) => (e.keyCode === 13 ? updateImageDuration(e) : null)}
                                />
                            </div>
                            <span className="ml-1 mt-2">{t("seconds-short")}</span>
                        </div>

                        <div className="first-capital  tex my-2">{t("adjustment")}</div>
                        <UseSelectWithSearch
                            data={{
                                oneSelected: true,
                                noSelect: true,
                                name: `carousel_select_${widgetID}`,
                                optionData: adjustPredefinedOptions,
                                selectedIds: adjust ? [adjust] : null,
                                onChange: updateImageOptions,
                                hideSearch: true,
                                optionsAdjust: "mt-11",
                                fixed: true,
                                parentName: `widgetOptionContainer_${widgetID}`,
                            }}
                        />
                    </div>
                </div>
            ) : null}
            <Toolbar type="style">
                <Settings.Border t={t} />
            </Toolbar>
            {ScreenType !== SCREEN.TYPE.PORTAL ? (
                <Toolbar type="actions">
                    <Actions
                        actions={{
                            content: true,
                            landing: true,
                            section: true,
                            url: deviceType !== "TV",
                            library: true,
                            tvchannel: true,
                        }}
                    />
                </Toolbar>
            ) : null}
            {/* <Toolbar type="clone" /> */}
            <Toolbar type="delete" />
        </>
    );
};

export default CarouselToolbar;
