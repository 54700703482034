import Icon from "components/Icon";
import React from "react";

export const ProfileInformation = ({ text, upload, download }) => {
    return (
        <div className="flex items-center mt-4">
            <p>{text}:</p>
            <div className="flex items-center gap-5 ml-10">
                <div className="flex items-center">
                    <Icon type="maximum" size="2xl" />
                    <span>{upload} Mb/s</span>
                </div>
                <div className="flex items-center">
                    <Icon type="minimum" size="2xl" />
                    <span>{download} Mb/s</span>
                </div>
            </div>
        </div>
    );
};
