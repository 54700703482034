const DeletePreviousEventsModal = (text1, text2) => {
    //Returns dele schedule modal content
    return {
        title: "change-event-title",
        text: [text1, "\n", text2],
        buttons: [
            { name: "cancel", style: "blue", action: "close" },
            {
                name: "change-event",
                style: "white",
                action: "change-event",
            },
        ],
    };
};

export default DeletePreviousEventsModal;
