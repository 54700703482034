import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//API
import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
//Actions
import { showGlobalLoading } from "../../../actions/uiActions";
import { setSectionName, setSectionContent, setRefreshContentData } from "../../../actions/sectionActions";
import { useNavigate as useHistory } from "react-router-dom";
import { QUERY_ALARMS_PART } from "../../Utils/Customers/AlarmUtils";

const GuestInfoRequest = ({ id }) => {
    const history = useHistory();
    //Store
    const { refreshData } = useSelector((state) => state.sectionContent);
    const { permissions } = useSelector((state) => state.ui);
    const hasProductSales = permissions?.services?.productSALES;
    const hasGuestSatisfaction = permissions?.guests?.guestSatisfaction;
    const hasMovieList = permissions?.services?.movieList;

    //Actions
    const dispatch = useDispatch();
    const GET_GUEST_INFO = gql`{
        guestData: stays(guestID: ${id}) {           
            results { 
                stayBookingID
                guestTitle guestName guestPhone guestSurname guestBirthdate guestEmail guestGender guestCustomFieldsInformation
                pmsID guestLang guestID guestCountry guestLoyaltyRef  guestDocumentType guestDocumentNumber guestDocumentExtraInformation
                rooms{RoomID name stayGuestRoomID number stayFrom stayTo parentalcode roomcode hasChromecast isAdultEnabled} 
                companions{id title name surname loyaltyRef email languageRef birthDate documentExtraInformation
                    documentType phone gender customFieldsInformation
                    documentNumber countryRef rooms{ StayGuestRoomID roomID name number}} 
                ${QUERY_ALARMS_PART} 
                bookings{ ID AreaName AreaRef peopleAmount Status Datetime} 
                comments{ id comment time} guestGroup{id name guestNumber}}
        },
        
        groupsData : guestGroups(page:1, size:10000){
            results{id name}
          }
        commonzones(page: 1, size: 10000) {
            results {
                id,
                ref
                name,
                parentCategoryName
            }
        }
        preCheckInSettingsCustomFields{
            fields{
              names{
                lang
                name
              }
              blocked
              enabled
              order
              ref
              required

            }
          }
          preCheckInSettingsCustomFieldsChildren{
            fields{
              names{
                lang
                name
              }
              blocked
              enabled
              order
              ref
              required
            }
          }
        preCheckInSettingsFields {
        name { enabled required blocked }
        surname { enabled required blocked }
        gender { enabled required blocked }
        nationality { enabled required blocked }
        birthdate { enabled required blocked }
        email { enabled required blocked }
        phone { enabled required blocked }
        documentType { enabled required blocked values }
        documentNumber { enabled required blocked }
        documentExpeditionDate { enabled required blocked }
        }
        ${
            hasProductSales
                ? ` 
                orderHistory(
                        languageRef: "es"
                        filter: {${id ? `stayGuestID: ${id}` : ""}}
                      ) {
                        info {
                          count
                          page
                          size
                        }
                        results {
                          id
                        }
                      }

                    `
                : ""
        }
        ${
            hasGuestSatisfaction
                ? `stayFeedbacks(filter: {guestID: ${id}}) {
                    results {
                        id
                        originRoom {
                            id
                            name
                        }
                        stayGuestRoom {
                            id
                            checkedout
                            room {
                                id
                                name
                            }
                            stayGuest {
                                id
                                name
                                surame
                            }
                        }
                        satisfactionLevel
                        createdAt
                    }
                }
                    `
                : ""
        }
        ${
            hasMovieList
                ? `
                movies:charges( filter: {type: MOVIE  clientId: ${id} }, orderBy: {field: "createdAt", criteria: "DESC"}) {
                    info {
                        count
                        page
                        size
                    }
                    results {
                        id
                        extId
                        stayGuestRoomId
                        createdAt
                        detailInfo
                        canceledAt
                        totalPriceAfterTaxes
                        currency
                        pmsStatus
                        canceledReason
                        roomId
                        roomNumber
                        guestCheckedOut
                    }
                }  `
                : ""
        }
    }`;

    const [getGuestInfo, { data, refetch }] = useLazyQuery(GET_GUEST_INFO, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
        errorPolicy: "all",
        onError({ graphQLErrors }) {
            if (!data?.guestData) {
                history(`/customers/guests/guest-list`);
                dispatch(showGlobalLoading(false));
            }
            if (graphQLErrors) {
                graphQLErrors.map((gError) => console.log(gError.message));
            }
        },
    });

    useEffect(() => {
        if (refreshData) {
            if (typeof refetch === "function") {
                refetch();
            }
            dispatch(setRefreshContentData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        getGuestInfo();
        // eslint-disable-next-line
    }, []);

    const countDigitalKey = (guest) => {
        let digitalKeys = [];
        if (guest.guestLoyaltyRef !== "") digitalKeys.push("main");
        guest.companions.forEach((companion) => {
            if (companion.loyaltyRef !== "") digitalKeys.push("companion");
        });
        return digitalKeys;
    };

    useEffect(() => {
        let gData = null;
        if (data?.guestData?.results) {
            dispatch(showGlobalLoading(false));
            gData = data.guestData.results[0];
            if (gData) {
                dispatch(setSectionName(`${gData.guestName} ${gData.guestSurname}`));
                dispatch(
                    setSectionContent({
                        data: {
                            ...gData,
                            "digital-key": countDigitalKey(gData),
                            movies: data?.movies?.results,
                        },
                        customFields: data?.preCheckInSettingsCustomFields?.fields,
                        customFieldsChildren: data?.preCheckInSettingsCustomFieldsChildren?.fields,
                        groupsData: data.groupsData.results,
                        commonzones: data.commonzones.results,
                        stayFeedbacks: data?.stayFeedbacks?.results || [],
                        preCheckInSettingsFields: data?.preCheckInSettingsFields || {},
                    })
                );
            } else {
                history(`/customers/guests/guest-list`);
                dispatch(showGlobalLoading(false));
            }
        }
        if (data?.orderHistory) {
            dispatch(
                setSectionContent({
                    data: {
                        ...gData,
                        movies: data?.movies?.results,
                        orders: data?.orderHistory?.results,
                    },
                })
            );
        }
        // eslint-disable-next-line
    }, [data]);
};

export default GuestInfoRequest;
