const Font = (font, t) => {
    const dataType = "font-library";
    let conutKey = "count-styles_other";
    if (font.styles.length === 1) {
        conutKey = "count-styles_one";
    }
    return {
        ...font,
        type: dataType,
        id: font.ref,
        actions: [{ type: dataType, value: font.ref }],
        adjust: "inline-block mr-5 ml-5 mb-5 card-item-container",
        info: [
            {
                name: "font-name",
                value: font.name,
                specialWidth: "hidden",
                cellContent: "text",
            },
            {
                name: "preview-font",
                cellContent: "text",
                link: `/design/library/fonts/fonts/${font.ref}`,
                value: `<span style='font-family:"${font.name}";'>Aa</span>`,
                specialWidth: "w-12/12 text-center pt-0 pb-0 card-item-container-image",
                cellAdjust: "w-full h-40 flex content-center ",
                adjust: `m-auto align-middle z-0 text-center text-6xl`,
            },
            {
                name: "name",
                cellContent: "link",
                value: font.name,
                subvalue: t(conutKey, { count: font.styles.length }),
                subvalueAlign: "center",
                specialWidth: "w-12/12 text-center pb-0 pt-0 mb-5",
                link: `/design/library/fonts/fonts/${font.ref}`,
                adjust: "truncate",
                textTooltip: font.name,
            },
        ],
    };
};

export default Font;
