import { capitalizeFirst } from "hooks/Utils/Utils";
import React from "react";
import { useTranslation } from "react-i18next";

const UseValueCellInfoExteded = ({ cellData }) => {
    const columnsInfo = cellData.columnsInfo || [];
    const { t } = useTranslation();

    return (
        <div className=" w-full flex">
            <div className=" w-full flex">
                {columnsInfo.map((column, index) => {
                    return (
                        <div key={index} className={` justify-between ${column?.columnStyle || ""} `}>
                            {column.info.map((c) => {
                                return (
                                    <div className=" flex mb-2">
                                        <div className=" mr-2">{capitalizeFirst(t(c?.label)) || ""}: </div>
                                        <div>{c?.value || "-"}</div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default UseValueCellInfoExteded;
