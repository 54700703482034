import React from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import Table from "components/ZafiroTable";

const ModalDuplicateSequence = (props) => {
    const { t } = useTranslation();

    const handleSaveClick = () => {};

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={props.batch ? t("duplicate") : `${props.name} - ${t("duplicate")}`}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-save" onClick={handleSaveClick}>
                        {t("duplicate")}
                    </Button>
                </>
            }
            className={"w-5/12 p-10"}
        >
            <div className="flex flex-col gap-2">
                <p>
                    {props.batch
                        ? t("are-you-sure-you-want-to-duplicate-the-following-sequences")
                        : t("are-you-sure-you-want-to-duplicate-this-sequence")}
                </p>
                <div className=" flex gap-2">
                    <Icon size={"2xl"} type="info"></Icon>
                    <p>{t("please-note-that-only-the-content-will-be-duplicated")}</p>
                </div>
                {props.batch && (
                    <Table
                        id={"signage-duplicate-batch-table"}
                        cols={["name"]}
                        className={" border border-gray-100 p-2 max-h"}
                        maxHeight={"max-h-30"}
                        header={{
                            name: { title: "" },
                        }}
                        rows={props.batchList}
                    ></Table>
                )}
            </div>
        </Modal>
    );
};

export const UseModalDuplicateSequence = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalDuplicateSequence {...newProps} />);
        },
    };
};
