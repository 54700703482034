import { useLayoutEffect } from "react";
import { THEME_TYPE_DESIGN, THEME_TYPE_MOBILE } from "hooks/Utils/AppearancesUtils";

function useSetAppearanceLoaded({
    appearanceSelected,
    loadingAppearance,
    loadingListAppearances,
    setAppearanceLoaded,
}) {
    useLayoutEffect(() => {
        const isThemeTypeValid =
            appearanceSelected?.themeType === THEME_TYPE_DESIGN || appearanceSelected?.themeType === THEME_TYPE_MOBILE;

        if (appearanceSelected?.id && isThemeTypeValid && !loadingAppearance && !loadingListAppearances) {
            setAppearanceLoaded(true);
        } else if (loadingAppearance || loadingListAppearances) {
            setAppearanceLoaded(false);
        }
    }, [appearanceSelected, loadingAppearance, loadingListAppearances, setAppearanceLoaded]);
}

export default useSetAppearanceLoaded;
