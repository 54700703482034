import { gql } from "apollo-boost";

export const VALIDATION_MUTATION = gql`
    mutation {
        validateCastNetworks {
            ok
            error
            __typename
        }
    }
`;

export const PENDING_VALIDATION_CAST_NETWORKS = gql`
    {
        pendingValidateCastNetwork {
            isPending
        }
    }
`;

export const VALIDATE_CAST_NETWORKS = gql`
    {
        getValidateCastNetworks {
            data {
                information
                isValid
            }
            lastUpdate
        }
    }
`;
