import { ACTIVE_ALARM, ALARM_TYPE } from "../../../Utils/Customers/AlarmUtils";
import _ from "lodash";

const Alarm = (alarm, t) => {
    // Data
    const dataType = "alarm";
    const { stayguestName, stayguestSurname, rooms, startTime, state, isFromGuest, lastUpdate, channelName, type } =
        alarm;

    const _starTime = new window.ZDate(startTime);
    const _lastUpdate = new window.ZDate(lastUpdate);
    const listRoomTvs = [];
    let _rooms = JSON.parse(rooms);
    if (_rooms) {
        _rooms = _.pick(_rooms, Object.keys(_rooms).sort((a, b) => a.localeCompare(b)));
    }

    Object.keys(_rooms).forEach((roomName) => {
        // eslint-disable-next-line
        _rooms[roomName].map((roomTv) => {
            listRoomTvs.push({ value: `${roomName}: ${roomTv.location}` });
        });
    });

    // Cells
    // guest - 3
    const cellGuest = {
        name: "body",
        cellContent: "text",
        value: `${stayguestName} ${stayguestSurname}`,
        specialWidth: "w-3/12",
    };
    // Rooms - 2
    const cellRooms = {
        name: "rooms",
        cellContent: "labelsTruncate",
        value: createRoomNumbersLabels(_rooms),
        specialWidth: "w-4/12",
    };
    // Hour - 1
    const cellHour = {
        name: "hour",
        cellContent: "text",
        value: `${
            _starTime
                ? _starTime.toLocaleTimeString("es-ES", {
                      hour: "2-digit",
                      minute: "2-digit",
                  })
                : ""
        }`,
        specialWidth: "w-1/12",
    };
    // Date - 2
    const cellDate = {
        name: "date",
        cellContent: "text",
        value: `${
            _starTime
                ? _starTime.toLocaleTimeString([], {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                  })
                : ""
        }`,
        specialWidth: "w-1/12",
    };
    // Last update - 3
    const cellLastUpdate = {
        name: "lastUpdate",
        cellContent: "text",
        value: `${
            _lastUpdate
                ? _lastUpdate.toLocaleTimeString([], {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                  })
                : ""
        } ${
            _lastUpdate
                ? _lastUpdate.toLocaleTimeString("es-ES", {
                      hour: "2-digit",
                      minute: "2-digit",
                  })
                : ""
        }`,
        specialWidth: "w-2/12",
    };
    // editedBy - 1
    const cellEditedBy = isFromGuest
        ? {
              name: "editedBy",
              cellContent: "icon",
              icon: { name: "user-regular" },
              tooltip: `${t("edited-by-guest")}`,
              specialWidth: "w-1/12",
          }
        : {
              name: "blank",
              cellContent: "void",
              specialWidth: "w-1/12",
          };

    //Extended
    //locations
    const cellLocations = {
        name: "locations",
        title: true,
        cellContent: "list",
        listValues: listRoomTvs,
        specialWidth: "w-3/12",
    };

    const alarmType = {
        name: "alarm-type",
        cellContent: "text",
        value: `<div class="font-bold mb-2">${t("{{capitalize, capitalize}}", {
            capitalize: t("alarm-type"),
        })}</div>
        <span>${type === ALARM_TYPE.BEEP ? `Beep` : `${t("channels")} <br/> - ${channelName} -`}</span>`,
        adjust: "text-base",
        // specialWidth: "w-1/12",
    };

    //alarm type
    return {
        ...alarm,
        id: alarm.groupingID,
        executeSectionQuery: true,
        dataType: dataType,
        actions: state === ACTIVE_ALARM.value ? [{ type: dataType, value: state }] : false,
        info: [cellGuest, cellRooms, cellHour, cellDate, cellLastUpdate, cellEditedBy],
        extendedInfo: [cellLocations, alarmType],
    };
};

export default Alarm;

const createRoomNumbersLabels = (rooms) => {
    const newLabel = (index, text) => {
        return [index, text, "bg-blue-300"];
    };

    let roomLabels = [];
    Object.keys(rooms).forEach((roomName, index) => {
        roomLabels.push(newLabel(index, roomName));
    });
    return roomLabels;
};
