import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseIcon from "../useIcon";
//Actions
import { setActiveSort, cleanSortCriteria } from "../../actions/tableActions";
//Utils
import Parser from "hooks/Utils/Parser";
import { useTranslation } from "react-i18next";
import { multipleTranslate } from "../../hooks/Utils/Utils";

const UseHeader = ({ header, index }) => {
    const { t } = useTranslation();

    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { results, sortCriteria } = useSelector((state) => state.table);
    let dataType;
    if (results && results.length > 0) {
        dataType = results[0].dataType;
    }
    //States
    const [criteria, setCriteria] = useState("asc");
    const [sortIcon, setSortIcon] = useState(header.icon);
    const [fullName, setFullName] = useState(false);

    //Actions
    const dispatch = useDispatch();

    //Column sort behaviour
    const sortHandler = (e) => {
        const field = getHeaderField(e.currentTarget.dataset.name, dataType);

        if (sortIcon.name === "table-sort") {
            setCriteria("desc");
            setSortIcon({ name: "sort-desc" });
            dispatch(setActiveSort(field, criteria));
        } else if (sortIcon.name === "sort-desc") {
            setCriteria("asc");
            setSortIcon({ name: "sort-asc" });
            dispatch(setActiveSort(field, criteria));
        } else if (sortIcon.name === "sort-asc") {
            setSortIcon({ name: "table-sort" });
            dispatch(cleanSortCriteria());
        }
    };

    //Listeners

    useEffect(() => {
        if (langStrings) {
            setFullName(multipleTranslate(header.name, t));
        }
        // eslint-disable-next-line
    }, [langStrings, header]);

    useEffect(() => {
        const field = getHeaderField(header.sortName ? header.sortName : header.name, dataType);
        if ((sortCriteria && !sortCriteria.includes(field)) || sortCriteria === "") {
            setSortIcon({ name: "table-sort" });
        }
        // eslint-disable-next-line
    }, [sortCriteria]);

    return (
        <div className={`${header.specialWidth} mr-2`}>
            <div className={`flex items-center ${header.hidden ? "hidden" : ""}`}>
                <p
                    id={`header-name-c${index}`}
                    onClick={header.icon ? sortHandler : null}
                    data-name={header.sortName ? header.sortName : header.name}
                    data-tip={header.tooltip ? t(header.tooltip) : ""}
                    data-for="dangerous-html-tooltip"
                    className={`font-bold text-gray-900 first-capital overflow-hidden ${
                        header.icon ? "cursor-pointer" : null
                    }`}
                >
                    {Parser(fullName ? fullName : "")}
                </p>
                {header.icon ? (
                    <div
                        id={`header-icon-sort-handler-c${index}`}
                        onClick={sortHandler}
                        data-name={header.sortName ? header.sortName : header.name}
                    >
                        <UseIcon icon={sortIcon} adjust="t-icon-headers text-gray-800" />
                    </div>
                ) : null}
                {header?.iconTooltip ? (
                    <div
                        data-tip={t(header.iconTooltip.tooltip)}
                        data-for="dangerous-html-tooltip"
                        className={` ml-2 text-2xl icon-${header.iconTooltip.icon}`}
                    ></div>
                ) : null}
            </div>
        </div>
    );
};

export const getHeaderField = (headerName, dataType) => {
    switch (headerName) {
        case "categories":
        case "name":
            if (dataType === "staff-member") {
                return "fullName";
            } else {
                return "name";
            }
        case "quantity":
            return "areasAmount";
        case "booking-number":
            if (dataType === "area") {
                return "bookingNumber";
            } else {
                return "booked";
            }
        case "capacity-limit":
            return "capacityLimit";
        case "area-activity":
            return "name";
        case "last-cleaning":
            return "lastCleaning";
        case "responsible":
            return "responsibleName";
        case "current-capacity":
            return "currentCapacity";
        case "last-refresh":
            return "currentCapacityUpdated";
        case "booking-confirmed":
            return "acceptedBookings";
        case "booking-pending":
            return "pendingBookings";
        default:
            return headerName;
    }
};

export default UseHeader;
