import { capitalizeFirst, latinise } from "hooks/Utils/Utils";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FilterSelector from "./FilterSelector";

const DvbTableFilters = ({ channels, checkedChannels, setFilteredChannels, lastSync, closeAll, setCloseAll }) => {
    const { t } = useTranslation();
    const [filter, setFilter] = useState({ search: "", type: "", behaviour: "" });

    const FILTER_TYPE = {
        ALL: "all",
        NOTYPE: "-",
        TV: "tv",
        RADIO: "radio",
    };

    const FILTER_BEHAVIOUR = {
        ALL: "all",
        TO_BE_ADDED: "to-be-added",
        TO_BE_OVERWRITTEN: "to-be-over",
        NOT_TO_BE_ADDED: "not-to-be-added",
        UNLINKED: "unlinked",
    };

    useEffect(() => {
        applyFilters();
    }, [filter, channels, checkedChannels]);

    useEffect(() => {
        if (lastSync) {
            setFilter({ search: "", type: null, behaviour: null });
        }
    }, [lastSync]);

    const filterBySearch = (e) => {
        setFilter({ search: e.target.value, type: filter.type, behaviour: filter.behaviour });
    };
    const filterByType = (type) => {
        setFilter({ search: filter.search, type: type, behaviour: filter.behaviour });
    };
    const filterByBehaviour = (behaviour) => {
        setFilter({ search: filter.search, type: filter.type, behaviour: behaviour });
    };

    const hasPreviousDVBTune = (channel) => {
        if (!channel.Tune?.TuneInfo) {
            return false;
        }
        return channel.Tune?.TuneInfo?.[channel?.TuneType];
    };

    const applyFilters = () => {
        let results = channels;

        //filter by search
        if (filter?.search) {
            results = results.filter((ch) =>
                latinise(ch.Name.toLowerCase()).includes(latinise(filter?.search.toLowerCase()))
            );
        }

        //filter by type
        if (filter?.type && filter?.type !== FILTER_TYPE.ALL) {
            if (filter?.type === FILTER_TYPE.RADIO) {
                results = results.filter((ch) => ch.PredefinedTvChannel?.IsRadio || ch.Tune?.IsRadio);
            }
            if (filter?.type === FILTER_TYPE.TV) {
                results = results.filter(
                    (ch) => (ch.PredefinedTvChannel && !ch.PredefinedTvChannel.IsRadio) || (ch.Tune && !ch.Tune.IsRadio)
                );
            }
            if (filter?.type === FILTER_TYPE.NOTYPE) {
                results = results.filter((ch) => !ch.PredefinedTvChannel && !ch.Tune);
            }
        }
        //filter by behaviour
        if (filter?.behaviour && filter?.behaviour !== FILTER_BEHAVIOUR.ALL) {
            switch (filter?.behaviour) {
                case FILTER_BEHAVIOUR.TO_BE_ADDED:
                    results = results.filter((ch) => {
                        const isAdded = ch.PredefinedTvChannel || ch.Tune;
                        const isChecked = checkedChannels?.find((item) => item === ch.Name);
                        const hasPrevTune = hasPreviousDVBTune(ch);
                        return !hasPrevTune && isAdded && isChecked;
                    });
                    break;
                case FILTER_BEHAVIOUR.NOT_TO_BE_ADDED:
                    results = results.filter((ch) => {
                        const isAdded = ch.PredefinedTvChannel || ch.Tune;
                        const isChecked = checkedChannels?.find((item) => item === ch.Name);
                        return !(isAdded && isChecked);
                    });
                    break;
                case FILTER_BEHAVIOUR.UNLINKED:
                    results = results.filter((ch) => {
                        const isAdded = ch.PredefinedTvChannel || ch.Tune;
                        const isChecked = checkedChannels?.find((item) => item === ch.Name);
                        return !isAdded && isChecked;
                    });
                    break;
                case FILTER_BEHAVIOUR.TO_BE_OVERWRITTEN:
                    results = results.filter((ch) => {
                        return hasPreviousDVBTune(ch);
                    });
                    break;

                default:
                    break;
            }
        }

        setFilteredChannels(results);
    };

    return (
        <div>
            <div className="w-3/12 relative float-left">
                <input
                    id={`search_input_filter`}
                    type="text"
                    className="t-filter-search"
                    style={{ width: "calc(100% - 1rem)" }}
                    placeholder={t("search") + "..."}
                    onClick={(e) => e.stopPropagation()}
                    value={filter.search}
                    onChange={(e) => filterBySearch(e)}
                ></input>
                <span
                    id={`search_input-icon`}
                    className="field-icon icon-search table mt-5"
                    style={{ height: "2rem" }}
                ></span>
            </div>
            <div className="w-9/12 float-left pl-10 py-2">
                <div>
                    <div className="first-capital float-left mr-4 pt-2">{t("filter-by")}:</div>
                    <div className="float-left w-2/5">
                        <FilterSelector
                            type="type"
                            options={[
                                { id: FILTER_TYPE.ALL, text: capitalizeFirst(t("all")) },
                                { id: FILTER_TYPE.NOTYPE, text: "-" },
                                { id: FILTER_TYPE.TV, text: t("TV") },
                                { id: FILTER_TYPE.RADIO, text: t("Radio") },
                            ]}
                            onSelect={(type) => filterByType(type)}
                            lastSync={lastSync}
                            closeAll={closeAll}
                            setCloseAll={setCloseAll}
                        />
                    </div>
                    <div className="float-left w-2/5">
                        <FilterSelector
                            type="behaviour"
                            options={[
                                { id: FILTER_TYPE.ALL, text: capitalizeFirst(t("all")) },
                                { id: FILTER_BEHAVIOUR.TO_BE_ADDED, text: t("to be added") },
                                { id: FILTER_BEHAVIOUR.NOT_TO_BE_ADDED, text: t("not to be added") },
                                { id: FILTER_BEHAVIOUR.TO_BE_OVERWRITTEN, text: t("to be overwritten") },
                                { id: FILTER_BEHAVIOUR.UNLINKED, text: t("unlinked") },
                            ]}
                            onSelect={(type) => filterByBehaviour(type)}
                            lastSync={lastSync}
                            closeAll={closeAll}
                            setCloseAll={setCloseAll}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DvbTableFilters;
