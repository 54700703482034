import React, { useEffect } from "react";
import UseTable from "../../../Table";
import UseSectionHeader from "../../../useSectionHeader";
import ListShop from "../../../../hooks/GraphqlCalls/Sales/ListShop";
import UpdateShop from "../../../../hooks/GraphqlCalls/Sales/UpdateShop";
import { setSaveHeadMenu } from "../../../../actions/sectionActions";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { useSalesModalAddShop } from "./modals/useSalesModalAddShop";
const Shops = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //Modal Add shop
    const { open: openModalAddShop } = useSalesModalAddShop();

    const { permissions } = useSelector((state) => state.ui);
    const shopsAdministrator = permissions?.services?.shopsAdministrator;

    const breadcrumbs = [
        {
            name: "services",
            translate: true,
            route: "/services/",
        },
        {
            name: "sales",
            translate: true,
            route: "/services/",
        },
        {
            name: "shop",
            translate: true,
            route: "/services/sales/shop",
        },
    ];

    useEffect(() => {
        dispatch(setSaveHeadMenu(null));
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <UseSectionHeader title={["shop-list"]} customBreadCrumbs={breadcrumbs} />

            <div className=" relative">
                {shopsAdministrator ? (
                    <div style={{ position: "absolute ", top: "1.9rem", right: "1.8rem", zIndex: "10" }}>
                        <Button
                            onClick={() => {
                                openModalAddShop();
                            }}
                            className=""
                            id="create-shop-button"
                            design="blue"
                        >
                            {t("add-shop")}
                        </Button>
                    </div>
                ) : null}
                <UseTable />
            </div>
            <UpdateShop />
            <ListShop />
        </>
    );
};

export default Shops;
