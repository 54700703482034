import { useEffect, useState } from "react";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
const GetRoomsNames = () => {
    const { values } = useSelector((state) => state.action);
    const [roomsData, setRoomsData] = useState([]);
    const roomsIds = values["room-number"];
    const isLoyalty = values["isLoyalty"];

    const ROOMS_QUERY = gql`
        {
            rooms: hotelRooms(filter: { roomID: [${
                roomsIds && roomsIds.length > 0 ? roomsIds.map((id) => id) : ""
            }] }) {
                info {
                    count
                }
                results {
                    id
                    name
                }
            }
        }
    `;
    const [executeQuery, { data, loading, error }] = useLazyQuery(ROOMS_QUERY);

    useEffect(() => {
        if (roomsIds && roomsIds.length > 0 && isLoyalty) executeQuery();
        // eslint-disable-next-line
    }, [roomsIds, isLoyalty]);

    useEffect(() => {
        if (data && data.rooms && data.rooms.results && !loading && !error && roomsIds.length > 0 && isLoyalty) {
            setRoomsData(data.rooms.results);
        } else {
            setRoomsData([]);
        }
        // eslint-disable-next-line
    }, [data]);

    return roomsData;
};

export default GetRoomsNames;
