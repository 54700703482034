import React, { useState, useEffect } from "react";
import UseSelectWithSearch from "../../../../useSelectWithSearch";
import { gql } from "apollo-boost";
import UseSearch from "../../../../useSearch";
import { useLazyQuery } from "react-apollo";
import { parseTranslation, getProjectLangs } from "../../../../../hooks/Utils/SalesUtils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { capitalizeFirst } from "../../../../../hooks/Utils/Utils";
import { useAuth } from "hooks/Session/auth";

const GuestOrdersFilters = ({ setShopsSelected, setSearch, setLoading }) => {
    const { t } = useTranslation();
    const [shopList, setShopList] = useState([]);

    const GET_SHOPS = gql`
        {
            shops {
                results {
                    id
                    token
                    name: nameTranslations {
                        lang
                        text
                    }
                }
            }
        }
    `;

    const [executeQuery, { data: dataShops }] = useLazyQuery(GET_SHOPS);
    const { languages: projectLangs } = useAuth();
    const { values } = useSelector((state) => state.action);

    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (dataShops?.shops?.results) {
            setShopList(arrangeShopFilterData(dataShops.shops.results));
        }
    }, [dataShops]);

    useEffect(() => {
        if (values?.["filters-shops-selected"]) {
            setShopsSelected(values["filters-shops-selected"]);
        }
    }, [values]);

    const arrangeShopFilterData = (dataShops) => {
        const data = dataShops.map((s) => {
            return {
                id: s.token,
                name: parseTranslation(s.name, getProjectLangs(projectLangs, t).langDefault),
            };
        });

        const allShops = {
            id: null,
            name: `-- ${capitalizeFirst(t("all"))} --`,
            visible: true,
        };
        data.unshift(allShops);
        return data;
    };

    const inputSearchChange = (change) => {
        if (change.target.value && change.target.value !== undefined) {
            setSearch(change.target.value);
        } else if (change.target.value === undefined || change.target.value === "") {
            setSearch("");
        }
    };

    return (
        <div className=" w-full mb-6 flex">
            <div className=" w-3/12">
                <div className=" w-4/5">
                    <UseSearch handleChangeSearch={inputSearchChange} id={"guest-order-search-filter"} />
                </div>
            </div>
            <div className="  w-8/12">
                <div className=" w-2/6 flex items-center">
                    <span className=" whitespace-no-wrap font-bold block mr-5">{`${t("filter-by")}:`}</span>
                    <UseSelectWithSearch
                        key={shopList}
                        data={{
                            id: "filters-shops-selected",
                            name: "filters-shops-selected",
                            optionData: shopList,
                            selectPlaceHolder: capitalizeFirst(t("shop")),
                            hideSearch: true,
                            noSelect: true,
                            oneSelected: true,
                            noShowSelectedOptionsInDropDownSection: true,
                            optionsAdjust: `whitespace-nowrap overflow-ellipsis mt-12`,
                            onChange: () => {
                                if (setLoading) {
                                    setLoading(true);
                                }
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default GuestOrdersFilters;
