import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { Session } from "../../../../hooks/Utils/Session";
import { vw2px, GetThemeDefault } from "../../../../hooks/Utils/DesignUtils";
import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";
import EmptyWidget from "./WidgetToolbars/CommonOptions/EmptyWidget";
import { DEVICE } from "constants/editor";

const WidgetButton = () => {
    const { deviceType, editorSize, itemSelected, theme } = useContext(EditorContext);
    const { config, type, id: widgetID } = useContext(WidgetContext);

    const [iconsReady, setIconsReady] = useState(false);
    const { lang } = useParams();
    const {
        gridItems,
        widgetToolbarVisible: toolbar,
        widgetToolbarVisibleTab: tab,
    } = useSelector((state) => state.design);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;

    const size = (s) => vw2px(s, editorSize?.width, deviceType === DEVICE.TYPE.MOBILE ? 3 : 1);

    const defaultDisabledBgColor = GetThemeDefault({ theme, id: "disabledBgColor" });
    const defaultDisabledFgColor = GetThemeDefault({ theme, id: "disabledFgColor" });

    const style = config?.style?.export();

    const iconPosition = style?.items?.icon?.position;
    const iconSpacing = style?.items?.icon?.padding;

    const disabled = itemSelected === widgetID && toolbar === "style" && tab === "disabled";

    const data = config?.data || Widget.ParseData(widget ? widget.data : null, deviceType);
    const title = data
        ? data.title && data.title[lang]
            ? data.title[lang]
            : data.title && data.title.text
            ? data.title.text
            : ""
        : "";
    const showIcon = data ? data.showIcon && iconsReady : true;
    const icon =
        data && data.icon
            ? {
                  icon: data.icon,
                  lib: data.lib || "fa",
              }
            : null;

    const src = data ? (data.libraryRef ? getFromDAS(data.libraryRef, lang) : data.externalUrl) : null;
    const bgSize = data
        ? data.adjust === "cover-adjust"
            ? "100% 100%"
            : data.adjust
            ? data.adjust
            : "cover"
        : "cover";

    useEffect(() => {
        const wz = document.querySelector(`#widget_zone_${widgetID}`);
        if (wz) {
            wz.style.backgroundColor = "transparent";
            wz.style.borderColor = "";
            wz.style.borderWidth = 0;
            wz.style.borderRadius = 0;
        }
    }, [gridItems, widgetID]);

    useEffect(() => {
        library.add(fas, fab);
        setIconsReady(true);
    }, []);

    const globalStyle = {
        color: disabled ? defaultDisabledFgColor : style?.fgColor,
        fontSize: size(style?.size) + "px",
        textAlign: style?.alignment,
        background: "transparent no-repeat center center",
        backgroundColor: disabled ? defaultDisabledBgColor : style?.bgColor,
        backgroundImage: src ? `url('${src}')` : "",
        backgroundSize: bgSize,
        borderColor: style?.borderColor,
        borderWidth: (disabled ? 0 : style?.borderWidth) + "px",
        borderRadius: style?.radius + "rem",
    };

    let iconStyle = {
        color: !icon ? defaultDisabledFgColor : style?.items?.icon?.fgColor,
        fontSize: size(style?.items?.icon?.size) + "px",
        display: iconPosition === "top" || iconPosition === "bottom" ? "block" : "inline",
    };
    switch (iconPosition) {
        case "top":
            iconStyle.paddingBottom = size(iconSpacing) + "px";
            break;
        case "bottom":
            iconStyle.paddingTop = size(iconSpacing) + "px";
            break;
        case "left":
            iconStyle.paddingRight = size(iconSpacing) + "px";
            break;
        case "right":
        default:
            iconStyle.paddingLeft = size(iconSpacing) + "px";
    }

    const iconElement = (
        <div style={iconStyle}>{icon && icon.icon ? <FontAwesomeIcon icon={[icon.lib, icon.icon]} /> : null}</div>
    );

    return (
        <>
            {iconsReady && (icon || title || src) ? (
                <div
                    className={`grid place-items-${
                        style?.alignment === "left" ? "start" : style?.alignment === "right" ? "end" : "center"
                    } items-${
                        style?.vAlignment === "top" ? "start" : style?.vAlignment === "bottom" ? "end" : "center"
                    } shadow h-full p-2`}
                    style={globalStyle}
                >
                    <div className={`text-center`}>
                        {showIcon && (iconPosition === "left" || iconPosition === "top") ? iconElement : null}
                        <span
                            style={{
                                fontFamily: `'${style?.font?.name}'`,
                                fontWeight: style?.bold ? "bold" : 400,
                                fontStyle: style?.italic ? "italic" : "normal",
                                textAlign: "center",
                                textDecoration: style?.underline ? "underline" : "none",
                                display: iconPosition === "top" || iconPosition === "bottom" ? "block" : "inline-block",
                            }}
                        >
                            {title}
                        </span>
                        {showIcon && (iconPosition === "right" || iconPosition === "bottom") ? iconElement : null}
                    </div>
                </div>
            ) : (
                <EmptyWidget radius={style ? style?.radius : 0} type={type}></EmptyWidget>
            )}
        </>
    );
};

const getFromDAS = (libraryRef, languageRef) => {
    if (libraryRef) {
        return Session.getDasUrl(`${libraryRef}?lang=${languageRef}`);
    }
    return null;
};

export default WidgetButton;
