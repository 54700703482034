import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTranslationName } from "../../../../../../../../hooks/Utils/SalesUtils";
import { setExecuteQuery } from "../../../../../../../../actions/sectionActions";
import { UPDATE_PRODUCT_EXTRA } from "../../../../../../../../hooks/Utils/Integrations/ShopSettingsUtils";
import { validateSection } from "../../../../../../../../hooks/Utils/Validations";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { setActionValidations } from "../../../../../../../../actions/globalActions";
import update from "immutability-helper";
import UseRadio from "../../../../../../../useRadio";
import UseButton from "../../../../../../../useButton";
import UseInputText from "../../../../../../../Inputs/useInputText";
import UseSwitch from "../../../../../../../Inputs/useSwitch";
import UseCurrencyInput from "../../../../../../../Table/UseCurrencyInput";
import ReactTooltip from "components/ReactTooltip";
import ExtraGroupItemChoicesEditCard from "./ExtraGroupItemChoicesEditCard";
import uuid from "react-uuid";
import UseInputNumberArrows from "../../../../../../../Table/UseInputNumberArrows";

function ExtraGroupItemEditCard({ item, langDefault, extraData, index: itemNumber }) {
    const dispatch = useDispatch();

    //Consts&states
    const { id } = item;
    const { t } = useTranslation();
    const { validations } = useSelector((state) => state.action);
    const { setEditingItemId, setLoading } = extraData;
    const [name, setName] = useState(getTranslationName(item.translations, langDefault));
    const [available, setAvailable] = useState(item.available);
    const [typeOfSelection, setTypeOfSelection] = useState(item.typeOfSelection);
    const [minimumQuantity, setMinimumQuantity] = useState(item.options ? item.options.min : null);
    const [maximumQuantity, setMaximumQuantity] = useState(item.options ? item.options.max : null);
    const [enableMinimumQuantity, setEnableMinimumQuantity] = useState(item.options && item.options.min > 0);
    const [enableMaximumQuantity, setEnableMaximumQuantity] = useState(item.options && item.options.max > 0);
    const [choices, setChoices] = useState([]);
    const decimalSeparator = (1.1).toLocaleString().substring(1, 2);
    const typeOfSelectionOptions = [`single-unit`, `several-units-per-item`];
    const isGroupItemsEmpty =
        !item?.options?.choices ||
        item?.options?.choices.length === 0 ||
        item?.options?.choices.every((opt) => opt.available === false);

    const sectionName = "product-edit-extra";
    const inputsIds = [
        `${sectionName}-name`,
        `${sectionName}-available`,
        `${sectionName}-type-of-selection`,
        `${sectionName}-enable-min`,
        `${sectionName}-min`,
        `${sectionName}-enable-max`,
        `${sectionName}-max`,
        `${sectionName}-choices`,
        `${sectionName}-type-of-selection-${typeOfSelectionOptions[0]}`,
        `${sectionName}-type-of-selection-${typeOfSelectionOptions[1]}`,
        `${sectionName}-save-changes`,
        `${sectionName}-cancel-changes`,
        `${sectionName}-save-changes`,
    ];

    //queries&mutations

    //effects
    useEffect(() => {
        if (item && item.options && item.options.choices && item.options.choices.length > 0) {
            let choicesData = [];
            item.options.choices.forEach((choice) => {
                choicesData.push(arrangeChoice(choice));
            });
            setChoices(choicesData);
        }
        // eslint-disable-next-line
    }, [item]);

    //arrangeData
    const arrangeChoice = (data) => {
        return {
            id: data?.id ? data.id : null,
            ref: data?.ref ? data.ref : uuid(),
            name: data?.translations ? getTranslationName(data.translations, langDefault) : null,
            available: data?.available ? data.available : false,
            priceWithoutTax: data ? data.priceWithoutTax : null,
            taxCategory: data?.taxCategory ?? extraData?.defaultTaxID,
            priceWithTax: data ? data.priceWithTax : null,
            order: data ? data.order : choices.length + 1,
            translations: data?.translations ? data.translations : [{ languageCode: langDefault, name: null }],
            loadInitialData: true,
        };
    };

    //handle&functions
    const handleCancel = () => {
        setEditingItemId(null);
    };

    const handleSave = () => {
        let values = {};
        values[inputsIds[0]] = name;
        values[inputsIds[3]] = enableMinimumQuantity;
        values[inputsIds[4]] = minimumQuantity;
        values[inputsIds[5]] = enableMaximumQuantity;
        values[inputsIds[6]] = maximumQuantity;
        values[inputsIds[7]] = choices;
        const validations = validateSection(UPDATE_PRODUCT_EXTRA, values, t);
        dispatch(setActionValidations(validations));
        const validationsArray = Object.values(validations);
        if (validationsArray.filter((item) => item === false).length > 0) {
            toast.error(t("errors-in-red"));
        } else {
            let translations = [...item.translations];
            let translationIndex = translations.findIndex((translation) => translation.languageCode === langDefault);
            let type = null;
            let choicesData = [];
            let choicesClone = [...choices];
            let options = { ...item.options };
            if (translationIndex !== -1) translations[translationIndex].name = name;
            if (typeOfSelection) {
                switch (typeOfSelection) {
                    case typeOfSelectionOptions[0]:
                        type = "combobox";
                        break;
                    case typeOfSelectionOptions[1]:
                        type = "multicombobox";
                        break;
                    default:
                        break;
                }
            }
            if (choicesClone && choicesClone.length > 0) {
                choicesClone.forEach((choice) => {
                    choice.loadInitialData = true;
                    let minimumUnitPriceWthoutTax =
                        (parseFloat(String(choice.priceWithoutTax).replace(decimalSeparator, ".")) || 0) * 100;
                    minimumUnitPriceWthoutTax = Math.round(minimumUnitPriceWthoutTax);
                    let translationsChoice = [...choice.translations];
                    translationIndex = translationsChoice.findIndex(
                        (translation) => translation.languageCode === langDefault
                    );
                    if (translationIndex !== -1) translationsChoice[translationIndex].name = choice.name;
                    choicesData.push({
                        available: choice.available,
                        translations: translationsChoice,
                        priceWithoutTax: minimumUnitPriceWthoutTax,
                        taxCategory: choice.taxCategory,
                        order: choice.order,
                    });
                });
            }
            options.choices = choicesData;
            options.min = enableMinimumQuantity ? minimumQuantity : 0;
            options.max = enableMaximumQuantity ? maximumQuantity : 0;
            setLoading(true);
            setChoices(choicesClone);
            dispatch(
                setExecuteQuery({
                    action: UPDATE_PRODUCT_EXTRA,
                    params: {
                        id: id,
                        type: type,
                        available: available,
                        order: item.order,
                        appliesTo: item.appliesTo,
                        taxCategory: item.taxCategory,
                        options: options,
                        translations: translations,
                    },
                })
            );
        }
    };

    const updateChoiceData = (data, index) => {
        let choicesClone = [...choices];
        let choice = choicesClone[index];
        choice.name = data.name;
        choice.available = data.available;
        choice.priceWithoutTax = data.priceWithoutTax;
        choice.taxCategory = data.taxCategory;
        choice.priceWithTax = data.priceWithTax;
        choice.loadInitialData = data.loadInitialData;
        choicesClone[index] = choice;
        setChoices(choicesClone);
    };

    const handleAddChoice = () => {
        const choice = arrangeChoice();
        setChoices([...choices, choice]);
    };

    const handleDeleteChoice = (index) => {
        let choicesClone = [...choices];
        choicesClone.splice(index, 1);
        setChoices(choicesClone);
    };

    const handleMoveChoices = () => {
        let choicesClone = [...choices];
        choicesClone.forEach((choice, index) => {
            delete choice["opacity"];
            choice.order = index + 1;
        });
        setChoices(choicesClone);
    };

    const moveChoiceCard = useCallback(
        (dragIndex, hoverIndex, noAddOpacity = false) => {
            let dragCard = choices[dragIndex];
            dragCard.opacity = !noAddOpacity ? 0 : null;
            setChoices(
                update(choices, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                })
            );
        },
        [choices]
    );

    //renders
    const renderTypeOfSelectionToolTip = (type) => {
        return (
            <div className="flex items-center w-full justify-between">
                {type === typeOfSelectionOptions[0] && (
                    <span className="h-6 w-6 border rounded border-gray-600 mr-2"></span>
                )}
                <span className="mr-4 max-w-xs truncate">{name && name !== "" ? name : t("extra-name")}</span>
                {type === typeOfSelectionOptions[1] && (
                    <div className="flex items-center">
                        <span className="text-3xl">-</span>
                        <div className="h-8 w-8 flex items-center justify-center mx-1 border rounded-md border-gray-600">
                            <span className="text-base text-gray-500">01</span>
                        </div>
                        <span className="text-3xl">+</span>
                    </div>
                )}
            </div>
        );
    };

    //response
    return (
        <div className="w-full pl-3 pr-2">
            <div className="flex items-center align-middle w-full justify-between pb-4 pt-3 border-b-2 border-gray-200">
                <div className="flex items-center">
                    <span className="icon icon-drag-and-drop text-gray-800 text-3xl mr-4 hidden"></span>
                    <div className="w-96">
                        <UseInputText
                            id={inputsIds[0]}
                            ph="extra-name"
                            value={name}
                            changeAction={(value) => {
                                setName(value);
                            }}
                            adjust={`${
                                typeof validations[inputsIds[0]] !== "undefined" && !validations[inputsIds[0]]
                                    ? "border border-red-100"
                                    : ""
                            }`}
                        />
                    </div>
                    <span className="font-bold ml-5">{t(item.typeOfExtra)}</span>
                </div>
                <span className="flex">
                    <span
                        className={`${isGroupItemsEmpty ? "text-gray-600" : ""}`}
                        data-tip={t(isGroupItemsEmpty ? "add-at-least-an-item-to-make-it-available" : "")}
                        data-for="disabled-switch-group-item"
                    >
                        {t("{{capitalize, capitalize}}", { capitalize: t("available") })}
                    </span>
                    <UseSwitch
                        id={inputsIds[1]}
                        checked={available}
                        action={(value) => {
                            setAvailable(value);
                        }}
                        disabled={isGroupItemsEmpty}
                        iconLeftDisabled={true}
                    />
                </span>
                <ReactTooltip
                    id="disabled-switch-group-item"
                    delayShow={0}
                    type={"light"}
                    borderColor={"#D3DAE1"}
                    border={true}
                    multiline={true}
                />
            </div>
            <div className="w-full flex my-2 pb-2 border-b-2 border-gray-200">
                <div className="w-8/12 border-r-2 border-gray-200 pl-16 pr-5 pt-5">
                    <div className="flex items-center w-full justify-between pb-3 border-b-2 border-gray-200">
                        <span className="font-bold text-xl">
                            {t("{{capitalize, capitalize}}", { capitalize: t("items") })}
                        </span>
                        <div className="text-zafiro-600 cursor-pointer" id={inputsIds[12]} onClick={handleAddChoice}>
                            <span className="icon icon-add mr-2"></span>
                            <span className="text-lg">
                                {t("{{capitalize, capitalize}}", { capitalize: t("add-item") })}
                            </span>
                        </div>
                    </div>
                    <div className="w-full overflow-y-scroll" style={{ height: "27rem" }}>
                        <DndProvider backend={HTML5Backend}>
                            {choices && choices.length > 0
                                ? choices.map((choice, index) => (
                                      <ExtraGroupItemChoicesEditCard
                                          key={`extra-item-${id}-${index}`}
                                          itemNumber={itemNumber}
                                          choice={choice}
                                          index={index}
                                          moveCard={moveChoiceCard}
                                          extraData={extraData}
                                          updateChoiceData={(data) => {
                                              updateChoiceData(data, index);
                                          }}
                                          handleDeleteChoice={() => {
                                              handleDeleteChoice(index);
                                          }}
                                          handleMoveChoices={(index) => {
                                              handleMoveChoices();
                                          }}
                                      />
                                  ))
                                : null}
                        </DndProvider>
                    </div>
                </div>
                <div className="w-4/12 pl-5 pt-3">
                    <div className="w-full">
                        <span className="font-bold text-xl block">
                            {t("{{capitalize, capitalize}}", { capitalize: t("general-settings") })}:
                        </span>
                    </div>
                    <div className="w-full my-5">
                        <span className="font-bold text-lg block mb-3">
                            {t("{{capitalize, capitalize}}", { capitalize: t("type-of-selection") })}
                        </span>
                        <div className="w-full mb-4">
                            <UseRadio
                                id={inputsIds[9]}
                                value={typeOfSelectionOptions[1]}
                                selected={typeOfSelection}
                                handleClick={(value) => {
                                    setTypeOfSelection(value);
                                }}
                            />
                            <span className="ml-6" data-tip data-for={`${typeOfSelectionOptions[0]}Tooltip`}>
                                {t("several-units")}
                            </span>
                            <ReactTooltip
                                id={`${typeOfSelectionOptions[0]}Tooltip`}
                                type="light"
                                borderColor={"#D3DAE1"}
                                border={true}
                                multiline={true}
                                place="right"
                            >
                                {renderTypeOfSelectionToolTip(typeOfSelectionOptions[1])}
                            </ReactTooltip>
                        </div>
                        <div className="w-full">
                            <UseRadio
                                id={inputsIds[8]}
                                value={typeOfSelectionOptions[0]}
                                selected={typeOfSelection}
                                handleClick={(value) => {
                                    setTypeOfSelection(value);
                                }}
                            />
                            <span className="ml-6" data-tip data-for={`${typeOfSelectionOptions[1]}Tooltip`}>
                                {t("single-unit")}
                            </span>
                            <ReactTooltip
                                id={`${typeOfSelectionOptions[1]}Tooltip`}
                                type="light"
                                borderColor={"#D3DAE1"}
                                border={true}
                                multiline={true}
                                place="right"
                            >
                                {renderTypeOfSelectionToolTip(typeOfSelectionOptions[0])}
                            </ReactTooltip>
                        </div>
                    </div>
                    <div className="w-full my-6">
                        <span className="font-bold text-lg block mb-3">
                            {t("{{capitalize, capitalize}}", { capitalize: t("select-quantity-limit") })}
                        </span>
                        <div className="w-full mb-5">
                            <div className="w-full mb-5">
                                <span className="flex">
                                    <span className="">
                                        {t("{{capitalize, capitalize}}", { capitalize: t("minimum-quantity-units") })}
                                    </span>
                                    <UseSwitch
                                        id={inputsIds[3]}
                                        checked={enableMinimumQuantity}
                                        action={(value) => {
                                            setEnableMinimumQuantity(value);
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="w-full flex items-center">
                                <div className="w-36 z-10">
                                    <UseCurrencyInput
                                        inputData={{ name: inputsIds[4] }}
                                        id={inputsIds[4]}
                                        forceIntegerNumbers={true}
                                        validation={true}
                                        value={minimumQuantity}
                                        disabled={!enableMinimumQuantity}
                                        onChange={(values) => setMinimumQuantity(values.float)}
                                        adjust={`text-right ${
                                            typeof validations[inputsIds[4]] !== "undefined" &&
                                            !validations[inputsIds[4]]
                                                ? "border border-red-100"
                                                : ""
                                        }`}
                                    />
                                </div>
                                <span className="pl-3">
                                    {t("{{capitalize, capitalize}}", { capitalize: t("units") })}
                                </span>
                            </div>
                        </div>
                        <div className="w-full mb-5">
                            <div className="w-full mb-5">
                                <span className="flex">
                                    <span className="">
                                        {t("{{capitalize, capitalize}}", { capitalize: t("maximum-quantity-units") })}
                                    </span>
                                    <UseSwitch
                                        id={inputsIds[5]}
                                        checked={enableMaximumQuantity}
                                        action={(value) => {
                                            setEnableMaximumQuantity(value);
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="w-full flex items-center">
                                <div className="w-36 z-10">
                                    <UseCurrencyInput
                                        inputData={{ name: inputsIds[6] }}
                                        id={inputsIds[6]}
                                        forceIntegerNumbers={true}
                                        validation={true}
                                        value={maximumQuantity}
                                        disabled={!enableMaximumQuantity}
                                        onChange={(values) => setMaximumQuantity(values.float)}
                                        adjust={`text-right ${
                                            typeof validations[inputsIds[6]] !== "undefined" &&
                                            !validations[inputsIds[6]]
                                                ? "border border-red-100"
                                                : ""
                                        }`}
                                    />
                                </div>
                                <span className="pl-3">
                                    {t("{{capitalize, capitalize}}", { capitalize: t("units") })}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-end h-10 mt-8 mr-4 mb-4">
                <div className="w-auto inline-block mr-4" onClick={handleCancel}>
                    <UseButton id={inputsIds[11]} buttonName="cancel" buttonColor="btn-blue-outline" adjust="px-8" />
                </div>
                <div className="w-auto inline-block" onClick={handleSave}>
                    <UseButton id={inputsIds[10]} buttonName="save" buttonColor="btn-blue" adjust="px-8" />
                </div>
            </div>
        </div>
    );
}

export default ExtraGroupItemEditCard;
