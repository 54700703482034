export const GetInfoCTableHeaders = (section) => {
    let res = [];
    //Sort icon
    //Special width classes
    const w212 = "w-2/12";
    const w312 = "w-3/12";

    //Header types
    let product = { name: "product" };
    let option = { name: "option" };
    let duration = { name: "duration" };
    let licenseNumber = { name: "license-number" };

    switch (section) {
        case "list-contracted-products":
            product.specialWidth = w312;
            option.specialWidth = w212;
            duration.specialWidth = w312;
            licenseNumber.specialWidth = w212;

            res.push(product, option /*, duration, licenseNumber*/);
            break;

        default:
            res = [""];
    }
    return res;
};

export default GetInfoCTableHeaders;
