import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//Components
import UseEditableField from "../../../../components/useEditableField";
import UseButton from "../../../../components/useButton";

//Actions
import { showGlobalLoading } from "../../../../actions/uiActions";
import { setExecuteQuery } from "../../../../actions/sectionActions";
import { setActionValidations } from "../../../../actions/globalActions";

//Utils
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Comments = ({ guestInfo }) => {
    //Store data
    const { t } = useTranslation();
    const { values } = useSelector((state) => state.action);

    //State
    const [addComment, showAddComment] = useState(false);

    let items = guestInfo.comments;

    if (items) {
        items = items.sort(function (a, b) {
            return b.id - a.id;
        });
    }

    //Actions
    const dispatch = useDispatch();

    const addCommentClick = (e) => {
        showAddComment(!addComment);
    };

    const saveComment = () => {
        //Validate comment
        if (values && !values.comment) {
            toast.error(t("errors-in-red"));
            dispatch(setActionValidations({ comment: false }));
            return;
        }
        dispatch(setActionValidations({ comment: true }));

        //compose comments array for query
        const commentsObject = {
            comment: values.comment,
            time: `${`${new window.ZDate().toLocaleDateString("es-ES", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            })} ${new window.ZDate().toLocaleTimeString("es-ES", {
                hour: "2-digit",
                minute: "2-digit",
            })}`}`,
        };
        dispatch(showGlobalLoading(true));
        showAddComment(false);
        dispatch(
            setExecuteQuery({
                action: "updateComment",
                params: { id: guestInfo.guestID, comments: commentsObject },
            })
        );
    };

    return (
        <div className="">
            {addComment ? (
                <div>
                    <div className="mt-4">
                        <UseEditableField
                            data={{
                                label: "comment",
                                inEditionMode: true,
                                placeholder: `${t("write-comment")}...`,
                                type: "textarea",
                                id: "comments-text-area",
                            }}
                        />
                    </div>
                    <div className="mt-4 flex  justify-end">
                        <div id="coomments-cancel-button" onClick={() => showAddComment(false)}>
                            <UseButton
                                id="comments-cancel-comment-button"
                                buttonName={"cancel"}
                                buttonColor={"btn-white"}
                                adjust="w-24 min-w-0 mr-2 float-left "
                            />
                        </div>
                        <div id="coomments-save-button" onClick={() => saveComment()}>
                            <UseButton
                                id="comments-save-comment-button"
                                buttonName={"save"}
                                buttonColor={"btn-blue"}
                                adjust="w-24 min-w-0"
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex mt-4">
                    <div
                        id="comments-add-comment-button"
                        className=" ml-auto text-lg text-zafiro-400 font-bold cursor-pointer align-middle my-auto"
                        onClick={(e) => addCommentClick(e)}
                    >
                        <span className="icon-comment-add mr-2 text-xl"></span>
                        {t("{{capitalize, capitalize}}", { capitalize: t("add comment") })}
                    </div>
                </div>
            )}

            <div className={`m-auto mt-4 commentsMaxHeight${addComment ? "_short" : ""} overflow-scroll`}>
                {items.map((item) => (
                    <div key={item.comment} className="p-4 rounded border border-gray-200 mb-4 break-words">
                        <div className="text-base font-normal whitespace-pre-wrap">{item.comment}</div>
                        <div className="pt-6 text-sm text-gray-800">
                            {new window.ZDate(item.time).toLocaleDateString([], {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                            })}{" "}
                            {new window.ZDate(item.time).toLocaleTimeString("es-ES", {
                                hour: "2-digit",
                                minute: "2-digit",
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Comments;
