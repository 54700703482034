export const CZSidebar = [
    "bookings",
    "capacity-monitor",
    "cleaning",
    "staff",
    "areas-activities",
    "zones-configuration",
    "categories",
    "edit-description",
    "area-temp-close",
    "close-comment",
    "close-warning",
    "enter-area-description",
];
