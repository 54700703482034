import React from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import { GroupViewModal } from "./GroupViewModal";
import Icon from "components/Icon";
import Button from "components/Button";

export const EditGroup = ({ group, refetch, setLoadingRefresh, type, position }) => {
    const { t } = useTranslation();
    const { open, close } = useModal();

    const typeOfGroup = type === "room" ? t("edit-room-group") : t("edit-group-guest");

    const handleEdit = () => {
        open(
            <Modal
                id="edit-room-group"
                title={
                    <h1 className="text-2xl font-bold">
                        {group?.id ? `${group?.name} - ${t(typeOfGroup)}` : t(typeOfGroup)}
                    </h1>
                }
                minWidth="30rem"
                className="w-8/12 p-10"
                onKeyPress={{ Enter: close }}
            >
                <GroupViewModal group={group} refetch={refetch} setLoadingRefresh={setLoadingRefresh} type={type} />
            </Modal>
        );
    };

    return (
        <Button id={`edit-group-${position}`} onClick={handleEdit}>
            <Icon id={`edit-group-room-${position}`} type="edit" size="xl" className="cursor-pointer" />
        </Button>
    );
};
