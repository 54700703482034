import React, { useState, useEffect } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import TextInput from "components/TextInput";
import Loading from "components/Loading";
import uuid from "react-uuid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "hooks/Session/auth";
import { toast } from "react-toastify";
import { getProjectLangs } from "hooks/Utils/SalesUtils";
import { Session } from "hooks/Utils/Session";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { executeVendureQuery, useVendureAPI } from "hooks/Utils/Integrations/useVendure";

const ModalAddProduct = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { adminApi } = useVendureAPI();

    const [loading, setLoading] = useState(false);
    const [langDefault, setLangDefault] = useState("");
    const [name, setName] = useState("");
    const [slug, setSlug] = useState("");
    const [validations, setValidations] = useState({
        name: true,
    });
    const { languages: projectLangs } = useAuth();
    let taxCategoryId =
        props.data &&
        props.data.shopData &&
        props.data.shopData.customFields &&
        props.data.shopData.customFields.default_tax_category &&
        props.data.shopData.customFields.default_tax_category.id
            ? props.data.shopData.customFields.default_tax_category.id
            : null;

    const tokenShop = Session.getSessionProp("tokenShop");

    async function createProduct() {
        const response = await executeVendureQuery(
            adminApi,
            {
                queryBody: `mutation {
                        createProduct(
                            input: {
                              enabled: false
                              translations: [
                                {
                                  languageCode: ${langDefault}
                                  name: "${name}"
                                  slug: "${slug}"
                                  description: ""
                                }
                              ]
                            }
                          ) {
                            id
                            createdAt
                          }
                    }`,
            },
            { "vendure-token": tokenShop }
        );
        if (response?.data?.errors) {
            toast.error(t("mutation-error"));
        } else {
            await createProductVariant(response?.data?.createProduct?.id);
        }
    }

    async function createProductVariant(productId) {
        const response = await executeVendureQuery(
            adminApi,
            {
                queryBody: ` mutation {
                        createProductVariants(
                            input: {
                                productId:${productId}
                                translations: [{
                                    languageCode: ${langDefault}
                                    name: "${name}"
                                }]
                                sku:"${uuid()}"
                                ${taxCategoryId ? " taxCategoryId:" + taxCategoryId + " " : " "}
                            }
                          ) {
                            id
                          }
                    }`,
            },
            { "vendure-token": tokenShop }
        );
        if (response?.data?.errors) {
            toast.error(t("mutation-error"));
        } else {
            navigate(`/services/sales/shop/${props?.data?.shopData?.channel?.id}/product/${productId}`);
            props.close();
        }
    }
    const handleSaveClick = () => {
        if (!name) {
            setValidations((val) => {
                return { ...val, name: false };
            });
            toast.error(t("errors-in-red"));
        } else {
            setLoading(true);
            createProduct();
        }
    };

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    useEffect(() => {
        if (projectLangs) {
            setLangDefault(getProjectLangs(projectLangs, t).langDefault);
        }
        //eslint-disable-next-line
    }, [projectLangs]);

    return (
        <Modal
            title={`${capitalizeFirst(t("new-product"))}`}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button disabled={loading} design="blue" id="modal-button-save" onClick={handleSaveClick}>
                        {capitalizeFirst(t("save"))}
                    </Button>
                </>
            }
            className={"w-5/12 p-10"}
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <>
                    <p className="font-bold">{t("name")} *</p>
                    <div className="flex gap-2">
                        <p>{`${t("language:" + langDefault)} ( ${t("default-language")} )`}</p>
                        <div className="w-full">
                            <TextInput
                                id={"product-lang-default"}
                                value={name}
                                className={`${!validations?.name ? "border border-red-100" : ""}`}
                                onChange={(values) => {
                                    setName(values);
                                    setSlug(values.trim().toLowerCase().replace(/\s+/g, "-"));
                                }}
                            ></TextInput>
                        </div>
                    </div>
                </>
            )}
        </Modal>
    );
};

export const UseAddProductModal = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalAddProduct {...newProps} />);
        },
    };
};
