import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    toggleCustomInlineStyle,
    removeAllInlineStyles,
    getSelectionCustomInlineStyle,
    getSelectionInlineStyle,
} from "draftjs-utils";
import { RichUtils } from "draft-js";

const CustomFontStyletype = ({ editorState, onChange, modalHandler, toolbar, defaultStyle = "paragraph" }) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(t(defaultStyle));

    const stylestype =
        toolbar && toolbar.styles
            ? toolbar.styles
            : [
                  { value: "header", text: t("heading"), style: {} },
                  { value: "paragraph", text: t("paragraph"), style: {} },
              ];

    const setFontStyletype = (styleType) => {
        setSelected(styleType.text);
        const { style } = styleType;
        editorState = removeAllInlineStyles(editorState);
        editorState = toggleCustomInlineStyle(editorState, "fontFamily", style.fontFamily);
        editorState = toggleCustomInlineStyle(editorState, "fontSize", style.fontSize);

        if (style.bold) {
            editorState = RichUtils.toggleInlineStyle(editorState, "BOLD");
        }
        if (style.italic) {
            editorState = RichUtils.toggleInlineStyle(editorState, "ITALIC");
        }
        if (style.underline) {
            editorState = RichUtils.toggleInlineStyle(editorState, "UNDERLINE");
        }

        if (editorState) {
            onChange(editorState);
        }
    };

    const close = () => {
        setOpen(false);
    };
    useEffect(() => {
        const styledApp = getCurrentInStyletype();
        if (isNaN(styledApp.fontSize) && styledApp.fontFamily === "undefined") {
            setSelected(t(defaultStyle));
            return;
        }
        const styleMatch = stylestype.find((style) => areObjEquals(style.style, styledApp));
        if (styleMatch) {
            setSelected(styleMatch.text);
            return;
        }
        const styleHeader = stylestype.find((style) => style.value === "header");
        if (styleHeader.style.fontSize === styledApp.fontSize) {
            setSelected(styleHeader.text + "*");
        } else {
            setSelected(t(defaultStyle) + "*");
        }
        // eslint-disable-next-line
    }, [editorState]);

    const getCurrentInStyletype = () => {
        const _size = parseInt(
            (getSelectionCustomInlineStyle(editorState, ["FONTSIZE"]).FONTSIZE + "").replace("fontsize-", "")
        );
        const _fontName = (getSelectionCustomInlineStyle(editorState, ["FONTFAMILY"]).FONTFAMILY + "").replace(
            "fontfamily-",
            ""
        );

        const { BOLD: _bold } = getSelectionInlineStyle(editorState);
        const { ITALIC: _italic } = getSelectionInlineStyle(editorState);
        const { UNDERLINE: _underline } = getSelectionInlineStyle(editorState);

        return { fontSize: _size, fontFamily: _fontName, bold: _bold, italic: _italic, underline: _underline };
    };

    useEffect(() => {
        if (modalHandler) modalHandler.registerCallBack(close);
        return () => {
            if (modalHandler) modalHandler.deregisterCallBack(close);
        };
    }, [modalHandler]);

    return (
        <div
            key={useRef()}
            onClick={() => {
                if (!open) setTimeout(() => setOpen(true), 100);
            }}
            className="rdw-block-wrapper custom-font-style-type"
            aria-label="rdw-block-control"
        >
            <div className="rdw-dropdown-wrapper rdw-block-dropdown w-full" aria-label="rdw-dropdown">
                <div className="rdw-dropdown-selectedtext">
                    <div className="first-capital pr-8">{selected}</div>
                    <div className={`rdw-dropdown-caretto${open ? "close" : "open"}`}></div>
                </div>
                <ul className={`rdw-dropdown-optionwrapper ${open ? "" : "hidden"}`}>
                    {stylestype &&
                        stylestype.map((item) => (
                            <li
                                onClick={() => setFontStyletype(item)}
                                key={"cfs-" + item.value}
                                className="rdw-dropdownoption-default placeholder-li"
                            >
                                {item.text}
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
};
function areObjEquals(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
}
export default CustomFontStyletype;
