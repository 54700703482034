import { useEffect, useState } from "react";

import { DEVICE } from "constants/editor";

const DEVICES = [
    {
        id: DEVICE.TYPE.TV,
        cols: 64,
        rows: 36,
        scrollY: false,
    },
    {
        id: DEVICE.TYPE.DESKTOP,
        cols: 64,
        rows: 36,
        scrollY: true,
    },
    {
        id: DEVICE.TYPE.MOBILE,
        cols: 12,
        rows: 26,
        scrollY: true,
    },
];

const BORDER_SIZE = 5;
const SCROLLBAR_SIZE = 5;

const MIN_AREA_WIDTH = 450;
const MIN_AREA_HEIGHT = 550;

const useDevice = ({ editorRef, deviceType, area }) => {
    const current = { ...DEVICES.find((d) => d.id === deviceType) };
    const cols = current?.cols;
    const rows = current?.rows;
    const scrollY = current?.scrollY;
    const ratio = cols / rows;
    const landscape = ratio > 1;
    const portrait = ratio < 1;
    const square = ratio === 1;

    const [editorSize, setEditorSize] = useState({ width: 0, height: 0 });

    const scrollBarSize = scrollY ? SCROLLBAR_SIZE : 0;

    const areaRect = area ?? { width: 800, height: 800 };
    const areaWidth = Math.max(areaRect?.width, MIN_AREA_WIDTH);
    const areaHeight = Math.max(areaRect?.height, MIN_AREA_HEIGHT);

    const maxWidth = (areaWidth ? areaWidth : 0) - BORDER_SIZE * 2 - scrollBarSize;
    const maxHeight = areaHeight ? areaHeight - BORDER_SIZE * 2 : 0;

    const [width, height] = (() => {
        if (landscape) {
            if (maxWidth / ratio > maxHeight) {
                return [maxHeight * ratio, maxHeight];
            }
            return [maxWidth, maxWidth / ratio];
        }
        if (maxHeight * ratio > maxWidth) {
            return [maxWidth, maxWidth / ratio];
        }
        return [maxHeight * ratio, maxHeight];
    })();

    const colWidth = (width - BORDER_SIZE * 2) / cols;
    const rowHeight = (height - BORDER_SIZE * 2) / rows;

    const editorElement = editorRef?.current?.getElement();

    useEffect(() => {
        const observer = new ResizeObserver((e) => {
            for (let entry of e) {
                setEditorSize({
                    width: entry?.contentRect?.width,
                    height: entry?.contentRect?.height,
                });
            }
        });

        if (editorElement) {
            observer.observe(editorElement);
        }
        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [editorElement]);

    const loading = !editorSize?.width;

    return {
        ...current,
        width: width ? width + scrollBarSize : 0,
        height: width ? height : 0,
        editor: {
            width: loading ? 0 : editorSize?.width + scrollBarSize,
            height: loading ? 0 : editorSize?.height,
        },
        border: BORDER_SIZE,
        scrollbar: scrollBarSize,
        cols,
        rows,
        colWidth,
        rowHeight,
        ratio,
        landscape,
        portrait,
        square,
        loading,
    };
};

export default useDevice;
