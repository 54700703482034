import React from "react";
import { gql } from "apollo-boost";
import { capitalizeFirst, POLLINTERVAL_15S } from "hooks/Utils/Utils";
import { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { parseTranslation } from "hooks/Utils/SalesUtils";
import Button from "components/Button";
import { useNavigate as useHistory } from "react-router-dom";

export const useListOrderSummary = () => {
    const [rows, setRows] = useState([]);

    const history = useHistory();
    const { t } = useTranslation();

    const GET_ZAFIRO_SHOPS_ORDERS_DATA = gql`
        query {
            shops(includeTotals: true, size: 999) {
                results {
                    id
                    name: nameTranslations {
                        lang
                        text
                    }
                    totalOrders {
                        inProgress
                        pending
                        ready
                        delayedReceived
                        delayedReady
                    }
                }
            }
        }
    `;

    const [executeQuery, { data, loading, error, called }] = useLazyQuery(GET_ZAFIRO_SHOPS_ORDERS_DATA, {
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (data?.shops?.results && !loading && !error) {
            const shops = data?.shops?.results?.map((shop) => {
                return {
                    shop: (
                        <p
                            className="t-link font-bold cursor-pointer"
                            alt={getShopName(shop)}
                            id={`shop-${shop?.id}`}
                            onClick={() => history(`/services/sales/order-monitor/${shop?.id}`)}
                        >
                            {getShopName(shop)}
                        </p>
                    ),
                    pending: getTexts(shop?.totalOrders?.pending),
                    "in-progress": getTexts(shop?.totalOrders?.inProgress),
                    ready: getTexts(shop?.totalOrders?.ready),
                    button: (
                        <Button
                            id={`view-${shop.id}`}
                            onClick={() => {
                                history(`/services/sales/order-monitor/${shop.id}`);
                            }}
                            className="t-link"
                            type="primary"
                        >
                            {capitalizeFirst(t("monitor"))}
                        </Button>
                    ),
                };
            });
            setRows(shops);
        }
    }, [data]);

    const getTexts = (items) =>
        `${items === 1 ? `${items} ${capitalizeFirst(t("order"))}` : `${items} ${t("orders")}`}`;
    const getShopName = (shop) => parseTranslation(shop?.name, "text");

    return { rows, ready: !loading && called };
};
