import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
//Components
import UseSectionHeader from "../../../useSectionHeader";
import UseDashboardCards from "../../DasboardCards/useDashboardCards";
import UseTable from "../../../Table";
import RoomDetailGuest from "./RoomDetailGuest";
import AddGuest from "../../Customers/Guest/AddGuest";
//Utils
import RoomDetailDevices from "../../../../hooks/GraphqlCalls/Hotel/Rooms/RoomDetailDevices";
import DetailRoomInfo from "../../../../hooks/GraphqlCalls/Hotel/Rooms/RoomDetailRoomInfo";
import DetailGuestInfo from "../../../../hooks/GraphqlCalls/Hotel/Rooms/RoomDetailGuestInfo";
import UpdateListDevices from "../../../../hooks/GraphqlCalls/Hotel/UpdateListDevices";

//Actions
import { cleanSectionContent, setRefreshContentData } from "../../../../actions/sectionActions";
import { cleanTableStore } from "../../../../actions/tableActions";
import { cleanDashboardCards } from "../../../../actions/uiActions";
import { Session } from "../../../../hooks/Utils/Session";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";

const RoomDetail = () => {
    const dispatch = useDispatch();
    //parameters
    const { id } = useParams();
    //states
    const { t } = useTranslation();
    const [roomName, setRoomName] = useState("");
    const [showCheckIn, setShowCheckIn] = useState(false);
    const [roomNumber, setRoomNumber] = useState(null);
    const { room } = useSelector((state) => state.sectionContent);
    const { permissions } = useSelector((state) => state.ui);
    const guestAccess = permissions?.product?.guest;
    const sectionName = `room-detail`;

    useEffect(() => {
        if (room) {
            setRoomName(room.name);
            setRoomNumber(room.number);
        } else setRoomName("");
    }, [room]);

    //hook calls
    DetailRoomInfo({ roomId: id, guestAccess: guestAccess, roomNumber: roomNumber });
    DetailGuestInfo({ roomId: id });
    RoomDetailDevices({ roomId: id });

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(cleanTableStore());
        return () => {
            dispatch(cleanSectionContent());
            dispatch(cleanTableStore());
            dispatch(cleanDashboardCards());
        };
        // eslint-disable-next-line
    }, []);

    const backToRoomDetail = () => {
        dispatch(setRefreshContentData(true));
        setShowCheckIn(false);
    };

    return showCheckIn ? (
        <AddGuest backToRoomDetail={backToRoomDetail} breadcrumbsLimit={2} roomId={id} />
    ) : (
        <>
            {/*<ListDevices roomId={id} />*/}

            <UseSectionHeader
                subTitle={roomName ? `${capitalizeFirst(t("room"))} ${roomNumber}` : ""}
                noParenthesisSubtitle={true}
                title={[roomName]}
                navToPreviousPage={true}
                breadcrumbsLimit={2}
                adjustSubtitle=" text-base font-normal"
            />
            <div className="inline-grid grid-cols-4 gap-6 w-full">
                {/* GUEST */}
                {guestAccess ? <RoomDetailGuest setShowCheckIn={setShowCheckIn} /> : null}
                <div className={`${guestAccess ? "col-span-3" : "col-span-12"}`}>
                    {/* CARDS */}
                    <UseDashboardCards parentSectionName={sectionName} />

                    {/* DEVICES TABLE */}
                    {
                        <>
                            <UpdateListDevices />
                            <UseTable />
                        </>
                    }
                </div>
            </div>
        </>
    );
};

export default RoomDetail;
