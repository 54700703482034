import React, { useState } from "react";
import { Link } from "react-router-dom";

const UseIconMultipleIconCell = ({ link, item, classNames, others = null }) => {
    const [display, setDisplay] = useState({
        icon: "text-blue-300",
        text: "hidden",
    });

    const hoverHandler = () => {
        setDisplay({ icon: "text-blue-200", text: "block" });
    };

    const mouseOutHandler = () => {
        setDisplay({ icon: "text-blue-300", text: "hidden" });
    };

    return (
        <div className="inline">
            <Link to={link + item.id} className="z-50">
                <div
                    onMouseEnter={hoverHandler}
                    onMouseLeave={mouseOutHandler}
                    className={`${classNames} cursor-pointer ${display.icon}`}
                ></div>
            </Link>
            <Link to={link + item.id}>
                <div
                    id="click-area"
                    className={`absolute z-100 click-area pt-2 ${display.text}`}
                    onMouseEnter={hoverHandler}
                    onMouseLeave={mouseOutHandler}
                >
                    <div className="bg-white text-grey-600 py-1 px-2 mt-0 rounded text-left z-50 border border-gray-600 shadow-lg">
                        {others ? (
                            others.map((_name, index) => (
                                <div key={index} className="overflow-ellipsis overflow-hidden whitespace-nowrap ">
                                    {_name}
                                </div>
                            ))
                        ) : (
                            <div className="overflow-ellipsis overflow-hidden whitespace-nowrap ">{item.name}</div>
                        )}
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default UseIconMultipleIconCell;
