import { SET_ACTIVE_HOTEL, CLEAR_PROJECT_REF, SET_PROJECT_REF } from "../actions/hotelActions";
import { Session } from "../hooks/Utils/Session";

const project = Session.getProject();

const initialState = {
    id: project?.id,
    name: project?.name,
    location: project?.location,
    global: project?.global,
    timezone: project?.timezone,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ACTIVE_HOTEL:
            return {
                ...state,
                ...action.payload,
            };
        case CLEAR_PROJECT_REF:
            return {
                ...state,
                ref: null,
            };
        case SET_PROJECT_REF:
            return {
                ...state,
                global: action.playload.global,
            };
        default:
            return state;
    }
}
