import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
//ACTIONS;
import { setRefreshData } from "../../../actions/tableActions";
//COMPONENT
import UseLoading from "../../Table/useLoading";
import UseSearch from "../../useSearch";
import UseEditableField from "../../useEditableField";
import UseSelect from "../../Inputs/useSelect";
import UseButton from "../../useButton";
import ReactTooltip from "components/ReactTooltip";
//UTILS
import { capitalizeFirst } from "../../../hooks/Utils/Utils";
import { toast } from "react-toastify";
import { escapeSpecialChars } from "../../../hooks/Utils/Utils";
import Parser from "hooks/Utils/Parser";

const PortalMac = () => {
    //DATA
    const { itemsAffected, values } = useSelector((state) => state.action);
    const { modalContent } = useSelector((state) => state.ui);
    const { t } = useTranslation();

    const HEADER_TITLES_INFO = [
        { name: "MAC", specialWidth: "w-4/12", valueSort: "" },
        { name: "description", specialWidth: "w-4/12", valueSort: "description" },
        { name: "permission", specialWidth: "w-3/12", valueSort: "" },
        { name: "items", specialWidth: "w-1/12 flex justify-end pr-8", valueSort: "" },
    ];

    const OPTIONS_FILTER_INFO = [
        { name: "all", query: "" },
        { name: "free-access-mac", value: true, query: ",access:true" },
        { name: "blocked-mac", value: false, query: ",access:false" },
    ];

    const OPTIONS_SELECT = [
        { value: t("free-access-mac"), text: t("free-access-mac") },
        { value: t("blocked-mac"), text: t("blocked-mac") },
    ];

    //STATE VARS
    const [hotspotName, setHotSpotName] = useState("");
    const [macsData, setMacsData] = useState([]);
    const [allMacsData, setAllMacsData] = useState([]);
    const [accessProfileData, setAccessProfileData] = useState(null);
    const [search, setSearch] = useState("");
    const [orderBy, setOrderBy] = useState({ orderQuery: "", criteria: "", field: "" });
    const [currentFilter, setCurrentFiler] = useState({ name: "all", query: "" });
    const [isSelectFilterV, setSelectFilterV] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const [currentIdMacInEdition, setCurrentMacInEdition] = useState(null);
    const [macsContainerScroll, setMacsContainerScroll] = useState(false);
    const [showModalDeleteMac, setShowModalDeleteMac] = useState(false);
    const [execute, setExecute] = useState(false);
    const [addMacE, setAddMacE] = useState(false);
    const [dataMacToDelete, setDataMacToDelete] = useState(null);
    const [mutation, setMutation] = useState(`
        mutation{
            fake{ error id ok}
        }
    `);

    const permissionFilter = currentFilter.query === "" ? "" : currentFilter.query;

    const finalQuery = search
        ? `(filter:{hotspot:"${hotspotName}" ${permissionFilter}  , search:"${search}"} ${orderBy.orderQuery} )`
        : `(filter:{hotspot:"${hotspotName}"  ${permissionFilter}  , search:""} ${orderBy.orderQuery} )`;
    //ACTIONS
    const dispatch = useDispatch();

    const GET_MACS = gql`
        {
            macExceptions ${finalQuery} {
                info {
                    count
                    page
                    size
                }
                results {
                    access
                    description
                    hotspot
                    mac
                }
            }
            wifiAccessTypes(hotspot: "${hotspotName}") {
                results {
                  accesstypes_name
                  additionalAccessProfileData {
                    name
                    uploadBWMax
                    uploadBWMin
                    downloadBWMax
                    downloadBWMin
                  }
                  accessprofiles_id
                }
              }
              allMacs:  macExceptions(filter: { hotspot: "${hotspotName}" }) {
                info {
                  count
                  page
                  size
                }
                results {
                  access
                  description
                  hotspot
                  mac
                }
              }
        }
    `;

    const [executeQuery, { data, loading, refetch }] = useLazyQuery(GET_MACS, {
        fetchPolicy: "network-only",
    });

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            toast.error(err.message);
            setLoadingState(false);
            resetMacInEdition();
            setAddMacE(false);
            refetch();
        },
        onCompleted() {
            toast.success(t("operation-successful"));
            setLoadingState(false);
            setAddMacE(false);
            resetMacInEdition();
            refetch();
            dispatch(setRefreshData(true));
        },
    });

    //LISTENERS

    useEffect(() => {
        setHotSpotName(itemsAffected[0] ? itemsAffected[0] : modalContent.value);
        executeQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (execute === true) {
            setLoadingState(true);
            executeMutation();
            setExecute(false);
        }
        // eslint-disable-next-line
    }, [execute]);

    useEffect(() => {
        if (!loading && data) {
            const dataMacsArrange = arrangeDataMacExceptions(data);
            const dataAccessProfiles = arrangeDataAccessProfiles(data);
            const dataAllMacsArrange = arrangeAllDataMacExceptions(data);
            setMacsData(dataMacsArrange);
            setAllMacsData(dataAllMacsArrange);
            setAccessProfileData(dataAccessProfiles);
            setLoadingState(false);
            setAddMacE(false);
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        const macsContainer = document.querySelector("#macs-container");
        if (macsContainer) {
            if (macsContainer.scrollHeight > macsContainer.clientHeight) {
                setMacsContainerScroll(true);
            } else {
                setMacsContainerScroll(false);
            }
        }
        // eslint-disable-next-line
    }, [macsData]);

    useLayoutEffect(() => {
        if (currentIdMacInEdition) {
            const macsContainer = document.querySelector("#macs-container");
            const macOptions = document.querySelector(
                `#permission-open-${currentIdMacInEdition.mac} .t-options-container`
            );
            const macsOptionsCreate = document.querySelector(`
                #permissions-open-create .t-options-container
            `);

            if (macsOptionsCreate && macsContainer && macsContainerScroll) {
                const macsOptionsCreateBounding = macsOptionsCreate.getBoundingClientRect();
                macsContainer.scrollTop -= macsOptionsCreateBounding.height;
            } else if (macsOptionsCreate && macsContainer && !macsContainerScroll) {
                const macOptionsCreateBounding = macsOptionsCreate.getBoundingClientRect();
                macsOptionsCreate.style.width = macOptionsCreateBounding.width + "px";
                macsOptionsCreate.style.position = "fixed";
            }

            if (macOptions && macsContainer && macsContainerScroll) {
                const macOptionsBounding = macOptions.getBoundingClientRect();
                macsContainer.scrollTop += macOptionsBounding.height;
            } else if (macOptions && macsContainer && !macsContainerScroll) {
                const macOptionsBounding = macOptions.getBoundingClientRect();
                macOptions.style.width = macOptionsBounding.width + "px";
                macOptions.style.position = "fixed";
            }
        }
        // eslint-disable-next-line
    }, [currentIdMacInEdition]);

    //FUNCTIONS
    const arrangeDataMacExceptions = (data) => {
        const ListMacExceptions = [];

        if (data && data.macExceptions && data.macExceptions.results) {
            data.macExceptions.results.forEach((macException) => {
                macException.inEdition = false;
                ListMacExceptions.push(macException);
            });
        }

        return ListMacExceptions;
    };

    const arrangeAllDataMacExceptions = (data) => {
        const ListMacExceptions = [];

        if (data && data.macExceptions && data.allMacs.results) {
            data.allMacs.results.forEach((macException) => {
                ListMacExceptions.push(macException);
            });
        }

        return ListMacExceptions;
    };

    const arrangeDataAccessProfiles = (data) => {
        let macAccessProfileData = {};

        if (data && data.wifiAccessTypes && data.wifiAccessTypes.results) {
            data.wifiAccessTypes.results.forEach((accessType) => {
                if (
                    accessType.accesstypes_name &&
                    accessType.accesstypes_name === "mac" &&
                    accessType.additionalAccessProfileData
                ) {
                    macAccessProfileData = {
                        id: accessType.accessprofiles_id,
                        ...accessType.additionalAccessProfileData,
                    };
                }
            });
        }

        return macAccessProfileData;
    };

    const validateNotRepeatedMac = (newMac) => {
        let repeated = true;
        allMacsData.forEach((dataMac) => {
            if (newMac.toLowerCase() === dataMac.mac.toLowerCase()) {
                repeated = false;
            }
        });
        return repeated;
    };

    const addMac = () => {
        const macsContainer = document.querySelector("#macs-container");
        setAddMacE((prev) => !prev);
        if (macsContainer && !addMacE) {
            macsContainer.scrollTop = "0px";
        }
    };

    const editMac = (editData) => {
        let macTemp = [];

        macsData.forEach((macInfo) => {
            if (macInfo.mac === editData.mac) {
                macInfo.inEdition = true;
            }
            macTemp.push(macInfo);
        });

        setMacsData(macTemp);
    };

    const resetMacInEdition = () => {
        let macTemp = [];

        macsData.forEach((macInfo) => {
            macInfo.inEdition = false;
            macTemp.push(macInfo);
        });

        setMacsData(macTemp);
    };

    const cancelEdit = (editData) => {
        let macTemp = [];

        macsData.forEach((macInfo) => {
            if (macInfo.mac === editData.mac) {
                macInfo.inEdition = false;
            }
            macTemp.push(macInfo);
        });

        setMacsData(macTemp);
    };

    const setModalBgOpacity = (value) => {
        const modalBg = document.querySelector("#modal-background");
        if (modalBg) {
            modalBg.style.opacity = `${value}`;
        }
    };

    const deleteMac = (editData) => {
        setModalBgOpacity("0");
        setDataMacToDelete(editData);
        setShowModalDeleteMac(true);
    };

    const updateMacException = (mac) => {
        const macDesc = document.querySelector(`#edition-mac-description-${mac.mac.replace(/[:]/g, "")}`);
        if (macDesc.value === "") {
            toast.error(capitalizeFirst(t("description-cannot-be-empty")));
            macDesc.classList.add("borderError");
        } else {
            macDesc.classList.remove("borderError");
            const permission = values[`select-mac-permission-${mac.mac.replace(/[:]/g, "")}`];
            const permissionValue = permission === t("free-access-mac") ? true : false;

            setMutation(`
                mutation {
                updateMacException(
                  mac: "${escapeSpecialChars(mac.mac)}"
                  hotspot: "${escapeSpecialChars(hotspotName)}"
                  description: "${escapeSpecialChars(macDesc.value)}"
                  access: ${permissionValue}
                ) {
                  id
                  ok
                  error
                }
              }`);

            setExecute(true);
        }
    };

    const createMacException = () => {
        const macInput = document.querySelector("#new-mac-exc-input");
        const macDesc = document.querySelector("#new-mac-desc-exc-input");
        const macPermission = values["new-mac-permission-exc-input"];

        const validateMacDirection = (mac) => {
            const regexMac = /^([0-9a-fA-F]{2}[:.-]?){5}[0-9a-fA-F]{2}$/gim;
            return regexMac.test(mac);
        };

        const isValidMac = validateMacDirection(macInput.value);

        if (macInput.value === "" || macDesc.value === "") {
            toast.error(t("errors-in-red"));
        }

        if (macDesc.value === "") {
            macDesc.classList.add("borderError");
        } else {
            macDesc.classList.remove("borderError");
        }

        if (macInput.value === "") {
            macInput.classList.add("borderError");
        } else {
            macInput.classList.remove("borderError");

            if (isValidMac === false) {
                macInput.classList.add("borderError");
                toast.error(t("not-a-valid-mac-adress"));
            } else if (macDesc.value !== "") {
                const permissionValue = macPermission === t("free-access-mac") ? true : false;

                if (!validateNotRepeatedMac(macInput.value)) {
                    toast.error(capitalizeFirst(t("user-with-mac-already-exists", { mac: macInput.value })));
                    return;
                }

                setMutation(`
                mutation {
                    createMacException(
                      mac: "${escapeSpecialChars(macInput.value)}"
                      hotspot: "${escapeSpecialChars(hotspotName)}"
                      description: "${escapeSpecialChars(macDesc.value)}"
                      access: ${permissionValue}
                    ) {
                      id
                      ok
                      error
                    }
                  }`);

                setExecute(true);
            }
        }
    };

    const deleteMacException = () => {
        setMutation(`
        mutation {
            deleteMacException(mac: "${
                dataMacToDelete && dataMacToDelete.mac ? dataMacToDelete.mac : ""
            }", hotspot: "${hotspotName}") {
              id
              ok
              error
            }
          }
        `);
        setExecute(true);
    };
    //RENDER JSX

    const renderHeaderButtons = () => {
        return (
            <>
                <div className=" w-1/5 mt-1 ml-1">
                    <UseSearch
                        placeholder={"search-for"}
                        value={search}
                        onChange={setSearch}
                        id="search-input-portal-mac"
                    />
                </div>
                <div className="mt-1 w-full ml-10 flex items-center">
                    <span className=" mr-3">{t("filter-by")}:</span>
                    <div className=" w-1/5 h-10 bg-gray-200 rounded relative">
                        <div
                            id="selec-macs-button"
                            className={`t-filter-ph first-capital ${
                                currentFilter && currentFilter.name !== "all" ? "text-black" : ""
                            } `}
                            onClick={() => setSelectFilterV((prev) => !prev)}
                        >
                            {currentFilter && currentFilter.name !== "all" ? t(currentFilter.name) : t("permissions")}
                        </div>
                        <span
                            id="selec-macs-icon"
                            className={`field-icon icon-chevron animated   ${isSelectFilterV && "mr-4 -rotate-180"}`}
                            onClick={() => setSelectFilterV((prev) => !prev)}
                        ></span>
                        {isSelectFilterV ? (
                            <div className="t-options-container w-full p-2">
                                {OPTIONS_FILTER_INFO.map((option, index) => (
                                    <div
                                        className="h-10 hover:bg-gray-100 flex items-center cursor-pointer first-capital"
                                        onClick={() => {
                                            setCurrentFiler(option);
                                            if (currentFilter.name !== option.name) {
                                                setLoadingState(true);
                                            }
                                        }}
                                        key={index}
                                        id={`permissions-filter-${option.name}`}
                                    >
                                        {capitalizeFirst(t(option.name))}
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className=" w-1/12 ml-2">
                    <span className="text-sm opacity-0 text-gray-800 whitespace-normal ">
                        {macsData.length} {t("items")}
                    </span>
                </div>
            </>
        );
    };

    const renderHeaders = () => {
        let headers = [];

        HEADER_TITLES_INFO.forEach((title, index) => {
            headers.push(
                <div
                    key={`${title}-${index}`}
                    className={`t-head-container t-row font-bold text-gray-900 first-capital overflow-hidden border-b border-gray-200 ${title.specialWidth}`}
                >
                    {title.name === "items" ? (
                        <div className=" font-normal flex justify-end whitespace-normal ">
                            {macsData.length} {t("items")}
                        </div>
                    ) : null}
                    {title.name !== "items" ? capitalizeFirst(t(title.name)) : null}
                    {renderSortButton(title)}
                </div>
            );
        });

        return headers;
    };

    const renderSortButton = (info) => {
        if (info.valueSort && macsData.length > 0) {
            if (info.valueSort === orderBy.field && orderBy.criteria === "desc") {
                return (
                    <div
                        className=" icon icon-sort-desc t-icon-headers text-gray-800 "
                        onClick={() => {
                            setOrderBy({
                                orderQuery: `orderBy:{field:"${info.valueSort}" , criteria:"asc"}`,
                                criteria: "asc",
                                field: info.valueSort,
                            });
                            setLoadingState(true);
                        }}
                    ></div>
                );
            } else if (info.valueSort === orderBy.field && orderBy.criteria === "asc") {
                return (
                    <div
                        className=" icon icon-sort-asc t-icon-headers text-gray-800 "
                        onClick={() => {
                            setOrderBy({
                                orderQuery: "",
                                criteria: "",
                                field: "",
                            });
                            setLoadingState(true);
                        }}
                    ></div>
                );
            } else {
                return (
                    <div
                        className=" icon icon-table-sort t-icon-headers text-gray-800"
                        onClick={() => {
                            setOrderBy({
                                orderQuery: `orderBy:{field:"${info.valueSort}" , criteria:"desc"}`,
                                criteria: "desc",
                                field: info.valueSort,
                            });
                            setLoadingState(true);
                        }}
                    ></div>
                );
            }
        }
    };

    const renderButtonsEditAndDelete = (mac) => {
        return (
            <>
                <div
                    id={`${mac.mac.replace(/[:]/g, "")}-edit-button`}
                    className="icon icon-edit cursor-pointer text-gray-800 text-xl mr-4 border-none outline-none"
                    onClick={() => {
                        editMac({
                            mac: mac.mac,
                            description: mac.description,
                            access: mac.access,
                            hotspotName: hotspotName,
                            inEdition: mac.inEdition,
                        });
                    }}
                ></div>
                <div
                    id={`${mac.mac.replace(/[:]/g, "")}-cancel-button`}
                    className="icon icon-delete cursor-pointer text-gray-800 text-xl"
                    onClick={() => {
                        deleteMac({
                            mac: mac.mac,
                            description: mac.description,
                            access: mac.access,
                            hotspotName: hotspotName,
                        });
                    }}
                ></div>
            </>
        );
    };

    const renderAcceptAndCancelButtons = (mac) => {
        return (
            <>
                <div
                    id={mac ? `${mac.mac.replace(/[:]/g, "")}-cancel-edit-button` : `create-mac-cancel-button`}
                    className="icon icon-remove bg-red-100 cursor-pointer text-white p-1 rounded text-xl mr-4"
                    onClick={() => {
                        if (mac) {
                            cancelEdit({
                                mac: mac.mac,
                            });
                        } else {
                            setAddMacE(false);
                        }
                    }}
                ></div>
                <div
                    id={mac ? `${mac.mac.replace(/[:]/g, "")}-accept-edit-button` : `create-mac-accept-button`}
                    className="icon icon-tick-right bg-green-100 text-white font-bold p-1 rounded cursor-pointer text-xl"
                    onClick={() => {
                        if (mac) {
                            updateMacException(mac);
                        } else {
                            createMacException();
                        }
                    }}
                ></div>
            </>
        );
    };

    const renderMacsItems = () => {
        let items = [];
        macsData.forEach((mac, index) => {
            items.push(
                <div key={index} className="w-full flex t-row t-info-container t-head-container">
                    <div className="w-4/12">{mac.mac}</div>
                    {mac.inEdition === false ? (
                        <div className="w-4/12">{mac.description}</div>
                    ) : (
                        <div className="w-4/12">
                            <UseEditableField
                                data={{
                                    id: `edition-mac-description-${mac.mac.replace(/[:]/g, "")}`,
                                    label: "edition-mac-description",
                                    placeholder: "",
                                    inEditionMode: true,
                                    type: "text",
                                    styleAdjust: "w-10/12",
                                    value: mac.description,
                                    noLabel: true,
                                }}
                            />
                        </div>
                    )}
                    {mac.inEdition === false ? (
                        <div className="w-3/12">
                            <div
                                className=" py-1 inline-block px-4 rounded align-middle"
                                style={{
                                    backgroundColor: mac.access === true ? "#36B37E" : "#DE350B",
                                    color: "#fff",
                                }}
                            >
                                {mac.access === true
                                    ? capitalizeFirst(t("free-access-mac"))
                                    : capitalizeFirst(t("blocked-mac"))}
                            </div>
                        </div>
                    ) : (
                        <div className="w-3/12">
                            <div
                                className="w-10/12"
                                onClick={() => {
                                    setCurrentMacInEdition({ mac: mac.mac.replace(/[:]/g, "") });
                                }}
                                id={`permission-open-${mac.mac.replace(/[:]/g, "")}`}
                            >
                                <UseSelect
                                    selectData={{
                                        id: `select-mac-permission-${mac.mac.replace(/[:]/g, "")}`,
                                        data: OPTIONS_SELECT,
                                        activeValue: mac.access === true ? t("free-access-mac") : t("blocked-mac"),
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    <div className=" flex justify-end w-1/12 pr-6 ">
                        {mac.inEdition === false ? renderButtonsEditAndDelete(mac) : renderAcceptAndCancelButtons(mac)}
                    </div>
                </div>
            );
        });

        return items;
    };

    const renderAddNewMac = () => {
        return (
            <div className="w-full flex t-row  t-info-container t-head-container">
                <div className=" ml-1 w-4/12">
                    <UseEditableField
                        data={{
                            id: "new-mac-exc-input",
                            label: "new-mac-exc-input",
                            placeholder: "MAC",
                            inEditionMode: true,
                            type: "text",
                            styleAdjust: "w-10/12",
                            value: "",
                            noLabel: true,
                        }}
                    />
                </div>
                <div className=" w-4/12">
                    <UseEditableField
                        data={{
                            id: "new-mac-desc-exc-input",
                            label: "new-mac-desc-exc-input",
                            placeholder: t("description"),
                            inEditionMode: true,
                            type: "text",
                            styleAdjust: "w-10/12",
                            value: "",
                            noLabel: true,
                        }}
                    />
                </div>
                <div className=" w-3/12">
                    <div
                        className="w-10/12"
                        id="permissions-open-create"
                        onClick={() => {
                            setCurrentMacInEdition({ mac: "create" });
                        }}
                    >
                        <UseSelect
                            selectData={{
                                id: "new-mac-permission-exc-input",
                                data: OPTIONS_SELECT,
                                activeValue: t("free-access-mac"),
                                placeholder: t("permission"),
                            }}
                        />
                    </div>
                </div>
                <div className=" flex justify-end  pr-6 w-1/12">{renderAcceptAndCancelButtons()}</div>
            </div>
        );
    };

    const renderLoading = (bgColor) => {
        return (
            <div
                className="table z-20  absolute top-0 left-0 w-full h-full"
                style={{ backgroundColor: bgColor ? bgColor : "" }}
            >
                <div className=" table-cell align-middle text-center ">
                    <UseLoading adjust="contents" />
                </div>
            </div>
        );
    };

    const renderModalDeleteMac = () => {
        return (
            <div className="flex fixed top-0 left-0 justify-center w-1/1 h-screen items-center">
                <div
                    className=" modal-background top-0 left-0"
                    onClick={(e) => {
                        setModalBgOpacity("0.5");
                        setShowModalDeleteMac(false);
                    }}
                ></div>
                <div className="modal-body  mt-4 mb-4 w-4/12 relative bg-white ">
                    <div className=" p-10">
                        <div className="flex">
                            <h1 className="section-title  first-capital pb-3">
                                {`${dataMacToDelete && dataMacToDelete.mac} - `}
                                <span>{capitalizeFirst(t("delete-mac-exception"))}</span>
                            </h1>
                        </div>
                        <div className=" mt-4">
                            <div className="pb-5">
                                <div className="block">
                                    <p>{t("delete-mac-confirmation")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="pt-8 text-center flex justify-end">
                            <div
                                onClick={() => {
                                    setModalBgOpacity("0.5");
                                    setShowModalDeleteMac(false);
                                    setDataMacToDelete(null);
                                }}
                            >
                                <UseButton buttonName={"cancel"} buttonColor={"btn-white"} />
                            </div>
                            <div
                                className=" ml-4"
                                onClick={() => {
                                    deleteMacException();
                                    setShowModalDeleteMac(false);
                                    setDataMacToDelete(null);
                                    setModalBgOpacity("0.5");
                                }}
                            >
                                <UseButton buttonName={"delete"} buttonColor={"btn-red"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const tooltipHTMLDataAccessProfile = () => {
        return `
        <div class=" flex flex-col text-gray-900 items-center "> 
            <div class="font-bold " >${capitalizeFirst(t("min-bandwidth"))}</div> 
            <div class=" flex items-center">
                <div class=" icon icon-maximum text-1xl" >
                </div>
                <div class=" flex items-center mt-1" >
                ${
                    accessProfileData.uploadBWMin === 0
                        ? "-"
                        : accessProfileData && accessProfileData.name === "Default Access Profile"
                        ? accessProfileData.uploadBWMin / 1000000
                        : accessProfileData.uploadBWMin
                } 
                Mb/s
                </div>
            </div>
            <div class="  flex items-center">
                <div class=" icon icon-minimum text-1xl" >
                </div>
                <div class=" flex items-center mt-1" >
                ${
                    accessProfileData.downloadBWMin === 0
                        ? "-"
                        : accessProfileData && accessProfileData.name === "Default Access Profile"
                        ? accessProfileData.downloadBWMin / 1000000
                        : accessProfileData.downloadBWMin
                } 
                Mb/s
                </div>
            </div>
            <div class="font-bold " >${capitalizeFirst(t("max-bandwidth"))}</div> 
            <div class="  flex items-center ">
                <div class=" icon icon-maximum text-1xl" >
                </div>
                <div class=" flex items-center mt-1" >
                ${
                    accessProfileData.uploadBWMax === 0
                        ? "-"
                        : accessProfileData && accessProfileData.name === "Default Access Profile"
                        ? accessProfileData.uploadBWMax / 1000000
                        : accessProfileData.uploadBWMax
                } 
                Mb/s
                </div>
            </div>
            <div class="  flex items-center">
                <div class=" icon icon-minimum text-1xl" >
                </div>
                <div class=" flex items-center mt-1" >
                ${
                    accessProfileData.downloadBWMax === 0
                        ? "-"
                        : accessProfileData && accessProfileData.name === "Default Access Profile"
                        ? accessProfileData.downloadBWMax / 1000000
                        : accessProfileData.downloadBWMax
                } 
                Mb/s
                </div>
            </div>
        </div>
        `;
    };

    const renderInfoAccessProfile = () => {
        return accessProfileData && accessProfileData.id === 0 ? (
            <div className=" flex items-start mb-6">
                <div className=" mr-2 text-gray-800">
                    {`${capitalizeFirst(t("default"))} (${capitalizeFirst(t("max-bandwidth"))}) `}:
                </div>
                <div className=" flex flex-col justify-start rounded ">
                    <div
                        className=" flex items-center"
                        style={{
                            marginBottom: "0.12rem",
                        }}
                    >
                        <span className=" icon icon-maximum text-gray-800 text-2xl"></span>
                        <span className=" text-gray-800">
                            {accessProfileData && accessProfileData.uploadBWMax / 1000000} Mb/s
                        </span>
                    </div>
                    <div className=" flex items-center">
                        <span className=" icon icon-minimum text-gray-800 text-2xl"></span>
                        <span className=" text-gray-800">
                            {accessProfileData && accessProfileData.downloadBWMax / 1000000} Mb/s
                        </span>
                    </div>
                </div>
            </div>
        ) : accessProfileData && accessProfileData.id !== 0 ? (
            <div className=" flex items-center mb-6">
                <div className=" mr-2">{capitalizeFirst(t("access-profile"))}:</div>
                <div data-tip data-for={"access-profile-macs-data"} className=" bg-zafiro-600 text-white p-2 rounded ">
                    {accessProfileData && accessProfileData.name ? accessProfileData.name : ""}
                </div>
                <ReactTooltip
                    borderColor="text-gray-200"
                    arrowColor="text-gray-200"
                    type="light"
                    id={"access-profile-macs-data"}
                    place="bottom"
                >
                    {Parser(tooltipHTMLDataAccessProfile())}
                </ReactTooltip>
            </div>
        ) : null;
    };

    const renderMainContent = () => {
        if (loading && !data) {
            return renderLoading();
        } else if (macsData.length <= 0) {
            return (
                <div>
                    {renderInfoAccessProfile()}
                    <div className="w-full flex">{renderHeaderButtons()}</div>
                    <div className="w-full flex">{renderHeaders()}</div>
                    {addMacE && renderAddNewMac()}
                    <div className="w-full flex justify-center t-row" id="no-result-message">
                        {search ? t("no-results-found") : t("no-results")}
                    </div>
                </div>
            );
        } else {
            return (
                <div className=" relative">
                    {renderInfoAccessProfile()}
                    <div className="w-full flex">{renderHeaderButtons()}</div>
                    <div className="w-full flex">{renderHeaders()}</div>
                    <div
                        id="macs-container"
                        style={{ minHeight: "auto", maxHeight: "19rem" }}
                        className="w-full overflow-scroll"
                    >
                        {addMacE && renderAddNewMac()}
                        {renderMacsItems()}
                    </div>
                    {loadingState && renderLoading("rgba(255,255,255, 0.5)")}
                </div>
            );
        }
    };

    return (
        <>
            <div
                style={{ minHeight: "auto", maxHeight: "30rem" }}
                className=" w-full relative"
                onClick={(e) => {
                    if (e.target.id === "selec-macs-button" || e.target.id === "selec-macs-icon") return;
                    setSelectFilterV(false);
                }}
            >
                {renderMainContent()}
            </div>
            <div className=" w-full ">
                <div
                    className=" inline-block text-zafiro-600 font-bold cursor-pointer"
                    id="add-mac-button"
                    onClick={() => {
                        addMac();
                    }}
                >
                    <i className="icon icon-add mr-1"></i>
                    {capitalizeFirst(t("add-mac"))}
                </div>
            </div>
            {showModalDeleteMac && renderModalDeleteMac()}
        </>
    );
};

export default PortalMac;
