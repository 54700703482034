import React from "react";

const DynamicSimpleTable = ({ data, headers }) => {
    return (
        <div className="w-full ">
            <div className="flex w-full mb-4">
                {headers.map((header, index) => (
                    <div className={`font-bold flex ${header.width} ${header.style || ""}`} key={index}>
                        {header.header}
                    </div>
                ))}
                <div className=" flex justify-end w-full">{` ${data?.length} ${headers?.[0]?.header}`}</div>
            </div>
            <div
                style={{ minHeight: "20rem", maxHeight: "25rem", overflowY: "scroll" }}
                className=" border rounded px-6 "
            >
                {data.map((row, rowIndex) => (
                    <div className="flex w-full py-4 border-b" key={rowIndex}>
                        {row.map((cell, colIndex) => (
                            <div className={`flex items-center ${cell.width} ${cell.style || ""}`} key={colIndex}>
                                {cell.item}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DynamicSimpleTable;
