import { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    cleanTableStore,
    setCountPosition,
    setHeaders,
    setLoading,
    setTableResults,
    setCustomError,
    setFormNewContent,
    setCustomConfig,
    setActions,
} from "../../../actions/tableActions";
//Utils
import GetTableForm from "../../Utils/Table/GetTableForm";
import GetTableHeaders from "../../Utils/Table/Headers/GetCustomersTableHeaders";
//Model
import Companion from "./models/Companion";
import { useTranslation } from "react-i18next";

import { GlobalContext } from "contexts/Global";

const ListCompanions = () => {
    const { t } = useTranslation();
    const { data, customFields, customFieldsChildren } = useSelector((state) => state.sectionContent);
    const { lang } = useContext(GlobalContext);
    const { permissions } = useSelector((state) => state.ui);

    const precheckinAvailable = permissions?.guests?.preCheckin ? true : false;

    //Data
    const sectionName = "list-companions";

    //Actions
    const dispatch = useDispatch();

    const composeTable = () => {
        dispatch(cleanTableStore());

        //Get data
        const dataItems = arrangeData(
            data ? data.companions : [],
            data?.rooms ? data.rooms : [],
            precheckinAvailable,
            customFields,
            customFieldsChildren,
            lang
        );
        const tableHeaders = GetTableHeaders(sectionName, { precheckinAvailable: precheckinAvailable });
        //Add to store
        dispatch(setLoading(true));
        dispatch(setActions());
        setTimeout(function () {
            dispatch(setHeaders(tableHeaders));
        }, 50);
        dispatch(setTableResults(dataItems));
        dispatch(setCountPosition("table-header"));
        dispatch(
            setCustomConfig({
                pagination: false,
                tableContainerAdjust: "min-h-0	",
            })
        );
        dispatch(showRowForm(false));
        dispatch(setCustomError(t("no companions available")));

        //Sets in row add new form
        dispatch(setFormNewContent(GetTableForm(sectionName)));
        dispatch(setLoading(false));
    };

    //Listeners
    useEffect(() => {
        composeTable();

        return () => {
            dispatch(setCustomConfig(null));
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        composeTable();
        // eslint-disable-next-line
    }, [data]);
};

const arrangeData = (data, rooms, precheckinAvailable, customFields, customFieldsChildren, lang) => {
    const companionsData = [];
    // eslint-disable-next-line
    data.map((companion) => {
        companionsData.push(
            Companion(
                companion,
                rooms,
                { precheckinAvailable: precheckinAvailable },
                customFields,
                customFieldsChildren,
                lang
            )
        );
    });
    return companionsData;
};

export default ListCompanions;
