import { Session } from "../../../Utils/Session";

function NewRole(editData) {
    const name = editData && editData.name ? editData.name : null;
    const roleId = editData && editData.id ? editData.id : null;
    const selectedAccesses = editData && editData.accesses ? editData.accesses : null;

    const HOTEL_ID = Session.getProject()?.id;
    const projects = Session.getProjects();
    const projectData = projects ? projects.filter((project) => parseInt(project.id) === parseInt(HOTEL_ID))[0] : null;
    const rolesQuery = `{
        data: accesses (orderBy:{field:"category",criteria:"asc"}){
            info { count }
            results { id:name name category }
          }
          projectAccesses : permissions (projectCode:"${projectData?.code || ""}" ${
        projectData && projectData.ref === "CORPORATE" ? " ,allChainPermissions:true" : ""
    })
    
        }`;
    return [
        {
            title: name ? [name, "-", "edit-role"] : "add-role",
            bodyAdjust: "w-4/12",
            id: roleId,
            executeSectionQuery: true,
            inputs: [
                {
                    text: ["add-role-desc", ":"],
                    type: "void",
                },
                {
                    text: ["name", "*"],
                    name: "role-name",
                    value: name,
                    type: "text",
                    focus: true,
                },
                {
                    text: ["accesses-text", "*"],
                    name: "role-accesses",
                    type: "selectMultipleWithSearch",
                    optionsQuery: rolesQuery,
                    selectedIdsForOQ: selectedAccesses,
                    selectPlaceHolder: "select-accesses",
                    translateOptions: true,
                    capitalizeOptions: true,
                    roleOptions: true,
                    sort: true,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "save",
                    style: "blue",
                    action: "add-manager-role",
                },
            ],
        },
    ];
}

export default NewRole;
