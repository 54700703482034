import { checkShowTooltip } from "hooks/Utils/Utils";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "components/ReactTooltip";
import { capitalizeFirst } from "hooks/Utils/Utils";

const UseSocialMediaSelector = ({ data, inEdition = false, reset, setDataSelected, validations, cardInEdition }) => {
    const { t } = useTranslation();
    const [optsOpen, setOptsOpen] = useState(null);
    const [socialMedia, setSocialMedia] = useState([]);
    const [showTooltip, setShowTooltip] = useState(false);
    const ref = useRef(null);

    const prefixId = "use-social-media-selector";

    const optionsSocialMedia = [
        { name: `-- ${capitalizeFirst(t("none"))} --`, src: null, key: "NONE" },
        { name: "Instagram", src: require("../../assets/images/icons/instagram.svg"), key: "INSTAGRAM" },
        { name: "X", src: require("../../assets/images/icons/x.svg"), key: "X" },
        { name: "Facebook", src: require("../../assets/images/icons/facebook.svg"), key: "FACEBOOK" },
        { name: "Linkedin", src: require("../../assets/images/icons/linkedin.svg"), key: "LINKEDIN" },
        { name: "Youtube", src: require("../../assets/images/icons/youtube.svg"), key: "YOUTUBE" },
        { name: "Tripadvisor", src: require("../../assets/images/icons/tripadvisor.svg"), key: "TRIPADVISOR" },
        { name: "Pinterest", src: require("../../assets/images/icons/pinterest.svg"), key: "PINTEREST" },
        { name: "Messenger", src: require("../../assets/images/icons/messenger.svg"), key: "MESSENGER" },
        { name: "Tik Tok", src: require("../../assets/images/icons/tiktok.svg"), key: "TIK-TOK" },
        { name: "Link", src: require("../../assets/images/icons/link.svg"), key: "LINK" },
    ];

    const handleSelectChange = (selectedKey, index) => {
        const updatedSocialMedia = [...socialMedia];

        const optionSocialMediaData = optionsSocialMedia.find((opt) => opt.key === selectedKey);

        updatedSocialMedia[index] = {
            ...updatedSocialMedia[index],
            id: selectedKey === "NONE" ? "" : selectedKey.toLowerCase(),
            src: optionSocialMediaData.src,
            url: selectedKey === "NONE" ? "" : updatedSocialMedia[index].url,
        };
        setSocialMedia(updatedSocialMedia);
    };

    const handleURLChange = (newURL, index) => {
        const updatedSocialMedia = [...socialMedia];
        updatedSocialMedia[index] = {
            ...updatedSocialMedia[index],
            url: newURL,
        };
        setSocialMedia(updatedSocialMedia);
    };

    useEffect(() => {
        const preparedData = data.map((item) => {
            let id = item.id.toLowerCase();

            if (id === "web") {
                id = "link";
            } else if (id === "twitter") {
                id = "X";
            } else if (id === "tiktok") {
                id = "tik-tok";
            }

            const foundOption = optionsSocialMedia.find((option) => option.key === id.toUpperCase());

            return {
                ...item,
                id: id,
                src: foundOption ? foundOption.src : "",
            };
        });

        while (preparedData.length < 5) {
            preparedData.push({ id: "", url: "", src: "", __typename: "" });
        }

        setSocialMedia(preparedData);
    }, [data, reset, cardInEdition]);

    useEffect(() => {
        if (typeof setDataSelected === "function") {
            setDataSelected(socialMedia);
        }
    }, [socialMedia]);

    const renderSocialMediaList = () => {
        return socialMedia.map((network, index) => {
            const displayText = network.id ? `${capitalizeFirst(network.id)}` : inEdition ? t("select-an-option") : "";

            return (
                <div
                    ref={ref}
                    key={index}
                    className={`flex items-center mb-2 w-full ${!network.id && !inEdition ? "hidden" : ""}`}
                >
                    <button
                        id={`${prefixId}-main-button-${index}`}
                        onClick={() => {
                            if (inEdition) {
                                setOptsOpen((prevOptsOpen) => (prevOptsOpen === index ? null : index));
                            }
                        }}
                        onBlur={() => {
                            setOptsOpen(null);
                        }}
                        className={`${
                            inEdition ? "bg-gray-200" : "bg-transparent cursor-default"
                        } relative  w-1/3 rounded px-4 py-2 focus:outline-none flex items-center justify-between`}
                    >
                        <div className=" flex items-center overflow-hidden">
                            {network.id ? (
                                <img id={`${prefixId}-image-${index}`} className=" mr-4" src={network.src}></img>
                            ) : null}
                            <div
                                id={`${prefixId}-text-button-${index}`}
                                className={` truncate ${!network.id ? " text-gray-600" : ""}`}
                            >
                                {displayText}
                            </div>
                        </div>
                        {inEdition ? <i className={` icon icon-chevron${optsOpen === index ? "-up" : ""}`}></i> : null}
                        {inEdition && optsOpen === index && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: "2.5rem",
                                    left: "0rem",
                                    maxHeight: "20rem",
                                    overflowY: "scroll",
                                }}
                                id={`${prefixId}-container-options-${index}`}
                                className=" t-options-container z-20 w-full"
                            >
                                {optionsSocialMedia.map((option) => (
                                    <div
                                        key={option.key}
                                        id={`${prefixId}-container-options-name-${option.key.toLowerCase()}-${index}`}
                                        onClick={(e) => {
                                            handleSelectChange(option.key, index);
                                        }}
                                        className="  flex items-center cursor-pointer p-2 hover:bg-gray-200"
                                    >
                                        {option.key !== "NONE" ? (
                                            <div
                                                style={{ minHeight: "1.5rem", minWidth: "1.5rem" }}
                                                className=" bg-gray-200 border border-gray-300 mr-2 p-2 rounded"
                                            >
                                                <img
                                                    id={`${prefixId}-container-options-image-${option.key.toLowerCase()}-${index}`}
                                                    src={option.src}
                                                ></img>
                                            </div>
                                        ) : null}
                                        <div
                                            id={`${prefixId}-container-options-name-${option.key.toLowerCase()}-${index}`}
                                            className=" truncate"
                                        >
                                            {option.name}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </button>
                    {inEdition && (
                        <input
                            type="text"
                            value={network.url || ""}
                            id={`${prefixId}-url-input-${index}`}
                            onChange={(e) => handleURLChange(e.target.value, index)}
                            className={` border border-transparent ${network.id ? "bg-gray-200" : " bg-gray-300"} ${
                                validations[index] ? "border-red-500" : ""
                            } ml-4 w-2/3 p-2 border rounded`}
                            disabled={network.id ? false : true}
                            placeholder="https://"
                        />
                    )}
                    {!inEdition ? (
                        <div className=" w-2/3 flex  items-center">
                            <div
                                onMouseOver={(e) => {
                                    checkShowTooltip(e.currentTarget, setShowTooltip);
                                    ReactTooltip.rebuild();
                                }}
                                id={`${prefixId}-url-value-${index}`}
                                data-tip={showTooltip ? network.url : ""}
                                className=" truncate"
                            >
                                {network.url || ""}
                            </div>
                        </div>
                    ) : null}
                </div>
            );
        });
    };

    return <div>{renderSocialMediaList()}</div>;
};

export default UseSocialMediaSelector;
