import { useMutation } from "react-apollo";
import { CREATE_ROOM } from "../utils/topologyQueries";
import { useContext } from "react";
import { TopologyContext } from "contexts/Topology";
import { toast } from "react-toastify";

export const useCreateRoom = () => {
    const { setLoadingRefresh } = useContext(TopologyContext);
    const [createRoom] = useMutation(CREATE_ROOM);

    const createRoomMutation = (floorID, name, number, pmsNumber, locations, tvsPerRoom) => {
        const filteredLocations = locations.map((location) => {
            const { id, ...rest } = location;
            return rest;
        });
        createRoom({
            variables: {
                floorID,
                name,
                number,
                pmsNumber,
                locations: filteredLocations,
                tvsPerRoom,
            },
        })
            .then((response) => {
                if (response.data.createRoom.ok) {
                    setLoadingRefresh(true);
                }
                return response;
            })
            .catch((error) => {
                toast.error(error?.graphQLErrors[0]?.message);
            });
    };

    return { createRoomMutation };
};
