import React from "react";

import UseSwitch from "components/Inputs/useSwitch";
import Icon from "components/Icon";
import Button from "components/Button";

/**
 * @name SettingsCard.Section
 * @description This component is a section of a card that can have a title, a description, and an edit button or a toggle button
 * @component
 * @param {string} id The id of the element
 * @param {string} title The title of the section
 * @param {string} subtitle The subtitle of the section
 * @param {string} description The description of the section
 * @param {object} edit The edit button of the section
 * @param {object} toggle The toggle button of the section
 * @param {object} collapseButton The collapse button of the section
 * @param {JSX.Element} children The content of the section
 * @param {string} adjustBox Styles to adjust the the box containing the title, toggle, and edit options.
 * @param {string} className The class name of the section
 * @returns {JSX.Element} The UI of the component
 * @example <Section title="Section Title" description="This is the description of the section">Section Content here</Section>
 */
const Section = ({
    children,
    id,
    title,
    description,
    edit,
    toggle,
    titleClassName,
    className,
    subtitle,
    collapseButton,
}) => {
    return (
        <div className={className || "mb-2"} key={title}>
            {title || edit || toggle ? (
                <div
                    className={`flex justify-between items-center px-4 py-1 bg-gray-100 mb-2 rounded ${
                        titleClassName || ""
                    }`}
                >
                    <div className="flex items-center">
                        {collapseButton ? (
                            <button
                                id={id ? `${id}-collapse-button-section-component` : null}
                                onClick={() => {
                                    if (collapseButton?.action) {
                                        collapseButton.action();
                                    }
                                }}
                                className={` mr-2 text-gray-800 icon-chevron${collapseButton?.open ? "-up" : ""}`}
                            ></button>
                        ) : null}
                        {title ? <h4 className="font-bold">{title}</h4> : null}{" "}
                        {subtitle ? <h5 className=" pl-3 text-gray-500">{subtitle}</h5> : null}
                    </div>
                    {edit || toggle ? (
                        <div className="flex items-center space-x-5 text-gray-800">
                            {toggle ? (
                                <UseSwitch
                                    id={toggle.id || `${id}-toggle-button`}
                                    disabled={toggle.disabled}
                                    checked={toggle.checked}
                                    action={toggle.action}
                                    label={toggle.label}
                                    adjust="flex items-center"
                                />
                            ) : null}
                            {edit ? (
                                <Button
                                    id={edit.id || `${id}-edit-button`}
                                    design="link"
                                    disabled={edit.disabled}
                                    onClick={edit.onClick}
                                >
                                    <Icon type={edit.icon || "edit"} size="xl" />
                                </Button>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            ) : null}

            {description ? (
                <div id={id ? `${id}-description` : null} className="px-4 py-1">
                    {description}
                </div>
            ) : null}
            {children}
        </div>
    );
};

export default Section;
