export const CalcPages = (maxPage, currentPage) => {
    //Calculate available pages
    let pages = [];
    const indexToShow = 7; //Should be odd number
    //There´s room for all pages...
    if (maxPage <= indexToShow) {
        for (let i = 1; i <= maxPage; i++) {
            pages.push(i);
        }
    } else {
        let indexesToFill = indexToShow - 3;
        //needs start etc
        if (currentPage - indexesToFill / 2 > 1) {
            pages.push(1, "...");
            indexesToFill = indexesToFill - 2;
            //central indexes
            for (let k = currentPage - indexesToFill / 2; pages.length <= indexToShow - 3 && k <= maxPage; k++) {
                pages.push(k);
            }
        } else {
            //central indexes
            if (currentPage - indexesToFill / 2 <= 1) {
                for (let j = 1; pages.length <= indexesToFill; j++) {
                    pages.push(j);
                }
            }
        }
        if (currentPage + indexesToFill < maxPage) {
            pages.push("...", maxPage);
        } else {
            for (let m = pages[pages.length - 1] + 1; m <= maxPage; m++) {
                pages.push(m);
            }
        }
    }
    return pages;
};
