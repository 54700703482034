import React, { Children } from "react";

const UseSettings = ({ children, visible = true, toolbarID, widgetTypeID, type = null }) => {
    return (
        <div id={widgetTypeID} className={visible ? "" : "hidden"} data-type={type}>
            {Children.toArray(children).map((ch) => {
                const isReactElement = ch && typeof ch.type === "function";
                return React.cloneElement(
                    ch,
                    !isReactElement
                        ? ch.props
                        : {
                              toolbarID: toolbarID,
                              ...ch.props,
                          }
                );
            })}
        </div>
    );
};

export default UseSettings;
