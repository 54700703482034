import { Session } from "../../../Utils/Session";

const NewInputGroup = (data, noRestore, inEdition) => {
    let groupName = data?.["input-group-name"];
    const prepareDataToRestore = (editionData) => {
        const inputGroup = editionData.info.find((info) => info.cellContent === "data")?.inputGroup;
        const inputs = inputGroup?.inputs;
        let dataToRestore = { values: {} };
        if (inputGroup?.name) {
            dataToRestore.values[`input-group-name`] = inputGroup.name;
            groupName = inputGroup.name;
        }
        if (inputs) {
            dataToRestore.values["inputs-number"] = inputs.length;

            inputs.forEach((input, index) => {
                const iconData = input.icon ? input.icon.match(/([a-z]*)-(.*)$/) : null;

                dataToRestore.values[`input-${index}-name`] = input.name;
                dataToRestore.values[`input-${index}-type`] = [input.input];
                dataToRestore.values[`input-${index}-device`] = input.deviceType ? [input.deviceType] : null;
                dataToRestore.values[`input-${index}-icon`] = iconData ? { lib: iconData[1], icon: iconData[2] } : null;
                dataToRestore.values[`input-${index}-image`] = input.imageRef;
            });

            Session.setSessionProp("actionDataToRestore", dataToRestore);
        }
    };

    if (noRestore) {
        Session.removeSessionProp("actionDataToRestore");
        if (data.editionData) {
            prepareDataToRestore(data.editionData);
        }
    }

    const cancelAction = () => {
        Session.removeSessionProp("actionDataToRestore");
    };

    return {
        title: inEdition ? "edit" : "new-input-group",
        value: inEdition || "",
        bodyAdjust: "w-8/12",
        executeSectionQuery: true,
        beforeModalOpen: function () {
            Session.removeSessionProp("actionDataToRestore");
        },
        inputs: [
            {
                type: "text",
                value: groupName,
                focus: true,
                name: "input-group-name",
                cStyle: "w-5/12",
                text: ["input-group-name", " ", "* "],
            },
            {
                type: "inputsManagement",
                data: data,
            },
        ],
        buttons: [
            { name: "cancel", style: "white", actionCallback: () => cancelAction(), action: "close" },
            {
                name: inEdition ? "save" : "create",
                style: "blue",
                action: inEdition ? "edit-input-group" : "create-new-input-group",
            },
        ],
    };
};

export default NewInputGroup;
