import React from "react";
import UseCustomPhoneInput from "components/Inputs/UseCustomPhoneInput";

const UseEditPhoneNumber = ({ cellData, colIndex, rowIndex, depth, subIndex }) => {
    return (
        <>
            <UseCustomPhoneInput
                value={cellData.value && cellData.value != "-" ? cellData.value : ""}
                fieldActionValues={"mobile-phone"}
                actionValues={true}
            ></UseCustomPhoneInput>
        </>
    );
};

export default UseEditPhoneNumber;
