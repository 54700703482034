export const CZAreasActivities = [
    "accept",
    "active",
    "activity",
    "add",
    "add-new-email",
    "area",
    "areas",
    "areas-activities",
    "area-edit-description",
    "area-erase",
    "area-move-category",
    "email-booking-notification",
    "insert-email-booking-notification",
    "area-open",
    "area-temp-close",
    "area-copy-link",
    "area-see-link",
    "available",
    "bookings",
    "booking-admission",
    "error-booking-allow",
    "booking-admit",
    "booking-allowed",
    "confirmation-mode",
    "booking-notallowed",
    "booking-number",
    "capacity-limit",
    "cancel",
    "categories",
    "category",
    "chars",
    "close-comment",
    "common-schedule",
    "confirm-area-deletion",
    "date-range",
    "date-specific",
    "day-1",
    "day-2",
    "day-3",
    "day-4",
    "day-5",
    "day-6",
    "day-7",
    "day-1-short",
    "day-2-short",
    "day-3-short",
    "day-4-short",
    "day-5-short",
    "day-6-short",
    "day-7-short",
    "description",
    "disabled",
    "each-15-min",
    "each-30-min",
    "each-hour",
    "each-custom",
    "edit",
    "enter-area-description",
    "enter-capacity-limit",
    "erase",
    "items",
    "manage-bookings",
    "minutes",
    "name",
    "next",
    "newa",
    "no",
    "of",
    "or",
    "people",
    "people-number",
    "periodic-event-title",
    "punctual-event-title",
    "please",
    "previous",
    "results",
    "save",
    "schedule",
    "search",
    "select-booking-freq",
    "select-booking-duration",
    "select-if",
    "select-move-category",
    "showing-all",
    "showing-all-results",
    "temp-closed",
    "theres-not",
    "unspecified",
    "view-schedules",
    "write-name",
    "yes",
    "error-select-category",
    "error-capacity-limit",
    "error-description",
    "visible-to-customer",
    "hidden",
    "manual",
    "automatic",
    "create-new-schedule",
    "area-empty-reopen-question",
    "area-empty-reopen-description",
    "common-schedules",
    "special-schedules",
    "closed-dayss",
];
