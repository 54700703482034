import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { setDesignToolbarVisible, setItemSelected } from "../../../../actions/designActions";
//Toolbars
import { setSectionStyle } from "../../../../actions/uiActions";
import {
    LOGOS_TOOLBAR,
    TYPOGRAPHY_TOOLBAR,
    COLOUR_TOOLBAR,
    THEME_TYPE_DESIGN,
} from "../../../../hooks/Utils/AppearancesUtils";
import AppearancesToolbarLoadSidebar from "./AppearancesToolbarLoadSidebar ";
import AppearancesListSidebar from "./Sidebar/AppearancesListSidebar";

const AppearancesToolbar = ({
    visibilityAL,
    setVisibilityAL,
    themeType,
    setThemeType,
    setLoadingListAppearances,
    loadingListAppearances,
    loadingAppearance,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [blocked, setBlocked] = useState(false);
    const [blockedLogos, setBlockedLogos] = useState(false);
    const { designToolbarVisible } = useSelector((state) => state.design);
    const { appearanceSelected, appearanceId } = useSelector((state) => state.sectionContent);

    const lockCloseIcon = () => <span className="icon-lock-close text-3xl absolute left-0 ml-12 mt-8"></span>;

    useEffect(() => {
        if (designToolbarVisible) {
            setWidthSubMenus(designToolbarVisible);
        } else {
            setWidthSubMenus();
        }
    }, [designToolbarVisible]);

    useEffect(() => {
        if (appearanceSelected && appearanceSelected.locks) {
            setLocks();
        }
        return () => {
            dispatch(setDesignToolbarVisible());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (appearanceSelected && appearanceSelected.locks) {
            setLocks();
        }
        // eslint-disable-next-line
    }, [appearanceSelected]);

    const setLocks = () => {
        if (appearanceSelected.locks.LOGOS_TOOLBAR) {
            setBlockedLogos(true);
        } else {
            setBlockedLogos(false);
        }
        if (appearanceSelected.locks.TYPOGRAPHY_TOOLBAR && appearanceSelected.locks.COLOUR_TOOLBAR) {
            setBlocked(true);
        } else {
            setBlocked(false);
        }
    };

    const setWidthSubMenus = (option) => {
        // recuperar todos los divs
        const appearancesDiv = document.querySelector(`#appearances_toolbar`);
        const logosDiv = document.querySelector(`#logos_toolbar`);
        const typographyDiv = document.querySelector(`#typography_toolbar`);
        const colourDiv = document.querySelector(`#colour_toolbar`);
        //no divs in document
        if (!appearancesDiv || !logosDiv || !typographyDiv || !colourDiv) {
            return;
        }
        //from start all widths 0px
        appearancesDiv.style.width = "0px";
        logosDiv.style.width = "0px";
        typographyDiv.style.width = "0px";
        colourDiv.style.width = "0px";
        //if option defined set width to 24rem
        if (option) {
            document.querySelector(`#${option}_toolbar`).style.width = "29rem";
        }
    };

    const setVisible = (item) => {
        if (!appearanceId) {
            return;
        }
        dispatch(setDesignToolbarVisible(item));
        dispatch(setItemSelected(null));
    };

    useEffect(() => {
        setVisible(COLOUR_TOOLBAR);
        dispatch(setSectionStyle("w-full  absolute px-0 py-0"));
        return () => {
            dispatch(setSectionStyle(null));
        };
        // eslint-disable-next-line
    }, []);

    //TODO remove this hack when the layout is done correctly
    const top = document.getElementById("appearancesHeadMenu")?.getBoundingClientRect().bottom || 0;

    return (
        <>
            <div
                className={`fixed p-6 flex justify-start items-center bg-white text-gray-800 overflow-hidden ${
                    visibilityAL ? "w-80" : "w-0"
                }`}
                style={{
                    top,
                    height: "5.5rem",
                }}
            >
                <span className="icon icon-appearances text-3xl mr-2"></span>
                <p className="first-capital font-bold ">{t("appearances")}</p>
            </div>
            <div
                id="containerAl"
                style={{ height: "90%", top: "calc( " + top + "px + 5.5rem )" }}
                className={` fixed bg-white border ${visibilityAL ? " w-80" : "w-0"}`}
            >
                <AppearancesListSidebar
                    setLoadingListAppearances={setLoadingListAppearances}
                    loadingListAppearances={loadingListAppearances}
                    setThemeType={setThemeType}
                    themeType={themeType}
                />
            </div>
            <div
                id="appearancesToolbarContainer"
                style={{ top }}
                className={`fixed ${
                    visibilityAL ? " ml-80" : "ml-0"
                } flex flex-col h-full bg-white z-0 border-r border-gray-200`}
            >
                <div className={`border-b border-gray-200 inline-block py-0 cursor-pointer sidebar-submenu-link`}>
                    <div
                        className={`icon p-6 text-3xl`}
                        id={`appearances-toolbar-show-menu`}
                        data-tip={`${visibilityAL ? t("hide-appearances-list") : t("show-appearances-list")}`}
                        onClick={(e) => {
                            setVisibilityAL((prev) => !prev);
                        }}
                    >
                        <span className={`${visibilityAL ? "icon-arrow-hide" : "icon-arrow-show"}`}></span>
                    </div>
                </div>
                <div
                    id="appearancesToolbar"
                    className="grid grid-cols-1 float-left"
                    style={{ position: "absolute", top: "5.5rem" }}
                >
                    <div
                        data-tip={t("colors-first-mayus")}
                        className={`border-b border-gray-200 inline-block sidebar-submenu-link py-0 ${
                            designToolbarVisible === COLOUR_TOOLBAR ? "text-zafiro-600  bg-gray-100" : ""
                        }`}
                    >
                        <div
                            id={`appearances-toolbar-color-toolbar`}
                            className={`icon p-6 text-3xl `}
                            onClick={(e) => setVisible(COLOUR_TOOLBAR)}
                        >
                            <span className="icon-color"></span>
                            {blocked && lockCloseIcon()}
                        </div>
                    </div>
                    {themeType === THEME_TYPE_DESIGN ? (
                        <div
                            data-tip={t("typography")}
                            className={`border-b border-gray-200 inline-block sidebar-submenu-link py-0 ${
                                designToolbarVisible === TYPOGRAPHY_TOOLBAR ? "text-zafiro-600  bg-gray-100" : ""
                            }`}
                        >
                            <div
                                className={`icon p-6 text-3xl `}
                                id={`appearances-toolbar-typography-toolbar`}
                                onClick={(e) => setVisible(TYPOGRAPHY_TOOLBAR)}
                            >
                                <span className="icon-typography"></span>
                                {blocked && lockCloseIcon()}
                            </div>
                        </div>
                    ) : null}
                    <div
                        data-tip="Logos"
                        className={`border-b border-gray-200 inline-block sidebar-submenu-link py-0 ${
                            designToolbarVisible === LOGOS_TOOLBAR ? "text-zafiro-600  bg-gray-100" : ""
                        }`}
                    >
                        <div
                            id={`appearances-toolbar-logos-toolbar`}
                            className={`icon p-6 text-3xl `}
                            onClick={(e) => setVisible(LOGOS_TOOLBAR)}
                        >
                            <span className="icon-logo"></span>
                            {blockedLogos && lockCloseIcon()}
                        </div>
                    </div>
                </div>
                <AppearancesToolbarLoadSidebar themeType={themeType} loadingAppearance={loadingAppearance} />
            </div>
        </>
    );
};

export default AppearancesToolbar;
