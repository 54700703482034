import React from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table/index";
//Utils
import ListBookingCategory from "../../../hooks/GraphqlCalls/CommonZones/ListBookingCategory";

const BookingCategory = () => {
    ListBookingCategory();

    return (
        <>
            <UseSectionHeader title={["bookings", "of"]} breadcrumbsLimit={2} navToPreviousPage={true} />
            <UseTable />
        </>
    );
};

export default BookingCategory;
