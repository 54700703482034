import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { allergensPrefix, getAllergens } from "hooks/Utils/SalesUtils";
import { executeVendureQuery, useVendureAPI } from "hooks/Utils/Integrations/useVendure";
import UseComponentLoading from "../../../useComponentLoading";
import { setRefreshContentData } from "actions/sectionActions";
import { setLoading } from "actions/tableActions";
import useModalsAllergensTranslate from "./modals/useModalsAllergensTranslate";

import { useAuth } from "hooks/Session/auth";

function GeneralSettingsAllergensCard() {
    const { t } = useTranslation();
    const { chain, project } = useAuth();

    const { adminApi } = useVendureAPI();
    const dispatch = useDispatch();
    const { refreshData } = useSelector((state) => state.sectionContent);
    const { languages: projectLangs } = useAuth();
    const projectRef = project?.ref;
    const { loading: loadingTable } = useSelector((state) => state.table);
    const [facetId, setFacetId] = useState(null);
    const [facetValues, setFacetValues] = useState(null);
    const [loading, setLoadingData] = useState(true);
    const allergens = getAllergens();

    const { open: openModalAllergensTranslate } = useModalsAllergensTranslate();

    const GET_ALLRGEN_FACET = `{
        facets(options:{
            filter:{code:{eq:"${allergensPrefix(chain?.ref)}"}}
        }){
            items{
                id
                values{
                    id
                    code
                    translations{
                        id
                        languageCode
                        name
                    }
                }
            }
        }
    }`;

    useEffect(() => {
        refetch();
        dispatch(setLoading(false));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            refetch();
            dispatch(setRefreshContentData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    const refetch = async () => {
        const response = await executeVendureQuery(adminApi, { queryBody: GET_ALLRGEN_FACET });
        if (response && response.data) {
            const { facetId, facetValues } = arrangeData(response.data);
            setFacetId(facetId);
            setFacetValues(facetValues);
        } else {
            toast.error(t("mutation-error"));
        }
        setLoadingData(false);
    };

    const arrangeData = (data) => {
        let facetId = null;
        let facetValues = [];
        if (data && data.facets && data.facets.items && data.facets.items.length > 0) {
            let facet = data.facets.items[0];
            facetId = facet.id ? facet.id : null;
            facetValues = facet.values ? facet.values : [];
        }
        return { facetId, facetValues };
    };

    const arrangeDataLang = (projectLangs) => {
        const response = [];
        if (projectLangs && projectLangs.length > 0) {
            const languageRefs = projectLangs.map((projectLang) => projectLang.languageRef);
            const filtered = projectLangs.filter(
                ({ languageRef }, index) => !languageRefs.includes(languageRef, index + 1)
            );
            // eslint-disable-next-line
            filtered.map((projectLang) => {
                const name = t("language:" + projectLang.languageRef);
                response.push({
                    id: projectLang.languageRef,
                    name: name || projectLang.languageRef,
                    isDefault: projectLang.isDefault,
                });
            });
        }
        return response;
    };

    const handleTranslate = () => {
        if (!loadingTable) {
            openModalAllergensTranslate({
                name: "translations",
                data: {
                    allergens: allergens,
                    facetValues: facetValues,
                    projectLangs: arrangeDataLang(projectLangs),
                    projectRef: projectRef,
                    facetId: facetId,
                },
            });
        }
    };

    return (
        <div className="w-full">
            {loading && !facetId ? (
                <UseComponentLoading />
            ) : (
                <>
                    <div class="flex items-center w-full justify-between">
                        <span class="font-bold text-gray-900 text-2xl">{t("allergens")}</span>
                        <span
                            class={` text-lg ${!loadingTable ? "text-zafiro-600 cursor-pointer" : "cursor-default"}`}
                            onClick={handleTranslate}
                        >
                            {t("translate")}
                        </span>
                    </div>
                    <div className="w-full text-left mt-20 mb-2">
                        {allergens &&
                            allergens.map((allergen) => (
                                <div className="w-3/12 inline-block mb-20 align-top">
                                    <div className="w-full text-center">
                                        <span
                                            className={`text-5xl icon ${allergen.icon} block`}
                                            style={{ color: allergen.icon_color }}
                                        ></span>
                                        <span className="block mt-5">{t(allergen.label)}</span>
                                    </div>
                                </div>
                            ))}
                    </div>
                </>
            )}
        </div>
    );
}

export default GeneralSettingsAllergensCard;
