import React, { useContext } from "react";
import { TopologyContext } from "contexts/Topology";
import Button from "components/Button";
import Icon from "components/Icon";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const EditRoom = ({ room }) => {
    const { setEditing, creating } = useContext(TopologyContext);
    const { t } = useTranslation();
    return (
        <Button
            id={`edit-btn-${room?.id}`}
            onClick={() => {
                if (!creating) {
                    setEditing({ editing: true, id: room?.id });
                } else {
                    toast.error(t("save-before-continue"));
                }
            }}
        >
            <Icon type="edit" size="xl" />
        </Button>
    );
};
