import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useLocation, useNavigate as useHistory } from "react-router-dom";

import { useAuth } from "hooks/Session/auth";
import { usePasswordChange } from "sections/login/changepass";

import Dropdown from "./Dropdown";
import Button from "components/Button";
import Icon from "components/Icon";

const AVAILABLE_LANGUAGES = ["es", "en"];

const UserMenu = () => {
    const userDropdownRef = useRef(null);
    const userMenuRef = useRef(null);

    const { userInitials, isLogged } = useAuth();

    if (!isLogged) {
        return null;
    }

    return (
        <Dropdown
            ref={userDropdownRef}
            id="user-menu"
            showArrow={false}
            handler={<UserGlobe>{userInitials}</UserGlobe>}
            float={true}
            onOpenChange={(open) => {
                if (!open && userMenuRef.current) {
                    userMenuRef.current.close();
                }
            }}
            maxWidth="20rem"
        >
            <Body ref={userMenuRef} dropdownRef={userDropdownRef} />
        </Dropdown>
    );
};

const Body = forwardRef(({ dropdownRef }, ref) => {
    const userMenuRef = useRef(null);

    const { lang, setLang } = useAuth();

    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const { user, userInitials, isLogged } = useAuth();
    const { open: openPasswordChange } = usePasswordChange({ loggedIn: user?.email });

    const [selectLanguage, setSelectLanguage] = useState(false);
    const [language, setLanguage] = useState(lang);

    useImperativeHandle(ref, () => ({
        close: () => {
            setSelectLanguage(false);
        },
    }));

    useEffect(() => {
        if (language !== lang) {
            setLang(language);
            toast.success(t("language changed to x", { name: t("language:" + language) }));
            if (dropdownRef?.current) {
                dropdownRef.current.close();
            }
        }
    }, [language]);

    if (!isLogged) {
        return null;
    }

    const isSelectProfileSection = location?.pathname?.includes("select-profile");

    const menu = (
        selectLanguage
            ? AVAILABLE_LANGUAGES.map((code) => ({
                  id: `select-lang-${code}`,
                  label: t(`language:${code}`),
                  selected: lang === code,
                  onClick: () => setLanguage(code),
              }))
            : [
                  {
                      id: "select-lang",
                      label: `${t("lang")}: ${t("language:" + lang)}`,
                      submenu: true,
                      onClick: () => setSelectLanguage(true),
                  },
                  {
                      id: "change-password",
                      label: t("change-password"),
                      onClick: () => {
                          openPasswordChange();
                          if (dropdownRef?.current) {
                              dropdownRef.current.close();
                          }
                      },
                  },
                  user?.superUser && !isSelectProfileSection
                      ? {
                            id: "change-profile",
                            label: t("change profile"),
                            onClick: () => {
                                history("/select-profile");
                            },
                        }
                      : null,
                  {
                      id: "close-session",
                      label: t("close-session"),
                      onClick: () => {
                          history("/logout");
                      },
                  },
              ]
    ).filter((m) => m);

    const dropdownClass = classNames({
        "mt-3 text-gray-900 bg-white shadow-lg rounded-md": true,
        "select-none text-left border border-gray-100 ": true,
    });

    const topClass = classNames({
        "w-full flex items-center space-x-2 border-b border-gray-200": true,
        "p-5": !selectLanguage,
    });

    const dropdownItemClass = classNames({
        "py-4 font-normal block w-full whitespace-no-wrap cursor-pointer text-gray-800": true,
        "flex justify-between items-center": true,
        "hover:bg-gray-200": true,
        "focus:text-zafiro-600 focus:bg-gray-100": true,
        "px-4": !selectLanguage,
        "pl-12 pr-4": selectLanguage,
    });

    const backButtonClass = classNames({
        "flex justify-between items-center w-full": true,
        "py-3 px-4 space-x-4 hover:bg-gray-200 focus:text-zafiro-600 focus:bg-gray-100": true,
    });

    return (
        <div className={dropdownClass}>
            <div className={topClass}>
                {!selectLanguage ? (
                    <>
                        <UserGlobe size="xl">{userInitials}</UserGlobe>
                        <div className="overflow-auto">
                            <div
                                title={user?.fullname}
                                className="font-bold whitespace-no-wrap overflow-ellipsis overflow-hidden"
                            >
                                {user?.fullname}
                            </div>
                            <div className="text-gray-500 truncate">{user?.email}</div>
                        </div>
                    </>
                ) : (
                    <Button className={backButtonClass} id="select-lang-back" onClick={() => setSelectLanguage(false)}>
                        <Icon type="chevron-left" />
                        <div className="font-bold">{t("select-lang")}</div>
                    </Button>
                )}
            </div>
            <div>
                {menu.map((item, index) => {
                    const id = `user-menu-${item?.id || index + 1}`;
                    return (
                        <Button
                            id={id}
                            key={id}
                            className={dropdownItemClass}
                            onClick={(e) => {
                                if (e) {
                                    e.stopPropagation();
                                }
                                if (item?.onClick) {
                                    item.onClick(e);
                                }
                                if (userMenuRef.current) {
                                    userMenuRef.current.close();
                                }
                            }}
                        >
                            <div>{item?.label}</div>
                            {item?.selected ? <Icon type="tick-right" /> : null}
                            {item?.submenu ? <Icon type="chevron-right" /> : null}
                        </Button>
                    );
                })}
            </div>
        </div>
    );
});

const UserGlobe = ({ children, size }) => {
    const globeClass = classNames({
        "flex items-center justify-center": true,
        "rounded-full leading-none tracking-tighter px-1": true,
        "bg-blue-200 text-white": true,
    });

    const remSize = size === "xl" ? 4 : 3;

    return (
        <div
            className={globeClass}
            style={{
                minWidth: `${remSize}rem`,
                minHeight: `${remSize}rem`,
            }}
        >
            {children}
        </div>
    );
};

export default UserMenu;
