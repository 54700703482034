import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-apollo";
import { setExecuteQuery } from "actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "actions/uiActions";
import { useNavigate as useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { setRefreshContentData } from "actions/sectionActions";
import { gql } from "apollo-boost";
import { useTranslation } from "react-i18next";
import { removeApolloCacheKeys } from "hooks/Utils/Utils";
import { setRefreshData } from "actions/tableActions";
import { cleanAction } from "actions/globalActions";

import { useLoading } from "components/Loading";
import { useParams } from "react-router-dom";
import { CREATE_MOBILE_APP, DELETE_MOBILE_APP, DELETE_MOBILE_APPS } from "../constants";
import { Session } from "hooks/Utils/Session";

const UpdateMobileApps = ({ client }) => {
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { checkedItems } = useSelector((state) => state.table);
    const { setLoading } = useLoading();
    const { id } = useParams();
    const values = useSelector((state) => state.action.values);
    const { t } = useTranslation();
    const history = useHistory();
    const [mutation, setMutation] = useState(`
    mutation {
        createHotspot(name: "test") {
          ok
          error
          id
        }
      }`);

    const dispatch = useDispatch();

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));

            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));

            setLoading(false);
            toast.error(err.message);
        },
    });

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            executeQuery.multipleMutations = [];
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.redirectOnSuccessUrl = null;
            execute = true;
            const dataParams = executeQuery.params ? executeQuery.params.data : {};
            switch (executeQuery.action) {
                case DELETE_MOBILE_APP:
                    setMutation(`mutation {
                        deleteMobileApp(id: ${dataParams?.id}) {
                            error
                            id
                            ok
                        }
                        }`);
                    setLoading(true);
                    executeQuery.multipleMutations.push("deleteMobileApp");
                    break;
                case DELETE_MOBILE_APPS:
                    const mobileAppsResults = Session.getSessionProp("mobileApps")
                        ? JSON.parse(Session.getSessionProp("mobileApps"))
                        : [];

                    const filteredIds = checkedItems.filter((id) => {
                        const item = mobileAppsResults.find((d) => d.id === id);
                        return item && item.isMultiProperty;
                    });

                    setMutation(`mutation{
                        deleteMobileApps(
                            ids:[${filteredIds.join(",")}]
                        ){
                            error
                            id
                            ok
                        }
                        }`);
                    executeQuery.multipleMutations.push("deleteMobileApps");
                    setLoading(true);
                    break;
                case CREATE_MOBILE_APP:
                    setMutation(`mutation{
                        createMobileApp(
                            url:"${dataParams?.url}"
                            projectRefs:[${
                                dataParams?.refMono
                                    ? `"${dataParams?.refMono}"`
                                    : dataParams.propertiesChecked.map((ref) => `"${ref}"`).join(",")
                            }]
                            name:"${dataParams?.nameApp}"
                            shortName:"${dataParams?.nameApp}"
                        ){
                            error
                            id
                            ok
                        }
                    }`);
                    setLoading(true);
                    executeQuery.multipleMutations.push("createMobileApp");
                    break;
                default:
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (!showGlobalLoading) dispatch(setLoading(true));
            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        const arrayData = data ? Object.entries(data).map(([key, value]) => ({ key, ...value })) : [];

        const hasMultipleMutationsAtLeastOneOk = executeQuery?.multipleMutations
            ? executeQuery?.multipleMutations.some((key) => {
                  return arrayData.find((item) => item.key === key && item.ok);
              })
            : false;

        const errorMessages = arrayData
            .filter((item) => item.error && item.error.trim() !== "")
            .map((item) => item.error);

        errorMessages.forEach((message) => {
            return toast.error(message);
        });

        if (arrayData.length > 0 && executeQuery && hasMultipleMutationsAtLeastOneOk) {
            toast.success(t("operation-successful"));
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }

            setLoading(false);
            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default UpdateMobileApps;
