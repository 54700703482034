import React, { useEffect, useRef, useContext, useState } from "react";
import { DEVICE } from "constants/editor";
import { useTranslation } from "react-i18next";
import UseSwitch from "../../../Inputs/useSwitch";
import { useDispatch, useSelector } from "react-redux";
import { setSectionContent } from "../../../../actions/sectionActions";
import UseSelectWithSearch from "../../../useSelectWithSearch";
import UseAddImage from "../../../Inputs/UseAddImage";
import { capitalizeT } from "../../../../hooks/Utils/Utils";
import { EditorContext } from "../../../../contexts/editor";
import ReactTooltip from "react-tooltip";

const SalesGeneralSettings = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { background, title, text, ordersButton } = useSelector((state) => state.sectionContent);
    const { fonts, permissions } = useSelector((state) => state.ui);
    const { deviceType } = useContext(EditorContext);

    const hasMyOrders = permissions?.services?.myOrders;

    const isTV = deviceType === DEVICE.TYPE.TV;

    const useHomeBg = useRef();
    useHomeBg.current = background.useHomeBg;

    const useOrdersButton = useRef();
    useOrdersButton.current = ordersButton ? true : false;

    const { titleFont, textFont } = useSelector((state) => state.action.values);
    const [fontsData, setFontsData] = useState();

    // DEFAULT_FONT_NAME

    useEffect(() => {
        if (fonts?.fontList) {
            setFontsData(arrangeFontsData());
        }
        // eslint-disable-next-line
    }, []);

    // TO STORE FONT DATA IN SECTIONCONTENT
    useEffect(() => {
        if (fontsData) {
            const _fontToStore = fontsData[titleFont];
            if (_fontToStore && _fontToStore.name !== title.name) {
                dispatch(
                    setSectionContent({
                        title: fonts.fontList.filter((elements) => elements.name === _fontToStore.name)[0],
                    })
                );
            }
        }
        // eslint-disable-next-line
    }, [titleFont]);

    useEffect(() => {
        if (fontsData) {
            const _fontToStore = fontsData[textFont];
            if (_fontToStore && _fontToStore.name !== text.name) {
                dispatch(
                    setSectionContent({
                        text: fonts.fontList.filter((elements) => elements.name === _fontToStore.name)[0],
                    })
                );
            }
        }
        // eslint-disable-next-line
    }, [textFont]);

    useEffect(() => {
        if (background?.libraryRef) {
            dispatchSectionContentValue(background.libraryRef);
        } else if (background?.externalUrl) {
            dispatchSectionContentValue(background.externalUrl);
        } else {
            dispatchSectionContentValue();
        }
        // eslint-disable-next-line
    }, [background]);

    const dispatchSectionContentValue = (value) => {
        dispatch(
            setSectionContent({
                styleInputData: {
                    generalSalesSave: true,
                    value,
                },
            })
        );
    };

    const arrangeFontsData = () => {
        let _fontsData = [];
        fonts.fontList.forEach((element, index) => {
            _fontsData.push({ id: index, name: element.name, selected: false, visible: true });
        });
        return _fontsData;
    };
    const setSelectedFontsData = (font) => {
        return fontsData
            ? fontsData.map((element) => {
                  return { ...element, selected: font.name === element.name };
              })
            : null;
    };

    const handleSwitchOnOff = () => {
        dispatch(setSectionContent({ background: { ...background, useHomeBg: !useHomeBg.current } }));
    };

    const handleSwitchShowMyOrders = () => {
        dispatch(setSectionContent({ ordersButton: !useOrdersButton.current }));
    };

    if (!fontsData) {
        return null;
    }

    const backgroundOptions = () => {
        return (
            <>
                <span className="flex font-bold leading-7">{t("background")}</span>
                <span className="inline-flex pt-2 leading-7">
                    {t("use-home-background")}
                    <UseSwitch
                        adjust={"mt-1 ml-2"}
                        checked={useHomeBg.current}
                        action={handleSwitchOnOff}
                        id={"sales-background"}
                    />
                </span>
                {!useHomeBg.current ? (
                    <div className="pt-2">
                        <UseAddImage />
                    </div>
                ) : null}
            </>
        );
    };

    const textOptions = () => {
        return (
            <>
                <span className="flex font-bold leading-7">{t("Text options")}</span>
                <span className=" flex py-2 leading-7">{capitalizeT("title", t)}</span>
                <UseSelectWithSearch
                    data={{
                        name: "titleFont",
                        optionData: setSelectedFontsData(title),
                        selectedIds: [fontsData.filter((element) => element.name === title.name)[0].id],
                        oneSelected: true,
                        noSelect: true,
                        optionsAdjust: "mt-10",
                    }}
                />
                <span className="flex py-2 leading-7">{capitalizeT("text", t)}</span>
                <UseSelectWithSearch
                    data={{
                        name: "textFont",
                        optionData: setSelectedFontsData(text),
                        selectedIds: [fontsData.filter((element) => element.name === text.name)[0].id],
                        oneSelected: true,
                        noSelect: true,
                        optionsAdjust: "mt-10",
                    }}
                />
            </>
        );
    };

    const myOrdersOptions = () => {
        return (
            <div className=" border-t pt-4 border-gray-300 ">
                <span className="flex items-center font-bold leading-7">
                    {t("my-orders-history")}{" "}
                    <i
                        onMouseOver={() => ReactTooltip.rebuild()}
                        data-tip={t("this-button-will-only-appear-in-the-shops-list-screen")}
                        className=" ml-2 text-2xl text-gray-700 icon icon-info"
                    ></i>
                </span>
                <span className="inline-flex pt-2 leading-7">
                    {t("show-my-orders-button")}
                    <UseSwitch
                        adjust={"mt-1 ml-2"}
                        checked={useOrdersButton.current}
                        action={handleSwitchShowMyOrders}
                        id={"sales-show-my-orders-button"}
                    />
                </span>
            </div>
        );
    };

    return (
        <div style={{ maxHeight: "80vh", overflowY: "auto", padding: "16px" }}>
            {isTV ? <div className="border-b border-gray-300 pb-4">{backgroundOptions()}</div> : null}
            <div className="pb-4 pt-4">{textOptions()}</div>
            {hasMyOrders ? myOrdersOptions() : null}
            <ReactTooltip
                place="bottom"
                type="light"
                offset={{ top: -8, left: -8 }}
                html={true}
                border={true}
                borderColor="#D3DAE1"
            />
        </div>
    );
};

export default SalesGeneralSettings;
