import React, { useContext, useState, useEffect, forwardRef, createRef, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import RGL, { WidthProvider } from "react-grid-layout-juanedc";
import WidgetStyle from "zrender/widgetStyle";

import { SCREEN, DEVICE, WIDGET, WIDGETS } from "constants/editor";
import { returnWidgetByType } from "hooks/Utils/Design/EditorUtils";
import EmptyWidget from "components/Section/Design/Widgets/WidgetToolbars/CommonOptions/EmptyWidget";
import { GetThemeDefault, scaleFontSize } from "hooks/Utils/DesignUtils";
import Widget from "components/Section/Design/Widgets/Widget";
import useDevice from "components/Editor/devices";

import Icon from "components/Icon";
import Switch from "components/Switch";
import Loading from "components/Loading";

import { EditorProvider, WidgetProvider, EditorContext } from "contexts/editor";

const ReactGridLayout = WidthProvider(RGL);

const MIN_WIDTH = 2; // Minimum width for a widget
const MIN_HEIGHT = 2; // Minimum height for a widget

const newItem = (props) => ({
    ...props,
    w: props?.w || MIN_WIDTH,
    h: props?.h || MIN_HEIGHT,
    x: props?.x || 0,
    y: props?.y || 0,
    static: props?.static ?? false,
    minW: props?.minW || MIN_WIDTH,
    minH: props?.minH || MIN_HEIGHT,
});

const WIDGET_TOPBAR_CONFIG = {
    type: WIDGET.TYPE.TOPBAR,
    actions: null,
    data: null,
    style: null,
    layout: { w: 12, h: 2, x: 0, y: 0 },
};

const WIDGET_ROOMS_CONFIG = {
    ...WIDGET_TOPBAR_CONFIG,
    type: WIDGET.TYPE.ROOMS,
};

const Editor = forwardRef(
    (
        {
            screenType,
            deviceType,
            backgroundColor,
            backgroundImage,
            widgets: initialWidgets,
            theme,
            lang,
            droppingItem,
            onReadyChange,
            onChange,
        },
        ref
    ) => {
        const { t } = useTranslation();

        const permissions = {
            guests: {
                digitalKey: true,
            },
        };

        const evaluateWidgets = (widgets) => {
            const widgetTopBar = widgets?.find((widget) => widget?.type === WIDGET.TYPE.TOPBAR);
            const widgetRooms = widgets?.find((widget) => widget?.type === WIDGET.TYPE.ROOMS);
            const allWidgets = widgets?.length ? widgets : [];
            let updateWidgets = false;

            if (ensureWidgetTopBar) {
                if (!widgetTopBar) {
                    // Add widget topbar
                    allWidgets.push({ id: "topbar", ...WIDGET_TOPBAR_CONFIG });
                    updateWidgets = true;
                } else {
                    // Ensure widget topbar layout
                    widgetTopBar.layout = WIDGET_TOPBAR_CONFIG.layout;
                }
            } else if (widgetTopBar) {
                // Remove widget topbar
                allWidgets.splice(allWidgets.indexOf(widgetTopBar), 1);
                updateWidgets = true;
            }
            if (ensureWidgetRooms) {
                if (!widgetRooms) {
                    // Add widget rooms
                    allWidgets.push({ id: "rooms", ...WIDGET_ROOMS_CONFIG });
                    updateWidgets = true;
                } else {
                    // Ensure widget rooms layout
                    widgetRooms.layout = WIDGET_ROOMS_CONFIG.layout;
                }
            } else if (widgetRooms) {
                // Remove widget rooms
                allWidgets.splice(allWidgets.indexOf(widgetRooms), 1);
                updateWidgets = true;
            }
            if (updateWidgets) {
                return allWidgets;
            }
            return widgets;
        };

        const ensureWidgetTopBar =
            deviceType === DEVICE.TYPE.MOBILE &&
            (screenType === SCREEN.TYPE.MOBILE || screenType === SCREEN.TYPE.GENERAL);
        const ensureWidgetRooms = deviceType === DEVICE.TYPE.MOBILE && screenType === SCREEN.TYPE.MOBILE;
        const showAppTabBar = ensureWidgetTopBar;
        const showAppKey = showAppTabBar && permissions?.guests?.digitalKey;

        const [area, setArea] = useState(null);

        const [areaRef, setAreaRef] = useState(null);
        const [widgets, setWidgets] = useState(evaluateWidgets(initialWidgets));
        const [containerRef, setContainerRef] = useState(null);
        const [scrollAreaRef, setScrollAreaRef] = useState(null);
        const [editorRef, setEditorRef] = useState(null);
        const [gridRef, setGridRef] = useState(null);
        const [dragging, setDragging] = useState(null);
        const [editorSelected, setEditorSelected] = useState(null);

        const [showGrid, setShowGrid] = useState(true);

        const device = useDevice({ editorRef, deviceType, area });

        // In mobile devices, the bottom bar is always visible as fixed position at top outside the editable area
        // The topbar is 2 rows height and the editor starts at row 2 (marginTop)
        // The bottom bar is 2.5 rows height and need to be taken into account in the scrollable area
        const marginTop = ensureWidgetTopBar ? device?.rowHeight * WIDGET_TOPBAR_CONFIG.layout?.h : 0;
        const marginBottom = showAppTabBar ? device?.rowHeight * 2.5 : 0;

        const scrollEnabled = editorRef?.current?.hasScroll();

        const scrollAreaClass = classNames({
            "screenScrollable overflow-x-hidden": true,
            "overflow-y-hidden": !scrollEnabled,
            "overflow-y-scroll": scrollEnabled,
        });

        const onEditorResize = (e) => {
            for (let entry of e) {
                setArea((current) => {
                    const areaWidth = area?.width;
                    const currentWidth = entry?.contentRect?.width;
                    if (currentWidth && currentWidth !== areaWidth) {
                        return {
                            width: currentWidth,
                            height: Math.min(currentWidth / device?.ratio, window.innerHeight * 0.7),
                        };
                    }
                    return current;
                });
            }
        };

        useImperativeHandle(ref, () => ({
            getSize: (w, h) => {
                return [(w || 0) * (device?.colWidth || 0), (h || 0) * (device?.rowHeight || 0)];
            },
        }));

        const ready = device?.width > 0 && device?.height > 0;
        useEffect(() => {
            if (ready && onReadyChange) {
                onReadyChange(ready);
            }
        }, [ready]);

        useEffect(() => {
            // Reset the editor when the device changes
            setAreaRef(createRef());
            setContainerRef(createRef());
            setScrollAreaRef(createRef());
            setEditorRef(createRef());
            setGridRef(createRef());
            setWidgets(initialWidgets);
        }, [initialWidgets, device?.id]);

        useEffect(() => {
            if (!droppingItem) {
                setDragging(false);
            }
        }, [droppingItem]);

        useEffect(() => {
            setWidgets(evaluateWidgets(widgets));
        }, [widgets]);

        useEffect(() => {
            setWidgets(evaluateWidgets(initialWidgets));
        }, [screenType, deviceType]);

        useEffect(() => {
            if (onChange) {
                onChange({ widgets });
            }
        }, [widgets]);

        useEffect(() => {
            const onScroll = () => {
                if (scrollAreaRef?.current && gridRef?.current) {
                    gridRef.current.scrollTo(scrollAreaRef.current.scrollLeft, scrollAreaRef.current.scrollTop);
                }
            };
            if (scrollAreaRef?.current && gridRef?.current) {
                scrollAreaRef.current.addEventListener("scroll", onScroll);
            }
            return () => {
                if (scrollAreaRef?.current) {
                    scrollAreaRef.current.removeEventListener("scroll", onScroll);
                }
            };
        }, [scrollAreaRef?.current, gridRef?.current]);

        useEffect(() => {
            const observer = new ResizeObserver(onEditorResize);
            if (areaRef?.current) {
                observer.observe(areaRef.current);
            }
            return () => {
                if (observer) {
                    observer.disconnect();
                }
            };
        }, [areaRef?.current]);

        const onWindowResize = () => {
            setArea((current) => {
                if (current?.height) {
                    return {
                        ...current,
                        height: Math.min(current.height, window.innerHeight * 0.7),
                    };
                }
                return current;
            });
        };

        useEffect(() => {
            window.addEventListener("resize", onWindowResize);
            return () => window.removeEventListener("resize", onWindowResize);
        }, []);

        return (
            <EditorProvider
                value={{
                    deviceType,
                    lang,
                    editorSize: {
                        width: device?.width,
                        height: device?.height,
                        my: 0,
                        mx: 0,
                        cols: device?.cols,
                        rows: device?.rows,
                    },
                    itemSelected: null,
                    screenType,
                    theme,
                }}
            >
                <div ref={areaRef} className="zafiro-editor">
                    <div className="top-bar">
                        <div className="flex items-center space-x-5 whitespace-no-wrap">
                            <Switch
                                label="Show Grid"
                                labelPosition="left"
                                checked={showGrid}
                                onChange={({ checked }) => setShowGrid(checked)}
                            />
                        </div>
                    </div>
                    <div
                        ref={containerRef}
                        className="screen"
                        style={{
                            width: device?.width + "px",
                            height: device?.height + "px",
                            ...(backgroundImage ? { backgroundImage: `url('${backgroundImage}')` } : null),
                            ...(backgroundColor ? { backgroundColor } : null),
                        }}
                    >
                        <div
                            ref={scrollAreaRef}
                            className={scrollAreaClass}
                            style={{
                                height: device?.height + "px",
                                border: device?.border + "px solid #2286ef",
                            }}
                            onClick={() => {
                                setEditorSelected(true);
                            }}
                        >
                            {device?.loading ? (
                                <div className="flex h-full items-center justify-center bg-white bg-opacity-25">
                                    <Loading />
                                </div>
                            ) : null}
                            {!device?.loading && (showGrid || dragging) ? (
                                <Overlay
                                    ref={gridRef}
                                    grid={showGrid}
                                    device={device}
                                    marginTop={marginTop}
                                    marginBottom={marginBottom}
                                />
                            ) : null}
                            {!device?.loading && ensureWidgetTopBar ? (
                                <div
                                    className="decoration absolute shadow bg-white"
                                    style={{
                                        top: device?.border + "px",
                                        left: device?.border + "px",
                                        right: device?.scrollbar + device?.border + "px",
                                        bottom: "auto",
                                        height: WIDGET_TOPBAR_CONFIG.layout.h * device?.rowHeight + "px",
                                    }}
                                >
                                    <DisplayWidget id="topbar" type={WIDGET.TYPE.TOPBAR} />
                                </div>
                            ) : null}
                            <ZafiroGridLayout
                                ref={editorRef}
                                enabled={true}
                                widgets={widgets}
                                onChange={setWidgets}
                                editorSelected={editorSelected}
                                onItemSelect={() => setEditorSelected(false)}
                                onDraggingChange={setDragging}
                                droppingItem={droppingItem}
                                style={{
                                    marginTop: marginTop + "px",
                                    marginBottom: marginBottom + "px",
                                    [scrollEnabled ? "minHeight" : "height"]:
                                        device?.height -
                                        device?.border * 2 -
                                        (ensureWidgetTopBar ? device?.rowHeight * 2 : 0) +
                                        "px",
                                }}
                                cols={device?.cols}
                                rows={device?.rows}
                                rowHeight={device?.rowHeight}
                                autoSize={true}
                                draggableHandle={".widget-label"}
                                scrollable={device?.scrollbar}
                            />
                        </div>
                        {!device?.loading && showAppKey ? (
                            <div
                                className="decoration absolute p-4 rounded-full shadow-md box-content text-white bg-zafiro-400 shadow leading-none"
                                style={{
                                    right: device?.scrollbar + device?.border + 5 + "px",
                                    bottom: device?.rowHeight * 3 + "px",
                                }}
                            >
                                <Icon type="key" size={1.8} />
                            </div>
                        ) : null}
                        {!device?.loading && showAppTabBar ? (
                            <div
                                className="decoration absolute pt-3 pb-2 bg-white shadow border border-gray-300 text-center text-gray-700 text-sm"
                                style={{
                                    bottom: device?.border + "px",
                                    left: device?.border + "px",
                                    right: device?.scrollbar + device?.border + "px",
                                    top: "auto",
                                    height: marginBottom + "px",
                                }}
                            >
                                <div className="grid grid-cols-3 place-content-center h-full">
                                    <div className="space-y-1 text-zafiro-400">
                                        <Icon type="hotel-solid" size={1.5} />
                                        <div className="first-capital">{t("property")}</div>
                                    </div>
                                    <div className="space-y-1">
                                        <Icon type="notifications" size={1.5} />
                                        <div className="first-capital">{t("notifications")}</div>
                                    </div>
                                    <div className="space-y-1">
                                        <Icon type="user-regular" size={1.5} />
                                        <div className="first-capital">{t("profile")}</div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </EditorProvider>
        );
    }
);

const Overlay = forwardRef(({ device, marginTop, marginBottom, grid }, ref) => {
    const gridBase = { w: device?.colWidth, h: device?.rowHeight };
    const gridBold = { w: device?.colWidth * 8, h: device?.rowHeight * 8 };
    const gridContainerStyles = {
        bottom: marginBottom + device?.border + "px",
        left: device?.border + "px",
        right: device?.scrollbar + device?.border + "px",
        top: marginTop + device?.border + "px",
    };
    const gridCoversStyles = {
        height: `${device?.editor?.height}px`,
    };
    const gridBaseStyles = {
        backgroundSize: `${gridBase?.w}px ${gridBase?.h}px`,
        backgroundPosition: "0 0",
    };
    const gridBoldStyles = {
        backgroundPosition:
            device?.id === DEVICE.TYPE.MOBILE
                ? `${device?.colWidth * 2}px ${marginTop}px`
                : `0px ${device?.colWidth * 2}px`,
        backgroundSize: `${gridBold?.w}px ${gridBold?.h}px`,
    };

    return (
        <div ref={ref} className="absolute pointer-events-none overflow-hidden" style={gridContainerStyles}>
            <div
                className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none cover-bg-overlay"
                style={gridCoversStyles}
            ></div>
            {grid ? (
                <>
                    <div
                        className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none cover-grid"
                        style={{ ...gridCoversStyles, ...gridBaseStyles }}
                    ></div>
                    <div
                        className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none cover-grid-bold"
                        style={{ ...gridCoversStyles, ...gridBoldStyles }}
                    ></div>
                </>
            ) : null}
        </div>
    );
});

const EditorItem = ({
    widget,
    item,
    selected,
    onItemSelect,
    resizing,
    dragging,
    rowHeight,
    maxRows,
    dragClassName,
    droppingItem,
    onChange,
}) => {
    const { t } = useTranslation();

    const itemSelected = selected && selected?.i === item.i ? selected : null;
    const itemResizing = resizing && resizing?.i === item.i ? resizing : null;
    const itemDragging = dragging && dragging?.i === item.i ? dragging : null;
    const currentItem = itemResizing || item;
    const topSpacing = currentItem?.y * rowHeight;
    const bottomSpacing = itemSelected ? (maxRows - currentItem?.y - currentItem?.h) * rowHeight : 0;
    const height = currentItem?.h * rowHeight;

    const showSizeOutside = bottomSpacing > 20;
    const showSizeInTitle = !showSizeOutside && height <= 40 && dragClassName;

    const config = {
        data: widget?.data,
        actions: widget?.actions,
        style: widget?.style,
        layout: {
            cols: currentItem?.w,
            maxRows: currentItem?.h,
            rowHeight,
        },
        widgets: widget?.widgets,
    };
    return (
        <>
            {itemSelected ? (
                <>
                    {dragClassName ? (
                        <div
                            className={dragClassName}
                            style={{
                                height: "1.8em",
                                left: "-2px",
                                ...(topSpacing <= 20
                                    ? {
                                          top: 0,
                                          borderRadius: "0 0 0.25rem 0.25rem",
                                      }
                                    : {
                                          top: "-1.8em",
                                          borderRadius: "0.25rem 0.25rem 0 0",
                                      }),
                            }}
                        >
                            <div className="widget-tag mx-auto flex space-x-1 items-center h-full">
                                <Icon type="move-widget" size={1} />
                                <div>{t(WIDGETS[widget?.type]?.name)}</div>
                                {showSizeInTitle ? (
                                    <div>
                                        ({currentItem?.w}x{currentItem?.h})
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : null}
                </>
            ) : null}
            <div className="overflow-hidden relative w-full h-full">
                <DisplayWidget
                    id={widget?.id}
                    type={widget?.type}
                    config={config}
                    enabled={!itemResizing && !itemDragging}
                    selected={itemSelected}
                    onItemSelect={onItemSelect}
                    droppingItem={widget?.type === WIDGET.TYPE.CONTAINER ? droppingItem : null}
                    onChange={(newConfig) => {
                        if (onChange) {
                            onChange(newConfig);
                        }
                    }}
                />
            </div>
            {itemSelected && !showSizeInTitle ? (
                <div
                    className="absolute left-0 right-0 overflow-hidden flex items-center"
                    style={{
                        ...(showSizeOutside ? { bottom: "-2em" } : { bottom: ".5em" }),
                    }}
                >
                    <div className="widget-tag mx-auto flex space-x-1 items-center">
                        {currentItem?.w}x{currentItem?.h}
                    </div>
                </div>
            ) : null}
        </>
    );
};
const DisplayWidget = ({ id, type, enabled, selected, onItemSelect, droppingItem, config, onChange }) => {
    const { deviceType, theme } = useContext(EditorContext);

    const data = Widget.ParseData(config?.data, deviceType);
    const rawStyle = Widget.ParseStyle(config?.style, deviceType);

    const style = type ? new WidgetStyle(type, theme) : null;
    if (style) {
        style.fill(rawStyle);
    }

    const usingDriveRender = false;

    if (type === WIDGET.TYPE.CONTAINER) {
        return (
            <EditorContainer
                cols={config?.layout?.cols}
                rows={config?.layout?.maxRows}
                rowHeight={config?.layout?.rowHeight}
                enabled={enabled}
                selected={selected}
                onItemSelect={onItemSelect}
                droppingItem={droppingItem}
                widgets={config?.widgets}
                onChange={(widgets) => {
                    if (onChange) {
                        onChange({
                            ...config,
                            widgets,
                        });
                    }
                }}
            />
        );
    }

    if (type !== WIDGET.TYPE.ROOMS && type !== WIDGET.TYPE.TOPBAR) {
        return <EmptyWidget type={type} />;
    }

    return (
        <WidgetProvider
            value={{
                id,
                type,
                style: rawStyle,
                config: {
                    style,
                    data,
                },
            }}
        >
            <Widget.Zone
                style={
                    Widget.ImplementNewLibrary(type)
                        ? null
                        : {
                              background: usingDriveRender
                                  ? "transparent"
                                  : style?.bgColor || GetThemeDefault({ theme, id: "bgColor" }),
                              color: style?.fgColor ? style.fgColor : GetThemeDefault({ theme, id: "fgColor" }),
                              borderWidth: style?.borderWidth ? `${scaleFontSize(style.borderWidth)}px` : "",
                              borderColor: style?.borderColor ? style.borderColor : "transparent",
                              borderRadius: style?.radius ? `${style.radius}rem` : "",
                          }
                }
            >
                {returnWidgetByType(type, {
                    isOnDrag: false,
                    isOnResize: false,
                    widgets: null,
                    setWarning: (widgetID, warn) => {
                        console.log("TEST WIDGET WARNING", { widgetID, warn });
                    },
                })}
            </Widget.Zone>
        </WidgetProvider>
    );
};

const EditorContainer = ({
    cols,
    rows,
    rowHeight,
    enabled,
    selected: editorSelected,
    widgets,
    onChange,
    onItemSelect,
    droppingItem,
}) => {
    return (
        <ZafiroGridLayout
            editorSelected={editorSelected}
            onItemSelect={onItemSelect}
            enabled={enabled}
            widgets={widgets}
            onChange={onChange}
            droppingItem={droppingItem}
            cols={cols}
            rows={rows}
            rowHeight={rowHeight}
            autoSize={false}
            draggableHandle={".container-widget-label"}
            style={{ filter: enabled ? "none" : "opacity(0.5)" }}
        />
    );
};

const ZafiroGridLayout = forwardRef((props, ref) => {
    const [key, setKey] = useState(Date.now());
    const layoutRef = useRef(null);
    const editorSelected = props?.editorSelected;
    const enabled = props?.enabled;
    const rowHeight = props?.rowHeight;
    const droppingItem = props?.droppingItem;
    const scrollable = props?.scrollable;
    const cols = props?.cols;
    const rows = props?.rows;
    const onChange = props?.onChange;
    const onItemSelect = props?.onItemSelect;
    const onDraggingChange = props?.onDraggingChange;
    const draggableHandle = props?.draggableHandle?.substring(1);

    const [widgets, setWidgets] = useState(props?.widgets);
    const [selected, setSelected] = useState(null);
    const [dragging, setDragging] = useState(null);
    const [resizing, setResizing] = useState(null);
    const [currentLayout, setCurrentLayout] = useState(null);

    const dropping = !!droppingItem;
    const droppingWidth = Math.max(droppingItem?.layout?.w || 0, MIN_WIDTH);
    const droppingHeight = Math.max(droppingItem?.layout?.h || 0, MIN_HEIGHT);

    const layout = widgets?.length
        ? widgets
              // Map widgets to layout items
              .map((widget) =>
                  newItem({
                      ...widget?.layout,
                      i: String(widget?.id),
                      static: false,
                      ...(widget?.type === WIDGET.TYPE.TOPBAR
                          ? { ...WIDGET_TOPBAR_CONFIG.layout, static: true }
                          : null),
                      ...(widget?.type === WIDGET.TYPE.ROOMS ? { ...WIDGET_ROOMS_CONFIG.layout, static: true } : null),
                  })
              )
              // Filter out null widgets
              .filter((item) => item)
        : [];

    // When device is not scrollable vertically, we can evaluate the max rows based on the layout and enable the scroll in this case
    const maxRows = scrollable
        ? undefined
        : Math.max(
              rows,
              layout?.reduce((y, item) => Math.max(y, item?.y + item?.h), 0)
          );
    const scrollEnabled = !!scrollable || maxRows != rows;

    const updateWidgets = (widgets) => {
        setWidgets(widgets);
        if (onChange) {
            onChange(widgets);
        }
    };

    const ignoreTopBar = (layout) =>
        layout?.filter((item) => {
            const widget = widgets?.find((w) => String(w?.id) === String(item.i));
            return widget?.type !== WIDGET.TYPE.TOPBAR;
        });

    const checkItemBottomLimit = (item) => {
        return item && (!maxRows || item.y + item.h <= maxRows);
    };
    const checkItemRightLimit = (item) => {
        return item && item.x + item.w <= cols;
    };
    const checkItemSize = (item) => {
        return item && item?.w >= MIN_WIDTH && item?.h >= MIN_HEIGHT;
    };

    const xCrash = (a, b) => {
        const outLeft = a.x + a.w <= b.x; // a is left of b
        const outRight = a.x >= b.x + b.w; // a is right of b
        return !(outRight || outLeft);
    };
    const yCrash = (a, b) => {
        const above = a.y + a.h <= b.y; // a is above b
        const below = a.y >= b.y + b.h; // a is below b
        return !(above || below);
    };
    const belowCrash = (a, items) =>
        items.sort((a, b) => a.y - b.y || a.x - b.x).find((b) => xCrash(a, b) && b.y + b.h > a.y && b?.y - a.y > 0);
    const rightCrash = (a, items) =>
        items.sort((a, b) => a.x - b.x || a.y - b.y).find((b) => yCrash(a, b) && b.x < a.x + a.w && b?.x - a.x > 0);

    const checkItemCrash = (a) => {
        return (
            a &&
            !layout
                .filter((b) => b.i !== a.i)
                .some((b) => {
                    return xCrash(a, b) && yCrash(a, b);
                })
        );
    };
    const checkItem = (item) => {
        const checkSize = checkItemSize(item);
        const checkBottom = checkItemBottomLimit(item);
        const checkRight = checkItemRightLimit(item);
        const checkCrash = checkItemCrash(item);
        return checkSize && checkBottom && checkRight && checkCrash;
    };
    const adjustNewItem = (item) => {
        if (item && !checkItem(item)) {
            if (!item.x) {
                item.x = 0;
            }
            if (!item.y) {
                item.y = 0;
            }
            if (!item.w) {
                item.w = MIN_WIDTH;
            }
            if (!item.h) {
                item.h = MIN_HEIGHT;
            }
            if (!checkItemBottomLimit(item)) {
                item.h = maxRows - item.y;
            }
            if (!checkItemRightLimit(item)) {
                item.w = cols - item.x;
            }
            return checkItem(item);
        }
        return !!item;
    };

    const editorLayout = ignoreTopBar(layout);

    const updateCurrentLayout = () => {
        if (currentLayout) {
            updateWidgets(
                (widgets || []).map((widget) => {
                    const itemLayout = currentLayout?.find((item) => item.i === widget?.id);
                    return {
                        ...widget,
                        layout: {
                            ...widget?.layout,
                            x: itemLayout?.x,
                            y: itemLayout?.y,
                            w: itemLayout?.w,
                            h: itemLayout?.h,
                        },
                    };
                })
            );
            setKey(Date.now());
        }
    };

    useEffect(() => {
        if (onDraggingChange) {
            onDraggingChange(dragging);
        }
    }, [dragging]);

    useEffect(() => {
        if (onItemSelect && selected) {
            onItemSelect(selected);
        }
    }, [selected]);

    useEffect(() => {
        setWidgets(props?.widgets);
    }, [props?.widgets]);

    const config = {
        ...props,
        key,
        layout: editorLayout,
        margin: [0, 0],
        containerPadding: [0, 0],
        maxRows,
        isBounded: false,
        compactType: null,
        useCSSTransforms: true,
        transformScale: 1,
        preventCollision: true,
        allowOverlap: dragging || dropping,
        resizeHandles: ["sw", "nw", "se", "ne"],
        isResizable: enabled,
        isDraggable: enabled,
        isDroppable: enabled,
        droppingItem: newItem({
            i: "new",
            w: droppingWidth,
            h: droppingHeight,
        }),
        onResizeStart: (layout, oldItem, newItem, placeholder, e, element) => {
            setResizing(newItem);
            setSelected(newItem);
        },
        onResize: (layout, oldItem, newItem, placeholder, e, element) => {
            if (!layout || !newItem || !oldItem || !placeholder) {
                return;
            }
            setResizing(newItem);
            layout = layout.map((item) => {
                if (item.i === newItem.i) {
                    item.x = newItem.x;
                    item.y = newItem.y;
                    item.w = newItem.w;
                    item.h = newItem.h;
                }
                return { ...item };
            });
            setCurrentLayout(layout);
        },
        onResizeStop: (layout, oldItem, newItem, placeholder, e, element) => {
            if (!layout || !newItem || !oldItem) {
                return;
            }
            setResizing(null);
            updateCurrentLayout();
        },
        onDragStart: (layout, oldItem, newItem, placeholder, e, element) => {
            setDragging({
                ...newItem,
            });
            if (e?.dataTransfer) {
                e.dataTransfer.setData("text/plain", "");
            }
        },
        onDrag: (layout, oldItem, newItem, placeholder, e, element) => {
            if (!layout || !newItem || !oldItem || !placeholder) {
                return;
            }
            const otherItems = layout.filter((item) => item.i !== newItem.i);
            newItem.w = oldItem.w;
            newItem.h = oldItem.h;

            if (!checkItemCrash(newItem)) {
                const itemBelow = belowCrash(newItem, otherItems);
                if (itemBelow) {
                    newItem.h = Math.max(Math.min(itemBelow?.y - newItem.y, newItem.h), MIN_HEIGHT);
                }
                const itemRight = rightCrash(newItem, otherItems);
                if (itemRight) {
                    newItem.w = Math.max(Math.min(itemRight?.x - newItem.x, newItem.w), MIN_WIDTH);
                    newItem.h = oldItem.h;
                    const itemBelow = belowCrash(newItem, otherItems);
                    if (itemBelow) {
                        newItem.h = Math.max(Math.min(itemBelow?.y - newItem.y, newItem.h), MIN_HEIGHT);
                    }
                }
            }

            if (!checkItemBottomLimit(newItem)) {
                newItem.h = Math.max(maxRows - newItem.y, MIN_HEIGHT);
            }
            if (!checkItemRightLimit(newItem)) {
                newItem.w = Math.max(cols - newItem.x, MIN_WIDTH);
            }

            if (!checkItem(newItem)) {
                if (dropping) {
                    // Cancel item drop
                    newItem.w = 0;
                    newItem.h = 0;
                } else {
                    // Reset item to original position
                    newItem.h = oldItem.h;
                    newItem.w = oldItem.w;
                    newItem.x = oldItem.x;
                    newItem.y = oldItem.y;
                }
            }
            placeholder.x = newItem.x;
            placeholder.y = newItem.y;
            placeholder.w = newItem.w;
            placeholder.h = newItem.h;

            layout = layout.map((item) => {
                if (item.i === newItem.i) {
                    item.x = newItem.x;
                    item.y = newItem.y;
                    item.w = newItem.w;
                    item.h = newItem.h;
                }
                return { ...item };
            });
            setCurrentLayout(layout);
        },
        onDragStop: () => {
            setDragging(null);
            updateCurrentLayout();
        },
        onDrop: (layout, item, e) => {
            setDragging(null);
            if (!adjustNewItem(item)) {
                // Cancel item drop
                return;
            }
            // Add new widget
            const newID = `new-${Date.now()}`;
            updateWidgets([
                ...(widgets || []),
                {
                    ...droppingItem,
                    id: String(newID),
                    layout: {
                        ...item,
                        i: String(newID),
                    },
                },
            ]);
        },
    };

    useEffect(() => {
        if (!enabled || editorSelected) {
            setSelected(null);
        }
    }, [enabled, editorSelected]);

    useImperativeHandle(ref, () => ({
        getElement: () => {
            return layoutRef?.current?.elementRef?.current;
        },
        hasScroll: () => scrollEnabled,
    }));

    useEffect(() => {
        updateWidgets(props?.widgets);
    }, [props?.widgets]);

    return (
        <ReactGridLayout ref={layoutRef} {...config}>
            {editorLayout?.length
                ? editorLayout.map((item) => {
                      const widget = widgets?.find((w) => String(w?.id) === String(item.i));
                      const itemSelected = selected && selected?.i === item.i ? selected : null;
                      return (
                          <div
                              key={item.i}
                              onClick={(e) => {
                                  if (e) {
                                      e.stopPropagation();
                                  }
                                  setSelected(item);
                              }}
                              onDoubleClick={(e) => {
                                  if (e) {
                                      e.stopPropagation();
                                  }
                                  updateWidgets(widgets.filter((w) => w?.id !== widget?.id));
                              }}
                              className={classNames({
                                  "bg-white": widget?.type === WIDGET.TYPE.ROOMS,
                                  "widget-container bg-gray-200 bg-opacity-50": widget?.type === WIDGET.TYPE.CONTAINER,
                                  selected: !!itemSelected,
                              })}
                          >
                              <EditorItem
                                  item={item}
                                  widget={widget}
                                  selected={selected}
                                  itemSelected={() => setSelected(false)}
                                  resizing={resizing}
                                  dragging={dragging}
                                  rowHeight={rowHeight}
                                  droppingItem={droppingItem}
                                  maxRows={maxRows}
                                  dragClassName={draggableHandle}
                                  onChange={(newConfig) => {
                                      updateWidgets(
                                          widgets.map((w) => {
                                              if (w.id === widget.id) {
                                                  return {
                                                      ...w,
                                                      data: newConfig?.data,
                                                      actions: newConfig?.actions,
                                                      style: newConfig?.style,
                                                      widgets: newConfig?.widgets,
                                                  };
                                              }
                                              return w;
                                          })
                                      );
                                  }}
                              />
                          </div>
                      );
                  })
                : null}
        </ReactGridLayout>
    );
});

export default Editor;
