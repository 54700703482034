import React from "react";
import uuid from "react-uuid";
import { capitalizeFirst } from "../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";

const UseTagCell = ({ cellData, rowIndex, colIndex }) => {
    //Store data
    const { t } = useTranslation();

    //order tags to show in same orden
    if (cellData && !cellData.noSorted) {
        cellData.value.sort(function (a, b) {
            if (a[0] > b[0]) {
                return 1;
            }
            if (a[0] < b[0]) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    }
    return (
        <div key={uuid()}>
            {cellData.value.map((val, index) => {
                return (
                    <div key={`tag_${val}_${index}`} className="inline">
                        <div
                            className={`rounded py-1 px-4 m-1 text-white inline-block ${
                                cellData.noCapitalice ? "" : "first-capital"
                            } `}
                            style={{ backgroundColor: val[2], whiteSpace: "break-spaces" }}
                            key={index}
                            data-tip={
                                val[3] ? (val[4] && val[4] === true ? t(val[3]) : capitalizeFirst(t(val[3]))) : ""
                            }
                            data-for="dangerous-html-tooltip"
                        >
                            {t(val[1])}
                            {val[5] ? <span className={`icon ${val[5]}`}></span> : null}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default UseTagCell;
