import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { openModal, setModalContent } from "../../../actions/uiActions";
import {
    changeGlobalAction,
    setActionName,
    setActionItemAffected,
    setActionData,
    setActionBackup,
} from "../../../actions/globalActions";
import { setRowInEdition } from "../../../actions/tableActions";
import { showRowForm } from "../../../actions/sectionActions";

import { toast } from "react-toastify";
import { useNavigate as useHistory } from "react-router-dom";

const UseIconCell = ({ cellData, setHideRow, rowIndex, colIndex }) => {
    const history = useHistory();
    const {
        hidden,
        specialWidth,
        icon,
        isAction,
        modal,
        name,
        tooltip,
        disabledIcon = false,
        onClickAction,
    } = cellData || {};
    const clickable = isAction && !disabledIcon ? "cursor-pointer" : "";
    const dataId = modal ? modal[0].id : 0;
    const prefixId = "icon-cell";

    //Store data
    const { rowForm } = useSelector((state) => state.sectionContent);
    const { disabled } = useSelector((state) => state.table);
    const { langStrings } = useSelector((state) => state.ui);

    //Actions
    const dispatch = useDispatch();

    const directLink = (link) => {
        history(link);
    };

    const showModal = (e) => {
        e.stopPropagation();
        if (rowForm) {
            toast.error(langStrings["save-before-continue"]);
            return;
        }
        if (icon.action) {
            setHideRow(true);
            dispatch(setRowInEdition(rowIndex));
            dispatch(showRowForm(true));
            if (!icon.actionBackup) {
                dispatch(setActionName(icon.action));
            } else {
                dispatch(setActionBackup(icon.action));
            }
            dispatch(setActionItemAffected(icon.id));
            dispatch(setActionData({ name: e.currentTarget.dataset.value }));
            return;
        }
        dispatch(setModalContent(modal[0]));

        dispatch(
            changeGlobalAction({
                actionName: e.currentTarget.dataset.action_name,
                itemsAffected: [e.currentTarget.dataset.id],
            })
        );
        dispatch(openModal());
    };

    return (
        <>
            {!hidden ? (
                <div className={`w-full flex items-center text-left ${specialWidth}`}>
                    <div
                        id={`${prefixId}-button-r${rowIndex}-c${colIndex}`}
                        className={`inline-block text-xl mr-3 icon-${icon.name} ${icon.adjust} ${clickable} ${
                            disabled || disabledIcon ? "text-gray-600" : ""
                        }`}
                        onClick={(e) => {
                            if (!disabled && !disabledIcon) {
                                if (icon.link) {
                                    directLink(icon.link);
                                } else if (typeof onClickAction === "function") {
                                    onClickAction();
                                } else if (clickable) {
                                    showModal(e);
                                }
                            }
                        }}
                        style={typeof icon?.style === "object" ? icon.style : null}
                        data-id={dataId}
                        data-action_name={`${icon.name}-${name}`}
                        data-tip={tooltip}
                        data-for="default-tooltip"
                    ></div>
                </div>
            ) : null}
        </>
    );
};

export default UseIconCell;
