import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

//Components
import UseLoading from "./useLoading";
import UseRows from "./useRows";
import UseSearch from "./useSearch";
import UseDateHeader from "./useDateHeader";
import UseHeaders from "./useHeaders";
import UseFilters from "./useFilters";
import UseBatchSelect from "./useBatchSelect";
import UsePagination from "./usePagination";
import UseError from "./useError";
import UseCards from "./useCards";
import {
    setRemoveLoadingTimeout,
    setLoading,
    setAllChecked,
    cleanCheckedItems,
    setPage,
    cleanTableStore,
    setAvailableFilters,
    getFilterQuery,
    cleanActiveFilters,
    restoreActiveFilters,
    setCheckboxDropdownFilter,
    setActiveSort,
    setFolder,
    setInformativeMessage,
} from "actions/tableActions";
import { setCheckboxDropdownNames } from "../../actions/uiActions";

//Utils
import { Session } from "../../hooks/Utils/Session";
import UseFolders from "./useFolders";
import MessagesBinWarning from "../Section/Customers/Message/MessagesBinWarning";
import { ChromeCastWarnings } from "components/Section/Hotel/ChromeCastWarnings";
import { useTranslation } from "react-i18next";

import Parser from "html-react-parser";
import Icon from "components/Icon";

const Table = ({ preventClean, useCollection, tableLength }) => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page");

    //Store data
    const {
        loading,
        loadingMoreResults,
        error,
        technicalProblems,
        removeTableLoadingTimeout,
        headerButtons,
        availableFilters,
        batchActive,
        customConfig,
        currentPage,
        filterQuery,
        activeFilters,
        sortCriteria,
        type,
        checkboxDropdownFilter,
        searchable,
        results,
        folder,
        informativeMessage,
    } = useSelector((state) => state.table);

    const prefixId = "table-";
    const { langStrings, checkboxDropdownNames, permissions } = useSelector((state) => state.ui);
    const location = useLocation();
    const { t } = useTranslation();

    const isMediaLibrary =
        location.pathname.endsWith("/media") ||
        location.pathname.endsWith("/design/library") ||
        location.pathname.endsWith("/design/library/menu");

    //Actions
    const dispatch = useDispatch();

    //Ensure remove loading when data doesn't change
    useEffect(() => {
        if (loading) {
            clearTimeout(removeTableLoadingTimeout);
            const timeoutObject = setTimeout(() => {
                dispatch(setLoading(false));
            }, 10000);
            dispatch(setRemoveLoadingTimeout(timeoutObject));
        } else {
            clearTimeout(removeTableLoadingTimeout);
        }
        // eslint-disable-next-line
    }, [loading]);

    useEffect(() => {
        Session.setSessionProp("tableType", type);
    }, [type]);

    useEffect(() => {
        Session.setSessionProp("tableFolder", folder);
    }, [folder]);

    useEffect(() => {
        Session.setSessionProp("tableCurrentPage", currentPage);
    }, [currentPage]);

    useEffect(() => {
        Session.setSessionProp("tableAvailableFilters", JSON.stringify(availableFilters));
    }, [availableFilters]);

    useEffect(() => {
        Session.setSessionProp("tableActiveFilters", JSON.stringify(activeFilters ? activeFilters : null));
    }, [filterQuery, activeFilters]);

    useEffect(() => {
        Session.setSessionProp("tableSortCriteria", JSON.stringify(sortCriteria ? sortCriteria : null));
    }, [sortCriteria]);

    useEffect(() => {
        Session.setSessionProp(
            "tableCheckboxDropdownFilter",
            JSON.stringify(checkboxDropdownFilter ? checkboxDropdownFilter : null)
        );
    }, [checkboxDropdownFilter]);

    useEffect(() => {
        Session.setSessionProp(
            "tableCheckboxDropdownNames",
            JSON.stringify(checkboxDropdownNames ? checkboxDropdownNames : null)
        );
    }, [checkboxDropdownNames]);

    useEffect(() => {
        if (!preventClean) {
            dispatch(cleanTableStore());
            dispatch(cleanActiveFilters());
        }

        const typeToRestore = needToRestore(location);
        //set data to restore previous state
        if (typeToRestore) {
            const lsPage = parseInt(Session.getSessionProp(`${typeToRestore}_page`));
            const lsFilters = JSON.parse(Session.getSessionProp(`${typeToRestore}_filters`));
            const lsActiveFilters = JSON.parse(Session.getSessionProp(`${typeToRestore}_active_filters`));
            const lsCheckFilters = JSON.parse(Session.getSessionProp(`${typeToRestore}_check_filters`));
            const lsCheckNames = JSON.parse(Session.getSessionProp(`${typeToRestore}_check_names`));
            const lsSortCriteria = JSON.parse(Session.getSessionProp(`${typeToRestore}_sort_criteria`));
            const lsFolder = JSON.parse(Session.getSessionProp(`${typeToRestore}_folder`));
            if (lsFilters) {
                dispatch(setAvailableFilters(lsFilters));
            }
            if (lsActiveFilters) {
                dispatch(getFilterQuery(lsActiveFilters));
                dispatch(restoreActiveFilters(lsActiveFilters));
            }
            if (lsCheckFilters) {
                dispatch(setCheckboxDropdownFilter(lsCheckFilters));
            }
            if (lsCheckNames) {
                dispatch(setCheckboxDropdownNames(lsCheckNames));
            }
            if (lsSortCriteria) {
                dispatch(setActiveSort(null, null, lsSortCriteria));
            }
            //Añado timeout porque al lanzar el dispatch de setCheckboxDropdownNames, si hay valor, se fuerza un setPage(1)
            //y machaca este setPage
            setTimeout(function () {
                dispatch(setPage(lsPage ? parseInt(lsPage) : 1));
            }, 1);
            //idem para setFolder
            if (lsFolder) {
                setTimeout(function () {
                    dispatch(setFolder(lsFolder));
                }, 1);
            }
        } else {
            dispatch(setPage(1));
        }
        return () => {
            //save table status

            const tableType = Session.getSessionProp("tableType");
            if (tableType) {
                Session.setSessionProp(`${tableType}_filters`, Session.getSessionProp("tableAvailableFilters"));
                Session.setSessionProp(`${tableType}_page`, Session.getSessionProp("tableCurrentPage"));
                Session.setSessionProp(`${tableType}_active_filters`, Session.getSessionProp("tableActiveFilters"));
                Session.setSessionProp(`${tableType}_folder`, Session.getSessionProp("tableFolder"));
                Session.setSessionProp(
                    `${tableType}_check_filters`,
                    Session.getSessionProp("tableCheckboxDropdownFilter")
                );
                Session.setSessionProp(
                    `${tableType}_check_names`,
                    Session.getSessionProp("tableCheckboxDropdownNames")
                );
                Session.setSessionProp(`${tableType}_sort_criteria`, Session.getSessionProp("tableSortCriteria"));
            }

            dispatch(setInformativeMessage(""));
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (page) {
            dispatch(setPage(Number(page)));
        }
    }, [page]);

    useEffect(() => {
        dispatch(setAllChecked(false));
        dispatch(cleanCheckedItems());
        // eslint-disable-next-line
    }, [currentPage, filterQuery]);

    return (
        <div
            id="table-container"
            className={`main-container ${
                customConfig && customConfig.tableContainerAdjust ? customConfig.tableContainerAdjust : null
            }`}
        >
            {error && !technicalProblems ? <UseError /> : null}
            {loading ? <UseLoading /> : null}
            {searchable || headerButtons || batchActive || (availableFilters && availableFilters.length > 0) ? (
                <div className="t-filters-container">
                    <div className="t-filters-row">
                        <div className="w-full">
                            <div className="w-full">
                                <UseDateHeader />
                            </div>
                            <div className="grid col-gap-2 row-gap-2 grid-cols-12 mb-4">
                                <div
                                    style={{ marginRight: "-0.4rem" }}
                                    className={` ${
                                        customConfig && customConfig.searchStyle
                                            ? customConfig.searchStyle
                                            : "col-span-2"
                                    }`}
                                >
                                    <UseSearch id={prefixId + "search"} />
                                </div>
                                {isMediaLibrary ? (
                                    <div className=" col-span-4 pr-24">
                                        <UseBatchSelect />
                                    </div>
                                ) : null}
                                <div
                                    className={` relative ${
                                        customConfig && customConfig.filtersStyle
                                            ? customConfig.filtersStyle
                                            : "col-span-10"
                                    } flex items-center justify-between`}
                                >
                                    <div>
                                        <UseFilters />
                                    </div>
                                    {tableLength > 0 && permissions?.hotel?.networkAlerts ? (
                                        <ChromeCastWarnings />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    {isMediaLibrary ? null : <UseBatchSelect />}
                </div>
            ) : null}
            <div id="table-body-container">
                <div
                    id="table-body"
                    className={`t-body ${
                        customConfig &&
                        typeof customConfig.pagination !== "undefined" &&
                        customConfig.pagination === false
                            ? "mb-0"
                            : ""
                    } ${customConfig && customConfig.tableBodyStyle ? customConfig.tableBodyStyle : ""}  `}
                >
                    {useCollection ? (
                        <>
                            {isMediaLibrary && <UseFolders />}
                            <UseCards />
                        </>
                    ) : (
                        <>
                            <UseFolders />
                            <UseHeaders />
                            <UseRows />
                        </>
                    )}
                    {technicalProblems ? (
                        <div className="flex items-center space-x-2 text-gray-900 text-lg py-5">
                            <Icon type="warning" size="lg" />
                            <div>
                                {Parser(t("technical problems contact as url", { url: "https://support.zafiro.tv" }))}
                            </div>
                        </div>
                    ) : (
                        <> {informativeMessage ? <div className=" pt-5 ">{t(informativeMessage)}</div> : null}</>
                    )}
                </div>
            </div>
            <MessagesBinWarning />
            {customConfig &&
            typeof customConfig.pagination !== "undefined" &&
            customConfig.pagination === false ? null : results && results.length > 0 ? (
                <UsePagination />
            ) : null}
            {loadingMoreResults ? (
                <div className="text-center first-capital font-bold">{`${langStrings["loading-more-results"]}...`}</div>
            ) : null}
        </div>
    );
};
export default Table;

const needToRestore = (location) => {
    const pLocation = Session.getCurrentPath();
    let typeToRestore = null;
    if (
        (location.pathname.endsWith("hotel/rooms/list") || location.pathname.endsWith("hotel/rooms")) &&
        pLocation.indexOf("/room/detail/") > -1
    ) {
        typeToRestore = `list-rooms`;
    } else if (
        (location.pathname.endsWith("customers/guests/guest-list/") ||
            location.pathname.endsWith("customers/guests/guest-list")) &&
        pLocation.indexOf("guest-list/guest/") > -1
    ) {
        typeToRestore = `list-guests`;
    } else if (
        (location.pathname.endsWith("/common-zones/zones-configuration/categories") ||
            location.pathname.endsWith("common-zones/categories")) &&
        (pLocation.indexOf("common-zones/categories/category/") > -1 ||
            pLocation.indexOf("common-zones/zones-configuration/areas") > -1)
    ) {
        typeToRestore = "categories";
    } else if (
        (location.pathname.endsWith("common-zones/booking/categories/") ||
            location.pathname.endsWith("common-zones/booking/categories")) &&
        pLocation.indexOf("common-zones/booking/categories/area/") > -1
    ) {
        typeToRestore = "booking-categories";
    } else if (
        location.pathname.indexOf("common-zones/categories/category/") > -1 &&
        pLocation.indexOf("common-zones/booking/categories/area/") > -1
    ) {
        typeToRestore = "areas-category";
    } else if (location.pathname.indexOf("/design/interface/list") > -1 && pLocation.indexOf("/design/") > -1) {
        typeToRestore = "list-designs";
    } else if (location.pathname.endsWith("/design/tags") && pLocation.indexOf("/design/tags/detail") > -1) {
        typeToRestore = "list-tags";
    } else if (
        location.pathname.endsWith("/customers/messages/list") &&
        (pLocation.indexOf("/customers/messages/drafts") > -1 ||
            pLocation.indexOf("/customers/messages/sent") > -1 ||
            pLocation.indexOf("/customers/messages/templates") > -1 ||
            pLocation.indexOf("/customers/messages/bin") > -1 ||
            pLocation.indexOf("/customers/messages/list/new") > -1 ||
            pLocation.indexOf("/customers/messages/scheduled") > -1)
    ) {
        typeToRestore = "list-messages";
    } else if (location.pathname.endsWith("tv-channels/channels") && pLocation.indexOf("tv-channels/channel/") > -1) {
        typeToRestore = "list-channels";
    } else if (location.pathname.endsWith("tv-channels/groups") && pLocation.indexOf("tv-channels/groups/") > -1) {
        typeToRestore = "list-group-channels";
    } else if (
        (location.pathname.endsWith("common-zones/zones-configuration/areas/") ||
            location.pathname.endsWith("common-zones/zones-configuration/areas")) &&
        (pLocation.indexOf("common-zones/zones-configuration/area/description/") > -1 ||
            pLocation.indexOf("common-zones/zones-configuration/area/close/") > -1 ||
            pLocation.indexOf("common-zones/booking/categories/area/") > -1)
    ) {
        typeToRestore = "areas";
    }

    return typeToRestore;
};
