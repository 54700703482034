import React from "react";
import ReactTooltip from "components/ReactTooltip";

const UseTabs = ({ color, titles, contents, config = { even: false, pX: "px-4" }, onChangeTabCallback }) => {
    const [openTab, setOpenTab] = React.useState(0);
    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full">
                    <ul className="flex mb-0 list-none flex-wrap flex-row" role="tablist">
                        {titles
                            ? titles.map((title, index) => (
                                  <li
                                      key={index}
                                      className={`text-center ${config.even ? `w-1/${titles.length}` : ""}`}
                                  >
                                      <a
                                          id={title.id || `tab-${index}`}
                                          className={
                                              `` +
                                              " first-capital px-10 py-3 block border-b-4 " +
                                              (openTab === index
                                                  ? `border-${color} t-link `
                                                  : `border-b-0` + (title.disabled ? " text-gray-500" : ""))
                                          }
                                          onClick={(e) => {
                                              e.preventDefault();
                                              setOpenTab(index);
                                              if (onChangeTabCallback) {
                                                  onChangeTabCallback(index);
                                              }
                                          }}
                                          data-toggle="tab"
                                          href={`#link${index}`}
                                          role="tablist"
                                          data-tip={title.tooltip}
                                      >
                                          {title.name}{" "}
                                          {title.image ? (
                                              <img
                                                  alt="active btn"
                                                  className="float-right w-5 h-5 ml-1"
                                                  src={title.image}
                                              ></img>
                                          ) : null}
                                          {title.spinner ? (
                                              <div className={`${title.spinnerAdjust}`}>
                                                  <div className="lds-roller" style={title.spinnerStyle}>
                                                      <div></div>
                                                      <div></div>
                                                      <div></div>
                                                      <div></div>
                                                      <div></div>
                                                      <div></div>
                                                      <div></div>
                                                      <div></div>
                                                  </div>
                                              </div>
                                          ) : null}
                                      </a>
                                  </li>
                              ))
                            : null}
                    </ul>
                    <ReactTooltip
                        place="bottom"
                        type="light"
                        offset={{ top: -8, left: -8 }}
                        html={true}
                        dangerousHtml={true}
                        border={true}
                        borderColor="#D3DAE1"
                    />
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full ">
                        <div className={`${config.pX} pt-5 flex-auto`}>
                            <div className="tab-content tab-space">
                                {contents
                                    ? contents.map((content, index) => (
                                          <div
                                              key={`contentlink${index}`}
                                              className={openTab === index ? "block" : "hidden"}
                                              id={`link${index}`}
                                          >
                                              {content}
                                          </div>
                                      ))
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default UseTabs;
