import React from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setRefreshData, cleanCheckedItems } from "actions/tableActions";
import { gql } from "apollo-boost";
import { useMutation } from "react-apollo";

const ModalSendNowMessages = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const SEND_NOW_SCHEDULE_MUTATION = gql`mutation{
        ${
            props.ids
                ? `sendMessages(
                    ids:[${props.ids}]
                    ){
                    error
                    ok
                }`
                : `updateMessage(
                    sendMessage: true
                    id: ${props.id}
                    ){
                    error
                    id
                    ok
                }`
        }
    }`;

    const [executeMutation] = useMutation(SEND_NOW_SCHEDULE_MUTATION, {
        onCompleted() {
            if (props.batch) {
                dispatch(cleanCheckedItems());
            }
            dispatch(setRefreshData(true));
            props.close();
        },
        onError() {
            toast.error(t("mutation-error"));
        },
    });

    const handleSaveClick = () => {
        executeMutation();
    };

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={
                props?.batch
                    ? `${capitalizeFirst(t("send-now-schedule-message"))}`
                    : `${props?.name} - ${capitalizeFirst(t("send-now-schedule-message"))}`
            }
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-send" onClick={handleSaveClick}>
                        {t("send-now-schedule-message")}
                    </Button>
                </>
            }
            className={"w-5/12 p-10"}
        >
            <div>
                <p>{capitalizeFirst(t("are-you-sure-you-want-to-send-now-this-message"))}</p>
            </div>
        </Modal>
    );
};

export const UseModalSendNowScheduleMessages = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalSendNowMessages {...newProps} />);
        },
    };
};
