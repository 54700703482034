import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
//Actions
import { showGlobalLoading } from "../../../actions/uiActions";
import { setRefreshContentData, setExecuteQuery } from "../../../actions/sectionActions";
//Utils
import { toast } from "react-toastify";

const UpdateComment = () => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { executeQuery } = useSelector((state) => state.sectionContent);

    //Actions
    const dispatch = useDispatch();

    const ADD_COMMENT = gql`
        mutation AddComment($id: Int64!, $comments: stayCommentsInput) {
            updateStay(id: $id, comments: $comments) {
                error
                id
                ok
            }
        }
    `;

    const [executeMutation, { data }] = useMutation(ADD_COMMENT);

    useEffect(() => {
        if (executeQuery && executeQuery.action === "updateComment") {
            dispatch(showGlobalLoading(true));
            dispatch(setExecuteQuery(null));
            setTimeout(function () {
                executeMutation({
                    variables: {
                        id: parseInt(executeQuery.params.id),
                        comments: executeQuery.params.comments,
                    },
                });
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (data && data.updateStay && data.updateStay.ok) {
            toast.success(langStrings["operation-successful"]);
            dispatch(setRefreshContentData(true));
        } else if (data && data.updateStay && data.updateStay.error) {
            toast.error(langStrings["mutation-error"]);
            dispatch(showGlobalLoading(false));
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default UpdateComment;
