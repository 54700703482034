import React from "react";
import { useTranslation } from "react-i18next";

import Icon from "components/Icon";

const ProjectName = ({ name, hasChainModule, size }) => {
    const { t } = useTranslation();

    const icon = hasChainModule ? (
        <Icon type="chain" tooltip={t("This property has access to Global Management content")} size={size} />
    ) : null;

    return name ? (
        <div className="flex items-center space-x-2">
            {icon}
            <div>{name}</div>
        </div>
    ) : (
        icon
    );
};

export default ProjectName;
