import React, { useContext, useState, useEffect } from "react";
import Modal, { useModal } from "components/Modal";
import DateInput from "components/Inputs/DateInput";
import { GlobalContext } from "contexts/Global";
import { useTranslation } from "react-i18next";
import { Radiobutton } from "components/Inputs/Radiobuttons";
import DaySelector from "components/DaySelector";
import DatePicker from "react-datepicker";
import Button from "components/Button";
import Icon from "components/Icon";
import { parseISO, isBefore } from "date-fns";
import moment from "moment";
import { useScheduleSignage } from "../../Design/Advertising/graphql/useSignages";
import { toast } from "react-toastify";
import classNames from "classnames";

const DAYS_TO_DISPLAY_SEQUENCE_ALL_DAYS_OF_WEEK = "all-days-of-week";
const DAYS_TO_DISPLAY_SEQUENCE_FILTER_BY_DAYS_OF_WEEK = "filter-by-days-of-week";
const DAILY_SCHEDULE_ALL_DAY = "all-day";
const DAILY_SCHEDULE_TIME_RANGE = "time-range";

const validateRanges = (timeRanges) => {
    let validationResults = {};

    for (let i = 0; i < timeRanges.length; i++) {
        const range = timeRanges[i];

        let isStartValid = true;
        let isEndValid = true;

        if (!range.startTime) {
            isStartValid = false;
        }
        if (!range.endTime) {
            isEndValid = false;
        }
        if (range.startTime && range.endTime && range.endTime <= range.startTime) {
            isEndValid = false;
        }

        for (let j = 0; j < timeRanges.length; j++) {
            if (i === j) continue;
            const otherRange = timeRanges[j];
            if (
                (range.startTime && range.startTime >= otherRange.startTime && range.startTime < otherRange.endTime) ||
                (range.endTime && range.endTime > otherRange.startTime && range.endTime <= otherRange.endTime)
            ) {
                isStartValid = false;
                isEndValid = false;
                break;
            }
        }

        validationResults[i] = { start: isStartValid, end: isEndValid };
    }

    return validationResults;
};

const validateStartDate = (startDate, setValidFields) => {
    if (!startDate) {
        setValidFields((prevState) => ({
            ...prevState,
            "start-date-input": false,
        }));
        return false;
    }
    return true;
};

const validateEndDate = (startDate, endDate, setValidFields) => {
    if (startDate && endDate) {
        const startIsoDate = parseISO(startDate);
        const endIsoDate = parseISO(endDate);
        if (isBefore(endIsoDate, startIsoDate)) {
            setValidFields((prevState) => ({
                ...prevState,
                "end-date-input": false,
            }));
            return false;
        }
    }
    return true;
};

const validateDaysSelected = (daysToDisplaySequence, daysSelected, setValidFields) => {
    if (daysToDisplaySequence === DAYS_TO_DISPLAY_SEQUENCE_FILTER_BY_DAYS_OF_WEEK) {
        const areDaysSelected = daysSelected.length > 0;
        setValidFields((prevState) => ({
            ...prevState,
            "days-selected": areDaysSelected,
        }));
        return areDaysSelected;
    }
    return true;
};

const validateTimeRanges = (dailySchedule, timeRanges, setValidFields) => {
    if (dailySchedule === DAILY_SCHEDULE_TIME_RANGE) {
        const newValidations = validateRanges(timeRanges);
        setValidFields((prevState) => ({
            ...prevState,
            "times-ranges": newValidations,
        }));
        return !Object.values(newValidations).some((v) => v.start === false || v.end === false);
    }
    return true;
};

const convertTimeRanges = (timeRanges) => {
    return timeRanges.map((range) => {
        return {
            startTime: parseDateHour(range.startTime),
            endTime: parseDateHour(range.endTime),
        };
    });
};

const parseDateHour = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
};

const convertInitialTimeRanges = (timeRanges) => {
    return timeRanges.map((range) => {
        const currentDate = new Date();

        const startTimeParts = range.startTime.split(":");
        const startTime = new Date(currentDate);
        startTime.setHours(parseInt(startTimeParts[0]), parseInt(startTimeParts[1]), parseInt(startTimeParts[2]));

        const endTimeParts = range.endTime.split(":");
        const endTime = new Date(currentDate);
        endTime.setHours(parseInt(endTimeParts[0]), parseInt(endTimeParts[1]), parseInt(endTimeParts[2]));

        return {
            ...range,
            startTime: startTime,
            endTime: endTime,
        };
    });
};

const ModalContent = (props) => {
    const { lang } = useContext(GlobalContext);
    const { data = {}, onCompleted } = props;
    const {
        scheduleDaysOfWeek,
        scheduleDailyType,
        scheduleDaysType,
        scheduleEnd,
        scheduleStart,
        id,
        timeRanges: initialTimeRanges,
    } = data;

    const [startDate, setStartDate] = useState(scheduleStart ? new Date(scheduleStart) : null);
    const [endDate, setEndDate] = useState(scheduleEnd ? new Date(scheduleEnd) : null);
    const [daysToDisplaySequence, setDaysToDisplaySequence] = useState(DAYS_TO_DISPLAY_SEQUENCE_ALL_DAYS_OF_WEEK);
    const [dailySchedule, setDailySchedule] = useState(DAILY_SCHEDULE_ALL_DAY);

    const { t } = useTranslation();
    const { updateScheduleSignage } = useScheduleSignage({
        onCompleted: () => {
            if (typeof onCompleted === "function") {
                onCompleted();
                props.close();
            }
        },
    });

    const [timeRanges, setTimeRanges] = useState([{ startTime: null, endTime: null }]);
    const [daysSelected, setDaysSelected] = useState(scheduleDaysOfWeek || []);
    const [validFields, setValidFields] = useState({
        "start-date-input": true,
        "end-date-input": true,
        "days-selected": true,
        "times-ranges": {
            0: { start: true, end: true },
            1: { start: true, end: true },
            2: { start: true, end: true },
            3: { start: true, end: true },
        },
    });

    const maxRangesTimes = 4;

    const updateScheduleItem = (index, key, value) => {
        const updatedRanges = [...timeRanges];
        updatedRanges[index][key] = value;
        setTimeRanges(updatedRanges);
    };

    const deleteScheduleItem = (index) => {
        const updatedRanges = timeRanges.filter((_, i) => i !== index);
        setTimeRanges(updatedRanges);
    };

    const addRange = () => {
        setTimeRanges([...timeRanges, { startTime: null, endTime: null }]);
    };

    const selectedDays = startDate && endDate ? { from: startDate, to: endDate } : startDate;
    const modifiers = {
        start: startDate,
        end: endDate,
    };

    useEffect(() => {
        if (scheduleDailyType) {
            if (scheduleDailyType === "ALL_DAY") {
                setDailySchedule(DAILY_SCHEDULE_ALL_DAY);
            } else {
                setDailySchedule(DAILY_SCHEDULE_TIME_RANGE);
            }
        }

        if (scheduleDaysType) {
            if (scheduleDaysType === "ALL_DAYS") {
                setDaysToDisplaySequence(DAYS_TO_DISPLAY_SEQUENCE_ALL_DAYS_OF_WEEK);
            } else {
                setDaysToDisplaySequence(DAYS_TO_DISPLAY_SEQUENCE_FILTER_BY_DAYS_OF_WEEK);
            }
        }
    }, [scheduleDailyType, scheduleDaysType]);

    useEffect(() => {
        if (initialTimeRanges?.length > 0) {
            setTimeRanges(convertInitialTimeRanges(initialTimeRanges));
        }
    }, [initialTimeRanges]);

    return (
        <Modal
            title={t("schedule-secuence")}
            className=" p-10 w-4/12"
            footer={
                <>
                    <Button onClick={() => props?.close()} id="modal-button-cancel" design="blue-outline">
                        {t("cancel")}
                    </Button>
                    <Button
                        id="modal-button-save"
                        onClick={() => {
                            let isValid = true;

                            isValid = validateStartDate(startDate, setValidFields) && isValid;
                            isValid = validateEndDate(startDate, endDate, setValidFields) && isValid;
                            isValid =
                                validateDaysSelected(daysToDisplaySequence, daysSelected, setValidFields) && isValid;
                            isValid = validateTimeRanges(dailySchedule, timeRanges, setValidFields) && isValid;

                            if (isValid) {
                                updateScheduleSignage({
                                    variables: {
                                        id: id,
                                        timeRanges:
                                            dailySchedule === DAILY_SCHEDULE_TIME_RANGE
                                                ? convertTimeRanges(timeRanges)
                                                : [],
                                        scheduleStart: startDate ? moment(startDate).utc().format("YYYY-MM-DD") : null,
                                        scheduleEnd: endDate ? moment(endDate).utc().format("YYYY-MM-DD") : null,
                                        scheduleDaysType:
                                            daysToDisplaySequence === DAYS_TO_DISPLAY_SEQUENCE_FILTER_BY_DAYS_OF_WEEK
                                                ? "SPECIFIC_DAYS"
                                                : "ALL_DAYS",
                                        scheduleDaysOfWeek:
                                            daysToDisplaySequence === DAYS_TO_DISPLAY_SEQUENCE_FILTER_BY_DAYS_OF_WEEK
                                                ? daysSelected
                                                : [],
                                        scheduleDailyType:
                                            dailySchedule === DAILY_SCHEDULE_TIME_RANGE ? "TIME_RANGE" : "ALL_DAY",
                                    },
                                });
                            } else {
                                toast.error(t("input error"));
                            }
                        }}
                        design="blue"
                    >
                        {t("save")}
                    </Button>
                </>
            }
        >
            <div>{t("schedule-tv-interface-sequence")}</div>
            <div className=" flex w-full justify-between">
                <div className=" flex  w-9/12 mt-4">
                    <div className=" mr-12">
                        <label className=" font-bold text-base mb-2 block">{t("start-date")}*</label>

                        <DateInput
                            id="start-date-input"
                            value={startDate}
                            onChange={setStartDate}
                            placeholder={lang === "en" ? "mm/dd/yyyy" : "dd/mm/yyyy"}
                            required={validFields?.["start-date-input"] === false ? true : false}
                            disabledDays={endDate ? { after: endDate } : undefined}
                            selectedDays={selectedDays}
                            modifiers={modifiers}
                        />
                    </div>
                    <div>
                        <label className=" font-bold text-base mb-2 block">{t("end-date")}</label>
                        <DateInput
                            id="end-date-input"
                            value={endDate}
                            onChange={setEndDate}
                            placeholder={lang === "en" ? "mm/dd/yyyy" : "dd/mm/yyyy"}
                            required={validFields?.["end-date-input"] === false ? true : false}
                            disabledDays={startDate ? { before: startDate } : undefined}
                            selectedDays={selectedDays}
                            modifiers={modifiers}
                        />
                    </div>
                </div>
            </div>
            <div className=" mt-10 flex flex-col ">
                <div className=" mb-2">{t("select-days-to-display-sequence")}</div>
                <div className=" mb-2 inline-block">
                    <Radiobutton
                        id={DAYS_TO_DISPLAY_SEQUENCE_ALL_DAYS_OF_WEEK + "-radio"}
                        checked={daysToDisplaySequence === DAYS_TO_DISPLAY_SEQUENCE_ALL_DAYS_OF_WEEK}
                        value={DAYS_TO_DISPLAY_SEQUENCE_ALL_DAYS_OF_WEEK}
                        label={t(DAYS_TO_DISPLAY_SEQUENCE_ALL_DAYS_OF_WEEK)}
                        onChange={({ checked, value }) => {
                            setDaysToDisplaySequence(value);
                        }}
                    />
                </div>
                <div className=" mb-2 inline-block">
                    <Radiobutton
                        id={DAYS_TO_DISPLAY_SEQUENCE_FILTER_BY_DAYS_OF_WEEK + "-radio"}
                        checked={daysToDisplaySequence === DAYS_TO_DISPLAY_SEQUENCE_FILTER_BY_DAYS_OF_WEEK}
                        value={DAYS_TO_DISPLAY_SEQUENCE_FILTER_BY_DAYS_OF_WEEK}
                        label={t(DAYS_TO_DISPLAY_SEQUENCE_FILTER_BY_DAYS_OF_WEEK)}
                        onChange={({ checked, value }) => {
                            setDaysToDisplaySequence(value);
                        }}
                    />
                    {daysToDisplaySequence === DAYS_TO_DISPLAY_SEQUENCE_FILTER_BY_DAYS_OF_WEEK ? (
                        <div className=" mt-4 ml-6">
                            <DaySelector
                                defaultValue={daysSelected}
                                onChange={(values) => setDaysSelected(values)}
                                width={"50%"}
                                id={"schedule-secuence-day-selector"}
                                isValid={validFields["days-selected"] === false ? false : true}
                            />
                            {validFields["days-selected"] === false ? (
                                <span className=" text-red-100">{t("select-at-least-a-weekday")}</span>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>

            <div className=" flex flex-col">
                <div className=" mt-10 mb-2">{t("select-daily-schedule-of-sequence")}</div>
                <div className=" mb-2 inline-block">
                    <Radiobutton
                        id={DAILY_SCHEDULE_ALL_DAY + "-radio"}
                        checked={dailySchedule === DAILY_SCHEDULE_ALL_DAY}
                        value={DAILY_SCHEDULE_ALL_DAY}
                        label={t(DAILY_SCHEDULE_ALL_DAY)}
                        onChange={({ checked, value }) => {
                            setDailySchedule(value);
                        }}
                    />
                </div>
                <div className=" mb-2 inline-block">
                    <Radiobutton
                        id={DAILY_SCHEDULE_TIME_RANGE + "-radio"}
                        checked={dailySchedule === DAILY_SCHEDULE_TIME_RANGE}
                        value={DAILY_SCHEDULE_TIME_RANGE}
                        label={t(DAILY_SCHEDULE_TIME_RANGE)}
                        onChange={({ checked, value }) => {
                            setDailySchedule(value);
                        }}
                    />
                    {dailySchedule === DAILY_SCHEDULE_TIME_RANGE ? (
                        <div className=" mt-5">
                            <div
                                className=" pr-52 ml-6"
                                style={{ minHeight: "0rem", maxHeight: "10rem", overflowY: "scroll" }}
                            >
                                {timeRanges.map((range, index) => (
                                    <TimePickerComponent
                                        key={index}
                                        index={index}
                                        startTime={range.startTime}
                                        endTime={range.endTime}
                                        updateScheduleItem={updateScheduleItem}
                                        deleteScheduleItem={deleteScheduleItem}
                                        isValid={validFields?.["times-ranges"]?.[index]}
                                    />
                                ))}
                            </div>
                            {timeRanges.length < maxRangesTimes && (
                                <Button
                                    onClick={() => addRange()}
                                    id="add-range-time"
                                    className=" text-zafiro-600 ml-6"
                                >
                                    <Icon type="add"></Icon>
                                    <span className=" font-bold">{t("add-range")}</span>
                                </Button>
                            )}
                        </div>
                    ) : null}
                </div>
            </div>
        </Modal>
    );
};

const TimePickerComponent = ({ index, startTime, endTime, isValid, updateScheduleItem, deleteScheduleItem }) => {
    const { t } = useTranslation();

    const parseDateHour = (value) => {
        return value;
    };

    return (
        <div className="flex items-center h-full w-full mb-2">
            <div className="flex flex-col mr-2">
                <div className="text-sm font-bold mb-2 text-gray-800">{t("start-date")}*</div>
                <DatePicker
                    selected={startTime}
                    onChange={(value) => {
                        updateScheduleItem(index, "startTime", parseDateHour(value));
                    }}
                    dateFormat="HH:mm"
                    timeCaption="Hour"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    name={`startTime_${index}`}
                    isClearable={false}
                    customInput={
                        <input
                            type="time"
                            id={`start-time-input-range-${index}`}
                            className={`time-input t-filter-input text-center inline-block py-2 px-3 w-full ${
                                isValid?.start === false ? "border border-red-100" : " border border-transparent"
                            }`}
                            placeholder="hh:mm"
                        />
                    }
                />
            </div>
            <div className="flex flex-col ml-2">
                <div className="text-sm font-bold mb-2 text-gray-800">{t("end-date")}*</div>
                <DatePicker
                    selected={endTime}
                    onChange={(value) => {
                        updateScheduleItem(index, "endTime", parseDateHour(value));
                    }}
                    dateFormat="HH:mm"
                    timeCaption="Hour"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    name={`endTime_${index}`}
                    isClearable={false}
                    customInput={
                        <input
                            type="time"
                            id={`end-time-input-range-${index}`}
                            className={`time-input t-filter-input text-center inline-block py-2 px-3 w-full ${
                                isValid?.end === false ? "border border-red-100" : " border border-transparent"
                            }`}
                            placeholder="hh:mm"
                        />
                    }
                />
            </div>

            <Button
                onClick={() => deleteScheduleItem(index)}
                className={classNames({
                    "pl-5 h-full mt-6": true,
                    "opacity-0": index === 0,
                    "opacity-100": index !== 0,
                })}
                id={`time-picker-delete${index}`}
                disabled={index === 0}
            >
                <Icon type="delete" size={1.5} className="text-gray-800" />
            </Button>
        </div>
    );
};

const useScheduleSequence = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalContent {...newProps} />);
        },
    };
};

export default useScheduleSequence;
