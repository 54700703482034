import React from "react";
import { useSelector } from "react-redux";

const EmptyWidget = ({ type, radius }) => {
    const { widgets } = useSelector((state) => state.design);
    const currentType = widgets.filter((w) => w && w.type === type)[0];
    const icon = currentType && currentType.icon ? currentType.icon : "warning";
    return (
        <div
            className="table h-full w-full overflow-hidden mr-auto ml-auto text-center bg-gray-200"
            style={{ borderRadius: radius ? radius + "rem" : 0 }}
        >
            <div className="table-cell align-middle">
                <span className={`icon icon-${icon} text-gray-700`} style={{ fontSize: "3em" }}></span>
            </div>
        </div>
    );
};

export default EmptyWidget;
