import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";

import { Session } from "hooks/Utils/Session";
import { pathsWithoutChainOrProjectRef } from "hooks/Utils/Utils";
import { openModal, setDropdownMenu, setModalContent, showGlobalLoading } from "actions/uiActions";
import useComponentVisible from "components/useComponentVisible";
import UseNotificationIcon from "components/Notifications/useNotificationIcon";

import { useAuth } from "hooks/Session/auth";

import Button from "components/Button";
import Icon from "components/Icon";

import { GlobalContext } from "contexts/Global";

export const useShowPrivacyPolicy = () => {
    const dispatch = useDispatch();
    return {
        show: () => {
            dispatch(
                setModalContent({
                    title: "privacy-policy-title",
                    bodyAdjust: "w-3/5 text-justify max-h-80 overflow-y-scroll",
                    inputs: [
                        {
                            text: "privacy-policy-text",
                            type: "htmltext",
                        },
                    ],
                    buttons: [
                        {
                            name: "accept",
                            style: "blue",
                            action: "close",
                        },
                    ],
                })
            );
            dispatch(openModal());
        },
    };
};

export const useShowReleaseNotes = () => {
    const { onVersionUpdated, setReleaseNotesAsRead } = useContext(GlobalContext);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    return {
        showReleaseNotes: (title) => {
            setReleaseNotesAsRead(true);
            dispatch(
                setModalContent({
                    bodyAdjust: `w-3/${window.innerWidth > 1700 ? "6" : "5"} text-justify max-h-80 overflow-y-hidden`,
                    text: t("updates-and-releases"),
                    textAdjust: "font-bold text-3xl pb-8",
                    inputs: [
                        {
                            type: "releaseNotes",
                        },
                    ],
                    buttons: [
                        {
                            name: "accept",
                            style: "blue",
                            action: "close",
                        },
                    ],
                })
            );
            dispatch(openModal());
            onVersionUpdated(false);
        },
    };
};

const IconInfo = () => {
    const { t } = useTranslation();

    const { isSuperUser, isCorporate, isImpersonating, project, lang, isVersionUpdated } = useAuth();

    const dispatch = useDispatch();
    const location = useLocation();

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const { dropdownOpened, permissions } = useSelector((state) => state.ui);

    const { show } = useShowPrivacyPolicy();
    const { showReleaseNotes } = useShowReleaseNotes();

    const projectRef = project?.ref;
    const hasGuestProduct = permissions?.product?.guest;
    const supportLinkEnabled = !(isSuperUser || isImpersonating);

    const GET_SUPPORT_URL = gql`
    {
        loginTicketing(lang :"${lang}") 
    }
    `;

    const GET_PMS_STATUS = gql`
        {
            pmsStatus {
                lastActivityTime
            }
        }
    `;

    const [executeSupportQuery, supportQueryResponse] = useLazyQuery(GET_SUPPORT_URL, {
        fetchPolicy: "network-only",
    });

    const [getPMSStatus, { loading: loadingPMSStatus, data: pms, error }] = useLazyQuery(GET_PMS_STATUS, {
        fetchPolicy: "cache-and-network",
    });

    const pmsLastActivity = pms?.pmsStatus?.lastActivityTime ? new Date(pms?.pmsStatus?.lastActivityTime) : null;
    // Warning if last activity is more than 12 hours ago
    const pmsWarning = pmsLastActivity ? new Date() - pmsLastActivity > 43200000 : false;
    const pmsLastActivityInfo = (
        <span className={`${pmsWarning ? "text-orange-100" : ""} ${pmsLastActivity ? "" : " text-gray-400"}`}>
            {pmsLastActivity
                ? pmsLastActivity.toLocaleString(lang, {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                  })
                : t("unknown")}
        </span>
    );

    //States
    const [notifNumber, setNotifNumber] = useState(0);

    const clickEvent = (visible) => {
        setIsComponentVisible(visible);
        if (visible) {
            dispatch(setDropdownMenu("info"));
        } else {
            dispatch(setDropdownMenu(null));
        }
    };

    const handleSupportClick = () => {
        dispatch(showGlobalLoading(true));
        executeSupportQuery();
    };

    useEffect(() => {
        if (supportQueryResponse.data && supportQueryResponse.data.loginTicketing) {
            dispatch(showGlobalLoading(false));
            Session.setSessionProp("supportLink", supportQueryResponse.data.loginTicketing);
            window.open(supportQueryResponse.data.loginTicketing, "_blank").focus();
        } else {
            dispatch(showGlobalLoading(false));
        }
        // eslint-disable-next-line
    }, [supportQueryResponse.data]);

    useEffect(() => {
        if (dropdownOpened !== "info") {
            setIsComponentVisible(false);
        }
        // eslint-disable-next-line
    }, [dropdownOpened]);

    useEffect(() => {
        setNotifNumber(isVersionUpdated ? 1 : 0);
    }, [isVersionUpdated]);

    useEffect(() => {
        if (projectRef && !isCorporate && ![...pathsWithoutChainOrProjectRef].includes(location.pathname)) {
            getPMSStatus();
        }
        //Revisar aqui para desencadenar un estado de redux!
        // eslint-disable-next-line
    }, [projectRef]);

    const Link = (props) => (
        <Button design="text-link" className="flex items-center space-x-2" {...props}>
            {props?.children}
        </Button>
    );

    return (
        <div className="relative" ref={ref}>
            <div
                onClick={() => clickEvent(!isComponentVisible ? true : false)}
                id={`icon-info-button`}
                className={`icon icon-info text-blue-1100 menu-icon p-2 cursor-pointer ${
                    isComponentVisible ? "text-blue-300 font-black" : ""
                }`}
            ></div>
            {notifNumber ? (
                <div className="infoIconPos">
                    <UseNotificationIcon number={notifNumber} />
                </div>
            ) : null}
            {isComponentVisible ? (
                <div
                    onClick={() => clickEvent(false)}
                    className="bg-white absolute mt-1 w-64 shadow-xl rounded-md border border-gray-200 flex flex-col space-y-4 p-3"
                    style={{ left: "-12rem" }}
                >
                    <div>
                        <div className="text-sm first-capital">{t("help")}</div>
                        <Link id="icon-info-support-link" disabled={!supportLinkEnabled} onClick={handleSupportClick}>
                            <div>{t("support")}</div>
                            <Icon type="external-link" size="xl" />
                        </Link>
                    </div>
                    <div>
                        <div className="text-sm first-capital">{t("system-updates")}</div>
                        <Link id="icon-info-show-release-notes-button" onClick={showReleaseNotes}>
                            <span>{t("updates-and-releases")}</span>
                            {isVersionUpdated ? (
                                <div style={{ width: "1.3rem", height: "1.3rem" }}>
                                    <UseNotificationIcon number={1} />
                                </div>
                            ) : null}
                        </Link>
                    </div>
                    {!isCorporate ? (
                        <div>
                            <div className="text-sm first-capital">{t("my-account")}</div>
                            <Link
                                id="icon-info-show-contracted-products-link"
                                to="/information/my-account/contracted-products"
                            >
                                {t("contracted-products")}
                            </Link>
                        </div>
                    ) : null}
                    <div>
                        <div className="text-sm first-capital">{t("legal-matters")}</div>
                        <Link id="icon-info-show-privacy-policy" onClick={show}>
                            {t("privacy-policy")}
                        </Link>
                    </div>

                    {!isCorporate && hasGuestProduct && (
                        <div>
                            <div id={`icon-info-show-pms-last-activity`} className="text-sm first-capital">
                                {t("pms last activity")}
                            </div>
                            <div id={`icon-info-pms-status`} className={`text-gray-900 py-2 text-sm `}>
                                {loadingPMSStatus ? "..." : pmsLastActivityInfo}
                            </div>
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default IconInfo;
