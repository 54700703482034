import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setExecuteQuery } from "../../../../../../actions/sectionActions";
import UseSwitch from "../../../../../../components/Inputs/useSwitch";
import Widget from "../../Widget";

import { EditorContext, WidgetContext } from "../../../../../../contexts/editor";

const InputCheck = ({
    id,
    title,
    icon,
    toolbarID,
    deviceSpecific,
    dataType,
    dafultValue: defaultValue,
    opposedValue,
    adjustTitle,
}) => {
    const { deviceType } = useContext(EditorContext);
    const { type, config: cfg, style, id: widgetID } = useContext(WidgetContext);

    const dispatch = useDispatch();

    const { gridItems } = useSelector((state) => state.design);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;

    const config = dataType === "style" ? style : Widget.ParseData(widget ? widget.data : null, deviceType);

    const implementNewStyles = Widget.ImplementNewLibrary(type);

    const defaultChecked =
        (implementNewStyles ? cfg.style.exportById(id) : style && style[id] ? style[id] : defaultValue) || null;
    const [checked, setChecked] = useState(defaultChecked);

    const updateWidget = (value) => {
        dispatch(
            setExecuteQuery(
                dataType === "style"
                    ? Widget.UpdateStyle({
                          widget,
                          property: id,
                          value,
                          deviceSpecific: deviceSpecific ? deviceType : null,
                      })
                    : Widget.UpdateData({
                          widget,
                          property: id,
                          value,
                          deviceSpecific: deviceSpecific ? deviceType : null,
                      })
            )
        );
    };

    const getChechedValue = () => {
        const customized =
            (implementNewStyles
                ? cfg.style.isCustomizedById(id)
                : config && (config[id] || config[id] === false)
                ? config[id]
                : defaultValue) || null;
        setChecked(customized);
    };

    useEffect(() => {
        getChechedValue();
        // eslint-disable-next-line
    }, []);

    /*
    useEffect(() => {
        if (checked !== undefined && config[id] !== checked) {
            setChecked(config[id]);
        } else if (deviceSpecific && checked !== undefined && config.devices[deviceType][id] !== checked) {
            setChecked(config.devices[deviceType][id]);
        }
        // eslint-disable-next-line
    }, [config[id]]);
    */

    const input = (
        <UseSwitch
            showLabel={false}
            icon={icon}
            checked={
                opposedValue && config[id] !== undefined
                    ? typeof config[id] === "boolean"
                        ? !config[id]
                        : checked
                    : checked
            }
            action={() => {
                setChecked(!checked);
                updateWidget(!checked);
            }}
            id={`${id}_${widgetID}`}
        />
    );

    return icon && !title ? (
        <div className="inline-block">{input}</div>
    ) : (
        <div className={`flex w-full mb-2`}>
            {title ? <div className={`first-capital ${adjustTitle ? adjustTitle : "w-3/12"}`}>{title}</div> : null}
            <div className="w-6/12">{input}</div>
        </div>
    );
};

export default InputCheck;
