import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
    OKTOCONTINUE_HORIZONTAL_ALIGN,
    OKTOCONTINUE_VERTICAL_ALIGN,
    OKTOCONTINUE_SHOW,
} from "./Widgets/WidgetToolbars/OkToContinueAligment";

const WelcomeOKToContinue = () => {
    const { t } = useTranslation();
    const { cScreenData } = useSelector((state) => state.design);
    //default alignment
    const [align, setAlign] = useState({ right: "0px", bottom: "0px" });
    const [showOkToContinue, setShowOkToContinue] = useState(
        cScreenData && cScreenData.contentStyle && cScreenData.contentStyle[OKTOCONTINUE_SHOW]
            ? cScreenData.contentStyle[OKTOCONTINUE_SHOW]
            : true
    );

    useEffect(() => {
        if (
            cScreenData &&
            cScreenData.contentStyle &&
            cScreenData.contentStyle[OKTOCONTINUE_HORIZONTAL_ALIGN] &&
            cScreenData.contentStyle[OKTOCONTINUE_VERTICAL_ALIGN]
        ) {
            setAlign(
                getStyleByPos([
                    cScreenData.contentStyle[OKTOCONTINUE_HORIZONTAL_ALIGN] + "-h",
                    cScreenData.contentStyle[OKTOCONTINUE_VERTICAL_ALIGN] + "-v",
                ])
            );
            setShowOkToContinue(cScreenData.contentStyle[OKTOCONTINUE_SHOW]);
        }
    }, [cScreenData]);

    const getStyleByPos = (pos) => {
        let continueDiv = document.querySelector("#okToContinue");
        let _style = {};
        pos.forEach((element) => {
            switch (element) {
                case "left-h":
                    _style.left = "0px";
                    break;
                case "center-h":
                    _style.left = `calc(50% - ${continueDiv.offsetWidth / 2}px)`;
                    break;
                case "right-h":
                    _style.right = "0px";
                    break;
                case "top-v":
                    _style.top = "0px";
                    break;
                case "center-v":
                    _style.top = "calc(50%)";
                    break;
                case "bottom-v":
                    _style.bottom = "0px";
                    break;
                default:
                    break;
            }
        });
        return _style;
    };

    if (!showOkToContinue) {
        return null;
    }

    return (
        <div
            id="okToContinue"
            className="bg-blue-400 opacity-50 m-1 text-white w-64 absolute text-center py-3"
            style={align}
        >{`[ ${t("press-key-continue", {
            key: "OK",
        })} ]`}</div>
    );
};

export default WelcomeOKToContinue;
