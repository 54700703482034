import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { setActionInput } from "../../actions/globalActions";

const UseInputMail = ({ inputData, validation, ph, currentValue, id }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);

    //Data
    const { name } = inputData;

    //Actions
    const dispatch = useDispatch();

    const blurHandler = (e) => {
        const dataToAction = { [e.target.name]: e.target.value };
        dispatch(setActionInput(dataToAction));
    };

    //Listeners
    useEffect(() => {
        if (currentValue) {
            const dataToAction = { [name]: currentValue };
            dispatch(setActionInput(dataToAction));
        }
        // eslint-disable-next-line
    }, []);

    return (
        <input
            id={id ? id : null}
            type="email"
            name={name}
            className={`w-full bg-gray-200 rounded p-2 ${!validation ? "border border-red-100 " : ""}${
                inputData.name === "mail" ? " w-full" : "long"
            }`}
            placeholder={ph ? ph : langStrings["email"]}
            onChange={blurHandler}
            defaultValue={currentValue ? currentValue : ""}
        ></input>
    );
};

export default UseInputMail;
