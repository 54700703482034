import React from "react";
import UseSectionHeader from "../../useSectionHeader";
import GetFont from "../../../hooks/GraphqlCalls/MediaLibrary/GetFont";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Style from "./Style";
import FontsActions from "../../../hooks/GraphqlCalls/MediaLibrary/FontsActions";

const FontStyles = () => {
    const { t } = useTranslation();
    const { fontSelected } = useSelector((state) => state.sectionContent);
    const customBC = [
        {
            name: "design",
            route: "/design",
            cookieRoute: null,
        },
        {
            name: "library",
            route: "/design/library/root/media",
            cookieRoute: null,
        },
        {
            name: "fonts",
            route: "/design/library/fonts/fonts",
            cookieRoute: null,
        },
    ];

    GetFont();
    // FontsActions();

    // const downloadAllStyles = () => {
    //     console.log("DOWNLOAD ALL STYLES OF FONT");
    // };

    return (
        <>
            <FontsActions />
            <UseSectionHeader title={t("font-styles")} customBreadCrumbs={customBC} navToPreviousPage={true} />
            {!fontSelected ? (
                <div>{t("no-font-found")}</div>
            ) : (
                <div className="bg-white w-full grid gap-4 grid-cols-3">
                    <div className="col-span-2 align-middle text-3xl mt-8 ml-8">{fontSelected.name}</div>
                    {/* TODO: zip file to use in download all style */}
                    <div className="z-20 mt-8 mr-8 ">
                        {/* <div onClick={(e) => downloadAllStyles(e)} className="float-right">
                            <UseButton
                                buttonName={"download-all-styles"}
                                buttonColor={"btn-white"}
                                adjust="w-auto px-4 min-w-24 right-4 m-auto"
                            />
                        </div> */}
                    </div>
                    {fontSelected.styles.map((style, index) => (
                        <Style items={fontSelected.items} style={style} key={index} index={index} />
                    ))}
                </div>
            )}
        </>
    );
};

export default FontStyles;
