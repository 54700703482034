import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSectionContent } from "../../../actions/sectionActions";
import { BACKGROUND, EXTERNAL, SALES_OPTIONS_KEYS } from "../../Utils/Design/SalesUtils";
import { gql } from "apollo-boost";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { LATO_FONT, textAlignment } from "../../Utils/Design/FontUtils";

const UpdateSalesStyle = () => {
    const dispatch = useDispatch();
    const { styleInputData, salesStyleData, screenIds, text, title, salesTitleData, background, ordersButton } =
        useSelector((state) => state.sectionContent);

    const { cLang, editorMode } = useSelector((state) => state.design);
    const { designID, salesId } = screenIds;
    const [mutation, setMutation] = useState("mutation{checkIn}");
    const [saveData, setSaveData] = useState(false);
    const [store, setStore] = useState(null);

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            toast.error(err.message);
        },
    });

    useEffect(() => {
        if (styleInputData?.key?.listOption && styleInputData?.key?.input) {
            const storeValue = getStoreValue(styleInputData.key);
            if (
                (styleInputData.value || parseInt(styleInputData.value) === 0) &&
                //storeValue !== null &&
                storeValue !== undefined &&
                storeValue !== styleInputData.value
            ) {
                const newData = putValueToStore(styleInputData.key);
                setDataInMutation(newData.salesStyleData);
            }
        } else if (styleInputData.generalSalesSave) {
            setDataInMutation(salesStyleData);
        }
        // eslint-disable-next-line
    }, [styleInputData]);

    useEffect(() => {
        setDataInMutation(salesStyleData);
    }, [text, title, ordersButton]);

    const setDataInMutation = (style) => {
        setStore(style);

        if (editorMode !== "PREVIEW") {
            setMutation(
                `mutation{
                    updatePredefinedScreen(
                        designID: ${designID}
                        id: ${salesId}
                        data: ${prepareJsonForMutation(style)}
                        ){ error ok }}`
            );
            // launch mutation + set in store when mutation is done and correct with no errors
            setSaveData(true);
        }
    };

    useEffect(() => {
        if (saveData) {
            executeMutation();
            // RESET of styleInputData
            dispatch(
                setSectionContent({ styleInputData: { ...styleInputData, value: null, generalSalesSave: false } })
            );
            setSaveData(false);
        }
        // eslint-disable-next-line
    }, [saveData]);

    useEffect(() => {
        if (data?.updatePredefinedScreen?.ok && !styleInputData.generalSalesSave) {
            dispatch(setSectionContent({ salesStyleData: store }));
        }
        // eslint-disable-next-line
    }, [data]);

    const getStoreValue = (key) => {
        const { listOption, input, status, option, group, field } = key;
        const opt = salesStyleData && listOption ? salesStyleData[listOption] : null;
        const optInput = opt && input ? opt[input] : null;
        const optInputStatus = optInput && status ? optInput[status] : null;
        const optInputStatusGroup = optInputStatus?.sections && group ? optInputStatus.sections[group] : null;
        const optElm = optInputStatusGroup || optInputStatus;

        let value;
        if (input === SALES_OPTIONS_KEYS.STATUS) {
            value = optElm && option && field && optElm[option] ? optElm[option][field] : null;
        } else if (input === SALES_OPTIONS_KEYS.COLORS) {
            value = optInput && field ? optInput[field] : null;
        } else if (input === SALES_OPTIONS_KEYS.OPACITY || input === SALES_OPTIONS_KEYS.COLOR) {
            value = optInput;
        }
        return value;
    };

    const putValueToStore = (key) => {
        const { listOption, input, status, group, option, field } = key;
        if (key?.listOption && key?.input === SALES_OPTIONS_KEYS.STATUS && key?.status && key?.option && key?.field) {
            let set;
            if (group) {
                set = {
                    ...salesStyleData[listOption][input][status].sections,
                    [group]: {
                        ...salesStyleData[listOption][input][status].sections[group],
                        [option]: {
                            ...salesStyleData[listOption][input][status].sections[group][option],
                            [field]: styleInputData.value,
                        },
                    },
                };
            } else {
                set = {
                    ...salesStyleData[listOption][input][status][option],
                    [field]: styleInputData.value,
                };
            }
            return {
                salesStyleData: {
                    ...salesStyleData,
                    [listOption]: {
                        ...salesStyleData[listOption],
                        [input]: {
                            ...salesStyleData[listOption][input],
                            [status]: {
                                ...salesStyleData[listOption][input][status],
                                [group ? "sections" : option]: set,
                            },
                        },
                    },
                },
            };
        } else if (key?.listOption && key?.input === SALES_OPTIONS_KEYS.COLORS) {
            return {
                salesStyleData: {
                    ...salesStyleData,
                    [listOption]: {
                        ...salesStyleData[listOption],
                        [input]: {
                            ...salesStyleData[listOption][input],
                            [field]: styleInputData.value,
                        },
                    },
                },
            };
        } else if (
            key?.listOption &&
            (key?.input === SALES_OPTIONS_KEYS.OPACITY || key?.input === SALES_OPTIONS_KEYS.COLOR)
        ) {
            return {
                salesStyleData: {
                    ...salesStyleData,
                    [styleInputData.key.listOption]: {
                        ...salesStyleData[styleInputData.key.listOption],
                        [styleInputData.key.input]: styleInputData.value,
                    },
                },
            };
        }
        return {
            salesStyleData,
        };
    };

    const prepareJsonForMutation = (style) => {
        return JSON.stringify(
            JSON.stringify({
                typography: { text: setFont(text), title: setFont(title) },
                title: { [cLang]: salesTitleData.text },
                background: setBackground(),
                style,
                textAlignment: salesTitleData?.alignment ? salesTitleData.alignment : textAlignment.CENTER,
                ...setOrdersButton(),
            })
        );
    };

    const setFont = (font) => {
        let newFont = {};
        if (!font) {
            // LATO default
            newFont.name = LATO_FONT.name;
            newFont.externalUrl = LATO_FONT.externalUrl;
        }
        if (font?.name) {
            newFont.name = font.name;
        }
        if (font?.ref === EXTERNAL) {
            newFont.externalUrl = font.externalUrl;
        } else {
            newFont.libraryRef = font.ref;
        }
        return newFont;
    };

    const setBackground = () => {
        let newBackground = {};
        newBackground.useHomeBg = background.useHomeBg;

        if (background?.type === BACKGROUND.IMAGE_TYPE.LIBRARY) {
            newBackground.libraryRef = background.libraryRef;
        }
        if (background?.type === BACKGROUND.IMAGE_TYPE.EXTERNAL) {
            newBackground.externalUrl = background.externalUrl;
        }
        return newBackground;
    };

    const setOrdersButton = () => {
        if (ordersButton !== undefined || ordersButton !== null) {
            return { ordersButton: ordersButton };
        }
    };

    return null;
};

export default UpdateSalesStyle;
