import React, { useRef } from "react";
import UseCard from "../../../../useCard";
import { useSelector } from "react-redux";

//Components
import UseSectionHeader from "../../../../useSectionHeader";
import UpdateChannels from "../../../../../hooks/GraphqlCalls/Services/UpdateChannels";

const ChannelsGeneralSettings = () => {
    const thirdCardRendered = useRef(false);

    //Consts&states
    const breadcrumbs = [
        {
            name: "services",
            translate: true,
            route: `/services`,
        },
        {
            name: "tv-channels",
            translate: true,
            route: `/services/tv-channels`,
        },
    ];
    const { permissions } = useSelector((state) => state.ui);
    const checkoutInterfacePerm = permissions?.services?.checkoutInterface;
    const highAvailability = permissions?.services?.highAvailability;

    const renderSecondCard = () => {
        // SECOND CARD
        if (checkoutInterfacePerm) {
            return <UseCard type="always-display-tv" adjust="h-full" />;
        }
        if (highAvailability && thirdCardRendered.current !== "high-availability") {
            return <UseCard type="high-availability" />;
        }
        return null;
    };

    const renderThirdCard = () => {
        // THIRD CARD
        if (highAvailability && checkoutInterfacePerm) {
            thirdCardRendered.current = "high-availability";
            return <UseCard type="high-availability" />;
        }

        return null;
    };

    //renders
    return (
        <>
            <UseSectionHeader title={["general-settings"]} customBreadCrumbs={breadcrumbs} />
            <UpdateChannels />
            <div className="flex w-full">
                <div className="flex w-full">
                    <div className="w-6/12">
                        <div className="w-full h-48 mb-10 pr-2">
                            <UseCard type="channels-general-settings-remember-last" adjust="h-full" />
                        </div>
                        <div className="w-full mb-10 pr-2">{renderThirdCard()}</div>
                    </div>
                    {(!thirdCardRendered.current || thirdCardRendered.current === "high-availability") && (
                        <div className="w-6/12">
                            <div className="w-full h-48 mb-10 pr-2">{renderSecondCard()}</div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ChannelsGeneralSettings;
