import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "@apollo/react-hoc";

//ACTIONS
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { setExecuteQuery } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";
import { useParams } from "react-router-dom";

//UTILS
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { removeApolloCacheKeys } from "../../Utils/Utils";

const UpdateAssignedUsers = ({ client, usersAssigned }) => {
    const { t } = useTranslation();
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { values, itemsAffected } = useSelector((state) => state.action);
    const { checkedItems } = useSelector((state) => state.table);
    const [mutation, setMutation] = useState(`
    mutation{
        fake{ error id ok}}`);

    const { id } = useParams();
    const dispatch = useDispatch();

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(err.message);
        },
    });

    const idsToRemove = (array1 = [], array2 = []) => {
        const idsToRemove = new Set(array1.map((id) => Number(id)));
        const newArray2 = array2.filter((item) => !idsToRemove.has(item));

        return newArray2.join(",");
    };

    const mergeArrays = (array1, array2) => {
        const validArray1 = array1.map(Number);
        const validArray2 = array2.map(Number);

        const set = new Set([...validArray1, ...validArray2]);
        return Array.from(set).join(",");
    };

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.mutationName = "";
            execute = true;
            switch (executeQuery.action) {
                case "delete-user":
                    setMutation(`mutation {
                        updateShop(
                          id: ${id}
                          users:[${idsToRemove(itemsAffected, usersAssigned)}]
                        ) {
                          id
                          ok
                        }
                      }
                      `);
                    executeQuery.mutationName = "updateShop";
                    executeQuery.closeModal = true;
                    break;
                case "add-assign-user":
                    setMutation(`mutation {
                        updateShop(
                          id: ${id}
                          users:[${mergeArrays(
                              usersAssigned,
                              values?.["assigned-users-shop"] ? values?.["assigned-users-shop"] : []
                          )}]
                        ) {
                          id
                          ok
                        }
                      }`);
                    executeQuery.mutationName = "updateShop";
                    break;
                case "delete-assigned-users":
                    let finalIdsToRemove = idsToRemove(checkedItems, usersAssigned);
                    if (finalIdsToRemove.trim() === "") {
                        finalIdsToRemove = String(usersAssigned[0]);
                    }
                    setMutation(`mutation {
                        updateShop(
                          id: ${id}
                          users:[${finalIdsToRemove}]
                        ) {
                          id
                          ok
                        }
                      }`);
                    executeQuery.closeModal = true;
                    executeQuery.mutationName = "updateShop";
                    break;
                default:
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (!showGlobalLoading) {
                dispatch(setLoading(true));
            }

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (data && executeQuery && data[executeQuery.mutationName] && data[executeQuery.mutationName].ok === true) {
            toast.success(t("operation-successful"));
            removeApolloCacheKeys(client.cache, "all");
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }
            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            dispatch(showGlobalLoading(false));
            dispatch(setRefreshData(true));
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateAssignedUsers);
