import React, { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { setExecuteQuery } from "../../../../../actions/sectionActions";

import AddImage from "./CommonOptions/AddImage";
import UseSelectWithSearch from "../../../../useSelectWithSearch";

import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Input from "./CommonOptions/Input";
import Widget from "../Widget";
import { setWidgetToolbarVisible } from "../../../../../actions/designActions";
import { capitalizeFirst } from "../../../../../hooks/Utils/Utils";
import { EditorContext, WidgetContext } from "../../../../../contexts/editor";
import Select from "./CommonOptions/Select";
import {
    getFontSizes,
    getOptionsFontList,
    getFontNameOptionSelected,
    getTextSizeSelected,
} from "../../../../../hooks/Utils/Design/FontUtils";
import { getParameterFromWidgetStyle } from "../../../../../hooks/Utils/Design/WidgetUtils";
import { GetThemeDefault } from "../../../../../hooks/Utils/DesignUtils";

const AlarmToolbar = () => {
    const { deviceType, editorSize, itemSelected, theme } = useContext(EditorContext);
    const { config, id: widgetID, type } = useContext(WidgetContext);
    const { fonts } = useSelector((state) => state.ui);

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const { gridItems, widgetToolbarVisibleTab, widgetToolbarVisible, widgetMenuColorsAndAnimations } = useSelector(
        (state) => state.design
    );
    // const tabs = [{ id: "regular", name: t("regular") }];
    const tabs = [
        { id: "regular", name: t("no-alarm-set") },
        { id: "AlarmSet", name: t("alarm-set") },
    ];
    const [currentTextTab, setCurrentTextTab] = useState(tabs[0].id);
    const [currentStyleTab, setCurrentStyleTab] = useState(tabs[0].id);

    const adjustPredefinedOptions = [
        {
            id: "cover",
            name: capitalizeFirst(t("fill container")),
        },
        {
            id: "cover-adjust",
            name: capitalizeFirst(t("stretch image")),
        },
        {
            id: "contain",
            name: capitalizeFirst(t("fit into container")),
        },
    ];

    //States
    const [adjust, setAdjust] = useState(null);

    // const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const [widget, setWidget] = useState(gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null);
    const widgetInsideMenu = widget && widget.insideMenu === true ? true : false;
    const style = config?.style || Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);

    useEffect(() => {
        const _widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
        setWidget(_widget);
        // eslint-disable-next-line
    }, [gridItems]);

    const data = config?.data || Widget.ParseData(widget ? widget.data : null, deviceType);

    const showIcon = data ? data.showIcon : false;

    // const selectTab = (type, tab) => {
    //     setCurrentTab(tab);
    //     dispatch(setWidgetToolbarVisible(type, tab));
    // };

    useEffect(() => {
        dispatch(setWidgetToolbarVisible("layout", currentTextTab));
        // eslint-disable-next-line
    }, [currentTextTab]);

    useEffect(() => {
        dispatch(setWidgetToolbarVisible("style", currentStyleTab));
        // eslint-disable-next-line
    }, [currentStyleTab]);

    useEffect(() => {
        return () => {
            dispatch(setWidgetToolbarVisible(null, "regular"));
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (widgetToolbarVisible === "layout" && widgetToolbarVisibleTab !== currentTextTab) {
            setCurrentTextTab(widgetToolbarVisibleTab);
        } else if (widgetToolbarVisible === "style" && widgetToolbarVisibleTab !== currentStyleTab) {
            setCurrentStyleTab(widgetToolbarVisibleTab);
        }
        // eslint-disable-next-line
    }, [widgetToolbarVisibleTab, widgetToolbarVisible]);

    const reloadFields = () => {
        const gItem = gridItems.filter((gridItem) => gridItem.i === widgetID)[0];
        if (gItem && gItem.data && gItem.data.adjust) {
            setAdjust(gItem.data.adjust);
        } else {
            setAdjust("cover");
        }
    };

    const updateImageOptions = (value) => {
        dispatch(setExecuteQuery(Widget.UpdateData({ widget, property: "adjust", value })));
    };

    const getMenuColors = () => {
        if (currentStyleTab === tabs[0].id || currentStyleTab === tabs[1].id) {
            return [
                widgetMenuColorsAndAnimations.fgColor,
                widgetMenuColorsAndAnimations.bgColor,
                widgetMenuColorsAndAnimations.iconColor,
            ];
        } else {
            return [null, null, null];
        }
    };

    // useEffect(() => {
    //     if (widgetToolbarVisible === "layout" && widgetID === itemSelected) {
    //         reloadFields();
    //     }
    //     // eslint-disable-next-line
    // }, [widgetToolbarVisible]);

    // console.log(currentStyleTab);

    useEffect(() => {
        if (itemSelected === widgetID) {
            reloadFields();
        }
        // eslint-disable-next-line
    }, [gridItems]);

    return (
        <>
            <Toolbar type="layout">
                {tabs && tabs.length > 1 ? (
                    <Toolbar.Tabs
                        data={tabs}
                        currentTab={currentTextTab}
                        setCurrentTab={(tab) => {
                            setCurrentTextTab(tab);
                        }}
                    />
                ) : null}
                {tabs && tabs.length > 0
                    ? tabs.map((tab) => {
                          const isRegular = tab.id === "regular";
                          const fontNameId = isRegular ? "fontName" : "fontName" + tab.id;
                          const textSizeId = isRegular ? "textSize" : "textSize" + tab.id;
                          const boldId = isRegular ? "bold" : "bold" + tab.id;
                          const italicId = isRegular ? "italic" : "italic" + tab.id;
                          const underlineId = isRegular ? "underline" : "underline" + tab.id;
                          return (
                              <Settings key={tab.id + "-tab-text-content"} visible={tab.id === currentTextTab}>
                                  <Settings.Group title={t("Text options")}>
                                      {isRegular ? <Input.Text id="title" title={t("text")} t={t} /> : null}
                                      <div className="flex justify-between">
                                          {theme && isRegular && (
                                              <Select
                                                  title={t("styletype")}
                                                  id={isRegular ? "styletype" : "styletypeAlarmSet"}
                                                  // deviceSpecific={true}
                                                  adjust="w-full mr-2"
                                                  data={{
                                                      selected:
                                                          widget.widgetStyle && widget.widgetStyle.styletype
                                                              ? widget.widgetStyle.styletype
                                                              : "paragraph",
                                                      options: [
                                                          { value: "header", text: t("heading") },
                                                          { value: "paragraph", text: t("paragraph") },
                                                      ],
                                                  }}
                                              />
                                          )}
                                          <Select
                                              title={t("font")}
                                              id={fontNameId}
                                              // deviceSpecific={true}
                                              adjust="w-full mr-2"
                                              data={{
                                                  selected: isRegular
                                                      ? getFontNameOptionSelected(
                                                            theme,
                                                            fonts,
                                                            deviceType,
                                                            widget,
                                                            Widget.ImplementNewLibrary(type)
                                                        )
                                                      : getParameterFromWidgetStyle(
                                                            fontNameId,
                                                            widget,
                                                            theme.paragraphStyle
                                                        ),
                                                  options: getOptionsFontList(fonts?.fontList),
                                              }}
                                          />
                                          <Select
                                              title={t("total size")}
                                              id={textSizeId}
                                              deviceSpecific={true}
                                              adjust="w-full"
                                              data={{
                                                  selected: isRegular
                                                      ? getTextSizeSelected(theme, deviceType, widget)
                                                      : getParameterFromWidgetStyle(
                                                            textSizeId,
                                                            widget,
                                                            theme.paragraphStyle
                                                        )
                                                      ? getParameterFromWidgetStyle(
                                                            textSizeId,
                                                            widget,
                                                            theme.paragraphStyle
                                                        )
                                                      : getTextSizeSelected(theme, deviceType, widget),
                                                  options: getFontSizes(editorSize.width, deviceType),
                                              }}
                                          />
                                          {/* <Input.Number deviceSpecific={true} id="textSize" /> */}
                                      </div>
                                      <Input.Check
                                          icon="icon-bold"
                                          dataType="style"
                                          id={boldId}
                                          dafultValue={
                                              getParameterFromWidgetStyle(boldId, widget, theme.paragraphStyle)

                                              // widget.widgetStyle && widget.widgetStyle.bold
                                              //     ? widget.widgetStyle.bold
                                              //     : theme?.paragraphStyle?.bold
                                          }
                                      />
                                      <Input.Check
                                          icon="icon-italic"
                                          dataType="style"
                                          id={italicId}
                                          dafultValue={
                                              widget.widgetStyle && widget.widgetStyle.italic
                                                  ? widget.widgetStyle.italic
                                                  : theme?.paragraphStyle?.italic
                                          }
                                      />
                                      <Input.Check
                                          icon="icon-underline"
                                          dataType="style"
                                          id={underlineId}
                                          dafultValue={
                                              widget.widgetStyle && widget.widgetStyle.underline
                                                  ? widget.widgetStyle.underline
                                                  : theme?.paragraphStyle?.underline
                                          }
                                      />
                                  </Settings.Group>
                                  <Settings.Group title={t("icon options")}>
                                      {!isRegular ? (
                                          <Input.Check
                                              id="showIconAlarmSet"
                                              dafultValue={true}
                                              deviceSpecific={true}
                                              title={t("icon")}
                                          />
                                      ) : (
                                          <>
                                              <Input.Check id="showIcon" deviceSpecific={true} title={t("icon")} />
                                              {showIcon ? (
                                                  <Input.Number
                                                      id="iconSize"
                                                      deviceSpecific={true}
                                                      title={t("total size")}
                                                  />
                                              ) : null}
                                              {showIcon ? (
                                                  <Input.Alignment
                                                      id="iconPosition"
                                                      type="around"
                                                      deviceSpecific={true}
                                                      title={t("position")}
                                                  />
                                              ) : null}
                                              {showIcon ? (
                                                  <Input.Number
                                                      deviceSpecific={true}
                                                      id="iconMargin"
                                                      title={t("spacing")}
                                                  />
                                              ) : null}
                                          </>
                                      )}
                                  </Settings.Group>
                                  {isRegular && (
                                      <Settings.Group title={t("alignment")}>
                                          <Input.Alignment inline={true} id="alignment" deviceSpecific={true} />
                                          <div className="border-l mb-2 inline-block border-gray-400 py-1">&nbsp;</div>
                                          <Input.Alignment
                                              inline={true}
                                              id="vAlignment"
                                              type="vertical"
                                              deviceSpecific={true}
                                          />
                                      </Settings.Group>
                                  )}
                              </Settings>
                          );
                      })
                    : null}
            </Toolbar>
            <Toolbar type="settings">
                <Settings>
                    <AddImage itemSelected={itemSelected} />
                    <div className="p-8 ">
                        <div className="first-capital mb-8 font-bold">{t("image-options")}</div>
                        <UseSelectWithSearch
                            data={{
                                oneSelected: true,
                                noSelect: true,
                                name: `image_select_${widgetID}`,
                                optionData: adjustPredefinedOptions,
                                selectedIds: adjust ? [adjust] : null,
                                onChange: updateImageOptions,
                                hideSearch: true,
                                optionsAdjust: "mt-11",
                                fixed: true,
                                parentName: `widgetOptionContainer_${widgetID}`,
                            }}
                        />
                    </div>
                </Settings>
            </Toolbar>
            <Toolbar type="style">
                {tabs && tabs.length > 1 ? (
                    <Toolbar.Tabs
                        data={tabs}
                        currentTab={currentStyleTab}
                        setCurrentTab={(tab) => {
                            setCurrentStyleTab(tab);
                        }}
                    />
                ) : null}
                {tabs && tabs.length > 0
                    ? tabs.map((tab) => {
                          const isRegular = tab.id === "regular";
                          const fgColor = isRegular ? "fgColor" : "fgColor" + tab.id;
                          const bgColor = isRegular ? "bgColor" : "bgColor" + tab.id;
                          const iconColor = isRegular ? "iconColor" : "iconColor" + tab.id;
                          return (
                              <Settings key={tab.id + "-tab-content"} visible={tab.id === currentStyleTab}>
                                  <Settings.Group type="colors">
                                      {fgColor ? (
                                          <Input.Color
                                              id={fgColor}
                                              title={t("foreground")}
                                              defaultColor={
                                                  style && style[fgColor]
                                                      ? style[fgColor]
                                                      : widgetInsideMenu && widgetMenuColorsAndAnimations
                                                      ? getMenuColors()[0]
                                                      : GetThemeDefault({ theme, id: "fgColor" })
                                              }
                                          />
                                      ) : null}
                                      {bgColor ? (
                                          <Input.Color
                                              id={bgColor}
                                              title={t("background")}
                                              defaultColor={
                                                  style && style[bgColor]
                                                      ? style[bgColor]
                                                      : widgetInsideMenu && widgetMenuColorsAndAnimations
                                                      ? getMenuColors()[1]
                                                      : GetThemeDefault({ theme, id: "bgColor" })
                                              }
                                          />
                                      ) : null}
                                      {iconColor ? (
                                          <Input.Color
                                              id={iconColor}
                                              title={t("icon")}
                                              defaultColor={
                                                  widgetInsideMenu && widgetMenuColorsAndAnimations
                                                      ? getMenuColors()[2]
                                                      : GetThemeDefault({ theme, id: "iconColor" })
                                              }
                                          />
                                      ) : null}
                                  </Settings.Group>
                                  {isRegular && <Settings.Border tab={tab.id} t={t} />}
                              </Settings>
                          );
                      })
                    : null}
            </Toolbar>
            {/* <Toolbar type="clone" /> */}
            <Toolbar type="delete" />
        </>
    );
};

export default AlarmToolbar;
