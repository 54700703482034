import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSectionContent } from "../../../../actions/sectionActions";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { toast } from "react-toastify";

const GenerateInstallationCode = () => {
    const { generateCode } = useSelector((state) => state.sectionContent);

    const dispatch = useDispatch();

    const MUTATION_GENERATE_CODE = gql`
        mutation {
            generateInstallationCode {
                code
            }
        }
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_GENERATE_CODE, {
        onError(err) {
            dispatch(setSectionContent({ generateCode: false, loadingCode: false }));
            toast.error(err.message);
        },
    });

    useEffect(() => {
        if (generateCode) {
            executeMutation();
            dispatch(setSectionContent({ generateCode: false }));
        }
        // eslint-disable-next-line
    }, [generateCode]);

    useEffect(() => {
        if (data && data.generateInstallationCode && data.generateInstallationCode.code) {
            dispatch(setSectionContent({ refreshCode: true }));
        }
        // eslint-disable-next-line
    }, [data]);
};

export default GenerateInstallationCode;
