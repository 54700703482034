import React from "react";
import { useSelector } from "react-redux";
//Components
import UseWizardFilter from "./useWizardFilter";

const WizardFilter = () => {
    //Store data
    const { stepsData, eventTypeActive, schedulePeriodicForm } = useSelector((state) => state.sectionContent);

    return (
        <>
            {eventTypeActive === "periodic-event" && !schedulePeriodicForm ? (
                <>
                    <div className="w-8/12 mx-auto pt-16 mb-12">
                        <div className="wizard-steps-container flex items-center">
                            <div className="wizard-progress-container">
                                <div className="relative">
                                    <div className="wizard-progress-bg"></div>
                                </div>
                            </div>
                            {stepsData.map((stepData, index) => (
                                <UseWizardFilter stepData={stepData} index={index} key={index} />
                            ))}
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default WizardFilter;
