import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseInputTimeSpecialSpecific from "../Schedule/useInputTimeSpecialSpecific";
import UseInputDateSpecialSpecific from "../Schedule/useInputDateSpecialSpecific";
//Actions
import { addContainerDateRow } from "../../actions/scheduleActions";

const UseSpecialSSpecificRow = ({ datesValidation }) => {
    const { specialDateRows, specialRowDateContainer } = useSelector((state) => state.schedules);
    const [rowAmount, setRowAmount] = useState(specialDateRows);
    const [rowLooper, setRowLooper] = useState([""]);

    //Actions
    const dispatch = useDispatch();

    //Listeners
    useEffect(() => {
        setRowAmount(specialDateRows);
        if (specialDateRows > 1) {
            dispatch(
                addContainerDateRow({
                    specificDays: [],
                    morningStart: false,
                    morningEnd: false,
                    afternoonStart: false,
                    afternoonEnd: false,
                })
            );
        }
        // eslint-disable-next-line
    }, [specialDateRows]);

    useEffect(() => {
        setRowLooper(getRowLooper(rowAmount));
    }, [rowAmount]);

    useEffect(() => {}, [datesValidation]);

    return (
        <>
            {rowLooper.map((emptyString, index) => (
                <div className="w-full flex justify-start" key={index}>
                    <div className="w-2/5 inline-block pr-4">
                        <UseInputDateSpecialSpecific validation={datesValidation} index={index} />
                    </div>
                    <div className="inline-block pb-4 w-3/5 ml-8">
                        <UseInputTimeSpecialSpecific
                            dateId="morningStart"
                            validation={specialRowDateContainer[index].MSvalidated}
                            index={index}
                        />{" "}
                        -{" "}
                        <UseInputTimeSpecialSpecific
                            dateId="morningEnd"
                            validation={specialRowDateContainer[index].MEvalidated}
                            index={index}
                        />{" "}
                        /{" "}
                        <UseInputTimeSpecialSpecific
                            dateId="afternoonStart"
                            validation={specialRowDateContainer[index].ASvalidated}
                            index={index}
                        />{" "}
                        -{" "}
                        <UseInputTimeSpecialSpecific
                            dateId="afternoonEnd"
                            validation={specialRowDateContainer[index].AEvalidated}
                            index={index}
                        />
                    </div>
                </div>
            ))}
        </>
    );
};

export const getRowLooper = (rowAmount) => {
    //Returns object to iterate through and add form rows
    let res = [];
    for (let i = 1; i <= rowAmount; i++) {
        res.push("");
    }
    return res;
};

export default UseSpecialSSpecificRow;
