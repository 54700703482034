import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Parser from "hooks/Utils/Parser";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import Loading from "components/Loading";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setRefreshContentData } from "actions/sectionActions";
import { useVendureAPI, executeVendureQuery } from "hooks/Utils/Integrations/useVendure";

const DELETE_FACET_VALUES_MUTATION = `
    mutation DeleteFacetValues($ids: [ID!]!, $force: Boolean!) {
        deleteFacetValues(ids: $ids, force: $force) {
            result
            message
        }
    }
`;

const useMutationDeleteFacetValues = ({ closeModal }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { adminApi } = useVendureAPI();
    const [loading, setLoading] = useState(false);

    const mutate = async (variables) => {
        setLoading(true);
        try {
            const response = await executeVendureQuery(adminApi, {
                queryBody: DELETE_FACET_VALUES_MUTATION,
                variables: variables,
            });

            if (response.errors) {
                const message = response.errors[0].message;
                toast.error(message);
                closeModal();
            } else if (response.data) {
                dispatch(setRefreshContentData(true));
                toast.success(t("operation-successful"));
                closeModal();
            } else {
                toast.error(t("error-mutation"));
                closeModal();
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
            closeModal();
        }
    };

    return { mutate, loading };
};

const ModalContent = (props) => {
    const { t } = useTranslation();

    const { mutate, loading } = useMutationDeleteFacetValues({ closeModal: props?.close });

    return (
        <Modal
            title={` ${t("delete")} ${props.name}`}
            className={"w-3/12 p-10"}
            footer={
                !loading ? (
                    <>
                        <Button design="blue-outline" onClick={() => props?.close()} id="modal-button-cancel">
                            {t("cancel")}
                        </Button>
                        <Button
                            design="red"
                            onClick={() => {
                                mutate({ force: true, ids: [props?.VARIABLES?.id] });
                            }}
                            id="modal-button-delete"
                        >
                            {t("delete")}
                        </Button>
                    </>
                ) : null
            }
        >
            {loading ? <Loading /> : <div className=" pb-6">{t("delete-label-description")}</div>}
        </Modal>
    );
};

export const useDeleteLabelModal = () => {
    const { open, close } = useModal();

    const openModal = (props) => {
        const newProps = {
            close,
            ...props,
        };

        open(<ModalContent {...newProps} />);
    };

    return {
        open: openModal,
        close,
    };
};
