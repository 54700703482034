import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//Components
import UseSectionHeader from "../../../useSectionHeader";
import { Session } from "hooks/Utils/Session";
import UseEditableField from "../../../../components/useEditableField";
import { useTranslation } from "react-i18next";
import UseTabs from "../../../useTabs";
import { validateSection } from "../../../../hooks/Utils/Validations";
import {
    setActionName,
    setActionValidations,
    cleanAction,
    changeGlobalAction,
    changeActionValues,
    cleanActionValues,
} from "../../../../actions/globalActions";
import { toast } from "react-toastify";
import {
    capitalizeFirst,
    destinationData,
    removeApolloCacheKeys,
    hasDestinationSelected,
    getDestinationLabels,
    sortLangs,
} from "../../../../hooks/Utils/Utils";
import { openModal, setModalContent, showGlobalLoading } from "../../../../actions/uiActions";
import { gql } from "apollo-boost";
import { useMSQuery } from "../../../../hooks/GraphqlCalls/useMSQuery";
import UpdateMessages from "../../../../hooks/GraphqlCalls/Customers/UpdateMessages";
import { DRAFTS_MSG, SCHEDULED_MSG, SENT_MSG } from "../../../../hooks/Utils/Customers/MessagesUtils";
import AutoSaveMessage from "../../../../hooks/GraphqlCalls/Customers/AutoSaveMessage";
import { SentMessageDetail } from "./SentMessageDetail";
import { DeleteMessageDetail } from "./DeleteMessageDetail";
import { setSectionContent, cleanSectionContent } from "../../../../actions/sectionActions";
import { setRefreshData } from "../../../../actions/tableActions";
import Destinations from "./Destinations";
import { withApollo } from "react-apollo";
import UseComponentLoading from "../../../useComponentLoading";
import UseInputDraftEditableTagsField from "../../../Inputs/UseInputDraftEditableTagsField";
import Button from "components/Button";
import DropdownButton from "components/DropdownButton";
import { UseModalScheduleMessages } from "./modals/useModalScheduleMessages";
import { useAuth } from "hooks/Session/auth";

const NewMessage = ({ client }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //data
    const { id, msgFolder } = useParams();

    //Data
    AutoSaveMessage(msgFolder !== "sent");
    //Store data
    const { permissions, languages: projectLangs } = useAuth();
    const { refreshData } = useSelector((state) => state.table);

    const { autoSaveDraftMessage } = useSelector((state) => state.sectionContent);
    const { validations } = useSelector((state) => state.action);
    const actionData = useSelector((state) => state.action.values);

    //States
    const inEdition = useRef(true);
    const [titles, setTitles] = useState();
    const [headTitle, setHeadTitle] = useState();
    const [contents, setContents] = useState();
    const [msgData, setMsgData] = useState();
    const [loadingDraft, setLoadingDraft] = useState(false);

    const [canSaveAsTemplate, setCanSaveAsTemplate] = useState();
    const oldId = id && msgFolder !== "new" ? id : null;
    const _id = useRef(autoSaveDraftMessage?.idMessage ? autoSaveDraftMessage.idMessage : oldId);
    const isTemplate = useRef(msgFolder === "new" && id);
    const MESSAGES_QUERY = `query Messages($id: Int64!) {
        messages(lang: "all", filter: { id: $id }) {            
            results {
                idMessage: id
                scheduleDate
                scheduleDaysOfWeek
                scheduleTime
                scheduleType
                sentBy{id fullName}
                deletedBy{id fullName}
                deletedTime
                title
                body
                priority
                sentTime
                expirationTime
                ${destinationData("messages")} 
                information{
                    channelToken
                    orderID
                }                       
                state
                recipients{
                    isRead
                    reason{all roomIDs roomGroupIDs wingIDs floorIDs countryRefs languageRefs stayGuestIDs stayGroupIDs}
                    checkedout
                    roomId
                    roomName
                    stayGuestName
                    stayGuestSurname
                }
            }
        }
        occupiedRoomsInfo {
            name
            id
        }
        guests(filter: {checkedOut: true}, orderBy: {field: "id", criteria: "ASC"} includeCheckoutRooms:true) {
        results {
            id
            rooms{
                name
            }
        }
    }
    }`;
    const TEMPLATES_QUERY = `query Messages($id: Int64!) {
        messages: templateMessages(lang: "all", filter: { id: $id }) {
            results {
                
                title
                body
            }
        }
    }`;

    const GET_MESSAGES = gql`
        ${msgFolder === "new" && id && isTemplate.current ? TEMPLATES_QUERY : MESSAGES_QUERY}
    `;
    const [executeQuery, { data, loading, error, refetch }] = useMSQuery(GET_MESSAGES, {
        fetchPolicy: "network-only",
        onCompleted() {
            dispatch(showGlobalLoading(false));
            setLoadingDraft(false);
        },
        onError({ graphQLErrors }) {
            if (graphQLErrors) {
                graphQLErrors.map((gError) => toast.error(gError.message));
            }
            toast.error(t("mutation-error"));
        },
    });
    const queryByIdOrRender = () => {
        if (msgFolder === "new" && id && isTemplate.current) {
            setLoadingDraft(true);
            executeQuery({
                variables: { id: id },
            });
        } else if (_id.current !== "new" || (msgFolder !== "new" && id !== "new")) {
            if (!document.URL.includes("new")) {
                setLoadingDraft(true);
            }
            executeQuery({
                variables: { id: _id.current ? _id.current : id },
            });
        } else {
            renderTitles();
            renderContent();
        }

        dispatch(showGlobalLoading(false));
    };

    useEffect(() => {
        dispatch(cleanActionValues());
        dispatch(showGlobalLoading(true));
        queryByIdOrRender();
        const sortedByDefaultValue = sortLangs(projectLangs, t);
        dispatch(changeActionValues({ langActive: sortedByDefaultValue?.[0]?.languageRef }));
        return () => {
            dispatch(cleanAction());
            dispatch(cleanSectionContent());
            if (_id.current && inEdition.current) {
                removeApolloCacheKeys(client.cache, "messages");
            }
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setHeadTitle(getTitle(msgData, inEdition.current, t));
        // eslint-disable-next-line
    }, [msgData]);

    useEffect(() => {
        if (autoSaveDraftMessage?.idMessage && !document.URL.includes(SENT_MSG.name)) {
            _id.current = autoSaveDraftMessage.idMessage;
            isTemplate.current = false;
            queryByIdOrRender();
        }
        // eslint-disable-next-line
    }, [autoSaveDraftMessage?.idMessage]);

    useEffect(() => {
        if (refreshData && refetch) {
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (!error && !loading && data?.messages?.results && data.messages.results.length > 0) {
            let msgData = data.messages.results[0];
            msgData.destinationLabels =
                getDestinationLabels(
                    msgData ? msgData.destination : null,
                    t,
                    data?.occupiedRoomsInfo,
                    data?.guests?.results
                ) || "";
            setMsgData(msgData);
            inEdition.current =
                data.messages.results[0].state === DRAFTS_MSG.value ||
                data.messages.results[0].state === SCHEDULED_MSG.value ||
                msgFolder === "new";
            renderTitles();
            renderContent(data.messages.results[0]);
            dispatch(
                setSectionContent({
                    data: {
                        message: data.messages.results[0],
                        destination: data.messages.results[0].destination,
                    },
                })
            );
            //to check destination in validation
            dispatch(changeActionValues({ destination: hasDestinationSelected(data.messages.results[0].destination) }));

            if (id && id !== "new") {
                arrangeBodyMessageValues(data?.messages?.results?.[0]?.body || "{}", dispatch, changeActionValues);
            }
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        renderTitles();
        updateSaveAsTemplate();
        // eslint-disable-next-line
    }, [actionData]);

    useEffect(() => {
        if (Object.keys(validations).length > 0) {
            const _validations = validateSection("new-message", actionData, t, {
                projectLangs: projectLangs,
                noCustomToast: true,
            });
            dispatch(setActionValidations(_validations));
        }
        // eslint-disable-next-line
    }, [actionData]);

    //Functions
    const renderTitles = () => {
        let titlesArray = [];

        const sortedByDefaultValue = sortLangs(projectLangs, t);

        // eslint-disable-next-line
        sortedByDefaultValue.map((lang) => {
            const colorIcon =
                actionData[`subject-${lang.languageRef}`] && actionData[`content-${lang.languageRef}`]
                    ? "green"
                    : "gray";
            titlesArray.push({
                name: t("language:" + lang.languageRef) || lang.languageRef,
                image:
                    actionData[`subject-${lang.languageRef}`] || actionData[`content-${lang.languageRef}`]
                        ? require(`../../../../assets/images/icons/save-circle-${colorIcon}.svg`)
                        : null,
                tooltip: lang.isDefault ? t("default-lang") : null,
            });
        });

        setTitles(titlesArray);
    };

    const renderContent = (results) => {
        let titleArray,
            bodyArray = null;
        if (results) {
            titleArray = JSON.parse(results.title);
            bodyArray = JSON.parse(results.body);
        }
        let contentsArray = [];
        // eslint-disable-next-line
        if (projectLangs) {
            projectLangs.forEach((lang) => {
                contentsArray.push(
                    <>
                        {inEdition.current ? <div className="pb-6">{t("message-body-title")}</div> : null}
                        <UseEditableField
                            data={{
                                id: `subject-${lang.languageRef}`,
                                label: `subject-${lang.languageRef}`,
                                placeholder: ["write subject", "..."],
                                labelOnEdition: "subject",
                                required: true,
                                value: titleArray ? titleArray[lang.languageRef] : "",
                                disabled: !inEdition.current,
                                inputAdjust: !inEdition.current ? "text-gray-900 bg-white" : "",
                                inputBgColor: !inEdition.current ? "bg-white border border-gray-200" : "",
                                inEditionMode: true,
                                tagsAvailable: true,
                                type: "text",
                            }}
                        ></UseEditableField>
                        <div className="pt-6 pb-1">
                            <UseInputDraftEditableTagsField
                                id={`content-${lang.languageRef}`}
                                height={"12rem"}
                                rightPositionTags={"0rem"}
                                disabled={!inEdition.current}
                                defaultTextState={bodyArray ? bodyArray[lang.languageRef] : ""}
                                textPlaceholder={capitalizeFirst(`${t("write content")}...`)}
                                adjustTagsButton="py-2 px-2"
                                forceScrollContent={true}
                                adjustTagsButtonComponentStyles={{
                                    bottom: "0.25rem",
                                    right: "0.25rem",
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                }}
                                noChangeValueOnKeyUp={true}
                                changeValueOnBlur={true}
                            />
                        </div>
                    </>
                );
            });
        }
        setContents(contentsArray);
    };
    const executeValidations = (type) => {
        const _validations = validateSection(type, actionData, t, { projectLangs: projectLangs });

        //Validate fields
        if (msgData?.recipients?.length == 0) {
            _validations.destination = false;
            toast.error(t("no-recipients-available"));
        }
        dispatch(setActionValidations(_validations));
        const validationsArray = Object.values(_validations);

        if (validationsArray.includes(false)) {
            if (
                validationsArray.filter((item) => item === false).length === 1 &&
                _validations["subject-empty"] === false
            ) {
                return false;
            }
            toast.error(t("errors-in-red"));
            return false;
        }
        return true;
    };

    //Actions
    const sendMessage = () => {
        //Validate fields
        if (executeValidations("new-message")) {
            const modalContent = {
                title: [t("send-message")],
                inputs: [
                    {
                        type: "void",
                        text: `${capitalizeFirst(t("send-msg-confirm"))}`,
                    },
                ],
                buttons: [
                    { name: "cancel", style: "white", action: "close" },
                    {
                        name: "send",
                        style: "blue",
                        action: "send-message",
                    },
                ],
                preventCleanOnClose: true,
                executeSectionQuery: true,
            };
            dispatch(setActionName("send-message"));
            dispatch(setModalContent(modalContent));
            dispatch(openModal());
        }
    };

    const discardMessage = () => {
        dispatch(
            changeGlobalAction({
                itemsAffected: msgData?.idMessage ? [msgData.idMessage] : false,
                actionName: "discard-message",
            })
        );
        const modalContent = {
            title: ["discard message"],
            inputs: [
                {
                    type: "void",
                    text: `${capitalizeFirst(t("discard-msg-confirm"))}`,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "discard",
                    style: "red",
                    action: "discard-message",
                },
            ],
            preventCleanOnClose: true,
            executeSectionQuery: true,
        };
        dispatch(setModalContent(modalContent));
        dispatch(openModal());
    };

    const saveAsTemplate = () => {
        const modalContent = {
            title: ["save as template"],
            inputs: [
                {
                    type: "void",
                    text: `${capitalizeFirst(t("save-template-confirm"))}`,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "save as template",
                    style: "blue",
                    action: "save-msg-template",
                },
            ],
            preventCleanOnClose: true,
            executeSectionQuery: true,
        };
        dispatch(setActionName("save-msg-template"));
        dispatch(setModalContent(modalContent));
        dispatch(openModal());
    };

    const updateSaveAsTemplate = () => {
        let canSave = false;
        if (projectLangs) {
            projectLangs.forEach((lang) => {
                if (!canSave && actionData[`subject-${lang.languageRef}`]) {
                    canSave = true;
                }
            });
        }
        setCanSaveAsTemplate(canSave);
    };

    const onChangeTab = (tabIndex) => {
        const sortedByDefaultLangs = sortLangs(projectLangs, t);
        dispatch(changeActionValues({ langActive: sortedByDefaultLangs[tabIndex].languageRef }));
    };

    const { open: openModalScheduleMessage } = UseModalScheduleMessages();

    return (
        <>
            <UpdateMessages />
            {loadingDraft ? (
                <UseComponentLoading />
            ) : (
                <>
                    <UseSectionHeader
                        title={`${!id && inEdition ? t("new message") : headTitle}`}
                        navToSection={"/customers/messages/list"}
                        noTranslate={true}
                    />
                    {msgData?.information?.orderID ? null : (
                        <div
                            id="message-section-save-as-a-template"
                            className={`${
                                canSaveAsTemplate ? "clickable link" : "disabled"
                            } first-capital absolute right-0 mr-14 -mt-10 font-bold`}
                            onClick={() => (canSaveAsTemplate ? saveAsTemplate() : null)}
                        >
                            {t("save as template")}
                        </div>
                    )}
                    <div className="grid grid-cols-5 gap-6 text-base">
                        {inEdition.current || msgData.state === SENT_MSG.value ? (
                            <SentMessageDetail msgData={msgData} headTitle={headTitle} inEdition={inEdition.current} />
                        ) : (
                            <DeleteMessageDetail msgData={msgData} />
                        )}
                        <div className={`bg-white col-span-2 row-span-2 rounded p-6 pb-2`}>
                            <Destinations
                                msgData={msgData}
                                inEdition={inEdition.current}
                                guests={data?.guests?.results}
                            />
                        </div>
                        <div className={`bg-white col-span-3 rounded p-6 pb-2`}>
                            <div className="w-full">
                                <UseTabs
                                    color={`blue-600`}
                                    titles={titles}
                                    contents={contents}
                                    onChangeTabCallback={onChangeTab}
                                />
                            </div>
                        </div>
                    </div>
                    {inEdition.current && (
                        <div className="w-full mt-4 pb-14">
                            {msgFolder != SCHEDULED_MSG.name && (
                                <>
                                    <div className="float-right ml-5">
                                        {msgFolder != SCHEDULED_MSG.name && permissions?.guests?.messageSendLater ? (
                                            <DropdownButton
                                                id="message-section-save-button"
                                                label={{
                                                    id: "message-section-send-button",
                                                    label: capitalizeFirst(t("send")),
                                                    onClick: () => sendMessage(),
                                                }}
                                                options={[
                                                    {
                                                        id: "message-section-send-later-button",
                                                        label: capitalizeFirst(t("schedule-send")),
                                                        onClick: () => {
                                                            if (executeValidations("send-later-message")) {
                                                                openModalScheduleMessage({ id: _id.current, navigate });
                                                            }
                                                        },
                                                    },
                                                ]}
                                            />
                                        ) : (
                                            <Button
                                                id={"message-section-discard-button"}
                                                design={"blue"}
                                                onClick={() => {
                                                    sendMessage();
                                                }}
                                            >
                                                {capitalizeFirst(t("send"))}
                                            </Button>
                                        )}
                                    </div>
                                    <div className="float-right" onClick={(e) => null}>
                                        <Button
                                            id={"message-section-discard-button"}
                                            design={""}
                                            onClick={() => {
                                                discardMessage();
                                            }}
                                            className="m-1 font-bold text-blue-300"
                                        >
                                            {capitalizeFirst(t("discard message"))}
                                        </Button>
                                    </div>
                                </>
                            )}
                            <div className="float-right mr-5 leading-10 text-gray-600">
                                {autoSaveDraftMessage?.autoSaveTime &&
                                autoSaveDraftMessage.autoSaveTime instanceof window.ZDate
                                    ? t("draft-save-at", {
                                          time: autoSaveDraftMessage.autoSaveTime.toLocaleTimeString("es-ES", {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                          }),
                                      })
                                    : ""}
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default withApollo(NewMessage);

const getTitle = (msgData, inEdition, t) => {
    let titleName = "New message";

    if (!msgData && inEdition) {
        return "new message";
    }
    const titles = JSON.parse(msgData.title);
    if (typeof titles === "object") {
        const lang = Session.getLang() || "en";
        if (titles?.[lang]) {
            titleName = titles[lang];
        }
    }

    const langSetted = [];
    Object.keys(titles).forEach((_lang) => {
        if (titles[_lang]) {
            langSetted.push(_lang);
        }
    });

    if (langSetted.length > 0 && titleName === "unspecified") {
        // get minor lang arranged alphabetically
        const langSettedSorted = langSetted.sort();
        titleName = titles[langSettedSorted[0]];
    }
    if (titleName === "unspecified") {
        titleName = `(${t(titleName)})`;
    }

    return titleName;
};

const arrangeBodyMessageValues = (data = "{}", dispatch, changeActionValues) => {
    try {
        const keyValArrBody = Object.entries(JSON.parse(data));
        keyValArrBody.forEach((pair) => {
            dispatch(changeActionValues({ [`content-${pair[0]}`]: pair[1] }));
        });
    } catch (error) {
        console.error("Error parsing JSON data:", error);
    }
};
