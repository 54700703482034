import React, { useEffect, useRef } from "react";
import ReactTooltip from "components/ReactTooltip";
import { useSelector, useDispatch } from "react-redux";

//Actions
import { changeActionValues } from "../../actions/globalActions";
//Utils
import { useTranslation } from "react-i18next";
import FileHandler from "components/FileHandler";
import { getFileTooltip } from "hooks/Utils/FileUtils";
import { toast } from "react-toastify";

const UseModalFile = ({ index }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const inputRef = useRef(null);

    const {
        name,
        contentTypes: defaultContentTypes,
        triggerAction,
    } = useSelector((state) => state.ui.modalContent.inputs[index]);

    const actionData = useSelector((state) => state.action);

    const values = actionData?.values;

    const updateType = (() => {
        if (values?.ref) {
            return values.ref.split("-")?.[0] === "video" ? "video" : "image";
        }
        return null;
    })();
    const currentFileType = values?.fileType ? values.fileType : null;
    const contentTypes = updateType
        ? [
              {
                  type: updateType,
                  accept: currentFileType ? [currentFileType] : null,
              },
          ]
        : defaultContentTypes;

    useEffect(() => {
        if (triggerAction) {
            if (actionData.values && actionData.values[name] && actionData.values[name].length > 0) {
                document.querySelector("[data-action='" + triggerAction + "']").click();
            }
        }
        // eslint-disable-next-line
    }, [actionData.values[name]]);

    useEffect(() => {
        const cancelModalButtons = document.querySelectorAll("#cancel-modal-button");
        const arrCancelModalButtons = [...cancelModalButtons];

        if (arrCancelModalButtons.length >= 2) {
            arrCancelModalButtons[0].remove();
        }
    }, []);

    return (
        <FileHandler
            ref={inputRef}
            id="select-file"
            options={{
                contentTypes,
                multiple: contentTypes?.some((t) => t.multiple),
                dragAndDrop: true,
            }}
            onSuccess={(files) => {
                dispatch(changeActionValues({ [name]: files }));
            }}
            onError={(error) => {
                toast.error(error);
            }}
        >
            <div className="border border-dashed border-gray-500">
                <div className="text-center p-10 ">
                    <h4 className="font-bold mb-2">
                        {t(
                            contentTypes.find((contentType) => {
                                return contentType.multiple;
                            })
                                ? "drop-files-to-upload"
                                : "drop-file-to-upload"
                        )}
                    </h4>
                    <p className="mb-2">{t("or")}</p>
                    <p
                        className="btn-blue w-6/12 m-auto mb-2"
                        id="select-file-button"
                        onClick={(e) => {
                            e.preventDefault();
                            if (inputRef.current) {
                                inputRef.current.click();
                            }
                        }}
                    >
                        {t(
                            contentTypes.find((contentType) => {
                                return contentType.multiple;
                            })
                                ? "select-files"
                                : "select-file"
                        )}
                    </p>
                    <div className="text-xs">
                        {t("maximum-upload-file-size")}
                        <span
                            className={`icon-info text-lg align-middle m-2 relative z-100`}
                            data-tip={`${contentTypes.reduce((acc, current) => {
                                acc += getFileTooltip(current, t);
                                return acc;
                            }, "")}`}
                            data-html={true}
                        ></span>
                        <ReactTooltip
                            place="bottom"
                            type="light"
                            offset={{ top: -8, left: -8 }}
                            html={true}
                            border={true}
                            multiline={true}
                            borderColor="#D3DAE1"
                        />
                    </div>
                </div>
            </div>
        </FileHandler>
    );
};
export default UseModalFile;
