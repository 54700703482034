import React from "react";
import { useTranslation } from "react-i18next";
import { capitalizeT } from "../../../../../../hooks/Utils/Utils";

import { printOptions } from "../../../../../../hooks/Utils/Design/SalesUtils";

const sortItems = ["colors", "border"];

const UseGroup = ({ data, id }) => {
    const { t } = useTranslation();

    const items = data ? Object.keys(data) : null;
    if (items) {
        items.sort((a, b) => {
            return sortItems.indexOf(a) - sortItems.indexOf(b);
        });
    }

    return (
        <div className="mt-4 w-full">
            <div className="mb-2 w-full px-8 font-bold">{capitalizeT(id, t)}</div>
            {items?.map((key) => {
                return printOptions(key, data[key], key, id, true);
            })}
        </div>
    );
};

export default UseGroup;
