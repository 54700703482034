import React, { useEffect, useState } from "react";
import UseSectionHeader from "../../../../useSectionHeader";
import UseTable from "../../../../Table/index";
import ListGroupEditChannels from "../../../../../hooks/GraphqlCalls/Services/ListGroupChannelsEdit";
import { useTranslation } from "react-i18next";
import { capitalizeFirst, POLLINTERVAL_15S } from "../../../../../hooks/Utils/Utils";
import { useParams } from "react-router-dom";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import ChannelsEditGroupGrid from "./ChannelsEditGroupGrid";
import UpdateListGroupEditChannels from "../../../../../hooks/GraphqlCalls/Services/UpdateListGroupEditChannels";
import EditNameChannel from "../../../../../hooks/GraphqlCalls/Services/modals/EditNameChannel";
import { setModalContent, openModal } from "../../../../../actions/uiActions";
import { useDispatch, useSelector } from "react-redux";
import { Session } from "../../../../../hooks/Utils/Session";
import { setRefreshData } from "../../../../../actions/tableActions";
import { useLocation } from "react-router-dom";
const GroupChannelsEdit = () => {
    const { t } = useTranslation();
    const { groupId, gridId } = useParams();
    const location = useLocation();
    const locationPath = location?.pathname ? location.pathname : null;
    const dispatch = useDispatch();
    const [nameGroup, setNameGroup] = useState("");
    const [breadCrumbs, setBreadcrumbs] = useState(null);
    const { refreshData } = useSelector((state) => state.table);

    const [TID, setTID] = useState(null);

    const GET_GROUP_CHANNEL = gql(`query {
        tuneGroupSummary(filter:{ id: ${groupId}}) {
          results {
            name
            id
            nameTID
          }
        }
      }
      `);

    const [executeQuery, { data, refetch }] = useLazyQuery(GET_GROUP_CHANNEL, {
        pollInterval: POLLINTERVAL_15S,
    });

    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (refreshData && refetch) {
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (locationPath) {
            setBreadcrumbs(getBreadcrumbs(locationPath, { groupId, gridId }));
        }
    }, [locationPath]);

    useEffect(() => {
        const [firstGroup] = data?.tuneGroupSummary?.results || [];
        if (firstGroup?.name) {
            const { name, nameTID } = firstGroup;
            setNameGroup(name);
            setTID(nameTID);
            Session.setSessionProp("groupChannelName", name);
            Session.setSessionProp("groupChannelId", groupId);
        }
    }, [data]);

    return (
        <div className=" rounded ">
            <UseSectionHeader
                navToPreviousPage={true}
                noCapitalize={true}
                title={nameGroup}
                noTranslate={true}
                customBreadCrumbs={breadCrumbs}
            />

            <div className=" bg-white rounded">
                <div className=" px-6 pt-6  bg-white rounded">
                    <span id="group-name" className=" text-2xl font-bold inline-block mr-5">
                        {nameGroup}
                    </span>
                    <button
                        id="group-name-edit-button"
                        disabled={TID === null}
                        onClick={() => {
                            const modalContent = EditNameChannel({
                                name: nameGroup,
                                TID: TID,
                                parentType: "TUNEGROUPS",
                                query: `{
                                translations(id: ${TID}, parentType: TUNEGROUPS) {
                                  text
                                  lang
                                }
                              }`,
                            });
                            dispatch(setModalContent(modalContent));
                            dispatch(openModal());
                        }}
                        className=" focus:outline-none text-sm underline text-zafiro-600 "
                    >
                        {capitalizeFirst(t("edit"))}
                    </button>
                </div>
                <div
                    className=" bg-white rounded"
                    style={{ minHeight: "13rem", maxHeight: "13rem", overflow: "hidden" }}
                >
                    <UseTable />
                    <ListGroupEditChannels nameGroup={nameGroup} />
                </div>
                <ChannelsEditGroupGrid nameChannelGroup={nameGroup} />
                <UpdateListGroupEditChannels />
            </div>
        </div>
    );
};

export default GroupChannelsEdit;

const getBreadcrumbs = (path, params) => {
    const commonBreadcrumbs = [
        {
            name: "services",
            translate: true,
            route: "/services",
        },
        {
            name: "tv-channels",
            translate: true,
            route: "/services/tv-channels/",
        },
    ];

    if (path.includes("grids")) {
        return [
            ...commonBreadcrumbs,
            {
                name: "grids",
                translate: true,
                route: `/services/tv-channels/grids/${params.gridId}`,
            },
        ];
    } else {
        return [
            ...commonBreadcrumbs,
            {
                name: "groups",
                translate: true,
                route: "/services/tv-channels/groups/",
            },
        ];
    }
};
