import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import translate from "../../lang/es/Global/ModalContent";
//Actions
import { changeActionValues, setActionValidations } from "../../actions/globalActions";
import { multipleTranslate } from "../../hooks/Utils/Utils";

import { toast } from "react-toastify";

const UseMultipleInput = ({ index }) => {
    //Store data
    const { name, text, selectedInputs, value, cStyle, ph } = useSelector(
        (state) => state.ui.modalContent.inputs[index]
    );
    const { validations } = useSelector((state) => state.action);

    const { langStrings } = useSelector((state) => state.ui);

    //state
    const [val, setVal] = useState(value);
    const [inputs, setInputs] = useState(selectedInputs ? selectedInputs : []);

    //Listeners
    useEffect(() => {
        if (selectedInputs) {
            dispatch(changeActionValues({ inputs: selectedInputs }));
        }
        // eslint-disable-next-line
    }, []);

    //Actions
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setVal(e.target.value);
        dispatch(changeActionValues({ [name]: e.target.value }));
    };

    const addNewInput = (e) => {
        const textInput = document.querySelector("#modal_focus");
        if (validateEmail(textInput.value) && inputs.filter((item) => item === textInput.value).length === 0) {
            let newInputs = [...inputs];
            newInputs.push(textInput.value);
            setInputs(newInputs);
            dispatch(changeActionValues({ inputs: newInputs }));
            dispatch(changeActionValues({ [name]: "" }));
            dispatch(setActionValidations({ [name]: true }));
            setVal("");
        } else {
            toast.error(langStrings["errors-in-red"]);
            dispatch(setActionValidations({ [name]: false }));
        }
    };

    const removeOption = (e) => {
        e.stopPropagation();
        let cInputs = [...inputs];

        const idxToDelete = cInputs.indexOf(e.currentTarget.dataset.id);
        cInputs.splice(idxToDelete, 1);
        setInputs(cInputs);
        dispatch(changeActionValues({ inputs: cInputs }));
    };

    //Functions
    const validateEmail = (text) => {
        // eslint-disable-next-line
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        return text && text.match(mailformat) ? true : false;
    };

    return (
        <div className={`${cStyle}`}>
            {text ? <span className="block pb-2 first-capital">{multipleTranslate(text, langStrings)}</span> : null}

            <div className="w-full flex mb-4">
                {inputs.map((item) => (
                    <div key={item} className="float-left z-20  bg-blue-200 text-white pl-2 mr-4 p-1 rounded mb-2">
                        <span id={`chip-text-${index}`}>{item}</span>
                        <div
                            id={`remove-chip-button-${index}`}
                            className="icon-remove inline-block ml-8 cursor-pointer"
                            data-id={item}
                            onClick={(e) => removeOption(e)}
                        ></div>
                    </div>
                ))}
            </div>

            <input
                id="modal_focus"
                type="text"
                min="0"
                required
                placeholder={ph ? (langStrings[ph] ? langStrings[ph] : translate(ph)) : ""}
                value={val}
                className={`w-7/12 h-10 bg-gray-200 rounded p-2 hover:text-zafiro-400 float-left  ${
                    typeof validations[name] !== "undefined" && !validations[name] ? "border border-red-100" : ""
                }`}
                onChange={(e) => handleChange(e)}
            ></input>

            <div
                onClick={(e) => addNewInput()}
                className="ml-4 mt-2 cursor-pointer text-zafiro-700 inline-block"
                id="add-new-email-button"
            >
                <div className="icon icon-add float-left mt-0.5"></div>
                <div className="pl-2 first-capital float-left">{langStrings["add-new-email"]}</div>
            </div>
        </div>
    );
};

export default UseMultipleInput;
