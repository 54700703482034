import GetActionModalContent from "../../../../Utils/DataCalc/getActionModalContent";

const RoomCheckoutModal = (guestInfo, room) => {
    const rowContent = {
        rooms: [...guestInfo.rooms],
        checked: room.number,
        name: "checkout",
        guestID: guestInfo.guestID,
    };

    const modalContent = GetActionModalContent("checkout", "guest-currents", null, rowContent);
    let checkoutModal = {
        title: "checkout",
        value: room.name,
        inputs: modalContent,
        buttons: [{ name: "cancel", style: "white", action: "close" }],
    };
    checkoutModal.buttons.push({
        name: "checkout",
        style: "blue",
        action: "checkout",
    });
    return checkoutModal;
};

export default RoomCheckoutModal;
