export const Sales = [
    "default-lang",
    "labels",
    "add-label",
    "edit-label",
    "add-label-description",
    "delete-label-description",
    "shop-opening-hours",
    "delivery-hour-description",
    "24-hours-delivery",
    "customise-timetable",
    "currency-and-taxes",
    "currency",
    "taxes-zone",
    "select-shop-currency",
    "select-shop-taxes-zone",
    "select-shop-default-tax",
    "product",
    "add-image",
    "name",
    "description",
    "translate",
    "price",
    "allergens",
    "labels",
    "product-list",
    "files",
    "image-recommended-resolution",
    "x-images-uploaded",
    "not-image-yet",
    "featured-image",
    "name-and-description",
    "without-tax",
    "tax",
    "total",
    "select-product-allergens",
    "select-allergens",
    "select-labels-like-show-product-details",
    "select-labels",
    "settings",
    "max-quantity-per-order",
    "product-extras",
    "edit-extra-options",
    "no-product-extra-yet",
    "write-name-ph",
    "write-description",
    "products",
    "settings",
    "edit",
    "define-maximum-quantity",
    "available",
    "translate-name",
    "group",
    "items",
    "add-item",
    "extra",
    "extras",
    "add-extra",
    "single-item",
    "group-items",
    "type-of-selection",
    "several-units-per-item",
    "single-unit",
    "type-of-extra",
    "select-type-of-extra",
    "extra-name",
    "select-quantity-limit",
    "minimum-quantity-units",
    "units",
    "maximum-quantity-units",
    "delete-label-description",
    "new-extra",
    "several-units",
    "general-settings",
    "no-extras-yet",
    "no-email-added-yet",
    "received",
    "ready-for-delivery",
    "archived",
    "customer",
    "room-delivery",
    "pick-up-location",
    "delivery-method",
    "delayed-deliveries",
    "order-date",
    "ready",
    "delivered",
    "cancelled",
    "checked-out",
    "delivery-date",
    "order-details",
    "delivery-details",
    "as-soon-as-possible",
    "order-cancel-description",
    "cancel-order",
    "no-orders-yet",
    "order",
    "orders",
    "pending",
    "in progress",
    "delayed",
    "back-to-shop-list",
    "delivery",
    "status",
    "cancel",
    "accept",
    "comments",
    "total",
    "shop",
    "general-monitoring",
    "cancel-order-description",
    "accept-order-description",
    "ready-order-description",
    "delivered-order-description",
    "delete-order-description",
    "accept-order",
    "ready-order",
    "delivered-order",
    "delete-order",
    "ready_",
    "enter-translation-here",
    "print-settings",
    "print-setting-instructions",
    "url-server",
    "user",
    "password",
    "print-setting-instructions-tooltip",
    "last-update",
    "expired"
];
