export const EditInformation = (guestInfo, customFields) => {
    return {
        title: [`${guestInfo?.guestName} ${guestInfo?.guestSurname}`, "-", "edit-information"],
        text: ["edit-the-following-informations"],
        executeSectionQuery: true,
        bodyAdjust: customFields?.length > 0 ? "w-8/12" : "w-4/12",
        inputs: [
            { type: "editGuestInformation", inputData: guestInfo, customFields },
            { type: "data", id: guestInfo?.guestID },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: "updateGuestData",
            },
        ],
    };
};
