import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { changeActionValues } from "../../actions/globalActions";

const UseModalNumber = ({ index }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { id, name, ph, focus, currentValue, text, styleAdjust, max, min, inputStyle, disabled } = useSelector(
        (state) => state.ui.modalContent.inputs[index]
    );
    const { validations } = useSelector((state) => state.action);

    const { title } = useSelector((state) => state.ui.modalContent);

    //State
    const [val, setVal] = useState(currentValue);
    //Data
    const inputValue = getInputValue(currentValue);
    const inputPlaceholder = langStrings[ph];

    //Listeners
    useEffect(() => {
        if (currentValue) {
            dispatch(changeActionValues({ [name]: currentValue }));
        }
        // eslint-disable-next-line
    }, []);

    //Actions
    const dispatch = useDispatch();

    const handleChange = (e) => {
        let inputValue = e.target.value;

        if (inputValue === "") {
            setVal("");
            dispatch(changeActionValues({ [name]: "" }));
            return;
        }

        if ((max && inputValue > max) || (min && inputValue < min)) {
            return;
        }

        setVal(inputValue);
        dispatch(changeActionValues({ [name]: inputValue }));
    };

    return (
        <div className={inputStyle}>
            <div className="w-full pb-4 first-capital">{langStrings[text]}</div>
            <input
                id={focus ? "modal_focus" : id ? id : `input-number-${title}-${index}`}
                type="number"
                min={min ? min : 0}
                max={max ? max : 99999999999999}
                value={val}
                required
                placeholder={inputPlaceholder}
                className={`w-48 h-10 bg-gray-200 rounded hover:text-zafiro-400 p-2 ${styleAdjust} ${
                    typeof validations[name] !== "undefined" && !validations[name] ? "border border-red-100" : ""
                }`}
                onChange={handleChange}
                defaultValue={inputValue}
                disabled={disabled ? disabled : false}
            ></input>
        </div>
    );
};

const getInputValue = (currentValue) => {
    //Returns value or empty string if undefined
    let res;
    if (currentValue) {
        res = currentValue;
    } else {
        res = "";
    }
    return res;
};

export default UseModalNumber;
