import React, { useRef, useEffect } from "react";
import videojs from "video.js";
import "@videojs/http-streaming";

/**
 * VideoPlayer component for playing videos with video.js, including custom controls and navigation buttons.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {boolean} [props.autoplay=false] - Specifies whether the video should autoplay.
 * @param {string} props.videoSrc - URL source of the video.
 * @param {Function} [props.onPrev] - Callback function executed when the "Previous" button is clicked.
 * @param {Function} [props.onNext] - Callback function executed when the "Next" button is clicked.
 * @param {boolean} [props.hideButtonsNavigations=false] - Determines if navigation buttons (previous/next) should be hidden.
 *
 * @example
 * <VideoPlayer
 *   autoplay={true}
 *   videoSrc="https://example.com/video.m3u8"
 *   onPrev={() => console.log("Previous video")}
 *   onNext={() => console.log("Next video")}
 *   hideButtonsNavigations={false}
 * />
 *
 * @returns {JSX.Element} The rendered VideoPlayer component.
 */

const VideoPlayer = ({ autoplay = false, videoSrc, onPrev, onNext, hideButtonsNavigations = false }) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(() => {
        const videoElement = videoRef.current;

        if (!playerRef.current && videoElement) {
            const options = {
                controls: true,
                autoplay: autoplay,
                preload: "auto",
                fill: true,
                controlBar: {
                    children: ["progressControl", "volumePanel", "playToggle"],
                    volumePanel: {
                        inline: false,
                    },
                },
            };

            playerRef.current = videojs(videoElement, options);

            playerRef.current.ready(() => {
                const player = playerRef.current;
                const playToggle = player.controlBar.playToggle;
                const muteButton = player.controlBar.volumePanel.muteToggle;

                const { customCurrentTimeDisplay, customDurationDisplay, customTooltip } = setupCustomElements(
                    player,
                    formatTimeAndSetContent
                );

                createNavigationButtons({
                    player,
                    onPrev,
                    onNext,
                    hideButtonsNavigations,
                });

                updateMuteButtonClasses(player, muteButton);

                player.on("timeupdate", function () {
                    formatTimeAndSetContent(player, customCurrentTimeDisplay, true);
                    formatTimeAndSetContent(player, customTooltip, true);
                    formatTimeAndSetContent(player, customDurationDisplay);
                });
                player.on("play", function () {
                    playToggle.removeClass("icon-Play");
                    playToggle.addClass("icon-Pause");
                });
                player.on("pause", function () {
                    playToggle.removeClass("icon-Pause");
                    playToggle.addClass("icon-Play");
                });
                player.on("volumechange", function () {
                    updateMuteButtonClasses(player, muteButton);
                });
                player.on("ended", function () {
                    if (typeof onNext) {
                        onNext();
                    }
                });
            });
        }

        return () => {
            if (playerRef.current) {
                playerRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.src({
                src: videoSrc,
                type: "application/x-mpegURL",
            });
            playerRef.current.autoplay(autoplay);

            if (autoplay) {
                playerRef.current.play();
            }
        }
    }, [videoSrc, autoplay]);

    return (
        <div data-vjs-player className="h-full" id="container-reproductor" style={{ display: "block" }}>
            <video ref={videoRef} className="video-js vjs-default-skin h-full" id="reproductor"></video>
        </div>
    );
};

export default VideoPlayer;

const updateMuteButtonClasses = (player, muteButton) => {
    if (player.muted() || player.volume() === 0) {
        muteButton.removeClass("icon-audio");
        muteButton.addClass("icon-mute");
    } else {
        muteButton.removeClass("icon-mute");
        muteButton.addClass("icon-audio");
    }
};

function formatTimeAndSetContent(player, displayElement, isCurrentTime = false) {
    if (!player) return;

    const time = isCurrentTime ? player.currentTime() : player.duration();
    const minutes = isNaN(time) ? "00" : String(Math.floor(time / 60)).padStart(2, "0");
    const seconds = isNaN(time) ? "00" : String(Math.floor(time % 60)).padStart(2, "0");

    displayElement.textContent = `${minutes}:${seconds}`;
}

function setupCustomElements(player, formatTime) {
    const progressControl = player.controlBar.progressControl.el_;
    const playProgress = player.controlBar.progressControl.seekBar.playProgressBar.el_;

    const customCurrentTimeDisplay = document.createElement("div");
    customCurrentTimeDisplay.className = "vjs-custom-current-time-display";
    progressControl.appendChild(customCurrentTimeDisplay);

    const customDurationDisplay = document.createElement("div");
    customDurationDisplay.className = "vjs-custom-duration-display";
    progressControl.appendChild(customDurationDisplay);

    const customTooltip = document.createElement("div");
    customTooltip.className = "vjs-custom-current-time-tooltip";
    playProgress.appendChild(customTooltip);

    formatTime(player, customDurationDisplay);
    formatTime(player, customCurrentTimeDisplay);

    return { customCurrentTimeDisplay, customTooltip, customDurationDisplay };
}

function createNavigationButtons({ player, onPrev, onNext, hideButtonsNavigations }) {
    const Button = videojs.getComponent("Button");

    const createButton = ({ name, iconClass, controlText, onClick, position }) => {
        const CustomButton = videojs.extend(Button, {
            constructor: function () {
                Button.apply(this, arguments);
                this.addClass(`vjs-${name}-control`);
                this.addClass(iconClass);
                this.controlText(controlText);
            },
            handleClick: function () {
                if (onClick) onClick();
            },
        });
        videojs.registerComponent(`${name}Button`, CustomButton);
        player.getChild("controlBar").addChild(`${name}Button`, {}, position);
    };

    createButton({
        name: "prev",
        iconClass: "icon-PlayOptions-previous",
        controlText: "Anterior",
        onClick: onPrev,
        position: 0,
    });

    createButton({
        name: "next",
        iconClass: "icon-PlayOptions-next",
        controlText: "Siguiente",
        onClick: onNext,
        position: 2,
    });

    if (hideButtonsNavigations) {
        ["prev", "next"].forEach((name) => {
            const buttonComponent = player.getChild("controlBar").getChild(`${name}Button`);
            if (buttonComponent) {
                buttonComponent.addClass("vjs-custom-hidden");
            }
        });
    }
}
