import React from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { setNewSchedules } from "../../actions/globalActions";
import { setLastScheduleEdition } from "../../actions/scheduleActions";

const UseInputTime = ({ dateId, validation, adjustWidth }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);

    //Actions
    const dispatch = useDispatch();

    const changeScheduleHandler = (e) => {
        if (!e.target.value || e.target.value !== "") {
            const keyToAdd = [dateId, e.target.value];
            dispatch(setNewSchedules(keyToAdd));
        }
        dispatch(setLastScheduleEdition());
    };

    return (
        <>
            <input
                type="time"
                className={`t-filter-search mx-0 my-0 ${adjustWidth ? adjustWidth : "w-24"} ${
                    !validation ? "border border-red-100" : ""
                }`}
                placeholder="hh:mm"
                onBlur={changeScheduleHandler}
            ></input>
            {!validation ? (
                <p className="absolute text-red-100 first-capital">{langStrings["schedule-error"]}</p>
            ) : null}
        </>
    );
};

export default UseInputTime;
