const NewGroupChannel = (t, data) => {
    return {
        title: "add-group",
        text: "write-the-name-of-the-group",
        executeSectionQuery: true,
        inputs: [
            {
                text: `${t("language:" + data?.lang)} (${t("default-language")})`,
                name: "name-group-selected",
                type: "text",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "add",
                style: "blue",
                action: "add-group-channel",
            },
        ],
    };
};

export default NewGroupChannel;
