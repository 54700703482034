import React from "react";

const NewHotspot = (editData, actionName, useProductMobile, mobileApps, t) => {
    let canEditName = false;
    let canEditLinkAction = false;
    if (actionName === "edit-hotspot-name" || actionName === "add-hotspot") {
        canEditName = true;
    } else if (actionName === "edit-hotspot-link") {
        canEditLinkAction = true;
    }
    const nameHotspot = editData && editData.info && editData.info[0] && editData.info[0].value;
    const linkHotspot = editData && editData.info && editData.info[1] && editData.info[1].value;
    let currentRedirectType = "url";
    if (linkHotspot && linkHotspot === "pwa") {
        currentRedirectType = "pwa-name";
    } else if (!linkHotspot || linkHotspot === "") {
        currentRedirectType = "none";
    }
    let options = ["url"];
    if (mobileApps && mobileApps?.length > 0) options.unshift("pwa-name");
    let labels = [
        {
            value: "pwa-name",
            text: t && t("pwa-name", { apps: mobileApps && mobileApps.length > 0 ? mobileApps.join(", ") : "" }),
        },
    ];
    if (!useProductMobile) {
        options = options.filter((option) => option !== "pwa-name" && option !== "url");
    }
    const display = options.length > 1 ? "block" : "hidden";

    return [
        {
            title: "add-hotspot",
            bodyAdjust: "w-4/12",
            executeSectionQuery: true,
            inputs: [
                {
                    text: ["name", "*"],
                    textStyle: "font-bold",
                    name: "hotspot-name",
                    instructions: "hotspot-name-instructions",
                    value: nameHotspot ? nameHotspot : "",
                    type: "text",
                    focus: canEditName,
                    ph: "name",
                    style: !canEditName ? "hidden" : "",
                },
                canEditLinkAction && {
                    text: "choose-redirecton-screen",
                    name: "hotspot-redirection-screen",
                    icon: "info",
                    adjust: "flex items-center",
                    adjustIcon: " text-3xl text-gray-700 ml-2",
                    iconTooltip: "redirection-portal-may-not-work-android-devices",
                    type: "void",
                    focus: true,
                },
                {
                    name: "redirect-type",
                    type: "radio",
                    values: options,
                    labels: labels,
                    style: display,
                    selected: currentRedirectType,
                    currentValue: currentRedirectType,
                    optionStyle: !canEditLinkAction ? "hidden" : "",
                    validationElementsChange: [
                        {
                            option: "pwa-name",
                            attr: "id",
                            value: "container-redirection-hotspot-link",
                            class: "hidden",
                            invert: false,
                        },
                        {
                            option: "none",
                            attr: "id",
                            value: "container-redirection-hotspot-link",
                            class: "hidden",
                            invert: false,
                        },
                    ],
                },
                options.length === 1 && {
                    name: "redirect-type",
                    type: "component",
                    component: <p className="mb-2">{t("URL")}</p>,
                },
                {
                    ref: "container-redirection-hotspot-link",
                    text: "",
                    name: "hotspot-link",
                    value: !canEditLinkAction ? linkHotspot : linkHotspot && linkHotspot !== "pwa" ? linkHotspot : "",
                    type: "text",
                    style: `${options.length > 1 ? "ml-10" : ""} -mt-5 ${!canEditLinkAction ? "hidden" : ""}`,
                    ph: "link",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "save",
                    style: "blue",
                    action: actionName && actionName !== "" ? actionName : "add-hotspot",
                },
            ],
        },
    ];
};

export default NewHotspot;
