import React, { useState, useEffect, useCallback, useRef } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import ImagePlayer from "components/Inputs/ImagePlayer";
import VideoPlayer from "components/Inputs/VideoPlayer";
import Icon from "components/Icon";
import classNames from "classnames";

const ModalContent = (props) => {
    const { t } = useTranslation();
    const { close, data = {} } = props;
    const { name = "", contents = [] } = data;

    const [arrContents, setArrContents] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTruncated, setIsTruncated] = useState({});
    const [thumbnailErrors, setThumbnailErrors] = useState([]);
    const contentRefs = useRef([]);

    const handlePrev = useCallback(() => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : arrContents.length - 1));
    }, [arrContents]);

    const handleNext = useCallback(() => {
        setCurrentIndex((prevIndex) => (prevIndex < arrContents.length - 1 ? prevIndex + 1 : 0));
    }, [arrContents]);

    useEffect(() => {
        if (contents?.length > 0) {
            setArrContents(contents);
            setThumbnailErrors(new Array(contents.length).fill(false));
        }
    }, [contents]);

    useEffect(() => {
        arrContents.forEach((_, index) => {
            const element = contentRefs.current[index];
            if (element) {
                setIsTruncated((prev) => ({
                    ...prev,
                    [index]: element.scrollWidth > element.clientWidth,
                }));
            }
        });
    }, [arrContents]);

    const handleImageError = (index) => {
        setThumbnailErrors((prevErrors) => {
            const newErrors = [...prevErrors];
            newErrors[index] = true;
            return newErrors;
        });
    };

    console.log(contents);

    return (
        <Modal
            id="location-map"
            title={`${name} - ${t("preview")}`}
            footer={
                <Button design="blue" id="modal-button-close" onClick={close}>
                    {t("close")}
                </Button>
            }
            className="w-11/12 p-10"
        >
            <div className=" w-full flex ">
                <div style={{ height: "30rem" }} className=" w-10/12 bg-black">
                    {arrContents?.[currentIndex]?.type === "ASSET" || arrContents?.[currentIndex]?.type === "SCREEN" ? (
                        <ImagePlayer
                            id={`image-player-preview-modal-${currentIndex}`}
                            key={currentIndex}
                            imgSrc={arrContents[currentIndex].src}
                            duration={arrContents[currentIndex].duration}
                            onPrev={handlePrev}
                            onNext={handleNext}
                        />
                    ) : arrContents?.[currentIndex]?.type === "VIDEO" ? (
                        <VideoPlayer
                            videoSrc={arrContents?.[currentIndex].src}
                            autoplay={true}
                            onPrev={handlePrev}
                            onNext={handleNext}
                        />
                    ) : null}
                </div>
                <div className=" ml-5 border border-gray-300 rounded w-2/12">
                    <div
                        style={{ minHeight: "3rem", maxHeight: "3rem" }}
                        className=" flex items-center border-b mx-4 font-bold text-lg"
                    >
                        {t("playlist")}
                    </div>

                    <div className=" px-6 pt-2" style={{ minHeight: "27rem", maxHeight: "27rem", overflowY: "scroll" }}>
                        {arrContents.map((content, index) => {
                            const isPlayingVideo = currentIndex === index;
                            const showNoImage = !content.thumbnail || thumbnailErrors[index];

                            return (
                                <Button
                                    onClick={() => setCurrentIndex(index)}
                                    className="w-full mb-2"
                                    id={`preview-content-${index}`}
                                    key={index}
                                >
                                    <div className="w-full">
                                        <div className="w-full flex items-center">
                                            <div className="w-1/12">{index + 1}</div>
                                            {showNoImage ? (
                                                <div
                                                    className={classNames(
                                                        "w-11/12 h-28 rounded bg-gray-200 flex items-center border-4 justify-center",
                                                        {
                                                            "border-zafiro-400": isPlayingVideo,
                                                            "border-transparent": !isPlayingVideo,
                                                        }
                                                    )}
                                                >
                                                    <Icon className="text-gray-400" size={5} type="no-image" />
                                                </div>
                                            ) : (
                                                <img
                                                    className={classNames(
                                                        "w-11/12 h-28 rounded object-cover border-4",
                                                        {
                                                            "border-zafiro-400": isPlayingVideo,
                                                            "border-transparent": !isPlayingVideo,
                                                        }
                                                    )}
                                                    src={content.thumbnail}
                                                    onError={() => handleImageError(index)}
                                                    alt={`Thumbnail ${index + 1}`}
                                                />
                                            )}
                                        </div>
                                        <div
                                            ref={(el) => (contentRefs.current[index] = el)}
                                            className="ml-4 w-full whitespace-nowrap overflow-hidden overflow-ellipsis"
                                            data-tip={isTruncated[index] ? content.name : ""}
                                            data-for="default-tooltip"
                                            style={{ maxWidth: "100%" }}
                                        >
                                            {content.name}
                                        </div>
                                    </div>
                                </Button>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const usePreviewModal = () => {
    const { open, close } = useModal();

    const openModal = (props) => {
        const newProps = {
            close,
            ...props,
        };

        open(<ModalContent {...newProps} />);
    };

    return {
        open: openModal,
        close,
    };
};

export default usePreviewModal;
