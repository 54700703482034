import i18n from "i18n";

import { STAFF_USER_ACCESSE_CLEAN, STAFF_USER_ACCESSE_CAPACITY, STAFF_USER_ACCESSE_ORDERS } from "constants/users";

const ShowZonesAndShops = ({ title, values, fullName, roles }) => {
    const infoArr = [];

    const manageClean = roles.includes(STAFF_USER_ACCESSE_CLEAN);
    const manageCapacity = roles.includes(STAFF_USER_ACCESSE_CAPACITY);
    const manageOrders = roles.includes(STAFF_USER_ACCESSE_ORDERS);

    if (manageClean && manageCapacity) {
        infoArr.push({
            header: "zones-for-cleaning-and-capacity",
            values: values.arrCommonzonesAssigned,
            width: "w-full",
            noResults: "no-zones-assigned",
        });
    } else if (manageClean) {
        infoArr.push({
            header: "zones-for-cleaning",
            values: values.arrCommonzonesAssigned,
            width: "w-full",
            noResults: "no-zones-assigned",
        });
    } else if (manageCapacity) {
        infoArr.push({
            header: "zones-for-capacity",
            values: values.arrCommonzonesAssigned,
            width: "w-full",
            noResults: "no-zones-assigned",
        });
    }
    if (manageOrders) {
        infoArr.push({
            header: "shops-for-orders",
            values: values.arrShopsAssigned,
            width: "w-full",
            noResults: "no-shops-assigned",
        });
    }

    return [
        {
            title: `${fullName} - ${i18n.t(title)}`,
            bodyAdjust: infoArr.length === 2 ? "w-11/12" : "w-1/12",
            executeSectionQuery: true,
            inputs: [
                {
                    type: "modal-informative-list",
                    data: {
                        info: infoArr,
                    },
                },
            ],
            buttons: [{ name: "close", style: "blue", action: "close" }],
        },
    ];
};

export default ShowZonesAndShops;
