import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Components
import UseRadio from "../useRadio";
import DeletePreviousEventsModal from "../../hooks/GraphqlCalls/CommonZones/modals/schedules/DeletePreviousEventsModal";

//Actions
import { setActiveEventType, showAddPunctualSchedule, showAddPeriodicSchedule } from "../../actions/sectionActions";
import { openModal, setModalContent } from "../../actions/uiActions";
import { cleanAction, setActionName, setActionItemAffected } from "../../actions/globalActions";

//Data
import { useParams } from "react-router-dom";

const UseMainOptions = () => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { eventTypeActive, punctualEventsData, periodicEventsData } = useSelector((state) => state.sectionContent);
    const { options } = useSelector((state) => state.sectionContent.eventTypes);
    const currentData = isCurrentData(punctualEventsData, periodicEventsData);

    //States
    const [selected, setSelected] = useState(eventTypeActive);
    const [showContent, setShowContent] = useState(false);

    //Data
    const { id } = useParams();
    const PUNCTUAL_EVENT = "punctual-event";
    const PERIODIC_EVENT = "periodic-event";

    //Actions
    const dispatch = useDispatch();

    const clickHandler = (e) => {
        if (
            (e.currentTarget.dataset.action === PUNCTUAL_EVENT && hasPeriodicEvents(periodicEventsData)) ||
            (e.currentTarget.dataset.action === PERIODIC_EVENT && hasPunctualEvents(punctualEventsData))
        ) {
            showEventsModal(e.currentTarget.dataset.action);
            return;
        }
        dispatch(setActiveEventType(e.currentTarget.dataset.action));
        dispatch(showAddPeriodicSchedule(false));
        dispatch(showAddPunctualSchedule(false));
        dispatch(cleanAction());
    };

    //Listeners
    useEffect(() => {
        if (eventTypeActive) {
            setSelected(eventTypeActive);
        }
    }, [eventTypeActive]);

    useEffect(() => {
        if (langStrings) {
            setShowContent(true);
        } else {
            setShowContent(false);
        }
    }, [langStrings]);

    //Modal

    const showEventsModal = (changeTo) => {
        dispatch(setActionName("change-event"));
        dispatch(setActionItemAffected([id]));

        //confirm-event-change
        const text1 = langStrings["confirm-event-change"].replace(
            "%0",
            langStrings[changeTo === PUNCTUAL_EVENT ? "punctual-event-title" : "periodic-event-title"]
        );
        const text2 = langStrings["confirm-event-change-info"].replace(
            "%0",
            langStrings[changeTo === PUNCTUAL_EVENT ? "periodic-event-title" : "punctual-event-title"]
        );
        dispatch(setModalContent(DeletePreviousEventsModal(text1, text2)));

        dispatch(openModal());
    };

    return (
        <div className="schedule-types-container">
            {options && showContent ? (
                <>
                    {options.map((option, index) => (
                        <div
                            className={`schedule-type-item cursor-pointer ${getOptionStyle(
                                option,
                                selected,
                                currentData
                            )}`}
                            data-action={option.value}
                            onClick={clickHandler}
                            key={index}
                        >
                            <div className="schedule-type-container">
                                <div className="schedule-radio-container">
                                    <UseRadio id={`${option.value}`} selected={selected} value={option.value} />
                                </div>
                                <div className="inline">
                                    <h2 className="schedule-type-title">{langStrings[option.title]}</h2>
                                    <p className="schedule-type-text">{langStrings[option.text]}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            ) : null}
        </div>
    );
};

const isCurrentData = (punctualEventsData, periodicEventsData) => {
    //Returns true is there are previous existing data
    let res;
    if (
        (punctualEventsData && punctualEventsData.currentData && punctualEventsData.currentData.length > 0) ||
        (periodicEventsData && periodicEventsData.currentData && periodicEventsData.currentData.length > 0)
    ) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

const hasPunctualEvents = (punctualEventsData) => {
    //Returns true is there are previous existing data
    return punctualEventsData && punctualEventsData.currentData && punctualEventsData.currentData.length > 0;
};

const hasPeriodicEvents = (periodicEventsData) => {
    //Returns true is there are previous existing data
    return periodicEventsData && periodicEventsData.currentData && periodicEventsData.currentData.length > 0;
};

const getOptionStyle = (option, selected, currentData) => {
    //Returns style for option (active, not active)
    let res;
    if (!currentData) {
        res = "";
    } else if (option.value === selected) {
        res = "schedule-type-selected";
    } else {
        res = "opacity-50";
    }
    return res;
};

export default UseMainOptions;
