import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Toolbar from "./CommonOptions/Toolbar";
import Input from "./CommonOptions/Input";
import UseRadio from "../../../../useRadio";
import ScreenMargins from "./OptionsDetails/ScreenMargins";
import Widget from "../Widget";
import Settings from "./CommonOptions/Settings";
import ReactTooltip from "components/ReactTooltip";
import { setWidgetToolbarVisible } from "../../../../../actions/designActions";
import { setExecuteQuery } from "../../../../../actions/sectionActions";
import { WidgetContext, EditorContext } from "../../../../../contexts/editor";
import { capitalizeFirst } from "../../../../../hooks/Utils/Utils";
import Parser from "hooks/Utils/Parser";
import sanitizeHtml from "sanitize-html";

const TVMenuToolbar = ({ deviceSpecific }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { gridItems, widgetToolbarVisible, itemSelected } = useSelector((state) => state.design);
    const { config, id: widgetID } = useContext(WidgetContext);
    const { deviceType } = useContext(EditorContext);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const data = config?.data || Widget.ParseData(widget ? widget.data : null, deviceType);
    const menuType = data && data.menuType ? data.menuType : "fixed";
    const buttonRC = data && data.buttonRC ? data.buttonRC : [];
    const sectionName = `toolbar-tvmenu`;

    const buttonsRC = [
        { value: "menu", icon: "icon-menu-remotecontrol" },
        { value: "left", icon: "icon-arrow-left" },
        { value: "up", icon: "icon-arrow-up" },
        { value: "right", icon: "icon-arrow-right" },
        { value: "bottom", icon: "icon-arrow-down" },
    ];
    const tabs = [
        { id: "defaultColor", name: t("default") },
        { id: "activeColor", name: t("active") },
        { id: "focusColor", name: t("focus") },
    ];
    const [currentStyleTab, setCurrentStyleTab] = useState(tabs[0].id);
    const [zoomInOutAnimation, setZoomInOutAnimation] = useState(null);
    const [buttonsRadioController, setButtonsRadioController] = useState(null);
    const [menuTypeOption, setMenuTypeOption] = useState(null);

    useEffect(() => {
        if (widgetID === itemSelected) {
            dispatch(setWidgetToolbarVisible("style", currentStyleTab));
        } else {
            setCurrentStyleTab(tabs[0].id);
            dispatch(setWidgetToolbarVisible(null));
        }
        // eslint-disable-next-line
    }, [currentStyleTab, itemSelected]);

    useEffect(() => {
        if (
            (widgetToolbarVisible === "tv-menu-settings" || widgetToolbarVisible === "style") &&
            widgetID === itemSelected
        ) {
            reloadFields();
        }
        if (widgetToolbarVisible === "style") {
            setCurrentStyleTab(tabs[0].id);
        }
        // eslint-disable-next-line
    }, [widgetToolbarVisible]);

    useEffect(() => {
        if (widgetID === itemSelected) {
            reloadFields();
        }
        // eslint-disable-next-line
    }, [gridItems]);

    useEffect(() => {
        if (zoomInOutAnimation !== null) {
            updateZoomInOut();
        }
        // eslint-disable-next-line
    }, [zoomInOutAnimation]);

    useEffect(() => {
        if (buttonsRadioController === null && buttonRC.length > 0) {
            setButtonsRadioController(buttonRC);
        } else if (buttonsRadioController === null && buttonRC.length === 0) {
            //button Home/Portal MANDATORY  in Remote Control
            setButtonsRadioController(["home-portal"]);
        }
        // eslint-disable-next-line
    }, [buttonRC]);

    useEffect(() => {
        if (menuTypeOption === null) {
            setMenuTypeOption(menuType);
        }
        // eslint-disable-next-line
    }, [menuType]);

    const reloadFields = () => {
        if ((data && data.zoomInOutAnimation === false) || (data && data.zoomInOutAnimation === true)) {
            setZoomInOutAnimation(data.zoomInOutAnimation);

            const switchEl = document.querySelector("#zoom-in-out-switch");
            if (switchEl) {
                switchEl.checked = zoomInOutAnimation;
            }
        }
    };

    const updateMenuType = (value) => {
        setMenuTypeOption(value);
        dispatch(setExecuteQuery(Widget.UpdateData({ widget, property: "menuType", value })));
    };

    const updateButtonRC = (value) => {
        let valueButtonsRC = [...buttonsRadioController];
        if (!valueButtonsRC.includes(value)) {
            valueButtonsRC.push(value);
        } else {
            valueButtonsRC = [...valueButtonsRC.filter((button) => button !== value)];
        }
        setButtonsRadioController(valueButtonsRC);
        dispatch(setExecuteQuery(Widget.UpdateData({ widget, property: "buttonRC", value: valueButtonsRC })));
    };

    const updateZoomInOut = () => {
        dispatch(
            setExecuteQuery(Widget.UpdateData({ widget, property: "zoomInOutAnimation", value: zoomInOutAnimation }))
        );
    };

    const renderButtonRC = (value, icon) => {
        return (
            <div
                data-tip={capitalizeFirst(t(value))}
                data-value={value}
                onClick={(e) => {
                    updateButtonRC(e.target.dataset.value);
                }}
                onMouseOver={() => {
                    ReactTooltip.rebuild();
                }}
                className={`${
                    buttonsRadioController && buttonsRadioController.includes(value)
                        ? " bg-zafiro-200 text-white "
                        : " bg-transparent text-gray-800"
                } h-12 w-12 flex items-center justify-center cursor-pointer font-hairline border-gray-400 rounded-sm border icon ${icon}`}
            ></div>
        );
    };

    return (
        <>
            <div
                id={`${sectionName}-settings`}
                className={`icon icon-settings-editor cursor-pointer p-2 ${
                    widgetToolbarVisible === "tv-menu-settings" ? "bg-gray-200" : ""
                }`}
                onClick={(e) =>
                    dispatch(
                        setWidgetToolbarVisible(widgetToolbarVisible === "tv-menu-settings" ? null : "tv-menu-settings")
                    )
                }
            ></div>

            {widgetToolbarVisible === "tv-menu-settings" ? (
                <div
                    id={`widgetOptionContainer_${widgetID}`}
                    className={`widgetOptionContainer invisible p-0`}
                    style={{ width: "29rem" }}
                >
                    <ScreenMargins deviceType={deviceType} isMenu={true} />
                    <Settings>
                        <Settings.Group title={t("type-of-menu")}>
                            <ReactTooltip
                                place="bottom"
                                type="light"
                                offset={{ top: -8, left: -8 }}
                                html={true}
                                border={true}
                                multiline={true}
                                borderColor="#D3DAE1"
                            />
                            <div
                                className="first-capital mb-5 pl-6 cursor-pointer"
                                onClick={(e) => updateMenuType("fixed-menu")}
                            >
                                <UseRadio selected={menuTypeOption} value={"fixed-menu"} />
                                <div className="first-capital ml-6 mb-2">{t("fixed-menu")}</div>
                            </div>
                            {menuTypeOption === "fixed-menu" && (
                                <div className=" pl-12 -mt-2 flex flex-col  ">
                                    <div className="first-capital mb-2 flex items-center">
                                        <span className="first-capital">{t("screen-will-not-show-menu")} </span>
                                    </div>
                                    <div className="first-capital mb-2 flex items-center">
                                        <span className="first-capital">
                                            {Parser(String(t("choose-button-use-infixed-menu")))}
                                        </span>
                                    </div>
                                </div>
                            )}
                            <div
                                className="first-capital mb-5 pl-6 cursor-pointer"
                                onClick={(e) => updateMenuType("hidden-menu")}
                            >
                                <UseRadio selected={menuTypeOption} value={"hidden-menu"} />
                                <div className="first-capital ml-6 mb-2">{t("hidden-menu")}</div>
                            </div>
                            {menuTypeOption === "hidden-menu" && (
                                <div className=" pl-12 -mt-2 flex flex-col  ">
                                    <Input.Check
                                        adjustTitle={"w-6-12 mr-2 mb-2"}
                                        id="blurMainScreen"
                                        deviceSpecific={false}
                                        title={t("blur background")}
                                    />
                                    <div className="first-capital mb-2 flex items-center">
                                        <span className="first-capital">{t("select-button-control")} </span>
                                        <span
                                            className=" ml-1 text-gray-700 icon icon-info"
                                            data-tip={t("choose-button-use")}
                                            onMouseOver={() => {
                                                ReactTooltip.rebuild();
                                            }}
                                        ></span>
                                    </div>
                                    <div className="flex mb-4 mt-2 space-x-1">
                                        <div
                                            data-tip={sanitizeHtml(
                                                capitalizeFirst(t("home-portal")) + `<p>* ${t("button-mandatory")}</p>`
                                            )}
                                            data-value={capitalizeFirst(t("home-portal"))}
                                            onMouseOver={() => {
                                                ReactTooltip.rebuild();
                                            }}
                                            className={`bg-zafiro-800 text-white h-12 w-12 flex items-center justify-center cursor-pointer font-hairline border-gray-400 rounded-sm border icon icon-home`}
                                        ></div>
                                        {buttonsRC.map((button) => {
                                            return renderButtonRC(button.value, button.icon);
                                        })}
                                    </div>
                                </div>
                            )}
                            <div
                                className="first-capital mb-5 pl-6 cursor-pointer"
                                onClick={(e) => updateMenuType("none")}
                            >
                                <UseRadio selected={menuTypeOption} value={"none"} />
                                <div className="first-capital ml-6 mb-2">{t("none")}</div>
                            </div>
                        </Settings.Group>
                    </Settings>
                </div>
            ) : null}

            <Toolbar type="style" parentSectionName={sectionName}>
                {tabs && tabs.length > 1 ? (
                    <Toolbar.Tabs
                        data={tabs}
                        currentTab={currentStyleTab}
                        setCurrentTab={(tab) => {
                            setCurrentStyleTab(tab);
                        }}
                    />
                ) : null}
                {tabs && tabs.length > 0
                    ? tabs.map((tab) => {
                          return (
                              <Settings key={tab.id + "-tab-content"} visible={tab.id === currentStyleTab}>
                                  <Settings.Group type="colors" title={"colors"}>
                                      <Input.Color
                                          id={
                                              tab.id === "defaultColor"
                                                  ? "bgColor"
                                                  : tab.id === "activeColor"
                                                  ? "bgActiveColor"
                                                  : "focusBgColor"
                                          }
                                          title={t("background")}
                                      />
                                      <Input.Color
                                          id={
                                              tab.id === "defaultColor"
                                                  ? "fgColor"
                                                  : tab.id === "activeColor"
                                                  ? "fgActiveColor"
                                                  : "fgFocusColor"
                                          }
                                          title={t("text")}
                                      />
                                      <Input.Color
                                          id={
                                              tab.id === "defaultColor"
                                                  ? "iconColor"
                                                  : tab.id === "activeColor"
                                                  ? "iconActiveColor"
                                                  : "iconFocusColor"
                                          }
                                          title={t("icons")}
                                      />
                                  </Settings.Group>
                                  {tab.id === "defaultColor" ? (
                                      <Settings.Border t={t} title="corners" onlyRadius={true} />
                                  ) : null}
                                  {tab.id === "focusColor" ? (
                                      <Settings.Group title="animation">
                                          <div className=" flex items-center">
                                              <span>{capitalizeFirst(t("zoom-in-and-out"))}</span>
                                              <label className="cursor-pointer switch ml-auto">
                                                  <input
                                                      type="checkbox"
                                                      id="zoom-in-out-switch"
                                                      checked={null}
                                                      onChange={(e) => {
                                                          setZoomInOutAnimation(e.currentTarget.checked);
                                                      }}
                                                  ></input>
                                                  <span className="slider round"></span>
                                              </label>
                                          </div>
                                      </Settings.Group>
                                  ) : null}
                              </Settings>
                          );
                      })
                    : null}
            </Toolbar>
        </>
    );
};

export default TVMenuToolbar;
