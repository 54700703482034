import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { changeActionValues } from "../actions/globalActions";
import { showGlobalLoading } from "../actions/uiActions";
import { updateDataOptions } from "../hooks/Utils/Utils";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";

//Utils
import useComponentVisible from "./useComponentVisible";
import UseLoading from "../components/Table/useLoading";
import UseCheckOptions from "./useCheckOptions";
import { removeDiacritics, multipleTranslate } from "../hooks/Utils/Utils";
import Parser from "hooks/Utils/Parser";
import parseOptionsSelect from "../hooks/Utils/Modal/parseOptionsSelect";
import { useTranslation } from "react-i18next";
import ReactTooltip from "components/ReactTooltip";

const UseSelectMultipleWithSearchOptionsQuery = ({ dataSelect }) => {
    const { t } = useTranslation();

    const {
        optionData,
        oneSelected,
        name,
        optionsQuery,
        selectPlaceHolder,
        noSelect,
        text,
        textStyle,
        cStyle,
        optionsAdjust,
        idLabel,
        sort,
        nameLabel,
        selectedIdsPath,
        id,
        selectedIds,
        noShowSelectedOptionsInDropDownSection,
        disabled = false,
    } = dataSelect;
    const parseInfo = { actionName: name, idLabel, nameLabel, t };
    const { values, validations } = useSelector((state) => state.action);

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //States
    const [selectActive, setSelectActive] = useState({});
    const initialOptions = optionData ? JSON.parse(JSON.stringify(optionData)) : [];
    const [dataOptions, setDataOptions] = useState(initialOptions);
    const [searching, setSearching] = useState(false);

    //API
    const GET_OPTIONS = optionsQuery
        ? gql`
              ${optionsQuery}
          `
        : null;
    const [getOptionsQuery, { data, loading }] = useLazyQuery(GET_OPTIONS, {
        fetchPolicy: "network-only",
    });

    //Actions
    const dispatch = useDispatch();

    //Listeners
    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({});
        } else {
            //ensure visibility of all elements
            const temCopy = JSON.parse(JSON.stringify(dataOptions));
            temCopy.map((item) => (item.visible = true));
            setDataOptions(temCopy);

            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
            });
        }
        // eslint-disable-next-line
    }, [isComponentVisible]);

    useEffect(() => {
        const selIds = dataOptions.map((item) => (item.selected ? item.id : null));
        dispatch(
            changeActionValues({
                [name]: selIds.filter((item) => item !== null),
            })
        );

        //get options from query
        if (optionsQuery) {
            // dispatch(setLoadingModalContent(true));
            dispatch(showGlobalLoading(true));
            getOptionsQuery();
        }
        // eslint-disable-next-line
    }, []);

    //File1
    useEffect(() => {
        updateDataOptions({
            dataOptions,
            values,
            name,
            setDataOptions,
            oneSelected,
            setIsComponentVisible,
        });
        // eslint-disable-next-line
    }, [values]);

    useEffect(() => {
        if (data && data.data && typeof data.data.results === "undefined") {
            // FIXME: add results in response query occupiedRoomsInfo @BACKEND
            // TEMPORAL solution to get rooms for ALARMS
            let tmpOptions = [];
            //as occupiedRoomsInfo comes with no results in data mapping here the results
            data.data.map((item) => tmpOptions.push(parseOptionsSelect(item, { ...parseInfo, selectedIds })));

            if (sort) {
                tmpOptions = tmpOptions.sort((a, b) => String(a.name).localeCompare(String(b.name)));
            }
            setDataOptions(tmpOptions);
        }
        if (data && data.data && data.data.results) {
            dispatch(showGlobalLoading(false));
            let idsToSelect = data.selected && data.selected.results ? data.selected.results : null;
            if (selectedIdsPath) {
                // eslint-disable-next-line
                idsToSelect = eval(selectedIdsPath);
            } else if (selectedIds) {
                idsToSelect = selectedIds;
            }
            let tmpOptions = [];
            data.data.results.map((item) => tmpOptions.push(parseOptionsSelect(item, { ...parseInfo, idsToSelect })));
            if (idsToSelect) {
                let idsArray = [];
                idsToSelect.map((item) => idsArray.push(item.id));
                dispatch(changeActionValues({ [name]: idsArray }));
            }
            if (sort) {
                tmpOptions = tmpOptions.sort((a, b) => String(a.name).localeCompare(String(b.name)));
            }
            setDataOptions(tmpOptions);
        }
        // eslint-disable-next-line
    }, [data]);

    const removeOption = (e) => {
        e.stopPropagation();
        const temCopy = JSON.parse(JSON.stringify(dataOptions));
        temCopy.map((item) =>
            String(item.id) === String(e.currentTarget.dataset.id) ? (item.selected = false) : null
        );
        setDataOptions(temCopy);

        const selIds = temCopy.map((item) => (item.selected ? item.id : null));
        dispatch(
            changeActionValues({
                [name]: selIds.filter((item) => item !== null),
            })
        );
    };

    const filterZones = (e) => {
        e.stopPropagation();

        const search = e?.currentTarget?.value || "";

        const temCopy = initialOptions?.length
            ? JSON.parse(
                  JSON.stringify(
                      initialOptions?.map((i) => {
                          const found = dataOptions ? dataOptions.find((d) => d.id === i.id) : null;
                          return found || i;
                      })
                  )
              )
            : dataOptions;

        if (search?.length) {
            setSearching(true);
            const cleanSearch = removeDiacritics(search.toLowerCase());
            temCopy.map((item) => {
                if (item) {
                    const cleanName = removeDiacritics((item.name || "").toLowerCase());
                    const cleanCategory = removeDiacritics((item.category || "").toLowerCase());
                    item.visible = cleanName.includes(cleanSearch) || cleanCategory.includes(cleanSearch);
                }
                return item;
            });
        } else {
            setSearching(false);
            temCopy.map((item) => (item.visible = true));
        }
        setDataOptions(temCopy);
    };

    const changeVisibility = (e) => {
        if (disabled) {
            return;
        }
        if (isComponentVisible) {
            const temCopy = JSON.parse(JSON.stringify(dataOptions));
            temCopy.map((item) => (item.visible = true));
            setDataOptions(temCopy);
        }

        if (!isComponentVisible) {
            setTimeout(function () {
                const inputSearch = document.querySelector(`#${name}_input`);
                if (inputSearch) {
                    inputSearch.focus();
                }
            }, 150);
        }

        const getParentFromTarget = (el) => {
            let targetOptionsConainer = false;
            for (let parents = []; el; el = el.parentNode) {
                if (String(el.id) === "options-container") {
                    targetOptionsConainer = true;
                }
                parents.push(el);
            }
            return targetOptionsConainer;
        };

        if (getParentFromTarget(e.target)) {
            return;
        }

        setIsComponentVisible(isComponentVisible ? false : true);
    };

    const showSelectedOptionsInDropDownSection = () => {
        let response = [];
        if (dataOptions != null && dataOptions.length > 0) {
            // eslint-disable-next-line
            dataOptions.map((item, index) => {
                if (item.selected) {
                    if (response != null && response.length < 1)
                        response.push(
                            <p className="font-bold text-gray-900 first-capital mt-1 mb-1">{t("selected")}:</p>
                        );
                    response.push(
                        <div
                            key={item.id}
                            className="float-left z-20  bg-blue-200 text-white pl-2 mr-4 text-sm py-0.5 px-1 mb-2 rounded"
                        >
                            {Parser(item.name)}
                            <div
                                className="icon-remove inline-block ml-8 cursor-pointer"
                                data-index={index}
                                data-id={item.id}
                                onClick={removeOption}
                            ></div>
                        </div>
                    );
                }
            });
        }
        return response;
    };

    return (
        <>
            {text ? (
                <span className={textStyle ? textStyle : "block pb-2 first-capital"}>{multipleTranslate(text, t)}</span>
            ) : null}
            <div
                tabIndex={0}
                ref={ref}
                className={`h-32px ${disabled ? " bg-gray-300" : "bg-gray-200"} rounded relative ${
                    cStyle ? cStyle : "w-full"
                } ${selectActive.container} } ${
                    typeof validations[name] !== "undefined" && !validations[name] ? "border border-red-100" : ""
                }`}
                onKeyPress={(e) => (e.key === "Enter" ? changeVisibility() : null)}
                onClick={changeVisibility}
                id={id ? `dropdown-${id}` : null}
            >
                <div className={`t-filter-ph z-10`} onClick={changeVisibility}>
                    {dataOptions.map((item, index) =>
                        item.selected &&
                        !noSelect &&
                        dataOptions.filter((item) => item.selected === true).length < 3 ? (
                            <div
                                key={item.id}
                                id={id ? `${id}-option-selected-${item.id}` : null}
                                className="float-left z-20  bg-blue-200 text-white pl-2 mr-4 py-0.5 px-1 text-base my-0.5 rounded cursor-default"
                                data-tip={item.tooltip ? item.tooltip : null}
                                onMouseOver={() => ReactTooltip.rebuild()}
                            >
                                {Parser(item.name)}
                                <div
                                    id={id ? `${id}-option-selected-${item.id}-remove-button` : null}
                                    className="icon-remove inline-block ml-8 cursor-pointer"
                                    data-index={index}
                                    data-id={item.id}
                                    onClick={removeOption}
                                ></div>
                            </div>
                        ) : null
                    )}
                    {(noSelect && dataOptions.filter((item) => item.selected === true).length > 0) ||
                    dataOptions.filter((item) => item.selected === true).length >= 3
                        ? dataOptions.filter((item) => item.selected === true).length === 1 && oneSelected
                            ? Parser(dataOptions.filter((item) => item.selected === true)[0].name)
                            : `${dataOptions.filter((item) => item.selected === true).length} ${t("selected")}`
                        : null}
                    {dataOptions.filter((item) => item.selected === true).length === 0 ? t(selectPlaceHolder) : null}
                </div>
                <div className="w-full flex">
                    <span
                        className={`field-icon icon-chevron animated cursor-pointer text-gray-900 ${selectActive.icon}`}
                        onClick={changeVisibility}
                        id={id ? `dropdown-icon-${id}` : null}
                    ></span>
                    {isComponentVisible ? (
                        <div id="options-container" className={`t-options-container mt-2 ${optionsAdjust}`}>
                            <div>
                                <div>
                                    <input
                                        id={`use-select-multiple-input-search`}
                                        type="text"
                                        className="t-filter-search"
                                        style={{ width: "calc(100% - 1rem)" }}
                                        placeholder={`${t("search")}...`}
                                        onChange={filterZones}
                                    ></input>
                                    <span
                                        className="field-icon icon-search table mt-6"
                                        style={{ height: "2rem" }}
                                    ></span>
                                </div>
                            </div>
                            <span>
                                {loading ? (
                                    <UseLoading />
                                ) : (
                                    <>
                                        {noSelect && !noShowSelectedOptionsInDropDownSection ? (
                                            <div className="bg-transparent w-full overflow-hidden pl-3">
                                                {showSelectedOptionsInDropDownSection()}
                                            </div>
                                        ) : null}
                                        <UseCheckOptions
                                            dataOptions={dataOptions}
                                            setDataOptions={setDataOptions}
                                            name={name}
                                            oneSelected={oneSelected}
                                            searching={searching}
                                            useOptionID={false}
                                        />
                                    </>
                                )}
                            </span>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default UseSelectMultipleWithSearchOptionsQuery;
