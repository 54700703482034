import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Button from "components/Button";
import Icon from "components/Icon";

import { GlobalContext } from "contexts/Global";

/**
 * SelectorItem Component
 *
 * This component renders a list of buttons based on the `items` prop. Each button can represent a selectable item.
 *
 * @param {string} id - The unique identifier for the component.
 * @param {string} type - The type of selector item to render. Currently, only "satisfaction" is supported.
 * @param {number} value - The current selected value.
 * @param {boolean} readOnly - Whether the component is read-only.
 * @param {function} onChange - The function to call when the selection changes.
 * @param {boolean} disabled - Whether the component is disabled.
 *
 * @returns {JSX.Element} The rendered SelectorItem component.
 */
const SelectorItem = (props) => {
    const { id, type, value: initialValue, readOnly, onChange, disabled } = props;

    const { highlightComponent } = useContext(GlobalContext);

    const [selected, setSelected] = useState(initialValue);

    const setValue = (value) => {
        if (value === selected) {
            return;
        }
        if (typeof onChange === "function") {
            onChange(value);
        }
        setSelected(value);
    };

    useEffect(() => {
        // Reset the selected value when the initialValue changes
        setValue(initialValue);
    }, [initialValue]);

    const items =
        type === "satisfaction"
            ? [
                  <Icon key={5} type="Face-very-happy" size={2} style={!disabled && { color: "#2A8A61" }} />,
                  <Icon key={4} type="Face-happy" size={2} style={!disabled && { color: "#36B37E" }} />,
                  <Icon key={3} type="Face-neutral" size={2} style={!disabled && { color: "#EBC500" }} />,
                  <Icon key={2} type="Face-sad" size={2} style={!disabled && { color: "#FF991F" }} />,
                  <Icon key={1} type="Face-angry" size={2} style={!disabled && { color: "#DE350B" }} />,
              ]
            : null;

    if (!items?.length) {
        throw new Error(`SelectorItem: type "${type}" is not supported.`);
    }

    const globalClass = classNames({
        "flex space-x-3 items-center justify-center": true,
        "highlight-component": highlightComponent,
    });

    return (
        <div className={globalClass}>
            {items?.map((item, index) => {
                const value = items.length - index;
                const buttonID = id ? `${id}-selector-item-component-${index}` : null;
                const isSelected = selected === value;
                const isMarked = selected !== null && value < selected;
                return (
                    <Button
                        key={buttonID}
                        id={buttonID}
                        className={classNames({
                            "selector-item": true,
                            selected: isSelected,
                            marked: isMarked,
                            editable: !readOnly && !disabled,
                            disabled: disabled,
                        })}
                        onClick={() => setValue(items.length - index)}
                        disabled={disabled}
                        readOnly={readOnly}
                    >
                        {item}
                    </Button>
                );
            })}
        </div>
    );
};

SelectorItem.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.number,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

SelectorItem.defaultProps = {
    value: null,
    readOnly: false,
    onChange: null,
    disabled: false,
};

export default SelectorItem;
