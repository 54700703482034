import { useEffect } from "react";
import { useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Actions
import { setSectionContent, setActiveEventType, setSectionName } from "../../../actions/sectionActions";
import { setTitle } from "../../../actions/tableActions";
//Utils
import { useParams } from "react-router-dom";
import { Session } from "../../Utils/Session";
import { POLLINTERVAL_15S } from "../../Utils/Utils";

const SetActivitySchedule = () => {
    //Data
    const punctualEvent = "punctual-event";
    const periodicEvent = "periodic-event";
    const { id } = useParams();
    const specialSchedule = "special-schedule";
    const exitWoSave = "exit-unsaved";
    const exitWoSaveStyle = "btn-blue-outline";
    const normalColumn = "col-span-3 pr-4";

    //API
    //ISO 8601 standard Monday(1) Sunday (7)
    const GET_AREA_SCHEDULES = gql`{
    schedules: timetables(page: 1, size: 100, area: ${id},orderBy: {field: "id", criteria: "desc"}) {
      results {
        ID
        dateType
        isEnabled
        name
        periodicityType
        type
        timetableDates {
          dateList
          timetableDateRanges{
            timeRanges
            weekdayList
          }
        }
      }
    }
    areaName: commonzones(page: 1, size: 1 id: ${id}){ results { name }}
  }`;
    const [executeQuery, { data, loading, error }] = useLazyQuery(GET_AREA_SCHEDULES, {
        pollInterval: POLLINTERVAL_15S,
    });

    //Actions
    const dispatch = useDispatch();

    const arrangeData = () => {
        let response = {
            results: [],
        };

        data.schedules.results.forEach((timetable) => {
            response.results.push(timetable);
        });

        const newPunctualEventsData = getEventDataLayout(normalColumn);

        const newperiodicEventsData = getEventDataLayout(normalColumn);

        if (response.results.length > 0) {
            response.results.forEach((item) => {
                if (item.periodicityType === "UNIQUE") {
                    newPunctualEventsData.currentData.push(item);
                } else {
                    newperiodicEventsData.currentData.push(item);
                }
            });
        }

        const eventTypes = getEventTypes(punctualEvent, periodicEvent);
        const dateTypes = getDateTypes();
        const stepsData = getStepsData(exitWoSave, exitWoSaveStyle, specialSchedule, normalColumn);

        const content = {
            eventTypes: eventTypes,
            steps: stepsData.length,
            currentStep: false,
            stepsData: stepsData,
            punctualEventsData: newPunctualEventsData,
            periodicEventsData: newperiodicEventsData,
            dateTypes: dateTypes,
            areaID: id,
        };

        //Show content if previous operation registered
        const previousSelected = Session.getSessionProp("event");
        let active;
        if (isPreviousPunctual(previousSelected)) {
            active = punctualEvent;
            content.eventTypeActive = active;
        } else if (isPreviousPeriodic(previousSelected)) {
            active = periodicEvent;
            content.eventTypeActive = active;
        }
        if (content) {
            dispatch(setSectionContent(content));
        }
        if (data && data.areaName) {
            dispatch(setTitle(data.areaName.results[0].name));
        }
        if (gotQueryResults(data)) {
            dispatch(setActiveEventType(getActiveEventType(data.schedules.results, punctualEvent, periodicEvent)));
        }
        dispatch(setSectionName("area-schedules"));
    };

    useEffect(() => {
        if (isQueryOk(data, loading, error)) {
            arrangeData();
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        executeQuery();
        // eslint-disable-next-line

        return () => {
            dispatch(setSectionName(""));
        };
        // eslint-disable-next-line
    }, []);
};

const getEventTypes = (punctualEvent, periodicEvent) => {
    return {
        selected: false,
        options: [
            {
                title: "punctual-event-title",
                text: "punctual-event-text",
                value: punctualEvent,
            },
            {
                title: "periodic-event-title",
                text: "periodic-event-text",
                value: periodicEvent,
            },
        ],
    };
};

const getDateTypes = () => {
    return [
        { value: "date-range", translate: true },
        { value: "date-specific", translate: true },
    ];
};

const getStepsData = (exitWoSave, exitWoSaveStyle, specialSchedule, normalColumn) => {
    return [
        {
            title: "common-schedule",
            headers: [
                {
                    name: "schedule",
                    translate: true,
                    specialWidth: "col-span-5 pr-4",
                },
                {
                    name: "week-days",
                    translate: true,
                    specialWidth: "col-span-7 pr-4",
                },
            ],
            inputs: [
                {
                    name: "schedule",
                    type: "schedule",
                },
                {
                    name: "week-days",
                    type: "week-days",
                },
                {
                    name: "cancel",
                    type: "button",
                    color: "blue-outline",
                    position: "footer",
                },
                {
                    name: "save",
                    type: "button",
                    color: "blue",
                    position: "footer",
                },
            ],
            buttons: [
                { name: exitWoSave, color: exitWoSaveStyle },
                { name: "continue-2", color: "btn-blue" },
            ],
        },
        {
            title: specialSchedule,
            headers: [
                {
                    name: "date-type",
                    translate: true,
                    specialWidth: normalColumn,
                },
                { name: "dates", translate: true, specialWidth: normalColumn },
                {
                    name: "schedule",
                    translate: true,
                    specialWidth: normalColumn,
                },
                {
                    name: "week-days",
                    translate: true,
                    specialWidth: normalColumn,
                },
            ],
            inputs: [
                {
                    name: "usual-schedule",
                    title: "usual-schedule",
                    type: "schedule",
                    buttons: [{ name: "add-schedule", color: "btn-blue" }],
                },
                {
                    name: specialSchedule,
                    title: specialSchedule,
                    type: "calendar",
                    options: ["range", "multiple"],
                },
            ],
            buttons: [
                { name: exitWoSave, color: exitWoSaveStyle },
                { name: "continue-3", color: "btn-blue" },
            ],
        },
        {
            title: "closed-days",
            headers: [
                {
                    name: "date-type",
                    translate: true,
                    specialWidth: normalColumn,
                },
                { name: "dates", translate: true, specialWidth: normalColumn },
            ],
            inputs: [
                {
                    name: "special-closed",
                    title: "special-closed",
                    type: "calendar",
                    options: ["multiple"],
                },
            ],
            buttons: [
                { name: exitWoSave, color: exitWoSaveStyle },
                { name: "finish-save", color: "btn-blue" },
            ],
        },
    ];
};

const getEventDataLayout = (normalColumn) => {
    return {
        headers: [
            { name: "date-type", translate: true, specialWidth: normalColumn },
            { name: "dates", translate: true, specialWidth: normalColumn },
            { name: "schedule", translate: true, specialWidth: normalColumn },
        ],
        currentData: [],
    };
};

const getActiveEventType = (results, punctualEvent, periodicEvent) => {
    let res;
    if (results[0].periodicityType === "UNIQUE") {
        res = punctualEvent;
    } else {
        res = periodicEvent;
    }
    return res;
};

const isQueryOk = (data, loading, error) => {
    let res;
    if (data && !loading && !error) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

const isPreviousPunctual = (previousSelected) => {
    let res;
    if (previousSelected && previousSelected === "punctual") {
        res = true;
    } else {
        res = false;
    }
    return res;
};

const isPreviousPeriodic = (previousSelected) => {
    let res;
    if (previousSelected && previousSelected === "periodic") {
        res = true;
    } else {
        res = false;
    }
    return res;
};

const gotQueryResults = (data) => {
    let res;
    if (data && data.schedules && data.schedules.results && data.schedules.results.length > 0) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export default SetActivitySchedule;
