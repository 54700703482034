function NewRoomGroupModal(name, roomgroupId) {
    const roomsQuery = `{
        data: rooms(orderBy:{field:"name", criteria: "asc"}){
            info { count }
            results { id name  }
          }
          ${roomgroupId
            ? `selected: hotelRooms(layout:{orderBy:{field:"id",criteria:"asc"}},filter: {roomGroupID:${roomgroupId}}){
                        results { id }
                    }`
            : ""
        }
        }
        `;

    return [
        {
            title: roomgroupId ? [name, "-", "edit-room-group"] : "new-group-of-rooms",
            bodyAdjust: "w-10/12",
            twoColumns: true,
            id: roomgroupId,
            inputs: [
                {
                    text: ["give-group-name", ":"],
                    name: "name",
                    value: name,
                    type: "text",
                    ph: "group-name",
                    cStyle: "w-5/12",
                },
                {
                    text: ["rooms-added", ":"],
                    name: "rooms-of-group",
                    ph: "rooms",
                    type: "selectedResume",
                    optionsQuery: roomsQuery,
                    style: "row-span-2",
                    cStyle: "w-full",
                },
                {
                    text: ["select-room-group", ":"],
                    name: "rooms-of-group",
                    type: "selectMultipleWithSearch",
                    optionsQuery: roomsQuery,
                    noSelect: true,
                    cStyle: "w-9/12",
                    selectPlaceHolder: "rooms",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "accept",
                    style: "blue",
                    action: "add-room-group",
                },
            ],
        },
    ];
}

export default NewRoomGroupModal;
