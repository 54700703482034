const NewBulkChannel = () => {
    return {
        title: "add-bulk-channels",
        text: "select-channel-class",
        executeSectionQuery: true,
        inputs: [
            {
                name: "bulk-channel-selected",
                type: "radio",
                translatable: ["text"],
                values: ["preset-channel", "customised-channel"],
                selected: "preset-channel",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: "add-bulk-channels",
            },
        ],
    };
};

export default NewBulkChannel;
