import React, { useEffect, useState } from "react";
import { gql } from "apollo-boost";
import { withApollo } from "@apollo/react-hoc";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setModalContent, showGlobalLoading } from "actions/uiActions";
import { setExecuteQuery } from "actions/sectionActions";
import { setRefreshData } from "../../../actions/tableActions";
import { cleanAction } from "actions/globalActions";
import { capitalizeFirst, removeApolloCacheKeys } from "hooks/Utils/Utils";
import { toast } from "react-toastify";
import { closeModal } from "actions/uiActions";
import { verifyGuest, mapRooms } from "components/Section/Customers/Precheckin/ReservationDetails/utils";
import JSZip from "jszip";
import papaparse from "papaparse";
import { saveAs } from "file-saver";
import { Session } from "hooks/Utils/Session";

const UpdateBooking = ({ client }) => {
    const { t } = useTranslation();
    const initialValue = "mutation{checkIn}";

    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { lang } = useSelector((state) => state.ui);
    const { useGlobalLoading, inputs } = useSelector((state) => state.ui.modalContent);
    const { values, itemsAffected } = useSelector((state) => state.action);

    const [mutation, setMutation] = useState(initialValue);
    const [secondaryMutation, setSecondaryMutation] = useState(initialValue);
    const [executeMutationConfirmation, setExecuteMutationConfirmation] = useState(false);
    const [downloadFile, setDownloadFile] = useState(false);

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const SECONDARY_MUTATION_OBJECT = gql`
        ${secondaryMutation}
    `;

    const NEW_QUERY = gql`
    {
        getStayTravelerPartReport(startDate: "${values["startDate"]}", endDate: "${values["endDate"]}", extendedTravelerPart: true) {
            results {
                date
                stayTravelersPart{
                    stayGuestInformation{
                      names {
                        lang
                        name
                      }
                      ref
                      value
                    }
                }
            }
        }
    }
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        errorPolicy: "all",
        onError(err) {
            //this control is in this onError, because errorPolicy =all is not working like in queryes
            //Custom errors
            // let validations = {};
            let errorMsg = err.message;

            dispatch(showGlobalLoading(false));
            toast.error(errorMsg);
        },
    });

    const [executeSecondaryMutation] = useMutation(SECONDARY_MUTATION_OBJECT, {
        errorPolicy: "all",
        onCompleted(val) {
            let elements = [];
            let index = 0;
            while (val[`addStayGuestToStayBooking${index}`]) {
                elements.push(val[`addStayGuestToStayBooking${index}`]?.id);
                index++;
            }
            executeQuery.mutationName = "checkInFromStayBooking";
            executeQuery.closeModal = true;
            const { rooms, mainGuestID } = mapRooms(inputs[1].data.stayBookings.numberOfRooms, values, elements);
            setMutation(`mutation {
                checkInFromStayBooking(stayBookingID: ${itemsAffected[0]}, mainGuestID: ${mainGuestID}, rooms:[${rooms}]){
                  error
                  id
                  ok
                }
              }`);
            setExecuteMutationConfirmation(true);
        },
        onError(err) {
            let errorMsg = err.message;
            dispatch(showGlobalLoading(false));
            toast.error(errorMsg);
        },
    });

    const [executeNewQuery, { data: newQueryData, loading: newQueryLoading }] = useLazyQuery(NEW_QUERY, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (executeMutationConfirmation && mutation != initialValue) {
            executeMutation();
        }
    }, [executeMutationConfirmation, mutation]);

    useEffect(() => {
        if (secondaryMutation != initialValue) {
            executeSecondaryMutation();
        }
    }, [secondaryMutation]);

    const dispatch = useDispatch();
    useEffect(() => {
        let execute = false;
        let mutation = null;
        let executeAnotherQuery = null;
        let avoidToast = false;
        let noCleanAction = true;
        let mutationFile = null;
        let aditionalHeader = null;
        let newQuery = false;
        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.mutationName = "";
            execute = true;
            switch (executeQuery.action) {
                case "add-booking":
                    execute = true;
                    setMutation(`mutation {
                        createStayBooking(
                          code: "${values["booking-code"]}"
                          numberOfRooms: ${values["rooms-reserved"]}
                          numberOfGuests: ${values["number-of-guests"]}
                          arrivalDate: "${values["arrival-date"]}"
                          ${values["departure-date"] ? `departureDate: "${values["departure-date"]}"` : ""}
                          stayHolderSurname: "${values["surname"]}"
                          stayHolderName: "${values["name"]}"
                          stayHolderEmail: "${values["email"]}"
                          ${values["pms-id"] ? `pmsID: "${values["pms-id"]}"` : ""}
                        ){
                          error
                          id
                          ok
                        }
                      }
                      `);
                    executeQuery.mutationName = "createStayBooking";
                    executeQuery.closeModal = true;
                    break;
                case "delete-pre-check-in":
                    setMutation(`mutation {
                            deleteStayBooking(id:${itemsAffected[0]})
                        {
                          error
                          id
                          ok
                        }
                      }`);
                    executeQuery.mutationName = "deleteStayBooking";
                    executeQuery.closeModal = true;
                    break;
                case "resend-pre-check-in":
                    setMutation(` mutation{
                        sendStayBookingReminder( id: ${itemsAffected[0]}){
                            error
                            ok
                          }
                        }`);
                    executeQuery.mutationName = "sendStayBookingReminder";
                    executeQuery.closeModal = true;
                    break;
                case "execute-checkin":
                    const verifyMutation = verifyGuest(
                        inputs[1].data.stayBookings.id,
                        inputs[1].data.stayBookings.numberOfRooms,
                        values
                    );
                    if (verifyMutation.ok) {
                        setMutation(`mutation {
                            checkInFromStayBooking(stayBookingID: ${itemsAffected[0]}, mainGuestID: ${
                            values[`modal-checkin-main-guest-select`]
                        }, rooms:[${mapRooms(inputs[1].data.stayBookings.numberOfRooms, values, []).rooms}]){
                              error
                              id
                              ok
                            }
                          }`);

                        executeQuery.mutationName = "checkInFromStayBooking";
                        executeQuery.closeModal = true;
                    } else {
                        setSecondaryMutation(`mutation {
                            ${verifyMutation.mutation}
                        }`);
                        execute = false;
                        executeQuery.closeModal = false;
                    }

                    break;
                case "export-guest-registration-form":
                    execute = false;
                    newQuery = true;
                    break;
                default:
                    mutation = null;
                    execute = false;
                    break;
            }
        }

        if (execute) {
            dispatch(showGlobalLoading(true));
            setTimeout(function () {
                executeMutation(
                    mutation,
                    executeAnotherQuery,
                    avoidToast,
                    noCleanAction,
                    mutationFile,
                    aditionalHeader
                );
            }, 100);
        }
        if (newQuery) {
            executeNewQuery();
            setDownloadFile(true);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (newQueryData && !newQueryLoading && downloadFile) {
            //logica para hacer csv
            const results = newQueryData?.getStayTravelerPartReport?.results || [];
            const zip = new JSZip();
            const file = results.map((val) => {
                const dayFields = val?.stayTravelersPart[0]?.stayGuestInformation.map((information, index) => {
                    const title =
                        information?.names?.length > 0
                            ? information.names.find((name) => name.lang == lang)?.name ||
                              information.names.find((name) => name.lang == (lang == "es" ? "en" : "es"))?.name
                            : t(information?.ref);
                    return title && title != "" ? title : `${t("without-name")}-${index}`;
                });
                const fields = [...dayFields];
                let data = val?.stayTravelersPart?.map((travelerPart) => {
                    let values = travelerPart?.stayGuestInformation?.reduce((acc, curr, index) => {
                        acc[fields[index]] = curr.value;
                        return acc;
                    }, {});
                    const result = { ...values };
                    return result;
                });

                return { name: val.date, results: data };
            });
            if (file?.length > 1) {
                for (let i = 0; i < file.length; i++) {
                    zip.file(`guest-report-${file[i].name}.csv`, `\uFEFF` + papaparse.unparse(file[i].results));
                }
                zip.generateAsync({ type: "blob" })
                    .then((content) => {
                        saveAs(content, "guest-reports.zip");
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (file?.length == 1) {
                const csv = papaparse.unparse(file[0].results);
                const blob = new Blob([`\uFEFF` + csv], { type: "text/csv;charset=utf-8;" });
                saveAs(blob, `guest-report-${file[0].name}.csv`);
            }
            dispatch(closeModal());
            dispatch(setModalContent(false));
            dispatch(showGlobalLoading(false));
            dispatch(setRefreshData(true));
            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            setDownloadFile(false);
            removeApolloCacheKeys(client.cache, "all");
        }
    }, [newQueryData]);

    useEffect(() => {
        if (data && executeQuery && data[executeQuery.mutationName] && data[executeQuery.mutationName].ok) {
            if (!executeQuery.params || (executeQuery.params && !executeQuery.params.noCleanAction)) {
                dispatch(cleanAction());
            }

            if (!executeQuery.preventToast) {
                toast.success(capitalizeFirst(t(executeQuery.customToast || "operation-successful")));
            }
            if (executeQuery.closeModal) {
                dispatch(closeModal());
                dispatch(setModalContent(false));
                dispatch(showGlobalLoading(false));
            }
            dispatch(setRefreshData(true));
            dispatch(setExecuteQuery(null));
            removeApolloCacheKeys(client.cache, "all");
        } else if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].errorMsg || data[executeQuery.action].error)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].errorMsg || data[executeQuery.mutationName].error)))
        ) {
            const errorMsg =
                data[executeQuery.action] && data[executeQuery.action].error
                    ? data[executeQuery.action].error.msg
                        ? data[executeQuery.action].error.msg
                        : data[executeQuery.action].error
                    : data[executeQuery.mutationName] && data[executeQuery.mutationName].error
                    ? data[executeQuery.mutationName].error
                    : null;

            toast.error(
                errorMsg && errorMsg.includes("already in use") ? t("code-already-in-use") : t("mutation-error")
            );
            dispatch(showGlobalLoading(false));
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateBooking);
