import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//Actions
import { filterPeriodicSchedules, setWizardStep } from "../../actions/sectionActions";

const UseWizardFilter = ({ stepData, index }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { periodicFilter } = useSelector((state) => state.sectionContent);

    //States
    const [filterStyle, setFilterStyle] = useState({
        text: "text-gray-600",
        number: "bg-gray-600",
    });
    const onMouseEnterHandler = () => {
        setFilterStyle({
            text: "text-blue-200",
            number: "bg-blue-200",
        });
    };

    //Actions
    const dispatch = useDispatch();

    const onMouseLeaveHandler = () => {
        if (isCurrentActive(periodicFilter, stepData.title)) {
            setFilterStyle({
                text: "text-blue-300",
                number: "bg-blue-300",
            });
        } else {
            setFilterStyle({
                text: "text-gray-600",
                number: "bg-gray-600",
            });
        }
    };

    const onClickHandler = (e) => {
        setFilterStyle({
            text: "text-blue-300",
            number: "bg-blue-300",
        });
        dispatch(filterPeriodicSchedules(e.currentTarget.dataset.filter));
        dispatch(setWizardStep(null));
    };

    //Listeners
    useEffect(() => {
        onMouseLeaveHandler();
        // eslint-disable-next-line
    }, [periodicFilter]);

    return (
        <div
            className={`relative cursor-pointer ${index === 0 ? "wizard-filter-adjust" : ""}`}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            onClick={onClickHandler}
            key={index}
            data-filter={stepData.title}
            data-stepnumber={index + 1}
        >
            <div className={`wizard-step-name ${filterStyle.text} first-capital`}>
                {`${langStrings["view"]} ${langStrings[stepData.title + "s"]}`}
            </div>
            <div className="block relative">
                <div className={`wizard-step-number ${filterStyle.number}`}>{index + 1}</div>
            </div>
        </div>
    );
};

export const isCurrentActive = (periodicFilter, title) => {
    //Returns true if current is the active schedule type
    let res = false;
    const festiveWanted = title === "closed-days" && periodicFilter === "festive-schedule";
    const filteredWanted = title === periodicFilter;

    if (festiveWanted || filteredWanted) {
        res = true;
    }
    return res;
};

export default UseWizardFilter;
