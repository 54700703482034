import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { setActionInput } from "../../actions/globalActions";

const UseInputText = ({
    inputData,
    validation,
    onChangeAction,
    onBlurAction,
    onFocusAction,
    ph,
    currentValue,
    id,
    disabled = false,
    adjust = "",
}) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);

    //Data
    const { name } = inputData;

    //Actions
    const dispatch = useDispatch();

    const changeHandler = (e) => {
        const dataToAction = { [e.target.name]: e.target.value };
        dispatch(setActionInput(dataToAction));

        if (typeof onChangeAction === "function") {
            onChangeAction(e.target.value);
        }
    };

    const blurHandler = (e) => {
        const dataToAction = { [e.target.name]: e.target.value };
        dispatch(setActionInput(dataToAction));

        if (typeof onBlurAction === "function") {
            onBlurAction(e.target.value);
        }
    };

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.target.blur();
            //Write you validation logic here
        }
    };

    //Listeners
    useEffect(() => {
        if (currentValue) {
            const dataToAction = { [name]: currentValue };
            dispatch(setActionInput(dataToAction));
        }
        // eslint-disable-next-line
    }, []);

    return (
        <input
            id={id}
            type="text"
            name={name}
            className={`w-full ${
                disabled ? "bg-gray-300 text-gray-700" : "bg-gray-200"
            } ${adjust} rounded py-1 px-4 leading-7 ${!validation ? "border border-red-100 " : ""}${
                inputData.name === "name" ? " w-full" : " long"
            }`}
            placeholder={ph ? ph : langStrings[name]}
            onChange={changeHandler}
            defaultValue={currentValue ? currentValue : ""}
            disabled={disabled}
            onBlur={blurHandler}
            onKeyDown={handleKeyPress}
            onFocus={onFocusAction}
        ></input>
    );
};

export default UseInputText;
