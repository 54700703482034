import React from "react";

import { Component, ListComponents } from "sections/playground";

import Button from "components/Button";
import ErrorInfo from "components/ErrorInfo";
import Icon from "components/Icon";
import Loading, { useLoading } from "components/Loading";
import { useAlert } from "components/Modal";
import OrderStatus from "components/OrderStatus";
import RoomChangeInfo from "components/RoomChangeInfo";
import ReactTooltip from "components/ReactTooltip";
import Warning from "components/Warning";

const Info = () => {
    const { setLoading } = useLoading();

    const { open: alert } = useAlert();
    return (
        <div className="w-full">
            <Warning
                id="warning-test-A"
                title="Update example"
                icon="alert-orange"
                design="blue"
                readMore={() => alert("Read more clicked")}
                collapsible={true}
            >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus nec nunc ultricies ultricies.
            </Warning>
            <Warning
                id="warning-test-B"
                title="Warning example"
                readMore={() => alert("Read more clicked")}
                collapsible={true}
                className="mt-5"
            >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                <br />
                Nullam nec purus nec nunc ultricies ultricies.
            </Warning>
            <Warning
                id={`warning-test-B${Math.random().toString(36).substring(7)}`}
                title="Info example"
                icon="alert"
                design="blue"
                closeable={true}
                className="mt-5"
            >
                This is an info message with a close button and a long text to show how it looks when the text is too
                long.
            </Warning>

            <ReactTooltip
                id="custom-playground-tooltip"
                delayShow={10}
                textColor="#ff0066"
                backgroundColor="#ffcccc"
                arrowColor="#ffcccc"
                borderColor="#ffaaaa"
                border={true}
            ></ReactTooltip>
            <ListComponents>
                <Icon type="info" size="xl" tooltip="This is a info tooltip" tooltipType="custom-playground-tooltip" />
                <Icon type="warning" size="3xl" tooltip="This is a warning tooltip" />
                <Icon type="error" size="xl" tooltip="This is the tooltip" />
                <Button id="room-icon-test" onClick={() => alert("Room icon clicked")}>
                    <Icon type="room" className="bg-green-100 text-green-200 hover:bg-green-500 text-4xl p-5 rounded" />
                </Button>

                <Component title="ReactTooltip">
                    <div data-tip="Lorem ipsum site amet" data-for="default-tooltip">
                        Default tooltip
                    </div>
                    <div data-tip="Lorem ipsum site amet" data-for="custom-playground-tooltip">
                        Custom tooltip
                    </div>
                </Component>

                <OrderStatus status="Ready" />
                <OrderStatus status="Pending" delayed={true} animate={true} />
                <OrderStatus status="Delivered" pmsStatus="PMS_CHARGED" />

                <RoomChangeInfo current="Presidential Suite" previous="Room 102" />

                <Component title="Loading">
                    <Loading />
                    <Loading zoom="50" overlay={true} />
                    <Loading zoom="25" />
                    <Button
                        id="show-loading"
                        design="blue-outline"
                        onClick={() => {
                            setLoading(true);
                            setTimeout(() => setLoading(false), 2000);
                        }}
                    >
                        Full screen loading
                    </Button>
                </Component>

                <ErrorInfo>Error example</ErrorInfo>
                <ErrorInfo useDefault={false}>This error is displayed to the user</ErrorInfo>
                <ErrorInfo useDefault={false} retry={() => alert("Retry action")}>
                    Error retry example
                </ErrorInfo>
            </ListComponents>
        </div>
    );
};

export default Info;
