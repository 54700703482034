import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//Components
import UseButton from "../../../../../useButton";
//Actions
import { openModal, setModalContent } from "../../../../../../actions/uiActions";
import { setExecuteQuery } from "../../../../../../actions/sectionActions";
import { changeGlobalAction, changeActionValues } from "../../../../../../actions/globalActions";

//Utils
import { Session } from "../../../../../../hooks/Utils/Session";
import BrowserMediaLibraryModal from "../../../../../../hooks/GraphqlCalls/MediaLibrary/modals/BrowserMediaLibraryModal";
import { WidgetContext } from "../../../../../../contexts/editor";
import { useTranslation } from "react-i18next";
import ReactHlsPlayer from "react-hls-player/dist";

const ChooseAsset = ({ disabled, isForModalActions, modalValues, destTypes, modalContentId = null, buttonId }) => {
    const { id: widgetID } = useContext(WidgetContext);

    //Params
    const { t } = useTranslation();
    const { lang } = useParams();

    const dispatch = useDispatch();
    //Store
    const { cScreenData, gridItems, cLang: designLang } = useSelector((state) => state.design);

    const assetLang = lang || designLang;
    //States
    const [previewSrc, setPreviewSrc] = useState(null);
    const [isVideo, setIsVideo] = useState(null);
    const [videoPosterUrl, setVideoPosterUrl] = useState(null);

    let cWidget = gridItems.filter((w) => w.i === widgetID)[0];
    const currentActions =
        cWidget && !isForModalActions
            ? cWidget.actions
            : isForModalActions === true && modalValues && modalValues.cValue
            ? modalValues.cValue
            : null;

    //Listeners
    useEffect(() => {
        getAssetUrl();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getAssetUrl();
        // eslint-disable-next-line
    }, [cScreenData, gridItems]);

    const getAssetUrl = () => {
        let url = null;
        if (currentActions) {
            // eslint-disable-next-line
            currentActions.map((a) => {
                if (a && a.type === "library" && a.value) {
                    if (a.value.includes("video-")) {
                        const fileRef = a.value;
                        url = Session.getDasUrl(`stream/${fileRef}-${assetLang}_hd.m3u8`);
                        setVideoPosterUrl(Session.getDasUrl(`thumb/${fileRef}-${assetLang}?w=633&h=336`));
                        setIsVideo(true);
                    } else {
                        setIsVideo(false);
                        url = Session.getDasUrl(a.value + "?lang=" + assetLang);
                    }
                }
            });
        }
        setPreviewSrc(url);
        return url;
    };

    const deleteAsset = () => {
        let newActions = currentActions || [];
        newActions = newActions.filter((v) => {
            if (!v || v.type === "library") {
                return false;
            }
            return true;
        });

        if (isForModalActions) {
            dispatch(
                changeActionValues({
                    "action-destination": {
                        type: "library",
                        value: null,
                    },
                })
            );
            setPreviewSrc(null);
            return;
        }

        dispatch(
            setExecuteQuery({
                action: "updateWidgetActions",
                params: {
                    widgetID: widgetID,
                    actions: { actions: newActions },
                },
            })
        );
    };

    const openModalLibrary = () => {
        if (disabled) return;
        const modal = BrowserMediaLibraryModal(t, {
            title: "choose-an-asset",
            text: "assets-of-the-media-library",
            bodyAdjust: "w-10/12",
            textAdjust: "",
            executeSectionQuery: true,
            buttonSelectedAssetName: "choose-an-asset",
            action: "widget-actions-media",
            dataMediaLibrary: {
                selected_ref: null,
                lang: assetLang,
                add_asset: {
                    active: true,
                    return_added: false,
                },
                search_input: true,
                type: destTypes ? destTypes.join(",") : null,
                modalContentId,
            },
        });
        setTimeout(() => {
            dispatch(
                changeGlobalAction({
                    actionName:
                        isForModalActions && modalValues && modalValues.globalAction
                            ? modalValues.globalAction
                            : "widget-actions-media",
                })
            );
        }, 500);
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    return (
        <>
            <div className="pb-6">
                <UseButton
                    id={buttonId}
                    buttonName={t("choose-an-asset")}
                    buttonColor={"btn-white"}
                    disabled={disabled}
                    action={(e) => {
                        e.stopPropagation();
                        openModalLibrary();
                    }}
                />
            </div>
            {previewSrc ? (
                <div className="relative bg-gray-200" style={{ width: "32rem" }}>
                    {isVideo ? (
                        <div div className="h-32 w-full object-contain mb-8">
                            {previewSrc.indexOf("youtube") > -1 ? (
                                <iframe
                                    id={`video_${widgetID}`}
                                    title="video"
                                    className="w-full h-full"
                                    src={previewSrc}
                                ></iframe>
                            ) : (
                                <ReactHlsPlayer
                                    id={`video_${widgetID}`}
                                    poster={videoPosterUrl}
                                    src={previewSrc}
                                    controls={true}
                                    autoPlay={false}
                                    loop={true}
                                    className={`h-full w-full`}
                                ></ReactHlsPlayer>
                            )}
                        </div>
                    ) : (
                        <img alt="" className="h-32 w-full object-contain mb-8" src={previewSrc}></img>
                    )}
                    <div
                        className="icon icon-delete-editor w-full  text-white text-base absolute bottom-0 p-1 right-0  text-center rounded cursor-pointer "
                        style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            return deleteAsset();
                        }}
                    ></div>
                </div>
            ) : null}
        </>
    );
};

export default ChooseAsset;
