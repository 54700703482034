import { escapeSpecialChars } from "../../../../Utils/Utils";

const RoomModalMutationDispatcher = (dataAction) => {
    let roomMutation = "";
    switch (dataAction.actionName) {
        case "new-room-group":
            roomMutation += `mutation{createRoomGroup(
                name: "${escapeSpecialChars(dataAction.values.name)}"
                rooms: [${
                    dataAction.values["rooms-of-group"]
                        ? dataAction.values["rooms-of-group"].map((idRoom) => idRoom)
                        : ""
                }]`;
            break;
        case "edit-room-group":
            roomMutation += `mutation{updateRoomGroup(
                name: "${escapeSpecialChars(dataAction.values.name)}"
                rooms: [${
                    dataAction.values["rooms-of-group"]
                        ? dataAction.values["rooms-of-group"].map((idRoom) => idRoom)
                        : ""
                }]
                id: ${dataAction.itemsAffected[0]}
                replace: true`;
            break;
        case "delete-room-group":
            roomMutation += `mutation{deleteRoomGroup(
                id: "${dataAction.itemsAffected[0]}"`;
            break;
        case "room-group-assign":
            roomMutation += `mutation{
                    updateRoomGroupsForRoom(room:${dataAction.itemsAffected}, roomGroups:[${
                dataAction.values && dataAction.values["room-groups"] && dataAction.values["room-groups"].length > 0
                    ? dataAction.values["room-groups"]
                    : ""
            }]
                `;
            break;
        case "batch-room-group-assign":
            roomMutation += `mutation{
                    addRoomsToRoomGroups(rooms:[${
                        dataAction.checkedItems && dataAction.checkedItems.length > 0 ? dataAction.checkedItems : ""
                    }], roomGroups:[${
                dataAction.values && dataAction.values["room-groups"] && dataAction.values["room-groups"].length > 0
                    ? dataAction.values["room-groups"]
                    : ""
            }]
                `;
            break;
        default:
            break;
    }
    return roomMutation;
};

export default RoomModalMutationDispatcher;
