import React, { useState } from "react";
import { GetCellContent } from "../../../hooks/Utils/GetCellContent";
import useLangStings from "../../../lang/useLangStrings";
import sanitizeHtml from "sanitize-html";
import { capitalizeFirst } from "../../../hooks/Utils/Utils";
import uuid from "react-uuid";

const UseIconWithTextCell = ({ cellData }) => {
    const { hidden, specialWidth, icon, image, adjust } = cellData || {};
    const content = GetCellContent(cellData);
    const tootltip = cellData?.icon?.tooltip;
    const tooltipText = useLangStings(tootltip ? tootltip : "");
    const [disableTooltip, setDisableTooltip] = useState(false);
    const showTooltip = (elem) => {
        if (elem.offsetWidth < elem.scrollWidth) {
            setDisableTooltip(false);
        } else {
            setDisableTooltip(true);
        }
    };
    const divId = uuid();
    return (
        <>
            {!hidden ? (
                <div className={`w-full flex items-center text-left ${specialWidth}`}>
                    {icon ? (
                        icon.name === "warning" ? (
                            <div className="inline-block text-xl mr-3">
                                <span className={`icon-${icon.name} ${icon.adjust}`}></span>
                                <span className={`icon icon-warning1 -ml-1em  text-orange-100`}></span>
                                <span className={`icon icon-warning2 -ml-1em  text-orange-100`}></span>
                            </div>
                        ) : tootltip ? (
                            <>
                                <div
                                    className={`inline-block mr-3 icon-${icon.name} ${icon.adjust}`}
                                    data-tip={sanitizeHtml(capitalizeFirst(tooltipText))}
                                    data-tip-disable={disableTooltip}
                                    onMouseOver={() => showTooltip(document.getElementById(divId))}
                                ></div>
                            </>
                        ) : (
                            <div className={`inline-block mr-3 icon-${icon.name} ${icon.adjust}`}></div>
                        )
                    ) : null}
                    {image ? (
                        <img
                            alt=""
                            className={`w-2/12 mr-4 ${image.adjust}`}
                            style={{ maxWidth: "40px" }}
                            src={`${image.url}`}
                        />
                    ) : null}
                    <div
                        id={divId}
                        className={`inline-block first-capital ${
                            icon && icon.name === "warning" ? "ml-4" : ""
                        } ${adjust}`}
                        data-tip={sanitizeHtml(capitalizeFirst(tooltipText))}
                        data-tip-disable={disableTooltip}
                        onMouseOver={(e) => showTooltip(e.currentTarget)}
                    >
                        {content}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default UseIconWithTextCell;
