import Nationalities from "../../lang/es/nationality.json";
import NationalitiesEN from "../../lang/en/nationality.json";
import { Session } from "hooks/Utils/Session";

export const GetNationalitiesOptions = () => {
    const lang = Session.getLang();

    let countries = [];

    for (let i in Nationalities) {
        if (lang === "es") {
            countries.push({ id: i.toUpperCase(), name: Nationalities[i] });
        } else if (lang === "en") {
            countries.push({
                id: i.toUpperCase(),
                name: NationalitiesEN[i.toLowerCase()],
            });
        }
    }

    return countries;
};

export const GetNationalityName = (key, lang) => {
    if (!key) {
        return null;
    }
    const currentLang = lang ? lang : Session.getLang();

    return currentLang === "es" ? Nationalities[key.toLowerCase()] : NationalitiesEN[key.toLowerCase()];
};
