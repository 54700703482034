import React, { useState } from "react";
import UseIcon from "../../useIcon";
import { useDispatch } from "react-redux";

import { openModal, setModalContent } from "../../../actions/uiActions";
import { changeGlobalAction, changeActionValues } from "../../../actions/globalActions";
import { setParametersSection } from "../../../actions/sectionActions";
import { createBrowserHistory } from "history";
import { GetCellContent } from "../../../hooks/Utils/GetCellContent";
import useLangStings from "../../../lang/useLangStrings";
import { Link } from "react-router-dom";
import { capitalizeFirst } from "../../../hooks/Utils/Utils";
import sanitizeHtml from "sanitize-html";
import Parser from "hooks/Utils/Parser";
import { checkShowTooltip } from "../../../hooks/Utils/Utils";
import { useLocationMapModal } from "components/LocationMap";

const UseEditableModalCell = ({ cellData, rowIndex, colIndex }) => {
    const {
        hidden,
        specialWidth,
        name,
        icon,
        modal,
        link,
        parametersSection,
        currentValue,
        fallBackLink,
        textTooltip,
        colorTextTooltip,
        alert,
        contentAdjust,
        onClickAction,
    } = cellData || {};
    const editIconStyle = " ml-2 text-2xl text-gray-800";
    const iconStyle = getIconStyle(currentValue, editIconStyle);
    const content = GetCellContent(cellData);
    const tooltipText = useLangStings(textTooltip ? textTooltip : "");
    const [showTooltip, setShowTooltip] = useState(true);

    const prefixId = "editable-modal-cell";
    //Actions
    const dispatch = useDispatch();
    const showModal = (e) => {
        e.stopPropagation();

        if (onClickAction && typeof onClickAction === "function") {
            onClickAction();
        } else if (fallBackLink) {
            const history = createBrowserHistory();
            history.push(fallBackLink);
            history.go(0);
        } else {
            dispatch(setModalContent(modal[0]));

            const initialInputData = () => {
                let selectedValues = {};
                // eslint-disable-next-line
                if (modal[0].inputs) {
                    // eslint-disable-next-line
                    modal[0].inputs.map((input) => {
                        selectedValues[input.name] = input.selected;
                    });
                }
                return selectedValues;
            };
            dispatch(
                changeGlobalAction({
                    actionName: e.currentTarget.dataset.action_name,
                    itemsAffected: [e.currentTarget.dataset.id],
                    values: initialInputData(),
                    modalSequence: modal,
                })
            );
            dispatch(openModal());
            //Guarrada pero no encuentro otra forma de hacerlo
            if (e.currentTarget.dataset.action_name === "edit-booking-allowed") {
                const selected = modal[0].inputs.filter((input) => input.name === "booking-duration");
                if (selected && selected[0] && selected[0].selected) {
                    setTimeout(function () {
                        dispatch(
                            changeActionValues({
                                // eslint-disable-next-line
                                ["booking-duration"]: selected[0].selected,
                            })
                        );
                    }, 200);
                }
            }
        }
    };

    const linkClickHandler = () => {
        if (parametersSection) dispatch(setParametersSection(parametersSection));
    };
    return (
        <div>
            {!hidden ? (
                <div className={` text-left flex items-center clickable`}>
                    {alert ? (
                        <div
                            id={`${prefixId}-alert-r${rowIndex}-c${colIndex}`}
                            className="mr-3"
                            data-tip={`${tooltipText ? sanitizeHtml(capitalizeFirst(tooltipText)) : ""}`}
                            data-for="default-tooltip"
                        >
                            <UseIcon icon={alert}></UseIcon>
                        </div>
                    ) : null}
                    {link ? (
                        <Link
                            id={`${prefixId}-link-r${rowIndex}-c${colIndex}`}
                            className={`${icon ? "w-auto max-w-80" : "w-full"} two-lines-ellipsis `}
                            onClick={linkClickHandler}
                            to={link}
                        >
                            <div
                                className={`contents text-blue-300 font-bold ${icon ? "w-9/12" : "w-full"}`}
                                data-tip={`${tooltipText ? sanitizeHtml(capitalizeFirst(tooltipText)) : ""}`}
                                data-for="default-tooltip"
                            >
                                {content}{" "}
                            </div>
                        </Link>
                    ) : (
                        <div
                            id={`${prefixId}-icon-r${rowIndex}-c${colIndex}`}
                            onClick={!icon || (fallBackLink !== undefined && !fallBackLink) ? showModal : null}
                            data-id={modal?.[0]?.id}
                            onMouseOver={(e) => {
                                checkShowTooltip(e.currentTarget, setShowTooltip);
                            }}
                            data-tip={showTooltip ? Parser(content) : null}
                            data-for="default-tooltip"
                            data-action_name={`${icon ? `${icon.name}-` : ""}${name}`}
                            className={`inline-block truncate first-capital ${contentAdjust} ${
                                fallBackLink !== undefined && !fallBackLink ? "cursor-pointer text-zafiro-400" : ""
                            }`}
                        >
                            {Parser(content)}{" "}
                        </div>
                    )}

                    {icon ? (
                        <div
                            id={`${prefixId}-icon-container-show-modal-r${rowIndex}-c${colIndex}`}
                            onClick={showModal}
                            data-id={modal?.[0]?.id}
                            data-action_name={`${icon ? `${icon.name}-` : ""}${name}`}
                            className=" inline-block px-3 text-2xl text-gray-800"
                        >
                            <i
                                id={`${prefixId}-icon-container-show-modal-r${rowIndex}-c${colIndex}`}
                                className={`inline-block ${icon ? ` icon icon-${icon.name}` : ""}`}
                                data-id={modal?.[0]?.id}
                                onClick={showModal}
                                data-action_name={`${icon ? `${icon.name}-` : ""}${name}`}
                            ></i>
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export const getIconStyle = (currentValue, editIconStyle) => {
    let res;
    if (!currentValue) {
        res = " t-cell-edit";
    } else {
        res = editIconStyle;
    }
    return res;
};

export default UseEditableModalCell;
