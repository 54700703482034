import React from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { capitalizeFirst } from "hooks/Utils/Utils";
import HTMLReactParser from "html-react-parser";

const ModalDeleteAssociatedPropertie = ({ close, action, name }) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={`${name} - ${t("delete-property")}`}
            footer={
                <>
                    <Button
                        design="blue-outline"
                        id={"close-button-modal"}
                        onClick={() => {
                            if (typeof close === "function") {
                                close();
                            }
                        }}
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        id={"delete-button-modal"}
                        onClick={() => {
                            if (typeof action === "function") {
                                action();
                                close();
                            }
                        }}
                        design="red"
                    >
                        {capitalizeFirst(t("delete"))}
                    </Button>
                </>
            }
            className="w-4/12 p-10"
        >
            <div className=" mb-4">
                {HTMLReactParser(t("are-you-sure-you-want-to-delete-app-from-project-mobile-app", { name: name }))}
            </div>
            <div className=" mb-10">
                {HTMLReactParser(
                    t("please-note-that-you-will-be-able-to-add-it-later", { name: t("manage-properties") })
                )}
            </div>
        </Modal>
    );
};

const useDeleteAssociatedPropertie = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalDeleteAssociatedPropertie {...newProps} />);
        },
    };
};

export default useDeleteAssociatedPropertie;
