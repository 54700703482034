import React, { Fragment, useRef } from "react";
import UseInputDateHour from "../useInputDateHour";
import { SessionDateToNew } from "../../date";
import UseSelectMultipleWithSearchOptionsQuery from "../useSelectMultipleWithSearchOptionsQuery";
import { ALARM_TYPE, getLastModifiedAlarm } from "../../hooks/Utils/Customers/AlarmUtils";
import UseSelect from "../Inputs/useSelect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Parser from "hooks/Utils/Parser";

const UseModalEditAlarm = ({ infoAlarms, filterRoom }) => {
    const { t } = useTranslation();

    const lastUpdateAlarm = useRef(getLastModifiedAlarm(infoAlarms));

    const { values } = useSelector((state) => state.action);
    const { channels } = useSelector((state) => state.sectionContent);
    const { actionName } = useSelector((state) => state.action);
    const { showAllGuestRooms } = values;

    const getRoomsToShow = () => {
        const rooms = getRoomsData();

        /// filter room by filterRoom
        let roomsToShow = Object.keys(rooms);
        if (filterRoom) {
            roomsToShow = Object.keys(rooms).filter((room) => room === filterRoom);
        }
        return roomsToShow;
    };
    const getRoomsData = () => {
        let rooms = JSON.parse(lastUpdateAlarm.current.rooms);
        if (showAllGuestRooms) {
            infoAlarms.forEach((alarm) => {
                let _auxRooms = JSON.parse(alarm.rooms);

                Object.keys(_auxRooms).forEach((_room) => {
                    rooms[_room] = _auxRooms[_room];
                });
            });
        }
        return rooms;
    };

    const printRoomTvs = () => {
        const rooms = getRoomsData();
        const allRoomsToShow = getRoomsToShow();

        return allRoomsToShow.map((roomName) => (
            <Fragment key={roomName}>
                <div className={`${filterRoom ? "" : "font-bold"} mb-1`}>
                    {filterRoom ? Parser(t("current-alarms-inroom", { roomName })) : roomName}
                </div>
                <div className="mt-2 p-4 rounded border border-gray-200 mb-4 break-words text-base text-gray-800">
                    {rooms[roomName].map((roomTv, index) => (
                        <Fragment key={roomTv.id}>
                            {index > 0 && <hr className="h-1 my-1" />}
                            <div className="flex my-2">
                                <span className=" my-auto icon-alarm text-blue-300 text-xl"></span>
                                <div className="ml-3 mr-auto font-bold">{`${t("SCREEN-TYPE-TV")} - ${
                                    roomTv.location
                                }`}</div>
                            </div>
                        </Fragment>
                    ))}
                </div>
            </Fragment>
        ));
    };

    if (!lastUpdateAlarm.current) {
        return <div>NO ALARM TO EDIT</div>;
    }

    return (
        <div className={`w-full grid gap-4 grid-cols-2 mt-4`}>
            <div>
                <div className="mb-2">
                    {filterRoom ? (
                        Parser(
                            t("update-room-alamr", {
                                roomName: `${filterRoom}`,
                            })
                        )
                    ) : (
                        <>
                            {showAllGuestRooms ? (
                                <div>
                                    {Parser(
                                        t("edit-all-alarms-text", {
                                            guestName: `${lastUpdateAlarm.current.stayguestName} ${lastUpdateAlarm.current.stayguestSurname}`,
                                            count: getRoomsToShow().length,
                                        })
                                    )}
                                </div>
                            ) : (
                                <>
                                    {actionName === "edit-alarm" ? (
                                        Parser(
                                            t("edit-alarm-text", {
                                                guestName: `${lastUpdateAlarm.current.stayguestName} ${lastUpdateAlarm.current.stayguestSurname}`,
                                            })
                                        )
                                    ) : (
                                        <>
                                            {t("set-room-alarms", {
                                                guest: `${lastUpdateAlarm.current.stayguestName} ${lastUpdateAlarm.current.stayguestSurname}`,
                                            })}
                                            <span className="text-blue-300 font-bold">
                                                {` (${t("num-room", { count: getRoomsToShow().length })})`}
                                            </span>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
                <UseInputDateHour
                    data={{
                        name: "",
                        date: new window.ZDate(lastUpdateAlarm.current.startTime).toLocaleDateString("en-Gb", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                        }),
                        hour: new window.ZDate(lastUpdateAlarm.current.startTime).toLocaleTimeString("es-ES", {
                            hour: "2-digit",
                            minute: "2-digit",
                        }),
                        minDate: SessionDateToNew(),
                        id: "modal-edit-alarm",
                    }}
                />
                <div className="mt-4"></div>
                <UseSelect
                    selectData={{
                        id: ALARM_TYPE.ID,
                        placeHolder: "select-alarm-type",
                        label: ["type", "*"],
                        data: [
                            { value: ALARM_TYPE.BEEP, text: t("Beep") },
                            { value: ALARM_TYPE.TVCHANNEL, text: t("tv-channel") },
                        ],
                        activeValue:
                            lastUpdateAlarm && lastUpdateAlarm.current && lastUpdateAlarm.current.type
                                ? lastUpdateAlarm.current.type
                                : ALARM_TYPE.BEEP,
                    }}
                />
                <div className="mt-4"></div>
                <div
                    className={`${
                        values && values[ALARM_TYPE.ID] && values[ALARM_TYPE.ID] === ALARM_TYPE.TVCHANNEL
                            ? ""
                            : "hidden"
                    }`}
                >
                    <UseSelectMultipleWithSearchOptionsQuery
                        dataSelect={{
                            name: ALARM_TYPE.TVCHANNEL,
                            text: ["tv-channel", "*"],
                            selectPlaceHolder: "select-channel",
                            optionData: channels
                                ? channels.map((channel) => ({
                                      ...channel,
                                      selected: channel.id === lastUpdateAlarm.current.tvChannelId ? true : false,
                                  }))
                                : [],
                            oneSelected: true,
                            id: ALARM_TYPE.TVCHANNEL.toLowerCase(),
                        }}
                    />
                </div>
            </div>
            <div>
                <div className="w-120 px-6 overflow-y-auto max-h-40">
                    {filterRoom ? null : <div className="mb-2 text-gray-900">{t("tvs")}:</div>}
                    <div>{printRoomTvs()}</div>
                </div>
            </div>
        </div>
    );
};

export default UseModalEditAlarm;
