import React from "react";
import { useSelector } from "react-redux";
const UseModalItemsResume = ({ index }) => {
    const { title, total, list } = useSelector((state) => state.ui.modalContent.inputs[index]);

    return (
        <>
            <div className="flex w-full">
                <div className="font-bold text-xl w-3/5">{title}</div>
                <div className="w-2/5 text-right">{total}</div>
            </div>
            <div className="border border-gray-200">
                <div className=" max-h-64 mt-2 mx-3 overflow-scroll">
                    {list?.map((item, index) => (
                        <div className={`${index < list?.length - 1 && "border-b border-gray-200"} p-3`}> {item}</div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default UseModalItemsResume;
