import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import useLangStings from "../../../lang/useLangStrings";
import sanitizeHtml from "sanitize-html";
import { capitalizeFirst } from "../../../hooks/Utils/Utils";
import { setParametersSection } from "../../../actions/sectionActions";
import ReactTooltip from "components/ReactTooltip";

const UseLinkCell = ({ cellData, rowIndex, colIndex }) => {
    const dispatch = useDispatch();
    //Store data
    const { langStrings, permissions } = useSelector((state) => state.ui);

    //Data
    const {
        link,
        name,
        value,
        subvalue,
        subvalueAlign,
        translate,
        hidden,
        icon,
        iconAdjust,
        iconTooltip,
        multipleLink,
        target,
        external,
        parametersSection,
        permissionToLink,
        adjust,
        textTooltip,
        preview,
        iconRight,
        linkAdjust,
        noFontBold = false,
    } = cellData || {};
    const content = translate ? langStrings[name] : value;
    const iconTooltipText = useLangStings(iconTooltip ? iconTooltip : "");
    const tooltipText = useLangStings(textTooltip ? textTooltip : value);
    const [disableTooltip, setDisableTooltip] = useState(false);
    const showTooltip = (e) => {
        if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) {
            setDisableTooltip(false);
        } else {
            setDisableTooltip(true);
        }
    };

    useEffect(() => {
        return () => {
            ReactTooltip.hide();
        };
    }, []);
    const prefixId = "link-cell";

    const handleClick = () => {
        if (parametersSection) dispatch(setParametersSection(parametersSection));
    };
    let linkEnabled =
        permissionToLink && permissions?.[permissionToLink.section]
            ? permissions[permissionToLink.section][permissionToLink.attribute]
            : true;
    return (
        <>
            {preview ? (
                <iframe
                    title="preview"
                    className={`mx-auto relative w-32 mr-1  float-left rounded center`}
                    srcDoc={preview}
                    width={1280}
                    height={720}
                    style={{
                        height: "9vh",
                        "-webkit-transform": "scale(0.85)",
                    }}
                ></iframe>
            ) : null}
            {!hidden ? (
                multipleLink ? (
                    multipleLink.map((item, index) => (
                        <div key={index}>
                            {index > 0 ? <span className="px-2 float-left">-</span> : null}
                            <Link to={linkEnabled ? item.link : "#"}>
                                <div
                                    className={`${linkEnabled ? "t-link" : ""} ${
                                        noFontBold ? "" : "font-bold"
                                    } first-capital float-left`}
                                >
                                    {translate ? langStrings[item.name] : item.name}
                                </div>
                            </Link>
                        </div>
                    ))
                ) : (
                    <Link
                        to={linkEnabled ? (external ? { pathname: link } : link) : "#"}
                        target={target ? target : ""}
                        onClick={handleClick}
                        className={`flex ${!subvalue ? "items-center" : "items-start"} space-x-1 ${linkAdjust || ""}`}
                        id={`${prefixId}-r${rowIndex}-c${colIndex}`}
                    >
                        {icon && !iconRight ? (
                            <div
                                className={`${
                                    linkEnabled ? "t-link" : ""
                                } two-lines-ellipsis icon-${icon} ${iconAdjust}`}
                                data-tip={sanitizeHtml(capitalizeFirst(iconTooltipText))}
                                data-for="default-tooltip"
                                id={`${prefixId}-r${rowIndex}-c${colIndex}-link-tooltip-cell`}
                            ></div>
                        ) : null}
                        <div>
                            <div
                                className={`${linkEnabled ? "t-link" : ""}
                            two-lines-ellipsis 
                            ${preview ? "flex items-center h-24" : ""}
                            ${noFontBold ? "" : "font-bold"} ${adjust}...`}
                                data-tip={`${tooltipText ? sanitizeHtml(capitalizeFirst(tooltipText)) : ""}`}
                                data-for="default-tooltip"
                                onMouseOver={(e) => showTooltip(e)}
                                data-tip-disable={disableTooltip}
                            >
                                {content}
                            </div>
                            {subvalue ? (
                                <div
                                    className={`${subvalueAlign ? `text-${subvalueAlign}` : "text-left"} ${
                                        content ? "text-gray-500" : "t-link font-bold"
                                    }`}
                                >
                                    {subvalue}
                                </div>
                            ) : null}
                        </div>
                        {icon && iconRight ? (
                            <div
                                className={`${linkEnabled ? "t-link" : ""}
                                    two-lines-ellipsis icon-${icon} ${iconAdjust}`}
                                data-tip={sanitizeHtml(capitalizeFirst(iconTooltipText))}
                                data-for="default-tooltip"
                            ></div>
                        ) : null}
                    </Link>
                )
            ) : null}
        </>
    );
};

export default UseLinkCell;
