import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RoomOrdersModal from "../../../../hooks/GraphqlCalls/Customers/modals/RoomOrdersModal";
import { openModal, setModalContent } from "../../../../actions/uiActions";
import { useDispatch, useSelector } from "react-redux";
import GetGuestOrders from "../../Customers/Guest/GuesOrders.js/GetGuestOrders";

import Icon from "components/Icon";
import Modal, { useModal } from "components/Modal";
import { capitalizeFirst } from "hooks/Utils/Utils";
import WatchedMoviesList from "sections/services/movies/WatchedMoviesList";
import Button from "components/Button";

const RoomDetailPurchases = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { open, close } = useModal();

    const [technicalProblems, setTechnicalProblems] = useState(false);
    const { room, guest, movies } = useSelector((state) => state.sectionContent);
    const [loading, setLoading] = useState(true);
    const [ordersCount, setOrdersCount] = useState(0);
    const { permissions } = useSelector((state) => state.ui);
    const hasMovieList = permissions?.services?.movieList;

    const guestID = guest?.guestID;
    const guestRoomID = guest && room ? guest?.rooms?.find((r) => r.RoomID === room?.id)?.stayGuestRoomID : null;

    const showRentedMovies = () => {
        open(
            <Modal
                id="room-rented-movies"
                title={
                    <h1 className="text-2xl font-bold">{`${capitalizeFirst(
                        t("show-rented-movies")
                    )} - ${capitalizeFirst(t("room"))} ${room?.number}`}</h1>
                }
                className=" p-10"
                minWidth="83vw"
                onKeyPress={{ Enter: close }}
            >
                <div className="py-2"></div>
                <WatchedMoviesList
                    chargesData={movies}
                    modalContent={true}
                    onClickDisplayTimes={close}
                    onCloseDisplayTimes={showRentedMovies}
                    displayTimesWidth="83vw"
                    showTimesIcon={true}
                    roomDetail={true}
                />

                <footer className="flex justify-end gap-4 mt-12">
                    <Button id="cancel" className="btn btn-blue" onClick={() => close()}>
                        {t("close")}
                    </Button>
                </footer>
            </Modal>
        );
    };

    return (
        <div className="bg-white rounded my-8 p-8">
            <GetGuestOrders
                guestID={guestID}
                guestRoomID={guestRoomID}
                setCount={setOrdersCount}
                setLoading={setLoading}
                onError={() => setTechnicalProblems(true)}
            />
            <div className="text-lg font-bold mb-4">{t("purchases")}</div>
            <hr className="border-t border-gray-300 mb-4" />

            <div className="space-y-4">
                <div className="flex items-center justify-start">
                    <i className={`icon icon-bag text-3xl ${loading ? "text-gray-700" : "text-blue-600"}`}></i>
                    <button
                        onClick={() => {
                            const modalContent = RoomOrdersModal({ room });
                            dispatch(setModalContent(modalContent));
                            dispatch(openModal());
                        }}
                        id="room-detail-show-orders"
                        disabled={loading}
                        className={`ml-4 ${loading ? "text-gray-700" : "text-blue-600"} focus:outline-none text-lg`}
                    >
                        {`${t("show-orders")} (${!isNaN(ordersCount) ? ordersCount : 0})`}
                    </button>
                    {technicalProblems && (
                        <Icon type="warning" size="3xl" tooltip={t("There are technical problems with Zafiro Sales")} />
                    )}
                </div>

                {hasMovieList && (
                    <div className="flex items-center justify-start">
                        <i className={`icon icon-Movies text-3xl ${loading ? "text-gray-700" : "text-blue-600"}`}></i>
                        <button
                            onClick={() => {
                                showRentedMovies();
                            }}
                            id="room-detail-show-movies"
                            disabled={loading}
                            className={`ml-4 ${loading ? "text-gray-700" : "text-blue-600"} focus:outline-none text-lg`}
                        >
                            {`${t("show-rented-movies")} (${movies?.length || 0})`}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RoomDetailPurchases;
