import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetFiltersDestinations, useListFilters, useScheduleSignage } from "../graphql/useSignages";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Icon from "components/Icon";
import Select from "components/Select";
import Loading from "components/Loading";

const FilterSelector = (props) => {
    const value = Object.keys(props.value)?.[0];

    return (
        <div className="w-full grid grid-cols-5 gap-2">
            <div className=" col-span-2">
                <Select
                    multiple={false}
                    options={props.filterTypes}
                    value={value}
                    onChange={(val) => {
                        props.setFilters((curr) => {
                            let response = curr;
                            response[props.index] = {};
                            response[props.index][val] = [];
                            return [...response];
                        });
                    }}
                    id={"select-type"}
                ></Select>
            </div>
            {value && (
                <>
                    <div className=" col-span-2">
                        <Select
                            multiple={true}
                            id={"select-type"}
                            options={props.listFilters[value].map((val) => ({ value: val.id, label: val.name }))}
                            onChange={(val) => {
                                props.setFilters((curr) => {
                                    let response = curr;
                                    response[props.index] = {};
                                    response[props.index][value] = val;
                                    return [...response];
                                });
                            }}
                            value={props.value[value]}
                        ></Select>
                    </div>
                    <Button
                        id={"delete-filter"}
                        onClick={() => {
                            props.setFilters((curr) => {
                                if (curr.length == 1) {
                                    return [{}];
                                } else {
                                    const response = [...curr];
                                    response.splice(props.index, 1);
                                    return response;
                                }
                            });
                        }}
                    >
                        <Icon size={"2xl"} type={"delete"}></Icon>
                    </Button>
                </>
            )}
        </div>
    );
};

const ModalAddFilters = (props) => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState([{}]);
    const [filterTypes, setFilterTypes] = useState([
        { value: "commonZones", label: t("common-zones"), disabled: false },
        { value: "groupCommonZones", label: t("group-common-zones"), disabled: false },
        { value: "tvLocations", label: t("tv-locations"), disabled: false },
    ]);

    const { getFilters, filters: listFilters, loading } = useListFilters();
    const { getFilterDestinations } = useGetFiltersDestinations();
    const { updateScheduleSignage } = useScheduleSignage();

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    const handleAddClick = () => {
        updateScheduleSignage({
            variables: {
                destination: {},
            },
        });
    };

    useEffect(() => {
        const usedFilters = filters.reduce((acc, curr) => {
            const values = Object.keys(curr);
            acc = [...acc, ...values];
            return acc;
        }, []);

        setFilterTypes((filters) => {
            const response = filters.map((filter) => {
                return { ...filter, disabled: usedFilters.includes(filter.value) };
            });
            return response;
        });
        const filterDestination = filters.reduce((acc, curr) => {
            acc = { ...acc, ...curr };
            return acc;
        }, {});

        getFilterDestinations({
            variables: {
                filters: { roomIDs: filterDestination.commonZones, roomGroupIDs: filterDestination.groupCommonZones },
            },
        });
    }, [filters]);

    useEffect(() => {
        getFilters();
    }, []);

    return (
        <Modal
            title={
                <div className="flex section-title mb-5 gap-2">
                    <p>{t("add-filters")}</p>
                    <Icon
                        className={"mt-2"}
                        size={"2xl"}
                        type={"info"}
                        tooltip={t("signage-only-will-be-displayed-in-commmon-zones")}
                    ></Icon>
                </div>
            }
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-add" onClick={handleCloseClick} disabled={loading}>
                        {t("add")}
                    </Button>
                </>
            }
            className={"w-10/12 p-10"}
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <div className=" flex">
                    <div className="flex flex-1 flex-col gap-2">
                        <p>{t("define-where-the-sequence-will-be-displayed")}:</p>
                        {filters.map((val, index) => {
                            return (
                                <FilterSelector
                                    key={`filter-n-${index}`}
                                    value={val}
                                    listFilters={listFilters}
                                    filterTypes={filterTypes}
                                    filters={props.filters}
                                    index={index}
                                    setFilters={setFilters}
                                ></FilterSelector>
                            );
                        })}

                        <Button
                            design={"link"}
                            id={"add-filter"}
                            disabled={
                                Object.keys(filters[filters.length - 1]).length == 0 ||
                                !filterTypes.find((val) => !val.disabled)
                            }
                            onClick={() => {
                                setFilters((val) => {
                                    const response = val;
                                    response.push({});
                                    return [...response];
                                });
                            }}
                        >
                            <Icon type="add"></Icon>
                            <span>{t("add-filter")}</span>
                        </Button>
                    </div>
                    <div className="flex flex-1 flex-col gap-2">
                        <p>{t("filter-results")}</p>
                        <div className="rounded-lg bg-gray-300 min-h-30 w-full"></div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export const UseModalAddFilters = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalAddFilters {...newProps} />);
        },
    };
};
