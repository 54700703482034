import Color from "zrender/color";

export const highlightColor = "#FF991F";
const coverBrightness = 35; // from 0 to 100

// Highlight an element
export const highlightElement = (type) => {
    const style = {
        outline: `2px solid ${highlightColor}`,
    };
    switch (type) {
        case "bottom":
        case "notop":
            style.outline = "none";
            style.boxShadow = `0 2px 0 0 ${highlightColor}`;
            break;
        case "top":
        case "nobottom":
            style.outline = "none";
            style.boxShadow = `0 -2px 0 0 ${highlightColor}`;
            break;
        case "vertical":
            style.outline = "none";
            style.boxShadow = `2px 0 0 2px ${highlightColor}`;
            break;
        case "radius":
            style.borderRadius = ".5em";
            break;
        default:
    }

    return style;
};

// Cover an element
export const coverElement = () => {
    return { filter: `grayscale(1) brightness(${coverBrightness}%)` };
};

// Cover a color
export const coverColor = (value) => {
    return Color(value)
        .saturate(-1)
        .luminance((100 - coverBrightness) / -100)
        .string();
};
