import { newRoute, newRedirect } from "routes/utils";

import Guests from "components/Section/Customers/Guest/Guests";
import GuestDetail from "components/Section/Customers/Guest/GuestDetail";
import GuestGroups from "components/Section/Customers/Guest/GuestGroups";
import { GuestConfig } from "components/Section/Customers/Guest/GuestConfig";
import Loyalty from "sections/customers/Loyalty";
import AddGuest from "components/Section/Customers/Guest/AddGuest";
import Messages from "components/Section/Customers/Message/Messages";
import NewMessage from "components/Section/Customers/Message/NewMessage";
import Alarms from "components/Section/Customers/Alarms";
import PasscodesConfiguration from "components/Section/Customers/PasscodesConfiguration";
import PasscodesPreview from "components/Section/Customers/PasscodesPreview";
import ReservationDetailsContainer from "components/Section/Customers/Precheckin/ReservationDetails/ReservationDetailsContainer";
import BookingInformationDetailsContainer from "components/Section/Customers/Guest/BookingInformationDetails";
import { PreCheckInInformation } from "components/Section/Customers/Guest/PreCheckInInformation";
import AutomationGuestReport from "components/Section/Customers/Guest/AutomationGuestReport";
import Settings from "hooks/GraphqlCalls/Customers/Satisfaction/Settings/Settings";

const sectionID = "customers";

const route = newRoute(sectionID);
const redirect = newRedirect(sectionID);

const checkGuests = ({ permissions }) => permissions?.guests;
const checkGuestList = ({ permissions }) => permissions?.guests?.guestList;
const checkGuestConfig = ({ permissions }) => permissions?.guests?.guestConfig;
const checkGuestGroups = ({ permissions }) => permissions?.guests?.guestGroups;
const checkLoyalty = ({ permissions }) => permissions?.guests?.loyaltyCustomers;
const checkMessages = ({ permissions }) => permissions?.guests?.messages;
const checkPreCheckIn = ({ permissions }) => permissions?.guests?.preCheckin;
const checkAlarms = ({ permissions }) => permissions?.guests?.alarms;
const checkSatifaction = ({ permissions }) => permissions?.guests?.guestSatisfaction;
const checkPasscodes = ({ permissions }) => permissions?.guests?.wifiPasscodes;

const routes = [
    route("/", { initial: true, redirect: redirect("/guests"), check: checkGuests }),

    // Guests
    route("/guests", { redirect: redirect("/guests/guest-list") }),
    // Guest list
    route("/guests/guest-list", { section: Guests, check: checkGuestList }),
    route("/guests/guest-list/check-in", { section: AddGuest, check: checkGuestList }),
    route("/guests/guest-list/guest/:id", { section: GuestDetail, check: checkGuestList }),
    route("/guests/guest-list/reservation-information/:id", {
        section: BookingInformationDetailsContainer,
        check: checkPreCheckIn,
    }),
    route("/guests/guest-list/reservation-details/:id", {
        section: ReservationDetailsContainer,
        check: checkPreCheckIn,
    }),
    // Guest groups
    route("/guests/guest-groups", { section: GuestGroups, check: checkGuestGroups }),
    // General settings
    route("/guests/general-settings", { section: GuestConfig, check: checkGuestConfig }),
    route("/guests/general-settings/guest-report", { section: AutomationGuestReport, check: checkGuestConfig }),
    route("/guests/general-settings/pre-check-in-information", {
        section: PreCheckInInformation,
        check: checkPreCheckIn,
    }),

    // Customers history
    route("/loyal-customers/*", { section: Loyalty, check: checkLoyalty }),

    // WiFi Passcodes
    route("/passcode-configuration", { section: PasscodesConfiguration, check: checkPasscodes }),
    route("/passcode-configuration/preview/:id", {
        section: PasscodesPreview,
        check: checkPasscodes,
        fullscreen: true,
    }),

    // Messages
    route("/messages", { redirect: redirect("/messages/list") }),
    route("/messages/list", { section: Messages, check: checkMessages }),
    route("/messages/new", { section: NewMessage, check: checkMessages }),
    route("/messages/:msgFolder/:id", { section: NewMessage, check: checkMessages }),

    // Alarms
    route("/alarms", { redirect: redirect("/alarms/list") }),
    route("/alarms/list", { section: Alarms, check: checkAlarms }),

    // Feedback settings
    route("/feedback-settings", { section: Settings, check: checkSatifaction }),
];

export default routes;
