import React, { useContext, useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useSendResetCode, useVerifyResetCode } from "hooks/Data/useUser";
import { toastValidations } from "hooks/Utils/Validations";
import Parser from "hooks/Utils/Parser";

import Button from "components/Button";
import TextInput from "components/TextInput";
import Modal, { useModal } from "components/Modal";

import { GlobalContext } from "contexts/Global";

import { usePasswordChange } from "sections/login/changepass";

// Countdown for resend code
const SEND_VERIFY_CODE_COUNTDOWN = 10; // seconds

export const usePasswordReminderModal = () => {
    const { t } = useTranslation();
    const reminderRef = useRef(null);

    const { open: openModal, close: closeModal } = useModal();
    const { open: openVerifyModal } = useCodeVerification();

    const submit = () => {
        if (reminderRef?.current) {
            reminderRef.current.submit();
        }
    };

    const onSuccess = (email) => {
        closeModal();
        openVerifyModal({ email });
    };

    return {
        open: (props) => {
            openModal(
                <Modal
                    id="password-reminder"
                    title={t("recover-password")}
                    footer={
                        <>
                            <Button design="blue-outline" id="modal-button-cancel" onClick={closeModal}>
                                {t("cancel")}
                            </Button>
                            <Button design="blue" id="modal-button-continue" onClick={submit}>
                                {t("continue")}
                            </Button>
                        </>
                    }
                >
                    <PasswordReminderModal ref={reminderRef} email={props?.email} onSuccess={onSuccess} />
                </Modal>
            );
        },
        close: closeModal,
    };
};

const useCodeVerification = () => {
    const { t } = useTranslation();

    const modalRef = useRef(null);
    const verifyRef = useRef(null);

    const { open: openModal, close: closeModal } = useModal();

    const { open: openPasswordChange } = usePasswordChange();

    const submit = () => {
        if (verifyRef?.current) {
            verifyRef.current.submit();
        }
    };

    const onSuccess = ({ email, code, hash }) => {
        closeModal();
        openPasswordChange({ email, code, hash });
    };

    return {
        open: ({ email }) => {
            openModal(
                <Modal
                    ref={modalRef}
                    id="password-verify"
                    title={t("enter-code")}
                    footer={
                        <>
                            <Button design="blue-outline" id="modal-button-cancel" onClick={closeModal}>
                                {t("cancel")}
                            </Button>
                            <Button design="blue" id="modal-button-continue" onClick={submit}>
                                {t("continue")}
                            </Button>
                        </>
                    }
                    minWidth="30rem"
                >
                    <CodeVerification
                        ref={verifyRef}
                        email={email}
                        onLoading={(loading) => {
                            if (modalRef.current) {
                                modalRef.current.setLoading(loading);
                            }
                        }}
                        onSuccess={onSuccess}
                    />
                </Modal>
            );
        },
        close: closeModal,
    };
};

const PasswordReminderModal = forwardRef(({ email, onSuccess }, ref) => {
    const { t } = useTranslation();

    const emailRef = useRef(null);

    const onEnterRecovery = (e) => {
        if (e?.key === "Enter") {
            e.preventDefault();
            recoveryPass();
        }
    };

    const recoveryPass = () => {
        const email = emailRef.current?.getValue();
        if (!toastValidations({ ref: emailRef, message: t("invalid email") })) {
            return;
        }
        if (onSuccess) {
            onSuccess(email);
        }
    };

    useImperativeHandle(ref, () => ({
        submit: recoveryPass,
    }));

    return (
        <div className="flex flex-col space-y-5">
            <div>{t("pass-reminder-text")}</div>
            <TextInput
                ref={emailRef}
                id="recovery-email-input"
                type="email"
                placeholder={t("email")}
                autoFocus={true}
                value={email}
                onKeyPress={onEnterRecovery}
            />
        </div>
    );
});

const CodeVerification = forwardRef(({ email, onLoading, onSuccess }, ref) => {
    const { lang } = useContext(GlobalContext);
    const { t } = useTranslation();

    const codeRef = useRef(null);
    const [countDown, setCountDown] = useState(0);
    const isSendCodeEnabled = countDown === 0;

    const {
        send: sendRecoveryCode,
        loading: sending,
        data: hash,
    } = useSendResetCode({
        lang,
        onError: (error) => {
            if (error) {
                console.error(error);
                toast.error(t("mutation-error"));
            }
        },
    });

    const { verify, loading, success } = useVerifyResetCode({
        onError: (error) => {
            if (error) {
                console.error(error);
                if (error?.message?.includes("is not the hash of the given password")) {
                    toast.error(t("Code is not valid"));
                } else {
                    toast.error(t("mutation-error"));
                }
                if (codeRef.current) {
                    codeRef.current.focus();
                }
            }
        },
    });

    const disabled = !hash || loading || sending;

    const sendCode = () => {
        setCountDown(SEND_VERIFY_CODE_COUNTDOWN);
        sendRecoveryCode({ email });
    };

    const verifyCode = () => {
        const code = codeRef.current?.getValue();
        if (!/^\d{6}$/.test(code)) {
            toast.error(t("Code is not valid"));
            if (codeRef.current) {
                codeRef.current.focus();
            }
            return;
        }
        verify({ email, hash, code: codeRef.current?.getValue() });
    };

    useImperativeHandle(ref, () => ({
        submit: verifyCode,
    }));

    useEffect(() => {
        sendCode();
    }, []);

    useEffect(() => {
        if (onLoading) {
            onLoading(loading || sending);
        }
    }, [loading, sending]);

    useEffect(() => {
        if (!isSendCodeEnabled) {
            const interval = setInterval(() => {
                setCountDown((prev) => {
                    return prev > 0 ? prev - 1 : 0;
                });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [isSendCodeEnabled]);

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess({ email, code: codeRef.current?.getValue(), hash });
        }
    }, [success]);

    return (
        <div className="flex flex-col space-y-5">
            <div>{Parser(t("recovery-mail-to", { email }))}</div>
            <TextInput
                ref={codeRef}
                id="verify-code-input"
                disabled={disabled}
                type="text"
                placeholder={t("code")}
                autoFocus={true}
                validate={/^\d{6}$/}
                onKeyPress={(e) => {
                    if (e?.key === "Enter") {
                        e.preventDefault();
                        verifyCode();
                    }
                }}
            />
            <Button
                id="resend-code-button"
                disabled={!isSendCodeEnabled}
                design="link"
                onClick={sendCode}
                className="text-blue-300"
            >
                {t("resend-code")}
                {!isSendCodeEnabled ? ` ${countDown}s` : null}
            </Button>
        </div>
    );
});
