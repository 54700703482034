import React from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table/index";
//Utils
import ListStaff from "../../../hooks/GraphqlCalls/CommonZones/ListStaff";
import UpdateStaff from "./UpdateStaff";

const Staff = () => {
    ListStaff();
    return (
        <>
            <UseSectionHeader
                title={"app-staff-users"}
                customBreadCrumbs={[
                    {
                        name: "general-settings",
                        translate: true,
                        route: `/general-settings`,
                    },
                    {
                        name: "user-management",
                        translate: true,
                        route: `/general-settings/user-management`,
                    },
                ]}
            />
            <UseTable />
            <UpdateStaff />
        </>
    );
};

export default Staff;
