import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//Utils
import Parser from "hooks/Utils/Parser";

import { changeActionValues } from "../../actions/globalActions";
import { useTranslation } from "react-i18next";

import { displayItem } from "./useSelectMultipleWithSearch";

const UseCheckOptions = ({
    index,
    dataOptions,
    setDataOptions,
    translateOptions,
    capitalizeOptions,
    categoryCheck = true,
    searching,
    groupsNames = null,
    orderOptions = false,
    onChangeValue,
}) => {
    let lastOptCategory;
    let isFirstGroup = true;
    const { t } = useTranslation();
    //Store
    const { name, oneSelected } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const RESULTS_INCREMENT = 10;
    const [resultsSize, setResultsSize] = useState(50);

    const orderDataOptions = () => {
        const dataOptionsModified = dataOptions.map((item, index) => {
            return { ...item, initialIndex: index };
        });

        if (orderOptions) {
            let newArrayData = [];
            let arrayGroupsNames = [];
            dataOptionsModified.forEach((element) => {
                if (!arrayGroupsNames.includes(element.category)) {
                    arrayGroupsNames.push(element.category);
                    newArrayData.push([]);
                }
                newArrayData[arrayGroupsNames.indexOf(element.category)].push(element);
            });
            return newArrayData.flat();
        } else {
            return dataOptionsModified;
        }
    };

    const [options, setOptions] = useState(orderDataOptions());

    useEffect(() => {
        setOptions(orderDataOptions());
        // eslint-disable-next-line
    }, [dataOptions]);

    //Actions
    const dispatch = useDispatch();

    const changeSelection = (e) => {
        const temCopy = options;
        temCopy.map((item) => {
            if (String(item.id) === String(e.currentTarget.dataset.id)) {
                item.selected = oneSelected && item.selected === true ? item.selected : !item.selected;
            } else if (oneSelected) {
                item.selected = false;
            }
        });

        let selectUnique = null;
        let unselectUnique = null;
        // eslint-disable-next-line
        temCopy.map((item) => {
            if (
                String(item.id) === String(e.currentTarget.dataset.id) &&
                item.selectUnique &&
                temCopy.filter((copyItem) => copyItem.category === item.category).length === 1
            ) {
                if (item.selected) {
                    selectUnique = item;
                } else {
                    unselectUnique = item;
                }
            }
        });
        if (selectUnique || unselectUnique) {
            // eslint-disable-next-line
            temCopy.map((item) => {
                if (unselectUnique) {
                    item.disabled = false;
                    item.selected = false;
                } else if (item !== selectUnique) {
                    item.disabled = true;
                    item.selected = false;
                }
            });
        }

        setDataOptions(temCopy);

        const selectedIds = temCopy.map((item) => (item.selected ? item.id : null));
        dispatch(
            changeActionValues({
                [name]: selectedIds.filter((item) => item !== null),
            })
        );
        if (onChangeValue != null && typeof onChangeValue === "function") {
            onChangeValue(selectedIds.filter((item) => item !== null));
        }
    };

    const selectCategory = (e, inputRef) => {
        const temCopy = options;
        let selectUnique = false;
        let unselectUnique = false;
        temCopy.map((item) => {
            if (String(item.category) === String(e.currentTarget.dataset.category) && !item.disabled) {
                item.selected = inputRef ? inputRef.checked : e.currentTarget.checked;
                if (item.selected && item.selectUnique) {
                    selectUnique = true;
                }
                if (!item.selected && item.selectUnique) {
                    unselectUnique = true;
                }
            }
        });
        if (selectUnique) {
            temCopy.map((item) => (!item.selectUnique ? ((item.selected = false), (item.disabled = true)) : null));
        }
        if (unselectUnique) {
            temCopy.map((item) => (!item.selectUnique ? ((item.selected = false), (item.disabled = false)) : null));
        }
        setDataOptions(temCopy);
        const selectedIds = temCopy.map((item) => (item.selected ? item.id : null));
        dispatch(
            changeActionValues({
                [name]: selectedIds.filter((item) => item !== null),
            })
        );
    };

    const addOptGroup = (option) => {
        let response = [];
        if (option.category) {
            const catOptions = options.filter((dataOption) => dataOption.category === option.category);
            const catDisabled = catOptions.length === catOptions.filter((catOpt) => catOpt.disabled).length;
            const catElementsEnabled = catOptions.filter((catOpt) => !catOpt.disabled);
            const catElementsEnabledChecked = catOptions.filter((catOpt) => !catOpt.disabled && catOpt.selected);

            //check if all options of the category are checked
            const catChecked = catElementsEnabled.length === catElementsEnabledChecked.length;

            if (option.category !== lastOptCategory) {
                response.push(
                    <div className={isFirstGroup ? "" : categoryCheck ? "border-t-2 border-gray-200 pt-4" : ""}>
                        <div className="block relative mb-4">
                            <p className="font-bold text-gray-900 first-capital mb-4">
                                {!oneSelected && categoryCheck ? (
                                    <input
                                        id={`group-${option.category}`}
                                        type="checkbox"
                                        data-category={option.category}
                                        className={`absolute top-0 left-0 w-5 h-5 checkbox cursor-pointer z-50 `}
                                        onChange={selectCategory}
                                        checked={catChecked}
                                        disabled={catDisabled}
                                    ></input>
                                ) : null}
                                <div
                                    className={getGroupClasses({ catDisabled, categoryCheck, oneSelected })}
                                    id={`group-${option.category}`}
                                    data-category={option.category}
                                    onClick={
                                        categoryCheck
                                            ? (e) => {
                                                  if (oneSelected) {
                                                      return;
                                                  }
                                                  e.currentTarget.previousElementSibling.checked =
                                                      !e.currentTarget.previousElementSibling.checked;
                                                  selectCategory(e, e.currentTarget.previousElementSibling);
                                              }
                                            : undefined
                                    }
                                >
                                    {getCategoryName({ option, translateOptions, groupsNames, t })}
                                </div>
                            </p>
                        </div>
                    </div>
                );
                isFirstGroup = false;
            }
            lastOptCategory = option.category;
        }
        return response;
    };

    const scroll = () => {
        const increment = resultsSize + RESULTS_INCREMENT;
        setResultsSize(increment < options.length ? increment : options.length);
    };
    return (
        <div
            className="bg-white rounded shadow-lg max-h-64 border border-gray-200 overflow-y-auto overflow-x-hidden p-2"
            onScroll={() => scroll()}
        >
            {options
                ? options
                      .filter((val) => val.visible)
                      .map((option, index) =>
                          option.visible && (index < resultsSize || searching) ? (
                              <Fragment key={option.id ? option.id : option.name}>
                                  {addOptGroup(option)}
                                  <div
                                      key={`div-option-${option.id ? option.id : option.name}`}
                                      className={`w-full  ${
                                          lastOptCategory && categoryCheck ? "ml-6 h-auto" : "ml-2 h-10"
                                      }`}
                                  >
                                      <div className="block relative">
                                          {option.image ? (
                                              <>
                                                  <img
                                                      src={require(`../../assets/images/${option.image}`)}
                                                      className={`absolute top-0 left-0 w-6 h-6 checkbox cursor-pointer z-50 ${option.image_adjust}`}
                                                      alt=""
                                                  ></img>
                                              </>
                                          ) : null}
                                          <div
                                              className={`pb-4 ${!oneSelected ? "ml-8" : "ml-2"} ${
                                                  !option.disabled
                                                      ? "hover:text-zafiro-400 cursor-pointer"
                                                      : " disabled"
                                              } ${capitalizeOptions ? "first-capital" : ""} ${option.adjust}`}
                                              data-id={option.id}
                                              id={`options-${index}`}
                                              onClick={option.disabled ? false : changeSelection}
                                          >
                                              {displayItem(option, translateOptions ? t : null)}
                                              {getOptionDescription({ option, translateOptions, t })}
                                          </div>
                                          {!oneSelected ? (
                                              <>
                                                  <input
                                                      id={`option-${option?.initialIndex}`}
                                                      type="checkbox"
                                                      data-id={option.id}
                                                      className={`absolute top-0 left-0 w-5 h-5 checkbox cursor-pointer z-50 `}
                                                      onChange={changeSelection}
                                                      checked={option.selected}
                                                      disabled={option.disabled}
                                                  ></input>
                                                  <span
                                                      id={`${option.id}-checkbox-background`}
                                                      className={`absolute top-0 left-0 w-5 h-5 bg-gray-200 hover:bg-gray-300 `}
                                                  ></span>
                                              </>
                                          ) : null}
                                      </div>
                                  </div>
                              </Fragment>
                          ) : null
                      )
                : null}
        </div>
    );
};

const getGroupClasses = (props) => {
    const { catDisabled, oneSelected, checkOptions, categoryCheck } = props;
    let response = categoryCheck ? "ml-8 " : "ml-2 ";
    if (catDisabled) {
        response += "disabled ";
    } else if (!oneSelected && categoryCheck) {
        response += "clickable ";
    }
    return response;
};

const getCategoryName = (props) => {
    const { option, translateOptions, groupsNames, t } = props;
    let response = "";
    if (translateOptions) {
        response = t(groupsNames ? groupsNames.find((group) => group.name === option.category).text : option.category);
    } else {
        response = option.category;
    }
    return response;
};

const getOptionDescription = (props) => {
    const { option, oneSelected, t } = props;
    if (t(`${option.id}Description`) !== `${option.id}Description`) {
        return (
            <span className={`${!oneSelected ? "clickable" : ""} text-gray-400 ml-1`}>
                - {t(`${option.id}Description`)}
            </span>
        );
    }

    return null;
};
export default UseCheckOptions;
