import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withApollo } from "@apollo/react-hoc";
import uuid from "react-uuid";
import { useParams } from "react-router-dom";
import { setExecuteQuery } from "../../../actions/sectionActions";
import { setModalContent, closeModal, showGlobalLoading } from "../../../actions/uiActions";
import { cleanActionValues } from "../../../actions/globalActions";
import Widget from "../../../components/Section/Design/Widgets/Widget";

const ACTION = {
    CREATE: "create-item-widget-item",
    EDIT: "edit-item-widget-item",
    DUPLICATE: "duplicate-item-widget-item",
    HIDE: "hide-visibility-item-widget-item",
    DELETE: "delete-item-widget-item",
    SORT: "sort-items-widget-item",
    check: (action) => {
        const actions = Object.values(ACTION);
        return actions.includes(action);
    },
};

const UpdateWidgetItem = () => {
    const dispatch = useDispatch();
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { itemSelected: widgetID, gridItems: allWidgets } = useSelector((state) => state.design);
    const actionData = useSelector((state) => state.action);
    const { lang: langParam } = useParams();

    useEffect(() => {
        const action = executeQuery?.action;
        if (!action) {
            console.warn("No action provided");
            return;
        }
        if (!ACTION.check(action)) {
            console.warn(`Invalid action ${action}`);
            return;
        }

        const widget = allWidgets?.find((item) => item.i === widgetID);
        if (!widget) {
            console.warn(`Widget not found for id ${widgetID}`);
            return;
        }

        const itemId = actionData?.values?.["save-item-id"];
        const setName = actionData?.values?.["widget-item-name"];
        const setText = actionData?.values?.["widget-item-text"];
        const setActions = actionData?.values?.["action-destination"];
        const setImage = actionData?.values?.["option-resource-selected"];
        const setImageUrl = actionData?.values?.["url-selected"];
        const setImageLib = actionData?.values?.["image-selected"];

        let updatedItems = [...(widget?.data?.items || [])];
        let newItem;

        switch (action) {
            case ACTION.CREATE:
            case ACTION.EDIT:
                newItem = {
                    ...updatedItems?.find((item) => item.id === itemId),
                    visibility: true,
                };
                newItem.name = setName || "";

                if (!newItem?.text) {
                    newItem.text = {};
                }
                delete newItem.text.id;
                delete newItem.text.text;
                newItem.text[langParam] = setText;

                if (setImage === "image-url") {
                    newItem.externalUrl = setImageUrl;
                    newItem.libraryRef = null;
                } else if (setImage === "image-library") {
                    newItem.externalUrl = null;
                    newItem.libraryRef = setImageLib;
                }
                newItem.actions = [setActions];

                if (action === ACTION.EDIT) {
                    updatedItems = updatedItems.map((item) => (item.id === itemId ? newItem : item));
                } else {
                    newItem.id = uuid();
                    newItem.position = updatedItems.length;
                    updatedItems.push(newItem);
                }
                break;
            case ACTION.DUPLICATE:
                updatedItems.push({
                    ...updatedItems.find((item) => item.id === executeQuery.params.itemId),
                    id: uuid(),
                });
                break;
            case ACTION.HIDE:
                updatedItems = updatedItems.map((item) => {
                    if (item.id === executeQuery.params.itemId) {
                        item.visibility = false;
                    }
                    return item;
                });
                break;
            case ACTION.DELETE:
                updatedItems = updatedItems.filter((item) => item.id !== actionData?.itemsAffected?.[0]);
                break;
            case ACTION.SORT:
                updatedItems = executeQuery.params.itemsSorted;
                break;
            default:
                break;
        }

        dispatch(
            setExecuteQuery(
                Widget.UpdateData({
                    widget,
                    property: "items",
                    value: updatedItems,
                })
            )
        );
        dispatch(cleanActionValues());
        dispatch(setModalContent(false));
        dispatch(closeModal());
    }, [executeQuery]);
    return null;
};

export default withApollo(UpdateWidgetItem);
