import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate as useHistory, useParams } from "react-router-dom";

//Components
import UseSectionHeader from "../../useSectionHeader";
import UseButton from "../../useButton";
import UseTable from "../../Table";
import NewAsset from "../../../hooks/GraphqlCalls/MediaLibrary/modals/NewAsset";

//Actions
import { setActionName } from "../../../actions/globalActions";
import { showRowForm } from "../../../actions/sectionActions";
import { openModal, setModalContent } from "../../../actions/uiActions";
import { changeGlobalAction } from "../../../actions/globalActions";
import { showGlobalLoading } from "../../../actions/uiActions";

//Utils
import ListMediaLibrary from "../../../hooks/GraphqlCalls/MediaLibrary/ListMediaLibrary";
import ListMediaLibraryBatchActions from "../../../hooks/GraphqlCalls/MediaLibrary/ListMediaLibraryBatchActions";
import UpdateMediaLibrary from "../../../hooks/GraphqlCalls/MediaLibrary/UpdateMediaLibrary";
import { toast } from "react-toastify";

import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { POLLINTERVAL_15S } from "../../../hooks/Utils/Utils";
import { TreeDataParser } from "./utils";

const GET_TREE = gql`
    {
        libraryTree(ref: "root") {
            response {
                name
                ref
                tree {
                    name
                    ref
                    tree {
                        name
                        ref
                        tree {
                            name
                            ref
                            tree {
                                name
                                ref
                            }
                        }
                    }
                }
            }
        }
    }
`;

const MediaLibrary = () => {
    const [loadTree, { data: treeData }] = useLazyQuery(GET_TREE, {
        pollInterval: POLLINTERVAL_15S,
    });

    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [currentTitle, setCurrentTitle] = useState("media");

    //Actions
    const dispatch = useDispatch();
    const history = useHistory();

    //State
    const [viewMode, setViewMode] = useState("table");
    const [viewModeRender, setViewModeRender] = useState("table");
    const { parentRef, sideBarRef } = useLibraryParams();

    if (parentRef === "asset") {
        history(`/design/library/${sideBarRef}`);
    }

    //Store
    const { langStrings } = useSelector((state) => state.ui);
    const { rowForm } = useSelector((state) => state.sectionContent);
    const { showGlobalLoading: showingGlobalLoading } = useSelector((state) => state.ui);

    ListMediaLibrary(viewMode);
    ListMediaLibraryBatchActions();

    //Functions
    const addFolder = (e) => {
        if (rowForm) {
            toast.error(langStrings["save-before-continue"]);
            return;
        }
        dispatch(showRowForm(true));
        dispatch(setActionName("add-media-library-folder"));
    };
    const addMedia = (e) => {
        dispatch(showRowForm(false));
        const modal = NewAsset(parentRef);
        dispatch(
            changeGlobalAction({
                actionName: "add-media-library-media",
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };
    const changeViewMode = (newViewMode) => {
        if (newViewMode !== viewMode) {
            setViewMode(newViewMode);
            dispatch(showGlobalLoading(true));
        }
    };

    useEffect(() => {
        loadTree();
    }, [loadTree]);

    useEffect(() => {
        if (viewMode !== viewModeRender && !showingGlobalLoading) {
            setViewModeRender(viewMode);
        }
        // eslint-disable-next-line
    }, [viewMode, showingGlobalLoading]);

    useEffect(() => {
        let isCustomCrumbs = false;
        let breadcrumbsData = [
            {
                name: "design",
                route: "/design",
                cookieRoute: null,
            },
            {
                name: "library",
                route: "/design/library/root/" + sideBarRef,
                cookieRoute: null,
            },
        ];
        if (treeData && treeData.libraryTree && treeData.libraryTree.response) {
            treeData.libraryTree.response.forEach((content) => {
                TreeDataParser(content, parentRef, sideBarRef, breadcrumbsData, () => (isCustomCrumbs = true));
            });
        }
        if (isCustomCrumbs) {
            setCurrentTitle(breadcrumbsData[breadcrumbsData.length - 1].name);
            breadcrumbsData.pop();
        }
        setBreadcrumbs(breadcrumbsData);
    }, [treeData, parentRef, sideBarRef]);

    return (
        <>
            <UpdateMediaLibrary viewMode={viewMode} />
            <UseSectionHeader
                title={[currentTitle]}
                customBreadCrumbs={breadcrumbs}
                noTranslate={currentTitle !== "media"}
                noCapitalize={currentTitle !== "media"}
            />
            <div>
                <div className="inline-flex export-btn z-20">
                    <div onClick={(e) => addFolder(e)}>
                        <UseButton
                            id={"media-library-add-folder-button"}
                            icon="add"
                            buttonName={"add-folder"}
                            buttonColor={"btn-white"}
                            adjust="w-auto px-4 min-w-24 mt-1 mr-4"
                        />
                    </div>
                    <div onClick={(e) => addMedia(e)}>
                        <UseButton
                            icon="add"
                            id={"media-library-add-media-button"}
                            buttonName={"add-media"}
                            buttonColor={"btn-blue"}
                            adjust="w-auto px-4 min-w-24 mt-1"
                        />
                    </div>
                </div>
                <div className="inline-flex export-btn z-20 mt-20 mr-2">
                    <div>
                        <UseButton
                            icon="list"
                            action={(e) => changeViewMode("table")}
                            buttonName={""}
                            buttonColor={`hover:text-blue-100 text-2xl ${viewMode === "table" ? "text-blue-300" : ""}`}
                            adjust="w-5 h-5 mt-2 cursor-pointer"
                        />
                    </div>
                    <div>
                        <UseButton
                            icon="areas-activities"
                            action={(e) => changeViewMode("map")}
                            buttonName={""}
                            buttonColor={`hover:text-blue-100 text-2xl ${viewMode === "map" ? "text-blue-300" : ""}`}
                            adjust="w-5 h-5 mt-2 cursor-pointer"
                        />
                    </div>
                </div>
                <UseTable useCollection={viewModeRender === "map"} />
            </div>
        </>
    );
};

export const useLibraryParams = () => {
    const { results } = useSelector((state) => state.table);
    const { values, actionName } = useSelector((state) => state.action);

    const EDITING = actionName === "edit-media-library-folder" || actionName === "edit-media-library-media";
    let parentRefForEditing = null;
    let relatedResult = null;

    if (EDITING) {
        relatedResult = results && results?.find((result) => result?.info[0].value === values.name);
    }
    const defaults = {
        root: "media",
        fonts: "design",
    };
    let { parentRef, sideBarRef, ref, fontRef } = useParams();
    if (!parentRef) {
        parentRef = Object.keys(defaults)[0];
    }
    if (!sideBarRef && defaults[parentRef]) {
        sideBarRef = defaults[parentRef];
    }
    if (relatedResult) {
        parentRefForEditing = relatedResult?.parentRef;
    }

    return {
        parentRef,
        sideBarRef,
        ref,
        fontRef,
        parentRefForEditing,
    };
};

export default MediaLibrary;
