const PortalsWifi = (t) => {
    const portalsWifi = [
        {
            id: "portalWifi",
            title: "portal-wifi",
            icon: "Wifi-access",
            adjust: "col-start-2 col-end-3",
            fields: [],
            redirect: true,
            redirectValue: "/hotel/property-settings/wifi/manager",
            redirectNameButton: "manage-wifi",
        },
    ];

    return portalsWifi;
};
export default PortalsWifi;
