export const NetworkSettings = [
    "wifi-networks-settings",
    "wifi-hotel",
    "wifi-hotel-description",
    "wifi-cast",
    "wifi-cast-description",
    "wifi-access",
    "wifi-access-description",
    "upload-mb",
    "download-mb",
    "number-users",
    "hotspots",
    "hotspots-description",
    "password",
    "wifi-name",
    "hidden",
    "authentication",
    "wifi",
    "chromecast-pairing",
    "chromecast-pairing-description",
    "chromecast-pairing-mode",
    "cec",
    "philips-chromecast",
    "permanent-socket",
    "chromecast-daily-reboot",
    "chromecast-daily-reboot-description",
    "automatic-device-restart",
    "reset-hour",
    "chromecast-report-status",
    "chromecast-report-status-description",
    "send-report-cc",
    "permanent-socket-tooltip",
    "portal-wifi",
    "portal-wifi-description",
    "manage-wifi",
    "manage-wifi-portal",
    "access-profile",
    "setup-portal-access",
    "manage-access-profile",
    "manage-exceptions-mac",
    "access-type",
    "manage-access-type",
    "redirection",
    "x-exceptions-macs",
    "x-access-types",
    "choose-redirecton-screen",
    "url",
    "pwa-name",
    "manage-access-profiles",
    "add-profile",
    "min-bandwidth",
    "max-bandwidth",
    "x mb/s",
    "room-surname",
    "open-access",
    "delete-access-profile-description",
    "access-profile-no-associated-access-type-warning",
    "mac-exceptions",
    "delete",
    "none",
    "link",
    "edit",
    "low-signal-warning",
    "low-signal-warning-description",
    "enable-low-signal-warning",
    "permanent-socket-text"
];
