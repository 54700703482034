import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withApollo } from "@apollo/react-hoc";
import { gql } from "apollo-boost";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/react-hooks";
import { setExecuteQuery } from "actions/sectionActions";
import { closeModal, showGlobalLoading } from "actions/uiActions";
import { useTranslation } from "react-i18next";
import { cleanSectionContent, setRefreshContentData } from "actions/sectionActions";

const UpdateMobileAppearance = () => {
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);
    const [mutation, setMutation] = useState("mutation{ fake{ error id ok}}");
    const [execute, setExecute] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const MUTATION_OBJECT = gql`
        ${mutation}
    `;
    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onCompleted: () => {
            dispatch(showGlobalLoading(false));
            dispatch(setRefreshContentData(true));
            setExecute(false);
            toast.success(t("operation-successful"));
        },
        onError(err) {
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(err.message);
        },
    });

    useEffect(() => {
        if (executeQuery && executeQuery.action === "update-mobile-appearance") {
            setExecute(true);
            setMutation(`mutation {
                assignThemeToMobileApp(themeID:${executeQuery.params.themeID},mobileAppID:${executeQuery.params.mobileAppID}){
                    ok
                    error
                    id
                }}`);
            dispatch(showGlobalLoading(true));
        }
    }, [executeQuery]);

    useEffect(() => {
        if (execute) {
            executeMutation();
        }
    }, [execute]);
    return null;
};

export default withApollo(UpdateMobileAppearance);
