import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Trans } from "react-i18next";

import { GetThemeDefault, vw2px } from "../../../../hooks/Utils/DesignUtils";
import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";

const WidgetRooms = () => {
    const { deviceType, editorSize, theme } = useContext(EditorContext);
    const { config, id: widgetID } = useContext(WidgetContext);

    const { gridItems } = useSelector((state) => state.design);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;

    const size = (s) => {
        return vw2px(s, editorSize ? editorSize.width : null);
    };

    //TODO rellenar estos colores a partir de la apariencia de la app
    const defaultBgColor = GetThemeDefault({ theme, id: "bgColor" });
    const defaultFgColor = GetThemeDefault({ theme, id: "fgColor" });
    const defaultFgActiveColor = GetThemeDefault({ theme, id: "fgActiveColor" });

    // Si en algun momento se quiere mostrar el preview de cuando hay habitación añadida
    // ésta sería la variable a cambiar
    const roomAdded = false;

    const style = config?.style || Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);
    const bgColor = style?.bgColor || defaultBgColor;
    const fgColor = style?.fgColor || defaultFgColor;
    const fgActiveColor = style?.fgActiveColor || style?.status?.active?.fgColor || defaultFgActiveColor;

    useEffect(() => {
        const widgetZone = document.querySelector(`#widget_zone_${widgetID}`);
        if (widgetZone) {
            widgetZone.style.backgroundColor = "transparent";
            widgetZone.style.borderColor = "";
            widgetZone.style.borderWidth = 0;
            widgetZone.style.borderRadius = 0;
        }
        // eslint-disable-next-line
    }, [gridItems]);

    return (
        <div
            className={`grid place-items-center items-center h-full px-4 text-center border-b border-gray-300`}
            style={{
                backgroundColor: bgColor,
                color: fgColor,
                fontSize: size(4) + "px",
            }}
        >
            <span className="inline-block">
                {roomAdded ? (
                    <>
                        <span className="font-bold" style={{ color: fgActiveColor }}>
                            Room 406
                        </span>
                        <i className="icon icon-chevron text-xs p-1"></i>
                        <i className="icon icon-row-actions text-2xl font-black absolute right-0 px-1"></i>
                    </>
                ) : (
                    <>
                        <Trans
                            i18nKey="widget preview add your room"
                            components={{ 1: <span className="font-bold" style={{ color: fgActiveColor }} /> }}
                        />
                    </>
                )}
            </span>
        </div>
    );
};

export default WidgetRooms;
