import React, { forwardRef } from "react";

const BlockTag = forwardRef(({ block, blockProps: { isFocused }, contentState }, ref) => {
    const data = contentState.getEntity(block.getEntityAt(0)).getData();
    return (
        <div
            ref={ref}
            style={{
                width: "100%",
                height: "auto",
                lineHeight: "1",
                boxShadow: isFocused ? "0 0 0 3px rgba(66, 153, 225, 0.5)" : "",
            }}
        >
            <p className=" text-center">{data && data.tag ? data.tag : ""}</p>
        </div>
    );
});

const UseCreateBlockTagPlugin = (config = {}) => {
    const component = config.decorator ? config.decorator(BlockTag) : BlockTag;
    return {
        blockRendererFn: (block, { getEditorState }) => {
            if (block.getType() === "atomic") {
                const contentState = getEditorState().getCurrentContent();
                const entity = contentState.getEntity(block.getEntityAt(0));
                const type = entity.getType();
                if (type === "BlockTag") {
                    return {
                        component,
                        editable: false,
                    };
                }
            }
            return null;
        },
    };
};

BlockTag.displayName = "BlockTag";
export default UseCreateBlockTagPlugin;
