import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const UseTagCheckbox = ({
    filter,
    setActiveFilters,
    activeSelection,
    setActiveSelection,
    activeFilters,
    active,
    translatable,
}) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { actionData } = useSelector((state) => state.action);

    //Data
    const { name } = filter;

    //States
    const [isActive, setIsActive] = useState(active);
    const [checkboxActive, setCheckboxActive] = useState({
        checked: false,
        visible: "hidden",
        background: "block",
        text: "",
    });

    //Actions
    const clickHandler = (e) => {
        const filterId = filter.id.toString();
        let tempArray = activeSelection;
        let tArray = [];

        if (checkboxActive.checked) {
            setIsActive(false);
            setActiveFilters(activeFilters.filter((af) => af !== filter.name));

            if (tempArray.includes(filterId)) {
                tArray = tempArray.filter((r) => r !== filterId);
            }
            tempArray = tArray;
            setActiveSelection(sortRol(tempArray));
        } else {
            setIsActive(true);
            setActiveFilters([...activeFilters, filter.name]);

            if (!tempArray.includes(filterId)) {
                tArray = [...tempArray, filterId];
            }
            tempArray = tArray;
            setActiveSelection(sortRol(tempArray));
        }
    };

    const sortRol = (rolsArray) => {
        rolsArray.sort(function (a, b) {
            if (a[0] > b[0]) {
                return 1;
            }
            if (a[0] < b[0]) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
        return rolsArray;
    };

    //Listeners
    useEffect(() => {
        if (isActive) {
            setCheckboxActive({
                checked: true,
                visible: "block",
                background: "hidden",
                text: "text-blue-300",
            });
        } else {
            setCheckboxActive({
                checked: false,
                visible: "hidden",
                background: "block",
                text: "",
            });
        }
    }, [isActive]);

    useEffect(() => {
        if (actionData && actionData.roles.includes(filter.id.toString())) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
        // eslint-disable-next-line
    }, [actionData]);

    return (
        <div className="w-full h-10">
            <div className="block relative mb-4 cursor-pointer">
                <div
                    className={`ml-8 hover:text-zafiro-400 first-capital ${checkboxActive.text}`}
                    onClick={clickHandler}
                >
                    {translatable ? langStrings[name] : name}
                </div>
                <input
                    id={`filter-${name}`}
                    type="checkbox"
                    className={`absolute top-0 left-0 w-5 h-5 checkbox cursor-pointer z-50 ${checkboxActive.visible}`}
                    onClick={clickHandler}
                    checked={checkboxActive.checked}
                    onChange={(e) => {}}
                ></input>
                <span
                    id={`${name}-checkbox-background`}
                    className={`absolute top-0 left-0 w-5 h-5 bg-gray-200 hover:bg-gray-300 ${checkboxActive.background}`}
                    onClick={clickHandler}
                ></span>
            </div>
        </div>
    );
};

export default UseTagCheckbox;
