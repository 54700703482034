import React from "react";

import WidgetZone from "./WidgetZone";
import { WIDGETS } from "../../../../hooks/Utils/Widgets";

const Widget = () => {
    //TODO
};

Widget.ImplementNewLibrary = (type) => {
    return WIDGETS[type] && WIDGETS[type].usingZRender;
};

Widget.Zone = (props) => <WidgetZone {...props} />;

// Extend global styles with device specific styles
Widget.ParseStyle = (style, deviceType) => {
    return Widget.ParseData(style, deviceType);
};

const spreadObject = (obj, newObject) => {
    let original = { ...obj };
    Object.keys(original).forEach((k) => {
        if (newObject[k] && typeof newObject[k] === "object") {
            spreadObject(original[k], newObject[k]);
        } else {
            newObject[k] = original[k];
        }
    });
};

// Extend global data with device specific data
Widget.ParseData = (data, deviceType) => {
    let newData = { ...data };
    if (newData && newData.devices && deviceType) {
        const deviceData = newData.devices.hasOwnProperty(deviceType) ? newData.devices[deviceType] : null;
        if (deviceData) {
            spreadObject(deviceData, newData);
        }
    }
    delete newData.devices;
    return newData;
};

Widget.UpdateStyle = ({ widget, property, value, deviceSpecific, extraArgs }) => {
    return Widget.UpdateData({
        widget,
        property,
        value,
        deviceSpecific,
        extraArgs,
        customAction: "updateWidgetStyle",
    });
};

// Ensure textids as strings
const ensureTextIdAsStr = (val) => {
    if (val && typeof val === "object" && !Array.isArray(val)) {
        if (Object.keys(val).length > 0) {
            Object.keys(val).forEach((key) => {
                const curval = val[key];
                if (curval && typeof curval === "object") {
                    if (curval.hasOwnProperty("id") && curval.hasOwnProperty("text")) {
                        val[key] = { id: "" + curval.id, text: curval.text };
                    } else {
                        val[key] = ensureTextIdAsStr(curval);
                    }
                }
            });
        }
    }
    return val;
};

Widget.SetPropValue = (current, prop, value, device) => {
    if (!current) current = {};
    const check = String(prop).replace(/^>+/gm, "");
    if (String(check) !== prop) {
        // eslint-disable-next-line
        throw `Invalid property param value: ${prop}`;
    }
    // Parse ids like: item.icon.fgColor
    const p = prop.split(".");
    if (p.length > 0) {
        switch (p.length) {
            case 6:
            case 5:
            case 4:
                alert("error: need to be implemented more than 3 levels");
                break;
            case 3:
                if (device) {
                    if (!current.devices) current.devices = {};
                    if (!current.devices[device]) current.devices[device] = {};
                    if (!current.devices[device][p[0]]) current.devices[device][p[0]] = {};
                    if (!current.devices[device][p[0]][p[1]]) current.devices[device][p[0]][p[1]] = {};
                    current.devices[device][p[0]][p[1]][p[2]] = value;
                }
                if (!current[p[0]]) current[p[0]] = {};
                if (!current[p[0]][p[1]]) current[p[0]][p[1]] = {};
                current[p[0]][p[1]][p[2]] = value;
                break;
            case 2:
                if (device) {
                    if (!current.devices) current.devices = {};
                    if (!current.devices[device]) current.devices[device] = {};
                    if (!current.devices[device][p[0]]) current.devices[device][p[0]] = {};
                    current.devices[device][p[0]][p[1]] = value;
                }
                if (!current[p[0]]) current[p[0]] = {};
                current[p[0]][p[1]] = value;
                break;
            default:
                if (device) {
                    if (!current.devices) current.devices = {};
                    if (!current.devices[device]) current.devices[device] = {};
                    current.devices[device][prop] = value;
                }
                current[prop] = value;
        }
    }
    return current;
};

Widget.UpdateData = ({ widget, property, value, deviceSpecific, extraArgs, customAction }) => {
    const action = customAction || "updateWidgetData";

    value = ensureTextIdAsStr(value);

    // Need to update full value
    if (!widget) {
        // eslint-disable-next-line
        throw "For device specific is need to know full widget data";
    }
    const currentValue = action === "updateWidgetData" ? widget.data : widget.widgetStyle;
    let newValue = { ...currentValue };

    if (deviceSpecific) {
        if (!newValue.devices) newValue.devices = {};
        if (!newValue.devices[deviceSpecific]) newValue.devices[deviceSpecific] = {};
    }

    if (property) {
        newValue = Widget.SetPropValue(newValue, property, value, deviceSpecific);
    } else {
        if (deviceSpecific) {
            newValue.devices[deviceSpecific] = value;
        }
        newValue = { devices: { ...newValue.devices }, ...value };
    }

    const query = {
        action,
        params: {
            widgetID: widget.i,
            value: newValue || {},
            ...(extraArgs || {}),
        },
    };

    return query;
};

export default Widget;
