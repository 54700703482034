import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { WidgetContext } from "../../../../contexts/editor";

const WidgetAlarm = ({ insideMenu, menuStatusStyle }) => {
    //State
    const [render, setRender] = useState(false);
    const { id: widgetID } = useContext(WidgetContext);
    const { widgetToolbarVisibleTab: tab, gridItems, itemSelected } = useSelector((state) => state.design);
    const [alarmConfigured, setAlarmConfigured] = useState(tab === "regular" ? false : true);

    let widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    if (widget) {
        widget.type = "ALARM";
        widget.insideMenu = insideMenu === true ? true : false;
    }

    useEffect(() => {
        if (widgetID === itemSelected) {
            setAlarmConfigured(tab === "regular" ? false : true);
        } else {
            setAlarmConfigured(false);
        }
        // eslint-disable-next-line
    }, [tab, itemSelected]);

    useEffect(() => {
        //Timeout to wait widget_zone exists
        setTimeout(function () {
            setRender(true);
        }, 200);
    }, []);

    return (
        <>
            {render ? (
                <div
                    dangerouslySetInnerHTML={{
                        width: "100%",
                        height: "100%",
                        __html: widget
                            ? window.Render.widget(widget, null, {
                                  alarm: { configured: alarmConfigured, date: null },
                                  insideMenu: insideMenu === true ? true : false,
                                  statusStyle: menuStatusStyle ? menuStatusStyle : null,
                              }).outerHTML
                            : null,
                    }}
                ></div>
            ) : null}
        </>
    );
};

export default WidgetAlarm;
