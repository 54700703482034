//Utils
import { capitalizeFirst } from "../../../Utils/Utils";

const BrowserMediaLibraryModal = (t, data) => {
    const {
        title,
        text,
        bodyAdjust,
        textAdjust,
        dataMediaLibrary,
        executeSectionQuery,
        buttonSelectedAssetName,
        action,
        closeAction,
        buttonAceptAction,
    } = data;
    return {
        id: `browse-media-library`,
        bodyAdjust: `${bodyAdjust ? bodyAdjust : "w-6/12"}`,
        title: `${title ? capitalizeFirst(t(title)) : ""}`,
        text: `${text ? capitalizeFirst(t(text)) : ""}`,
        textAdjust: `${textAdjust ? textAdjust : ""}`,
        executeSectionQuery: executeSectionQuery,
        action: action,
        inputs: [
            {
                name: "browse-media-library",
                type: "browseMediaLibrary",
                dataMediaLibrary: dataMediaLibrary,
                dataMediaLibraryBrowser: data,
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: closeAction ? closeAction : "close" },
            {
                name: buttonSelectedAssetName ? buttonSelectedAssetName : "choose-an-image",
                style: "blue",
                action: buttonAceptAction ? buttonAceptAction : "return-selected-asset",
            },
        ],
    };
};

export default BrowserMediaLibraryModal;
