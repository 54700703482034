import { gql } from "apollo-boost";

export const ROOMS_QUERY = gql`
    {
        data: occupiedRoomsInfo {
            name
            stayGuestsRoom {
                id
                stayGuest {
                    name
                    surname
                }
            }
        }
        guests: guests(filter: { checkedOut: false }, mainGuest: true, orderBy: { field: "name", criteria: "ASC" }) {
            results {
                id
                name
                surname
                title
                alarmRooms: rooms {
                    name
                    number
                }
            }
        }
    }
`;

export const NEW_ALARM_MUTATION = gql`
    mutation createAlarm(
        $stayGuestRoomId: Int64
        $stayGuestId: Int64
        $type: AlarmType!
        $startTime: DateTime!
        $tvChannelId: Int64
    ) {
        createAlarm(
            stayGuestRoomId: $stayGuestRoomId
            stayGuestId: $stayGuestId
            type: $type
            startTime: $startTime
            tvChannelId: $tvChannelId
        ) {
            error
            ok
        }
    }
`;

export const GET_ALARMS = gql`
    query Alarms($state: [AlarmStateType]) {
        alarms(size: 999, orderBy: { field: "id", criteria: "desc" }, filter: { state: $state }) {
            info {
                count
                page
                size
            }
            results {
                groupingID
                isFromGuest
                lastUpdate
                rooms
                startTime
                state
                stayguestName
                stayguestSurname
                tvChannelId
                type
            }
        }
        roomsData: alarms(filter: { state: $state }) {
            results {
                rooms
            }
        }
    }
`;

export const DELETE_ALARM = gql`
    mutation DeleteAlarm($id: Int64!) {
        deleteAlarm(groupingIds: [$id]) {
            error
            ok
        }
    }
`;

export const EDIT_ALARM = gql`
    mutation updateAlarm($groupingID: Int64!, $startTime: DateTime!, $type: AlarmType!, $tvChannelId: Int64) {
        updateAlarm(groupingIds: [$groupingID], startTime: $startTime, type: $type, tvChannelId: $tvChannelId) {
            error
            ok
        }
    }
`;
