import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    cleanTableStore,
    setCountPosition,
    setHeaders,
    setLoading,
    setTableResults,
    setCustomError,
    setCustomConfig,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetCustomersTableHeaders";
//Model
import DigitalKey from "./models/DigitalKey";
import { useTranslation } from "react-i18next";

const ListDigitalKeys = () => {
    const { t } = useTranslation();
    const { langStrings } = useSelector((state) => state.ui);
    const { data } = useSelector((state) => state.sectionContent);

    //Data
    const sectionName = "list-digital-keys";

    //Actions
    const dispatch = useDispatch();

    const composeTable = () => {
        dispatch(cleanTableStore());

        //Get data
        const dataItems = arrangeData(data ? data : [], t);
        const tableHeaders = GetTableHeaders(sectionName);
        //Add to store
        dispatch(setLoading(true));
        setTimeout(function () {
            dispatch(setHeaders(tableHeaders));
        }, 50);
        dispatch(setTableResults(dataItems));
        dispatch(setCountPosition("table-header"));
        dispatch(
            setCustomConfig({
                pagination: false,
                tableContainerAdjust: "min-h-0	",
            })
        );
        dispatch(showRowForm(false));
        dispatch(setCustomError("no digital keys available"));

        dispatch(setLoading(false));
    };

    //Listeners
    useEffect(() => {
        composeTable();

        return () => {
            dispatch(setCustomConfig(null));
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        composeTable();
        // eslint-disable-next-line
    }, [data, langStrings]);
};

const arrangeData = (data, t) => {
    const digitalKeysData = [];
    // eslint-disable-next-line
    digitalKeysData.push(
        DigitalKey(data.guestID, data.guestName, data.guestSurname, "main", data.rooms, data.guestLoyaltyRef, t)
    );
    data.companions.forEach((companion) => {
        digitalKeysData.push(
            DigitalKey(
                companion.id,
                companion.name,
                companion.surname,
                "companion",
                companion.rooms,
                companion.loyaltyRef,
                t
            )
        );
    });
    return digitalKeysData;
};

export default ListDigitalKeys;
