import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import { EditorContext } from "../../../../../contexts/editor";
import Widget from "../../../../Widget";
import { vw2px } from "../../../../../hooks/Utils/DesignUtils";
import TopBar from "./topbar";
import { coverElement, highlightElement, coverColor } from "./utils";
import { useSelector } from "react-redux";

const Shops = ({ device: forceDeviceType, width: forceWidth, templating }) => {
    const { t: tr } = useTranslation();
    const { lang, deviceType, editorSize } = useContext(EditorContext);
    const { ordersButton } = useSelector((state) => state.sectionContent);

    const t = (label, data) => {
        return tr(label, { lng: lang, ...(data || {}) });
    };

    const deviceProps = {
        device: forceDeviceType || deviceType,
        deviceWidth: forceWidth || editorSize?.width,
    };

    const size = (s) => vw2px(s, deviceProps.deviceWidth, 3);

    const css = templating?.css;

    const highlightActive = css?.highlight;
    const hlBackground = highlightActive === "general";

    const styles = {
        global: {
            fontFamily: templating?.textFont?.name,
            color: css?.fgColor,
            backgroundColor: highlightActive && !hlBackground ? coverColor(css?.bgColor) : css?.bgColor,
            textAlign: "left",
            width: "100%",
            minHeight: "100%",
            cursor: "default",
        },
    };

    const itemProps = {
        ...deviceProps,
        type: "SHOPITEM",
        config: {
            name: t("shop-name"),
            delivery: ["00:00 - 00:00"],
            cart: 2,
        },
        style: {
            fgColor: "rgb(255, 255, 255)",
            bgColor: "rgb(245, 246, 248)",
        },
        texts: {
            name: t("shop-name"),
            delivery: t("delivery-time"),
        },
    };

    const shopItem = (
        <div
            style={{
                marginTop: size(1),
                marginBottom: size(1),
                ...(highlightActive && hlBackground ? highlightElement("radius") : null),
            }}
        >
            <div
                style={{
                    ...(highlightActive ? coverElement() : null),
                }}
            >
                <Widget {...itemProps} />
            </div>
        </div>
    );

    return (
        <div style={styles.global}>
            <div
                style={{
                    position: "sticky",
                    zIndex: 10,
                    top: 0,
                    ...(highlightActive && hlBackground ? highlightElement("bottom") : null),
                }}
            >
                <TopBar
                    title={t("shops-word")}
                    config={{ ordersButton: ordersButton ? true : false }}
                    device={deviceType}
                    width={forceWidth}
                    templating={templating}
                />
            </div>
            <div style={{ position: "relative", padding: size(1.5) }}>{new Array(5).fill(shopItem)}</div>
        </div>
    );
};

export default Shops;
