import { UPDATE_PRE_CHECK_IN_TRAVELERS_REPORT_FIELDS } from "components/Section/Customers/Precheckin/ReservationDetails/utils";

export const GuestDataReport = (showTwoColumns, hasChildrenCustomFields, customFields, customFieldsChildren) => {
    return {
        title: "guest-report-data",
        text: ["the-following-fields-will-be-marked-as-mandatory"],
        executeSectionQuery: true,
        bodyAdjust: hasChildrenCustomFields && showTwoColumns ? "w-8/12" : "w-4/12",
        inputs: [
            { type: "guestDataReport" },
            { type: "data", customFields, hasChildrenCustomFields, customFieldsChildren, showTwoColumns },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "closeModal" },
            {
                name: "save",
                style: "blue",
                action: UPDATE_PRE_CHECK_IN_TRAVELERS_REPORT_FIELDS,
            },
        ],
    };
};
