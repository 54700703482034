import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

//Actions
import { setActionInput } from "../../actions/globalActions";

const UseInputNumber = ({
    inputData,
    validation,
    currentValue,
    maxNumber,
    valueHandler,
    placeholder,
    adjust,
    disabled,
    id,
}) => {
    //Data
    const { name } = inputData;
    const [maxCapacity, setMaxCapacity] = useState(1000);

    //Actions
    const dispatch = useDispatch();

    const blurHandler = (e) => {
        if (typeof valueHandler === "function") {
            valueHandler(e);
        }

        const dataToAction = { [e.target.name]: e.target.value };
        dispatch(setActionInput(dataToAction));
    };

    useEffect(() => {
        if (currentValue) {
            const dataToAction = { [inputData.name]: currentValue };
            dispatch(setActionInput(dataToAction));
        }
        if (maxNumber) {
            setMaxCapacity(maxNumber);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <input
            id={id ? id : null}
            type="number"
            name={name}
            disabled={disabled ? true : false}
            min="0"
            max={String(maxCapacity)}
            className={`t-filter-input cursor-auto
            ${disabled ? "text-gray-600" : "text-gray-900"} 
            ${adjust ? adjust : ""} p-2 
            ${!validation ? "border border-red-100 " : ""}${
                inputData.name === "people" ||
                inputData.name === "wifipmsaccessdownloadbw" ||
                inputData.name === "wifipmsaccessuploadbw" ||
                inputData.name === "wifipmsaccessmaxdevices" ||
                inputData.name === "passcode-limit-of-devices-hours" ||
                inputData.name === "passcode-limit-of-devices-days" ||
                inputData.name === "passcode-renovation-days" ||
                inputData.name === "passcode-renovation-hours" ||
                inputData.name === "shop-bgAlpha"
                    ? " w-full "
                    : " long "
            }${!validation ? "border border-red-100" : ""}`}
            placeholder={placeholder ? placeholder : "0"}
            onChange={blurHandler}
            defaultValue={currentValue ? currentValue : !placeholder ? "0" : null}
        ></input>
    );
};

export default UseInputNumber;
