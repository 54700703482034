import React from "react";
import { useSelector } from "react-redux";
//Components
import UseInputCheckboxSpecialSchedule from "./useInputCheckboxSpecialSchedule";

const UseInputWeekDaysSpecialSchedule = ({ validation, index }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);

    //Data
    //ISO 8601 standard Monday(1) Sunday (7)
    const weekLetters = [1, 2, 3, 4, 5, 6, 7];

    return (
        <span className="relative overflow-visible">
            {weekLetters.map((weekLetter) => (
                <UseInputCheckboxSpecialSchedule data={weekLetter} index={index} key={weekLetter} />
            ))}
            {!validation ? (
                <p className="absolute schedule-weekday-warning text-red-100">{langStrings["choose-weekday"]}</p>
            ) : null}
            <span
                className={`absolute w-full rounded h-10 schedule-weekday-warning ${
                    !validation ? "border border-red-100" : ""
                }`}
            ></span>
        </span>
    );
};

export default UseInputWeekDaysSpecialSchedule;
