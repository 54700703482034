import React from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMutation } from "react-apollo";
import { gql } from "apollo-boost";
import { useDispatch } from "react-redux";
import { setRefreshData } from "actions/tableActions";

const ModalDeleteAssignedUsers = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const DELETE_USER_MUTATION = gql`mutation {
        updateShop(
            id: ${props.shopId}
            users:[${props.usersAssigned.filter((val) => {
                return val != props.id;
            })}]
        ) {
            id
            ok
        }
        }`;

    const [executeMutation] = useMutation(DELETE_USER_MUTATION, {
        onCompleted() {
            dispatch(setRefreshData(true));
            toast.success(t("operation-successful"));
            props.close();
        },
        onError() {
            toast.error(t("mutation-error"));
        },
    });

    const handleSaveClick = () => {
        executeMutation();
    };

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={`${props?.name} - ${capitalizeFirst(t("delete"))}`}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="red" id="modal-button-delete" onClick={handleSaveClick}>
                        {capitalizeFirst(t("delete"))}
                    </Button>
                </>
            }
            className={"w-5/12 p-10"}
        >
            <div>
                <p>{capitalizeFirst(t("delete-user-confirm"))}</p>
            </div>
        </Modal>
    );
};

export const UseModalDeleteAssignedUsers = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalDeleteAssignedUsers {...newProps} />);
        },
    };
};
