import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// HOOKs
import IsLoyaltyEmail from "../../../../../hooks/GraphqlCalls/Customers/IsLoyaltyEmail";
import GetRoomsNames from "../../../../../hooks/GraphqlCalls/Hotel/Rooms/GetRoomsNames";
//Actions
import { changeActionValues } from "../../../../../actions/globalActions";

const NewDigitalKey = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isLoyalty, setisLoyalty] = useState(false);

    //hooks
    const loyaltyQuery = IsLoyaltyEmail();
    const roomsNames = GetRoomsNames();

    useEffect(() => {
        setisLoyalty(loyaltyQuery);
        dispatch(changeActionValues({ isLoyalty: loyaltyQuery }));
        // eslint-disable-next-line
    }, [loyaltyQuery]);

    const displayRoomName = (room) => {
        return (
            <div key={room.id} className="text-sm float-left z-20  bg-blue-400 text-white mr-4 p-1 px-3 mb-2 rounded">
                {room.name}
            </div>
        );
    };

    return (
        <div className="p-8 ">
            <div className="border-b border-gray-200 text-lg pb-2 flex justify-between">
                <div className="first-capital text-gray-900 font-bold">{t("digital-key")}</div>
            </div>
            {!isLoyalty ? (
                <div className="mt-4">
                    <div className="dc-icon text-2xl float-left">
                        <span className=" icon icon-warning1 -ml-1em text-white"></span>
                        <span className=" icon icon-warning text-orange-500"></span>
                        <span className=" icon icon-warning2 -ml-1em text-white"></span>
                    </div>
                    <div className="float-left pt-1 pl-8">{t("asociate-email-required")}</div>
                </div>
            ) : null}
            {isLoyalty && (
                <div>
                    <div className="font-normal text-sm text-gray-800 first-capital my-4">
                        {t("digital-key-text-access")}
                    </div>
                    <div className="first-capital text-sm font-bold text-gray-900 my-2">{t("room-access")}</div>
                    <div>
                        {roomsNames && roomsNames.length > 0 ? (
                            roomsNames.map((room) => displayRoomName(room))
                        ) : (
                            <span className="text-sm first-capital text-gray-700 font-normal">{t("select-room")}</span>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewDigitalKey;
