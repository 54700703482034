import React from "react";
//Components
import UseTable from "../../../Table/";
//Utils
import ListDigitalKeys from "../../../../hooks/GraphqlCalls/Customers/ListDigitalKeys";

const GuestDigitalKey = () => {
    ListDigitalKeys();

    return <UseTable />;
};

export default GuestDigitalKey;
