import { openModal, setModalContent } from "actions/uiActions";
import { capitalizeFirst } from "hooks/Utils/Utils";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate as useHistory } from "react-router-dom";

const UseTextWithActionCell = ({ cellData, rowIndex, colIndex }) => {
    const dispatch = useDispatch();
    const actions = {
        OPEN_MODAL: "open-modal",
    };
    const prefixId = "cell-with-action";

    const text = cellData?.text || { value: "", style: "" };
    const textAction = cellData?.textAction || { value: "", style: "", typeAction: actions.OPEN_MODAL, action: null };
    const history = useHistory();

    const actionClickHandler = () => {
        if (textAction?.typeAction === actions.OPEN_MODAL) {
            if (typeof textAction.modal === "function") {
                dispatch(setModalContent(textAction.modal()));
                dispatch(openModal());
            }
        }
    };

    return (
        <div>
            <div id={`${prefixId}-r${rowIndex}-c${colIndex}-text`} className={` mb-2 ${text.style}`}>
                {text.value}
            </div>
            <button
                id={`${prefixId}-r${rowIndex}-c${colIndex}-button`}
                onClick={() => {
                    actionClickHandler();
                }}
                className={textAction.style || " text-zafiro-600 font-bold"}
            >
                {capitalizeFirst(textAction.value)}
            </button>
        </div>
    );
};

export default UseTextWithActionCell;
