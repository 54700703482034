import React from "react";
import { useTranslation } from "react-i18next";

import { Session } from "hooks/Utils/Session";
import NotFound from "components/Section/NotFound";

const Support = () => {
    const { t } = useTranslation();

    const link = Session.getSessionProp("supportLink");
    if (!link) {
        return (
            <div className="bg-white p-10 overflow-auto" style={{ height: "100vh" }}>
                <NotFound title={t("Support link not found")} desc={t("error-mutation")} />
            </div>
        );
    }
    return <iframe title="Zafiro Manager 3.8.3" className="w-full h-full absolute" src={link} />;
};

export default Support;
