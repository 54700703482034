import { useQuery } from "react-apollo";
import { gql } from "apollo-boost";

const EMPTY_QUERY = gql`
    query EmptyQuery {
        __typename
    }
`;

const useGetInfoMultipleAssets = (refs) => {
    const validRefs = refs && refs.length > 0 ? refs : [];

    const skipQuery = validRefs.length === 0;

    const queryVariables = validRefs.map((_, index) => `$ref${index}: String!`).join(", ");

    const queryFields = validRefs
        .map(
            (_, index) => ` 
      asset${index}: libraryAsset(ref: $ref${index}) {
        response {
          name
          ref
        }
      }
    `
        )
        .join("\n");

    const GET_MULTIPLE_ASSETS = !skipQuery
        ? gql`
        query GetMultipleAssets(${queryVariables}) {
          ${queryFields}
        }
      `
        : EMPTY_QUERY;

    const variables = !skipQuery
        ? validRefs.reduce((acc, ref, index) => {
              acc[`ref${index}`] = ref;
              return acc;
          }, {})
        : {};

    const { data, loading, error } = useQuery(GET_MULTIPLE_ASSETS, {
        variables,
        skip: skipQuery,
    });

    const transformData = (data) => {
        if (!data) return {};
        return Object.keys(data).reduce((acc, key) => {
            const asset = data[key]?.response;
            if (asset?.ref) {
                acc[asset.ref] = {
                    name: asset.name,
                    ref: asset.ref,
                    __typename: asset.__typename,
                };
            }
            return acc;
        }, {});
    };

    const processedData = !skipQuery ? transformData(data) : {};

    return { data: processedData, loading, error };
};

export default useGetInfoMultipleAssets;
