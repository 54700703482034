import Button from "components/Button";
import Checkbox from "components/Inputs/Checkbox";
import Modal, { useModal } from "components/Modal";
import { AccessTypesContext } from "contexts/AccessTypes";
import { useUpdateAccessProfiles } from "hooks/GraphqlCalls/Hotel/Monitoring/useUpdateAccessType";
import React, { useContext, useEffect, useState, createRef } from "react";
import { useTranslation } from "react-i18next";

export const AccessTypesOptions = ({ wifiAccessTypes, accessProfile, refetch, setLoadingRefresh, checkboxStates }) => {
    const storedIDs =
        wifiAccessTypes && accessProfile?.accesstypes
            ? accessProfile.accesstypes.map((accessType) => accessType.id)
            : [];
    const storedHash = storedIDs.join(",");

    const [newIDs, setNewIDs] = useState(storedIDs);
    const [refresh, setRefresh] = useState(false);
    const newIDsHash = newIDs.join(",");

    const { creating, editing } = useContext(AccessTypesContext);
    const { updateWifiAccess } = useUpdateAccessProfiles();
    const { open } = useModal();

    const hasChanges = storedIDs.length !== newIDs.length || storedIDs.some((id) => !newIDs.includes(id));

    useEffect(() => {
        setNewIDs(storedIDs);
    }, [storedHash]);

    useEffect(() => {
        if (hasChanges) {
            updateWifiAccess(accessProfile.id, storedIDs, newIDs);
            if (refresh) {
                setLoadingRefresh(true);
                refetch();
                setRefresh(false);
            }
        }
    }, [newIDsHash]);

    return (
        <>
            {wifiAccessTypes.map((wifiAccessType) => {
                const checkRef = createRef();
                const id = `${accessProfile?.name}-access-type-${wifiAccessType.value}`;
                const value = wifiAccessType?.ids?.[0];
                const isChecked = newIDs?.includes(value);
                return (
                    <Checkbox
                        ref={checkRef}
                        id={id}
                        key={id}
                        value={value}
                        label={wifiAccessType.name}
                        checked={isChecked}
                        disabled={creating || editing?.editing}
                        onChange={({ checked, value }) => {
                            const getIfAccessTypeIsNotUsed = (value) => checkboxStates.includes(value);
                            const isTypeUsed = getIfAccessTypeIsNotUsed(wifiAccessType.value);
                            if (!checked) {
                                setNewIDs(newIDs.filter((id) => id !== value) || []);
                            } else if (!isTypeUsed) {
                                setNewIDs([...newIDs, value]);
                            } else {
                                if (checkRef.current) {
                                    checkRef.current.uncheck();
                                }
                                open(
                                    <ChangeAccessTypeModal
                                        accessProfile={accessProfile}
                                        wifiAccessType={wifiAccessType}
                                        onConfirm={() => {
                                            if (checkRef.current) {
                                                checkRef.current.check();
                                            }
                                            setNewIDs([...newIDs, value]);
                                            setRefresh(true);
                                        }}
                                    />
                                );
                            }
                        }}
                    />
                );
            })}
        </>
    );
};

const ChangeAccessTypeModal = ({ accessProfile, wifiAccessType, onConfirm }) => {
    const { t } = useTranslation();
    const { close } = useModal();

    return (
        <Modal
            title={`${accessProfile?.name} - ${t("edit-access-type")}`}
            onClose={close}
            className="w-2/7 p-10"
            footer={
                <footer className="flex w-full gap-4 mt-10 justify-end">
                    <Button id="cancel" className="btn-white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        id="alert-accept"
                        design="blue"
                        onClick={() => {
                            close();
                            if (onConfirm) {
                                onConfirm();
                            }
                        }}
                    >
                        {t("accept")}
                    </Button>
                </footer>
            }
        >
            <p
                className="mt-4"
                dangerouslySetInnerHTML={{
                    __html: t("access-type-already-used", {
                        accessTypes: wifiAccessType?.name,
                    }),
                }}
            ></p>
            <p className="my-6">{t("access-type-already-used-confirm")}</p>
            <p
                dangerouslySetInnerHTML={{
                    __html: t("access-type-already-used-description", {
                        accessTypes: wifiAccessType?.name,
                        accessProfile: accessProfile?.name,
                    }),
                }}
            ></p>
        </Modal>
    );
};
