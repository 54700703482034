import React, { useState } from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
import GetTVChannels from "../../../hooks/GraphqlCalls/Customers/GetTVChannels";
import { useListAlarms } from "hooks/GraphqlCalls/Customers/useListAlarms";
import Table, { stringValue } from "components/ZafiroTable";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "hooks/Utils/Utils";
import Loading from "components/Loading";
import Button from "components/Button";
import Modal, { useModal } from "components/Modal";
import { NewAlarm } from "./NewAlarm";

const Alarms = () => {
    const [newOptions, setNewOptions] = useState([
        {
            id: "active",
            label: "active",
            value: "active",
            active: true,
        },
        {
            id: "expired",
            label: "expired",
            value: "expired",
            active: false,
        },
    ]);
    const { t } = useTranslation();
    const { open, close } = useModal();
    const { alarmList, ready, roomFilter, execute, refetch, setLoadingRefresh, loadingRefresh } = useListAlarms();
    GetTVChannels();

    const handleClicked = (id) => {
        const updatedOptions = newOptions.map((option) => {
            if (option.id === id) {
                if (id === "expired") {
                    execute("EXPIRED");
                } else if (id === "active") {
                    execute("PENDING");
                }
                return { ...option, active: true };
            } else {
                return { ...option, active: false };
            }
        });
        setNewOptions(updatedOptions);
    };

    return (
        <>
            <UseSectionHeader title={["list-alarms"]} />
            <main className="main-container">
                {ready && !loadingRefresh ? (
                    <Table
                        id="alarms"
                        paginate={true}
                        search={true}
                        showCount={true}
                        rows={alarmList}
                        folders={
                            <div className="flex items-center">
                                {newOptions?.map((folder, index) => (
                                    <Button
                                        id={`${folder.id}-button`}
                                        key={index}
                                        onClick={() => handleClicked(folder.id)}
                                        className={`border-b-4 ${
                                            folder?.active
                                                ? "text-blue-800 border-blue-700 font-black"
                                                : "border-gray-400 text-gray-800"
                                        } px-6 py-2 text-md w-40 justify-center font-bold hover:text-zafiro-600`}
                                    >
                                        {capitalizeFirst(t(folder?.label))}
                                    </Button>
                                ))}
                            </div>
                        }
                        topRightCorner={
                            <Button
                                id="new-alarm"
                                className="btn btn-blue"
                                onClick={() =>
                                    open(
                                        <Modal
                                            id="new-alarm"
                                            title={capitalizeFirst(t("new-alarm"))}
                                            className="w-1/3 px-12 py-10 overflow-hidden"
                                            onKeyPress={{ Enter: close }}
                                        >
                                            <NewAlarm refetch={refetch} setLoadingRefresh={setLoadingRefresh} />
                                        </Modal>
                                    )
                                }
                            >
                                {capitalizeFirst(t("new-alarm"))}
                            </Button>
                        }
                        filters={[
                            {
                                id: "room",
                                title: t("room"),
                                multiple: true,
                                options: roomFilter,
                                onFilter: (values, row) => (values?.includes(stringValue(row?.rooms)) ? row : null),
                            },
                            {
                                id: "edited-by",
                                title: t("edited-by"),
                                options: [
                                    {
                                        id: "by-guest",
                                        label: t("by-guest"),
                                        value: "by-guest",
                                    },
                                    {
                                        id: "by-staff",
                                        label: t("manager-users"),
                                        value: "by-staff",
                                    },
                                ],
                                onFilter: (values, row) => (values?.includes(stringValue(row?.edited)) ? row : null),
                            },
                        ]}
                        header={{
                            guest: { title: capitalizeFirst(t("guest")), sortable: true },
                            rooms: { title: capitalizeFirst(t("rooms")) },
                            hour: { title: capitalizeFirst(t("hour")) },
                            date: { title: capitalizeFirst(t("date")), sortable: true },
                            "last-update": { title: t("last-update"), sortable: true },
                            edited: { title: "" },
                        }}
                        cols={["guest", "rooms", "hour", "date", "last-update", "edited"]}
                    />
                ) : (
                    <Loading adjust="section-loading" style={{ height: "50vh" }} />
                )}
            </main>
        </>
    );
};

export default Alarms;
