import { ContentState, convertToRaw, EditorState } from "draft-js";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import UseButton from "../../../../useButton";
import { useTranslation } from "react-i18next";
import { getStylesToApply } from "./TypographySidebar";
import { useSelector } from "react-redux";
import { getFontSizes } from "../../../../../hooks/Utils/Design/FontUtils";
import CustomFontSizes from "../../Widgets/WidgetToolbars/OptionsDetails/CustomFontSizes";

function TypographyPane({ type, cancelHandle, saveHandle }) {
    const { t } = useTranslation();

    const { fonts } = useSelector((state) => state.ui);

    const [fontFamily, setFontFamily] = useState(type.style.fontName);
    const [fontLink, setFontLink] = useState(type.style.fontLink);
    const [fontSize, setFontSize] = useState(type.style.size);
    const [italic, setItalic] = useState(type.style.italic);
    const [bold, setBold] = useState(type.style.bold);
    const [underline, setUnderline] = useState(type.style.underline);
    const [textAlign, setTextAlign] = useState(type.style.textAlign);
    const fontSizes = getFontSizes(1280, "TV");

    const contentBlock = htmlToDraft(
        `<p style="text-align:${textAlign};"><span style="${getStylesToApply(type.style)}">${t(
            "{{capitalize, capitalize}}",
            {
                capitalize: t("example-of-text"),
            }
        )}</span></p>`
    );
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [cEditorState, setCEditorState] = useState(EditorState.createWithContent(contentState));

    const onEditorStateChange = (editorState) => {
        const inlineStyle = editorState.getCurrentInlineStyle()._map._list._tail.array;
        const newStlyes = getEditorStyles(convertToRaw(editorState.getCurrentContent()), inlineStyle);
        const _contentBlock = htmlToDraft(
            `<p style="text-align:${newStlyes.textAlign};"><span style="${newStlyes.spanStyle}">${t(
                "{{capitalize, capitalize}}",
                {
                    capitalize: t("example-of-text"),
                }
            )}</span></p>`
        );
        const _contentState = ContentState.createFromBlockArray(_contentBlock.contentBlocks);
        const _newState = EditorState.createWithContent(_contentState);
        setCEditorState(_newState);
    };

    const getEditorStyles = (currentCotent, inlineStyle) => {
        let newStyle = {
            textAlign: currentCotent.blocks[0].data["text-align"] ? currentCotent.blocks[0].data["text-align"] : "left",
            spanStyle: "",
        };
        if (currentCotent.blocks[0].data["text-align"]) {
            setTextAlign(currentCotent.blocks[0].data["text-align"]);
        }
        let _bold = false;
        let _underline = false;
        let _italic = false;
        let _fontsize = false;
        inlineStyle.forEach((element) => {
            if (!element) {
                return;
            }
            if (element[0].includes("fontsize")) {
                const data = element[0].split("-");
                const _size = fontSizes.find((element) => element.px === parseInt(data[1]));
                setFontSize(_size ? _size.value : data[1]);
                newStyle.spanStyle += `font-size:${data[1]}px; `;

                _fontsize = true;
            }
            if (element[0].includes("fontfamily")) {
                const data = element[0].split("-");
                setFontFamily(data.slice(1).join("-"));
                setFontLink(getFontLinkByName(data.slice(1).join("-")));
                newStyle.spanStyle += `font-family:${data.slice(1).join("-")}; `;
            }
            if (element[0].includes("BOLD")) {
                _bold = true;
            }
            if (element[0].includes("UNDERLINE")) {
                _underline = true;
            }
            if (element[0].includes("ITALIC")) {
                _italic = true;
            }
        });
        if (inlineStyle.length < 2) {
            if (_fontsize) {
                newStyle.spanStyle += `font-family:${fontFamily}; `;
            } else {
                const _s = fontSizes.find((element) => element.vw === fontSize);

                newStyle.spanStyle += `font-size:${_s?.px}px; `;
            }
            _bold = bold;
            _underline = underline;
            _italic = italic;
        }
        if (_bold) {
            setBold(true);
            newStyle.spanStyle += "font-weight: bold; ";
        } else {
            setBold(false);
        }
        if (_italic) {
            setItalic(true);
            newStyle.spanStyle += "font-style: italic; ";
        } else {
            setItalic(false);
        }
        if (_underline) {
            setUnderline(true);
            newStyle.spanStyle += "text-decoration: underline; ";
        } else {
            setUnderline(false);
        }

        return newStyle;
    };

    const getFontLinkByName = (fontName) => {
        const fontFound = fonts.fontList.find((_font) => _font.name === fontName);
        if (fontFound && fontFound.ref === "EXTERNAL") {
            return { externalUrl: fontFound.externalUrl, libraryRef: null };
        } else if (fontFound) {
            return { externalUrl: null, libraryRef: fontFound.ref };
        } else {
            return { externalUrl: null, libraryRef: "" };
        }
    };

    const defaultSize = 2;
    return (
        <div id="typographyPane" className="mt-4 w-full  bg-white">
            <Editor
                editorState={cEditorState}
                wrapperClassName="demo-wrapper border border-gray-200"
                editorClassName="demo-editor text-widget-edition-area bg-gray-200 px-2 overflow-y-scroll"
                onEditorStateChange={onEditorStateChange}
                toolbarCustomButtons={[
                    <CustomFontSizes
                        defaultSize={defaultSize}
                        convertToPx={true}
                        type={type}
                        toolbar={{
                            options: ["sizes"],

                            sizes: fontSizes,
                        }}
                    />,
                ]}
                toolbar={{
                    options: ["fontFamily", "inline", "textAlign"],
                    fontFamily: {
                        options: fonts?.fontNames,
                        className: "typography-panel-font-family",
                        dropdownClassName: "custom-dropdown-family w-auto absolute",
                    },
                    inline: {
                        inDropdown: false,
                        className: "custom-class-inline",
                        options: ["bold", "italic", "underline"],
                        bold: { className: "typography-panel-inline-bold" },
                        italic: { className: "typography-panel-inline-italic" },
                        underline: { className: "typography-panel-inline-underline" },
                    },
                    textAlign: {
                        inDropdown: true,
                        className: "custom-class-textalign",
                        left: { className: "typography-panel-align-left" },
                        center: { className: "typography-panel-align-center" },
                        right: { className: "typography-panel-align-right" },
                        justify: { className: "typography-panel-align-justify" },
                    },
                }}
            />
            <div className="right-0 mt-4 inline-flex w-full justify-end">
                <UseButton
                    id={`typography-panel-${type.name}-cancel-button`}
                    buttonName="cancel"
                    action={cancelHandle}
                    buttonColor="btn-blue-outline"
                    adjust={"w-1/4 mr-2"}
                />
                <UseButton
                    id={`typography-panel-${type.name}-save-button`}
                    buttonName="save"
                    buttonColor="btn-blue"
                    adjust={"w-1/4"}
                    action={() => {
                        saveHandle({
                            ...type,
                            style: {
                                fontName: fontFamily,
                                size: fontSize,
                                textAlign: textAlign,
                                bold: bold,
                                italic: italic,
                                underline: underline,
                                fontLink: fontLink, // FIXME: poner url de la fuente o asset
                            },
                        });
                    }}
                />
            </div>
        </div>
    );
}

export default TypographyPane;
