import React, { createContext, useState, useContext, useEffect } from "react";
import { Session } from "hooks/Utils/Session";
import { toast } from "react-toastify";
import UseToast from "components/Notifications/useToast";

const UploadFileContext = createContext();

const UploadFileContextProvider = ({ children }) => {
    const [toastID, setToastID] = useState();
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [toastValues, setToastValues] = useState([]);
    const [executeChange, setExecuteChange] = useState(false);

    useEffect(() => {
        if (executeChange) {
            toast.update(toastID, {
                render: <UseToast title={toastValues[0]} msgs={toastValues[1]} minimize={true} />,
                className: "use-toast",
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }, [executeChange]);

    const generateFilesUploading = ({ files = [], lang, t, arrangeToastMessagesUploadingFiles }) => {
        let filesUploading = [];
        for (let file of files) {
            filesUploading.push({
                name: file.file.name,
                status: 1,
                errorMessage: "",
                size: file?.file?.size ?? 0,
                ref: "",
                typeFile: file.file.type,
                lang: lang,
            });
        }
        const [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesUploading, files.length);

        setToastID(
            toast.warning(<UseToast title={title} msgs={msgs} minimize={true} />, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: false,
                closeButton: false,
                closeOnClick: false,
                draggable: false,
                hideProgressBar: true,
                icon: false,
                className: "use-toast",
            })
        );
        setFilesToUpload(filesUploading);
    };
    const uploadFilesRequest = async ({
        files,
        url,
        fileRef,
        t,
        lang,
        arrangeToastMessagesUploadingFiles,
        parseName,
        folder,
    }) => {
        let transcodingVideos = false;
        let file;
        for (let i = 0; i < files.length; i++) {
            file = files[i].file;
            const uploadUrl = Session.getUploadUrl(
                `${url ?? ""}?type=${file.type.includes("image") ? "asset" : file.type}&token=${Session.getSessionProp(
                    "token"
                ).replace("Bearer ", "")}${fileRef ? `&ref=${fileRef}` : ""}${folder && `&folder=${folder}`}`
            );
            const formData = new FormData();
            formData.append("file", file);
            formData.append("fileName", parseName ? parseName(file.name) : file.name);
            lang && formData.append("fileLanguage", lang);
            try {
                const uploadResponse = await fetch(uploadUrl, {
                    method: "POST",
                    body: formData,
                }).then((uploadResponse) => uploadResponse.json());
                if (uploadResponse?.ref) {
                    setFilesToUpload((prevValue) => {
                        let filesUploading = prevValue;
                        filesUploading[i].status =
                            file.type === "video" && uploadResponse?.streamStatus !== "COMPLETE" ? 1 : 2;
                        filesUploading[i].status = file.type !== "video" && 2;
                        filesUploading[i].ref = uploadResponse?.ref;
                        setToastValues(() => {
                            let [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesUploading, files.length);
                            return [title, msgs];
                        });

                        return filesUploading;
                    });
                } else {
                    setFilesToUpload((prevValue) => {
                        let filesUploading = prevValue;
                        filesUploading[i].status = 4;
                        filesUploading[i].errorMessage = uploadResponse?.error?.message ?? "";
                        setToastValues(() => {
                            let [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesUploading, files.length);
                            return [title, msgs];
                        });
                        return filesUploading;
                    });
                }
            } catch (err) {
                setFilesToUpload((prevStatus) => {
                    let filesUploading = prevStatus;
                    filesUploading[i].status = 4;
                    setToastValues(() => {
                        let [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesUploading, files.length);
                        return [title, msgs];
                    });
                    return filesUploading;
                });
            }
            setExecuteChange(true);

            if (filesToUpload[i]?.status === 1) {
                transcodingVideos = true;
            }
            if (!transcodingVideos) {
                setToastValues(() => {
                    let [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesToUpload, files.length);
                    return [title, msgs];
                });
            }
            setExecuteChange(false);
            setTimeout(function () {
                toast.dismiss(toastID);
            }, 2000);
        }
    };

    return (
        <UploadFileContext.Provider
            value={{ executeChange, toastID, setToastID, filesToUpload, generateFilesUploading, uploadFilesRequest }}
        >
            {children}
        </UploadFileContext.Provider>
    );
};

const useUploadFileContext = () => useContext(UploadFileContext);

export { UploadFileContextProvider, useUploadFileContext };
