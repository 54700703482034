const NewStaffUser = () => {
    return [
        {
            title: "add-user",
            bodyAdjust: "w-3/12",
            executeSectionQuery: true,
            inputs: [
                {
                    type: "add-edit-staff-user",
                    values: null,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "add",
                    style: "blue",
                    action: "add-staff-user",
                },
            ],
        },
    ];
};

export default NewStaffUser;
