import React from "react";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import UseButton from "../../../useButton";
import { preventNegativeValues, parseSecondsToDaysHours, UPDATE_OPEN, tooltipHTMLDataAccessProfile } from "./Utils";

const AccessTypesOpen = ({
    openData,
    setOpenFields,
    openFields,
    setCardEdition,
    openFieldsBackup,
    setKeepCurrentPreview,
    setSelectMutation,
}) => {
    const { t } = useTranslation();
    const sectionName = "access-type-open";

    const accessProfile =
        openData && openData.additionalAccessProfileData ? openData.additionalAccessProfileData : null;
    const idAccessProfile = openData && openData.accessprofiles_id;

    return (
        <div>
            <div className=" flex w-full mt-4">
                <div className=" w-1/2 mr-10">
                    {/* ACCESS-PROFILE-OPEN */}
                    <div className=" pl-2 border-b text-left font-bold border-gray-400">
                        {capitalizeFirst(t("access-profile"))}
                    </div>
                    <div className=" w-full flex mt-4 ml-2">
                        {String(idAccessProfile) === "0" ? (
                            <div className=" font-normal rounded flex flex-col items-start text-gray-800 truncate ">
                                <span className=" block">
                                    {`${capitalizeFirst(t("default"))} (${capitalizeFirst(t("max-bandwidth"))}) `}
                                </span>
                                <div className=" flex items-start mt-2 ">
                                    <span className=" icon icon-maximum text-gray-800 text-2xl"></span>
                                    <span className=" text-gray-800">
                                        {accessProfile && accessProfile.uploadBWMax / 1000000} Mb/s
                                    </span>
                                </div>
                                <div className=" flex items-start mt-2 ">
                                    <span className=" icon icon-minimum text-gray-800 text-2xl"></span>
                                    <span className=" text-gray-800">
                                        {accessProfile && accessProfile.downloadBWMax / 1000000} Mb/s
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div
                                    data-tip={tooltipHTMLDataAccessProfile(accessProfile, t, capitalizeFirst)}
                                    data-for="dangerous-html-tooltip"
                                    className=" bg-zafiro-600 font-bold py-2 px-2 rounded flex items-center text-white truncate "
                                >
                                    {accessProfile && accessProfile.name ? accessProfile.name : ""}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className=" w-1/2">
                    {/* CONFIGURATION-OPEN */}
                    <div className=" pl-2 border-b text-left font-bold border-gray-400">
                        {capitalizeFirst(t("configuration"))}
                    </div>
                    <div className=" w-full flex flex-col mt-4 ml-2 text-left">
                        {/* DURATION-OPEN */}
                        <div className=" text-gray-800">
                            {capitalizeFirst(t("define-connection-time"))}
                            <span className=" ml-3 relative inline-block text-2xl text-gray-700 ">
                                <div
                                    data-tip={capitalizeFirst(t("user-reconnect-after-that-time"))}
                                    data-for="dangerous-html-tooltip"
                                    className=" icon icon-info"
                                    style={{ position: "absolute", top: "-15px", left: "0" }}
                                ></div>
                            </span>
                        </div>
                        <div className=" mt-2 w-2/4 flex mb-8">
                            <div className=" w-1/2 flex flex-col">
                                <span className=" text-gray-800 text-sm mb-2 font-bold">
                                    {capitalizeFirst(t("days"))}
                                </span>
                                <input
                                    className="t-filter-input  p-2 "
                                    placeholder="--"
                                    min={0}
                                    id={`${sectionName}-open-renovation-days`}
                                    max={9999}
                                    defaultValue={
                                        openFields && openFields["open-renovation"]
                                            ? parseSecondsToDaysHours(openFields["open-renovation"]).d
                                            : 1
                                    }
                                    onChange={(e) => {
                                        e.target.value = preventNegativeValues(e.target.value);
                                    }}
                                    type={"number"}
                                ></input>
                            </div>

                            <div className=" w-1/2 flex flex-col">
                                <span className=" text-gray-800 text-sm mb-2 font-bold">
                                    {capitalizeFirst(t("hours"))}
                                </span>
                                <input
                                    className="t-filter-input p-2 "
                                    placeholder="--"
                                    min={0}
                                    id={`${sectionName}-open-renovation-hours`}
                                    max={9999}
                                    defaultValue={
                                        openFields && openFields["open-renovation"]
                                            ? parseSecondsToDaysHours(openFields["open-renovation"]).h
                                            : ""
                                    }
                                    onChange={(e) => {
                                        e.target.value = preventNegativeValues(e.target.value);
                                    }}
                                    type={"number"}
                                ></input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className=" flex space-x-3 justify-end mx-10 mb-6 mt-6">
                    <div
                        onClick={() => {
                            setCardEdition(null);
                            setOpenFields({ ...openFieldsBackup });
                        }}
                    >
                        <UseButton id={`${sectionName}-cancel`} buttonName="cancel" buttonColor="btn-white" />
                    </div>
                    <div
                        onClick={() => {
                            setSelectMutation(UPDATE_OPEN);
                            setKeepCurrentPreview(true);
                        }}
                    >
                        <UseButton id={`${sectionName}-save`} buttonName="save" buttonColor="btn-blue" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccessTypesOpen;
