import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useEffect } from "react";

export const useLogoutDevices = ({ onSuccess, onError }) => {
    const query = gql`
        mutation logOutKey($ref: String!, $date: DateTime!) {
            logOutKey(ref: $ref, date: $date) {
                error
                ok
            }
        }
    `;
    const [load, { data, loading, error: mutationError, called }] = useMutation(query, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const error = mutationError || data?.logOutKey?.error;
    const success = data?.logOutKey?.ok || false;

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess(data?.logOutKey);
        }
    }, [success]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return {
        called,
        logout: ({ ref }) => {
            if (load) {
                load({ variables: { ref, date: new Date().toISOString() } });
            }
        },
        loading,
        error: error,
        ready: called && !loading && !mutationError,
        success: success,
    };
};

export const useUpdateMarketing = ({ onSuccess, onError }) => {
    const query = gql`
        mutation updateCustomer($id: Int64!, $marketingAccepted: Boolean) {
            updateCustomer(ID: $id, marketingAccepted: $marketingAccepted) {
                error
                ok
            }
        }
    `;
    const [load, { data, loading, error: mutationError, called }] = useMutation(query, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const error = mutationError || data?.updateCustomer?.error;
    const success = data?.updateCustomer?.ok || false;

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess(data?.updateCustomer);
        }
    }, [success]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return {
        called,
        update: ({ id, value }) => {
            if (load) {
                load({ variables: { id, marketingAccepted: value } });
            }
        },
        loading,
        error: error,
        ready: called && !loading && !mutationError,
        success: success,
    };
};

export const useUpdateUnregisteredMarketing = ({ onSuccess, onError }) => {
    const query = gql`
        mutation updateUnregisteredCustomer($email: Email!, $marketingAccepted: Boolean) {
            updateUnregisteredCustomer(email: $email, marketingAccepted: $marketingAccepted) {
                error
                ok
            }
        }
    `;
    const [load, { data, loading, error: mutationError, called }] = useMutation(query, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const error = mutationError || data?.updateCustomer?.error;
    const success = data?.updateCustomer?.ok || false;

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess(data?.updateCustomer);
        }
    }, [success]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return {
        called,
        update: ({ email, value }) => {
            if (load) {
                load({ variables: { email, marketingAccepted: value } });
            }
        },
        loading,
        error: error,
        ready: called && !loading && !mutationError,
        success: success,
    };
};
