import { CORPORATE_CHANNEL } from "hooks/Utils/Services/ChannelsUtils";

import { Session } from "hooks/Utils/Session";

const NewChannel = (closeAction, typeChannel, corporateChannelsWithoutGlobalManagement) => {
    const isCorporate = typeChannel === CORPORATE_CHANNEL.value;
    const hasChainModule = Session.hasChainModule();
    const inputs = [];
    let text;
    let action;

    if (isCorporate) {
        if (!corporateChannelsWithoutGlobalManagement) {
            inputs.push({
                name: "addCorporateChannel",
                type: "addCorporateChannel",
            });
            text = !hasChainModule ? "write-the-name-of-corporate-channel" : "Select-corporate-channel-content";
            action = "add-corporate-channel-property";
        } else {
            inputs.push({
                name: "name-corporate-channel",
                type: "text",
                style: "pb-10",
            });
            text = "write-the-name-of-corporate-channel";
            action = "add-corporate-channel-without-global-management";
        }
    } else {
        inputs.push({
            name: "addChannel",
            type: "add-channel",
        });
        text = "select-channel-class";
        action = "add-channel";
    }

    return {
        title: isCorporate ? "add-corporate-channel" : "add-channel",
        text,
        executeSectionQuery: true,
        inputs,
        buttons: [
            { name: "cancel", style: "white", action: closeAction ? closeAction : "close" },
            {
                name: "save",
                style: "blue",
                action: action,
            },
        ],
    };
};

export default NewChannel;
