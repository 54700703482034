import { gql } from "apollo-boost";

export const GET_DAILY_CREDENTIALS_RESET = gql`
    {
        getDailyResetDeviceCredentialsDate {
            enable
            time
        }
    }
`;

export const SET_DAILY_RESET_CREDENTIALS_MUTATION = gql`
    mutation DailyReset($enable: Boolean!, $time: String!) {
        updateDailyResetDeviceCredentialsDate(enable: $enable, time: $time) {
            ok
            error
        }
    }
`;
