import React, { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Table, { stringValue } from "components/ZafiroTable";
import { useAlert, useConfirm } from "components/Modal";
import Icon from "components/Icon";
import Button from "components/Button";

const Tables = () => {
    return (
        <div className="p-10 flex-col space-y-5">
            <div className="w-full">
                <FullTable />
            </div>
            <div className="flex space-x-3">
                <div className="bg-white rounded shadow p-5 space-y-3 mb-5 w-2/3">
                    <div className="font-bold">Table with custom controls</div>
                    <ServerTable />
                </div>

                <div className="bg-white rounded shadow p-5 space-y-3 mb-5 w-1/3">
                    <div className="font-bold">Lazy load table</div>
                    <LazyLoadTable />
                </div>
            </div>
        </div>
    );
};

const MOCKUP_COLORS = ["red", "green", "blue", "yellow", "purple"];
const MOCKUP_ANIMALS = ["Dog", "Cat", "Bird", "Fish", "Rabbit", "Turtle", "Horse", "Cow", "Pig", "Sheep"];
const MOCKUP_CITIES_SPAIN = ["Madrid", "Barcelona", "Saragossa"];
const MOCKUP_CITIES_WORLD = ["Paris", "London", "Buenos Aires"];

const getRandom = (set) => {
    return set[Math.floor(Math.random() * set.length)];
};

const LazyLoadTable = () => {
    const tableEmailsRef = useRef(null);

    const { open: alert } = useAlert();
    const { open: confirm } = useConfirm();

    const [page, setPage] = useState(1);

    const totalItems = 93;
    const perPage = 5;

    return (
        <>
            <Table
                ref={tableEmailsRef}
                id="table-emails"
                className="bg-gray-100 rounded shadow"
                maxHeight="40rem"
                cols={["email", "delete"]}
                header={{
                    email: { title: "Email", sortable: true },
                    delete: { align: "right", width: "5%" },
                }}
                paginate={"lazy"}
                perPage={perPage}
                onPageChange={setPage}
                rows={new Array(totalItems).fill(0).map((_, index) => ({
                    rowConfig: {
                        batchActions: ![3, 4].some((v) => v == index),
                    },
                    email: `${Math.random().toString(36).substring(2, 7)}@zafiro.tv`,
                    delete: (
                        <Button
                            id={`delete-email-${index}`}
                            onClick={() =>
                                confirm({
                                    title: "Delete email",
                                    message: "Are you sure you want to delete this email?",
                                    onConfirm: () => toast.success(`Email deleted`),
                                    sensitive: true,
                                })
                            }
                        >
                            <Icon type="delete" />
                        </Button>
                    ),
                }))}
                batchActions={true}
            />
            <div className="text-right text-gray-700">
                Showing {Math.min(page * perPage, totalItems)} of {totalItems}
            </div>
            <Button
                id="get-selected-rows"
                design="blue"
                onClick={() => {
                    const selectedRows = tableEmailsRef.current?.getSelectedRows();
                    alert(
                        <div className="space-y-2">
                            {selectedRows?.map((row) => (
                                <div key={row?.id}>{row?.email}</div>
                            ))}
                        </div>,
                        `Selected emails: ${selectedRows?.length || 0}`
                    );
                }}
            >
                Get selected emails
            </Button>
        </>
    );
};

const ServerTable = () => {
    const { t } = useTranslation();

    const [customSearch, setCustomSearch] = useState(null);
    const [customSort, setCustomSort] = useState({ field: "id", criteria: "asc" });
    const [customPage, setCustomPage] = useState(1);
    const [filterHeight, setFilterHeight] = useState(null);
    const [filterType, setFilterType] = useState(null);

    const baseRows = [
        { id: 1, name: "Bulbasaur", type: "Grass", weight: 69, height: 7 },
        { id: 2, name: "Charmander", type: "Fire", weight: 85, height: 16 },
        { id: 3, name: "Squirtle", type: "Water", weight: 90, height: 25 },
        { id: 4, name: "Caterpie", type: "Bug", weight: 29, height: 3 },
        { id: 5, name: "Weedle", type: "Bug", weight: 32, height: 3 },
        { id: 6, name: "Pidgey", type: "Normal", weight: 18, height: 3 },
        { id: 7, name: "Rattata", type: "Normal", weight: 35, height: 3 },
        { id: 8, name: "Spearow", type: "Normal", weight: 20, height: 3 },
        { id: 9, name: "Ekans", type: "Poison", weight: 69, height: 20 },
        { id: 10, name: "Pikachu", type: "Electric", weight: 60, height: 4 },
    ]
        ?.filter((row) => {
            if (customSearch) {
                const searchString = `${row?.name} ${row?.type} ${row?.weight} ${row?.height} ${row?.id}`;
                return searchString.toLowerCase().includes(customSearch.toLowerCase());
            }
            return true;
        })
        ?.filter((row) => {
            if (filterType?.length) {
                return filterType.includes(row?.type);
            }
            return true;
        })
        ?.filter((row) => {
            if (filterHeight) {
                if (filterHeight === "Short") {
                    return row?.height < 10;
                } else if (filterHeight === "Medium") {
                    return row?.height >= 10 && row?.height < 20;
                } else {
                    return row?.height >= 20;
                }
            }
            return true;
        })
        ?.sort((a, b) => {
            if (customSort?.field) {
                if (["name", "type"].includes(customSort?.field)) {
                    return customSort?.criteria === "asc"
                        ? a[customSort?.field].localeCompare(b[customSort?.field])
                        : b[customSort?.field].localeCompare(a[customSort?.field]);
                }
                if (customSort?.criteria === "asc") {
                    return a[customSort?.field] - b[customSort?.field];
                } else {
                    return b[customSort?.field] - a[customSort?.field];
                }
            }
            return 0;
        });
    const perPage = 3;
    const rows = baseRows.slice((customPage - 1) * perPage, customPage * perPage);

    const exampleAPIParams = {};
    if (filterType) {
        exampleAPIParams.type = filterType;
    }
    if (customSearch) {
        exampleAPIParams.search = customSearch;
    }
    if (filterHeight) {
        exampleAPIParams.height = filterHeight;
    }
    if (customPage) {
        exampleAPIParams.page = customPage;
    }
    if (customSort?.field) {
        exampleAPIParams.sort = `${customSort?.field}:${customSort?.criteria}`;
    }

    const exampleAPICall = "/api/" + exampleAPIParams ? "?" + new URLSearchParams(exampleAPIParams).toString() : "";

    return (
        <>
            <Table
                id="table-pokemon"
                cols={["id", "name", "type", "weight", "height"]}
                rows={rows}
                header={{
                    id: { title: "ID", sortable: true },
                    name: { title: "Name", sortable: true },
                    type: { title: "Type", sortable: true },
                    weight: { title: "Weight", sortable: true },
                    height: { title: "Height", sortable: true },
                }}
                showCount={true}
                paginate={true}
                perPage={perPage}
                search={true}
                filters={[
                    {
                        id: "filter-by-type",
                        title: t("Type"),
                        options: [
                            { id: "t1", value: "Grass", label: "Grass" },
                            { id: "t2", value: "Fire", label: "Fire" },
                            { id: "t3", value: "Water", label: "Water" },
                            { id: "t4", value: "Bug", label: "Bug" },
                            { id: "t5", value: "Normal", label: "Normal" },
                        ],
                        multiple: true,
                        value: filterType,
                        onChange: setFilterType,
                    },
                    {
                        id: "filter-by-height",
                        title: t("Height"),
                        options: [
                            { id: "h1", value: "Short", label: "Short" },
                            { id: "h2", value: "Medium", label: "Medium" },
                            { id: "h3", value: "Tall", label: "Tall" },
                        ],
                        value: filterHeight,
                        onChange: setFilterHeight,
                    },
                ]}
                customPagination={{
                    value: customPage,
                    onChange: setCustomPage,
                }}
                customSort={{
                    value: customSort,
                    onChange: setCustomSort,
                }}
                customCount={baseRows.length}
                customSearch={{
                    value: customSearch,
                    onChange: setCustomSearch,
                }}
            />
            <pre className="p-5 bg-yellow-200">{exampleAPICall}</pre>
            <pre className="p-5 bg-yellow-300">
                {JSON.stringify(
                    {
                        sort: customSort,
                        search: customSearch,
                        page: customPage,
                        filters: { height: filterHeight, type: filterType },
                    },
                    null,
                    2
                )}
            </pre>
        </>
    );
};

const FullTable = () => {
    const { t } = useTranslation();

    const { open: alert } = useAlert();
    const { open: confirm } = useConfirm();

    return (
        <Table
            id="table-test"
            cols={["id", "color", "pet", "city", "code", "available"]}
            search={true}
            page={3}
            paginate={true}
            perPage={8}
            showCount={true}
            className="bg-white rounded shadow p-5"
            topRightCorner={
                <div className="border border-dashed border-zafiro-200 p-5 text-gray-600">
                    Contenido custom en la esquina superior derecha de la tabla
                </div>
            }
            batchActions={{
                options: [
                    {
                        id: "delete",
                        value: "delete",
                        label: t("erase"),
                    },
                    {
                        id: "set-available",
                        value: "set-available",
                        label: t("set-as-available"),
                    },
                ],
                onChange: (action, rows) => {
                    if (action === "delete") {
                        confirm({
                            title: "Delete selected rows",
                            message: "Are you sure you want to delete the selected rows?",
                            onConfirm: () => toast.success(`${rows.length} rows deleted`),
                            sensitive: true,
                        });
                    } else {
                        toast.success(`Action ${action} applied to ${rows.length} rows`);
                    }
                },
            }}
            header={{
                id: { title: "ID", sortable: true },
                color: {
                    title: "Color",
                    width: "20%",
                    sortable: true,
                },
                pet: { title: "Pet", width: "10%" },
                city: { title: "City", width: "15%", sortable: true, align: "center" },
                code: { title: "Code", width: "15%", sortable: true, align: "right" },
                available: { title: "Available", width: "10%", sortable: true, align: "center" },
            }}
            rows={Array.from({ length: 57 }, (_, index) => {
                const color = getRandom(MOCKUP_COLORS);
                const city = getRandom(MOCKUP_CITIES_SPAIN);
                const code = Math.floor(Math.random() * 1000);
                const id = `ref-${String(index).padStart(4, "0")}`;
                const info = [
                    [{ id: 1, title: "Personal info", data: { Name: "John", Surname: "Doe", Age: 25 } }],
                    [{ id: 2, title: "Location", data: { City: "New York", Country: "USA" } }],
                    [
                        { id: 3, title: "Phone number", data: "+1 555 123 456" },
                        { id: 4, title: "Email", data: "john.doe@gmail.com" },
                    ],
                ];

                let expandedData;
                if (id === "ref-0020") {
                    expandedData = true;
                } else if (id === "ref-0018") {
                    expandedData = (
                        <div className="flex flex-row space-x-5 justify-between">
                            <div className="w-full">
                                <Table
                                    id={`table-${id}`}
                                    cols={["name", "surname", "age"]}
                                    header={{
                                        name: { title: "Name" },
                                        surname: { title: "Surname" },
                                        age: { title: "Age" },
                                    }}
                                    rows={[
                                        { name: "John", surname: "Doe", age: 25 },
                                        { name: "Jane", surname: "Doe", age: 22 },
                                        { name: "Alice", surname: "Smith", age: 30 },
                                        { name: "Bob", surname: "Smith", age: 28 },
                                    ]}
                                    style={{ zoom: 0.8 }}
                                />
                            </div>
                            <img
                                src="https://cdn.pixabay.com/photo/2024/01/04/13/41/room-8487500_1280.jpg"
                                alt="image"
                                className="rounded max-h-20"
                            />
                        </div>
                    );
                } else if (id !== "ref-0019") {
                    expandedData = (
                        <div
                            className={`text-sm text-gray-800 leading-6 grid gap-4 grid-cols-${info.length}`}
                            style={{ marginLeft: "5.5rem" }}
                        >
                            {info.map((items, index) => (
                                <div key={index} className="flex flex-col space-y-2">
                                    {items?.map((item) => (
                                        <div key={item?.id}>
                                            <p>
                                                <strong>{item?.title}</strong>
                                            </p>
                                            {typeof item?.data === "object"
                                                ? Object.keys(item.data).map((subKey) => (
                                                      <Fragment key={subKey}>
                                                          {`${subKey}: ${item?.data[subKey]}`}
                                                          <br />
                                                      </Fragment>
                                                  ))
                                                : item?.data}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    );
                }
                return {
                    id,
                    color: ({ expanded }) => (
                        <span
                            alt={color}
                            className={`text-white bg-${color}-600 px-3 ${
                                expandedData === true && expanded ? "text-xl" : ""
                            } rounded-lg`}
                        >
                            {color}
                        </span>
                    ),
                    pet: getRandom(MOCKUP_ANIMALS),
                    city: ({ expanded }) => (
                        <span className={expandedData === true && expanded ? "text-xl" : null} alt={city}>
                            {city}
                        </span>
                    ),
                    code: <span alt={code}>{`#${code}`}</span>,
                    available: Math.random() > 0.5 ? true : false,
                    rowConfig: {
                        expanded: expandedData,
                        actions: [
                            {
                                id: "edit",
                                label: "Edit",
                                disabled: id === "ref-0002",
                                tooltip: "Edit tooltip here",
                                onClick: () => alert(`Editing row ${id}`, "Edit row"),
                            },
                            {
                                id: "delete",
                                label: "Delete",
                                disabled: id === "ref-0002" || Math.random() > 0.5,
                                onClick: () =>
                                    confirm({
                                        title: "Delete row",
                                        message: `Are you sure you want to delete row ${id}?`,
                                        onConfirm: () => toast.success(`Row ${id} deleted`),
                                        sensitive: true,
                                    }),
                            },
                        ],
                    },
                };
            })}
            filters={[
                {
                    id: "filter-by-color",
                    title: t("color"),
                    multiple: true,
                    options: MOCKUP_COLORS.map((color) => ({
                        id: color,
                        value: color,
                        label: color,
                    })),
                    onFilter: (values, row) => (values?.includes(stringValue(row?.color)) ? row : null),
                },
                {
                    id: "filter-by-pet",
                    title: t("Animal"),
                    options: MOCKUP_ANIMALS.map((animal) => ({
                        id: animal,
                        value: animal,
                        label: animal,
                    })),
                    onFilter: (value, row) => (stringValue(row?.pet) === String(value) ? row : null),
                },
                {
                    id: "filter-by-city",
                    title: t("City"),
                    multiple: true,
                    options: [
                        {
                            id: "spain",
                            label: "Spain",
                            options: MOCKUP_CITIES_SPAIN.map((city) => ({
                                id: city,
                                value: city,
                                label: city,
                            })),
                        },
                        {
                            id: "world",
                            label: "World",
                            options: MOCKUP_CITIES_WORLD.map((city) => ({
                                id: city,
                                value: city,
                                label: city,
                            })),
                        },
                    ],
                    onFilter: (values, row) => (values?.includes(stringValue(row?.city)) ? row : null),
                },
            ]}
        />
    );
};

export default Tables;
