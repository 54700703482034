import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import UseIcon from "../useIcon";
import DeleteScheduleModal from "../../hooks/GraphqlCalls/CommonZones/modals/schedules/DeleteScheduleModal";
//Actions
import { cleanAction, setActionName, setActionItemAffected, setActionInput } from "../../actions/globalActions";
import { openModal, setModalContent } from "../../actions/uiActions";
import { showEditCommonSchedule } from "../../actions/sectionActions";
import { updateSScheduleStatus, setMutationRefresh } from "../../actions/scheduleActions";
//Utils
import getAvailableActions from "../../hooks/Utils/DataCalc/GetAvailableActions";
import useComponentVisible from "../useComponentVisible";

const UseScheduleWizardEditorOptions = ({ currentEvent }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { ID, isEnabled } = currentEvent;
    const [activeAction, setActiveAction] = useState();
    const availableActions = getAvailableActions("schedule-wizard", isEnabled);
    const { periodicSchedulesBatch } = useSelector((state) => state.schedules);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const icon = {
        name: "row-actions",
    };

    //Actions
    const dispatch = useDispatch();

    const showModal = (e) => {
        dispatch(setActionName("delete-schedule-editor"));
        dispatch(setActionItemAffected(currentEvent.tempId ? currentEvent.tempId : ID));
        dispatch(setModalContent(DeleteScheduleModal(ID, currentEvent)));
        //Launch modal
        dispatch(openModal());
    };

    const directExecute = (e) => {
        const { action } = e.currentTarget.dataset;
        //Set mutation
        setActiveAction(action);
    };

    const showEditRowForm = (e) => {
        dispatch(showEditCommonSchedule(true));
        dispatch(setActionName("edit-periodic"));
        dispatch(setActionItemAffected([ID]));
        dispatch(setActionInput(currentEvent.values));
    };

    //Listeners
    useEffect(() => {
        if (activeAction) {
            const itemIndex = getItemIndex(periodicSchedulesBatch, currentEvent);
            switch (activeAction) {
                case "enable":
                    dispatch(
                        updateSScheduleStatus({
                            newIndex: itemIndex,
                            newStatus: true,
                        })
                    );
                    dispatch(setMutationRefresh(true));
                    break;
                case "disable":
                    dispatch(
                        updateSScheduleStatus({
                            newIndex: itemIndex,
                            newStatus: false,
                        })
                    );
                    dispatch(setMutationRefresh(true));
                    break;
                case "delete":
                    showModal();
                    dispatch(setMutationRefresh(true));
                    break;
                default:
                    updateEditorSchedule(currentEvent);
            }
            dispatch(cleanAction());
        }
        // eslint-disable-next-line
    }, [activeAction]);

    return (
        <div ref={ref} className="t-actions-icon relative overflow-visible">
            <div onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}>
                <UseIcon icon={icon} adjust="text-lg cursor-pointer z-100" />
            </div>
            {isComponentVisible ? (
                <div
                    id="actions-container"
                    className="t-actions-container"
                    onClick={() => setIsComponentVisible(false)}
                >
                    <div className="t-actions-background">
                        <div id="action">
                            {availableActions.length > 0 ? (
                                availableActions.map((action, index) => (
                                    <span
                                        key={index}
                                        className="t-action-container first-capital"
                                        onClick={
                                            !action.noModal
                                                ? showModal
                                                : action.directAction
                                                ? directExecute
                                                : showEditRowForm
                                        }
                                        data-action={action.name}
                                    >
                                        {langStrings[action.name]}
                                    </span>
                                ))
                            ) : (
                                <span className="t-noaction-container">{langStrings["no-actions"]}</span>
                            )}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

const getItemIndex = (periodicSchedulesBatch, currentEvent) => {
    //Returns item affected index from redux store
    let res;
    // eslint-disable-next-line
    periodicSchedulesBatch.map((periodicSchedule, index) => {
        if (JSON.stringify(periodicSchedule) === JSON.stringify(currentEvent)) {
            res = index;
        }
    });
    return res;
};

const updateEditorSchedule = (currentEvent) => {
    //TODO
};

export default UseScheduleWizardEditorOptions;
