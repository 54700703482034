import React, { useEffect, useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLazyQuery } from "react-apollo";
import { getMobileAppearancesQuery } from "../MobileAppearances/mobileAppearancesUtils";
import { setExecuteQuery } from "actions/sectionActions";
import { gql } from "apollo-boost";
import UseSelectWithSearch from "components/useSelectWithSearch";
import Loading from "components/Loading";
import { cleanActionValidations, cleanActionValues, setActionValidations } from "actions/globalActions";
import { useAuth } from "hooks/Session/auth";

const ModalChooseAppearance = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isCorporate, hasChainModule } = useAuth();
    const [designsOptions, setDesignsOptions] = useState([]);
    const [selectedDesign, setSelectedDesign] = useState(props.designID);

    const GET_MOBILE_APPEARANCE_THEMES = gql`
        ${getMobileAppearancesQuery(props.designID)}
    `;

    const [executeQueryThemes, { data: designs, loading: loadingDesigns }] = useLazyQuery(
        GET_MOBILE_APPEARANCE_THEMES,
        {
            fetchPolicy: "network-only",
        }
    );

    useEffect(() => {
        executeQueryThemes();
        return () => {
            setSelectedDesign(null);
        };
    }, []);

    useEffect(() => {
        if (designs?.data?.results?.length > 0) {
            const groupsNames = {};
            groupsNames.LOCAL = t("property-appearances");
            if (isCorporate || hasChainModule) {
                groupsNames.CORPORATE = t("general-appearances");
            }
            setDesignsOptions(designs?.data?.results.map((val) => ({ ...val, category: groupsNames[val.category] })));
        }
    }, [designs]);

    const handleSaveClick = () => {
        if (selectedDesign) {
            dispatch(
                setExecuteQuery({
                    action: "update-mobile-appearance",
                    params: { themeID: selectedDesign, mobileAppID: props.mobileAppID },
                })
            );
            props.close();
        } else {
            dispatch(setActionValidations({ ["select-appearance"]: false }));
            toast.error(t("input error"));
        }
    };

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            dispatch(cleanActionValues());
            dispatch(cleanActionValidations());
            setSelectedDesign(null);
            props.close();
        }
    };

    return (
        <Modal
            title={`${props?.name} - ${t("choose-appearance")}`}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-save" onClick={handleSaveClick}>
                        {capitalizeFirst(t("save"))}
                    </Button>
                </>
            }
            className={"w-4/12 p-10"}
        >
            <div>
                <p className="font-bold">{capitalizeFirst(t("appearance"))}*</p>
                <div>{t("select-appearance-description")}</div>
                {!loadingDesigns && designsOptions.length > 0 ? (
                    <UseSelectWithSearch
                        data={{
                            textStyle:
                                "text-gray-800 text-sm font-bold pb-0.5 first-capital whitespace-nowrap overflow-ellipsis overflow-hidden",
                            name: "select-appearance",
                            optionData: designsOptions,
                            selectedIds: [selectedDesign],
                            selectPlaceHolder: "select",
                            cStyle: "w-full",
                            sort: true,
                            oneSelected: true,
                            noSelect: true,
                            id: "select-appearance",
                            onChange: (design) => {
                                setSelectedDesign(design);
                            },
                        }}
                    ></UseSelectWithSearch>
                ) : (
                    <Loading></Loading>
                )}
            </div>
        </Modal>
    );
};

const useChooseAppearance = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalChooseAppearance {...newProps} />);
        },
    };
};

export default useChooseAppearance;
