export const Settings = [
    "name",
    "fullname",
    "surname",
    "email",
    "properties",
    "roles",
    "accesses",
    "manager-users",
    "edit-user",
    "delete-user",
    "delete-users",
    "generate-password",
    "delete-user-confirm",
    "delete-users-confirm",
    "user",
    "users",
    "add-user",
    "add-role",
    "add-role-desc",
    "confirm-pass-reset",
    "confirm-pass-reset-multiple",
    "manager-roles",
    "assigned",
    "edit-role",
    "duplicate",
    "delete-role",
    "delete-role-confirm",
    "password-send",
    "select-accesses",
    "select-roles",
    "language-list",
    "flag",
    "edit-language",
    "add",
    "newo",
    "delete",
    "make-default",
    "default",
    "are-you-sure",
    "you-want",
    "delete",
    "lang",
    "cant-undo",
    "cancel",
    "close",
    "erase",
    "set",
    "as",
    "predetermined",
    "accept",
    "language-confirm-deletion",
    "language-confirm-update",
    "language-descriptive-text",
    "manager-properties",
    "manager-propertyGroups",
    "property-management",
    "group-name",
    "groups",
    "property",
    "delete-group",
    "duplicate-group",
    "edit-group",
    "edit-property-group",
    "add-new-group",
    "select-properties",
    "duplicate-property-group",
    "delete-property-group",
    "property-groups",
    "delete-properties-groups-confirm",
    "select-properties-groups",
    "edit-property",
    "choose-an-image",
    "images-of-the-media-library",
    "corporate-user",
    "corporate-users",
    "corporate",
    "non-corporate-users",
    "isCorporate",
    "corporate-users",
    "user-email-exist-footenote",
    "accesses-text",
    "chain",
    "sales",
    "Sales",
    "group-assigned-user",
];
