import { useMonitorWarnings } from "components/Section/Monitor/hooks/useMonitorWarnings";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import { formatDate } from "components/Section/Monitor/services/formatDate";
import { capitalizeFirst } from "hooks/Utils/Utils";

export const CastError = () => {
    const [collapsibleExtended, setCollapsibleExtended] = useState(true);
    const { warnings, called, loading } = useMonitorWarnings();
    const { t } = useTranslation();
    const collapsibleServerError = () => {
        setCollapsibleExtended(!collapsibleExtended);
    };

    if (!called || loading || !warnings?.areCriticalNetworkWarnings) {
        return null;
    }

    return (
        <div className="p-4 bg-gray-900 text-white w-full mb-8">
            <div className="flex gap-4 items-center">
                <span
                    className={`icon-chevron${collapsibleExtended ? "-up" : ""} ml-8 cursor-pointer animated`}
                    id="serverErrorExtendedIcon"
                    onClick={collapsibleServerError}
                ></span>
                <span className=" icon icon-alert text-red-100 text-3xl "></span>
                <div className="my-1 ml-2">{t("network-warnings-detected")}</div>
            </div>
            {collapsibleExtended ? (
                <AnimatePresence>
                    <motion.main
                        initial="hidden"
                        animate="visible"
                        exit="close"
                        variants={{
                            visible: {
                                opacity: 1,
                                height: "auto",
                                display: "block",
                                transition: {
                                    when: "beforeChildren",
                                    staggerChildren: 0.3,
                                },
                            },
                            hidden: {
                                opacity: 0,
                                height: 0,
                                display: "hidden",
                            },
                            close: {
                                opacity: 0,
                                height: 0,
                                display: "hidden",
                                transition: {
                                    when: "afterChildren",
                                    delay: 0.3,
                                },
                            },
                        }}
                    >
                        <div className="flex flex-col mt-4 ml-16 px-16">
                            <ul className="list-disc">
                                {Object.keys(warnings).map((key) => {
                                    return (
                                        <Fragment key={key}>
                                            {warnings[key].alert ? (
                                                <>
                                                    <li id={key}>
                                                        {warnings[key].text}
                                                        {warnings[key].value ? (
                                                            <>
                                                                {warnings[key].value.map((ip) => (
                                                                    <span key={ip}>
                                                                        {" "}
                                                                        ({t("ip")}: {ip} )
                                                                    </span>
                                                                ))}
                                                                . {warnings[key].secondText}
                                                            </>
                                                        ) : null}
                                                        {warnings[key].firstValue &&
                                                        warnings[key].secondValue &&
                                                        warnings[key].thirdValue ? (
                                                            <>
                                                                {warnings[key].firstValue.map((mac) => (
                                                                    <span key={mac}> {mac} </span>
                                                                ))}
                                                                . {warnings[key].secondaryText} {"("}
                                                                {warnings[key].secondValue.map((ip) => (
                                                                    <span key={ip}>
                                                                        {t("ip")}: {ip}{" "}
                                                                    </span>
                                                                ))}{" "}
                                                                / {t("mac")}
                                                                {warnings[key].thirdValue.map((mac) => (
                                                                    <span key={mac}>
                                                                        {" "}
                                                                        {mac}
                                                                        {")"}
                                                                    </span>
                                                                ))}
                                                            </>
                                                        ) : null}
                                                    </li>
                                                    <span className="text-gray-600" id={`${key}-last-update-error`}>
                                                        {capitalizeFirst(t("last-update"))}:{" "}
                                                        {formatDate(warnings[key].lastUpdate)}
                                                    </span>
                                                </>
                                            ) : null}
                                        </Fragment>
                                    );
                                })}
                            </ul>
                        </div>
                        <p className="font-bold mt-6 ml-8 px-16 pb-4">{t("check-network-warning")} </p>
                    </motion.main>
                </AnimatePresence>
            ) : null}
        </div>
    );
};
