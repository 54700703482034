export const HotelSidebar = [
    "installation",
    "monitoring",
    "map",
    "networks-settings",
    "rooms",
    "service-config",
    "statistics",
    "room-list",
    "room-groups",
    "roomGroups",
    "SCREEN-TYPE-TV",
];
