import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import Switch from "components/Switch";
import NumberInput from "components/NumberInput";
import Button from "components/Button";
import { executeVendureQuery, useVendureAPI } from "hooks/Utils/Integrations/useVendure";
import { toast } from "react-toastify";
import Loading from "components/Loading";
import { useDispatch } from "react-redux";
import { setRefreshContentData } from "actions/sectionActions";
import { Session } from "hooks/Utils/Session";

const MUTATION_QUANTITY_PRODUCT = `mutation UpdateProductVariants($id: ID!, $customFields: UpdateProductVariantCustomFieldsInput!) {
    updateProductVariants(input: {
        id: $id,
        customFields: $customFields
    }) {
        id
        __typename
    }
}
`;

const useMutation = ({ closeModal }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { adminApi } = useVendureAPI();
    const [loading, setLoading] = useState(false);
    const tokenShop = Session.getSessionProp("tokenShop");

    const mutate = async (variables) => {
        setLoading(true);
        try {
            const response = await executeVendureQuery(
                adminApi,
                {
                    queryBody: MUTATION_QUANTITY_PRODUCT,
                    variables,
                },
                { "vendure-token": tokenShop }
            );

            if (response?.errors && response.errors.length > 0) {
                toast.error(t("mutation-error"));
                dispatch(setRefreshContentData(true));
                closeModal();
            } else if (response?.data) {
                toast.success(t("operation-successful"));
                dispatch(setRefreshContentData(true));
                closeModal();
            } else {
                toast.error(t("mutation-error"));
                dispatch(setRefreshContentData(true));
                closeModal();
            }
        } catch (error) {
            toast.error(`${error.message}`);
            dispatch(setRefreshContentData(true));
            closeModal();
        } finally {
            setLoading(false);
        }
    };

    return { mutate, loading };
};

export const ModalContent = (props) => {
    const { t } = useTranslation();
    const { VALUES } = props;

    const { max_units_per_order_enabled = false, max_units_per_order = 0, id: idShop = null } = VALUES || {};

    const [maxUnitsPerOrderEnabled, setMaxUnitsPerOrderEnabled] = useState(max_units_per_order_enabled);
    const [maxUnitsPerOrder, setMaxUnitsPerOrder] = useState(max_units_per_order);

    const { mutate, loading } = useMutation({ closeModal: props?.close });

    const handleUpdate = () => {
        mutate({
            id: idShop,
            customFields: {
                max_units_per_order: Number(maxUnitsPerOrder),
                max_units_per_order_enabled: maxUnitsPerOrderEnabled,
            },
        });
    };

    return (
        <Modal
            id="modal-max-quantity-product"
            footer={
                <>
                    {loading ? null : (
                        <>
                            <Button design="blue-outline" id="modal-button-cancel" onClick={props?.close}>
                                {t("cancel")}
                            </Button>
                            <Button
                                design="blue"
                                id="modal-button-save"
                                onClick={() => {
                                    handleUpdate();
                                }}
                            >
                                {t("save")}
                            </Button>
                        </>
                    )}
                </>
            }
            title={`${t("settings")} - ${t("edit")}`}
            minWidth="30vw"
        >
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className=" flex items-center">
                        <div className=" font-bold mr-2">{t("max-quantity-per-order")}</div>
                        <Switch
                            id="max-quantity-per-order-enabled"
                            checked={maxUnitsPerOrderEnabled}
                            onChange={({ checked }) => {
                                setMaxUnitsPerOrderEnabled(checked);
                            }}
                        />
                    </div>
                    <div>
                        <div className=" mt-4">{t("define-maximum-quantity")}</div>
                    </div>
                    <div className=" flex mt-5 items-center pb-10">
                        <NumberInput
                            disabled={maxUnitsPerOrderEnabled === false}
                            id="max-quantity-units-per-order"
                            type="number"
                            value={maxUnitsPerOrder}
                            className="mt-2"
                            onChange={(value) => {
                                setMaxUnitsPerOrder(value);
                            }}
                        />
                        <div className=" ml-4 mt-1">{t("products")}</div>
                    </div>
                </>
            )}
        </Modal>
    );
};

export const useMaxQuantityProduct = () => {
    const { open, close } = useModal();

    const openModal = (props) => {
        const newProps = {
            close,
            ...props,
        };

        open(<ModalContent {...newProps} />);
    };

    return {
        open: openModal,
        close,
    };
};
