import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { DEVICE_FUNCTIONS } from "../../hooks/Utils/Hotel/Monitor/ListDevicesUtils";
import { useTranslation } from "react-i18next";
import UseRadio from "../useRadio";
import { changeActionValues } from "../../actions/globalActions";
import uuid from "react-uuid";
import UseLoading from "../Table/useLoading";
import UseSelectWithSearch from "../useSelectWithSearch";
import { Session } from "../../hooks/Utils/Session";

const UseModalAssignDevice = ({ index }) => {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const isPhilips = data?.type === "Philips";
    const { permissions } = useSelector((state) => state.ui);
    const [optionsFunctions, setOptionsFunctions] = useState([]);
    const [optionSelected, setOptionSelected] = useState(
        isPhilips ? DEVICE_FUNCTIONS.tvAndcast.enumValue : DEVICE_FUNCTIONS.cast.enumValue
    );
    const [dataSelect, setDataSelect] = useState([]);
    const { t } = useTranslation();

    const functionDevice = data?.params?.deviceFunctionData ? data.params.deviceFunctionData : {};
    const productCast = permissions?.product?.cast ? true : false;
    const productTv = permissions?.product?.tv ? true : false;
    const onlyAssign = data?.onlyAssign ? true : false;
    const type = data?.params?.type ? data.params.type : "";
    const kind = data?.params?.kind ? data.params.kind : "";

    const GET_ROOMS_AVAILABLE = gql`
        query {
            roomtvsWithoutDeviceByFunction(kind:${kind} function: ${optionSelected}) {
                roomID
                roomName
                roomNumber
                roomTVID
                roomTVName
            }
        }
    `;

    const [executeQueryRooms, { data: dataRoomsAvailable, loading }] = useLazyQuery(GET_ROOMS_AVAILABLE, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (functionDevice && functionDevice.enumValue) {
            setOptionSelected(functionDevice.enumValue);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (optionSelected) {
            Session.setSessionProp("updateSelectOptions", "assign-device-id");
            executeQueryRooms();
            dispatch(
                changeActionValues({
                    function_device: optionSelected,
                })
            );
        }
        // eslint-disable-next-line
    }, [optionSelected]);

    useEffect(() => {
        if (dataRoomsAvailable?.roomtvsWithoutDeviceByFunction) {
            setDataSelect(arrangeDataRooms(dataRoomsAvailable.roomtvsWithoutDeviceByFunction));
        }
        // eslint-disable-next-line
    }, [dataRoomsAvailable]);

    useEffect(() => {
        const optionsByType = {
            GoogleTV: [
                { label: "cast", value: DEVICE_FUNCTIONS.cast.enumValue, canAssign: productCast },
                {
                    label: "tvAndcast",
                    value: DEVICE_FUNCTIONS.tvAndcast.enumValue,
                    canAssign: productCast && productTv,
                },
                { label: "tv", value: DEVICE_FUNCTIONS.tv.enumValue, canAssign: productTv },
            ],
            Chromecast: [{ label: "cast", value: DEVICE_FUNCTIONS.cast.enumValue, canAssign: productCast }],
            Philips: [
                {
                    label: "tvAndcast",
                    value: DEVICE_FUNCTIONS.tvAndcast.enumValue,
                    canAssign: productCast && productTv,
                },
            ],
        };

        let options = [];

        if (type && optionsByType[type]) {
            options = optionsByType[type].filter((option) => option.canAssign);
        } else if (productTv) {
            options = [{ label: "tv", value: DEVICE_FUNCTIONS.tv.enumValue, canAssign: true }];
        }

        if (!optionSelected && options.length > 0) {
            setOptionSelected(options[0].value);
        }

        setOptionsFunctions(options);
    }, [type]);

    return (
        <div className=" relative">
            {!onlyAssign ? (
                <span className=" block mb-4">{`${t("device-authorise-and-assign-you-sure").replace(
                    "%0",
                    data?.name
                )}`}</span>
            ) : null}
            {(optionsFunctions.length > 1 && onlyAssign) || (optionsFunctions.length > 1 && !onlyAssign) ? (
                <>
                    <div className=" mb-4">{t("select-device-function")}</div>
                    <div className=" mb-2">
                        {optionsFunctions.map((opt) => {
                            const key = uuid();
                            return (
                                <div key={key} className=" mb-1">
                                    <UseRadio
                                        handleClick={() => setOptionSelected(opt.value)}
                                        value={opt.value}
                                        selected={optionSelected}
                                        text={`${t(DEVICE_FUNCTIONS[opt.label].label)} ${
                                            opt.label === "tv" || opt.label === "tvAndcast" ? "*" : ""
                                        }`}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    {optionsFunctions.some((option) => option.label === "tv" || option.label === "tvAndcast") ? (
                        <span className="block mb-8 text">{t("device-assign-type-function")}</span>
                    ) : null}
                </>
            ) : null}
            <div className=" mb-2">{`${t("select-room-location-assign")} ${data?.name}`}</div>
            <div className={`${optionsFunctions.length <= 1 ? " mb-6" : ""}`}>
                <UseSelectWithSearch
                    data={{
                        optionData: dataSelect,
                        oneSelected: true,
                        noSelect: true,
                        selectPlaceHolder: "select",
                        name: "assign-device-id",
                        optionsAdjust: "mt-11",
                    }}
                    key={dataSelect}
                />
            </div>
            <div>
                {optionsFunctions.length <= 1 ? (
                    <span>{`${t("device-function")}: ${t(
                        DEVICE_FUNCTIONS[optionsFunctions?.[0]?.label]?.label
                    )}  `}</span>
                ) : null}
            </div>
            {loading ? (
                <div className=" w-full absolute top-0 left-0 h-full flex  justify-center bg-white bg-opacity-75 rounded items-center">
                    <UseLoading adjust={"contents"} />
                </div>
            ) : null}
        </div>
    );
};

export default UseModalAssignDevice;

const arrangeDataRooms = (dataRooms) => {
    const arr = [];
    dataRooms.forEach((element) => {
        arr.push({
            id: element.roomTVID,
            name: `${element.roomName}&nbsp;&nbsp; <span class='text-gray-800 text-sm'>${element.roomTVName}</span>`,
        });
    });
    return arr;
};
