import { useEffect, useState } from "react";
import { gql } from "apollo-boost";
import { useMSQuery } from "../useMSQuery";

const LogosFolderRef = () => {
    const [logosFolderRef, setLogosFolderRef] = useState("root");

    //API Call
    const LIBRARY_TREE = gql`
        {
            libraryTree: libraryTree(ref: "root") {
                error
                ok
                response {
                    name
                    ref
                }
            }
        }
    `;

    const [executeLibraryTreeQuery, { loading, error, data }] = useMSQuery(LIBRARY_TREE);

    useEffect(() => {
        if (!loading && !error && data && data.libraryTree && data.libraryTree.response) {
            let logosFolder = data.libraryTree.response.find((folder) => folder.name === "Logos");
            if (logosFolder) {
                setLogosFolderRef(logosFolder.ref);
            }
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        executeLibraryTreeQuery();
        // eslint-disable-next-line
    }, []);

    return logosFolderRef;
};

export default LogosFolderRef;
