import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { GetScheduleMutationDates } from "../../hooks/Utils/GetScheduleMutationDates";
//Components
import UseInputDateEditSchedule from "./useInputDateEditSchedule";
import UseInputNameEditSchedule from "./useInputNameEditSchedule";
import UseButton from "../useButton";
//Actions
import {
    cleanBatchSchedules,
    cleanViewSchedules,
    setScheduleToUpdate,
    cancelScheduleUpdate,
    setMutationRefresh,
} from "../../actions/scheduleActions";
import { cleanAction } from "../../actions/globalActions";
import {
    showEditFestiveSchedule,
    restorePeriodicScheduleBackup,
    setWizardAllowNext,
} from "../../actions/sectionActions";
//Notifications
import { toast } from "react-toastify";
import { getDateType } from "../../hooks/Utils/ScheduleDisplayTools";
import { validateDates, validateName } from "../../hooks/Utils/ScheduleManagement";
import { useTranslation } from "react-i18next";
import { Session } from "../../hooks/Utils/Session";

const UseFormFestiveUpdate = ({ setEditFestiveSchedule }) => {
    const { t } = useTranslation();
    //Store data
    const { itemsAffected } = useSelector((state) => state.action);

    const { scheduleToUpdate } = useSelector((state) => state.schedules);
    const { periodicEventsData } = useSelector((state) => state.sectionContent);
    const currentEvent = getCurrentEvent(itemsAffected[0], periodicEventsData);

    //Validation States
    const [nameValidation, setNameValidation] = useState(true);
    const [datesValidation, setDatesValidation] = useState(true);

    //Mutation states
    const [mutation, setMutation] = useState(
        //Default mutation to prevent gql conversion to throw error
        `mutation {updateTimetable(areaID: 0 name: " " isEnabled:true periodicityType: " " type: " "){ error id ok}}`
    );
    const { mutationRefresh } = useSelector((state) => state.schedules);
    //CurrentData states
    const [timetableToEdit, setTimetableToEdit] = useState(false);
    //API
    const UPDATE_PUNCTUAL_SCHEDULE = gql`
        ${mutation}
    `;
    const [executeMutation] = useMutation(UPDATE_PUNCTUAL_SCHEDULE);

    //Actions
    const dispatch = useDispatch();

    const handleClickSaveAll = () => {
        //Validation
        const nameValid = validateName(scheduleToUpdate.name);
        const datesValid = validateDates(
            scheduleToUpdate.dateType,
            scheduleToUpdate.timetableDates[0].dateList,
            scheduleToUpdate.timetableDates[0].dateList
        );
        setNameValidation(validateName(nameValid));
        setDatesValidation(datesValid);
        if (!nameValid || !datesValid) {
            toast.error(t("check form"));
            return;
        }

        //Save data
        executeMutation();
        toast.success(t("holidays updated"));
        handleClickCancelAll(false);
    };

    const handleClickCancelAll = (showToast = true) => {
        //Cancel all process
        dispatch(restorePeriodicScheduleBackup(JSON.parse(Session.getSessionProp("periodicScheduleBackup"))));
        setEditFestiveSchedule(false);
        dispatch(showEditFestiveSchedule(false));
        dispatch(cancelScheduleUpdate());
        dispatch(cleanAction());
        dispatch(cleanBatchSchedules());
        dispatch(cleanViewSchedules());
        if (showToast) {
            toast.success(t("exit-wo-save"));
        }
        dispatch(setWizardAllowNext(true));
    };

    //Listeners
    useEffect(() => {
        //Load current event values in schedules store
        dispatch(setScheduleToUpdate(currentEvent));
        Session.setSessionProp("periodicScheduleBackup", JSON.stringify(periodicEventsData));
        setTimetableToEdit(scheduleToUpdate);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (scheduleToUpdate && scheduleToUpdate.timetableDates && scheduleToUpdate.timetableDates.length > 0) {
            setTimetableToEdit(scheduleToUpdate);
        }

        if (mutationRefresh) {
            //Set mutation
            setMutation(
                getMutation(
                    scheduleToUpdate.name,
                    scheduleToUpdate.ID,
                    scheduleToUpdate.dateType,
                    GetScheduleMutationDates(scheduleToUpdate.timetableDates),
                    scheduleToUpdate.isEnabled
                )
            );
            dispatch(setMutationRefresh(false));
        }
        // eslint-disable-next-line
    }, [scheduleToUpdate, mutationRefresh]);

    return (
        <>
            <div className="w-full block bg-white p-8">
                <>
                    <div className="pt-4 pb-8">
                        {timetableToEdit ? (
                            <>
                                <div className="w-full block mb-4">
                                    <span className="w-full">
                                        <div className="inline-block w-3/12 pr-8">
                                            <UseInputNameEditSchedule validation={nameValidation} />
                                        </div>
                                        <div className="inline-block w-3/12">
                                            <div className="inline-block">
                                                {t(getDateType(timetableToEdit.dateType))}
                                            </div>
                                        </div>
                                        <div className="inline-block w-6/12">
                                            <div className="w-8/12 inline-block pr-8 z-200">
                                                <UseInputDateEditSchedule validation={datesValidation} index={0} />
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </>
                        ) : null}
                    </div>
                </>
            </div>
            <div className="w-full flex justify-end h-10 mt-8 mr-4 mb-4">
                <div className="w-auto inline-block mr-4" onClick={handleClickCancelAll}>
                    <UseButton buttonName="exit-unsaved" buttonColor="btn-blue-outline" adjust="px-8" />
                </div>
                <div className="w-auto inline-block" onClick={handleClickSaveAll}>
                    <UseButton buttonName="save-and-close" buttonColor="btn-blue" adjust="px-8" />
                </div>
            </div>
        </>
    );
};

const getCurrentEvent = (itemId, punctualEventsData) => {
    //Returns current event data to update proccess
    let res;
    // eslint-disable-next-line
    punctualEventsData.currentData.map((punctualEventData) => {
        if (punctualEventData.ID === itemId) {
            res = punctualEventData;
        }
    });
    return res;
};

//Mutation functions
const getMutation = (name, areaID, dateType, timetableDates, status) => {
    //Returns dynamic mutation
    //ISO 8601 standard Monday(1) Sunday (7)
    return `mutation {
      updateTimetable(
        name: "${name}"
        type: "CLOSED"
        isEnabled: ${status}
        dateType: "${dateType}"
        timetableDates: ${timetableDates}
        id: ${areaID}
        ){ error id ok}}`;
};

export default UseFormFestiveUpdate;
