import Keys from "components/Section/Devices/Keys";
import RemoteControllers from "components/Section/Devices/RemoteControllers";
import { newRoute, newRedirect } from "routes/utils";

const sectionID = "devices";

const route = newRoute(sectionID);
const redirect = newRedirect(sectionID);

export const devicesRoutes = [
    route("/", {
        redirect: redirect("/keys"),
        check: ({ permissions }) => permissions?.hotel?.devices,
    }),
    route("/keys", {
        section: Keys,
        check: ({ permissions }) => permissions?.hotel?.devices,
    }),
    route("/remote-controllers", {
        section: RemoteControllers,
        check: ({ permissions }) => permissions?.hotel?.devices,
    }),
];
