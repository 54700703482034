import React, { useContext, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "components/ReactTooltip";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { EditorContext, WidgetContext } from "../../../../../../contexts/editor";

//Actions
import { setExecuteQuery } from "../../../../../../actions/sectionActions";
import { setColorPickerReference } from "../../../../../../actions/designActions";
import { rgbaToHex, hexToRgbA, GetThemeDefault } from "../../../../../../hooks/Utils/DesignUtils";
import Widget from "../../Widget";

const InputColor = ({ id, title, deviceSpecific, toolbarID, defaultColor }) => {
    const { deviceType, itemSelected, theme } = useContext(EditorContext);
    const { type, config, style, id: widgetID } = useContext(WidgetContext);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { widgetToolbarVisible, gridItems } = useSelector((state) => state.design);

    const [inputError, setInputError] = useState("");
    const [opacity, setOpacity] = useState("");

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const isOpen = itemSelected === widgetID && (!toolbarID || widgetToolbarVisible === toolbarID);

    const hasTheme = theme && id !== "borderColor";

    const implementNewStyles = Widget.ImplementNewLibrary(type);

    const customized = implementNewStyles ? config.style.isCustomizedById(id) : style && style[id];
    const value =
        (implementNewStyles
            ? config.style.exportById(id)
            : style && style[id]
            ? style[id]
            : defaultColor == null
            ? GetThemeDefault({ theme, id })
            : defaultColor) || null;

    useEffect(() => {
        if (isOpen) {
            const regex = /^\w+\((\s*\d+\s*,){3}\s*([^\s]*)\s*\)/gm;
            const alpha = ("" + value).match(regex) ? ("" + value).replace(regex, `$2`) : 1;
            const colorOpacity = Math.round(alpha || `${alpha}` === "0" ? alpha * 100 : "100");

            let opacityInput = document.getElementById(id + "_opacity");
            if (opacityInput) {
                if (!value) {
                    opacityInput.placeholder = "100";
                } else {
                    opacityInput.value = colorOpacity;
                }
            }
            setOpacity(colorOpacity);
            let colorInput = document.getElementById(id);
            if (colorInput) colorInput.value = rgbaToHex(value);
        }
        // eslint-disable-next-line
    }, [value, gridItems]);

    const handleOnClick = useCallback((e) => {
        if (e && e.composedPath) {
            // Click on color picker
            let colorPicker = false;
            // eslint-disable-next-line
            e.composedPath().map((p) => {
                if (p && p.id === "pickerContainer") {
                    colorPicker = true;
                }
            });
            if (colorPicker) return;
        }
        dispatch(setColorPickerReference(null));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        document.body.addEventListener("click", handleOnClick);
        return () => {
            document.body.removeEventListener("click", handleOnClick);
        };
    }, [handleOnClick]);

    //Actions
    const updateColor = (property) => {
        let hexaColor = document.getElementById(`${property}`).value;
        const colorOpacity = document.getElementById(`${property}_opacity`).value;
        if (
            ((hexaColor.length !== 6 && !/^#[0-9a-fA-F]{6}$/i.test(hexaColor)) ||
                (hexaColor.length === 6 && !/^[0-9a-fA-F]{6}$/i.test(hexaColor))) &&
            hexaColor !== ""
        ) {
            toast.error(t("input-error"));
            setInputError(property);
            return;
        }

        setInputError(false);

        if (hexaColor !== "" && !hexaColor.includes("#")) {
            hexaColor = `#${hexaColor}`;
            document.getElementById(`${property}`).value = hexaColor;
        }
        const value = hexaColor !== "" ? hexToRgbA(hexaColor, colorOpacity || 100) : null;
        let colorOpacityInput = document.getElementById(`${property}_opacity`);
        if (colorOpacityInput) {
            colorOpacityInput.value = value ? colorOpacity : "";
        }
        setOpacity(colorOpacity);
        dispatch(
            setExecuteQuery(
                Widget.UpdateStyle({ widget, property, value, deviceSpecific: deviceSpecific ? deviceType : null })
            )
        );
    };

    const updateColorOpacity = (property) => {
        const hexaColor = document.getElementById(`${property}`).value;
        const colorOpacity = document.getElementById(`${property}_opacity`).value || 100;
        const value = hexToRgbA(hexaColor || "#000000", colorOpacity);
        setOpacity(colorOpacity);
        dispatch(
            setExecuteQuery(
                Widget.UpdateStyle({ widget, property, value, deviceSpecific: deviceSpecific ? deviceType : null })
            )
        );
    };
    return (
        <>
            <div className="flex mb-4">
                {title ? (
                    <div
                        className={`w-3/12 mt-2 first-capital${hasTheme && customized ? " text-gray-700 italic" : ""}`}
                        data-tip
                        data-for={`${id}_edit_color_tooltip`}
                    >
                        {title + (hasTheme && customized ? "*" : "")}
                    </div>
                ) : null}
                {title && hasTheme && customized ? (
                    <ReactTooltip
                        id={`${id}_edit_color_tooltip`}
                        delayShow={300}
                        type={"light"}
                        borderColor={"#D3DAE1"}
                        border={true}
                        multiline={true}
                    >
                        {t("The appearance values are not being used here")}
                    </ReactTooltip>
                ) : null}
                <div className="w-9/12 flex">
                    <div
                        id={id + "_square"}
                        onClick={(e) => {
                            dispatch(
                                setColorPickerReference({
                                    id: id + "_square",
                                    property: id,
                                    color: value,
                                })
                            );
                            e.stopPropagation();
                        }}
                        className={`mr-3 w-10 h-10 cursor-pointer rounded-md border border-gray-400 ${
                            value && opacity > 0 ? "" : "icon icon-no-color"
                        }`}
                        style={{
                            backgroundColor: value,
                            fontSize: value && opacity > 0 ? "" : "2.3rem",
                        }}
                    ></div>
                    <div className="w-5/12 mr-3 ">
                        <input
                            type="text"
                            id={id}
                            className={`h-10  bg-gray-200 rounded px-4 py-2 w-full ${
                                inputError === id ? " border border-red-100" : ""
                            }`}
                            onBlur={(e) => updateColor(id)}
                            placeholder="#000000"
                            onKeyUp={(e) => (e.keyCode === 13 ? updateColor(id) : null)}
                        />
                    </div>
                    <div className="w-5/12  ">
                        <input
                            type="number"
                            step="1"
                            min="0"
                            max="100"
                            id={id + "_opacity"}
                            className={`h-10 w-5/7 bg-gray-200 rounded px-4 py-2 `}
                            disabled={!value}
                            onBlur={() => updateColorOpacity(id)}
                            onKeyUp={(e) => (e.keyCode === 13 ? updateColorOpacity(id) : null)}
                        />
                        <span className="mt-2 ml-1">%</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InputColor;
