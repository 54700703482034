import React, { useState, useEffect } from "react";
import { useMutation } from "react-apollo";
import { gql } from "apollo-boost";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Session } from "hooks/Utils/Session";
import { cleanActionValues, setActionValidations } from "actions/globalActions";
import { closeModal, setModalContent, showGlobalLoading } from "actions/uiActions";
import { setExecuteQuery, setRefreshContentData } from "actions/sectionActions";
import { validateEmail } from "hooks/Utils/Validations";
import { useTranslation } from "react-i18next";

const UpdateStaff = () => {
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const values = useSelector((state) => state.action.values);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [mutation, setMutation] = useState(`
    mutation {
        createHotspot(name: "test") {
          ok
          error
          id
        }
      }`);

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const endActions = () => {
        dispatch(showGlobalLoading(false));
        dispatch(cleanActionValues());
        dispatch(setRefreshContentData(true));
        dispatch(setModalContent(false));
        dispatch(closeModal());
        dispatch(setExecuteQuery(null));
    };

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            toast.error(err.message);
            endActions();
        },
        onCompleted(data) {
            endActions();
            toast.success(t("operation-successful"));
        },
    });

    const validateFormNewEditStaff = (values) => {
        const validations = {};
        if (values.name.trim() === "") {
            validations["name"] = true;
        }

        if (values.email.trim() === "") {
            validations["email"] = true;
        } else if (!validateEmail(values.email)) {
            toast.error(t("invalid email"));
            validations["email"] = true;
        }

        if (values.accesses.length <= 0) {
            toast.error(t("you-need-to-select-at-least-one-access"));
            validations["accesses"] = true;
        }

        return validations;
    };

    useEffect(() => {
        if (executeQuery) {
            executeQuery.validations = {};

            switch (executeQuery.action) {
                case "new-staff-member":
                    executeQuery.validations = validateFormNewEditStaff(values);
                    setMutation(`mutation {
                        createStaffUser(
                          accountName: "${values.email}"
                          fullName: "${values.name}"
                          projects: "${Session.getProject()?.ref}"
                          roles: [${values.accesses.join(",")}]
                          shops: [${values.shops.join(",")}]
                          areas: [${values.zones.join(",")}]
                        ) {
                          error
                          id
                          ok
                        }
                    }`);

                    break;
                case "edit-staff-user":
                    executeQuery.validations = validateFormNewEditStaff(values);
                    setMutation(`mutation {
                        updateStaffUser(
                          id: ${values.id},
                          accountName: "${values.email}"
                          fullName: "${values.name}"
                          roles: "${values.accesses.join(",")}"
                          shops: [${values.shops.join(",")}]
                          areas: [${values.zones.join(",")}]
                        ) {
                          error
                          id
                          ok
                        }
                    }`);
                    break;
                case "staff-generate-password":
                    setMutation(`mutation {
                    regeneratePassword(
                        email: "${values.ref.values.email}", 
                        isStaff: true, 
                        ref: "${values.ref.id}"
                    ) {
                      id
                      okEmail
                      okUpdate
                      __typename
                    }
                  }
                  `);
                    break;
                case "staff-delete":
                    setMutation(`mutation {
                    deleteStaffUser(id: ${values.ref.id}) {
                      error
                      id
                      ok
                      __typename
                    }
                  }
                  `);
                    break;
                default:
                    break;
            }

            if (Object.keys(executeQuery.validations).length === 0) {
                dispatch(showGlobalLoading(true));
                setTimeout(function () {
                    executeMutation();
                }, 100);
            } else {
                toast.error(t("input error"));
                dispatch(setActionValidations(executeQuery.validations));
            }
        }
    }, [executeQuery]);

    return null;
};

export default UpdateStaff;
