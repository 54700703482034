import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import { executeVendureQuery, useVendureAPI } from "hooks/Utils/Integrations/useVendure";
import { setRefreshContentData } from "actions/sectionActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Parser from "hooks/Utils/Parser";

const findResultsItemById = (results, id) => {
    for (let item of results) {
        if (item.id === id) {
            return item;
        }
        if (item.subrowData && item.subrowData.length) {
            const found = findResultsItemById(item.subrowData, id);
            if (found) {
                return found;
            }
        }
    }
    return null;
};

const DELETE_PRODUCT_MUTATION = (facet = []) => {
    return `mutation DeleteCategory($id: ID!) {
        deleteCollection(id: $id){
            result
            message
        }

        ${
            facet.length > 0
                ? `deleteFacetValues(
                    ids: [${facet.join(",")}]
                    force: true
                ){
                    result
                    message
                }`
                : ""
        }
    }
    `;
};

const useMutationDeleteProduct = (onError, onSuccess) => {
    const { adminApi } = useVendureAPI();

    const mutate = async (variables, facet) => {
        try {
            const response = await executeVendureQuery(adminApi, {
                queryBody: DELETE_PRODUCT_MUTATION(facet),
                variables,
            });

            if (response?.error) {
                if (onError) onError(response.error);
            } else {
                if (onSuccess) onSuccess(response.data);
            }
        } catch (error) {
            if (onError) onError(error.message);
        }
    };

    return { mutate };
};

const ModalParent = ({ id, facetValueIds, name, count, close: closeModal }) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const { mutate } = useMutationDeleteProduct(
        (error) => handleMutationCompletion(error, true),
        (data) => handleMutationCompletion(data)
    );

    const handleMutationCompletion = (result, isError = false) => {
        const message = isError ? result : t("operation-successful");
        isError ? toast.error(message) : toast.success(message);
        dispatch(setRefreshContentData(true));
        closeModal();
    };

    return (
        <Modal
            title={`${name} - ${capitalizeFirst(t("delete"))}`}
            footer={
                !loading ? (
                    <>
                        <Button design="blue-outline" id="modal-button-cancel" onClick={() => closeModal()}>
                            {t("cancel")}
                        </Button>
                        <Button
                            design="red"
                            id="modal-button-delete"
                            onClick={() => {
                                mutate({ id }, facetValueIds);
                                setLoading(true);
                            }}
                        >
                            {capitalizeFirst(t("delete"))}
                        </Button>
                    </>
                ) : null
            }
            className={"w-4/12 p-10"}
        >
            {!loading ? (
                <div className=" py-4">
                    <p>{Parser(t("category-x-delete", { category: name }))}</p>
                    {count > 0 && <p>{Parser(t("categories-count", { count, text: t("subcategories") }))}</p>}
                </div>
            ) : (
                <Loading />
            )}
        </Modal>
    );
};

const useModalDeleteCategory = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalParent {...newProps} />);
        },
    };
};

export default useModalDeleteCategory;
