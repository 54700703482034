import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "@apollo/react-hoc";
//Actions
import { showGlobalLoading, closeModal, setModalContent } from "../../../actions/uiActions";

import { setRefreshContentData, setExecuteQuery } from "../../../actions/sectionActions";
import { cleanAction } from "../../../actions/globalActions";
import { setLoading } from "../../../actions/tableActions";
import { escapeSpecialChars, removeApolloCacheKeys } from "../../../hooks/Utils/Utils";

//Utils
import { toast } from "react-toastify";

const UpdateTemplate = ({ client }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);

    //State
    const [mutation, setMutation] = useState("mutation{checkIn}");

    //Actions
    const dispatch = useDispatch();

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(err.message);
        },
    });

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            executeQuery.mutationName = "";
            execute = true;
            switch (executeQuery.action) {
                case "delete-template":
                    setMutation(`
                        mutation{
                            deleteTemplate(
                                ID: ${executeQuery.params.value.id}
                                type: ${executeQuery.params.value.type}
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "deleteTemplate";
                    removeApolloCacheKeys(client.cache, "templates");
                    dispatch(showGlobalLoading(true));
                    break;
                case "edit-template-name":
                    let translations = "";
                    Object.entries(actionData.values).map((item) =>
                        item[0] !== "undefined" && item[0] !== "defaultLang"
                            ? (translations += `{lang:"${item[0]}" text:"${escapeSpecialChars(item[1])}"}`)
                            : null
                    );
                    setMutation(`
                        mutation{
                            updateTemplate(
                                ID: ${executeQuery.params.value.id}
                                name: [${translations}]
                                type: ${executeQuery.params.value.type}
                            ){ error  ok }}
                    `);
                    executeQuery.mutationName = "updateTemplate";
                    break;
                default:
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (!showGlobalLoading) dispatch(setLoading(true));
            if (!executeQuery.hideLoading) {
                dispatch(showGlobalLoading(true));
            }

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].isOk || data[executeQuery.action].ok)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].isOk || data[executeQuery.mutationName].ok)))
        ) {
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }
            if (!executeQuery.params || (executeQuery.params && !executeQuery.params.noCleanAction)) {
                dispatch(cleanAction());
            }

            toast.success(langStrings["operation-successful"]);
            dispatch(setExecuteQuery(null));
            dispatch(setRefreshContentData(true));
        } else if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].errorMsg || data[executeQuery.action].error)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].errorMsg || data[executeQuery.mutationName].error)))
        ) {
            const errorMsg =
                data[executeQuery.action] && data[executeQuery.action].error
                    ? data[executeQuery.action].error
                    : data[executeQuery.mutationName] && data[executeQuery.mutationName].error
                    ? data[executeQuery.mutationName].error
                    : null;
            toast.error(errorMsg ? errorMsg : langStrings["mutation-error"]);
            dispatch(showGlobalLoading(false));
            dispatch(setExecuteQuery(null));
            dispatch(setRefreshContentData(true));
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateTemplate);
