import { useLazyQuery } from "@apollo/react-hooks";

// Manager Service Query with notifyOnNetworkStatusChange: true as base config
// config for queries with no data change to render info when catches response

// if need to turn it off pass it false in options query

export function useMSQuery(query, options) {
    const queryOptions = { ...options, notifyOnNetworkStatusChange: true };
    return useLazyQuery(query, queryOptions, true);
}
