import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeActionValues } from "../actions/globalActions";
import { GetCustomTags } from "../hooks/GraphqlCalls/Design/ListTags";
import { capitalizeFirst, predefinedTags, removeDiacritics } from "../hooks/Utils/Utils";
import useComponentVisible from "./useComponentVisible";

const UseTagSelector = ({
    itemLabel,
    cValue,
    setCValue,
    customTop,
    customRight,
    customPadding,
    customDropDownWith,
    screenType,
    deviceType,
    id,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //Data
    let lastCategory = null;

    //Store data
    const { customTags } = useSelector((state) => state.sectionContent);
    const { permissions } = useSelector((state) => state.ui);

    //State
    const [allTags, setAllTags] = useState([]);

    GetCustomTags();

    useEffect(() => {
        let _allTags = [];
        if (customTags && customTags.length > 0) {
            customTags.forEach((tag) => {
                _allTags.push({
                    key: tag.id,
                    value: `{{.${tag.tag}}}`,
                    text: tag.name,
                    category: "custom-tags",
                    visible: true,
                });
            });
        }
        predefinedTags(t, permissions?.tags, deviceType, screenType).forEach((staticTag) => {
            _allTags.push({ ...staticTag, visible: true });
        });
        setAllTags(_allTags);
        // eslint-disable-next-line
    }, [customTags]);

    useEffect(() => {
        if (isComponentVisible) {
            //remove previous search
            const temCopy = JSON.parse(JSON.stringify(allTags));
            temCopy.map((item) => (item.visible = true));
            setAllTags(temCopy);

            setTimeout(function () {
                const inputSearch = document.querySelector(`#${itemLabel}_tag_input`);
                if (inputSearch) {
                    inputSearch.focus();
                }
            }, 200);
        }
        // eslint-disable-next-line
    }, [isComponentVisible]);

    const addLabel = (value) => {
        const newVal = `${cValue || ""}${cValue ? " " : ""}${value}`;
        setCValue(newVal);
        dispatch(
            changeActionValues({
                [itemLabel]: newVal,
            })
        );
        setIsComponentVisible(false);
    };

    const filterTags = (e) => {
        e.stopPropagation();

        const temCopy = JSON.parse(JSON.stringify(allTags));
        if (e.currentTarget.value && e.currentTarget.value.length > 0) {
            temCopy.map((item) =>
                removeDiacritics(item.text.toLowerCase()).indexOf(
                    removeDiacritics(e.currentTarget.value.toLowerCase())
                ) > -1
                    ? (item.visible = true)
                    : (item.visible = false)
            );
        } else {
            temCopy.map((item) => (item.visible = true));
        }
        setAllTags(temCopy);
    };

    return (
        <div ref={ref} id={id ? id : null}>
            <div
                className={`clickable absolute bg-white rounded ${customPadding || "py-2"} ${
                    customRight ? "" : "px-2"
                }`}
                style={{ right: customRight || "0.25rem", top: customTop || "2.25rem" }}
                onClick={(e) => {
                    e.stopPropagation();
                    setIsComponentVisible(!isComponentVisible);
                }}
            >
                <div className="icon icon-tags pr-2 float-left"></div>
                <div className={`icon icon-${isComponentVisible ? "chevron-up" : "chevron"} float-left`}></div>
            </div>

            {isComponentVisible && (
                <div
                    className={`absolute overflow-scroll p-2 bg-white border rounded border-gray-300 z-10 right-0 ${customDropDownWith}`}
                    style={{ height: "16rem" }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div>
                        <div>
                            <input
                                id={`${itemLabel}_tag_input`}
                                onClick={(e) => e.stopPropagation()}
                                type="text"
                                className="t-filter-search"
                                style={{ width: "calc(100% - 1rem)" }}
                                placeholder={`${capitalizeFirst(t("search"))}...`}
                                onChange={(e) => filterTags(e)}
                            />
                            <span
                                className="field-icon icon-search table mt-6 mr-2"
                                style={{ height: "2rem" }}
                                onClick={(e) => e.stopPropagation()}
                            ></span>
                        </div>
                    </div>
                    <div>
                        {allTags.length > 0 &&
                            allTags.map((item, index) => {
                                if (!item.visible) {
                                    return null;
                                }
                                let showCategoryName = false;
                                if (item.category !== lastCategory) {
                                    showCategoryName = true;
                                    lastCategory = item.category;
                                }
                                return (
                                    <Fragment key={index}>
                                        {showCategoryName && (
                                            <div className={`font-bold mt-2 mb-1 pt-1 ${index > 0 ? "border-t" : ""}`}>
                                                {capitalizeFirst(t(item.category))}
                                            </div>
                                        )}
                                        <li
                                            onMouseDown={(e) => {
                                                e.stopPropagation();
                                                addLabel(item.value);
                                            }}
                                            key={item.key}
                                            className="rdw-dropdownoption-default placeholder-li text-gray-900 clickable"
                                        >
                                            {item.text}
                                        </li>
                                    </Fragment>
                                );
                            })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default UseTagSelector;
