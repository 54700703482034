const MacExceptions = (content) => {
    return [
        {
            title: "handle-mac-exceptions",
            value: content.id,
            bodyAdjust: "w-4/4",
            executeSectionQuery: true,
            inputs: [
                {
                    text: ["handle-mac-exceptions-text"],
                    adjust: "flex items-center gap-2",
                    icon: "info",
                    iconTooltip: "handle-mac-exceptions-tooltip",
                    adjustIcon: "text-3xl text-gray-700 cursor-help",
                    type: "void",
                },
                {
                    text: ["remember-macs-free-access"],
                    type: "void",
                },
                {
                    text: ["name"],
                    type: "tableMacs",
                    focus: true,
                },
            ],
            buttons: [{ name: "close", style: "blue", action: "close" }],
        },
    ];
};

export default MacExceptions;
