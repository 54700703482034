import React from "react";
import { useSelector } from "react-redux";
import { withDeprecationWarning } from "dev";

import Loading from "components/Loading";

const UseGlobalLoading = withDeprecationWarning({ name: "UseGlobalLoading", alt: "Loading" }, () => {
    //Store data
    const { showGlobalLoading } = useSelector((state) => state.ui);

    return <>{showGlobalLoading ? <Loading adjust="absolute" /> : null}</>;
});

export default UseGlobalLoading;
