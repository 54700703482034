import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ShopContext } from "contexts/Sales/Shop";
import SettingsCard from "components/SettingsCard";
import { UseShopSettingsDeliveryHourModal } from "../../modals/useShopSettingsDeliveryHourModal";

const ShopSettingsDeliveryHour = () => {
    const { t } = useTranslation();
    const { open, close } = UseShopSettingsDeliveryHourModal();

    const { deliverySchedule, updateConfig } = useContext(ShopContext);

    const handleDeliveryHour = () => {
        open({
            value: deliverySchedule,
            onSave: (val) => {
                return updateConfig({ deliverySchedule: val }).then(() => {
                    close();
                });
            },
        });
    };

    return (
        <SettingsCard
            title={t("delivery-hour")}
            edit={{
                id: `shop-settings-button-open-modal-delivery-hour`,
                onClick: handleDeliveryHour,
            }}
            description={t("select-ranges-client-will-receive-order")}
        >
            <span className="block pb-5 text-base px-4">
                {Array.isArray(deliverySchedule) && deliverySchedule?.length > 0
                    ? deliverySchedule.map((item, index) => (
                          <span
                              key={item?.startTime}
                              id={`shop-settings-delivery-hour-${index}`}
                              className="block mb-4"
                          >
                              {item?.startTime} - {item?.endTime}
                          </span>
                      ))
                    : t("24-hours-delivery")}
            </span>
        </SettingsCard>
    );
};

export default ShopSettingsDeliveryHour;
