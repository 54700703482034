import Button from "components/Button";
import Icon from "components/Icon";
import Modal, { useModal } from "components/Modal";
import { useDeleteRoom } from "hooks/GraphqlCalls/Hotel/Settings/useDeleteRoom";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const DeleteRoom = ({ name, id, recentGuests }) => {
    const { open, close } = useModal();
    const { t } = useTranslation();
    const { deleteRoomMutation } = useDeleteRoom();
    return (
        <Button
            id={`delete-btn-${id}`}
            onClick={() =>
                open(
                    <Modal title={`${t("delete")} ${name}`} className="w-1/3 py-10 px-12">
                        <main className="mb-10">
                            {recentGuests && <p className="mb-4">{t("topology-delete-room-had-guests")}</p>}
                            <p>{t("topology-delete-room")}</p>
                        </main>
                        <footer className="flex justify-end gap-2">
                            <Button id="cancel" design="white" onClick={close}>
                                {t("cancel")}
                            </Button>
                            <Button
                                id="delete"
                                design="red"
                                onClick={() => {
                                    if (recentGuests) {
                                        toast.error(t("cannot delete occupied room"));
                                    } else {
                                        deleteRoomMutation(id);
                                    }
                                    close();
                                }}
                            >
                                {t("delete")}
                            </Button>
                        </footer>
                    </Modal>
                )
            }
        >
            <Icon type="delete" size="xl" />
        </Button>
    );
};
