import React from "react";
import { useSelector } from "react-redux";
//Components
import UseSchedulePunctualOptions from "./useSchedulePunctualOptions";
import UseScheduleWizardOptions from "./useScheduleWizardOptions";
import UseScheduleWizardEditorOptions from "./useScheduleWizardEditorOptions";
//Utils
import { getScheduleDisplay, showScheduleStatus, getStatusColor } from "../../hooks/Utils/ScheduleDisplayTools";
import Parser from "hooks/Utils/Parser";

const UseSchedule = ({ currentEvent, filterActive }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { eventTypeActive, currentStep } = useSelector((state) => state.sectionContent);

    //Data
    const { name, isEnabled } = currentEvent;
    const scheduleData = Parser(getScheduleDisplay(currentEvent, filterActive, langStrings, currentStep));
    const showTitle = gotTitle(currentEvent);

    return (
        <div className="schedule-existing-row h-auto p-8 relative">
            <div className="w-full block">
                <div className="w-full border-b border-gray-200 flex justify-between">
                    <div className="font-bold pb-4">
                        {showTitle ? <div className="inline-block text-blue-300 pr-2">{`${name}`}</div> : null}
                        <div className={`inline-block ${getStatusColor(isEnabled)}`}>
                            {showScheduleStatus(isEnabled, langStrings)}
                        </div>
                    </div>
                    {eventTypeActive === "periodic-event" ? (
                        <>
                            {!currentStep ? (
                                <UseScheduleWizardOptions currentEvent={currentEvent} />
                            ) : (
                                <UseScheduleWizardEditorOptions currentEvent={currentEvent} />
                            )}
                        </>
                    ) : (
                        <UseSchedulePunctualOptions currentEvent={currentEvent} />
                    )}
                </div>
                <div className="w-full pt-4 flex items-start">{scheduleData}</div>
            </div>
        </div>
    );
};

const gotTitle = (currentEvent) => {
    //Returns if has to show title depending of event type
    let res;
    if (currentEvent.periodicityType === "UNIQUE") {
        res = false;
    } else if (currentEvent.timetableDates && !currentEvent.timetableDates[0].dateList) {
        res = false;
    } else {
        res = true;
    }
    return res;
};

export default UseSchedule;
