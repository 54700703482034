import React from "react";

import UseSettings from "../../../../../../hooks/Utils/useSettings";
import UseSettingsGroup from "../../../../../../hooks/Utils/useSettingsGroup";
import SettingsBorder from "./SettingsBorder";
import SettingsRadio from "./SettingsRadio";

const Settings = (props) => {
    return <UseSettings {...props} />;
};

Settings.Group = (props) => {
    return <UseSettingsGroup {...props} />;
};

Settings.Border = (props) => {
    return <SettingsBorder {...props} />;
};

Settings.Radio = (props) => {
    return <SettingsRadio {...props} />;
};

export default Settings;
