import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const UseModalInformativeList = ({ index }) => {
    const { data } = useSelector((state) => state.ui.modalContent.inputs[index]) || {};
    const { info } = data;
    const { t } = useTranslation();

    return (
        <div className=" flex w-full">
            {info?.map((i, index) => {
                return (
                    <div className={` ${i.width} ${index !== 0 ? " ml-4" : " mr-4"} `}>
                        <div className=" bg-gray-100 text-gray-900 font-bold px-4 py-2 rounded">{t(i?.header)}</div>
                        <div className=" mt-4 ml-4" style={{ maxHeight: "20rem", overflowY: "scroll" }}>
                            {i?.values?.map((v) => {
                                return (
                                    <div className=" mb-1" key={v}>
                                        {v}
                                    </div>
                                );
                            })}
                            {i?.values?.length <= 0 ? <div className=" text-center">{t(i.noResults)}</div> : null}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default UseModalInformativeList;
