import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBatchActive } from "../../../actions/tableActions";
const ListFontsBatchActions = () => {
    const dispatch = useDispatch();
    const { checkedItems, activeFilters } = useSelector((state) => state.table);
    const { executeQuery } = useSelector((state) => state.sectionContent);
    // TODO: zip file to use with download font
    // const actionsLabels = ["download", "delete-permanently"];
    const actionsLabels = ["delete-permanently"];

    const loadBatchActions = () => {
        // no checkedItems no actions
        if (checkedItems.length === 0) return [];

        //load all possible actions
        let actions = createActions();
        return actions._list;
    };

    // Listeners
    useEffect(() => {
        dispatch(setBatchActive(loadBatchActions()));
        // eslint-disable-next-line
    }, [checkedItems.length, activeFilters.state]);

    useEffect(() => {
        dispatch(setBatchActive(loadBatchActions()));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // console.log("ejecute query");
    }, [executeQuery]);

    // Funcitons
    const createActions = () => {
        let _actions = {
            _list: [],
        };

        actionsLabels.forEach((label) => {
            // modal for batch action
            let modalInputs = [
                {
                    type: "void",
                    adjust: "first-capital",
                    text: `font-library-batch-${label}-text`,
                },
                {
                    type: "batchSelection",
                    style: "mt-0 table",
                    columnPos: 0,
                },
            ];
            modalInputs.push({
                type: "data",
                ids: checkedItems,
            });
            // include action
            _actions._list.push({
                name: `media-library-${label}`,
                action: `${label === "download" ? "meida" : "font"}-library-${label}`,
                modalInputs,
            });
        });
        return _actions;
    };
};

export default ListFontsBatchActions;
