import React from "react";
import MenuSideBar from "./MenuSideBar";
import { usePermissions } from "hooks/map";
import { UseSectionMap } from "./utils";
import Sitemap from "../../sitemap";

import { useAuth } from "hooks/Session/auth";

export default function GenericSidebar({ sectionID }) {
    const { paths } = useAuth();
    const { checkPath } = usePermissions();
    const { getItems } = UseSectionMap(checkPath);

    return <MenuSideBar menuItems={getItems(sectionID, Sitemap[sectionID], paths)} />;
}
