import React, { useEffect, useState } from "react";
import UseSectionHeader from "../../../../useSectionHeader";
import { useTranslation } from "react-i18next";
import {
    capitalizeFirst,
    checkShowTooltip,
    POLLINTERVAL_15S,
    getFromDAS,
    normalizeToSearch,
} from "../../../../../hooks/Utils/Utils";
import UseSearch from "../../../../useSearch";
import UseButton from "../../../../useButton";
import UseItemActions from "../../../../useItemActions";
import { sortableContainer, sortableElement, arrayMove } from "react-sortable-hoc";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { getTagInfo } from "../../../../../hooks/Utils/Services/ChannelsUtils";
import ReactTooltip from "components/ReactTooltip";
import { useDispatch, useSelector } from "react-redux";
import { openModal, setModalContent } from "../../../../../actions/uiActions";
import NewGroupToGrid from "../../../../../hooks/GraphqlCalls/Services/modals/NewGroupToGrid";
import UpdateGridChannels from "../../../../../hooks/GraphqlCalls/Services/UpdateGridChannels";
import { useParams } from "react-router-dom";
import { setExecuteQuery, setRefreshContentData } from "../../../../../actions/sectionActions";
import EditGridName from "../../../../../hooks/GraphqlCalls/Services/modals/EditGridName";
const GridsEdition = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { gridId } = useParams();
    const [itemsGrids, setItemsGrids] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [search, setSearch] = useState("");
    const [nameGrid, setNameGrid] = useState("");
    const [indexMapping, setIndexMapping] = useState([]);
    const { refreshData } = useSelector((state) => state.sectionContent);

    const GET_INFO = gql(`
    query{
        tuneGridsSummary(filter: { id: ${gridId}  }) {
          results {
            id
            name
            tuneGroupsNum
            tuneGroups {
              id
              name
              tunes {
                channelName
                countryRef
                id
                isRadio
                languageRef
                logoRef
                tuneInfo
                type
              }
            }
          }
        }
      }    
      `);

    const [executeQuery, { data, refetch, loading }] = useLazyQuery(GET_INFO, {
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (data?.tuneGridsSummary?.results?.length > 0) {
            const tuneGroups = addTunePosition(data.tuneGridsSummary.results[0].tuneGroups);
            setItemsGrids(tuneGroups);
            setNameGrid(data.tuneGridsSummary.results[0].name);
        }
    }, [data]);

    useEffect(() => {
        const results = [];
        const mapping = [];

        itemsGrids.forEach((item, index) => {
            if (normalizeToSearch(item.name).includes(normalizeToSearch(search))) {
                results.push(item);
                mapping.push(index);
            }
        });

        setFilteredItems(results);
        setIndexMapping(mapping);
    }, [search, itemsGrids]);

    useEffect(() => {
        if (refreshData && refetch) {
            refetch();
            dispatch(setRefreshContentData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const mappedOldIndex = indexMapping[oldIndex];
        const mappedNewIndex = indexMapping[newIndex];

        const idGroup = itemsGrids[mappedOldIndex]?.id;

        setItemsGrids((prevItems) => {
            const updatedItems = arrayMove(prevItems, mappedOldIndex, mappedNewIndex);
            return updatedItems;
        });

        if (mappedOldIndex !== mappedNewIndex) {
            dispatch(
                setExecuteQuery({
                    action: "change-position-group-in-grid",
                    params: { newIndex: mappedNewIndex, idGroup },
                })
            );
        }
    };

    return (
        <div>
            <UseSectionHeader
                navToPreviousPage={true}
                title={nameGrid}
                customBreadCrumbs={breadcrumbs}
                noTranslate={true}
                noCapitalize={true}
            ></UseSectionHeader>
            <div className=" w-full p-6 rounded bg-white">
                <div className=" w-full">
                    <span id={"edition-grids-name-channel"} className=" font-bold mr-2">
                        {nameGrid}
                    </span>
                    <button
                        onClick={() => {
                            const content = EditGridName(t, { name: nameGrid });
                            dispatch(setModalContent(content));
                            dispatch(openModal());
                        }}
                        id={"edition-grids-edit-grid-button"}
                        className=" focus:outline-none text-zafiro-600 underline"
                    >
                        {capitalizeFirst(t("edit"))}
                    </button>
                </div>
                <div className=" flex w-full border-b border-gray-200 pb-2">
                    <div className=" w-8/12 flex items-center">
                        <span>{t("add-and-sort-groups-on-the-grid")}</span>
                    </div>
                    <div className=" w-4/12 flex">
                        <UseSearch
                            id={"edition-grids-input-search"}
                            value={search}
                            onChange={(val) => {
                                setSearch(val);
                            }}
                        ></UseSearch>
                        <UseButton
                            id={"edition-grids-add-group-channel-button"}
                            buttonName={"add-group"}
                            action={() => {
                                dispatch(setModalContent(NewGroupToGrid({ gridId })));
                                dispatch(openModal());
                            }}
                            buttonColor={"btn-blue"}
                        ></UseButton>
                    </div>
                </div>
                <SortableContainer onSortEnd={onSortEnd} distance={30} helperClass="dragging" axis="xy">
                    {filteredItems.map((item, index) => {
                        return <SortableItem key={`item-${item.id}`} index={index} info={{ ...item, index: index }} />;
                    })}
                </SortableContainer>
                {filteredItems.length <= 0 && !loading ? (
                    <div className=" w-full text-center">{t("no-groups-yet")}</div>
                ) : null}
            </div>
            <UpdateGridChannels />
        </div>
    );
};

const SortableContainer = sortableContainer(({ children }) => {
    return <div className=" py-4">{children}</div>;
});

const SortableItem = sortableElement(({ info }) => {
    const { t } = useTranslation();
    const [hasImageError, setHasImageError] = useState({});
    const { gridId } = useParams();
    const [showTooltip, setShowTooltip] = useState(false);

    const items = info.tunes ? info.tunes : [];
    const prefixId = "edition-grids-sortable-group-card";

    const hasTune = (tuneInfo) => {
        const tunes = JSON.parse(tuneInfo);
        let hasTune = false;
        if (!tunes) {
            return hasTune;
        }
        Object.entries(tunes).map((tune) => (tune[1]?.isEnabled ? (hasTune = true) : null));
        return hasTune;
    };

    return (
        <div
            id={`${prefixId}-container-${info.index}`}
            className=" border bg-white overflow-visible mb-4 flex border-gray-300 rounded w-full"
            style={{ height: "11.4rem" }}
        >
            <div className=" h-full flex items-center justify-center bg-gray-200" style={{ width: "2.8rem" }}>
                <i className=" icon icon-drag-and-drop text-gray-800 text-3xl"></i>
            </div>
            <div
                className="  h-full  px-6 overflow-visible  "
                style={{ userSelect: "none", width: "calc(100% - 2.8rem)" }}
            >
                <div className=" flex border-b border-gray-200 justify-between items-center">
                    <div>
                        <span id={`${prefixId}-name-${info.index}`} className=" text-lg font-bold">
                            {info.name}
                        </span>
                    </div>
                    <div className=" h-10 flex items-center relative ">
                        <span id={`${prefixId}-number-channels-${info.index}`}>{`${info?.tunes?.length} ${t(
                            "channels_"
                        )}`}</span>
                        <div className=" -mt-2">
                            <UseItemActions
                                id={`${prefixId}-actions-${info.id}`}
                                adjustFontSize={"text-2xl"}
                                dataType={"gridChannel"}
                                executeSectionQuery={true}
                                actions={[
                                    {
                                        name: t("set-up-group"),
                                        action: "edit-channel-group",
                                        noModal: true,
                                        link: `/services/tv-channels/grids/${gridId}/group/${info.id}`,
                                        t,
                                    },
                                    {
                                        name: t("remove-from-grid"),
                                        action: "remove-grid-from-group",
                                        adjust: "text-gray-900",
                                        modal_title: "remove",
                                        executeSectionQuery: true,
                                        modal_value: info.name,
                                        modal_btn_name: "remove",
                                        t,
                                        id: info.id,
                                        mutationParams: {
                                            id: info.id,
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className=" flex w-full scrollMenuNav py-2 overflow-scroll" style={{ minHeight: "7.4rem" }}>
                    {items.map((item, idx) => {
                        const tuneIsDisabled = !hasTune(item.tuneInfo);

                        return (
                            <div
                                className=" bg-gray-900 flex flex-col items-center justify-center mr-4 relative  rounded"
                                style={{ minWidth: "7rem", maxWidth: "7rem", height: "7rem" }}
                            >
                                {tuneIsDisabled ? (
                                    <div
                                        id={`${prefixId}-disabled-channel-${info.index}-${idx}`}
                                        data-tip={t("untuned-channel")}
                                        onMouseOver={() => {
                                            ReactTooltip.rebuild();
                                        }}
                                        style={{ opacity: "0.6" }}
                                        className=" rounded z-10 bg-gray-100 absolute h-full w-full top-0 left-0"
                                    ></div>
                                ) : null}

                                <div
                                    className="flex items-center justify-center h-full relative w-full"
                                    style={{ minHeight: "5rem", maxHeight: "5rem" }}
                                >
                                    <div
                                        id={`${prefixId}-type-channel-${info.index}-${idx}`}
                                        style={{ right: "0.25rem", top: "0.25rem" }}
                                        className={` text-sm px-2  absolute text-white rounded ${
                                            getTagInfo(item).color
                                        }`}
                                    >
                                        {capitalizeFirst(t(getTagInfo(item).text))}
                                    </div>
                                    {!hasImageError[item.id] && item.logoRef ? (
                                        <img
                                            id={`${prefixId}-logo-channel-${info.index}-${idx}`}
                                            className=" pointer-events-none object-scale-down h-full w-full "
                                            onError={() => setHasImageError({ ...hasImageError, [item.id]: true })}
                                            src={getFromDAS(item.logoRef)}
                                            alt="something"
                                        ></img>
                                    ) : (
                                        <span
                                            id={`${prefixId}-not-image-channel-${info.index}-${idx}`}
                                            className=" text-4xl icon icon-channel text-gray-800 "
                                        ></span>
                                    )}
                                </div>
                                <div
                                    className=" text-white rounded flex items-center  w-full bg-gray-800 "
                                    style={{ height: "2rem" }}
                                >
                                    <span
                                        onMouseOver={(e) => {
                                            checkShowTooltip(e.currentTarget, setShowTooltip);
                                            ReactTooltip.rebuild();
                                        }}
                                        className=" px-2 z-10 truncate"
                                        id={`${prefixId}-name-channel-${info.index}-${idx}`}
                                        data-tip={showTooltip && item?.channelName ? item.channelName : ""}
                                    >
                                        {`${item.tunePosition}.- ${item.channelName}`}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                    {items.length <= 0 ? <div className=" w-full text-center">{t("no-channels-yet")}</div> : null}
                    <ReactTooltip
                        delayShow={200}
                        type={"light"}
                        borderColor={"#D3DAE1"}
                        border={true}
                        multiline={true}
                    ></ReactTooltip>
                </div>
            </div>
        </div>
    );
});

export default GridsEdition;

const breadcrumbs = [
    {
        name: "services",
        translate: true,
        route: "/services",
    },
    {
        name: "tv-channels",
        translate: true,
        route: "/services/tv-channels/",
    },
    {
        name: "grids_",
        translate: true,
        route: "/services/tv-channels/grids",
    },
];
function addTunePosition(data) {
    let clonedData = JSON.parse(JSON.stringify(data));
    let positionCounter = 1;

    for (const group of clonedData) {
        for (const tune of group.tunes) {
            tune.tunePosition = positionCounter++;
        }
    }

    return clonedData;
}
