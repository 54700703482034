import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { validateModal } from "../../Utils/Validations";
import { toast } from "react-toastify";
import { getMutation } from "../CommonZones/modals/modalMutationDispatcher";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { useNavigate as useHistory } from "react-router-dom";
import { withApollo } from "@apollo/react-hoc";

// Actions
import { changeActionValues, setActionValidations, cleanActionValidations } from "../../../actions/globalActions";
import { showGlobalLoading } from "../../../actions/uiActions";
import { useTranslation } from "react-i18next";
import { removeApolloCacheKeys } from "../../Utils/Utils";
import { setExecuteQuery } from "actions/sectionActions";
import { cleanAction } from "../../../actions/globalActions";
import { setRefreshContentData } from "actions/sectionActions";

const AddNewGuest = ({ client, backToRoomDetail }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const actionData = useSelector((state) => state.action);
    const history = useHistory();
    // Mutation
    const [mutation, setMutation] = useState(`mutation{
            fake{ error id ok}}`);
    const DYNAMIC_MUTATION = gql`
        ${mutation}
    `;
    const [executeMutation, { data }] = useMutation(DYNAMIC_MUTATION, {
        onCompleted() {
            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            dispatch(setRefreshContentData(true));
        },
        onError(err) {
            console.log(err);
            dispatch(showGlobalLoading(false));
            toast.error(t("mutation-error"));
        },
    });
    const { save } = actionData.values;
    useEffect(() => {
        if (save) {
            const { validations, avoidDefError } = validateModal("new-guest", actionData, null, t);
            const validationsArray = Object.values(validations);
            if (validationsArray.filter((item) => item === false).length > 0) {
                dispatch(setActionValidations(validations));
                if (!avoidDefError) {
                    toast.error(t("errors-in-red"));
                }
                dispatch(changeActionValues({ save: false }));
                return;
            } else {
                dispatch(showGlobalLoading(true));
                dispatch(cleanActionValidations());
                setMutation(
                    getMutation({
                        ...actionData,
                        actionName: "new-guest",
                    })
                );
                dispatch(showGlobalLoading(false));
            }

            dispatch(changeActionValues({ save: false }));
        }
        // eslint-disable-next-line
    }, [save]);

    useEffect(() => {
        if (!mutation.includes("fake")) {
            executeMutation();
        }
        // eslint-disable-next-line
    }, [mutation]);

    useEffect(() => {
        if (data && data.checkIn && data.checkIn.id) {
            removeApolloCacheKeys(client.cache, ["guests", "installationMap"]);

            toast.success(t("operation-successful"));
            if (backToRoomDetail) {
                removeApolloCacheKeys(client.cache, "stays");
                backToRoomDetail();
            } else {
                history(`/customers/guests/guest-list/guest/${data.checkIn.id}`);
            }
        }
        // eslint-disable-next-line
    }, [data]);

    return <></>;
};

export default withApollo(AddNewGuest);
