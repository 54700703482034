export const CZCleaning = [
    "all",
    "areas",
    "area-sym-activity",
    "available",
    "cancel",
    "category",
    "cleaning",
    "cleaning-update",
    "common-zones",
    "items",
    "last-cleaning",
    "name",
    "next",
    "ofo",
    "previous",
    "responsible",
    "save",
    "search",
    "select",
    "theres-not",
    "update",
    "was-performed",
    "wich-performed",
    "mutation-error",
    "errors-in-red",
    "showing-all-results",
    "future-date-no-valid",
    "cleaning success",
    "select who cleaning name",
];
