import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

import { ShopContext } from "contexts/Sales/Shop";

import { cleanAction } from "actions/globalActions";

import UseSectionHeader from "components/useSectionHeader";
import Loading from "components/Loading";

import OrdersSettings from "components/Section/Services/Sales/components/ShopSettings/ShopSettingsOrdersDelivery";
import CurrencySettings from "components/Section/Services/Sales/components/ShopSettings/ShopSettingsCurrencyAndTaxes";
import ImageSettings from "components/Section/Services/Sales/components/ShopSettings/ShopSettingsImage";
import DeliveryHoursSettings from "components/Section/Services/Sales/components/ShopSettings/ShopSettingsDeliveryHour";
import EmailsSettings from "components/Section/Services/Sales/components/ShopSettings/ShopSettingsEmail";

const Settings = () => {
    const dispatch = useDispatch();

    const { loading, syncId } = useContext(ShopContext);

    useEffect(() => {
        return () => {
            dispatch(cleanAction());
        };
    }, []);

    return (
        <>
            <UseSectionHeader title={"shop-settings"} hideBreadcrumbs={true} />
            <div className="grid grid-cols-2 gap-4">
                {loading ? (
                    <Loading adjust="full" />
                ) : (
                    <>
                        <OrdersSettings />
                        <div className="grid grid-cols-1 gap-4">
                            <CurrencySettings disabled={!!syncId} />
                            <ImageSettings />
                            <EmailsSettings />
                        </div>
                        <DeliveryHoursSettings />
                    </>
                )}
            </div>
        </>
    );
};

export default Settings;
