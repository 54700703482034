import React, { useState } from "react";

import Toggle from "components/Inputs/Toggle";

export const Radiobutton = (props) => {
    return <Toggle design="radio" uncheck={false} {...props} />;
};

const Radiobuttons = ({ checked, onChange, options, disabled, className }) => {
    const [selected, setSelected] = useState(checked);

    return options
        .filter((option) => option)
        .map((option) => {
            const optionValue = typeof option === "object" ? option.value : option;
            const label = option?.label === undefined ? optionValue : option?.label;
            return (
                <Radiobutton
                    key={optionValue}
                    label={label}
                    value={optionValue}
                    checked={selected === optionValue}
                    className={className}
                    disabled={disabled ?? option?.disabled}
                    onChange={({ checked, value }) => {
                        if (checked) {
                            setSelected(value);
                            if (onChange) {
                                onChange(value);
                            }
                        }
                    }}
                />
            );
        });
};

export default Radiobuttons;
