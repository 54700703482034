import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { setExecuteQuery } from "../../../../../actions/sectionActions";
import { useMSQuery } from "../../../../../hooks/GraphqlCalls/useMSQuery";
import { gql } from "apollo-boost";
import { setRefreshData } from "../../../../../actions/tableActions";
import UseSwitch from "../../../../Inputs/useSwitch";

function ChannelsGeneralSettingsRememberLastCard() {
    //Consts&states
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { refreshData } = useSelector((state) => state.sectionContent);
    const [rememberLastChannel, setRememberLastChannel] = useState(false);
    const action = "channel-update-remember-last";

    //queries&mutations
    const GET_DATA = gql`
        {
            tvSetting: tvSettings {
                rememberLastTvChannel
            }
        }
    `;

    //useQueries&useMutations
    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_DATA);

    //effects
    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (!loading && !error && networkStatus === 7 && data && data.tvSetting) {
            setRememberLastChannel(arrangeData({ tvSetting: data.tvSetting }));
        }
    }, [data, networkStatus]);

    useEffect(() => {
        if (error) {
            toast.error(t("error-mutation"));
        }
    }, [error]);

    useEffect(() => {
        if (refreshData) {
            dispatch(setRefreshData(false));
            refetch();
        }
        // eslint-disable-next-line
    }, [refreshData]);

    //arrangeData
    const arrangeData = (props) => {
        const { tvSetting } = props;
        let response = false;
        if (tvSetting && tvSetting.rememberLastTvChannel) {
            response = tvSetting.rememberLastTvChannel;
        }
        return response;
    };

    //handle&Functions
    const handleChangeRememberLastChannel = (value) => {
        dispatch(
            setExecuteQuery({
                action: action,
                params: {
                    value: value,
                },
            })
        );
    };

    //renders
    return (
        <div className="w-full">
            <div className="w-full flex items-center justify-between mb-5">
                <span className="font-bold text-gray-900 text-2xl">{t("remember-last-channel")}</span>
                <UseSwitch
                    disabled={loading}
                    id={"remember-last-channel-switch"}
                    checked={rememberLastChannel}
                    action={(value) => handleChangeRememberLastChannel(value)}
                    adjust={"flex"}
                />
            </div>
            <div className="w-full text-left">
                <span className="block">{t("remember-last-channel-text")}</span>
            </div>
            <div className="w-full text-left my-3 flex">
                <i className="icon icon-warning text-orange-100 text-1xl mr-2" />
                <span>{t("remember-last-channel-not-apply")}</span>
            </div>
        </div>
    );
}

export default ChannelsGeneralSettingsRememberLastCard;
