import { capitalizeFirst, uncapitalizeFirst } from "../../../Utils/Utils";

const PreviewCorporateChannel = (t, data) => {
    return {
        title: capitalizeFirst(t("preview")),
        value: data?.dataChannel?.name ? data.dataChannel.name : "",
        executeSectionQuery: true,
        bodyAdjust: "w-full",
        noCapitalizeTitle: true,
        inputs: [
            {
                type: "previewChannel",
                data: data ? data : data,
            },
        ],
        buttons: [{ name: "close", style: "blue", action: "close" }],
    };
};

export default PreviewCorporateChannel;
