import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { changeActionValues, setActionInput, setActionValidations } from "../../actions/globalActions";
import UseInputText from "../Table/useInputText";
import { gql } from "apollo-boost";
import { useMutation } from "react-apollo";
import { useLazyQuery } from "react-apollo";
import { capitalizeFirst } from "../../hooks/Utils/Utils";
import UseSelect from "../Inputs/useSelect";
import UseComponentLoading from "../useComponentLoading";
import UseRadio from "../useRadio";
import UseInputNumber from "../Table/useInputNumber";
import ReactTooltip from "components/ReactTooltip";

const UseModalNewPasscode = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { values, actionData, validations } = useSelector((state) => state.action);

    const PASSCODE = "passcode-code";
    const ACCESS_PROFILE = "passcode-access-profile";
    const LIMIT_OF_DEVICES = "passcode-limit-of-devices";
    const LIMIT_OF_DEVICES_DAYS = "passcode-limit-of-devices-days";
    const LIMIT_OF_DEVICES_HOURS = "passcode-limit-of-devices-hours";
    const RENOVATION_DAYS = "passcode-renovation-days";
    const RENOVATIONS_HOURS = "passcode-renovation-hours";
    const LIMIT_OF_DEVICES_OPTS = ["unlimited", "customised"];
    const LIMIT_OF_DURARION_OPTS = ["unlimited", "customised"];

    const CHANGE_PASSCODE = gql`
        mutation {
            createAccessCode {
                error
                ok
                code
            }
        }
    `;

    const GET_ACCESS_PROFILE = gql`
        query {
            wifiAccessProfiles {
                results {
                    id
                    name
                    downloadbwmax
                    downloadbwmin
                    uploadbwmin
                    uploadbwmax
                }
            }
        }
    `;

    const [executeMutation, { data: dataPasscode }] = useMutation(CHANGE_PASSCODE, {
        onError(err) {
            console.log(err);
        },
    });

    const [executeQuery, { data: dataAccessProfiles }] = useLazyQuery(GET_ACCESS_PROFILE, {
        onError(err) {
            console.log(err);
        },
    });

    const [passcode, setPasscode] = useState("");
    const [optionsDataProfiles, setOptionsDataProfiles] = useState([]);
    const [infoProfiles, setInfoDataProfiles] = useState(null);
    const [showModalLoading, setShowModalLoading] = useState(true);
    const [limitOfDevices, setLimitOfDevices] = useState(LIMIT_OF_DEVICES_OPTS[1]);
    const [limitOfDuration, setLimitOfDuration] = useState(LIMIT_OF_DURARION_OPTS[1]);

    const accessProfileId =
        (values && values[ACCESS_PROFILE]) || values[ACCESS_PROFILE] === 0 ? values[ACCESS_PROFILE] : null;
    const limitOfDevicesVal = actionData && actionData[LIMIT_OF_DEVICES] ? actionData[LIMIT_OF_DEVICES] : null;
    const limitOfDevicesDaysVal =
        actionData && actionData[LIMIT_OF_DEVICES_DAYS] ? actionData[LIMIT_OF_DEVICES_DAYS] : null;
    const limitOfDevicesHoursVal =
        actionData && actionData[LIMIT_OF_DEVICES_HOURS] ? actionData[LIMIT_OF_DEVICES_HOURS] : null;
    const renovationDaysVal = actionData && actionData[RENOVATION_DAYS] ? actionData[RENOVATION_DAYS] : null;
    const renovationHoursVal = actionData && actionData[RENOVATIONS_HOURS] ? actionData[RENOVATIONS_HOURS] : null;

    useEffect(() => {
        executeMutation();
        executeQuery();
        setTimeout(() => {
            setShowModalLoading(false);
        }, 500);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (dataPasscode && dataPasscode.createAccessCode && dataPasscode.createAccessCode.code) {
            setPasscode(dataPasscode.createAccessCode.code);
        }
        //eslint-disable-next-line
    }, [dataPasscode]);

    useEffect(() => {
        if (
            dataAccessProfiles &&
            dataAccessProfiles.wifiAccessProfiles &&
            dataAccessProfiles.wifiAccessProfiles.results
        ) {
            const arrangeDataProfiles = [];
            const arrangeDataProfilesInfo = {};
            let noRepValues = {};

            // eslint-disable-next-line
            dataAccessProfiles.wifiAccessProfiles.results.map((item) => {
                if (item.id && item.name && !noRepValues[item.id]) {
                    arrangeDataProfiles.push({ value: String(item.id), text: item.name });
                    arrangeDataProfilesInfo[item.id] = {
                        name: item.name,
                        dbwmax: item.downloadbwmax && Number(item.downloadbwmax) > 0 ? Number(item.downloadbwmax) : "-",
                        dbwmin: item.downloadbwmin && Number(item.downloadbwmin) > 0 ? Number(item.downloadbwmin) : "-",
                        ubwmax: item.uploadbwmax && Number(item.uploadbwmax) > 0 ? Number(item.uploadbwmax) : "-",
                        ubwmin: item.uploadbwmin && Number(item.uploadbwmin) > 0 ? Number(item.uploadbwmin) : "-",
                    };
                    noRepValues[item.id] = true;
                }
            });

            setInfoDataProfiles(arrangeDataProfilesInfo);
            setOptionsDataProfiles(arrangeDataProfiles);
        }
        //eslint-disable-next-line
    }, [dataAccessProfiles]);

    // console.log(infoProfiles);

    useEffect(() => {
        changeInputNumberValueByRadioOption(
            LIMIT_OF_DEVICES,
            "0",
            limitOfDevicesVal,
            LIMIT_OF_DEVICES_OPTS,
            limitOfDevices
        );
        //eslint-disable-next-line
    }, [limitOfDevicesVal, limitOfDevices]);

    useEffect(() => {
        changeInputNumberValueByRadioOption(
            LIMIT_OF_DEVICES_DAYS,
            "0",
            limitOfDevicesDaysVal,
            LIMIT_OF_DURARION_OPTS,
            limitOfDuration
        );
        //eslint-disable-next-line
    }, [limitOfDevicesDaysVal, limitOfDuration]);

    useEffect(() => {
        changeInputNumberValueByRadioOption(
            LIMIT_OF_DEVICES_HOURS,
            "0",
            limitOfDevicesHoursVal,
            LIMIT_OF_DURARION_OPTS,
            limitOfDuration
        );
        //eslint-disable-next-line
    }, [limitOfDevicesHoursVal, limitOfDuration]);

    useEffect(() => {
        changeInputNumberValueByRadioOption(
            RENOVATION_DAYS,
            "0",
            renovationDaysVal,
            LIMIT_OF_DURARION_OPTS,
            limitOfDuration,
            true
        );
        //eslint-disable-next-line
    }, [renovationDaysVal, limitOfDuration]);

    useEffect(() => {
        changeInputNumberValueByRadioOption(
            RENOVATIONS_HOURS,
            "0",
            renovationHoursVal,
            LIMIT_OF_DURARION_OPTS,
            limitOfDuration,
            true
        );
        //eslint-disable-next-line
    }, [renovationHoursVal, limitOfDuration]);

    useEffect(() => {
        if (limitOfDuration === LIMIT_OF_DURARION_OPTS[0]) {
            changeInputNumberValueByRadioOption(
                LIMIT_OF_DEVICES_DAYS,
                "0",
                limitOfDevicesDaysVal,
                LIMIT_OF_DURARION_OPTS,
                limitOfDuration,
                true,
                true
            );
            changeInputNumberValueByRadioOption(
                LIMIT_OF_DEVICES_HOURS,
                "0",
                limitOfDevicesHoursVal,
                LIMIT_OF_DURARION_OPTS,
                limitOfDuration,
                true,
                true
            );
        } else if (limitOfDuration === LIMIT_OF_DURARION_OPTS[1]) {
            changeInputNumberValueByRadioOption(
                RENOVATION_DAYS,
                "0",
                renovationDaysVal,
                LIMIT_OF_DURARION_OPTS,
                limitOfDuration,
                true,
                true
            );
            changeInputNumberValueByRadioOption(
                RENOVATIONS_HOURS,
                "0",
                renovationHoursVal,
                LIMIT_OF_DURARION_OPTS,
                limitOfDuration,
                true,
                true
            );
        }
        if (
            validations &&
            validations[LIMIT_OF_DEVICES_DAYS] === false &&
            validations[LIMIT_OF_DEVICES_HOURS] === false
        ) {
            const newValidations = validations;
            delete newValidations[LIMIT_OF_DEVICES_DAYS];
            delete newValidations[LIMIT_OF_DEVICES_HOURS];
            dispatch(setActionValidations(newValidations));
        }

        //eslint-disable-next-line
    }, [limitOfDuration]);

    useEffect(() => {
        if (actionData && actionData[PASSCODE] !== passcode) {
            changeInputTextValue(PASSCODE, actionData[PASSCODE]);
        }
        //eslint-disable-next-line
    }, [actionData]);

    useEffect(() => {
        changeInputTextValue(PASSCODE, passcode);
        //eslint-disable-next-line
    }, [passcode]);

    const changeInputTextValue = (property, newValue) => {
        if (values && values[property]) {
            dispatch(changeActionValues({ [property]: newValue }));
            const changeInput = document.querySelector("#" + property);
            if (changeInput) {
                changeInput.value = newValue;
            }
        } else {
            dispatch(changeActionValues({ [property]: newValue }));
        }
    };

    const changeInputNumberValueByRadioOption = (
        property,
        defValue,
        currentValue,
        optVal,
        optCurrVal,
        noDefVal,
        resetVal
    ) => {
        if (resetVal) {
            dispatch(changeActionValues({ [property]: defValue }));
            dispatch(setActionInput({ [property]: defValue }));
        } else {
            if (optCurrVal === optVal[0] && !noDefVal) {
                dispatch(changeActionValues({ [property]: defValue }));
            } else if (optCurrVal === optVal[0]) {
                if (currentValue === null) {
                    dispatch(changeActionValues({ [property]: defValue }));
                } else if (currentValue) {
                    dispatch(changeActionValues({ [property]: currentValue }));
                }
            } else if (optCurrVal === optVal[1]) {
                if (currentValue === null) {
                    dispatch(changeActionValues({ [property]: defValue }));
                } else if (currentValue) {
                    dispatch(changeActionValues({ [property]: currentValue }));
                }
            }
        }
        const optionSelected = property + "-option-selected";
        dispatch(changeActionValues({ [optionSelected]: optCurrVal }));
    };

    const renderLoading = () => {
        return (
            <div className="absolute bg-white top-0 left-0 h-full w-full">
                <UseComponentLoading />
            </div>
        );
    };

    const showErrorPasscode = () => {
        const selector = document.querySelector("#" + PASSCODE);
        const errorsToShow = [];
        if (selector) {
            if (selector.value.length < 4) {
                errorsToShow.push("minimum");
            }
            if (/[^a-zA-Z0-9]/gim.test(selector.value)) {
                errorsToShow.push("specialchars");
            }
        }
        return errorsToShow;
    };

    const getSpecialCharacters = () => {
        const selector = document.querySelector("#" + PASSCODE);
        const specialChars = [];
        if (selector) {
            selector.value.split("").forEach((element) => {
                if (/[^a-zA-Z0-9]/gim.test(element)) {
                    specialChars.push(element);
                }
            });
        }

        return specialChars.join();
    };

    return (
        <div className=" text-gray-900 relative">
            <div>
                <h3 className=" font-bold text-base">{capitalizeFirst(t("passcode"))}</h3>
                <div className=" flex w-5/12 items-center mt-2 ">
                    <div>
                        <div className=" flex items-center">
                            <div className=" relative ">
                                <UseInputText
                                    inputData={{ name: PASSCODE }}
                                    validation={validations[PASSCODE] === false ? false : true}
                                    currentValue={passcode}
                                    id={PASSCODE}
                                    key={passcode}
                                />
                                {validations[PASSCODE] === false ? (
                                    <div
                                        className=" icon icon-alert text-red-100 text-2xl absolute"
                                        style={{ top: "0.5rem", right: "1rem" }}
                                    ></div>
                                ) : null}
                            </div>
                            <div
                                className=" ml-2 icon cursor-pointer icon-refresh text-zafiro-600 text-3xl  "
                                onClick={() => {
                                    executeMutation();
                                }}
                            ></div>
                        </div>
                        {validations[PASSCODE] === false && showErrorPasscode().includes("minimum") ? (
                            <div className=" text-red-100 mt-2">
                                {capitalizeFirst(t("minimum")) + " " + 4 + " " + t("characters")}
                            </div>
                        ) : null}
                        {validations[PASSCODE] === false && showErrorPasscode().includes("specialchars") ? (
                            <div className=" text-red-100 mt-2">
                                {"(" + getSpecialCharacters() + ")" + t("unsupported")}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className=" mt-6">
                <h3 className=" font-bold text-base">{capitalizeFirst(t("access-profile"))}</h3>
                <div className=" flex w-5/12 items-center mt-2">
                    <UseSelect
                        key={optionsDataProfiles}
                        selectData={{
                            id: ACCESS_PROFILE,
                            data: optionsDataProfiles,
                            activeValue: optionsDataProfiles.length > 0 ? optionsDataProfiles[0].value : null,
                            inputContainerAdjust: "h-10",
                            inputIconContainerAdjust: "text-sm",
                        }}
                    />
                    <div className=" ml-2 icon opacity-0 icon-refresh-cw  text-2xl font-bold "></div>
                </div>
            </div>
            {infoProfiles && infoProfiles[accessProfileId] ? (
                <div className=" mt-2">
                    <h3 className=" text-base">{capitalizeFirst(t("access-profile-information"))} :</h3>
                    <div className=" grid grid-cols-1 grid-rows-2 w-4/6 mt-2 ">
                        <div className=" grid grid-cols-2 mt-2">
                            <span style={{ minWidth: "7rem" }}>{capitalizeFirst(t("min-bandwidth"))}:</span>
                            <div className=" flex justify-start items-center" style={{ width: "95%" }}>
                                <div className=" flex justify-start items-center " style={{ minWidth: "7rem" }}>
                                    <span className="icon icon-maximum text-2xl "></span>
                                    <span>{`${infoProfiles[accessProfileId]["ubwmin"]} Mb/s`}</span>
                                </div>
                                <div className=" flex justify-start items-center " style={{ minWidth: "7rem" }}>
                                    <span className=" icon icon-minimum text-2xl"></span>
                                    <span>{`${infoProfiles[accessProfileId]["dbwmin"]} Mb/s`} </span>
                                </div>
                            </div>
                        </div>
                        <div className=" grid grid-cols-2 mt-2">
                            <span style={{ minWidth: "7rem" }}>{capitalizeFirst(t("max-bandwidth"))}:</span>
                            <div className=" flex justify-start items-center" style={{ width: "95%" }}>
                                <div className=" flex justify-start items-center" style={{ minWidth: "7rem" }}>
                                    <span className="icon icon-maximum text-2xl "></span>
                                    <span>{`${infoProfiles[accessProfileId]["ubwmax"]} Mb/s`}</span>
                                </div>
                                <div className=" flex justify-start items-center " style={{ minWidth: "7rem" }}>
                                    <span className=" icon icon-minimum text-2xl"></span>
                                    <span>{`${infoProfiles[accessProfileId]["dbwmax"]} Mb/s`} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            <div className=" mt-6">
                <h3 className=" font-bold text-base mb-2">{capitalizeFirst(t("limit-of-devices"))}</h3>
                <div className=" mb-3 cursor-pointer" onClick={() => setLimitOfDevices(LIMIT_OF_DEVICES_OPTS[0])}>
                    <UseRadio
                        id={"limit-of-devices-unlimited-radio"}
                        selected={limitOfDevices}
                        value={LIMIT_OF_DEVICES_OPTS[0]}
                    />
                    <div className="first-capital ml-6 -mt-1">{t("unlimited")}</div>
                </div>
                <div className=" mb-3 cursor-pointer" onClick={() => setLimitOfDevices(LIMIT_OF_DEVICES_OPTS[1])}>
                    <UseRadio
                        id={"limit-of-devices-customised-radio"}
                        selected={limitOfDevices}
                        value={LIMIT_OF_DEVICES_OPTS[1]}
                    />
                    <div className="first-capital ml-6 -mt-1">{t("customised")}</div>
                </div>
                {limitOfDevices === LIMIT_OF_DEVICES_OPTS[1] ? (
                    <div className=" ml-6">
                        <UseInputNumber
                            id={`${LIMIT_OF_DEVICES}-input-number`}
                            inputData={{ name: LIMIT_OF_DEVICES }}
                            maxNumber={9999}
                            currentValue={1}
                            valueHandler={(e) => {
                                e.target.value = Math.abs(e.target.value);
                            }}
                            validation={validations[LIMIT_OF_DEVICES] === false ? false : true}
                        />
                    </div>
                ) : null}
            </div>
            <div className=" mt-6">
                <h3 className=" font-bold text-base mb-2">{capitalizeFirst(t("duration"))}</h3>
                <div className=" mb-2 cursor-pointer" onClick={() => setLimitOfDuration(LIMIT_OF_DURARION_OPTS[0])}>
                    <UseRadio
                        id={"limit-of-duration-unlimited-radio"}
                        selected={limitOfDuration}
                        value={LIMIT_OF_DURARION_OPTS[0]}
                    />
                    <div className=" flex flex-col w-2/4 items-start -mt-1 ml-6">
                        <div className="first-capital">{t("unlimited-fem")}</div>
                        {limitOfDuration === LIMIT_OF_DURARION_OPTS[0] ? (
                            <div className=" flex mt-2">
                                <div>{capitalizeFirst(t("set-connection-time"))}</div>
                                <div
                                    data-tip={capitalizeFirst(t("user-re-enter-passcode"))}
                                    className=" icon icon-info text-gray-800 text-2xl ml-2 "
                                ></div>
                                <ReactTooltip
                                    place="bottom"
                                    type="light"
                                    offset={{ top: -8, left: -8 }}
                                    html={true}
                                    border={true}
                                    multiline={true}
                                    borderColor="#D3DAE1"
                                />
                            </div>
                        ) : null}
                    </div>
                </div>

                {limitOfDuration === LIMIT_OF_DURARION_OPTS[0] ? (
                    <div className=" flex ml-6 mb-3 w-2/5 ">
                        <div>
                            <div className=" pb-2">{capitalizeFirst(t("days"))}</div>
                            <div>
                                <UseInputNumber
                                    id={`${RENOVATION_DAYS}-input-number`}
                                    inputData={{ name: RENOVATION_DAYS }}
                                    maxNumber={9999}
                                    currentValue={1}
                                    valueHandler={(e) => (e.target.value = Math.abs(e.target.value))}
                                    validation={validations[LIMIT_OF_DEVICES_DAYS] === false ? false : true}
                                />
                            </div>
                        </div>
                        <div className=" ml-6">
                            <div className=" pb-2">{capitalizeFirst(t("hours"))}</div>
                            <div>
                                <UseInputNumber
                                    id={`${RENOVATIONS_HOURS}-input-number`}
                                    inputData={{ name: RENOVATIONS_HOURS }}
                                    maxNumber={9999}
                                    currentValue={0}
                                    valueHandler={(e) => (e.target.value = Math.abs(e.target.value))}
                                    validation={validations[LIMIT_OF_DEVICES_HOURS] === false ? false : true}
                                />
                            </div>
                        </div>
                    </div>
                ) : null}

                <div className=" mb-3 cursor-pointer" onClick={() => setLimitOfDuration(LIMIT_OF_DURARION_OPTS[1])}>
                    <UseRadio
                        id={"limit-of-duration-customised-radio"}
                        selected={limitOfDuration}
                        value={LIMIT_OF_DURARION_OPTS[1]}
                    />
                    <div className=" flex flex-col w-2/4 items-start -mt-1 ml-6">
                        <div className="first-capital">{t("customised-the-code-expire")}</div>
                        {limitOfDuration === LIMIT_OF_DURARION_OPTS[1] ? (
                            <div className=" flex mt-2">
                                <div>{capitalizeFirst(t("set-expiration-tim"))}</div>
                                <div
                                    data-tip={capitalizeFirst(t("code-will-expire-elapsed-first-use"))}
                                    className=" icon icon-info text-gray-800 text-2xl ml-2 "
                                ></div>
                                <ReactTooltip
                                    place="bottom"
                                    type="light"
                                    offset={{ top: -8, left: -8 }}
                                    html={true}
                                    border={true}
                                    multiline={true}
                                    borderColor="#D3DAE1"
                                />
                            </div>
                        ) : null}
                    </div>
                </div>

                {limitOfDuration === LIMIT_OF_DURARION_OPTS[1] ? (
                    <div className=" flex ml-6 mb-3 w-2/5 ">
                        <div>
                            <div className=" pb-2">{capitalizeFirst(t("days"))}</div>
                            <div>
                                <UseInputNumber
                                    id={`${"limit-of-duration-days"}-input-number`}
                                    inputData={{ name: LIMIT_OF_DEVICES_DAYS }}
                                    maxNumber={9999}
                                    currentValue={1}
                                    valueHandler={(e) => (e.target.value = Math.abs(e.target.value))}
                                    validation={validations[LIMIT_OF_DEVICES_DAYS] === false ? false : true}
                                />
                            </div>
                        </div>
                        <div className=" ml-6">
                            <div className=" pb-2">{capitalizeFirst(t("hours"))}</div>
                            <div>
                                <UseInputNumber
                                    id={`${"limit-of-duration-hours"}-input-number`}
                                    inputData={{ name: LIMIT_OF_DEVICES_HOURS }}
                                    maxNumber={9999}
                                    currentValue={0}
                                    valueHandler={(e) => (e.target.value = Math.abs(e.target.value))}
                                    validation={validations[LIMIT_OF_DEVICES_HOURS] === false ? false : true}
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
            {showModalLoading && renderLoading()}
        </div>
    );
};

export default UseModalNewPasscode;
