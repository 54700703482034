import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import { setExecuteQuery, setRefreshContentData, setSectionContent } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { toast } from "react-toastify";

import { gql } from "apollo-boost";
import { capitalizeFirst, removeApolloCacheKeys } from "../../Utils/Utils";
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { cleanAction } from "../../../actions/globalActions";
import { withApollo } from "@apollo/react-hoc";
import { useNavigate as useHistory } from "react-router-dom";
import { Session } from "hooks/Utils/Session";

import { GlobalContext } from "contexts/Global";

const UpdateBulkChannel = ({ client }) => {
    const { t } = useTranslation();
    const { token } = useContext(GlobalContext);
    const dispatch = useDispatch();
    const history = useHistory();
    const { executeQuery, actionOnChannelNotFound } = useSelector((state) => state.sectionContent);

    //State
    const [mutation, setMutation] = useState("mutation{checkIn}");

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        errorPolicy: "all",
        onError(err) {
            //this control is in this onError, because errorPolicy =all is not working like in queryes
            //Custom errors
            // let validations = {};
            let errorMsg = err.message;
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(errorMsg);
        },
    });

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            switch (executeQuery.action) {
                case "create-preset-bulk-channels":
                    execute = true;
                    const presetChannels = executeQuery?.params?.channels ? executeQuery.params.channels : [];
                    setMutation(`
                        mutation{
                        createPredefinedTunesBatch(predefinedTunes:[${buildMutationPresetChannels(presetChannels)}]){
                            error
                            id
                            ok
                        }
                        }
                    `);
                    executeQuery.mutationName = "createPredefinedTunesBatch";
                    break;
                case "create-customised-bulk-channels":
                    execute = true;
                    const customisedChannels = executeQuery?.params?.channels ? executeQuery.params.channels : [];
                    setMutation(`
                        mutation{
                            createCustomTunesBatch(customTunes:[${buildMutationCustomisedChannels(
                                customisedChannels
                            )}]){
                            error
                            id
                            ok
                        }
                        }
                    `);
                    executeQuery.mutationName = "createCustomTunesBatch";
                    break;
                case "import-dvb-channels":
                    execute = true;
                    setMutation(`
                        mutation{
                            importDvbChannels(channels:[${buildMutationDvbChannels(
                                executeQuery?.params,
                                actionOnChannelNotFound
                            )}]){
                            error
                            id
                            ok
                        }
                        }
                    `);
                    executeQuery.mutationName = "importDvbChannels";
                    break;
                case "upload-dvb-channels-file":
                    dispatch(showGlobalLoading(true));
                    const INPUT_ID = "channelMapInput";
                    const FILE_TYPE = "channelMap";
                    const channelsMapFile = document.getElementById(INPUT_ID).files[0];
                    if (!channelsMapFile) {
                        toast.error("errors-in-red");
                        return;
                    }

                    const uploadUrl = Session.getUploadUrl(
                        `channelMap/?type=${FILE_TYPE}&token=${token?.replace("Bearer ", "")}`
                    );
                    const formData = new FormData();
                    formData.append("file", channelsMapFile);
                    formData.append("fileName", channelsMapFile.name);
                    fetch(uploadUrl, {
                        method: "POST",
                        body: formData,
                    })
                        .then((response) => response.json())
                        .then((resp) => {
                            dispatch(showGlobalLoading(false));
                            if (resp.error) {
                                document.getElementById("file-0") &&
                                    (document.getElementById("file-0").style.color = "red");
                                toast.error(t("unrecognised-file"));
                                return;
                            }
                            dispatch(setModalContent(false));
                            dispatch(closeModal());
                            dispatch(setExecuteQuery(null));

                            dispatch(
                                setSectionContent({
                                    parsedFileName: channelsMapFile.name,
                                    parsedChannels: resp.ParsedChannels,
                                    predefinedChannels: resp.PredefinedChannels,
                                    tunedChannels: resp.TunedChannels,
                                })
                            );
                            history("/services/tv-channels/channels/import");
                        });

                    break;
                default:
                    break;
            }
        }
        if (execute) {
            if (!showGlobalLoading) {
                dispatch(setLoading(true));
            }
            if (!executeQuery.hideLoading) {
                dispatch(showGlobalLoading(true));
            }

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].isOk || data[executeQuery.action].ok)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].isOk || data[executeQuery.mutationName].ok)))
        ) {
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }
            if (!executeQuery.params || (executeQuery.params && !executeQuery.params.noCleanAction)) {
                dispatch(cleanAction());
            }

            if (!executeQuery.preventToast) {
                toast.success(capitalizeFirst(t(executeQuery.customToast || "operation-successful")));
            }
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            removeApolloCacheKeys(client.cache, "all");
            history("/services/tv-channels/channels/");
        } else if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].errorMsg || data[executeQuery.action].error)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].errorMsg || data[executeQuery.mutationName].error)))
        ) {
            const errorMsg =
                data[executeQuery.action] && data[executeQuery.action].error
                    ? data[executeQuery.action].error.msg
                        ? data[executeQuery.action].error.msg
                        : data[executeQuery.action].error
                    : data[executeQuery.mutationName] && data[executeQuery.mutationName].error
                    ? data[executeQuery.mutationName].error
                    : null;

            toast.error(errorMsg ? errorMsg : t("mutation-error"));
            dispatch(showGlobalLoading(false));
            dispatch(setExecuteQuery(null));
            if (errorMsg !== "Code already in use!") {
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
            }
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateBulkChannel);

const generateTuneInfo = (c) => {
    const tuneInfo = {};
    if (c.ip && c.port) {
        tuneInfo.udp = {
            ip: c.ip,
            port: Number(c.port),
            isEnabled: !!c.udpCB,
        };
    }
    if (c.hls) {
        tuneInfo.hls = {
            url: c.hls,
            isEnabled: !!c.hlsCB,
        };
    }
    if (!tuneInfo.udp && !tuneInfo.hls) {
        return null;
    }

    return tuneInfo;
};

const buildMutationPresetChannels = (channels) => {
    let textToMutation = "";
    channels.forEach((c) => {
        const tuneInfo = generateTuneInfo(c);
        textToMutation += `
            {
                tvChannelID: ${c.idChannel} , 
                tuneInfo:"${JSON.stringify(tuneInfo).replace(/"/g, '\\"')}" 
            },
        `;
    });

    return textToMutation;
};

const buildMutationDvbChannels = (params, actionOnChannelNotFound) => {
    let textToMutation = "";
    const tuneType = params.channels?.[0]?.TuneType;
    params.channels.forEach((c) => {
        if (c.PredefinedTvChannel) {
            const tuneInfo = {};
            let newTuneInfo = JSON.parse(c.TuneData);
            newTuneInfo._parsedChannelName = c.Name;
            tuneInfo[c.TuneType] = newTuneInfo;

            textToMutation += `
            {
                tvChannelID: ${c.PredefinedTvChannel.ID} ,                 
                tuneInfo:"${JSON.stringify(tuneInfo).replace(/"/g, '\\"')}" 
            },
        `;
        } else if (c.Tune?.id) {
            const tuneInfo = c.Tune?.TuneInfo || {};
            let newTuneInfo = JSON.parse(c.TuneData);
            newTuneInfo._parsedChannelName = c.Name;
            tuneInfo[c.TuneType] = newTuneInfo;

            textToMutation += `
            {
                tuneID: ${c.Tune.id} ,                 
                tuneInfo:"${JSON.stringify(tuneInfo).replace(/"/g, '\\"')}" 
            },
        `;
        }
    });

    if (params.tunesToDelete?.length > 0 && actionOnChannelNotFound === "delete") {
        params.tunesToDelete.forEach((tune) => {
            const tuneInfo = tune.TuneInfo;
            delete tuneInfo[tuneType];
            textToMutation += `
            {
                tuneID: ${tune.id} ,                 
                tuneInfo:"${JSON.stringify(tuneInfo).replace(/"/g, '\\"')}" 
            },
        `;
        });
    }
    return textToMutation;
};

const buildMutationCustomisedChannels = (channels) => {
    let textToMutation = "";
    channels.forEach((c) => {
        const tuneInfo = generateTuneInfo(c);
        textToMutation += `
            {
                countryRef: "${c.countryRef}" ,
                name: "${c.name}",
                isRadio: ${c.isRadio},
                logoRef: "${c.logoRef}",
                languageRef: "${c.languageRef}"
                tune:{
                    tuneInfo:"${JSON.stringify(tuneInfo).replace(/"/g, '\\"')}"
                } 
            },
        `;
    });

    return textToMutation;
};
