export const QUERY_STAY_BOOKING_FILTER = (id) => {
    return `query {
        stayBookings(
          filter: {
            id: ${id}
          }
        ) {
          info {
            count
            page
            size
          }
          results {
            id
            code
            reservationDate
            arrivalDate
            departureDate
            numberOfGuests
            numberOfRooms
            observations
            pendingChangesSend
            state
            stayHolderEmail
            stayHolderName
            stayHolderSurname
            pmsID
            guests {
              birthDate
              countryRef
              customFieldsInformation
              documentExtraInformation
              documentNumber
              documentType
              email
              gender
              pmsID
              guestRole
              id
              languageRef
              name
              phoneNumber
              stayBookingRoomIndex
              surname
            }
            logs {
              createdAt
              id
              message
              stayBookingID
              subject
            }
          }
        }
        preCheckInSettingsFields {
            name { enabled required blocked }
            surname { enabled required blocked }
            gender { enabled required blocked }
            nationality { enabled required blocked }
            birthdate { enabled required blocked }
            email { enabled required blocked }
            phone { enabled required blocked }
            documentType { enabled required blocked values }
            documentNumber { enabled required blocked }
            documentExpeditionDate { enabled required blocked }
        }
        preCheckInSettingsCustomFields{
          fields{
            blocked
            enabled
            names{
              lang
              name
            }
            order
            ref
            required
          }
        }
        preCheckInSettingsCustomFieldsChildren{
          fields{
            blocked
            enabled
            names{
              lang
              name
            }
            order
            ref
            required
          }
        }
          preCheckInSettings {
          maximumAgeOfChildren
          requestDataFromChildren
      }
      }
      `;
};

export const MUTATION_UPDATE_PRE_CHECK_IN_TRAVELERS_REPORT = (data) => {
    return `mutation {
    updatePreCheckInSettingsTravelersReport(
      authorities: [${data.authorities ? `"${data.authorities}"` : ""}]
      user: "${data.user ? `${data.user}` : ""}"
      password: "${data.password ? `${data.password}` : ""}"
    ) {
      ok
      error
    }
  }
  `;
};

export const MUTATION_UPDATE_PRE_CHECK_IN_TRAVELERS_REPORT_FIELDS = (
    hasChildrenCustomFields,
    guestFields = [],
    guestChildrenFields = []
) => {
    return `mutation {
    updatePreCheckInSettingsTravelersReport(
      guestData: {
        fields:{
          surname: ${guestFields?.includes("surname")},
          gender:${guestFields?.includes("gender")},
          birthdate:${guestFields?.includes("birthdate")},
          documentType:${guestFields?.includes("documentType")},
          email:${guestFields?.includes("email")},
          documentExpeditionDate:${guestFields?.includes("documentExpeditionDate")},
          documentNumber:${guestFields?.includes("documentNumber")},
          name:${guestFields?.includes("name")},
          nationality:${guestFields?.includes("nationality")},
          phone:${guestFields?.includes("phone")},
        }
        customFields:[${guestFields?.filter((val) => val.length == 36).map((val) => `"${val}"`)}]
      }
      ${
          hasChildrenCustomFields
              ? `guestDataChildren: {
        fields:{
          surname: ${guestChildrenFields?.includes("surname")},
          gender:${guestChildrenFields?.includes("gender")},
          birthdate:${guestChildrenFields?.includes("birthdate")},
          documentType:${guestChildrenFields?.includes("documentType")},
          email:${guestChildrenFields?.includes("email")},
          documentExpeditionDate:${guestChildrenFields?.includes("documentExpeditionDate")},
          documentNumber:${guestChildrenFields?.includes("documentNumber")},
          name:${guestChildrenFields?.includes("name")},
          nationality:${guestChildrenFields?.includes("nationality")},
          phone:${guestChildrenFields?.includes("phone")},
        }
        customFields:[${guestChildrenFields?.filter((val) => val.length == 36).map((val) => `"${val}"`)}]
      }`
              : ""
      }


    ) {
      ok
      error
    }
  }`;
};

export const MUTATION_UPDATE_AUTOMATION_GUEST_REPORT = (deactivate) => {
    return `mutation{
    updatePreCheckInSettings(
      automaticSendingOfTheTravelersReport:${deactivate ? false : true}
    ){
      error
      ok
    }
  }`;
};

export const QUERY_PRE_CHECK_IN_SETTINGS_TRAVELERS_REPORT = () => {
    return `query{
      preCheckInSettingsTravelersReport{
        guestData{
          fields{
            name
            surname
            gender
            birthdate
            email
            nationality
            phone
            documentExpeditionDate
            documentNumber
            documentType
          }
          customFields
        }
        guestDataChildren{
          fields{
            name
            surname
            gender
            birthdate
            email
            nationality
            phone
            documentExpeditionDate
            documentNumber
            documentType
          }
          customFields
        }
        authorities
        password
        user
      }
      preCheckInSettings{
        automaticSendingOfTheTravelersReport
        requestDataFromChildren
      }
      preCheckInSettingsCustomFields{
        fields{
            ref
            enabled
            order
            names{
                lang
                name
            }
        }
    }
    
    preCheckInSettingsCustomFieldsChildren{
        fields{
            ref
            enabled
            order
            names{
                lang
                name
            }
        }
    }
  }`;
};

export const FAKE_MUTATION = () => {
    return `mutation {
    fakeMutation{
      error
      ok
    }
  }`;
};

export const QUERY_PRECKECKIN_INFORMATION_DATA = () => {
    return `
    query {
        preCheckInSettingsFields {
            name { enabled required blocked }
            surname { enabled required blocked }
            gender { enabled required blocked }
            nationality { enabled required blocked }
            birthdate { enabled required blocked }
            email { enabled required blocked }
            phone { enabled required blocked }
            documentType { enabled required blocked values }
            documentNumber { enabled required blocked }
            documentExpeditionDate { enabled required blocked }
        }
        preCheckInSettingsFieldsChildren {
            name { enabled required blocked }
            surname { enabled required blocked }
            gender { enabled required blocked }
            nationality { enabled required blocked }
            birthdate { enabled required blocked }
            email { enabled required blocked }
            phone { enabled required blocked }
            documentType { enabled required blocked values }
            documentNumber { enabled required blocked }
            documentExpeditionDate { enabled required blocked }
        }
        preCheckInSettings {
            maximumAgeOfChildren
            requestDataFromChildren
        }
        preCheckInSettingsCustomFields {
            fields {
                ref
                enabled
                required
                blocked
                order
                names {
                    lang
                    name
                }
            }
        }
        preCheckInSettingsCustomFieldsChildren {
            fields {
                ref
                enabled
                required
                blocked
                order
                names {
                    lang
                    name
                }
            }
        }
    }
`;
};

export const UPDATE_PRECHECKIN_INFORMATION_DATA = (
    maximumAgeOfChildren,
    requestDataFromChildren,
    fieldSettings,
    actionData,
    mutationCustomFields
) => {
    const parseValues = (value) => {
        const finalValue = { enabled: value?.enabled, required: value?.required };

        return JSON.stringify(finalValue).replaceAll(`"`, "");
    };

    return `
mutation {
    updatePreCheckInSettings(
        maximumAgeOfChildren: ${maximumAgeOfChildren && maximumAgeOfChildren > 0 ? maximumAgeOfChildren : "0"}
        requestDataFromChildren: ${requestDataFromChildren}
    ){
        ok
        error
    }
    updatePreCheckInSettingsFields(
        name: ${parseValues(fieldSettings.name)}
        surname: ${parseValues(fieldSettings.surname)}
        gender: ${parseValues(fieldSettings.gender)}
        birthdate: ${parseValues(fieldSettings.birthdate)}
        email: ${parseValues(fieldSettings.email)}
        phone: ${parseValues(fieldSettings.phone)}
        nationality: ${parseValues(fieldSettings.nationality)}
        documentType: {
            enabled: ${fieldSettings.documentType.enabled},
            required: ${fieldSettings.documentType.required},
            values: ${fieldSettings?.documentType?.values ? JSON.stringify(fieldSettings?.documentType?.values) : "[]"}
        }
        documentNumber: ${parseValues(fieldSettings.documentNumber)}
        documentExpeditionDate: ${parseValues(fieldSettings.documentExpeditionDate)}
    ) {
        error
        ok
    }
    updatePreCheckInSettingsFieldsChildren(
        name: ${parseValues(fieldSettings.nameChildren)}
        surname: ${parseValues(fieldSettings.surnameChildren)}
        gender: ${parseValues(fieldSettings.genderChildren)}
        birthdate: ${parseValues(fieldSettings.birthdateChildren)}
        email: ${parseValues(fieldSettings.emailChildren)}
        phone: ${parseValues(fieldSettings.phoneChildren)}
        nationality: ${parseValues(fieldSettings.nationalityChildren)}
        documentType: {
            enabled: ${fieldSettings.documentTypeChildren.enabled},
            required: ${fieldSettings.documentTypeChildren.required},
            values: ${
                fieldSettings?.documentTypeChildren?.values
                    ? JSON.stringify(fieldSettings?.documentTypeChildren?.values)
                    : "[]"
            }
        }
        documentNumber: ${parseValues(fieldSettings.documentNumberChildren)}
        documentExpeditionDate: ${parseValues(fieldSettings.documentExpeditionDateChildren)}
    ) {
        error
        ok
    }
    ${mutationCustomFields ? mutationCustomFields : ""}
}
`;
};
