import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import Icon from "components/Icon";
import Button from "components/Button";

const InteractiveImageDisplay = ({
    id,
    title,
    src,
    onAdd,
    onDelete,
    info,
    resolution = { width: 1280, height: 720 },
    className,
}) => {
    if (!id) {
        throw new Error("id is required");
    }

    const { t } = useTranslation();

    const [onErrorImg, setOnErrorImg] = useState(false);

    const infoIcon = info ? <Icon type="info" size={1.3} className="mt-1" tooltip={info} /> : null;

    const barClass = classNames({
        "bg-gray-800 text-white bg-opacity-75 absolute bottom-0 flex items-center justify-between py-1 px-2": true,
        "left-0 w-full": onDelete,
        "right-0 rounded-full": !onDelete,
    });

    const imageClass = classNames({
        "object-cover rounded": true,
        [className]: className,
    });

    const noImageClass = classNames({
        "flex items-center justify-center": true,
        [className]: className,
    });

    return (
        <div className="font-bold flex flex-col items-center">
            {title || resolution ? (
                <div className="flex w-full">
                    <span className="block mr-1 text-lg">{title}</span>
                    {resolution ? (
                        <Icon
                            tooltip={t("recommended-resolution", {
                                width: resolution?.width || 0,
                                height: resolution?.height || 0,
                            })}
                            type="info"
                            size={1.8}
                        />
                    ) : null}
                </div>
            ) : null}

            <div className="flex relative mt-2 items-center justify-center mb-2 rounded border border-gray-300 w-full bg-gray-100">
                {!src || onErrorImg ? (
                    <div className={noImageClass}>
                        <Icon type="no-image" className="text-gray-400" size={1.5} />
                    </div>
                ) : (
                    <img
                        className={imageClass}
                        src={src}
                        onError={() => {
                            setOnErrorImg(true);
                        }}
                    />
                )}
                {src && (info || onDelete) ? (
                    <div className={barClass}>
                        {onDelete ? (
                            <>
                                <div className="w-5"></div>
                                <div>
                                    {onDelete ? (
                                        <Button id={`${id}-library-image-selector-delete`} onClick={onDelete}>
                                            <Icon type="delete" size={1.3} />
                                        </Button>
                                    ) : null}
                                </div>
                            </>
                        ) : null}
                        <div className="w-5">{infoIcon}</div>
                    </div>
                ) : null}
            </div>
            {onAdd ? (
                <Button className="w-full" onClick={onAdd} design="blue" id={`${id}-library-image-selector-add-image`}>
                    {t("add-image")}
                </Button>
            ) : null}
        </div>
    );
};
InteractiveImageDisplay.displayName = "InteractiveImageDisplay";

export default InteractiveImageDisplay;
