import { capitalizeFirst } from "../../../../hooks/Utils/Utils";

const InputGroupAssignedTvs = (t, title, id, isDefault, tvsAssigned) => {
    return [
        {
            id: id,
            titlePrefix: title,
            title: ["-", capitalizeFirst(t("assigned-tvs"))],
            executeSectionQuery: true,
            bodyAdjust: "w-3/12",
            buttons: [{ name: "close", style: "blue", action: "close" }],
            inputs: [
                {
                    text: `${t("make-input-group-default").replace("%0", title)}`,
                    type: "assignedTvs",
                    isDefault: isDefault,
                    tvsAssigned: tvsAssigned,
                },
            ],
        },
    ];
};

export default InputGroupAssignedTvs;
