import { setExecuteQuery } from "actions/sectionActions";
import UseCheckBox from "components/Inputs/useCheckBox";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const CoaxialTunes = ({ tuneData, dvbTunes, setDvbTunes }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [tunesVisible, setTunesVisible] = useState([]);

    useEffect(() => {
        let visible = [];

        dvbTunes &&
            dvbTunes.forEach((tune) => {
                visible.push(false);
            });
        setTunesVisible(visible);
    }, []);

    const updateEnabled = (tune, value) => {
        tuneData.tuneInfo[tune.type].isEnabled = value;
        dispatch(
            setExecuteQuery({
                action: "update-tune",
                params: {
                    id: tuneData.id,
                    tuneInfo: JSON.stringify(JSON.stringify(tuneData.tuneInfo)),
                },
            })
        );
    };
    const changeVisibility = (index) => {
        let tunesVisibleCopy = tunesVisible;
        tunesVisibleCopy[index] = tunesVisible[index] ? false : true;
        setTunesVisible([...tunesVisibleCopy]);
    };

    const printTuneType = (type) => {
        return type?.replaceAll("-", " ");
    };

    return (
        <>
            {dvbTunes?.length > 0 ? (
                <>
                    <div className="flex w-full text-left mb-2">
                        <span className="font-bold text-gray-900 text-1xl">{t("coaxial cable tuning")}</span>
                    </div>

                    {dvbTunes
                        ?.sort((a, b) => (a.type > b.type ? 1 : -1))
                        .map((tune, index) => (
                            <div className="border-b border-gray-200">
                                <div
                                    className={`icon icon-chevron${
                                        tunesVisible?.[index] ? "-up" : ""
                                    } float-left text-gray-800 p-3 mt-1 clickable`}
                                    onClick={() => changeVisibility(index)}
                                ></div>
                                <UseCheckBox
                                    checked={tune.tune.isEnabled}
                                    id={tune.type}
                                    adjust={"w-full my-3 float-left"}
                                    name={printTuneType(tune.type)}
                                    onClick={(value) => {
                                        updateEnabled(tune, value);
                                    }}
                                />
                                {tunesVisible?.[index] ? (
                                    <div className="ml-10">
                                        <div class="font-bold p-2 "> {t("tuning information")} </div>
                                        {Object.keys(tune.tune)
                                            .sort((a, b) => (a > b ? 1 : -1))
                                            .map((prop) =>
                                                tune.tune[prop] && prop != "isEnabled" && prop?.[0] != "_" ? (
                                                    <>
                                                        <div class="p-2 flex w-7/12">
                                                            <div class="w-4/5">{prop}</div>
                                                            <div class="w-1/5">{tune.tune[prop]}</div>
                                                        </div>
                                                    </>
                                                ) : null
                                            )}
                                    </div>
                                ) : null}
                            </div>
                        ))}
                </>
            ) : null}
        </>
    );
};

export default CoaxialTunes;
