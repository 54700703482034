import { TopologyRoomModel } from "../models/TopologyModel";
/* eslint-disable */
export const arrangeData = (roomsInfo) => {
    const roomResults = roomsInfo?.rooms?.results?.map((room) => {
        roomsInfo?.floors?.results?.forEach((floor) => {
            floor.floors?.forEach((roomFloor) => {
                roomFloor.rooms?.forEach((roomFloorRoom) => {
                    if (roomFloorRoom.id === room.id) {
                        room.recentGuests = roomFloorRoom.recentGuests;
                    }
                });
            });
        });
        return room;
    });

    return roomResults?.map((room) => TopologyRoomModel(room));
};

export const arrangeFloorFilter = (floors) => {
    return floors?.map((floor) => {
        const { id, name, floors } = floor;
        return {
            id,
            label: name,
            options: floors?.map((floor) => ({
                id: floor.id,
                label: floor.name,
                value: floor.id,
            })),
        };
    });
};

export const arrangeLoactionFilter = (locations) => {
    return locations.map((location) => ({
        label: location,
        value: location,
    }));
};
