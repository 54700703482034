import React from "react";
import Checkbox from "components/Inputs/Checkbox";
import Radiobuttons, { Radiobutton } from "components/Inputs/Radiobuttons";
import Switch from "components/Switch";
import { Header, Examples } from "sections/playground";

export const TYPES = [
    {
        id: "CHECKBOX",
        title: "Checkbox",
        variants: ["ACTIVE", "ICON", "DISABLED", "DISABLED-ICON"],
        examples: ["DEFAULT", "MARKED", "TOOLTIP"],
    },
    {
        id: "RADIO",
        title: "Radio buttons",
        variants: ["ACTIVE", "DISABLED"],
        examples: ["DEFAULT", "MARKED", "MULTIPLE"],
    },
    {
        id: "SWITCH",
        title: "Switch",
        variants: ["ACTIVE", "DISABLED"],
        examples: ["DEFAULT", "MARKED"],
    },
];

const SelectionControls = ({ section }) => {
    return (
        <>
            <Header title="Selection Controls">
                Use selection controls when uses need to selection a single option (Radio button) or multiple options
                (Checkboxex) from a short list. Switchers are a way to switch between simple states.
            </Header>
            <Examples options={TYPES?.filter((s) => !section || s?.id === section)} display={DisplayExample} />
        </>
    );
};

const DisplayExample = ({ type, variant, example }) => {
    const id = `${type.id}-${variant}-${example}`;

    const checked = example === "MARKED";
    const disabled = variant === "DISABLED";
    const hasIcon = variant === "ICON";

    const multiple = example === "MULTIPLE";
    const tooltip = example === "TOOLTIP" ? "This is a tooltip" : undefined;

    const labels = {
        ACTIVE: "Active",
        ICON: "Active with icon",
        DISABLED: "Disabled",
        "DISABLED-ICON": "Disabled with icon",
    };

    if (type.id === "CHECKBOX") {
        return (
            <Checkbox
                label={labels[variant]}
                checked={checked}
                disabled={disabled}
                icon={
                    hasIcon
                        ? {
                              type: "warning-lines",
                              size: "xl",
                              className: disabled ? "text-gray-500" : "text-orange-500",
                          }
                        : null
                }
                tooltip={tooltip}
            />
        );
    }

    if (type.id === "RADIO") {
        if (multiple) {
            return (
                <div className="flex space-x-5">
                    <Radiobuttons
                        checked="B"
                        disabled={disabled}
                        options={[
                            { label: "Option A", value: "A" },
                            { label: "Option B", value: "B" },
                            { label: "Option C", value: "C" },
                        ]}
                    />
                </div>
            );
        }
        return (
            <Radiobutton
                label="Option"
                uncheck={true} // this is only for the example, normally you don't want to set this
                disabled={disabled}
                checked={checked}
            />
        );
    }

    if (type.id === "SWITCH") {
        return <Switch checked={checked} disabled={disabled} />;
    }

    return id;
};

export default SelectionControls;
