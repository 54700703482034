import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ShopHeadMenu from "./ShopHeadMenu";
import ErrorInfo from "components/ErrorInfo";
import UseSectionHeader from "../../../useSectionHeader";

import SalesProvider from "contexts/Sales";
import ShopProvider from "contexts/Sales/Shop";
import { SalesProductsProvider } from "./SalesContext";

import { setRefreshData } from "actions/tableActions";
import ListProducts from "../../../../hooks/GraphqlCalls/Sales/ListProducts";
import UpdateListProducts from "../../../../hooks/GraphqlCalls/Sales/UpdateListProducts";
import UseTable from "../../../Table";

const Products = () => {
    const { id } = useParams();

    const salesRef = useRef(null);
    const contextRef = useRef(null);
    const refresh = () => {
        if (contextRef?.current) {
            contextRef.current.refresh();
        }
    };

    const dispatch = useDispatch();
    const refreshData = useSelector((state) => state?.sectionContent?.refreshData);

    const [error, setError] = useState(null);

    useEffect(() => {
        if (refreshData) {
            dispatch(setRefreshData(false));
            refresh();
        }
    }, [refreshData]);

    return (
        <SalesProvider ref={salesRef}>
            <ShopProvider
                ref={contextRef}
                id={id}
                onError={(error) => {
                    setError(error);
                }}
            >
                <SalesProductsProvider>
                    <ListProducts />
                    <ShopHeadMenu />
                    {error ? (
                        <ErrorInfo retry={refresh}>{error}</ErrorInfo>
                    ) : (
                        <>
                            <UseSectionHeader title={"products"} hideBreadcrumbs={true} />
                            <UseTable />
                            <UpdateListProducts />
                        </>
                    )}
                </SalesProductsProvider>
            </ShopProvider>
        </SalesProvider>
    );
};

export default Products;
