import React from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table/index";
//Utils
import ListBookingCategories from "../../../hooks/GraphqlCalls/CommonZones/ListBookingCategories";

const BookingCategories = () => {
    ListBookingCategories();

    return (
        <>
            <UseSectionHeader title={"all bookings"} />
            <UseTable />
        </>
    );
};

export default BookingCategories;
