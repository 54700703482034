const DeleteTaxZone = (id, name, taxRates) => {
    return [
        {
            title: ["delete", name],
            text: "delete-tax-zone-description",
            id: id,
            executeSectionQuery: true,
            taxes: taxRates,
            inputs: [
                {
                    type: "data",
                    name: "taxRates",
                    value: taxRates,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "delete",
                    style: "red",
                    action: "delete-tax-zone",
                },
            ],
        },
    ];
};

export default DeleteTaxZone;
