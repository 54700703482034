import { TVInput } from "hooks/GraphqlCalls/Hotel/Monitoring/models/Devices";
import { DEVICE_FUNCTIONS, DEVICE_TYPES } from "hooks/Utils/Hotel/Monitor/ListDevicesUtils";
import React from "react";
import { useTranslation } from "react-i18next";

export const ExpandedInfo = ({ device, inputGroupData }) => {
    const { function: deviceFunction, ref, type, wifiSignal, firmwareVersion, commercialName, tvInputID, ip } = device;

    const renderExpandedInfo = (deviceFunction) => {
        switch (deviceFunction) {
            case DEVICE_FUNCTIONS.cast.value:
                if (type === DEVICE_TYPES.googleTV.value) {
                    return (
                        <ExpandedRows
                            ip={ip}
                            type={type}
                            commercialName={commercialName}
                            wifiSignal={wifiSignal}
                            reference={ref}
                        />
                    );
                } else {
                    return <ExpandedRows ip={ip} type={type} commercialName={commercialName} wifiSignal={wifiSignal} />;
                }
            case DEVICE_FUNCTIONS.tv.value:
                if (type === DEVICE_TYPES.pc.value) {
                    return (
                        <ExpandedRows
                            type={type}
                            commercialName={commercialName}
                            inputGroupData={inputGroupData}
                            tvInputID={tvInputID}
                        />
                    );
                } else if (type === DEVICE_TYPES.googleTV.value) {
                    return <ExpandedRows type={type} commercialName={commercialName} reference={ref} />;
                } else {
                    switch (type) {
                        case DEVICE_TYPES.samsung.value:
                        case DEVICE_TYPES.samsungOrsay.value:
                        case DEVICE_TYPES.samsungTizen.value:
                        case DEVICE_TYPES.lg.value:
                            return (
                                <ExpandedRows
                                    type={type}
                                    commercialName={commercialName}
                                    firmwareVersion={firmwareVersion}
                                    inputGroupData={inputGroupData}
                                    tvInputID={tvInputID}
                                />
                            );
                    }
                }
            case DEVICE_FUNCTIONS.tvAndcast.value:
                if (type === DEVICE_TYPES.googleTV.value) {
                    return (
                        <ExpandedRows
                            ip={ip}
                            type={type}
                            commercialName={commercialName}
                            wifiSignal={wifiSignal}
                            reference={ref}
                        />
                    );
                } else if (type === DEVICE_TYPES.philips.value || type === DEVICE_TYPES.lg.value) {
                    return (
                        <ExpandedRows
                            ip={ip}
                            type={type}
                            commercialName={commercialName}
                            reference={ref}
                            inputGroupData={inputGroupData}
                            tvInputID={tvInputID}
                        />
                    );
                }
            case "":
                return (
                    <ExpandedRows
                        ip={ip}
                        type={type}
                        commercialName={commercialName}
                        wifiSignal={wifiSignal}
                        reference={ref}
                    />
                );
        }
    };

    return renderExpandedInfo(deviceFunction);
};

const ExpandedRows = ({
    ip,
    type,
    commercialName,
    wifiSignal,
    reference,
    inputGroupData,
    tvInputID,
    firmwareVersion,
}) => {
    const { t } = useTranslation();

    return (
        <div className={`text-sm text-gray-800 leading-6 grid grid-cols-3 w-7/12 ml-20`}>
            <div className="flex flex-col ml-2">
                {reference && (
                    <p>
                        {t("ref")}: {reference}
                    </p>
                )}
                {ip && (
                    <p>
                        {t("ip")}: {ip}
                    </p>
                )}
                {firmwareVersion && (
                    <p>
                        {t("tv-firmware")}: {firmwareVersion}
                    </p>
                )}
            </div>
            <div>
                <div className="flex flex-col">
                    <p>
                        {t("type")}: {type}
                    </p>
                    <p>
                        {t("model")}: {commercialName}
                    </p>
                </div>
            </div>
            {wifiSignal && parseInt(wifiSignal) < 0 ? (
                <div className="flex items-center gap-2">
                    <img
                        src={require(`../../../../../assets/images/icons/${getWifiSignal(wifiSignal)}.svg`)}
                        alt="wifi-signal"
                    />
                    {t("wifi-signal")}: {wifiSignal} dBm
                </div>
            ) : null}
            {inputGroupData?.name && (
                <div>
                    <TVInput inputGroupData={inputGroupData} tvInputID={tvInputID} type={type} />
                </div>
            )}
        </div>
    );
};

const getWifiSignal = (wifiSignal) => {
    let res = "";
    if (parseInt(wifiSignal) >= -50) {
        res = "wifi-signal-high";
    } else if (parseInt(wifiSignal) < -50 && parseInt(wifiSignal) >= -60) {
        res = "wifi-signal-good";
    } else if (parseInt(wifiSignal) < -60 && parseInt(wifiSignal) >= -70) {
        res = "wifi-signal-medium";
    } else if (parseInt(wifiSignal) < -70) {
        res = "wifi-signal-low";
    }
    return res;
};
