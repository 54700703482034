import React from "react";
import { useSelector } from "react-redux";
const UseCurrentTag = ({
    activeSelection,
    setActiveSelection,
    activeFilters,
    setActiveFilters,
    color,
    idValue,
    activeFilter,
}) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    //Actions
    const removeTag = () => {
        setActiveSelection(activeSelection.filter((af) => af !== idValue));
        setActiveFilters(activeFilters.filter((af) => af !== activeFilter));
    };

    return (
        <div
            className="h-8 text-white p-2 py-1 ml-1 rounded z-50 inline-block whitespace-no-wrap "
            style={{ backgroundColor: color }}
        >
            <div className="inline-block whitespace-normal first-capital">{langStrings[activeFilter]}</div>
            <div className="px-2 mb-2 inline-block cursor-pointer whitespace-normal" onClick={removeTag}>
                x
            </div>
        </div>
    );
};

export default UseCurrentTag;
