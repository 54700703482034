import { useState } from "react";
import { Session } from "../../../Utils/Session";
import { THEME_TYPE_DESIGN } from "hooks/Utils/AppearancesUtils";
import { tmpGetChain, tmpGetProject } from "hooks/Utils/Utils";

function NewDesign(editData, t) {
    const chain = tmpGetChain();
    const project = tmpGetProject();

    const isCorporateAccess = project && project.ref === "CORPORATE" ? true : false;
    const id = editData && editData.id ? editData.id : null;
    const name = editData && editData.name ? editData.name : null;
    const type = editData && editData.type ? editData.type : isCorporateAccess ? "CORPORATE" : "LOCAL";
    const visibleAll = editData && editData.visibleAll ? editData.visibleAll : false;
    const themeID = editData && editData.themeID ? editData.themeID : 0;

    const visibleAllProject = editData && editData.visibleAllProject ? editData.visibleAllProject : null;
    const projectsData = [];
    const dynamicInputs = [];

    const THEME_CORPORATE_OR_LOCAL = Session.isCorporate() || Session.hasChainModule() ? "" : "type:LOCAL";

    const themesQuery = `{
        data: themes ${
            type === "CORPORATE"
                ? `(filter:{type:${type} , themeType:${THEME_TYPE_DESIGN},${THEME_CORPORATE_OR_LOCAL}})`
                : `(filter:{themeType:${THEME_TYPE_DESIGN},${THEME_CORPORATE_OR_LOCAL}})`
        } {
            info {
                count
            }
            results {
                id
                name
                category:type
            }
        }
        ${
            themeID
                ? `selected: themes(filter:{id:${themeID}}){
                    results {
                        id
                    }
                }`
                : ""
        } 
    }`;

    if (chain?.name) {
        projectsData.push({
            id: `all`,
            name: chain.name,
            selected: visibleAll,
            category: "chain",
            selectedLabelColor: "bg-purple-600 text-white",
        });
    }

    if (isCorporateAccess) {
        const brands = Session.getSessionProp("brands");
        if (brands) {
            // eslint-disable-next-line
            JSON.parse(brands)?.map((brand) => {
                const hasProjectsWithoutChain = brand?.projects?.length
                    ? brand.projects.some((x) => !x.hasChainModule)
                    : false;
                let itemPreSelected = null;
                if (brand.ref) {
                    if (
                        visibleAllProject &&
                        visibleAllProject.ref &&
                        visibleAllProject.type === "BRAND" &&
                        visibleAllProject.ref === brand.ref
                    ) {
                        itemPreSelected = visibleAllProject;
                    }
                    projectsData.push({
                        id: `${brand.ref}`,
                        hasProjectsWithoutChain,
                        name: `<span class="flex items-center">${brand.name}${
                            hasProjectsWithoutChain
                                ? `<span class="icon-info text-2xl ml-2 inline-block" data-for="dangerous-html-tooltip" data-tip="${t(
                                      "Some properties dont have access to Global Management content"
                                  )}"></span>`
                                : ""
                        }</span>`,
                        selected: !visibleAll && itemPreSelected !== null ? true : false,
                        category: "property-groups",
                        selectedLabelColor: "bg-orange-100 text-white",
                    });
                }
            });
        }
    }
    if (Session.getProjects() !== null && Session.getProjects() !== undefined) {
        // eslint-disable-next-line
        Session.getProjects().map((p) => {
            let itemPreSelected = null;
            if (p.ref) {
                if (
                    visibleAllProject &&
                    visibleAllProject.ref &&
                    visibleAllProject.type === "PROJECT" &&
                    visibleAllProject.ref === p.ref
                ) {
                    itemPreSelected = visibleAllProject;
                }
                if (p.id !== 0 && (isCorporateAccess || p.ref === project.ref)) {
                    const hasChainModule = p.hasChainModule;
                    projectsData.push({
                        id: `${p.ref}`,
                        withoutChain: !hasChainModule,
                        name: `${
                            hasChainModule
                                ? `<span class="icon-corporate-access mr-2 inline-block" data-for="dangerous-html-tooltip" data-tip="${t(
                                      "This property has access to Global Management content"
                                  )}"></span>`
                                : ""
                        }${p.name}`,
                        selected: !visibleAll && itemPreSelected !== null ? true : false,
                        category: "properties",
                        selectedLabelColor: "bg-teal-500 text-white",
                    });
                }
            }
        });
    }
    const themeInput = {
        text: ["default appearance", "*"],
        name: "design-themeId",
        instructions: "default appearance instructions",
        type: "selectMultipleWithSearch",
        optionsQuery: themesQuery,
        selectPlaceHolder: "select appearance",
        oneSelected: true,
        noSelect: true,
        textStyle: "font-bold first-capital",
        translateOptions: true,
        forceSelection: true,
        groupsNames: [
            { name: "LOCAL", text: "property-appearances" },
            Session.isCorporate() || Session.hasChainModule()
                ? { name: "CORPORATE", text: "general-appearances" }
                : undefined,
        ].filter((x) => x !== undefined),
        orderOptions: true,
    };
    if (isCorporateAccess) {
        dynamicInputs.push(
            {
                text: ["properties"],
                name: "properties",
                instructions: "design-properties-instructions",
                type: "selectMultipleWithSearch",
                optionData: projectsData,
                onChange: (values) => {
                    const warn = document.getElementById("warning-prop-without-chain");
                    if (warn) {
                        if (
                            values?.length &&
                            values?.some((item) => item?.hasProjectsWithoutChain || item?.withoutChain)
                        ) {
                            warn.classList.remove("hidden");
                        } else {
                            warn.classList.add("hidden");
                        }
                    }
                },
                selectPlaceHolder: "select-properties",
                labelsColors: "bg-teal-500 text-white",
                oneSelected: true,
                translateOptions: true,
                textStyle: "font-bold first-capital",
            },
            themeInput,
            {
                id: "design-assignedAllProject",
                name: "design-assignedAllProject",
                type: "checkbox",
                checked: false,
                warningID: "warning-prop-without-chain",
                warning: t(
                    "You selected a property or a group of properties without Zafiro Chain license. Those properties will not be able to use this design"
                ),
                instructions: "design-global-apply-instructions",
                instructionsInfo: t(
                    "The default screen will only apply to those properties that have Zafiro Chain licenses"
                ),
                textActive: "design-global-apply-active",
                textInactive: "design-global-apply-inactive",
                textActiveAdjust: "text-gray-400 -ml-1 pl-1",
                textInactiveAdjust: "text-blue-200 -ml-1 pl-1",
                adjust: "hidden mb-10",
                cStyle: "mb-14",
                noDisable: true,
            }
        );
    } else {
        dynamicInputs.push(
            {
                text: "",
                name: "properties",
                value: "",
                type: "text",
                style: "hidden",
            },
            themeInput
        );
    }

    return [
        {
            title: name ? [name, "-", "edit-design"] : "add-design",
            bodyAdjust: isCorporateAccess ? "w-10/12" : "w-4/12",
            twoColumns: isCorporateAccess,
            id: id,
            executeSectionQuery: true,
            isCorporateAccess,
            inputs: [
                {
                    text: ["name", "*"],
                    name: "design-name",
                    instructions: "design-name-instructions",
                    value: name,
                    type: "text",
                    focus: true,
                    cStyle: isCorporateAccess ? "w-6/12" : "",
                    textStyle: "font-bold first-capital",
                },
                {
                    text: "",
                    name: "design-type",
                    value: type,
                    type: "text",
                    style: "hidden",
                },
                ...dynamicInputs,
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "save",
                    style: "blue",
                    action: "add-design",
                },
            ],
        },
    ];
}

export default NewDesign;
