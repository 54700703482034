import React from "react";

//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table";

import ListTemplates from "../../../hooks/GraphqlCalls/Design/ListTemplates";
import UpdateTemplate from "../../../hooks/GraphqlCalls/Design/UpdateTemplate";

const Templates = () => {
    //Actions

    ListTemplates();
    return (
        <>
            <UpdateTemplate />
            <UseSectionHeader title={["list-of", "templates"]} value={"demo"} />
            <UseTable />
        </>
    );
};

export default Templates;
