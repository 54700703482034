export const Hotel = [
    "map-of-installation",
    "wings",
    "wing",
    "floor",
    "floors",
    "search-rooms",
    "export",
    "no-hotel-map",
    "no-search-results",
    "topology",
    "property-settings",
    "add-room",
    "add-floor",
    "add-wing",
    "delete",
    "edit-room",
    "export-map",
    "view",
    "map",
    "name",
    "table",
    "number-of-rooms",
    "starter-room",
    "tvs-per-room",
    "location",
    "location-as-tvs",
    "topology-delete-room",
    "topology-delete-floor",
    "topology-delete-wing",
    "room-name",
    "room-number",
    "room-number-short",
    "pms-number",
    "pms-number-short",
    "edit-name",
    "write-floorname",
    "write-wingname",
    "edit-pmsnumber",
    "write-pmsnumber",
    "pmsnumber-no-valid",
    "customize-each-floor",
    "floors-number",
    "floor-name",
    "wing-name",
    "order",
    "monitor-of",
    "devices",
    "device",
    "last-activity",
    "status",
    "room",
    "SCREEN-TYPE-TV",
    "sending-content",
    "permanent-socket",
    "tv-also-assigned-cc",
    "tv-unassign-cc",
    "tv-device",
    "cec-abbreviation",
    "select-tv-locations-and-cec",
    "cec-enabled-description",
    "send-instructions-chromecast",
    "cast-inst",
    "tv-locations",
    "tv-location",
    "last-display",
    "export-devices",
    "device-model",
    "chromecast-disable-text",
    "disable",
    "detach-from-property",
    "authorise",
    "authorise-and-assign",
    "delete-device",
    "change-device-function",
    "launch-settings-screen",
    "chromecast-disable-text",
    "move-room",
    "edit-name",
    "restart",
    "chromecast-enable",
    "assign",
    "device-reload-interface-text",
    "device-restart-text",
    "device-restart-text-extended",
    "chromecast-and-stb",
    "online-low-signal",
    "online-standby",
    "move-room",
    "select-roomtv-text",
    "device-function",
    "move",
    "low-signal",
    "type",
    "model",
    "input-group",
    "wifi-signal",
    "tv-firmware",
    "ref",
    "tv-devices",
    "device-unassign-text",
    "device-assign-type-function",
    "device-authorise-text",
    "device-authorise-and-assign-you-sure",
    "delete-device-in-room-confirm-without-room",
    "topology-delete-room-had-guests"
];
