import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import { executeVendureQuery, useVendureAPI } from "hooks/Utils/Integrations/useVendure";
import { setRefreshContentData } from "actions/sectionActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Parser from "hooks/Utils/Parser";

const DELETE_PRODUCT_MUTATION = `
    mutation DeleteProduct($id: ID!) {
        deleteProduct(id: $id) {
            result
            message
        }
    }
`;

const useMutationDeleteProduct = (onError, onSuccess) => {
    const { adminApi } = useVendureAPI();
    const mutate = async (variables) => {
        try {
            const response = await executeVendureQuery(adminApi, {
                queryBody: DELETE_PRODUCT_MUTATION,
                variables,
            });

            if (response?.error) {
                if (onError) onError(response.error);
            } else {
                if (onSuccess) onSuccess(response.data);
            }
        } catch (error) {
            if (onError) onError(error.message);
        }
    };

    return { mutate };
};

const ModalParent = ({ name, available, id, close: closeModal }) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const { mutate } = useMutationDeleteProduct(
        (error) => handleMutationCompletion(error, true),
        (data) => handleMutationCompletion(data)
    );

    const handleMutationCompletion = (result, isError = false) => {
        const message = isError ? result : t("operation-successful");
        isError ? toast.error(message) : toast.success(message);
        dispatch(setRefreshContentData(true));
        closeModal();
    };

    return (
        <Modal
            title={`${name} - ${capitalizeFirst(t("delete"))}`}
            footer={
                !loading ? (
                    <>
                        <Button design="blue-outline" id="modal-button-cancel" onClick={() => closeModal()}>
                            {t("cancel")}
                        </Button>
                        <Button
                            design="red"
                            id="modal-button-delete"
                            onClick={() => {
                                mutate({ id, enabled: !available });
                                setLoading(true);
                            }}
                        >
                            {capitalizeFirst(t("delete"))}
                        </Button>
                    </>
                ) : null
            }
            className={"w-3/12 p-10"}
        >
            {!loading ? <div className=" py-4">{Parser(t("product-x-delete", { product: name }))}</div> : <Loading />}
        </Modal>
    );
};

const useDeleteProductModal = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalParent {...newProps} />);
        },
    };
};

export default useDeleteProductModal;
