const NewGridChannel = (t, data) => {
    return {
        title: "add-grid",
        text: "write-the-name-of-the-grid",
        executeSectionQuery: true,
        bodyAdjust: "w-1/12",
        inputs: [
            {
                text: "",
                name: "name-grid-channel",
                type: "text",
                ph: t("name"),
                focus: true,
            },
            {
                type: "void",
                style: `mt-2 ${data?.multipleGrids ? "opacity-0" : "opacity-1"} `,
                text: "only-a-single-grid-can-be-applied",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "add",
                style: "blue",
                action: "add-grid-channels",
            },
        ],
    };
};

export default NewGridChannel;
