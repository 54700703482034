import React, { useContext } from "react";
import classNames from "classnames";

import { GlobalContext } from "contexts/Global";

/**
 * @description A loader with a spinner
 * @component
 * @param {(block|absolute|full|inline)} adjust Adjust the position of the loader (default: block)
 * @param {number} zoom Zoom the loader (default: 100)
 * @param {string} className Add custom classes to the loader
 * @param {object} style Add custom styles to the loader
 * @param {string} text Add text to the loader
 * @param {string} textPosition Position the text when adjust is absolute (default: empty)
 * @returns {JSX.Element} Loader with spinner
 * @example
 * <Loading adjust="full" className="bg-white" text="Hello world" textPosition="flex-col-reverse" />
 */
const Loading = ({ adjust, zoom = 100, overlay, className = "", style, text, textPosition }) => {
    const { highlightComponent } = useContext(GlobalContext);

    let loadingClassName;
    switch (adjust) {
        case "absolute":
            loadingClassName = `flex ${textPosition} absolute z-500 top-0 bottom-0 left-0 right-0 items-center justify-center`;
            break;
        case "full":
            loadingClassName = `flex ${textPosition} fixed z-500 top-0 bottom-0 left-0 right-0 items-center justify-center`;
            break;
        case "inline":
            loadingClassName = "inline-block";
            break;
        case "section-loading":
            loadingClassName = `flex ${textPosition} h-5/6 z-500 top-0 bottom-0 left-0 right-0 items-center justify-center`;
            break;
        case "block":
        default:
            loadingClassName = "flex";
    }

    // (adjust absolute has overlay by default)
    const hasOverlay = overlay || (adjust === "absolute" && overlay === undefined);

    const containerClass = classNames({
        "inset-0 z-500 bg-gray-200 bg-opacity-50": hasOverlay,
        fixed: (adjust === "absolute" || adjust === "full") && hasOverlay,
        "h-full": adjust === "section-loading" && !hasOverlay,
        "highlight-component": highlightComponent,
    });

    const loaderClass = classNames({
        [loadingClassName]: loadingClassName,
        [className]: className,
    });

    return (
        <div className={containerClass}>
            <div className={loaderClass} style={style}>
                {text && <p className="font-bold text-gray-800">{text}</p>}
                <div
                    className={`lds-roller ${!text && "m-auto"}`}
                    style={{
                        zoom: `${zoom}%`,
                    }}
                >
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};
Loading.displayName = "Loading";

export const useLoading = () => {
    const { loading, setLoading } = useContext(GlobalContext);

    return {
        loading,
        setLoading,
    };
};

export default Loading;
