import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import { toast } from "react-toastify";
import Loading from "components/Loading";
import { useMutation } from "react-apollo";
import { setRefreshData } from "actions/tableActions";
import { useDispatch } from "react-redux";

const BULK_CHECK_IN = gql`
    mutation BulkCheckIn {
        bulkCheckIn {
            error
            ok
        }
    }
`;

const ModalContent = (props) => {
    const [onLoading, setOnLoading] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [bulkCheckIn] = useMutation(BULK_CHECK_IN, {
        onCompleted: (data) => {
            setOnLoading(false);
            if (data.bulkCheckIn.ok) {
                props.close();
                toast.success(t("operation-successful"));
                dispatch(setRefreshData(true));
            } else {
                toast.error(data.bulkCheckIn.error);
                props.close();
                dispatch(setRefreshData(true));
            }
        },
    });

    const handleSaveClick = () => {
        setOnLoading(true);
        bulkCheckIn();
    };

    return (
        <Modal
            title={t("bulk-check-in")}
            footer={
                <>
                    {onLoading ? null : (
                        <>
                            <Button design="blue-outline" id="modal-button-cancel" onClick={props?.close}>
                                {t("cancel")}
                            </Button>
                            <Button
                                design="blue"
                                id="modal-bulk-check-in"
                                onClick={() => {
                                    handleSaveClick();
                                }}
                            >
                                {t("bulk-check-in")}
                            </Button>
                        </>
                    )}
                </>
            }
            className=" w-4/12 p-10"
        >
            {onLoading ? (
                <Loading />
            ) : (
                <>
                    <div>{t("are-you-sure-you-want-to-perform-a-bulk-check-in-for-all-empty-rooms")}</div>
                    <div className=" mt-2 text-gray-700">
                        {t("please-note-that-completing-the-check-in-for-all-empty-rooms-might-take-a-few-minutes")}
                    </div>
                </>
            )}
        </Modal>
    );
};

export const useModalBulkCheckin = () => {
    const { open, close } = useModal();

    const openModal = (props) => {
        const newProps = {
            close,
            ...props,
        };

        open(<ModalContent {...newProps} />);
    };

    return {
        open: openModal,
        close,
    };
};

export default useModalBulkCheckin;
