import React, { useRef, useEffect, useContext, useState, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";

import { ShopContext } from "contexts/Sales/Shop";

import { usePMSConfigUpdate } from "hooks/Data/useShopConfig";

import Loading from "components/Loading";
import SettingsCard from "components/SettingsCard";
import ErrorInfo from "components/ErrorInfo";
import TextInput from "components/TextInput";

import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Switch from "components/Switch";

const ShopSettingsPMS = () => {
    const { t } = useTranslation();

    const modalRef = useRef(null);
    const changeRef = useRef(null);
    const { open: openModal, close: closeModal } = useModal();

    const submit = () => {
        if (changeRef?.current) {
            changeRef.current.submit();
        }
    };

    const onSuccess = () => {
        closeModal();
        refresh();
    };

    const { refresh, id, permissions, pms } = useContext(ShopContext);

    let pmsChargesDescription = `${t("pms-charges-settings-description")}`;
    if (permissions?.ordersManagement) {
        pmsChargesDescription += `\n${t("pms-charges-settings-process-with-orders")}`;
    } else {
        pmsChargesDescription += `\n${t("pms-charges-settings-process-without-orders")}`;
    }

    const editPMSConfig = () => {
        openModal(
            <Modal
                ref={modalRef}
                id="modal-pms-charges"
                title={`${t("PMS charges")} - ${t("edit")}`}
                footer={
                    <>
                        <Button design="blue-outline" id="modal-button-cancel" onClick={closeModal}>
                            {t("cancel")}
                        </Button>
                        <Button design="blue" id="modal-button-save" onClick={submit}>
                            {t("save")}
                        </Button>
                    </>
                }
                minWidth="30rem"
            >
                <EditPMSConfig
                    ref={changeRef}
                    id={id}
                    values={pms?.data}
                    onSuccess={onSuccess}
                    onLoading={(loading) => {
                        if (modalRef.current) {
                            modalRef.current.setLoading(loading);
                        }
                    }}
                />
            </Modal>
        );
    };

    useEffect(() => {
        if (pms) {
            pms.load();
        }
    }, []);

    return (
        <SettingsCard
            id="settings-pms-charges"
            title={t("PMS charges")}
            description={pmsChargesDescription}
            edit={{
                disabled: !pms?.ready,
                onClick: editPMSConfig,
            }}
        >
            {(() => {
                if (pms?.loading) {
                    return <Loading />;
                }

                if (pms?.error) {
                    return <ErrorInfo retry={pms.get}>{pms.error}</ErrorInfo>;
                }

                if (!pms?.data?.enabled) {
                    return (
                        <div id="pms-charges-disabled" className="text-gray-500">
                            {t("PMS charges disabled")}
                        </div>
                    );
                }

                return (
                    <SettingsCard.ParamsList
                        data={[
                            {
                                id: "pms-charges-id",
                                name: t("PMS charge ID"),
                                description: pms?.data?.id,
                            },
                            {
                                id: "pms-charges-desc",
                                name: t("PMS charge description"),
                                description: pms?.data?.description,
                            },
                        ]}
                    />
                );
            })()}
        </SettingsCard>
    );
};

const EditPMSConfig = forwardRef(({ id, values, onSuccess, onLoading }, ref) => {
    const { t } = useTranslation();

    const inputIDRef = useRef(null);
    const { update, loading, error, success } = usePMSConfigUpdate();

    const [inputValues, setInputValues] = useState({
        enabled: values?.enabled,
        id: values?.id,
        description: values?.description,
    });

    const updateSettings = () => {
        if (!loading) {
            const isValid = !inputValues?.enabled || (inputValues?.id && inputValues?.description);
            if (isValid) {
                update({ id, pmsConfig: inputValues });
            }
        }
    };

    useImperativeHandle(ref, () => ({
        submit: () => {
            updateSettings();
        },
    }));

    useEffect(() => {
        if (inputIDRef.current && inputValues?.enabled) {
            inputIDRef.current.focus();
        }
    }, [inputValues?.enabled]);

    useEffect(() => {
        if (onLoading) {
            onLoading(loading);
        }
    }, [loading]);

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess();
        }
    }, [success]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <ErrorInfo>{error}</ErrorInfo>;
    }

    return (
        <>
            <div className="mb-5">
                {t("pms-charges-enable-switch")}
                <Switch
                    id={`pms-charges-enable`}
                    checked={inputValues?.enabled}
                    className="my-2"
                    onChange={({ checked }) => {
                        setInputValues({ ...inputValues, enabled: checked });
                    }}
                />
            </div>

            <div className="mb-5">{t("pms-charges-edit-description")}</div>

            <div className="mb-3">
                <div className="font-bold">{t("PMS charge ID")}*</div>
                <div className="mt-1">
                    <TextInput
                        ref={inputIDRef}
                        id="pms-charge-id"
                        required={true}
                        placeholder={t("example something", { something: "111" })}
                        disabled={!inputValues?.enabled}
                        value={inputValues?.id}
                        onChange={(value) => setInputValues({ ...inputValues, id: value })}
                    />
                </div>
            </div>
            <div>
                <div className="font-bold">{t("PMS charge description")}*</div>
                <div className="mt-1">
                    <TextInput
                        id="pms-charge-description"
                        required={true}
                        placeholder={t("example something", { something: t("room-service") })}
                        disabled={!inputValues?.enabled}
                        value={inputValues?.description}
                        onChange={(value) => setInputValues({ ...inputValues, description: value })}
                    />
                </div>
            </div>
        </>
    );
});

export default ShopSettingsPMS;
