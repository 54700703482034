import React, { useContext, useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//Components
import EmptyWidget from "./WidgetToolbars/CommonOptions/EmptyWidget";
import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";
import { vw2px } from "../../../../hooks/Utils/DesignUtils";
// import { GetThemeDefault } from "../../../../hooks/Utils/DesignUtils";

const WidgetHTML = () => {
    const { deviceType } = useContext(EditorContext);
    const { config, type, id: widgetID } = useContext(WidgetContext);

    //Params
    const { lang } = useParams();

    //State
    const [code, setCode] = useState(null);

    //Store data
    const { gridItems } = useSelector((state) => state.design);

    // const defaultBgColor = GetThemeDefault({ theme, id: "bgColor" });
    // const defaultFgColor = GetThemeDefault({ theme, id: "fgColor" });

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const data = config?.data || Widget.ParseData(widget ? widget.data : null, deviceType);

    useEffect(() => {
        setCode(data && data.code ? (data.code[lang] ? data.code[lang] : data.code.text ? data.code.text : "") : "");
        // eslint-disable-next-line
    }, [gridItems]);

    useEffect(() => {
        if (document.querySelector(`#widget_zone_${widgetID}`)) {
            document.querySelector(`#widget_zone_${widgetID}`).style.backgroundColor = code ? "transparent" : "inherit";
        }
        // eslint-disable-next-line
    }, [code]);

    return (
        <div className="overflow-hidden w-full h-full">
            {code ? (
                <iframe
                    title="preview"
                    className={`w-full h-full mx-auto`}
                    srcDoc={`<div style="font-size:${vw2px(1.6)}px;">${code}</div>`}
                    width={``}
                ></iframe>
            ) : (
                <EmptyWidget type={type}></EmptyWidget>
            )}

            <div className="absolute left-0 top-0 w-full h-full bg-transparent"></div>
        </div>
    );
};

export default WidgetHTML;
