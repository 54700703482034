import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { setItemName } from "../../actions/globalActions";

const UseInputText = ({ validation }) => {
    const { langStrings } = useSelector((state) => state.ui);
    //States
    const [itemNameError, setItemNameError] = useState(validation);

    //Actions
    const dispatch = useDispatch();
    const changeNameHandler = (e) => {
        if (!e.target.value || e.target.value === "") {
            setItemNameError(false);
            dispatch(setItemName(""));
        } else {
            setItemNameError(true);
            dispatch(setItemName(e.target.value));
        }
    };

    //Listeners
    useEffect(() => {
        setItemNameError(validation);
    }, [validation]);

    return (
        <>
            <input
                type="text"
                placeholder={langStrings["name"]}
                className={`w-full h-10 bg-gray-200 rounded relative mr-4 p-2 ${
                    !itemNameError ? "border border-red-100" : ""
                }`}
                onBlur={changeNameHandler}
            ></input>
            {!itemNameError ? (
                <p className="absolute font-normal text-red-100">
                    {
                        langStrings[
                            `${langStrings["please"]}, ${langStrings["write-name"]} ${langStrings["of"]} ${langStrings["schedule"]}.`
                        ]
                    }
                </p>
            ) : null}
        </>
    );
};

export default UseInputText;
