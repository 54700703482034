const GridFilters = (data) => {
    return {
        title: "filters",
        value: data?.name ? data.name : "",
        executeSectionQuery: true,
        bodyAdjust: "w-8/12",
        inputs: [
            {
                type: "gridChannelsFilters",
                data: data ? data : {},
            },
        ],
        buttons: [{ name: "close", style: "blue", action: "close" }],
    };
};

export default GridFilters;
