import React from "react";
import { useTranslation } from "react-i18next";

const UseModalAutomationOfGuestReport = () => {
    const { t } = useTranslation();
    const fields = [
        "name",
        "surname",
        "gender",
        "nationality",
        "date-of-birth",
        "identification-document-document-and-number",
        "date-of-issue",
    ];

    return (
        <div>
            <div>
                {fields.map((field) => {
                    return <div className=" mb-2 font-bold">{t(field)}</div>;
                })}
                <div className=" mb-2">
                    {t("if-activated-they-will-automatically-be-marked-as-mandatory")}{" "}
                    <span className=" font-bold">{t("pre-checkin-information")}</span>
                </div>
                <div>{t("are-you-sure-you-want-to-continue")}</div>
            </div>
        </div>
    );
};

export default UseModalAutomationOfGuestReport;
