import React from "react";
//Components
import UseTable from "../../../Table/";
//Utils
import ListCompanions from "../../../../hooks/GraphqlCalls/Customers/ListCompanions";

const GuestCompanions = () => {
    ListCompanions();
    return (
        <>
            <UseTable />
        </>
    );
};

export default GuestCompanions;
