import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withApollo } from "@apollo/react-hoc";
import uuid from "react-uuid";
import { useParams } from "react-router-dom";
import { setExecuteQuery } from "../../../actions/sectionActions";
import { setModalContent, closeModal, showGlobalLoading } from "../../../actions/uiActions";
import { cleanActionValues } from "../../../actions/globalActions";
import Widget from "../../../components/Section/Design/Widgets/Widget";

const UpdateTVMenuNav = ({ client }) => {
    const dispatch = useDispatch();
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { itemSelected, gridItems } = useSelector((state) => state.design);
    const actionData = useSelector((state) => state.action);
    const { lang: langParam } = useParams();

    useEffect(() => {
        if (
            executeQuery &&
            executeQuery.action &&
            (executeQuery.action === "create-item-widget-nav" ||
                executeQuery.action === "edit-item-widget-nav" ||
                executeQuery.action === "duplicate-item-widget-nav" ||
                executeQuery.action === "hide-visibility-item-widget-nav" ||
                executeQuery.action === "delete-item-widget-nav" ||
                executeQuery.action === "sort-actions-items-widget-nav")
        ) {
            const action = executeQuery && executeQuery.action ? executeQuery.action : null;
            const currItemSelected = itemSelected ? itemSelected : null;
            const gridItem = gridItems.filter((gridItem) => gridItem.i === currItemSelected)[0];
            const widget =
                gridItems && currItemSelected ? gridItems.filter((w) => w && w.i === currItemSelected)[0] : null;
            const values = actionData.values;
            const actionItem = values["action-destination"] ? values["action-destination"] : null;
            const itemId = values["save-item-id"] ? values["save-item-id"] : null;
            const resourceItemSelected = values["option-resource-selected"];
            const iconItem = values["icon-selected"];
            const libraryRefItem = values["image-selected"] ? values["image-selected"] : null;

            let nameItem = values["menu-nav-item-name"];
            let item = {};
            let indexItem = -1;
            let itemNavSelected = null;
            const newData = gridItem.data || {};
            let newActions = gridItem.actions || [];
            if (newData["items"]) {
                newData.items = [...gridItem.data.items];
            } else {
                newData.items = [];
            }

            indexItem = newData && newData.items && newData.items.findIndex((item) => item.id === itemId);

            if (indexItem > -1 && action !== "edit-item-widget-nav" && action !== "delete-item-widget-nav") {
                itemNavSelected = newData.items[indexItem];
                item = { ...itemNavSelected };
            }

            if (item["name"] && item["name"][langParam]) {
                item["name"][langParam] = nameItem;
            } else {
                item["name"] = { [langParam]: nameItem };
            }
            item["id"] = item && item.id ? item.id : uuid();
            item["visibility"] = true;
            if (resourceItemSelected === "icon-library") {
                if (iconItem) {
                    item["icon"] = iconItem.icon;
                    item["lib"] = iconItem.lib;
                } else {
                    delete item["icon"];
                    delete item["lib"];
                }
                delete item["libraryRef"];
            } else if (resourceItemSelected === "image-library") {
                item["libraryRef"] = libraryRefItem;
                delete item["icon"];
                delete item["lib"];
            }

            switch (action) {
                case "create-item-widget-nav":
                    newData.items.push(item);
                    newActions.push(actionItem);
                    break;
                case "edit-item-widget-nav":
                    newData.items[indexItem] = item;
                    newActions[indexItem] = actionItem;
                    break;
                case "duplicate-item-widget-nav":
                    const idItemDuplicate = executeQuery.params.itemId;
                    const itemToDuplicate = newData.items.find((item) => item.id === idItemDuplicate);
                    const itemDuplicated = { ...itemToDuplicate };
                    const indexItemToDuplicate = newData.items.findIndex((item) => item.id === idItemDuplicate);
                    const actionDuplicated = newActions[indexItemToDuplicate];
                    itemDuplicated["id"] = uuid();
                    newData.items.push(itemDuplicated);
                    newActions.push(actionDuplicated);
                    break;
                case "hide-visibility-item-widget-nav":
                    const itemIdNavAffected = executeQuery.params.itemId;
                    const itemToAffect = newData.items.find((item) => item.id === itemIdNavAffected);
                    itemToAffect["visibility"] = false;
                    break;
                case "delete-item-widget-nav":
                    const itemIdNavToDelete = itemId;
                    const indexItemIdNavToDelete = newData.items.findIndex((item) => item.id === itemIdNavToDelete);
                    newActions.splice(indexItemIdNavToDelete, 1);
                    newData.items.splice(indexItemIdNavToDelete, 1);
                    break;
                case "sort-actions-items-widget-nav":
                    newActions = executeQuery.params.actionsSorted;
                    newData.items = executeQuery.params.itemsSorted;
                    break;
                default:
                    break;
            }

            if (
                action === "create-item-widget-nav" ||
                action === "edit-item-widget-nav" ||
                action === "duplicate-item-widget-nav" ||
                action === "delete-item-widget-nav" ||
                action === "sort-actions-items-widget-nav"
            ) {
                dispatch(
                    setExecuteQuery(
                        Widget.UpdateData({
                            widget,
                            property: "items",
                            value: newData.items,
                            extraArgs: {
                                actions: { actions: newActions },
                                type: "TVMENUNAV",
                            },
                        })
                    )
                );
                dispatch(cleanActionValues());
                dispatch(setModalContent(false));
                dispatch(closeModal());
            } else {
                dispatch(cleanActionValues());
                dispatch(setModalContent(false));
                dispatch(closeModal());
                dispatch(
                    setExecuteQuery(
                        Widget.UpdateData({
                            widget,
                            property: "items",
                            value: newData.items,
                        })
                    )
                );
            }

            if (
                action === "create-item-widget-nav" ||
                action === "edit-item-widget-nav" ||
                action === "duplicate-item-widget-nav" ||
                action === "delete-item-widget-nav" ||
                action === "hide-visibility-item-widget-nav" ||
                action === "sort-actions-items-widget-nav"
            ) {
                dispatch(showGlobalLoading(true));
                setTimeout(() => {
                    dispatch(showGlobalLoading(false));
                }, 1500);
            }
        }
        // eslint-disable-next-line
    }, [executeQuery]);
    return null;
};

export default withApollo(UpdateTVMenuNav);
