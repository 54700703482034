import i18n from "i18n";

const DeleteCustomItem = ({ action, name }) => {
    return {
        title: name + " - " + i18n.t("delete-item"),
        bodyAdjust: "w-3/12",
        executeSectionQuery: true,
        inputs: [
            {
                type: "void",
                text: i18n.t("are-you-sure-you-want-to-delete-custom-item", { name: name }),
            },
            {
                type: "void",
                text: "please-note-that-item-will-also-dissapear-in-the-guests-information",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "delete",
                style: "red",
                action: action ? action : "delete-custom-item-field",
                actionCallback: () => {
                    if (typeof action === "function") {
                        action();
                    }
                },
            },
        ],
    };
};

export default DeleteCustomItem;
