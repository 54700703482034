export const UPDATE_PRODUCT_NAME_AND_DESCRIPTION = "update-product-name-and-description";
export const UPDATE_PRODUCT_NAME_TRANSLATIONS = "update-product-name-translations";
export const UPDATE_PRODUCT_PRICES = "update-product-prices";
export const UPDATE_PRODUCT_ALLERGENS_AND_LABELS = "update-product-allergens-and-labels";
export const UPDATE_ENABLED_ALLERGENS = "update-enabled-allergens";
export const UPDATE_PRODUCT_SETTINGS = "edit-product-settings";
export const UPDATE_PRODUCT_DELETE_ASSET = "update-product-delete-asset";
export const UPDATE_PRODUCT_CREATE_ASSET = "update-product-create-asset";
export const UPDATE_PRODUCT_ADD_ASSET = "update-product-add-asset";
export const UPDATE_PRODUCT_GET_ASSETS = "update-product-get-assets";
export const UPDATE_PRODUCT_FEATURED_IMAGE = "update-product-featured-image";
export const UPDATE_PRODUCT_ASSETS = "update-product-assets";
export const UPDATE_PRODUCT_AVAILABLE = "update-product-available";
export const UPDATE_PRODUCT_DESCRIPTION = "edit-description-product";

export const ADD_PRODUCT_EXTRA = "add-product-extra";
export const UPDATE_PRODUCT_EXTRA = "update-product-extra";
export const UPDATE_PRODUCT_EXTRAS = "update-product-extras";
export const DELETE_PRODUCT_EXTRA = "delete-product-extra";
