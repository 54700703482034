import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Session } from "../../../../hooks/Utils/Session";
import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";
import { SCREEN } from "constants/editor";
import WidgetElement from "../../../Widget";
import { WIDGET } from "constants/editor";

const WidgetTopBar = () => {
    const { deviceType, editorSize, screenType } = useContext(EditorContext);
    const { config: cfg, id: widgetID } = useContext(WidgetContext);
    const { lang } = useParams();

    const isLanding = screenType === SCREEN.TYPE.MOBILE;

    const { gridItems } = useSelector((state) => state.design);
    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const globalData = widget?.data;

    const propertyName = Session.getProject()?.name || "Property";

    const deviceConfig = cfg?.data || Widget.ParseData(globalData, deviceType);

    const styles = cfg?.style?.export();

    const texts = {
        title: isLanding ? propertyName : deviceConfig?.title?.text || deviceConfig?.title?.[lang],
    };

    return (
        <WidgetElement
            id={"widget_topbar_" + widgetID}
            type={WIDGET.TYPE.TOPBAR}
            device={deviceType}
            deviceWidth={editorSize?.width}
            screenType={screenType}
            style={styles}
            texts={texts}
        />
    );
};

export default WidgetTopBar;
