import { useLazyQuery, useMutation } from "react-apollo";
import { GET_DAILY_CREDENTIALS_RESET, SET_DAILY_RESET_CREDENTIALS_MUTATION } from "./services/dailyResetQueries";
import { useEffect, useState } from "react";
import { DailyReset } from "./models/DailyReset";

export const useGetDailyResetDate = () => {
    const [credentialsData, setCredentialsData] = useState();

    const [executeGetDailyResetDate, { data, loading, called }] = useLazyQuery(GET_DAILY_CREDENTIALS_RESET, {
        fetchPolicy: "network-only",
    });
    const [executeMutationCredentialsDate] = useMutation(SET_DAILY_RESET_CREDENTIALS_MUTATION);

    useEffect(() => {
        executeGetDailyResetDate();
    }, []);

    useEffect(() => {
        if (called && !loading) {
            const dailyReset = data.getDailyResetDeviceCredentialsDate;
            if (dailyReset) {
                setCredentialsData(DailyReset(dailyReset?.enable, formatTime(dailyReset?.time)));
            }
        }
    }, [data]);

    return {
        mutate: (enable, time) =>
            executeMutationCredentialsDate({
                variables: {
                    enable,
                    time,
                },
            }),
        credentialsData,
        called,
        loading,
    };
};

export const formatTime = (fechaStr) => {
    const horas = fechaStr.slice(0, 2);
    const minutos = fechaStr.slice(3, 5);

    return `${horas}:${minutos}`;
};
