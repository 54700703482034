import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { setExecuteQuery } from "../../../../../actions/sectionActions";
import { setRefreshData } from "../../../../../actions/tableActions";
import { changeActionValues, setActionValidations } from "../../../../../actions/globalActions";
import {
    validate244IPaddress,
    validateNoDecimals,
    validatePositive,
    validateSection,
    validateUrl,
} from "../../../../../hooks/Utils/Validations";

import UseComponentLoading from "../../../../useComponentLoading";
import UseCheckBox from "../../../../Inputs/useCheckBox";
import UseInputText from "../../../../Table/useInputText";
import { CORPORATE_CHANNEL } from "../../../../../hooks/Utils/Services/ChannelsUtils";
import ReactTooltip from "components/ReactTooltip";
import { useLazyQuery } from "react-apollo";
import { POLLINTERVAL_15S } from "hooks/Utils/Utils";
import CoaxialTunes from "./CoaxialTunes";

const ChannelEditionTuningCard = (props) => {
    //Consts&states
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { data: dataChannel = {} } = props;
    const { channelData: channelInitialData = null } = dataChannel;
    const { refreshData } = useSelector((state) => state.sectionContent);
    const { validations } = useSelector((state) => state.action);
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [tuneData, setTuneData] = useState(null);
    const [dvbTunes, setDvbTunes] = useState(null);
    const [runUpdate, setRunUpdate] = useState(false);
    const sectionName = "channel-tuning";
    const actionData = useSelector((state) => state.action);
    const inputsName = [
        `${sectionName}-udp`,
        `${sectionName}-ip`,
        `${sectionName}-port`,
        `${sectionName}-hls`,
        `${sectionName}-url`,
    ];

    const preventClick = useRef();
    const urlInputEnabled = useRef();

    //queries&mutations
    const GET_DATA = gql`
        {
            tunes: tunes
                (filter:{id:${channelInitialData?.id}})
                {
                results {
                    id
                    tuneInfo
                }
            }
        }
    `;

    //useQueries&useMutations
    const [executeQuery, { data, loading: loadingData, error, refetch, networkStatus }] = useLazyQuery(GET_DATA, {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        pollInterval: POLLINTERVAL_15S,
        onCompleted: () => {
            setLoading(false);
        },
    });

    //effects
    useEffect(() => {
        dispatch(setActionValidations({}));
        setLoading(true);
        executeQuery();
    }, []);

    useEffect(() => {
        if (!loadingData && !error && networkStatus === 7 && data && data.tunes) {
            setLoading(false);
            setInitialLoading(false);
            if (data.tunes.results && data.tunes.results.length > 0) {
                const tuneData = arrangeData(data.tunes.results[0]);
                if (tuneData.tuneInfo) {
                    let dvb = [];
                    Object.keys(tuneData.tuneInfo).forEach((tuneType) =>
                        tuneType != "hls" && tuneType != "udp"
                            ? (dvb = [...dvb, { type: tuneType, tune: tuneData.tuneInfo[tuneType] }])
                            : null
                    );
                    setDvbTunes(dvb);
                }
                setTuneData(tuneData);
                handleClickInput(inputsName[0], tuneData?.tuneInfo?.udp?.isEnabled ? true : false);
                handleClickInput(inputsName[1], tuneData?.tuneInfo?.udp?.ip);
                handleClickInput(inputsName[2], tuneData?.tuneInfo?.udp?.port);
                handleClickInput(inputsName[3], tuneData?.tuneInfo?.hls?.isEnabled ? true : false);
                urlInputEnabled.current = tuneData?.tuneInfo?.hls?.isEnabled;
                handleClickInput(inputsName[4], tuneData?.tuneInfo?.hls?.url);
            }
        }
    }, [data, networkStatus]);

    useEffect(() => {
        if (refreshData) {
            setLoading(false);
            dispatch(setRefreshData(false));
            refetch && refetch();
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (runUpdate) {
            setRunUpdate(false);
            let tuneInfo = tuneData && tuneData.tuneInfo ? { ...tuneData.tuneInfo } : {};
            tuneInfo.udp = {
                isEnabled: actionData.values[inputsName[0]] ? true : false,
                ip: actionData.values[inputsName[1]],
                port: actionData.values[inputsName[2]],
            };
            tuneInfo.hls = {
                isEnabled: actionData.values[inputsName[3]] ? true : false,
                url: actionData.values[inputsName[4]],
            };
            tuneInfo = clearIncorrectData(tuneInfo);
            const validations = validateSection("update-tune", tuneInfo, t, { inputsName: inputsName });
            dispatch(setActionValidations(validations));
            const validationsArray = Object.values(validations);
            if (validationsArray.filter((item) => item === false).length <= 0) {
                dispatch(
                    setExecuteQuery({
                        action: "update-tune",
                        params: {
                            id: tuneData.id,
                            tuneInfo: JSON.stringify(JSON.stringify(tuneInfo)),
                        },
                    })
                );
            } else {
                toast.error(t("errors-in-red"));
            }
        }
    }, [runUpdate]);

    //handle&Functions
    const handleClickInput = (name, value) => {
        dispatch(changeActionValues({ [name]: value }));
    };

    const handleUpdateChannel = () => {
        setRunUpdate(true);
    };

    //renders
    return (
        <>
            {initialLoading ? (
                <UseComponentLoading />
            ) : (
                <>
                    <div className="ChannelEditionCard w-full">
                        <div className="flex w-full text-left mb-5">
                            <span className="font-bold text-gray-900 text-1xl">{t("set-tuning")}</span>
                        </div>

                        <div className="ChannelEditionCard-configuration">
                            <div className=" flex items-center">
                                <div className=" w-auto  ">
                                    <UseCheckBox
                                        checked={tuneData?.tuneInfo?.udp?.isEnabled}
                                        id={inputsName[0]}
                                        name="udp-multicast"
                                        translatable={true}
                                        useAllSpace={false}
                                        onClick={(value) => {
                                            handleClickInput(inputsName[0], value);
                                            handleUpdateChannel();
                                        }}
                                    />
                                </div>
                                {channelInitialData?.type === CORPORATE_CHANNEL.value ? (
                                    <i
                                        onMouseOver={() => {
                                            ReactTooltip.rebuild();
                                        }}
                                        data-for={"channel-edition-tuning-card-tooltip"}
                                        data-tip={t("recommended-ip-range", { range: "239.0.0.0/8" })}
                                        className=" icon icon-info text-2xl ml-2 text-gray-700"
                                    ></i>
                                ) : null}
                            </div>
                            <div className="text-left ml-8 pr-8 w-full flex mb-2">
                                <label className="ChannelEditionCard-configuration--label mt-2">
                                    {!validateDisabled({
                                        name: inputsName[1],
                                        loading,
                                        actionData,
                                        validations,
                                        inputsName,
                                    })
                                        ? "*"
                                        : ""}
                                    {t("ip")}
                                </label>
                                <div className="w-full">
                                    <UseInputText
                                        disabled={validateDisabled({
                                            name: inputsName[1],
                                            loading,
                                            actionData,
                                            validations,
                                            inputsName,
                                        })}
                                        currentValue={tuneData?.tuneInfo?.udp?.ip}
                                        id={inputsName[1]}
                                        inputData={{ name: inputsName[1] }}
                                        key={inputsName[1]}
                                        ph={t("ip-example")}
                                        onFocusAction={() => (preventClick.current = true)}
                                        onBlurAction={(value) => {
                                            setTimeout(function () {
                                                preventClick.current = false;
                                            }, 300);
                                            handleClickInput(inputsName[1], value);
                                            handleUpdateChannel();
                                        }}
                                        validation={validations && validations[inputsName[1]] === false ? false : true}
                                    />
                                    {validations && validations[inputsName[1]] === false ? (
                                        <span className="block text-sm text-red-100">
                                            {t("ip-range-available", { range: "224.0.0.0/4" })}
                                        </span>
                                    ) : null}
                                </div>
                            </div>

                            <div className="text-left ml-8 pr-8 w-full flex">
                                <label className="ChannelEditionCard-configuration--label mt-2">
                                    {!validateDisabled({
                                        name: inputsName[2],
                                        loading,
                                        actionData,
                                        validations,
                                        inputsName,
                                    })
                                        ? "*"
                                        : ""}
                                    {t("port")}
                                </label>
                                <UseInputText
                                    disabled={validateDisabled({
                                        name: inputsName[2],
                                        loading,
                                        actionData,
                                        validations,
                                        inputsName,
                                    })}
                                    currentValue={tuneData?.tuneInfo?.udp?.port}
                                    id={inputsName[2]}
                                    inputData={{ name: inputsName[2] }}
                                    key={inputsName[2]}
                                    ph={t("port-example")}
                                    onFocusAction={() => (preventClick.current = true)}
                                    onBlurAction={(value) => {
                                        setTimeout(function () {
                                            preventClick.current = false;
                                        }, 300);
                                        handleClickInput(inputsName[2], value);
                                        handleUpdateChannel();
                                    }}
                                    validation={validations && validations[inputsName[2]] === false ? false : true}
                                />
                            </div>
                        </div>

                        {channelInitialData.type !== CORPORATE_CHANNEL.value ? (
                            <>
                                <div className="ChannelEditionCard-configuration">
                                    <UseCheckBox
                                        checked={urlInputEnabled.current}
                                        forceUpdateChecked={true}
                                        id={inputsName[3]}
                                        name="hls-channel"
                                        translatable={true}
                                        preventClick={preventClick}
                                        useAllSpace={false}
                                        onClick={(value) => {
                                            urlInputEnabled.current = value;
                                            handleClickInput(inputsName[3], value);
                                            handleUpdateChannel();
                                        }}
                                    />
                                    <div className="text-left ml-8 pr-8 w-full flex mb-2">
                                        <label className="ChannelEditionCard-configuration--label mt-2">
                                            {!validateDisabled({
                                                name: inputsName[4],
                                                loading,
                                                actionData,
                                                validations,
                                                inputsName,
                                            })
                                                ? "*"
                                                : ""}{" "}
                                            {t("url")}
                                        </label>
                                        <div className="w-full">
                                            <UseInputText
                                                disabled={!urlInputEnabled.current}
                                                currentValue={tuneData?.tuneInfo?.hls?.url}
                                                id={inputsName[4]}
                                                inputData={{ name: inputsName[4] }}
                                                key={inputsName[4]}
                                                onBlurAction={(value) => {
                                                    handleClickInput(inputsName[4], value);
                                                    handleUpdateChannel();
                                                }}
                                                validation={
                                                    validations && validations[inputsName[4]] === false ? false : true
                                                }
                                            />
                                            {validations && validations[inputsName[4]] === false ? (
                                                <span className="block text-sm text-red-100">{t("incorrect-url")}</span>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <CoaxialTunes tuneData={tuneData} dvbTunes={dvbTunes} setDvbTunes={setDvbTunes} />
                            </>
                        ) : null}
                    </div>
                </>
            )}
            <ReactTooltip
                place="bottom"
                id="channel-edition-tuning-card-tooltip"
                type="light"
                offset={{ top: -8, left: -8 }}
                html={true}
                border={true}
                multiline={true}
                borderColor="#D3DAE1"
            />
        </>
    );
};

//arrangeData
const arrangeData = (data) => {
    let response = null;
    if (data) {
        response = { ...data };
        if (response.tuneInfo) {
            const tuneInfoJson = JSON.parse(data.tuneInfo);
            response.tuneInfo = tuneInfoJson;
        }
    }
    return response;
};

const validateDisabled = (props) => {
    const { name, loading, actionData, inputsName } = props;
    let response = true;
    if (!loading && actionData?.values) {
        response = false;
        switch (name) {
            case inputsName[1]:
            case inputsName[2]:
                response = !actionData.values[inputsName[0]];
                break;
            case inputsName[4]:
                response = !actionData.values[inputsName[3]];
                break;
        }
    }

    return response;
};

const clearIncorrectData = (tuneInfo) => {
    let response = tuneInfo;
    if (response) {
        if (!response.udp.isEnabled) {
            if (response.udp.ip && !validate244IPaddress(response.hls.url)) {
                delete response.udp.url;
            }
            if (response.udp.port && (!validatePositive(response.udp.port) || !validateNoDecimals(response.udp.port))) {
                delete response.udp.port;
            }
        }
        if (!response.hls.isEnabled && response.hls.url && !validateUrl(response.hls.url)) {
            delete response.hls.url;
        }
    }
    return response;
};

export default ChannelEditionTuningCard;
