import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { sortLangs } from "hooks/Utils/Utils";
import UseTabs from "components/useTabs";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { setActionValues } from "actions/globalActions";
import { useMSQuery } from "hooks/GraphqlCalls/useMSQuery";
import { gql } from "apollo-boost";
import UseComponentLoading from "components/useComponentLoading";
import { closeModal } from "actions/uiActions";
import { cleanActionValues } from "actions/globalActions";
import { setModalContent } from "actions/uiActions";
import HTMLReactParser from "html-react-parser";
import ReactTooltip from "react-tooltip";
import { useAuth } from "hooks/Session/auth";

const UseModalTranslateTextsTabs = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { languages: projectLangs } = useAuth();
    const [titles, setTitles] = useState();
    const [contents, setContents] = useState();

    const sortedByDefaultValue = sortLangs(projectLangs, t);
    const inEditionLang = useRef(0);

    const connLostChars = useRef([]);
    const connectionLostAllMsg = useRef({});
    const connReadyChars = useRef([]);
    const connectionReadyAllMsg = useRef({});
    const showLostEditor = useRef(true);
    const showReadyEditor = useRef(true);
    //queries&mutations
    const GET_DATA = gql`
        {
            highAvailabilityConfig {
                connectionLost
                connectionRestored
                defaultConnectionLost
                defaultConnectionRestored
            }
        }
    `;

    //useQueries&useMutations
    const [executeQuery, { data, loading, error }] = useMSQuery(GET_DATA, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (data) {
            //init refs
            inEditionLang.current = 0;
            sortedByDefaultValue.forEach((lang) => {
                const lostMsg = data.highAvailabilityConfig?.connectionLost?.[lang.languageRef] || "";
                connLostChars.current.push(lostMsg.length);
                connectionLostAllMsg.current = {
                    ...connectionLostAllMsg.current,
                    [lang.languageRef]: lostMsg,
                };
                const redyMsg = data.highAvailabilityConfig?.connectionRestored?.[lang.languageRef] || "";
                connReadyChars.current.push(redyMsg.length);
                connectionReadyAllMsg.current = {
                    ...connectionReadyAllMsg.current,
                    [lang.languageRef]: redyMsg,
                };
            });
            // TITLES
            renderTitles();
            // CONTENTS
            renderContent();
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            dispatch(closeModal());
            dispatch(cleanActionValues());
            dispatch(setModalContent(false));
        }
    }, [error]);

    const toolbarEditor = {
        options: ["inline"],
        inline: {
            options: ["bold", "italic", "underline"],
            bold: { icon: "", className: "icon icon-bold text-3xl text-gray-800 rdw-bold-button " },
            italic: { icon: "", className: "icon icon-italic text-3xl text-gray-800 rdw-italic-button" },
            underline: { icon: "", className: "icon-underline text-3xl text-gray-800 rdw-underline-button" },
        },
    };

    const langCompleted = (lang) => {
        //?si no es prederterminado y esta modificado
        const defaultLostMsg = predefinedConvertToHTML(
            data.highAvailabilityConfig.defaultConnectionLost[lang] ||
                data.highAvailabilityConfig.defaultConnectionLost.en
        );
        const defaultRestoredMsg = predefinedConvertToHTML(
            data.highAvailabilityConfig.defaultConnectionRestored[lang] ||
                data.highAvailabilityConfig.defaultConnectionRestored.en
        );

        if (
            connectionLostAllMsg.current[lang] !== "" &&
            connectionLostAllMsg.current[lang] !== "<p></p>" &&
            connectionLostAllMsg.current[lang] !== "<p></p>\n" &&
            getPlainText(connectionLostAllMsg.current[lang]) !== getPlainText(defaultLostMsg) &&
            connectionReadyAllMsg.current[lang] !== "" &&
            connectionReadyAllMsg.current[lang] !== "<p></p>" &&
            connectionReadyAllMsg.current[lang] !== "<p></p>\n" &&
            getPlainText(connectionReadyAllMsg.current[lang]) !== getPlainText(defaultRestoredMsg)
        ) {
            return true;
        }

        return false;
    };
    const langPartialCompleted = (lang) => {
        //?si no es prederterminado y esta modificado uno de los dos textos
        const defaultLostMsg = predefinedConvertToHTML(
            data.highAvailabilityConfig.defaultConnectionLost[lang] ||
                data.highAvailabilityConfig.defaultConnectionLost.en
        );
        const defaultRestoredMsg = predefinedConvertToHTML(
            data.highAvailabilityConfig.defaultConnectionRestored[lang] ||
                data.highAvailabilityConfig.defaultConnectionRestored.en
        );

        if (
            (connectionLostAllMsg.current[lang] !== "" &&
                connectionLostAllMsg.current[lang] !== "<p></p>" &&
                connectionLostAllMsg.current[lang] !== "<p></p>\n" &&
                getPlainText(connectionLostAllMsg.current[lang]) !== getPlainText(defaultLostMsg)) ||
            (connectionReadyAllMsg.current[lang] !== "" &&
                connectionReadyAllMsg.current[lang] !== "<p></p>" &&
                connectionReadyAllMsg.current[lang] !== "<p></p>\n" &&
                getPlainText(connectionReadyAllMsg.current[lang]) !== getPlainText(defaultRestoredMsg))
        ) {
            return true;
        }

        return false;
    };

    const renderTitles = () => {
        let titlesArray = [];

        // eslint-disable-next-line
        sortedByDefaultValue.map((lang) => {
            titlesArray.push({
                id: `tab-${lang.languageRef}`,
                name: t("language:" + lang.languageRef) || lang.languageRef,
                image: langCompleted(lang.languageRef)
                    ? require(`../../assets/images/icons/save-circle-green.svg`)
                    : langPartialCompleted(lang.languageRef)
                    ? require(`../../assets/images/icons/save-circle-gray.svg`)
                    : null,
                tooltip: lang.isDefault ? t("default-lang") : null,
            });
        });

        setTitles(titlesArray);
    };

    const editorStateChangeConnectionLost = (editorState) => {
        const _current = draftToHtml(convertToRaw(editorState.getCurrentContent())).slice(0, -1);
        if (_current.length > 180) {
            // max length reached
            return;
        } else {
            //update chars typed
            connLostChars.current[inEditionLang.current] = _current.length;

            //update action values [slice to remove \n added at the end]
            connectionLostAllMsg.current[sortedByDefaultValue[inEditionLang.current].languageRef] = _current;
        }
        updateActionValues();
        renderContent();
        renderTitles();
    };

    const editorStateChangeConnectionReady = (editorState) => {
        const _current = draftToHtml(convertToRaw(editorState.getCurrentContent())).slice(0, -1);
        if (_current.length > 180) {
            // max length reached
            return;
        } else {
            //update chars typed
            connReadyChars.current[inEditionLang.current] = _current.length;

            //update action values [slice to remove \n added at the end]
            connectionReadyAllMsg.current[sortedByDefaultValue[inEditionLang.current].languageRef] = _current;
        }
        updateActionValues();
        renderContent();
        renderTitles();
    };

    const updateActionValues = () => {
        dispatch(
            setActionValues({
                connectionLost: connectionLostAllMsg.current,
                connectionRestored: connectionReadyAllMsg.current,
            })
        );
    };

    const predefinedConvertToHTML = (predefined) => {
        const editorStatePredefined = EditorState.createWithContent(ContentState.createFromText(predefined));
        return draftToHtml(convertToRaw(editorStatePredefined.getCurrentContent()));
    };

    const getStateFromHtml = (html) => {
        const contentBlock = htmlToDraft(html);
        const contentState = contentBlock ? ContentState.createFromBlockArray(contentBlock.contentBlocks) : null;
        return convertToRaw(contentState);
    };

    const getPlainText = (string) => {
        // remove HTML tags and linebreaks \n
        return string.replace(/<\/?[^>]+(>|$)/g, "").replace(/(\r\n|\n|\r)/gm, "");
    };

    const renderContent = () => {
        const link_style_enable = "cursor-pointer text-zafiro-600";
        const link_style_disable = "text-gray-700";
        let contentsArray = [];
        // eslint-disable-next-line
        sortedByDefaultValue.map((lang) => {
            const defaultLostMsg = predefinedConvertToHTML(
                data.highAvailabilityConfig.defaultConnectionLost[lang.languageRef] ||
                    data.highAvailabilityConfig.defaultConnectionLost.en
            );

            const predefindedLostEnable = connectionLostAllMsg.current[lang.languageRef]
                ? getPlainText(connectionLostAllMsg.current[lang.languageRef]) !== getPlainText(defaultLostMsg)
                    ? true
                    : false
                : false;
            const defaultRestoredMsg = predefinedConvertToHTML(
                data.highAvailabilityConfig.defaultConnectionRestored[lang.languageRef] ||
                    data.highAvailabilityConfig.defaultConnectionRestored.en
            );
            const predefindedRestoredEnable = connectionReadyAllMsg.current[lang.languageRef]
                ? getPlainText(connectionReadyAllMsg.current[lang.languageRef]) !== getPlainText(defaultRestoredMsg)
                    ? true
                    : false
                : false;

            const _connectionLost = getStateFromHtml(connectionLostAllMsg.current[lang.languageRef] || "");

            const _connectionRestore = getStateFromHtml(connectionReadyAllMsg.current[lang.languageRef] || "");

            contentsArray.push(
                <>
                    <div className="flex pb-4">
                        {t("connection-lost")}
                        <span
                            id="btn-predefined-lost"
                            className={`ml-auto ${predefindedLostEnable ? link_style_enable : link_style_disable}`}
                            onClick={() => {
                                if (!predefindedLostEnable) {
                                    return;
                                }
                                showLostEditor.current = false;
                                editorStateChangeConnectionLost(
                                    EditorState.createWithContent(convertFromRaw(getStateFromHtml(defaultLostMsg)))
                                );
                            }}
                        >
                            {t("use-predefined-message")}
                        </span>
                    </div>

                    {showLostEditor.current ? (
                        <Editor
                            key={"lostEditor"}
                            initialContentState={_connectionLost}
                            onEditorStateChange={editorStateChangeConnectionLost}
                            wrapperClassName={`editor-policy-and-conditions-wrapper `}
                            editorClassName={`editor-ha-translate-text text-lost-editor`}
                            toolbarClassName={`-editor-toolbar text-lost-toolbar`}
                            toolbar={toolbarEditor}
                            stripPastedStyles={true}
                            placeholder={
                                lang.languageRef === "es"
                                    ? data.highAvailabilityConfig.defaultConnectionLost.es
                                    : data.highAvailabilityConfig.defaultConnectionLost.en
                            }
                        />
                    ) : (
                        <div className="editor-policy-and-conditions-wrapper mt-12">
                            <div
                                className="editor-ha-translate-text"
                                onClick={() => {
                                    showLostEditor.current = true;
                                    renderContent();
                                }}
                            >
                                {HTMLReactParser(draftToHtml(_connectionLost))}
                            </div>
                        </div>
                    )}
                    <div className="flex pt-2 align-middle">
                        <span id="lost-chars" className={`ml-auto leading-10 text-sm text-gray-800`}>
                            {connLostChars.current[inEditionLang.current]} {t("chars")} {maxCharsTooltip()}
                        </span>
                    </div>
                    <div className="flex pt-8 pb-4">
                        {t("connection-restored")}
                        <span
                            id="btn-predefined-restored"
                            className={`ml-auto ${predefindedRestoredEnable ? link_style_enable : link_style_disable}`}
                            onClick={() => {
                                if (!predefindedRestoredEnable) {
                                    return;
                                }
                                showReadyEditor.current = false;
                                editorStateChangeConnectionReady(
                                    EditorState.createWithContent(convertFromRaw(getStateFromHtml(defaultRestoredMsg)))
                                );
                            }}
                        >
                            {t("use-predefined-message")}
                        </span>
                    </div>
                    <div className="pb-1">
                        {showReadyEditor.current ? (
                            <Editor
                                id="text-restored-msg"
                                key={"restoredEditor"}
                                defaultContentState={_connectionRestore}
                                onEditorStateChange={editorStateChangeConnectionReady}
                                wrapperClassName={`editor-policy-and-conditions-wrapper `}
                                editorClassName={`editor-ha-translate-text text-restored-editor`}
                                toolbarClassName={`-editor-toolbar text-restored-toolbar`}
                                toolbar={toolbarEditor}
                                stripPastedStyles={true}
                                placeholder={
                                    lang.languageRef === "es"
                                        ? data.highAvailabilityConfig.defaultConnectionRestored.es
                                        : data.highAvailabilityConfig.defaultConnectionRestored.en
                                }
                            />
                        ) : (
                            <div className="editor-policy-and-conditions-wrapper mt-12">
                                <div
                                    className="editor-ha-translate-text"
                                    onClick={() => {
                                        showReadyEditor.current = true;
                                        renderContent();
                                    }}
                                >
                                    {HTMLReactParser(draftToHtml(_connectionRestore))}
                                </div>
                            </div>
                        )}
                        <div className="flex pt-2">
                            <span className={`ml-auto text-sm text-gray-800`}>
                                {connReadyChars.current[inEditionLang.current]} {t("chars")} {maxCharsTooltip()}
                            </span>
                        </div>
                    </div>
                </>
            );
        });
        setContents(contentsArray);
    };

    const onChangeTab = (index) => {
        inEditionLang.current = index;
        showLostEditor.current = true;
        showReadyEditor.current = true;
        renderContent();
    };

    const maxCharsTooltip = () => {
        return (
            <span
                class="icon icon-info align-middle ml-3 text-4xl"
                data-tip={t("max-180-chars")}
                onMouseOver={() => {
                    ReactTooltip.rebuild();
                }}
            ></span>
        );
    };

    return loading ? (
        <UseComponentLoading />
    ) : (
        <>
            <ReactTooltip
                place="bottom"
                type="light"
                offset={{ top: -8, left: -8 }}
                html={true}
                border={true}
                borderColor="#D3DAE1"
            />
            <UseTabs color={`blue-600`} titles={titles} contents={contents} onChangeTabCallback={onChangeTab} />
        </>
    );
};

export default UseModalTranslateTextsTabs;
