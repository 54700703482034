import React from "react";
import UseSectionHeader from "../../../useSectionHeader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import MoviesPMSGeneralSettings from "./MoviesPMSGeneralSettings";
import MoviesTaxes from "./MoviesTaxes";

const MoviesGeneralSettings = () => {
    const { t } = useTranslation();
    const hasProductGuests = useSelector((state) => state?.ui?.permissions?.product?.guest);

    return (
        <>
            <UseSectionHeader title={t("general-settings")}></UseSectionHeader>
            {hasProductGuests && (
                <div className="grid grid-cols-2 gap-4">
                    <div className="h-full">
                        <MoviesTaxes />
                    </div>
                    <div className="h-full">
                        <MoviesPMSGeneralSettings />
                    </div>
                </div>
            )}
        </>
    );
};

export default MoviesGeneralSettings;
