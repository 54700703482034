import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    cleanTableStore,
    setCountPosition,
    setHeaders,
    setLoading,
    setTableResults,
    setCustomError,
    setFormNewContent,
    setCustomConfig,
    setSearchable,
    setAvailableFilters,
    setCount,
} from "../../../actions/tableActions";
//Utils
import GetTableForm from "../../Utils/Table/GetTableForm";
import GetTableHeaders from "../../Utils/Table/Headers/GetDesignTableHeaders";
//Model
import { useTranslation } from "react-i18next";
import TagValue from "./models/TagValue";

const ListPropertyTagValues = () => {
    const { t } = useTranslation();
    const { propertiesValues } = useSelector((state) => state.sectionContent);

    //Data
    const sectionName = "list-tags-values";

    //Actions
    const dispatch = useDispatch();

    const composeTable = () => {
        //Get data
        const dataItems = arrangeData(propertiesValues ? propertiesValues.results : [], t);
        const tableHeaders = GetTableHeaders(sectionName);
        //Add to store
        dispatch(setLoading(true));
        dispatch(setSearchable(true));
        setTimeout(function () {
            dispatch(setHeaders(tableHeaders));
        }, 50);
        dispatch(setTableResults(dataItems));
        dispatch(setCount(propertiesValues ? propertiesValues.info.count : 0));
        dispatch(setCountPosition("table-header"));
        dispatch(
            setCustomConfig({
                pagination: true,
                tableContainerAdjust: "min-h-0 pt-0 -mt-1",
                searchStyle: "col-span-3 absolute right-0 flex -mt-14 pt-3",
            })
        );
        dispatch(setAvailableFilters(null));

        dispatch(showRowForm(false));
        dispatch(setCustomError(t("no properties available")));

        //Sets in row add new form
        dispatch(setFormNewContent(GetTableForm(sectionName)));
        dispatch(setLoading(false));
    };

    //Listeners
    useEffect(() => {
        composeTable();

        return () => {
            dispatch(setCustomConfig(null));
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        composeTable();
        // eslint-disable-next-line
    }, [propertiesValues]);
};

const arrangeData = (data, t) => {
    const propertyData = [];
    // eslint-disable-next-line
    data.map((pValue) => {
        propertyData.push(TagValue(pValue, t));
    });
    return propertyData;
};

export default ListPropertyTagValues;
