import { timer } from "hooks/GraphqlCalls/Hotel/utils/timer";

export const DailyReset = (enabled, activeValue) => {
    return {
        id: "daily-reset",
        activeValue: activeValue,
        data: timer(),
        enabled: enabled,
        name: "daily-reset",
        noTranslateAV: true,
        type: "select",
        useActionInput: true,
        containerOptionsAdjust: "overflow-y-auto max-h-64",
    };
};
