import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { EditorContext, WidgetContext } from "../../../../../../contexts/editor";
import { setExecuteQuery } from "../../../../../../actions/sectionActions";
import { isEmpty } from "../../../../../../hooks/Utils/DesignUtils";
import Widget from "../../Widget";

const InputNumber = ({
    id,
    title,
    min,
    max,
    step,
    placeholder,
    unit,
    toolbarID,
    deviceSpecific,
    dataType,
    titleWidth,
    defaultInputValue,
    disabled,
}) => {
    const { deviceType, itemSelected } = useContext(EditorContext);
    const { id: widgetID } = useContext(WidgetContext);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { widgetToolbarVisible, gridItems } = useSelector((state) => state.design);

    const [inputError, setInputError] = useState("");

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const widgetData = widget?.data ? widget.data : null;
    const widgetStyle = widget?.widgetStyle ? widget.widgetStyle : null;
    const config =
        dataType === "data" ? Widget.ParseData(widgetData, deviceType) : Widget.ParseStyle(widgetStyle, deviceType);
    const isOpen = itemSelected === widgetID && (!toolbarID || widgetToolbarVisible === toolbarID);
    const defaultValue = id === "iconMargin" ? 0.5 : defaultInputValue || "";
    useEffect(() => {
        if (isOpen) {
            let customized;
            const idParts = id.split(".");
            if (idParts.length > 1) {
                if (config) {
                    customized = config;
                    idParts.forEach((i) => {
                        if (i && customized) customized = customized[i];
                        else customized = null;
                    });
                }
                customized = customized && !isEmpty(customized) ? customized : defaultValue;
            } else {
                customized = config && !isEmpty(config[id]) ? config[id] : defaultValue;
            }
            const elm = document.getElementById(id + "_" + widgetID);
            if (elm) {
                elm.value = customized;
            }
        }
        // eslint-disable-next-line
    }, [widgetToolbarVisible, gridItems]);

    //Actions
    const updateSize = (property, value, min, max) => {
        value = parseFloat(value);
        min = parseFloat(min);
        max = parseFloat(max);

        if (value && value < min) {
            setInputError(true);
            toast.error(t("value-greater-x", { val: min }));
            return;
        }

        if (value && value > max) {
            setInputError(true);
            toast.error(t("value-under-x", { val: max }));
            return;
        }

        setInputError(false);

        dispatch(
            setExecuteQuery(
                dataType === "data"
                    ? Widget.UpdateData({
                          widget,
                          property,
                          value: parseFloat(value),
                          deviceSpecific: deviceSpecific ? deviceType : null,
                      })
                    : Widget.UpdateStyle({
                          widget,
                          property,
                          value: parseFloat(value),
                          deviceSpecific: deviceSpecific ? deviceType : null,
                      })
            )
        );
    };

    min = min || 0;
    max = max || 100;
    step = step || (id === "iconMargin" ? 0.5 : 1);
    unit = unit || "";
    placeholder = placeholder || "";

    return (
        <div className="flex mb-2">
            {title ? (
                <div
                    className={`${titleWidth ? titleWidth : "w-3/12"} ${disabled ? "disabled" : ""} mt-2 first-capital`}
                >
                    {title}
                </div>
            ) : null}
            <div className={`${unit ? "w-6/12" : "w-9/12"}  `}>
                <input
                    type="number"
                    step={step}
                    min={min}
                    max={max}
                    disabled={disabled}
                    placeholder={placeholder}
                    id={`${id}_${widgetID}`}
                    className={` bg-gray-200 rounded px-4 py-2 ${inputError === id ? " border border-red-100" : ""} ${
                        disabled ? "disabled" : ""
                    } `}
                    onBlur={(e) => updateSize(id, e.target.value, min, max)}
                    onKeyUp={(e) => (e.keyCode === 13 ? updateSize(id, e.target.value, min, max) : null)}
                />
                {unit ? <span className="w-3/12 mt-2 ml-1">{unit}</span> : null}
            </div>
        </div>
    );
};

export default InputNumber;
