import React, { useContext, useEffect, useState } from "react";
import { useMSQuery } from "../useMSQuery";
import { useMutation } from "@apollo/react-hooks";
import { Session } from "hooks/Utils/Session";
import Icon from "components/Icon";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Select from "components/Select";
import { LanguagesContext } from "components/Section/Settings/Languages";
import { toast } from "react-toastify";
import Modal, { useModal } from "components/Modal";
import { ADD_LANGUAGE, DELETE_LANGUAGE, GET_LANGUAGES, UPDATE_LANGUAGE } from "./utils/languagesQueries";
import { arrangeLanguages, getLanguagesList } from "./utils/languagesUtils";
import { Save } from "components/Section/Settings/LanguagesComponents";

export const useLanguages = () => {
    const { setSelectedLanguage } = useContext(LanguagesContext);
    const [languages, setLanguages] = useState([]);
    const [tableResults, setTableResults] = useState([]);
    const [loadingRefresh, setLoadingRefresh] = useState(false);
    const { t } = useTranslation();

    const [executeQuery, { data, loading, error, refetch }] = useMSQuery(GET_LANGUAGES);
    const [executeMutation] = useMutation(ADD_LANGUAGE);
    const [deleteMutation] = useMutation(DELETE_LANGUAGE);
    const [updateMutation] = useMutation(UPDATE_LANGUAGE);

    useEffect(() => {
        if (loadingRefresh) {
            toast.success(t("operation-successful"));
            setTimeout(() => {
                refetch().then(() => {
                    setLoadingRefresh(false);
                });
            }, 1200);
        }
    }, [loadingRefresh]);

    useEffect(() => {
        if (data && !loading && !error) {
            const sortedProjectLangsAlphabetically = data.projectLangs.results.sort((a, b) =>
                t("language:" + a.languageRef).localeCompare(t("language:" + b.languageRef))
            );

            const sortedByDefaultValue = sortedProjectLangsAlphabetically.sort(
                (a, b) => Number(b.isDefault) - Number(a.isDefault)
            );

            const languages = getLanguagesList(data.languages.results, sortedByDefaultValue, t);
            const languagesData = arrangeLanguages(data, languages, setLoadingRefresh);

            const filteredLanguages = languages
                .filter((lang) => !data?.projectLangs?.results?.some((dataLang) => dataLang.languageRef === lang.id))
                .sort((a, b) => a.label.localeCompare(b.label));

            setLanguages(filteredLanguages);
            setTableResults(languagesData);
        }
    }, [data]);

    const cancelAdd = () => {
        setTableResults((prev) => prev.slice(1));
        setSelectedLanguage(null);
    };

    const addLanguage = () => {
        let newLanguage = {
            language: (
                <Select
                    id="languages"
                    width="50%"
                    options={languages}
                    onChange={(value) => setSelectedLanguage(value)}
                />
            ),
            delete: (
                <div className="flex items-center gap-3">
                    <Button id="cancel" className="btn btn-white" onClick={cancelAdd}>
                        {t("cancel")}
                    </Button>
                    <Save setLoadingRefresh={setLoadingRefresh} />
                </div>
            ),
        };
        setTableResults([newLanguage, ...tableResults]);
    };

    const performMutation = (mutation, variables) => mutation({ variables });

    const saveLanguage = (languageRef) => performMutation(executeMutation, { languageRef });
    const deleteLanguage = (languageRef) => performMutation(deleteMutation, { languageRef });
    const updateLanguage = (languageRef) => performMutation(updateMutation, { languageRef });

    return {
        tableResults,
        addLanguage,
        saveLanguage,
        deleteLanguage,
        updateLanguage,
        load: loading || loadingRefresh,
        executeQuery,
    };
};
