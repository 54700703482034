import { useLazyQuery } from "react-apollo";
import { GET_CCS_HIGH_LATENCY, GET_CCS_LOW_WIFI_SIGNAL, GET_CCS_NEVER_DISCOVERED } from "../services/getMonitorInfo";
import { useEffect, useState } from "react";

export const useCCsList = (query) => {
    const [callQuery, setCallQuery] = useState();

    useEffect(() => {
        if (!query) return;
    }, [query]);

    useEffect(() => {
        switch (query) {
            case "lowSignal":
                setCallQuery(GET_CCS_LOW_WIFI_SIGNAL);
                break;
            case "latency":
                setCallQuery(GET_CCS_HIGH_LATENCY);
                break;
            case "neverDiscovered":
                setCallQuery(GET_CCS_NEVER_DISCOVERED);
                break;
        }
    }, [query]);

    const [executeGetInfoQuery, { data, loading }] = useLazyQuery(callQuery, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeGetInfoQuery();
    }, []);

    return { listData: data, loading };
};
