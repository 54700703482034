import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { changeActionValues } from "../../actions/globalActions";
//Utils
import { multipleTranslate } from "../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";

const UseModalTextArea = ({ index }) => {
    const { t } = useTranslation();
    const { validations } = useSelector((state) => state.action);
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const {
        name,
        text,
        charLimit,
        selected,
        styleText,
        noResize,
        id,
        formatText = true,
    } = useSelector((state) => state.ui.modalContent.inputs[index]);

    //States
    const [currentLength, setCurrentLength] = useState(selected ? selected.length : 0);

    const { title } = useSelector((state) => state.ui.modalContent);

    //Actions
    const dispatch = useDispatch();

    //Listeners
    useEffect(() => {
        if (selected) {
            dispatch(changeActionValues({ [name]: selected }));
        }
        // eslint-disable-next-line
    }, []);

    const handleChange = (e) => {
        if (e.target.value.length > charLimit) {
            e.target.value = e.target.value.substr(0, charLimit);
        } else {
            setCurrentLength(e.target.value.length);
            dispatch(
                changeActionValues({
                    [name]: formatText ? e.target.value.replace(/(\r\n|\n|\r)/gm, "") : e.target.value,
                })
            );
        }
    };

    return (
        <>
            {text ? (
                <span className={`block pb-2 first-capital ${styleText ? styleText : ""}`}>
                    {multipleTranslate(text, t)}
                </span>
            ) : null}
            <div className="">
                <textarea
                    id={id ?? `text-area-modal-${title}-${index}`}
                    rows="4"
                    className={`w-full h-32 bg-gray-200 rounded hover:text-zafiro-400 p-2 ${
                        noResize ? "resize-none" : ""
                    } ${typeof validations[name] !== "undefined" && !validations[name] ? "border border-red-100" : ""}`}
                    onChange={handleChange}
                >
                    {selected}
                </textarea>
            </div>
            {charLimit ? (
                <p className="text-sm text-right">{`${currentLength} / ${charLimit} ${langStrings["chars"]}`}</p>
            ) : null}
        </>
    );
};

export default UseModalTextArea;
