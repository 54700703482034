import React, { useState, useEffect, cloneElement } from "react";
import { getRGBA } from "hooks/Utils/DesignUtils";
import { ChromePicker } from "react-color";
import { isDescendant, remToPixel } from "hooks/Utils/Utils";

export const ColorPicker = ({ defaultColor = getRGBA("rgba(123,123,123,1)"), onChange, children, disabled }) => {
    const [color, setColor] = useState(defaultColor);
    const [hide, setHide] = useState(true);
    const [presetColors, setPresetColors] = useState([]);

    useEffect(() => {
        setColor(defaultColor);
    }, [defaultColor]);

    function onChangeComplete(color) {
        setColor(color.rgb);
        setPresetColors((val) => [...val, { color: color.rgb }]);
        if (onChange) {
            onChange(color);
        }
    }

    function handleClickOutside(e) {
        const pickerContainer = document.getElementById("pickerContainer");
        if (!isDescendant(pickerContainer, e.target) && !hide) {
            setHide(true);
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        if (!hide) {
            const refDivPos = document.getElementById(`color-picker-container`).getClientRects()[0];
            const picker = document.getElementById("pickerContainer");
            if (refDivPos) {
                const percentagePositionHeight = Math.floor((refDivPos.y / window.innerHeight) * 100);
                const percentagePositionWidth = Math.floor((refDivPos.x / window.innerWidth) * 100);

                if (percentagePositionHeight > 65 && percentagePositionHeight <= 69) {
                    picker.style.top = `${refDivPos.top - refDivPos.height * 2}px`;
                } else if (percentagePositionHeight >= 70 && percentagePositionHeight <= 75) {
                    picker.style.top = `${refDivPos.top - refDivPos.height * 3}px`;
                } else if (percentagePositionHeight >= 76 && percentagePositionHeight <= 80) {
                    picker.style.top = `${refDivPos.top - refDivPos.height * 4}px`;
                } else if (percentagePositionHeight >= 81 && percentagePositionHeight <= 100) {
                    picker.style.top = `${refDivPos.top - refDivPos.height * 5}px`;
                } else {
                    picker.style.top = `${refDivPos.top}px`;
                }
                if (percentagePositionWidth >= 83) {
                    picker.style.left = `${refDivPos.x - refDivPos.width * 8.5}px`;
                } else {
                    picker.style.left = `${refDivPos.x + refDivPos.width + remToPixel(1)}px`;
                }
                picker.classList.remove("hidden");
            }
        }
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [hide]);

    return (
        <div className="relative" id="color-picker-container">
            {!hide ? (
                <div id="pickerContainer" className="z-201 fixed">
                    <ChromePicker color={color} onChangeComplete={onChangeComplete} presetColors={presetColors} />
                </div>
            ) : null}
            {cloneElement(children, {
                onClick: () => {
                    if (!disabled) {
                        setHide(false);
                    }
                },
            })}
        </div>
    );
};
