import React, { useRef, useState, useLayoutEffect } from "react";

const LogoPreview = ({ update, size, logoType, className, style }) => {
    const domRef = useRef(null);

    const [sizes, setSizes] = useState({ w: 0, h: 0 });

    useLayoutEffect(() => {
        if (!size) domRef.current && setSizes({ w: domRef.current.offsetWidth, h: domRef.current.offsetHeight });
    }, [update, domRef, size]);

    const logo = parseLogo(logoType);

    const f = logo.orientation === "icon" ? [10, 5] : logo.orientation === "vertical" ? [12, 8] : [14, 5];
    const maxWSize = Math.min(Math.round(sizes.w / f[0]), 18);
    const maxHSize = Math.min(Math.round(sizes.h / f[1]), 18);
    const baseSize = size || Math.min(maxWSize, maxHSize);
    const title = logo.orientation === "icon" ? null : "LOREM IPSUM";
    const darkMode = logo.background !== "light";
    const grayScale = logo.group !== "color";
    const fgColor = grayScale ? (darkMode ? "gray-100" : "gray-900") : darkMode ? "blue-200" : "blue-300";
    const bgColor = darkMode ? "gray-900" : "gray-200";
    const alignment = "center";
    const valignment = "center";
    const showIcon = true;
    const iconPosition = logo.orientation === "vertical" ? "top" : "left";
    const textEm = baseSize > 10 ? 1.2 : 1.5;
    const iconEm = baseSize > 10 ? 3 : 2;

    const icon = (
        <i
            className={`inline-block icon icon-logo text-${fgColor}`}
            style={{
                fontSize: iconEm + "em",
                marginBottom: logo.orientation === "vertical" ? ".3em" : 0,
                marginRight: logo.orientation === "vertical" ? 0 : ".2em",
            }}
        ></i>
    );

    return (
        <div
            ref={domRef}
            className={`grid select-none place-items-${
                alignment === "left" ? "start" : alignment === "right" ? "end" : "center"
            } items-${
                valignment === "top" ? "start" : valignment === "bottom" ? "end" : "center"
            } shadow h-full p-2 bg-${bgColor} text-${fgColor} ${className}`}
            style={{
                fontSize: baseSize + "px",
                lineHeight: baseSize + "px",
                textAlign: alignment,
                ...(style ? style : {}),
            }}
        >
            <div className={`text-center ${iconPosition === "top" ? "" : "flex items-center"}`}>
                {showIcon && (iconPosition === "left" || iconPosition === "top") ? icon : null}
                {title ? (
                    <span
                        style={{
                            fontSize: textEm + "em",
                            textAlign: "center",
                            display: iconPosition === "top" || iconPosition === "bottom" ? "block" : "inline-block",
                        }}
                    >
                        {title}
                    </span>
                ) : null}
                {showIcon && (iconPosition === "right" || iconPosition === "bottom") ? icon : null}
            </div>
        </div>
    );
};

const parseLogo = (version) => {
    const parts = version ? (version + "").split("_") : null;
    const hasType = parts && parts.length > 3;
    const type = hasType ? parts[0].toLowerCase() : null; // property | generic
    const group = parts && parts.length > 0 ? parts[hasType ? 1 : 0].toLowerCase() : null; // color | bw | others
    const background = parts && parts.length > 1 ? parts[hasType ? 2 : 1].toLowerCase() : null; // dark | light
    const orientation = parts && parts.length > 2 ? parts[hasType ? 3 : 2].toLowerCase() : null; // horizontal | vertical | icon
    return {
        origin: type,
        type: version ? [group, background, orientation].join("_") : null,
        group: group,
        background: background,
        orientation: orientation,
    };
};

export default LogoPreview;
