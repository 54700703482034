import React, { useState, useEffect } from "react";

import { CalcPages } from "../../hooks/Utils/DataCalc/CalcPages";
import { useTranslation } from "react-i18next";

const UsePaginationLocalState = ({ initialState, onStateChange, countProp = 0, setLoading }) => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        count: 0,
        currentPage: 1,
        perPage: 9,
        disabled: false,
    });

    const { count, currentPage, perPage, disabled } = state;

    const maxPage = Math.ceil(count / perPage);
    const pages = CalcPages(maxPage, currentPage);

    const [nextVisibility, setNextVisibility] = useState("100");
    const [prevVisibility, setPrevVisibility] = useState("");

    const handlerClickDirect = (e) => {
        if (disabled) {
            return;
        }
        setState((prevState) => ({
            ...prevState,
            currentPage: Number(e),
        }));
    };

    useEffect(() => {
        if (initialState) {
            setState(initialState);
        }
    }, []);

    useEffect(() => {
        onStateChange(state);
    }, [state]);

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            count: Number(countProp),
        }));
    }, [countProp]);

    useEffect(() => {
        if (currentPage > 1) {
            setPrevVisibility("100");
        } else {
            setPrevVisibility("0");
        }
        if (currentPage < maxPage && maxPage > 1) {
            setNextVisibility("100");
        } else {
            setNextVisibility("0");
        }
    }, [currentPage, maxPage]);

    return (
        <div className="t-pagination-container first-capital" style={{ position: "relative" }}>
            {count <= perPage ? null : (
                <>
                    <div
                        className={`t-pagination-${disabled ? "disabled" : "index"} ${
                            prevVisibility === "0" ? "hidden" : ""
                        }`}
                    >
                        <div className="inline">
                            <div
                                className="flex items-center"
                                onClick={() => {
                                    handlerClickDirect(currentPage - 1);
                                    if (setLoading) {
                                        setLoading(true);
                                    }
                                }}
                            >
                                <span className="t-pagination-left icon-chevron-left"></span>
                                <span className="first-capital">{t("previous")}</span>
                            </div>
                        </div>
                    </div>

                    {pages.map((page, index) => (
                        <div
                            className={`t-pagination-${disabled ? "disabled" : "number"}`}
                            onClick={
                                page === "..." || currentPage === page
                                    ? null
                                    : () => {
                                          handlerClickDirect(page);
                                          if (setLoading) {
                                              setLoading(true);
                                          }
                                      }
                            }
                            key={index}
                        >
                            <p className="inline">
                                {currentPage === page ? (
                                    <span
                                        className={`t-pagination-active${disabled ? "-disabled" : ""}`}
                                        data-id={page}
                                    >
                                        {page}
                                    </span>
                                ) : null}
                                {page === "..." ? <span>{page}</span> : null}
                                {currentPage !== page && page !== "..." ? (
                                    <span
                                        className={`t-pagination-${disabled ? "disabled" : "index"}`}
                                        onClick={() => {
                                            handlerClickDirect(page);
                                            if (setLoading) {
                                                setLoading(true);
                                            }
                                        }}
                                    >
                                        {page}
                                    </span>
                                ) : null}
                            </p>
                        </div>
                    ))}

                    <div
                        className={`t-pagination-${disabled ? "disabled" : "index"} ${
                            nextVisibility === "0" ? "hidden" : ""
                        }`}
                    >
                        <div className="inline">
                            <div
                                className="flex items-center"
                                onClick={() => {
                                    handlerClickDirect(currentPage + 1);
                                    if (setLoading) {
                                        setLoading(true);
                                    }
                                }}
                            >
                                <span className="first-capital">{t("next")}</span>
                                <span className="t-pagination-right icon-chevron-right"></span>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default UsePaginationLocalState;
