import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import UseItemActions from "components/useItemActions";

const RoomDetailParentalCode = () => {
    const { t } = useTranslation();
    const { guest } = useSelector((state) => state.sectionContent);
    const { room } = useSelector((state) => state.sectionContent);
    const parentalCode = guest?.rooms?.find((r) => r.RoomID === room?.id)?.parentalcode;
    const stayGuestRoomID = guest?.rooms?.find((r) => r.RoomID === room?.id)?.stayGuestRoomID;

    return (
        <div className="h-28 mt-8 bg-white rounded px-10 py-5">
            <div className="flex w-full">
                <div className="text-gray-900 font-bold text-lg">
                    {t("{{capitalize, capitalize}}", { capitalize: t("parentalControl") })}
                </div>
                <div className="ml-auto">
                    {parentalCode ? (
                        <UseItemActions
                            dataType="parental"
                            executeSectionQuery={true}
                            containerAdjust={"w-auto"}
                            // id={alarms.current.map((alarm) => alarm.id)}
                            id={4}
                            // or ROOM.ID depends in what mutation of updateRoomAlarm need
                            actions={[
                                {
                                    name: t("disable-parental"),
                                    executeSectionQuery: true,
                                    mutation: "disable-parental-code",
                                    mutationParams: {
                                        stayGuestRoomID: stayGuestRoomID,
                                    },
                                },
                            ]}
                        />
                    ) : null}
                </div>
            </div>
            <hr className="h-1 my-2" />
            <div className=" text-base flex ">
                <div className="text-gray-800 font-bold text-lg" style={{ width: "60%" }}>
                    {t("{{capitalize, capitalize}}", { capitalize: t("parental code") })}
                </div>
                <div className="text-gray-600 text-lg text-right" style={{ width: "40%" }}>
                    {parentalCode ? "*****" : t("{{capitalize, capitalize}}", { capitalize: t("disabled") })}
                </div>
            </div>
        </div>
    );
};

export default RoomDetailParentalCode;
