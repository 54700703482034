import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setExecuteQuery } from "../../../../../actions/sectionActions";

export const OKTOCONTINUE_HORIZONTAL_ALIGN = "textToContinueHorizontalAlign";
export const OKTOCONTINUE_VERTICAL_ALIGN = "textToContinueVerticalAlign";
export const OKTOCONTINUE_SHOW = "textToContinue";

const OkToContinueAligment = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // const positions
    const horizontalPos = ["left", "center", "right"];
    const verticalPos = ["top", "center", "bottom"];

    //Store
    const { cScreenData } = useSelector((state) => state.design);
    const [align, setAlign] = useState({ h: "right", v: "bottom" });
    const showText = useRef(
        cScreenData && cScreenData.contentStyle && cScreenData.contentStyle[OKTOCONTINUE_SHOW]
            ? cScreenData.contentStyle[OKTOCONTINUE_SHOW]
            : true
    );

    useEffect(() => {
        if (
            cScreenData &&
            cScreenData.contentStyle &&
            cScreenData.contentStyle[OKTOCONTINUE_HORIZONTAL_ALIGN] &&
            cScreenData.contentStyle[OKTOCONTINUE_VERTICAL_ALIGN]
        ) {
            setAlign({
                h: cScreenData.contentStyle[OKTOCONTINUE_HORIZONTAL_ALIGN],
                v: cScreenData.contentStyle[OKTOCONTINUE_VERTICAL_ALIGN],
            });
        }
    }, [cScreenData]);

    const updateContinueAligment = (orientation, pos) => {
        const _contentStyle = JSON.parse(JSON.stringify(cScreenData.contentStyle));
        _contentStyle[orientation] = pos;
        dispatch(
            setExecuteQuery({
                action: "updateContentStyle",
                params: {
                    contentStyle: _contentStyle,
                },
            })
        );
    };

    const updateShowContinue = (show) => {
        showText.current = show;
        updateContinueAligment(OKTOCONTINUE_SHOW, show);
    };

    return (
        <div className="p-8">
            <div className="w-full flex">
                <div className="first-capital mr-auto font-bold">{t("text-to-continue")}</div>
                <label className="cursor-pointer switch ml-auto">
                    <input
                        type="checkbox"
                        checked={showText.current}
                        onChange={() => updateShowContinue(!showText.current)}
                    ></input>
                    <span className="slider round"></span>
                </label>
            </div>
            {showText.current && (
                <>
                    <div className="first-capital mt-2">{t("aligment")}</div>
                    <div className={"inline-block mb-2 mr-2"}>
                        <div className="w-9/12 flex">
                            {horizontalPos.map((pos) => {
                                const icon = "icon-align-" + pos;
                                return (
                                    <div
                                        key={"text-to-continue-align-" + pos}
                                        className={`inline-block p-2 mr-1 cursor-pointer icon ${icon} ${
                                            align.h === pos ? "bg-gray-200" : ""
                                        }`}
                                        onClick={() => updateContinueAligment(OKTOCONTINUE_HORIZONTAL_ALIGN, pos)}
                                    ></div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="inline-block border-l border-gray-400 py-1 mb-2">&nbsp;</div>
                    <div className={"inline-block mb-2 mr-2"}>
                        <div className="w-9/12 flex">
                            {verticalPos.map((pos) => {
                                const icon = "icon-align-" + (pos === "center" ? pos + "-v" : pos);
                                return (
                                    <div
                                        key={"text-to-continue-align-" + pos}
                                        className={`inline-block p-2 mr-1 cursor-pointer icon ${icon} ${
                                            align.v === pos ? "bg-gray-200" : ""
                                        }`}
                                        onClick={() => updateContinueAligment(OKTOCONTINUE_VERTICAL_ALIGN, pos)}
                                    ></div>
                                );
                            })}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default OkToContinueAligment;
