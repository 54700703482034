import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { setExecuteQuery } from "actions/sectionActions";
import { UPDATE_PRODUCT_PRICES } from "hooks/Utils/Integrations/ShopSettingsUtils";

import PriceWithTax from "components/Inputs/PriceWithTax";
import Loading from "components/Loading";

import { SalesContextDeprecated } from "contexts/Sales";

const UPDATE_PRICE_DELAY = 1500;

const ProductSettingsPriceCard = ({ data, disabled }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const delayedSave = useRef(null);

    const { productId } = data;

    const { data: ctx } = useContext(SalesContextDeprecated);

    const taxRates = ctx?.taxRates?.items;
    const channel = ctx?.channel;
    const product = ctx?.productVariants?.items?.[0] || null;

    const currencyCode = channel?.currencyCode;
    const price = product?.price || 0;
    const taxID = product?.taxCategory?.id || null;
    const taxes =
        channel?.defaultTaxZone && taxRates?.length
            ? taxRates
                  .filter((t) => t?.zone?.id === channel?.defaultTaxZone?.id)
                  .map((t) => ({
                      id: t.category.id,
                      name: t.name,
                      value: t.value,
                      default: t.value && channel?.customFields?.default_tax_category?.id === t.value,
                  }))
            : null;

    const [values, setValues] = useState({ price, taxID });

    useEffect(() => {
        setValues({ price, taxID });
    }, [price, taxID]);

    if (!channel) {
        return <Loading zoom={50} />;
    }

    const save = (price, taxID) => {
        if (delayedSave.current) {
            clearTimeout(delayedSave.current);
        }
        delayedSave.current = setTimeout(() => {
            setValues({ price, taxID });
            dispatch(
                setExecuteQuery({
                    action: UPDATE_PRODUCT_PRICES,
                    params: {
                        id: productId,
                        productVariantId: product?.id,
                        finalPrice: price,
                        taxCategoryId: taxID,
                    },
                })
            );
        }, UPDATE_PRICE_DELAY);
    };

    return (
        <div className="w-full my-2">
            <div className="w-full text-left  first-capital mb-5 ">
                <span className="font-bold text-gray-900 text-xl">{t("price")}</span>
            </div>
            <PriceWithTax
                id="product-settings-price"
                price={values?.price}
                taxID={values?.taxID}
                disabled={disabled}
                currencyCode={currencyCode}
                taxes={taxes}
                onChange={(data) => {
                    if (data) {
                        save(data.price, data.tax?.id);
                    }
                }}
                design="product"
            />
        </div>
    );
};

export default ProductSettingsPriceCard;
