import Languages from "../../lang/es/language.json";
import LanguagesEN from "../../lang/en/language.json";
import { Session } from "hooks/Utils/Session";

export const GetLanguageName = (key) => {
    const lang = Session.getLang();
    if (!key) {
        return null;
    }
    if (lang === "es") {
        return Languages[key.toLowerCase()];
    } else if (lang === "en") {
        return LanguagesEN[key.toLowerCase()];
    }
};

export const GetLanguageOptions = () => {
    const lang = Session.getLang();

    //Get countries list
    let languages = [];

    for (let i in Languages) {
        if (lang === "es") {
            languages.push({ id: i, name: Languages[i] });
        } else if (lang === "en" && LanguagesEN[i]) {
            languages.push({ id: i, name: LanguagesEN[i] });
        }
    }

    return languages;
};

export const GetLanguageList = (data, t) => {
    const languages = [""];
    // eslint-disable-next-line
    data.languages.results.map((language) => {
        languages.push([t("language:" + language.ref), language.ref, "str"]);
    });
    return languages;
};

export default GetLanguageList;
