import React, { useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";

export const IFrame = ({ children, id, onLayoutCallBack, ...props }) => {
    const [contentRef, setContentRef] = useState(null);
    const mountNode = contentRef?.contentWindow?.document?.body;

    useLayoutEffect(() => {
        if (onLayoutCallBack) {
            onLayoutCallBack();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <iframe id={id} title={id} {...props} ref={setContentRef} className="h-full w-full overflow-hidden">
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    );
};
