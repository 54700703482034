import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBatchActive } from "../../../actions/tableActions";
import { getDataFromResult } from "../../Utils/Utils";
const ListMediaLibraryBatchActions = () => {
    const dispatch = useDispatch();
    const { results, checkedItems, activeFilters } = useSelector((state) => state.table);

    const loadBatchActions = () => {
        let currentWarnings = [];
        let rows = [];
        //filter to get current warnings from items selected
        if (checkedItems.length > 0) {
            checkedItems.forEach((itemId) => {
                let row = results.filter((item) => item.id === itemId);
                if (row.length > 0) rows.push(row[0]);
            });
            if (rows.length > 0 && rows[0].dataType === "media-library")
                currentWarnings = rows.map((item) => item && item.type);
        }
        //add warning from filter selected
        if (Object.keys(activeFilters).includes("state") && activeFilters.state !== "") {
            currentWarnings.push(activeFilters.state);
        }

        //if not current warning no actions
        if (currentWarnings.length === 0) {
            return [];
        }

        const getLibraryTreeOptionsFromResult = () => {
            let _data = [];
            //OK, para hacer esto, debemos obtener del primer elemento la lista de libraryTreeOptions e ir quitando folders por id.
            if (checkedItems.length > 0 && results.length > 0) {
                _data = results[0].libraryTreeOptions;
                checkedItems.forEach((itemId) => {
                    _data = _data.filter((item) => item.id !== itemId);
                });
            }
            return _data;
        };

        //load all possible actions
        const actionsLabels = ["move-content", "download", "delete-permanently"];
        const actionsData = {
            ids: getDataFromResult("id", checkedItems, results),
            libraryTreeOptions: getLibraryTreeOptionsFromResult(),
        };
        let actions = createActions(actionsLabels, actionsData);
        if (currentWarnings.includes("folder")) {
            actions.remove("media-library-download");
        }
        return actions._list;
    };

    useEffect(() => {
        dispatch(setBatchActive(loadBatchActions()));
        // eslint-disable-next-line
    }, [checkedItems.length, activeFilters.state]);

    useEffect(() => {
        dispatch(setBatchActive(loadBatchActions()));
        // eslint-disable-next-line
    }, []);
};

const createActions = (labels, data) => {
    let _actions = {
        _list: [],
        remove(actionName) {
            let index = this._list.findIndex((item) => item.name === actionName);
            if (index > -1) this._list.splice(index, 1);
        },
    };
    labels.forEach((label) => {
        let modalInputs = [
            {
                type: "void",
                adjust: "first-capital",
                text: `media-library-batch-${label}-text`,
            },
            {
                type: "batchSelection",
                style: "mt-0 table",
                columnPos: 1,
            },
        ];
        modalInputs.push({
            type: "data",
            ids: data.ids,
        });
        if (label === "move-content") {
            modalInputs.push({
                text: ["folder"],
                name: "folderRef",
                optionData: data.libraryTreeOptions,
                oneSelected: true,
                noSelect: true,
                ph: "move_to",
                cStyle: "w-6/12",
                sort: true,
                type: "selectMultipleWithSearch",
            });
        }
        if (label === "download") {
            modalInputs.push({
                text: "download-confirm-ublocked-pop-ups",
                type: "htmltext",
            });
        }
        _actions._list.push({
            name: `media-library-${label}`,
            action: `media-library-${label}`,
            modalInputs,
        });
    });
    return _actions;
};

export default ListMediaLibraryBatchActions;
