import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createBrowserHistory } from "history";
//API
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

//Components
import UseSectionHeader from "../../../useSectionHeader";
import UseButton from "../../../useButton";
import RoomFields from "./RoomFields";

//Utils
import UpdateTopology from "../../../../hooks/GraphqlCalls/Hotel/Settings/UpdateTopology";
import { validateSection } from "../../../../hooks/Utils/Validations";
import { toast } from "react-toastify";

//Actions
import { setExecuteQuery } from "../../../../actions/sectionActions";
import { showGlobalLoading } from "../../../../actions/uiActions";
import { setActionValidations, cleanActionValidations } from "../../../../actions/globalActions";
import { useTranslation } from "react-i18next";

const EditRoom = () => {
    const { t } = useTranslation();
    //Actions
    const dispatch = useDispatch();
    const history = createBrowserHistory();
    const sectionName = `add-room`;

    //Store

    const { values } = useSelector((state) => state.action);

    //States
    const [roomData, setRoomData] = useState();

    //Params
    const { id } = useParams();

    const GET_ROOM_INFO = gql`{
        rooms( id: ${id}) {           
            results { floorID floorName id name number pmsNumber roomTVs{ id name cec} wingID wingName}
        },
    }`;

    const [getRoomInfo, { data }] = useLazyQuery(GET_ROOM_INFO, {
        fetchPolicy: "network-only",
    });

    //Functions
    const navToPrevious = () => {
        history.back();
    };

    const addRoom = () => {
        //Validate fields
        const validations = validateSection("topology-edit-room", values, t);

        dispatch(setActionValidations(validations));
        const validationsArray = Object.values(validations);

        if (validationsArray.filter((item) => item === false).length > 0) {
            toast.error(t("errors-in-red"));
            return;
        }
        dispatch(showGlobalLoading(true));
        dispatch(
            setExecuteQuery({
                action: "topology-edit-room",
                params: { roomId: id, roomtvs: roomData.roomTVs },
            })
        );
    };

    //Listeners
    useEffect(() => {
        return () => {
            dispatch(cleanActionValidations());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(showGlobalLoading(true));
        getRoomInfo();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data && data.rooms && data.rooms.results) {
            setRoomData(data.rooms.results[0]);
            dispatch(showGlobalLoading(false));
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <>
            {roomData ? (
                <>
                    <UpdateTopology />
                    <UseSectionHeader
                        title={[roomData.name, "(", roomData.wingName, ",", roomData.floorName, ")"]}
                        navToPreviousPage={true}
                    />

                    <div className="bg-white w-full p-8 ">
                        <RoomFields
                            fieldsToShow={{
                                roomName: true,
                                roomNumber: true,
                                pmsNumber: true,
                                tvsPerRoom: true,
                                location: true,
                            }}
                            initialData={{
                                parentSectionName: sectionName,
                                roomNumber: roomData.number,
                                roomName: roomData.name,
                                pmsNumber: roomData.pmsNumber,
                                tvsPerRoom: roomData.roomTVs.length,
                                roomtvs: roomData.roomTVs,
                            }}
                            isEdit={true}
                        />
                        <div className="inline-flex w-full justify-end mt-2">
                            <UseButton
                                action={(e) => navToPrevious()}
                                buttonName={"cancel"}
                                buttonColor="btn-white"
                                adjust="float-left mr-6 "
                                id={`${sectionName}-cancel`}
                            />
                            <UseButton
                                action={(e) => addRoom()}
                                buttonName={"save"}
                                buttonColor="btn-blue"
                                adjust="float-left "
                                id={`${sectionName}-save`}
                            />
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default EditRoom;
