import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseModalRadio from "./useModalRadio";
//Actions
import { changeActionValues } from "../../actions/globalActions";

import Parser from "hooks/Utils/Parser";

const UseModalRadios = ({ index }) => {
    //Store data
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const {
        name,
        textPrefix,
        translatable,
        text,
        textSufix,
        values,
        selected,
        currentValue,
        optionStyle,
        tooltipValues,
        validationElementsChange,
        labels,
    } = useSelector((state) => state.ui.modalContent.inputs[index]);

    //States
    const [currentSelected, setCurrentSelected] = useState(selected);

    //Actions
    const dispatch = useDispatch();

    const { title } = useSelector((state) => state.ui.modalContent);

    //Effects
    useEffect(() => {
        dispatch(changeActionValues({ [name]: selected }));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let containerModal = document.querySelector(".modal-body");
        if (validationElementsChange && validationElementsChange.length > 0) {
            // eslint-disable-next-line
            validationElementsChange.map((element) => {
                if (containerModal) {
                    let items = containerModal.querySelectorAll(`[${element.attr}=${element.value}]`);
                    if (items && items.length > 0 && element.class) {
                        items.forEach(async (item) => {
                            if (element.invert) {
                                item.classList.add(element.class);
                            } else {
                                item.classList.remove(element.class);
                            }
                        });
                    }
                }
            });
        }
        if (currentSelected) {
            if (validationElementsChange && validationElementsChange.length > 0) {
                let validationElementsChangeFiltered = validationElementsChange.filter(
                    (validationElementChange) => validationElementChange.option === currentSelected
                );
                if (validationElementsChangeFiltered && validationElementsChangeFiltered.length > 0) {
                    // eslint-disable-next-line
                    validationElementsChangeFiltered.map((element) => {
                        if (containerModal) {
                            let items = containerModal.querySelectorAll(`[${element.attr}=${element.value}]`);
                            if (items && items.length > 0 && element.class) {
                                items.forEach(async (item) => {
                                    if (element.invert) {
                                        item.classList.remove(element.class);
                                    } else {
                                        item.classList.add(element.class);
                                    }
                                });
                            }
                        }
                    });
                }
            }
        }
        // eslint-disable-next-line
    }, [currentSelected]);

    const handleClick = (e) => {
        let targetValue = e.target.value;
        if (e.target.value === "each-custom") {
            const ecInput = document.getElementById("each-custom-input");
            targetValue = ecInput && ecInput.value ? ecInput.value : e.currentTarget.dataset.currentvalue;
        }
        dispatch(changeActionValues({ [name]: targetValue }));
        setCurrentSelected(e.currentTarget.dataset.action);
    };

    const getLabel = (value) => {
        let response = null;
        if (labels && labels.length > 0) {
            response = labels.filter((label) => label.value === value);
            response = response.length > 0 ? response[0].text : null;
        }
        return response;
    };

    return (
        <>
            {textPrefix || text || textSufix ? (
                <div className="pb-4 first-capital">
                    {textPrefix
                        ? translatable && translatable.includes("text-prefix")
                            ? `${Parser(langStrings[textPrefix])} `
                            : `${Parser(textPrefix)} `
                        : null}
                    {text
                        ? translatable && translatable.includes("text")
                            ? Parser(langStrings[text])
                            : Parser(text)
                        : null}
                    {textSufix
                        ? Parser(
                              translatable && translatable.includes("text-sufix")
                                  ? ` ${langStrings[textSufix]}`
                                  : ` ${textSufix}`
                          )
                        : null}
                    :
                </div>
            ) : null}
            {values.map((value, index) => (
                <div
                    className={`table ${optionStyle}`}
                    onClick={handleClick}
                    key={index}
                    id={`${title}-${name}-${value}-${"click-handler-radio"}`}
                    data-action={value}
                    data-currentValue={currentValue}
                >
                    <UseModalRadio
                        titleModal={title}
                        value={value}
                        currentSelected={currentSelected}
                        currentValue={currentValue}
                        name={name}
                        label={getLabel(value)}
                        tooltip={tooltipValues && tooltipValues.length > index ? tooltipValues[index] : null}
                    />
                </div>
            ))}
        </>
    );
};

export default UseModalRadios;
