import React from "react";
import GuestOrders from "../Section/Customers/Guest/GuesOrders.js/GuestOrders";
import SalesProvider from "contexts/Sales";

const UseModalRoomOrders = () => {
    return (
        <SalesProvider>
            <GuestOrders isModal={true} minHeight={"24rem"} maxHeight={"24rem"} />
        </SalesProvider>
    );
};

export default UseModalRoomOrders;
