import React, { useEffect, useState, useMemo, useRef } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { ButtonDesign, MUTATIONS, TYPE_BATCH_ACTION } from "./utils";
import { useDispatch } from "react-redux";
import { useMutation } from "react-apollo";
import { setRefreshContentData } from "actions/sectionActions";
import { gql } from "apollo-boost";
import { toast } from "react-toastify";
import Loading from "components/Loading";
import { executeVendureQuery, useVendureAPI } from "hooks/Utils/Integrations/useVendure";
import { cleanCheckedItems } from "actions/tableActions";
import { useApolloClient } from "react-apollo";

const useExecuteMutationZafiroVendure = ({ closeModal, items, type, textsSuccess, textsError }) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { adminApi } = useVendureAPI();

    const executeMutationForItem = async (item) => {
        const { mutation, variables } = MUTATIONS(type, item);

        try {
            const response = await executeVendureQuery(adminApi, {
                queryBody: mutation,
                variables: variables,
            });
            return { item, response };
        } catch (error) {
            return { item, error };
        }
    };

    const mutate = async () => {
        try {
            setLoading(true);

            const successResponse = [];
            const errorsResponse = [];

            const results = await Promise.all(items.map((item) => executeMutationForItem(item)));

            results.forEach(({ item, response, error }) => {
                const updatedName = item.name || `Product with ID ${item.id}`;

                if (error || response?.error) {
                    errorsResponse.push(updatedName);
                } else {
                    successResponse.push(updatedName);
                }
            });

            if (successResponse.length > 0) {
                toast.success(<ToastMessage title={t(textsSuccess)} items={successResponse} />);
            }
            if (errorsResponse.length > 0) {
                toast.error(<ToastMessage title={t(textsError)} items={errorsResponse} />);
            }

            dispatch(setRefreshContentData(true));
            dispatch(cleanCheckedItems());
            closeModal();
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return {
        handleExecuteMutation: mutate,
        loading,
    };
};
const useExecuteMutationZafiro = ({ closeModal, items, type, textsSuccess, textsError }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const client = useApolloClient();
    const [loading, setLoading] = useState(false);

    const executeMutationForItem = async (item) => {
        const { mutation, variables } = MUTATIONS(type, item);

        console.log(MUTATIONS(type, item));

        try {
            const { data } = await client.mutate({
                mutation: gql`
                    ${mutation}
                `,
                variables,
            });
            return { item, data };
        } catch (error) {
            return { item, error };
        }
    };

    const mutate = async () => {
        try {
            setLoading(true);

            const successResponse = [];
            const errorsResponse = [];

            const results = await Promise.all(items.map((item) => executeMutationForItem(item)));

            results.forEach(({ item, data, error }) => {
                const itemName = item.name || `Item with ID ${item.id}`;

                if (error) {
                    errorsResponse.push(itemName);
                } else if (data) {
                    const isSuccess = Object.values(data)[0]?.ok;
                    if (isSuccess) {
                        successResponse.push(itemName);
                    } else {
                        errorsResponse.push(itemName);
                    }
                } else {
                    errorsResponse.push(itemName);
                }
            });

            if (successResponse.length > 0) {
                toast.success(<ToastMessage title={t(textsSuccess)} items={successResponse} />);
            }
            if (errorsResponse.length > 0) {
                toast.error(<ToastMessage title={t(textsError)} items={errorsResponse} />);
            }

            closeModal();
            dispatch(setRefreshContentData(true));
            dispatch(cleanCheckedItems());
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return {
        handleExecuteMutation: mutate,
        loading,
    };
};

const ToastMessage = ({ title, items }) => {
    return (
        <div className="flex flex-col">
            <span>{title}:</span>
            {items.map((name) => (
                <span key={name}>&#x2022; {name}</span>
            ))}
        </div>
    );
};

const ModalContent = (props) => {
    const { t } = useTranslation();

    const { type, close, subtitle, labels, title, textsSuccess, textsError, apiPointer } = props;

    const executeObj = {
        closeModal: close,
        items: labels,
        type: type,
        textsSuccess,
        textsError,
    };

    const zafiroMutation = useExecuteMutationZafiro(executeObj);

    const vendureMutation = useExecuteMutationZafiroVendure(executeObj);

    const { handleExecuteMutation, loading } = apiPointer === "ZAFIRO" ? zafiroMutation : vendureMutation;

    const { colorButton, textButton } = ButtonDesign(type);

    return (
        <Modal
            id="sales-modal-batch-actions"
            title={t(title || "insert-title")}
            className=" w-4/12 p-10 "
            footer={
                !loading ? (
                    <>
                        <Button
                            onClick={() => {
                                close();
                            }}
                            id="modal-cancel"
                            design="blue-outline"
                        >
                            {t("cancel")}
                        </Button>
                        <Button
                            onClick={() => {
                                handleExecuteMutation();
                            }}
                            id="modal-delete-button"
                            design={colorButton}
                        >
                            {t(textButton)}
                        </Button>
                    </>
                ) : null
            }
        >
            {!loading ? (
                <>
                    <p>{t(subtitle)}</p>
                    <div className=" w-full mt-2 flex flex-wrap">
                        {labels.map((label) => {
                            return <div className=" bg-gray-200 px-2 mr-2 mt-2 py-1 rounded ">{label.name}</div>;
                        })}
                    </div>
                </>
            ) : (
                <Loading />
            )}
        </Modal>
    );
};

export const useModalSalesBatchActions = () => {
    const { open, close } = useModal();

    const openModal = (props) => {
        const newProps = {
            close,
            ...props,
        };

        open(<ModalContent {...newProps} />);
    };

    return {
        open: openModal,
        close,
    };
};
