import { newRoute, newRedirect } from "routes/utils";

import Capacity from "components/Section/CommonZones/Capacity";
import Cleaning from "components/Section/CommonZones/Cleaning";
import Categories from "components/Section/CommonZones/Categories";
import Areas from "components/Section/CommonZones/Areas";
import AreaSchedule from "components/Section/CommonZones/AreaSchedule";
import BookingCategories from "components/Section/CommonZones/BookingCategories";
import BookingCategory from "components/Section/CommonZones/BookingCategory";
import AreaDescription from "components/Section/CommonZones/AreaDescription";
import AreaClose from "components/Section/CommonZones/AreaClose";

const sectionID = "common-zones";

const route = newRoute(sectionID);
const redirect = newRedirect(sectionID);

const checkCommonZones = ({ permissions }) => permissions?.goOutSafe;
const checkAreas = ({ permissions }) => permissions?.goOutSafe?.areas;
const checkBookings = ({ permissions }) => permissions?.goOutSafe?.bookings;
const checkCapacity = ({ permissions }) => permissions?.goOutSafe?.capacity;
const checkCategories = ({ permissions }) => permissions?.goOutSafe?.categories;
const checkCleaning = ({ permissions }) => permissions?.goOutSafe?.cleaning;

const routes = [
    route("/", { initial: true, redirect: redirect("/booking/categories"), check: checkCommonZones }),

    // Bookings
    route("/booking", { redirect: redirect("/booking/categories") }),
    route("/booking/categories", { section: BookingCategories, check: checkBookings }),
    route("/booking/categories/area/:id", { section: BookingCategory, check: checkAreas }),
    route("/booking/categories/area/:id/:date", { section: BookingCategory, check: checkAreas }),

    // Capacity monitoring
    route("/capacity", { section: Capacity, check: checkCapacity }),

    // Cleaning
    route("/cleaning", { section: Cleaning, check: checkCleaning }),

    // Zones configuration
    route("/zones-configuration", { redirect: redirect("/zones-configuration/areas") }),
    // Areas and activities
    route("/zones-configuration/areas", { section: Areas, check: checkAreas }),
    route("/zones-configuration/area", { redirect: redirect("/zones-configuration/areas") }),
    route("/zones-configuration/area/description/:id", { section: AreaDescription, check: checkAreas }),
    route("/zones-configuration/area/close/:id", { section: AreaClose, check: checkAreas }),
    route("/zones-configuration/areas/schedule/:id", { section: AreaSchedule, check: checkCategories }),
    // Categories
    route("/zones-configuration/categories", { section: Categories, check: checkCategories }),
];

export default routes;
