import React, { useEffect, useState } from "react";
import { MOBILE_CHAT_FEATURE } from "./MobileSettingsUtils";
import { useTranslation } from "react-i18next";
import UseCustomPhoneInput from "components/Inputs/UseCustomPhoneInput";
import UseButton from "components/useButton";
import { useDispatch } from "react-redux";
import { setExecuteQuery } from "actions/sectionActions";
import { ACTIONS_MOBILE_SETTINGS } from "./MobileSettingsUtils";
import { useAuth } from "hooks/Session/auth";

const MobileSettingsChatFeature = ({ cardInEdition, setCardInEdition, data }) => {
    const { t } = useTranslation();
    const { isCorporate } = useAuth();
    const [settingsData, setSettingsData] = useState({ phoneNumber: "", id: data?.id });
    const [resetData, setResetData] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (
            (data?.information?.chatNumber && data?.information?.chatNumberLada) ||
            (resetData && data?.information?.chatNumber && data?.information?.chatNumberLada)
        ) {
            setSettingsData({
                phoneNumber: `${data.information.chatNumberLada} ${data.information.chatNumber}`,
                id: data?.id,
            });
            setResetData(false);
        } else if (
            (data?.information?.chatNumber === "" && data?.information?.chatNumberLada === "") ||
            (resetData && data?.information?.chatNumber === "" && data?.information?.chatNumberLada === "")
        ) {
            setSettingsData({
                phoneNumber: ``,
                id: data?.id,
            });
            setResetData(false);
        }
    }, [data, resetData]);

    return (
        <div className=" flex-1 rounded p-5 bg-white mb-8 ">
            <div className=" flex justify-between p-1 text-xl font-bold border-b border-gray-200">
                <span>{t("chat-feature")}</span>
                {!isCorporate && cardInEdition !== MOBILE_CHAT_FEATURE ? (
                    <button
                        id="mobile-settings-chat-feature-edit-button"
                        onClick={() => setCardInEdition(MOBILE_CHAT_FEATURE)}
                        className="  text-gray-800 focus:outline-none icon icon-edit"
                    ></button>
                ) : null}
            </div>
            <div>
                <div className=" block mt-2">
                    {t(
                        isCorporate
                            ? "phone-number-associated-with-whatsapp"
                            : "write-the-phone-number-associated-with-whatsapp"
                    )}
                </div>
                {cardInEdition === MOBILE_CHAT_FEATURE ? (
                    <div>
                        <div className=" mt-6" style={{ width: "50%" }}>
                            <UseCustomPhoneInput value={settingsData.phoneNumber} actionValues={true} />
                        </div>

                        <div className=" flex justify-end mt-8">
                            <UseButton
                                action={() => {
                                    setCardInEdition(null);
                                    setResetData(true);
                                }}
                                adjust="mr-2"
                                buttonColor="btn-white"
                                buttonName={t("cancel")}
                                id={"mobile-settings-chat-feature-cancel-button"}
                            />
                            <UseButton
                                action={() => {
                                    dispatch(
                                        setExecuteQuery({
                                            action: ACTIONS_MOBILE_SETTINGS.UPDATE_SETTINGS_CHAT_FEATURE,
                                            params: settingsData,
                                        })
                                    );
                                }}
                                adjust="mr-2"
                                buttonColor="btn-blue"
                                id={"mobile-settings-chat-feature-save-button"}
                                buttonName={t("save")}
                            />
                        </div>
                    </div>
                ) : (
                    <div className=" mt-4 text-gray-800">
                        {!settingsData.phoneNumber ? t("no-phone-number-configurated-yet") : settingsData.phoneNumber}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MobileSettingsChatFeature;
