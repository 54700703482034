import React, { useRef, useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";

import DisplayMenuSidebar from "hooks/Utils/DisplayMenuSidebar";

import MainMenu from "components/Header/MainMenu";
import HotelsDropdown from "components/Header/HotelsDropdown";
import Logo from "components/Header/logo";
import UserMenu from "components/UserMenu";
import IconsBar from "components/Header/IconsBar";

const Header = () => {
    const showMainMenu = useSelector((state) => state.ui?.showMainMenu);
    const menusDisplay = DisplayMenuSidebar();
    const scrollMenuRef = useRef(null);

    const [hasScroll, setHasScroll] = useState(null);

    const visible = !(menusDisplay?.hideHeader || !showMainMenu);

    useLayoutEffect(() => {
        const handleScroll = () => {
            if (scrollMenuRef.current) {
                setHasScroll(scrollMenuRef.current.scrollWidth > scrollMenuRef.current.clientWidth);
            }
        };
        setTimeout(() => {
            handleScroll();
        }, 1000);
        window.addEventListener("resize", handleScroll);
        return () => {
            window.removeEventListener("resize", handleScroll);
        };
    }, [scrollMenuRef.current, visible]);

    if (!visible) {
        return null;
    }

    return (
        <div className="fixed z-200 flex bg-white w-full box-border h-20">
            <div className="w-1/6 p-2 overflow-hidden">
                <Logo />
            </div>
            <div className="w-5/6 flex justify-between pr-5 shadow-sm">
                <div
                    ref={scrollMenuRef}
                    className="flex items-center scrollMenuNav overflow-y-hidden overflow-x-auto"
                    style={{
                        boxShadow: hasScroll ? "inset 0 4px 8px 0 rgba(0, 0, 0, 0.06)" : "none",
                    }}
                >
                    <MainMenu />
                </div>
                <div className="flex items-center p-5 space-x-5">
                    <IconsBar />
                    <HotelsDropdown />
                    <UserMenu />
                </div>
            </div>
        </div>
    );
};

export default Header;
