import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    cleanTableStore,
    setCount,
    setCountPosition,
    setError,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    cleanActiveFilters,
    setRefreshData,
    setActions,
    setHeaderButtons,
    setAvailableFilters,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetSettingsTableHeaders";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import { Session } from "../../Utils/Session";
import { useTranslation } from "react-i18next";

import { useAuth } from "hooks/Session/auth";

//Model
import Role from "./models/Role";

const ListRoles = () => {
    const { t } = useTranslation();

    const { isCorporate } = useAuth();

    //Store data
    const { currentPage, perPage, filterQuery, sortCriteria, refreshData } = useSelector((state) => state.table);
    const [rolesIds, setRolesIds] = useState([]);

    //Data
    const sectionName = "list-roles";
    const HOTEL_ID = Session.getProject()?.id;
    const projects = Session.getProjects();
    const projectData = projects ? projects.filter((project) => parseInt(project.id) === parseInt(HOTEL_ID))[0] : null;
    const isCorportate = projectData.ref === "CORPORATE";

    //States
    const [sorting] = useState(`,orderBy:{field: "id", criteria: "desc"}`);

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_ROLES_INFO = gql`
        {
            roles(page: ${currentPage}, size: ${perPage}${sortCriteria ? sortCriteria : sorting}${(filterQuery.includes(
        "customised"
    ) || filterQuery.includes("predefined")
        ? filterQuery.replace("customised", "CUSTOMISED").replace("predefined", "PREDEFINED")
        : filterQuery.replace("type :", "")
    )
        .replace('"CUSTOMISED"', "CUSTOMISED")
        .replace('"PREDEFINED"', "PREDEFINED")}){
               info { count }
              results{ id type name users accesses{ id:name name} }}
        }`;
    const GET_USER_INFO = gql`
        {
            user: users(filter: { roles: [${rolesIds.join(",")}] }) {
                results {
                    email
                    fullName
                    roles {
                        type
                        id
                    }
                    assignedAll
                    hasCorporate
                    properties {
                        name
                        id
                        ref
                        __typename
                    }
                    __typename
                }
                __typename
            }
        }
    `;
    const GET_FILTERS_DATA = gql`
        {
              projectAccesses : permissions (projectCode:"${projectData?.code || ""}" ${
        projectData && projectData.ref === "CORPORATE" ? " ,allChainPermissions:true" : ""
    })      
              accesses (orderBy:{field:"category",criteria:"asc"}){
                info { count }
                results { id:name name category }
              }
        }`;

    const { data, loading, error, refetch, networkStatus } = useQuery(GET_ROLES_INFO, {
        notifyOnNetworkStatusChange: true,
    });
    const [executeOtherQuery, { data: otherQueryData, loading: otherQueryLoading, error: otherQueryError }] =
        useLazyQuery(GET_USER_INFO);
    const [executeFiltersQuery, filtersData] = useLazyQuery(GET_FILTERS_DATA);

    //Listeners
    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(cleanTableStore());
        const tableHeaders = GetTableHeaders(sectionName);
        dispatch(setSearchable(true));
        dispatch(setHeaders(tableHeaders));
        if (isCorporate) {
            dispatch(setHeaderButtons(GetTableHeaderButton(sectionName)));
        }
        dispatch(setCountPosition("table-header"));
        dispatch(showRowForm(false));
        executeFiltersQuery();
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (rolesIds.length) {
            executeOtherQuery();
        }
    }, [rolesIds]);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (!loading && !error && !otherQueryLoading && data && data.roles && networkStatus === 7) {
            setRolesIds(data.roles.results.map((x) => x.id));
            executeOtherQuery();
        }
    }, [networkStatus, data]);

    useEffect(() => {
        if (!otherQueryData?.user?.results) return;
        const assigned_users = otherQueryData.user.results;
        const items = data.roles.results.map((role) => {
            const users = assigned_users.filter(
                (user) =>
                    (isCorportate ? true : user.properties.some((property) => property.ref === projectData.ref)) &&
                    user.roles.some((x) => x.id === role.id)
            );
            return Role({ ...role, users }, t, isCorportate);
        });
        dispatch(setTableResults(items));
        dispatch(setCount(data.roles.info.count));
        //Add to store
        if (isCorportate) dispatch(setActions());
        if (filterQuery === "") {
            dispatch(cleanActiveFilters());
        }
        dispatch(setLoading(false));
    }, [data, otherQueryData, networkStatus]);

    useEffect(() => {
        if (
            !filtersData.error &&
            !filtersData.loading &&
            filtersData &&
            filtersData.data &&
            filtersData.data.projectAccesses &&
            filtersData.data.accesses
        ) {
            const availableFilters = GetAvailableFilters(
                sectionName,
                extractAccessesList(filtersData.data, t),
                undefined,
                undefined,
                undefined,
                t
            );
            dispatch(setAvailableFilters(availableFilters));
        }
        // eslint-disable-next-line
    }, [filtersData]);

    useEffect(() => {
        if (error) {
            console.error(error);
            dispatch(setError(error));
        }
        if (filtersData.error) {
            console.error(filtersData.error);
            console.error(filtersData.error.message);
            dispatch(setError(filtersData.error));
        }

        // eslint-disable-next-line
    }, [error, filtersData]);
};

const extractAccessesList = (data, t) => {
    let accessesArray = [];
    data.accesses.results.map((accesse) =>
        data.projectAccesses.includes(accesse.name) || accesse.category === "General Settings"
            ? accessesArray.push({
                  value: t(accesse.name),
                  id: accesse.name,
                  visible: true,
                  optGroup: t(accesse.category),
              })
            : null
    );
    accessesArray.sort((a, b) => {
        if (a.optGroup === b.optGroup) {
            return t(a.value).localeCompare(t(b.value));
        }
        return String(a.optGroup).localeCompare(String(b.optGroup));
    });
    return accessesArray;
};

export default ListRoles;
