const NewFont = () => {
    let action = "add-library-font";
    let modalInputs = [
        {
            name: "files",
            value: "",
            type: "file",
            contentTypes: [
                {
                    type: "font",
                    maxSize: 11,
                    multiple: true,
                },
            ],
            triggerAction: action,
        },
        {
            text: "",
            name: "file",
            value: "",
            type: "text",
            cStyle: "hidden",
        },
        {
            text: "",
            name: "fileType",
            value: "",
            type: "text",
            cStyle: "hidden",
        },
    ];
    let modal = {
        title: "",
        value: "",
        inputs: modalInputs,
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            { name: "save", style: "blue", action: action, customClass: "hidden" },
        ],
    };
    return modal;
};

export default NewFont;
