import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { formatDate } from "./services/formatDate";
import { Warning } from "./Warning";
import { NetworkHeader } from "./NetworkHeader";

export const DhcpInfo = ({ dhcpInfo, called, loading }) => {
    const { t } = useTranslation();

    const { lastUpdate, updateTimeMin, dhcpData } = dhcpInfo || {};
    const lastUpdateDate = formatDate(lastUpdate);

    const updateDay = updateTimeMin / 60 / 24 === 1 ? t("day") : `${updateTimeMin} ${t("minutes")}`;

    if (loading || !called) return null;

    return (
        <main className="bg-white p-4 border-r border-gray-300">
            <NetworkHeader title="dhcp" lastUpdateDate={lastUpdateDate} updateTimeMin={updateDay} isDhcp={true} />
            <div className="flex flex-wrap mb-6">
                <ul className="flex flex-col gap-4 list-disc ml-10">
                    {dhcpData &&
                        typeof dhcpData === "object" &&
                        Object?.keys(dhcpData)?.map((key) => {
                            return (
                                <Fragment key={key}>
                                    <li>
                                        {capitalizeFirst(t(key))}:{" "}
                                        <span className="font-bold" id={`dhcp-${key}`}>
                                            {typeof dhcpData[key] === "object" ? dhcpData[key]?.value : dhcpData[key]}
                                        </span>
                                    </li>
                                    {Array.isArray(dhcpData[key]?.warning) ? (
                                        !dhcpData[key]?.warning?.length > 0 ? null : (
                                            <div className="flex ml-2 flex-col text-red-100">
                                                <Warning text={t("other-server-detected")} t={t} />
                                                <ul id="other-servers-errors">
                                                    {dhcpData[key]?.warning?.map((warning) => (
                                                        <li className="text-gray-800 ml-6" key={warning}>
                                                            - {t(key)}: {warning}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )
                                    ) : null}
                                    {dhcpData[key]?.warning && key === "dns" ? (
                                        <Warning color={"orange"} text={t("dns-alert")} />
                                    ) : null}
                                    {dhcpData[key]?.warning && key === "free-ips" ? (
                                        <Warning color={"orange"} text={t("free-ips-low")} />
                                    ) : null}
                                </Fragment>
                            );
                        })}
                </ul>
            </div>
        </main>
    );
};
