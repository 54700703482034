import React from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { setActionDateType } from "../../actions/globalActions";

const UseOptions = () => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { dateTypes } = useSelector((state) => state.sectionContent);

    //Actions
    const dispatch = useDispatch();
    const clickHandler = (e) => {
        dispatch(setActionDateType(e.currentTarget.dataset.action));
    };
    return (
        <div className="t-options-container p-2">
            {dateTypes.map((dateType, index) => (
                <div
                    className="h-10 hover:bg-gray-100 w-56"
                    key={index}
                    id={`options-component-${index}`}
                    data-action={dateType.value}
                    onClick={clickHandler}
                >
                    <div className="t-select-option p-2 ">
                        {dateType.translate ? langStrings[dateType.value] : dateType.value}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default UseOptions;
