import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { fireKeyEvent } from "../../../../actions/designActions";
import { setExecuteQuery } from "../../../../actions/sectionActions";
import { cloneObject } from "../../../../hooks/Utils/Utils";
import { ensureToolbarPos } from "../../../../hooks/Utils/DesignUtils";

const KeyControl = () => {
    const dispatch = useDispatch();

    const { type: screenType } = useParams();

    const {
        gridLayout,
        containersLayouts,
        itemSelected,
        widgetToolbarVisible,
        gridItems,
        cDevice,
        keyEvent,
        editingMenu,
    } = useSelector((state) => state.design);
    const isMobile = cDevice && cDevice.type === "Mobile";

    const itemSelectedRef = useRef();
    itemSelectedRef.current = itemSelected;

    const widgetToolbarVisibleRef = useRef();
    widgetToolbarVisibleRef.current = widgetToolbarVisible;

    useEffect(() => {
        document.onkeydown = function (e) {
            let code = e.keyCode ? e.keyCode : e.which;
            if ([37, 38, 39, 40].includes(code)) {
                if (widgetToolbarVisibleRef.current) {
                    return;
                }
                if (itemSelectedRef.current && document.activeElement.className.indexOf("DraftEditor") === -1) {
                    e.preventDefault();
                }
                e.stopPropagation();
            }
            dispatch(fireKeyEvent(e));
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (keyEvent) {
            if (!itemSelected) return;
            let code = keyEvent.keyCode ? keyEvent.keyCode : keyEvent.which;
            //buscar item en widgets
            let iWidget = gridLayout.filter((item) => item.i === itemSelected)[0];
            let containerID = null;
            let gridLimit = cDevice.grid;
            let gridLayoutToLoop = cloneObject(gridLayout);
            if (!iWidget) {
                //buscar item en contenedores
                containersLayouts.map((container) =>
                    container.layout.map((l) =>
                        l.i === itemSelected
                            ? ((iWidget = l),
                              (containerID = container.i),
                              (gridLayoutToLoop = cloneObject(container.layout)))
                            : null
                    )
                );
                if (!iWidget) {
                    return;
                } else {
                    //get containerLimits
                    const cWidget = gridItems.filter((item) => String(item.i) === String(containerID))[0];
                    gridLimit = cWidget.data.grid;
                }
            }

            //Block movement in text widget if focus is in the widget body. Movement allowed from widget title
            if (iWidget.type === "TEXT" && document.activeElement.className.indexOf("DraftEditor") > -1) {
                return;
            }
            if (iWidget.type === "TVMENU" && !editingMenu) {
                return;
            }

            const cPos = {
                x: iWidget.x,
                y: iWidget.y,
                w: iWidget.w,
                h: iWidget.h,
            };
            let newPos = cloneObject(cPos);
            let updatePos = false;
            let collision = false;

            code = keyEvent.keyCode ? keyEvent.keyCode : keyEvent.which;
            switch (code) {
                //left
                case 37:
                    if (widgetToolbarVisible) {
                        return;
                    }
                    if (cPos.x > 0) {
                        updatePos = true;
                        newPos.x = cPos.x - 1;
                        const xPos = cPos.x;
                        const initY = newPos.y;
                        const endY = newPos.y + newPos.h;
                        gridLayoutToLoop.forEach((gItem) => {
                            if (
                                gItem.type !== "TVMENU" &&
                                gItem.x + gItem.w === xPos &&
                                ((initY >= gItem.y && initY < gItem.y + gItem.h) ||
                                    (gItem.y >= initY && gItem.y < endY))
                            ) {
                                collision = true;
                            }
                        });
                    }
                    break;
                //top
                case 38:
                    if (widgetToolbarVisible) {
                        return;
                    }
                    if (cPos.y > 0) {
                        updatePos = true;
                        newPos.y = cPos.y - 1;
                        const yPos = cPos.y;
                        const initX = newPos.x;
                        const endX = newPos.x + newPos.w;
                        gridLayoutToLoop.forEach((gItem) => {
                            if (
                                gItem.type !== "TVMENU" &&
                                gItem.y + gItem.h === yPos &&
                                ((initX >= gItem.x && initX < gItem.x + gItem.w) ||
                                    (gItem.x >= initX && gItem.x < endX))
                            ) {
                                collision = true;
                            }
                        });
                    }
                    break;
                //right
                case 39:
                    if (widgetToolbarVisible) {
                        return;
                    }
                    if (cPos.x + cPos.w < gridLimit.cols) {
                        updatePos = true;
                        newPos.x = cPos.x + 1;
                        const xPos = cPos.x + cPos.w;
                        const initY = newPos.y;
                        const endY = newPos.y + newPos.h;
                        gridLayoutToLoop.forEach((gItem) => {
                            if (
                                gItem.type !== "TVMENU" &&
                                gItem.x === xPos &&
                                ((initY >= gItem.y && initY < gItem.y + gItem.h) ||
                                    (gItem.y >= initY && gItem.y < endY))
                            ) {
                                collision = true;
                            }
                        });
                    }
                    break;
                //down
                case 40:
                    if (widgetToolbarVisible) {
                        return;
                    }
                    if (cPos.y + cPos.h < gridLimit.rows || isMobile) {
                        updatePos = true;
                        newPos.y = cPos.y + 1;
                        const yPos = cPos.y + cPos.h;
                        const initX = newPos.x;
                        const endX = newPos.x + newPos.w;
                        gridLayoutToLoop.forEach((gItem) => {
                            if (
                                gItem.type !== "TVMENU" &&
                                gItem.y === yPos &&
                                ((initX >= gItem.x && initX < gItem.x + gItem.w) ||
                                    (gItem.x >= initX && gItem.x < endX))
                            ) {
                                collision = true;
                            }
                        });
                    }
                    break;
                case 46:
                    if (
                        ["TVMENU", "TOPBAR", "ROOMS", "LANGSELECTION", "WIFILOGIN"].includes(iWidget.type) ||
                        (iWidget.type === "VIDEO" && screenType === "WELCOME") ||
                        widgetToolbarVisible
                    ) {
                        return;
                    }

                    dispatch(setExecuteQuery({ action: "deleteWidget", params: { widgetID: itemSelected } }));
                    return;

                default:
                    break;
            }

            if (iWidget.type === "TVMENU") {
                collision = false;
            }
            if (updatePos && !collision) {
                if (containerID) {
                    dispatch(
                        setExecuteQuery({
                            action: "updateContainerWidgetPos",
                            params: {
                                containerId: containerID,
                                widgetID: itemSelected,
                                widgetPos: newPos,
                                prevWidgetPos: cPos,
                            },
                        })
                    );
                } else {
                    dispatch(
                        setExecuteQuery({
                            action: "updateWidgetPos",
                            params: {
                                widgetID: itemSelected,
                                widgetPos: newPos,
                                prevWidgetPos: cPos,
                            },
                        })
                    );
                }

                ensureToolbarPos(itemSelected);
            }
        }
        // eslint-disable-next-line
    }, [keyEvent]);

    return null;
};

export default KeyControl;
