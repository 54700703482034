import { PROPERTY_APPEARANCE_TYPE, CORPORATE_APPEARANCE_TYPE } from "../../../Utils/AppearancesUtils";
const TreeDesigns = (designsTree, appearanceName, t) => {
    return {
        title: t("{{capitalize, capitalize}}", { capitalize: t("linked-designs") }),
        bodyAdjust: "w-3/12",
        inputs: [
            {
                text: [t("linked-designs-text", { name: appearanceName })],
                name: "linked-designs-text",
                type: "htmltext",
            },
            {
                data: orderDesignsTree(designsTree),
                name: "designs-tree",
                type: "designsTree",
            },
        ],
        buttons: [{ name: "accept", style: "blue", action: "close" }],
    };
};

const orderDesignsTree = (designsTree) => {
    let _orderedTree = { [CORPORATE_APPEARANCE_TYPE]: [], [PROPERTY_APPEARANCE_TYPE]: [] };
    designsTree.forEach((design) => {
        if (design.type === CORPORATE_APPEARANCE_TYPE) {
            _orderedTree[CORPORATE_APPEARANCE_TYPE].push(design);
        } else if (design.type === PROPERTY_APPEARANCE_TYPE) {
            const indexTree = _orderedTree[PROPERTY_APPEARANCE_TYPE].findIndex(
                (project) => project.projectID === design.projectID
            );
            if (indexTree > -1) {
                _orderedTree[PROPERTY_APPEARANCE_TYPE][indexTree].designs.push(design);
            } else {
                _orderedTree[PROPERTY_APPEARANCE_TYPE].push({
                    projectID: design.projectID,
                    name: design.projectName,
                    designs: [design],
                });
            }
        }
    });
    return _orderedTree;
};

export default TreeDesigns;
