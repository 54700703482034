const NewVideo = (parentRef, ref, language, dataMediaLibraryBrowser, refName) => {
    let isUpdate = ref ? true : false;
    let action = isUpdate ? "media-library-update-video" : "add-media-library-video";
    let modalInputs = [
        {
            name: "files",
            value: "",
            type: "file",
            contentTypes: [
                {
                    type: "video",
                    maxSize: 2048,
                    multiple: !isUpdate,
                },
            ],
            triggerAction: action,
        },
        {
            text: "",
            name: "ref",
            value: ref,
            type: "text",
            cStyle: "hidden",
        },
        {
            text: "",
            name: "language",
            value: language,
            type: "text",
            cStyle: "hidden",
        },
        {
            text: "",
            name: "parentRef",
            value: parentRef,
            type: "text",
            cStyle: "hidden",
        },
        {
            text: "",
            name: "file",
            value: "",
            type: "text",
            cStyle: "hidden",
        },
        {
            text: "",
            name: "fileType",
            value: "",
            type: "text",
            cStyle: "hidden",
        },
        {
            text: "",
            name: "fileName",
            value: "",
            type: "text",
            cStyle: "hidden",
        },
        {
            text: "",
            name: "dataMediaLibraryBrowser",
            value: dataMediaLibraryBrowser,
            type: "text",
            cStyle: "hidden",
        },
        {
            text: "",
            name: "refName",
            value: refName,
            type: "text",
            cStyle: "hidden",
        },
    ];
    let modal = {
        title: "",
        value: "",
        inputs: modalInputs,
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            { name: "save", style: "blue", action: action, customClass: "hidden" },
        ],
    };
    if (isUpdate) {
        modal["id"] = ref;
        modal["useGlobalLoading"] = true;
    }
    return modal;
};

export default NewVideo;
