export const TreeDataParser = (content, parentRef, sideBarRef, breadcrumbsData, setIsCustomCrumbs = null) => {
    let include = content.ref === parentRef;
    if (!include) {
        content.tree.map((s) => {
            if (s.ref === parentRef) {
                include = true;
            }
            return s;
        });
    }
    if (include) {
        if (setIsCustomCrumbs) setIsCustomCrumbs(true);
        breadcrumbsData.push({
            name: content.name,
            translate: false,
            route: "/design/library/" + content.ref + "/" + sideBarRef,
        });
        if (content.tree) {
            content.tree.map((c) => {
                TreeDataParser(c, parentRef, sideBarRef, breadcrumbsData, setIsCustomCrumbs);
                return c;
            });
        }
    }
};
