import { capitalizeFirst } from "../../../../hooks/Utils/Utils";

const InputGroupMakeDefault = (t, title, id) => {
    return [
        {
            id: id,
            titlePrefix: title,
            title: ["-", capitalizeFirst(t("make-default"))],
            executeSectionQuery: true,
            bodyAdjust: "w-3/12",
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "make-default",
                    style: "blue",
                    action: "make-input-group-default",
                },
            ],
            inputs: [
                {
                    text: `${t("make-input-group-default").replace("%0", title)}`,
                    type: "htmltext",
                },
            ],
        },
    ];
};

export default InputGroupMakeDefault;
