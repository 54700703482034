import { useEffect, useState } from "react";
import {
    UPDATE_EMAIL,
    UPDATE_OPEN,
    UPDATE_PASSCODE,
    UPDATE_PMS,
    parseRenovationToSeconds,
    numberDevices,
} from "./Utils";
import { toast } from "react-toastify";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";

const UpdateAccessTypes = ({
    selectMutation,
    emailFields,
    openFields,
    pmsFields,
    codeFields,
    mainSwitches,
    refetch,
    setSelectMutation,
    cardEdition,
    cardsIDs,
    setCardEdition,
}) => {
    const { t } = useTranslation();
    const [mutation, setMutation] = useState("mutation{checkIn}");
    const [execute, setExecute] = useState(false);

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;
    const [executeMutation] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            console.log(err);
        },
        onCompleted() {
            toast.success(t("operation-successful"));
            refetch();
            setCardEdition(null);
        },
    });

    useEffect(() => {
        if (execute) {
            executeMutation();
            setExecute(false);
            setSelectMutation(null);
        }
        // eslint-disable-next-line
    }, [execute]);

    useEffect(() => {
        switch (selectMutation) {
            case UPDATE_EMAIL:
                setMutation(`
                mutation {
                    updateWifiAccessType(
                      id: ${emailFields["email-id"]}
                      breakconn: ${emailFields["email-breakconn"]}
                      renovation: ${
                          parseRenovationToSeconds("access-type-email-email") ||
                          (cardEdition && cardEdition === cardsIDs[0])
                              ? parseRenovationToSeconds("access-type-email-email")
                              : emailFields["email-renovation"]
                      }                      
                      rrss: ${emailFields["email-social-networks"]}
                      enable: ${mainSwitches && mainSwitches["email"]}
                      accesstypes_name: "email"
                      additionalData: {
                        name: { 
                            mandatory:  ${emailFields["email-name"].mandatory} , 
                            enabled: ${emailFields["email-name"].enabled}
                        }
                        surname: { 
                            mandatory:  ${emailFields["email-surname"].mandatory} , 
                            enabled: ${emailFields["email-surname"].enabled} 
                        }
                        age: { 
                            mandatory:  ${emailFields["email-date-of-birth"].mandatory} , 
                            enabled: ${emailFields["email-date-of-birth"].enabled} 
                        }
                        email: { 
                            mandatory:  ${emailFields["email-email"].mandatory} , 
                            enabled: ${emailFields["email-email"].enabled} 
                        }
                        country: { 
                            mandatory:  ${emailFields["email-country"].mandatory} , 
                            enabled: ${emailFields["email-country"].enabled} 
                        }
                      }
                    ) {
                      error
                      id
                      ok
                    }
                  }
                `);
                setExecute(true);
                break;
            case UPDATE_OPEN:
                setMutation(`
                mutation {
                    updateWifiAccessType(
                      id: ${openFields["open-id"]}
                      breakconn: ${openFields["open-breakconn"]}
                      renovation: ${
                          parseRenovationToSeconds("access-type-open-open") ||
                          (cardEdition && cardEdition === cardsIDs[0])
                              ? parseRenovationToSeconds("access-type-open-open")
                              : openFields["open-renovation"]
                      }                      
                      enable: ${mainSwitches && mainSwitches["open"]}
                      accesstypes_name: "open"
                      additionalData: {
                        name: { 
                            mandatory:  false, 
                            enabled: false
                        }
                        surname: { 
                            mandatory:  false, 
                            enabled: false
                        }
                        age: { 
                            mandatory:  false, 
                            enabled: false
                        }
                        email: { 
                            mandatory:  false, 
                            enabled: false
                        }
                        country: { 
                            mandatory:  false, 
                            enabled: false
                        }
                      }
                    ) {
                      error
                      id
                      ok
                    }
                  }
                `);
                setExecute(true);
                break;
            case UPDATE_PMS:
                const pmsUsersNumber =
                    pmsFields["pms-devices-option"] === "unlimited"
                        ? 0
                        : numberDevices("access-type-pms") || pmsFields["pms-users-number"];

                setMutation(`mutation {
                    updateWifiAccessType(
                      id: ${pmsFields["pms-id"]}
                      breakconn: ${pmsFields["pms-breakconn"]}
                      enable: ${mainSwitches && mainSwitches["pms"]}
                      accesstypes_name: "pms"
                      usersNumber: ${pmsUsersNumber}
                      additionalData: {
                        name: { 
                            mandatory:  ${pmsFields["pms-name"].mandatory} , 
                            enabled: ${pmsFields["pms-name"].enabled}
                        }
                        surname: { 
                            mandatory:  ${pmsFields["pms-surname"].mandatory} , 
                            enabled: ${pmsFields["pms-surname"].enabled} 
                        }
                        age: { 
                            mandatory:  ${pmsFields["pms-date-of-birth"].mandatory} , 
                            enabled: ${pmsFields["pms-date-of-birth"].enabled} 
                        }
                        email: { 
                            mandatory:  ${pmsFields["pms-email"].mandatory} , 
                            enabled: ${pmsFields["pms-email"].enabled} 
                        }
                        country: { 
                            mandatory:  ${pmsFields["pms-country"].mandatory} , 
                            enabled: ${pmsFields["pms-country"].enabled} 
                        }
                      }
                    ) {
                      error
                      id
                      ok
                    }
                  }`);
                setExecute(true);
                break;
            case UPDATE_PASSCODE:
                setMutation(`mutation {
                updateWifiAccessType(
                  id: ${codeFields["code-id"]}
                  breakconn: ${codeFields["code-breakconn"]}
                  enable: ${mainSwitches && mainSwitches["code"]}
                  accesstypes_name: "code"
                  additionalData: {
                    name: { 
                        mandatory:  ${codeFields["code-name"].mandatory} , 
                        enabled: ${codeFields["code-name"].enabled}
                    }
                    surname: { 
                        mandatory:  ${codeFields["code-surname"].mandatory} , 
                        enabled: ${codeFields["code-surname"].enabled} 
                    }
                    age: { 
                        mandatory:  ${codeFields["code-date-of-birth"].mandatory} , 
                        enabled: ${codeFields["code-date-of-birth"].enabled} 
                    }
                    email: { 
                        mandatory:  ${codeFields["code-email"].mandatory} , 
                        enabled: ${codeFields["code-email"].enabled} 
                    }
                    country: { 
                        mandatory:  ${codeFields["code-country"].mandatory} , 
                        enabled: ${codeFields["code-country"].enabled} 
                    }
                  }
                ) {
                  error
                  id
                  ok
                }
              }`);
                setExecute(true);
                break;
            default:
                break;
        }
        // eslint-disable-next-line
    }, [selectMutation]);

    return null;
};

export default UpdateAccessTypes;
