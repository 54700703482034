import { gql } from "apollo-boost";

export const GET_ROOMS_INFO = gql`
    {
        rooms: rooms(size: 999, orderBy: { field: "number", criteria: "asc" }) {
            info {
                count
            }
            results {
                floorID
                floorName
                id
                name
                number
                pmsNumber
                roomTVs {
                    id
                    name
                    cec
                }
                wingID
                wingName
            }
        }
        locations: locations {
            results
        }
        floors: installationMap {
            results {
                id
                name
                floors {
                    id
                    name
                    rooms {
                        id
                        name
                        recentGuests
                    }
                }
            }
        }
    }
`;

export const DELETE_ROOM = gql`
    mutation DeleteRoom($id: Int64!) {
        deleteRoom(id: $id) {
            error
            ok
        }
    }
`;

export const CREATE_ROOM = gql`
    mutation CreateRoom(
        $floorID: Int64!
        $name: String!
        $number: Int!
        $pmsNumber: String
        $locations: [Location]!
        $tvsPerRoom: Int
    ) {
        createRoom(
            floorID: $floorID
            name: $name
            number: $number
            pmsNumber: $pmsNumber
            Locations: $locations
            TVsPerRoom: $tvsPerRoom
        ) {
            error
            ok
        }
    }
`;
