import React from "react";
import { useSelector } from "react-redux";
//Components
import UsePunctualEvent from "./Schedule/usePunctualEvent";
import UsePeriodicEvent from "./Schedule/usePeriodicEvent";
import UseMainOption from "./Schedule/useMainOptions";

const UseRadioDispatcher = () => {
    const { eventTypeActive } = useSelector((state) => state.sectionContent);

    return (
        <>
            <UseMainOption />
            {eventTypeActive ? (
                eventTypeActive === "punctual-event" ? (
                    <UsePunctualEvent />
                ) : (
                    <UsePeriodicEvent />
                )
            ) : null}
        </>
    );
};

export default UseRadioDispatcher;
