import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../../../../../hooks/Utils/Utils";
import { ShopContext } from "contexts/Sales/Shop";
import SettingsCard from "components/SettingsCard";
import Loading from "components/Loading";
import ErrorInfo from "components/ErrorInfo";

const ShopSettingsPrinter = () => {
    const { t } = useTranslation();
    const { print } = useContext(ShopContext);

    useEffect(() => {
        if (print) {
            print.load();
        }
    }, []);

    return (
        <SettingsCard
            title={t("print-settings")}
            info={t("print-setting-instructions-tooltip")}
            description={t("print-setting-instructions")}
        >
            {(() => {
                if (print?.loading) {
                    return <Loading />;
                }

                if (print?.error) {
                    return <ErrorInfo retry={print.get}>{print.error}</ErrorInfo>;
                }

                return (
                    <SettingsCard.ParamsList
                        data={[
                            {
                                id: "settings-print-url",
                                name: t("url-server"),
                                description: print?.data?.link,
                            },
                            {
                                id: "settings-print-id",
                                name: t("ID"),
                                description: print?.data?.id,
                            },
                            {
                                id: "settings-print-password",
                                name: capitalizeFirst(t("password")),
                                description: print?.data?.password,
                            },
                        ]}
                    />
                );
            })()}
        </SettingsCard>
    );
};

export default ShopSettingsPrinter;
