export const RoomGroups = [
    "group-name",
    "number-of-rooms",
    "search",
    "room",
    "rooms",
    "room-group",
    "room-groups",
    "rooms-added",
    "give-group-name",
    "new-group",
    "new-group-of-rooms",
    "add-remove-room",
    "add",
    "group",
    "select-room-group",
    "edit-room-group",
    "room-group-delete",
];
