import React, { createContext, useMemo, useState } from "react";

export const AccessTypesContext = createContext({
    name: null,
    setName: () => {},
    uploadMinBandwidth: null,
    setUploadMinBandwidth: () => {},
    downloadMinBandwidth: null,
    setDownloadMinBandwidth: () => {},
    uploadMaxBandwidth: null,
    setUploadMaxBandwidth: () => {},

    //Validations
    nameValidation: {},
    uploadMinBandwidthValidation: {},
    downloadMinBandwidthValidation: {},
    setNameValidation: () => {},
    setUploadMinBandwidthValidation: () => {},
    setDownloadMinBandwidthValidation: () => {},

    // Create and edit
    editing: false,
    setEditing: () => {},
    creating: false,
    setCreating: () => {},

    // Loading
    loadingRefresh: false,
    setLoadingRefresh: () => {},
});

export const AccessTypesProvider = ({ children }) => {
    const [name, setName] = useState(null);
    const [uploadMinBandwidth, setUploadMinBandwidth] = useState(null);
    const [downloadMinBandwidth, setDownloadMinBandwidth] = useState(null);
    const [uploadMaxBandwidth, setUploadMaxBandwidth] = useState(null);
    const [downloadMaxBandwidth, setDownloadMaxBandwidth] = useState(null);

    const [nameValidation, setNameValidation] = useState("");
    const [uploadMinBandwidthValidation, setUploadMinBandwidthValidation] = useState("");
    const [downloadMinBandwidthValidation, setDownloadMinBandwidthValidation] = useState("");

    const [editing, setEditing] = useState(false);
    const [creating, setCreating] = useState(false);

    const [loadingRefresh, setLoadingRefresh] = useState(false);

    const contextValue = useMemo(
        () => ({
            name,
            setName,
            uploadMinBandwidth,
            setUploadMinBandwidth,
            downloadMinBandwidth,
            setDownloadMinBandwidth,
            uploadMaxBandwidth,
            setUploadMaxBandwidth,
            downloadMaxBandwidth,
            setDownloadMaxBandwidth,
            nameValidation,
            setNameValidation,
            uploadMinBandwidthValidation,
            setUploadMinBandwidthValidation,
            downloadMinBandwidthValidation,
            setDownloadMinBandwidthValidation,
            editing,
            setEditing,
            creating,
            setCreating,
            loadingRefresh,
            setLoadingRefresh,
        }),
        [
            name,
            uploadMinBandwidth,
            downloadMinBandwidth,
            uploadMaxBandwidth,
            downloadMaxBandwidth,
            nameValidation,
            uploadMinBandwidthValidation,
            downloadMinBandwidthValidation,
            editing,
            creating,
            loadingRefresh,
        ]
    );

    return <AccessTypesContext.Provider value={contextValue}>{children}</AccessTypesContext.Provider>;
};
