import { CORPORATE_CHANNEL, TUNE_TYPES } from "../../../Utils/Services/ChannelsUtils";
import { Session } from "../../../Utils/Session";

const Channel = (channel, folder, t, lang) => {
    const { id, name, isRadio, tuneInfo, groups, grids, logoRef } = channel;
    const dataType = "channel";
    const type = isRadio ? t("radio") : t("tv");
    const imgSrc = logoRef ? Session.getDasUrl(logoRef + "?lang=" + lang + "&w=70&h=70") : null;
    let tunesCell = {
        cellContent: "text",
        value: TUNE_TYPES.untuned.label,
        specialWidth: folder !== CORPORATE_CHANNEL.value ? "w-2/12" : "w-3/12",
        translate: true,
    };

    const tooltipTunesInfo = (type, tune) => {
        let response = `<div class="font-bold py-2">${t("tuning information")} </div>`;
        if (type === TUNE_TYPES.udp.value.toLowerCase() && tune.ip && tune.port) {
            response += `
            <div class="block">${t("ip")}: ${tune.ip}</div>
            <div class="block">${t("port")}: ${tune.port}</div>
            `;
        } else if (type === TUNE_TYPES.hls.value.toLowerCase() && tune.url) {
            response += `
            <span class="block">${t("url")}: ${tune.url}</span>
            `;
        } else if (typeof tune === "object") {
            Object.keys(tune).forEach((prop) =>
                tune[prop] && prop != "isEnabled" && prop?.[0] != "_"
                    ? (response += `<div class="p-2 flex w-full"><div class="w-3/5">${prop}</div><div class="w-2/5">${tune[prop]}</div></div>`)
                    : null
            );
        }
        return response;
    };

    const tooltipGroupsInfo = () => {
        let response = "";
        if (groups && groups.length > 0) {
            groups.forEach((group) => {
                response += `<span class="block">${group.name}</span><br />`;
            });
        }
        return response;
    };

    const tooltipGridsInfo = () => {
        let response = "";
        if (grids && grids.length > 0) {
            grids.forEach((grid) => {
                response += `<span class="block">${grid.name}</span><br />`;
            });
        }
        return response;
    };

    if (tuneInfo) {
        let tuneInfoJson = JSON.parse(tuneInfo);
        if (tuneInfoJson) {
            const listTunes = [];
            Object.keys(tuneInfoJson)
                .sort((a, b) => (a < b ? 1 : -1))
                .forEach((objectKey, index) => {
                    if (tuneInfoJson[objectKey] && tuneInfoJson[objectKey].isEnabled) {
                        let toolTipContent = tooltipTunesInfo(objectKey, tuneInfoJson[objectKey]);
                        listTunes.push([
                            index,
                            objectKey.replaceAll("-", " "),
                            TUNE_TYPES[objectKey]?.labelColor || TUNE_TYPES.other.labelColor,
                            toolTipContent && {
                                id: `${dataType} - ${id} - ${objectKey}`,
                                content: toolTipContent,
                            },
                        ]);
                    }
                });
            if (listTunes.length > 0) {
                tunesCell.cellContent = "labelsTruncate";
                tunesCell.cellAdjust = "labels";
                tunesCell.value = listTunes;
            }
        }
    }

    const numOfGroups = () => {
        if (groups.length === 1) {
            return `1  ${t("group")}`;
        }
        return `${groups.length} ${t("groups")}`;
    };

    const numOfGrids = () => {
        if (grids.length === 1) {
            return `1  ${t("grid")}`;
        }
        return `${grids.length} ${t("grids").toLowerCase()}`;
    };

    const getDeleteWaring = () => {
        if (groups.length > 0 && grids.length > 0) {
            return t("used-in-x-and-y", { x: numOfGroups(), y: numOfGrids() });
        }
        if (groups.length > 0) {
            return t("used-in", { x: numOfGroups() });
        }
    };

    return {
        id: id,
        dataType: dataType,
        executeSectionQuery: true,
        name: name,
        groups: groups,
        grids: grids,
        deleteWarning: groups.length > 0 ? getDeleteWaring() : null,
        actions: [
            {
                type: "channel",
                value: id,
            },
        ],
        info: [
            {
                cellContent: "image",
                isFromUrl: true,
                urlPath: imgSrc,
                path: "icons/",
                type: "svg",
                name: "image",
                linkText: name,
                noImgText: true,
                specialWidth: "w-3/12 ",
                adjust: "w-18 h-12 object-cover rounded mr-4 bg-gray-200 border border-gray-300",
                notImgFontSize: "0.65rem",
                adjustNotImageIcon: "icon icon-channel text-gray-400 text-xl",
                iconError: "icon-channel text-gray-400 text-xl",
                adjustNotImage:
                    "border border-gray-300 flex items-center justify-center bg-gray-200 w-18 h-12 rounded mr-4",
                adjustText: " text-zafiro-600 cursor-pointer",
                value: name,
                redirectTo: `/services/tv-channels/channel/${id}`,
            },
            folder !== CORPORATE_CHANNEL.value && {
                name: "type",
                value: type,
                cellContent: "text",
                specialWidth: "w-2/12",
            },
            { ...tunesCell },
            {
                name: "groups",
                value: groups ? groups.length : 0,
                cellContent: "text",
                specialWidth: folder !== CORPORATE_CHANNEL.value ? "w-2/12" : "w-3/12",
                textTooltip: tooltipGroupsInfo(),
                noWidthFullCell: true,
            },
            {
                name: "grid",
                value: grids ? grids.length : 0,
                cellContent: "text",
                specialWidth: "w-2/12",
                textTooltip: tooltipGridsInfo(),
                noWidthFullCell: true,
            },
        ],
    };
};

const arrangeTuneInfo = (props) => {
    const { tuneInfoJson } = props;
    let response = {};
    if (tuneInfoJson) {
        if (tuneInfoJson[TUNE_TYPES.udp.value.toLowerCase()]) {
            response[TUNE_TYPES.udp.value.toLowerCase()] = tuneInfoJson[TUNE_TYPES.udp.value.toLowerCase()];
        } else if (tuneInfoJson[TUNE_TYPES.hls.value.toLowerCase()]) {
            response[TUNE_TYPES.hls.value.toLowerCase()] = tuneInfoJson[TUNE_TYPES.hls.value.toLowerCase()];
        } else {
            response[TUNE_TYPES.hls.value.toLowerCase()] = tuneInfoJson[TUNE_TYPES.hls.value.toLowerCase()];
        }
    }
    return response;
};

export default Channel;
