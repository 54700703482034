import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChromePicker } from "react-color";

import { remToPixel, isDescendant } from "hooks/Utils/Utils";
import { getRGBA, ensureRGBA } from "hooks/Utils/DesignUtils";

import { setExecuteQuery } from "actions/sectionActions";
import { setColorToApplyInTextEditor, setColorPickerReference } from "actions/designActions";

import Widget from "components/Section/Design/Widgets/Widget";

const CustomColorPicker = ({ deviceType, deviceSpecific }) => {
    const dispatch = useDispatch();

    const [color, setColor] = useState(getRGBA("rgba(123,123,123,1)"));
    const [presetColors, setPresetColors] = useState([]);

    const { colorPickerReference, itemSelected, gridItems, cScreenData, widgetToolbarVisible } = useSelector(
        (state) => state.design
    );
    const widget = gridItems && itemSelected ? gridItems.filter((w) => w && w.i === itemSelected)[0] : null;

    useEffect(() => {
        const picker = document.querySelector(`#pickerContainer`);
        if (!picker) {
            return;
        }

        if (colorPickerReference) {
            setColor(
                colorPickerReference.color ? getRGBA(ensureRGBA(colorPickerReference.color)) : "rgba(255,255,255,1)"
            );
            const refDivPos = document.getElementById(`${colorPickerReference.id}`).getClientRects()[0];

            if (colorPickerReference.id.includes("modal")) {
                picker.style.zIndex = "500";
            }
            if (refDivPos) {
                const percentagePositionHeight = Math.floor((refDivPos.y / window.innerHeight) * 100);
                const percentagePositionWidth = Math.floor((refDivPos.x / window.innerWidth) * 100);

                if (percentagePositionHeight > 65 && percentagePositionHeight <= 69) {
                    picker.style.top = `${refDivPos.top - refDivPos.height * 2}px`;
                } else if (percentagePositionHeight >= 70 && percentagePositionHeight <= 75) {
                    picker.style.top = `${refDivPos.top - refDivPos.height * 3}px`;
                } else if (percentagePositionHeight >= 76 && percentagePositionHeight <= 80) {
                    picker.style.top = `${refDivPos.top - refDivPos.height * 4}px`;
                } else if (percentagePositionHeight >= 81 && percentagePositionHeight <= 100) {
                    picker.style.top = `${refDivPos.top - refDivPos.height * 5}px`;
                } else {
                    picker.style.top = `${refDivPos.top}px`;
                }

                if (percentagePositionWidth >= 83) {
                    picker.style.left = `${refDivPos.x - refDivPos.width * 8.5}px`;
                } else {
                    picker.style.left = `${refDivPos.x + refDivPos.width + remToPixel(1)}px`;
                }
                picker.classList.remove("hidden");
            }
        } else {
            picker.classList.add("hidden");
        }
    }, [colorPickerReference]);

    const saveColor = (color) => {
        if (colorPickerReference?.action) {
            window[colorPickerReference.action]({
                ...colorPickerReference,
                color: `rgba(${color.r},${color.g},${color.b},${color.a})`,
            });
            return;
        }
        if (itemSelected === null) {
            //Appearances color picker
            // let _params = [];
            // if (executeQuery && executeQuery.params && executeQuery.params.length > 0) {
            //     _params = [...executeQuery.params];
            // }
            // const findItem = (element) => element.property === colorPickerReference.property;
            // const indexProperty = _params.findIndex(findItem);

            // if (indexProperty > -1) {
            //     _params[indexProperty].value = `rgba(${color.r},${color.g},${color.b},${color.a})`;
            // } else {
            //     _params.push({
            //         property: colorPickerReference.property,
            //         value: `rgba(${color.r},${color.g},${color.b},${color.a})`,
            //     });set
            // }

            dispatch(
                setExecuteQuery({
                    action: "update-appearance-colors",
                    params: {
                        property: colorPickerReference.property,
                        value: `rgba(${color.r},${color.g},${color.b},${color.a})`,
                    },
                })
            );
        } else if (colorPickerReference && colorPickerReference.id === "textEditor") {
            dispatch(setColorToApplyInTextEditor(`rgba(${color.r},${color.g},${color.b},${color.a})`));
        } else if (itemSelected === "bg") {
            const contentStyle = JSON.parse(JSON.stringify(cScreenData.contentStyle));
            contentStyle.bgColor = `rgba(${color.r},${color.g},${color.b},${color.a})`;

            dispatch(
                setExecuteQuery({
                    action: "updateContentStyle",
                    params: {
                        contentStyle: contentStyle,
                    },
                })
            );
        } else if (colorPickerReference) {
            dispatch(
                setExecuteQuery(
                    Widget.UpdateStyle({
                        widget,
                        property: colorPickerReference.property,
                        value: `rgba(${color.r},${color.g},${color.b},${color.a})`,
                        deviceSpecific: deviceSpecific ? deviceType : null,
                    })
                )
            );
        }
    };

    const colorSelected = (color) => {
        setColor(color.rgb);
        saveColor(color.rgb);
        setPresetColors([...presetColors, { color: color.rgb }]);
    };

    const handleClickOutside = (e) => {
        const picker_popup = document.querySelector("#pickerContainer");
        if (
            !isDescendant(picker_popup, e.target) &&
            !e.target.id.includes("picker") &&
            !e.target.id.includes("bgColor_square") &&
            !e.target.id.includes("borderColor_square") &&
            !e.target.id.includes("textEditor") &&
            !e.target.id.includes("screenBgColor") &&
            widgetToolbarVisible !== "config-navigation-items" &&
            widgetToolbarVisible !== "navigation"
        ) {
            dispatch(setColorPickerReference(null));
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <div id="pickerContainer" onClick={(e) => e.stopPropagation()} className="z-201 fixed hidden">
            <ChromePicker color={color} onChangeComplete={colorSelected} presetColors={presetColors} />
        </div>
    );
};

export default CustomColorPicker;
