import { forbiddenWords } from "hooks/Utils/ForbiddenWords";
import { isAValidHTTPS } from "hooks/Utils/Services/ChannelsUtils";

export const MOBILE_INFORMATION_CARD = "mobile-information-card";
export const MOBILE_CHAT_FEATURE = "mobile-chat-feature";
export const MOBILE_LINKS_AND_SOCIAL_NETWORKS = "mobile-links-and-social-networks";

export const initialStateValidationsInformationCard = { name: true, url: true, urlAlias: true, domain: true };

export const validateMobileInformationCard = (dataInformation) => {
    const validations = { ...initialStateValidationsInformationCard };
    let error = false;

    const validateField = (fieldValue, fieldName) => {
        if (!fieldValue?.trim()) {
            validations[fieldName] = false;
            error = true;
        } else {
            const containsForbiddenWord = forbiddenWords.some((forbiddenWord) =>
                fieldValue.toLowerCase().includes(forbiddenWord.toLowerCase())
            );
            if (containsForbiddenWord) {
                validations[fieldName] = false;
                error = true;
            }
        }
    };

    const validateUrlAlias = (urlAlias) => {
        if (urlAlias?.trim() !== "" && !isAValidHTTPS(urlAlias)) {
            validations.urlAlias = false;
            error = true;
        }
    };

    validateField(dataInformation?.name, "name");
    validateUrlAlias(dataInformation?.urlAlias);
    validateField(dataInformation?.domain, "domain");

    return { validations, error };
};

export const getFilterSocialMedia = (data) => {
    let filteredData = data
        .filter((item) => item.id && item.url)
        .map(({ id, url }) => {
            if (id === "link") {
                id = "web";
            } else if (id === "tik-tok") {
                id = "tiktok"; // Transforma "tik-tok" en "tiktok"
            }
            return { id, url };
        });

    return (
        "[" +
        filteredData
            .map((item) => {
                return (
                    "{\n" +
                    Object.entries(item)
                        .map(([key, value]) => {
                            let formattedValue;
                            if (key === "id") {
                                formattedValue = value;
                            } else {
                                formattedValue = `"${value.replace(/"/g, '\\"')}"`;
                            }
                            return `    ${key}: ${formattedValue}`;
                        })
                        .join(",\n") +
                    "\n}"
                );
            })
            .join(",\n") +
        "]"
    );
};

export const validateMobileSocialMediaCard = (dataSelected = []) => {
    const validations = {};

    let error = false;

    console.log(dataSelected);

    dataSelected.forEach((d, index) => {
        if ((d.id && !d.url) || (d.id && !isAValidHTTPS(d.url))) {
            validations[index] = true;
            error = true;
        }
    });

    return { validations, error };
};

export const ACTIONS_MOBILE_SETTINGS = {
    UPDATE_SETTINGS_DELETE_MOBILE_APP: "update-settings-delete-mobile-app",
    UPDATE_SETTINGS_INFORMATION_CARD: "update-settings-information-card",
    UPDATE_SETTINGS_CHAT_FEATURE: "update-settings-chat-feature",
    UPDATE_SETTINGS_SOCIAL_MEDIA: "update-settings-social-media",
    UPDATE_SETTINGS_PROPERTY_INFORMATION: "update-settings-property-information",
};

export const getMobileAppQuery = (filter = "") => {
    return `query{
        mobileApps${filter}{
            info{
            count
            page
            size
          }
          results{
            id
            name
            shortName
            isMultiProperty
            url
            config {
                iconAssetRef
                socialNetworks{
                    id
                    url
                }
                urlAlias
            }
            projects{
                id
                chainID
                name
                ref
                information{
                    appAvailable
                    chatNumber
                    chatNumberLada
                    chatPublic
                    chatURL
                    picture
                }
                brands{
                    chainID
                    id
                    name
                    projects{
                        chainID
                        id
                        name
                        ref
                    }
                    ref
                }
            }
          }
        }
      }`;
};

export const getMobileAppDesign = () => {
    return `query{
        mobileAppThemes{
            chainId
            hasMobileApps
            mobileApps{
                mobileAppId
            }
            type
            themeType
            themeId:id
            name
            projectId
          }
      }`;
};
