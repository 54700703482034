import { useEffect } from "react";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";

const PropertyConfig = (id) => {
    const GET_PROJECT_CONFIG = gql`
        query Config($id: Int64!) {
            properties(id: $id) {
                results {
                    information {
                        chatURL
                        chatPublic
                    }
                }
            }
        }
    `;
    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_PROJECT_CONFIG, {
        fetchPolicy: "network-only",
    });

    const results = data && data.properties ? data.properties.results : null;
    const config = results && results.length > 0 ? results[0].information : null;

    useEffect(() => {
        executeQuery({
            variables: { id: id },
        });
    }, [executeQuery, id]);

    return { config, loading, error, refetch };
};

export default PropertyConfig;
