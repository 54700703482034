export const Customers = [
    "all",
    "available",
    "country",
    "customers",
    "email",
    "items",
    "lang",
    "last-access",
    "list",
    "name",
    "next",
    "number",
    "of",
    "previous",
    "search",
    "signupDate",
    "surname",
    "theres-not",
    "title-treatment",
    "title-birthDate",
    "title-phone",
    "showing-all-results",
    "hotel-guests",
    "stay-date",
    "group",
    "room-number",
    "room-number-short",
    "add",
    "guest",
    "guests",
    "view-guest",
    "change-room",
    "assign-to-group",
    "checkout",
    "guest-delete",
    "erase",
    "cancel",
    "guest-confirm-deletion",
    "guest-confirm-checkout",
    "guest-enter_data",
    "add-guest",
    "mobile-phone",
    "arrival",
    "departure",
    "select-group",
    "select-room",
    "group-name",
    "number-of-guests",
    "newo",
    "delete",
    "guest-group-delete",
    "new-group-of-guests",
    "give-group-name",
    "accept",
    "select-group-guest",
    "guests-added",
    "guest-information",
    "stay-information",
    "comments",
    "pms-id",
    "title",
    "age",
    "guest-group",
    "phone",
    "number-of-rooms",
    "room",
    "rooms",
    "write-comment",
    "no-batch",
    "batch-title",
    "apply",
    "room-code",
    "room-parental-code",
    "companions",
    "bookings",
    "companion-delete",
    "people-number",
    "status",
    "area-sym-activity",
    "date-and-time",
    "state-bookings-0",
    "state-bookings-1",
    "state-bookings-2",
    "state-bookings-3",
    "state-bookings-2,3",
    "add-companion",
    "view-all-guest-bookings",
    "birthdate",
    "choose-new-room",
    "view-room",
    "generate-roomcode",
    "edit-parental-code",
    "select-rooms-to-checkout",
    "confirm-full-checkout",
    "full-checkout",
    "same-room-error",
    "guests-to-add-to-group",
    "guests-to-checkout",
    "digital-key",
    "digital-keys",
    "room-no",
    "asociate-email-required",
    "digital-key-text-access",
    "room-access",
    "type",
    "accesses",
    "accesses-text",
    "companion",
    "main",
    "no-digital-key",
    "loyal-guest",
    "logout-devices",
    "logout-devices-confirm",
    "logout-all",
    "logout",
    "new-companion",
    "companion-added",
    "access-to-room",
    "loyal-customer",
    "guest-is-loyal",
    "chromecast-change-pairing",
    "chromecast-change-pairing-text",
    "chromecast-change-pairing-text-extended",
    "access-profile",
    "export-all-customer-list",
    "advanced-filter",
    "signUp",
    "accesses-text",
    "communications",
    "verification",
    "personal-details",
    "sent-commercial-communication",
    "deactivate-commercial-communications",
    "access-list-record",
    "gdpr",
    "customer-pending-gdpr",
    "accepted",
    "pending",
    "not-accepted",
    "verified-user",
    "unverified",
    "deactivate-commercial-communications-confirm",
    "activate-commercial-communications",
    "activate-commercial-communications-confirm",
    "resend-pre-check-in",
];
