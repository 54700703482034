export const NewBooking = (t, data) => {
    return [
        {
            title: "add-booking",
            text: "complete-the-following-information",
            executeSectionQuery: true,
            bodyAdjust: "w-1/12",
            inputs: [
                {
                    type: "newBooking",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "add",
                    style: "blue",
                    action: "add-booking",
                },
            ],
        },
    ];
};
