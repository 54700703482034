import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import { withApollo } from "@apollo/react-hoc";

//ACTIONS
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { setModalContent, closeModal } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";

//UTILS
import { toast } from "react-toastify";
import { removeApolloCacheKeys } from "../../Utils/Utils";
import { useTranslation } from "react-i18next";
import { executeVendureQuery, useVendureAPI } from "../../Utils/Integrations/useVendure";
import { ORDER_ACTIONS } from "../../Utils/Services/OrdersUtils";
import _ from "lodash";

const UpdateOrders = ({ client }) => {
    const { t } = useTranslation();
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);
    const dispatch = useDispatch();

    const { adminApi } = useVendureAPI();

    useEffect(() => {
        let execute = false;
        let mutation = null;
        let executeAnotherQuery = null;
        let aditionalHeader = null;
        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.mutationName = "";
            execute = true;
            switch (executeQuery.action) {
                case ORDER_ACTIONS.cancel.action.name:
                    mutation = `
                        mutation{
                            cancelOrder(input:{
                                orderId:${actionData.itemsAffected[0]}
                            }){
                                __typename
                            }
                        }
                    `;
                    executeQuery.mutationName = "cancelOrder";
                    aditionalHeader = { "vendure-token": actionData?.values?.shopToken };
                    break;
                case ORDER_ACTIONS.accept.action.name:
                    mutation = "mutation {";
                    if (executeQuery?.params?.orderData?.payments) {
                        Object.keys(executeQuery.params.orderData.payments).map((index) => {
                            let payment = executeQuery.params.orderData.payments[index];
                            mutation += `
                                settlePayment${index}: settlePayment(id:${payment?.id}){
                                    __typename
                                    ...on Payment{
                                        id
                                    }
                                }
                            `;
                        });
                    }
                    mutation += "}";
                    executeQuery.mutationName = "settlePayment";
                    aditionalHeader = { "vendure-token": executeQuery?.params?.shopToken };
                    break;
                case "refresh-order-after-fulfillment":
                    const orderId = executeQuery?.params?.orderData?.id;
                    if (orderId) {
                        mutation = `query{order(id:${orderId}){fulfillments{id}}}`;
                        executeQuery.mutationName = "order";
                        aditionalHeader = { "vendure-token": executeQuery?.params?.shopToken };
                        executeAnotherQuery = {
                            action: "set-ready-after-fulfillment",
                            useResponsePrevQueryData: {
                                name: "order",
                                path: "order",
                            },
                            extraParams: executeQuery.params,
                        };
                    }
                    break;
                case "set-ready-after-fulfillment":
                    mutation = transitionFulfillmentToState(executeQuery?.params?.order);
                    executeQuery.mutationName = "transitionFulfillmentToState";
                    aditionalHeader = { "vendure-token": executeQuery?.params?.shopToken };
                    break;
                case ORDER_ACTIONS.ready.action.name:
                    if (executeQuery?.params?.orderData?.fulfillments?.length < 1) {
                        mutation = "mutation {";
                        if (
                            executeQuery?.params?.orderData?.lines &&
                            executeQuery?.params?.orderData?.lines.length > 0
                        ) {
                            let lines = "";
                            executeQuery.params.orderData.lines.forEach((line) => {
                                lines += `
                                { orderLineId: "${line.id}", quantity: ${line.quantity} }
                            `;
                            });
                            mutation += `
                            addFulfillmentToOrder(input: {
                                lines: [${lines}]
                                handler: {
                                    code: "room-fulfillment"
                                    arguments: []
                                }
                            }) {
                                __typename
                                ... on InvalidFulfillmentHandlerError {
                                    errorCode
                                    message
                                }
                                ... on ItemsAlreadyFulfilledError {
                                    errorCode
                                    message
                                }
                            }
                        `;
                        }
                        mutation += "}";
                        executeQuery.mutationName = "addFulfillmentToOrder";
                        aditionalHeader = { "vendure-token": executeQuery?.params?.shopToken };
                        executeAnotherQuery = {
                            action: "refresh-order-after-fulfillment",
                            extraParams: executeQuery.params,
                        };
                    } else {
                        mutation = transitionFulfillmentToState(executeQuery?.params?.orderData);
                        executeQuery.mutationName = "transitionFulfillmentToState";
                        aditionalHeader = { "vendure-token": executeQuery?.params?.shopToken };
                    }
                    break;
                case ORDER_ACTIONS.delivered.action.name:
                    mutation = "mutation {";
                    if (executeQuery?.params?.orderData?.fulfillments) {
                        Object.keys(executeQuery.params.orderData.fulfillments).map((index) => {
                            let fulfillment = executeQuery.params.orderData.fulfillments[index];
                            mutation += `
                                transitionFulfillmentToState${index}: transitionFulfillmentToState(id:${fulfillment?.id}, state: "Delivered"){
                                    __typename
                                    ...on Fulfillment{
                                        id
                                    }
                                }
                            `;
                        });
                    }
                    mutation += "}";
                    executeQuery.mutationName = "transitionFulfillmentToState";
                    aditionalHeader = { "vendure-token": executeQuery?.params?.shopToken };
                    break;
                default:
                    mutation = null;
                    execute = false;
                    break;
            }
        }
        if (mutation && execute) {
            dispatch(setLoading(true));
            setTimeout(function () {
                executeMutation(mutation, executeAnotherQuery, aditionalHeader);
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    const executeMutation = async (mutation, executeAnotherQuery, aditionalHeader = null) => {
        if (executeQuery.closeModal) {
            dispatch(setModalContent(false));
            dispatch(closeModal());
            dispatch(setRefreshContentData(false));
        }
        let errorMutation = false;
        const response = await executeVendureQuery(adminApi, { queryBody: mutation }, aditionalHeader);
        if (response && response.data && !response.data.errors) {
            if (executeQuery.mutationName) {
                let mutationObject = response.data[executeQuery.mutationName];
                if (mutationObject && mutationObject.result && mutationObject.result.toUpperCase().includes("NOT")) {
                    errorMutation = true;
                }
            }
        } else {
            errorMutation = true;
        }
        if (!errorMutation) {
            if (executeAnotherQuery) {
                let params = executeAnotherQuery.extraParams ? executeAnotherQuery.extraParams : {};
                if (executeAnotherQuery.useResponsePrevQueryData) {
                    let param = executeAnotherQuery.useResponsePrevQueryData;
                    params[param.name] = _.get(response.data, param.path);
                }
                dispatch(
                    setExecuteQuery({
                        action: executeAnotherQuery.action,
                        params: params,
                    })
                );
            } else {
                toast.success(t("operation-successful"));
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
                dispatch(setExecuteQuery(null));
                dispatch(cleanAction());
                dispatch(setLoading(false));
                if (executeQuery.cacheKeyToDelete) {
                    removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
                }
            }
        } else {
            dispatch(setExecuteQuery(null));
            dispatch(setLoading(false));
            toast.error(t("mutation-error"));
        }
    };

    return null;
};

function transitionFulfillmentToState(data) {
    if (data?.fulfillments?.length > 0) {
        let mutation = "mutation {";
        data.fulfillments.forEach((fulfillment, index) => {
            mutation += `
                transitionFulfillmentToState${index}: transitionFulfillmentToState(id:${fulfillment?.id}, state: "Shipped"){
                    __typename
                    ...on Fulfillment{
                        id
                    }
                }
            `;
        });
        mutation += "}";
        return mutation;
    }
    return null;
}

export default withApollo(UpdateOrders);
