import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { setActionInput } from "../../actions/globalActions";

const UseInputPhone = ({ inputData, validation, currentValue }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);

    //Data
    const { name } = inputData;

    //Actions
    const dispatch = useDispatch();

    const blurHandler = (e) => {
        const dataToAction = { [e.target.name]: e.target.value };
        dispatch(setActionInput(dataToAction));
    };

    //Listeners
    useEffect(() => {
        if (currentValue) {
            const dataToAction = { [name]: currentValue };
            dispatch(setActionInput(dataToAction));
        }
        // eslint-disable-next-line
    }, []);

    return (
        <input
            type="phone"
            name={name}
            className={`t-filter-input cursor-auto p-2 ${!validation ? "border border-red-100 " : ""}${
                inputData.name === "phone" ? " w-full" : "long"
            }`}
            placeholder={langStrings["phone"]}
            onChange={blurHandler}
            defaultValue={currentValue ? currentValue : ""}
        ></input>
    );
};

export default UseInputPhone;
