import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "components/TextInput";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { Session } from "hooks/Utils/Session";
import { setExecuteQuery } from "actions/sectionActions";
import { DELETE_MOBILE_APP } from "../constants";
import { toast } from "react-toastify";

const ModalDeleteApp = (props) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState("");
    const [valid, setValid] = useState(true);

    const { permissions } = useSelector((state) => state.ui);
    const nativeApps = permissions?.hotel?.nativeApps || permissions?.allProperties?.nativeApps;
    const lang = Session.getLang();
    const dispatch = useDispatch();

    const textValueToCheck = lang === "en" ? "delete" : "borrar";

    const handleDeleteClick = () => {
        if (inputValue.trim() === textValueToCheck.toLocaleUpperCase()) {
            setValid(true);
            dispatch(
                setExecuteQuery({ action: props?.action || DELETE_MOBILE_APP, params: { data: { id: props?.id } } })
            );
            props.close();
        } else {
            setValid(false);
            toast.error(t("input error"));
        }
    };

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={`${t("delete-mobile-app-title-modal", { appName: props?.name || "" })}`}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-delete" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="red" id="modal-button-delete" onClick={handleDeleteClick}>
                        {capitalizeFirst(t("delete"))}
                    </Button>
                </>
            }
            className={"w-4/12 p-10"}
        >
            <div>
                <div className="">{t("delete-mobile-app-description-modal")}</div>
                <div className=" mb-6">{t("delete-mobile-app-instructions-modal")}</div>

                <TextInput
                    className={`${!valid ? " border border-red-500" : " border border-transparent"}`}
                    placeholder={`${t("write-delete")}...`}
                    onChange={(value) => {
                        setInputValue(value);
                    }}
                />

                <div className={`flex items-start mt-2 ${nativeApps ? "opacity-1" : "opacity-0"}`}>
                    <Icon type="warning" style={{ fontSize: "1.7rem", marginRight: "1rem" }} />
                    <p>{t("delete-native-app-message")}</p>
                </div>
            </div>
        </Modal>
    );
};

const useDeleteModalApp = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalDeleteApp {...newProps} />);
        },
    };
};

export default useDeleteModalApp;
