import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useParams, useNavigate as useHistory } from "react-router-dom";

//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

//Components
import UseSectionHeader from "../../useSectionHeader";
import UseButton from "../../../components/useButton";
import UseTranslationTextArea from "../../useTranslationTextArea";

//Actions
import { cleanAction } from "../../../actions/globalActions";
import { showGlobalLoading } from "../../../actions/uiActions";
import { setSectionContent } from "../../../actions/sectionActions";

//Utils
import GetAreaDescription from "../../../hooks/GraphqlCalls/CommonZones/GetAreaDescription";
import { toast } from "react-toastify";
import Parser from "hooks/Utils/Parser";
import { useTranslation } from "react-i18next";
import { escapeSpecialChars } from "../../../hooks/Utils/Utils";

const AreaClose = () => {
    const history = useHistory();
    const { t } = useTranslation();

    //Params
    const { id } = useParams();

    //Store data
    const sectionData = useSelector((state) => state.sectionContent.data);
    const { sectionName } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);
    const [translationArray, setTranslationArray] = useState();
    //Actions
    const dispatch = useDispatch();

    const MUTATION_OBJECT = gql`mutation{updateCommonZone(id:${id} currentStatus:false currentComment:[${translationArray}]){error ok}}`;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            console.log(err);
            dispatch(showGlobalLoading(false));
            toast.error(t("mutation-error"));
        },
    });

    //listeners
    useEffect(() => {
        dispatch(showGlobalLoading(true));
        return () => {
            dispatch(cleanAction());
            dispatch(
                setSectionContent({
                    data: null,
                })
            );
        };
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        if (data && data.updateCommonZone && data.updateCommonZone.ok) {
            dispatch(showGlobalLoading(false));
            toast.success(t("operation-successful"));
            history("/common-zones/zones-configuration/areas");
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        let translations = "";

        Object.entries(actionData.values).map((item) =>
            item[0] !== "undefined"
                ? (translations += `{lang:"${item[0]}" text:"${escapeSpecialChars(item[1])}"}`)
                : null
        );
        setTranslationArray(translations);
    }, [actionData.values]);

    //Functions
    const saveDescription = () => {
        dispatch(showGlobalLoading(true));
        executeMutation();

        return null;
    };
    const goBack = () => {
        history(-1);
    };

    GetAreaDescription({ id: id, close: true });

    return (
        <>
            <UseSectionHeader title={sectionName} navToPreviousPage={true} />
            {sectionData && sectionData.area ? (
                <>
                    <div>
                        <div className="pb-8 first-capital flex">
                            <div className="text-2xl mt-1 mr-4 float-left">
                                <span className="icon icon-warning1 -ml-1em text-white"></span>
                                <span className="icon icon-warning2 -ml-1em  text-white"></span>
                                <span className="icon icon-warning text-orange-100"></span>
                            </div>
                            <div className="float-left ml-4">
                                {Parser(t("close-warning", { area: `<strong>${sectionData.area.name}</strong>` }))}
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-8">
                        <div className="pb-8 first-capital">{t("close-comment")}</div>
                        <UseTranslationTextArea textId={sectionData.area.commentTextId} parentType="COMMONZONES" />

                        <div className="mt-6 pt-6 border-gray-200 flex  justify-end">
                            <div id={"area-close-go-back"} onClick={() => goBack()}>
                                <UseButton
                                    id={"cancel-button"}
                                    buttonName={"cancel"}
                                    buttonColor={"btn-white"}
                                    adjust="w-24 min-w-0 mr-2 float-left "
                                />
                            </div>
                            <div id={"area-close-save-description"} onClick={() => saveDescription()}>
                                <UseButton buttonName={"save"} buttonColor={"btn-blue"} adjust="w-24 min-w-0" />
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default AreaClose;
