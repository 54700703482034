import { capitalizeFirst } from "hooks/Utils/Utils";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PreCheckInContext } from "./ReservationDetailsContext";
import { BOOKING_INFORMATION, GUESTS, PRE_CHECKIN_HISTORY } from "./utils";

const TabReservationDetails = () => {
    const { t } = useTranslation();

    const { tabSelected, setTabSelected, inEdition } = useContext(PreCheckInContext);

    const tabs = [BOOKING_INFORMATION, GUESTS, PRE_CHECKIN_HISTORY];

    const tabStyles = (tabSelected, tab) => {
        if (tabSelected === tab) {
            return " text-zafiro-600 border-zafiro-600 cursor-pointer";
        } else if (inEdition && tabSelected !== tab) {
            return "text-gray-500 border-gray-200 cursor-not-allowed ";
        } else {
            return "text-gray-800 border-gray-300 cursor-pointer";
        }
    };

    return (
        <div>
            {tabs.map((tab) => {
                return (
                    <button
                        id={`reservation-details-tab-${tab}`}
                        style={{ minWidth: "10rem" }}
                        className={`  ${tabStyles(tabSelected, tab)} text-base px-2 py-2 border-b-2 font-bold `}
                        onClick={() => {
                            if (!inEdition) {
                                setTabSelected(tab);
                            }
                        }}
                    >
                        {capitalizeFirst(t(tab))}
                    </button>
                );
            })}
        </div>
    );
};

export default TabReservationDetails;
