import TextInput from "components/TextInput";
import { AccessTypesContext } from "contexts/AccessTypes";
import { TopologyContext } from "contexts/Topology";
import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

export const TopologyTextInputs = ({ type, value, section }) => {
    const { setName, nameValidation, setPmsNumber } = useContext(TopologyContext);
    const { setName: setNameAccesType, nameValidation: accessTypeNameValidation } = useContext(AccessTypesContext);
    const { t } = useTranslation();

    useEffect(() => {
        if (value) {
            if (section === "topology") {
                if (type === "name") {
                    setName(value);
                } else if (type === "pms-number") {
                    setPmsNumber(value);
                }
            } else if (section === "access-types") {
                setNameAccesType(value);
            }
        }
    }, [value]);

    return (
        <TextInput
            placeholder={type === "name" ? t("name") : t("pms-number")}
            className={
                (type === "name" && section === "topology" && nameValidation) ||
                (section === "access-types" && accessTypeNameValidation)
            }
            value={value}
            onChange={(value) => {
                if (section === "topology") {
                    if (type === "name") {
                        setName(value);
                    } else if (type === "pms-number") {
                        setPmsNumber(value);
                    }
                } else if (section === "access-types") {
                    setNameAccesType(value);
                }
            }}
        />
    );
};
