import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SalesProvider from "contexts/Sales";
import ShopProvider from "contexts/Sales/Shop";

import ShopHeadMenu from "components/Section/Services/Sales/ShopHeadMenu";
import ErrorInfo from "components/ErrorInfo";

import { setRefreshData } from "actions/tableActions";

import ListLabels from "../../../../hooks/GraphqlCalls/Sales/ListLabels";

const Labels = () => {
    const { id } = useParams();

    const salesRef = useRef(null);
    const contextRef = useRef(null);
    const refresh = () => {
        if (contextRef?.current) {
            contextRef.current.refresh();
        }
    };

    const dispatch = useDispatch();
    const refreshData = useSelector((state) => state?.sectionContent?.refreshData);

    const [error, setError] = useState(null);

    useEffect(() => {
        if (refreshData) {
            dispatch(setRefreshData(false));
            refresh();
        }
    }, [refreshData]);

    return (
        <SalesProvider ref={salesRef}>
            <ShopProvider
                ref={contextRef}
                id={id}
                onError={(error) => {
                    setError(error);
                }}
            >
                <ShopHeadMenu />
                {error ? <ErrorInfo retry={refresh}>{error}</ErrorInfo> : <ListLabels />}
            </ShopProvider>
        </SalesProvider>
    );
};
export default Labels;
