import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createBrowserHistory } from "history";
//API
import { useParams } from "react-router-dom";

//Components
import UseSectionHeader from "../../../useSectionHeader";
import UseButton from "../../../useButton";
import RoomFields from "./RoomFields";

//Utils
import UpdateTopology from "../../../../hooks/GraphqlCalls/Hotel/Settings/UpdateTopology";
import { validateSection } from "../../../../hooks/Utils/Validations";
import { toast } from "react-toastify";

//Actions
import { setExecuteQuery } from "../../../../actions/sectionActions";
import { showGlobalLoading } from "../../../../actions/uiActions";
import { setActionValidations, cleanActionValidations } from "../../../../actions/globalActions";
import { useTranslation } from "react-i18next";

const AddRoom = () => {
    const { t } = useTranslation();
    //Actions
    const dispatch = useDispatch();
    const history = createBrowserHistory();
    const sectionName = `add-room`;

    //Store

    const { data } = useSelector((state) => state.sectionContent);
    const { values } = useSelector((state) => state.action);

    //Params
    const { id } = useParams();
    if (!data) {
        window.location.href = `#/hotel/property-settings/topology`;
    }

    let wingName = "";
    let wingData = "";
    let wingLastFloorPos = "";
    let floorName = "";
    let floorId = "";
    let floorRooms = null;
    let initialRoomNumber = null;
    let wingPrefix = null;

    if (data) {
        data.map((wing) =>
            wing.floors.map((floor) =>
                parseInt(floor.id) === parseInt(id)
                    ? ((floorName = floor.name),
                      (floorId = floor.id),
                      (floorRooms = floor.rooms),
                      (wingName = wing.name),
                      (wingData = wing))
                    : null
            )
        );
        wingLastFloorPos = wingData.floors.length > 0 ? wingData.floors[wingData.floors.length - 1].order : 0;

        wingPrefix = data.length > 1 ? wingData.pos : null;
    }
    if (floorRooms) {
        floorRooms.sort(function (a, b) {
            return a.number < b.number ? 1 : a.number > b.number ? -1 : 0;
        });
    }

    if (floorRooms && floorRooms[0]) {
        initialRoomNumber = parseInt(floorRooms[0].number) + 1;
    }

    //Functions
    const navToPrevious = () => {
        history.back();
    };

    const addRoom = () => {
        //Validate fields
        const validations = validateSection("topology-add-room", values, t);

        dispatch(setActionValidations(validations));
        const validationsArray = Object.values(validations);

        if (validationsArray.filter((item) => item === false).length > 0) {
            toast.error(t("errors-in-red"));
            return;
        }
        dispatch(showGlobalLoading(true));
        dispatch(
            setExecuteQuery({
                action: "topology-add-room",
                params: { floorId: floorId },
            })
        );
    };

    //Listeners
    useEffect(() => {
        return () => {
            dispatch(cleanActionValidations());
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <UpdateTopology />
            <UseSectionHeader title={["add-room", "(", wingName, ",", floorName, ")"]} navToPreviousPage={true} />

            <div className="bg-white w-full p-8 ">
                <RoomFields
                    fieldsToShow={{
                        numberOfRooms: true,
                        starterRoom: true,
                        tvsPerRoom: true,
                        location: true,
                    }}
                    initialData={{
                        parentSectionName: sectionName,
                        starterRoom: initialRoomNumber
                            ? initialRoomNumber
                            : parseInt(`${wingPrefix}${wingLastFloorPos}01`),
                    }}
                />
                <div className="inline-flex w-full justify-end mt-2">
                    <UseButton
                        action={(e) => navToPrevious()}
                        buttonName={"cancel"}
                        buttonColor="btn-white"
                        adjust="float-left mr-6 "
                        id={`${sectionName}-cancel`}
                    />
                    <UseButton
                        action={(e) => addRoom()}
                        buttonName={"save"}
                        buttonColor="btn-blue"
                        adjust="float-left "
                        id={`${sectionName}-save`}
                    />
                </div>
            </div>
        </>
    );
};

export default AddRoom;
