import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Icon from "components/Icon";
import Modal, { useModal } from "components/Modal";
import MoviesPMSModal from "./MoviesPMSModal";
import SettingsCard from "components/SettingsCard";
import useProjectConfig from "hooks/Data/useProjectConfig";

const MoviesPMSGeneralSettings = () => {
    const { t } = useTranslation();
    const { open, close } = useModal();

    const { load: loadProjectConfig, data: projectConfigData } = useProjectConfig({
        concept: "pms_charges_settings",
    });

    const [pmsConfig, setPmsConfig] = useState({
        enabled: false,
        id: "",
        description: "",
    });

    useEffect(() => {
        loadProjectConfig();
    }, [loadProjectConfig]);

    useEffect(() => {
        if (Array.isArray(projectConfigData) && projectConfigData.length > 0) {
            const pmsChargesConfig = projectConfigData[0];

            if (pmsChargesConfig?.value) {
                const parsedConfig = JSON.parse(pmsChargesConfig.value);

                setPmsConfig({
                    enabled: parsedConfig?.enableCharges || false,
                    id: parsedConfig?.chargeId || "",
                    description: parsedConfig?.chargeDescription || "",
                });
            }
        }
    }, [projectConfigData]);

    const modalRef = useRef(null);

    const titleModal = <h2 className="text-3xl font-bold px-6 py-2">{`${t("PMS charges")} - ${t("edit")}`}</h2>;

    const handleSuccess = () => {
        close();
    };

    const handleSavePMSConfig = (newConfig) => {
        if (!newConfig.id || !newConfig.description) {
            return;
        }
        setPmsConfig(newConfig);
    };

    return (
        <div className="items-center justify-start flex flex-wrap h-full">
            <div className="p-6 w-full bg-white rounded shadow-sm flex flex-col h-full">
                <div className="flex justify-between items-center mb-5">
                    <div className="flex items-center">
                        <div className="text-3xl font-bold ml-2">{t("PMS charges")}</div>
                    </div>
                    <div className="flex items-center space-x-5 text-gray-800">
                        <Button
                            id="edit-button-taxes-movies"
                            className="cursor-pointer"
                            onClick={() =>
                                open(
                                    <Modal
                                        title={titleModal}
                                        id="modal-body-taxes-movies"
                                        className="w-4/12 p-6"
                                        style={{
                                            width: "560px",
                                            height: "376px",
                                            position: "absolute",
                                            top: "256px",
                                            left: "440px",
                                        }}
                                    >
                                        <MoviesPMSModal
                                            ref={modalRef}
                                            id="modal-pms"
                                            values={pmsConfig}
                                            onSuccess={handleSuccess}
                                            onSave={handleSavePMSConfig}
                                        />
                                    </Modal>
                                )
                            }
                        >
                            <Icon type="edit" size="xl" />
                        </Button>
                    </div>
                </div>

                <div className="text-base mb-5 ml-2">{t("pms-charges-movies")}</div>

                <div className={`text-base mt-6 ml-2 ${pmsConfig.enabled ? "text-black" : "text-gray-500"}`}>
                    {pmsConfig.enabled ? (
                        <SettingsCard.ParamsList
                            data={[
                                {
                                    id: "pms-charges-id",
                                    name: t("PMS charge ID"),
                                    description: pmsConfig.id,
                                },
                                {
                                    id: "pms-charges-desc",
                                    name: t("PMS charge description"),
                                    description: pmsConfig.description,
                                },
                            ]}
                        />
                    ) : (
                        <div>{t("PMS charges disabled")}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MoviesPMSGeneralSettings;
