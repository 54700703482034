import React from "react";
import { MdnsInfo } from "./MdnsInfo";
import { Traffic } from "./Traffic";
import { ChromeCastStatus } from "./ChromeCastStatus";
import { NetworkInfo } from "./NetworkInfo";
import { DhcpInfo } from "./DhcpInfo";

export const NetworkComponent = ({ type, mdnsInfo, called, loading, networkInfo, dhcpInfo }) => {
    switch (type) {
        case "MdnsInfo":
            return <MdnsInfo mdnsInfo={mdnsInfo} called={called} loading={loading} />;
        case "Traffic":
            return <Traffic mdnsInfo={mdnsInfo} called={called} loading={loading} networkInfo={networkInfo} />;
        case "ChromeCastStatus":
            return <ChromeCastStatus mdnsInfo={mdnsInfo} called={called} loading={loading} />;
        case "NetworkInfo":
            return <NetworkInfo networkInfo={networkInfo} called={called} loading={loading} />;
        case "DhcpInfo":
            return <DhcpInfo dhcpInfo={dhcpInfo} called={called} loading={loading} />;
        default:
            return null;
    }
};
