import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
//Actions
import { showRowForm, setExecuteQuery, setHiddenRows } from "../../actions/sectionActions";
import { cleanAction, setActionValidations } from "../../actions/globalActions";
//Utils
import { toast } from "react-toastify";
import UseEditableField from "../useEditableField";
import { validateSection } from "../../hooks/Utils/Validations";
import { Session } from "../../hooks/Utils/Session";
import { useTranslation } from "react-i18next";

const useFormTopologyRoom = () => {
    const { t } = useTranslation();
    const { rowFormContent, results } = useSelector((state) => state.table);
    const isSuperUser = Session.isSuperUser();

    const { values, actionName, itemsAffected } = useSelector((state) => state.action);

    //get functions
    const setValue = (name) => {
        let initVal = "";
        if (actionName === "edit-topology-room") {
            results.filter((result) =>
                result.id === itemsAffected
                    ? result.info.map((cellData) => (cellData.name === name ? (initVal = cellData.value) : null))
                    : null
            );
        }

        return initVal;
    };

    const setSelectedIds = (name) => {
        let ids = [];
        if (actionName === "edit-topology-room") {
            if (name === "location") {
                let row = results.filter((result) => result.id === itemsAffected);
                if (row) {
                    row = row[0];
                    if (row.roomTVs && row.roomTVs.length > 0) {
                        // eslint-disable-next-line
                        row.roomTVs.map((roomTV) => {
                            ids.push({
                                id: roomTV.id,
                                name: roomTV.name,
                                cec: roomTV.cec,
                            });
                        });
                    }
                }
            }
        }

        return ids;
    };

    //State
    const [floorOptions, setFloorOptions] = useState([]);
    const [numberTvsPerRoom, setNumberTvsPerRoom] = useState(setValue("tvs"));

    //CONSTANT
    const EDITING = actionName === "edit-topology-room";

    //Variables
    let locationsSelected = setSelectedIds("location");

    //Actions
    const dispatch = useDispatch();

    const cancelAction = (e) => {
        dispatch(showRowForm(false));
        dispatch(cleanAction());
        dispatch(setHiddenRows(true));
    };

    const saveAction = (e) => {
        //Validate fields
        const validations = validateSection(EDITING ? "topology-edit-room" : "topology-table-add-room", values, t);
        dispatch(setActionValidations(validations));
        const validationsArray = Object.values(validations);

        if (validationsArray.filter((item) => item === false).length > 0) {
            toast.error(t("errors-in-red"));
            return;
        }
        if (EDITING) {
            let roomTvs = [];
            results.filter((result) =>
                result.id === itemsAffected
                    ? result.info.map((cellData) =>
                          cellData.name === "location"
                              ? cellData.value.map((location) => roomTvs.push({ id: location[0], name: location[1] }))
                              : null
                      )
                    : null
            );
            dispatch(
                setExecuteQuery({
                    action: "topology-edit-room",
                    params: {
                        roomId: itemsAffected,
                        roomtvs: roomTvs,
                        noGoBack: true,
                    },
                })
            );
        } else {
            dispatch(
                setExecuteQuery({
                    action: "topology-table-add-room",
                    params: {
                        noGoBack: true,
                    },
                })
            );
        }
        dispatch(showRowForm(false));
        dispatch(setHiddenRows(true));
    };

    const prepareOptions = (name) => {
        let options = [];
        if (rowFormContent && rowFormContent.data) {
            switch (name) {
                case "wing":
                    rowFormContent.data.rawFloors.results.map(
                        (wing) =>
                            wing !== "" &&
                            wing.floors &&
                            wing.floors.length > 0 &&
                            options.push({ id: wing.id, name: String(wing.name) })
                    );
                    break;
                case "floor":
                    rowFormContent.data.floors.map(
                        (floor) => floor !== "" && options.push({ id: floor[1], name: String(floor[0]) })
                    );
                    break;
                case "location":
                    rowFormContent.data.locations.results.map(
                        (location) => location !== "" && options.push({ id: location, name: String(location) })
                    );
                    break;

                default:
                    break;
            }
        }
        return options;
    };

    useEffect(() => {
        if (values["wing"] && values["wing"][0]) {
            let fOptions = [];
            rowFormContent.data.rawFloors.results.map((wing) =>
                wing.id === parseInt(values["wing"][0])
                    ? wing.floors.map((floor) =>
                          fOptions.push({
                              id: floor.id,
                              name: String(floor.name),
                          })
                      )
                    : null
            );
            //Guarrada que a día de hoy no sé como solucionar, sino se recargar todos los componentes
            //select with search
            Session.setSessionProp("updateSelectOptions", "floor");
            setFloorOptions(fOptions);
        }
        // eslint-disable-next-line
    }, [values["wing"]]);

    const renderLocations = () => {
        const locationItems = [];
        if (numberTvsPerRoom && numberTvsPerRoom > 0) {
            for (let i = 1; i <= numberTvsPerRoom; i++) {
                let locationData = locationsSelected && locationsSelected.length >= i ? locationsSelected[i - 1] : null;
                locationItems.push(
                    <div className="w-full flex justify-start items-center my-2">
                        <div className="w-36 pr-2">
                            <UseEditableField
                                data={{
                                    label: `location_${i}`,
                                    labelOnEdition: `location`,
                                    labelAdjust: "hidden",
                                    required: true,
                                    placeholder: ["select", "location"],
                                    inEditionMode: true,
                                    type: "select-with-search",
                                    markSelected: false,
                                    oneSelected: true,
                                    optionData: prepareOptions("location"),
                                    selectWithAddInput: true,
                                    selectedIds: locationData ? [locationData.name] : null,
                                    editOptions: true,
                                }}
                            ></UseEditableField>
                        </div>
                        <div className={`w-auto flex items-center ${!isSuperUser ? "hidden" : ""}`}>
                            <span
                                className="icon icon-info text-xl mr-2 lg:text-2xl"
                                data-tip={`${t("{{capitalize, capitalize}}", {
                                    capitalize: t("select-tv-locations-and-cec"),
                                })} \n ${t("{{capitalize, capitalize}}", {
                                    capitalize: t("cec-enabled-description"),
                                })}`}
                                data-for="default-tooltip"
                            ></span>
                            <span className="inline-block text-gray-800 pr-2">{t("cast-inst")}</span>
                            <UseEditableField
                                data={{
                                    label: `location-cec_${i}`,
                                    noLabel: true,
                                    type: "switch",
                                    inEditionMode: true,
                                    value: locationData ? locationData.cec : true,
                                    markSelected: locationData ? locationData.cec : true,
                                }}
                            ></UseEditableField>
                        </div>
                    </div>
                );
            }
        }
        return (
            <div className="w-full block">
                <div className="w-full">
                    <UseEditableField
                        data={{
                            label: "locations",
                            noLabel: true,
                            inEditionMode: true,
                            value: true,
                            type: "text",
                            inputAdjust: "hidden",
                        }}
                    ></UseEditableField>
                    {locationItems}
                </div>
            </div>
        );
    };

    return (
        <>
            {rowFormContent ? (
                <div className="border-b border-gray-200">
                    <div className="w-full bg-white  h-auto flex items-center " style={{ minHeight: "4rem" }}>
                        <UseEditableField
                            data={{
                                label: "wing",
                                placeholder: "wing",
                                inEditionMode: !EDITING,
                                type: "select-with-search",
                                optionData: prepareOptions("wing"),
                                oneSelected: true,
                                styleAdjust: `pr-2 w-1.5/12 ${EDITING ? "pl-4" : ""}`,
                                value: EDITING ? setValue("wing") : null,
                                selectedIds: setSelectedIds("wing"),
                                noLabel: true,
                            }}
                        />
                        <UseEditableField
                            data={{
                                label: "floor",
                                placeholder: "floor",
                                inEditionMode: !EDITING,
                                type: "select-with-search",
                                optionData: floorOptions,
                                oneSelected: true,
                                value: EDITING ? setValue("floor") : null,
                                styleAdjust: `pr-2 w-1.5/12 ${EDITING ? "pl-4" : ""}`,
                                selectedIds: setSelectedIds("floor"),
                                noLabel: true,
                                disabled:
                                    !EDITING && (!values["wing"] || (values["wing"] && values["wing"].length === 0)),
                            }}
                        />
                        <UseEditableField
                            data={{
                                label: "room-number",
                                placeholder: "room-number-short",
                                inEditionMode: true,
                                type: "number",
                                styleAdjust: `pr-2 w-1/12`,
                                value: setValue("room-number"),
                                noLabel: true,
                                numberMin: 1,
                            }}
                        />
                        <UseEditableField
                            data={{
                                label: "pms-number",
                                placeholder: "pms-number-short",
                                inEditionMode: true,
                                type: "text",
                                styleAdjust: `pr-2 w-1/12`,
                                value: setValue("pms-number"),
                                noLabel: true,
                            }}
                        />
                        <UseEditableField
                            data={{
                                label: "room-name",
                                placeholder: "name",
                                inEditionMode: true,
                                type: "text",
                                styleAdjust: `pr-2 w-2/12`,
                                value: setValue("name"),
                                noLabel: true,
                            }}
                        />
                        <UseEditableField
                            data={{
                                label: "tvs-per-room",
                                placeholder: "tvs",
                                inEditionMode: true,
                                type: "number",
                                styleAdjust: `pr-2 w-1/12`,
                                value: setValue("tvs"),
                                noLabel: true,
                                numberMin: 0,
                                onChangeAction: (e) => {
                                    setNumberTvsPerRoom(e.target.value);
                                },
                            }}
                        />
                        <div className="pr-2 w-3/12">{renderLocations()}</div>{" "}
                        <div className="w-1/12 text-right inline-block whitespace-no-wrap pl-2  r-0 pr-2">
                            <img
                                alt="delete btn"
                                className="float-left mr-4 cursor-pointer"
                                onClick={(e) => cancelAction(e)}
                                src={require(`../../assets/images/icons/delete-red.svg`)}
                            ></img>
                            <img
                                alt="save btn"
                                className="cursor-pointer"
                                onClick={(e) => saveAction(e)}
                                src={require(`../../assets/images/icons/save-green.svg`)}
                            ></img>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default useFormTopologyRoom;
