import React from "react";
import UseIconMultipleIconCell from "./useIconMultipleIconCell";

const UseIconGuestCell = ({ cellData }) => {
    const { hidden, link, values } = cellData || {};
    const others = [];
    if (values.length > 1) {
        values.forEach((accomp) => {
            others.push(accomp.name);
        });
        others.shift();
    }
    if (values.length < 1) {
        return null;
    }
    return (
        <>
            {!hidden ? (
                <div className="flex justify-start">
                    {others.length > 0 ? (
                        <>
                            <UseIconMultipleIconCell
                                link={link}
                                item={values[0]}
                                classNames={"icon-guest text-xl mr-2 inline"}
                            />
                            <UseIconMultipleIconCell
                                link={link}
                                item={values[1]}
                                others={others}
                                classNames={"icon-guest text-xl mr-2 inline"}
                            />
                        </>
                    ) : (
                        <UseIconMultipleIconCell
                            link={link}
                            item={values[0]}
                            classNames={"icon-guest text-xl mr-2 inline"}
                        />
                    )}
                </div>
            ) : null}
        </>
    );
};

export default UseIconGuestCell;
