import React, { useState, useEffect } from "react";
import UseButton from "../useButton";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
//Actions
import { setWizardStep, showAddPeriodicSchedule } from "../../actions/sectionActions";
import {
    cleanBatchSchedules,
    cleanViewSchedules,
    resetScheduleSpecialRows,
    resetDateSpecialRows,
    setMutationRefresh,
} from "../../actions/scheduleActions";
import { showGlobalLoading } from "../../actions/uiActions";
import { cleanAction } from "../../actions/globalActions";
//Utils
import { toast } from "react-toastify";
import { createBrowserHistory } from "history";
import { useTranslation } from "react-i18next";

const WizardNav = () => {
    const { t } = useTranslation();
    //Store data
    const { currentStep, stepsData, allowWizardNext } = useSelector((state) => state.sectionContent);
    const { periodicSchedulesBatch } = useSelector((state) => state.schedules);
    const { mutationRefresh } = useSelector((state) => state.schedules);
    const history = createBrowserHistory();

    //States
    const [mutation, setMutation] = useState(`mutation {createTimetable(areaID: 0){ error id ok}}`);
    const [netxStepState, setNetxStepState] = useState("");
    //API
    const ADD_PERIODIC_SCHEDULES = gql`
        ${mutation}
    `;
    const [executeMutation, { data }] = useMutation(ADD_PERIODIC_SCHEDULES);

    //Actions
    const dispatch = useDispatch();

    const changeStepHandler = () => {
        if (netxStepState === "") {
            dispatch(setWizardStep(currentStep + 1));
            dispatch(cleanAction());
        } else {
            toast.error(t("save or cancel schedules"));
        }
    };
    const refresh = () => {
        dispatch(showGlobalLoading(true));
        history.go(0);
    };

    const saveAllHandler = () => {
        if (periodicSchedulesBatch && periodicSchedulesBatch.length === 0) {
            exitUsavedHandler();
            return;
        }
        executeMutation();
    };

    const exitUsavedHandler = () => {
        dispatch(setWizardStep(null));
        dispatch(cleanAction());
        dispatch(cleanBatchSchedules());
        dispatch(cleanViewSchedules());
        dispatch(showAddPeriodicSchedule(false));
        dispatch(resetScheduleSpecialRows());
        dispatch(resetDateSpecialRows());
        toast.success(t("exit-wo-save"));
    };

    //Listeners
    useEffect(() => {
        if (periodicSchedulesBatch.length > 0 || mutationRefresh) {
            setMutation(getMutation(periodicSchedulesBatch));
            dispatch(setMutationRefresh(false));
        }
        // eslint-disable-next-line
    }, [periodicSchedulesBatch, mutationRefresh]);

    useEffect(() => {
        if (isCreatedOk(data)) {
            toast.success(t("schedules created"));
            setTimeout(refresh, 2000);
        } else if (data && !isCreatedOk(data)) {
            toast.error(t("mutation-error"));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (allowWizardNext) {
            setNetxStepState("");
        } else {
            setNetxStepState("cursor-not-allowed bg-gray-500 hover:bg-gray-500");
        }
    }, [allowWizardNext]);

    return (
        <div className="py-8 flex justify-end w-full">
            {stepsData
                ? stepsData[currentStep - 1].buttons.map((button, index) => (
                      <div key={index}>
                          {button.name.includes("continue") ? (
                              <div onClick={changeStepHandler} data-id={button.name} className="mr-4">
                                  <UseButton
                                      buttonName={button.name}
                                      buttonColor={button.color}
                                      adjust={`px-4 w-124px ${netxStepState}`}
                                  />
                              </div>
                          ) : null}

                          {button.name === "finish-save" ? (
                              <div onClick={saveAllHandler} data-id={button.name} className="mr-4">
                                  <UseButton
                                      buttonName={button.name}
                                      buttonColor={button.color}
                                      adjust={`${netxStepState}`}
                                  />
                              </div>
                          ) : null}
                          {button.name === "exit-unsaved" ? (
                              <div onClick={exitUsavedHandler} data-id={button.name} className="mr-4">
                                  <UseButton buttonName={button.name} buttonColor={button.color} />
                              </div>
                          ) : null}
                      </div>
                  ))
                : null}
        </div>
    );
};

export const getMutation = (periodicSchedulesBatch) => {
    //Returns dynamic mutation strig
    let res = `mutation {
    createTimetable(
      timetables: [`;
    // eslint-disable-next-line
    periodicSchedulesBatch.map((periodicSchedule, index) => {
        const { areaID, dateType, isEnabled, name, periodicityType, type, timetableDates } = periodicSchedule;
        if (index !== 0) {
            res += `,`;
        }
        res += `{
          areaID: ${areaID}
          dateType: "${dateType}"
          isEnabled: ${isEnabled}
          name: "${name}"
          periodicityType: "${periodicityType}"
          type: "${type}"
          timetableDates: ${getFormattedTimetableDates(timetableDates)}
    }`;
    });

    res += `]
      ){ error IDs ok}}`;
    return res;
};

export const getFormattedTimetableDates = (timetableDates) => {
    //Returns TimetableDates as mutation compatible string
    let res = `[`;
    // eslint-disable-next-line
    timetableDates.map((timetableDate, index) => {
        if (index !== 0) {
            res += `,`;
        }
        res += `{
      dateList: `;
        if (timetableDate.dateList === "") {
            res += `"" `;
        } else {
            res += `"${timetableDate.dateList}" `;
        }
        res += `timetableDateRanges: [`;
        // eslint-disable-next-line
        timetableDate.timetableDateRanges.map((timetableDateRange, index) => {
            if (index !== 0) {
                res += `,`;
            }
            res += `{
        timeRanges: `;
            if (timetableDateRange.timeRanges === "") {
                res += `"" `;
            } else {
                res += `"${timetableDateRange.timeRanges}" `;
            }
            res += `
        weekdayList: `;
            if (timetableDateRange.weekdayList === "" || timetableDateRange.weekdayList === "[]") {
                res += `"" `;
            } else {
                res += `"${timetableDateRange.weekdayList}" `;
            }
            res += `}`;
        });
        res += `]}`;
    });

    res += `]`;
    return res;
};

export const isCreatedOk = (data) => {
    let res;
    if (data && data.createTimetable && data.createTimetable.ok) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export default WizardNav;
