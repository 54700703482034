const ContractedProduct = (product, langStrings) => {
    const { code, isPremium, name } = product;

    const dataType = "contracted-product";

    return {
        dataType: dataType,
        adjust: "h-auto",
        info: [
            {
                cellContent: "icon-with-text",
                value: name,
                image: {
                    url: `/assets/images/products/${code}.png`,
                },
                specialWidth: "w-3/12",
            },
            {
                cellContent: "text",
                value: isPremium ? langStrings["premium"] : null,
                adjust: "first-capital",
                specialWidth: "w-2/12",
            } /*,
            {
                name: "room-number",
                cellContent: "text",
                value: `55`,
                specialWidth: "w-3/12",
            },
            {
                name: "pms-number",
                cellContent: "text",
                value: `666`,
                specialWidth: "w-2/12",
            },*/,
        ],
    };
};

export default ContractedProduct;
