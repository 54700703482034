import React from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
import NetworkGrid from "../../NetworkGrid";
import { useWifiConfig } from "hooks/GraphqlCalls/Hotel/useWifiConfig";
import { NetworkSettingsProvider } from "contexts/NetworkSettings";
import Loading from "components/Loading";

const WifiSettingsWithContext = () => {
    const { portalsWS, portalsWifi, ready } = useWifiConfig();
    return (
        <>
            <UseSectionHeader title={["wifi-settings"]} />
            <div style={{ height: "75vh" }}>
                {ready ? (
                    <NetworkGrid
                        gridStyles="grid grid-rows-1 grid-flow-col gap-4"
                        networks={[...portalsWS, ...portalsWifi]}
                        type="wifi"
                    />
                ) : (
                    <Loading adjust="section-loading" />
                )}
            </div>
        </>
    );
};
const WifiSettings = () => {
    return (
        <NetworkSettingsProvider>
            <WifiSettingsWithContext />
        </NetworkSettingsProvider>
    );
};

export default WifiSettings;
