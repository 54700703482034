import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import DayPicker, { DateUtils } from "react-day-picker";
import UseCalendarNavbar from "./useCalendarNavbar";
//Actions
import { addSpecialSpecificDays } from "../../actions/scheduleActions";
//Utils
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/es";

const UseCalendarSpecialSpecific = ({ index }) => {
    //Store data
    const { specificDays } = useSelector((state) => state.schedules.specialRowDateContainer[index]);

    //States
    const [selectedDays, setSelectedDays] = useState([]);

    //Actions
    const dispatch = useDispatch();

    const handleDayClick = (day, { selected }) => {
        const daysSelected = selectedDays;
        if (selected) {
            const selectedIndex = daysSelected.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay, day));
            daysSelected.splice(selectedIndex, 1);
        } else {
            daysSelected.push(day.toString());
        }
        setSelectedDays(daysSelected);
        dispatch(
            addSpecialSpecificDays({
                valueKey: "specificDays",
                value: daysSelected,
                index: index,
            })
        );
    };

    // Convert Strings to dates
    const currentDates = () => {
        let tempArray = [];
        if (specificDays) {
            // eslint-disable-next-line
            specificDays.map((specificDate) => {
                tempArray.push(new window.ZDate(Date.parse(specificDate)));
            });
        }
        return tempArray;
    };

    const datosToDisplay = currentDates();

    return (
        <div>
            {/*ISO 8601 standard Monday(1) Sunday (7)*/}
            <DayPicker
                firstDayOfWeek={1}
                navbarElement={<UseCalendarNavbar />}
                selectedDays={datosToDisplay}
                onDayClick={handleDayClick}
                localeUtils={MomentLocaleUtils}
                locale="es"
            />
        </div>
    );
};

export default UseCalendarSpecialSpecific;
