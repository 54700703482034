import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseCalendarRangeEditSchedule from "./useCalendarRangeEditSchedule";
import UseCalendarSpecificEditSchedule from "./useCalendarSpecificEditSchedule";
//Actions
import { setMutationRefresh } from "../../actions/scheduleActions";
//Utils
import useComponentVisible from "../useComponentVisible";
import { getDateType } from "../../hooks/Utils/ScheduleDisplayTools";
import CleanUnnecessaryChars from "../../hooks/Utils/CleanUnnecessaryChars";

const UseInputDateEditSchedule = ({ validation, index }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { scheduleToUpdate } = useSelector((state) => state.schedules);
    const [selectActive, setSelectActive] = useState({});
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //states
    const [datesConverted, setDatesConverted] = useState(false);
    const [dateTypeConverted, setDateTypeConverted] = useState(false);
    const [datesError, setDatesError] = useState(validation);

    //Actions
    const dispatch = useDispatch();

    //Listeners
    useEffect(() => {
        setDatesError(validation);
    }, [validation]);

    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({});
        } else {
            setSelectActive({
                text: "text-blue-300",
            });
        }
    }, [isComponentVisible]);

    useEffect(() => {
        if (scheduleToUpdate && scheduleToUpdate.dateType) {
            setDateTypeConverted(getDateType(scheduleToUpdate.dateType));
        }
        if (scheduleToUpdate && scheduleToUpdate.timetableDates && scheduleToUpdate.timetableDates[index]) {
            setDatesConverted(getDatesConverted(scheduleToUpdate.timetableDates[index], scheduleToUpdate.dateType));
        }
        // eslint-disable-next-line
    }, [scheduleToUpdate]);

    useEffect(() => {
        if (datesConverted && datesConverted.length > 0) {
            //Force mutation refresh
            setDatesError(true);
            dispatch(setMutationRefresh(true));
        } else {
            setDatesError(false);
        }
        // eslint-disable-next-line
    }, [datesConverted]);

    return (
        <div ref={ref} className="relative">
            {dateTypeConverted === "date-range" ? (
                <>
                    <div
                        className={`w-full h-10 bg-gray-200 rounded relative p-2 ${selectActive.text} ${getErrorStyle(
                            datesError
                        )}`}
                        onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                    >
                        {datesConverted && datesConverted.length > 0
                            ? `${datesConverted[0].toLocaleDateString()} - ${datesConverted[1].toLocaleDateString()}`
                            : langStrings["range"]}
                    </div>
                    {!datesError ? (
                        <p className="absolute text-red-100">{langStrings["activity-dates-error"]}</p>
                    ) : null}
                    {isComponentVisible ? (
                        <div className="absolute z-200">
                            <UseCalendarRangeEditSchedule
                                datesConverted={datesConverted}
                                index={index}
                                setDatesConverted={setDatesConverted}
                            />
                        </div>
                    ) : null}
                </>
            ) : (
                <>
                    <div
                        className={`w-48 h-10 bg-gray-200 rounded relative p-2 ${selectActive.text} ${getErrorStyle(
                            datesError
                        )}`}
                        onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                    >
                        {datesConverted && datesConverted.length > 0
                            ? `${datesConverted.length} ${langStrings["dates-selected"]}`
                            : langStrings["days"]}
                    </div>
                    {!datesError ? (
                        <p className="absolute text-red-100">{langStrings["schedule-range-error"]}</p>
                    ) : null}
                    {isComponentVisible ? (
                        <div className="absolute z-200">
                            <UseCalendarSpecificEditSchedule
                                datesConverted={datesConverted}
                                index={index}
                                setDatesConverted={setDatesConverted}
                            />
                        </div>
                    ) : null}
                </>
            )}
        </div>
    );
};

const getErrorStyle = (error) => {
    let res = "";
    if (!error) {
        res = "border border-red-100";
    }
    return res;
};

const getDatesConverted = (timetableDate, dateType) => {
    let res = [];
    let cleandateStrings = [];
    const cleanDate = CleanUnnecessaryChars(timetableDate.dateList);
    //Else is empty row and returns []
    if (cleanDate && cleanDate !== "") {
        if (dateType === "DATE_RANGE") {
            cleandateStrings = cleanDate.split("-");
        } else {
            const separatedItems = cleanDate.split(",");
            // eslint-disable-next-line
            separatedItems.map((separatedItem) => {
                const separatedDuplicates = separatedItem.split("-");
                cleandateStrings.push(separatedDuplicates[0]);
            });
        }
        // eslint-disable-next-line
        cleandateStrings.map((cleandateString) => {
            res.push(new window.ZDate(cleandateString));
        });
    }

    return res;
};

export default UseInputDateEditSchedule;
