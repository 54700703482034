import React from "react";
import { useSelector } from "react-redux";
//Utils
import { capitalizeFirst } from "../hooks/Utils/Utils";
import { useNavigate as useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UseTitle = ({
    text,
    subTitle,
    adjust,
    forModal,
    navToPreviousPage,
    navToSection,
    actionOnBack,
    noCapitalize,
    icon = null,
    noTranslate,
    noParenthesisSubtitle = false,
    adjustSubtitle = "",
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    //Store data
    const { title } = useSelector((state) => state.table);
    const { modalContent } = useSelector((state) => state.ui);

    //Function
    const getTextFromArray = (text) => {
        let ret = "";
        if (Array.isArray(text)) {
            const arrayRes = text.map((term) => `${noTranslate ? term : t(term)} `);
            arrayRes.forEach((element) => {
                ret = ret + element;
            });
        } else {
            ret = noTranslate ? text : t(text);
        }
        return ret;
    };

    const navToPrevious = () => {
        if (navToSection) {
            history(navToSection);
        } else if (actionOnBack) {
            actionOnBack();
        } else history(-1);
    };
    return (
        <div className="flex">
            {navToPreviousPage || navToSection || actionOnBack ? (
                <div
                    id="nav-to-previous-section-button"
                    onClick={navToPrevious}
                    className="icon-chevron-left float-left mt-2 mr-2 cursor-pointer text-gray-600 text-xl"
                />
            ) : null}

            <h1 id="section-title" className={`section-title  ${!noCapitalize ? "first-capital" : ""} ${adjust}`}>
                {icon && <i className={`icon-${icon} mr-5`}></i>}
                {forModal && modalContent.value && ` ${modalContent.value} - `}
                <span className={`${!noCapitalize ? "first-capital" : ""}`}>
                    {!noCapitalize ? capitalizeFirst(getTextFromArray(text)) : getTextFromArray(text)}
                    {title && !forModal ? ` ${capitalizeFirst(title)}` : null}
                </span>
                {subTitle ? (
                    <span className={`text-gray-600 ml-4 ${adjustSubtitle}`}>
                        {!noParenthesisSubtitle ? `( ${t(subTitle)} )` : `${t(subTitle)}`}
                    </span>
                ) : null}
            </h1>
        </div>
    );
};

export default UseTitle;
