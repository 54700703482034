import React from "react";
import { useSelector } from "react-redux";
import sanitizeHtml from "sanitize-html";

const UseTagWithTooltipCell = ({ cellData }) => {
    const { langStrings } = useSelector((state) => state.ui);
    const { adjust, tipValue, label, tooltipData } = cellData || {};
    // label Tag {
    // text: text show on tag translatable
    // textColor: color for text in tag tailwing-classname
    // bgColo: colo for background of tag tailwing-classname
    // }
    // tipValue: usually number

    return (
        <div className={`text-left ${adjust} first-capital`}>
            {label && label.text ? (
                <div className={`rounded py-1 ${label.textColor} ${label.bgColor} inline-block first-capital`}>
                    {langStrings[label.text] ? langStrings[label.text] : label.text}
                </div>
            ) : null}
            {tipValue && tipValue > 0 ? (
                <>
                    <div
                        className={`text-left text-blue-300 inline ml-1 cursor-default`}
                        data-tip={sanitizeHtml(tooltipData)}
                        data-for="default-tooltip"
                    >
                        {`+${tipValue}`}
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default UseTagWithTooltipCell;
