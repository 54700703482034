import React, { useState } from "react";

import useSelect from "./useSelect";
import UseSearch from "../components/useSearch";
import { useTranslation } from "react-i18next";

import { Tooltip } from "react-tippy";

const SelectFilter = (props) => {
    const { t } = useTranslation();
    const config = {
        placeHolder: "Select a value",
        search: false,
        ux: {
            handler: {
                countSelected: true,
                tooltip: true,
            },
            body: {
                countSelected: true,
                listSelected: true,
            },
        },
        getItems: () => {
            return [];
        },
        ...props,
    };
    const Select = useSelect(config);
    const [search, setSearch] = useState("");

    const hasItems = () => {
        const items = config.getItems();
        let count = 0;
        // eslint-disable-next-line
        items.map((i) => {
            if (i.items && i.items.length > 0) {
                count += i.items.length;
            }
        });
        return count > 0;
    };

    const selectedTooltip = () => {
        let groups = [];
        // eslint-disable-next-line
        config.getItems().map((group) => {
            if (group.items && group.items.length > 0) {
                let sel_items = [];
                // eslint-disable-next-line
                group.items.map((option) => {
                    if (Select.isSelected(option)) {
                        sel_items.push(option);
                    }
                });
                if (sel_items.length > 0) {
                    groups.push({ ...group, items: sel_items });
                }
            }
        });
        return (
            <div className="text-left">
                {groups.map((group, gindex) => (
                    <>
                        {group.name ? <div className="px-3 py-1 font-black">{group.name}</div> : null}
                        {group.items
                            ? group.items.map((option, index) => (
                                  <div key={gindex + "-" + index} className="px-3">
                                      {option.name}
                                  </div>
                              ))
                            : null}
                    </>
                ))}
            </div>
        );
    };

    const Handler = (props) => {
        const icon = Select.value ? Select.value.icon : null;
        const iconColor = Select.value ? Select.value.iconColor : null;
        return (
            <Select.Handler>
                <div
                    className="flex bg-white py-2 px-2 border border-gray-200 rounded-md justify-between items-center leading-tight whitespace-no-wrap"
                    {...props}
                >
                    {typeof props.children !== "undefined" ? props.children : null}
                    <div>
                        {Select.value && !config.multiple ? (
                            <>
                                <div className="font-bold">
                                    {icon ? (
                                        <i
                                            className={
                                                "mr-2 icon icon-" + icon + (iconColor ? " text-" + iconColor : "")
                                            }
                                        ></i>
                                    ) : null}
                                    {Select.value.name}
                                </div>
                            </>
                        ) : Select.value && config.multiple && Select.value.length > 0 ? (
                            config.ux.handler.countSelected ? (
                                config.ux.handler.tooltip ? (
                                    <Tooltip theme="light" html={selectedTooltip()} position="bottom">
                                        <span className={"rounded py-1 px-2 text-white bg-zafiro-400 text-sm"}>
                                            {t("x items selected", { count: Select.value.length })}
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <span className={"rounded py-1 px-2 text-white bg-zafiro-400 text-sm"}>
                                        {t("x items selected", { count: Select.value.length })}
                                    </span>
                                )
                            ) : (
                                selectedTooltip()
                            )
                        ) : (
                            <div className="text-gray-600 px-2">{config.placeHolder}</div>
                        )}
                    </div>

                    {hasItems() ? (
                        <div className={config.disabled ? "text-gray-400" : "text-gray-900 text-xs pl-2 pr-2"}>
                            <i className={Select.isOpen ? "icon-chevron-up" : "icon-chevron"}></i>
                        </div>
                    ) : null}
                </div>
            </Select.Handler>
        );
    };

    const clickOption = (value) => {
        if (config.multiple) {
            if (Select.isSelected(value)) {
                Select.removeValue(value);
            } else {
                Select.addValue(value);
            }
        } else {
            Select.setValue(value);
            Select.close();
        }
    };

    const filterItem = (option, index) => {
        const selected = Select.isSelected(option);
        const icon = option ? option.icon : null;
        const iconColor = option ? option.iconColor : null;
        return (
            <div className="dropdown-item py-2 px-4" key={index} onClick={() => clickOption(option)}>
                {config.multiple ? (
                    <>
                        <span
                            style={{
                                padding: ".1rem",
                                borderRadius: ".2rem",
                            }}
                            className={
                                "icon align-middle icon-tick-right cursor-pointer inline-block mr-2 " +
                                (selected ? "bg-blue-300 text-white" : "bg-gray-300 text-gray-300")
                            }
                        ></span>
                        <span className={"inline-block align-middle text-gray-900"}>
                            {icon ? (
                                <i className={"mr-2 icon icon-" + icon + (iconColor ? " text-" + iconColor : "")}></i>
                            ) : null}
                            {option.name}
                        </span>
                    </>
                ) : (
                    <span className={"inline-block align-middle " + (selected ? "text-blue-300" : "text-gray-900")}>
                        {icon ? (
                            <i className={"mr-2 icon icon-" + icon + (iconColor ? " text-" + iconColor : "")}></i>
                        ) : null}
                        {option.name}
                    </span>
                )}
            </div>
        );
    };

    const selectedItem = (option, index) => {
        const fg = "white";
        const bg = option.color || "zafiro-500";
        const icon = option ? option.icon : null;
        const iconColor = option ? option.iconColor : null;
        return (
            <div className="inline-block align-middle p-1" key={index}>
                <span className={"rounded py-1 pl-4 pr-2 text-" + fg + " bg-" + bg + " flex flex-auto justify-between"}>
                    <span className="flex-star text-sm">
                        {icon ? (
                            <i className={"mr-2 icon icon-" + icon + (iconColor ? " text-" + iconColor : "")}></i>
                        ) : null}
                        {option.name}
                    </span>
                    <span
                        className={"flex-end text-" + fg + " text-xs cursor-pointer pl-2"}
                        style={{ paddingTop: "0.1rem" }}
                        onClick={() => clickOption(option)}
                    >
                        <i className="icon-remove inline-block align-middle"></i>
                    </span>
                </span>
            </div>
        );
    };

    const Body = (props) => {
        const [search, setSearch] = useState(props.search || "");
        return hasItems() ? (
            <Select.Body
                style={props.style}
                className={"dropdown-body absolute max-h-80 overflow-y-scroll " + (props.className || "")}
            >
                {props.children}
                {config.search ? (
                    <div className="py-2 px-4">
                        <UseSearch key={"search"} value={search} onChange={setSearch} onEnter={props.setSearch} />
                    </div>
                ) : null}
                {(config.ux.body.selected || config.ux.body.countSelected) &&
                Select.value &&
                Select.value.length > 0 ? (
                    <>
                        {config.multiple && config.ux.body.countSelected ? (
                            <div className="dropdown-item py-2 px-4">
                                {t("x items selected", {
                                    count: Select.value.length,
                                })}
                            </div>
                        ) : null}
                        {config.multiple && config.ux.body.listSelected ? (
                            <div className="px-3 pb-3">
                                {Select.value.map((option, index) => {
                                    return selectedItem(option, "sel-" + index);
                                })}
                            </div>
                        ) : null}
                        <hr />
                    </>
                ) : null}
                {config
                    .getItems()
                    .filter((group) => {
                        if (!search) {
                            return group;
                        }
                        if (group && group.items) {
                            group.items = group.items.filter((item) => {
                                let normalize = (s) => {
                                    let r = (s + "").toLowerCase();
                                    const non_asciis = {
                                        a: "[àáâãäå]",
                                        ae: "æ",
                                        c: "ç",
                                        e: "[èéêë]",
                                        i: "[ìíîï]",
                                        n: "ñ",
                                        o: "[òóôõö]",
                                        oe: "œ",
                                        u: "[ùúûűü]",
                                        y: "[ýÿ]",
                                    };
                                    for (const i in non_asciis) {
                                        r = r.replace(new RegExp(non_asciis[i], "g"), i);
                                    }
                                    return r;
                                };
                                if (normalize(item.name).search(normalize(search)) !== -1) {
                                    return item;
                                }
                                return false;
                            });
                            if (group.items && group.items.length > 0) {
                                return group;
                            }
                        }
                        return false;
                    })
                    .map((group, gindex) => {
                        const vips = group.items
                            ? group.items.filter((option) => {
                                  return option.vip ? option : null;
                              })
                            : null;
                        const novips = group.items
                            ? group.items.filter((option) => {
                                  return !option.vip ? option : null;
                              })
                            : null;
                        return (
                            <>
                                {group.name ? (
                                    <div className="dropdown-item py-2 px-4" key={gindex}>
                                        <strong>{group.name}</strong>
                                    </div>
                                ) : (
                                    <div key={gindex}></div>
                                )}
                                {vips ? (
                                    <>
                                        {vips.map((option, index) => {
                                            return filterItem(option, gindex + "-" + index);
                                        })}
                                        <hr />
                                    </>
                                ) : null}
                                {novips
                                    ? novips.map((option, index) => {
                                          return filterItem(option, gindex + "-" + index);
                                      })
                                    : null}
                            </>
                        );
                    })}
            </Select.Body>
        ) : null;
    };

    const Container = (props) => (
        <div className="relative inline-block" {...props}>
            <Handler />
            <Body
                search={search}
                setSearch={(v) => {
                    setSearch(v);
                }}
            />
        </div>
    );

    return {
        ...Select,
        Component: Container,
        items: config.getItems(),
        hasValues: hasItems,
    };
};

export default SelectFilter;
