import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//Actions
import { addWeekDay, removeWeekDay } from "../../actions/globalActions";

const UseInputCheckbox = ({ data, key, disabled }) => {
    const { langStrings } = useSelector((state) => state.ui);

    //States
    const [checked, setChecked] = useState(false);
    const [checkboxStyle, setCheckboxStyle] = useState("opacity-100");
    const content = getContent(data, langStrings);

    //Actions
    const dispatch = useDispatch();

    const handleClick = () => {
        setChecked(checked ? false : true);
    };

    //Listeners
    useEffect(() => {
        //ISO 8601 standard Monday(1) Sunday (7)
        if (checked) {
            setCheckboxStyle("opacity-0");
            dispatch(addWeekDay(data));
        } else {
            setCheckboxStyle("opacity-100");
            dispatch(removeWeekDay(data));
        }
        // eslint-disable-next-line
    }, [checked]);

    return (
        <div className="inline-block" key={key}>
            <div className="h-10 flex items-center" onClick={disabled ? null : handleClick}>
                <div className="relative w-6 h-6 mr-2">
                    {disabled ? (
                        <input type="checkbox" className="absolute w-6 h-6 z-10" disabled></input>
                    ) : (
                        <input
                            type="checkbox"
                            className="absolute w-6 h-6 z-10"
                            value={data}
                            checked={checked}
                            onChange={() => {}}
                        ></input>
                    )}
                    <div className={`absolute w-6 h-6 bg-gray-200 z-100 ${checkboxStyle}`}></div>
                </div>
                <div
                    onClick={disabled ? null : handleClick}
                    className={`h-6 flex items-center pr-8 ${disabled ? "text-gray-400" : "cursor-pointer"}`}
                >
                    {content}
                </div>
            </div>
        </div>
    );
};

const getContent = (data, langStrings) => {
    //Returns "label" content
    let res;
    if (data === "repeat-yearly") {
        res = langStrings["repeat-yearly"];
    } else {
        res = langStrings[`day-${data}-short`];
    }
    return res;
};

export default UseInputCheckbox;
