import React, { useContext, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Session } from "../../../../hooks/Utils/Session";

import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";
import EmptyWidget from "./WidgetToolbars/CommonOptions/EmptyWidget";

const WidgetCarousel = () => {
    const { deviceType } = useContext(EditorContext);
    const { config, type, id: widgetID } = useContext(WidgetContext);

    const { lang } = useParams();
    const { gridItems } = useSelector((state) => state.design);

    const [currentImage, setCurrentImage] = useState(0);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const style = config?.style || Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);
    const data = config?.data || Widget.ParseData(widget ? widget.data : null, deviceType);
    const imagesCount = data && data.images ? data.images.length : 0;
    const intervalDuration = data && data.duration > 0 ? data.duration : 30;
    const src =
        data && data.images && data.images[currentImage]
            ? data.images[currentImage].libraryRef
                ? getFromDAS(data.images[currentImage].libraryRef, lang)
                : data.images[currentImage].externalUrl
            : null;
    const bgSize = data
        ? data.adjust === "cover-adjust"
            ? "100% 100%"
            : data.adjust
            ? data.adjust
            : "cover"
        : "cover";

    const timer = useRef(null);
    const showing = useRef(currentImage);
    showing.current = currentImage;

    useEffect(() => {
        if (timer && imagesCount > 0) {
            timer.current = setInterval(() => {
                const current = showing ? showing.current : 0;
                setCurrentImage(current === imagesCount - 1 ? 0 : current + 1);
            }, [intervalDuration * 1000]);
        }
        return () => {
            if (timer && timer.current) {
                clearInterval(timer.current);
            }
        };
        // eslint-disable-next-line
    }, [imagesCount, intervalDuration]);

    useEffect(() => {
        const elm = document.querySelector(`#widget_zone_${widgetID}`);
        if (elm) {
            elm.style.background = "transparent no-repeat center center";
            elm.style.backgroundImage = src ? `url('${src}')` : "";
            elm.style.backgroundSize = bgSize;
            elm.style.borderRadius = style && style.radius ? `${style.radius}rem` : "";
        }
        // eslint-disable-next-line
    }, [style]);

    return <>{imagesCount > 0 ? null : <EmptyWidget radius={style ? style.radius : 0} type={type}></EmptyWidget>}</>;
};

const getFromDAS = (libraryRef, languageRef) => {
    if (libraryRef) {
        return Session.getDasUrl(`${libraryRef}?lang=${languageRef}`);
    }
    return null;
};

export default WidgetCarousel;
