import SelectProject from "components/Section/Auth/SelectProject";
import SelectProfile from "components/Section/Auth/SelectProfile";
import ImpersonateList from "components/Section/Auth/ImpersonateList";
import Policy from "components/Section/Auth/Policy";

import Login from "sections/login";
import Logout from "sections/logout";

const checkSuperuser = ({ isSuperUser }) => isSuperUser;

export const routes = [
    { path: "/login", section: Login, fullscreen: true },
    { path: "/logout", section: Logout, fullscreen: true },
    { path: "/select-profile", section: SelectProfile, check: checkSuperuser, fullscreen: true },
    { path: "/select-project", section: SelectProject, check: ({ isChainLogged }) => isChainLogged, fullscreen: true },
    { path: "/impersonate-list", section: ImpersonateList, check: checkSuperuser, fullscreen: true },
    { path: "/policy", section: Policy, fullscreen: true },
    { path: "/privacy-policy", section: Policy, fullscreen: true },
];

export default routes;
