import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
//Components
import UseButton from "../useButton";
import UseCapacitySetter from "./useCapacitySetter";
//Actions
import { showRowForm, setHiddenRows } from "../../actions/sectionActions";
import { cleanAction } from "../../actions/globalActions";
import { setRefreshData } from "../../actions/tableActions";
//Utils
import { dateString, parseBoolean } from "../../hooks/Utils/Utils";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const UseCZFormCapacityUpdate = () => {
    const { t } = useTranslation();
    //Store data
    const { rowFormContent } = useSelector((state) => state.table);
    const { actionData, itemsAffected } = useSelector((state) => state.action);

    //Validation States
    const [validationPeopleNumber, setValidationPeopleNumber] = useState(true);
    const [validationPeoplePercentaje, setValidationPeoplePercentaje] = useState(true);

    //API
    const MUTATION = gql`
  mutation {
    updateCapacity(
      areaID: ${itemsAffected ? Number(itemsAffected[0]) : 0},
      currentCapacity: ${actionData && actionData.currentCapacity ? Number(actionData.currentCapacity) : 0}
      capacityDate: "${dateString(new window.ZDate())}"
      isPercentage: ${parseBoolean(actionData.isPercentage)}
      ) { error id ok }}`;

    const [editCapacity, { data }] = useMutation(MUTATION);
    //Actions
    const dispatch = useDispatch();

    const rowFormClickHandler = (e) => {
        const actionName = e.currentTarget.dataset.action;

        if (actionName !== "save") {
            dispatch(showRowForm(false));
            dispatch(cleanAction());
            dispatch(setHiddenRows(true));
        } else {
            //Validation
            let validatedPeopleNumber = false;
            let validatedPeoplePercentage = false;

            const validationProcess = () => {
                let res = false;
                if (actionData && actionData.isPercentage !== "true") {
                    //Validate people number
                    validatedPeopleNumber =
                        !actionData ||
                        !actionData.currentCapacity ||
                        parseInt(actionData.currentCapacity) > parseInt(actionData.capacityLimit) ||
                        parseInt(actionData.currentCapacity) < 0
                            ? false
                            : true;
                    setValidationPeopleNumber(validatedPeopleNumber);
                } else if (actionData && parseBoolean(actionData.isPercentage)) {
                    //Validate people percentaje
                    validatedPeoplePercentage =
                        !actionData ||
                        !actionData.currentCapacity ||
                        parseInt(actionData.currentCapacity) > 100 ||
                        parseInt(actionData.currentCapacity) < 0
                            ? false
                            : true;
                    setValidationPeoplePercentaje(validatedPeopleNumber);
                }

                if (validatedPeopleNumber || validatedPeoplePercentage) {
                    res = true;
                }
                return res;
            };
            if (validationProcess()) {
                editCapacity();
            } else {
                toast.error(t("input error"));
            }
        }
    };

    //Listeners
    useEffect(() => {
        if (actionData && actionData.currentCapacity && actionData.currentCapacity > "0") {
            setValidationPeopleNumber(true);
        }
    }, [actionData]);

    useEffect(() => {
        if (data && data.updateCapacity && data.updateCapacity.error === "") {
            toast.success(t("capacity of x updated", { name: actionData.name }));
            dispatch(showRowForm(false));
            dispatch(setRefreshData(true));

            dispatch(setHiddenRows(true));
            dispatch(cleanAction());
        } else if (data && data.updateCapacity && data.updateCapacity.error !== "") {
            toast.error(t("capacity of x updated error", { name: actionData.name }));
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <>
            {rowFormContent ? (
                <div className="w-full bg-white border-b border-gray-200 h-26 flex items-center">
                    <span className="mr-4"></span>
                    <div className="inline-block w-3/12 pr-4">{actionData.name}</div>
                    <div className="inline-block w-2/12 pr-4">
                        <UseCapacitySetter
                            ph={actionData.currentCapacity}
                            inputData={rowFormContent.inputs[0]}
                            validationPeopleNumber={validationPeopleNumber}
                            setValidationPeopleNumber={setValidationPeopleNumber}
                            validationPeoplePercentaje={validationPeoplePercentaje}
                            setValidationPeoplePercentaje={setValidationPeoplePercentaje}
                        />
                    </div>
                    <div className="inline-block w-2/12 pr-4">{`${actionData.capacityLimit} ${t("people")}`}</div>
                    <div className="inline-block w-2/12 pr-4">
                        {new window.ZDate(actionData.currentCapacityUpdated).toLocaleString()}
                    </div>
                    <div className="inline-block w-3/12 text-right pr-4 whitespace-no-wrap">
                        {rowFormContent.buttons.map((button, index) => (
                            <div
                                className={`inline-block ${index === 0 ? "pr-2" : ""} whitespace-normal`}
                                data-action={button.name}
                                key={index}
                                onClick={rowFormClickHandler}
                            >
                                <UseButton
                                    id={`capacity-update-form-${button.name}`}
                                    adjust="min-w-24"
                                    buttonName={button.name}
                                    buttonColor={button.color}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default UseCZFormCapacityUpdate;
