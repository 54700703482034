export const STAFF_USER_ACCESSE_CLEAN = 1;
export const STAFF_USER_ACCESSE_CAPACITY = 2;
export const STAFF_USER_ACCESSE_ORDERS = 3;
export const STAFF_USER_ACCESSES = [STAFF_USER_ACCESSE_CLEAN, STAFF_USER_ACCESSE_CAPACITY, STAFF_USER_ACCESSE_ORDERS];

/**
 * Get staff user accesse display text
 * @param {string} accesse - Staff user accesse
 * @returns {string} - Translation key
 */
export const getStaffAccesseText = (accesse) => {
    if (!accesse) {
        return "-";
    }
    switch (accesse) {
        case STAFF_USER_ACCESSE_CLEAN:
            return "cleaning"; // t("cleaning")
        case STAFF_USER_ACCESSE_CAPACITY:
            return "capacity"; // t("capacity")
        case STAFF_USER_ACCESSE_ORDERS:
            return "orders"; // t("orders")
        default:
            return `${accesse}??`;
    }
};

/**
 * Get staff user accesse information text
 * @param {[]string} accesses - Staff user accesses
 * @returns {string} - Translation key
 */
export const getStaffAccessesInfo = (accesses) => {
    const ids = accesses?.map((a) => a?.id);

    const manageClean = ids?.includes(STAFF_USER_ACCESSE_CLEAN);
    const manageCapacity = ids?.includes(STAFF_USER_ACCESSE_CAPACITY);
    const manageOrders = ids?.includes(STAFF_USER_ACCESSE_ORDERS);

    const showZones = manageClean || manageCapacity;
    const showShops = manageOrders;

    if (showZones && showShops) {
        return "show-zones-and-shops"; // t("show-zones-and-shops")
    } else if (showZones) {
        return "show-zones"; // t("show-zones")
    } else if (showShops) {
        return "show-shops"; // t("show-shops")
    }

    return null;
};
