import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    setHeaders,
    setTableResults,
    setAvailableFilters,
    setLoading,
    setCount,
    setExtendedInfo,
    setColumns,
    setSearchable,
    setCountPosition,
    setRowGroups,
    setCustomError,
    cleanActiveFilters,
    setError,
    setRefreshData,
    setTableType,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetCZTableHeaders";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
//Model
import BookingCategories from "./models/BookingCategories";
import { useTranslation } from "react-i18next";
import { POLLINTERVAL_15S } from "../../Utils/Utils";

const ListBookingCategories = () => {
    const { t } = useTranslation();
    const { currentPage, perPage, filterQuery, sortCriteria, refreshData, searchString } = useSelector(
        (state) => state.table
    );

    //Data
    const sectionName = "booking-categories";
    let categoriesList = [];

    //Actions
    const dispatch = useDispatch();

    const constFilter = ",filter:{bookingAllowed:true}";

    const GET_BOOKING_CATEGORIES = gql`
    {
      bookingCategories: bookingCategories(page: ${currentPage}, size: ${perPage}${sortCriteria}${
        filterQuery ? `${filterQuery.slice(0, -1)}, bookingAllowed:true}` : constFilter
    }) {
        info { count }
        results { id ref name parentCategoryName bookingAllowed acceptedBookings pendingBookings currentStatus}}
      cz_categories: cz_categories(page: 1, size: 10000){
          info { count }
          results { id name }}
      }`;

    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_BOOKING_CATEGORIES, {
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "network-only",
    });

    //Listeners
    useEffect(() => {
        executeQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (!loading && !error && data && data.bookingCategories) {
            //Get Data
            const dataItems = arrangeData(data, sortCriteria, categoriesList);
            const tableHeaders = GetTableHeaders(sectionName);
            const availableFilters = GetAvailableFilters(sectionName, categoriesList);
            //Add to store
            dispatch(setLoading(true));
            dispatch(setTableType(sectionName));
            dispatch(setSearchable(true));
            dispatch(setHeaders(tableHeaders));
            dispatch(setAvailableFilters(availableFilters));
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.bookingCategories.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(setCustomError(searchString !== null ? t("no-results-found") : t("no categories available")));

            //Show row groups if no sort selected
            if (sortCriteria === "") {
                dispatch(setRowGroups(true));
            }

            if (dataItems.length > 0) {
                dispatch(setColumns(dataItems[0].info.length));
            }
            dispatch(setExtendedInfo(false));
            dispatch(showRowForm(false));
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        dispatch(setError(error));
        // eslint-disable-next-line
    }, [error]);
};

const reOrder = (categoriesArray) => {
    const orderArray = categoriesArray.sort(function (a, b) {
        if (a.categoryName > b.categoryName) {
            return 1;
        }
        if (a.categoryName < b.categoryName) {
            return -1;
        }
        return 0;
    });
    const displayedCategories = [];
    const reOrderArray = [];
    // eslint-disable-next-line
    orderArray.map((category) => {
        //Category grouping
        if (!displayedCategories.includes(category.categoryName)) {
            displayedCategories.push(category.categoryName);
            reOrderArray.push(category);
        } else {
            reOrderArray.push({ ...category, category: false });
        }
    });
    return reOrderArray;
};

const arrangeData = (data, sortCriteria, categoriesList) => {
    const tempArray = [];
    const displayedCategories = [];
    // eslint-disable-next-line
    data.bookingCategories.results.map((category) => {
        tempArray.push(BookingCategories(category, displayedCategories, sortCriteria));

        //Category grouping
        if (!displayedCategories.includes(category.parentCategory)) {
            displayedCategories.push(category.parentCategory);
        }
    });

    //Get categories for filter
    categoriesList.push(["", "", ""]);
    // eslint-disable-next-line
    data.cz_categories.results.map((category) => {
        categoriesList.push([category.name, category.id, "int"]);
    });

    const orderArray = reOrder(tempArray);
    return orderArray;
};

export default ListBookingCategories;
