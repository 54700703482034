import React, { useEffect } from "react";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Utils
import { toast } from "react-toastify";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { setServerError, setCustomServerError, setTableResults } from "../../../../actions/tableActions";
import { parseBoolean, POLLINTERVAL_15S } from "../../../Utils/Utils";
import { Session } from "hooks/Utils/Session";

const NSStatus = (params) => {
    const { langStrings } = useSelector((state) => state.ui);
    const dispatch = useDispatch();
    const GET_NSSTATUS = gql`
        {
            nsStatus {
                results {
                    status
                    lastActivityTime
                }
            }
        }
    `;
    const [executeQuery, { data, loading, error }] = useLazyQuery(GET_NSSTATUS, {
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (!loading && !error && data && data.nsStatus && !data.nsStatus.results.status) {
            if (params && params.clearTableResults) dispatch(setTableResults([]));
            if (params && params.showGenericNotification) toast.error(langStrings["non-response-server"]);
            dispatch(setServerError(true));
            dispatch(
                setCustomServerError({
                    enabled: params && params.customServerError ? params.customServerError : false,
                    lastUpdated: data.nsStatus.results.lastActivityTime,
                })
            );
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        executeQuery();
        return () => {
            dispatch(setServerError(false));
        };
        // eslint-disable-next-line
    }, []);
};
export const networkServerErrorDiv = (customServerError, t) => {
    const superUser = Session.isSuperUser();

    const lastUpdatedTimeDate =
        customServerError && customServerError.lastUpdated ? new window.ZDate(customServerError.lastUpdated) : null;
    const lastUpdated = lastUpdatedTimeDate
        ? `${lastUpdatedTimeDate.toLocaleString()} ${lastUpdatedTimeDate.getTimezone()}`
        : "";

    function collapsibleServerError(e) {
        e.preventDefault();
        const items = document.querySelectorAll(".serverErrorExtended");
        const icon = document.querySelector("#serverErrorExtendedIcon");
        if (icon) {
            const extend = !parseBoolean(icon.dataset.extended);
            if (items && items.length > 0) {
                items.forEach(async (item) => {
                    item.style.display = extend ? "block" : "none";
                });
            }
            icon.classList.remove("icon-chevron-up");
            icon.classList.remove("icon-chevron");
            icon.classList.add(extend ? "icon-chevron-up" : "icon-chevron");
            icon.setAttribute("data-extended", extend);
        }
    }
    return (
        <div className=" mt-5 pt-3 pb-3 bg-gray-900 text-white w-full">
            <div className="w-full grid grid-flow-col grid-cols-12">
                <div className="dc-icon text-1xl  inline ">
                    <span
                        className=" icon icon-chevron-up mr-8 ml-8 cursor-pointer"
                        id="serverErrorExtendedIcon"
                        data-extended={true}
                        onClick={collapsibleServerError}
                    ></span>

                    <span className=" icon icon-alert text-red-100"></span>
                    <span className=" icon icon-warning2 -ml-1em "></span>
                </div>
                <div className="col-span-11">
                    <div className="my-1 ml-2">{t("server-unresponsive")}</div>
                    <div className="mt-2 serverErrorExtended">
                        {t("server-unresponsive-last-updated").replace("%0", lastUpdated)}
                    </div>
                    {superUser ? null : (
                        <div className="mt-2 serverErrorExtended">
                            <span className=" ">{t("server-unresponsive-check")}</span>{" "}
                            {t("server-unresponsive-contact")}{" "}
                            <a
                                className="underline"
                                href="https://support.zafiro.tv"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://support.zafiro.tv
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default NSStatus;
