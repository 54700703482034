import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    setCount,
    setCountPosition,
    setExtendedInfo,
    setHeaderButtons,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    setFormNewContent,
    setCustomError,
    cleanActiveFilters,
    setError,
    setRefreshData,
    setTableType,
    setCustomConfig,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetCZTableHeaders";
import GetTableForm from "../../Utils/Table/GetTableForm";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
//Model
import Category from "./models/Category";
import { useTranslation } from "react-i18next";
import { useMSQuery } from "../useMSQuery";

const ListCategories = () => {
    const { t } = useTranslation();
    const { currentPage, perPage, filterQuery, sortCriteria, refreshData } = useSelector((state) => state.table);
    const { langStrings } = useSelector((state) => state.ui);

    //Data
    const itemType = "categories";

    //States
    const [sorting] = useState(`,orderBy:{field: "ID", criteria: "desc"}`);

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_AREAS_CATEGORIES = gql`{
    cz_categories(page: ${currentPage}, size: ${perPage}${
        sortCriteria ? sortCriteria : sorting
    }${filterQuery}, withAmounts:true){
      info { count }
      results { areasAmount id name nameTID booked}}}`;
    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_AREAS_CATEGORIES, {
        pollInterval: 300000,
    });
    //Listeners
    useEffect(() => {
        executeQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (!loading && !error && data && data.cz_categories && networkStatus === 7) {
            //Gets formatted results
            const dataItems = arrangeData(data, langStrings);
            //Gets this table headers
            const tableHeaders = GetTableHeaders(itemType);
            //Shows loader component
            dispatch(setLoading(true));
            //Adds results to store
            dispatch(setTableResults(dataItems));
            //Config table
            //Shows search
            dispatch(setSearchable(true));
            dispatch(setTableType(itemType));
            //Sets table headers
            dispatch(setHeaders(tableHeaders));
            //Sets total count
            dispatch(setCount(data.cz_categories.info.count));
            dispatch(setCountPosition("table-header"));
            //Sets table header buttons
            dispatch(setHeaderButtons(GetTableHeaderButton(itemType)));
            //Sets in row add new form
            dispatch(setFormNewContent(GetTableForm(itemType)));
            //Sets custom error
            dispatch(setCustomError(t("no categories available")));

            //Set custom config
            dispatch(
                setCustomConfig({
                    searchSuffix: "category",
                    searchStyle: "col-span-3",
                    filtersStyle: "col-span-9",
                })
            );
            //Sets if rows are expandible
            dispatch(setExtendedInfo(false));
            //Sets initial visibility of row form
            dispatch(showRowForm(false));
            //If no added filters, cleans current ones from previous pages
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            //Hides loader compoent
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data, networkStatus, sortCriteria]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
        }
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        dispatch(setLoading(loading));
        // eslint-disable-next-line
    }, [loading]);
};

export const arrangeData = (data, langStrings) => {
    const tempArray = [];
    // eslint-disable-next-line
    data.cz_categories.results.map((category) => {
        tempArray.push(Category(category, langStrings));
    });
    return tempArray;
};

export default ListCategories;
