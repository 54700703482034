const ResendPrechekin = (code) => {
    return {
        title: [code, "-", "resend-pre-check-in"],
        bodyAdjust: "w-3/12",
        text: "resend-pre-check-in-description",
        executeSectionQuery: true,
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "resend",
                style: "blue",
                action: "RESEND_PRE_CHECK_IN",
            },
        ],
    };
};

export default ResendPrechekin;
