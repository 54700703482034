import React from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table/index";
//Utils
import ListCapacity from "../../../hooks/GraphqlCalls/CommonZones/ListCapacity";

const Capacity = () => {
    ListCapacity();
    return (
        <>
            <UseSectionHeader title={["monitor", "ofo", "capacity"]} />
            <UseTable />
        </>
    );
};

export default Capacity;
