import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { UseMainMenu } from "hooks/map";
import { NavLink } from "react-router-dom";

import { useAuth } from "hooks/Session/auth";

const prefix = `main-topbar`;

const MainMenu = () => {
    const { t } = useTranslation();
    const { paths } = useAuth();
    const sections = UseMainMenu({ paths });

    return (
        <ul className="inline-block whitespace-no-wrap text-gray-800 font-bold">
            {sections
                ? sections.map((value) => {
                      const id = `${prefix}-${value}`;
                      const name = t(value);
                      const to = "/" + value;
                      const menuItemClass = (isActive) =>
                          classNames({
                              "block first-capital h-20 border-b-2": true,
                              "text-xs py-10": true,
                              "sm:text-sm": true,
                              "md:py-8 md:text-base": true,
                              "text-blue-1100 hover:text-blue-300 border-transparent": !isActive,
                              "text-zafiro-400 font-black border-zafiro-400": isActive,
                          });
                      return (
                          <li key={id} id={id} className="inline-block w-auto px-2 lg:px-0 lg:w-36 text-center">
                              <NavLink
                                  className={({ isActive }) => menuItemClass(isActive)}
                                  to={to}
                                  id={`${prefix}-${value}-link`}
                              >
                                  {name}
                              </NavLink>
                          </li>
                      );
                  })
                : null}
        </ul>
    );
};

export default MainMenu;
