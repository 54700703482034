import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";

import { Component, ListComponents } from "sections/playground";

import Button from "components/Button";
import FileHandler from "components/FileHandler";
import CurrencyInput from "components/Inputs/CurrencyInput";
import Toggle from "components/Inputs/Toggle";
import { useAlert } from "components/Modal";
import TextInput from "components/TextInput";
import ColorInput from "components/ColorInput";
import PriceWithTax from "components/Inputs/PriceWithTax";
import Translations from "components/Translations";
import SelectorItem from "components/SelectorItem";
import ImageColorInput from "components/ImageColorInput";
import CustomTimePicker from "components/CustomTimePicker";
import DateInput from "components/Inputs/DateInput";
import DaySelector from "components/DaySelector";

const Inputs = () => {
    const inputFileRef = useRef(null);
    const displayFilesRef = useRef(null);

    const { open: alert } = useAlert();

    const [selectorValue, setSelectorValue] = useState(3);

    return (
        <ListComponents>
            <DateInput onChange={(date) => console.log("DateInput value", date)} />
            <DateInput value={new Date()} disabled={true} />
            <DateInput
                value={new Date()}
                placeholder="Please select a date"
                tooltip="Current week available only"
                disabledDays={[
                    {
                        before: new Date(new Date() - (new Date().getDay() - 1) * 24 * 60 * 60 * 1000),
                        after: new Date(new Date() - (new Date().getDay() - 7) * 24 * 60 * 60 * 1000),
                    },
                ]}
            />
            <DateInput required={true} tooltip={"tooltip for disabled days"} />
            <ColorInput id="color-1" value="#FF0000" disabled={true} />
            <ColorInput id="color-2" placeholder="Enter color" required={true} />
            <ColorInput id="color-3" value="#36f" />
            <ColorInput id="color-4" value="rgba(255,255,0,.7)" />
            <ColorInput
                id="color-5"
                value="pink"
                onChange={(color) => {
                    console.log(`Color changed to ${color}`);
                }}
            />

            <ImageColorInput id="image-color-1" />

            <Component title="CustomTimePicker" validated={false}>
                <CustomTimePicker
                    id="time-picker-enabled"
                    value="10:15:30"
                    onChange={(newTime) => console.log("Changed time:", newTime)}
                    units={["hours", "minutes", "seconds"]}
                    disabled={false}
                />
                <CustomTimePicker
                    id="time-picker-disabled"
                    value="08:45:00"
                    onChange={(newTime) => console.log("Changed time:", newTime)}
                    units={["hours", "minutes"]}
                    disabled={true}
                />
                <CustomTimePicker
                    id="time-picker-no-initial"
                    onChange={(newTime) => console.log("Changed time:", newTime)}
                    units={["hours", "minutes", "seconds"]}
                    disabled={false}
                />
                <CustomTimePicker
                    id="time-picker-hours-minutes"
                    value="14:30"
                    onChange={(newTime) => console.log("Changed time:", newTime)}
                    units={["hours", "minutes"]}
                    disabled={false}
                />
            </Component>

            <Component title="DaySelector" validated={false}>
                <DaySelector
                    id="basic-multi-select"
                    multiple={true}
                    language="en"
                    defaultValue={[2, 4]}
                    onChange={(selectedDays) => {
                        console.log("Selected Days:", selectedDays);
                    }}
                    width="300px"
                />
                <DaySelector
                    id="language-toggle"
                    multiple={true}
                    language="es"
                    onChange={(selectedDays) => {
                        console.log("Selected Days:", selectedDays);
                    }}
                    width="300px"
                />
                <DaySelector
                    id="single-select"
                    multiple={false}
                    language="en"
                    defaultValue={[3]}
                    onChange={(selectedDays) => {
                        console.log("Selected Days:", selectedDays);
                    }}
                    width="300px"
                />
                <DaySelector
                    id="visual-validation"
                    multiple={true}
                    language="en"
                    defaultValue={[0, 1]}
                    onChange={(selectedDays) => {
                        console.log("Selected Days:", selectedDays);
                    }}
                    width="300px"
                    isValid={false}
                />
                <DaySelector
                    id="no-initial"
                    multiple={true}
                    language="en"
                    onChange={(selectedDays) => {
                        console.log("Selected Days:", selectedDays);
                    }}
                    width="300px"
                />
            </Component>

            <Component title="SelectorItem">
                <div>
                    <div className="font-bold mb-2 text-lg">Editable example</div>
                    <SelectorItem
                        type="satisfaction"
                        id="pending-item-test-1"
                        value={selectorValue}
                        onChange={setSelectorValue}
                    />
                    <p className="pt-2">Selected value: {selectorValue}</p>
                    <Button design="classic-link" id="reset-test" onClick={() => setSelectorValue(3)}>
                        Reset value
                    </Button>
                </div>
                <div>
                    <div className="font-bold mb-2 text-lg">Read only example</div>
                    <SelectorItem type="satisfaction" id="pending-item-test-2" value={3} readOnly={true} />
                </div>
                <div>
                    <div className="font-bold mb-2 text-lg">Disabled example</div>
                    <SelectorItem type="satisfaction" id="pending-item-test-3" value={2} disabled={true} />
                </div>
            </Component>

            <Translations onChange={(values) => alert(JSON.stringify(values, null, 2))} />
            <Translations
                id="test-translations"
                languages={["fr", "es", "en", "it"]}
                translations={[{ languageCode: "fr", name: "FR test" }]}
                defaultLang="en"
                onChange={(translations) => {
                    console.log("Translations changed", translations);
                }}
                className="border border-dashed border-zafiro-200 p-5 rounded-lg"
                maxLength={30}
                maxHeight="10rem"
                multiline={2}
                template={[
                    { languageCode: "fr", name: "France" },
                    { languageCode: "es", name: "Spain" },
                    { languageCode: "en", name: "England" },
                    { languageCode: "it", name: "Italy" },
                ]}
            />
            <Translations
                id="test-translations-yellow"
                design="cols"
                languages={["eu", "es", "ca"]}
                defaultLang="es"
                placeholder="Enter translation"
                onChange={(translations) => {
                    console.log("Translations changed", translations);
                }}
                className="border bg-yellow-200 border-dashed border-yellow-400 p-5 rounded-lg"
            />

            <PriceWithTax
                id="test-price"
                price={1400}
                taxID={2}
                currencyCode="EUR"
                minValue={0}
                taxes={[
                    { id: 1, value: 0, name: "None" },
                    { id: 2, value: 10, name: "IVA" },
                    { id: 3, value: 15, name: "VAT" },
                    { id: 4, value: 20, name: "GST", default: true },
                ]}
                onChange={(data) => {
                    console.log("Price with tax changed", data);
                }}
            />

            <TextInput value="Lorem ipsum" disabled={true} />
            <TextInput placeholder="Enter your name" required={true} />
            <TextInput type="email" placeholder="Enter your email" required={true} />
            <TextInput type="password" placeholder="Enter password" />

            <CurrencyInput required={true} value={100} />
            <CurrencyInput currencyCode="EUR" minValue={5} maxValue={50} />

            <Toggle
                on={<div className="cursor-pointer p-5 text-xl text-blue-200">Custom ON</div>}
                off={<div className="cursor-pointer p-5 text-xl text-pink-500">Custom OFF</div>}
                label="Custom toggle"
            />
            <Toggle design="radio" label="Radio toggle" />
            <Toggle
                on={<div className="cursor-pointer text-4xl text-green-100 icon-Face-happy"></div>}
                off={<div className="cursor-pointer text-4xl text-red-100 icon-Face-angry"></div>}
            />

            <Component title="FileHandler">
                <div>
                    <FileHandler
                        ref={inputFileRef}
                        id="select-file"
                        options={{
                            multiple: true,
                            contentTypes: ["image"],
                        }}
                        onSuccess={(v) => {
                            console.log("FileHandler success", v);
                            if (displayFilesRef.current) {
                                displayFilesRef.current.setFiles(v);
                            }
                        }}
                        onError={(error) => {
                            alert(error);
                        }}
                    >
                        <Button
                            id="select-file-btn"
                            design="dark-blue"
                            onClick={() => {
                                if (inputFileRef.current) {
                                    inputFileRef.current.click();
                                }
                            }}
                        >
                            <i className="icon icon-image mr-2"></i> Choose images
                        </Button>
                    </FileHandler>
                    <SelectedFiles ref={displayFilesRef} />
                </div>
            </Component>
        </ListComponents>
    );
};

const SelectedFiles = forwardRef(({ className }, ref) => {
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [previewImages, setPreviewImages] = useState({});
    const [showPreview, setShowPreview] = useState(null);

    useImperativeHandle(ref, () => ({
        setFiles: (files) => {
            setSelectedFiles(files);
        },
    }));

    useEffect(() => {
        if (selectedFiles) {
            selectedFiles.forEach((file, index) => {
                if (file) {
                    const key = `file_${index}`;
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        setPreviewImages((prev) => ({ ...prev, [key]: e.target.result }));
                    };
                    reader.readAsDataURL(file.file);
                }
            });
        }
    }, [selectedFiles]);

    if (!selectedFiles?.length) {
        return null;
    }

    return (
        <>
            <div className={`grid grid-cols-2 gap-2 ${className || ""}`}>
                {selectedFiles
                    .filter((file) => file)
                    .map((file, index) => {
                        const name = file.file?.name;
                        const shortName = name.length > 16 ? `${name.substring(0, 16)}...` : name;
                        const preview = previewImages?.[`file_${index}`];
                        return (
                            <div key={name} className="border p-5">
                                <span
                                    className="font-bold whitespace-no-wrap"
                                    data-tip={name}
                                    data-for="default-tooltip"
                                >
                                    {shortName}
                                </span>
                                <br />
                                <span className="text-sm">Type: {file.file?.type}</span>
                                <br />
                                <span className="text-sm">Size: {Math.round(file.file?.size / 1024)} KB</span>
                                {preview ? (
                                    <img
                                        src={preview}
                                        alt={name}
                                        className="p-2 mt-2 border bg-white w-full"
                                        onClick={() => {
                                            setShowPreview(preview);
                                        }}
                                    />
                                ) : null}
                            </div>
                        );
                    })}
            </div>
            {showPreview && <FullScreenImage src={showPreview} onClick={() => setShowPreview(null)} />}
        </>
    );
});

const FullScreenImage = ({ src, onClick }) => {
    return (
        <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center z-200"
            onClick={onClick}
        >
            <img src={src} alt="Preview" className="max-w-full max-h-full" />
        </div>
    );
};

export default Inputs;
