import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
    ORDER_STATUS_CANCELLED,
    ORDER_STATUS_DELIVERED,
    ORDER_STATUS_DELIVERY_FAILED,
    ORDER_STATUS_INPROGRESS,
    ORDER_DELAYED,
    ORDER_STATUS_PENDING,
    ORDER_STATUS_EXPIRED,
    FILTER_ORDER_DELAYED,
    FILTER_ORDER_STATUS_CANCELLED,
    FILTER_ORDER_STATUS_EXPIRED,
    FILTER_ORDER_STATUS_PENDING,
    FILTER_ORDER_STATUS_INPROGRESS,
    FILTER_ORDER_STATUS_DELIVERED,
    FILTER_ORDER_STATUS_DELIVERY_FAILED,
} from "constants/sales";

import { MonitorContext } from "contexts/Sales/Monitor";
import { ShopContext } from "contexts/Sales/Shop";

import { capitalizeFirst } from "../../../../../../hooks/Utils/Utils";
import { Session } from "../../../../../../hooks/Utils/Session";
import { changeActionValues, cleanActionValues } from "../../../../../../actions/globalActions";
import {
    ORDER_DELIVERY_METHODS,
    ORDER_STATUS,
    ORDER_TYPE_RECEIVED,
    ORDER_TYPE_READY,
    ORDER_TYPE_ARCHIVED,
} from "../../../../../../hooks/Utils/Services/OrdersUtils";
import UseInputText from "../../../../../Inputs/useInputText";
import UseSelectWithSearch from "../../../../../useSelectWithSearch";
import UseFilterRangeDate from "../../../../../Table/UseFilterRangeDate";

import {
    FILTERS,
    FILTER_DELIVERY_METHOD,
    FILTER_LAST_UPDATE,
    FILTER_DELIVERY_SHOPS,
    FILTER_STATUS,
    FILTER_SEARCH,
} from "./OrderMonitor";

const OrderMonitorFilters = ({ handleChange }) => {
    const { t } = useTranslation();
    const { id: shopId } = useContext(ShopContext);
    const { shops, autoAccept, loading, currentType } = useContext(MonitorContext);

    const [filters, setFilters] = useState(null);
    const [updateQueryFilter, setUpdateQueryFilter] = useState(false);
    const dispatch = useDispatch();
    const filterValues = useSelector((state) => state.action?.values);

    useEffect(() => {
        if (shops && currentType) {
            let actionDataClone = { ...filterValues };
            dispatch(cleanActionValues());
            setTimeout(function () {
                Session.setSessionProp("updateSelectOptions", FILTERS.join(","));
                setFilters(
                    arrangeFilters({
                        currentType,
                        shopId,
                        shops,
                        filterValues: actionDataClone,
                        t,
                        autoAccept,
                    })
                );
            }, 200);
        }
    }, [currentType, shops, autoAccept]);

    useEffect(() => {
        if (updateQueryFilter) {
            setUpdateQueryFilter(false);
            handleChange(filterValues);
        }
    }, [updateQueryFilter]);

    useEffect(() => {
        if (filters?.forceRefetch) {
            if (filters.shops.selectedIds) {
                dispatch(
                    changeActionValues({
                        [FILTER_DELIVERY_SHOPS]: filters.shops.selectedIds,
                    })
                );
            }
            setTimeout(function () {
                setFilters({ ...filters, forceRefetch: false });
                setUpdateQueryFilter(true);
            }, 200);
        }
    }, [filters?.forceRefetch]);

    //handleFunctions
    const handleFilters = () => {
        setUpdateQueryFilter(true);
    };

    //Response
    return filters ? (
        <>
            <UseInputText
                id={FILTER_SEARCH}
                name={FILTER_SEARCH}
                disabled={loading}
                ph="search-for"
                updateActionValuesOnChange={true}
                value={filterValues?.[FILTER_SEARCH] || ""}
                action={() => {
                    handleFilters();
                }}
                setTimeoutBeforeType={true}
            />
            <span className="ml-4 mr-4">{capitalizeFirst(t("filter-by"))}:</span>
            <div className="w-52 mr-4">
                <UseSelectWithSearch
                    data={{
                        id: FILTER_STATUS,
                        disabled: loading,
                        name: FILTER_STATUS,
                        optionData: filters?.status?.options,
                        selectedIds: filters?.status?.selectedIds,
                        selectPlaceHolder: capitalizeFirst(t("status")),
                        hideSearch: true,
                        oneSelected: filters?.status?.oneSelected,
                        noSelect: true,
                        noShowSelectedOptionsInDropDownSection: true,
                        optionsAdjust: `whitespace-nowrap overflow-ellipsis mt-12`,
                        onChangeComponentVisible: handleFilters,
                    }}
                />
            </div>
            <div className="w-52 mr-4">
                <UseSelectWithSearch
                    data={{
                        id: FILTER_DELIVERY_METHOD,
                        disabled: loading,
                        name: FILTER_DELIVERY_METHOD,
                        optionData: filters?.deliveryMethods?.options,
                        selectedIds: filters?.deliveryMethods?.selectedIds,
                        selectPlaceHolder: capitalizeFirst(t("delivery-method")),
                        hideSearch: true,
                        noSelect: true,
                        oneSelected: filters?.deliveryMethods?.oneSelected,
                        noShowSelectedOptionsInDropDownSection: true,
                        optionsAdjust: `whitespace-nowrap overflow-ellipsis mt-12`,
                        onChangeComponentVisible: handleFilters,
                    }}
                />
            </div>
            {filters?.shops?.visible ? (
                <div className="w-52 mr-4">
                    <UseSelectWithSearch
                        data={{
                            id: FILTER_DELIVERY_SHOPS,
                            disabled: loading,
                            name: FILTER_DELIVERY_SHOPS,
                            optionData: filters?.shops?.options,
                            selectedIds: filters?.shops?.selectedIds,
                            selectPlaceHolder: capitalizeFirst(t("shops_")),
                            hideSearch: true,
                            noSelect: true,
                            oneSelected: filters?.shops?.oneSelected,
                            noShowSelectedOptionsInDropDownSection: true,
                            optionsAdjust: `whitespace-nowrap overflow-ellipsis mt-12`,
                            onChangeComponentVisible: handleFilters,
                        }}
                    />
                </div>
            ) : null}
            {filters?.lastUpdate?.visible ? (
                <div className="w-52 mr-4">
                    <UseFilterRangeDate
                        disabled={loading}
                        id={FILTER_LAST_UPDATE}
                        defaultInputName={"last-update"}
                        action={() => {
                            handleFilters();
                        }}
                    />
                </div>
            ) : null}
        </>
    ) : null;
};

export default OrderMonitorFilters;

//arrangeData
const arrangeFilters = ({ currentType, shopId, shops, filterValues, t, autoAccept }) => {
    // Pending, In Progress, Delayed
    const receivedOptions = [
        {
            id: FILTER_ORDER_STATUS_PENDING,
            name: capitalizeFirst(t(ORDER_STATUS[ORDER_STATUS_PENDING].title)),
        },
        {
            id: FILTER_ORDER_STATUS_INPROGRESS,
            name: capitalizeFirst(t(ORDER_STATUS[ORDER_STATUS_INPROGRESS].title)),
        },
        {
            id: FILTER_ORDER_DELAYED,
            name: capitalizeFirst(t(ORDER_STATUS[ORDER_DELAYED].title)),
        },
    ];
    // All, Delayed
    const readyOptions = [
        {
            id: null,
            name: `-- ${capitalizeFirst(t("all"))} --`,
        },
        {
            id: FILTER_ORDER_DELAYED,
            name: capitalizeFirst(t(ORDER_STATUS[ORDER_DELAYED].title)),
        },
    ];
    // All, Cancelled, Delivered, Expired
    const archivedOptions = [
        {
            id: null,
            name: `-- ${capitalizeFirst(t("all"))} --`,
        },
        {
            id: FILTER_ORDER_STATUS_CANCELLED,
            name: capitalizeFirst(t(ORDER_STATUS[ORDER_STATUS_CANCELLED].title)),
        },
        {
            id: FILTER_ORDER_STATUS_DELIVERED,
            name: capitalizeFirst(t(ORDER_STATUS[ORDER_STATUS_DELIVERED].title)),
        },
        {
            id: FILTER_ORDER_STATUS_DELIVERY_FAILED,
            name: capitalizeFirst(t(ORDER_STATUS[ORDER_STATUS_DELIVERY_FAILED].title)),
        },
        {
            id: FILTER_ORDER_STATUS_EXPIRED,
            name: capitalizeFirst(t(ORDER_STATUS[ORDER_STATUS_EXPIRED].title)),
        },
    ];

    let response = {
        forceRefetch: false,
        status: {
            options: [],
            oneSelected: true,
            selectedIds: [],
        },
        deliveryMethods: {
            options: [],
            oneSelected: true,
            selectedIds: [],
        },
        lastUpdate: {
            visible: false,
            options: [],
            oneSelected: true,
            selectedIds: [],
        },
        shops: {
            visible: false,
            options: [],
            oneSelected: false,
            selectedIds: [],
        },
    };

    response.deliveryMethods.options.push({
        id: null,
        name: `-- ${capitalizeFirst(t("all"))} --`,
    });
    Object.keys(ORDER_DELIVERY_METHODS).forEach((subjectKey) => {
        const method = ORDER_DELIVERY_METHODS[subjectKey];
        response.deliveryMethods.options.push({
            id: method.value,
            name: capitalizeFirst(t(method.title)),
            iconName: method.iconName,
        });
    });
    if (!shopId) {
        response.shops.visible = true;
    }
    if (shops?.length > 0) {
        shops.forEach((shop) => {
            response.shops.options.push({
                id: shop.token,
                name: shop.name,
            });
        });
    }
    if (filterValues && FILTERS) {
        if (filterValues[FILTER_DELIVERY_SHOPS]) {
            response.shops.selectedIds = filterValues[FILTER_DELIVERY_SHOPS];
            response.forceRefetch = true;
        }
    }

    if (currentType) {
        switch (currentType) {
            case ORDER_TYPE_RECEIVED:
                if (autoAccept) {
                    response.status.options.push(...readyOptions);
                } else {
                    response.status.options.push(...receivedOptions);
                    response.status.oneSelected = false;
                }
                break;
            case ORDER_TYPE_READY:
                response.status.options.push(...readyOptions);
                break;
            case ORDER_TYPE_ARCHIVED:
                response.lastUpdate.visible = true;
                response.status.options.push(...archivedOptions);
                break;
        }
    }
    return response;
};
