import React from "react";
import UseIcon from "../useIcon";

import { capitalizeFirst } from "../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";

function useSelectOption({ selectName, value, icons, itemType = null, translatable, selected }) {
    const { t } = useTranslation();

    //Data
    let styleSelected = "";

    //Actions
    if (selected === value) {
        styleSelected = "font-bold text-blue-100";
    }

    let icon = {};
    if (icons) {
        icon = { name: selectName, itemType: itemType, values: value };
    }

    return (
        <>
            <div className={`t-select-option p-2 cursor-pointer ${styleSelected}`}>
                {icon ? <UseIcon icon={icon} type={itemType} adjust="t-icon-select" /> : null}
                <div className="first-capital">
                    {renderLabel({value, translatable, t})}
                </div>
            </div>
        </>
    );
}

const renderLabel = (props) => {
    const {value, translatable, t} = props;
    let response = null;
    if(value !== ""){
        response = translatable ? t(`${value}`) : value;
    }else{
        response = `-- ${capitalizeFirst(t("all"))} --`;
    }
    return response;
}

export default useSelectOption;
