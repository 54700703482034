import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ShopContext } from "contexts/Sales/Shop";
import SettingsCard from "components/SettingsCard";
import useModalAssignEmails, { TYPES_MODAL_ASSIGN_EMAILS } from "components/Modal/useModalAssignEmails";
import { setRefreshContentData } from "actions/sectionActions";

const ShopSettingsEmail = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { data: dataContext } = useContext(ShopContext);
    const [numbersEmails, setNumbersEmails] = useState(0);
    const { open } = useModalAssignEmails();

    const { permissions } = useSelector((state) => state.ui);
    useEffect(() => {
        if (dataContext?.destinationEmails && dataContext?.destinationUsers) {
            setNumbersEmails(dataContext?.destinationEmails.length + dataContext?.destinationUsers.length);
        }
    }, [dataContext]);

    const handleModalShopSettings = () => {
        const hasOrdersManagement = permissions?.services?.shopsOrders;

        open({
            title: t("email-to-receive-orders"),
            type: hasOrdersManagement
                ? TYPES_MODAL_ASSIGN_EMAILS.SHOP_EMAILS
                : TYPES_MODAL_ASSIGN_EMAILS.SHOP_EMAILS_WITHOUT_ORDER_MANAGEMENT,
            selectedData: {
                emails: dataContext?.destinationEmails || [],
                users: dataContext?.destinationUsers || [],
            },
            actionPostUpdate: () => {
                dispatch(setRefreshContentData(true));
            },
            extraVariables: {
                id: id,
            },
        });
    };

    return (
        <SettingsCard
            title={t("email-to-receive-orders")}
            warning={numbersEmails < 1 ? t("no-email-added-yet") : null}
            edit={{
                id: "shop-settings-email-button-modal",
                onClick: handleModalShopSettings,
            }}
            description={t("select-users-receive-an-email-order-received")}
        >
            <span id={"shop-settings-number-emails"} className=" text-base">{`${numbersEmails} emails`}</span>
        </SettingsCard>
    );
};

export default ShopSettingsEmail;
