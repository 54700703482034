import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { safeJsonParse } from "hooks/Utils/Utils";
import { APPEARANCE_DEFAULT } from "hooks/Utils/AppearancesUtils";
import { TRANSLATE_SCREEN_TYPE_TITLE } from "hooks/Utils/Design/EditorUtils";
import { SCREEN, DEVICE, getWidgets } from "constants/editor";

import { Header, HEADER_DESIGN } from "sections/playground";
import Icon from "components/Icon";
import Switch from "components/Switch";
import Select from "components/Select";
import ContentEditor from "components/Editor";

const BG_IMAGE_TEST = "https://picsum.photos/1280/720";

const Editor = () => {
    const { t } = useTranslation();

    const editorRef = useRef(null);

    const [data, setData] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(BG_IMAGE_TEST);
    const [droppingItem, setDroppingItem] = useState(null);
    const [editorReady, setEditorReady] = useState(false);

    const stored = safeJsonParse(localStorage.getItem("editor-widgets"));
    const dbScreen = {
        type: SCREEN.TYPE.TV,
        content: {
            devices: [{ type: DEVICE.TYPE.TV }],
            contentWidgets: stored || [],
            pos: JSON.stringify({
                [DEVICE.TYPE.TV]: stored?.map((widget) => ({
                    ID: widget?.id,
                    W: widget?.layout?.w,
                    H: widget?.layout?.h,
                    X: widget?.layout?.x,
                    Y: widget?.layout?.y,
                })),
            }),
        },
    }; // This is screen object returned from API
    const dbTheme = null; // This is theme object returned from API

    const dbScreenType = dbScreen?.type;
    const dbContent = dbScreen?.content;
    const dbContentStyle = safeJsonParse(dbContent?.contentStyle);
    const dbContentPositions = safeJsonParse(dbContent?.pos);
    const dbContentDevice = dbContent?.devices?.[0]?.type;
    const dbContentWidgets = dbContent?.contentWidgets?.map((widget) => {
        const data = safeJsonParse(widget?.data);
        const actions = safeJsonParse(widget?.actions);
        const style = safeJsonParse(widget?.widgetStyle);
        const pos = dbContentPositions?.[dbContentDevice]?.find((pos) => pos?.ID === widget?.id);
        return {
            id: widget?.id,
            type: widget?.type,
            actions,
            data,
            style,
            widgets: widget?.widgets,
            layout: {
                w: pos?.W,
                h: pos?.H,
                x: pos?.X,
                y: pos?.Y,
            },
        };
    });

    const appearance = dbTheme?.config || APPEARANCE_DEFAULT;
    const theme = {
        default: {
            rgbaBG: appearance?.colors?.defaultBackground,
            rgbaFG: appearance?.colors?.defaultForeground,
        },
        focus: {
            rgbaBG: appearance?.colors?.focusBackground,
            rgbaFG: appearance?.colors?.focusForeground,
        },
        active: {
            rgbaBG: appearance?.colors?.activeBackground,
            rgbaFG: appearance?.colors?.activeForeground,
        },
    };

    const screenType = data?.screenType;
    const deviceType = data?.deviceType;
    const widgets = data?.widgets;
    const backgroundColor = data?.backgroundColor;

    const makeScreenOption = (value) => {
        const keyName = Object.keys(SCREEN.TYPE).find((key) => SCREEN.TYPE[key] === value);
        const name = t(TRANSLATE_SCREEN_TYPE_TITLE[keyName]);
        return {
            value: value,
            label: name,
        };
    };

    const screenOptions = (deviceType) => {
        let types = [];
        switch (deviceType) {
            case DEVICE.TYPE.TV:
                types = [
                    SCREEN.TYPE.TV,
                    SCREEN.TYPE.WELCOME,
                    SCREEN.TYPE.CAST,
                    SCREEN.TYPE.CCINSTRUCTION,
                    SCREEN.TYPE.EXPRESSCHECKOUT,
                    SCREEN.TYPE.GENERAL,
                ];
                break;
            case DEVICE.TYPE.MOBILE:
                types = [SCREEN.TYPE.MOBILE, SCREEN.TYPE.CCPAIRINGSUCCESSFUL, SCREEN.TYPE.GENERAL];
                break;
            case DEVICE.TYPE.DESKTOP:
                types = [SCREEN.TYPE.PORTAL];
                break;
            default:
        }
        return types?.map(makeScreenOption);
    };

    useEffect(() => {
        setData(
            dbScreen
                ? {
                      deviceType: dbContentDevice,
                      screenType: dbScreenType,
                      widgets: dbContentWidgets,
                      backgroundColor: dbContentStyle?.bgColor,
                  }
                : {
                      deviceType: DEVICE.TYPE.TV,
                      screenType: screenOptions(DEVICE.TYPE.TV)?.[0]?.value,
                  }
        );
    }, []);

    const availableWidgets = getWidgets({ deviceType, screenType });

    return (
        <div className="h-full grid bg-white" style={{ gridTemplateRows: "auto auto 1fr" }}>
            <Header title="Content Editor" design={HEADER_DESIGN.COMPACT} />
            <div className="p-5 flex items-center justify-between">
                <div className="flex items-center space-x-5 whitespace-no-wrap">
                    <div className="flex items-center space-x-2">
                        <div>Device</div>
                        <Select
                            id="device-type"
                            value={deviceType}
                            options={Object.values(DEVICE.TYPE)}
                            onChange={(device) =>
                                setData({
                                    deviceType: device,
                                    screenType: screenOptions(device)?.[0]?.value,
                                })
                            }
                            required={true}
                        />
                    </div>
                    <div className="flex items-center space-x-2">
                        <div>Screen</div>
                        <Select
                            id="screen-type"
                            value={screenType}
                            options={screenOptions(deviceType)}
                            onChange={(screen) =>
                                setData((data) => ({
                                    deviceType: data?.deviceType,
                                    screenType: screen,
                                }))
                            }
                            required={true}
                        />
                    </div>

                    <Switch
                        label="Image background"
                        labelPosition="left"
                        checked={!!backgroundImage}
                        onChange={({ checked }) =>
                            setBackgroundImage(checked ? `${BG_IMAGE_TEST}?random=${Math.random()}` : null)
                        }
                    />
                </div>
            </div>
            <div className="flex">
                {editorReady ? (
                    <div className="px-5 max-w-sm">
                        <div className="grid grid-cols-2 gap-2">
                            {availableWidgets?.length
                                ? availableWidgets.map((widget) => {
                                      const item = {
                                          type: widget?.type,
                                          actions: widget?.actions,
                                          data: widget?.data,
                                          style: widget?.style,
                                          layout: widget?.layout?.[deviceType],
                                      };
                                      return (
                                          <div
                                              className="border p-3 flex flex-col justify-center items-center space-y-1 bg-white cursor-move overflow-hidden"
                                              draggable={true}
                                              onDragStartCapture={(e) => {
                                                  setDroppingItem(item);
                                                  if (e?.dataTransfer) {
                                                      e.dataTransfer.setDragImage(e.target, 0, 0);
                                                      e.dataTransfer.setData("text/plain", "");
                                                  }
                                              }}
                                              onDragEndCapture={() => {
                                                  setDroppingItem(null);
                                              }}
                                          >
                                              <Icon type={widget?.icon} size="3xl" />
                                              <div className="text-xs truncate">{t(widget?.name)}</div>
                                          </div>
                                      );
                                  })
                                : null}
                        </div>
                    </div>
                ) : null}
                {screenType && deviceType ? (
                    <div className="p-5 w-full bg-gray-200">
                        <ContentEditor
                            ref={editorRef}
                            screenType={screenType}
                            deviceType={deviceType}
                            backgroundColor={backgroundColor}
                            backgroundImage={backgroundImage}
                            widgets={widgets}
                            lang={"es"}
                            theme={theme}
                            droppingItem={droppingItem}
                            onReadyChange={(ready) => setEditorReady(ready)}
                            onChange={({ widgets }) => {
                                setData((data) => ({ ...data, widgets }));
                                console.log("TEST save editor layout", widgets);
                                localStorage.setItem("editor-widgets", JSON.stringify(widgets));
                            }}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Editor;
