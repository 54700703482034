import i18n from "i18n";
export const AdditionalInformation = (guestInfo, customFields) => {
    return {
        title: `${guestInfo?.guestTitle} ${guestInfo?.guestName} ${guestInfo?.guestSurname}-${i18n.t(
            "additional-information"
        )}`,
        executeSectionQuery: true,
        inputs: [{ type: "guestAdditionalInformation", inputData: { guestInfo, customFields } }],
        buttons: [{ name: "cancel", style: "blue", action: "close" }],
    };
};
