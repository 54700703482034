import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";

const useSalesConfig = () => {
    const query = gql(`
    query{
        shopMessages{
          results{
            enabled
            type
          }
        }
      }
    `);

    const [load, { data, loading, error, called }] = useLazyQuery(query, { fetchPolicy: "cache-first" });

    const messages = data?.shopMessages?.results
        ? Object.fromEntries(
              data.shopMessages.results.map((message) => (message ? [message.type, message.enabled] : null))
          )
        : null;

    return {
        called,
        load,
        loading,
        error,
        ready: called && !loading && !error,
        data: messages,
    };
};

export default useSalesConfig;
