import { gql } from "apollo-boost";

export const GET_HOTEL_ROOMS_STATIC = gql`
    {
        hotelRoomsStatic: hotelRooms(layout: { size: 1000, orderBy: { field: "room", criteria: "ASC" } }) {
            info {
                count
            }
            results {
                id
                roomNumber
                name
                group
                guests {
                    id
                }
                warnings
            }
        }
    }
`;
export const GET_HOTEL_ROOMS_POLLING = gql`
    {
        hotelRoomsPolling: hotelRooms(layout: { size: 1000, orderBy: { field: "room", criteria: "ASC" } }) {
            info {
                count
            }
            results {
                id
                status
                stayFrom
                stayTo
                lastStay
                warnings
                floorID
                guests {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_FILTERS_DATA = gql`
    {
        hotelMap: installationMap {
            info {
                count
            }
            results {
                id
                name
                floors {
                    id
                    name
                }
            }
        }
        roomGroups: roomGroups {
            info {
                count
            }
            results {
                id
                name
            }
        }
    }
`;

export const updateRoomGroupsForRoom = gql`
    mutation updateRoomGroupsForRoom($room: Int64!, $roomGroups: [Int64]) {
        updateRoomGroupsForRoom(room: $room, roomGroups: $roomGroups) {
            error
            id
            ok
        }
    }
`;

export const addRoomsToRoomGroups = gql`
    mutation addRoomsToRoomGroups($rooms: [Int64], $roomGroups: [Int64]) {
        addRoomsToRoomGroups(rooms: $rooms, roomGroups: $roomGroups) {
            error
            id
            ok
        }
    }
`;
