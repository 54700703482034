import React from "react";
import { useTranslation } from "react-i18next";

export const SearchInput = ({ setSearchValue }) => {
    const { t } = useTranslation();
    return (
        <div className="w-full relative">
            <input
                type="text"
                placeholder={t("search")}
                className="rounded-md p-2 mb-4 w-full bg-gray-200 "
                onChange={(e) => setSearchValue(e.target.value)}
            />
            <span className="field-icon icon-search" style={{ top: "-6px" }}></span>
        </div>
    );
};
