export const GetCZTableHeaders = (section) => {
    let res = [];
    //Sort icon
    const tableSortIcon = { name: "table-sort" };

    //Special width classes
    const w112 = "w-1/12";
    const w212 = "w-2/12";
    const w312 = "w-3/12";
    const w412 = "w-4/12";
    const w512 = "w-5/12";
    const w15 = "w-1/5";

    //Header types
    let name = { name: "name" };
    let category = { name: "category", sortName: "categoryName" };
    let email = { name: "email" };
    let staffRoles = { name: "accesses" };
    let staffAreas = { name: "zones" };
    let areaActivity = { name: "area-sym-activity", sortName: "name" };
    let lastCleaning = { name: "last-cleaning", sortName: "lastCleaning" };
    let responsible = { name: "responsible", sortName: "responsibleName" };
    let refresh = { name: "refresh", hidden: true };
    let emptyCell = { name: "" };

    let currentCapacity = {
        name: "current-capacity",
        sortName: "currentCapacity",
    };
    let capacityLimit = { name: "capacity-limit", sortName: "capacityLimit" };
    let lastRefresh = {
        name: "last-update",
        sortName: "currentCapacityUpdated",
    };
    let editCapacity = { name: "edit-current-capacity", hidden: true };
    let hour = { name: "hour" };
    let peopleHeader = { name: "people" };
    let phone = { name: "phone" };
    let status = { name: "status" };
    let modify = { name: "modify", hidden: true };
    let bookingConfirmed = {
        name: ["bookings", "confirmeda"],
        sortName: "acceptedBookings",
    };
    let bookingPending = {
        name: ["bookings", "pending"],
        sortName: "pendingBookings",
    };
    let manageBookings = { name: ["manage", "bookings"], hidden: true };
    let bookingAllowed = { name: "booking-allowed" };
    let bookingNumber = { name: "booking-number" };
    let schedule = { name: "schedule" };
    let bookingManagement = { name: "booking-management", hidden: true };
    let categories = { name: "categories" };
    let quantity = { name: "quantity" };
    let viewDetail = { name: "view-detail", hidden: true };
    let erase = { name: "delete", hidden: true, isAction: true };

    switch (section) {
        case "list-staff":
            name.specialWidth = w312;
            email.specialWidth = w412;
            staffRoles.specialWidth = w312;
            staffAreas.specialWidth = w212;
            staffAreas.name = "";
            staffRoles.sortName = "role";
            name.sortName = "fullName";

            name.icon = tableSortIcon;
            email.icon = tableSortIcon;
            staffRoles.icon = tableSortIcon;

            res.push(name, email, staffRoles, staffAreas);
            break;
        case "list-cleaning":
            areaActivity.specialWidth = w312;
            lastCleaning.specialWidth = w312;
            responsible.specialWidth = w512;
            refresh.specialWidth = w112;

            areaActivity.icon = tableSortIcon;
            lastCleaning.icon = tableSortIcon;
            responsible.icon = tableSortIcon;

            res.push(areaActivity, lastCleaning, responsible, refresh);
            break;
        case "list-capacity":
            areaActivity.specialWidth = w312;
            currentCapacity.specialWidth = w212;
            capacityLimit.specialWidth = w212;
            lastRefresh.specialWidth = w312;
            editCapacity.specialWidth = w212;

            areaActivity.icon = tableSortIcon;
            currentCapacity.icon = tableSortIcon;
            capacityLimit.icon = tableSortIcon;
            lastRefresh.icon = tableSortIcon;

            res.push(areaActivity, currentCapacity, capacityLimit, lastRefresh, editCapacity);
            break;
        case "booking-category":
            hour.specialWidth = w112;
            peopleHeader.specialWidth = w112;
            name.specialWidth = w212;
            phone.specialWidth = w212;
            email.specialWidth = w212;
            status.specialWidth = w212;
            modify.specialWidth = w212;

            hour.icon = tableSortIcon;

            res.push(hour, peopleHeader, name, phone, email, status, modify);
            break;
        case "booking-categories":
            areaActivity.specialWidth = w412;
            bookingConfirmed.specialWidth = w412;
            bookingPending.specialWidth = w412;
            manageBookings.specialWidth = w412;

            areaActivity.icon = tableSortIcon;
            bookingConfirmed.icon = tableSortIcon;
            bookingPending.icon = tableSortIcon;

            res.push(areaActivity, bookingConfirmed, bookingPending, manageBookings);
            break;
        case "areas":
            name.specialWidth = w212;
            category.specialWidth = `${w312} `;
            bookingAllowed.specialWidth = w212;
            // bookingNumber.specialWidth = w15;
            schedule.specialWidth = w212;
            capacityLimit.specialWidth = w212;

            name.icon = tableSortIcon;
            category.icon = tableSortIcon;
            capacityLimit.icon = tableSortIcon;

            res.push(name, category, bookingAllowed, schedule, capacityLimit);
            break;
        case "areas-category":
            name.specialWidth = w312;
            bookingAllowed.specialWidth = w15;
            bookingNumber.specialWidth = w15;
            schedule.specialWidth = w15;
            capacityLimit.specialWidth = w15;
            bookingManagement.specialWidth = w15;

            name.icon = tableSortIcon;
            capacityLimit.icon = tableSortIcon;

            res.push(name, bookingAllowed, bookingNumber, schedule, capacityLimit, bookingManagement);
            break;
        case "categories":
            categories.specialWidth = w212;
            quantity.specialWidth = w212;
            bookingNumber.specialWidth = w212;
            emptyCell.specialWidth = w412;

            res.push(categories, quantity, bookingNumber, emptyCell);
            break;
        default:
            res = [""];
    }
    return res;
};

export default GetCZTableHeaders;
