import { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import useGQLQuery from "hooks/useQuery";

export const useSendResetCode = ({ lang, onSuccess, onError }) => {
    const query = gql`
        query sendResetCode($email: String!, $lang: LanguageCode!) {
            sendResetCode(email: $email, lang: $lang) {
                hash
            }
        }
    `;
    const [load, { data, loading, error: err, called }] = useLazyQuery(query, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const error = err || (data && !data?.sendResetCode?.hash ? "Hash is missing" : null);
    const success = data?.sendResetCode?.hash ? true : false;
    const response = data?.sendResetCode?.hash || null;

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess(response);
        }
    }, [success]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return {
        called,
        send: ({ email }) => {
            if (load) {
                load({ variables: { email, lang } });
            }
        },
        loading,
        error,
        ready: called && !loading && !error,
        success,
        data: response,
    };
};

export const useVerifyResetCode = ({ onSuccess, onError }) => {
    const query = gql`
        query verifyResetCode($email: String!, $code: String!, $hash: String!) {
            verifyResetCode(email: $email, code: $code, hash: $hash)
        }
    `;
    const [load, { data, loading, error, called }] = useLazyQuery(query, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const success = !!data?.verifyResetCode;
    const response = data?.verifyResetCode || null;

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess(response);
        }
    }, [success]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return {
        called,
        verify: ({ email, code, hash }) => {
            if (load) {
                load({ variables: { email, code, hash } });
            }
        },
        loading,
        error,
        ready: called && !loading && !error,
        success,
        data: response,
    };
};

export const useChangePassword = ({ onSuccess, onError }) => {
    const query = gql`
        mutation resetPassword($email: String!, $old: String!, $new: String!) {
            resetPassword(email: $email, password: { old: $old, new: $new }) {
                error
                okUpdate
            }
        }
    `;

    const [load, { data, loading, error: err, called }] = useMutation(query, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const error = err || data?.resetPassword?.error;
    const success = data?.resetPassword?.okUpdate || false;
    const response = data?.resetPassword?.okUpdate;

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess(response);
        }
    }, [success]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return {
        called,
        update: ({ email, oldPassword, newPassword }) => {
            if (load) {
                load({ variables: { email, old: oldPassword, new: newPassword } });
            }
        },
        loading,
        error,
        ready: called && !loading && !error,
        success,
        data: response,
    };
};

export const useChangeForgottenPassword = ({ lang, onSuccess, onError }) => {
    const query = gql`
        mutation resetForgottenPassword(
            $lang: String!
            $email: String!
            $newPassword: String!
            $code: String!
            $hash: String!
        ) {
            resetForgottenPassword(lang: $lang, email: $email, newPassword: $newPassword, code: $code, hash: $hash) {
                error
                okUpdate
            }
        }
    `;

    const [load, { data, loading, error: err, called }] = useMutation(query, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const error = err || data?.resetForgottenPassword?.error;
    const success = data?.resetForgottenPassword?.okUpdate || false;
    const response = data?.resetForgottenPassword?.okUpdate;

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess(response);
        }
    }, [success]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return {
        called,
        update: ({ email, code, hash, newPassword }) => {
            if (load) {
                load({ variables: { email, newPassword, code, hash, lang } });
            }
        },
        loading,
        error,
        ready: called && !loading && !error,
        success,
        data: response,
    };
};

export const useLogin = ({ lang, onSuccess, onError }) => {
    const query = gql`
        mutation login($user: String!, $pass: String!, $lang: String!) {
            login(user: $user, pass: $pass, lang: $lang) {
                chainId
                chainName
                chainRef
                chainAppURL
                cloudManagerURL
                managerLogo
                error
                isOk
                token
                isSuperuser
                userID
                userRef
                userFullname
                userManagerRole
                hasCorporateUser
                userTOSAccepted
                warnings {
                    type
                    message
                }
                userAdvicedUpdate
                projects {
                    id
                    location
                    hasChainModule
                    name
                    timeZone
                    keyBrand
                    ref
                    managerURL
                    managerUsers
                    blockGuestManagement
                    code
                    supportPhone
                    supportEmail
                    zMobileURL
                }
            }
        }
    `;
    const [load, { data, loading, error: err, called }] = useMutation(query, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const error = err || data?.login?.error;
    const success = data?.login?.isOk || false;
    const response = data?.login?.isOk ? data?.login : null;

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess(response);
        }
    }, [success]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return {
        called,
        login: ({ user, pass }) => {
            if (load) {
                load({ variables: { user, pass, lang } });
            }
        },
        loading,
        error,
        ready: called && !loading && !error,
        success,
        data: response,
    };
};

export const useImpersonate = ({ lang, onSuccess, onError }) => {
    const query = gql`
        mutation loginImpersonate($ref: String!, $lang: String!) {
            loginImpersonate(userRefToImpersonate: $ref, lang: $lang) {
                chainId
                chainName
                chainRef
                chainAppURL
                cloudManagerURL
                managerLogo
                error
                isOk
                token
                isSuperuser
                userID
                userRef
                userFullname
                userManagerRole
                hasCorporateUser
                userTOSAccepted
                warnings {
                    type
                    message
                }
                userAdvicedUpdate
                projects {
                    id
                    location
                    hasChainModule
                    name
                    timeZone
                    keyBrand
                    ref
                    managerURL
                    managerUsers
                    blockGuestManagement
                    code
                    supportPhone
                    supportEmail
                    zMobileURL
                }
            }
        }
    `;

    const [load, { data, loading, error: err, called }] = useMutation(query, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const error = err || data?.loginImpersonate?.error;
    const success = data?.loginImpersonate?.isOk || false;
    const response = data?.loginImpersonate?.isOk ? data?.loginImpersonate : null;

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess(response);
        }
    }, [success]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return {
        called,
        load: (ref) => {
            if (load) {
                load({ variables: { ref, lang } });
            }
        },
        loading,
        error,
        ready: called && !loading && !error,
        success,
        data: response,
    };
};

export const usePermissions = ({ token, userID, projectID, projectCode, onSuccess, onError }) => {
    const isCorporate = projectCode === "CORPORATE";

    const queryProjectPermissions = "permissions(projectCode: $projectCode)";
    const queryChainPermissions = "chainPermissions: permissions(projectCode: $projectCode, allChainPermissions: true)";
    const queryProjectLangs = "projectLangs(filter: {projectID: $projectID}) { results { isDefault languageRef } }";
    const queryChainLangs = "distinctProjectLangs{ results }";
    const queryUserPermissions = "userPermissions: users(id: $userID) { results { id roles { accesses { name } } } }";
    const queryDHCP = "dhcp: getMonitorAvailability { isAllData isDhcpData isSection }";

    const queryCorporate = gql`
        query permissions( $projectCode: String!) {            
            ${queryChainPermissions}
            ${queryProjectPermissions}          
            ${queryChainLangs}
            ${queryDHCP}
        }
    `;
    const queryCorporateWithUser = gql`
        query permissions( $userID: Int64!, $projectCode: String!) {            
            ${queryUserPermissions}
            ${queryChainPermissions}
            ${queryProjectPermissions}
            ${queryChainLangs}
            ${queryDHCP}
        }
    `;

    const queryProject = gql`
        query permissions( $projectID: Int64!, $projectCode: String!) {
            ${queryProjectPermissions}
            ${queryProjectLangs}
            ${queryDHCP}
        }
    `;
    const queryProjectWithUser = gql`
        query permissions( $userID: Int64!, $projectID: Int64!, $projectCode: String!) {
            ${queryUserPermissions}    
            ${queryProjectPermissions}            
            ${queryProjectLangs}
            ${queryDHCP}
        }
    `;

    const query = (() => {
        if (isCorporate) {
            return userID ? queryCorporateWithUser : queryCorporate;
        }
        return userID ? queryProjectWithUser : queryProject;
    })();

    const q = useGQLQuery(query, { onSuccess, onError, token });

    return {
        ...q,
        load: () => q.load({ userID, projectID: projectID || 0, projectCode: projectCode || "" }),
    };
};

export const useAdvicedUpdate = () => {
    const query = gql`
        mutation updateUser($ref: String!, $advicedUpdate: Boolean) {
            updateUser(ref: $ref, advicedUpdate: $advicedUpdate) {
                error
                ok
            }
        }
    `;
    const [load, { data, loading, error, called }] = useMutation(query, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    return {
        called,
        update: ({ ref, value }) => {
            if (load) {
                load({ variables: { ref, advicedUpdate: value } });
            }
        },
        loading,
        error: error || data?.updateUser?.error,
        ready: called && !loading && !error,
        success: data?.updateUser?.ok || false,
    };
};

export const useLoginZafiroV3 = ({ lang, onSuccess, onError }) => {
    const query = gql`
        query login($lang: String!) {
            loginOldManager(lang: $lang) {
                isEnabled
                token
            }
        }
    `;
    const [variables, setVariables] = useState(null);
    const q = useGQLQuery(query, {
        onSuccess: (data) => {
            if (onSuccess) {
                onSuccess({ ...data?.loginOldManager, users: variables?.users, url: variables?.url });
            }
        },
        onError,
    });

    return {
        ...q,
        data: { ...q?.data, users: variables?.users, url: variables?.url },
        login: ({ url, users }) => {
            setVariables({ url, users, lang });
            q.load({ lang });
        },
    };
};

export const useLoginProject = ({ chainID, chainRef, lang, onSuccess, onError }) => {
    const mutationProject = gql`
        mutation login($chainRef: String!, $projectRef: String!, $userRef: String!, $chainID: Int64!, $lang: String!) {
            loginProject(
                chainRef: $chainRef
                projectRef: $projectRef
                userRef: $userRef
                chainId: $chainID
                lang: $lang
            ) {
                isOk
                token
            }
        }
    `;

    const [variables, setVariables] = useState(null);

    const [load, { data, loading, error: err, called }] = useMutation(mutationProject, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const error = err || (data?.loginProject && !data.loginProject.isOk ? "Login error" : null);
    const success = data?.loginProject?.isOk || false;
    const response = data?.loginProject?.token
        ? {
              token: data?.loginProject?.token,
              userRef: variables?.userRef,
              projectRef: variables?.projectRef,
          }
        : null;

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess(response);
        }
    }, [success]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return {
        called,
        login: ({ userRef, projectRef }) => {
            if (load) {
                const variables = { projectRef, chainRef, chainID, userRef, lang };
                setVariables(variables);
                load({ variables });
            }
        },
        loading,
        error,
        ready: called && !loading && !error,
        success,
        data: response,
    };
};

export const useLoginCorporate = ({ chainID, chainRef, lang, onSuccess, onError }) => {
    const query = gql`
        mutation login($chainRef: String!, $userRef: String!, $chainID: Int64!, $lang: String!) {
            loginCorporate(chainRef: $chainRef, userRef: $userRef, chainId: $chainID, lang: $lang) {
                isOk
                token
            }
        }
    `;

    const [variables, setVariables] = useState(null);

    const [load, { data, loading, error: err, called }] = useMutation(query, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const error = err || (data?.loginCorporate && !data.loginCorporate.isOk ? "Login error" : null);
    const success = data?.loginCorporate?.isOk || false;
    const response = data?.loginCorporate?.token
        ? {
              token: data?.loginCorporate?.token,
              userRef: variables?.userRef,
              projectRef: "CORPORATE",
          }
        : null;

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess(response);
        }
    }, [success]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return {
        called,
        login: ({ userRef }) => {
            if (load) {
                const variables = { chainRef, chainID, userRef, lang };
                setVariables(variables);
                load({ variables });
            }
        },
        loading,
        error,
        ready: called && !loading && !error,
        success,
        data: response,
    };
};

export const useChains = (props) => {
    const { onSuccess, onError } = props || {};
    const query = gql`
        {
            chains {
                results {
                    name
                    id
                    ref
                }
            }
        }
    `;
    const [load, { data, loading, error, called }] = useLazyQuery(query, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const success = data?.chains || false;
    const response = data?.chains ? data?.chains.results : null;

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess(response);
        }
    }, [success]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return {
        called,
        load: () => {
            if (load) {
                load();
            }
        },
        loading,
        error,
        ready: called && !loading && !error,
        success,
        data: response,
    };
};

export const useLoginChain = ({ lang, onSuccess, onError }) => {
    const mutationChain = gql`
        mutation loginSuperuser($chainRef: String!, $lang: String!) {
            loginSuperuser(chainRef: $chainRef, lang: $lang) {
                chainId
                chainName
                chainRef
                chainAppURL
                cloudManagerURL
                managerLogo
                error
                isOk
                token
                isSuperuser
                userID
                userRef
                userFullname
                userManagerRole
                hasCorporateUser
                userTOSAccepted
                projects {
                    id
                    location
                    hasChainModule
                    name
                    timeZone
                    keyBrand
                    ref
                    managerURL
                    managerUsers
                    blockGuestManagement
                    code
                    supportPhone
                    supportEmail
                    zMobileURL
                }
            }
        }
    `;

    const [load, { data, loading, error: err, called }] = useMutation(mutationChain, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const error = err || data?.loginSuperuser?.error;
    const success = data?.loginSuperuser?.isOk || false;
    const response = data?.loginSuperuser || null;

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess(response);
        }
    }, [success]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return {
        called,
        login: ({ chainRef }) => {
            if (load) {
                load({ variables: { chainRef, lang } });
            }
        },
        loading,
        error,
        ready: called && !loading && !error,
        success,
        data: response,
    };
};
