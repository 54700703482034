import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { useDispatch } from "react-redux";
import Loading from "components/Loading";
import { toast } from "react-toastify";
import { executeVendureQuery, useVendureAPI } from "hooks/Utils/Integrations/useVendure";
import { setRefreshContentData } from "actions/sectionActions";
import { setRefreshData } from "actions/tableActions";

const MUTATION_DELETE_EXTRA = `mutation DeleteExtra($extraId: ID!) {
  deleteExtra(extraId: $extraId) {
    result
    message
  }
}`;

const useDeleteExtraMutation = ({ closeModal, refetch }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { adminApi } = useVendureAPI();

    const mutate = async (extraId) => {
        setLoading(true);
        try {
            const response = await executeVendureQuery(adminApi, {
                queryBody: MUTATION_DELETE_EXTRA,
                variables: { extraId },
            });

            if (response?.errors && response.errors.length > 0) {
                toast.error(t("mutation-error"));
            } else if (response?.data?.deleteExtra?.result === "DELETED") {
                toast.success(t("operation-successful"));
            } else if (response?.data?.deleteExtra?.result === "NOT_DELETED") {
                toast.error(response?.data?.deleteExtra?.message || t("mutation-error"));
            } else {
                toast.error(t("mutation-error"));
            }

            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));
            closeModal();
            refetch();
        } catch (error) {
            toast.error(`${error.message}`);
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));
            closeModal();
            refetch();
        } finally {
            setLoading(false);
        }
    };

    return { mutate, loading };
};
const ModalContent = (props) => {
    const { t } = useTranslation();

    const { close, refetch } = props;
    const { extraId, extraName } = props?.VALUES || {};

    const { mutate, loading } = useDeleteExtraMutation({ closeModal: close, refetch });

    return (
        <Modal
            title={`${t("delete")} ${extraName}`}
            id="delete-extra-modal"
            minWidth={"30vw"}
            footer={
                !loading ? (
                    <>
                        <Button design="blue-outline" id="modal-button-cancel" onClick={props?.close}>
                            {t("cancel")}
                        </Button>
                        <Button
                            design="red"
                            id="modal-button-delete"
                            onClick={() => {
                                mutate(extraId);
                            }}
                        >
                            {t("delete")}
                        </Button>
                    </>
                ) : null
            }
        >
            {loading ? <Loading /> : <div className=" mb-6">{t("delete-extra-description")}</div>}
        </Modal>
    );
};

export const useDeleteExtraModal = () => {
    const { open, close } = useModal();

    const openModal = (props) => {
        const newProps = {
            close,
            ...props,
        };

        open(<ModalContent {...newProps} />);
    };

    return {
        open: openModal,
        close,
    };
};
