import Select from "components/Select";
import { TopologyContext } from "contexts/Topology";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

export const Wings = ({ floorFilter }) => {
    const { t } = useTranslation();
    const { setSelectedWing, wingValidation } = useContext(TopologyContext);

    return (
        <Select
            id="new-wing"
            options={floorFilter.map((wing) => ({ label: wing.label, value: wing.label.toLowerCase() }))}
            placeholder={t("wing")}
            designClass={wingValidation}
            onChange={(value) => setSelectedWing(value)}
        />
    );
};
