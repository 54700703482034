import ShowZonesAndShops from "hooks/GraphqlCalls/Customers/modals/ShowZonesAndShops";
import AssignAreasToStaff from "../modals/staff/AssignAreasToStaff";
import { parseTranslation } from "hooks/Utils/SalesUtils";

import { getStaffAccessesInfo } from "constants/users";

export const getTagColor = (roleId) => {
    let res;
    switch (roleId) {
        case 1:
            res = "#26BCAD";
            break;
        case 2:
            res = "#6554C0";
            break;
        case 3:
            res = "#FF991F";
            break;
        default:
            res = "#0F63BD";
    }
    return res;
};

export const Staff = (user, langStrings, commonzones, shops, langDefault) => {
    const dataType = "staff-member";
    const { id, email, fullName, roles, staffShops, staffAreas } = user;
    const currentRoles = getCurrentRoles(roles);
    const currentRolesArrCopy = [...currentRoles];
    const order = { 2: 1, 1: 2, 3: 3 };
    const currentRolseSortedAlphabetically = currentRolesArrCopy.sort(function (a, b) {
        return order[a[0]] - order[b[0]];
    });

    const values = {
        name: fullName,
        email: email,
        accesses: roles.map((item) => item.id),
        shops: staffShops.map((item) => item.id),
        zones: staffAreas.map((item) => item.id),
        id: id,
    };

    const arrCommonzonesAssigned = [];

    commonzones.map((c) => {
        if (values.zones.includes(c.id)) {
            arrCommonzonesAssigned.push(c.name);
        }
    });

    const arrShopsAssigned = [];

    staffShops.forEach((element) => {
        arrShopsAssigned.push(element.name);
    });

    const staffInfo = getStaffAccessesInfo(roles);

    return {
        dataType: dataType,
        id: id,
        ref: user.ref,
        actions: [{ type: dataType }],
        executeSectionQuery: true,
        values: values,
        info: [
            {
                name: "name",
                cellContent: "text",
                value: fullName,
                specialWidth: "w-3/12 break-all",
            },
            {
                name: "email",
                cellContent: "text",
                value: email,
                specialWidth: "w-4/12 break-all",
            },
            {
                name: "staff-roles",
                cellContent: "labels",
                noSorted: true,
                value: currentRolseSortedAlphabetically,
                specialWidth: "w-3/12",
            },
            // {
            //     name: "staff-areas",
            //     cellContent: "edit-modal",
            //     icon: { name: "edit", itemType: dataType, position: "right" },
            //     modal: AssignAreasToStaff(user, langStrings ? langStrings : [], commonzones ? commonzones : []),
            //     value: user.staffAreas && user.staffAreas.length > 0 ? "see-zones" : "unspecified",
            //     specialWidth: "w-2/12",
            //     translate: true,
            // },
            {
                name: "staff-areas",
                cellContent: "text",
                // icon: { name: "edit", itemType: dataType, position: "right" },
                // modal: AssignAreasToStaff(user, langStrings ? langStrings : [], commonzones ? commonzones : []),
                // value: user.staffAreas && user.staffAreas.length > 0 ? "see-zones" : "unspecified",
                specialWidth: "w-2/12",
                adjust: " text-zafiro-600 font-bold cursor-pointer",
                translate: true,
                modal: ShowZonesAndShops({
                    title: staffInfo,
                    values: { arrCommonzonesAssigned, arrShopsAssigned },
                    fullName,
                    roles: roles.map((rol) => rol.id),
                }),
                value: staffInfo,
            },
        ],
    };
};

export const getCurrentRoles = (roles) => {
    console.log(roles);

    const res = [];
    const Roles = {
        1: "cleaning",
        2: "capacity",
        3: "orders",
    };
    // eslint-disable-next-line
    roles.map((rol) => {
        res.push([rol.id, Roles[rol.id], getTagColor(rol.id)]);
    });
    return res;
};

export default Staff;
