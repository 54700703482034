import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
//Utils
import { useParams } from "react-router-dom";
import GetFilterFormattedDate from "./GetFilterFormattedDate";
import { getOpeningTime, createSlots } from "./ScheduleManagement";
import moment from "moment";
//ACtions
import { setLoading } from "../../actions/tableActions";
import { setActionInput } from "../../actions/globalActions";
import { setCurrentZoneSchedule } from "../../actions/scheduleActions";

const GetProperPageBooking = (hour, requestSchedule, requestDate) => {
    //Store data
    const { dateFilter } = useSelector((state) => state.table);
    const { actionData } = useSelector((state) => state.action);
    const { cZoneSchedule } = useSelector((state) => state.schedules);

    //Data
    const { id } = useParams();
    const queryDate = GetFilterFormattedDate(dateFilter);

    //Actions
    const dispatch = useDispatch();

    //API

    const SCHEDULE_REQUEST = `schedule{
        ID dateType isEnabled
        name periodicityType type
        isActive
        timetableDates{
          dateList isActive
          timetableDateRanges{
              timeRanges weekdayList isActive
          }}}`;

    const GET_DATE_BOOKINGS = gql`{
    bookings(page: 1, size: 1000, area: "${id}", currentDate:"${queryDate}",orderBy:{field:"hour", criteria:"asc"}) {
      info{count}
      results{ bookingDate peopleAmount status}}
    commonzones: commonzones(page: 1, size: 1,  filter:{ref:"${id}"}) {
        results {
        capacityLimit
        bookingFreq
        slotsPerBooking
        ${requestSchedule ? SCHEDULE_REQUEST : ""}
        }
    }}`;

    const { loading, error, data } = useQuery(GET_DATE_BOOKINGS, {
        fetchPolicy: "network-only",
    });

    let res;
    if (!loading && !error) {
        if (hour) {
            let properIndex;
            // eslint-disable-next-line

            data.bookings.results.map((booking, index) => {
                if (
                    `${new window.ZDate(booking.bookingDate).getHours()}:${String(
                        new window.ZDate(booking.bookingDate).getMinutes()
                    ).padStart(2, "0")}` === hour
                ) {
                    properIndex = index + 1;
                }
                return booking;
            });
            if (!properIndex) {
                data.bookings.results.map((booking, index) => {
                    if (
                        `${new window.ZDate(booking.bookingDate).getHours()}:${String(
                            new window.ZDate(booking.bookingDate).getMinutes()
                        ).padStart(2, "0")}` < hour
                    ) {
                        properIndex = index + 1;
                    }
                    return booking;
                });
            }
            //Returns the page wich contains the new booking
            res = Math.ceil(properIndex % 6 === 0 ? properIndex / 6 + 1 : properIndex / 6);
        }
        if (!actionData.bookingData) {
            dispatch(setLoading(false));

            if (requestSchedule) {
                dispatch(setCurrentZoneSchedule(data.commonzones.results[0].schedule));
            }
            const zoneSchedule =
                cZoneSchedule && cZoneSchedule.length > 0 ? cZoneSchedule : data.commonzones.results[0].schedule;
            const openingTime = getOpeningTime(zoneSchedule, new window.ZDate(dateFilter));
            let slots = [];
            if (openingTime.openingTime) {
                openingTime.openingTime.forEach((time) => {
                    const startTime = moment(time.start, "hh:mm:ss");
                    const endDay = moment(time.end, "hh:mm:ss");
                    slots = slots.concat(
                        createSlots(
                            startTime,
                            endDay,
                            data.bookings.results,
                            new window.ZDate(requestDate ? requestDate : dateFilter),
                            data.commonzones.results[0].bookingFreq,
                            data.commonzones.results[0].slotsPerBooking,
                            data.commonzones.results[0].capacityLimit,
                            1 // people to reserve
                        )
                    );
                });
            }
            const hours = slots.filter((slot) => slot.enabled).map((item) => item.time);

            const dataToAction = {
                // eslint-disable-next-line
                ["bookingData"]: {
                    bookings: data.bookings.results,
                    areaInfo: data.commonzones.results[0],
                    availableHours: { values: hours },
                },
            };
            dispatch(setActionInput(dataToAction));
        }
    }
    return res;
};

export default GetProperPageBooking;
