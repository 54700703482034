import React, { useEffect, useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import Translations from "components/Translations";
import { gql } from "apollo-boost";
import Loading from "components/Loading";
import { useLazyQuery, useMutation } from "react-apollo";
import { toast } from "react-toastify";

const ModalEditCommonZones = (props) => {
    const { t } = useTranslation();
    const [projectLangs, setProjectLangs] = useState([]);
    const [translations, setTranslations] = useState([]);
    const [defaultLang, setDefaultLang] = useState("en");
    const [loading, setLoading] = useState(true);

    const GET_LANGS = gql`
    {        
        projectLangs {
            results {
                isDefault
                languageRef
            }
        }
                
        ${
            props.id
                ? `translations(id: ${props.id} parentType:COMMONZONES){
                            lang
                            text
                        }`
                : ""
        }
    }
    `;

    const UPDATE_LANGS = gql`mutation {
            updateText(id: ${props.id}, parentType: COMMONZONES, i18n: [${translations.map(
        (val) => `{lang:"${val.languageCode}", text: "${val.name}"}`
    )}]) {
                error
                id
                ok
            }
        }`;

    const [getLangsQuery, { data }] = useLazyQuery(GET_LANGS, {
        fetchPolicy: "network-only",
    });

    const [updateLangs] = useMutation(UPDATE_LANGS, {
        onCompleted() {
            toast.success(t("operation-successful"));
            props.refetch();
            props.close();
        },
        onError() {
            toast.error(t("mutation-error"));
        },
    });
    useEffect(() => {
        getLangsQuery();
    }, []);

    useEffect(() => {
        if (data) {
            setProjectLangs(data?.projectLangs?.results?.map((val) => val.languageRef));
            setTranslations(
                data?.translations?.map((val) => {
                    return { languageCode: val.lang, name: val.text };
                })
            );
            setDefaultLang(data?.projectLangs?.results?.find((val) => val.isDefault)?.languageRef);
            setLoading(false);
        }
    }, [data]);

    const handleSaveClick = () => {
        setLoading(true);
        updateLangs();
    };

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={`${t("edit-name")}`}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-save" onClick={handleSaveClick}>
                        {t("save")}
                    </Button>
                </>
            }
            className={"w-5/12 p-10"}
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <div>
                    <p>{t("enter-name-and-translations")}:</p>
                    <Translations
                        id="edit-name-common-zones-translations"
                        maxHeight="300px"
                        languages={projectLangs}
                        translations={translations}
                        defaultLang={defaultLang}
                        onChange={(trans) => {
                            setTranslations(trans);
                        }}
                        design="modal"
                    ></Translations>
                </div>
            )}
        </Modal>
    );
};

export const UseModalEditNameCommonZones = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalEditCommonZones {...newProps} />);
        },
    };
};
