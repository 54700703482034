import Widget from "../../../components/Section/Design/Widgets/Widget";
import { vw2px, getElementRects } from "../DesignUtils";

const SCREEN_CONTAINER_SELECTOR = `#editor_screen_container`;
export const DEFAULT_FONT_NAME = "Lato";
export const ROBOTO_FONT = {
    name: "Roboto",
    ref: "EXTERNAL",
    externalUrl:
        "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap",
};
export const LATO_FONT = {
    name: "Lato",
    ref: "EXTERNAL",
    externalUrl:
        "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap",
};

const CONFIG_STYLE_FONT = "fontName";
const CONFIG_TEXT_SIZE = "textSize";
export const textAlignment = { LEFT: "left", CENTER: "center", RIGHT: "right", JUSTIFY: "justify" };

// commented 3 first sizes - too small
const FONT_SIZES_VW = [
    // 0.41, 0.73, 0.88,
    1.02, 1.17, 1.32, 1.46, 1.61, 1.76, 1.9, 2.05, 2.2, 2.34, 2.49, 2.79, 2.93, 3.37, 3.81, 4.25, 4.4, 4.99, 5.28, 5.72,
];
export const getFontSizes = (screenWith, deviceType) => {
    // return list of sizes options for select
    return FONT_SIZES_VW.map((size, index) => {
        return { value: size, text: index + 1, vw: size, px: sizeToPx(size, screenWith, deviceType) };
    });
};

export const getFontSizesPX = (defaultTextSize, t) => {
    // return list of sizes options for select
    return [
        { value: getPXDefaultSizeForPreview(parseFloat(defaultTextSize * 0.7)), text: t("Extra small") },
        { value: getPXDefaultSizeForPreview(parseFloat(defaultTextSize * 0.8)), text: t("Small") },
        { value: getPXDefaultSizeForPreview(defaultTextSize), text: t("Default") },
        { value: getPXDefaultSizeForPreview(parseFloat(defaultTextSize * 1.25)), text: t("Medium") },
        { value: getPXDefaultSizeForPreview(parseFloat(defaultTextSize * 1.5)), text: t("Large") },
        { value: getPXDefaultSizeForPreview(parseFloat(defaultTextSize * 2)), text: t("Extra Large") },
        { value: getPXDefaultSizeForPreview(parseFloat(defaultTextSize * 3)), text: t("Huge") },
    ];
};

export const getOptionsFontList = (fontList) => {
    // return list of font options for select
    return fontList?.length
        ? fontList.map((font) => {
              return {
                  value: font.name,
                  name: font.name,
                  text: font.name,
                  fontRef: font.ref === "EXTERNAL" ? font.externalUrl : font.ref,
              };
          })
        : [];
};

export const ensureFontName = (fontName, fonts) => {
    return (fontName && fonts ? fonts.fontNames.find((name) => name === fontName) : null) || DEFAULT_FONT_NAME;
};

export const getFontNameOptionSelected = (theme, fonts, deviceType, widget, implementNewStyles) => {
    // get font name of widget config or paragraph defined theme if not return default font Roboto
    const config = Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);

    let fontNameToSearch;
    if (implementNewStyles) {
        fontNameToSearch = config?.devices?.[deviceType]?.font?.name
            ? config.devices[deviceType].font.name
            : config?.font?.name;
    } else {
        fontNameToSearch = config?.devices?.[deviceType]?.[CONFIG_STYLE_FONT]
            ? config.devices[deviceType][CONFIG_STYLE_FONT]
            : config[CONFIG_STYLE_FONT];
    }

    const fontUsed = fonts ? fonts.fontNames.find((fontName) => fontName === fontNameToSearch) : null;
    if (fontUsed) {
        return fontUsed;
    } else if (theme?.paragraphStyle?.font) {
        return theme?.paragraphStyle?.font;
    } else {
        return DEFAULT_FONT_NAME;
    }
};

export const getTextSizeSelected = (theme, deviceType, widget) => {
    const config = Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);

    if (config?.devices?.[deviceType]?.size) {
        return config.devices[deviceType].size;
    }
    if (config?.size) {
        return config.size;
    }

    if (config?.devices?.[deviceType]?.[CONFIG_TEXT_SIZE]) {
        return config.devices[deviceType][CONFIG_TEXT_SIZE];
    }
    if (config?.[CONFIG_TEXT_SIZE]) {
        return config[CONFIG_TEXT_SIZE];
    }

    if (theme?.paragraphStyle?.size) {
        return theme?.paragraphStyle?.size;
    }
    return null;
};

export const getPXDefaultSizeForPreview = (s, screen = 1280) => {
    const preview = document.getElementById("appearancePreviewDiv");
    if (preview) {
        screen = preview.clientWidth;
    }
    return vw2px(s, screen, 1);
};

export const getPXDefaultSizeForPreviewMobile = (s, screen = 1280) => {
    const preview = document.getElementById("preview-mobile-appearance");
    if (preview) {
        screen = preview.clientWidth;
    }
    return vw2px(s, screen, 3);
};

export const sizeToPx = (s, screenSize, deviceType) =>
    vw2px(s, screenSize ? screenSize : 1280, deviceType === "Mobile" ? 3 : 1);
export const pxToSize = (s, screenSize, deviceType) =>
    px2vwRound(s, screenSize ? screenSize : 1280, deviceType === "Mobile" ? 3 : 1);

export const px2vwRound = (size, width, zoom) => {
    if (!zoom) zoom = 1;
    if (width > 0) {
        return round((size * 100) / width / zoom, 1);
    } else {
        let editorContainer = document.querySelector(SCREEN_CONTAINER_SELECTOR);
        if (editorContainer) {
            const editorPos = getElementRects(editorContainer);
            if (editorPos) {
                return round((size * 100) / editorPos.width / zoom, 1);
            }
        }
    }
    return size;
};

export const styleMatches = (theme, config, defaultStyle) => {
    const configStyle = config ? config.styletype || config.fontStyle : null;
    const _defaultStyle = defaultStyle ? `${defaultStyle.toLowerCase()}Style` : "paragraphStyle";
    const style = configStyle ? `${configStyle.toLowerCase()}Style` : _defaultStyle;
    const themeStyle = theme ? theme[style] : null;
    const styleApplied = config ? config : {};

    let styleDefined = false;
    ["font", "fontName", "bold", "italic", "underline"].forEach((element) => {
        if (Object.keys(styleApplied).includes(element)) {
            styleDefined = true;
        }
    });
    if (!styleDefined) {
        //no config set
        console.warn("NO CONFIG SET");
        return true;
    }
    let matches = true;

    if (themeStyle.size !== styleApplied.textSize) {
        matches = false;
    }

    if (Object.keys(config).includes("fontName") && themeStyle.font !== config.fontName) {
        matches = false;
    }
    if (Object.keys(config).includes("bold") && themeStyle.bold !== config.bold) {
        matches = false;
    }
    if (Object.keys(config).includes("italic") && themeStyle.italic !== config.italic) {
        matches = false;
    }
    if (Object.keys(config).includes("underline") && themeStyle.underline !== config.underline) {
        matches = false;
    }
    return matches;
};

function round(value, precision) {
    let multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}
