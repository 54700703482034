import React from "react";
import UseSectionHeader from "../../../../useSectionHeader";
import UseTable from "../../../../Table/index";
import ListCorporateChannels from "../../../../../hooks/GraphqlCalls/Services/ListCorporateChannels";
import UpdateCorporateChannel from "../../../../../hooks/GraphqlCalls/Services/UpdateCorporateChannel";
import { useParams } from "react-router-dom";

const CorporateChannels = () => {
    const { id } = useParams();
    return (
        <div>
            <UseSectionHeader noCapitalize={true} title={"corporate-channels-list"} />
            <UseTable />
            <ListCorporateChannels />
            <UpdateCorporateChannel id={id} />
        </div>
    );
};

export default CorporateChannels;
