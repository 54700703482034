import React from "react";
import UseSectionHeader from "../../useSectionHeader";

const RemoteControllers = () => {
    return (
        <>
            <UseSectionHeader
                title={["remote-controllers"]}
                buttonName={["add", "client"]}
                buttonColor="btn-blue"
                action={"function"}
                value={"demo"}
            />
        </>
    );
};

export default RemoteControllers;
