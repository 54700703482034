import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

//Utils
import { WidgetContext } from "../../../../contexts/editor";

const WidgetCalendar = () => {
    //Params
    //State
    const [render, setRender] = useState(false);
    //Store data
    const { id: widgetID } = useContext(WidgetContext);
    const { gridItems } = useSelector((state) => state.design);
    let widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    if (widget) {
        widget.type = "CALENDAR";
    }
    useEffect(() => {
        //Timeout to wait widget_zone exists
        setTimeout(function () {
            setRender(true);
        }, 200);
    }, []);

    return (
        <>
            {render ? (
                <div
                    dangerouslySetInnerHTML={{
                        width: "100%",
                        height: "100%",
                        __html: widget ? window.Render.widget(widget).outerHTML : null,
                    }}
                ></div>
            ) : null}
        </>
    );
};

export default WidgetCalendar;

/*
Render widget without Render external library

import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//Utils
import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";
import { GetThemeDefault } from "../../../../hooks/Utils/DesignUtils";
import dayjs from "dayjs";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";

const WidgetCalendar = () => {
    //Params
    const { lang } = useParams();
    //Store data
    const { deviceType, theme } = useContext(EditorContext);
    const { id: widgetID } = useContext(WidgetContext);
    const { gridItems } = useSelector((state) => state.design);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;

    //Widget styles
    const style = config?.style || Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);
    const bgColor = style?.bgColor || GetThemeDefault({ theme, id: "bgColor" });
    const fgColor = style?.fgColor || GetThemeDefault({ theme, id: "fgColor" });

    //Widget styles
    const data = config?.data || Widget.ParseData(widget ? widget.data : null, deviceType);
    const format = data.format;
    const weekStart = data.weekStart;
    const typography = data.typography;

    //Calendar dates
    const cDate = new window.ZDate();
    const firstMonthDay = dayjs(dayjs(cDate).format("YYYY-MM") + "-01").format("d");
    const cMonthAndYear = capitalizeFirst(dayjs(cDate).locale(lang).format("MMMM YYYY"));
    const cDay = dayjs(cDate).locale(lang).format("dddd").toUpperCase();
    const cNumberDay = dayjs(cDate).locale(lang).format("DD");
    const daysInMonth = dayjs(cDate).daysInMonth();
    const daysToPrint = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    const emptyDays = Array.from(
        { length: parseInt(firstMonthDay) - (weekStart === "monday" ? 1 : 0) },
        (_, i) => i + 1
    );

    const daysLettersSundayFirst = ["S", "M", "T", "W", "T", "F", "S"];
    const daysLettersMondayFirst = ["M", "T", "W", "T", "F", "S", "S"];
    const daysLetters = weekStart === "monday" ? daysLettersMondayFirst : daysLettersSundayFirst;

    // CALCULATE SIZES
    const DAY_TITLE_FACTOR = 12;
    const DAY_BODY_FACTOR = 20;
    const DAY_NUMBER_FACTOR = 3;
    const widgetDiv = document.querySelector(`#widget_zone_${widgetID}`);
    let fontSize,
        paddingSize,
        calendarDayWidth,
        calendarDayHeight,
        calendarDayFontSize = null;
    if (widgetDiv) {
        const widgetZoneStyles = getComputedStyle(widgetDiv);
        let zoneWidth = parseFloat(widgetZoneStyles.width.replace("px", ""));
        let zoneHeight = parseFloat(widgetZoneStyles.height.replace("px", ""));
        const borderWidth = parseFloat(widgetZoneStyles.borderWidth.replace("px", ""));
        zoneWidth = zoneWidth - 2 * borderWidth;
        zoneHeight = zoneHeight - 2 * borderWidth;

        fontSize = Math.round(zoneWidth / DAY_TITLE_FACTOR);
        paddingSize = Math.round(zoneHeight / DAY_BODY_FACTOR);
        calendarDayWidth = zoneWidth / 9;
        calendarDayHeight = Math.round(zoneHeight / 12);
        calendarDayFontSize = Math.min(Math.round(zoneWidth / 18), Math.round(zoneHeight / 18));
    }

    return (
        <div
            className="w-full h-full text-center"
            style={{
                backgroundColor: bgColor,
                color: fgColor,
                fontFamily: typography ? typography.name : null,
            }}
        >
            {format ? (
                format === "month" ? (
                    <div className="h-full" style={{ fontSize: fontSize + "px" }}>
                        <div
                            className=" w-full font-medium"
                            style={{
                                paddingTop: paddingSize + "px",
                                paddingBottom: paddingSize + "px",
                            }}
                        >
                            {cMonthAndYear.toUpperCase()}
                        </div>
                        <div
                            style={{
                                display: "table",
                                width: "100%",
                                marginTop: "-" + paddingSize * 2.5 + "px",
                                height: "100%",
                            }}
                        >
                            <div className="table-cell align-middle">
                                <div
                                    className=" w-full font-medium"
                                    style={{
                                        paddingLeft: calendarDayWidth + "px",
                                        paddingRight: calendarDayWidth + "px",
                                    }}
                                >
                                    {daysLetters.map((dayLetter) => (
                                        <div
                                            className="float-left"
                                            style={{
                                                width: calendarDayWidth + "px",
                                                height: calendarDayHeight + "px",
                                                fontWeight: 700,
                                                fontSize: calendarDayFontSize * 1.2 + "px",
                                                marginBottom: calendarDayHeight / 2 + "px",
                                            }}
                                        >
                                            {dayLetter}
                                        </div>
                                    ))}
                                    {emptyDays.map((day) => (
                                        <div
                                            className="float-left"
                                            style={{ width: calendarDayWidth + "px", height: calendarDayHeight + "px" }}
                                        >{` `}</div>
                                    ))}
                                    {daysToPrint.map((day) => (
                                        <div
                                            className="float-left"
                                            style={{
                                                width: calendarDayWidth + "px",
                                                height: calendarDayHeight + "px",
                                                lineHeight:
                                                    calendarDayHeight - (parseInt(cNumberDay) === day ? 2 : 0) + "px",
                                                fontSize: calendarDayFontSize + "px",
                                                border: parseInt(cNumberDay) === day ? `1px solid ${fgColor}` : "none",
                                            }}
                                        >
                                            {day}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : format === "day" ? (
                    <div className="h-full font-medium " style={{ fontSize: fontSize + "px" }}>
                        <div
                            className=" w-full border-b "
                            style={{
                                borderColor: fgColor,
                                paddingTop: paddingSize + "px",
                                paddingBottom: paddingSize + "px",
                            }}
                        >
                            {cMonthAndYear}
                        </div>
                        <div
                            style={{
                                display: "table",
                                width: "100%",
                                marginTop: "-" + paddingSize * 2 + "px",
                                height: "100%",
                            }}
                        >
                            <div className="table-cell align-middle">
                                <div className=" w-full">{cDay}</div>
                                <div
                                    className=" w-full"
                                    style={{ fontSize: Math.round(fontSize * DAY_NUMBER_FACTOR) + "px" }}
                                >
                                    {cNumberDay}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            ) : null}
        </div>
    );
};

export default WidgetCalendar;
*/
