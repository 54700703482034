const NewAlarm = () => {
    return {
        title: "new-alarm",
        text: ["new-alarm-warning"],
        bodyAdjust: "w-4/12",
        executeSectionQuery: true,
        inputs: [
            {
                text: "new-alarm",
                type: "newAlarm",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: "new-alarm",
            },
        ],
    };
};

export default NewAlarm;
