import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate as useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import UseButton from "../../useButton";
import UseSectionHeader from "../../useSectionHeader";
import NotFound from "../NotFound";
import translate from "../../../lang/es/hotel/translate";
import { showGlobalLoading, reloadBreadCrumbs, setSectionStyle } from "../../../actions/uiActions";

import KibanaDashboard from "./KibanaDashboard";
import FilterProject from "./FilterProject";
import useFilterReport from "./FilterReport";

import Warning from "components/Warning";

import LoginKibana from "../../../hooks/GraphqlCalls/Stats/LoginKibana";

import { useAuth } from "hooks/Session/auth";

const Analytics = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { type: tag, id, sub: initialReport } = useParams();

    const { projects, isCorporate } = useAuth();

    const availableProperties = projects?.filter((p) => p?.hasChainModule);

    const location = useLocation();
    const history = useHistory();
    const { permissions } = useSelector((state) => state.ui);

    const { login, dashboards: groups, loading: loadingLogin, error: errorLogin } = LoginKibana();
    const refIframe = React.createRef();

    const getAvailableStats = (groups) => {
        if (!permissions?.stats) return {};

        const filteredGroups = { ...groups };
        Object.keys(filteredGroups).forEach((key) => {
            if (!permissions?.stats?.[key?.toLocaleLowerCase()]) {
                delete filteredGroups[key];
            }
        });

        return filteredGroups;
    };

    const availableStats = getAvailableStats(groups);

    const group =
        availableStats && tag
            ? tag === "cast"
                ? availableStats["Cast"]
                : tag === "wifi"
                ? availableStats["WiFi"]
                : tag === "internal"
                ? availableStats["Internal"]
                : tag === "mobile"
                ? availableStats["Mobile"]
                : tag === "sales"
                ? availableStats["Sales"]
                : availableStats[tag]
            : null;
    const dashboard = group ? group.items[id - 1] : null;

    const [selectedIndex, setSelectedIndex] = useState(1);

    const {
        Component: FilterReport,
        value: selectedReport,
        setValue: setFilterReportValue,
    } = useFilterReport({
        data: dashboard ? [dashboard, ...dashboard.items].map((item) => item) : null,
    });

    useEffect(() => {
        dispatch(reloadBreadCrumbs(true));
        if (tag && id) {
            const reportIndex = initialReport || 0;
            const selected =
                reportIndex > 1 ? (dashboard && dashboard.items ? dashboard.items[reportIndex - 2] : null) : dashboard;
            setFilterReportValue(selected ? { value: selected, name: showName(selected) } : null);
        }
    }, [tag, id, initialReport]);

    useEffect(() => {
        dispatch(showGlobalLoading(loadingLogin));
        // eslint-disable-next-line
    }, [loadingLogin]);

    useEffect(() => {
        if (!selectedReport) {
            const reportIndex = initialReport || 0;
            const selected =
                reportIndex > 1 ? (dashboard && dashboard.items ? dashboard.items[reportIndex - 2] : null) : dashboard;
            setFilterReportValue(selected ? { value: selected, name: showName(selected) } : null);
        }
        // eslint-disable-next-line
    }, [groups]);

    useEffect(() => {
        if (!isCorporate) login();
        dispatch(setSectionStyle("pt-10 px-14 w-5/6 right-0 h-auto section-top bg-gray-100"));
        return () => {
            dispatch(setSectionStyle(null));
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!id && location?.pathname) {
            history(location.pathname + "/1");
        }
    }, [location?.pathname, id]);

    useEffect(() => {
        let _selectedIndex = 0;
        if (dashboard && selectedReport?.value) {
            const arr = [dashboard, ...dashboard.items];
            // eslint-disable-next-line
            arr.forEach((item, index) => {
                if (selectedReport.value.id === item.id) {
                    _selectedIndex = index + 1;
                }
            });
        }
        if (_selectedIndex !== selectedIndex && tag && id) {
            setSelectedIndex(_selectedIndex);
            history("/stats/" + tag + "/" + id + "/" + _selectedIndex);
        }
        // eslint-disable-next-line
    }, [selectedReport]);

    const defaultTitle =
        tag === "cast"
            ? ["cast-statistics"]
            : tag === "wifi"
            ? ["wifi-statistics"]
            : tag === "internal"
            ? ["internal-statistics"]
            : tag === "mobile"
            ? ["mobile-statistics"]
            : tag === "sales"
            ? ["sales-statistics"]
            : ["statistics"];

    const report = selectedReport ? selectedReport.value : null;
    const foundReport = report?.url;
    const reportReady = !loadingLogin && !errorLogin && dashboard;
    const showReport = reportReady && dashboard.items && dashboard.items.length > 0;
    const showFilterProject = isCorporate;

    const showName = (dashboard) => {
        return dashboard ? (dashboard.default ? t(dashboard.name) : dashboard.name) : "";
    };

    if (!tag) {
        let type;
        if (availableStats) {
            const gg = Object.keys(availableStats);
            if (gg && gg.length > 0) {
                type = gg[0].toLowerCase();
            }
        }
        if (type) {
            history("/stats/" + type + "/" + (id || 1));
        }
    }

    return (
        <>
            <UseSectionHeader title={dashboard ? showName(dashboard) : defaultTitle} />
            {errorLogin ? (
                <NotFound title={translate("error-mutation")} desc="" />
            ) : (
                <>
                    {showReport || showFilterProject ? (
                        <div className="flex justify-between">
                            <div className="justify-start">
                                {showReport ? (
                                    <FilterReport className="mr-5" />
                                ) : (
                                    <div className="inline-block mr-5">
                                        <div className="inline-block mr-3">
                                            {t("{{text, capitalize}}", { text: t("report") })}:
                                        </div>
                                        <strong>{showName(dashboard)}</strong>
                                    </div>
                                )}
                                {showFilterProject ? (
                                    <FilterProject
                                        onChange={(v) => {
                                            login({
                                                projectRefs: v?.length
                                                    ? v
                                                    : (isCorporate ? availableProperties : []).map((p) => p?.ref),
                                            });
                                        }}
                                    />
                                ) : null}
                            </div>
                            <div className="justify-end">
                                {reportReady ? <PrintButton iframeRef={refIframe} /> : null}
                            </div>
                        </div>
                    ) : null}
                    {loadingLogin ? null : !foundReport ? (
                        <NotFound title="" desc={translate("no-results")} />
                    ) : (
                        <>
                            <Warning id="warning-chain-designs">
                                {t("The statistics will show results only for properties with Zafiro Chain licences")}
                            </Warning>
                            <KibanaDashboard
                                ref={refIframe}
                                url={report.url}
                                filterBar={true}
                                filterTime={true}
                                className="border-t border-gray-300"
                                style={{
                                    minWidth: "calc(100% + 2.5rem)",
                                    minHeight: "calc(100vh - 75px - " + (showReport ? 12 : 8.5) + "rem)",
                                    margin: "0 -3.5rem 0 -1rem",
                                }}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};

const PrintButton = ({ iframeRef }) => {
    const { t } = useTranslation();

    const print = () => {
        const iframe = iframeRef.current ?? null;
        try {
            if (iframe) {
                let css = `
                    @media print{
                        @page {size: landscape; } 
                        body{
                            width:100%;
                            -webkit-transform: rotate(0deg); 
                            -moz-transform:rotate(0deg);
                            filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
                            zoom: 80%;
                        } 
                    }`;
                const headIframe = iframe.contentWindow.document.getElementsByTagName("head")[0];
                const style = document.createElement("style");

                style.type = "text/css";
                style.media = "print";

                if (style.styleSheet) {
                    style.styleSheet.cssText = css;
                } else {
                    style.appendChild(document.createTextNode(css));
                }

                headIframe.appendChild(style);

                const iframeWindow = iframe.contentWindow || iframe;
                iframe.focus();
                iframeWindow.print();
            }
        } catch (e) {
            console.error(e);
            alert("Cross origin conflict");
        }
        return false;
    };

    return (
        <UseButton
            buttonName={t("print")}
            buttonNoTranslate={true}
            buttonColor="btn-blue-outline"
            style={{ marginTop: "-1rem" }}
            adjust="px-8"
            action={print}
        />
    );
};

export default Analytics;
