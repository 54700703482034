import { WELCOME_SEQUENCES, WELCOME_SEQUENCE } from "constants/editor";

const AddWelcomeScreen = (welcomeData, t, hasLanguageSelection) => {
    const optionData = [];
    WELCOME_SEQUENCES.forEach((type) =>
        optionData.push({
            id: type.type,
            name: t("{{capitalize, capitalize}}", { capitalize: t(type.translate) }),
            disabled: hasLanguageSelection && type.type === WELCOME_SEQUENCE.LANGSELECTION ? true : false,
        })
    );

    //Returns dele schedule modal content

    return {
        id: welcomeData.contentID,
        title: `add-screen`,
        bodyAdjust: "w-3/9 max-h-80",
        buttonsStyle: " right-0 bottom-0",
        executeSectionQuery: true,
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "add",
                style: "blue",
                action: "create-welcome-content",
            },
        ],
        inputs: [
            {
                text: t("add-welcome-screen-text"),
                type: "void",
            },
            {
                name: "welcome-type",
                type: "selectMultipleWithSearch",
                selectPlaceHolder: "select-screen-type",
                optionData: optionData,
                oneSelected: true,
                noSelect: true,
                noSearch: true,
                cStyle: "w-7/12",
            },
            {
                welcome: welcomeData,
                type: "data",
            },
        ],
    };
};

export default AddWelcomeScreen;
