import { newRoute, newRedirect } from "routes/utils";

import ContractedProducts from "components/Section/Info/ContractedProducts";

const sectionID = "information";

const route = newRoute(sectionID, null);
const redirect = newRedirect(sectionID);

const checkNotCorporate = ({ isCorporate }) => !isCorporate;

const routes = [
    route("/", { initial: true, redirect: redirect("/my-account/contracted-products") }),
    route("/my-account", { redirect: redirect("/my-account/contracted-products") }),
    route("/my-account/contracted-products", { section: ContractedProducts, check: checkNotCorporate }),
    route("/legal-matters", { redirect: redirect("/legal-matters/privacy-policy") }),
    route("/legal-matters/privacy-policy", { redirect: "/" }),
];

export default routes;
