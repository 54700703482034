import React from "react";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { LastUpdateToolTip } from "./LastUpdateToolTip";
import { useTranslation } from "react-i18next";

export const NetworkHeader = ({ title, lastUpdateDate, updateTimeMin, isDhcp = false }) => {
    const { t } = useTranslation();
    return (
        <header className="flex justify-between py-6 mx-4">
            <h1 className="font-bold">{t(title)}</h1>
            <h3
                className="text-gray-800"
                id={`${title}-last-update`}
                data-for={`last-update-tooltip-${updateTimeMin}`}
                data-tip
            >
                {capitalizeFirst(t("last-update"))}: {lastUpdateDate}
            </h3>
            <LastUpdateToolTip time={updateTimeMin} isDhcp={isDhcp} />
        </header>
    );
};
