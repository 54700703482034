import i18n from "i18n";
import { VALIDATE_DATA } from "../utils";

const ValidateData = ({ code }) => {
    return {
        title: `${code || ""} - ${i18n.t("validate-data")}`,
        bodyAdjust: "w-3/12",
        executeSectionQuery: true,
        inputs: [
            {
                text: i18n.t("are-you-sure-you-want-to-validate-booking-data"),
                type: "void",
            },
            {
                text: i18n.t("if-any-modifications-is-made"),
                type: "void",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: i18n.t("validate"),
                style: "blue",
                action: VALIDATE_DATA,
            },
        ],
    };
};

export default ValidateData;
