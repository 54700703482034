import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
//Actions
import { showRowForm, setSectionContent } from "../../../actions/sectionActions";
import { showGlobalLoading } from "../../../actions/uiActions";
import {
    cleanTableStore,
    setCount,
    setCountPosition,
    setError,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    cleanActiveFilters,
    setCustomError,
    setRefreshData,
    setHeaderButtons,
    setCheckable,
    setAvailableFilters,
    setTableType,
    setTargetId,
    setPage,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetDesignTableHeaders";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import { useMSQuery } from "../useMSQuery";

//Model
import Tag from "./models/Tag";
import { useTranslation } from "react-i18next";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";

const ListTags = () => {
    const { t } = useTranslation();
    const { currentPage, perPage, filterQuery, sortCriteria, refreshData, targetId } = useSelector(
        (state) => state.table
    );

    //Data
    const sectionName = "list-tags";

    //States
    const [sorting] = useState(`,orderBy:{field:"name",criteria:"asc"}`);

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_TAGS = gql`
        {
            designTags(page: ${currentPage} ${targetId && 1 == 2 ? `targetID:${targetId}` : ""} size: ${perPage}${
        sortCriteria || sorting
    }${filterQuery} ){                
                info{
                    count
                }
                results{
                    id
                    name
                    tag
                    target
                    description                        
                }
            }
        }`;

    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_TAGS, {
        pollInterval: 60000,
    });

    //Listeners
    useEffect(() => {
        dispatch(setLoading(false));
        dispatch(showGlobalLoading(true));
        executeQuery();
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData || targetId) {
            dispatch(showGlobalLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData, targetId]);

    useEffect(() => {
        dispatch(setLoading(false));
        if (!loading && !error && data && data.designTags && networkStatus === 7) {
            const dataItems = arrangeData(data, t);
            const tableHeaders = GetTableHeaders(sectionName);
            dispatch(showGlobalLoading(false));
            if (targetId && data.designTags.info.page) {
                dispatch(setPage(data.designTags.info.page));
                dispatch(setTargetId(null));
            }
            dispatch(setTableType(sectionName));
            dispatch(setSearchable(true));
            dispatch(setHeaders(tableHeaders));
            dispatch(setCheckable(false));
            dispatch(setHeaderButtons(GetTableHeaderButton(sectionName, null, null, t)));
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.designTags.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(showRowForm(false));
            dispatch(setAvailableFilters(GetAvailableFilters(sectionName, extractTypesList(t))));
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            dispatch(setCustomError(t("no available(general)", { name: t("tags") })));
        }
        // eslint-disable-next-line
    }, [data, networkStatus, sortCriteria]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
        }
        // eslint-disable-next-line
    }, [error]);
};

const arrangeData = (data, t) => {
    const tagList = [];

    // eslint-disable-next-line
    data.designTags.results.map((tag) => {
        tagList.push(Tag(tag, t));
    });
    return tagList;
};

const extractTypesList = (t) => {
    let typesArray = [
        { value: t("text").toUpperCase(), id: "TEXT", visible: true },
        { value: "URL", id: "URL", visible: true },
    ];

    return typesArray;
};

export const GetCustomTags = () => {
    //Actions
    const dispatch = useDispatch();

    //API
    const GET_TAGS = gql`
        {
            designTags {
                info {
                    count
                }
                results {
                    id
                    name
                    tag
                    target
                }
            }
        }
    `;

    const [executeQuery, { data, loading, error, networkStatus }] = useMSQuery(GET_TAGS);

    useEffect(() => {
        executeQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!loading && !error && data && data.designTags && networkStatus === 7) {
            dispatch(setSectionContent({ customTags: data.designTags.results }));
        }
        // eslint-disable-next-line
    }, [data]);
};

export default ListTags;
