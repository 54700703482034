import { useState, useEffect } from "react";

import { Session } from "hooks/Utils/Session";

import { useTokenLanguage } from "./auth";

const useUserAuth = ({ onError }) => {
    const storedUser = Session.getLoggedUser();
    const storedImpersonated = Session.getImpersonated();

    const [info, setInfo] = useState(storedUser);
    const [impersonated, setImpersonated] = useState(storedImpersonated);

    const { update: updateToken, token: updatedToken, loading } = useTokenLanguage({ onError });

    const activeInfo = impersonated || info;
    const activeToken = activeInfo?.token;

    useEffect(() => {
        Session.setLoggedUser(info);
    }, [info]);

    useEffect(() => {
        Session.setImpersonated(impersonated);
    }, [impersonated]);

    useEffect(() => {
        if (updatedToken) {
            if (impersonated) {
                setImpersonated((prev) => ({ ...prev, token: updatedToken }));
            } else {
                setInfo((prev) => ({ ...prev, token: updatedToken }));
            }
        }
    }, [updatedToken]);

    return {
        info: activeInfo,
        token: activeToken,
        impersonating: !!impersonated,
        updateLanguage: (lang) => updateToken({ lang, token: activeToken }),
        loading,
        login: (user) => {
            setInfo(user);
        },
        impersonate: (user) => {
            setImpersonated(user);
        },
        stopImpersonation: () => {
            setImpersonated(null);
        },
        logout: () => {
            setInfo(null);
        },
    };
};

export default useUserAuth;
