import React, { useEffect, useState } from "react";
import UseSectionHeader from "../../useSectionHeader";
import MobileSettingsCards from "./MobileSettings/MobileSettingsCards";
import { MobileSettingsAddMobileCard } from "./MobileSettings/MobileSettingsAddMobileAppCard";
import { useSelector, useDispatch } from "react-redux";
import { gql } from "apollo-boost";
import { POLLINTERVAL_15S } from "hooks/Utils/Utils";
import { useLazyQuery } from "react-apollo";
import { getMobileAppQuery } from "./MobileSettings/MobileSettingsUtils";
import { setRefreshContentData } from "actions/sectionActions";
import { showGlobalLoading } from "actions/uiActions";
import { useNavigate, useParams } from "react-router-dom";
import UpdateMobileApps from "../Customers/allProperties/ListMobileApps/UpdateMobileApps";

import { useAuth } from "hooks/Session/auth";

const MobileSettings = () => {
    const navigate = useNavigate();
    const { refreshData } = useSelector((state) => state.sectionContent);
    const [cardInEdition, setCardInEdition] = useState(null);
    const dispatch = useDispatch();
    const { isCorporate } = useAuth();

    const { id } = useParams();

    const breadcrumbs = [
        {
            name: "all-properties",
            translate: true,
            route: `/all-properties`,
        },
        {
            name: "mobile-apps",
            translate: true,
            respectRoute: true,
            route: `/all-properties/mobile-apps`,
        },
    ];

    const GET_DATA_MOBILE_APP = gql(getMobileAppQuery(isCorporate ? `(filter:{id:${id}})` : ""));

    const [executeQuery, { data, error, loading, refetch }] = useLazyQuery(GET_DATA_MOBILE_APP, {
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            refetch();
        }, POLLINTERVAL_15S);

        return () => clearInterval(intervalId);
    }, [refetch]);

    useEffect(() => {
        if (executeQuery) {
            executeQuery();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            refetch();
            dispatch(setRefreshContentData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (data?.mobileApps?.results?.length > 0) {
            dispatch(showGlobalLoading(false));
        } else if (data?.mobileApps?.results.length <= 0) {
            dispatch(showGlobalLoading(false));
        }
        // eslint-disable-next-line
    }, [data]);

    const renderContent = () => {
        if (loading || error) {
            return null;
        } else if (!loading && data?.mobileApps?.results?.length > 0) {
            return (
                <MobileSettingsCards
                    data={data?.mobileApps?.results?.[0]}
                    cardInEdition={cardInEdition}
                    setCardInEdition={setCardInEdition}
                />
            );
        } else if (!loading && data?.mobileApps?.results?.length <= 0 && !isCorporate) {
            return (
                <>
                    <MobileSettingsAddMobileCard />
                    <UpdateMobileApps />
                </>
            );
        }
        if (isCorporate && data?.mobileApps?.results?.length <= 0 && !loading) {
            navigate("/all-properties/mobile-apps");
        }
    };

    return (
        <>
            {isCorporate ? (
                <UseSectionHeader
                    title={[data?.mobileApps?.results[0].name || ""]}
                    customBreadCrumbs={breadcrumbs}
                    navToSection={"/all-properties/mobile-apps"}
                />
            ) : (
                <UseSectionHeader title={["mobile-settings"]} />
            )}
            {renderContent()}
        </>
    );
};

export default MobileSettings;
