import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Actions
import { setColorPickerReference } from "../../../../../actions/designActions";

//Components
import ColourSet from "./ColourSet";
import { getNewDataGroupColor } from "../../../../../hooks/Utils/ColorUtils";
import { setSectionContent } from "../../../../../actions/sectionActions";
import { capitalizeFirst, isDescendant } from "../../../../../hooks/Utils/Utils";
import { THEME_TYPE_DESIGN } from "hooks/Utils/AppearancesUtils";
import { THEME_TYPE_MOBILE } from "hooks/Utils/AppearancesUtils";
import { useTranslation } from "react-i18next";
import UseLoading from "components/Table/useLoading";

export default function ColourSidebar({ themeType, loadingAppearance }) {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    //states
    const { executeQuery, appearanceSelected } = useSelector((state) => state.sectionContent);

    // DEFAUKT COLORS
    const _DEFAULT_COLORS_DESIGN = {
        default: {
            bg: "",
            bgAlpha: 100,
            rgbaBG: ``,
            fg: "#5F8FC1",
            fgAlpha: 100,
            rgbaFG: `rgba(95,143,193,1)`,
        },
        focus: {
            bg: "#5F8FC1",
            bgAlpha: 80,
            rgbaBG: `rgba(95,143,193,0.8)`,
            fg: "#FFFFFF",
            fgAlpha: 100,
            rgbaFG: `rgba(255,255,255,1)`,
        },
        active: {
            bg: "#FFFFFF",
            bgAlpha: 100,
            rgbaBG: `rgba(255,255,255,1)`,
            fg: "#5F8FC1",
            fgAlpha: 100,
            rgbaFG: `rgba(95,143,193,0.8)`,
        },
    };

    const _DEFAULT_COLORS_MOBILE = {
        accent: {
            bg: null,
            bgAlpha: 100,
            rgbaBG: null,
        },
        login: {
            bg: null,
            bgAlpha: 100,
            rgbaBG: null,
        },
    };

    const [colors, setColors] = useState(_DEFAULT_COLORS_DESIGN);

    useEffect(() => {
        if (themeType === THEME_TYPE_DESIGN) {
            setColors(_DEFAULT_COLORS_DESIGN);
        } else if (themeType === THEME_TYPE_MOBILE) {
            setColors(_DEFAULT_COLORS_MOBILE);
        }
    }, [themeType]);

    useEffect(() => {
        if (appearanceSelected && themeType === THEME_TYPE_DESIGN) {
            setColors({
                default: { ...colors.default, ...(appearanceSelected.default || {}) },
                active: { ...colors.active, ...(appearanceSelected.active || {}) },
                focus: { ...colors.focus, ...(appearanceSelected.focus || {}) },
            });
        }

        if (appearanceSelected && themeType === THEME_TYPE_MOBILE) {
            setColors({
                accent: { ...colors.accent, ...(appearanceSelected.accent || {}) },
                login: { ...colors.login, ...(appearanceSelected.login || {}) },
            });
        }
    }, [appearanceSelected]);

    useEffect(() => {
        if (executeQuery?.params && executeQuery.action === "update-appearance-colors") {
            // default-bgColor / default-fgColor / active-bgColor / active-fgColor / focus-bgColor / focus-fgColor en rgba => "rgba(138,36,36,0.69)"
            // focus-border-size para el borde de foco
            let _newFocusBorderSize = appearanceSelected.focusBorderSize;
            const _colourGroup = executeQuery.params.property.split("-");
            let newColors = {
                default: { ...colors.default },
                active: { ...colors.active },
                focus: { ...colors.focus },
            };
            if (executeQuery.params.property === "focus-border-size") {
                _newFocusBorderSize = executeQuery.params.value;
            } else if (_colourGroup[0] === "active") {
                let newActive = getNewDataGroupColor(colors.active, executeQuery.params.value, _colourGroup[1]);
                newColors = { default: { ...colors.default }, active: { ...newActive }, focus: { ...colors.focus } };
            } else if (_colourGroup[0] === "focus") {
                let newFocus = getNewDataGroupColor(colors.focus, executeQuery.params.value, _colourGroup[1]);
                newColors = { default: { ...colors.default }, active: { ...colors.active }, focus: { ...newFocus } };
            } else if (_colourGroup[0] === "mainColour") {
                newColors = {
                    login: { ...colors.login },
                    accent: getNewDataGroupColor(colors.accent, executeQuery.params.value, _colourGroup[1]),
                };
            } else if (_colourGroup[0] === "loginScreen") {
                newColors = {
                    accent: { ...colors.accent },
                    login: getNewDataGroupColor(colors.login, executeQuery.params.value, _colourGroup[1]),
                };
            } else {
                let newDefault = getNewDataGroupColor(colors.default, executeQuery.params.value, _colourGroup[1]);
                newColors = { default: { ...newDefault }, focus: { ...colors.focus }, active: { ...colors.active } };
            }

            setColors(newColors);
            dispatch(
                setSectionContent({
                    appearanceSelected: { ...appearanceSelected, ...newColors, focusBorderSize: _newFocusBorderSize },
                })
            );
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    const handleClickOutside = (e) => {
        const picker_popup = document.querySelector("#pickerContainer");

        if (!isDescendant(picker_popup, e.target) && !e.target.id.includes("picker")) {
            dispatch(setColorPickerReference(null));
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <ul
            id="colour_List"
            className="max-h-80 collapseAnimation min-w-full flex-col   overflow-x-hidden overflow-y-scroll p-4 pl-6"
        >
            {loadingAppearance ? <UseLoading /> : null}
            {themeType === THEME_TYPE_DESIGN ? (
                <TabsDesignColourSidebar colors={colors}></TabsDesignColourSidebar>
            ) : null}
            {themeType === THEME_TYPE_MOBILE ? (
                <>
                    <ColourSet
                        set={"mainColour"}
                        colours={colors.accent}
                        tooltip={t("the-main-colour-should-be-in-contrast-with-white")}
                        avoidForeground={true}
                    />
                    <ColourSet set={"loginScreen"} colours={colors.login} avoidForeground={true} />
                </>
            ) : null}
        </ul>
    );
}

const TabsDesignColourSidebar = ({ colors }) => {
    const { t } = useTranslation();
    const arrTabs = ["default", "active", "focus"];
    const [tabSelected, setTabSelected] = useState(arrTabs[0]);

    return (
        <>
            <div className=" flex">
                {arrTabs.map((tab) => {
                    return (
                        <button
                            id={`${tab}-colour-button`}
                            onClick={() => {
                                setTabSelected(tab);
                            }}
                            className={` focus:outline-none px-10 py-4 border-b-2 ${
                                tabSelected === tab
                                    ? "border-zafiro-600 text-zafiro-600 font-bold "
                                    : " border-transparent"
                            } `}
                        >
                            {capitalizeFirst(t(tab))}
                        </button>
                    );
                })}
            </div>
            <ColourSet set={tabSelected} colours={colors[tabSelected]} hideName={true} />
        </>
    );
};
