import DeleteCategoryModal from "../modals/areasCategory/DeleteCategoryModal";
import TranslateTextModal from "../../Translate/Modals/TranslateTextModal";

const Category = (category, langStrings) => {
    const { dataType, id, name, nameTID, areasAmount, booked, parentCategoryName } = category;
    return {
        dataType: dataType,
        id: id,
        withAction: true,
        parentCategory: parentCategoryName,
        info: [
            {
                name: "text",
                cellContent: "edit-modal",
                link: `/common-zones/zones-configuration/areas`,
                parametersSection: id,
                value: name,
                specialWidth: "w-3/12",
                componentStyle: { maxWidth: "17%" },
                modal: TranslateTextModal("edit-category-name", nameTID, langStrings, "COMMONZONES"),
                icon: {
                    id: id,
                    name: "edit",
                    color: "text-gray-800",
                    itemType: dataType,
                    position: "right",
                    action: "edit-category-name",
                    value: name,
                },
            },
            {
                name: "number",
                cellContent: "value",
                value: areasAmount.toString(),
                translateSufix: true,
                specialWidth: "w-2/12",
            },
            {
                name: "booking-number",
                cellContent: "value",
                value: booked.toString(),
                sufix: "bookings",
                translateSufix: true,
                specialWidth: "w-2/12",
            },
            {
                name: "view-a&a",
                cellContent: "link",
                value: id,
                translate: true,
                parametersSection: id,
                link: `/common-zones/zones-configuration/areas`,
                specialWidth: "w-4/12 text-center",
            },
            {
                name: "delete-category",
                cellContent: "icon",
                value: id,
                evaluate: true,
                isAction: areasAmount === 0 ? true : false,
                specialWidth: "w-1/12",
                icon: {
                    name: "delete",
                    values: id,
                    itemType: dataType,
                    adjust: areasAmount === 0 ? "text-black" : "text-gray-300",
                },
                popover: areasAmount !== 0 ? "cat-must-empty" : false,
                translatePopover: true,
                modal: areasAmount === 0 ? DeleteCategoryModal(category) : false,
            },
        ],
    };
};

export default Category;
