import { gql } from "apollo-boost";

export const GET_FILTERS_DATA = gql`
    {
        rooms: rooms {
            results {
                wingID
                floorID
                floorName
                wingName
            }
        }
    }
`;

export const GET_TABLE_DATA = gql`
    {
        getCCsGuestNetworkInfo(filter: { model: [] }) {
            data {
                ip
                isZafiroDevice
                mac
                model
                name
                ref
                roomID
                roomNumber
                roomTVName
                type
            }
            lastUpdate
        }
    }
`;

export const getTableInfo = (filter = "") => {
    return gql`
    {
        getCCsGuestNetworkInfo(filter: { ${filter} }) {
            data {
                ip
                isZafiroDevice
                mac
                model
                name
                ref
                roomID
                roomNumber
                roomTVName
                type
            }
            lastUpdate
        }
    }
    `;
};

export const UPDATE_CC_INFORMATION = gql`
    mutation {
        syncCCUserNetwork {
            error
            ok
        }
    }
`;

export const CC_SYNC_ACTION_PENDING = gql`
    {
        getCCSyncActionPending {
            isPending
        }
    }
`;

export const GET_CCS_GUEST_NETWORK_FILTER_INFO = gql`
    {
        getCCsGuestNetworkFilterInfo {
            models {
                type
                models
            }
            ownership
        }
    }
`;
