import Table from "../../ZafiroTable";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useModal } from "components/Modal";
import Button from "components/Button";
import { Session } from "../../../hooks/Utils/Session";
import Select from "components/Select";
import { escapeSpecialChars } from "../../../hooks/Utils/Utils";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate as useHistory } from "react-router-dom";
import TextInput from "components/TextInput";
import Loading from "components/Loading";

const accessesQuery = gql`
    {
        accesses {
            results {
                name
                category
            }
        }
    }
`;

const UsersAssignedModal = ({ role }) => {
    const { t } = useTranslation();

    const [searchInput, setSearchInput] = useState("");

    const usersQuery = gql`
        query getusers($roles: [Int64]) {
            usersGlobal(filter: { roles: $roles }) {
                results {
                    id
                    fullName
                    properties {
                        name
                        id
                    }
                    email
                }
            }
        }
    `;

    const {
        loading,
        error,
        data = { usersGlobal: { results: [] } },
    } = useQuery(usersQuery, {
        fetchPolicy: "network-only",
        errorPolicy: "all",
        variables: { roles: [role.id] },
    });

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);

    const filteredUsers = (data?.usersGlobal?.results || [])?.filter(
        (x) => !searchInput?.length || x.fullName.startsWith(searchInput) || x.email.startsWith(searchInput)
    );

    return (
        <div style={{ minWidth: "560px" }}>
            <div className="flex mb-4">
                <div className="w-1/2 flex">
                    <div className="w-full mr-8 relative">
                        <TextInput
                            disabled={loading}
                            onInput={(value) => setSearchInput(value)}
                            placeholder={t("search")}
                        />
                        <span className="field-icon icon-search"></span>
                    </div>
                </div>
                <div className="w-1/2 mt-auto ml-auto block text-right">
                    {filteredUsers.length} {t("users")}
                </div>
            </div>

            <div
                style={{ minHeight: "21.45rem", maxHeight: "21.45rem", overflowY: "scroll" }}
                className=" border rounded border-gray-200"
            >
                {loading ? <Loading /> : null}

                {searchInput?.length && !loading && !filteredUsers?.length ? (
                    <div className="text-center text-gray-700 p-10">{t("results-not-found")}</div>
                ) : null}

                {filteredUsers
                    ? filteredUsers.map((user) => {
                          return (
                              <div
                                  key={user.fullName}
                                  className=" flex justify-between border-b mx-4 border-gray-200 py-4 "
                              >
                                  <span className="block">{user.email}</span>
                                  <span
                                      className="block text-blue-600 font-bold"
                                      data-for={"default-tooltip"}
                                      data-tip={user.properties.map((x) => x.name).join("\n")}
                                  >
                                      {user.properties.length} {t("properties")}
                                  </span>
                              </div>
                          );
                      })
                    : null}
            </div>
        </div>
    );
};

const UpdateRoleModal = ({ role, close, refresh }) => {
    const [updateData, setUpdateData] = useState({ name: role.name, accesses: role.accesses.map((x) => x.name) });
    const {
        loading,
        called,
        error,
        data = { accesses: { results: [] } },
    } = useQuery(accessesQuery, { fetchPolicy: "network-only", errorPolicy: "all" });
    const [initialAccesses, setInitialAccesses] = useState(role.accesses.map((x) => x.name));
    const { t } = useTranslation();
    const [groupedAccesses, setGroupedAccesses] = useState({});
    const [accesses, setAccesses] = useState([]);
    const { langStrings } = useSelector((state) => state.ui);
    const [nameError, setNameError] = useState(false);
    const [dropdownError, setDropdownError] = useState(false);

    useEffect(() => {
        if (called && !loading && !error && data.accesses) {
            setGroupedAccesses(
                data.accesses.results.reduce((acc, cur) => {
                    acc[cur.category] = acc[cur.category] ? acc[cur.category].concat(cur.name) : [cur.name];
                    return acc;
                }, {})
            );
        }
    }, [loading, error, data]);
    useEffect(() => {
        setAccesses(
            Object.entries(groupedAccesses)
                .map(([group, accesses]) => {
                    return {
                        label: t(group),
                        options: accesses
                            .map((x) => ({ label: t(x), value: x }))
                            .sort((a, b) => a.label.localeCompare(b.label)),
                    };
                })
                .sort((a, b) => a.label.localeCompare(b.label))
        );
    }, [groupedAccesses]);
    const updateRoleMutation = gql`
    mutation{
        updatePredefinedRole(
            id:  ${role.id}
            name: "${escapeSpecialChars(updateData.name)}"
            accesses: ["${(updateData.accesses ?? [])?.join('","')}"]
       ){ error ok }
    }`;
    const [mutate] = useMutation(updateRoleMutation);

    const onSubmit = () => {
        let toast_shown = false;
        if (!updateData.name?.length || !updateData.accesses?.length) {
            if (!updateData.name?.length) {
                setNameError(true);
                toast.error(t("errors-in-red"));
                toast_shown = true;
            } else {
                setNameError(false);
            }
            if (!updateData.accesses?.length) {
                setDropdownError(true);
                if (!toast_shown) {
                    toast.error(t("errors-in-red"));
                }
            } else {
                setDropdownError(false);
            }
        } else {
            mutate()
                .then(() => {
                    setNameError(false);
                    setDropdownError(false);
                    refresh();
                    close();
                    toast.success(langStrings["operation-successful"]);
                })
                .catch(() => toast.error(langStrings["mutation-error"]));
        }
    };

    return (
        <div style={{ minWidth: "560px" }}>
            <div className="mt-4">
                <div className="pb-5 ">
                    <div className="block">{t("add-role-desc")}</div>
                </div>
                <div className="pb-5">
                    <span className="block pb-2 first-capital">{t("name")} *</span>
                    <TextInput
                        required={true}
                        value={updateData.name}
                        error={nameError}
                        onChange={(value) => setUpdateData((prev) => ({ ...prev, name: value }))}
                        placeholder={t("select-an-option")}
                    />
                </div>
                <div className="pb-5">
                    <span className="block pb-2 first-capital">{t("accesses")} *</span>
                    {accesses.length ? (
                        <Select
                            id={"accesses-select"}
                            placeholder={t("select-accesses")}
                            multiple={true}
                            error={dropdownError}
                            search={true}
                            value={initialAccesses}
                            onChange={(selected) => {
                                return setUpdateData((prev) => ({ ...prev, accesses: selected }));
                            }}
                            options={accesses}
                        />
                    ) : (
                        <Select
                            id={"accesses-select"}
                            placeholder={t("select-accesses")}
                            multiple={true}
                            error={dropdownError}
                            search={false}
                            value={[]}
                            options={[]}
                        />
                    )}
                </div>
            </div>
            <div id="modal-buttons" className="pt-8 text-center flex justify-end">
                <div onClick={close} data-action="close" id="cancel-modal-button">
                    <button id="modal-button-cancel" className="btn-white p-4 rounded btn-blue-outline">
                        <div className="first-capital">{t("cancel")}</div>
                    </button>
                </div>
                <div onClick={onSubmit} className="ml-4" data-action="edit-role" id="edit-role-modal-button">
                    <button id="modal-button-edit-role" className="btn-blue p-4 rounded">
                        <div className="first-capital">{t("edit-role")}</div>
                    </button>
                </div>
            </div>
        </div>
    );
};

const CreateRoleModal = ({ close, refresh }) => {
    const { t } = useTranslation();
    const [createData, setCreateData] = useState({ name: "", accesses: [] });
    const [submitted, setSubmitted] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [dropdownError, setDropdownError] = useState(false);

    const [getAccesses, { loading, error, called, data = { accesses: { results: [] } } }] = useLazyQuery(
        accessesQuery,
        {
            fetchPolicy: "network-only",
            errorPolicy: "all",
        }
    );
    const [accessesOptions, setAccessesOptions] = useState({});
    const { langStrings } = useSelector((state) => state.ui);

    useEffect(() => {
        getAccesses();
    }, []);

    useEffect(() => {
        if (called && !loading && !error && data.accesses) {
            const groupedAccesses = data.accesses.results.reduce((acc, cur) => {
                acc[cur.category] = acc[cur.category] ? acc[cur.category].concat(cur.name) : [cur.name];
                return acc;
            }, {});
            setAccessesOptions(
                Object.entries(groupedAccesses)
                    .map(([group, accesses]) => ({
                        label: t(group),
                        options: accesses
                            .map((x) => ({ label: t(x), value: x }))
                            .sort((a, b) => a.label.localeCompare(b.label)),
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))
            );
        }
    }, [called, loading, error, data]);

    const createRoleMutation = gql`
    mutation{
        createPredefinedRole(
            name: "${escapeSpecialChars(createData.name)}"
            accesses: ["${(createData.accesses || []).join('","')}"]
       ){ error ok }
    }`;
    const [createRole] = useMutation(createRoleMutation);
    const onSubmit = () => {
        let toast_shown = false;
        if (!createData.name?.length || !createData.accesses?.length) {
            if (!createData.name?.length) {
                setNameError(true);
                toast.error(t("errors-in-red"));
                toast_shown = true;
            } else {
                setNameError(false);
            }
            if (!createData.accesses?.length) {
                setDropdownError(true);
                if (!toast_shown) {
                    toast.error(t("errors-in-red"));
                }
            } else {
                setDropdownError(false);
            }
        } else {
            createRole()
                .then(() => {
                    setSubmitted(true);
                    refresh();
                    close();
                    toast.success(langStrings["operation-successful"]);
                })
                .catch(() => toast.error(langStrings["mutation-error"]))
                .finally(() => setSubmitted(true));
        }
    };

    return (
        <div style={{ minWidth: "560px" }}>
            <div className="mt-4">
                <div className="pb-5 ">
                    <div className="block">{t("add-role-desc")}</div>
                </div>
                <div className="pb-5">
                    <span className="block pb-2 first-capital">{t("name")} *</span>
                    <TextInput
                        required={true}
                        error={nameError}
                        onChange={(value) => setCreateData((prev) => ({ ...prev, name: value }))}
                        placeholder={t("select-an-option")}
                    />
                </div>
                <div className="pb-5">
                    <span className="block pb-2 first-capital">{t("accesses")} *</span>
                    {accessesOptions.length ? (
                        <Select
                            id={"accesses-select"}
                            placeholder={t("select-accesses")}
                            multiple={true}
                            search={true}
                            value={createData.accesses}
                            error={dropdownError}
                            onChange={(selected) => setCreateData((prev) => ({ ...prev, accesses: selected }))}
                            options={accessesOptions}
                        />
                    ) : (
                        <Select
                            id={"accesses-select"}
                            placeholder={t("select-accesses")}
                            multiple={true}
                            search={false}
                            value={createData.accesses}
                            options={[]}
                        />
                    )}
                </div>
            </div>
            <div id="modal-buttons" className="pt-8 text-center flex justify-end">
                <div onClick={close} data-action="close" id="cancel-modal-button">
                    <button id="modal-button-cancel" className="p-4 rounded font-bold text-gray-900 btn-white">
                        <div className="first-capital">{t("cancel")}</div>
                    </button>
                </div>
                <div onClick={onSubmit} className="ml-4" data-action="edit-role" id="edit-role-modal-button">
                    <button id="modal-button-edit-role" className="btn-blue p-4 rounded">
                        <div className="first-capital">{t("add")}</div>
                    </button>
                </div>
            </div>
        </div>
    );
};
const DeleteRoleModal = ({ close, refresh, role }) => {
    const { t } = useTranslation();
    const { langStrings } = useSelector((state) => state.ui);
    const deleteRoleMutation = gql`
    mutation{
        delete${role.type === "PREDEFINED" ? "Predefined" : "Customised"}Role(
            id: ${role.id}
       ){ error ok }
    }`;
    const [deleteRole] = useMutation(deleteRoleMutation);
    return (
        <div style={{ minWidth: "560px" }}>
            <div>{t("delete-role-confirm")}</div>
            <div id="modal-buttons" className="pt-8 text-center flex justify-end">
                <div onClick={close} data-action="close" id="cancel-modal-button">
                    <button id="modal-button-cancel" className="p-4 rounded font-bold text-gray-900 btn-white">
                        <div className="first-capital">{t("cancel")}</div>
                    </button>
                </div>
                <div
                    onClick={() =>
                        deleteRole()
                            .then(() => {
                                refresh();
                                close();
                                toast.success(langStrings["operation-successful"]);
                            })
                            .catch(() => toast.error(langStrings["mutation-error"]))
                    }
                    className="ml-4"
                    data-action="edit-role"
                    id="edit-role-modal-button"
                >
                    <button id="modal-button-edit-role" className="btn-red p-4 rounded">
                        <div className="first-capital">{t("delete")}</div>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default () => {
    const { open, close } = useModal();
    const history = useHistory();
    let [modalCrud, setModalCrud] = useState(null);
    let [accesses, setAccesses] = useState([]);
    const { langStrings } = useSelector((state) => state.ui);
    const isSuperUser = Session.isSuperUser();
    const duplicateRoleMutation = gql`
        mutation duplicatePredefinedRole($id: Int64!) {
            duplicatePredefinedRole(id: $id) {
                error
                ok
                __typename
            }
        }
    `;
    const query = gql`
        {
            roles(filter: { type: PREDEFINED }, size: 999) {
                results {
                    id
                    name
                    type
                    users
                    accesses {
                        name
                        category
                    }
                }
            }
            accesses(orderBy: { field: "category", criteria: "asc" }) {
                results {
                    id: name
                    name
                    category
                }
            }
        }
    `;
    const [duplicateRole] = useMutation(duplicateRoleMutation);
    const { t } = useTranslation();
    const closeModal = () => close() && setModalCrud(null);
    const [executeQuery, { loading, error, data = { roles: { results: [] } } }] = useLazyQuery(query, {
        fetchPolicy: "network-only",
        errorPolicy: "all",
    });
    useEffect(() => {
        if (data?.accesses?.results) {
            setAccesses(data.accesses.results);
        }
    }, [data]);
    useEffect(() => {
        executeQuery();
    }, []);
    useEffect(() => {
        if (modalCrud?.type === "read") {
            open({
                id: "assigned-users-modal",
                title: t("assigned-users"),
                children: <UsersAssignedModal role={modalCrud.role} />,
                footer: (
                    <Button id="btn-exit" design="blue" onClick={closeModal}>
                        {t("close")}
                    </Button>
                ),
            });
        } else if (modalCrud?.type === "update") {
            open({
                id: "update-role-modal",
                title: `${modalCrud.role.name} - ${t("edit-role")}`,
                children: <UpdateRoleModal refresh={executeQuery} close={closeModal} role={modalCrud.role} />,
            });
        } else if (modalCrud?.type === "delete") {
            open({
                id: "delete-role-modal",
                title: `${modalCrud.role.name} - ${t("delete-role")}`,
                children: <DeleteRoleModal refresh={executeQuery} close={closeModal} role={modalCrud.role} />,
            });
        }
        return closeModal;
    }, [modalCrud]);

    const filterOptions = Object.entries(Object.groupBy(accesses, (item) => item.category))
        .map(([category, options]) => ({
            id: category,
            label: t(category),
            options: options
                .map((x) => ({ id: x.name, value: x.name, label: t(x.name) }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    return (
        <div>
            <div style={{ height: "72px" }} className=" w-full flex h-48 shadow-sm bg-white">
                <button
                    onClick={() => history(-1)}
                    style={{ width: "10%" }}
                    className=" focus:outline-none flex items-center justify-center border-r border-gray-200 text-gray-800  "
                >
                    <span className=" icon icon-chevron-left mt-1 pr-2 text-xl "></span>
                    <span className=" text-lg first-capital">{t("back")}</span>
                </button>
                <div style={{ width: "90%" }} className=" px-6 h-full font-bold text-lg flex items-center">
                    {t("manage-of-predefined-roles")}
                </div>
            </div>
            <div className={"my-8 ml-10 text-lg flex"}>
                <span className={"icon-warning mr-2"} style={{ fontSize: "22px", color: "#FF991F" }}></span>
                {t("predefined-roles-warning")}
            </div>
            <div className="px-10">
                <Table
                    id={"predefined-roles-table"}
                    search={true}
                    showCount={true}
                    forceInlineFilters={true}
                    paginate={true}
                    loading={loading}
                    filters={[
                        {
                            id: "filter-by-accesses",
                            title: t("accesses"),
                            multiple: true,
                            selectGroup: true,
                            options: filterOptions,
                            onFilter: (values, row) =>
                                row?.accesses?.some((selectedValue) => values.some((value) => value === selectedValue)),
                        },
                    ]}
                    className="w-full main-container"
                    topRightCorner={
                        isSuperUser ? (
                            <Button
                                onClick={() =>
                                    open({
                                        id: "add-role-modal",
                                        title: t("add-role"),
                                        children: <CreateRoleModal refresh={executeQuery} close={closeModal} />,
                                    })
                                }
                                id={"new-role-button"}
                                design={"blue"}
                            >
                                {t("add-role")}
                            </Button>
                        ) : null
                    }
                    cols={["one", "two", "three"]}
                    header={{
                        one: {
                            title: t("name"),
                            sortable: true,
                        },
                        two: {
                            title: t("accesses"),
                            sortable: true,
                        },
                        three: {
                            title: t("assigned"),
                            sortable: true,
                            align: "center",
                        },
                    }}
                    rows={data.roles.results.map((x) => ({
                        rowConfig: {
                            actions: [
                                {
                                    label: t("edit-role"),
                                    onClick: () => setModalCrud({ type: "update", role: x }),
                                },
                                {
                                    label: t("duplicate"),
                                    onClick: () =>
                                        duplicateRole({ variables: { id: x.id } }).then(
                                            () => toast.success(langStrings["operation-successful"]) && executeQuery()
                                        ),
                                },
                                {
                                    label: t("delete-role"),
                                    disabled: x.users > 0,
                                    tooltip: x.users > 0 ? t("role-assigned") : null,
                                    onClick: () => x.users === 0 && setModalCrud({ type: "delete", role: x }),
                                },
                            ],
                        },
                        one: (
                            <span searchValue={x.name} sortvalue={x.name}>
                                {x.name}
                            </span>
                        ),
                        two: (
                            <div sortvalue={x.accesses.length} className={"gap-x-5 whitespace-nowrap w-full"}>
                                {x.accesses.slice(0, 7).map((x) => (
                                    <div
                                        key={x.name}
                                        className={
                                            "rounded py-1 px-4 m-1 text-white inline-block bg-blue-100 first-capital"
                                        }
                                    >
                                        {t(x.name)}
                                    </div>
                                ))}
                                {x.accesses.length > 7 ? (
                                    <span
                                        className={"font-bold text-zafiro-600 ml-1 whitespace-pre"}
                                        data-for="dangerous-html-tooltip"
                                        data-tip={x.accesses
                                            .slice(7)
                                            .map((x) => t(x.name))
                                            .join("\n")}
                                    >{`+ ${x.accesses.length - 7}`}</span>
                                ) : null}
                            </div>
                        ),
                        three: (
                            <div
                                sortvalue={x.users}
                                className={"text-blue-600 font-bold cursor-pointer"}
                                onClick={() => setModalCrud({ type: "read", role: x })}
                            >
                                {x.users} {t("users")}
                            </div>
                        ),
                        accesses: x.accesses.map((x) => x.name),
                    }))}
                />
            </div>
        </div>
    );
};
