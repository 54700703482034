import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-apollo";
import { setExecuteQuery } from "actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "actions/uiActions";
import { useNavigate as useHistory } from "react-router-dom";
import { setLoading } from "actions/tableActions";
import { toast } from "react-toastify";
import { setRefreshContentData } from "actions/sectionActions";
import { gql } from "apollo-boost";
import { useTranslation } from "react-i18next";
import { removeApolloCacheKeys } from "hooks/Utils/Utils";
import { setRefreshData } from "actions/tableActions";
import { cleanAction } from "actions/globalActions";
import {
    BUILD_STAY_GUEST_TO_STAY_BOOKING_MUTATION,
    UPDATE_BOOKING_INFORMATION,
    UPDATE_STAY_GUEST_TO_STAY_BOOKING,
    BUILD_UPDATE_GUEST_MUTATION,
    VALIDATE_DATA,
    RESEND_PRE_CHECK_IN,
    REQUEST_MODIFICATION,
    mapRooms,
    verifyGuest,
} from "./utils";
import { useParams } from "react-router-dom";

const UpdateReservationDetails = ({ client }) => {
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { id } = useParams();
    const values = useSelector((state) => state.action.values);
    const { inputs } = useSelector((state) => state.ui.modalContent);
    const { t } = useTranslation();
    const history = useHistory();
    const initialValue = "mutation{checkIn}";
    const [mutation, setMutation] = useState(`
    mutation {
        createHotspot(name: "test") {
          ok
          error
          id
        }
      }`);
    const [secondaryMutation, setSecondaryMutation] = useState(initialValue);
    const [executeMutationConfirmation, setExecuteMutationConfirmation] = useState(false);
    const dispatch = useDispatch();

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const SECONDARY_MUTATION_OBJECT = gql`
        ${secondaryMutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));

            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            dispatch(setLoading(false));
            toast.error(err.message);
        },
    });

    const [executeSecondaryMutation] = useMutation(SECONDARY_MUTATION_OBJECT, {
        errorPolicy: "all",
        onCompleted(val) {
            let elements = [];
            let index = 0;
            while (val[`addStayGuestToStayBooking${index}`]) {
                elements.push(val[`addStayGuestToStayBooking${index}`]?.id);
                index++;
            }
            executeQuery.multipleMutations.push("checkInFromStayBooking");
            executeQuery.closeModal = true;
            const { rooms, mainGuestID } = mapRooms(inputs[1].data.stayBookings.numberOfRooms, values, elements);
            setMutation(`mutation {
                checkInFromStayBooking(stayBookingID: ${id}, mainGuestID: ${mainGuestID}, rooms:[${rooms}]){
                  error
                  id
                  ok
                }
              }`);
            setExecuteMutationConfirmation(true);
        },
        onError(err) {
            let errorMsg = err.message;
            dispatch(showGlobalLoading(false));
            toast.error(errorMsg);
        },
    });
    useEffect(() => {
        if (executeMutationConfirmation && mutation != initialValue) {
            executeMutation();
        }
    }, [executeMutationConfirmation, mutation]);

    useEffect(() => {
        if (secondaryMutation != initialValue) {
            executeSecondaryMutation();
        }
    }, [secondaryMutation]);
    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.multipleMutations = [];
            executeQuery.redirectOnSuccessUrl = null;
            execute = true;
            switch (executeQuery.action) {
                case UPDATE_BOOKING_INFORMATION:
                    const { data } = executeQuery.params;

                    const deleteGuestMutations =
                        data?.IDS_EXTRAS_GUESTS?.length > 0
                            ? data.IDS_EXTRAS_GUESTS.map(
                                  (id, index) => `deleteGuest${index}: deleteGuest(id: ${id}){ error id ok }`
                              ).join("\n")
                            : "";

                    setMutation(`
                            mutation{
                                updateStayBooking(
                                    numberOfRooms: ${data.PERSONAL_INFORMATION.numberOfRooms},
                                    numberOfGuests: ${data.PERSONAL_INFORMATION.numberOfGuests},
                                    arrivalDate: "${data.PERSONAL_INFORMATION.arrivalDate}",
                                     ${
                                         data.PERSONAL_INFORMATION.departureDate
                                             ? `departureDate:"${data.PERSONAL_INFORMATION.departureDate}",`
                                             : ``
                                     }
                                    observations: "${data.OBSERVATIONS || ""}",
                                    stayHolderName:"${data.BOOKING_HOLDER.name}"
                                    stayHolderSurname:"${data.BOOKING_HOLDER.surname}"
                                    stayHolderEmail:"${data.BOOKING_HOLDER.email}"
                                    id: ${id}
                                ){
                                    error
                                    id
                                    ok
                                }
                                ${deleteGuestMutations}
                            }`);

                    executeQuery.multipleMutations.push("updateStayBooking");
                    executeQuery.multipleMutations.push("updateGuest");

                    if (data?.IDS_EXTRAS_GUESTS?.length > 0) {
                        data.IDS_EXTRAS_GUESTS.forEach((index) => {
                            executeQuery.multipleMutations.push(`deleteGuest${index}`);
                        });
                    }
                    break;
                case UPDATE_STAY_GUEST_TO_STAY_BOOKING:
                    const { guests } = executeQuery.params;
                    let mutation = "mutation {";
                    guests.forEach((guest, index) => {
                        if (guest.id) {
                            mutation += BUILD_UPDATE_GUEST_MUTATION(guest, index);
                            executeQuery.multipleMutations.push(`updateGuest${index}`);
                        } else {
                            mutation += BUILD_STAY_GUEST_TO_STAY_BOOKING_MUTATION(guest, index, id);
                            executeQuery.multipleMutations.push(`addStayGuestToStayBooking${index}`);
                        }
                    });
                    dispatch(showGlobalLoading(true));
                    mutation += "}";
                    setMutation(mutation);
                    break;
                case VALIDATE_DATA:
                    setMutation(`mutation{
                        stayBookingValidateData(
                            id:${id}
                        ){
                            error
                            ok
                        }
                        updateStayBooking(
                            id: ${id},
                            pendingChangesSend: false,
                        ){
                            error
                        }
                    }`);
                    executeQuery.multipleMutations.push(`stayBookingValidateData`, `updateStayBooking`);
                    break;
                case RESEND_PRE_CHECK_IN:
                    setMutation(`mutation{
                        sendStayBookingReminder(
                            id:${id}
                        ){
                            error
                            ok
                        }
                        updateStayBooking(
                            id: ${id},
                            pendingChangesSend: false,
                        ){
                            error
                            ok    
                        }
                    }`);
                    executeQuery.multipleMutations.push(`sendStayBookingReminder`, `updateStayBooking`);
                    dispatch(showGlobalLoading(true));
                    break;
                case REQUEST_MODIFICATION:
                    setMutation(`mutation{
                        sendStayBookingCorrectionRequest(
                            id:${id},
                            comments: """${values["request-modification-comments"]}"""
                        ){
                            error
                            ok
                        }
                        updateStayBooking(
                            id: ${id},
                            pendingChangesSend: false,
                        ){
                            error
                            ok    
                        }
                    }`);
                    executeQuery.multipleMutations.push(`sendStayBookingCorrectionRequest`, `updateStayBooking`);
                    dispatch(showGlobalLoading(true));
                    break;
                case "execute-checkin":
                    const verifyMutation = verifyGuest(
                        inputs[1].data.stayBookings.id,
                        inputs[1].data.stayBookings.numberOfRooms,
                        values
                    );
                    if (verifyMutation.ok) {
                        setMutation(`mutation {
                                checkInFromStayBooking(stayBookingID: ${id}, mainGuestID: ${
                            values[`modal-checkin-main-guest-select`]
                        }, rooms:[${mapRooms(inputs[1].data.stayBookings.numberOfRooms, values, []).rooms}]){
                                  error
                                  id
                                  ok
                                }
                                updateStayBooking(
                                    pendingChangesSend: false,
                                    id: ${id}
                                ){
                                    error
                                    ok    
                                }
                              }`);

                        executeQuery.multipleMutations.push("checkInFromStayBooking", `updateStayBooking`);
                        executeQuery.closeModal = true;
                    } else {
                        setSecondaryMutation(`mutation {
                                ${verifyMutation.mutation}
                            }`);
                        execute = false;
                        executeQuery.closeModal = false;
                    }
                    dispatch(showGlobalLoading(true));

                    break;
                default:
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (!showGlobalLoading) dispatch(setLoading(true));
            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        const arrayData = data ? Object.entries(data).map(([key, value]) => ({ key, ...value })) : [];

        const hasMultipleMutationsAtLeastOneOk = executeQuery?.multipleMutations
            ? executeQuery?.multipleMutations.some((key) => {
                  return arrayData.find((item) => item.key === key && item.ok);
              })
            : false;

        const errorMessages = arrayData
            .filter((item) => item.error && item.error.trim() !== "")
            .map((item) => item.error);

        errorMessages.forEach((message) => {
            return toast.error(message);
        });

        if (arrayData.length > 0 && executeQuery && hasMultipleMutationsAtLeastOneOk) {
            toast.success(t("operation-successful"));
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }

            if (executeQuery.action !== UPDATE_STAY_GUEST_TO_STAY_BOOKING) {
                dispatch(showGlobalLoading(false));
            }
            if (executeQuery.multipleMutations.some((element) => element == "checkInFromStayBooking")) {
                history(-1);
            }
            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));

            if (executeQuery.cacheKeyToDelete) {
                removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
            }
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default UpdateReservationDetails;
