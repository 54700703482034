import React from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { setActionInput } from "../../actions/globalActions";

const UseInputSelectOptions = ({ inputData }) => {
    //Store data
    const { actionName, actionData } = useSelector((state) => state.action);

    //Actions
    const dispatch = useDispatch();

    const handleClick = (e) => {
        dispatch(setActionInput({ hour: e.currentTarget.dataset.id }));
    };

    return (
        <div className="t-options-container overflow-y-auto p-2 max-h-64">
            {actionName !== "area-move-category"
                ? inputData.values.map((value, index) => (
                      <div
                          className="h-10 w-full hover:bg-gray-100 w-56"
                          onClick={handleClick}
                          data-id={value}
                          key={index}
                          value={value}
                      >
                          <div
                              className={`t-select-option p-2 ${
                                  actionData && actionData.hour === value ? "font-bold text-blue-100" : ""
                              }`}
                              name={value}
                              value={value}
                          >
                              {value ? value : null}
                          </div>
                      </div>
                  ))
                : null}
        </div>
    );
};

export default UseInputSelectOptions;
