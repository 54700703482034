import React, { useState, useEffect } from "react";

import { CalcPages } from "../../../hooks/Utils/DataCalc/CalcPages";
import { useTranslation } from "react-i18next";

const UseProjectPagination = ({ total, perPage = 1, page = 1, setPage }) => {
    const { t } = useTranslation();

    //Data
    const maxPage = Math.ceil(total / perPage);
    const pages = CalcPages(maxPage, page);

    //States
    const [nextVisibility, setNextVisibility] = useState("");
    const [prevVisibility, setPrevVisibility] = useState("");

    //Actions
    const changePage = (page) => {
        if (setPage) {
            setPage(page);
        }
    };

    // Listeners
    useEffect(() => {
        setPrevVisibility(getPrevVisibility(page));
        setNextVisibility(getNextVisibility(page, maxPage));
        // eslint-disable-next-line
    }, [page]);

    return (
        <div className="projects-pagination-container fist-capital">
            {total <= perPage ? (
                <></>
            ) : (
                <>
                    <div className={`t-pagination-item ${prevVisibility === "0" ? "hidden" : ""}`}>
                        <div className="inline">
                            <div className="flex items-center" onClick={() => changePage(page - 1)}>
                                <span className="t-pagination-left icon-chevron-left"></span>
                                <span className="first-capital">{t("previous")}</span>
                            </div>
                        </div>
                    </div>

                    {pages.map((p, index) => (
                        <div
                            className="t-pagination-number"
                            onClick={p === "..." ? null : () => changePage(p)}
                            key={index}
                        >
                            <div className="inline">
                                {p === page ? (
                                    <span className="t-pagination-active" data-id={p}>
                                        {p}
                                    </span>
                                ) : null}
                                {p === "..." ? <span>{p}</span> : null}
                                {p !== page && p !== "..." ? (
                                    <span className="t-pagination-index" onClick={() => changePage(p)}>
                                        {p}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    ))}

                    <div className={`t-pagination-item ${nextVisibility === "0" ? "hidden" : ""}`}>
                        <div className="inline">
                            <div className="flex items-center" onClick={() => changePage(page + 1)}>
                                <span className="first-capital">{t("next")}</span>
                                <span className="t-pagination-right icon-chevron-right"></span>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export const getPrevVisibility = (displayPage) => {
    let res;
    if (displayPage > 1) {
        res = "100";
    } else {
        res = "0";
    }
    return res;
};

export const getNextVisibility = (displayPage, maxPage) => {
    let res;
    if (displayPage < maxPage && maxPage > 1) {
        res = "100";
    } else {
        res = "0";
    }
    return res;
};

export default UseProjectPagination;
