import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate as useHistory } from "react-router-dom";

//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "@apollo/react-hoc";
//Actions
import { showGlobalLoading, closeModal, setModalContent } from "../../../actions/uiActions";

import { setRefreshContentData, setExecuteQuery } from "../../../actions/sectionActions";
import { cleanAction, prepareUpdates } from "../../../actions/globalActions";
import { setLoading, setTargetId } from "../../../actions/tableActions";
import {
    addGridItem,
    removeGridItem,
    updateGridItemPos,
    updateGridItemProperty,
    updateGridItemActions,
    updateGridItemStyle,
    setScreenContentStyle,
    duplicateGridItem,
    cloningGridItem,
    updateScreenProperty,
    updateContainerItemPos,
    setCurrentDevice,
    setCurrentScreenData,
    setEditingMenu,
    setWidgetToolbarVisible,
    setItemSelected,
    cleanLayout,
    setDesignToolbarVisible,
    setGridLayout,
} from "../../../actions/designActions";
import { escapeSpecialChars, removeApolloCacheKeys, dateString, capitalizeFirst } from "../../../hooks/Utils/Utils";
import {
    WidgetInitialPosition,
    CalulateWidgetData,
    GetScreenDevices,
    GetScreenDeviceSizes,
} from "../../../hooks/Utils/Widgets";
//Utils
import { toast } from "react-toastify";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { SCREEN, WELCOME_SEQUENCE } from "constants/editor";
import { getNewNameByType } from "../../Utils/Design/EditorUtils";
import { setWelcomeMutation, getContentByWelcomeTypeScreen } from "./UpdateWelcome";
import { LATO_FONT, textAlignment } from "../../Utils/Design/FontUtils";
import { SALES_STYLES_OPTIONS } from "../../Utils/Design/SalesUtils";

import { GlobalContext } from "contexts/Global";
import { EditorContext } from "../../../contexts/editor";

const UpdateDesign = ({ client }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const { lang } = useContext(GlobalContext);

    const { fonts } = useSelector((state) => state.ui);

    //Store data
    const {
        editorMode,
        cScreenData,
        cDevice,
        gridLayout,
        itemSelected,
        gridItems,
        cloningGridItemId,
        containerInEdition,
        containersLayouts,
    } = useSelector((state) => state.design);

    const { executeQuery } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);

    const { theme } = useContext(EditorContext);

    //Params
    const { id, screenid, type, lang: langParam, landingid } = useParams();

    //State
    const [mutation, setMutation] = useState("mutation{checkIn}");
    const [savePrevItemSelected, setSavePrevItemSelected] = useState("");
    const [addedWidgets, setAddedWidgets] = useState(null);
    //Actions
    const dispatch = useDispatch();

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            if (executeQuery && executeQuery.action === "updateWidgetPos") {
                dispatch(updateGridItemPos(executeQuery.params.widgetID, executeQuery.params.prevWidgetPos));
            } else if (executeQuery && executeQuery.action === "updateContainerItemPos") {
                dispatch(
                    updateContainerItemPos(
                        executeQuery.params.containerId,
                        executeQuery.params.widgetID,
                        executeQuery.params.prevWidgetPos
                    )
                );
            }
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(err.message);
        },
    });

    // PWA screens need to add TopBar always (and rooms if landing)
    const isPWAType = (type) => {
        return type === "MOBILE";
    };

    const isPortalType = (type) => {
        return type === "PORTAL";
    };

    // TV screens need to add Menu if landing
    const isTVType = (type) => {
        return type === "TV";
    };

    // General screens need to add TopBar always (and tvmenu and rooms if landing)
    const isGeneralType = (type) => {
        return type === "GENERAL";
    };

    const updateItemsPos = (device, changes) => {
        const scr = cScreenData.data;
        const scrPos = scr?.pos ? JSON.parse(scr.pos) : {};
        const widgets = scrPos ? scrPos[device] : [];
        // eslint-disable-next-line
        Object.keys(changes).forEach((k) => {
            const v = changes[k];

            const factor = v[1] / v[0] || 1;
            const updateParams = [];
            if (widgets && widgets.length > 0) {
                widgets.map((w) => {
                    const prevWidgetPos = { X: w.X, Y: w.Y, W: w.W, H: w.H };
                    switch (k) {
                        case "cols":
                            w.W = Math.max(Math.floor(w.W * factor), 1);
                            w.X = Math.floor(w.X * factor);
                            break;
                        case "rows":
                            w.H = Math.max(Math.floor(w.H * factor), 1);
                            w.Y = Math.floor(w.Y * factor);
                            break;
                        default:
                            break;
                    }
                    updateParams.push({
                        widgetID: w.ID,
                        widgetPos: { x: w.X, y: w.Y, w: w.W, h: w.H },
                        prevWidgetPos,
                    });
                    return w;
                });
            }

            //TODO Mejorar todo este tema de las mutations
            // Lo de los timeouts es un apaño horrible, pero bueno... funciona
            // eslint-disable-next-line
            updateParams.forEach((p, n) => {
                setTimeout(() => {
                    dispatch(
                        setExecuteQuery({
                            action: "updateWidgetPos",
                            params: p,
                        })
                    );
                }, 210 * (n + 1));
            });
        });
    };

    const updateContainerItemsPos = (changes) => {
        const cWidget = cScreenData.data.contentWidgets.filter(
            (item) => String(item.ID) === String(changes.containerId)
        )[0];
        let containerPosis = cWidget?.pos ? JSON.parse(cWidget.pos) : null;
        if (!containerPosis) {
            containerPosis = [];
        }

        if (changes.pVal === changes.nVal) {
            return;
        }
        const factor = changes.nVal / changes.pVal || 1;
        const rows = changes.rows;
        const cols = changes.cols;
        // eslint-disable-next-line
        containerPosis.map((p) => {
            p.X = cols ? Math.floor(factor * p.X) : p.X;
            p.Y = rows ? Math.floor(factor * p.Y) : p.Y;
            p.H = rows ? Math.max(Math.floor(factor * p.H), 1) : p.H;
            p.W = cols ? Math.max(Math.floor(factor * p.W), 1) : p.W;
            return p;
        });

        dispatch(
            setExecuteQuery({
                action: "updateContainerWidgetPos",
                params: {
                    containerId: changes.containerId,
                    fullPos: containerPosis,
                },
            })
        );
    };

    /**
     * Generates the occupied coordinates according to the parameters of the widget passed as argument.
     *
     * @param {Object} item - Data position widget
     * @param {Integer} contIteration - Increment counter for the widget - Default value is 1
     * @param {String} direction - Direction of creation coords - Default value is up
     *
     * @returns {Object} - Object with the coordinates covered by the widget
     */
    const generateCoordsIntegrateItemWidget = (item, newPosition = 1, direction = "up") => {
        const { h: heightItem, i: itemID, w: widthItem, x: positionXItem, y: positinoYItem } = item;

        const coordsItem = {};
        coordsItem[itemID] = [];

        if (direction === "up") {
            let contPositionY = newPosition;

            for (contPositionY; contPositionY < newPosition + heightItem; contPositionY++) {
                for (let contPositionX = positionXItem; contPositionX < positionXItem + widthItem; contPositionX++) {
                    coordsItem[itemID].push(`${contPositionY},${contPositionX}`);
                }
            }
        } else {
            let contPositionX = newPosition;

            for (let contPositionY = positinoYItem; contPositionY < positinoYItem + heightItem; contPositionY++) {
                for (contPositionX; contPositionX < newPosition + widthItem; contPositionX++) {
                    coordsItem[itemID].push(`${contPositionY},${contPositionX}`);
                }
            }
        }

        return coordsItem;
    };

    /**
     * Check if the coordinates of the new widget are not occupied by another widget.
     *
     * @param {Object} coordsNewItem - Coordinates new widget
     * @param {Object} coordsWidgets - Coordinates of all other existing widgets
     *
     * @returns {Boolean} - Boolean indicating whether the position to occupy is available.
     */
    const verifyCoordsNewWidgetNotIncludeInAllWidgets = (coordsNewItem, coordsWidgets) => {
        const isValidPositionsNewWidget = [];

        for (const iWidget in coordsNewItem) {
            if (Object.hasOwnProperty.call(coordsNewItem, iWidget)) {
                const itemWidget = coordsNewItem[iWidget];

                for (const keyItemWidget in itemWidget) {
                    if (Object.hasOwnProperty.call(itemWidget, keyItemWidget)) {
                        let isValidPosition = true;
                        const elWidget = itemWidget[keyItemWidget];

                        for (const key in coordsWidgets) {
                            if (Object.hasOwnProperty.call(coordsWidgets, key)) {
                                const element = coordsWidgets[key];

                                if (element.includes(elWidget)) {
                                    isValidPosition = false;
                                    break;
                                }
                            }
                        }

                        isValidPositionsNewWidget.push(isValidPosition);
                    }
                }
            }
        }

        return isValidPositionsNewWidget.some((value) => value === false);
    };

    /**
     * Checks if the coordinates of the new widget exist within the layout coordinates
     *
     * @param {Object} coordsNewItem - Coordinates new widget
     * @param {Object} coordsLayout - Coordinates layout
     *
     * @returns {Boolean} - Boolean that indicates whether the position is within the layout.
     */

    const verifyCoordsNewWidgetIncludeInLayout = (coordsNewItem, coordsLayout) => {
        const isValidPositionsNewWidget = [];

        for (const iWidget in coordsNewItem) {
            if (Object.hasOwnProperty.call(coordsNewItem, iWidget)) {
                const itemWidget = coordsNewItem[iWidget];

                for (const keyItemWidget in itemWidget) {
                    if (Object.hasOwnProperty.call(itemWidget, keyItemWidget)) {
                        let isValidPosition = true;
                        const elWidget = itemWidget[keyItemWidget];

                        for (const key in coordsLayout) {
                            if (Object.hasOwnProperty.call(coordsLayout, key)) {
                                const element = coordsLayout[key];

                                if (element.includes(elWidget)) {
                                    isValidPosition = false;
                                    break;
                                }
                            }
                        }

                        isValidPositionsNewWidget.push(isValidPosition);
                    }
                }
            }
        }

        return isValidPositionsNewWidget.filter((value) => value === false).length === isValidPositionsNewWidget.length;
    };

    /**
     * Generate object with new widget data
     *
     * @param {Object} item - Data of the widget to be duplicated
     * @param {String} widgetID - ID widget
     * @param {String} dataContainer - Data container, when widget to clone exist in container
     * @param {String} layoutWithWidgets - Widgets includes in container
     *
     * @returns {Object} - Final data of the widget to be duplicated
     */
    const positionWidgetClone = (item, widgetID, dataContainer = null, layoutWithWidgets = null) => {
        const { grid = {} } = dataContainer || cDevice;

        const { cols: columnsLayout, rows: rowsLayout } = grid;

        const { h: heightItem, i: itemID, w: widthItem, x: positionXItem, y: positinoYItem } = item;

        const coordsLayout = generateCoordsIntegrateItemWidget(
            {
                h: rowsLayout,
                i: itemID,
                w: columnsLayout,
                x: 0,
                y: 0,
            },
            0
        );

        const coordsWidgets = {};

        const allWidgetsInLayout = layoutWithWidgets ? layoutWithWidgets : gridLayout;

        allWidgetsInLayout.forEach((widgetItemCurrent) => {
            const { i: idWidgetCurrent, y: positinoYItemCurrent } = widgetItemCurrent;

            coordsWidgets[idWidgetCurrent] = Object.values(
                generateCoordsIntegrateItemWidget(widgetItemCurrent, positinoYItemCurrent)
            )[0];
        });

        let isFinalValidPositionItemWidget = false;
        let validateIfCoordExistInLayout = false;

        let newPositionX = 0;
        let newPositionY = 0;

        for (newPositionX; newPositionX <= columnsLayout - 1; newPositionX++) {
            newPositionY = 0;
            let coordsNewItem = generateCoordsIntegrateItemWidget({ ...item, y: newPositionY }, newPositionX, "down");

            isFinalValidPositionItemWidget = verifyCoordsNewWidgetNotIncludeInAllWidgets(coordsNewItem, coordsWidgets);

            if (!isFinalValidPositionItemWidget) {
                validateIfCoordExistInLayout = verifyCoordsNewWidgetIncludeInLayout(coordsNewItem, coordsLayout);
                break;
            }

            for (newPositionY; newPositionY <= rowsLayout - 1; newPositionY++) {
                coordsNewItem = generateCoordsIntegrateItemWidget({ ...item, x: newPositionX }, newPositionY);

                isFinalValidPositionItemWidget = verifyCoordsNewWidgetNotIncludeInAllWidgets(
                    coordsNewItem,
                    coordsWidgets
                );

                if (!isFinalValidPositionItemWidget) {
                    validateIfCoordExistInLayout = verifyCoordsNewWidgetIncludeInLayout(coordsNewItem, coordsLayout);
                    break;
                }
            }

            if (!isFinalValidPositionItemWidget && validateIfCoordExistInLayout) {
                validateIfCoordExistInLayout = verifyCoordsNewWidgetIncludeInLayout(coordsNewItem, coordsLayout);
                break;
            }
        }

        const positionsNewWidget = {
            y: newPositionY,
            x: newPositionX,
            w: widthItem,
            h: heightItem,
        };

        return {
            widgetID,
            widgetPos: positionsNewWidget,
            prevWidgetPos: {
                x: positionXItem,
                y: positinoYItem,
                w: widthItem,
                h: heightItem,
            },
            widgetProps: item,
            prevMutationName: "cloneWidget",
            isInvalidClone: !validateIfCoordExistInLayout,
        };
    };

    const positionWidgetCloneInContainer = () => {
        let params = null;
        if (containerInEdition) {
            const infoDuplicateWidgetInContainer = {
                idContainerLayout: null,
                itemWidget: null,
            };

            for (const containerLayout of containersLayouts) {
                const { i: idContainerLayout, layout } = containerLayout;

                for (const keyLayout in layout) {
                    if (Object.hasOwnProperty.call(layout, keyLayout)) {
                        const { i: idLayout } = layout[keyLayout];

                        if (idLayout === String(executeQuery.params.widgetID)) {
                            infoDuplicateWidgetInContainer["idContainerLayout"] = idContainerLayout;
                            infoDuplicateWidgetInContainer["itemWidget"] = layout[keyLayout];
                            break;
                        }
                    }
                }

                const { itemWidget = null } = infoDuplicateWidgetInContainer;

                if (itemWidget) {
                    const findDataContainer = gridItems.filter(
                        ({ i: idContainer }) => String(idContainerLayout) === String(idContainer)
                    );
                    if (findDataContainer.length) {
                        const { data: dataContainer = {} } = findDataContainer[0];

                        params = {
                            ...positionWidgetClone(itemWidget, itemWidget.i, dataContainer, layout),
                            idContainerLayout,
                        };
                    }
                    break;
                }
            }
        }

        return params;
    };

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            let projectData,
                itemType,
                cItemsPos,
                contentType,
                mutationDeviceContent,
                currentDate,
                allProperties,
                newData;

            executeQuery.mutationName = "";
            execute = true;
            executeQuery.refresh = true;
            const contentIsLanding =
                (executeQuery.params && (executeQuery.params.isLanding || executeQuery.params.landingID)) ||
                (executeQuery?.params?.value &&
                    (executeQuery.params.value.isLanding || executeQuery.params.value.landingID));
            const contentIsWelcome = executeQuery?.params?.value?.contentType === SCREEN.TYPE.WELCOME ? true : false;
            const contentIsSales =
                executeQuery?.params?.value?.contentType === SCREEN.TYPE.SALES ||
                executeQuery?.params?.value?.contentType === SCREEN.TYPE.SALESMOBILE
                    ? true
                    : false;

            const newValue = executeQuery.params ? executeQuery.params.value : null;

            switch (executeQuery.action) {
                case "widget-actions-media":
                    //update widget actions
                    executeQuery.params = {
                        widgetID: itemSelected,
                        actions: {
                            actions: [
                                {
                                    type: "library",
                                    value: actionData.values["browser-media-library-items-selected"][0],
                                },
                            ],
                        },
                    };
                    setMutation(`
                        mutation{
                            updateWidget(
                                designID: ${id}
                                contentID: ${screenid}
                                contentType: ${type}
                                widgetID : ${executeQuery.params.widgetID}
                                actions :  ${JSON.stringify(JSON.stringify(executeQuery.params.actions))}

                            ){ error ok id }}
                    `);
                    executeQuery.hideLoading = true;
                    executeQuery.mutationName = "updateWidget";
                    break;
                case "browser-media-library":
                    const currentItemSelected = itemSelected !== null ? itemSelected : savePrevItemSelected;
                    const gItem = gridItems.filter((gridItem) => gridItem.i === currentItemSelected)[0];
                    itemType = gItem ? gItem.type : null;
                    if (gItem && !itemType) {
                        const getWidgetType = (arrayWidget, itemId) => {
                            const itemMatch = [];
                            arrayWidget.forEach((contentWidget) => {
                                if (contentWidget.type === "CONTAINER") {
                                    if (contentWidget.contentWidgets)
                                        contentWidget.contentWidgets.forEach((contentContainer) => {
                                            if (parseInt(contentContainer.ID) === parseInt(itemId)) {
                                                itemMatch.push(contentContainer);
                                            }
                                        });
                                }
                                if (parseInt(contentWidget.ID) === parseInt(itemId)) {
                                    itemMatch.push(contentWidget);
                                }
                            });
                            return itemMatch;
                        };

                        itemType = getWidgetType(cScreenData.data.contentWidgets, gItem.i)[0]
                            ? getWidgetType(cScreenData.data.contentWidgets, gItem.i)[0].type
                            : null;
                    }

                    //update screen bg image
                    if (itemSelected === "bg") {
                        const contentStyle = JSON.parse(JSON.stringify(cScreenData.contentStyle));
                        contentStyle.images = [
                            {
                                libraryRef: actionData.values["browser-media-library-items-selected"][0],
                            },
                        ];

                        setMutation(`
                            mutation{
                                updateContent(
                                    contentStyle: ${JSON.stringify(JSON.stringify(contentStyle))}
                                    designID: ${id}
                                    id: ${screenid}
                                    type: ${type}
                                ){ error ok }}
                        `);
                        executeQuery.hideLoading = true;
                        executeQuery.mutationName = "updateContent";
                        //Update data without reload
                        executeQuery.action = "updateContentStyle";
                        executeQuery.params = {
                            contentStyle: contentStyle,
                        };
                    } else if (itemType === "CONTAINER") {
                        const newProperty = {};
                        newProperty[`>images`] = [
                            {
                                libraryRef: actionData.values["browser-media-library-items-selected"][0],
                            },
                        ];

                        setMutation(`
                                mutation{
                                    updateWidget(
                                        designID: ${id}
                                        contentID: ${screenid}
                                        contentType: ${type}
                                        widgetID : ${itemSelected}
                                        widgetStyle :  ${JSON.stringify(JSON.stringify(newProperty))}

                                    ){ error ok id }}
                            `);
                        executeQuery.hideLoading = true;
                        executeQuery.mutationName = "updateWidget";
                        //Update data without reload
                        executeQuery.action = "updateWidgetStyle";
                        const widget =
                            gridItems && itemSelected ? gridItems.filter((w) => w && w.i === itemSelected)[0] : null;
                        executeQuery.params = {
                            widgetID: itemSelected,
                            value: {
                                ...((widget ? widget.widgetStyle : null) || {}),
                                images: [{ libraryRef: actionData.values["browser-media-library-items-selected"][0] }],
                            },
                        };
                    } else {
                        newData = gItem.data || {};

                        // Ensure textids as strings
                        const ensureTextIdAsStr = (val) => {
                            if (val && typeof val === "object") {
                                if (Object.keys(val).length > 0) {
                                    Object.keys(val).forEach((key) => {
                                        const curval = val[key];
                                        if (curval && typeof curval === "object") {
                                            if (curval.hasOwnProperty("id") && curval.hasOwnProperty("text")) {
                                                val[key] = { id: "" + curval.id, text: curval.text };
                                            } else {
                                                val[key] = ensureTextIdAsStr(curval);
                                            }
                                        }
                                    });
                                }
                            }
                            return val;
                        };
                        newData = ensureTextIdAsStr(newData);

                        if (itemType && itemType === "CAROUSEL") {
                            const dataImages = newData && newData.images ? newData.images : [];

                            actionData.values["browser-media-library-items-selected"].map((assetRef) =>
                                dataImages.push({
                                    externalUrl: null,
                                    libraryRef: assetRef,
                                })
                            );
                            newData.images = dataImages?.slice(0, 15);
                            if (dataImages?.length > 15) {
                                toast.error(capitalizeFirst(t("up-to-images", { num: "15" })));
                            }
                        } else {
                            newData.libraryRef = actionData.values["browser-media-library-items-selected"][0];
                            newData.externalUrl = null;

                            if (itemType && itemType === "VIDEO") {
                                delete newData.libraryRef;
                                delete newData.externalUrl;
                                newData.video = [
                                    {
                                        libraryRef: actionData.values["browser-media-library-items-selected"][0],
                                    },
                                ];
                            }

                            executeQuery.params = {
                                widgetID: itemSelected,
                                value: newData,
                            };
                        }

                        setMutation(`
                        mutation{
                            updateWidget(
                                designID: ${id}
                                contentID: ${screenid}
                                contentType: ${type}
                                widgetID : ${currentItemSelected}
                                data :  ${JSON.stringify(JSON.stringify(newData))}

                            ){ error ok id }}
                    `);
                        executeQuery.hideLoading = true;
                        executeQuery.mutationName = "updateWidget";
                        //Update data without reload
                        executeQuery.action = "updateWidgetData";
                        executeQuery.params = {
                            widgetID: currentItemSelected,
                            value: newData,
                        };
                    }
                    setSavePrevItemSelected("");
                    break;
                case "content-edit-name":
                    let mutationChain = `mutation {
                        updateContent(
                          id: ${executeQuery.params.value.contentID}
                          designID: ${executeQuery.params.value.designID}
                          type: ${executeQuery.params.value.contentType}
                          `;
                    executeQuery.mutationName = "updateContent";
                    if (contentIsSales) {
                        mutationChain = `mutation {
                            updatePredefinedScreen(
                              id: ${executeQuery.params.value.contentID}
                              designID: ${executeQuery.params.value.designID}
                              `;
                        executeQuery.mutationName = "updatePredefinedScreen";
                    }
                    if (contentIsWelcome) {
                        mutationChain = `mutation {
                            updateWelcomeScreen(
                              id: ${executeQuery.params.value.contentID}
                              designId: ${executeQuery.params.value.designID}
                              isEnabled: ${executeQuery.params.value.isEnabled ? true : false}
                              `;
                        executeQuery.mutationName = "updateWelcomeScreen";
                    }
                    if (contentIsLanding) {
                        mutationChain = `mutation {
                            updateLanding(
                              id: ${executeQuery.params.value.landingID}
                              designID: ${executeQuery.params.value.designID}
                              `;
                        executeQuery.mutationName = "updateLanding";
                    }
                    mutationChain += `
                    name: "${escapeSpecialChars(actionData.values["screen-name"])}"
                ){ error ok }}
        `;

                    setMutation(mutationChain);

                    removeApolloCacheKeys(client.cache, "contentTree");
                    break;
                case "duplicate-content":
                    if (contentIsLanding) {
                        setMutation(`
                        mutation{
                            cloneLanding(
                                designID: ${executeQuery.params.designID}
                                landingID: ${executeQuery.params.landingID}
                            ){ error ok }}
                    `);
                        executeQuery.mutationName = "cloneLanding";
                    } else if (executeQuery.params.contentType === "WELCOME") {
                        setMutation(`
                            mutation{
                                duplicateWelcome(
                                    sourceDesignId: ${executeQuery.params.designID}
                                    id: ${executeQuery.params.contentID}                                   
                                ){ error ok }}
                        `);
                        executeQuery.mutationName = "duplicateWelcome";
                    } else {
                        setMutation(`
                        mutation{
                            cloneContent(
                                designID: ${executeQuery.params.designID}
                                contentID: ${executeQuery.params.contentID}
                                contentType: ${executeQuery.params.contentType}
                            ){ error ok }}
                    `);
                        executeQuery.mutationName = "cloneContent";
                    }

                    executeQuery.hideLoading = true;
                    break;
                case "duplicate-in":
                    if (cScreenData.welcome) {
                        setMutation(`
                        mutation{
                            duplicateWelcome(
                                sourceDesignId: ${id}
                                id: ${landingid}           
                                destinationChainRef: "${actionData.values["chain"][0]}"
                                destinationDesignId: "${actionData.values["design"][0]}"                                
                            ){ error ok }}
                    `);
                        executeQuery.mutationName = "duplicateWelcome";
                    } else if (landingid) {
                        setMutation(`
                            mutation{
                                cloneLanding(
                                    designID: "${id}"
                                    landingID: "${landingid}"
                                    destinationChainRef: "${actionData.values["chain"][0]}"
                                    destinationDesignID: "${actionData.values["design"][0]}"                            
                                ){ id error ok }}
                        `);
                        executeQuery.mutationName = "cloneLanding";
                    } else {
                        setMutation(`
                            mutation{
                                duplicateInContent(
                                    contentID: "${screenid}"
                                    contentType: ${type}
                                    destinationChainRef: "${actionData.values["chain"][0]}"
                                    destinationDesignID: "${actionData.values["design"][0]}"
                                    sourceDesignID: "${id}"
                                ){ id error ok }}
                        `);
                        executeQuery.mutationName = "duplicateInContent";
                    }
                    executeQuery.hideLoading = true;
                    break;
                case "content-delete":
                    if (contentIsWelcome) {
                        setMutation(`
                            mutation{
                                deleteWelcomeScreen(
                                    designId: ${executeQuery.params.value.designID}
                                    id: ${executeQuery.params.value.landingID || executeQuery.params.value.contentID}
                                ){ error ok }}
                        `);
                        executeQuery.mutationName = "deleteWelcomeScreen";
                    } else if (contentIsSales) {
                        setMutation(`
                            mutation{
                                deletePredefinedScreen(
                                    designID: ${executeQuery.params.value.designID}
                                    id: ${executeQuery.params.value.contentID}
                                ){ error ok }}
                        `);
                        executeQuery.mutationName = "deletePredefinedScreen";
                    } else if (contentIsLanding) {
                        setMutation(`
                            mutation{
                                deleteLanding(
                                    designID: ${executeQuery.params.value.designID}
                                    landingID: ${executeQuery.params.value.landingID}
                                ){ error ok }}
                        `);
                        executeQuery.mutationName = "deleteLanding";
                    } else {
                        setMutation(`
                            mutation{
                                deleteContent(
                                    designID: ${executeQuery.params.value.designID}
                                    contentID: ${executeQuery.params.value.contentID}
                                ){ error ok }}
                        `);
                        executeQuery.mutationName = "deleteContent";
                    }

                    dispatch(setDesignToolbarVisible(cScreenData?.screenType === "GENERAL" ? "screens" : "landings")); // this is needed to redirect to an exisiting content after current deletion
                    dispatch(setEditingMenu(false));
                    break;
                case "publish-content":
                    if (landingid) {
                        setMutation(`
                            mutation{
                                ${cScreenData.welcome ? "updateWelcomeScreen" : "updateLanding"}(
                                    ${cScreenData.welcome ? "designId" : "designID"}: ${id}
                                    id: ${landingid}
                                    isEnabled: true
                                ){ error ok }}
                        `);
                        executeQuery.mutationName = cScreenData.welcome ? "updateWelcomeScreen" : "updateLanding";
                    } else if (type === SCREEN.TYPE.SALES || type === SCREEN.TYPE.SALESMOBILE) {
                        setMutation(`
                        mutation {
                            updatePredefinedScreen(
                                id: ${screenid}
                                designID: ${id}
                                isPublished: true
                            ) { error ok id }
                        }
                        `);
                        executeQuery.mutationName = "updatePredefinedScreen";
                    } else {
                        setMutation(`
                            mutation{
                                publishContent(
                                    designID: ${id}
                                    contentID: ${screenid}
                                    contentType: ${type}
                                ){ error ok }}
                        `);
                        executeQuery.mutationName = "publishContent";
                    }
                    executeQuery.hideLoading = true;
                    break;
                case "unpublish-content":
                    if (landingid) {
                        setMutation(`
                            mutation{
                                    ${cScreenData.welcome ? "updateWelcomeScreen" : "updateLanding"}(
                                        ${cScreenData.welcome ? "designId" : "designID"}: ${id}
                                    id: ${landingid}
                                    isEnabled: false
                                ){ error ok }}
                        `);
                        executeQuery.mutationName = cScreenData.welcome ? "updateWelcomeScreen" : "updateLanding";
                    } else {
                        setMutation(`
                        mutation{
                            unpublishContent(
                                designID: ${id}
                                contentID: ${screenid}
                                contentType: ${type}
                            ){ error ok }}
                    `);
                        executeQuery.mutationName = "unpublishContent";
                    }
                    executeQuery.hideLoading = true;
                    break;
                case "make-content-default":
                    setMutation(`
                        mutation{
                            setContentAsDefault(         
                                designID: ${
                                    executeQuery.params && executeQuery.params.value
                                        ? executeQuery.params.value.designID
                                        : id
                                }
                                contentID: ${
                                    executeQuery.params && executeQuery.params.value
                                        ? executeQuery.params.value.contentID
                                        : screenid
                                }
                                contentType: ${
                                    executeQuery.params && executeQuery.params.value
                                        ? executeQuery.params.value.contentType
                                        : type
                                }                     
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "setContentAsDefault";
                    executeQuery.hideLoading = true;
                    break;
                case "add-screen-from-template":
                    contentType = actionData.values["content-type"]
                        ? actionData.values["content-type"][0]
                        : executeQuery.params
                        ? executeQuery.params.type
                        : null;
                    const templateId =
                        actionData.values["template-id"] && actionData.values["template-id"] !== -99
                            ? actionData.values["template-id"]
                            : null;
                    if (!contentType) {
                        return;
                    }

                    const devices = GetScreenDevices({ screenType: contentType });
                    mutationDeviceContent =
                        "[" +
                        devices
                            .map((d) => {
                                return `{ type: "${d.type}", name: "${d.name}", orientation: "${d.orientation}", res: { h: ${d.res.h}, w: ${d.res.w} }, grid: { cols: ${d.grid.cols}, rows: ${d.grid.rows} } }`;
                            })
                            .join(" ") +
                        "]";
                    const _newScreenName = getNewNameByType(contentType, t);
                    if (
                        executeQuery.params.landingType &&
                        contentType !== SCREEN.TYPE.WELCOME &&
                        contentType !== SCREEN.TYPE.SALES &&
                        contentType !== SCREEN.TYPE.SALESMOBILE
                    ) {
                        setMutation(`
                            mutation{
                                createLanding(
                                    designID: ${id}
                                    type: ${contentType}
                                    ${!templateId ? `name: "${_newScreenName}"` : `templateID: ${templateId}`} 
                                    ${
                                        !templateId
                                            ? `content:{
                                                devices: ${mutationDeviceContent}
                                                contentStyle: ${JSON.stringify(
                                                    JSON.stringify({
                                                        margin: {
                                                            h: 10,
                                                            w: 10,
                                                        },
                                                        itemsMargin: {
                                                            h: 10,
                                                            w: 10,
                                                        },
                                                    })
                                                )}
                                                }`
                                            : ""
                                    }
                                    ${actionData.values.makeDefault ? "isEnabled: true" : "isEnabled: false"}
                                ){ error id contentId ok }}
                        `);
                        executeQuery.mutationName = "createLanding";
                    } else if (contentType === SCREEN.TYPE.WELCOME) {
                        setMutation(`
                        mutation{
                            createWelcomeScreen(
                                ${actionData.values.makeDefault ? "isEnabled: true" : "isEnabled: false"}
                                welcomeContents: [${getContentByWelcomeTypeScreen(WELCOME_SEQUENCE.LANGSELECTION, {
                                    lang: langParam,
                                    texts: t("select-lang"),
                                    managerLang: lang,
                                })}]
                                designId: ${id}
                                name: "${_newScreenName}"
                            ){ error contentId id ok }
                        }`);
                        executeQuery.mutationName = "createWelcomeScreen";
                    } else if (contentType === SCREEN.TYPE.SALES || contentType === SCREEN.TYPE.SALESMOBILE) {
                        const latoFont = { name: LATO_FONT.name, externalUrl: LATO_FONT.externalUrl };
                        setMutation(`mutation{
                            createPredefinedScreen(
                                name: "${_newScreenName}"
                                type: ${contentType}
                                designID: ${id}
                                ${actionData.values.makeDefault ? "isPublished: true" : "isPublished: false"}
                                data: ${JSON.stringify(
                                    JSON.stringify({
                                        typography: { text: latoFont, title: latoFont },
                                        title: {
                                            es: "Bienvenido a nuestra sección de compras",
                                            en: "Welcome to our shopping section",
                                        },
                                        background: { useHomeBg: true, libraryRef: "" },
                                        style: SALES_STYLES_OPTIONS()?.[contentType](theme),
                                        textAlignment: textAlignment.CENTER,
                                    })
                                )}
                            ){ error predefinedScreenChanged id ok }
                        }`);

                        executeQuery.mutationName = "createPredefinedScreen";
                        executeQuery.contentType = contentType;
                    } else {
                        setMutation(`
                        mutation{
                            createContent(
                                designID: ${id}
                                type: ${contentType}
                                ${!templateId ? `name: "${_newScreenName}"` : `templateID: ${templateId}`}
                                ${
                                    !templateId
                                        ? `devices: ${mutationDeviceContent}
                                            contentStyle: ${JSON.stringify(
                                                JSON.stringify({
                                                    margin: {
                                                        h: 10,
                                                        w: 10,
                                                    },
                                                    itemsMargin: {
                                                        h: 10,
                                                        w: 10,
                                                    },
                                                    showHomeMenu: true,
                                                    useHomeBackground: true,
                                                    bgColor: null,
                                                    images: null,
                                                })
                                            )}`
                                        : ""
                                }
                                ${executeQuery.params.dirID ? `dirID: ${executeQuery.params.dirID}` : ""}
                                isEnabled: ${contentType === "GENERAL" ? "true" : "false"}
                                
                            ){ error id ok }}
                    `);
                        executeQuery.mutationName = "createContent";
                    }
                    executeQuery.closeModal = true;
                    break;
                case "createWidget":
                case "createWidgets":
                    const isMultiple = executeQuery.action === "createWidgets";

                    // Content data
                    const contentParams = {
                        type: type,
                        designID: id,
                        contentID:
                            cScreenData && cScreenData.data && cScreenData.data.welcomeId
                                ? cScreenData.data.contentId
                                : screenid,
                        containerID: executeQuery.params.containerID,
                    };

                    // Widget data
                    const widgetParams = {
                        deviceType: executeQuery.params ? executeQuery.params.deviceType : null,
                        widgets: [],
                    };

                    if (isMultiple) {
                        if (executeQuery.params && executeQuery.params.widgets) {
                            executeQuery.params.widgets.map((w) => {
                                widgetParams.widgets.push({
                                    type: w ? w.widgetType : null,
                                    widgetPos: w ? w.widgetPos : null,
                                    layoutPos: w ? w.layoutPos : null,
                                    data: w ? w.data : null,
                                    style: w ? w.style : null,
                                });
                                return w;
                            });
                        }
                    } else {
                        widgetParams.widgets.push({
                            type: executeQuery.params ? executeQuery.params.widgetType : null,
                            widgetPos: executeQuery.params ? executeQuery.params.widgetPos : null,
                            layoutPos: executeQuery.params ? executeQuery.params.layoutPos : null,
                            data: executeQuery.params ? executeQuery.params.data : null,
                            style: executeQuery.params ? executeQuery.params.style : null,
                        });
                    }

                    // Parse data
                    const mutationVars = {
                        contentType: contentParams.type,
                        designID: contentParams.designID,
                        contentID: contentParams.contentID,
                        containerID: contentParams.containerID,
                        activeDevice: widgetParams.deviceType,
                        widgets: [],
                    };
                    widgetParams.widgets.forEach((w) => {
                        const { data, style, layoutPos } = CalulateWidgetData(
                            {
                                deviceType: widgetParams.deviceType,
                                ...w,
                            },
                            contentParams.containerID ? true : false,
                            {
                                type: contentParams.type,
                                data: cScreenData ? cScreenData.data : null,
                                grid: cDevice ? cDevice.grid : null,
                                lang: lang,
                                fonts,
                            }
                        );
                        mutationVars.widgets.push({
                            type: w.type,
                            layoutPos: layoutPos,
                            data: data,
                            widgetStyle: style,
                        });
                    });

                    // Mutation
                    const createWidgetParams = (params) => {
                        let graphql = `type: "${params.type}" `;
                        graphql += `layoutPos:  ${JSON.stringify(JSON.stringify(params.layoutPos))} `;
                        if (params.data) {
                            graphql += `data: ${JSON.stringify(JSON.stringify(params.data))} `;
                        }
                        if (params.widgetStyle) {
                            graphql += `widgetStyle: ${JSON.stringify(JSON.stringify(params.widgetStyle))} `;
                        }
                        graphql += `pos:1 `;
                        return graphql;
                    };
                    let mutation = `mutation{
                        ${isMultiple ? "createWidgets" : "createWidget"}(
                        designID: ${mutationVars.designID}
                        contentID: ${mutationVars.contentID}
                    `;
                    if (mutationVars.contentType) {
                        mutation += `contentType: ${mutationVars.contentType} `;
                    }
                    if (mutationVars.containerID) {
                        mutation += `containerID: ${mutationVars.containerID} `;
                    }
                    if (isMultiple) {
                        mutation += `widgets:[
                            ${mutationVars.widgets.map((w) => "{" + createWidgetParams(w) + "}")}
                        ]
                        `;
                    } else {
                        mutation += createWidgetParams(mutationVars.widgets[0]) + "\n";
                    }
                    mutation += `){ error ok id }}`;
                    setMutation(mutation);

                    executeQuery.hideLoading = true;
                    setAddedWidgets(mutationVars);
                    break;
                case "deleteWidget":
                    setMutation(`
                                mutation{
                                    deleteWidget(
                                        designID: ${id}
                                        contentID: ${
                                            cScreenData.data && cScreenData.data.welcomeId
                                                ? cScreenData.data.contentId
                                                : screenid
                                        }
                                        contentType: ${type}
                                         widgetID :${executeQuery.params.widgetID}
                                    ){ error ok id }}
                            `);
                    executeQuery.hideLoading = true;
                    break;
                case "updateWidgetPos":
                    const newPos = executeQuery.params.widgetPos;
                    const widgetID = String(executeQuery.params.widgetID);
                    const prevMutation = executeQuery.params.prevMutationName;

                    // refresh current device positions
                    cItemsPos = cScreenData && cScreenData.data ? JSON.parse(cScreenData.data.pos) : {};
                    const devicesList = Object.keys(cItemsPos);

                    if (prevMutation !== "cloneWidget") {
                        if (cDevice && cDevice.name) {
                            cItemsPos[cDevice.name] = [];

                            gridLayout.forEach((grid) => {
                                const id = String(grid.i);
                                cItemsPos[cDevice.name].push({
                                    ID: parseInt(id),
                                    H: widgetID === id ? newPos.h : grid.h,
                                    W: widgetID === id ? newPos.w : grid.w,
                                    X: widgetID === id ? newPos.x : grid.x,
                                    Y: widgetID === id ? newPos.y : grid.y,
                                });
                            });
                        }
                    } else if (prevMutation === "cloneWidget") {
                        const wType = executeQuery.params.widgetProps.type ? executeQuery.params.widgetProps.type : "";
                        const wPos = executeQuery.params.widgetProps.pos;

                        const posByDevice = WidgetInitialPosition({
                            type: wType,
                            deviceType: cDevice.name,
                            devicePosition: newPos,
                            screenType: type,
                            screen: cScreenData ? cScreenData.data : null,
                        });

                        if (cDevice && cDevice.name) {
                            // eslint-disable-next-line
                            devicesList.forEach((device) => {
                                if (device === cDevice.name) {
                                    cItemsPos[cDevice.name] = [];
                                    // eslint-disable-next-line
                                    gridLayout.map((grid, index) => {
                                        const id = String(grid.i);
                                        cItemsPos[cDevice.name].push({
                                            ID: parseInt(id),
                                            H: widgetID === id ? newPos.h : grid.h,
                                            W: widgetID === id ? newPos.w : grid.w,
                                            X: widgetID === id ? newPos.x : grid.x,
                                            Y: widgetID === id ? newPos.y : grid.y,
                                        });
                                    });
                                } else {
                                    cItemsPos[device] = [];
                                    // eslint-disable-next-line
                                    gridLayout.map((grid, index) => {
                                        const id = String(grid.i);
                                        cItemsPos[device].push({
                                            ID: parseInt(id),
                                            H:
                                                widgetID === id
                                                    ? wPos[device].h
                                                    : grid.pos
                                                    ? grid.pos[device].h
                                                    : grid.h,
                                            W:
                                                widgetID === id
                                                    ? wPos[device].w
                                                    : grid.pos
                                                    ? grid.pos[device].w
                                                    : grid.w,
                                            X:
                                                widgetID === id
                                                    ? posByDevice[device].x
                                                    : grid.pos
                                                    ? grid.pos[device].x
                                                    : grid.x,
                                            Y:
                                                widgetID === id
                                                    ? posByDevice[device].y
                                                    : grid.pos
                                                    ? grid.pos[device].y
                                                    : grid.y,
                                        });
                                    });
                                }
                            });
                        }
                    }

                    // update store
                    dispatch(updateGridItemPos(widgetID, newPos));

                    // update back
                    setMutation(`
                                mutation{
                                    updateContentPos(
                                        designID: ${id}
                                        contentID: ${
                                            type === SCREEN.TYPE.WELCOME ? cScreenData.data.contentId : screenid
                                        }
                                        pos: ${JSON.stringify(JSON.stringify(cItemsPos))}
                                    ){ error ok id }}
                            `);

                    // if (type === SCREEN.TYPE.WELCOME) {
                    //     const updatedSequence = cScreenData.welcome.sequence.map((screen) => {
                    //         if (screen.contentId === cScreenData.data.contentId) {
                    //             return { ...screen, content: { ...screen.content, pos: JSON.stringify(cItemsPos) } };
                    //         } else {
                    //             return screen;
                    //         }
                    //     });
                    //     dispatch(
                    //         setCurrentScreenData({
                    //             ...cScreenData,
                    //             data: { ...cScreenData.data, pos: JSON.stringify(cItemsPos) },
                    //             welcome: { ...cScreenData.welcome, sequence: [...updatedSequence] },
                    //         })
                    //     );
                    // }

                    if (gridItems.length > gridLayout.length && type === SCREEN.TYPE.PORTAL) {
                        dispatch(setGridLayout(gridLayout));
                    }

                    executeQuery.hideLoading = true;
                    executeQuery.mutationName = "updateContentPos";
                    executeQuery.newPos = cItemsPos;
                    break;
                case "updateContainerWidgetPos":
                    let containerPosis = null;
                    if (executeQuery.params.fullPos) {
                        containerPosis = executeQuery.params.fullPos;
                    } else {
                        dispatch(
                            updateContainerItemPos(
                                executeQuery.params.containerId,
                                executeQuery.params.widgetID,
                                executeQuery.params.widgetPos
                            )
                        );

                        containerPosis = JSON.parse(
                            cScreenData.data.contentWidgets.filter(
                                (item) => item.ID === parseInt(executeQuery.params.containerId)
                            )[0].pos
                        );

                        //pongo las posicions actuales de los widgets para el dispositivo que edito

                        containerPosis.map((containerItem) =>
                            containerItem.ID === parseInt(executeQuery.params.widgetID)
                                ? ((containerItem.H = executeQuery.params.widgetPos.h),
                                  (containerItem.W = executeQuery.params.widgetPos.w),
                                  (containerItem.X = executeQuery.params.widgetPos.x),
                                  (containerItem.Y = executeQuery.params.widgetPos.y))
                                : null
                        );
                    }
                    setMutation(`
                                mutation{
                                    updateContentPos(
                                        designID: ${id}
                                        contentID: ${
                                            type === SCREEN.TYPE.WELCOME ? cScreenData.data.contentId : screenid
                                        }
                                        containerID: ${executeQuery.params.containerId}
                                        pos: ${JSON.stringify('{"pos":' + JSON.stringify(containerPosis) + "}")} 
                                    ){ error ok id }}
                            `);
                    executeQuery.hideLoading = true;
                    executeQuery.mutationName = "updateContentPos";
                    executeQuery.newPos = cItemsPos;
                    break;
                case "updateWidgetData":
                    //prevent updatewidget queries on Editor Preview mode
                    let actions = "";
                    if (editorMode === "PREVIEW" || !id) {
                        dispatch(setExecuteQuery(null));
                        return;
                    }
                    if (executeQuery.params.actions) {
                        actions = `actions : ${JSON.stringify(JSON.stringify(executeQuery.params.actions))}`;
                    }

                    setMutation(`
                                mutation{
                                    updateWidget(
                                        designID: ${id}
                                        contentID: ${
                                            type === SCREEN.TYPE.WELCOME ? cScreenData.data.contentId : screenid
                                        }
                                        contentType: ${type}
                                        widgetID : ${executeQuery.params.widgetID}
                                        ${actions}
                                        data :  ${JSON.stringify(JSON.stringify(newValue))}

                                    ){ error ok id ${
                                        executeQuery?.params?.type === "TVMENUNAV"
                                            ? "data widgetStyle actions type"
                                            : ""
                                    } 
                                     }}
                            `);

                    executeQuery.hideLoading = true;
                    executeQuery.mutationName = "updateWidget";
                    if (executeQuery?.params?.type) {
                        executeQuery.type = executeQuery?.params?.type;
                    }
                    break;
                case "updateWidgetActions":
                    setMutation(`
                                mutation{
                                    updateWidget(
                                        designID: ${id}
                                        contentID: ${
                                            type === SCREEN.TYPE.WELCOME ? cScreenData.data.contentId : screenid
                                        }
                                        contentType: ${type}
                                        widgetID : ${executeQuery.params.widgetID}
                                        actions :  ${JSON.stringify(JSON.stringify(executeQuery.params.actions))}
                                    ){ error ok id }}
                            `);
                    executeQuery.hideLoading = true;
                    executeQuery.mutationName = "updateWidget";
                    break;
                case "updateWidgetStyle":
                    if (!id) {
                        dispatch(setExecuteQuery(null));
                        return;
                    }
                    setMutation(`
                                mutation{
                                    updateWidget(
                                        designID: ${id}
                                        contentID: ${
                                            type === SCREEN.TYPE.WELCOME ? cScreenData.data.contentId : screenid
                                        }   
                                        contentType: ${type}
                                        widgetID : ${executeQuery.params.widgetID}
                                        widgetStyle :  ${JSON.stringify(JSON.stringify(newValue))}

                                    ){ error ok id }}
                            `);
                    executeQuery.hideLoading = true;
                    executeQuery.mutationName = "updateWidget";
                    break;
                case "updateContentDevices":
                    mutationDeviceContent =
                        "[" +
                        executeQuery.params.devices
                            .map((d) => {
                                return `{ type: "${d.type}", name: "${d.name}", orientation: "${d.orientation}", res: { h: ${d.res.h}, w: ${d.res.w} }, grid: { cols: ${d.grid.cols}, rows: ${d.grid.rows} } }`;
                            })
                            .join(" ") +
                        "]";
                    setMutation(`
                            mutation{
                                updateContent(
                                    devices: ${mutationDeviceContent}
                                    designID: ${id}
                                    id: ${type === SCREEN.TYPE.WELCOME ? cScreenData.data.contentId : screenid}
                                ){ error ok }}
                        `);
                    executeQuery.hideLoading = true;
                    executeQuery.mutationName = "updateContent";
                    break;
                case "updateContentStyle":
                    if (!id) {
                        dispatch(setExecuteQuery(null));
                        return;
                    }
                    setMutation(`
                            mutation{
                                updateContent(
                                    contentStyle: ${JSON.stringify(JSON.stringify(executeQuery.params.contentStyle))}
                                    designID: ${id}
                                    id: ${type === SCREEN.TYPE.WELCOME ? cScreenData.data.contentId : screenid}
                                ){ error ok }}
                        `);
                    executeQuery.hideLoading = true;
                    executeQuery.mutationName = "updateContent";
                    break;
                case "duplicateWidget":
                    const generateCoordToWidgetInContainer = positionWidgetCloneInContainer();

                    if (generateCoordToWidgetInContainer) {
                        const { isInvalidClone } = generateCoordToWidgetInContainer;

                        if (isInvalidClone) {
                            toast.error(t("place-into-edition-area"));
                            return;
                        }
                    }

                    const findWidget = gridLayout.filter((item) => item.i === String(executeQuery.params.widgetID));
                    if (findWidget.length) {
                        const { isInvalidClone } = positionWidgetClone(
                            findWidget[0],
                            String(executeQuery.params.widgetID)
                        );

                        if (isInvalidClone) {
                            toast.error(t("place-into-edition-area"));
                            return;
                        }
                    }

                    setMutation(`
                            mutation{
                                cloneWidget(
                                    designID: ${id}
                                    contentID: ${type === SCREEN.TYPE.WELCOME ? cScreenData.data.contentId : screenid}
                                    contentType: ${type}
                                    widgetID : ${executeQuery.params.widgetID}
                                ){ error id ok }}
                        `);
                    executeQuery.hideLoading = true;
                    executeQuery.mutationName = "cloneWidget";
                    break;
                case "add-design":
                    //for now, the user is added to the current project
                    currentDate = dateString(new window.ZDate());
                    allProperties =
                        actionData.values["properties"] &&
                        actionData.values["properties"].filter((property) => property === "all").length > 0;

                    projectData = "";
                    if (
                        !allProperties &&
                        actionData.values["properties"] &&
                        actionData.values["properties"].length > 0
                    ) {
                        projectData = actionData.values["properties"].map(function (a) {
                            return `"${a}"`;
                        });
                    }
                    setMutation(`
                        mutation{
                            createDesign(
                                name: "${escapeSpecialChars(actionData.values["design-name"])}"
                                type: "${actionData.values["design-type"]}"
                                currentDate:"${currentDate}"
                                ${projectData ? `visibleAllProjectRef :${projectData}` : ""}
                                ${
                                    actionData.values["design-isAssigned"] &&
                                    actionData.values["design-isAssigned"] !== ""
                                        ? `isAssigned: ${actionData.values["design-isAssigned"]}`
                                        : ""
                                }
                                ${
                                    actionData.values["visibleAll"] && actionData.values["visibleAll"] !== ""
                                        ? `visibleAll: ${actionData.values["visibleAll"]}`
                                        : ""
                                }
                                ${
                                    projectData && actionData.values["design-assignedAllProject"]
                                        ? `assignedAllProjectRef :${projectData}`
                                        : ""
                                }
                                ${allProperties ? `visibleAll : true` : ``}
                                ${
                                    allProperties && actionData.values["design-assignedAllProject"]
                                        ? `assignedAll : true`
                                        : `assignedAll : false`
                                }
                                ${
                                    actionData.values["design-themeId"] &&
                                    actionData.values["design-themeId"] !== "" &&
                                    actionData.values["design-themeId"].length > 0
                                        ? `themeID: ${actionData.values["design-themeId"]}`
                                        : ""
                                }
                            ){ error id ok }}
                    `);
                    executeQuery.mutationName = "createDesign";
                    executeQuery.cleanSortAndFilters = true;
                    removeApolloCacheKeys(client.cache, "designs");
                    dispatch(showGlobalLoading(true));
                    break;
                case "edit-design":
                    //for now, the user is added to the current project.
                    allProperties =
                        actionData.values["properties"] &&
                        actionData.values["properties"].filter((property) => property === "all").length > 0;

                    projectData = "";
                    currentDate = moment().format("YYYY-MM-DDTHH:mm:ss") + "Z";
                    if (
                        !allProperties &&
                        actionData.values["properties"] &&
                        actionData.values["properties"].length > 0
                    ) {
                        projectData = actionData.values["properties"].map(function (a) {
                            return `"${a}"`;
                        });
                    }
                    setMutation(`
                        mutation{
                            updateDesign(
                                ID: ${executeQuery.params.value.id}
                                name: "${escapeSpecialChars(actionData.values["design-name"])}"
                                currentDate:"${currentDate}"
                                ${projectData ? `visibleAllProjectRef :${projectData}` : `visibleAllProjectRef :""`}
                                ${
                                    actionData.values["design-isAssigned"] &&
                                    actionData.values["design-isAssigned"] !== ""
                                        ? `isAssigned: ${actionData.values["design-isAssigned"]}`
                                        : ""
                                }
                                ${
                                    actionData.values["visibleAll"] && actionData.values["visibleAll"] !== ""
                                        ? `visibleAll: ${actionData.values["visibleAll"]}`
                                        : ""
                                }
                                ${
                                    projectData && actionData.values["design-assignedAllProject"]
                                        ? `assignedAllProjectRef :${projectData}`
                                        : `assignedAllProjectRef : ""`
                                }
                                visibleAll : ${allProperties ? true : false}
                                
                                ${
                                    allProperties && actionData.values["design-assignedAllProject"]
                                        ? `assignedAll : true`
                                        : `assignedAll : false`
                                }
                                ${
                                    actionData.values["design-themeId"] &&
                                    actionData.values["design-themeId"][0] &&
                                    actionData.values["design-themeId"][0] !== ""
                                        ? `themeID: ${actionData.values["design-themeId"][0]}`
                                        : ""
                                }
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "updateDesign";
                    dispatch(showGlobalLoading(true));
                    break;
                case "delete-design":
                    setMutation(`
                        mutation{
                            deleteDesign(
                                id: "${actionData.itemsAffected[0]}"
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "deleteDesign";
                    dispatch(showGlobalLoading(true));
                    break;
                case "duplicate-design":
                    setMutation(`
                        mutation{
                            duplicateDesign(
                                designID: ${actionData.itemsAffected[0]}
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "duplicateDesign";
                    dispatch(showGlobalLoading(true));
                    break;
                case "unapply-design":
                    currentDate = moment().format("YYYY-MM-DDTHH:mm:ss") + "Z";
                    setMutation(`
                        mutation{
                            updateDesign(
                                ID: ${actionData.itemsAffected[0]}
                                currentDate:"${currentDate}"
                                isAssigned:false
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "updateDesign";
                    dispatch(showGlobalLoading(true));
                    break;
                case "save-template":
                    let translations = "";
                    Object.entries(actionData.values).map((item) =>
                        item[0] !== "undefined" && item[0] !== "defaultLang"
                            ? (translations += `{lang:"${item[0]}" text:"${escapeSpecialChars(item[1])}"}`)
                            : null
                    );
                    setMutation(`
                    mutation{
                        createTemplate(
                            contentID: ${executeQuery.params.value.contentID}
                            name: [${translations}]
                            type: ${executeQuery.params.value.contentType}
                        ){ error  ok }}
                `);
                    executeQuery.mutationName = "createTemplate";
                    executeQuery.customSuccessText = "Template has been saved succesfully";
                    removeApolloCacheKeys(client.cache, "templates");
                    break;
                case "content-edit-folder-name":
                    setMutation(`
                        mutation{
                            updateDir(
                                id: "${executeQuery.params.value.id}"
                                designID: "${executeQuery.params.value.designID}"
                                name: "${escapeSpecialChars(actionData.values["name"])}"
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "updateDir";
                    removeApolloCacheKeys(client.cache, "contentTree");
                    break;
                case "content-folder-delete":
                    setMutation(`
                        mutation{
                            deleteDir(
                                id: "${executeQuery.params.value.id}"
                                designID: "${executeQuery.params.value.designID}"
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "deleteDir";
                    removeApolloCacheKeys(client.cache, "contentTree");
                    break;
                case "content-add-folder":
                    setMutation(`
                        mutation{
                            createDir(
                                type: ${executeQuery.params.value.category}
                                designID: "${executeQuery.params.value.designID}"
                                name: "${escapeSpecialChars(actionData.values["name"])}"
                                ${
                                    executeQuery.params.value.parentID && executeQuery.params.value.parentID !== ""
                                        ? ` parentID: ${executeQuery.params.value.parentID}`
                                        : ""
                                }
                                ${
                                    executeQuery.params.value.pos && executeQuery.params.value.pos !== ""
                                        ? ` pos: ${executeQuery.params.value.pos}`
                                        : ""
                                }
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "createDir";
                    removeApolloCacheKeys(client.cache, "contentTree");
                    break;
                case "content-move-to":
                    setMutation(`
                        mutation{
                            updateContent(
                                id: ${executeQuery.params.value.contentID}
                                dirID: "${actionData.values["folder"]}"
                                designID: ${executeQuery.params.value.designID}
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "updateContent";
                    removeApolloCacheKeys(client.cache, "contentTree");
                    break;
                case "add-welcome-screen":
                case "welcome-edit-name":
                case "move-welcomescreen-content":
                case "publish-welcome-as-default":
                case "duplicate-welcome-content":
                case "welcome-delete":
                case "delete-welcome-content":
                case "duplicate-welcome-screen":
                    const welcomeExecuteQuery = setWelcomeMutation(executeQuery, setMutation, {
                        ...actionData,
                        lang: cScreenData.langs[0].languageRef,
                        texts: t("select-lang"),
                        managerLang: lang,
                    });
                    if (welcomeExecuteQuery.hideLoading) {
                        executeQuery.hideLoading = true;
                    }
                    if (welcomeExecuteQuery.noRefresh) {
                        executeQuery.refresh = false;
                    }
                    if (welcomeExecuteQuery.noExecute) {
                        execute = false;
                    }

                    executeQuery.mutationName = welcomeExecuteQuery.mutationName;
                    break;
                default:
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (!showGlobalLoading) dispatch(setLoading(true));
            if (!executeQuery.hideLoading) {
                dispatch(showGlobalLoading(true));
            }

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].isOk || data[executeQuery.action].ok)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].isOk || data[executeQuery.mutationName].ok)))
        ) {
            const { action = null, mutationName = null } = executeQuery;

            if (executeQuery.action === "updateWidgetData" && executeQuery.type === "TVMENUNAV") {
                if (cScreenData?.data?.contentWidgets) {
                    cScreenData.data.contentWidgets.forEach((w) => {
                        if (w.type === "TVMENU") {
                            w.contentWidgets.forEach((wMenu) => {
                                if (wMenu.ID === data[executeQuery.mutationName].id) {
                                    wMenu.actions = data[executeQuery.mutationName].actions;
                                    wMenu.data = data[executeQuery.mutationName].data;
                                    wMenu.widgetStyle = data[executeQuery.mutationName].widgetStyle;
                                    dispatch(setCurrentScreenData(cScreenData));
                                }
                            });
                        }
                    });
                }
            }

            if (action === "duplicateWidget" && mutationName === "cloneWidget") {
                const widgetIdClone = data[mutationName]?.id || null;
                if (widgetIdClone) {
                    setTimeout(() => {
                        dispatch(setItemSelected(widgetIdClone));
                        dispatch(setWidgetToolbarVisible(true));
                    }, 2750);
                }
            }

            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }
            if (!executeQuery.params || (executeQuery.params && !executeQuery.params.noCleanAction)) {
                dispatch(cleanAction());
            }
            // refresh Content Data and clear cache
            const mutationsWithReload = ["updateWelcomeScreen"];

            if (mutationsWithReload.includes(executeQuery.mutationName)) {
                dispatch(setRefreshContentData(true));
                removeApolloCacheKeys(client.cache, "designs");
                removeApolloCacheKeys(client.cache, "contentTree");
            }
            if (executeQuery.mutationName === "deleteWelcomeScreenContent") {
                if (cScreenData.data.contentId === executeQuery.params.value.contentId) {
                    //delete same content than selected so reload welcome
                    const contentIdToLoad = cScreenData.welcome.sequence.filter(
                        (screen) => screen.contentId !== executeQuery.params.value.contentId
                    );
                    history(
                        `/design/${cScreenData.data.designId}/screen/WELCOME/${contentIdToLoad[0].contentId}/${cScreenData.langs[0].languageRef}/${cScreenData.data.welcomeId}`
                    );
                    dispatch(setRefreshContentData(true));
                }
            }
            // refresh store DATA in reducer for welcomes
            if (executeQuery.mutationName === "moveWelcomeScreenContent") {
                // save order modification in sequence
                dispatch(
                    setCurrentScreenData({
                        ...cScreenData,
                        welcome: {
                            ...cScreenData.welcome,
                            sequence: executeQuery.params.newArrayPos,
                        },
                    })
                );
            }

            if (executeQuery.mutationName === "deleteDesign" || executeQuery.mutationName === "duplicateDesign") {
                removeApolloCacheKeys(client.cache, "designs");
            }

            if (executeQuery.action === "createWidget" || executeQuery.action === "createWidgets") {
                if (addedWidgets.widgets && addedWidgets.widgets.length > 0) {
                    addedWidgets.widgets.map((w, i) => {
                        const item = {
                            i: `${
                                executeQuery.action === "createWidget" ? data.createWidget.id : data.createWidgets.id[i]
                            }`,
                            pos: w.layoutPos,
                            deviceType: addedWidgets.activeDevice,
                            type: w.type,
                            data: w.data,
                            style: w.widgetStyle,
                            isDraggable: true,
                            isResizable: true,
                            resizeHandles: ["se"],
                        };
                        if (!gridItems.some((gItem) => gItem.i === item.i)) {
                            dispatch(addGridItem(item, addedWidgets.containerID));
                        }
                        return w;
                    });
                }
                setAddedWidgets(null);
                dispatch(setExecuteQuery(null));
                return;
            } else if (executeQuery.action === "content-delete") {
                // if (parseInt(executeQuery.params.value.contentID) === parseInt(screenid)) {
                //     const lang = langParam || currentLang || "en";

                //     const idToRedirect = GetCustomizedScreenIDToRedirect(screenid);

                //     if (executeQuery.params.value.contentType === "GENERAL" && idToRedirect !== undefined) {
                //         history(
                //             `/design/${id}/screen/${type}/${idToRedirect}/${lang}${landingid ? `/${landingid}` : ""}`
                //         );
                //     } else {
                //         history(`/design/${id}`);
                //     }

                //     //quito de cache de apollo la key contentree para que refresque el listado de screens
                //     removeApolloCacheKeys(client.cache, "contentTree");
                //     dispatch(setExecuteQuery(null));
                //     dispatch(setCurrentDevice(null));
                //     return;
                // }
                removeApolloCacheKeys(client.cache, "contentTree");
                dispatch(setExecuteQuery(null));
                dispatch(setCurrentDevice(null));
                if (id) {
                    history(`/design/${id}`);
                }
            } else if (executeQuery.action === "deleteWidget") {
                dispatch(removeGridItem(executeQuery.params.widgetID));
                dispatch(setExecuteQuery(null));
                return;
            } else if (executeQuery.action === "updateWidgetPos") {
                dispatch(updateScreenProperty("pos", JSON.stringify(executeQuery.newPos)));
                dispatch(setExecuteQuery(null));
                if (cloningGridItemId) {
                    dispatch(setRefreshContentData(true));
                    dispatch(cloningGridItem(null));
                    dispatch(showGlobalLoading(false));
                }
                return;
            } else if (executeQuery.action === "updateContainerWidgetPos") {
                if (executeQuery.params.fullPos) {
                    dispatch(setExecuteQuery(null));
                    dispatch(showGlobalLoading(true));
                    dispatch(setRefreshContentData(true));
                    return;
                }
                dispatch(
                    updateGridItemProperty(executeQuery.params.widgetID, "pos", JSON.stringify(executeQuery.newPos))
                );
                dispatch(setExecuteQuery(null));
                if (cloningGridItemId) {
                    dispatch(setRefreshContentData(true));
                    dispatch(cloningGridItem(null));
                    dispatch(showGlobalLoading(false));
                }
                return;
            } else if (executeQuery.action === "updateContentStyle") {
                dispatch(setScreenContentStyle(executeQuery.params.contentStyle));
                dispatch(setExecuteQuery(null));
                return;
            } else if (executeQuery.action === "updateContentDevices") {
                const cdevice = executeQuery.params.devices.filter((d) => d.type === cDevice.type);
                dispatch(setCurrentDevice(cdevice && cdevice.length > 0 ? { ...cdevice[0] } : null));
                dispatch(setExecuteQuery(null));
                if (executeQuery.params.changes) {
                    updateItemsPos(cDevice.type, executeQuery.params.changes);
                }
                return;
            } else if (executeQuery.action === "updateWidgetActions") {
                if (executeQuery.params.property) {
                    dispatch(
                        updateGridItemActions(
                            executeQuery.params.widgetID,
                            executeQuery.params.property,
                            executeQuery.params.value
                        )
                    );
                } else {
                    dispatch(
                        updateGridItemActions(executeQuery.params.widgetID, "actions", executeQuery.params.actions)
                    );
                }
                dispatch(prepareUpdates({ screensSidebar: true }));
                dispatch(setExecuteQuery(null));
                return;
            } else if (executeQuery.action === "updateWidgetData") {
                if (executeQuery.params.property) {
                    dispatch(
                        updateGridItemProperty(
                            executeQuery.params.widgetID,
                            executeQuery.params.property,
                            executeQuery.params.value
                        )
                    );
                    if (executeQuery.params.actions) {
                        dispatch(
                            updateGridItemActions(executeQuery.params.widgetID, "actions", executeQuery.params.actions)
                        );
                    }
                } else if (executeQuery.params.actions) {
                    dispatch(
                        updateGridItemActions(executeQuery.params.widgetID, "actions", executeQuery.params.actions)
                    );
                } else {
                    dispatch(updateGridItemProperty(executeQuery.params.widgetID, "data", executeQuery.params.value));
                }

                if (executeQuery.params.changeContainerSize) {
                    updateContainerItemsPos(executeQuery.params.changeContainerSize);
                } else {
                    dispatch(setExecuteQuery(null));
                }

                return;
            } else if (executeQuery.action === "updateWidgetStyle") {
                if (executeQuery.params.property) {
                    dispatch(
                        updateGridItemStyle(
                            executeQuery.params.widgetID,
                            executeQuery.params.property,
                            executeQuery.params.value
                        )
                    );
                } else {
                    dispatch(
                        updateGridItemStyle(executeQuery.params.widgetID, "widgetStyle", executeQuery.params.value)
                    );
                }
                dispatch(setExecuteQuery(null));
                return;
            } else if (executeQuery.mutationName === "cloneWidget") {
                //añadir widget en layouts o containerLayouts
                dispatch(cloningGridItem(data[executeQuery.mutationName].id));
                dispatch(duplicateGridItem(executeQuery.params.widgetID, data[executeQuery.mutationName].id));

                dispatch(showGlobalLoading(true));

                //Actualizar posicion nuevo widget
                if (containerInEdition) {
                    const generateCoordToWidgetInContainer = positionWidgetCloneInContainer();

                    if (generateCoordToWidgetInContainer) {
                        const { isInvalidClone, idContainerLayout, widgetID, widgetPos } =
                            generateCoordToWidgetInContainer;

                        if (!isInvalidClone) {
                            dispatch(
                                setExecuteQuery({
                                    action: "updateContainerWidgetPos",
                                    params: {
                                        containerId: String(idContainerLayout),
                                        widgetID,
                                        widgetPos,
                                    },
                                })
                            );
                        }
                    }
                } else {
                    const findWidget = gridLayout.filter((item) => item.i === String(executeQuery.params.widgetID));
                    if (findWidget.length) {
                        const params = positionWidgetClone(findWidget[0], data[executeQuery.mutationName].id);
                        dispatch(
                            setExecuteQuery({
                                action: "updateWidgetPos",
                                params,
                            })
                        );
                    }
                }

                return;
            } else if (executeQuery.mutationName === "createContent" || executeQuery.mutationName === "createLanding") {
                let createdScreenID = data && data.createContent ? data.createContent.id : null;
                const createdLandingID = data && data.createLanding ? data.createLanding.id : null;
                if (createdLandingID && data && data.createLanding) {
                    createdScreenID = data.createLanding.contentId;
                }
                const createdData = actionData ? actionData.values : null;
                const createdScreenType =
                    createdData && createdData["content-type"] ? createdData["content-type"][0] : "GENERAL";
                const lang = langParam || "en";
                const isBlank = !createdData || createdData["template-id"] === -99;

                //TODO Mejorar todo este tema de las mutations
                // Lo de los timeouts es un apaño horrible, pero bueno... funciona
                let actions = [];
                actions.push(["open-new-content", 200]);
                if (createdScreenID || createdLandingID) {
                    // Add default widgets
                    if (isGeneralType(createdScreenType)) {
                        if (createdLandingID) {
                            actions.push(["add-rooms-and-menu-tv", 1200]);
                        } else {
                            actions.push(["add-topbar", 400]);
                        }
                        actions.push(["refresh", 1500]);
                    } else if (isBlank && isPWAType(createdScreenType)) {
                        if (createdLandingID) {
                            actions.push(["add-rooms", 1200]);
                        } else {
                            actions.push(["add-topbar", 400]);
                        }
                        actions.push(["refresh", 1500]);
                    } else if (isBlank && isTVType(createdScreenType)) {
                        if (createdLandingID) {
                            actions.push(["add-menu-tv", 400]);
                        }
                        actions.push(["refresh", 1400]);
                    } else if (isBlank && isPortalType(createdScreenType)) {
                        actions.push(["add-wifilogin", 1300]);
                        dispatch(setRefreshContentData(true));
                        actions.push(["refresh-open-mobile", 1500]);
                    } else {
                        actions.push(["refresh", 1500]);
                    }
                }

                let devices = null;
                // eslint-disable-next-line
                actions.map(([a, interval], i) => {
                    setTimeout(() => {
                        switch (a) {
                            case "refresh":
                                dispatch(setRefreshContentData(true));
                                break;
                            case "refresh-open-mobile":
                                dispatch(setRefreshContentData(true));
                                dispatch(cleanLayout());
                                dispatch(setCurrentDevice(GetScreenDeviceSizes({ deviceType: "Mobile" })));
                                break;
                            case "open-new-content":
                                dispatch(setCurrentDevice(null));
                                history(
                                    `/design/${id}/screen/${createdScreenType}/${createdScreenID}/${lang}${
                                        createdLandingID ? `/${createdLandingID}` : ""
                                    }`
                                );
                                break;
                            case "add-topbar":
                                dispatch(
                                    setExecuteQuery({
                                        action: "createWidget",
                                        params: {
                                            widgetType: "TOPBAR",
                                            deviceType: "Mobile",
                                            widgetPos: { x: 0, y: 0, w: 6, h: 1 },
                                        },
                                    })
                                );
                                break;
                            case "add-rooms-and-menu-tv":
                                devices = GetScreenDeviceSizes({ deviceType: "TV" });
                                dispatch(
                                    setExecuteQuery({
                                        action: "createWidgets",
                                        params: {
                                            deviceType: "TV",
                                            widgets: [
                                                {
                                                    widgetType: "TVMENU",
                                                    widgetPos: { x: 0, y: 0, w: devices.grid.cols, h: 5 },
                                                },
                                                {
                                                    widgetType: "TOPBAR",
                                                    widgetPos: { x: 0, y: 0, w: 6, h: 1 },
                                                },
                                                {
                                                    widgetType: "ROOMS",
                                                    widgetPos: { x: 0, y: 0, w: 6, h: 1 },
                                                },
                                            ],
                                        },
                                    })
                                );
                                break;
                            case "add-menu-tv":
                                devices = GetScreenDeviceSizes({ deviceType: "TV" });
                                dispatch(
                                    setExecuteQuery({
                                        action: "createWidget",
                                        params: {
                                            widgetType: "TVMENU",
                                            deviceType: "TV",
                                            widgetPos: { x: 0, y: 0, w: devices.grid.cols, h: 5 },
                                        },
                                    })
                                );
                                break;
                            case "add-rooms":
                                dispatch(
                                    setExecuteQuery({
                                        action: "createWidgets",
                                        params: {
                                            deviceType: "Mobile",
                                            widgets: [
                                                {
                                                    widgetType: "TOPBAR",
                                                    widgetPos: { x: 0, y: 0, w: 6, h: 1 },
                                                },
                                                {
                                                    widgetType: "ROOMS",
                                                    widgetPos: { x: 0, y: 0, w: 6, h: 1 },
                                                },
                                            ],
                                        },
                                    })
                                );
                                break;
                            case "add-wifilogin":
                                dispatch(
                                    setExecuteQuery({
                                        action: "createWidgets",
                                        params: {
                                            deviceType: "Desktop",
                                            widgets: [
                                                {
                                                    widgetType: "TEXT",
                                                    layoutPos: {
                                                        Desktop: {
                                                            w: 100,
                                                            h: 10,
                                                            x: 70,
                                                            y: 5,
                                                        },
                                                        Mobile: {
                                                            x: 0,
                                                            y: 1,
                                                            h: 2,
                                                            w: 12,
                                                        },
                                                    },

                                                    data: {
                                                        text: {
                                                            en: `<div style="text-align:center; font-size: 1.8 vw;"><strong>Welcome to Zafiro Wi-Fi</strong></div>`,
                                                            es: `<div style="text-align:center; font-size: 1.8 vw;"><strong>Bienvenido a Zafiro Wi-Fi</strong></div>`,
                                                        },
                                                    },
                                                    style: {
                                                        bgColor: "rgba(255,255,255,0)",
                                                    },
                                                },
                                                {
                                                    widgetType: "TEXT",
                                                    layoutPos: {
                                                        Desktop: {
                                                            w: 100,
                                                            h: 10,
                                                            x: 70,
                                                            y: 15,
                                                        },
                                                        Mobile: {
                                                            x: 0,
                                                            y: 3,
                                                            h: 2,
                                                            w: 12,
                                                        },
                                                    },
                                                    data: {
                                                        text: {
                                                            en: `<div style="text-align:center; font-size: 1.4 vw;">Enter your credentials and get access to our Wi-Fi</div>`,
                                                            es: `<div style="text-align:center; font-size: 1.4 vw;">Ingrese sus credenciales y obtenga acceso a nuestro Wi-Fi</div>`,
                                                        },
                                                    },
                                                    style: {
                                                        bgColor: "rgba(255,255,255,0)",
                                                    },
                                                },
                                                {
                                                    widgetType: "WIFILOGIN",
                                                    layoutPos: {
                                                        Desktop: {
                                                            w: 100,
                                                            h: 100,
                                                            x: 70,
                                                            y: 30,
                                                        },
                                                        Mobile: {
                                                            x: 0,
                                                            y: 5,
                                                            h: 15,
                                                            w: 12,
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    })
                                );
                                break;
                            default:
                                break;
                        }
                    }, interval);
                    return false;
                });
                dispatch(setExecuteQuery(null));
                return;
            } else if (executeQuery.mutationName === "createWelcomeScreen") {
                const createdWelcomeID = data && data.createWelcomeScreen ? data.createWelcomeScreen.id : null;
                if (createdWelcomeID) {
                    const createdScreenID = data.createWelcomeScreen.contentId;
                    if (createdScreenID) {
                        const createdData = actionData ? actionData.values : null;
                        const createdScreenType =
                            createdData && createdData["content-type"] ? createdData["content-type"][0] : "GENERAL";
                        const lang = langParam || "en";
                        dispatch(setCurrentDevice(null));
                        setTimeout(() => {
                            history(
                                `/design/${id}/screen/${createdScreenType}/${createdScreenID}/${lang}${
                                    createdWelcomeID ? `/${createdWelcomeID}` : ""
                                }`
                            );
                        }, 100);
                    }
                }

                // dispatch(setTargetId(data.createDesign.id));
                dispatch(setRefreshContentData(true));
            } else if (executeQuery.mutationName === "createPredefinedScreen") {
                const createPredefinedScreenID =
                    data && data.createPredefinedScreen ? data.createPredefinedScreen.id : null;
                if (createPredefinedScreenID) {
                    if (createPredefinedScreenID) {
                        const lang = langParam || "en";
                        dispatch(setCurrentDevice(null));
                        setTimeout(() => {
                            history(
                                `/design/${id}/screen/${
                                    executeQuery?.contentType === "SALESMOBILE" ? "SALESMOBILE" : "SALES"
                                }/${createPredefinedScreenID}/${lang}`
                            );
                        }, 100);
                    }
                }

                // dispatch(setTargetId(data.createDesign.id));
                dispatch(setRefreshContentData(true));
            } else if (executeQuery.mutationName === "createDesign") {
                dispatch(setTargetId(data.createDesign.id));
            } else if (
                executeQuery.mutationName === "createDir" ||
                executeQuery.mutationName === "deleteDir" ||
                executeQuery.mutationName === "updateDir"
            ) {
                const actions = [];
                if (
                    executeQuery &&
                    executeQuery.params &&
                    executeQuery.params.value &&
                    executeQuery.params.value.redirect
                ) {
                    actions.push(["open-new-content", 200, executeQuery.params.value.redirect]);
                    actions.push(["refresh", 1200]);
                }
                actions.map(([a, interval, param], i) => {
                    setTimeout(() => {
                        switch (a) {
                            case "refresh":
                                dispatch(setRefreshContentData(true));
                                break;
                            case "open-new-content":
                                dispatch(setCurrentDevice(null));
                                history(param);
                                break;
                            default:
                                break;
                        }
                    }, interval);
                    return false;
                });
            }
            toast.success(t(executeQuery.customSuccessText ? executeQuery.customSuccessText : "operation-successful"));
            if (executeQuery.refresh) {
                dispatch(setRefreshContentData(true));
            }
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
        } else if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].errorMsg || data[executeQuery.action].error)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].errorMsg || data[executeQuery.mutationName].error)))
        ) {
            const errorMsg =
                data[executeQuery.action] && data[executeQuery.action].error
                    ? data[executeQuery.action].error
                    : data[executeQuery.mutationName] && data[executeQuery.mutationName].error
                    ? data[executeQuery.mutationName].error
                    : null;
            toast.error(errorMsg ? errorMsg : t("mutation-error"));
            dispatch(showGlobalLoading(false));
            dispatch(setExecuteQuery(null));
            dispatch(setRefreshContentData(true));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (itemSelected !== null) {
            setSavePrevItemSelected(itemSelected);
        }
    }, [itemSelected]);

    return null;
};

export default withApollo(UpdateDesign);
