import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseButton from "../useButton";
//Actions
import { showRowForm, setExecuteQuery, setHiddenRows } from "../../actions/sectionActions";
import { cleanAction, setActionValidations } from "../../actions/globalActions";
import { showGlobalLoading } from "../../actions/uiActions";
//Utils
import { toast } from "react-toastify";
import UseEditableField from "../useEditableField";
import { validateSection } from "../../hooks/Utils/Validations";
import { GetLanguageOptions } from "../../hooks/Utils/LanguagesUtils";
import { bdDateToDateInput } from "../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";

const useFormCompanion = () => {
    const { t } = useTranslation();
    const { rowFormContent, results } = useSelector((state) => state.table);

    const { values, actionName, itemsAffected } = useSelector((state) => state.action);
    const sectionContentData = useSelector((state) => state.sectionContent.data);

    //State
    const [inEditionMode, setInEditionMode] = useState(true);

    //Actions
    const dispatch = useDispatch();

    const rowFormClickHandler = (e) => {
        const btnActionName = e.currentTarget.dataset.action;

        if (btnActionName !== "save") {
            dispatch(showRowForm(false));
            dispatch(cleanAction());
            dispatch(setHiddenRows(true));
        } else {
            //Validate fields
            const validations = validateSection("add-companion", values, t);

            dispatch(setActionValidations(validations));
            const validationsArray = Object.values(validations);

            if (validationsArray.filter((item) => item === false).length > 0) {
                toast.error(t("errors-in-red"));
                return;
            }
            if (actionName === "edit-companion") {
                const companion = sectionContentData.companions.filter((comp) => comp.id === itemsAffected)[0];
                dispatch(
                    setExecuteQuery({
                        action: "updateGuest",
                        params: {
                            stayGuestRoomID: companion.rooms[0].StayGuestRoomID,
                            oldRoomID: companion.rooms[0].roomID,
                            oldEmail: companion.email,
                            rooms: sectionContentData.rooms,
                        },
                    })
                );
            } else {
                dispatch(
                    setExecuteQuery({
                        action: "checkIn",
                        params: {
                            rooms: sectionContentData.rooms,
                        },
                    })
                );
            }
            dispatch(showGlobalLoading(true));
            setInEditionMode(false);
            dispatch(showRowForm(false));
            dispatch(setHiddenRows(true));
        }
    };

    const prepareOptions = (name) => {
        let options = [];
        switch (name) {
            case "room":
                sectionContentData.rooms.map((room) => options.push({ id: room.RoomID, name: String(room.number) }));
                break;
            case "lang":
                options = GetLanguageOptions();
                break;
            default:
                break;
        }
        return options;
    };

    const setValue = (name) => {
        let initVal = "";
        if (actionName === "edit-companion") {
            results.filter((result) =>
                result.id === itemsAffected
                    ? result.info.map((cellData) =>
                          cellData.name === name
                              ? (initVal = cellData.value)
                              : name === "birthday" && cellData.name === "age"
                              ? (initVal = bdDateToDateInput(cellData.birthday))
                              : null
                      )
                    : null
            );
        } else {
            if (name === "title") {
                // initVal = "Mr";
            }
        }

        return initVal;
    };

    const setSelectedIds = (name) => {
        let options = null;

        switch (name) {
            case "room":
                options = prepareOptions(name);
                break;
            case "lang":
                options = GetLanguageOptions(name);
                break;

            default:
                break;
        }

        let ids = [];
        let valToSelect = null;
        if (actionName === "edit-companion") {
            results.filter((result) =>
                result.id === itemsAffected
                    ? result.info.map((cellData) =>
                          name === "room" && cellData.name === "roomnumber"
                              ? (valToSelect = cellData.value)
                              : name === "lang" && cellData.name === "language"
                              ? (valToSelect = cellData.value)
                              : null
                      )
                    : null
            );
        } else {
            //On new companion, if there is a single room, we mark it as selected
            if (name === "room" && options && options.length === 1) {
                ids.push(options[0].id);
            }
        }
        if (valToSelect && options) {
            options.forEach((option) => (String(option.name) === String(valToSelect) ? ids.push(option.id) : null));
        }
        return ids;
    };

    return (
        <>
            {rowFormContent ? (
                <div className="border-b border-gray-200">
                    <div className="w-full bg-white  h-16 flex items-center">
                        <span className="mr-4"></span>
                        {rowFormContent.inputs.map((input, index) => (
                            <UseEditableField
                                key={index}
                                data={{
                                    label: input.name,
                                    placeholder: input.name,
                                    inEditionMode: inEditionMode,
                                    type: input.type,
                                    optionData: prepareOptions(input.name),
                                    oneSelected: input.oneSelected,
                                    styleAdjust: `pr-2 ${input.style}`,
                                    value: setValue(input.name),
                                    selectedIds: setSelectedIds(input.name),
                                    noLabel: true,
                                    id: `${input.name}-use-form-companion-input`,
                                }}
                            />
                        ))}
                    </div>
                    <div className="w-full text-right inline-block whitespace-no-wrap pb-2 r-0 pr-2">
                        {rowFormContent.buttons.map((button, index) => (
                            <div
                                className={`inline-block ${index === 0 ? "pr-2" : ""} whitespace-normal`}
                                data-action={button.name}
                                key={index}
                                onClick={rowFormClickHandler}
                            >
                                <UseButton buttonName={button.name} buttonColor={button.color} />
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default useFormCompanion;
