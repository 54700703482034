import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { changeActionValues } from "actions/globalActions";

import Translations from "components/Translations";
import { useAuth } from "hooks/Session/auth";

const UseModalTranslations = ({ index }) => {
    const dispatch = useDispatch();
    const { languages: projectLangs } = useAuth();
    const { data, name } = useSelector((state) => state.ui?.modalContent?.inputs?.[index]);

    return (
        <Translations
            id="modal-translations-component"
            design="modal"
            languages={projectLangs ? projectLangs.map((lang) => lang.languageRef) : null}
            translations={
                data.translations
                    ? data.translations.map((item) => ({ languageCode: item.languageRef, name: item.value }))
                    : null
            }
            defaultLang={projectLangs ? projectLangs.find((lang) => lang.isDefault === true)?.languageRef : null}
            onChange={(translations) => {
                dispatch(
                    changeActionValues({
                        [name]: translations
                            ? translations.map((t) => ({
                                  languageRef: t.languageCode,
                                  value: t.name,
                              }))
                            : [],
                    })
                );
            }}
        />
    );
};

export default UseModalTranslations;
