import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import { EditorContext } from "../../../../../contexts/editor";
import { vw2px } from "../../../../../hooks/Utils/DesignUtils";
import TopBar from "./topbar";
import { capitalizeFirst } from "../../../../../hooks/Utils/Utils";
import { coverElement, highlightElement, coverColor } from "./utils";

const ShopProduct = ({ device: forceDeviceType, width: forceWidth, templating }) => {
    const { t: tr } = useTranslation();
    const { lang, deviceType, editorSize } = useContext(EditorContext);

    const t = (label, data) => {
        return tr(label, { lng: lang, ...(data || {}) });
    };

    const deviceProps = {
        device: forceDeviceType || deviceType,
        deviceWidth: forceWidth || editorSize?.width,
    };

    const size = (s) => vw2px(s, deviceProps.deviceWidth, 3) + "px";

    const css = templating?.css;

    const highlightActive = css?.highlight;
    const hlBackground = highlightActive === "general";
    const hlDetails = highlightActive === "details";
    const hlFooter = highlightActive === "section2";

    const details = css?.items?.details;
    const footer = css?.items?.general;
    const footerButton = css?.items?.button;

    const styles = {
        global: {
            fontFamily: templating?.textFont?.name,
            color: css?.fgColor,
            backgroundColor: highlightActive && !hlBackground ? coverColor(css?.bgColor) : css?.bgColor,
            textAlign: "left",
            width: "100%",
            minHeight: "100%",
            cursor: "default",
        },

        footer: {
            backgroundColor: footer?.bgColor,
            color: footer?.fgColor,
            padding: size(1.5),
            textAlign: "center",
        },
        orderButton: {
            display: "inline-block",
            fontSize: size(1.5),
            fontWeight: "bold",
            padding: size(0.75),
            minWidth: "80%",
            color: footerButton?.fgColor,
            background: footerButton?.bgColor,
            borderWidth: footerButton?.borderWidth + "px",
            borderRadius: footerButton?.radius + "rem",
            borderColor: footerButton?.borderColor,
        },
        units: {
            marginBottom: size(1),
            color: footer?.fgColor,
            display: "flex",
            justifyContent: "center",
            // marginTop: size(2),
        },
        unitsItem: {
            display: "inline-block",
            fontSize: size(1.4),
            lineHeight: size(1.4),
            padding: size(0.5),
            margin: "0 " + size(0.5),
            textAlign: "center",
            borderWidth: "1px",
            borderColor: "transparent",
        },
        unitsCtrl: {
            borderRadius: "0.25rem",
            borderColor: footer?.fgColor,
        },

        info: {
            fontSize: size(1.4),
            padding: size(1),
            color: "#323232",
            backgroundColor: highlightActive && hlDetails ? coverColor("#FBFBFB") : "#FBFBFB",
        },
        picture: {
            textAlign: "center",
            color: "#FFFFFF",
            background: "#91A3B5",
            fontSize: size(5),
            lineHeight: size(16),
            height: size(16),
        },
        description: {
            fontSize: size(1.25),
            paddingBottom: size(1),
            color: "rgb(118,136,155)",
        },
        allergens: {
            fontSize: size(1.25),
            lineHeight: size(3),
        },
        extra: {
            fontSize: size(1.25),
            display: "flex",
            justifyContent: "space-between",
        },
        label: {
            fontSize: size(1),
            paddingLeft: size(1),
            paddingRight: size(1),
            paddingTop: size(0.5),
            paddingBottom: size(0.5),
            margin: size(0.5),
            marginLeft: 0,
            color: details?.fgColor,
            backgroundColor: details?.bgColor,
            display: "inline-block",
            borderRadius: "0.5rem",
        },
    };

    const label = (name) => (
        <div
            style={{
                ...styles.label,
                ...(highlightActive && hlDetails ? highlightElement() : null),
            }}
        >
            {name}
        </div>
    );

    return (
        <div style={styles.global}>
            <div
                style={{
                    position: "sticky",
                    zIndex: 10,
                    top: 0,
                }}
            >
                <TopBar title={t("Product name")} device={deviceType} width={forceWidth} templating={templating} />
            </div>

            <div
                className="icon icon-image"
                style={{ ...styles.picture, ...(highlightActive ? coverElement() : null) }}
            ></div>

            <div
                style={{
                    textAlign: "center",
                    backgroundColor: highlightActive && hlDetails ? coverColor("#FBFBFB") : "#FBFBFB",
                    ...(highlightActive && !hlDetails ? coverElement() : null),
                }}
            >
                <div
                    style={{
                        display: "inline-block",
                        // backgroundColor: details?.fgColor,
                        borderRadius: ".5rem",
                        padding: size(0.25) + " " + size(0.5),
                        ...(highlightActive && hlDetails ? highlightElement() : null),
                    }}
                >
                    {[1, 2, 3, 4, 5].map((v) => (
                        <span
                            key={"dot_" + v}
                            style={{
                                display: "inline-block",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderColor: details?.bgColor,
                                backgroundColor: v == 1 ? details?.bgColor : "transparent",
                                width: size(0.75),
                                height: size(0.75),
                                lineHeight: size(0.75),
                                borderRadius: "100%",
                                margin: "0 " + size(0.25),
                            }}
                        ></span>
                    ))}
                </div>
            </div>

            <div
                style={{
                    marginBottom: size(0.75),
                    ...(highlightActive && hlBackground ? highlightElement("bottom") : null),
                }}
            >
                <div
                    style={{
                        ...styles.info,
                        paddingTop: 0,
                        ...(highlightActive && !hlDetails ? coverElement() : null),
                    }}
                >
                    <div style={{ ...(highlightActive ? coverElement() : null) }}>
                        {capitalizeFirst(t("product"))}
                        <div className="font-bold">00,00 €</div>
                        <div style={styles.description}>{capitalizeFirst(t("product description"))}</div>
                    </div>
                    {label(capitalizeFirst(t("label extra large")))}
                    {label(capitalizeFirst(t("label")))}
                    <div style={{ ...styles.allergens, ...(highlightActive ? coverElement() : null) }}>
                        <i
                            className="icon icon-info pr-2"
                            style={{ color: "#76889B", verticalAlign: "middle", fontSize: "1.4em" }}
                        ></i>
                        {t("allergens")}:{" "}
                        <i
                            className="icon icon-gluten pl-2"
                            style={{ color: "#D2AC6A", verticalAlign: "middle", fontSize: "1.4em" }}
                        ></i>
                    </div>
                </div>
            </div>

            <div
                style={{
                    marginBottom: size(0.5),
                    ...(highlightActive && hlBackground ? highlightElement() : null),
                }}
            >
                <div style={{ ...styles.info, ...(highlightActive && !hlDetails ? coverElement() : null) }}>
                    <div style={{ ...styles.extra, ...(highlightActive ? coverElement() : null) }}>
                        <span>
                            <i className="icon icon-chevron pr-3" style={{ verticalAlign: "middle" }}></i>
                            {t("Extra")}{" "}
                        </span>
                    </div>
                </div>
            </div>

            <div
                style={{
                    position: "sticky",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: "100%",
                    ...(highlightActive && (hlBackground || hlFooter)
                        ? highlightElement(hlBackground ? "top" : null)
                        : null),
                }}
            >
                <div style={{ ...styles.footer, ...(highlightActive && !hlFooter ? coverElement() : null) }}>
                    <div style={styles.units}>
                        <div
                            style={{
                                fontSize: size(1.5),
                                paddingTop: size(0.75),
                                paddingBottom: size(0.75),
                                paddingLeft: size(1),
                                paddingRight: size(1),
                                textAlign: "center",
                                borderWidth: "1px",
                                borderRadius: "0.25rem",
                                borderColor: footer?.fgColor,
                                color: footer?.fgColor,
                                marginBottom: ".5em",
                                minHeight: size(2.5),
                                maxHeight: size(2.5),
                                minWidth: size(2.5),
                                maxWidth: size(2.5),
                                overflow: "hidden",
                                marginRight: size(1),
                            }}
                            className="icon flex items-center justify-center icon-editor-remove"
                        >
                            <div className=" path2 text-5xl font-bold  "></div>
                        </div>
                        <div style={styles.unitsItem}>1</div>
                        <div
                            style={{
                                fontSize: size(1.5),
                                paddingTop: size(0.75),
                                paddingBottom: size(0.75),
                                paddingLeft: size(1),
                                paddingRight: size(1),
                                textAlign: "center",
                                borderWidth: "1px",
                                borderRadius: "0.25rem",
                                borderColor: footer?.fgColor,
                                color: footer?.fgColor,
                                marginBottom: ".5em",
                                minHeight: size(2.5),
                                maxHeight: size(2.5),
                                minWidth: size(2.5),
                                maxWidth: size(2.5),
                                overflow: "hidden",
                                marginLeft: size(1),
                            }}
                            className="icon flex items-center justify-center icon-editor-add-small"
                        >
                            <div className=" path1 text-5xl font-bold text-re "></div>
                        </div>
                    </div>
                    <div style={styles.orderButton}>{capitalizeFirst(t("add-to-cart"))}</div>
                </div>
            </div>
        </div>
    );
};

export default ShopProduct;
