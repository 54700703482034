import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { useEffect } from "react";
import {
    setError,
    setLoading,
    setTableResults,
    setCustomConfig,
    setSearchable,
    setCheckable,
    setItemsPerpage,
    cleanTableStore,
    setTableType,
    setActions,
    setAvailableFilters,
    setReload,
} from "../../../actions/tableActions";
import { useDispatch, useSelector } from "react-redux";
import Font from "./models/Font";
import { GetAvailableFilters } from "../../Utils/GetAvailableFilters";
import { useTranslation } from "react-i18next";
import { setSectionContent, showRowForm } from "../../../actions/sectionActions";
import { showGlobalLoading } from "../../../actions/uiActions";
import { POLLINTERVAL_15S } from "../../Utils/Utils";

const ListFonts = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { refreshData, filterQuery, reload } = useSelector((state) => state.table);

    const sectionName = "list-fonts-library";

    const GET_FONTS_LIST = gql`
        {
            libraryFonts ${filterQuery && `(${filterQuery})`} {
                response {
                    results {
                        contentType
                        items
                        lastModified
                        name
                        ref
                        size
                        styles {
                            contentType
                            lastModified
                            name
                            size
                            style
                        }
                    }
                }
            }
        }
    `;
    const [loadFontList, { data: fontList, error, loading, refetch }] = useLazyQuery(GET_FONTS_LIST, {
        pollInterval: POLLINTERVAL_15S,
    });

    // Listeners

    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(cleanTableStore());
        loadFontList();
        dispatch(setTableType(sectionName));
        const availableFilters = GetAvailableFilters(sectionName);
        dispatch(setAvailableFilters(availableFilters));
        dispatch(setSearchable(true));
        dispatch(setCheckable(true));
        dispatch(setItemsPerpage(15));
        dispatch(showRowForm(false));
        dispatch(
            setCustomConfig({
                searchStyle: "col-span-4 mb-2",
                tableBodyStyle: "mt-20",
                // pagination: search === "",
            })
        );
        return () => {
            dispatch(setItemsPerpage(6));
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (
            !loading &&
            !error &&
            fontList &&
            fontList.libraryFonts &&
            fontList.libraryFonts.response &&
            fontList.libraryFonts.response.results
        ) {
            dispatch(setSectionContent({ listFonts: fontList.libraryFonts.response.results }));
            const fonts = arrangeData(fontList.libraryFonts.response.results);
            dispatch(setTableResults(fonts));
            dispatch(setActions(true));
            dispatch(setLoading(false));
            dispatch(showGlobalLoading(false));
            dispatch(setReload(false));
        }
        // eslint-disable-next-line
    }, [fontList]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
        }
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        if (refreshData || reload) {
            if (refetch) {
                refetch();
            }
        }
        // eslint-disable-next-line
    }, [refreshData, reload]);

    // Functions

    const arrangeData = (fonts) => {
        const tempArray = [];
        // eslint-disable-next-line
        fonts.map((font) => {
            tempArray.push(Font(font, t));
        });
        return tempArray;
    };
};

export default ListFonts;
