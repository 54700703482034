import React, { useContext, Children, useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { setExecuteQuery } from "../../../../../../actions/sectionActions";
import { setWidgetToolbarVisible } from "../../../../../../actions/designActions";
import Settings from "./Settings";
import { EditorContext, WidgetContext } from "../../../../../../contexts/editor";

const Toolbar = ({ children, type, title, description, warning, disabled, parentSectionName }) => {
    const { deviceType, screenType, itemSelected } = useContext(EditorContext);
    const { id: widgetID, container } = useContext(WidgetContext);
    const sectionName = `${parentSectionName ? parentSectionName : "toolbar"}-${type}`;

    const dispatch = useDispatch();
    const { containersLayouts, gridItems, widgetToolbarVisible, widgetToolbarVisibleTab } = useSelector(
        (state) => state.design
    );

    const { permissions } = useSelector((state) => state.ui);
    const appsAvailable = permissions?.design?.apps ? true : false;

    const containerLayout = JSON.parse(
        JSON.stringify(
            containersLayouts.filter((layout) => parseInt(layout.i) === parseInt(widgetID))[0]
                ? containersLayouts.filter((layout) => parseInt(layout.i) === parseInt(widgetID))[0].layout
                : []
        )
    );

    const containerHasActions = container && container.actions ? container.actions.length > 0 : false;

    const toolbarContainerID = "widgetOptionContainer_" + widgetID;
    const [actionInChilds, setActionInChilds] = useState(false);

    const { t } = useTranslation();

    const appsNotAvailableValidation =
        appsAvailable === false &&
        type === "actions" &&
        (screenType === "CCPAIRINGSUCCESSFUL" || screenType === "CCINSTRUCTION");

    const isDisabled =
        disabled ||
        (type === "actions" &&
            ((deviceType !== "Mobile" && deviceType !== "TV") ||
                screenType === "WELCOME" ||
                screenType === "CCPAIRINGSUCCESSFUL" ||
                containerHasActions ||
                actionInChilds)) ||
        appsNotAvailableValidation;

    const id = type;
    const icon = getToolbarIcon(type);
    const action = getToolbarAction(type);

    const isOpen = itemSelected === widgetID && widgetToolbarVisible === id;

    const iconCSS =
        "relative p-2 icon " +
        icon +
        (isDisabled ? " opacity-50" : " cursor-pointer" + (isOpen ? " toolbarOptionSelected" : ""));
    const containerCSS = "widgetOptionContainer p-0 invisible min-w-120";

    const iconAction = () => {
        if (isDisabled) return false;
        if (action) {
            dispatch(setWidgetToolbarVisible(null));
            dispatch(setExecuteQuery({ action: action, params: { widgetID: widgetID } }));
        } else {
            dispatch(
                setWidgetToolbarVisible(
                    isOpen ? null : id,
                    widgetToolbarVisibleTab && widgetToolbarVisibleTab !== "regular"
                        ? "regular"
                        : widgetToolbarVisibleTab
                )
            );
        }
    };

    const checkActions = useCallback(() => {
        setActionInChilds(
            containerLayout &&
                containerLayout.filter((ci) => {
                    if (ci) {
                        const cw = gridItems && gridItems.filter((item) => item.i === ci.i)[0];
                        return cw && cw.actions && cw.actions.length > 0;
                    }
                    return false;
                }).length > 0
        );
    }, [containerLayout, gridItems]);

    useEffect(() => {
        checkActions();
        // eslint-disable-next-line
    }, [containerLayout, gridItems, itemSelected, checkActions]);

    return (
        <>
            {appsNotAvailableValidation ? null : (
                <div className={iconCSS} onClick={iconAction} id={sectionName}>
                    {warning ? (
                        <i
                            className="absolute icon icon-status-solid text-orange-100"
                            style={{ top: ".2rem", right: ".2rem", fontSize: ".5rem" }}
                        ></i>
                    ) : null}
                </div>
            )}
            {!isDisabled &&
            !action &&
            isOpen &&
            ((type === "info" && description) || Children.toArray(children).length > 0) ? (
                <div id={toolbarContainerID} className={containerCSS}>
                    {type === "info" && description ? (
                        <Settings toolbarID={id}>
                            <Settings.Group title={title || t("information")}>
                                <div className="w-104">{description}</div>
                            </Settings.Group>
                        </Settings>
                    ) : null}
                    {Children.toArray(children).map((ch) => {
                        const isReactElement = ch && typeof ch.type === "function";
                        return React.cloneElement(
                            ch,
                            !isReactElement
                                ? ch.props
                                : {
                                      type: type,
                                      toolbarID: id,
                                      ...ch.props,
                                  }
                        );
                    })}
                </div>
            ) : null}
        </>
    );
};

Toolbar.Tabs = ({ data, currentTab, setCurrentTab }) => {
    return (
        <div className="flex flex-row pt-2 justify-center bg-white sticky top-0 z-500">
            {data.map((tab) => {
                return (
                    <div
                        key={tab.id + "-tab-title"}
                        id={tab.id + "-tab-title"}
                        className={`w-1/2 text-center capitalize cursor-pointer p-4 border-b ${
                            currentTab === tab.id ? " border-zafiro-400 font-black" : ""
                        }`}
                        onClick={() => {
                            setCurrentTab(tab.id);
                        }}
                    >
                        {tab.name}
                    </div>
                );
            })}
        </div>
    );
};

const getToolbarAction = (type) => {
    switch (type) {
        case "clone":
            return "duplicateWidget";
        case "delete":
            return "deleteWidget";
        default:
            return null;
    }
};

const getToolbarIcon = (type) => {
    switch (type) {
        case "clone":
            return "icon-clone";
        case "delete":
            return "icon-delete-editor";
        case "info":
            return "icon-info";
        case "style":
            return "icon-color";
        case "layout":
            return "icon-layout";
        case "navigation":
            return "icon-widget-navigation";
        case "settings":
        case "text-settings":
            return "icon-settings-editor";
        case "actions":
            return "icon-action";
        default:
            return "icon-warning";
    }
};

export default Toolbar;
