export const CZSchedules = [
    "activated",
    "active",
    "add",
    "add-dates",
    "add-schedules",
    "and",
    "cancel",
    "cant-delete-last",
    "check",
    "closed-days",
    "closed-dayss",
    "common-schedule",
    "common-schedules",
    "continue-2",
    "continue-3",
    "dates",
    "dates-selected",
    "date-range",
    "date-specific",
    "date-type",
    "days",
    "day-1",
    "day-2",
    "day-3",
    "day-4",
    "day-5",
    "day-6",
    "day-7",
    "day-1-short",
    "day-2-short",
    "day-3-short",
    "day-4-short",
    "day-5-short",
    "day-6-short",
    "day-7-short",
    "delete",
    "deleted",
    "delete-schedule-text",
    "delete-schedule-title",
    "disable",
    "disabled",
    "edit",
    "enable",
    "erase",
    "exit-unsaved",
    "exit-wo-save",
    "finish-save",
    "first",
    "check form",
    "mutation-error",
    "name",
    "no-actions",
    "of",
    "ofo",
    "or",
    "punctual-event-text",
    "punctual-event-title",
    "periodic-event-text",
    "periodic-event-title",
    "please",
    "punctual-schedule",
    "range",
    "save",
    "save-and-close",
    "schedule",
    "schedules",
    "schedule-datetype-error",
    "schedule-days-error",
    "schedule-error",
    "choose-weekday",
    "schedule-range-error",
    "schedule-weekday-error",
    "special-schedule",
    "special-schedules",
    "view",
    "week-days",
    "wizard-error",
    "confirm-event-change",
    "confirm-event-change-info",
    "change-event",
    "change-event-title",
    "input-error",
    "repeat-yearly",
    "categories",
    "category",
];
