import React, { useContext } from "react";
import Select from "components/Select";
import { TopologyContext } from "contexts/Topology";
import { useTranslation } from "react-i18next";

export const Floors = ({ floorFilter }) => {
    const { t } = useTranslation();
    const { setSelectedFloor, selectedWing, floorValidation } = useContext(TopologyContext);
    const floors = floorFilter.find((floor) => floor?.label?.toLowerCase() === selectedWing);

    return (
        <Select
            id="new-floor"
            placeholder={t("floor")}
            designClass={floorValidation}
            options={selectedWing ? floors?.options?.map((floor) => ({ label: floor.label, value: floor.value })) : []}
            onChange={(value) => setSelectedFloor(value)}
        />
    );
};
