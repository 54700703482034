import React from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table/index";
//Utils
import ListAreas from "../../../hooks/GraphqlCalls/CommonZones/ListAreas";
import { UseModalEditNameCommonZones } from "./modals/useModalEditNameCommonZones";
import { UseModalEditBookingAllowed } from "./modals/useModalEditBookingAllowed";

const Areas = () => {
    const { open: openModalEditNameCommonZones } = UseModalEditNameCommonZones();
    const { open: openModalEditBookingAllowed } = UseModalEditBookingAllowed();
    ListAreas({ openModalEditNameCommonZones, openModalEditBookingAllowed });

    return (
        <>
            <UseSectionHeader title={["areas-list"]} navToPreviousPage={true} />
            <UseTable />
        </>
    );
};

export default Areas;
