import React from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Parser from "hooks/Utils/Parser";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import Loading from "components/Loading";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setRefreshContentData } from "actions/sectionActions";

const DELETE_SHOP_MUTATION = gql`
    mutation DeleteShop($id: Int64!) {
        deleteShop(id: $id) {
            error
            id
            ok
        }
    }
`;

const useDeleteShop = ({ closeModal }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [deleteShop, { loading }] = useMutation(DELETE_SHOP_MUTATION, {
        onCompleted: (data) => {
            if (data.deleteShop.ok) {
                toast.success(t("operation-successful"));
                closeModal();
                dispatch(setRefreshContentData(true));
            }
        },
        onError: (error) => {
            toast.error(error.message);
            closeModal();
            dispatch(setRefreshContentData(true));
        },
    });

    const handleDeleteShop = (shopId) => {
        deleteShop({
            variables: { id: shopId },
        });
    };

    return {
        handleDeleteShop,
        loading,
    };
};

const ModalContent = (props) => {
    const { t } = useTranslation();

    const { handleDeleteShop, loading } = useDeleteShop({ closeModal: props?.close });

    return (
        <Modal
            title={`${props.nameShop} - ${t("delete")}`}
            className={"w-3/12 p-10"}
            footer={
                !loading ? (
                    <>
                        <Button design="blue-outline" onClick={() => props?.close()} id="modal-button-cancel">
                            {t("cancel")}
                        </Button>
                        <Button
                            design="red"
                            onClick={() => {
                                handleDeleteShop(props?.VARIABLES?.id);
                            }}
                            id="modal-button-delete"
                        >
                            {t("delete")}
                        </Button>
                    </>
                ) : null
            }
        >
            {loading ? <Loading /> : Parser(t("delete-shop-html", { shop: props?.nameShop }))}
        </Modal>
    );
};

export const useDeleteShopModal = () => {
    const { open, close } = useModal();

    const openModal = (props) => {
        const newProps = {
            close,
            ...props,
        };

        open(<ModalContent {...newProps} />);
    };

    return {
        open: openModal,
        close,
    };
};
