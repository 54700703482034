import { gql } from "apollo-boost";
import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";
import { GlobalContext } from "contexts/Global";

const TemplatePreview = () => {
    const { t } = useTranslation();
    const { lang } = useContext(GlobalContext);
    const { id } = useParams();
    const [html, setHtml] = useState(null);
    const [width, setWidth] = useState(1280);
    const [height, setHeight] = useState(720);

    const GET_TEMPLATE = gql`
        {
            templates( filter:{id :${id}}
                lang:"${lang}"
                ) {
                results{
                    HTML
                    devices{
                        res{
                        h
                        w
                    }
                    }
                }
            }
        }
    `;

    const [executeQuery, { data, error }] = useLazyQuery(GET_TEMPLATE, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data && data.templates && data.templates.results) {
            setHtml(data.templates.results[0].HTML);
            setHeight(data.templates.results[0].devices[0].res.h);
            setWidth(data.templates.results[0].devices[0].res.w);
        }
    }, [data]);

    return (
        <>
            {error ? (
                <div className="w-screen h-screen table-cell text-center align-middle">{t("error-mutation")}</div>
            ) : (
                <div className="w-full h-full bg-gray-100">
                    <iframe
                        title="html-iframe"
                        width={width}
                        height={height}
                        className=" mx-auto overflow-hidden"
                        srcDoc={html}
                    ></iframe>
                </div>
            )}
        </>
    );
};

export default TemplatePreview;
