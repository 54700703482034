import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate as useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { toastValidations } from "hooks/Utils/Validations";
import { usePasswordReminderModal } from "sections/login/reminder";

import Button from "components/Button";
import TextInput from "components/TextInput";
import ZafiroLogo from "components/ZafiroLogo";

import { useAuth } from "hooks/Session/auth";

const Login = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const emailRef = useRef(null);
    const passRef = useRef(null);
    const loginButtonRef = useRef(null);

    const { open: reminderModal } = usePasswordReminderModal();

    const { isLogged, isChainLogged, isProjectLogged, login } = useAuth();

    /**
     * Open the password reminder modal
     */
    const passwordReminder = () => {
        reminderModal({ email: emailRef.current?.getValue() });
    };

    /**
     * Check the email and password and try to login
     */
    const submitLogin = () => {
        if (
            !toastValidations(
                { ref: emailRef, message: t("invalid email") },
                { ref: passRef, message: t("invalid password") }
            )
        ) {
            return;
        }
        login({
            user: emailRef.current?.getValue(),
            pass: passRef.current?.getValue(),
            onError: (error) => {
                if (error?.message?.includes("password is incorrect")) {
                    // Invalid password
                    toast.error(t("invalid email or password"));
                    if (passRef.current) {
                        passRef.current.focus();
                    }
                } else {
                    // Other error
                    toast.error(t("mutation-error"));
                }
            },
        });
    };

    /**
     * Handle the enter key to submit the login
     */
    const onEnterLogin = (e) => {
        if (e?.key === "Enter") {
            e.preventDefault();
            if (loginButtonRef.current) {
                loginButtonRef.current.focus();
                loginButtonRef.current.click();
            }
        }
    };

    useEffect(() => {
        if (isLogged) {
            // When login is successful
            if (!isChainLogged) {
                // Go to select chain
                history("/select-profile");
            } else if (!isProjectLogged) {
                // Go to select project
                history("/select-project");
            } else {
                // Go to main page
                history("/");
            }
        }
    }, [isLogged]);

    return (
        <div className="bg-white flex items-center justify-center h-screen">
            <div className="mb-96 flex flex-col space-y-5">
                <ZafiroLogo />
                <div className="text-gray-900 text-2xl text-center font-bold pb-5">
                    <h2>{t("welcome to zafiro")}</h2>
                </div>
                <TextInput
                    ref={emailRef}
                    id="email-input"
                    type="email"
                    required={true}
                    placeholder={t("email")}
                    autoFocus={true}
                    onKeyPress={onEnterLogin}
                />
                <TextInput
                    ref={passRef}
                    id="password-input"
                    type="password"
                    required={true}
                    placeholder={t("password")}
                    onKeyPress={onEnterLogin}
                />
                <div className="pt-5 flex flex-col space-y-5">
                    <Button
                        ref={loginButtonRef}
                        id="access-button"
                        design="blue"
                        onClick={submitLogin}
                        className="w-full"
                    >
                        {t("access")}
                    </Button>
                    <Button
                        id="pass-reminder-button"
                        design="link"
                        className="font-bold text-blue-300 m-auto"
                        onClick={passwordReminder}
                    >
                        {t("pass-reminder")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Login;
