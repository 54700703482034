import React, { useContext } from "react";
import { useParams } from "react-router-dom";
//Components

//Utils
import PreviewRoom from "../../../../hooks/GraphqlCalls/Hotel/Rooms/RoomPreview";

import { GlobalContext } from "contexts/Global";

const RoomPreview = () => {
    const { lang } = useContext(GlobalContext);

    const { roomtvid, lang: forceLang } = useParams();

    PreviewRoom(roomtvid, forceLang || lang);
    return (
        <div className="w-full h-full bg-gray-100">
            <iframe
                width="1280"
                height="720"
                id="roomPreviewFrame"
                title="roomPreviewFrame"
                className=" mx-auto overflow-hidden"
            ></iframe>
        </div>
    );
};

export default RoomPreview;
