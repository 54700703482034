import React from "react";
import { useTranslation } from "react-i18next";
import UseHotspotCard from "./Section/DasboardCards/useHotspotCard";
import ProductSettingsAllergensCard from "./Section/Services/Sales/components/ShopSettings/ProductSettings/ProductSettingsAllergensCard";
import ProductSettingsExtrasCard from "./Section/Services/Sales/components/ShopSettings/ProductSettings/ProductSettingsExtrasCard";
import ProductSettingsNameAndDescriptionCard from "./Section/Services/Sales/components/ShopSettings/ProductSettings/ProductSettingsNameAndDescriptionCard";
import ProductSettingsPriceCard from "./Section/Services/Sales/components/ShopSettings/ProductSettings/ProductSettingsPriceCard";
import ProductSettingsSettingsCard from "./Section/Services/Sales/components/ShopSettings/ProductSettings/ProductSettingsSettingsCard";
import GeneralSettingsAllergensCard from "./Section/Services/Sales/GeneralSettingsAllergensCard";
import GeneralSettingsTaxCard from "./Section/Services/Sales/GeneralSettingsTaxCard";
import UseButton from "./useButton";
import ProductSettingsFilesCard from "./Section/Services/Sales/components/ShopSettings/ProductSettings/ProductSettingsFilesCard";
import GeneralSettingsAutomaticMessages from "./Section/Services/Sales/GeneralSettingsAutomaticMessages";
import ChannelEditionInformationCard from "./Section/Services/tvChannels/channels/ChannelEditionInformationCard";
import ChannelEditionTuningCard from "./Section/Services/tvChannels/channels/ChannelEditionTuningCard";
import ChannelRewiewInformationCard from "./Section/Services/tvChannels/channels/ChannelRewiewInformationCard";
import ChannelsGeneralSettingsRememberLastCard from "./Section/Services/tvChannels/channels/ChannelsGeneralSettingsRememberLastCard";
import CorporateChannelInformationCard from "./Section/Services/tvChannels/channels/CorporateChannelInformationCard";
import ChannelsGeneralSettingsAlwaysDisplayTv from "./Section/Services/tvChannels/channels/ChannelsGeneralSettingsAlwaysDisplayTv";
import ChannelsGeneralSettingsHighAvailability from "./Section/Services/tvChannels/channels/ChannelsGeneralSettingsHighAvailability";

const UseCard = ({
    key,
    type,
    data,
    disabled,
    adjust,
    noDefaultAdjust,
    title,
    titleAdjust,
    noDefaultTitleAdjust,
    description,
    noDefaultDescriptionAdjust,
    descriptionAdjust,
    buttons,
    buttonsAdjust,
    noDefaultButtonsAdjust,
}) => {
    const { t } = useTranslation();
    const renderCard = () => {
        let response = (
            <>
                {title && (
                    <div
                        className={`${
                            !noDefaultTitleAdjust ? "font-bold text-gray-900 text-2xl mb-4" : null
                        } ${titleAdjust}`}
                    >
                        <span>{t(title)}</span>
                    </div>
                )}
                {description && (
                    <div
                        className={`${
                            !noDefaultDescriptionAdjust ? "font-normal text-gray-800 mb-4" : null
                        } ${descriptionAdjust}`}
                    >
                        <span>{t(description)}</span>
                    </div>
                )}
                {buttons && buttons.length > 0 && (
                    <div className={`${!noDefaultButtonsAdjust ? "inline-flex" : null} ${buttonsAdjust}`}>
                        {buttons.map((button, index) => {
                            const action = button.actionCallback ? button.actionCallback : button.action;
                            return (
                                <div className={`${button.adjust2}`} key={button.name}>
                                    <UseButton
                                        id={`${button.name}-button`}
                                        buttonName={button.name}
                                        disabled={disabled}
                                        link={button.link}
                                        action={disabled ? null : action}
                                        buttonColor={button.buttonColor}
                                        adjust={button.cStyle}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </>
        );

        if (type) {
            switch (type) {
                case "hotspot-card":
                    response = <UseHotspotCard data={data} disabled={disabled} key={key} />;
                    break;
                case "sales-tax-card":
                    response = <GeneralSettingsTaxCard data={data} disabled={disabled} key={key} />;
                    break;
                case "sales-allergens-card":
                    response = <GeneralSettingsAllergensCard data={data} disabled={disabled} key={key} />;
                    break;
                case "product-translations-name-description-card":
                    response = <ProductSettingsNameAndDescriptionCard data={data} disabled={disabled} key={key} />;
                    break;
                case "product-price-card":
                    response = <ProductSettingsPriceCard data={data} disabled={disabled} key={key} />;
                    break;
                case "product-allergens-card":
                    response = <ProductSettingsAllergensCard data={data} disabled={disabled} key={key} />;
                    break;
                case "product-settings-card":
                    response = <ProductSettingsSettingsCard data={data} disabled={disabled} key={key} />;
                    break;
                case "product-extras-card":
                    response = <ProductSettingsExtrasCard data={data} disabled={disabled} key={key} />;
                    break;
                case "product-files-card":
                    response = <ProductSettingsFilesCard data={data} disabled={disabled} key={key} />;
                    break;
                case "automatic-messages":
                    response = <GeneralSettingsAutomaticMessages data={data} disabled={disabled} key={key} />;
                    break;
                case "channel-edition-information":
                    response = <ChannelEditionInformationCard data={data} disabled={disabled} key={key} />;
                    break;
                case "channel-corporate-information":
                    response = <CorporateChannelInformationCard data={data} />;
                    break;
                case "channel-edition-tuning":
                    response = <ChannelEditionTuningCard data={data} disabled={disabled} key={key} />;
                    break;
                case "channel-review-information":
                    response = <ChannelRewiewInformationCard data={data} disabled={disabled} key={key} />;
                    break;
                case "channels-general-settings-remember-last":
                    response = <ChannelsGeneralSettingsRememberLastCard data={data} disabled={disabled} key={key} />;
                    break;
                case "always-display-tv":
                    response = <ChannelsGeneralSettingsAlwaysDisplayTv data={data} disabled={disabled} key={key} />;
                    break;
                case "high-availability":
                    response = <ChannelsGeneralSettingsHighAvailability data={data} disabled={disabled} key={key} />;
                    break;
                default:
                    break;
            }
        }
        return response;
    };

    return (
        <div
            className={`${
                !noDefaultAdjust ? "bg-white rounded shadow items-center align-middle text-center p-5" : null
            } ${adjust}`}
        >
            {renderCard()}
        </div>
    );
};

export default UseCard;
