import React, { useEffect } from "react";
import UseButton from "../../../../../../components/useButton";
import { gql } from "apollo-boost";
import { showGlobalLoading } from "actions/uiActions";
import { useLazyQuery } from "react-apollo";
import { useDispatch } from "react-redux";
import { latinise } from "hooks/Utils/Utils";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const SynchroniseButton = ({
    setPrededinedChannels,
    setTunedChannels,
    channels,
    setChannels,
    checkedChannels,
    setCheckedChannels,
    setLastSync,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const UPDATE_CHANNEL_LIST = gql(`
    query{
        predefinedChannels{            
            results {
                ID:id
                Name:name
                IsRadio:isRadio
                CountryRef:countryRef
            }
        }
        customAndPresetTunesSummary{
            results{
                id
                channelID
                Name:name
                IsRadio:isRadio
                TuneInfo:tuneInfo
                CountryRef:countryRef
                type
            }
        }
    }`);

    const [executeUpdateChannelsQuery, { data, error, loading }] = useLazyQuery(UPDATE_CHANNEL_LIST, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        dispatch(showGlobalLoading(loading));
    }, [loading]);

    useEffect(() => {
        const newPredefinedChannels = data?.predefinedChannels?.results;
        const newTunedChannels = data?.customAndPresetTunesSummary?.results;

        if (newPredefinedChannels && newTunedChannels) {
            let newChannels = channels;

            //remove from predefined channels those used in tunedChannels
            setPrededinedChannels(
                newPredefinedChannels?.filter(
                    (ch) => !newTunedChannels.find((tCh) => tCh?.type === "PRESET" && tCh?.channelID === ch.ID)
                )
            );
            setTunedChannels(newTunedChannels);

            //update channels with last predefined and tuned channels
            newChannels.forEach((ch) => {
                //if current channel link exists on new synchronized channels, we keep it
                if (
                    (ch.PredefinedTvChannel &&
                        newPredefinedChannels.find(
                            (newPreCh) =>
                                latinise(ch.PredefinedTvChannel.Name).toLowerCase() ===
                                latinise(newPreCh.Name).toLowerCase()
                        )) ||
                    (ch.Tune &&
                        newTunedChannels.find(
                            (newTunedCh) =>
                                latinise(ch.Tune.Name).toLowerCase() === latinise(newTunedCh.Name).toLowerCase()
                        ))
                ) {
                    return;
                }

                //else
                const predMatch = newPredefinedChannels.find(
                    (newPreCh) => latinise(ch.Name).toLowerCase() === latinise(newPreCh.Name).toLowerCase()
                );
                const tunedMatch = newTunedChannels.find(
                    (newTunedCh) => latinise(ch.Name).toLowerCase() === latinise(newTunedCh.Name).toLowerCase()
                );

                if (tunedMatch) {
                    ch.PredefinedTvChannel = null;
                    ch.Tune = tunedMatch;
                    ch.newTune = true;
                } else if (predMatch) {
                    ch.PredefinedTvChannel = predMatch;
                    ch.Tune = null;
                    ch.newTune = true;
                } else {
                    ch.PredefinedTvChannel = null;
                    ch.Tune = null;
                }
            });

            setChannels([...newChannels]);

            //update checked channels after synchronization
            let checked = checkedChannels;
            newChannels.forEach((ch) => {
                !checkedChannels[ch.Name] && ch.newTune && checked.push(ch.Name);
            });
            setCheckedChannels(checked);
            setLastSync(new Date().getMilliseconds());
        }
    }, [data]);

    useEffect(() => {
        error && toast.error(t("Error getting channels information"));
    }, [error]);

    return (
        <div className="absolute right-0 ">
            <div
                id="btn-synchronise_tooltip"
                data-tip={t("synchronise-tooltip")}
                data-for="dangerous-html-tooltip"
                className="icon icon-info cursor-help float-left text-3xl ml-3 mr-3"
                style={{ lineHeight: "2.5rem" }}
            ></div>
            <UseButton
                action={(e) => {
                    executeUpdateChannelsQuery();
                }}
                buttonName={"synchronize"}
                buttonColor="btn-white"
                adjust="float-left "
                id={`synchronize-btn`}
            />
        </div>
    );
};

export default SynchroniseButton;
