import { setActionValues } from "actions/globalActions";
import { EN_CUSTOM_FIELD, ES_CUSTOM_FIELD } from "components/Section/Customers/Precheckin/ReservationDetails/utils";
import UseInputText from "components/Table/useInputText";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const UseModalEditCustomField = ({ index }) => {
    const { values, isEdit } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { validations } = useSelector((state) => state.action);
    const [nameValues, setNameValues] = useState({ [EN_CUSTOM_FIELD]: "", [ES_CUSTOM_FIELD]: "" });
    const dispatch = useDispatch();

    const { t } = useTranslation();

    useEffect(() => {
        if (values) {
            setNameValues({ [EN_CUSTOM_FIELD]: values.en || "", [ES_CUSTOM_FIELD]: values.es || "" });
        }
    }, [values]);

    useEffect(() => {
        dispatch(setActionValues({ ...nameValues }));
        console.log(nameValues);
    }, [nameValues]);

    return (
        <div>
            <div className=" flex items-center mt-3">
                <div className=" w-1/3 font-bold ">{t("language:" + "en")}</div>

                <div className=" w-2/3">
                    <UseInputText
                        inputData={{ name: EN_CUSTOM_FIELD }}
                        ph={nameValues[EN_CUSTOM_FIELD] || nameValues[ES_CUSTOM_FIELD] || t("custom-item")}
                        validation={validations[EN_CUSTOM_FIELD] === false ? false : true}
                        id={"add-booking-code"}
                        onChangeAction={(val) => {
                            setNameValues({ ...nameValues, [EN_CUSTOM_FIELD]: val });
                        }}
                        currentValue={nameValues[EN_CUSTOM_FIELD]}
                    />
                </div>
            </div>

            <div className=" flex items-center mt-3">
                <div className=" w-1/3 font-bold ">{t("language:" + "es")}</div>
                <div className=" w-2/3">
                    <UseInputText
                        inputData={{ name: ES_CUSTOM_FIELD }}
                        ph={nameValues[ES_CUSTOM_FIELD] || nameValues[EN_CUSTOM_FIELD] || t("custom-item")}
                        validation={validations[ES_CUSTOM_FIELD] === false ? false : true}
                        id={"add-booking-code"}
                        currentValue={nameValues[ES_CUSTOM_FIELD]}
                        onChangeAction={(val) => {
                            setNameValues({ ...nameValues, [ES_CUSTOM_FIELD]: val });
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default UseModalEditCustomField;
