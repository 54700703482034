import React from "react";
import { useDispatch, useSelector } from "react-redux";
//Actions
import { openModal, setModalContent } from "../../../actions/uiActions";
import { setActionName, setActionItemAffected, setActionInput } from "../../../actions/globalActions";
import { useTranslation } from "react-i18next";

const UseButtonCell = ({ cellData, rowIndex, colIndex }) => {
    const { t } = useTranslation();
    //Store data
    const { cleaningStaff } = useSelector((state) => state.table);
    //Data
    const {
        hidden,
        buttonColor,
        value,
        name,
        id,
        currentResponsibleName,
        currentResponsibleID,
        areaName,
        action,
        actionParams,
        adjust,
        modal,
    } = cellData || {};
    if (cellData && name === "refresh-cleaning") {
        cellData.displayName = "update";
    }
    //Actions
    const dispatch = useDispatch();

    const prefixId = "cell-button";

    const buttonClickHandler = (e) => {
        e.stopPropagation();
        if (name === "refresh-cleaning") {
            const affectedId = e.currentTarget.dataset.id;
            dispatch(setActionName(name));
            dispatch(setActionItemAffected([affectedId]));
            const dataToAction = { responsible: currentResponsibleID };
            dispatch(setActionInput(dataToAction));

            //Modal content
            const modalContent = {
                id: id,
                title: "cleaning-update",
                value: `- ${areaName}`,
                inputs: [
                    {
                        name: "cleaning",
                        type: "date",
                        text: "cleaning success",
                        empty: true,
                        noFilterPassedTime: true,
                        id: "cleaning-update",
                    },
                    {
                        name: "responsible",
                        type: "selectWithSearch",
                        text: "select who cleaning name",
                        optionData: cleaningStaff,
                        ph: "responsible",
                        currentResponsible: currentResponsibleName,
                    },
                ],
                buttons: [
                    { name: "cancel", style: "white", action: "close" },
                    {
                        name: "save",
                        style: "blue",
                        action: "cleaning-update",
                    },
                ],
            };
            dispatch(setModalContent(modalContent));
            //Launch modal
            dispatch(openModal());
        } else {
            if (action) {
                action(actionParams);
            } else if (modal) {
                dispatch(setModalContent(modal));
                dispatch(openModal());
            }
        }
    };

    return (
        <>
            {!hidden ? (
                <div
                    id={`${prefixId}-r${rowIndex}-c${colIndex}`}
                    className={`btn-${buttonColor} ${adjust ? adjust : "w-full cursor-pointer px-4 "} `}
                    onClick={buttonClickHandler}
                    data-id={value}
                >
                    <div id={`${prefixId}-r${rowIndex}-c${colIndex}-text`} className="first-capital">
                        {t(cellData?.displayName || name)}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default UseButtonCell;
