import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseSchedule from "./useSchedule";
import UseFormPunctualUpdate from "./useFormPunctualUpdate";
import UseButton from "../useButton";
import UseFormPunctual from "./useFormPunctual";
import UseScheduleHeader from "./useScheduleHeader";
//Actions
import { showAddPunctualSchedule } from "../../actions/sectionActions";
import { setActionName, setActionItemAffected } from "../../actions/globalActions";
//Utils
import { useParams } from "react-router-dom";

const UsePunctualEvent = () => {
    //Store data
    const { schedulePunctualForm, editPunctualForm } = useSelector((state) => state.sectionContent);

    const { currentData, headers } = useSelector((state) => state.sectionContent.punctualEventsData);
    const { id } = useParams();

    //States
    const [addPunctualSchedule, setAddPunctualSchedule] = useState(false);
    const [editPunctualSchedule, setEditPunctualSchedule] = useState(false);

    //Actions
    const dispatch = useDispatch();

    const handleClickNew = () => {
        setAddPunctualSchedule(true);
        dispatch(showAddPunctualSchedule(true));
        dispatch(setActionName("add-punctual"));
        dispatch(setActionItemAffected([id]));
    };

    //Listeners
    useEffect(() => {
        setAddPunctualSchedule(schedulePunctualForm);
    }, [schedulePunctualForm]);

    useEffect(() => {
        setEditPunctualSchedule(editPunctualForm);
    }, [editPunctualForm]);

    return (
        <>
            {addPunctualSchedule || currentData ? (
                <div className="schedule-existing-row px-8 bg-transparent h-5 my-4">
                    <div className="w-full grid grid-flow-col grid-cols-12 gap-2">
                        {headers.map((header, index) => (
                            <UseScheduleHeader header={header} key={index} />
                        ))}
                    </div>
                </div>
            ) : null}

            {editPunctualSchedule || !currentData ? (
                <UseFormPunctualUpdate setEditPunctualSchedule={setEditPunctualSchedule} />
            ) : null}
            {currentData && !addPunctualSchedule && !editPunctualSchedule
                ? currentData.map((currentEvent, index) => <UseSchedule currentEvent={currentEvent} key={index} />)
                : null}
            {!addPunctualSchedule && !editPunctualSchedule ? (
                <div id="button-row" className="w-full flex justify-end h-10 pt-4">
                    <div className="w-auto inline-block" onClick={handleClickNew}>
                        <UseButton buttonName="add" buttonColor="btn-blue" icon="add" />
                    </div>
                </div>
            ) : null}
            {addPunctualSchedule || !currentData ? (
                <UseFormPunctual setAddPunctualSchedule={setAddPunctualSchedule} />
            ) : null}
        </>
    );
};

export default UsePunctualEvent;
