import React from "react";

const UseComponentLoading = ({ adjust }) => {
    return (
        <div className={`z-500 t-0 l-0 w-full h-full flex m-auto ${adjust ? adjust : ""}`}>
            <div className="lds-roller m-auto">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default UseComponentLoading;
