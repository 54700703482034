import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//API
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import ModalMutationDispatcher from "../../../hooks/GraphqlCalls/CommonZones/modals/modalMutationDispatcher";
//Actions
import { closeModal, setModalContent } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";
import {
    deletePunctualView,
    deletePeriodicView,
    setSectionContent,
    setActiveEventType,
} from "../../../actions/sectionActions";
import { setBatchPeriodicSchedule, setViewPeriodicSchedule } from "../../../actions/scheduleActions";
//Components
import UseButton from "../../../components/useButton";
//Utils
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ModalContentSchedule = () => {
    const { t } = useTranslation();
    //Store data
    const { id, text, textAdjust, buttons, buttonAlignment } = useSelector((state) => state.ui.modalContent);
    const actionData = useSelector((state) => state.action);
    const { eventTypeActive } = useSelector((state) => state.sectionContent);
    const { sectionContent } = useSelector((state) => state);
    const { periodicSchedulesBatch, periodicSchedulesView } = useSelector((state) => state.schedules);

    //Data
    const buttonLayout = buttonAlignment ? buttonAlignment : "end";

    const PUNCTUAL_EVENT = "punctual-event";
    const PERIODIC_EVENT = "periodic-event";

    //States
    const [fullText, setFullText] = useState();

    //API
    const mutation = ModalMutationDispatcher();
    const DYNAMIC_MUTATION = gql`
        ${mutation}
    `;
    const [executeMutation, { data }] = useMutation(DYNAMIC_MUTATION);

    //Actions
    const dispatch = useDispatch();
    const hideModal = () => dispatch(closeModal());

    const handleClick = (e) => {
        const actionType = e.currentTarget.dataset.action;
        e.stopPropagation();
        if (actionType === "close") {
            hideModal();
            dispatch(setModalContent(false));
            dispatch(cleanAction());
        } else if (actionType === "delete" && actionData.actionName === "delete-schedule-editor" && !id) {
            //Update BatchToStore
            const newBatch = periodicSchedulesBatch.filter((item) => item.tempId !== actionData.itemsAffected);
            const newView = periodicSchedulesView.filter((item) => item.tempId !== actionData.itemsAffected);
            dispatch(setBatchPeriodicSchedule(newBatch));
            dispatch(setViewPeriodicSchedule(newView));

            hideModal();
            dispatch(setModalContent(false));
            dispatch(cleanAction());
        } else {
            executeMutation();
            if (actionType === "change-event") {
                if (eventTypeActive === PUNCTUAL_EVENT) {
                    dispatch(setActiveEventType(PERIODIC_EVENT));
                } else {
                    dispatch(setActiveEventType(PUNCTUAL_EVENT));
                }
            }
        }
    };
    //Listeners
    useEffect(() => {
        if (data && data.deleteTimetable && data.deleteTimetable.ok) {
            hideModal();
            if (eventTypeActive === PUNCTUAL_EVENT) {
                dispatch(deletePunctualView(Number(actionData.itemsAffected[0])));
            } else {
                dispatch(deletePeriodicView(Number(actionData.itemsAffected[0])));
            }
            dispatch(setModalContent(false));
            dispatch(cleanAction());
            toast.success(t("schedule deleted"));
        } else if (data && data.deleteTimetableByCZ && data.deleteTimetableByCZ.ok) {
            hideModal();
            dispatch(setModalContent(false));
            dispatch(cleanAction());
            sectionContent.punctualEventsData.currentData = [];
            sectionContent.periodicEventsData.currentData = [];
            dispatch(setSectionContent(sectionContent));
            toast.success(t("schedule deleted"));
        } else if (data && data.deleteTimetable && !data.deleteTimetable.ok) {
            toast.error(t("error-mutation"));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (text) {
            //TODO quitar esto
            setFullText(Array.isArray(text) ? text.map((word) => t(word)).join(" ") : t(text));
        }
        // eslint-disable-next-line
    }, [text]);

    return (
        <>
            {text ? (
                <div
                    className={`first-capital break-words whitespace-pre-line ${textAdjust}`}
                    style={{ paddingTop: "16px" }}
                >
                    {Array.isArray(fullText) ? (
                        <>
                            {fullText.map((ftext, index) => (
                                <div className="block first-capital" key={index}>
                                    {ftext}
                                </div>
                            ))}
                        </>
                    ) : (
                        fullText
                    )}
                </div>
            ) : null}
            {/* Modal footer content*/}
            {buttons ? (
                <div id="modal-buttons" className={`pt-8 flex justify-${buttonLayout}`}>
                    {buttons.map((button, index) => (
                        <div onClick={handleClick} className="ml-4" data-action={button.action} key={index}>
                            <UseButton
                                buttonName={button.name}
                                buttonColor={`btn-${button.style}`}
                                link={button.link}
                                action={button.action}
                            />
                        </div>
                    ))}
                </div>
            ) : null}
        </>
    );
};

export default ModalContentSchedule;
