import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Components
import Input from "./CommonOptions/Input";
//Actions
import Select from "./CommonOptions/Select";

//Utils
import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import { useTranslation } from "react-i18next";
import { EditorContext, WidgetContext } from "../../../../../contexts/editor";
import { capitalizeFirst } from "../../../../../hooks/Utils/Utils";
import { ensureFontName, getOptionsFontList, getFontSizes } from "../../../../../hooks/Utils/Design/FontUtils";
import { setWidgetToolbarVisible } from "../../../../../actions/designActions";
import ResetTypographyButton from "./CommonOptions/ResetTypographyButton";

const WifiLoginToolbar = () => {
    const { deviceType, theme, editorSize } = useContext(EditorContext);
    const { config } = useContext(WidgetContext);
    const { fonts } = useSelector((state) => state.ui);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const fontFamilyOptions = getOptionsFontList(fonts?.fontList);
    const fontSizeOptions = getFontSizes(editorSize.width, deviceType);

    const style = config?.style?.export();

    const buttonFontStyle = style?.items?.button.fontStyle;
    const textFontStyle = style?.items?.text.fontStyle;

    const buttonFont = ensureFontName(style?.items?.button.font ? style?.items?.button.font.name : null, fonts);
    const textFont = ensureFontName(style?.items?.text.font ? style?.items?.text.font.name : null, fonts);

    const tabs = [
        { id: "regular", name: t("default") },
        { id: "active", name: t("active") },
    ];

    const [currentTab, setCurrentTab] = useState(tabs[0].id);
    const selectTab = (type, tab) => {
        setCurrentTab(tab);
        dispatch(setWidgetToolbarVisible(type, tab));
    };

    return (
        <>
            <Toolbar type="settings">
                <Settings>
                    <Settings.Group title={capitalizeFirst(t("text-options"))}>
                        <div className="flex w-full justify-between relative ">
                            {theme && (
                                <Select
                                    title={t("styletype")}
                                    id="items.text.fontStyle"
                                    adjust=" w-5/12 mr-2"
                                    data={{
                                        selected: textFontStyle,
                                        options: [
                                            { value: "header", text: t("heading") },
                                            { value: "paragraph", text: t("paragraph") },
                                        ],
                                    }}
                                />
                            )}
                            <Select
                                title={t("font")}
                                id="items.text.font"
                                adjust=" w-5/12 mr-2"
                                data={{
                                    selected: textFont,
                                    options: fontFamilyOptions,
                                }}
                            />
                            <Select
                                title={t("total size")}
                                id="items.text.size"
                                deviceSpecific={true}
                                adjust=" w-2/12 "
                                data={{
                                    selected: style?.items?.button.size,
                                    options: fontSizeOptions,
                                }}
                            />
                            <ResetTypographyButton
                                actionKeys={[
                                    "items.text.fontStyle",
                                    "items.text.font",
                                    "items.text.size",
                                    `devices.${deviceType}.items.text.size`,
                                ]}
                                stylesButton={{ position: "absolute", top: "-2.8rem", right: 0 }}
                            />
                        </div>
                    </Settings.Group>
                    <Settings.Group title={capitalizeFirst(t("button-options"))}>
                        <div className="flex w-full justify-between relative">
                            {theme && (
                                <Select
                                    title={t("styletype")}
                                    id="items.button.fontStyle"
                                    adjust=" w-5/12 mr-2"
                                    data={{
                                        selected: buttonFontStyle,
                                        options: [
                                            { value: "header", text: t("heading") },
                                            { value: "paragraph", text: t("paragraph") },
                                        ],
                                    }}
                                />
                            )}
                            <Select
                                title={t("font")}
                                id="items.button.font"
                                adjust=" w-5/12 mr-2"
                                data={{
                                    selected: buttonFont,
                                    options: fontFamilyOptions,
                                }}
                            />
                            <Select
                                title={t("total size")}
                                id="items.button.size"
                                deviceSpecific={true}
                                adjust=" w-2/12 "
                                data={{
                                    selected: style?.items?.button.size,
                                    options: fontSizeOptions,
                                }}
                            />

                            <ResetTypographyButton
                                actionKeys={[
                                    "items.button.fontStyle",
                                    "items.button.font",
                                    "items.button.size",
                                    `devices.${deviceType}.items.button.size`,
                                ]}
                                stylesButton={{ position: "absolute", top: "-2.8rem", right: 0 }}
                            />
                        </div>
                    </Settings.Group>
                </Settings>
            </Toolbar>
            <Toolbar type="style">
                <Toolbar.Tabs
                    data={tabs}
                    currentTab={currentTab}
                    setCurrentTab={(tab) => {
                        selectTab("style", tab);
                    }}
                />
                {tabs.map((tab) => {
                    const isRegular = tab.id === "regular";
                    return (
                        <Settings key={tab.id + "-tab-content"} visible={tab.id === currentTab}>
                            <Settings.Group
                                type="colors"
                                title={capitalizeFirst(isRegular ? t("container") : t("color"))}
                            >
                                {isRegular ? <Input.Color id={"bgColor"} title={t("background")} /> : null}
                                <Input.Color
                                    id={isRegular ? "fgColor" : "status." + tab.id + ".fgColor"}
                                    title={t("text")}
                                />
                                {isRegular ? (
                                    <Input.Number
                                        id={"radius"}
                                        deviceSpecific={true}
                                        title={t("radius")}
                                        min="0"
                                        max="5"
                                        step="0.1"
                                        placeholder="1.0"
                                    />
                                ) : null}
                            </Settings.Group>
                            {isRegular ? (
                                <Settings.Group type="colors" title={capitalizeFirst(t("button"))}>
                                    <Input.Color id={"items.button.bgColor"} title={t("background")} />
                                    <Input.Color id={"items.button.fgColor"} title={t("text")} />
                                    <Input.Number
                                        id={"items.button.radius"}
                                        deviceSpecific={true}
                                        title={t("radius")}
                                        min="0"
                                        max="5"
                                        step="0.1"
                                        placeholder="1.0"
                                    />
                                </Settings.Group>
                            ) : null}
                        </Settings>
                    );
                })}
            </Toolbar>
        </>
    );
};

export default WifiLoginToolbar;
