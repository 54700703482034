import { escapeSpecialChars } from "../../../Utils/Utils";

function SettingsModalDispatcher(dataAction) {
    let settingsMutation = "";

    switch (dataAction.actionName) {
        case "new-property-group":
            settingsMutation += `mutation{
                createBrand(
                    projects:${
                        dataAction.values.properties && dataAction.values.properties.length > 0
                            ? `[${dataAction.values.properties.map((property) => {
                                  return `${property}`;
                              })}]`
                            : `[]`
                    },
                    name:"${escapeSpecialChars(dataAction.values.name)}"
            `;
            break;
        case "edit-property-group":
            settingsMutation += `mutation{
                updateBrand(
                    projects:${
                        dataAction.values.properties && dataAction.values.properties.length > 0
                            ? `[${dataAction.values.properties.map((property) => {
                                  return `${property}`;
                              })}]`
                            : `[]`
                    },
                    name:"${escapeSpecialChars(dataAction.values.name)}",
                    id:${dataAction.itemsAffected[0]},
                    replace: true
            `;
            break;
        case "duplicate-property-group":
            settingsMutation += `mutation{
                duplicateBrand(
                    id:${dataAction.itemsAffected[0]},
            `;
            break;
        case "delete-property-group":
            settingsMutation += `mutation{
                deleteBrand(
                    id:${
                        dataAction.itemsAffected
                            ? `"${dataAction.itemsAffected[0]}"`
                            : `["${dataAction.checkedItems.join('","')}"]`
                    },
            `;
            break;
        case "edit-property":
            settingsMutation += `mutation{
                updateProperty(
                    brands:${
                        dataAction.values["properties-groups"] && dataAction.values["properties-groups"].length > 0
                            ? `[${dataAction.values["properties-groups"].map((brand) => {
                                  return `${brand}`;
                              })}]`
                            : `[]`
                    },
                    name:"${escapeSpecialChars(dataAction.inputData.name)}",
                    id:${dataAction.itemsAffected[0]},
                    replace: true
            `;
            break;
        default:
            break;
    }
    return settingsMutation;
}

export default SettingsModalDispatcher;
