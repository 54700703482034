import React, { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { setExecuteQuery } from "../../../../../actions/sectionActions";

import AddImage from "./CommonOptions/AddImage";
import UseSelectWithSearch from "../../../../useSelectWithSearch";

import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Actions from "./CommonOptions/Actions";
import Input from "./CommonOptions/Input";
import Widget from "../../Widgets/Widget";
import { setWidgetToolbarVisible } from "../../../../../actions/designActions";
import { capitalizeFirst } from "../../../../../hooks/Utils/Utils";
import { EditorContext, WidgetContext } from "../../../../../contexts/editor";
import Select from "./CommonOptions/Select";
import {
    getFontSizes,
    getOptionsFontList,
    getFontNameOptionSelected,
    getTextSizeSelected,
} from "../../../../../hooks/Utils/Design/FontUtils";

const ButtonToolbar = () => {
    const { deviceType, editorSize, itemSelected, theme } = useContext(EditorContext);
    const { config, id: widgetID, type } = useContext(WidgetContext);
    const { fonts } = useSelector((state) => state.ui);

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const { gridItems, widgetToolbarVisible } = useSelector((state) => state.design);

    const tabs = [{ id: "regular", name: t("regular") }];

    const adjustPredefinedOptions = [
        {
            id: "cover",
            name: capitalizeFirst(t("fill container")),
        },
        {
            id: "cover-adjust",
            name: capitalizeFirst(t("stretch image")),
        },
        {
            id: "contain",
            name: capitalizeFirst(t("fit into container")),
        },
    ];

    //States
    const [adjust, setAdjust] = useState(null);

    const [currentTab, setCurrentTab] = useState(tabs[0].id);

    const [widget, setWidget] = useState(gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null);

    useEffect(() => {
        const _widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
        setWidget(_widget);
        // eslint-disable-next-line
    }, [gridItems]);

    const data = config?.data || Widget.ParseData(widget ? widget.data : null, deviceType);

    const showIcon = data ? data.showIcon : false;

    const selectTab = (type, tab) => {
        setCurrentTab(tab);
        dispatch(setWidgetToolbarVisible(type, tab));
    };

    const reloadFields = () => {
        const gItem = gridItems.filter((gridItem) => gridItem.i === widgetID)[0];
        if (gItem && gItem.data && gItem.data.adjust) {
            setAdjust(gItem.data.adjust);
        } else {
            setAdjust("cover");
        }
    };

    const updateImageOptions = (value) => {
        dispatch(setExecuteQuery(Widget.UpdateData({ widget, property: "adjust", value })));
    };

    useEffect(() => {
        if (widgetToolbarVisible === "button-settings" && widgetID === itemSelected) {
            reloadFields();
        }
        // eslint-disable-next-line
    }, [widgetToolbarVisible]);

    useEffect(() => {
        if (itemSelected === widgetID) {
            reloadFields();
        }
        // eslint-disable-next-line
    }, [gridItems]);

    return (
        <>
            <Toolbar type="layout">
                <Settings>
                    <Settings.Group title={t("Text options")}>
                        <Input.Text id="title" title={t("text")} t={t} />
                        <div className="flex justify-between">
                            {theme && (
                                <Select
                                    title={t("styletype")}
                                    id="styletype"
                                    adjust="w-full mr-2"
                                    data={{
                                        selected:
                                            widget.widgetStyle && widget.widgetStyle.styletype
                                                ? widget.widgetStyle.styletype
                                                : "paragraph",
                                        options: [
                                            { value: "header", text: t("heading") },
                                            { value: "paragraph", text: t("paragraph") },
                                        ],
                                    }}
                                />
                            )}
                            <Select
                                title={t("font")}
                                id="fontName"
                                adjust="w-full mr-2"
                                data={{
                                    selected: getFontNameOptionSelected(
                                        theme,
                                        fonts,
                                        deviceType,
                                        widget,
                                        Widget.ImplementNewLibrary(type)
                                    ),
                                    options: getOptionsFontList(fonts?.fontList),
                                }}
                            />
                            <Select
                                title={t("total size")}
                                id="textSize"
                                deviceSpecific={true}
                                adjust="w-full"
                                data={{
                                    selected: getTextSizeSelected(theme, deviceType, widget),
                                    options: getFontSizes(editorSize.width, deviceType),
                                }}
                            />
                        </div>
                        <Input.Check
                            icon="icon-bold"
                            dataType="style"
                            id="bold"
                            dafultValue={
                                widget.widgetStyle && widget.widgetStyle.bold
                                    ? widget.widgetStyle.bold
                                    : theme?.paragraphStyle?.bold
                            }
                        />
                        <Input.Check
                            icon="icon-italic"
                            dataType="style"
                            id="italic"
                            dafultValue={
                                widget.widgetStyle && widget.widgetStyle.italic
                                    ? widget.widgetStyle.italic
                                    : theme?.paragraphStyle?.italic
                            }
                        />
                        <Input.Check
                            icon="icon-underline"
                            dataType="style"
                            id="underline"
                            dafultValue={
                                widget.widgetStyle && widget.widgetStyle.underline
                                    ? widget.widgetStyle.underline
                                    : theme?.paragraphStyle?.underline
                            }
                        />
                    </Settings.Group>
                    <Settings.Group title={t("icon options")}>
                        <Input.Check id="showIcon" deviceSpecific={true} title={t("icon")} />
                        {showIcon ? <Input.Icon /> : null}
                        {showIcon ? (
                            <Input.Number
                                id="iconSize"
                                deviceSpecific={true}
                                title={t("total size")}
                                defaultInputValue={theme?.paragraphStyle?.size}
                            />
                        ) : null}
                        {showIcon ? (
                            <Input.Alignment
                                id="iconPosition"
                                type="around"
                                deviceSpecific={true}
                                title={t("position")}
                            />
                        ) : null}
                        {showIcon ? <Input.Number deviceSpecific={true} id="iconMargin" title={t("spacing")} /> : null}
                    </Settings.Group>
                    <Settings.Group title={t("alignment")}>
                        <Input.Alignment inline={true} id="alignment" deviceSpecific={true} />
                        <div className="border-l mb-2 inline-block border-gray-400 py-1">&nbsp;</div>
                        <Input.Alignment inline={true} id="vAlignment" type="vertical" deviceSpecific={true} />
                    </Settings.Group>
                </Settings>
            </Toolbar>
            <Toolbar type="settings">
                <Settings>
                    <AddImage itemSelected={itemSelected} />
                    <div className="p-8 ">
                        <div className="first-capital mb-8 font-bold">{t("image-options")}</div>
                        <UseSelectWithSearch
                            data={{
                                oneSelected: true,
                                noSelect: true,
                                name: `image_select_${widgetID}`,
                                optionData: adjustPredefinedOptions,
                                selectedIds: adjust ? [adjust] : null,
                                onChange: updateImageOptions,
                                hideSearch: true,
                                optionsAdjust: "mt-11",
                                fixed: true,
                                parentName: `widgetOptionContainer_${widgetID}`,
                            }}
                        />
                    </div>
                </Settings>
            </Toolbar>
            <Toolbar type="style">
                {tabs && tabs.length > 1 ? (
                    <Toolbar.Tabs
                        data={tabs}
                        currentTab={currentTab}
                        setCurrentTab={(tab) => {
                            selectTab("style", tab);
                        }}
                    />
                ) : null}
                {tabs && tabs.length > 0
                    ? tabs.map((tab) => {
                          const isRegular = tab.id === "regular";
                          const fgColor = isRegular ? "fgColor" : tab.id + "FgColor";
                          const bgColor = isRegular ? "bgColor" : tab.id + "BgColor";
                          const iconColor = isRegular ? "iconColor" : null;
                          return (
                              <Settings key={tab.id + "-tab-content"} visible={tab.id === currentTab}>
                                  <Settings.Group type="colors">
                                      {fgColor ? <Input.Color id={fgColor} title={t("foreground")} /> : null}
                                      {bgColor ? <Input.Color id={bgColor} title={t("background")} /> : null}
                                      {iconColor ? <Input.Color id={iconColor} title={t("icon")} /> : null}
                                  </Settings.Group>
                                  <Settings.Border tab={tab.id} t={t} />
                              </Settings>
                          );
                      })
                    : null}
            </Toolbar>
            <Toolbar type="actions">
                <Actions
                    actions={{
                        content: true,
                        landing: true,
                        section: true,
                        url: deviceType !== "TV",
                        library: true,
                        tvchannel: true,
                    }}
                />
            </Toolbar>
            {/* <Toolbar type="clone" /> */}
            <Toolbar type="delete" />
        </>
    );
};

export default ButtonToolbar;
