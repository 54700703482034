import React from "react";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import UseRadio from "../../../useRadio";
import {
    generateContent,
    generateContent2,
    preventNegativeValues,
    tooltipHTMLDataAccessProfile,
    UPDATE_PMS,
} from "./Utils";
import AccessTypesCB from "./AccessTypesCB";
import AccessTypesSwitch from "./AccessTypesSwitch";
import UseButton from "../../../useButton";

const AccessTypesPMS = ({
    pmsData,
    setPmsFields,
    pmsFields,
    setCardEdition,
    pmsFieldsBackup,
    setKeepCurrentPreview,
    setSelectMutation,
}) => {
    const { t } = useTranslation();
    const sectionName = "access-type-pms";
    const accessProfile = pmsData && pmsData.additionalAccessProfileData ? pmsData.additionalAccessProfileData : null;
    const idAccessProfile = pmsData && pmsData.accessprofiles_id;
    const emailEnabled = pmsFields && pmsFields["pms-email"] && pmsFields["pms-email"].enabled;
    const pmsBreakconn = pmsFields && pmsFields["pms-breakconn"];
    const pmsCheckboxData = generatePMSCheckboxData(pmsFields, sectionName);
    const pmsSwitchData = generatePMSSwitchData(pmsFields, sectionName);

    const content = generateContent(emailEnabled, pmsBreakconn);
    const content2 = generateContent2(emailEnabled, pmsBreakconn);

    return (
        <div>
            <div className=" flex w-full mt-4">
                <div className=" w-1/2 mr-10">
                    <div className=" pl-2 border-b text-left font-bold border-gray-400">
                        {capitalizeFirst(t("access-profile"))}
                    </div>
                    <div className=" w-full flex mt-4 ml-2">
                        {String(idAccessProfile) === "0" ? (
                            <div className=" font-normal rounded flex flex-col items-start text-gray-800 truncate ">
                                <span className=" block">
                                    {`${capitalizeFirst(t("default"))} (${capitalizeFirst(t("max-bandwidth"))}) `}
                                </span>
                                <div className=" flex items-start mt-2 ">
                                    <span className=" icon icon-maximum text-gray-800 text-2xl"></span>
                                    <span className=" text-gray-800">
                                        {accessProfile && accessProfile.uploadBWMax / 1000000} Mb/s
                                    </span>
                                </div>
                                <div className=" flex items-start mt-2 ">
                                    <span className=" icon icon-minimum text-gray-800 text-2xl"></span>
                                    <span className=" text-gray-800">
                                        {accessProfile && accessProfile.downloadBWMax / 1000000} Mb/s
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div
                                data-tip={tooltipHTMLDataAccessProfile(accessProfile, t, capitalizeFirst)}
                                data-for="dangerous-html-tooltip"
                                className=" bg-zafiro-600 font-bold py-2 px-2 rounded flex items-center text-white truncate "
                            >
                                {accessProfile && accessProfile.name ? accessProfile.name : ""}
                            </div>
                        )}
                    </div>
                </div>
                <div className=" w-1/2">
                    <div className=" pl-2 border-b text-left font-bold border-gray-400">
                        {capitalizeFirst(t("devices-per-room"))}
                    </div>
                    <div className=" w-full flex flex-col mt-4 mb-6 ml-2 text-left">
                        <div
                            className=" mb-3"
                            id={`${sectionName}-devices-option-unlimited`}
                            onClick={() => {
                                setPmsFields({ ...pmsFields, "pms-devices-option": "unlimited" });
                            }}
                        >
                            <UseRadio
                                id={`${sectionName}-devices-option-unlimited-switch`}
                                value={"unlimited"}
                                selected={pmsFields && pmsFields["pms-devices-option"]}
                            />
                            <div className=" ml-6 -mt-1">{capitalizeFirst(t("unlimited-devices"))}</div>
                        </div>
                        <div
                            id={`${sectionName}-devices-option-custom`}
                            onClick={() => {
                                setPmsFields({ ...pmsFields, "pms-devices-option": "custom" });
                            }}
                        >
                            <UseRadio
                                id={`${sectionName}-devices-option-custom-switch`}
                                value={"custom"}
                                selected={pmsFields && pmsFields["pms-devices-option"]}
                            />
                            <div className=" ml-6 -mt-1">{capitalizeFirst(t("choose-number-of-devices-per-room"))}</div>
                            <input
                                className="t-filter-input ml-6 mt-2 p-2 "
                                placeholder={capitalizeFirst(t("devices-per-room"))}
                                id={`${sectionName}-devices-per-room`}
                                defaultValue={
                                    pmsFields && pmsFields["pms-users-number"] ? pmsFields["pms-users-number"] : ""
                                }
                                disabled={pmsFields && pmsFields["pms-devices-option"] === "unlimited" ? true : false}
                                style={{
                                    backgroundColor:
                                        pmsFields && pmsFields["pms-devices-option"] === "unlimited" ? "#D3DAE1" : "",
                                }}
                                onChange={(e) => {
                                    e.target.value = preventNegativeValues(e.target.value);

                                    if (e.target.value === "0") {
                                        e.target.value = 1;
                                    }
                                }}
                                type={"number"}
                            ></input>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className=" pl-2 border-b text-left font-bold border-gray-400">
                    {capitalizeFirst(t("content"))}
                </div>
                <div className=" flex pl-2">
                    {/* FIRST-STEP-PMS */}
                    <div className=" w-1/2 mr-10">
                        <div className="  mt-4 mb-4 text-gray-800 text-left">{capitalizeFirst(t("first-step"))}</div>
                        {/* EMAIL-CHECKBOX-PMS */}
                        <div className=" text-left mb-4">
                            {capitalizeFirst(t("check-you-want-to-add-email-option"))}
                        </div>
                        <div className=" w-full flex pr-1">
                            <div className="  w-1/2 ">
                                <AccessTypesCB data={pmsCheckboxData[0]} setState={setPmsFields} />
                            </div>
                            <div
                                className={` flex justify-end  w-1/2 ${
                                    emailEnabled ? " text-gray-900" : "text-gray-500"
                                } `}
                            >
                                <span className=" mr-2 ">{capitalizeFirst(t("mandatory"))}</span>
                                <AccessTypesSwitch
                                    data={pmsSwitchData[0]}
                                    setState={setPmsFields}
                                    forceDisabled={!emailEnabled}
                                />
                            </div>
                        </div>
                        <div className=" w-full flex">
                            {/* EMAIL-VALIDATION-PMS */}
                            <div className=" pr-8 ">
                                <div className={`text-left ${emailEnabled ? "text-gray-900" : "text-gray-600"} `}>
                                    {capitalizeFirst(t("email-validation-every-login"))}
                                    <span
                                        className={` ml-3 relative inline-block text-2xl ${
                                            emailEnabled ? " text-gray-700" : " text-gray-600"
                                        } `}
                                    >
                                        <div
                                            data-tip={capitalizeFirst(
                                                t("by-checking-yes-user-does-not-validate-email")
                                            )}
                                            data-for="dangerous-html-tooltip"
                                            className=" icon icon-info"
                                            style={{ position: "absolute", top: "-15px", left: "0" }}
                                        ></div>
                                    </span>
                                </div>
                                <div className={` mt-2 ${emailEnabled ? "text-gray-900" : "text-gray-600"} `}>
                                    <div
                                        id={`${sectionName}-validation-required-container-true`}
                                        className=" mb-3"
                                        onClick={() => {
                                            if (emailEnabled) {
                                                setPmsFields({ ...pmsFields, "pms-breakconn": true });
                                            }
                                        }}
                                    >
                                        <div className=" relative">
                                            <UseRadio
                                                id={`${sectionName}-validation-required-radio-true`}
                                                selected={pmsFields && pmsFields["pms-breakconn"]}
                                                value={true}
                                            />
                                            {content}
                                        </div>
                                        <div className=" ml-6 w-4 -mt-1">{capitalizeFirst(t("yes"))}</div>
                                    </div>
                                    <div
                                        id={`${sectionName}-validation-required-container-false`}
                                        onClick={() => {
                                            if (emailEnabled) {
                                                setPmsFields({ ...pmsFields, "pms-breakconn": false });
                                            }
                                        }}
                                    >
                                        <div className=" relative">
                                            <UseRadio
                                                id={`${sectionName}-validation-required-radio-false`}
                                                selected={pmsFields && pmsFields["pms-breakconn"]}
                                                value={false}
                                            />
                                            {content2}
                                        </div>
                                        <div className=" ml-6 w-4  -mt-1">{capitalizeFirst(t("no"))}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" w-1/2">
                        {/* SECOND-STEP-PMS */}
                        <div className="  mt-4 mb-4 text-gray-800 text-left">{capitalizeFirst(t("second-step"))}</div>
                        <div className={`text-left ${emailEnabled ? "text-gray-900" : "text-gray-600"}`}>
                            {capitalizeFirst(t("ask-for-aditional-information-requested-not-registered"))}
                        </div>
                        <div className=" text-right mr-14 text-gray-800 mt-2">{capitalizeFirst(t("mandatory"))}</div>
                        {/* CHECKBOX-PMS */}
                        <div className=" w-full flex">
                            <div className=" mt-3  w-1/2 ">
                                <AccessTypesCB
                                    data={pmsCheckboxData[1]}
                                    setState={setPmsFields}
                                    disabled={!emailEnabled}
                                />
                                <AccessTypesCB
                                    data={pmsCheckboxData[2]}
                                    setState={setPmsFields}
                                    disabled={!emailEnabled}
                                />
                                <AccessTypesCB
                                    data={pmsCheckboxData[3]}
                                    setState={setPmsFields}
                                    disabled={!emailEnabled}
                                />
                                <AccessTypesCB
                                    data={pmsCheckboxData[4]}
                                    setState={setPmsFields}
                                    disabled={!emailEnabled}
                                />
                            </div>
                            <div className=" mt-3 mr-14  w-1/2  ">
                                <AccessTypesSwitch
                                    data={pmsCheckboxData[1]}
                                    setState={setPmsFields}
                                    forceDisabled={!emailEnabled}
                                />
                                <AccessTypesSwitch
                                    data={pmsCheckboxData[2]}
                                    setState={setPmsFields}
                                    forceDisabled={!emailEnabled}
                                />
                                <AccessTypesSwitch
                                    data={pmsCheckboxData[3]}
                                    setState={setPmsFields}
                                    forceDisabled={!emailEnabled}
                                />
                                <AccessTypesSwitch
                                    data={pmsCheckboxData[4]}
                                    setState={setPmsFields}
                                    forceDisabled={!emailEnabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" flex space-x-3 justify-end mx-10 mb-6 mt-6">
                    <div
                        onClick={() => {
                            setCardEdition(null);
                            setPmsFields({ ...pmsFieldsBackup });
                        }}
                    >
                        <UseButton buttonName="cancel" buttonColor="btn-white" id={`${sectionName}-cancel`} />
                    </div>
                    <div
                        onClick={() => {
                            setSelectMutation(UPDATE_PMS);
                            setKeepCurrentPreview(true);
                        }}
                    >
                        <UseButton buttonName="save" buttonColor="btn-blue" id={`${sectionName}-save`} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccessTypesPMS;

const generatePMSCheckboxData = (pmsFields, sectionName) => {
    return [
        {
            name: "email",
            dataField: pmsFields,
            keyField: "pms-email",
            singleValue: false,
            id: `${sectionName}-email-checkbox`,
        },
        {
            name: "name",
            dataField: pmsFields,
            keyField: "pms-name",
            singleValue: false,
            id: `${sectionName}-name-checkbox`,
        },
        {
            name: "surname",
            dataField: pmsFields,
            keyField: "pms-surname",
            singleValue: false,
            id: `${sectionName}-surname-checkbox`,
        },
        {
            name: "birthdate",
            dataField: pmsFields,
            keyField: "pms-date-of-birth",
            singleValue: false,
            id: `${sectionName}-date-of-birth-checkbox`,
        },
        {
            name: "country",
            dataField: pmsFields,
            keyField: "pms-country",
            singleValue: false,
            id: `${sectionName}-country-checkbox`,
        },
    ];
};

const generatePMSSwitchData = (pmsFields, sectionName) => {
    return [
        {
            dataField: pmsFields,
            keyField: "pms-email",
            id: `${sectionName}-email-switch`,
        },
        {
            dataField: pmsFields,
            keyField: "pms-name",
            id: `${sectionName}-name-switch`,
        },
        {
            dataField: pmsFields,
            keyField: "pms-surname",
            id: `${sectionName}-surname-switch`,
        },
        {
            dataField: pmsFields,
            keyField: "pms-date-of-birth",
            id: `${sectionName}-date-of-birth-switch`,
        },
        {
            dataField: pmsFields,
            keyField: "pms-country",
            id: `${sectionName}-country-switch`,
        },
    ];
};
