import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import translate from "../../lang/es/hotel/translate";
//Components

import TableCard from "./useCard";
import UseCZFormAreaCreate from "./useCZFormAreaCreate";
import UseCZFormAreaUpdate from "./useCZFormAreaUpdate";
import UseCZFormCategoryCreate from "./useCZFormCategoryCreate";
import UseCZFormCategoryUpdate from "./useCZFormCategoryUpdate";
import UseCZFormBookingCreate from "./useCZFormBookingCreate";
import UseCZFormStaffCreate from "./useCZFormStaffCreate";
import UseCZFormStaffUpdate from "./useCZFormStaffUpdate";
import UseCZFormCapacityUpdate from "./useCZFormCapacityUpdate";
import UseCZFormBookingUpdate from "./useCZFormBookingUpdate";
import UseFormCompanion from "./useFormCompanion";
import UseFormTopologyRoom from "./useFormTopologyRoom";
//Actions
import { showRowForm } from "../../actions/sectionActions";
//Utils
import { toast } from "react-toastify";
import { setLoading } from "../../actions/tableActions";
import { capitalizeFirst } from "../../hooks/Utils/Utils";
import UseLanguageFormCreate from "./UseLanguageFormCreate";
//NS Error div
import { networkServerErrorDiv } from "../../hooks/GraphqlCalls/Hotel/Monitoring/NSStatus";
import UseFormMediaLibraryFolder from "./useFormMediaLibraryFolder";
import { useTranslation } from "react-i18next";

const UseCards = () => {
    //Store data
    const { results, dateFilter } = useSelector((state) => state.table);
    const { langStrings } = useSelector((state) => state.ui);
    const { t } = useTranslation();
    const { rowForm } = useSelector((state) => state.sectionContent);
    const { actionName } = useSelector((state) => state.action);
    const { serverError, customServerError, customError, rowInEdition, customConfig, activeFilters, searchString } =
        useSelector((state) => state.table);
    //Actions
    const dispatch = useDispatch();
    //Listeners
    useEffect(() => {
        if (dateFilter && rowForm) {
            if (dateFilter !== "") {
                const currentDate = dateFilter;
                const today = new window.ZDate();
                if (currentDate < today && currentDate.toLocaleDateString() !== today.toLocaleDateString()) {
                    dispatch(showRowForm(false));
                    toast.error(translate("error-book-past"));
                }
            }
        }
        // eslint-disable-next-line
    }, [dateFilter]);

    useEffect(() => {}, [results, customError]);

    useEffect(() => {
        if (actionName === "update-booking") {
            dispatch(setLoading(true));
        }
        // eslint-disable-next-line
    }, [actionName]);
    const noResultsDiv = () => {
        return (
            <div className="text-center">
                <h1 className="mt-5 pt-5" id="no-result-message">
                    {capitalizeFirst(
                        customConfig && customConfig.searchNoResultText && activeFilters && activeFilters.search
                            ? langStrings[customConfig.searchNoResultText]
                            : customError
                            ? customError
                            : searchString !== null
                            ? langStrings["no-results-found"]
                            : langStrings["no-results"]
                    )}
                </h1>
            </div>
        );
    };

    //Functions
    const getEditForm = () => {
        return (
            <>
                {rowForm && actionName === "edit-area-name" ? <UseCZFormAreaUpdate /> : null}
                {rowForm && actionName === "edit-category-name" ? <UseCZFormCategoryUpdate /> : null}
                {rowForm && actionName === "staff-edit" ? <UseCZFormStaffUpdate /> : null}
                {rowForm && actionName === "capacity-edit" ? <UseCZFormCapacityUpdate /> : null}
                {rowForm && actionName === "update-booking" ? <UseCZFormBookingUpdate /> : null}
                {rowForm && actionName === "edit-companion" ? <UseFormCompanion /> : null}
                {rowForm && actionName === "edit-topology-room" ? <UseFormTopologyRoom /> : null}
                {rowForm &&
                (actionName === "edit-media-library-folder" || actionName === "edit-media-library-media") ? (
                    <UseFormMediaLibraryFolder />
                ) : null}
            </>
        );
    };
    return (
        <>
            <div id="forms"></div>
            {results.length === 0 ? (
                serverError && (!customServerError || !customServerError.enabled) ? (
                    networkServerErrorDiv(customError, t)
                ) : (
                    noResultsDiv()
                )
            ) : (
                <div
                    id="cards"
                    className={`${customConfig ? customConfig.rowsContainerAdjust : null}`}
                    onScroll={(e) =>
                        customConfig && customConfig.onScrollCallback ? customConfig.onScrollCallback(e) : null
                    }
                >
                    {rowForm && actionName === "new-cz-area" ? <UseCZFormAreaCreate /> : null}
                    {rowForm && actionName === "new-cz-category" ? <UseCZFormCategoryCreate /> : null}
                    {rowForm && actionName === "new-area-booking" ? <UseCZFormBookingCreate /> : null}
                    {rowForm && actionName === "new-staff-member" ? <UseCZFormStaffCreate /> : null}
                    {rowForm && actionName === "add-companion" ? <UseFormCompanion /> : null}
                    {rowForm && actionName === "add-topology-room" ? <UseFormTopologyRoom /> : null}
                    {rowForm && actionName === "new-language" ? <UseLanguageFormCreate /> : null}
                    {rowForm && actionName === "add-media-library-folder" ? <UseFormMediaLibraryFolder /> : null}
                    {results
                        ? results.map((item, index) => (
                              <Fragment key={index}>
                                  {rowInEdition !== null && index === rowInEdition && rowForm ? (
                                      getEditForm()
                                  ) : (
                                      <TableCard index={index} />
                                  )}
                              </Fragment>
                          ))
                        : null}
                </div>
            )}
        </>
    );
};

export default UseCards;
