import React from "react";
import UseSectionHeader from "../../../useSectionHeader";
import Table from "components/ZafiroTable";
import Button from "components/Button";
import { useNavigate as useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useListOrderSummary } from "hooks/GraphqlCalls/Sales/useListOrderSummary";
import Loading from "components/Loading";

const OrderSummary = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { rows, ready } = useListOrderSummary();

    const breadcrumbs = [
        {
            name: "services",
            translate: true,
            route: "/services/",
        },
        {
            name: "sales",
            translate: true,
            route: "/services/",
        },
        {
            name: "orders",
            translate: true,
            route: "/services/sales/order-summary",
        },
    ];

    return (
        <>
            <UseSectionHeader title={"orders"} customBreadCrumbs={breadcrumbs} />
            <main className="main-container">
                {ready ? (
                    <Table
                        id="order-summary"
                        paginate={true}
                        search={true}
                        showCount={true}
                        perPage={8}
                        header={{
                            shop: { title: t("shop"), width: "30%" },
                            pending: { title: t("pending"), width: "20%" },
                            "in-progress": { title: t("in-progress"), width: "20%" },
                            ready: { title: t("ready"), width: "20%" },
                            button: { title: "", width: "10%" },
                        }}
                        cols={["shop", "pending", "in-progress", "ready", "button"]}
                        rows={rows}
                        topRightCorner={
                            <Button
                                id="monitor-all"
                                className="btn btn-blue"
                                onClick={() => history("/services/sales/order-monitor/all")}
                            >
                                {t("monitor-all")}
                            </Button>
                        }
                    />
                ) : (
                    <Loading adjust="section-loading" style={{ height: "50vh" }} />
                )}
            </main>
        </>
    );
};

export default OrderSummary;
