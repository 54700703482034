const AddFolder = (t, title, folderData) => {
    return {
        title: title,
        executeSectionQuery: true,
        bodyAdjust: "w-3/12",
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: "content-add-folder",
            },
        ],
        inputs: [
            {
                text: ["write-foldername", ":"],
                name: "name",
                type: "text",
                focus: true,
            },
            {
                value: folderData,
                type: "data",
            },
        ],
    };
};

export default AddFolder;
