import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setExecuteQuery } from "../../../../../actions/sectionActions";

import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Actions from "./CommonOptions/Actions";
import Widget from "../Widget";
import { WidgetContext } from "../../../../../contexts/editor";

const QRToolbar = ({ deviceSpecific }) => {
    const { id: widgetID } = useContext(WidgetContext);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { gridItems } = useSelector((state) => state.design);
    const { permissions } = useSelector((state) => state.ui);

    const hasCustomQR = permissions?.actions?.customQr;

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const currentActions =
        !widget || (!widget.data && hasCustomQR)
            ? null
            : widget.data?.predefined || !hasCustomQR
            ? [{ type: "qr", value: widget?.data?.predefined }]
            : widget.data.url
            ? [{ type: "url", value: widget.data.url }]
            : null;

    return (
        <>
            <Toolbar type="settings">
                <Actions
                    widgetTypeID={`QR_${widgetID}`}
                    title={t("add-qr")}
                    actions={{
                        disabled: false,
                        qr: true,
                        url: hasCustomQR,
                        http: true,
                    }}
                    value={currentActions}
                    onChange={(action) => {
                        const data = action
                            ? action.type === "url"
                                ? { url: action.value }
                                : action.type === "qr"
                                ? { predefined: action.value }
                                : {}
                            : {};
                        dispatch(setExecuteQuery(Widget.UpdateData({ widget, value: data })));
                    }}
                />
            </Toolbar>
            <Toolbar type="style">
                <Settings.Border t={t} />
            </Toolbar>
            {/* <Toolbar type="clone" /> */}
            <Toolbar type="delete" />
        </>
    );
};

export default QRToolbar;
