import {
    ADD_BATCH_PUNCTUAL,
    ADD_VIEW_PUNCTUAL,
    ADD_BATCH_PERIODIC,
    SET_BATCH_PERIODIC,
    ADD_VIEW_PERIODIC,
    SET_VIEW_PERIODIC,
    CLEAN_VIEW_SCHEDULES,
    CLEAN_BATCH_SCHEDULES,
    SET_SCHEDULE_ROWS,
    ADD_SCHEDULE_ROW,
    SET_SSCHEDULE_VALUE,
    ADD_SSCHEDULE_WEEKDAY,
    REMOVE_SSCHEDULE_WEEKDAY,
    RESET_SSCHEDULE_ROWS,
    SET_DATE_ROWS,
    ADD_DATE_ROW,
    SET_SDATE_VALUE,
    ADD_SDATE_DAYS,
    RESET_SDATE_ROWS,
    SET_SCHEDULE_2UPDATE,
    SET_SSCHEDULE_STATUS,
    SET_MUTATION_REFRESH,
    DELETE_SSCHEDULE_EDITOR,
    CANCEL_SCHEDULE_UPDATE,
    PSCHEDULE_BACKUP,
    PERIODIC_SCHEDULE_BACKUP,
    PSCHEDULE_ADD_ROW,
    SSCHEDULE_ADD_ROW,
    SET_CURRENT_ZONE_SCHEDULE,
    SET_LAST_SCHEDULE_EDITION,
} from "../actions/scheduleActions";

const initialState = {
    punctualSchedulesBatch: [],
    punctualSchedulesView: [],
    periodicSchedulesBatch: [],
    periodicSchedulesView: [],
    specialRowScheduleContainer: [
        {
            morningStart: false,
            morningEnd: false,
            afternoonStart: false,
            afternoonEnd: false,
            MSvalidated: true,
            MEvalidated: true,
            ASvalidated: true,
            AEvalidated: true,
            WDvalidated: true,
            weekdays: [],
        },
    ],
    specialRowDateContainer: [
        {
            specificDays: [],
            morningStart: false,
            morningEnd: false,
            afternoonStart: false,
            afternoonEnd: false,
            MSvalidated: true,
            MEvalidated: true,
            ASvalidated: true,
            AEvalidated: true,
            WDvalidated: true,
        },
    ],
    cZoneSchedule: [],
    lastScheduleEdition: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_BATCH_PUNCTUAL:
            return {
                ...state,
                punctualSchedulesBatch: action.payload,
            };
        case ADD_BATCH_PERIODIC:
            return {
                ...state,
                periodicSchedulesBatch: [...state.periodicSchedulesBatch, action.payload],
            };
        case SET_BATCH_PERIODIC:
            return {
                ...state,
                periodicSchedulesBatch: action.payload,
            };
        case ADD_VIEW_PUNCTUAL:
            return {
                ...state,
                punctualSchedulesView: [...state.punctualSchedulesView, action.payload],
            };
        case ADD_VIEW_PERIODIC:
            return {
                ...state,
                periodicSchedulesView: [...state.periodicSchedulesView, action.payload],
            };
        case SET_VIEW_PERIODIC:
            return {
                ...state,
                periodicSchedulesView: action.payload,
            };
        case CLEAN_BATCH_SCHEDULES:
            return {
                ...state,
                punctualSchedulesBatch: [],
                periodicSchedulesBatch: [],
            };
        case CLEAN_VIEW_SCHEDULES:
            return {
                ...state,
                punctualSchedulesView: [],
                periodicSchedulesView: [],
            };
        case SET_SCHEDULE_ROWS:
            return {
                ...state,
                specialScheduleRows: action.payload,
            };
        case SET_DATE_ROWS:
            return {
                ...state,
                specialDateRows: action.payload,
            };
        case ADD_SCHEDULE_ROW:
            return {
                ...state,
                specialRowScheduleContainer: [...state.specialRowScheduleContainer, action.payload],
            };
        case ADD_DATE_ROW:
            return {
                ...state,
                specialRowDateContainer: [...state.specialRowDateContainer, action.payload],
            };
        case SET_CURRENT_ZONE_SCHEDULE:
            return {
                ...state,
                cZoneSchedule: action.payload,
            };
        case SET_SSCHEDULE_VALUE:
            let currentRange = state.specialRowScheduleContainer;
            currentRange[action.payload.index][action.payload.valueKey] = action.payload.value;
            return {
                ...state,
                specialRowScheduleContainer: currentRange,
            };
        case SET_SDATE_VALUE:
            let currentSpecific = state.specialRowDateContainer;
            currentSpecific[action.payload.index][action.payload.valueKey] = action.payload.value;
            return {
                ...state,
                specialRowDateContainer: currentSpecific,
            };
        case ADD_SSCHEDULE_WEEKDAY:
            let currentWeekDays = state.specialRowScheduleContainer;
            currentWeekDays[action.payload.index].weekdays.push(action.payload.value);
            return {
                ...state,
                specialRowScheduleContainer: currentWeekDays,
            };
        case ADD_SDATE_DAYS:
            let currentSpecificDays = state.specialRowDateContainer;
            currentSpecificDays[action.payload.index].specificDays = action.payload.value;
            return {
                ...state,
                specialRowDateContainer: currentSpecificDays,
            };
        case REMOVE_SSCHEDULE_WEEKDAY:
            let currentWeekdays = state.specialRowScheduleContainer;
            if (currentWeekdays[action.payload.index]) {
                const newWeekdaysData = currentWeekdays[action.payload.index].weekdays.filter(
                    (weekday) => weekday !== action.payload.value
                );
                currentWeekdays[action.payload.index].weekdays = newWeekdaysData;
            }

            return {
                ...state,
                specialRowScheduleContainer: currentWeekdays,
            };
        case RESET_SSCHEDULE_ROWS:
            return {
                ...state,
                specialScheduleRows: 1,
                specialRowScheduleContainer: [
                    {
                        morningStart: false,
                        morningEnd: false,
                        afternoonStart: false,
                        afternoonEnd: false,
                        weekdays: [],
                        MSvalidated: true,
                        MEvalidated: true,
                        ASvalidated: true,
                        AEvalidated: true,
                        WDvalidated: true,
                    },
                ],
            };
        case RESET_SDATE_ROWS:
            return {
                ...state,
                specialDateRows: 1,
                specialRowDateContainer: [
                    {
                        morningStart: false,
                        morningEnd: false,
                        afternoonStart: false,
                        afternoonEnd: false,
                        specificDays: [],
                        MSvalidated: true,
                        MEvalidated: true,
                        ASvalidated: true,
                        AEvalidated: true,
                        WDvalidated: true,
                    },
                ],
            };
        case SET_SCHEDULE_2UPDATE:
            return {
                ...state,
                scheduleToUpdate: action.payload,
            };
        case SET_SSCHEDULE_STATUS:
            const currentBatch = state.periodicSchedulesBatch;
            currentBatch[action.payload.newIndex].isEnabled = action.payload.newStatus;
            return {
                ...state,
                periodicSchedulesBatch: currentBatch,
                periodicSchedulesView: currentBatch,
            };
        case SET_MUTATION_REFRESH:
            return {
                ...state,
                mutationRefresh: action.payload,
            };
        case DELETE_SSCHEDULE_EDITOR:
            const currentEvents = state.periodicSchedulesBatch;
            let newEvents = [];
            // eslint-disable-next-line
            currentEvents.forEach((currentEvent) => {
                if (JSON.stringify(currentEvent) !== JSON.stringify(action.payload)) {
                    newEvents.push(currentEvent);
                }
            });
            return {
                ...state,
                periodicSchedulesBatch: newEvents,
                periodicSchedulesView: newEvents,
            };
        case CANCEL_SCHEDULE_UPDATE:
            return {
                ...state,
                scheduleToUpdate: false,
            };
        case PSCHEDULE_BACKUP:
            return {
                ...state,
                scheduleBackup: action.payload,
            };
        case PERIODIC_SCHEDULE_BACKUP:
            return {
                ...state,
                periodicScheduleBackup: action.payload,
            };
        case PSCHEDULE_ADD_ROW:
            let actualPSchedule = state.scheduleToUpdate;
            actualPSchedule.timetableDates.push(action.payload);
            return {
                ...state,
                scheduleToUpdate: actualPSchedule,
            };
        case SSCHEDULE_ADD_ROW:
            let actualSSchedule = state.scheduleToUpdate;
            actualSSchedule.timetableDates[0].timetableDateRanges.push(action.payload);
            return {
                ...state,
                scheduleToUpdate: actualSSchedule,
            };
        case SET_LAST_SCHEDULE_EDITION:
            return {
                ...state,
                lastScheduleEdition: new window.ZDate(),
            };
        default:
            return state;
    }
}
