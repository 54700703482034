import React from "react";

const Checkable = ({ id, checked, onclick }) => {
    return (
        <div className={`t-checkbox-container`} id={`${id}-checkbox-container`}>
            <input
                type="checkbox"
                onClick={(e) => onclick(e)}
                onChange={(e) => null}
                className={`t-checkbox-checked opacity-${checked ? "100" : "0"}`}
                checked={checked}
                id={id}
            ></input>
            <span className="t-checkbox-unchecked" id={`${id}-checkbox-span`}></span>
        </div>
    );
};

export default Checkable;
