import React, { forwardRef } from "react";

import Toggle from "components/Inputs/Toggle";

const Checkbox = forwardRef((props, ref) => {
    return <Toggle {...{ ...props, ref }} design="checkbox" />;
});
Checkbox.displayName = "Checkbox";

export default Checkbox;
