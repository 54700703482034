import React, { useEffect, useState } from "react";
import { gql } from "apollo-boost";
import { POLLINTERVAL_15S, capitalizeFirst, checkShowTooltip, getFromDAS } from "../../../../../hooks/Utils/Utils";
import { useLazyQuery } from "react-apollo";
import { sortableContainer, sortableElement, arrayMove } from "react-sortable-hoc";
import UseItemActions from "../../../../useItemActions";
import ReactTooltip from "components/ReactTooltip";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setExecuteQuery } from "../../../../../actions/sectionActions";
import { useParams } from "react-router-dom";
import {
    addCheckedItem,
    removeCheckedItem,
    setTableResults,
    setRefreshData,
} from "../../../../../actions/tableActions";
import { getTagInfo } from "../../../../../hooks/Utils/Services/ChannelsUtils";

const ChannelsEditGroupGrid = ({ nameChannelGroup }) => {
    const [items, setItems] = useState([]);

    const { groupId } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { refreshData, activeFilters } = useSelector((state) => state.table);
    const search = activeFilters?.search ? ` filter: {search: "${activeFilters.search}"}` : "";

    const GET_INFO = gql(`{
        tunesInGroup(tuneGroupID: ${groupId} ${search} ) {
          info {
            count
          }
          results {
            id
            channelID
            logoRef
            tuneInfo
            type
            name
            isRadio
          }
        }
        }
      `);

    const [executeQuery, { data, loading, refetch }] = useLazyQuery(GET_INFO, {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_15S,
    });

    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (refreshData && refetch) {
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (data?.tunesInGroup?.results) {
            const dataResults = arrangeData(data.tunesInGroup.results);
            setItems(dataResults);
            dispatch(setTableResults(dataResults));
        }
    }, [data]);

    const arrangeData = (dataTunes) => {
        const arrData = [];
        dataTunes.forEach((t) => {
            const name = t?.name ? t.name : "";
            arrData.push({ ...t, name: name, info: [{ value: name }] });
        });
        return arrData;
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            setItems((prevItems) => {
                const movedItem = prevItems[oldIndex];
                const updatedItems = arrayMove(prevItems, oldIndex, newIndex);
                dispatch(
                    setExecuteQuery({
                        action: "move-channel-position-in-group",
                        params: { newIndex, tuneGroupID: groupId, id: movedItem.id },
                    })
                );
                return updatedItems;
            });
        }
    };

    return (
        <div
            className={` relative w-full px-6 pb-6 py-2 bg-white rounded ${
                items.length <= 0 && !loading ? " flex justify-center" : ""
            }  `}
            id="table-sortable-channels-group"
            style={{ marginTop: "-3rem", height: "50vh", overflowY: "scroll" }}
        >
            {items.length <= 0 && !loading ? (
                <p className=" text-center absolute text-gray-900 top-0">{t("not-channels-yet")}</p>
            ) : null}
            {items.length > 0 ? (
                <SortableContainer onSortEnd={onSortEnd} distance={30} helperClass="dragging" axis="xy">
                    {items.map((info, index) => {
                        return (
                            <SortableItem
                                key={`item-${info.id}`}
                                index={index}
                                info={{ ...info, index, max_value: items.length }}
                                nameChannelGroup={nameChannelGroup}
                                idGroup={groupId}
                            />
                        );
                    })}
                </SortableContainer>
            ) : null}
            <ReactTooltip
                delayShow={200}
                type={"light"}
                id="sortable-card-tooltip"
                borderColor={"#D3DAE1"}
                border={true}
                place="bottom"
                multiline={true}
            ></ReactTooltip>
        </div>
    );
};

const hasTune = (tuneInfo) => {
    const tunes = JSON.parse(tuneInfo);
    let hasTune = false;
    if (!tunes) {
        return hasTune;
    }
    Object.entries(tunes).map((tune) => (tune[1]?.isEnabled ? (hasTune = true) : null));
    return hasTune;
};

const SortableItem = sortableElement(({ info, nameChannelGroup, idGroup }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { checkedItems } = useSelector((state) => state.table);

    const [showTooltip, setShowTooltip] = useState(false);
    const [hasImageError, setHasImageError] = useState(false);
    const untuned = !hasTune(info?.tuneInfo);
    let tooltipName = "";
    if (info && info.name && showTooltip) {
        tooltipName = info.name;
    } else if (untuned) {
        tooltipName = t("untuned-channel");
    }
    return (
        <div
            id={`sortable-card-${info.id}`}
            onClick={(e) => {
                if (!e.target.classList.contains("t-action-container") && e.target.id !== "actions-container") {
                    if (!checkedItems.includes(info.id)) {
                        dispatch(addCheckedItem(info.id));
                    } else {
                        dispatch(removeCheckedItem(info.id));
                    }
                }
            }}
            className="relative flex items-center justify-center h-40 rounded  bg-gray-900"
            style={{
                outline: `4px solid ${checkedItems.includes(info.id) ? "#2286EF" : "transparent"}`,
                userSelect: "none",
            }}
        >
            {untuned ? (
                <div
                    data-tip={t("untuned-channel")}
                    className=" absolute h-32 top-8 left-0 rounded-b bg-gray-100 w-full z-10 "
                    style={{ opacity: 0.6 }}
                ></div>
            ) : null}
            <div className=" rounded-t  text-white flex items-center justify-between absolute top-0 left-0 h-8 w-full bg-gray-800 ">
                <span className=" block px-2 text-lg pt-1 icon icon-drag-and-drop"></span>
                <span
                    data-tip={t("position-in-the-group")}
                    style={{ paddingTop: "0.10rem" }}
                    data-for={"sortable-card-tooltip"}
                    className=" cursor-default block  text-lg"
                    id={`position-card-${info.id}`}
                >
                    {info.index + 1}
                </span>
                <span style={{ maxWidth: "30px" }}>
                    <UseItemActions
                        elementReference={"table-sortable-channels-group"}
                        dataType="channel"
                        id={info?.index + 1}
                        executeSectionQuery={true}
                        actions={[
                            {
                                name: t("edit-channel"),
                                action: "edit-channel-group",
                                adjust: " text-gray-900",
                                t,
                                link: `/services/tv-channels/groups/${idGroup}/edit-channel/${info.id}`,
                            },
                            {
                                name: t("move"),
                                action: "edit-move-channel-group",
                                adjust: "text-gray-900",
                                modal_title: "move-channel",
                                modal_value: info.name,
                                modal_btn_name: "move",
                                channel_info: { ...info, idGroup },
                                current_value: info.index + 1,
                                max_value: info.max_value,
                                t,
                            },
                            {
                                name: t("remove-from-group"),
                                action: "delete-channel-group",
                                adjust: "text-gray-900",
                                modal_title: "remove-from-group",
                                modal_btn_name: "remove",
                                modal_value: info.name,
                                name_channel_group: nameChannelGroup,
                                channel_info: { ...info, idGroup },
                                t,
                            },
                        ]}
                    />
                </span>
            </div>

            <div className=" h-24 relative flex items-center justify-center w-full">
                <div
                    id={`sortable-card-type-${info.id}`}
                    style={{ right: "0.25rem", top: "0.25rem" }}
                    className={` px-2 text-sm absolute rounded ${getTagInfo(info).color}`}
                >
                    {capitalizeFirst(t(getTagInfo(info).text))}
                </div>
                {!hasImageError && info.logoRef ? (
                    <img
                        id={`sortable-card-img-${info.id}`}
                        className=" pointer-events-none h-full w-full object-scale-down"
                        src={getFromDAS(info.logoRef)}
                        onError={() => setHasImageError(true)}
                        alt="something"
                    ></img>
                ) : (
                    <span
                        id={`sortable-card-no-img-${info.id}`}
                        className=" text-4xl icon icon-channel text-gray-800 "
                    ></span>
                )}
            </div>

            <div className=" text-white rounded absolute flex items-center  bottom-0 left-0 h-8 w-full bg-gray-800 ">
                <span
                    onMouseOver={(e) => checkShowTooltip(e.currentTarget, setShowTooltip)}
                    data-tip={tooltipName}
                    className=" px-2 truncate z-20"
                    id={`sortable-card-name-${info.id}`}
                    data-for={"sortable-card-tooltip"}
                >
                    {info.name}
                </span>
            </div>
        </div>
    );
});

const SortableContainer = sortableContainer(({ children }) => {
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: `repeat(auto-fill, minmax(clamp(7.4rem, 100%, 7.4rem), 1fr))`,
                gridGap: "2.3rem",
                alignItems: "center",
                overflow: "hidden",
                padding: "0.5rem",
            }}
        >
            {children}
        </div>
    );
});

export default ChannelsEditGroupGrid;
