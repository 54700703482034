import React, { createContext, useMemo, useState } from "react";

export const NetworkSettingsContext = createContext();

export const NetworkSettingsProvider = ({ children }) => {
    const [castSettings, setCastSettings] = useState({});
    const [wifiSettings, setWifiSettings] = useState({});

    const contextValue = useMemo(
        () => ({
            castSettings,
            setCastSettings,
            wifiSettings,
            setWifiSettings,
        }),
        [castSettings, wifiSettings]
    );

    return <NetworkSettingsContext.Provider value={contextValue}>{children}</NetworkSettingsContext.Provider>;
};
