import Logo from "zrender/widgets/logo";
import { CORPORATE_CHANNEL, PRESET_CHANNEL, CUSTOMISED_CHANNEL } from "../../Services/ChannelsUtils";

export const GetServicesTableHeaders = (section, data) => {
    let res = [];
    //Sort icon
    const tableSortIcon = { name: "table-sort" };

    //Special width classes

    const w112 = "w-1/12";
    const w212 = "w-2/12";
    const w312 = "w-3/12";
    const w412 = "w-4/12";
    const w512 = "w-5/12";
    const w612 = "w-6/12";
    // const w812  "w-8/12";
    // const w912  "w-9/12";

    //Header types
    let name = { name: "name", sortName: "name" };
    let logo = { name: "logo" };
    let country = { name: "country", sortName: "countryRef" };
    let language = { name: "language", sortName: "languageRef" };
    let type = { name: "type", sortName: "isRadio" };
    let tune = { name: "tune", sortName: "tuneInfo" };
    let group = { name: "group", sortName: "groupsNum" };
    let grid = { name: "grid", sortName: "gridsNum" };
    let use = { name: "use", sortName: "gridsNum" };
    let filter = { name: "filter", sortName: "filtersNum" };
    let numberChannels = { name: "number-channels", sortName: "channelsNum" };
    let numberGroups = { name: "number-groups", sortName: "tuneGroupsNum" };
    let content = { name: "content", sortName: "videosNum" };
    let emptyCell = { name: "" };

    switch (section) {
        case `list-channels-`:
        case `list-channels-${CORPORATE_CHANNEL.name}`:
        case `list-channels-${PRESET_CHANNEL.name}`:
        case `list-channels-${CUSTOMISED_CHANNEL.name}`:
            name.specialWidth = w312;
            type.specialWidth = w212;
            tune.specialWidth = w212;
            group.specialWidth = w212;
            grid.specialWidth = w212;

            name.icon = tableSortIcon;
            type.icon = tableSortIcon;
            tune.icon = tableSortIcon;
            group.icon = tableSortIcon;
            grid.icon = tableSortIcon;

            if (data && data.folder === CORPORATE_CHANNEL.value) {
                tune.specialWidth = w312;
                group.specialWidth = w312;
                res.push(name, tune, group, grid);
            } else {
                res.push(name, type, tune, group, grid);
            }
            break;
        case "list-group-channels":
            name.specialWidth = w312;
            use.specialWidth = w312;
            numberChannels.specialWidth = w312;
            name.icon = tableSortIcon;
            use.icon = tableSortIcon;
            numberChannels.icon = tableSortIcon;
            emptyCell.specialWidth = w312;
            res.push(name, numberChannels, use, emptyCell);
            break;
        case "list-grids-channels":
            name.specialWidth = w312;
            filter.specialWidth = w312;
            numberGroups.specialWidth = w312;
            name.icon = tableSortIcon;
            if (data?.multipleGrids) {
                filter.icon = tableSortIcon;
                filter.specialWidth = `${w312}`;
            } else {
                filter.icon = null;
                filter.specialWidth = `${w312} opacity-0`;
            }
            numberGroups.icon = tableSortIcon;
            emptyCell.specialWidth = w312;
            res.push(name, numberGroups, filter, emptyCell);
            break;
        case "list-corporate-channels":
            name.specialWidth = w312;
            content.specialWidth = w312;
            use.specialWidth = w612;
            use.sortName = "usedInProjectsNum";
            name.icon = tableSortIcon;
            content.icon = tableSortIcon;
            use.icon = tableSortIcon;
            res.push(name, content, use);
            break;
        case "list-predefined-channels":
            logo.specialWidth = `${w112}`;
            name.specialWidth = `${w312}`;
            type.specialWidth = `${w112}`;
            country.specialWidth = `${w212}`;
            language.specialWidth = `${w212}`;
            use.specialWidth = `${w312} `;
            country.icon = tableSortIcon;
            use.sortName = "usedInProjectsNum";
            logo.iconTooltip = {
                icon: "info",
                tooltip: "image-recommended-channel",
            };
            type.icon = tableSortIcon;
            language.icon = tableSortIcon;
            name.icon = tableSortIcon;
            use.icon = tableSortIcon;
            res.push(logo, name, type, country, language, use);
            break;
        default:
            res = [""];
            break;
    }
    return res;
};

export default GetServicesTableHeaders;
