import React, { useState } from "react";
import { useSelector } from "react-redux";
import { parseTranslation, getProjectLangs } from "../../hooks/Utils/SalesUtils";
import { useTranslation } from "react-i18next";
import { Session } from "../../hooks/Utils/Session";
import { useAuth } from "hooks/Session/auth";

const UseModalTaxInUse = ({ index }) => {
    const { data: modalData } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { languages: projectLangs } = useAuth();
    const { t } = useTranslation();

    const projects = Session.getProjects() || [];
    const idTax = modalData?.taxZone?.id ? Number(modalData.taxZone.id) : null;

    console.log("ID de Impuesto:", idTax);

    // Obtener nombres de los proyectos de las películas
    const moviesWithTaxInfo = Array.isArray(modalData?.moviesData)
        ? modalData.moviesData
              .filter((m) => m.config && m.config.length > 0 && m.project && m.project.name)
              .map((m) => {
                  const configItem = m.config.find((c) => c.value);
                  if (configItem) {
                      try {
                          const parsedValue = JSON.parse(configItem.value);
                          return { projectName: m.project.name, taxZone: parsedValue.taxZone };
                      } catch (e) {
                          console.error("Error parsing config value:", e);
                          return null;
                      }
                  }
                  return null;
              })
              .filter((m) => m !== null)
        : [];

    // Obtener las películas que usan la tasa de impuestos actual
    const moviesProjectNames = moviesWithTaxInfo
        .filter((m) => String(m.taxZone) === String(idTax))
        .map((m) => m.projectName);

    console.log("Proyectos de películas con la tasa actual:", moviesProjectNames);

    const shopsRelatedToTax =
        idTax && modalData?.shopsData?.length > 0
            ? modalData.shopsData.filter((s) => Number(s.defaultTaxZone.id) === idTax)
            : [];

    const projectsGrouped = shopsRelatedToTax.reduce((acc, shop) => {
        const projectMatch = projects.find((p) => p.ref === shop.projectRef);
        const projectName = projectMatch ? projectMatch.name : "Unknown Project";

        if (!acc[projectName]) {
            acc[projectName] = { shops: [], movies: [] };
        }

        acc[projectName].shops.push(parseTranslation(shop.name, getProjectLangs(projectLangs, t).langDefault));
        return acc;
    }, {});

    moviesProjectNames.forEach((projectName) => {
        if (!projectsGrouped[projectName]) {
            projectsGrouped[projectName] = { shops: [], movies: [] };
        }
        projectsGrouped[projectName].movies.push(t("movies"));
    });

    const [expandedProjects, setExpandedProjects] = useState({});

    const toggleProject = (projectName) => {
        setExpandedProjects((prevState) => ({
            ...prevState,
            [projectName]: !prevState[projectName],
        }));
    };

    return (
        <div>
            <div
                style={{ minHeight: "21.45rem", maxHeight: "21.45rem", overflowY: "scroll" }}
                className="border-t border-b rounded border-gray-200"
            >
                {Object.entries(projectsGrouped).map(([projectName, { shops, movies }], index, array) => (
                    <div
                        key={projectName}
                        className={`py-2 ${index !== 0 ? "border-t border-gray-200" : ""} ${
                            index === array.length - 1 ? "border-b border-gray-200" : ""
                        }`}
                    >
                        <div className="flex items-center cursor-pointer" onClick={() => toggleProject(projectName)}>
                            <i
                                className={`fas py-4 fa-chevron-${expandedProjects[projectName] ? "up" : "down"} mr-3`}
                            ></i>
                            <span className="font-bold block w-full cursor-pointer">{projectName}</span>
                        </div>
                        {expandedProjects[projectName] && (
                            <>
                                {movies.length > 0 && (
                                    <div className="pl-6 py-4 border-t border-gray-200">
                                        <span className="block w-full">{`${t("movies")}`}</span>
                                    </div>
                                )}

                                {shops.map((shopName) => (
                                    <div key={shopName} className="pl-6 py-4 border-t border-gray-200">
                                        <span className="block w-full">{`${t("shop")} - ${shopName}`}</span>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UseModalTaxInUse;
