import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//Components
import UseCalendarDays from "./useCalendarDays";
import UseCalendarRange from "./useCalendarRange";
//Utils
import useComponentVisible from "../useComponentVisible";

const UseInputDate = ({ validation }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { dateType } = useSelector((state) => state.action);
    const [selectActive, setSelectActive] = useState({});
    const { specificDates, specificRange } = useSelector((state) => state.action);

    //Validation styles
    const [datesError, setDatesError] = useState(validation);

    //Styles
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const getErrorStyle = (error) => {
        let res = "";
        if (!error) {
            res = "border border-red-100";
        }
        return res;
    };

    //Listeners
    useEffect(() => {
        setDatesError(validation);
    }, [validation]);

    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({});
        } else {
            setSelectActive({
                text: "text-blue-300",
            });
        }
    }, [isComponentVisible]);

    return (
        <div ref={ref} className="relative">
            {dateType === "date-range" ? (
                <>
                    <div
                        className={`w-full h-10 bg-gray-200 rounded relative p-2 ${selectActive.text} ${getErrorStyle(
                            datesError
                        )}`}
                        onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                    >
                        {specificRange && specificRange.to ? (
                            `${specificRange.from.toLocaleDateString()} - ${specificRange.to.toLocaleDateString()}`
                        ) : (
                            <div className="first-capital">{langStrings["range"]}</div>
                        )}
                    </div>
                    {!datesError ? (
                        <p className="absolute text-red-100">{langStrings["schedule-range-error"]}</p>
                    ) : null}
                    {isComponentVisible ? (
                        <div className="absolute z-200">
                            <UseCalendarRange />
                        </div>
                    ) : null}
                </>
            ) : (
                <>
                    <div
                        className={`w-48 h-10 bg-gray-200 rounded relative p-2 ${selectActive.text} ${getErrorStyle(
                            datesError
                        )}`}
                        onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                    >
                        {specificDates && specificDates.length > 0
                            ? `${specificDates.length} ${langStrings["dates-selected"]}`
                            : langStrings["days"]}
                    </div>
                    {!datesError ? <p className="absolute text-red-100">{langStrings["schedule-days-error"]}</p> : null}
                    {isComponentVisible ? (
                        <div className="absolute">
                            <UseCalendarDays />
                        </div>
                    ) : null}
                </>
            )}
        </div>
    );
};

export default UseInputDate;
