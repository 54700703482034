import React from "react";

export const CastingWarning = ({ text }) => {
    return (
        <div className="p-4 bg-gray-900 text-white w-full mb-8">
            <div className="flex gap-4 items-center">
                <span className=" icon icon-alert text-red-100 text-3xl "></span>
                <div className="my-1 ml-2">{text}</div>
            </div>
        </div>
    );
};
