const PropertyGroup = (propertyGroup, t) => {
    const { id, name, projects } = propertyGroup;
    const dataType = "propertyGroup";

    let listProperties = [];
    if (projects)
        projects.forEach((project, index) => {
            listProperties.push([
                index,
                `${
                    project?.hasChainModule
                        ? `<span class="icon-corporate-access mr-2 inline-block" data-for="dangerous-html-tooltip" data-tip="${t(
                              "This property has access to Global Management content"
                          )}"></span>`
                        : ""
                }${project?.name}`,
                "bg-teal-500",
            ]);
        });

    return {
        ...propertyGroup,
        dataType: dataType,
        adjust: "h-auto",
        actions: [{ type: dataType, value: id }],
        mutationParams: { id: id, name: name },
        info: [
            {
                cellContent: "text",
                value: name,
                specialWidth: "w-3/12",
            },

            {
                cellContent: "labelsTruncate",
                value: listProperties,
                specialWidth: "w-9/12 pr-6",
                cellAdjust: "truncate labels",
            },
        ],
    };
};

export default PropertyGroup;
