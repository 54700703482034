import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageChannelSelector from "./ImageChannelSelector";
import UseInputText from "../../../../Table/useInputText";
import { useDispatch, useSelector } from "react-redux";
import { setExecuteQuery, setRefreshContentData } from "../../../../../actions/sectionActions";
import { capitalizeFirst, POLLINTERVAL_15S } from "../../../../../hooks/Utils/Utils";
import { toast } from "react-toastify";
import { useLazyQuery } from "react-apollo";
import PreviewCorporateChannel from "../../../../../hooks/GraphqlCalls/Services/modals/PreviewCorporateChannel";
import { gql } from "apollo-boost";
import { setModalContent, openModal } from "../../../../../actions/uiActions";
import UseComponentLoading from "../../../../useComponentLoading";

const CorporateChannelInformationCard = ({ data }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { permissions } = useSelector((state) => state.ui);
    const { refreshData } = useSelector((state) => state.sectionContent);
    const initialDataChannel = data?.channelData ? data.channelData : {};
    const setNameChannel = data?.setName ? data.setName : null;
    const [validations, setValidations] = useState({});
    const [dataChannel, setDataChannel] = useState({});
    const [videosRef, setVideosRef] = useState([]);
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);

    const GET_VIDEOS_FROM_CORP_CHANNEL = gql`
        query {
            corporateTvChannelVideos(corpChannelID: ${
                dataChannel?.["channelID"] ? dataChannel?.["channelID"] : dataChannel?.["id"]
            }) {
                corporateTvChannelID
                id
                pos
                videoRef
            }
        }
    `;

    const [getDetailsVideos, { data: dataVideosCorpChannel }] = useLazyQuery(GET_VIDEOS_FROM_CORP_CHANNEL, {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_15S,
    });

    useEffect(() => {
        if (dataChannel?.["channelID"] ? dataChannel?.["channelID"] : dataChannel?.["id"]) {
            getDetailsVideos();
        }
    }, [dataChannel]);

    useEffect(() => {
        if (dataVideosCorpChannel?.corporateTvChannelVideos) {
            setVideosRef(
                dataVideosCorpChannel?.corporateTvChannelVideos.map((v) => {
                    return v.videoRef;
                })
            );
            setInitialLoading(false);
        }
    }, [dataVideosCorpChannel]);

    useEffect(() => {
        if (refreshData) {
            setLoading(false);
            dispatch(setRefreshContentData(false));
        }
    }, [refreshData]);

    useEffect(() => {
        if (initialDataChannel) {
            setDataChannel(initialDataChannel);
        }
    }, [initialDataChannel]);

    return initialLoading ? (
        <UseComponentLoading />
    ) : (
        <div className=" flex flex-col relative">
            <div className="flex items-center mb-5">
                <span className={`font-bold mr-2 text-1xl`}>{t("channel-information")}</span>
                {dataChannel?.isCorporateTvChannelLocal ? (
                    <>
                        <i
                            data-for={"dangerous-html-tooltip"}
                            data-tip={t("image-recommended-channel")}
                            className={"icon icon-info text-gray-800 text-1xl"}
                        ></i>
                    </>
                ) : null}
            </div>
            <div className=" flex">
                <div style={{ width: "14.2rem" }} className=" mr-18">
                    <ImageChannelSelector
                        action="add-image-to-corporate-channel"
                        dataComponent={dataChannel}
                        setDataComponent={setDataChannel}
                        adjustButtonImage={`h-10 ${!dataChannel?.isCorporateTvChannelLocal ? "hidden" : ""}`}
                        idCard="corporate-channel-information-card"
                        widthImage="100%"
                        heightImage="11rem"
                        headerLogoAdjust=" hidden"
                        headerLogoText=" hidden"
                        headerIconAdjust=" hidden"
                        textButton="choose-an-image"
                        adjustButtonDeleteImage={`${!dataChannel?.isCorporateTvChannelLocal ? "hidden" : ""}`}
                        dispatchActionDeleteImage="delete-image-corporate-channel"
                    />
                </div>
                <div className=" flex flex-1 mt-4 ">
                    <div className=" flex-col">
                        <div
                            className=" font-bold flex items-center mb-3 mr-4"
                            style={{ height: "2.2rem" }}
                            id="corporate-channel-information-card-label-name"
                        >{`${capitalizeFirst(t("name"))}:`}</div>
                        <div
                            className=" font-bold flex items-center mb-3 mr-4"
                            style={{ height: "2.2rem" }}
                            id="corporate-channel-information-card-label-type"
                        >{`${capitalizeFirst(t("type"))}:`}</div>
                        {!permissions?.services?.corporateChannelsWithoutGlobalManagement ? (
                            <div
                                className=" font-bold flex items-center mb-3 mr-4"
                                style={{ height: "2.2rem" }}
                                id="corporate-channel-information-card-label-content"
                            >{`${capitalizeFirst(t("content"))}:`}</div>
                        ) : null}
                    </div>
                    <div className=" flex-col w-full">
                        <div className=" mb-3 h-10 flex items-center" style={{ height: "2.2rem" }}>
                            {dataChannel?.isCorporateTvChannelLocal ? (
                                <UseInputText
                                    onBlurAction={(val) => {
                                        if (val && val !== dataChannel?.name) {
                                            setValidations({ ...validations, "input-text-name-channel": false });
                                            dispatch(
                                                setExecuteQuery({
                                                    action: "update-name-corp-channel",
                                                    params: { nameVal: val },
                                                    hideLoading: true,
                                                })
                                            );
                                            setLoading(true);
                                            setDataChannel({ ...dataChannel, name: val });
                                        } else if (!val) {
                                            setDataChannel({ ...dataChannel, name: "" });
                                            setValidations({ ...validations, "input-text-name-channel": true });
                                            toast.error(t("input error"));
                                        }
                                    }}
                                    disabled={loading}
                                    onChangeAction={(val) => {
                                        if (setNameChannel) {
                                            setNameChannel(val);
                                        }
                                    }}
                                    ph={t("name")}
                                    id="corporate-channel-information-card-input-name"
                                    validation={validations["input-text-name-channel"] ? false : true}
                                    currentValue={dataChannel?.name ? dataChannel.name : ""}
                                    inputData={{ name: "name-corporate-channel" }}
                                    adjust="h-full text-base "
                                />
                            ) : (
                                <span id="corporate-channel-information-card-value-name"> {dataChannel?.name} </span>
                            )}
                        </div>
                        <div
                            id="corporate-channel-information-card-value-type"
                            className=" mb-3 flex items-center"
                            style={{ height: "2.2rem" }}
                        >
                            {t("corporate-channel")}
                        </div>

                        {!permissions?.services?.corporateChannelsWithoutGlobalManagement ? (
                            <div
                                id="corporate-channel-information-card-value-content"
                                className=" mb-3 flex items-center"
                                style={{ height: "2.2rem" }}
                            >
                                {t(
                                    dataChannel?.isCorporateTvChannelLocal || dataChannel?.isContentProperty
                                        ? "content-property"
                                        : "global-management-content"
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            {dataChannel?.isContentProperty || !dataChannel?.isCorporateTvChannelLocal ? (
                <button
                    onClick={() => {
                        if (videosRef.length > 0) {
                            const content = PreviewCorporateChannel(t, {
                                videosRef: videosRef,
                                dataChannel: dataChannel,
                            });
                            dispatch(setModalContent(content));
                            dispatch(openModal());
                        }
                    }}
                    id="corporate-channel-information-card-preview-button"
                    className=" font-bold focus:outline-none absolute bottom-0 right-0 text-zafiro-600"
                >
                    {t("preview-channel")}
                </button>
            ) : null}
        </div>
    );
};

export default CorporateChannelInformationCard;
