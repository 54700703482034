import { GetThemeDefault, isEmpty } from "../DesignUtils";
export const getParameterFromWidgetStyle = (parameter, widget, themeSpecificSyle) => {
    const widgetStyleParameter =
        widget.widgetStyle && widget.widgetStyle[parameter] ? widget.widgetStyle[parameter] : null;
    const themeSpecificSyleParameter = themeSpecificSyle[parameter] ? themeSpecificSyle[parameter] : null;
    let themeBasic = null;
    if (!themeSpecificSyleParameter) {
        // if there is no parameter in theme search for a basic option
        const basicOptions = ["bold", "italic", "underline", "font", "size"];
        basicOptions.forEach((element) => {
            if (parameter.includes(element)) {
                themeBasic = themeSpecificSyle[element];
            }
        });
    }
    return widgetStyleParameter
        ? widgetStyleParameter
        : themeSpecificSyleParameter
        ? themeSpecificSyleParameter
        : themeBasic;
};

export const getWidgetStyleSet = (style, theme) => {
    const defaultBgColor = GetThemeDefault({ theme, id: "bgColor" });
    const defaultFgColor = GetThemeDefault({ theme, id: "fgColor" });
    const defaultFgActiveColor = GetThemeDefault({ theme, id: "fgActiveColor" });
    const defaultBorderColor = GetThemeDefault({ theme, id: "borderColor" });
    const defaultDisabledBgColor = GetThemeDefault({ theme, id: "disabledBgColor" });
    const defaultDisabledFgColor = GetThemeDefault({ theme, id: "disabledFgColor" });
    const defaultDisabledBorderColor = GetThemeDefault({ theme, id: "disabledBorderColor" });

    const bgColor = style?.bgColor || defaultBgColor;
    const fgColor = style?.fgColor || defaultFgColor;
    const iconColor = style?.iconColor || style?.items?.icon?.fgColor || defaultFgColor;
    const fgActiveColor = style?.fgActiveColor || style?.status?.active?.fgColor || defaultFgActiveColor;
    const radius = style && (style.radius || style.radius === 0) ? style.radius : 0;
    const fontName = style?.fontName || style?.font?.name || theme?.paragraphStyle?.font;
    const textSize = style?.textSize || style?.size || theme?.paragraphStyle?.size;
    const iconSize = style?.iconSize || style?.item?.icon?.size || theme?.paragraphStyle?.size;
    const iconPosition = style?.iconPosition || style?.items?.icon?.position || "right";
    const iconMargin = style && !isEmpty(style.iconMargin) ? style.iconMargin : 0.5;
    const alignment = style && style.alignment ? style.alignment : "center";
    const valignment = style && style.vAlignment ? style.vAlignment : "center";
    const borderColor = style?.borderColor || defaultBorderColor;
    const borderWidth = style && style.borderWidth !== null ? style.borderWidth : 0;
    const bold = style && Object.keys(style).includes("bold") ? style.bold : theme?.paragraphStyle?.bold;
    const italic = style && Object.keys(style).includes("italic") ? style.italic : theme?.paragraphStyle?.italic;
    const underline =
        style && Object.keys(style).includes("underline") ? style.underline : theme?.paragraphStyle?.underline;
    const disabledBgColor = style?.disabledBgColor || style?.status?.disabled?.bgColor || defaultDisabledBgColor;
    const disabledFgColor = style?.disabledFgColor || style?.status?.disabled?.fgColor || defaultDisabledFgColor;
    const disabledBorderColor =
        style?.disabledBorderColor || style?.status?.disabled?.borderColor || defaultDisabledBorderColor;
    const disabledBorderWidth = style?.disabledBorderWidth || style?.status?.disabled?.borderWidth || 0;

    // Alarm widget
    const fontNameAlarmSet = style && style.fontNameAlarmSet ? style.fontNameAlarmSet : theme?.paragraphStyle?.font;
    const textSizeAlarmSet = style && style.textSizeAlarmSet ? style.textSizeAlarmSet : theme?.paragraphStyle?.size;
    const boldAlarmSet =
        style && Object.keys(style).includes("boldAlarmSet") ? style.boldAlarmSet : theme?.paragraphStyle?.bold;
    const italicAlarmSet =
        style && Object.keys(style).includes("italicAlarmSet") ? style.italicAlarmSet : theme?.paragraphStyle?.italic;
    const underlineAlarmSet =
        style && Object.keys(style).includes("underlineAlarmSet")
            ? style.underlineAlarmSet
            : theme?.paragraphStyle?.underline;
    const fgActiveColorAlarmSet =
        style && Object.keys(style).includes("fgActiveColorAlarmSet") ? style.fgActiveColorAlarmSet : fgActiveColor;

    return {
        bgColor,
        fgColor,
        iconColor,
        fgActiveColor,
        radius,
        fontName,
        textSize,
        iconSize,
        iconPosition,
        iconMargin,
        alignment,
        valignment,
        borderColor,
        borderWidth,
        bold,
        italic,
        underline,
        disabledBgColor,
        disabledFgColor,
        disabledBorderColor,
        disabledBorderWidth,
        fontNameAlarmSet,
        textSizeAlarmSet,
        boldAlarmSet,
        italicAlarmSet,
        underlineAlarmSet,
        fgActiveColorAlarmSet,
    };
};
