const translate = (term, value) => {
    const translations = {
        //Sections

        //Common zones
        booking: "Reservas",
        "capacity-monitor": "Monitorización de aforo",
        "areas-activities": "Áreas y actividades",
        status: "Estado",
        "status-booking-0": "Pendiente",
        "status-booking-1": "Confirmada",
        "status-booking-2": "Cancelada",
        "status-booking-3": "Cancelada",
        unspecified: "Sin especificar",
        "temp-closed": "Cerrado temporalmente",

        //Booking
        "manage-bookings": "Gestionar reservas",
        "booking-data": "Datos de la reserva",
        "client-data": "Datos del cliente",
        day: "Día",
        hour: "Hora",
        people: "Personas",
        done: "Realizada",
        today: "Hoy",
        "select-date": "Seleccione una fecha",
        "add-dates": "Añadir fechas",
        "add-schedules": "Añadir horarios",
        state: "Estado",
        "state-booking-0": "Pendiente",
        "state-booking-1": "Confirmada",
        "state-booking-2": "Cancelada",
        "state-booking-2,3": "Cancelada",
        "day-00": "Domingo",
        "day-01": "Lunes",
        "day-02": "Martes",
        "day-03": "Miércoles",
        "day-04": "Jueves",
        "day-05": "Viernes",
        "day-06": "Sábado",
        "month-0": "Enero",
        "month-1": "Febrero",
        "month-2": "Marzo",
        "month-3": "Abril",
        "month-4": "Mayo",
        "month-5": "Junio",
        "month-6": "Julio",
        "month-7": "Agosto",
        "month-8": "Septiembre",
        "month-9": "Octubre",
        "month-10": "Noviembre",
        "month-11": "Diciembre",
        of: "de",
        "reason-cancelled": "Motivo",
        phone: "Teléfono",
        email: "Email",

        //Devices
        keys: "Llaves",
        "remote-controls": "Mandos",

        //Clients
        "clients-list": "Listado clientes",
        prechecking: "Pre-checking",
        client: "Cliente",

        //Mobile
        design: "Diseño",
        configuration: "Configuración",
        "assigned-hotels": "Propiedades asignadas",

        //Wizard
        "wizard-error": "Lo sentimos, el wizard no está disponible",
        "event-type": "Tipo de evento",
        "days-schedule": "Días y horario",
        "closed-days": value === "plural" ? " días cerrados" : "Días cerrados",
        "event-unique": "Evento único (no se repite)",
        "event-periodic": "Evento periódico",
        "schedule-common": "Horario habitual",
        "schedule-special": "Horario especial",
        "special-schedule": " horarios especiales",
        "schedule-festive": "Días cerrados",
        "schedule-punctual": "Evento puntual",
        "sh-periodic-common": "Horario periódico habitual",
        "sh-periodic-special": "Horario periódico especial",
        "sh-periodic-festive": "Horario periódico festivo",
        "sh-punctual": "Horario puntual",
        "repeat-yearly": "Repetir anualmente",

        //Dispatcher
        "punctual-event-title": "Evento puntual",
        "punctual-event-text":
            "Eventos que no se repitan regularmente en el hotel como espectáculos, actividades concretas, etc.",
        "periodic-event-title": "Evento periódico",
        "periodic-event-text":
            "Horarios que se repitan cada semana, por ejemplo para restaurantes, sesiones de spá, etc.",

        //Schedule punctual
        "view-schedules": "Ver horarios",
        name: "Nombre",
        "date-type": "Tipo de fecha",
        dates: "Fechas",
        schedule: "Horario",
        "date-range": "Rango de fechas",
        range: "Rango de fechas",
        specific: "Días específicos",
        "date-specific": "Días específicos",
        "dates-selected": "fechas seleccionadas",
        "enter-name": "Escriba el nombre",
        days: "Días",
        "activity-name-error": "Por favor, indique un nombre.",
        "activity-datetype-error": "Por favor, seleccione un tipo de fecha.",
        "activity-dates-error": "Por favor, seleccione un rango de fechas.",
        "activity-days-error": "Por favor, seleccione uno o varios días.",
        "schedule-error": "Escriba el horario deseado.",
        "multiple-edit-error": "Por favor, guarde o cancele los cambios antes de empezar a editar otro registro.",
        "multbooking-edit-error":
            "Por favor, guarde o cancele los cambios en la reserva antes de empezar a editar otra.",
        "multibooking-create-error": "Por favor, guarde o cancele los cambios en la reserva antes de crear otra.",
        "day-1": "Lunes",
        "day-1-short": "L",
        "day-2": "Martes",
        "day-2-short": "M",
        "day-3": "Miércoles",
        "day-3-short": "X",
        "day-4": "Jueves",
        "day-4-short": "J",
        "day-5": "Viernes",
        "day-5-short": "V",
        "day-6": "Sabado",
        "day-6-short": "S",
        "day-7": "Domingo",
        "day-7-short": "D",
        enabled: "Activo",
        disabled: "Desactivado",
        festive: "Festivos",
        "common-schedule": value === "plural" ? " horarios habituales" : "Horario habitual",
        "week-days": "Días de la semana",
        "choose-weekday": "Por favor, escoja uno o varios días de la semana.",
        "add-schedule": "Añadir horario",
        //Schedule filter
        show: "Ver",
        //Schedule mutation success
        "punctual-schedule-success": `Se ha añadido el horario puntual correctamente.`,
        "punctual-update-success": `Se ha modificado el horario del evento puntual correctamente.`,
        "periodic-schedule-success": `Se ha añadido el horario habitual correctamente.`,
        "periodic-update-success": `Se ha modificado el horario del evento periódico correctamente.`,
        "finish-before-add": `Por favor, complete el horario añadido antes de añadir otro.`,
        "cant-delete-last": `Las fechas deben tener un horario. Edítelo o borre ${value}`,

        //Schedule exit whithout save
        "exit-wo-save": "Ha escogido salir sin guardar los horarios. No se ha realizado ninguna modificación.",

        //Internal Error
        "technical-error":
            "Lo sentimos, estamos trabajando para solucionar la incidencia a la mayor brevedad posible. Gracias por su paciencia.",
        "error-booking-area-list": "Lo sentimos, en este momento no es posible mostrar las reservas de este área.",
        "error-czcarea-list": "Lo sentimos, en este momento no es posible mostrar las áreas de esta zona común.",
        "booking-list-error": "No hay reservas para la fecha seleccionada.",
        "areas-categories-error": "No hay categorías disponibles.",
        "areas-activities-error": "No hay áreas o actividades disponibles.",
        "staff-members-error": "No hemos encontrado ningún miembro del staff que mostrar.",
        "categories-error": "No hay resultados disponibles.",

        //General
        yes: "Si",
        no: "No",
    };
    return translations[term];
};

export default translate;
