import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Parser from "hooks/Utils/Parser";
import UseRadio from "components/useRadio";
import { setSectionContent } from "actions/sectionActions";

const UseModalDvbImportResume = ({ index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const ACTIONS = {
        PRESERVE: "preserve",
        DELETE: "delete",
    };
    const [action, setAction] = useState(ACTIONS.PRESERVE);

    const { channelsToAdd, tunesToDelete } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { sectionContent } = useSelector((state) => state);

    const hasPreviousDVBTune = (channel) => {
        if (!channel.Tune?.TuneInfo) {
            return false;
        }
        return channel.Tune?.TuneInfo?.[channel?.TuneType];
    };
    const tuneType = channelsToAdd?.[0]?.TuneType;

    const printTuneType = (type) => {
        return type?.replaceAll("-", " ");
    };
    //get channels to be overwritten
    let overwrittenChannels = channelsToAdd?.filter((ch) => hasPreviousDVBTune(ch));
    let overwrittenChannelsNum = overwrittenChannels?.length || 0;

    useEffect(() => {
        dispatch(
            setSectionContent({
                ...sectionContent,
                actionOnChannelNotFound: action,
            })
        );
    }, [action]);

    return (
        <>
            <div className="flex">
                <div className={`${tunesToDelete?.length > 0 ? "w-3/6" : "w-full"} mr-6 mb-8`}>
                    <div>{t("parsing-summary-text")}</div>
                    <div className="font-bold mt-8">{t("channels tune to be added")}</div>
                    <div className="mt-3">
                        {Parser(
                            t("tunes-to-be-added", {
                                num: channelsToAdd?.length - overwrittenChannelsNum,
                                tune: printTuneType(tuneType),
                            })
                        )}
                        .
                    </div>
                    {overwrittenChannelsNum > 0 ? (
                        <>
                            <div className="font-bold mt-8">{t("channels tune to be overwritten")}</div>
                            <div className="mt-3">
                                {Parser(
                                    t("tunes-to-be-overwritten", {
                                        num: overwrittenChannelsNum,
                                        tune: printTuneType(tuneType),
                                    })
                                )}
                                .
                            </div>
                        </>
                    ) : null}
                </div>
                {tunesToDelete?.length > 0 ? (
                    <div className="w-3/6">
                        <>
                            <div className="flex">
                                <div className="flex icon icon-warning text-2xl text-orange-100"></div>
                                <div className="block ml-2">
                                    {Parser(
                                        t("dvb-overwritten-confirm", {
                                            num: tunesToDelete?.length,
                                            tune: printTuneType(tuneType),
                                        })
                                    )}
                                </div>
                            </div>
                            <div className="ml-8 my-6">
                                <div className="mb-2">
                                    <UseRadio
                                        value={ACTIONS.PRESERVE}
                                        text="preserve channel tuning"
                                        selected={action}
                                        handleClick={() => setAction(ACTIONS.PRESERVE)}
                                    />
                                </div>
                                <UseRadio
                                    value={ACTIONS.DELETE}
                                    selected={action}
                                    text="delete channel tuning"
                                    handleClick={() => setAction(ACTIONS.DELETE)}
                                />
                            </div>
                            <div className="flex w-full">
                                <div className="font-bold text-xl w-3/5">{t("channels")}</div>
                            </div>
                            <div className="border border-gray-200">
                                <div className="h-80 mt-2 mx-3 overflow-scroll">
                                    {tunesToDelete?.map((ch, index) => (
                                        <div
                                            className={`${
                                                index < tunesToDelete?.length - 1 && "border-b border-gray-200"
                                            } p-3`}
                                        >
                                            {" "}
                                            {ch.Name}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default UseModalDvbImportResume;
