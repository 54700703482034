import { useContext, useEffect, useState } from "react";
import { GET_ACCESS_PROFILES } from "../utils/accessProfileQueries";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMSQuery } from "hooks/GraphqlCalls/useMSQuery";
import { capitalizeFirst } from "hooks/Utils/Utils";
import _ from "lodash";
import { AccessProfiles } from "../models/AccessProfiles";
import { toast } from "react-toastify";
import { AccessTypesContext } from "contexts/AccessTypes";

export const useAccessProfile = () => {
    const [notConfigutarionAccessTypeWarning, setNotConfigutarionAccessTypeWarning] = useState(null);
    const [tableResults, setTableResults] = useState([]);
    const { loadingRefresh, setLoadingRefresh } = useContext(AccessTypesContext);
    const { permissions } = useSelector((state) => state.ui);
    const { t } = useTranslation();

    const hasGuest = permissions?.product?.guest;

    const [executeQuery, { data, loading, called, error, refetch, networkStatus }] = useMSQuery(GET_ACCESS_PROFILES);

    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (!loading && !error && data?.wifiAccessProfiles && networkStatus === 7) {
            const dataItems = arrangeData(
                data?.wifiAccessProfiles,
                arrangeWifiAccessTypes(data.wifiAccessTypes, hasGuest),
                data?.wifiAccessCodes?.results
            );
            setTableResults(dataItems);
            setNotConfigutarionAccessTypeWarning(
                showNotConfigutarionAccessTypeWarning(data.allWifiAccessProfiles, data.wifiAccessTypes)
            );
        }
    }, [data, networkStatus]);

    const getAccessTypesByProfile = (wifiAccessProfiles) => {
        const accessTypesArray = [];
        if (wifiAccessProfiles.results.length > 0) {
            // eslint-disable-next-line
            wifiAccessProfiles?.results?.forEach((wifiAccessProfile) => {
                if (wifiAccessProfile?.accesstypes.length > 0) {
                    wifiAccessProfile.accesstypes.map((accesstype) => {
                        accessTypesArray.push(accesstype.accesstypes_name);
                    });
                }
            });
        }
        return accessTypesArray;
    };

    useEffect(() => {
        if (loadingRefresh) {
            toast.success(t("operation-successful"));
            refetch();
            setTimeout(() => {
                setLoadingRefresh(false);
            }, 1200);
        }
    }, [loadingRefresh]);

    const showNotConfigutarionAccessTypeWarning = (wifiAccessProfiles, wifiAccessTypes) => {
        let response = { show: false, accessTypes: [] };
        if (wifiAccessTypes && wifiAccessTypes.results && wifiAccessTypes.results.length > 0) {
            wifiAccessTypes.results
                .filter((wifiAccessType) => wifiAccessType.accesstypes_name !== "code")
                // eslint-disable-next-line
                .map((wifiAccessType) => {
                    let name = capitalizeFirst(translateWifiAccessType(wifiAccessType.accesstypes_name));
                    if (!response.accessTypes.includes(name)) {
                        let add = true;
                        if (wifiAccessProfiles && wifiAccessProfiles.results && wifiAccessProfiles.results.length > 0) {
                            // eslint-disable-next-line
                            wifiAccessProfiles.results.map((wifiAccessProfile) => {
                                if (
                                    wifiAccessProfile &&
                                    wifiAccessProfile.accesstypes &&
                                    wifiAccessProfile.accesstypes.length > 0
                                ) {
                                    if (
                                        wifiAccessProfile.accesstypes.filter(
                                            (accesstype) =>
                                                accesstype.accesstypes_name === wifiAccessType.accesstypes_name
                                        ).length > 0
                                    ) {
                                        add = false;
                                    }
                                }
                            });
                        }
                        if (add) {
                            response.accessTypes.push(name);
                        }
                    }
                });
        }
        if (response.accessTypes.length > 0) response.show = true;
        return response;
    };

    const arrangeWifiAccessTypes = (wifiAccessTypes, hasGuest) => {
        let wifiAccessTypesGrouped = [];
        if (wifiAccessTypes && wifiAccessTypes.results && wifiAccessTypes.results.length > 0) {
            wifiAccessTypes.results
                .filter((wifiAccessType) => wifiAccessType.accesstypes_name !== "code")
                // eslint-disable-next-line
                .map((wifiAccessType) => {
                    if (hasGuest || wifiAccessType.accesstypes_name !== "pms") {
                        let index = _.findIndex(
                            wifiAccessTypesGrouped,
                            (wifiAccessTypeGrouped) => wifiAccessTypeGrouped.value === wifiAccessType.accesstypes_name
                        );
                        if (index > -1) {
                            wifiAccessTypesGrouped[index].ids.push(wifiAccessType.id);
                        } else {
                            wifiAccessTypesGrouped.push({
                                value: wifiAccessType.accesstypes_name,
                                name: translateWifiAccessType(wifiAccessType.accesstypes_name),
                                ids: [wifiAccessType.id],
                            });
                        }
                    }
                });
        }

        const predefinedOrder = ["email", "pms", "code", "open", "mac"];
        wifiAccessTypesGrouped.sort((a, b) => {
            return predefinedOrder.indexOf(a.value) - predefinedOrder.indexOf(b.value);
        });

        return wifiAccessTypesGrouped;
    };

    const arrangeData = (wifiAccessProfiles, wifiAccessTypes, wifiAccessCodes) => {
        const checkboxStates = getAccessTypesByProfile(wifiAccessProfiles);
        const response = [];
        let passCodes = [];
        // eslint-disable-next-line
        wifiAccessProfiles.results.map((wifiAccessProfile) => {
            passCodes = wifiAccessCodes?.filter((passCode) => passCode.accessprofileID === wifiAccessProfile.id) || [];
            response.push(
                AccessProfiles(
                    wifiAccessProfile,
                    wifiAccessTypes,
                    t,
                    refetch,
                    setLoadingRefresh,
                    passCodes,
                    checkboxStates
                )
            );
        });
        return response;
    };

    const translateWifiAccessType = (name) => {
        let response = name;
        if (name && name !== "") {
            switch (name) {
                case "email":
                    response = t("email");
                    break;
                case "pms":
                    response = t("room-surname");
                    break;
                case "open":
                    response = t("open-access");
                    break;
                case "mac":
                    response = t("mac-exceptions");
                    break;
                default:
                    t(response);
                    break;
            }
        }
        return response;
    };

    return { tableResults, ready: called && loading, loading: loadingRefresh, notConfigutarionAccessTypeWarning };
};
