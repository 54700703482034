import React, { useContext, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { changeActionValues, changeGlobalAction } from "../../actions/globalActions";
//Utils
import { useTranslation } from "react-i18next";
import UseButton from "../useButton";
import BrowserMediaLibraryModal from "../../hooks/GraphqlCalls/MediaLibrary/modals/BrowserMediaLibraryModal";
import { setModalContent, openModal } from "../../actions/uiActions";
import LogosFolderRef from "../../hooks/GraphqlCalls/Appearances/LogosFolderRef";
import { setSectionContent } from "../../actions/sectionActions";
import { getLogoAssetURL, getLogosTypes, getVersionsLogosUsed } from "../../hooks/Utils/AppearancesUtils";
import UseSelectWithPreview from "../Inputs/useSelectWithPreview";
import LogoInfo from "../Section/Design/Appearances/Sidebar/LogoInfo";

import { GlobalContext } from "contexts/Global";

const UseModalLogo = ({ index }) => {
    //Store data
    const { t } = useTranslation();
    const { lang } = useContext(GlobalContext);
    const actionData = useSelector((state) => state.action);
    const { replace, upload } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { addLogo, allowNullLogo, modals, appearanceSelected, themeType } = useSelector(
        (state) => state.sectionContent
    );

    const { asset: assetLogo, version: logoType } = addLogo;
    const originalLogo =
        appearanceSelected?.logos && addLogo && appearanceSelected.logos.find((logo) => logo.id === addLogo.id);

    let valuesInput = useRef({ values: actionData.values });
    let validationsInput = useRef(actionData.validations);
    validationsInput.current = actionData.validations;
    useEffect(() => {
        valuesInput.current = { values: actionData.values };
        validationsInput.current = actionData.validations;
    }, [actionData]);

    useEffect(() => {
        const cancelButtons = document.querySelectorAll("#cancel-modal-button");
        const arrCancelButtons = [...cancelButtons];

        if (arrCancelButtons.length >= 2) {
            arrCancelButtons[0].remove();
        }
    }, []);

    // -- # for URL image available # --- //
    // const [disabledInsert, setDisabledInsert] = useState(true);
    // const [assetURL, setAssetURL] = useState(null);
    // const [fileSource, setFileSource] = useState("library");
    const _LOGOS_TYPE = getLogosTypes(t, null, themeType);

    const dispatch = useDispatch();

    const LOGOS_FOLDER_REF = LogosFolderRef();

    const selectLogoAsset = () => {
        dispatch(
            changeActionValues({
                "select-asset": true,
            })
        );
        if (replace) {
            dispatch(setSectionContent({ replaceLogo: true, uploadLogo: upload ? true : false }));
        } else {
            dispatch(setSectionContent({ replaceLogo: false }));
        }
    };

    const openModalLibrary = () => {
        dispatch(changeActionValues({ "browser-media-library-items-selected": null }));
        const modal = BrowserMediaLibraryModal(t, {
            title: "choose-an-image",
            text: "images-of-the-media-library",
            bodyAdjust: "w-10/12",
            textAdjust: "",
            executeSectionQuery: true,
            buttonSelectedAssetName: "choose-an-image",
            dataMediaLibrary: {
                selected_ref: null,
                select_multiple: false,
                initial_folder: LOGOS_FOLDER_REF,
                type: "asset",
                lang: lang,
                add_asset: {
                    active: true,
                    return_added: false,
                },
            },
        });

        dispatch(
            changeGlobalAction({
                actionName: "browser-media-library",
            })
        );
        dispatch(
            setModalContent({
                ...modal,
                buttons: [
                    {
                        name: "cancel",
                        style: "white",
                        actionCallback: () => {
                            dispatch(changeActionValues({ "browser-media-library-items-selected": null }));
                            dispatch(setSectionContent({ modals: { ...modals, openLast: true }, noValidations: true }));
                        },
                    },
                    {
                        name: "choose-an-image",
                        style: "blue",
                        actionCallback: () => selectLogoAsset(),
                    },
                ],
            })
        );
        dispatch(openModal());
    };

    // -- # for URL image available # --- //

    // const changeFileSource = (elem) => {
    //     validateURL(document.getElementById("url-link").value, elem.target.value);
    //     if (document.getElementById(elem.target.value).checked) {
    //         setFileSource(elem.target.value);
    //         if (elem.target.value === "library" && addLogo.asset_tmp && addLogo.asset_tmp_selected) {
    //             dispatch(setSectionContent({ addLogo: { ...addLogo, asset: addLogo.asset_tmp } }));
    //         } else if (elem.target.value === "link" && addLogo.asset_link && addLogo.asset_link_selected) {
    //             dispatch(setSectionContent({ addLogo: { ...addLogo, asset: addLogo.asset_link } }));
    //         } else {
    //             dispatch(setSectionContent({ addLogo: { ...addLogo, asset: null } }));
    //         }
    //     }
    // };

    // const validateURL = (_url, source) => {
    //     const _fileSource = source ? source : fileSource;
    //     if (_fileSource === "link") {
    //         const valid = _url.match(/^(https)\:\/\//);
    //         if (valid) {
    //             setDisabledInsert(false);
    //             setAssetURL(_url);
    //         } else {
    //             setDisabledInsert(true);
    //             setAssetURL(null);
    //         }
    //     } else {
    //         setDisabledInsert(true);
    //         setAssetURL(null);
    //     }
    // };

    return (
        <>
            {!replace && (
                <>
                    <div className="w-full text-left pb-2 mt-2">
                        {t("{{capitalize, capitalize}}", { capitalize: t("logo-version") })} *
                    </div>
                    <div className="w-5/12 text-left pb-4">
                        <UseSelectWithPreview
                            selectData={{
                                name: "logo-type",
                                options: getLogosTypes(
                                    t,
                                    getVersionsLogosUsed(appearanceSelected.logos, addLogo.type),
                                    themeType
                                ),
                                optionSelected: logoType ? _LOGOS_TYPE.find((type) => type.value === logoType) : null,
                                ph: "select-logo-type",
                                themeType: themeType,
                            }}
                        />
                    </div>
                </>
            )}
            <div className={`w-full ${replace ? "" : "grid gap-4 grid-cols-2"} mt-6`}>
                {!replace && (
                    <div className="col-span-2 text-left">
                        {t("{{capitalize, capitalize}}", { capitalize: t("logo") })} {allowNullLogo ? "" : ` *`}
                    </div>
                )}

                {assetLogo ? (
                    <div className="w-full rounded  h-52 mt-2">
                        <img alt={t("logo")} src={getLogoAssetURL(assetLogo)} className="mx-auto my-6 h-full" />
                        <div
                            className={`absolute ${replace ? "w-full pr-20" : "w-1/2 pr-12"} rounded-b-md h-10 z-201`}
                            style={{ marginTop: "-35px" }}
                        >
                            <div
                                className="grid grid-flow-col grid-cols-3  text-1xl font-black text-white  h-full items-center rounded-b-md"
                                style={{ backgroundColor: "rgba(46,56,67,0.5)" }}
                            >
                                <div className="ml-2"></div>
                                <div className="text-center items-center align-middle">
                                    <span
                                        className="icon-delete-editor block z-100 cursor-pointer"
                                        style={{ fontSize: "2rem", paddingTop: "0.1rem" }}
                                        id="modal-logo-delete-logo"
                                        onClick={() => {
                                            dispatch(
                                                setSectionContent({
                                                    addLogo: {
                                                        ...addLogo,

                                                        asset: null,
                                                    },
                                                })
                                            );
                                            dispatch(
                                                changeActionValues({ "browser-media-library-items-selected": null })
                                            );
                                        }}
                                    ></span>
                                </div>
                                <div className="mr-2 text-right items-end">
                                    {assetLogo && <LogoInfo imageRef={assetLogo} />}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex border border-dashed border-gray-600 rounded min-h-20">
                        <span className="text-gray-600 m-auto">
                            {t("{{capitalize, capitalize}}", { capitalize: t("unspecified") })}
                        </span>
                    </div>
                )}
                <div className="text-left grid grid-flow-row gap-4">
                    <div className="w-full flex-row">
                        {replace && !upload ? (
                            <div className="flex text-right place-items-end mt-4">
                                {originalLogo.imageRef === addLogo.asset ? (
                                    <span className="right-0 ml-auto text-gray-600 py-2">
                                        {t("{{capitalize, capitalize}}", { capitalize: t("original-logo-shown") })}
                                    </span>
                                ) : (
                                    <span
                                        className="cursor-pointer sidebar-submenu-link right-0 ml-auto"
                                        onClick={() => {
                                            dispatch(
                                                setSectionContent({
                                                    addLogo: {
                                                        ...addLogo,

                                                        asset: originalLogo.imageRef,
                                                    },
                                                })
                                            );
                                        }}
                                    >
                                        {t("{{capitalize, capitalize}}", { capitalize: t("reset-logo") })}
                                    </span>
                                )}
                            </div>
                        ) : upload ? null : (
                            <>
                                <div className="mb-2 mt-4">
                                    {assetLogo ? t("replace-image-logo") : t("add-image-logo")}
                                </div>
                                <div className="mb-2 mt-4 text-gray-600">{t("logo-recommended-size")}</div>
                            </>
                        )}
                        <div
                            className={`mt-4 mb-2 ${
                                Object.keys(validationsInput.current).length > 0 &&
                                Object.keys(validationsInput.current).includes(
                                    "browser-media-library-items-selected"
                                ) &&
                                !validationsInput.current["browser-media-library-items-selected"]
                                    ? "text-red-100"
                                    : ""
                            }`}
                        >
                            {t("select-file")}
                        </div>
                        <UseButton
                            buttonName={t("choose-an-image")}
                            buttonColor="btn-white"
                            id={"modal-logo-choose-an-image-button"}
                            adjust={replace ? "w-full" : "w-3/5"}
                            // disabled={fileSource !== "library"}
                            action={() => openModalLibrary()}
                        />
                    </div>
                    {/* -- COMENTED -- // -- # for URL image available # --- // */}
                    {/* <div className="">
                        <input
                            id="library"
                            type="radio"
                            name="file-source"
                            value="library"
                            defaultChecked={true}
                            onChange={(e) => {
                                changeFileSource(e);
                            }}
                        />
                        <label htmlFor="library" className="cursor-pointer ml-4">
                            {t("select-file")}
                        </label>
                    </div> 
                    <div className="">
                        <input
                            id="link"
                            type="radio"
                            name="file-source"
                            value="link"
                            className="cursor-pointer"
                            onChange={(e) => {
                                changeFileSource(e);
                            }}
                        />
                        <label htmlFor="link" className="cursor-pointer ml-4">
                            {t("link")} ({t("URL-https")})
                        </label>
                    </div>
                    <div className="flex">
                        <input
                            id="url-link"
                            type="text"
                            min="0"
                            required
                            placeholder={t("paste here")}
                            className={`w-3/5 h-10 bg-gray-200 rounded p-2 mr-8 ${
                                disabledInsert ? "text-gray-400" : "text-gray-900"
                            }`}
                            disabled={fileSource !== "link"}
                            value={addLogo.asset_link && addLogo.asset_link}
                            onChange={(e) => {
                                validateURL(e.target.value);
                            }}
                        ></input>
                        <UseButton
                            buttonName={t("insert")}
                            buttonColor="btn-blue"
                            disabled={disabledInsert}
                            action={() => {
                                dispatch(
                                    setSectionContent({
                                        addLogo: {
                                            ...addLogo,
                                            asset_link: assetURL,
                                            asset_link_selected: true,
                                            asset: assetURL,
                                        },
                                    })
                                );
                            }}
                        />
                    </div> */}
                </div>
            </div>
        </>
    );
};
export default UseModalLogo;
