import React from "react";
import { useSelector } from "react-redux";
//Utils
import { capitalizeFirst } from "../hooks/Utils/Utils";
import Parser from "hooks/Utils/Parser";

const UseSectionIntroduction = ({ text, adjust, forModal, icon = null }) => {
    //Store data
    const { title } = useSelector((state) => state.table);
    const { langStrings, modalContent } = useSelector((state) => state.ui);
    //Function
    const getTextFromArray = (text) => {
        const arrayRes = text.map((term) => `${langStrings[term] ? langStrings[term] : term} `);
        let ret = "";
        arrayRes.forEach((element) => {
            ret = ret + element;
        });
        return ret;
    };
    return (
        <div className="flex mt-4">
            {langStrings ? (
                <h5 className={`section-introduction first-capital ${adjust}`}>
                    {icon && <i className={`icon-${icon} mr-5`}></i>}
                    {forModal && modalContent.value && ` ${modalContent.value} - `}
                    <span className="first-capital">
                        {Array.isArray(text)
                            ? Parser(String(capitalizeFirst(getTextFromArray(text))))
                            : Parser(String(capitalizeFirst(langStrings[text])))
                            ? Parser(String(capitalizeFirst(langStrings[text])))
                            : Parser(String(capitalizeFirst(text)))}
                        {title && !forModal ? ` ${Parser(String(capitalizeFirst(title)))}` : null}
                    </span>
                </h5>
            ) : null}
        </div>
    );
};

export default UseSectionIntroduction;
