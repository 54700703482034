import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMSQuery } from "../../GraphqlCalls/useMSQuery";
import { gql } from "apollo-boost";
import { setConnectionError, setError } from "../../../actions/tableActions";
import { POLLINTERVAL_15S } from "../Utils";

const NETWORK_ERROR = "Network error";

function CheckConnectionError() {
    const { error, connectionError } = useSelector((state) => state.table);
    const dispatch = useDispatch();

    const CHECK_CONNECTION_QUERY = gql`
        {
            users {
                results {
                    id
                }
            }
        }
    `;

    const [executeQuery, dataQ] = useMSQuery(CHECK_CONNECTION_QUERY, {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_15S,
    });

    useEffect(() => {
        if (error && connectionError) {
            executeQuery();
        }
        // eslint-disable-next-line
    }, [connectionError]);

    useEffect(() => {
        if (dataQ.error && dataQ.error.message && dataQ.error.message.includes(NETWORK_ERROR)) {
            dispatch(setConnectionError(true));
        } else if (dataQ.data && !dataQ.error) {
            dispatch(setConnectionError(false));
            dispatch(setError(false));
        }
        // eslint-disable-next-line
    }, [dataQ]);
}

export default CheckConnectionError;
