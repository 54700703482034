export const GetUpdatedPosition = (itemsAffected, results) => {
    let res;
    const itemId = Number(itemsAffected);
    // eslint-disable-next-line
    results.map((result, index) => {
        if (result.id === itemId) {
            res = index;
        }
    });
    return res;
};
