import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import pkg from "../../../package.json";

for (let i = 0; i < pkg.languages.length; i++) {
    require("dayjs/locale/" + pkg.languages[i] + ".js");
}
dayjs.extend(localizedFormat);

export default dayjs;
