import { minutesToHours } from "../../../../Utils/Utils";

export const getBookingFreqSelected = (current) => {
    switch (current) {
        case 15:
            return "each-15-min";
        case 30:
            return "each-30-min";
        case 60:
            return "each-hour";
        default:
            return "each-custom";
    }
};

export const bookingAllowedModal = (area) => {
    const { name, bookingAllowed, id, bookingFreq, slotsPerBooking, visibleDuration, autoBooking, capacityLimit } =
        area;
    return [
        {
            id: id,
            value: name,
            title: "booking-admission",
            bodyAdjust: "pb-8",
            buttonsStyle: "float-right",
            inputs: [
                {
                    name: "allow",
                    type: "radio",
                    textPrefix: "select-if",
                    text: name,
                    textSufix: "booking-admit",
                    translatable: ["text-prefix", "text-sufix"],
                    values: ["booking-allowed", "booking-notallowed"],
                    selected: bookingAllowed ? "booking-allowed" : "booking-notallowed",
                    ph: "booking-freq",
                },
                {
                    name: "confirmation-mode",
                    type: "radio",
                    text: "confirmation-mode",
                    translatable: ["text"],
                    values: ["manual", "automatic"],
                    selected: autoBooking ? "automatic" : "manual",
                },
                {
                    name: "booking-freq",
                    type: "radio",
                    text: "select-booking-freq",
                    translatable: ["text"],
                    values: ["each-15-min", "each-30-min", "each-hour", "each-custom"],
                    selected: getBookingFreqSelected(bookingFreq),
                    currentValue: bookingFreq,
                },
                {
                    name: "booking-duration",
                    type: "select",
                    text: "select-booking-duration",
                    selected: minutesToHours(bookingFreq * slotsPerBooking),
                    cStyle: "w-6/12",
                    cHeight: "h-28",
                    style: "float-left h-28",
                },
                {
                    name: "booking-duration-visible",
                    type: "radio",
                    values: ["visible-to-customer", "hidden"],
                    selected: visibleDuration ? "visible-to-customer" : "hidden",
                    currentValue: visibleDuration ? "visible-to-customer" : "hidden",
                    style: "inline-flex mt-10",
                    optionStyle: "pr-5",
                },
                {
                    name: "people-number",
                    type: "number",
                    ph: "people-number",
                    currentValue: capacityLimit ? capacityLimit : null,
                    text: "enter-capacity-limit",
                    style: "flex w-full",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "save",
                    style: "blue",
                    action: "area-allow-booking-save",
                },
            ],
        },
    ];
};

export default bookingAllowedModal;
