import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import { useEffect, useState } from "react";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { PRESET_CHANNEL, CUSTOMISED_CHANNEL } from "../../Utils/Services/ChannelsUtils";
import { toast } from "react-toastify";

import { gql } from "apollo-boost";
import { capitalizeFirst, removeApolloCacheKeys } from "../../Utils/Utils";
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { cleanAction } from "../../../actions/globalActions";
import { withApollo } from "@apollo/react-hoc";
import { useParams } from "react-router-dom";
import NewChannelModalGroup from "./modals/NewChannelModalGroup";

const UpdateListGroupEditChannels = ({ client }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { groupId } = useParams();
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { values } = useSelector((state) => state.action);
    const { checkedItems } = useSelector((state) => state.table);
    //State
    const [mutation, setMutation] = useState("mutation{checkIn}");
    const [closeModalVal, setCloseModalVal] = useState(true);

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        errorPolicy: "all",
        onError(err) {
            //this control is in this onError, because errorPolicy =all is not working like in queryes
            //Custom errors
            // let validations = {};
            let errorMsg = err.message;

            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(errorMsg);
        },
    });

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            executeQuery.mutationName = "";
            switch (executeQuery.action) {
                case "move-channel-position-in-group":
                    execute = true;
                    setMutation(`mutation {
                        setTuneOrderInGroup(
                            tuneGroupID: ${executeQuery.params.tuneGroupID}, 
                            tuneID: ${executeQuery.params.id}, 
                            pos: ${executeQuery.params.newIndex + 1}) {
                          id
                          error
                          ok
                        }
                      }`);
                    executeQuery.mutationName = "setTuneOrderInGroup";
                    setCloseModalVal(true);
                    break;
                case "edit-move-channel-group":
                    execute = true;
                    setMutation(`mutation {
                        setTuneOrderInGroup(
                            tuneID: ${values["edit-move-channel-group"].id}, 
                            tuneGroupID: ${values["edit-move-channel-group"].idGroup}, 
                            pos: ${values["edit-move-channel-group-position"]}) {
                          id
                          error
                          ok
                        }
                      }`);
                    executeQuery.mutationName = "setTuneOrderInGroup";
                    setCloseModalVal(true);
                    break;
                case "delete-channel-group":
                    execute = true;
                    setMutation(`mutation {
                        deleteTuneFromGroup(
                            tuneIDs: [${values["delete-channel-group"].id}], 
                            tuneGroupID: ${values["delete-channel-group"].idGroup}) {
                          id
                          error
                          ok
                        }
                      }
                    `);
                    executeQuery.mutationName = "deleteTuneFromGroup";
                    setCloseModalVal(true);
                    break;
                case "add-channel-to-group-channel":
                    execute = true;
                    setMutation(`mutation {
                        addTunesToGroup(tuneIDs: [${values["channels-to-add-group"].join(
                            ", "
                        )}], tuneGroupID: ${groupId}) {
                          id
                          error
                          ok
                        }
                      }
                      `);
                    executeQuery.mutationName = "addTunesToGroup";
                    setCloseModalVal(true);
                    break;
                case "delete-groups-channels":
                    execute = true;
                    setMutation(`
                      mutation {
                        deleteTuneFromGroup(tuneGroupID: ${groupId}, tuneIDs: [${checkedItems.join(",")}]) {
                          error
                          id
                          ok
                        }
                      }
                      `);
                    executeQuery.mutationName = "deleteTuneFromGroup";
                    setCloseModalVal(true);
                    break;
                case "add-channel":
                    switch (values["add-channel-class"]) {
                        case PRESET_CHANNEL.value:
                            execute = true;
                            setMutation(`
                    		mutation {
                    			createPredefinedTunesBatch(
                                    predefinedTunes:{
                    				    tvChannelID:${values["add-channel-preset"][0]}
                    				    ${
                                            values["add-channel-preset-tuneinfo"]
                                                ? `tuneInfo: ${JSON.stringify(values["add-channel-preset-tuneinfo"])}`
                                                : ""
                                        }
                    			    }
                                ){
                    				error
                    				ok
                                    id
                    				__typename
                    			}
                    		}
                    		`);
                            executeQuery.mutationName = "createPredefinedTunesBatch";
                            setCloseModalVal(false);
                            break;
                        case CUSTOMISED_CHANNEL.value:
                            execute = true;
                            setMutation(`
                    		mutation {
                    			createCustomTunesBatch(customTunes:[{
                    				name:"${values["add-channel-name"]}",
                    				languageRef:"${values["add-channel-language"]}",
                    				countryRef:"${values["add-channel-country"]}",
                    				isRadio: false
                    			}]){
                    				error
                    				ok
                                    id
                    				__typename
                    			}
                    		}
                    		`);
                            executeQuery.mutationName = "createCustomTunesBatch";
                            setCloseModalVal(false);
                            break;
                        default:
                            execute = false;
                            break;
                    }
                    break;
                case "edit-name-channel":
                    execute = true;
                    setMutation(`
                            mutation{
                                updateText(
                                    id: ${values["TID"]}
                                    i18n: ${values["mutation-langs-string"]}
                                    parentType: ${values["parentType"]}
                                ){
                                    error
                                    ok
                                    id
                                }
                            }
                        `);
                    setCloseModalVal(true);
                    executeQuery.mutationName = "updateText";
                    break;
                default:
                    break;
            }
        }
        if (execute) {
            if (!showGlobalLoading) {
                dispatch(setLoading(true));
            }
            if (!executeQuery.hideLoading) {
                dispatch(showGlobalLoading(true));
            }

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].isOk || data[executeQuery.action].ok)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].isOk || data[executeQuery.mutationName].ok)))
        ) {
            if (closeModalVal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            } else if (
                (!closeModalVal && executeQuery.mutationName === "createCustomTunesBatch") ||
                executeQuery.mutationName === "createPredefinedTunesBatch"
            ) {
                dispatch(setModalContent(NewChannelModalGroup(t)));
            }
            if (!executeQuery.params || (executeQuery.params && !executeQuery.params.noCleanAction)) {
                dispatch(cleanAction());
            }

            if (!executeQuery.preventToast) {
                toast.success(capitalizeFirst(t(executeQuery.customToast || "operation-successful")));
            }
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));
            removeApolloCacheKeys(client.cache, "all");
        } else if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].errorMsg || data[executeQuery.action].error)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].errorMsg || data[executeQuery.mutationName].error)))
        ) {
            const errorMsg =
                data[executeQuery.action] && data[executeQuery.action].error
                    ? data[executeQuery.action].error.msg
                        ? data[executeQuery.action].error.msg
                        : data[executeQuery.action].error
                    : data[executeQuery.mutationName] && data[executeQuery.mutationName].error
                    ? data[executeQuery.mutationName].error
                    : null;

            toast.error(errorMsg ? errorMsg : t("mutation-error"));
            dispatch(showGlobalLoading(false));
            dispatch(setExecuteQuery(null));
            if (errorMsg !== "Code already in use!") {
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
            }
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateListGroupEditChannels);
