export const AuthLogin = [
    "access",
    "choose",
    "check",
    "close-session",
    "email",
    "not-valido",
    "not-valids",
    "onea",
    "option",
    "or",
    "password",
    "pass-reminder",
    "pass-reminder-text",
    "please",
    "send-email",
    "to",
    "user",
    "welcome",
    "zafiro",
    "cancel",
];
