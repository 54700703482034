import {
    SET_CHECKABLE,
    SET_HEADERS,
    SET_TABLE_RESULTS,
    SET_AVAILABLE_FILTERS,
    SET_LOADING,
    SET_LOADING_MORE_RESULTS,
    SET_ERROR,
    SET_COUNT,
    SET_CURRENT_PAGE,
    SET_ACTIVE_FILTERS,
    RESTORE_ACTIVE_FILTERS,
    CLEAN_ACTIVE_FILTERS,
    GET_FILTER_QUERY,
    SET_ACTIONS,
    SET_EXTENDED_INFO,
    SET_COLUMNS,
    SET_ALL_CHECKED,
    SET_BATCH_ACTIVE,
    CLEAN_TABLE_STORE,
    SET_SEARCHABLE,
    SET_TITLE,
    SET_COUNT_POSITION,
    SET_HEADER_BUTTONS,
    SET_ROW_GROUPS,
    SET_DATE_HEADER,
    SET_DATE_FILTER,
    SET_ROW_FORM,
    SET_REFRESH_DATA,
    SET_RELOAD,
    SET_SORT_CRITERIA,
    CLEAN_SORT_CRITERIA,
    SET_ACTIVE_PARAMS,
    GET_PARAMS_QUERY,
    SET_CHECKDD_VALUES,
    SET_CLEANING_STAFF,
    SET_CUSTOM_ERROR,
    SET_CUSTOM_CONFIG,
    UPDATE_RESULT_ITEM,
    SET_REMOVE_LOADING_TIMEOUT,
    SET_EXPORT,
    ADD_TOASTINFO_ITEM,
    REMOVE_TOASTINFO_ITEM,
    ADD_CHECKED_ITEM,
    REMOVE_CHECKED_ITEM,
    CLEAN_CHECKED_ITEMS,
    SET_ROW_IN_EDITION,
    SET_ITEMS_PERPAGE,
    SET_TABLE_TYPE,
    SET_SERVER_ERROR,
    SET_CUSTOM_SERVER_ERROR,
    SET_TARGET_ID,
    SET_DISABLED,
    SET_FOLDER,
    SET_CONNECTION_ERROR,
    SET_ID_SUBROW,
    RESET_IDS_SUBROWS,
    CHROMECAST_ERROR,
    SET_INFORMATIVE_MESSAGE,
} from "../actions/tableActions";

const initialState = {
    actions: false,
    type: null,
    searchString: null,
    activeFilters: {},
    activeParams: {},
    allChecked: false,
    availableFilters: false,
    batchActive: false,
    checkable: false,
    columns: false,
    count: false,
    countPosition: false,
    currentPage: 1,
    rowInEdition: null,
    dateFilter: false,
    dateHeader: false,
    error: false,
    extendedInfo: false,
    filterQuery: "",
    headers: false,
    headerButtons: false,
    loading: true,
    loadingMoreResults: false,
    removeTableLoadingTimeout: null,
    paramsQuery: "",
    perPage: 6,
    results: false,
    searchable: false,
    showRowGroups: false,
    sortCriteria: "",
    title: false,
    updateData: false,
    refreshData: false,
    reload: false,
    exportData: false,
    toastInfoItems: [],
    checkedItems: [],
    serverError: false,
    disabled: false,
    folder: null,
    connectionError: false,
    idsSubrows: [],
    field: "",
    criteria: "",
    informativeMessage: "",
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CHECKABLE:
            return {
                ...state,
                checkable: action.payload,
            };
        case SET_DISABLED:
            return {
                ...state,
                disabled: action.payload,
            };

        case SET_HEADERS:
            return {
                ...state,
                headers: action.payload,
            };

        case SET_TABLE_TYPE:
            return {
                ...state,
                type: action.payload,
            };

        case SET_TABLE_RESULTS:
            return {
                ...state,
                results: action.payload,
            };

        case SET_AVAILABLE_FILTERS:
            return {
                ...state,
                availableFilters: action.payload,
            };

        case SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        case SET_LOADING_MORE_RESULTS:
            return {
                ...state,
                loadingMoreResults: action.payload,
            };

        case SET_REMOVE_LOADING_TIMEOUT:
            return {
                ...state,
                removeTableLoadingTimeout: action.payload,
            };

        case SET_ERROR:
            return {
                ...state,
                ...(action.payload || {}),
            };

        case SET_CONNECTION_ERROR:
            return {
                ...state,
                connectionError: action.payload,
            };

        case SET_COUNT:
            return {
                ...state,
                count: action.payload,
            };

        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload,
            };
        case SET_ROW_IN_EDITION:
            return {
                ...state,
                rowInEdition: action.payload,
            };

        case SET_ACTIVE_FILTERS:
            let currentActive = { ...state.activeFilters };
            const name = action.payload[0];
            const value = action.payload[1];
            const search = name === "search" ? value : state.search;
            if (value !== undefined && value !== "") {
                //Add, modify...
                currentActive[name] = value;
            } else {
                //Delete...
                delete currentActive[name];
            }
            return {
                ...state,
                searchString: search,
                activeFilters: currentActive,
            };
        case RESTORE_ACTIVE_FILTERS:
            return {
                ...state,
                activeFilters: action.payload,
            };
        case CLEAN_ACTIVE_FILTERS:
            return {
                ...state,
                activeFilters: {},
            };
        case SET_ACTIVE_PARAMS:
            let currentParamsActive = state.activeParams;
            if (action.payload[1] !== "") {
                //Add, modify...
                currentParamsActive[action.payload[0]] = action.payload[1];
            } else {
                //Delete...
                delete currentParamsActive[action.payload[0]];
            }
            return {
                ...state,
                activeParams: currentParamsActive,
            };

        case GET_FILTER_QUERY:
            return {
                ...state,
                filterQuery: action.payload,
            };

        case GET_PARAMS_QUERY:
            return {
                ...state,
                paramsQuery: action.payload,
            };

        case SET_ACTIONS:
            return {
                ...state,
                actions: true,
            };

        case SET_EXTENDED_INFO:
            return {
                ...state,
                extendedInfo: action.payload,
            };

        case SET_COLUMNS:
            return {
                ...state,
                columns: `w-1/${action.payload}`,
            };

        case SET_ALL_CHECKED:
            return {
                ...state,
                allChecked: action.payload,
            };

        case SET_BATCH_ACTIVE:
            return {
                ...state,
                batchActive: action.payload,
            };

        case CLEAN_TABLE_STORE:
            return initialState;

        case SET_SEARCHABLE:
            return {
                ...state,
                searchable: true,
            };

        case SET_TITLE:
            return {
                ...state,
                title: action.payload,
            };

        case SET_COUNT_POSITION:
            return {
                ...state,
                countPosition: action.payload,
            };

        case SET_HEADER_BUTTONS:
            return {
                ...state,
                headerButtons: action.payload,
            };
        case SET_ROW_GROUPS:
            return {
                ...state,
                showRowGroups: action.payload,
            };
        case SET_DATE_HEADER:
            return {
                ...state,
                dateHeader: true,
            };
        case SET_DATE_FILTER:
            if (action.payload instanceof Date) {
                action.payload = new window.ZDate(action.payload.toISOString());
            }
            return {
                ...state,
                dateFilter: action.payload,
            };
        case SET_ROW_FORM:
            return {
                ...state,
                rowFormContent: action.payload,
            };
        case SET_REFRESH_DATA:
            return {
                ...state,
                refreshData: action.payload,
            };
        case SET_RELOAD:
            return {
                ...state,
                reload: action.payload,
            };
        case SET_SORT_CRITERIA:
            return {
                ...state,
                criteria: action.payload.criteria,
                field: action.payload.field,
                loading: state.sortCriteria !== action.payload,
                sortCriteria: action.payload.sortString,
            };
        case CLEAN_SORT_CRITERIA:
            return {
                ...state,
                criteria: "",
                field: "",
                loading: state.sortCriteria !== action.payload,
                sortCriteria: "",
            };
        case SET_ID_SUBROW:
            if (!state.idsSubrows.includes(Number(action.payload))) {
                return {
                    ...state,
                    idsSubrows: [...state.idsSubrows, Number(action.payload)],
                };
            } else {
                let indexSubrow = state.idsSubrows.indexOf(Number(action.payload));
                if (indexSubrow !== -1) {
                    state.idsSubrows.splice(indexSubrow, 1);
                }
                return {
                    ...state,
                    idsSubrows: state.idsSubrows,
                };
            }
        case RESET_IDS_SUBROWS:
            return {
                ...state,
                idsSubrows: [],
            };

        case SET_CHECKDD_VALUES:
            if (!action.payload) {
                return {
                    ...state,
                    checkboxDropdownFilter: {},
                };
            }
            return {
                ...state,
                checkboxDropdownFilter: {
                    ...state.checkboxDropdownFilter,
                    ...action.payload,
                },
            };
        case SET_INFORMATIVE_MESSAGE:
            return {
                ...state,
                informativeMessage: action.payload,
            };
        case SET_CLEANING_STAFF:
            return {
                ...state,
                cleaningStaff: action.payload,
            };
        case SET_CUSTOM_ERROR:
            return {
                ...state,
                customError: action.payload,
            };
        case SET_CUSTOM_CONFIG:
            return {
                ...state,
                customConfig: action.payload,
            };
        case UPDATE_RESULT_ITEM:
            let newResults = state.results;
            newResults[action.payload.index] = action.payload.data;
            return {
                ...state,
                results: newResults,
            };
        case SET_EXPORT:
            return {
                ...state,
                exportData: action.payload,
            };
        case SET_FOLDER:
            return {
                ...state,
                folder: action.payload,
            };
        case ADD_CHECKED_ITEM:
            let newCheckedItems = state.checkedItems;
            newCheckedItems.push(action.payload);
            return {
                ...state,
                checkedItems: [...newCheckedItems],
            };
        case REMOVE_CHECKED_ITEM:
            let currentItemsChecked = state.checkedItems.filter((item) => item !== action.payload);
            return { ...state, checkedItems: currentItemsChecked };
        case CLEAN_CHECKED_ITEMS:
            return { ...state, checkedItems: [], activeFilters: { ...state.activeFilters } };
        case ADD_TOASTINFO_ITEM:
            let newToastItems = state.toastInfoItems;
            newToastItems.push(action.payload);
            return {
                ...state,
                toastInfoItems: newToastItems,
            };

        case REMOVE_TOASTINFO_ITEM:
            let currentItems = state.toastInfoItems.filter((item) => item !== action.payload);
            return { ...state, toastInfoItems: currentItems };
        case SET_ITEMS_PERPAGE:
            return { ...state, perPage: parseInt(action.payload) };
        case SET_SERVER_ERROR:
            return { ...state, serverError: action.payload };
        case SET_CUSTOM_SERVER_ERROR:
            return { ...state, customServerError: action.payload };
        case SET_TARGET_ID:
            return { ...state, targetId: action.payload };
        default:
            return state;
    }
}
