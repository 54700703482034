import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
//Notifications
import { toast } from "react-toastify";
//Components
import UseIcon from "../useIcon";
import DeleteScheduleModal from "../../hooks/GraphqlCalls/CommonZones/modals/schedules/DeleteScheduleModal";
//Actions
import { cleanAction, setActionName, setActionItemAffected, setActionInput } from "../../actions/globalActions";
import { openModal, setModalContent } from "../../actions/uiActions";
import { showEditPunctualSchedule, setPunctualViewStatus } from "../../actions/sectionActions";
import { setScheduleToUpdate } from "../../actions/scheduleActions";
//Utils
import getAvailableActions from "../../hooks/Utils/DataCalc/GetAvailableActions";
import { GetScheduleMutationDates } from "../../hooks/Utils/GetScheduleMutationDates";
import useComponentVisible from "../useComponentVisible";
import { useTranslation } from "react-i18next";

const UseSchedulePunctualOptions = ({ currentEvent }) => {
    const { t } = useTranslation();
    //Store data
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //Data
    const { ID } = currentEvent;
    const { isEnabled } = currentEvent;
    const [activeAction, setActiveAction] = useState();
    const availableActions = getAvailableActions("schedule", isEnabled);

    const icon = {
        name: "row-actions",
    };
    //States
    const [mutation, setMutation] = useState(getMutation(currentEvent));

    //API
    const UPDATE_PUNCTUAL_SCHEDULE = gql`
        ${mutation}
    `;
    const [executeMutation, { data }] = useMutation(UPDATE_PUNCTUAL_SCHEDULE);

    //Actions
    const dispatch = useDispatch();

    const showModal = () => {
        dispatch(setActionName("delete-schedule"));
        dispatch(setActionItemAffected([ID.toString()]));
        dispatch(setModalContent(DeleteScheduleModal(ID)));
        //Launch modal
        dispatch(openModal());
    };

    const directExecute = (e) => {
        const { action } = e.currentTarget.dataset;
        //Set mutation
        setMutation(getMutation(currentEvent));
        setActiveAction(action);
        executeMutation();
    };

    const showEditRowForm = () => {
        dispatch(setScheduleToUpdate(currentEvent));
        dispatch(showEditPunctualSchedule(true));
        dispatch(setActionName("edit-punctual"));
        dispatch(setActionItemAffected([ID.toString()]));
        dispatch(setActionInput(currentEvent.values));
    };

    //Listeners
    useEffect(() => {
        if (checkUpdateError(data)) {
            toast.error(t("error-mutation"));
        } else if (checkUpdateSuccess(data)) {
            if (activeAction === "enable") {
                dispatch(
                    setPunctualViewStatus({
                        id: currentEvent.ID,
                        newStatus: true,
                    })
                );
                toast.success(t("schedule activated"));
            } else if (activeAction === "disable") {
                dispatch(
                    setPunctualViewStatus({
                        id: currentEvent.ID,
                        newStatus: false,
                    })
                );
                toast.success(t("schedule disabled"));
            }
            dispatch(cleanAction());
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <div ref={ref} className="t-actions-icon relative overflow-visible">
            <div onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}>
                <UseIcon icon={icon} adjust="text-lg cursor-pointer z-100" />
            </div>
            {isComponentVisible ? (
                <div
                    id="actions-container"
                    className="t-actions-container"
                    onClick={() => setIsComponentVisible(false)}
                >
                    <div className="t-actions-background">
                        <div id="action">
                            {availableActions.length > 0 ? (
                                availableActions.map((action, index) => (
                                    <div
                                        key={index}
                                        className="t-action-container first-capital"
                                        onClick={
                                            !action.noModal
                                                ? showModal
                                                : action.directAction
                                                ? directExecute
                                                : showEditRowForm
                                        }
                                        data-action={action.name}
                                    >
                                        {t(action.name)}
                                    </div>
                                ))
                            ) : (
                                <span className="t-noaction-container first-capital">{t("no-actions")}</span>
                            )}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

//Mutation functions
const getMutation = (currentEvent) => {
    //Returns mutation
    const { ID, name, type, dateType } = currentEvent;
    let { timetableDates } = currentEvent;

    cleanValidationValues(timetableDates);

    if (timetableDates.length === 0) {
        timetableDates = null;
    } else {
        // eslint-disable-next-line
        timetableDates.map((timetableDate) => {
            if (timetableDate.dateList === "") {
                timetableDate.dateList = null;
            }
            // eslint-disable-next-line
            if (timetableDate.timetableDateRanges.length === 0) {
                timetableDate.timetableDateRanges = null;
            }
        });
    }
    const formattedDates = GetScheduleMutationDates(timetableDates);

    let res = `
      mutation {
        updateTimetable(
          id: ${ID} `;

    if (currentEvent.isEnabled) {
        res += `isEnabled: false `;
    } else {
        res += `isEnabled: true `;
    }
    //Include all current data required
    res += `name: "${name}" type: "${type}" dateType: "${dateType}" timetableDates: ${formattedDates}`;
    res += `){ error id ok}}`;

    return res;
};

const cleanValidationValues = (timetableDates) => {
    timetableDates.map((timetableDate) => {
        delete timetableDate.timetableDateRanges[0].passValidation;
        delete timetableDate.timetableDateRanges[0].MSValidation;
        delete timetableDate.timetableDateRanges[0].MEValidation;
        delete timetableDate.timetableDateRanges[0].ASValidation;
        delete timetableDate.timetableDateRanges[0].AEValidation;
        delete timetableDate.timetableDateRanges[0].WDValidation;
        return null;
    });
    return timetableDates;
};

const checkUpdateError = (data) => {
    //Return true if mutation response is error
    let res = false;
    if (data && data.updateTimetable && data.updateTimetable.error !== "") {
        res = true;
    }
    return res;
};

const checkUpdateSuccess = (data) => {
    //Return true if mutation response is ok
    let res = false;
    if (data && data.updateTimetable && data.updateTimetable.ok) {
        res = true;
    }
    return res;
};

export default UseSchedulePunctualOptions;
