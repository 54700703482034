import { gql } from "apollo-boost";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSectionContent } from "../../../actions/sectionActions";
import { useMSQuery } from "../useMSQuery";

const GetTVChannels = () => {
    const { permissions } = useSelector((state) => state.ui);
    const hasChannels = permissions?.product?.channels;

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_CHANNELS = !hasChannels
        ? gql`
              {
                  oldTVChannels {
                      channels
                  }
              }
          `
        : gql`
              {
                  tunes {
                      results {
                          id
                          name
                      }
                  }
              }
          `;

    const [executeQuery, { data, loading, error, networkStatus }] = useMSQuery(GET_CHANNELS, {
        // fetchPolicy: "network-only",
    });

    //Listeners
    useEffect(() => {
        executeQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!loading && !error && data && (data.oldTVChannels || data.tunes) && networkStatus === 7) {
            let channels = data.oldTVChannels?.channels ? JSON.parse(data.oldTVChannels.channels) : null;
            let channelItems =
                channels && channels.categories && channels.categories[0] ? channels.categories[0].items : [];

            if (data?.tunes?.results) {
                channels = data?.tunes?.results;
                channels.forEach((channel) => {
                    channelItems.push({ id: channel.id, name: channel.name });
                });
            }

            dispatch(
                setSectionContent({
                    channels: channelItems,
                })
            );
        }
        // eslint-disable-next-line
    }, [data, networkStatus]);

    useEffect(() => {
        if (error) {
            console.error(error);
        }
        // eslint-disable-next-line
    }, [error]);
};

export default GetTVChannels;
