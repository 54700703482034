import React, { useEffect, useState } from "react";
import UseSectionHeader from "../../../../useSectionHeader";
import ImageChannelSelector from "./ImageChannelSelector";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UseInputText from "../../../../Table/useInputText";
import CorporateChannelsVideos from "./CorporateChannelsVideos";
import { POLLINTERVAL_15S } from "../../../../../hooks/Utils/Utils";
import UpdateCorporateChannel from "../../../../../hooks/GraphqlCalls/Services/UpdateCorporateChannel";
import { setExecuteQuery, setRefreshContentData } from "../../../../../actions/sectionActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import UseLoading from "../../../../Table/useLoading";

const CorporateChannelEdition = () => {
    const { id } = useParams();
    const [dataCorpChannel, setCorpDataChannel] = useState(null);
    const [loading, setLoading] = useState(true);
    const [validations, setValidations] = useState({});
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { refreshData } = useSelector((state) => state.sectionContent);

    const GET_INFO_CORP_CHANNEL = gql`
        query {
            corporateTvChannelsSummary(filter: { id: ${id} }) {
                results {
                    id
                    logoRef
                    name
                    usedInProjectsNum
                    videosNum
                }
            }
        }
    `;

    const [getInfoCorpChannel, { data, refetch }] = useLazyQuery(GET_INFO_CORP_CHANNEL, {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_15S,
    });

    useEffect(() => {
        setLoading(true);
        getInfoCorpChannel();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData && refetch) {
            refetch();
            dispatch(setRefreshContentData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        const results = data?.corporateTvChannelsSummary?.results;
        if (results) {
            const firstResult = results[0];
            setCorpDataChannel(firstResult);
            setLoading(false);
        }
        //eslint-disable-next-line
    }, [data]);

    return (
        <div>
            <UseSectionHeader
                navToPreviousPage={true}
                title={dataCorpChannel?.name ? dataCorpChannel.name : ""}
                customBreadCrumbs={breadcrumbs}
                noCapitalize={true}
                noTranslate={true}
            ></UseSectionHeader>
            <div className=" relative rounded w-full">
                <div className=" flex">
                    <div
                        className=" px-8 py-6 w-1/6 bg-white rounded shadow-sm "
                        style={{ minWidth: "18.8rem", maxWidth: "18.8rem" }}
                    >
                        <div className=" mb-2 font-bold" id="channel-information-corporate-channel-edition">
                            {t("channel-information")}
                        </div>
                        <ImageChannelSelector
                            action="add-image-to-corporate-channel"
                            dataComponent={dataCorpChannel}
                            setDataComponent={setCorpDataChannel}
                            widthImage="100%"
                            heightImage="9.6rem"
                            headerLogoAdjust=" text-base"
                            textButton="choose-an-image"
                            dispatchActionDeleteImage="delete-image-corporate-channel"
                        />
                        <div className=" mt-6 border-t border-gray-200 flex pt-6">
                            <div className=" flex flex-col mr-3  ">
                                <span className=" font-bold flex items-center h-10">{t("name")}</span>
                                <span className=" mt-3 flex items-center font-bold h-10 pr-2">{t("type")}</span>
                            </div>
                            <div className=" flex w-full flex-col">
                                <div className=" h-10">
                                    <UseInputText
                                        onBlurAction={(val) => {
                                            if (val && val !== dataCorpChannel?.name) {
                                                setValidations({
                                                    ...validations,
                                                    "input-text-name-channel": false,
                                                });
                                                dispatch(
                                                    setExecuteQuery({
                                                        action: "update-name-corp-channel",
                                                        params: { nameVal: val },
                                                    })
                                                );
                                            } else if (!val) {
                                                setValidations({ ...validations, "input-text-name-channel": true });
                                                toast.error(t("input error"));
                                            }
                                        }}
                                        ph={t("name")}
                                        id={"corporate-channel-name"}
                                        validation={data && validations["input-text-name-channel"] ? false : true}
                                        currentValue={dataCorpChannel?.name ? dataCorpChannel.name : ""}
                                        inputData={{ name: "name-corporate-channel" }}
                                        adjust="h-full"
                                    />
                                </div>
                                <span id="corporate-channel-type" className=" flex items-center h-10 mt-3 ">
                                    {t("corporate-channel")}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className=" w-full pl-8  ">
                        <CorporateChannelsVideos
                            id={id}
                            nameChannel={dataCorpChannel?.name ? dataCorpChannel.name : ""}
                        />
                    </div>
                </div>
                {loading ? (
                    <div className=" z-100 absolute top-0 left-0 bg-white rounded shadow-sm h-full w-full flex items-center justify-center">
                        <UseLoading />
                    </div>
                ) : null}
            </div>
            <UpdateCorporateChannel id={id} />
        </div>
    );
};

export default CorporateChannelEdition;

const breadcrumbs = [
    {
        name: "services",
        translate: true,
        route: "/services",
    },
    {
        name: "tv-channels",
        translate: true,
        route: "/services/tv-channels/",
    },
    {
        name: "channels-list",
        translate: true,
        route: "services/tv-channels/corporate-channels",
    },
];
