//Utils
import Parser from "hooks/Utils/Parser";
import { getEventType, getModalScheduleDisplay, classifyPeriodicEvent } from "../../../../Utils/ScheduleDisplayTools";
import { capitalizeFirst } from "../../../../Utils/Utils";

const ShowScheduleModal = (area, langStrings) => {
    //Data
    const { id, name, schedule } = area;
    const eventType = schedule && schedule[0] && schedule[0].periodicityType === "UNIQUE" ? false : true;
    const scheduleDisplay = getSchedulesDisplay(schedule, eventType, langStrings);
    return [
        {
            id: id,
            title: "view-schedules",
            value: name,
            text: `${getEventType(schedule)}-title`,
            textAdjust: "font-bold mb-4",
            bodyAdjust: "w-7/12 max-h-80 overflow-y-scroll",
            schedule: scheduleDisplay ? Parser(scheduleDisplay) : "",
            buttons: [
                {
                    name: "edit",
                    style: "link",
                    link: `/common-zones/zones-configuration/areas/schedule/${id}`,
                },
                { name: "accept", style: "blue", action: "close" },
            ],
            buttonAlignment: "between",
        },
    ];
};

export const getSchedulesDisplay = (schedules, eventType, langStrings) => {
    //Returns all schedules display
    let res = ``;
    const classifiedSchedules = classifyPeriodicEvent(schedules);
    if (schedules) {
        let hasData = false;
        let _partialRes = ``;
        // eslint-disable-next-line
        classifiedSchedules.habitual.forEach((schedule) => {
            _partialRes += getModalScheduleDisplay(schedule, eventType, langStrings);
            hasData = true;
        });
        if (hasData) {
            res += `<div class="font-black mb-2">${capitalizeFirst(langStrings["common-schedules"])}</div>`;
            res += _partialRes;
            res += `<div class="h-4 w-full block border-b border-gray-200"></div>`;
            hasData = false;
            _partialRes = ``;
        }
        // eslint-disable-next-line
        classifiedSchedules.special.forEach((schedule) => {
            _partialRes += getModalScheduleDisplay(schedule, eventType, langStrings);
            hasData = true;
        });
        if (hasData) {
            res += `<div class="font-black mt-5">${capitalizeFirst(langStrings["special-schedules"])}</div>`;
            res += _partialRes;
            res += `<div class="h-4 w-full block border-b border-gray-200"></div>`;
            hasData = false;
            _partialRes = ``;
        }
        // eslint-disable-next-line
        classifiedSchedules.festive.forEach((schedule) => {
            _partialRes += getModalScheduleDisplay(schedule, eventType, langStrings);
            hasData = true;
        });
        if (hasData) {
            res += `<div class="font-black mt-5">${capitalizeFirst(langStrings["closed-dayss"])}</div>`;
            res += _partialRes;
            res += `<div class="h-4 w-full block border-b border-gray-200"></div>`;
            hasData = false;
            _partialRes = ``;
        }
        if (classifiedSchedules.unique) {
            classifiedSchedules.unique.forEach((schedule) => {
                res += getModalScheduleDisplay(schedule, eventType, langStrings);
            });
        }
    }
    return res;
};

export default ShowScheduleModal;
