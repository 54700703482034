import GenericSidebar from "components/Sidebar/GenericSidebar";

export const newRoute = (sectionID) => (path, params) => {
    if (path === "/" || path.length === 0) {
        path = "";
    }
    if (params?.section && !params.sidebar && !params.fullscreen) {
        params.sidebar = GenericSidebar;
    }
    return {
        path: `/${sectionID + path}`,
        sectionID,
        ...params,
    };
};

export const newRedirect = (sectionID) => (path) => {
    if (path === "/" || path.length === 0) {
        path = "";
    }
    return `/${sectionID + path}`;
};
