import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { GetScheduleMutationDates } from "../../hooks/Utils/GetScheduleMutationDates";
//Input components
import UseInputTimeEditSchedule from "./useInputTimeEditSchedule";
import UseInputDateEditSchedule from "../Schedule/useInputDateEditSchedule";
import UseButton from "../useButton";
//Actions
import {
    cleanBatchSchedules,
    cleanViewSchedules,
    setScheduleToUpdate,
    setMutationRefresh,
    addPunctualEditRow,
} from "../../actions/scheduleActions";
import { cleanAction } from "../../actions/globalActions";
import { showEditPunctualSchedule } from "../../actions/sectionActions";
import { validateSchedule } from "../../hooks/Utils/ScheduleManagement";
//Notifications
import { toast } from "react-toastify";
//Utils
import { getDateType } from "../../hooks/Utils/ScheduleDisplayTools";
import { cleanUnnecessaryChars } from "../../hooks/Utils/CleanUnnecessaryChars";
import { createBrowserHistory } from "history";
import { useTranslation } from "react-i18next";

const UseFormPunctualUpdate = ({ setEditPunctualSchedule }) => {
    const { t } = useTranslation();
    //Store data
    const { scheduleToUpdate } = useSelector((state) => state.schedules);

    //Validation States
    const [datesValidation] = useState(true);
    //Mutation states
    const [mutation, setMutation] = useState(
        //Default mutation to prevent gql conversion to throw error
        `mutation {updateTimetable(areaID: 0 name: " " isEnabled:true periodicityType: " " type: " "){ error id ok}}`
    );
    const { mutationRefresh } = useSelector((state) => state.schedules);
    //States
    const [saveAllState, setSaveAllState] = useState("");
    const [timetableToEdit, setTimetableToEdit] = useState(false);

    //API
    const UPDATE_PUNCTUAL_SCHEDULE = gql`
        ${mutation}
    `;
    const [executeMutation, { data }] = useMutation(UPDATE_PUNCTUAL_SCHEDULE);

    //Actions
    const dispatch = useDispatch();

    const handleAddDatesClick = () => {
        //Add new data form to existing schedule
        const newEmptyRow = {
            dateList: "",
            timetableDateRanges: [
                {
                    timeRanges: "",
                    weekdayList: "",
                },
            ],
        };
        dispatch(addPunctualEditRow(newEmptyRow));
        setSaveAllState("cursor-not-allowed bg-gray-500 hover:bg-gray-500");
    };

    const handleClickSaveAll = () => {
        //Validation
        const errors = getTimetableValidation(scheduleToUpdate);
        if (errors) {
            dispatch(setScheduleToUpdate(scheduleToUpdate));
            toast.error(t("check form"));
        } else {
            //Save data
            executeMutation();
        }
    };

    const handleClickCancelAll = () => {
        //Cancel all process
        const history = createBrowserHistory();
        history.go(0);
        setEditPunctualSchedule(false);
        dispatch(showEditPunctualSchedule(false));
        dispatch(cleanAction());
        dispatch(cleanBatchSchedules());
        dispatch(cleanViewSchedules());
        toast.success(t("exit-wo-save"));
    };

    const removeRow = (e) => {
        if (scheduleToUpdate.timetableDates.length > 1) {
            //Create new scheduleToUpdateInfo
            const newScheduleInfo = scheduleToUpdate;
            let newTimetables = [];
            // eslint-disable-next-line
            newScheduleInfo.timetableDates.map((timetableDate, index) => {
                if (index !== Number(e.currentTarget.dataset.index)) {
                    newTimetables.push(timetableDate);
                }
            });
            newScheduleInfo.timetableDates = newTimetables;
            //Update current store
            dispatch(setScheduleToUpdate(newScheduleInfo));
            dispatch(setMutationRefresh(true));
        } else {
            toast.error(t("cant-delete-last"));
        }
    };

    //Listeners
    useEffect(() => {
        if (data && data.updateTimetable && data.updateTimetable.ok) {
            toast.success(t("punctual schedule updated"));
            //Reset view
            dispatch(cleanAction());
            dispatch(showEditPunctualSchedule(false));
        } else if (data && data.updateTimetable && !data.updateTimetable.ok) {
            toast.error(t("mutation-error"));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (scheduleToUpdate && scheduleToUpdate.timetableDates && scheduleToUpdate.timetableDates.length > 0) {
            setTimetableToEdit(scheduleToUpdate);
        }

        if (mutationRefresh) {
            //Set mutation
            setMutation(
                getMutation(
                    scheduleToUpdate.ID,
                    scheduleToUpdate.dateType,
                    GetScheduleMutationDates(cleanValidationValues(scheduleToUpdate.timetableDates)),
                    scheduleToUpdate.isEnabled
                )
            );
            dispatch(setMutationRefresh(false));
        }
        // eslint-disable-next-line
    }, [scheduleToUpdate, mutationRefresh]);

    return (
        <>
            <div className="w-full block bg-white p-8">
                <>
                    <div className="pt-4 pb-8">
                        {timetableToEdit ? (
                            <>
                                <div className="w-full block mb-4">
                                    <div className="w-3/12 inline-block pr-8">
                                        {t(getDateType(timetableToEdit.dateType))}
                                    </div>

                                    {timetableToEdit.timetableDates.map((tt, index) => (
                                        <span className="w-full" key={index}>
                                            {index !== 0 ? <div className="w-3/12 inline-block pr-8"></div> : null}
                                            <div className="w-3/12 inline-block pr-8 z-200">
                                                {
                                                    <UseInputDateEditSchedule
                                                        validation={datesValidation}
                                                        index={index}
                                                    />
                                                }
                                            </div>
                                            <div className="inline-block w-6/12">
                                                <div className="inline-block">
                                                    <UseInputTimeEditSchedule
                                                        dateId="morningStart"
                                                        validation={
                                                            typeof tt.timetableDateRanges[0].MSValidation !==
                                                            "undefined"
                                                                ? tt.timetableDateRanges[0].MSValidation
                                                                : true
                                                        }
                                                        index={index}
                                                    />{" "}
                                                    -{" "}
                                                    <UseInputTimeEditSchedule
                                                        dateId="morningEnd"
                                                        validation={
                                                            typeof tt.timetableDateRanges[0].MEValidation !==
                                                            "undefined"
                                                                ? tt.timetableDateRanges[0].MEValidation
                                                                : true
                                                        }
                                                        index={index}
                                                    />{" "}
                                                    /{" "}
                                                    <UseInputTimeEditSchedule
                                                        dateId="afternoonStart"
                                                        validation={
                                                            typeof tt.timetableDateRanges[0].ASValidation !==
                                                            "undefined"
                                                                ? tt.timetableDateRanges[0].ASValidation
                                                                : true
                                                        }
                                                        index={index}
                                                    />{" "}
                                                    -{" "}
                                                    <UseInputTimeEditSchedule
                                                        dateId="tt.timetableDateRanges[0].passValidation"
                                                        validation={
                                                            typeof tt.timetableDateRanges[0].AEValidation !==
                                                            "undefined"
                                                                ? tt.timetableDateRanges[0].AEValidation
                                                                : true
                                                        }
                                                        index={index}
                                                    />
                                                </div>
                                                <div
                                                    className="icon-remove inline-block ml-8 hover:text-blue-200 cursor-pointer"
                                                    data-index={index}
                                                    onClick={removeRow}
                                                ></div>
                                            </div>
                                        </span>
                                    ))}
                                </div>
                            </>
                        ) : null}
                        <>
                            <div className="w-3/12 inline-block"></div>
                            <div
                                className="w-3/12 inline-block text-blue-300 font-bold cursor-pointer hover:text-blue-200"
                                onClick={handleAddDatesClick}
                            >
                                <span className="icon-add mr-2"></span>
                                {t("add-dates")}
                            </div>
                        </>
                    </div>
                </>
            </div>
            <div className="w-full flex justify-end h-10 mt-8 mr-4 mb-4">
                <div className="w-auto inline-block mr-4" onClick={handleClickCancelAll}>
                    <UseButton buttonName="exit-unsaved" buttonColor="btn-blue-outline" adjust="px-8" />
                </div>
                <div className={`w-auto inline-block ${saveAllState}`} onClick={handleClickSaveAll}>
                    <UseButton buttonName="save-and-close" buttonColor="btn-blue" adjust="px-8" />
                </div>
            </div>
        </>
    );
};

//Mutation functions
const getMutation = (areaID, dateType, timetableDates, status) => {
    //Returns dynamic mutation
    //ISO 8601 standard Monday(1) Sunday (7)
    return `mutation {
    updateTimetable(
      name: ""
      type: "OPEN"
      isEnabled: ${status}
      dateType: "${dateType}"
      timetableDates: ${timetableDates}
      id: ${areaID}
      ){ error id ok}}`;
};

const cleanValidationValues = (timetableDates) => {
    timetableDates.map((timetableDate) => {
        delete timetableDate.timetableDateRanges[0].passValidation;
        delete timetableDate.timetableDateRanges[0].MSValidation;
        delete timetableDate.timetableDateRanges[0].MEValidation;
        delete timetableDate.timetableDateRanges[0].ASValidation;
        delete timetableDate.timetableDateRanges[0].AEValidation;
        return true;
    });
    return timetableDates;
};

const getTimetableValidation = (scheduleToUpdate) => {
    let errors = false;
    scheduleToUpdate.timetableDates.map((timetableDate, index) => {
        //Validate dates
        if (
            timetableDate.dateList === "" ||
            timetableDate.dateList === "[]" ||
            timetableDate.timetableDateRanges[0].timeRanges === "" ||
            timetableDate.timetableDateRanges[0].timeRanges === "[-]"
        ) {
            errors = true;
        }
        //if (timetableDate.timetableDateRanges[0])
        const schedules = extractHours(timetableDate.timetableDateRanges[0].timeRanges);

        const validationResult = validateSchedule({
            morningStart: schedules[0],
            morningEnd: schedules[1],
            afternoonStart: schedules[2],
            afternoonEnd: schedules[3],
        });
        if (
            !validationResult.morningStart ||
            !validationResult.morningEnd ||
            !validationResult.afternoonStart ||
            !validationResult.afternoonEnd
        ) {
            errors = true;
        }
        timetableDate.timetableDateRanges[0].MSValidation = validationResult.morningStart;
        timetableDate.timetableDateRanges[0].MEValidation = validationResult.morningEnd;
        timetableDate.timetableDateRanges[0].ASValidation = validationResult.afternoonStart;
        timetableDate.timetableDateRanges[0].AEValidation = validationResult.afternoonEnd;

        return true;
    });
    return errors;
};

const extractHours = (hoursString) => {
    let schedules = cleanUnnecessaryChars(hoursString);
    schedules = schedules.split('"').join("");
    schedules = schedules.split("[").join("");
    schedules = schedules.split("]").join("");
    schedules = schedules.split(",").join("-");
    schedules = schedules.split("-");
    return schedules;
};

export default UseFormPunctualUpdate;
