import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { GetScreenDeviceSizes, GetWidgetGrid } from "../../../../../../hooks/Utils/Widgets";
import ReactTooltip from "components/ReactTooltip";

//Actions
import { setExecuteQuery } from "../../../../../../actions/sectionActions";
import Widget from "../../Widget";

import { toast } from "react-toastify";
import { setItemSelected, setWidgetToolbarVisible } from "../../../../../../actions/designActions";
import { showGlobalLoading } from "../../../../../../actions/uiActions";

const ScreenMargins = ({ deviceType, deviceSpecific, isMenu }) => {
    const DEFAULT_COLS = 16;
    const DEFAULT_ROWS = 9;
    const { t } = useTranslation();

    const dispatch = useDispatch();

    //States
    const [hmError, setHMError] = useState(false);
    const [vmError, setVMError] = useState(false);
    const [whmError, setWHMError] = useState(false);
    const [wvmError, setWVMError] = useState(false);
    const [rowsError, setRowsError] = useState(false);
    const [colsError, setColsError] = useState(false);

    //Store
    const {
        cDevice,
        cScreenData,
        widgetToolbarVisible,
        itemSelected: widgetID,
        gridItems,
        gridLayout,
    } = useSelector((state) => state.design);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const data = Widget.ParseData(widget ? widget.data : null, deviceType);
    const wSelected = gridLayout.find((el) => {
        return widget?.i === el.i;
    });

    const isBackground = widgetID === "bg";

    const { maxGrid: { cols: columnsGrid = 0, rows: rowsGrid = 0 } = {} } = GetScreenDeviceSizes({ deviceType });
    const {
        maxGrid: { cols: columnsWidget = 0, rows: rowsWidget = 0 },
    } = GetWidgetGrid({ widgetType: wSelected?.type });

    const maxColumn = wSelected?.type ? columnsWidget : columnsGrid ? columnsGrid : null;
    const maxRows = wSelected?.type ? rowsWidget : rowsGrid ? rowsGrid : null;

    //Listeners
    useEffect(() => {
        if (
            widgetToolbarVisible === "screen-settings" ||
            widgetToolbarVisible === "container-settings" ||
            widgetToolbarVisible === "tv-menu-settings"
        ) {
            const device = isBackground ? cDevice : data;
            const settings = isBackground ? (cScreenData ? cScreenData.contentStyle : null) : data;
            const grid = device ? device.grid : null;
            const margin = settings ? settings.margin : null;
            const itemsMargin = settings ? settings.itemsMargin : null;

            let rows = document.querySelector("#input_rows");
            let columns = document.querySelector("#input_columns");
            rows.value = grid ? grid.rows : DEFAULT_ROWS;
            columns.value = grid ? grid.cols : DEFAULT_COLS;

            let mh = document.querySelector("#input_mh");
            let mw = document.querySelector("#input_mw");
            mh.value = margin ? margin.h : 10;
            mw.value = margin ? margin.w : 10;

            let imh = document.querySelector("#input_imh");
            let imw = document.querySelector("#input_imw");
            imh.value = itemsMargin ? itemsMargin.h : 10;
            imw.value = itemsMargin ? itemsMargin.w : 10;
        }
        // eslint-disable-next-line
    }, [widgetToolbarVisible]);

    //Actions
    const updateHorintalMargin = (e) => {
        const value = e.target.value;
        if (value === "0") {
            e.target.value = "0";
            setHMError(false);
            return;
        }

        if (e.target.value < 0) {
            setHMError(true);
            toast.error(t("value-greater-x", { val: "0" }));
            return;
        }
        setHMError(false);

        if (isBackground) {
            const contentStyle = JSON.parse(JSON.stringify(cScreenData.contentStyle));
            contentStyle.margin = {
                w: parseFloat(e.target.value),
                h: cScreenData.contentStyle.margin.h,
            };
            dispatch(
                setExecuteQuery({
                    action: "updateContentStyle",
                    params: {
                        contentStyle: contentStyle,
                    },
                })
            );
        } else {
            const margins = JSON.parse(JSON.stringify(data.margin));
            margins.w = parseFloat(e.target.value);

            dispatch(
                setExecuteQuery(
                    Widget.UpdateData({
                        widget,
                        property: "margin",
                        value: margins,
                        deviceSpecific: deviceSpecific ? deviceType : null,
                    })
                )
            );
        }
    };

    const updateVerticalMargin = (e) => {
        const value = e.target.value;

        if (value === "0") {
            e.target.value = "0";
            setVMError(false);
            return;
        }

        if (e.target.value < 0) {
            setVMError(true);
            toast.error(t("value-greater-x", { val: "0" }));
            return;
        }
        setVMError(false);

        if (isBackground) {
            const contentStyle = JSON.parse(JSON.stringify(cScreenData.contentStyle));
            contentStyle.margin = {
                h: parseFloat(e.target.value),
                w: cScreenData.contentStyle.margin.w,
            };

            dispatch(
                setExecuteQuery({
                    action: "updateContentStyle",
                    params: {
                        contentStyle: contentStyle,
                    },
                })
            );
        } else {
            const margins = JSON.parse(JSON.stringify(data.margin));
            margins.h = parseFloat(e.target.value);

            dispatch(
                setExecuteQuery(
                    Widget.UpdateData({
                        widget,
                        property: "margin",
                        value: margins,
                        deviceSpecific: deviceSpecific ? deviceType : null,
                    })
                )
            );
        }
    };

    const updateWidgetHorizontalMargin = (e) => {
        const value = e.target.value;

        if (value === "0") {
            e.target.value = "0";
            setHMError(false);
            return;
        }

        if (e.target.value < 0) {
            setWHMError(true);
            toast.error(t("value-greater-x", { val: "0" }));
            return;
        }
        setWHMError(false);

        if (isBackground) {
            const contentStyle = JSON.parse(JSON.stringify(cScreenData.contentStyle));
            contentStyle.itemsMargin = {
                w: parseFloat(e.target.value),
                h: cScreenData.contentStyle.itemsMargin.h,
            };

            dispatch(
                setExecuteQuery({
                    action: "updateContentStyle",
                    params: {
                        contentStyle: contentStyle,
                    },
                })
            );
        } else {
            const margins = JSON.parse(JSON.stringify(data.itemsMargin));
            e.target.value = e.target.value ? e.target.value : "0";
            margins.w = parseFloat(e.target.value);

            dispatch(
                setExecuteQuery(
                    Widget.UpdateData({
                        widget,
                        property: "itemsMargin",
                        value: margins,
                        deviceSpecific: deviceSpecific ? deviceType : null,
                    })
                )
            );
        }
    };

    const updateWidgetVerticalMargin = (e) => {
        const value = e.target.value;

        if (value === "0") {
            e.target.value = "0";
            setHMError(false);
            return;
        }
        if (e.target.value < 0) {
            setWVMError(true);
            toast.error(t("value-greater-x", { val: "0" }));
            return;
        }
        setWVMError(false);
        if (isBackground) {
            const contentStyle = JSON.parse(JSON.stringify(cScreenData.contentStyle));
            contentStyle.itemsMargin = {
                h: parseFloat(e.target.value),
                w: cScreenData.contentStyle.itemsMargin.w,
            };

            dispatch(
                setExecuteQuery({
                    action: "updateContentStyle",
                    params: {
                        contentStyle: contentStyle,
                    },
                })
            );
        } else {
            const margins = JSON.parse(JSON.stringify(data.itemsMargin));
            e.target.value = e.target.value ? e.target.value : "0";
            margins.h = parseFloat(e.target.value);

            dispatch(
                setExecuteQuery(
                    Widget.UpdateData({
                        widget,
                        property: "itemsMargin",
                        value: margins,
                        deviceSpecific: deviceSpecific ? deviceType : null,
                    })
                )
            );
        }
    };

    const updateRows = (e) => {
        if (e.target.value < 1) {
            setRowsError(true);
            toast.error(t("value-greater-x", { val: "1" }));
            return;
        }
        if (e.target.value >= rowsWidget && (wSelected?.type === "CONTAINER" || wSelected?.type === "TVMENU")) {
            setRowsError(true);
            toast.error(t("warning-less-value-rows-colums", { maxvalue: rowsWidget }));
            return;
        } else if (e.target.value >= rowsGrid && deviceType !== "Desktop") {
            setRowsError(true);
            toast.error(t("warning-less-value-rows-colums", { maxvalue: rowsGrid }));
            return;
        }
        setRowsError(false);
        let pVal = null;

        const device = isBackground ? cDevice : data;
        let grid = device ? device.grid : null;

        if (grid) {
            grid = JSON.parse(JSON.stringify(grid));
            pVal = grid.rows;
        } else {
            grid = { rows: DEFAULT_ROWS, cols: DEFAULT_COLS };
            pVal = grid.rows;
        }
        grid.rows = parseFloat(e.target.value);

        if (isBackground) {
            let devices = cScreenData && cScreenData.data ? cScreenData.data.devices : [];
            devices = devices.map((d) => {
                if (d.type === device.type) {
                    d.grid = grid;
                }
                return d;
            });
            dispatch(
                setExecuteQuery({
                    action: "updateContentDevices",
                    params: { devices: [...devices], changes: { rows: [pVal, grid.rows] } },
                })
            );
            reloadToolbarVisible("bg");
        } else {
            dispatch(
                setExecuteQuery(
                    Widget.UpdateData({
                        widget,
                        property: "grid",
                        value: grid,
                        deviceSpecific: deviceSpecific ? deviceType : null,
                        extraArgs: {
                            changeContainerSize: {
                                rows: true,
                                pVal: pVal,
                                nVal: grid.rows,
                                containerId: widgetID,
                            },
                        },
                    })
                )
            );
            reloadToolbarVisible(wSelected.type, wSelected.i);
        }
    };
    const updateColumns = (e) => {
        if (e.target.value < 1) {
            setColsError(true);
            toast.error(t("value-greater-x", { val: "1" }));
            return;
        }
        if (e.target.value >= columnsWidget && (wSelected?.type === "CONTAINER" || wSelected?.type === "TVMENU")) {
            setColsError(true);
            toast.error(t("warning-less-value-rows-colums", { maxvalue: columnsWidget }));
            return;
        } else if (e.target.value >= columnsGrid && deviceType !== "Desktop") {
            setColsError(true);
            toast.error(t("warning-less-value-rows-colums", { maxvalue: columnsGrid }));
            return;
        }
        setColsError(false);

        let pVal = null;

        const device = isBackground ? cDevice : data;
        let grid = device ? device.grid : null;

        if (grid) {
            grid = JSON.parse(JSON.stringify(grid));
            pVal = grid.cols;
        } else {
            grid = { rows: DEFAULT_ROWS, cols: DEFAULT_COLS };
            pVal = grid.cols;
        }
        grid.cols = parseFloat(e.target.value);

        if (isBackground) {
            let devices = cScreenData && cScreenData.data ? cScreenData.data.devices : [];
            devices = devices.map((d) => {
                if (d.type === device.type) {
                    d.grid = grid;
                }
                return d;
            });
            dispatch(
                setExecuteQuery({
                    action: "updateContentDevices",
                    params: { devices: [...devices], changes: { cols: [pVal, grid.cols] } },
                })
            );
            reloadToolbarVisible("bg");
        } else {
            dispatch(
                setExecuteQuery(
                    Widget.UpdateData({
                        widget,
                        property: "grid",
                        value: grid,
                        deviceSpecific: deviceSpecific ? deviceType : null,
                        extraArgs: {
                            changeContainerSize: {
                                cols: true,
                                pVal: pVal,
                                nVal: grid.cols,
                                containerId: widgetID,
                            },
                        },
                    })
                )
            );
            reloadToolbarVisible(wSelected.type, wSelected.i);
        }
    };

    const reloadToolbarVisible = (wType, wId) => {
        if (wType === "bg") {
            dispatch(showGlobalLoading(true));
            setTimeout(() => {
                dispatch(setItemSelected("bg"));
                dispatch(setWidgetToolbarVisible("screen-settings"));
                dispatch(showGlobalLoading(false));
                const buttonSettingsBG = document.querySelector("#background .icon-settings-editor");
                if (buttonSettingsBG) {
                    buttonSettingsBG.click();
                }
            }, 1000);
        } else {
            setTimeout(() => {
                dispatch(setItemSelected(wId));
                const itemSettings = document.querySelector(`#toolbar_${wId} div #toolbar-settings`);
                if (itemSettings) {
                    itemSettings.click();
                }
            }, 2500);
        }
    };

    return (
        <div className="border-b border-gray-200 p-8">
            <div className="first-capital font-bold mb-8">
                {t(isBackground || isMenu ? "screen-options" : "container options")}
            </div>

            <div className="first-capital mb-2 flex items-center">
                {t("rows and columns")}
                {deviceType !== "Desktop" ? (
                    <i className="icon icon-info ml-2" data-for={`info-max-rows-and-columns`} data-tip />
                ) : null}
                <ReactTooltip id={`info-max-rows-and-columns`}>
                    {t("max-value-rows-colums", {
                        row: wSelected?.type ? rowsWidget : rowsGrid,
                        column: wSelected?.type ? columnsWidget : columnsGrid,
                    })}
                </ReactTooltip>
            </div>
            <div className="flex justify-between w-full">
                <div className="flex w-5/12">
                    <div className="icon icon-rows text-gray-800 w-1/5 mt-3  mr-3"></div>
                    <input
                        type="number"
                        step="1"
                        min="1"
                        max={maxRows - 1}
                        id={`input_rows`}
                        className={`w-3/5 h-10 bg-gray-200 rounded px-4 py-2 ${rowsError ? "borderError" : ""}`}
                        onBlur={(e) => updateRows(e)}
                        onKeyUp={(e) => (e.keyCode === 13 ? updateRows(e) : null)}
                    />
                </div>
                <div className="flex w-5/12 ">
                    <div className="icon icon-columns text-gray-800 w-1/5 mt-3 mr-3"></div>
                    <input
                        type="number"
                        step="1"
                        min="1"
                        max={maxColumn - 1}
                        id={`input_columns`}
                        className={`w-3/5 h-10 bg-gray-200 rounded px-4 py-2 ${colsError ? "borderError" : ""}`}
                        onBlur={(e) => updateColumns(e)}
                        onKeyUp={(e) => (e.keyCode === 13 ? updateColumns(e) : null)}
                    />
                </div>
            </div>

            <div className="first-capital mt-5 mb-2">{t("margins")}</div>
            <div className={`flex justify-between w-full`}>
                <div className="flex w-5/12 ">
                    <div className="icon icon-vertical-margins w-1/5 mt-2 mr-3"></div>
                    <input
                        type="number"
                        step="1"
                        min="0"
                        id={`input_mh`}
                        className={`w-3/5 h-10 bg-gray-200 rounded px-4 py-2  ${vmError ? "borderError" : ""}`}
                        onBlur={(e) => updateVerticalMargin(e)}
                        onKeyUp={(e) => (e.keyCode === 13 ? updateVerticalMargin(e) : null)}
                    />
                    <span className="mt-2 ml-1">px</span>
                </div>
                <div className="flex w-5/12">
                    <div className="icon icon-horizontal-margins w-1/5 mt-2  mr-3"></div>
                    <input
                        type="number"
                        step="1"
                        min="0"
                        id={`input_mw`}
                        className={`w-3/5 h-10 bg-gray-200 rounded px-4 py-2 ${hmError ? "borderError" : ""}`}
                        onBlur={(e) => updateHorintalMargin(e)}
                        onKeyUp={(e) => (e.keyCode === 13 ? updateHorintalMargin(e) : null)}
                    />
                    <span className="mt-2 ml-1">px</span>
                </div>
            </div>
            <div className="first-capital mb-2 mt-5">{t("margin-between-items")}</div>
            <div className={`flex justify-between  w-full`}>
                <div className="flex w-5/12">
                    <div className="icon icon-horizontal-space w-1/5 mt-2  mr-3"></div>
                    <input
                        type="number"
                        step="1"
                        min="0"
                        id={`input_imw`}
                        className={`w-3/5 h-10 bg-gray-200 rounded px-4 py-2  ${whmError ? "borderError" : ""}`}
                        onBlur={(e) => updateWidgetHorizontalMargin(e)}
                        onKeyUp={(e) => (e.keyCode === 13 ? updateWidgetHorizontalMargin(e) : null)}
                    />
                    <span className="mt-2 ml-1">px</span>
                </div>
                <div className="flex w-5/12 ">
                    <div className="icon icon-vertical-space w-1/5 mt-2 mr-3"></div>
                    <input
                        type="number"
                        step="1"
                        min="0"
                        id={`input_imh`}
                        className={`w-3/5 h-10 bg-gray-200 rounded px-4 py-2  ${wvmError ? "borderError" : ""}`}
                        onBlur={(e) => updateWidgetVerticalMargin(e)}
                        onKeyUp={(e) => (e.keyCode === 13 ? updateWidgetVerticalMargin(e) : null)}
                    />
                    <span className="mt-2 ml-1">px</span>
                </div>
            </div>
        </div>
    );
};

export default ScreenMargins;
