import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import translate from "../../lang/es/Global/ModalContent";
//Utils
import useComponentVisible from "../useComponentVisible";
import UseModalOptions from "./useModalOptions";
import { multipleTranslate, getBookingDurationOptions, minutesToHours } from "../../hooks/Utils/Utils";
import { changeActionValues } from "../../actions/globalActions";

import Parser from "hooks/Utils/Parser";

const UseModalSelect = ({ index }) => {
    //Store data
    const { text, name, ph, cStyle, data } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const activeValue = useSelector((state) => state.action.values[name]);
    const bookingFreqValue = useSelector((state) => state.action.values["booking-freq"]);
    const { langStrings } = useSelector((state) => state.ui);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //States
    const [optionSelected, setOptionSelected] = useState();
    const [dynamicValues, setDynamicValues] = useState(data);
    const [selectActive, setSelectActive] = useState({});

    const dispatch = useDispatch();
    //Listeners
    useEffect(() => {
        if (!isComponentVisible) {
            if (optionSelected) {
                setSelectActive({ text: "font-normal text-gray-900" });
            } else {
                setSelectActive({});
            }
        } else {
            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
            });
        }
    }, [isComponentVisible, optionSelected]);

    useEffect(() => {
        if (activeValue) {
            if (name === "logo-type") {
                setOptionSelected(data[activeValue].value);
            } else {
                setOptionSelected(activeValue);
            }
        }
        // eslint-disable-next-line
    }, [activeValue]);

    useEffect(() => {
        if (activeValue) {
            setOptionSelected(activeValue);
            if (name === "logo-type") {
                setOptionSelected(data[activeValue].value);
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (bookingFreqValue) {
            let freqInMinutes = 0;
            switch (bookingFreqValue) {
                case "each-15-min":
                    freqInMinutes = 15;
                    break;
                case "each-30-min":
                    freqInMinutes = 30;
                    break;
                case "each-hour":
                    freqInMinutes = 60;
                    break;
                case "each-custom":
                    freqInMinutes = 0;
                    break;
                default:
                    freqInMinutes = bookingFreqValue;
                    break;
            }
            setDynamicValues(getBookingDurationOptions(freqInMinutes));

            dispatch(
                changeActionValues({
                    // eslint-disable-next-line
                    ["booking-duration"]: minutesToHours(freqInMinutes),
                })
            );
        }
        // eslint-disable-next-line
    }, [bookingFreqValue]);

    return (
        <>
            {text ? (
                <span className="block pb-2 first-capital">{Parser(multipleTranslate(text, langStrings))}</span>
            ) : null}
            <div ref={ref} className={`w-48 h-10 bg-gray-200 rounded relative ${selectActive.container} ${cStyle}`}>
                <div
                    className={`t-filter-ph ${selectActive.text}`}
                    onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                >
                    {!activeValue ? (ph ? multipleTranslate(ph, langStrings) : translate(name)) : optionSelected}
                </div>
                <span
                    className={`field-icon icon-chevron animated ${selectActive.icon}`}
                    onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                ></span>
                {isComponentVisible ? (
                    <span onClick={() => setIsComponentVisible(false)}>
                        <UseModalOptions index={index} dynamicValues={dynamicValues} />
                    </span>
                ) : null}
            </div>
        </>
    );
};

export default UseModalSelect;
