const rawForbiddenWords = [
    "Portal",
    "Zafiro",
    "Entertainment Solutions",
    "Testing",
    "Beta",
    "Release",
    "Staging",
    "Produccion",
    "Production",
    "fuck",
    "shit",
    "bitch",
    "damn",
    "asshole",
    "dick",
    "pussy",
    "bastard",
    "cunt",
    "hell",
    "cock",
    "motherfucker",
    "crap",
    "piss",
    "ass",
    "faggot",
    "slut",
    "whore",
    "wanker",
    "prick",
    "twat",
    "bollocks",
    "bullshit",
    "arsehole",
    "son of a bitch",
    "screw you",
    "jackass",
    "douchebag",
    "sissy",
    "shithead",
    "fuckwit",
    "arse",
    "shitface",
    "dickhead",
    "fuckface",
    "dickbag",
    "piss off",
    "asswipe",
    "scumbag",
    "shitbag",
    "dumbass",
    "fuckboy",
    "douche",
    "turd",
    "mierda",
    "joder",
    "puta",
    "cabron",
    "gilipollas",
    "coño",
    "culo",
    "polla",
    "bastardo",
    "carajo",
    "chingar",
    "pendejo",
    "cojones",
    "huevón",
    "perra",
    "puto",
    "marica",
    "zorra",
    "idiota",
    "imbecil",
    "estupido",
    "tonto",
    "baboso",
    "boludo",
    "soplapote",
    "mamón",
    "chingada madre",
    "hijo de puta",
    "gil",
    "pelotudo",
    "forro",
    "mongólico",
    "subnormal",
    "tarado",
    "retardado",
    "desgraciado",
    "putazo",
    "chinga tu madre",
    "verga",
    "mamon",
    "pinche",
    "nigger",
    "kike",
    "spic",
    "gringo",
    "yanqui",
    "gabacho",
    "grasa",
    "naco",
    "redneck",
    "hillbilly",
    "cuntface",
    "twatwaffle",
    "cumslut",
    "assclown",
    "buttlicker",
    "cocksmoker",
    "fudgepacker",
    "abortionista",
    "satanas",
    "diablo",
    "infeliz",
    "muerto de hambre",
    "cabeza de chorlito",
    "cabeza hueca",
    "ojete",
];

export const forbiddenWords = rawForbiddenWords.map((word) => word.replace(/\s/g, "").toLowerCase());
