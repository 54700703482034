import {
    PENDING_VALIDATION_CAST_NETWORKS,
    VALIDATION_MUTATION,
} from "components/Section/Monitor/services/getReportQueries";
import React, { createContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "react-apollo";

const STEP_MUTATE = 1;
const STEP_PENDING = 2;

export const ValidateNetworkContext = createContext({
    controlToRefetch: null, // State to control refetch
    isPending: false, // CC data is being updated
    updateNetwork: () => {}, // Update cc data
});

export const ValidateNetworkProvider = ({ children }) => {
    const [controlToRefetch, setControlToRefetch] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [step, setStep] = useState(null);

    const [executeMutation, { data: mutationData }] = useMutation(VALIDATION_MUTATION);

    const [executeQuery, { data }] = useLazyQuery(PENDING_VALIDATION_CAST_NETWORKS, {
        pollInterval: 20000,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        switch (step) {
            case STEP_MUTATE:
                executeMutation();
                break;
            case STEP_PENDING:
                executeQuery();
                break;
            default:
            //do nothing
        }
    }, [step]);

    useEffect(() => {
        if (step === STEP_MUTATE && mutationData) {
            if (mutationData.validateCastNetworks.ok) {
                setStep(STEP_PENDING);
                setIsPending(true);
            } else {
                setStep(null);
            }
        }
    }, [mutationData]);

    useEffect(() => {
        if (step === STEP_PENDING && data) {
            if (data?.pendingValidateCastNetwork) {
                if (data.pendingValidateCastNetwork.isPending) {
                    setIsPending(true);
                    setControlToRefetch(true);
                } else if (controlToRefetch && !data.pendingValidateCastNetwork.isPending) {
                    setIsPending(false);
                    setControlToRefetch(false);
                    setStep(null);
                }
            } else {
                setStep(null);
            }
        }
    }, [controlToRefetch, data?.pendingValidateCastNetwork?.isPending]);

    return (
        <ValidateNetworkContext.Provider
            value={{
                isPending,
                updateNetwork: () => {
                    if (!step) {
                        setStep(STEP_MUTATE);
                        setControlToRefetch(null);
                    }
                },
                controlToRefetch,
            }}
        >
            {children}
        </ValidateNetworkContext.Provider>
    );
};
