import React from "react";
//Components
import UseSectionHeader from "../../../useSectionHeader";
import UseTable from "../../../Table";
//Utils
import ListMessages from "../../../../hooks/GraphqlCalls/Customers/ListMessages";
import UpdateMessages from "../../../../hooks/GraphqlCalls/Customers/UpdateMessages";
import { useTranslation } from "react-i18next";

const Messages = () => {
    ListMessages();
    const { t } = useTranslation();
    return (
        <>
            <UpdateMessages />
            <UseSectionHeader title={["list-of", t("messages").toLowerCase()]} />
            <UseTable />
        </>
    );
};

export default Messages;
