export const GetHotelTableHeaders = (section, guestAccess, dataOne) => {
    let res = [];
    //Sort icon
    const tableSortIcon = { name: "table-sort" };

    //Special width classes
    const w112 = "w-1/12";
    const w1p512 = "w-1.5/12";
    const w212 = "w-2/12";
    const w312 = "w-3/12";
    const w412 = "w-4/12";
    const w512 = "w-5/12";
    const w612 = "w-6/12";
    const w1112 = "w-11/12";

    //Header types
    let name = { name: "name" };
    let mac = { name: "mac" };
    let status = { name: "status" };
    let ip = { name: "ip" };
    let room = { name: "room" };
    let group = { name: "group" };
    let roomWarning = { name: "device-status" };
    let warning = { name: "warning" };
    let lastActivity = { name: "last-activity", sortName: "lastActivityDate" };
    let model = { name: "model" };
    let brand = { name: "brand" };
    let device = { name: "device" };
    let guest = { name: "guest" };
    let stay = { name: "stay" };
    let notice = { name: "status" };
    let preview = { name: "preview", hidden: true };
    let groupName = { name: "group-name", sortName: "name" };
    let numberRooms = { name: "number-of-rooms", sortName: "numberRooms" };
    let wing = { name: "wing", sortName: "wingName" };
    let floor = { name: "floor", sortName: "floorName" };
    let roomNumber = { name: "room-number-short", sortName: "number" };
    let pmsNumber = { name: "pms-number-short", sortName: "pmsNumber" };
    let tvs = { name: "tvs" };
    let location = { name: "location" };
    let minBandWidth = { name: "min-bandwidth" };
    let maxBandWidth = { name: "max-bandwidth" };
    let accessType = { name: "access-type" };
    let emptyCell = { name: "" };
    let inputs = { name: "inputs" };
    let tvsAssigned = { name: "num-tvs-assigned" };
    let deviceFunction = { name: "device-function", sortName: "function" };
    let tvLocation = { name: "tv-location", sortName: "room" };
    let lastDisplay = { name: "last-display", sortName: "lastActivityDate" };

    switch (section) {
        case "list-device":
            name.specialWidth = w312;
            deviceFunction.specialWidth = w212;
            tvLocation.specialWidth = w212;
            status.specialWidth = w212;
            lastDisplay.specialWidth = w212;
            emptyCell.specialWidth = w112;
            name.icon = tableSortIcon;
            deviceFunction.icon = tableSortIcon;
            tvLocation.icon = tableSortIcon;
            status.icon = tableSortIcon;
            lastDisplay.icon = tableSortIcon;

            res.push(name, deviceFunction, tvLocation, status, lastDisplay);
            break;
        case "list-room-devices":
            name.specialWidth = w312;
            deviceFunction.specialWidth = w212;
            status.specialWidth = w212;
            lastDisplay.specialWidth = w212;
            preview.specialWidth = w212;
            res.push(name, deviceFunction, status, lastDisplay, preview);
            break;
        case "list-rooms":
            room.icon = tableSortIcon;
            room.specialWidth = w412;
            group.specialWidth = w312;
            status.icon = tableSortIcon;
            status.specialWidth = w312;
            guest.specialWidth = w212;
            stay.icon = tableSortIcon;
            stay.specialWidth = w312;
            roomWarning.icon = tableSortIcon;
            roomWarning.specialWidth = w312;
            preview.specialWidth = w212;
            if (guestAccess) {
                res.push(room, group, status, guest, stay, roomWarning);
            } else {
                group.specialWidth = w312;
                roomWarning.specialWidth = w1112;
                //preview.specialWidth = w212;
                res.push(room, group, roomWarning, preview);
            }
            break;

        case "list-room-groups":
            groupName.icon = tableSortIcon;
            groupName.specialWidth = w412;
            numberRooms.icon = tableSortIcon;
            numberRooms.specialWidth = w612;
            res.push(groupName, numberRooms, { specialWidth: w112 }, { specialWidth: w112 });
            break;

        case "list-topology-rooms":
            wing.specialWidth = w1p512;
            floor.specialWidth = w1p512;
            roomNumber.specialWidth = w112;
            pmsNumber.specialWidth = w112;
            name.specialWidth = w212;
            tvs.specialWidth = w112;
            location.specialWidth = w312;
            emptyCell.specialWidth = w112;

            wing.icon = tableSortIcon;
            floor.icon = tableSortIcon;
            roomNumber.icon = tableSortIcon;
            pmsNumber.icon = tableSortIcon;
            name.icon = tableSortIcon;

            res.push(wing, floor, roomNumber, pmsNumber, name, tvs, location, emptyCell);
            break;
        case "list-access-profiles":
            name.specialWidth = w312;
            minBandWidth.specialWidth = w212;
            maxBandWidth.specialWidth = w212;
            emptyCell.specialWidth = w112;
            accessType.specialWidth = w312;
            name.icon = tableSortIcon;
            res.push(name, minBandWidth, maxBandWidth, emptyCell, accessType);
            emptyCell.specialWidth = w112;
            res.push(emptyCell);
            break;

        case "list-input-groups":
            name.specialWidth = w312;
            inputs.specialWidth = w412;
            tvsAssigned.specialWidth = w212;
            emptyCell.specialWidth = w312;

            res.push(name, inputs, tvsAssigned, emptyCell);

            break;
        default:
            res = [""];
    }
    return res;
};

export default GetHotelTableHeaders;
