import React from "react";

import Toggle from "components/Inputs/Toggle";

const Switch = (props) => {
    return <Toggle {...props} design="switch" />;
};
Switch.displayName = "Switch";

export default Switch;
