import { useMemo } from "react";
import Sitemap, { MainMenu, Settings } from "sitemap";
import { UseSectionMap } from "components/Sidebar/utils";

export const UseMainMenu = ({ paths }) => {
    const { checkPath } = usePermissions();
    const { getItems } = UseSectionMap(checkPath);

    const menu = useMemo(() => {
        const sections = MainMenu.reduce((acc, sectionID) => {
            const pathAvailable = checkPath("/" + sectionID, paths);
            const mapAvailable = pathAvailable ? getItems(sectionID, Sitemap[sectionID], paths)?.length : false;
            acc[sectionID] = pathAvailable && mapAvailable;
            return acc;
        }, {});
        return sections ? Object.keys(sections).filter((key) => sections[key]) : null;
    }, [paths, getItems]);

    return menu;
};

export const UseSettingsMenu = ({ paths }) => {
    const { checkPath } = usePermissions();
    const menu = {};
    if (Settings) {
        Object.keys(Settings).forEach((subsectionID) => {
            if (Settings[subsectionID].childs) {
                const childs = Settings[subsectionID].childs;
                if (childs) {
                    let found = false;
                    Object.keys(childs).forEach((itemKey) => {
                        if (!found && checkPath(childs[itemKey].to, paths)) {
                            menu[itemKey] = true;
                            found = true;
                        }
                    });
                }
            } else {
                menu[subsectionID] = checkPath(Settings[subsectionID].to, paths);
            }
        });
    }

    return menu ? Object.keys(menu).filter((key) => menu[key]) : null;
};

export const usePermissions = () => {
    return {
        checkPath: (path, paths) => {
            return !!paths?.filter((v) => v === path || v === path + "/" || v === path + "/*")?.length;
        },
    };
};
