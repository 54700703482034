import React, { useState } from "react";

import { Header, Examples, getRandomIcon } from "sections/playground";

import Icon from "components/Icon";
import Select from "components/Select";

import Button from "components/Button";

export const TYPES = [
    {
        id: "SELECT",
        title: "Select",
        variants: ["SINGLE", "MULTIPLE"],
        examples: ["DEFAULT", "DETAILED", "GROUPS", "SEARCH", "ICON", "DISABLED", "ERROR", "WARNING", "COLORS"],
    },
    { id: "TESTS", title: "Tests", variants: ["Dynamic options"] },
];

const Dropdowns = ({ section }) => {
    return (
        <>
            <Header title="Dropdowns">
                Select gives the ability to make a single or multiple selections from different options.
            </Header>
            <Examples options={TYPES?.filter((s) => !section || s?.id === section)} display={DisplayExample} />
        </>
    );
};

const DisplayExample = ({ type, variant, example }) => {
    const id = `${type.id}-${variant}-${example}`;

    if (type.id === "TESTS") {
        return <TestsExample id={id} variant={variant} example={example} />;
    }

    return <SelectExample id={id} variant={variant} example={example} />;
};

const SelectExample = ({ id, variant, example }) => {
    const disabled = example === "DISABLED";
    const search = example === "SEARCH" ? true : undefined;
    const multiple = variant === "MULTIPLE";
    const detailed = example === "DETAILED";
    const error = example === "ERROR";
    const warning = example === "WARNING" ? "This field is important" : "";
    const customColor = example === "COLORS";

    const options = (() => {
        if (example === "GROUPS" || example === "SEARCH") {
            return groupOptions();
        }
        if (example === "ICON") {
            return iconOptions();
        }
        return singleOptions();
    })();

    const value = (detailed || customColor) && multiple ? [1, 2, 3, 4] : null;

    return (
        <Select
            id={id}
            search={search}
            disabled={disabled}
            multiple={multiple}
            showSelectedItems={customColor || detailed}
            options={options}
            value={value}
            onChange={(value) => console.log("Selected value: ", value)}
            error={error}
            warning={warning}
            labelColor={customColor ? "bg-pink-500" : undefined}
        />
    );
};

const TestsExample = ({ id, variant, example }) => {
    const [testOptions, setTestOptions] = useState(null);
    return (
        <div>
            <div className="font-bold pb-5">{variant}</div>
            <div className="flex space-x-3">
                <Button
                    id={id}
                    design={testOptions ? "red" : "blue-outline"}
                    onClick={() => {
                        setTestOptions(
                            testOptions
                                ? null
                                : [
                                      {
                                          label: "Estadísticas de Cast",
                                          value: "statsCast",
                                      },
                                      {
                                          label: "estadísticas de WiFi",
                                          value: "statsWifi",
                                      },
                                      {
                                          label: "estadísticas de Mobile",
                                          value: "statsMobile",
                                      },
                                      {
                                          label: "estadísticas de ventas",
                                          value: "statsSales",
                                      },
                                  ]
                        );
                    }}
                >
                    {testOptions ? "Clear options" : "Fill options"}
                </Button>
                <Select
                    id={"dynamic-test"}
                    multiple={true}
                    value={testOptions ? ["statsCast"] : ["fakeValue"]}
                    options={testOptions}
                    onChange={(value) => console.log("TEST ON CHANGE value: ", value)}
                />
            </div>
        </div>
    );
};

const singleOptions = () =>
    new Array(5).fill(0).map((_, i) => ({
        label: `Option ${i + 1}`,
        value: i + 1,
    }));

const iconOptions = () =>
    new Array(2).fill(0).map((_, i) => ({
        label: `Header ${i + 1}`,
        options: new Array(3).fill(0).map((_, j) => ({
            id: `group-${i + 1}-option-${j + 1}`,
            label: (
                <div className="inline-block" alt={`Option ${i + 1}${j + 1}`}>
                    <div className="flex items-center space-x-2">
                        <Icon type={getRandomIcon()} />
                        <div>{`Option ${i + 1}${j + 1}`}</div>
                    </div>
                </div>
            ),
            value: `${i + 1}${j + 1}`,
        })),
    }));

const groupOptions = () =>
    new Array(5).fill(0).map((_, i) => ({
        label: `Header ${i + 1}`,
        options: new Array(3).fill(0).map((_, j) => ({
            id: `group-${i + 1}-option-${j + 1}`,
            label: `Option ${i + 1}${j + 1}`,
            value: `${i + 1}${j + 1}`,
        })),
    }));

export default Dropdowns;
