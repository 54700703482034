import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate as useHistory } from "react-router-dom";
import { toast } from "react-toastify";

//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    cleanTableStore,
    setAvailableFilters,
    setCountPosition,
    setError,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    cleanActiveFilters,
    setCustomError,
    setLoadingMoreResults,
    setCustomConfig,
    setRefreshData,
    setCount,
} from "../../../actions/tableActions";

import GetAccountUsersTableHeaders from "../../Utils/Table/Headers/GetAccountUsersTableHeaders";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import { useTranslation } from "react-i18next";
import AccountUser from "./models/AccountUser";

import _ from "lodash";

import { useAuth } from "hooks/Session/auth";

const ListAccountsUsers = () => {
    const history = useHistory();

    const { impersonate } = useAuth();
    const { t } = useTranslation();
    const { filterQuery, refreshData, activeFilters, sortCriteria } = useSelector((state) => state.table);

    //Data
    const sectionName = "list-account-users";
    const RESULTS_INCREMENT = 10;

    //States
    const [resultsSize, setResultsSize] = useState(RESULTS_INCREMENT);
    const [applyFilters, setApplyFilters] = useState("");

    //Variables

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_USERS_INFO = gql`
        {
            users (size: ${resultsSize} ${sortCriteria} ${applyFilters}) {
                info {count}
                results {
                    fullName
                    email
                    chainID
                    id
                    ref
                    hasCorporate
                    brands {
                        id
                        name
                    }
                    properties {
                        id
                        name
                        chainID
                    }
                }
            }
            chains {
                results {
                    name
                    id
                    ref
                }
            }
        }
    `;

    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_USERS_INFO);

    //Listeners
    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(cleanTableStore());
        executeQuery();
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    const startImpersonation = (data) => {
        impersonate({
            ref: data?.ref,
            email: data?.email,
            onSuccess: () => {
                history("/");
            },
            onError: (err) => {
                if (err?.message?.match(/any projects for this user/)) {
                    toast.error(t("There are no projects for this user"));
                } else {
                    toast.error(t("mutation-error"));
                }
            },
        });
    };

    useEffect(() => {
        if (!loading && !error && data && data.users) {
            //Get data
            const dataItems = arrangeData(data, data.chains.results, startImpersonation);
            const tableHeaders = GetAccountUsersTableHeaders(sectionName);
            const availableFilters = GetAvailableFilters(
                sectionName,
                extractAccountList(data.chains.results),
                extractPropertiesList(data.users.results)
            );
            //Add to store
            dispatch(setSearchable(true));
            dispatch(setHeaders(tableHeaders));

            dispatch(setAvailableFilters(availableFilters));

            dispatch(
                setCustomConfig({
                    pagination: false,
                    tableContainerAdjust: "min-h-0 max-h-80",
                    rowsContainerAdjust: "overflow-scroll max-h-60",
                    onScrollCallback: function (e) {
                        if (
                            e.target.scrollTop + e.target.offsetHeight > e.target.scrollHeight &&
                            resultsSize < data.users.info.count
                        ) {
                            setResultsSize(resultsSize + RESULTS_INCREMENT);
                            if (!loading) {
                                dispatch(setLoadingMoreResults(true));
                            }
                        }
                    },
                })
            );

            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.users.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(showRowForm(false));
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            dispatch(setLoading(false));
            dispatch(setCustomError(t("no users available")));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (!loading) {
            dispatch(setLoadingMoreResults(false));
        }
        // eslint-disable-next-line
    }, [loading]);

    useEffect(() => {
        dispatch(setError(error));
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        let currentFilters = arrangeFilters();
        setApplyFilters(`, filter:{${currentFilters}}`);
        // eslint-disable-next-line
    }, [filterQuery]);

    const arrangeFilters = () => {
        let projectsData = "";
        let accountsData = "";
        let othersFilters = activeFilters;
        let currentFilters = "";

        if (_.get(othersFilters, "property")) {
            projectsData = _.get(othersFilters, "property");
            delete othersFilters["property"];
        }

        if (_.get(othersFilters, "account")) {
            accountsData = _.get(othersFilters, "account");
            delete othersFilters["account"];
        }

        if (!_.isEmpty(othersFilters)) {
            for (const [key, value] of Object.entries(othersFilters)) {
                if (value) {
                    if (value.charAt(0) === "[" || value === "true" || value === "false") {
                        currentFilters += `${key}:${value},`;
                    } else {
                        currentFilters += `${key}:"${value}",`;
                    }
                }
            }
        }
        currentFilters += `property:{${projectsData ? `,properties:${projectsData}` : ``} ${
            accountsData ? `,chains:${accountsData}` : ``
        }}`;
        return currentFilters;
    };
};

const arrangeData = (data, chains, startImpersonation) => {
    const userData = [];
    data.users.results.forEach((user) => {
        userData.push(AccountUser(user, chains, startImpersonation));
    });
    return userData;
};

const extractPropertiesList = (users) => {
    let propertiesArray = [{}];

    users.map((user) =>
        // eslint-disable-next-line
        user.properties.map((project) => {
            if (
                project.id &&
                project.id > 0 &&
                propertiesArray.filter((property) => property.id === project.id).length === 0
            ) {
                propertiesArray.push({
                    value: project.name,
                    id: project.id,
                    visible: true,
                });
            }
        })
    );

    return propertiesArray;
};

const extractAccountList = (chains) => {
    let accountsArray = [];
    // eslint-disable-next-line
    chains.map((chain) => {
        if (chain.id && chain.id > 0 && accountsArray.filter((iChain) => iChain.id === chain.id).length === 0) {
            accountsArray.push({
                value: chain.name,
                id: chain.id,
                visible: true,
            });
        }
    });

    return accountsArray;
};

export default ListAccountsUsers;
