import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import Table from "components/ZafiroTable";
import Loading from "components/Loading";
import { useAvailabilitySignage } from "../graphql/useSignages";

const ModalChangeAvailability = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { changeAvailability } = useAvailabilitySignage({
        available: props.available,
        onCompleted: () => {
            setLoading(false);
            props.getSignages();
            props.close();
        },
    });

    const handleSaveClick = () => {
        setLoading(true);
        changeAvailability({ variables: { ids: props.batch ? props.batchList.map((val) => val.id) : [props.id] } });
    };

    const handleCancelClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
        if (typeof props?.onCancel === "function") {
            props.onCancel();
        }
    };
    const title = props.available ? t("set-as-unavailable") : t("set-as-available");
    return (
        <Modal
            title={props.batch ? title : `${props.name} - ${title}`}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCancelClick}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        id="modal-button-change-availability"
                        disabled={loading}
                        onClick={handleSaveClick}
                    >
                        {props.available ? t("set-as-unavailable") : t("set-as-available")}
                    </Button>
                </>
            }
            className={"w-5/12 p-10"}
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <div>
                    {props.available ? (
                        <>
                            <p>
                                {props.batch
                                    ? t("if-you-set-the-following-sequences-as-unavailable")
                                    : t("if-you-set-this-sequence-as-unavailable")}
                            </p>
                            <p>{t("the-sequence-will-stop-being-displayed")}</p>
                        </>
                    ) : (
                        <p>
                            {props.batch
                                ? t("if-you-set-the-following-sequences-as-available")
                                : t("if-you-set-this-sequence-as-available")}
                        </p>
                    )}
                    {props.batch && (
                        <Table
                            id={"signage-duplicate-batch-table"}
                            cols={["name"]}
                            className={" border border-gray-100 p-2 max-h"}
                            maxHeight={"max-h-30"}
                            header={{
                                name: { title: "" },
                            }}
                            rows={props.batchList}
                        ></Table>
                    )}
                </div>
            )}
        </Modal>
    );
};

export const UseModalChangeAvailability = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalChangeAvailability {...newProps} />);
        },
    };
};
