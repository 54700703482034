import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UseRadio from "../useRadio";
import UseSelectWithSearch from "../useSelectWithSearch";
import UseInputText from "../Inputs/useInputText";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import orderBy from "lodash/orderBy";
import { Session } from "../../hooks/Utils/Session";
import { useDispatch, useSelector } from "react-redux";
import { changeActionValues } from "../../actions/globalActions";

import { useAuth } from "hooks/Session/auth";

const UseModalAddCorporateChannel = () => {
    const { t } = useTranslation();

    const { lang, hasChainModule } = useAuth();

    const dispatch = useDispatch();
    const [optionSelected, setOptionSelected] = useState(hasChainModule ? "create-global" : "create-content");

    const [globalContentData, setGlobalContentData] = useState([]);
    const { validations } = useSelector((state) => state.action);

    const imgSrcRegex = Session.getDasUrl("{{libraryRef}}?lang=" + lang + "&w=70&h=70");

    const GET_GLOBAL_CONTENT = gql`
        query {
            corporateTvChannels(filter: { onlyUnusedChannels: true }) {
                id
                logoRef
                name
            }
        }
    `;

    const [executeQuery, { data }] = useLazyQuery(GET_GLOBAL_CONTENT, { fetchPolicy: "network-only" });

    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (data?.corporateTvChannels) {
            const arrangedData = arrangeGlobalContent(data.corporateTvChannels);
            setGlobalContentData(arrangedData);
        }
    }, [data]);

    useEffect(() => {
        dispatch(changeActionValues({ ["create-corp-channel-opt"]: optionSelected, ["create-corp-channel-name"]: "" }));
    }, [optionSelected]);

    const arrangeGlobalContent = (data) => {
        const newData = [];

        data.forEach((content) => {
            newData.push({
                id: content.id,
                name: content.name,
                imgSrc: imgSrcRegex.replace("{{libraryRef}}", content.logoRef),
                iconError: "icon-channel",
            });
        });

        return orderBy(newData, ["name"], ["asc"]);
    };

    return (
        <div>
            <div>
                {hasChainModule ? (
                    <>
                        <div className=" mb-4">
                            <UseRadio
                                handleClick={() => {
                                    setOptionSelected("create-global");
                                }}
                                text={"global-management-content"}
                                value={"create-global"}
                                selected={optionSelected}
                            />
                            {optionSelected === "create-global" ? (
                                <div className=" mt-4 mb-6 ml-10">
                                    <UseSelectWithSearch
                                        key={globalContentData}
                                        data={{
                                            disabled: false,
                                            id: "select-global-corporate-channel",
                                            name: "select-global-corporate-channel",
                                            optionData: globalContentData,
                                            selectPlaceHolder: t("select-corporate-channel"),
                                            noSelect: true,
                                            oneSelected: true,
                                            optionsAdjust: "mt-11",
                                        }}
                                    />
                                </div>
                            ) : null}
                        </div>

                        <div>
                            <UseRadio
                                handleClick={() => {
                                    setOptionSelected("create-content");
                                }}
                                text={"create-content"}
                                value={"create-content"}
                                selected={optionSelected}
                            />
                            {optionSelected === "create-content" ? (
                                <div className=" mt-2 ml-10">
                                    <span className=" text-sm font-bold text-gray-800 ">{t("name")}</span>
                                    <UseInputText
                                        ph={t("name")}
                                        name={"create-corp-channel-name"}
                                        id={"create-corp-channel-name"}
                                        adjust={
                                            validations["create-corp-channel-name"] === false
                                                ? "border border-red-100"
                                                : "border border-transparent"
                                        }
                                        changeAction={(val) => {
                                            dispatch(changeActionValues({ ["create-corp-channel-name"]: val }));
                                        }}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </>
                ) : (
                    <UseInputText
                        ph={t("name")}
                        name={"create-corp-channel-name"}
                        id={"create-corp-channel-name"}
                        adjust={
                            validations["create-corp-channel-name"] === false
                                ? "border border-red-100"
                                : "border border-transparent"
                        }
                        changeAction={(val) => {
                            dispatch(changeActionValues({ ["create-corp-channel-name"]: val }));
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default UseModalAddCorporateChannel;
