import React, { useEffect, useState } from "react";
import { capitalizeFirst, getFromDAS } from "../../../../../hooks/Utils/Utils";
import UseCheckBox from "../../../../Inputs/useCheckBox";
import UseInputText from "../../../../Table/useInputText";
import UseRadio from "../../../../useRadio";
import { useTranslation } from "react-i18next";
import SelectPresetChannels from "./SelectPresetChannels";
import UseSelectWithSearch from "../../../../useSelectWithSearch";
import { GetCountriesOptions } from "../../../../../hooks/Utils/CountriesUtils";
import { GetLanguageOptions } from "../../../../../hooks/Utils/LanguagesUtils";
import ReactTooltip from "components/ReactTooltip";
import ImageChannelSelector from "./ImageChannelSelector";

const ContainerBulkChannelCard = ({
    idCard,
    number,
    onDelete,
    disabledDelete,
    channelType,
    setChannelsInfo,
    channelsInfo,
    validations,
    validationsErrorFields,
}) => {
    const { t } = useTranslation();

    return (
        <div id={`card-container-${channelType}-${number + 1}`} className=" border px-8 rounded border-gray-200 mb-2">
            <div className=" border-b border-gray-200 py-2 flex justify-between ">
                <span id={`number-card-${channelType}-${number + 1}`} className=" block text-center font-bold text-lg ">
                    {`${capitalizeFirst(t("channel"))} ${number + 1}`}
                </span>
                <button
                    disabled={disabledDelete}
                    onClick={onDelete}
                    className={`disabled focus:outline-none icon icon-delete text-1xl ${
                        disabledDelete ? " text-gray-400 cursor-not-allowed" : "text-gray-800 "
                    } `}
                    id={`delete-button-${channelType}-${number + 1}`}
                ></button>
            </div>
            {channelType === "preset-channel" ? (
                <PresetChannelCard
                    number={number + 1}
                    setChannelsInfo={setChannelsInfo}
                    channelsInfo={channelsInfo}
                    idCard={idCard}
                    validations={validations}
                    validationsErrorFields={validationsErrorFields}
                />
            ) : (
                <CustomisedTuneChannel
                    number={number + 1}
                    setChannelsInfo={setChannelsInfo}
                    channelsInfo={channelsInfo}
                    idCard={idCard}
                    validations={validations}
                    validationsErrorFields={validationsErrorFields}
                />
            )}
        </div>
    );
};

const PresetChannelCard = ({ setChannelsInfo, channelsInfo, idCard, number, validations, validationsErrorFields }) => {
    const { t } = useTranslation();
    const [infoChannel, setInfoChannel] = useState(null);
    const [srcImage, setSrcImage] = useState(null);
    const [dataChannel, setDataChannel] = useState(initialObjPresetChannel(idCard));

    const countryChannel = infoChannel?.countryRef ? t("country:" + infoChannel.countryRef.toLowerCase()) : "";
    const languageChannel = infoChannel?.languageRef ? t("language:" + infoChannel.languageRef.toLowerCase()) : "";
    let typeChannel = "";
    if (infoChannel) {
        if (!infoChannel.isRadio) {
            typeChannel = t("tv");
        } else {
            typeChannel = t("radio");
        }
    }

    useEffect(() => {
        if (infoChannel) {
            setDataChannel({
                ...dataChannel,
                idChannel: infoChannel?.id ? infoChannel.id : "",
                logoRef: infoChannel?.logoRef ? infoChannel.logoRef : "",
            });
        }
        if (infoChannel?.logoRef) {
            setSrcImage(infoChannel.logoRef);
        }
        // eslint-disable-next-line
    }, [infoChannel]);

    useEffect(() => {
        const modifiedChannels = channelsInfo.map((c) => {
            if (c.id === idCard) {
                return {
                    ...c,
                    idChannel: dataChannel?.idChannel ? dataChannel.idChannel : "",
                    hls: dataChannel?.hls ? dataChannel.hls : "",
                    ip: dataChannel?.ip ? dataChannel.ip : "",
                    port: dataChannel?.port ? dataChannel.port : "",
                    udpCB: dataChannel?.udpCB ? true : false,
                    hlsCB: dataChannel?.hlsCB ? true : false,
                    isNotValidIP: dataChannel.isNotValidIP ? true : false,
                    isNotValidPort: dataChannel.isNotValidPort ? true : false,
                    isNotValidURL: dataChannel.isNotValidURL ? true : false,
                };
            } else {
                return {
                    ...c,
                };
            }
        });
        setChannelsInfo(modifiedChannels);
        // eslint-disable-next-line
    }, [dataChannel]);

    return (
        <div className=" flex py-2 ">
            <div className=" pr-8 border-r border-gray-200">
                <div>
                    <span className=" font-bold block mb-4">{t("preset-section")}</span>
                </div>
                <div className=" mb-6" style={{ width: "23.7rem" }}>
                    <span className=" block text-gray-800 text-sm mt-2 font-bold mb-2 ">{`${t(
                        "select-preset-channel"
                    )} *`}</span>
                    <div>
                        <SelectPresetChannels
                            // fixedOptions={true}
                            id={number}
                            idParentContainer={"bulk-channels-container"}
                            onSelect={setInfoChannel}
                            channelsInfo={channelsInfo}
                            validation={!validations[idCard] || validations[idCard].idChannel}
                        />
                    </div>
                </div>
                <div className=" flex">
                    <div
                        id={`preset-channel-${number}-image-preview-container`}
                        style={{ width: "9.5rem", height: "7.4rem" }}
                        className=" bg-gray-900 rounded mr-6 flex items-center justify-center "
                    >
                        {!srcImage ? (
                            <i className=" icon icon-channel text-gray-800 text-5xl "></i>
                        ) : (
                            <img
                                alt="something"
                                className=" h-full  object-scale-down w-full "
                                onError={() => {
                                    setSrcImage(null);
                                }}
                                src={getFromDAS(srcImage)}
                            />
                        )}
                    </div>
                    <div className=" mt-2 flex">
                        <div className=" mr-2">
                            <div className=" flex font-bold mb-2">
                                <span id={`preset-channel-${number}-country-label`}>{`${capitalizeFirst(
                                    t("country")
                                )}:`}</span>
                            </div>
                            <div className=" flex font-bold mb-2">
                                <span id={`preset-channel-${number}-language-label`}>{`${capitalizeFirst(
                                    t("language")
                                )}:`}</span>
                            </div>
                            <div className=" flex font-bold mb-2">
                                <span id={`preset-channel-${number}-type-label`}>{`${capitalizeFirst(
                                    t("type")
                                )}:`}</span>
                            </div>
                        </div>
                        <div>
                            <div className=" flex mb-2">
                                <span
                                    id={`preset-channel-${number}-country-value`}
                                    className={`${countryChannel ? " opacity-1" : "opacity-0"}`}
                                >
                                    {countryChannel ? countryChannel : "t"}
                                </span>
                            </div>
                            <div className=" flex mb-2">
                                <span
                                    id={`preset-channel-${number}-language-value`}
                                    className={`${languageChannel ? " opacity-1" : "opacity-0"}`}
                                >
                                    {languageChannel ? languageChannel : "t"}
                                </span>
                            </div>
                            <div className=" flex mb-2">
                                <span
                                    id={`preset-channel-${number}-type-value`}
                                    className={`${typeChannel ? " opacity-1" : "opacity-0"}`}
                                >
                                    {typeChannel ? typeChannel : "t"}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" pl-8">
                <TuningCard
                    idCard={idCard}
                    number={number}
                    prefixId="preset-channel"
                    dataChannel={dataChannel}
                    setDataChannel={setDataChannel}
                    validationsErrorFields={validationsErrorFields}
                />
            </div>
        </div>
    );
};

const CustomisedTuneChannel = ({
    idCard,
    setChannelsInfo,
    channelsInfo,
    number,
    validations,
    validationsErrorFields,
}) => {
    const { t } = useTranslation();
    const [dataChannel, setDataChannel] = useState(initialObCustomise(idCard));

    useEffect(() => {
        const modifiedChannels = channelsInfo.map((c) => {
            if (c.id === idCard) {
                return {
                    ...c,
                    logoRef: dataChannel?.logoRef ? dataChannel.logoRef : "",
                    name: dataChannel?.name ? dataChannel.name : "",
                    countryRef: dataChannel?.countryRef ? dataChannel.countryRef : "",
                    languageRef: dataChannel?.languageRef ? dataChannel.languageRef : "",
                    isRadio: dataChannel?.isRadio ? true : false,
                    hls: dataChannel?.hls ? dataChannel.hls : "",
                    ip: dataChannel?.ip ? dataChannel.ip : "",
                    port: dataChannel?.port ? dataChannel.port : "",
                    udpCB: dataChannel?.udpCB ? true : false,
                    hlsCB: dataChannel?.hlsCB ? true : false,
                    isNotValidIP: dataChannel.isNotValidIP ? true : false,
                    isNotValidPort: dataChannel.isNotValidPort ? true : false,
                    isNotValidURL: dataChannel.isNotValidURL ? true : false,
                };
            } else {
                return {
                    ...c,
                };
            }
        });
        setChannelsInfo(modifiedChannels);
        // eslint-disable-next-line
    }, [dataChannel]);

    return (
        <div className=" flex mt-2 mb-4">
            <div className=" border-r pr-8 border-gray-200">
                <ImageChannelSelector
                    number={number}
                    dataComponent={dataChannel}
                    setDataComponent={setDataChannel}
                    idCard={idCard}
                />
            </div>
            <div className=" px-8 ">
                <div className=" flex">
                    <div className=" mr-10 ">
                        <div className=" flex items-center">
                            <span className=" font-bold text-lg mr-2">{t("informations")}</span>
                        </div>

                        <div className=" flex">
                            <div className=" mr-8 w-64 ">
                                <span className=" text-sm font-bold text-gray-800 ">
                                    {`${capitalizeFirst(t("name"))} *`}
                                </span>
                                <UseInputText
                                    id={`customised-channel-name-text-${number}`}
                                    onChangeAction={(val) => {
                                        setDataChannel({ ...dataChannel, name: val });
                                    }}
                                    adjust="h-10"
                                    ph={t("enter-name")}
                                    validation={!validations[idCard] || validations[idCard].name}
                                    inputData={{ name: "text" }}
                                />
                            </div>
                            <div className=" mr-8 w-52 ">
                                <span className=" text-sm font-bold text-gray-800 ">
                                    {`${capitalizeFirst(t("country"))} *`}
                                </span>
                                <div
                                    className={` border rounded  ${
                                        !validations[idCard] || validations[idCard].countryRef
                                            ? " border-transparent"
                                            : " border-red-100"
                                    } `}
                                >
                                    <UseSelectWithSearch
                                        data={{
                                            disabled: false,
                                            id: `customised-channel-select-country-${number}`,
                                            name: `customised-channel-select-country-${number}`,
                                            onChange: (ref) => {
                                                setDataChannel({ ...dataChannel, countryRef: ref });
                                            },
                                            optionsAdjust: "mt-11",
                                            optionData: GetCountriesOptions(),
                                            selectPlaceHolder: capitalizeFirst(t("country")),
                                            noSelect: true,
                                            oneSelected: true,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className=" w-52">
                                <span className=" text-sm font-bold text-gray-800 ">
                                    {`${capitalizeFirst(t("language"))} *`}
                                </span>
                                <div
                                    className={` border rounded  ${
                                        !validations[idCard] || validations[idCard].languageRef
                                            ? " border-transparent"
                                            : " border-red-100"
                                    } `}
                                >
                                    <UseSelectWithSearch
                                        data={{
                                            isabled: false,
                                            id: `customised-channel-select-language-${number}`,
                                            name: `customised-channel-select-language-${number}`,
                                            onChange: (ref) => {
                                                setDataChannel({ ...dataChannel, languageRef: ref });
                                            },
                                            optionsAdjust: "mt-11",
                                            optionData: GetLanguageOptions(),
                                            selectPlaceHolder: capitalizeFirst(t("language")),
                                            noSelect: true,
                                            oneSelected: true,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className=" flex items-center">
                            <span className=" font-bold text-lg mr-2 mb-2 block">{t("select-type")}</span>
                        </div>
                        <div>
                            <UseRadio
                                id={`customised-channel-type-radio-tv-${number}`}
                                value={false}
                                handleClick={() => {
                                    setDataChannel({ ...dataChannel, isRadio: false });
                                }}
                                selected={dataChannel.isRadio}
                                text={"tv"}
                                textAdjust={"mb-2"}
                            ></UseRadio>
                            <UseRadio
                                id={`customised-channel-type-radio-radio-${number}`}
                                value={true}
                                handleClick={() => {
                                    setDataChannel({ ...dataChannel, isRadio: true });
                                }}
                                selected={dataChannel.isRadio}
                                text={"radio"}
                            ></UseRadio>
                        </div>
                    </div>
                </div>
                <div className=" w-96 mt-4 ">
                    <TuningCard
                        idCard={idCard}
                        dataChannel={dataChannel}
                        setDataChannel={setDataChannel}
                        validationsErrorFields={validationsErrorFields}
                    />
                </div>
            </div>
            <ReactTooltip
                place="bottom"
                type="light"
                offset={{ top: -8, left: -8 }}
                html={true}
                border={true}
                borderColor="#D3DAE1"
            />
        </div>
    );
};
const TuningCard = ({
    dataChannel,
    setDataChannel,
    prefixId = "tuning-card",
    number = 0,
    validationsErrorFields,
    idCard,
}) => {
    const { t } = useTranslation();

    const { ip, port, hls } = validationsErrorFields?.[idCard] || {};

    const [checkBoxUDP, setcheckBoxUDP] = useState(false);
    const [checkBoxHLS, setCheckBoxHLS] = useState(false);

    useEffect(() => {
        setDataChannel({ ...dataChannel, udpCB: checkBoxUDP, hlsCB: checkBoxHLS });
        // eslint-disable-next-line
    }, [checkBoxUDP, checkBoxHLS]);

    return (
        <div className=" ">
            <div className="preset-section">
                <span className=" block font-bold mb-4">{t("set-tuning")}</span>
            </div>
            <div>
                <div>
                    <UseCheckBox
                        id={`${prefixId}-${number}-udp-cb`}
                        checked={checkBoxUDP}
                        onClick={() => {
                            setcheckBoxUDP((val) => !val);
                        }}
                        name="udp-multicast"
                        translatable={true}
                        useAllSpace={false}
                    />
                </div>
                <div className=" flex items-center mb-2 ml-8">
                    <div className=" flex flex-col w-full">
                        <div className=" flex">
                            <span className=" block w-24">{t("ip")} </span>
                            <UseInputText
                                id={`${prefixId}-${number}-ip-text`}
                                disabled={checkBoxUDP === false}
                                validation={ip === true ? false : true}
                                ph={"Ex. 000.000.00.0"}
                                inputData={{ name: "text" }}
                                onChangeAction={(val) => {
                                    setDataChannel({
                                        ...dataChannel,
                                        ip: val,
                                    });
                                }}
                            />
                        </div>
                        {ip === true ? (
                            <span className=" block ml-18 text-sm text-red-100">
                                {t("ip-range-available", { range: "224.0.0.0/4" })}
                            </span>
                        ) : null}
                    </div>
                </div>
                <div className=" flex items-center ml-8">
                    <span className=" block w-24">{t("port")} </span>
                    <input
                        type="number"
                        min="0"
                        id={`${prefixId}-${number}-port-text`}
                        disabled={checkBoxUDP === false}
                        className={`py-1 px-4 leading-7 ${checkBoxUDP === false ? "bg-gray-300" : "bg-gray-200"} ${
                            port === true ? "border border-red-100" : "border border-transparent"
                        } w-full rounded`}
                        placeholder="Ex. 1234"
                        onKeyPress={(e) => {
                            if (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") {
                                e.preventDefault();
                            }
                        }}
                        onPaste={(e) => {
                            const pasteValue = e.clipboardData.getData("Text");
                            if (isNaN(pasteValue) || pasteValue.includes("e") || pasteValue.includes("-")) {
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            setDataChannel({
                                ...dataChannel,
                                port: e.target.value,
                            });
                        }}
                    ></input>
                </div>
            </div>
            <div className=" mt-5 ">
                <div className="">
                    <UseCheckBox
                        id={`${prefixId}-${number}-hls-cb`}
                        checked={checkBoxHLS}
                        onClick={() => {
                            setCheckBoxHLS((val) => !val);
                        }}
                        name="hls"
                        translatable={true}
                        useAllSpace={false}
                    />
                </div>
                <div className=" flex items-center ml-8">
                    <div className=" flex flex-col w-full">
                        <div className=" flex">
                            <span className=" block w-24 ">{t("url")} </span>
                            <UseInputText
                                id={`${prefixId}-${number}-url-text`}
                                disabled={checkBoxHLS === false}
                                validation={hls === true ? false : true}
                                inputData={{ name: "text" }}
                                onChangeAction={(val) => {
                                    setDataChannel({ ...dataChannel, hls: val });
                                }}
                            />
                        </div>
                        {hls === true ? (
                            <span className=" block ml-18 text-sm text-red-100">{t("incorrect-url")}</span>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export { PresetChannelCard, CustomisedTuneChannel, TuningCard, ContainerBulkChannelCard };

const initialObCustomise = (idCard) => {
    return {
        logoRef: "",
        name: "",
        countryRef: "",
        languageRef: "",
        isRadio: false,
        hls: "",
        ip: "",
        port: "",
        udpCB: false,
        hlsCB: false,
        idCard: idCard,
        isNotValidIP: false,
        isNotValidPort: false,
        isNotValidURL: false,
    };
};

const initialObjPresetChannel = (idCard) => {
    return {
        hls: "",
        ip: "",
        port: "",
        udpCB: false,
        hlsCB: false,
        idChannel: "",
        logoRef: "",
        idCard: idCard,
        isNotValidIP: false,
        isNotValidPort: false,
        isNotValidURL: false,
    };
};
