import { newRoute, newRedirect } from "routes/utils";

import ListMobileAppsComponent from "components/Section/Customers/allProperties/ListMobileApps/ListMobileAppsComponent";
import Properties from "components/Section/Settings/Properties";
import AssociatedProperties from "components/Section/Customers/allProperties/AssociatedProperties.js/AssociatedProperties";
import PropertyGroups from "components/Section/Settings/PropertyGroups";
import MobileSettings from "components/Section/Hotel/MobileSettings";

const sectionID = "all-properties";

const route = newRoute(sectionID);
const redirect = newRedirect(sectionID);

const checkAllProperties = ({ permissions, isCorporate }) => isCorporate && permissions?.allProperties;
const checkMobile = ({ permissions }) => permissions?.allProperties?.mobileConfig;
const checkProperties = ({ permissions }) => permissions?.allProperties?.manageProperties;
const checkPropertyGroups = ({ permissions }) => permissions?.allProperties?.manageBrands;

const routes = [
    route("/", { initial: true, redirect: redirect("/property-management"), check: checkAllProperties }),

    // Property management
    route("/property-management", { redirect: redirect("/property-management/properties") }),
    // Property list
    route("/property-management/properties", { section: Properties, check: checkProperties }),
    // Property groups
    route("/property-management/propertyGroup", { check: checkPropertyGroups, section: PropertyGroups }),

    // Mobile Apps
    route("/mobile-apps", { section: ListMobileAppsComponent, check: checkMobile }),
    route("/mobile-apps/:id/associated-properties", { section: AssociatedProperties, check: checkMobile }),
    route("/mobile-apps/:id", { section: MobileSettings, check: checkMobile }),
];

export default routes;
