export const cleanUnnecessaryChars = (data) => {
    //Returns a string from given timetables API response attr without unnecessary chars
    if (data) {
        let clearTempString = data.split("}").join("");
        clearTempString = clearTempString.split("{").join("");
        clearTempString = clearTempString.split("[").join("");
        clearTempString = clearTempString.split("]").join("");
        return clearTempString;
    } else {
        return "";
    }
};

export default cleanUnnecessaryChars;
