import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setModalContent } from "actions/uiActions";
import { openModal } from "actions/uiActions";
import HighAvailabilityTexts from "hooks/GraphqlCalls/Services/modals/HighAvailabilityTexts";

function ChannelsGeneralSettingsHighAvailability() {
    //Consts&states
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const openModalHighAvailabilityTranslations = () => {
        const modal = HighAvailabilityTexts();
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };
    //renders
    return (
        <div className="w-full">
            <div className="w-full flex items-center justify-between mb-5">
                <span className="font-bold text-gray-900 text-2xl">{t("high-availability")}</span>
                <span
                    id="ha-edit"
                    className="icon-edit text-2xl text-gray-800 cursor-pointer hover:text-zafiro-400"
                    onClick={() => openModalHighAvailabilityTranslations()}
                ></span>
            </div>
            <div className="w-full text-left mb-10">
                <span className="block">{t("high-availability-text-1")}</span>
                <span className="block mt-4">{t("high-availability-text-2")}</span>
            </div>
        </div>
    );
}

export default ChannelsGeneralSettingsHighAvailability;
