import i18next from "i18next";

const DeletePredefinedChannel = (name, id) => {
    const inputs = [];

    inputs.push(
        {
            text: i18next.t("delete-predefined-channel-confirm", { name: name }),
            type: "void",
            name: "1",
        },
        {
            text: i18next.t("delete-predefined-channel-warning"),
            type: "void",
            name: "2",
        },
        {
            name: "predefined-channel-id",
            type: "text",
            value: id,
            style: "hidden",
        }
    );
    return {
        title: "delete",
        value: name,
        executeSectionQuery: true,
        inputs: inputs,
        bodyAdjust: "w-4/12",
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "delete",
                style: "red",
                action: "delete-predefined-channel",
            },
        ],
    };
};

export default DeletePredefinedChannel;
