import React from "react";
import { useDispatch, useSelector } from "react-redux";
//Actions
import { setSpecialScheduleValue, setLastScheduleEdition } from "../../actions/scheduleActions";

const UseInputTimeSpecialRange = ({ dateId, validation, index }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    //States
    //Actions
    const dispatch = useDispatch();

    const changeScheduleHandler = (e) => {
        dispatch(
            setSpecialScheduleValue({
                valueKey: dateId,
                value: e.target.value,
                index: index,
            })
        );
        dispatch(setLastScheduleEdition());
    };
    return (
        <>
            <input
                type="time"
                className={`t-filter-search w-1/5 mx-0 my-0 ${!validation ? "border border-red-100" : ""}`}
                placeholder="hh:mm"
                onBlur={changeScheduleHandler}
            ></input>
            {!validation ? (
                <p className="absolute text-red-100 first-capital">{langStrings["schedule-error"]}</p>
            ) : null}
        </>
    );
};

export default UseInputTimeSpecialRange;
