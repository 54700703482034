import Button from "components/Button";
import Icon from "components/Icon";
import Radiobuttons from "components/Inputs/Radiobuttons";
import Loading from "components/Loading";
import { useModal } from "components/Modal";
import Select from "components/Select";
import TextInput from "components/TextInput";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberInput from "components/NumberInput";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { Duration } from "./Duration";
import { ProfileInformation } from "./ProfileInformation";
import { useNewPassCode } from "hooks/GraphqlCalls/Customers/useNewPassCode";

export const AddNewPassCode = ({ refetch }) => {
    const [bandwidth, setBandwidth] = useState({});
    const [limitOfDevices, setLimitOfDevices] = useState(true);
    const [durationCustomized, setDurationCustomized] = useState("customized");
    const [accessProfileId, setAccessProfileId] = useState();
    const [limitOfDevicesValue, setLimitOfDevicesValue] = useState("1");
    const [durationHours, setDurationHours] = useState("0");
    const [durationDays, setDurationDays] = useState("1");
    const [renovationDays, setRenovationDays] = useState("1");
    const [renovationHours, setRenovationHours] = useState("0");
    const [code, setCode] = useState();

    const { t } = useTranslation();
    const { close } = useModal();
    const { dataPasscode, refreshPasscode, loading, dataAccessProfiles, createPasscode } = useNewPassCode({ refetch });

    const handleSelect = (e) => {
        const selectedProfile = dataAccessProfiles?.find((profile) => profile.id === e);
        setAccessProfileId(e);
        setBandwidth({
            min: {
                download: selectedProfile?.downloadbwmin,
                upload: selectedProfile?.uploadbwmin,
            },
            max: {
                download: selectedProfile?.downloadbwmax,
                upload: selectedProfile?.uploadbwmax,
            },
        });
    };

    useEffect(() => {
        if (dataAccessProfiles) {
            setBandwidth({
                min: {
                    download: dataAccessProfiles[0].downloadbwmin,
                    upload: dataAccessProfiles[0].uploadbwmin,
                },
                max: {
                    download: dataAccessProfiles[0].downloadbwmax,
                    upload: dataAccessProfiles[0].uploadbwmax,
                },
            });
        }
    }, [dataAccessProfiles]);

    useEffect(() => {
        if (dataAccessProfiles && dataPasscode) {
            setCode(dataPasscode);
            setAccessProfileId(dataAccessProfiles[0].id);
        }
    }, [dataAccessProfiles, dataPasscode]);

    return !loading ? (
        <>
            <main className="flex flex-col mt-8">
                <label className="font-bold">{capitalizeFirst(t("passcode"))}</label>
                <div className="flex gap-2 items-center w-3/7">
                    <TextInput id="passcode" value={dataPasscode} onChange={(value) => setCode(value)} />
                    <Button id="generate-passcode" onClick={() => refreshPasscode()}>
                        <Icon type="refresh" size="3xl" className="text-zafiro-600" />
                    </Button>
                </div>

                <div className="mt-8 w-2/5">
                    <label className="font-bold ">{t("access-profile")}</label>
                    <Select
                        id="access-profile"
                        value={dataAccessProfiles?.[0]?.id}
                        options={dataAccessProfiles?.map((profile) => ({
                            id: profile.id,
                            value: profile.id,
                            label: profile.name,
                        }))}
                        onChange={handleSelect}
                        allowUnselect={false}
                    />
                </div>

                <div className="mt-4 w-full">
                    <h4>{t("access-profile-information")}:</h4>
                    <ProfileInformation
                        text={t("min-bandwidth")}
                        upload={bandwidth?.min?.upload}
                        download={bandwidth?.min?.download}
                    />
                    <ProfileInformation
                        text={t("max-bandwidth")}
                        upload={bandwidth?.max?.upload}
                        download={bandwidth?.max?.download}
                    />
                </div>

                <div className="mt-8 w-2/5">
                    <label className="font-bold">{capitalizeFirst(t("limit-of-devices"))}</label>
                    <Radiobuttons
                        className={"mt-2"}
                        options={[
                            {
                                id: "unlimited",
                                value: "unlimited",
                                label: capitalizeFirst(t("unlimited")),
                            },
                            {
                                id: "customized",
                                value: "customized",
                                label: capitalizeFirst(t("customized")),
                            },
                        ]}
                        checked={"customized"}
                        onChange={() => setLimitOfDevices(!limitOfDevices)}
                    />
                    {limitOfDevices && (
                        <NumberInput
                            id="limit-of-devices"
                            type="number"
                            value="1"
                            className="mt-2 ml-6"
                            onChange={(value) => setLimitOfDevicesValue(value)}
                        />
                    )}
                </div>

                <div className="mt-8">
                    <label className="font-bold">{capitalizeFirst(t("duration"))}</label>
                    <Radiobuttons
                        className={"mt-2"}
                        options={[
                            {
                                id: "unlimited",
                                value: "unlimited",
                                label: capitalizeFirst(t("unlimited")),
                            },
                            {
                                id: "customized",
                                value: "customized",
                                label: capitalizeFirst(t("customised-the-code-expire")),
                            },
                        ]}
                        checked={durationCustomized ? "customized" : "unlimited"}
                        onChange={(value) => setDurationCustomized(value)}
                    />
                    {durationCustomized === "customized" && (
                        <Duration
                            tooltipText={t("code-will-expire-elapsed-first-use")}
                            text={t("set-expiration-tim")}
                            durationDays={setDurationDays}
                            durationHours={setDurationHours}
                        />
                    )}
                    {durationCustomized === "unlimited" && (
                        <Duration
                            tooltipText={t("user-re-enter-passcode")}
                            text={t("set-connection-time")}
                            durationDays={setRenovationDays}
                            durationHours={setRenovationHours}
                        />
                    )}
                </div>
            </main>
            <div className="flex justify-end gap-4 mt-8">
                <Button id="cancel" onClick={close} className="btn btn-white">
                    {t("cancel")}
                </Button>
                <Button
                    id="save"
                    className="btn btn-blue"
                    onClick={() => {
                        close();
                        refetch();
                        createPasscode({
                            code,
                            accessProfileId,
                            ...(limitOfDevices && { limitOfDevicesValue }),
                            ...(durationCustomized === "customized" && { durationHours }),
                            ...(durationCustomized === "customized" && { durationDays }),
                            ...(durationCustomized !== "customized" && { renovationDays }),
                            ...(durationCustomized !== "customized" && { renovationHours }),
                        });
                    }}
                >
                    {t("save")}
                </Button>
            </div>
        </>
    ) : (
        <Loading adjust="section-loading" style={{ height: "50vh" }} />
    );
};
