import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const UseTagFilterCheckbox = ({ name, filter, filterText, translatable }) => {
    const { t } = useTranslation();

    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { checkboxDropdownNames } = useSelector((state) => state.ui);
    const activeFilters = useSelector((state) => state.table.activeFilters);

    //States
    const [isActive, setIsActive] = useState(false);
    const [checkboxActive, setCheckboxActive] = useState({
        checked: false,
        visible: "hidden",
        background: "block",
        text: "",
    });

    //Actions
    const clickHandler = (e) => {
        if (checkboxActive.checked) {
            setIsActive(false);
        } else {
            setIsActive(true);
        }
    };

    //Listeners
    useEffect(() => {
        if (isActive) {
            setCheckboxActive({
                checked: true,
                visible: "block",
                background: "hidden",
                text: "text-blue-300",
            });
        } else {
            setCheckboxActive({
                checked: false,
                visible: "hidden",
                background: "block",
                text: "",
            });
        }
    }, [isActive]);

    useEffect(() => {
        if (
            checkboxDropdownNames &&
            checkboxDropdownNames[name] &&
            checkboxDropdownNames[name].length > 0 &&
            (checkboxDropdownNames[name].includes(String(filter)) ||
                checkboxDropdownNames[name].includes(String(t(filter))))
        ) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
        // eslint-disable-next-line
    }, [checkboxDropdownNames]);

    useEffect(() => {
        //Clean filters
        if (activeFilters && !activeFilters[name]) {
            setIsActive(false);
        }
        // eslint-disable-next-line
    }, [activeFilters]);

    const getTranslations = () => {
        let _translation = "";
        if (Array.isArray(filterText)) {
            filterText.forEach((_word) => (_translation += langStrings[_word] ? langStrings[_word] : _word));
        } else _translation = langStrings[filterText] ? langStrings[filterText] : filterText;
        return _translation;
    };
    return (
        <div className="w-full">
            <div className="block relative mb-4 cursor-pointer" onClick={clickHandler}>
                <div className={`ml-8 hover:text-zafiro-400 first-capital ${checkboxActive.text}`}>
                    {translatable ? getTranslations() : filterText}
                </div>
                <input
                    id={`filter-${filter}`}
                    type="checkbox"
                    className={`absolute top-0 left-0 w-5 h-5 checkbox cursor-pointer z-50 ${checkboxActive.visible}`}
                    checked={checkboxActive.checked}
                    onChange={(e) => {}}
                ></input>
                <span
                    id={`${filter}-checkbox-background`}
                    className={`absolute top-0 left-0 w-5 h-5 bg-gray-200 hover:bg-gray-300 ${checkboxActive.background}`}
                ></span>
            </div>
        </div>
    );
};

export default UseTagFilterCheckbox;
