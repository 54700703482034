import i18next from "i18next";

const RoomMoviesModal = (data) => {
    return {
        title: `${i18next.t("room")} ${data?.room?.number ? data.room.number : 0} `,
        value: i18next.t("show-rented-movies"),
        bodyAdjust: "w-full",
        executeSectionQuery: true,
        inputs: [
            {
                type: "roomMoviesModal",
            },
        ],
        buttons: [
            {
                name: "close",
                style: "blue",
                action: "close",
            },
        ],
    };
};

export default RoomMoviesModal;
