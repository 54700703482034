import { capitalizeFirst } from "hooks/Utils/Utils";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { PreCheckInContext } from "./ReservationDetailsContext";
import { PARSE_AND_FORMAT_DATE, SUBJECT_TRANSLATIONS } from "./utils";

const PreCheckinHistory = () => {
    const { t } = useTranslation();

    const prefixId = "pre-check-in-history";
    const { data: dataContext } = useContext(PreCheckInContext);

    const logsArray =
        dataContext?.stayBookings?.logs?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) || [];

    const [logsOpen, setLogsOpen] = useState([]);

    const toggleLogOpen = (logId) => {
        setLogsOpen((prevLogsOpen) => {
            if (prevLogsOpen.includes(logId)) {
                return prevLogsOpen.filter((id) => id !== logId);
            } else {
                return [...prevLogsOpen, logId];
            }
        });
    };

    const renderLog = (log, index) => {
        const logId = log.id || index;
        console.log(log);
        return (
            <div
                className={`w-full flex flex-col ${logsOpen.includes(logId) ? " border-b border-gray-200" : ""} `}
                key={logId}
            >
                <div className={`py-3 flex w-full ${logsOpen.includes(logId) ? "" : "border-b"}  border-gray-200`}>
                    <div className="w-2/12 relative flex px-8 pb-2">
                        <button
                            id={`${prefixId}-${index}-button-arrow`}
                            onClick={() => toggleLogOpen(logId)}
                            disabled={!log.message}
                            style={{ marginTop: "0.4rem", left: "0.5rem" }}
                            className={`${
                                log.message ? "opacity-100" : "opacity-0"
                            } absolute top-0 text-sm text-gray-800 icon ${
                                logsOpen.includes(logId) ? "icon-chevron-up" : "icon-chevron"
                            }`}
                        ></button>
                        <div id={`${prefixId}-${index}-date`}>{PARSE_AND_FORMAT_DATE(log.createdAt)}</div>
                    </div>
                    <div className="w-2/12 px-8 pb-2">{log.subject ? SUBJECT_TRANSLATIONS[log.subject] : ""}</div>
                    <div
                        className="w-8/12 px-8 pb-2 "
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                        id={`${prefixId}-${index}-message`}
                    >
                        {log.message ? log.message : ""}
                    </div>
                </div>
                {logsOpen.includes(logId) && (
                    <div className=" w-full ml-8 pr-8  ">
                        <div className=" border-t  flex  border-gray-200">
                            <div className=" mt-2 mb-3 flex">
                                <div className=" mr-4 ">{`${t("complete-message")}`}</div>
                                <div>
                                    {log.message.split("\n").map((paragraf, indexP) => {
                                        return (
                                            <p id={`${prefixId}-i${index}-p${indexP}-complete-message`}>{paragraf}</p>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            <div className=" mt-6 font-bold ">{t("pre-checkin-history")}</div>
            <div className=" w-full border-b border-gray-200 flex mt-4 ">
                <div className=" w-2/12 px-8 pb-2 font-bold">{capitalizeFirst(t("date"))}</div>
                <div className=" w-2/12 px-8 pb-2 font-bold">{capitalizeFirst(t("subject"))}</div>
                <div className=" w-8/12 px-8 pb-2 font-bold">{capitalizeFirst(t("message"))}</div>
            </div>
            <div className=" w-full">
                {logsArray.map((log, index) => {
                    return renderLog(log, index);
                })}
                {logsArray?.length === 0 ? (
                    <div className=" mt-6 flex justify-center">{t("no-information-yet")}</div>
                ) : null}
            </div>
        </div>
    );
};

export default PreCheckinHistory;
