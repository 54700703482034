import React, { useEffect, useState } from "react";
import UseItemActions from "../../useItemActions";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Session } from "../../../hooks/Utils/Session";
import { DELETE_FONT_STYLE } from "../../../hooks/GraphqlCalls/MediaLibrary/FontsActions";

const Style = ({ items = 1, style, index }) => {
    const { t } = useTranslation();
    const { fontSelected } = useSelector((state) => state.sectionContent);
    const [overStyle, setOverStyle] = useState(false);
    const [styleParsed, setStyleParsed] = useState("");

    useEffect(() => {
        const { style: _style } = style;
        let styleToApply = "";
        _style.split(" ").forEach((element) => {
            styleToApply += getStyles(element.toLowerCase());
        });
        setStyleParsed(styleToApply);
        // eslint-disable-next-line
    }, []);

    return (
        <div
            className={`m-8 border ${overStyle ? " border-gray-200" : "border-white"}`}
            onMouseOver={() => setOverStyle(true)}
            onMouseLeave={() => setOverStyle(false)}
        >
            <div
                className={`inline-flex mt-4 float-right text-gray-900 ${overStyle ? "" : "hidden"}`}
                onClick={(e) => e.stopPropagation()}
            >
                <UseItemActions
                    dataType="appearance"
                    id={`style-font-${index}`}
                    containerAdjust="w-48"
                    executeSectionQuery={true}
                    actions={[
                        {
                            name: "media-library-download",
                            action: "media-library-download",
                            noModal: true,
                            link: Session.getDasUrl(fontSelected.ref + "?style=" + style.style + "&download"),
                            target: "_blank",
                            id: "media-library-download",
                            // modal_title: `${style.name} - ${t("media-library-download")}`,
                            // modal_value: style.style,
                        },
                        {
                            name: "media-library-delete-permanently",
                            action: DELETE_FONT_STYLE,
                            modal_title: `${style.name} - ${t("media-library-delete-permanently")}`,
                            modal_value: style.style,
                            disabled: items === 1,
                            id: "media-library-delete-permanently",
                        },
                    ]}
                />
            </div>
            <div className="leading-6 mt-3 ml-3">{style.style}</div>
            <div
                className={`text-3xl break-words text-center m-10 ${styleParsed}`}
                style={{ fontFamily: `'${style.name}'` }}
            >
                ABCDEFGHIJKLMNOPQRSTUVWXYZ
                <br />
                abcdefghijklmnopqrstuvwxyz
                <br />
                1234567890
            </div>
        </div>
    );
};

export default Style;

const getStyles = (key) => {
    let _style = "";

    if (["italic", "oblique"].includes(key)) {
        _style += " italic";
    }
    if (["thin", "hair", "hairline"].includes(key)) {
        _style += " font-thin";
    }
    if (["extra-light", "extralight", "ultra-light", "ultralight"].includes(key)) {
        _style += " font-extralight";
    }
    if (["light", "lighter"].includes(key)) {
        _style += " font-light";
    }
    if (["normal", "regular"].includes(key)) {
        _style += " font-normal";
    }
    if (["medium"].includes(key)) {
        _style += " font-bold";
    }
    if (["semi-bold", "semibold", "demi bold", "demi-bold", "demi"].includes(key)) {
        _style += " font-semibold";
    }
    if (["bold", "bolder"].includes(key)) {
        _style += " font-black";
    }
    if (["extra-bold", "extrabold", "ultra-bold", "ultrabold", "ultra"].includes(key)) {
        _style += " font-extrabold";
    }
    if (["black", "heavy"].includes(key)) {
        _style += " font-black";
    }
    return _style;
};
