import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MonitorContext } from "contexts/Sales/Monitor";

import { ORDER_TYPE_ARCHIVED } from "hooks/Utils/Services/OrdersUtils";

const OrderMonitorColumns = () => {
    const { t } = useTranslation();

    const { orders, currentType } = useContext(MonitorContext);
    const totalOrders = orders?.length;

    const [columns, setColumns] = useState([]);

    useEffect(() => {
        setColumns(arrangeData({ t, currentType, totalOrders }));
    }, [currentType, totalOrders]);

    return (
        <>
            <div className="t-row">
                {columns.map((column) => (
                    <Column key={column?.title} columnData={column} t={t} />
                ))}
            </div>
        </>
    );
};

export default OrderMonitorColumns;

//renders
const Column = (props = {}) => {
    const { columnData, t } = props;
    return (
        <div className={columnData?.mainAdjust}>
            <div className={columnData?.secondAdjust}>
                <span className={columnData?.spanAdjust}>
                    {columnData?.noTitleTranslate ? columnData?.title : t(columnData?.title)}
                </span>
            </div>
        </div>
    );
};

//arrangeData
const arrangeData = ({ currentType, totalOrders, t }) => {
    const response = [];
    const defaultMainAdjust = "w-2/12";
    const defaultSecondAdjust = "flex items-center";
    const defaultSpanAdjust = "font-bold text-gray-900 first-capital";
    response.push(
        {
            title: "order",
            mainAdjust: defaultMainAdjust,
            secondAdjust: defaultSecondAdjust,
            spanAdjust: `ml-14 ${defaultSpanAdjust}`,
        },
        {
            title: "customer",
            mainAdjust: defaultMainAdjust,
            secondAdjust: defaultSecondAdjust,
            spanAdjust: defaultSpanAdjust,
        },
        {
            title: "order-date",
            mainAdjust: defaultMainAdjust,
            secondAdjust: defaultSecondAdjust,
            spanAdjust: defaultSpanAdjust,
        },
        {
            title: currentType === ORDER_TYPE_ARCHIVED ? "last-update" : "delivery",
            mainAdjust: defaultMainAdjust,
            secondAdjust: defaultSecondAdjust,
            spanAdjust: defaultSpanAdjust,
        },
        {
            title: "status",
            mainAdjust: defaultMainAdjust,
            secondAdjust: defaultSecondAdjust,
            spanAdjust: defaultSpanAdjust,
        },
        {
            title: `${totalOrders} ${t("orders")}`,
            noTitleTranslate: true,
            mainAdjust: defaultMainAdjust,
            secondAdjust: "flex items-center justify-end",
            spanAdjust: "text-sm text-gray-800 whitespace-normal",
        }
    );

    return response;
};
