import React from "react";

export const UseBookingCell = ({ cellData, colIndex, rowIndex }) => {
    const { room, guest } = cellData.value;
    return (
        <div className="flex flex-col ">
            <div className=" grid grid-cols-4">
                <i className="icon icon-room mt-1" />
                <p className=" col-span-3">{room}</p>
            </div>
            <div className="grid grid-cols-4">
                <i className="icon icon-user-regular mt-1" />
                <p className=" col-span-3">{guest}</p>
            </div>
        </div>
    );
};
