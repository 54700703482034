export const SET_CHECKABLE = "SET_CHECKABLE";
export const SET_HEADERS = "SET_HEADERS";
export const SET_TABLE_RESULTS = "SET_TABLE_RESULTS";
export const SET_TABLE_TYPE = "SET_TABLE_TYPE";
export const SET_AVAILABLE_FILTERS = "SET_AVAILABLE_FILTERS";
export const SET_LOADING = "SET_LOADING";
export const SET_LOADING_MORE_RESULTS = "SET_LOADING_MORE_RESULTS";
export const SET_ERROR = "SET_ERROR";
export const SET_COUNT = "SET_COUNT";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_ACTIVE_FILTERS = "SET_ACTIVE_FILTERS";
export const RESTORE_ACTIVE_FILTERS = "RESTORE_ACTIVE_FILTERS";
export const CLEAN_ACTIVE_FILTERS = "CLEAN_ACTIVE_FILTERS";
export const GET_FILTER_QUERY = "GET_FILTER_QUERY";
export const SET_ACTIONS = "SET_ACTIONS";
export const SET_EXTENDED_INFO = "SET_EXTENDED_INFO";
export const SET_COLUMNS = "SET_COLUMNS";
export const SET_ALL_CHECKED = "SET_ALL_CHECKED";
export const SET_BATCH_ACTIVE = "SET_BATCH_ACTIVE";
export const CLEAN_TABLE_STORE = "CLEAN_TABLE_STORE";
export const SET_SEARCHABLE = "SET_SEARCHABLE";
export const SET_TITLE = "SET_TITLE";
export const SET_COUNT_POSITION = "SET_COUNT_POSITION";
export const SET_HEADER_BUTTONS = "SET_HEADER_BUTTONS";
export const SET_ROW_GROUPS = "SET_ROW_GROUPS";
export const SET_DATE_HEADER = "SET_DATE_HEADER";
export const SET_DATE_FILTER = "SET_DATE_FILTER";
export const REFRESH_DATA = "REFRESH_DATA";
export const RELOAD = "RELOAD";
export const SET_ROW_FORM = "SET_ROW_FORM";
export const SET_REFRESH_DATA = "SET_REFRESH_DATA";
export const SET_RELOAD = "SET_RELOAD";
export const SET_SORT_CRITERIA = "SET_SORT_CRITERIA";
export const CLEAN_SORT_CRITERIA = "CLEAN_SORT_CRITERIA";
export const SET_ACTIVE_PARAMS = "SET_ACTIVE_PARAMS";
export const GET_PARAMS_QUERY = "GET_PARAMS_QUERY";
export const SET_CHECKDD_VALUES = "SET_CHECKDD_VALUES";
export const SET_CLEANING_STAFF = "SET_CLEANING_STAFF";
export const SET_INFORMATIVE_MESSAGE = "SET_INFORMATIVE_MESSAGE";
export const SET_CUSTOM_ERROR = "SET_CUSTOM_ERROR";
export const SET_CUSTOM_CONFIG = "SET_CUSTOM_CONFIG";
export const SET_REMOVE_LOADING_TIMEOUT = "SET_REMOVE_LOADING_TIMEOUT";
export const UPDATE_RESULT_ITEM = "UPDATE_RESULT_ITEM";
export const SET_EXPORT = "SET_EXPORT";
export const ADD_TOASTINFO_ITEM = "ADD_TOASTINFO_ITEM";
export const REMOVE_TOASTINFO_ITEM = "REMOVE_TOASTINFO_ITEM";
export const ADD_CHECKED_ITEM = "ADD_CHECKED_ITEM";
export const REMOVE_CHECKED_ITEM = "REMOVE_CHECKED_ITEM";
export const CLEAN_CHECKED_ITEMS = "CLEAN_CHECKED_ITEMS";
export const SET_ROW_IN_EDITION = "SET_ROW_IN_EDITION";
export const SET_ITEMS_PERPAGE = "SET_ITEMS_PERPAGE";
export const SET_SERVER_ERROR = "SET_SERVER_ERROR";
export const SET_CUSTOM_SERVER_ERROR = "SET_CUSTOM_SERVER_ERROR";
export const SET_TARGET_ID = "SET_TARGET_ID";
export const SET_DISABLED = "SET_DISABLED";
export const SET_FOLDER = "SET_FOLDER";
export const SET_CONNECTION_ERROR = "SET_CONNECTION_ERROR";
export const SET_ID_SUBROW = "SET_ID_SUBROW";
export const RESET_IDS_SUBROWS = "RESET_IDS_SUBROWS";

export function setCheckable(config) {
    //Loads bool in store.checkable to show or hide checkboxes at row start (batch actions)
    return (dispatch) => {
        dispatch({
            type: SET_CHECKABLE,
            payload: config,
        });
    };
}
export function setDisabled(value) {
    //Loads bool in store.checkable to show or hide checkboxes at row start (batch actions)
    return (dispatch) => {
        dispatch({
            type: SET_DISABLED,
            payload: value,
        });
    };
}

export function setHeaders(config) {
    //Loads object array in store.headers with table headers data & config
    return (dispatch) => {
        dispatch({
            type: SET_HEADERS,
            payload: config,
        });
    };
}

export function setTableType(payload) {
    //Loads object array in store.results with table rows data & config
    return (dispatch) => {
        dispatch({
            type: SET_TABLE_TYPE,
            payload: payload,
        });
    };
}
export function setTableResults(results) {
    //Loads object array in store.results with table rows data & config
    return (dispatch) => {
        dispatch({
            type: SET_TABLE_RESULTS,
            payload: results,
        });
    };
}

export function setAvailableFilters(filters) {
    //Loads object array in store.availableFilters with filter data & config
    return (dispatch) => {
        dispatch({
            type: SET_AVAILABLE_FILTERS,
            payload: filters,
        });
    };
}

export function setLoading(loading) {
    //Loads bool in store.loading to show or hide spinner
    return (dispatch) => {
        dispatch({
            type: SET_LOADING,
            payload: loading,
        });
    };
}

export function setLoadingMoreResults(loading) {
    //Loads bool in store.loading to show or hide spinner
    return (dispatch) => {
        dispatch({
            type: SET_LOADING_MORE_RESULTS,
            payload: loading,
        });
    };
}

export function setError(error, technicalProblems) {
    //Loads string in store.error with API error message
    return (dispatch) => {
        dispatch({
            type: SET_ERROR,
            payload: { error, technicalProblems },
        });
    };
}

export function setConnectionError(error) {
    //Sets  store.connectionError if network error
    return (dispatch) => {
        dispatch({
            type: SET_CONNECTION_ERROR,
            payload: error ? error : false,
        });
    };
}

export function setCount(count) {
    //Loads number in store.count with total results count
    return (dispatch) => {
        dispatch({
            type: SET_COUNT,
            payload: count,
        });
    };
}

export function setPage(page) {
    //Sets number in store.currentPage for paginating results in query
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_PAGE,
            payload: page,
        });
    };
}
export function setRowInEdition(rowIdx) {
    //Sets number in store.currentPage for paginating results in query
    return (dispatch) => {
        dispatch({
            type: SET_ROW_IN_EDITION,
            payload: rowIdx,
        });
    };
}

export function setActiveFilters(name, value) {
    //Sets key value in store.activeFilters for filtering purposes in query
    return (dispatch) => {
        dispatch({
            type: SET_ACTIVE_FILTERS,
            payload: [name, value],
        });
    };
}

export function restoreActiveFilters(activeFilters) {
    //Sets key value in store.activeFilters for filtering purposes in query
    return (dispatch) => {
        dispatch({
            type: RESTORE_ACTIVE_FILTERS,
            payload: activeFilters,
        });
    };
}

export function cleanActiveFilters() {
    //Cleans store.activeFilters
    return (dispatch) => {
        dispatch({
            type: CLEAN_ACTIVE_FILTERS,
        });
    };
}

export function setActiveParams(name, value) {
    //Sets key value in store.activeParams for filtering purposes in query
    return (dispatch) => {
        dispatch({
            type: SET_ACTIVE_PARAMS,
            payload: [name, value],
        });
    };
}

export function getFilterQuery(activeFilters) {
    //Loads query compatible string from store.activeFilters in store.filterQuery
    const queryFilters = Object.keys(activeFilters).length > 0 ? queryCalc(activeFilters) : "";
    return (dispatch) => {
        dispatch({
            type: GET_FILTER_QUERY,
            payload: queryFilters,
        });
    };
}

export function getParamsQuery(activeParams) {
    //Loads query compatible string from store.activeParams in store.paramsQuery
    const queryParams = paramsCalc(activeParams);
    return (dispatch) => {
        dispatch({
            type: GET_PARAMS_QUERY,
            payload: queryParams,
        });
    };
}

export function setActions() {
    //Sets true in store.actions to show row 3dot menú
    return (dispatch) => {
        dispatch({
            type: SET_ACTIONS,
        });
    };
}

export function setExtendedInfo(config) {
    //Sets bool to store.extendedInfo if rows are expandable
    return (dispatch) => {
        dispatch({
            type: SET_EXTENDED_INFO,
            payload: config,
        });
    };
}

export function setColumns(amount) {
    //Loads store.columns with default configuration of table column number (this is overrided if element has specialWidth attr)
    return (dispatch) => {
        dispatch({
            type: SET_COLUMNS,
            payload: amount,
        });
    };
}

export function setAllChecked(config) {
    //Loads bool in store.allChecked to check all row cheboxes or uncheck all
    return (dispatch) => {
        dispatch({
            type: SET_ALL_CHECKED,
            payload: config,
        });
    };
}

export function setBatchActive(data) {
    return (dispatch) => {
        dispatch({
            type: SET_BATCH_ACTIVE,
            payload: data,
        });
    };
}

export function cleanTableStore() {
    //Cleans store data to initial values
    return (dispatch) => {
        dispatch({
            type: CLEAN_TABLE_STORE,
        });
    };
}

export function setSearchable() {
    //Sets bool in store.searchable to show or hide search input
    return (dispatch) => {
        dispatch({
            type: SET_SEARCHABLE,
        });
    };
}

export function setInformativeMessage(value) {
    return (dispatch) => {
        dispatch({
            type: SET_INFORMATIVE_MESSAGE,
            payload: value,
        });
    };
}

export function setTitle(titleAddition) {
    //Sets string in store.title
    return (dispatch) => {
        dispatch({
            type: SET_TITLE,
            payload: titleAddition,
        });
    };
}

export function setCountPosition(position) {
    //Sets string in store.countPosition with the position of count component
    return (dispatch) => {
        dispatch({
            type: SET_COUNT_POSITION,
            payload: position,
        });
    };
}

export function setHeaderButtons(config) {
    //Loads obj array in store.headerButtons with data & configuration of header buttons
    return (dispatch) => {
        dispatch({
            type: SET_HEADER_BUTTONS,
            payload: config,
        });
    };
}

export function setRowGroups(config) {
    //Sets bool in store.showRowGroups to show or hide row groups
    return (dispatch) => {
        dispatch({
            type: SET_ROW_GROUPS,
            payload: config,
        });
    };
}

export function setDateHeader() {
    //Sets to true store.dateHeader to show header calendar & button for bookings
    return (dispatch) => {
        dispatch({
            type: SET_DATE_HEADER,
        });
    };
}

export function setDateFilter(date) {
    //Sets date string in store.dateFilter to filter date in bookings
    return (dispatch) => {
        dispatch({
            type: SET_DATE_FILTER,
            payload: date,
        });
    };
}

export function setFormNewContent(formContent) {
    //Sets obj in store.rowFormContent with data & config for row form
    return (dispatch) => {
        dispatch({
            type: SET_ROW_FORM,
            payload: formContent,
        });
    };
}

export function setRefreshData(refresh) {
    //Sets obj in store.rowFormContent with data & config for row form
    return (dispatch) => {
        dispatch({
            type: SET_REFRESH_DATA,
            payload: refresh,
        });
    };
}

export function setReload(reload) {
    //Sets obj in store.rowFormContent with data & config for row form
    return (dispatch) => {
        dispatch({
            type: SET_RELOAD,
            payload: reload,
        });
    };
}

export function setRemoveLoadingTimeout(timeoutObject) {
    //Sets obj in store.rowFormContent with data & config for row form
    return (dispatch) => {
        dispatch({
            type: SET_REMOVE_LOADING_TIMEOUT,
            payload: timeoutObject,
        });
    };
}

export function setActiveSort(field, criteria, sort) {
    //Loads key & value in store.sortCriteria to refetch query
    const sortString = sort ? sort : sortCalc(field, criteria);
    return (dispatch) => {
        dispatch({
            type: SET_SORT_CRITERIA,
            payload: {
                criteria,
                field,
                sortString,
            },
        });
    };
}

export function cleanSortCriteria() {
    //Clean store.sortCriteria
    return (dispatch) => {
        dispatch({
            type: CLEAN_SORT_CRITERIA,
        });
    };
}

export function setCheckboxDropdownFilter(dropdownData) {
    //Loads key & value in store.checkboxDropdownFilter
    return (dispatch) => {
        dispatch({
            type: SET_CHECKDD_VALUES,
            payload: dropdownData,
        });
    };
}

export function setCleaningStaff(staffData) {
    //Loads array of obj in store.cleaningStaff to populate responsible selects
    return (dispatch) => {
        dispatch({
            type: SET_CLEANING_STAFF,
            payload: staffData,
        });
    };
}

export function setCustomError(message) {
    //Loads string in store.customError with section custom message if no results (needs translation)
    return (dispatch) => {
        dispatch({
            type: SET_CUSTOM_ERROR,
            payload: message,
        });
    };
}
export function setCustomConfig(message) {
    //Loads string in store.customError with section custom message if no results (needs translation)
    return (dispatch) => {
        dispatch({
            type: SET_CUSTOM_CONFIG,
            payload: message,
        });
    };
}

export function updateResultItem(conf) {
    //Updates proper result object in store.results with updated data
    return (dispatch) => {
        dispatch({
            type: UPDATE_RESULT_ITEM,
            payload: conf,
        });
    };
}

export function setIdSubRow(val) {
    return (dispatch) => {
        dispatch({
            type: SET_ID_SUBROW,
            payload: Number(val),
        });
    };
}

export function resetIdSubRow() {
    return (dispatch) => {
        dispatch({
            type: RESET_IDS_SUBROWS,
        });
    };
}

export const queryCalc = (activeFilters) => {
    //clean activeFilters
    if (Object.keys(activeFilters).length > 0) {
        Object.keys(activeFilters).forEach((key) => {
            if (key === "null") {
                delete activeFilters[key];
            }
        });
    }
    // reset filter query when only 1 filter active and value es ""
    let resetFilter = false;
    if (Object.keys(activeFilters).length === 1) {
        Object.keys(activeFilters).forEach((key) => {
            if (activeFilters[key] === "") {
                resetFilter = true;
            }
        });
    }
    if (resetFilter) return "";

    //Returns filter query from active filters
    let filterString = ",filter:{";
    // eslint-disable-next-line
    Object.keys(activeFilters).forEach((key, index) => {
        if (activeFilters[key] !== "") {
            //format param if neccessary
            if (index > 0) {
                filterString += ",";
            }
            if (convertToType(key)) {
                filterString += `${convertToType(key)} : ${activeFilters[key].toUpperCase()}`;
            } else if (
                activeFilters[key] &&
                (key === "capacityRange" || (typeof activeFilters[key] == "string" && activeFilters[key].includes("[")))
            ) {
                filterString += `${key} : ${activeFilters[key]}`;
            } else if (
                activeFilters[key] &&
                (key === "room" || key === "guestGroup" || key === "category" || key === "staffId")
            ) {
                filterString += `${key} : ${Number(activeFilters[key])}`;
            } else if (activeFilters[key] && key !== "roles" && typeof activeFilters[key] === "number") {
                filterString += `${key} : ${activeFilters[key]}`;
            } else if (activeFilters[key] && activeFilters[key] === "true") {
                filterString += `${key} : ${true}`;
            } else if (activeFilters[key] && activeFilters[key] === "false") {
                filterString += `${key} : ${false}`;
            } else if (activeFilters[key]) {
                filterString += `${key} : ${
                    activeFilters[key]?.isEnum ? activeFilters[key].value : `"${activeFilters[key]}"`
                }`;
            }
        }
    });
    filterString += "}";

    return filterString;
};

const convertToType = (key) => {
    switch (key) {
        case "messages-state":
        case "messages-priority":
        case "messages-expiration":
            return key.replace("messages-", "");
        case "alarm-state":
            return key.replace("alarm-", "");
        default:
            return false;
    }
};

export const paramsCalc = (activeParams) => {
    //Returns extra params query from active params
    let paramString = "";
    if (Object.keys(activeParams).length > 0) {
        Object.keys(activeParams).forEach((key) => {
            if (activeParams[key] !== "") {
                paramString += ", ";
                if (key === "category") {
                    paramString += `${key} : ${Number(activeParams[key])}`;
                } else {
                    paramString += `${key} : "${activeParams[key]}"`;
                }
            }
        });
        if (paramString.length > 0) {
            paramString += "";
        }
    }
    return paramString;
};

export const sortCalc = (field, criteria) => {
    //Returns string to orderBy column
    return `,orderBy:{field:"${field}", criteria:"${criteria}"}`;
};

export function setExport(exportValue) {
    // sets expotData values so data can be loaded to export
    return (dispatch) => {
        dispatch({
            type: SET_EXPORT,
            payload: exportValue,
        });
    };
}
export function setFolder(folder) {
    return (dispatch) => {
        dispatch({
            type: SET_FOLDER,
            payload: folder,
        });
    };
}
export function addToastInfoItem(id) {
    // sets expotData values so data can be loaded to export
    return (dispatch) => {
        dispatch({
            type: ADD_TOASTINFO_ITEM,
            payload: id,
        });
    };
}
export function removeToastInfoItem(id) {
    // sets expotData values so data can be loaded to export
    return (dispatch) => {
        dispatch({
            type: REMOVE_TOASTINFO_ITEM,
            payload: id,
        });
    };
}
export function addCheckedItem(id) {
    // sets expotData values so data can be loaded to export
    return (dispatch) => {
        dispatch({
            type: ADD_CHECKED_ITEM,
            payload: id,
        });
    };
}
export function removeCheckedItem(id) {
    // sets expotData values so data can be loaded to export
    return (dispatch) => {
        dispatch({
            type: REMOVE_CHECKED_ITEM,
            payload: id,
        });
    };
}
export function cleanCheckedItems() {
    // sets expotData values so data can be loaded to export
    return (dispatch) => {
        dispatch({
            type: CLEAN_CHECKED_ITEMS,
        });
    };
}
export function setItemsPerpage(numItems) {
    // sets num items per page in tables
    return (dispatch) => {
        dispatch({
            type: SET_ITEMS_PERPAGE,
            payload: numItems,
        });
    };
}
export function setServerError(status) {
    return (dispatch) => {
        dispatch({
            type: SET_SERVER_ERROR,
            payload: status,
        });
    };
}
export function setCustomServerError(status) {
    return (dispatch) => {
        dispatch({
            type: SET_CUSTOM_SERVER_ERROR,
            payload: status,
        });
    };
}
export function setTargetId(id) {
    return (dispatch) => {
        dispatch({
            type: SET_TARGET_ID,
            payload: id,
        });
    };
}
