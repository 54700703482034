import React from "react";
import { NetworkCard } from "./Section/Hotel/NetworkCard";

const NetworkGrid = ({ gridStyles, networks, type }) => {
    return (
        <>
            {networks ? (
                <div className={gridStyles}>
                    {networks.map((network) => (
                        <NetworkCard network={network} key={network.id} type={type} />
                    ))}
                </div>
            ) : null}
        </>
    );
};

export default NetworkGrid;
