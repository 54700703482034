import CleanUnnecessaryChars from "./CleanUnnecessaryChars";

const dateRange = "date-range";

export const validateDateRange = (dateType, specificRange) => {
    let res;
    if (dateType && dateType === dateRange && specificRange && specificRange.from && specificRange.to) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const validateDateSpecific = (dateType, specificDates) => {
    return dateType && dateType === "date-specific" && specificDates && specificDates.length > 0;
};

export const formatToAPIDate = (dateList) => {
    const dateArray = dateList.toLocaleDateString("es-ES").split("/");
    if (dateArray[0].length < 2) {
        dateArray[0] = `0${dateArray[0]}`;
    }
    if (dateArray[1].length < 2) {
        dateArray[1] = `0${dateArray[1]}`;
    }
    return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
};

export const GetMutationDateType = (dateType) => {
    let res;
    if (dateType && dateType === dateRange) {
        res = "DATE_RANGE";
    } else if (dateType && dateType === "date-specific") {
        res = "SPECIFIC_DAYS";
    } else {
        res = "";
    }
    return res;
};
//ISO 8601 standard Monday(1) Sunday (7)
export const GetMutationWeekDays = (weekDays) => {
    let res;
    if (weekDays) {
        const sortedWeekdays = weekDays.sort();
        res = `[${sortedWeekdays.join(",")}]`;
    } else {
        res = ``;
    }
    return res;
};

export const GetMutationDateList = (specificRange, specificDates, dateType) => {
    let res;
    if (validateDateRange(dateType, specificRange)) {
        res = `[{${formatToAPIDate(specificRange.from)}-${formatToAPIDate(specificRange.to)}}]`;
    } else if (validateDateSpecific(dateType, specificDates)) {
        res = `[`;
        // eslint-disable-next-line
        specificDates.map((specificDate, index) => {
            if (index !== 0) {
                res += `,`;
            }
            const formattedDate = formatToAPIDate(new window.ZDate(specificDate));
            res += `{${formattedDate}-${formattedDate}}`;
        });
        res += `]`;
    }
    return res;
};

export const GetMutationSchedules = (schedule) => {
    let res = `[`;
    const validationResult = validateSchedule(schedule);
    if (
        validationResult.morningStart &&
        validationResult.morningEnd &&
        validationResult.afternoonStart &&
        validationResult.afternoonEnd
    ) {
        res += `${schedule.morningStart}-${schedule.morningEnd}`;
    }
    if (
        schedule &&
        schedule.afternoonStart &&
        validationResult.morningStart &&
        validationResult.morningEnd &&
        validationResult.afternoonStart &&
        validationResult.afternoonEnd
    ) {
        res += `,${schedule.afternoonStart}-${schedule.afternoonEnd}`;
    }
    res += `]`;
    return res;
};

export const validateDateType = (dateType) => {
    let res;
    if (!dateType) {
        res = false;
    } else {
        res = true;
    }
    return res;
};

export const validateDates = (dateType, specificRange, specificDates) => {
    let res;
    if (dateType === dateRange) {
        if (
            !specificRange ||
            (typeof specificRange === "string" &&
                (specificRange.indexOf("null") > 0 || specificRange.indexOf("NaN") > 0)) ||
            (typeof specificRange === "object" && (!specificRange.from || !specificRange.to))
        ) {
            res = false;
        } else {
            res = true;
        }
    } else {
        if (
            !specificDates ||
            (typeof specificDates === "string" &&
                (specificDates.length < 3 || specificDates.indexOf("null") > 0 || specificDates.indexOf("NaN") > 0)) ||
            specificDates.length < 1
        ) {
            res = false;
        } else {
            res = true;
        }
    }
    return res;
};

export const validateSchedule = (schedule) => {
    let res = {
        morningStart: true,
        morningEnd: true,
        afternoonStart: true,
        afternoonEnd: true,
    };
    //If no schedules
    if (!schedule) {
        res.morningStart = false;
        res.morningEnd = false;
    } else {
        if (!schedule.morningStart || !schedule.morningEnd) {
            //If any mandatory is missing
            res.morningStart = false;
            res.morningEnd = false;
        } else {
            //If optional are started but incomplete
            if (schedule.afternoonStart && !schedule.afternoonEnd) {
                res.afternoonEnd = false;
            } else if (schedule.afternoonEnd && !schedule.afternoonStart) {
                res.afternoonStart = false;
            } else {
                //Mandatory ok and optional present or not
                //Check schedule values
                const tempMorningStart = schedule.morningStart.split(":");
                const morningStart = new window.ZDate("2000", "01", "01", tempMorningStart[0], tempMorningStart[1]);
                const tempMorningEnd = schedule.morningEnd.split(":");
                const morningEnd = new window.ZDate("2000", "01", "01", tempMorningEnd[0], tempMorningEnd[1]);

                if (morningStart >= morningEnd) {
                    res.morningEnd = false;
                }
                if (schedule.afternoonStart) {
                    const tempAfternoonStart = schedule.afternoonStart.split(":");
                    const afternoonStart = new window.ZDate(
                        "2000",
                        "01",
                        "01",
                        tempAfternoonStart[0],
                        tempAfternoonStart[1]
                    );
                    const tempAfternoonEnd = schedule.afternoonEnd.split(":");
                    const afternoonEnd = new window.ZDate("2000", "01", "01", tempAfternoonEnd[0], tempAfternoonEnd[1]);

                    if (afternoonStart >= afternoonEnd) {
                        res.afternoonEnd = false;
                    }

                    if (afternoonStart <= morningEnd) {
                        res.afternoonStart = false;
                    }
                }
            }
        }
    }
    return res;
};

export const validateScheduleTo = (schedule) => {
    let res;
    if (!schedule || (schedule && !schedule.morningEnd) || (schedule && schedule.morningEnd === "")) {
        res = false;
    }
    if (schedule && schedule.morningEnd) {
        res = true;
    }
    return res;
};
//ISO 8601 standard Monday(1) Sunday (7)
export const validateWeekdays = (weekDays) => {
    let res;
    if (!weekDays || weekDays.length === 0 || weekDays === "[]") {
        res = false;
    } else {
        res = true;
    }
    return res;
};

export const validateName = (itemName) => {
    let res;
    if (!itemName || itemName === "") {
        res = false;
    } else {
        res = true;
    }
    return res;
};

export const setScheduleType = (periodicityType, type, dateList) => {
    let res;
    if (periodicityType === "UNIQUE") {
        res = "punctual-schedule";
    } else {
        if (type === "CLOSED") {
            res = "festive-schedule";
        } else {
            if (dateList && dateList.length > 2) {
                res = "special-schedule";
            } else {
                res = "common-schedule";
            }
        }
    }
    return res;
};

export const getClearTimetableranges = (dateList, dateType) => {
    let res = [];
    let cleanArray = [];
    const clearTempString = CleanUnnecessaryChars(dateList);

    if (clearTempString !== "") {
        if (dateType === "DATE_RANGE") {
            cleanArray = clearTempString.split("-");
        } else {
            const specificDateInfo = clearTempString.split(",");
            // eslint-disable-next-line
            specificDateInfo.map((dateItem) => {
                const tempArray = dateItem.split("-");
                cleanArray.push(tempArray[0]);
            });
        }
        // eslint-disable-next-line
        cleanArray.map((formatedDate) => {
            res.push(
                new window.ZDate(formatedDate).toLocaleDateString([], {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                })
            );
            return true;
        });
    }
    return res;
};

export const getClearSchedules = (timeRanges) => {
    let res = [];
    let clearTempString = CleanUnnecessaryChars(timeRanges);
    clearTempString = clearTempString.split("-").join(" - ");
    res = clearTempString.split(",");
    return res;
};
//ISO 8601 standard Monday(1) Sunday (7)
export const getClearWeekDays = (weekDays) => {
    let res = [];
    const clearTempString = CleanUnnecessaryChars(weekDays);
    res = clearTempString.split(",");
    return res;
};

export const formatTTRanges = (timetableRanges, dateList, dateType) => {
    let res = [];
    // eslint-disable-next-line
    timetableRanges.map((ttrange) => {
        const scheduleItem = {};
        if (dateList) {
            scheduleItem.dates = getClearTimetableranges(dateList, dateType);
        }
        if (ttrange.timeRanges) {
            scheduleItem.schedule = getClearSchedules(ttrange.timeRanges);
        }
        if (ttrange.weekdayList) {
            //ISO 8601 standard Monday(1) Sunday (7)
            scheduleItem.weekDays = getClearWeekDays(ttrange.weekdayList);
        }
        res.push(scheduleItem);
    });
    return res;
};
//ISO 8601 standard Monday(1) Sunday (7)
export const getFrontWeekdays = (weekDays) => {
    let res = [];
    // eslint-disable-next-line
    weekDays.map((weekDay) => {
        res.push(String(weekDay));
    });
    return res;
};

export const getFrontSchedules = (schedule) => {
    let res = [];
    res.push(`${schedule.morningStart} - ${schedule.morningEnd}`);
    if (schedule.afternoonEnd) {
        res.push(`${schedule.afternoonStart} - ${schedule.afternoonEnd}`);
    }
    return res;
};

export const getFrontDateList = (specificRange, specificDates, dateType) => {
    let res = [];
    if (dateType === "date-range") {
        res.push(
            specificRange.from.toLocaleDateString([], {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
            specificRange.to.toLocaleDateString([], {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            })
        );
    } else {
        // eslint-disable-next-line
        specificDates.map((specificDate) => {
            res.push(
                new window.ZDate(specificDate).toLocaleDateString([], {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                })
            );
        });
    }
    return res;
};

export const isMutationError = (data) => {
    //Checks mutation error response
    let res;
    if (data && data.createTimetable && data.createTimetable.error !== "") {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const isMutationOk = (data) => {
    //Checks mutation success response
    let res;
    if (data && data.createTimetable && data.createTimetable.ok) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const getClosingDays = (schedule) => {
    const daysOnwardsToReserve = process.env.REACT_APP.DAYS_ONWARDS_TO_RESERVE;
    let closingDays = [];
    const today = new window.ZDate();
    const todayPlus30 = new window.ZDate();
    todayPlus30.setMonth(todayPlus30.getMonth() + 1);
    //Close All days before today
    closingDays.push({ before: today });

    //Close All days after one month today
    closingDays.push({ after: todayPlus30 });

    //no timetables all 30 day open
    if (schedule === undefined || schedule.length === 0) {
        return closingDays;
    }

    //Check timetables to disable closing days
    let cDate = new window.ZDate();
    while (cDate <= todayPlus30) {
        let dayClosed = false;
        schedule.map((timetable) => {
            if (timetable.isEnabled && timetable.type === "CLOSED") {
                timetable.timetableDates.forEach((timetableDate) => {
                    const dateList = extractDatesFromRange(timetableDate.dateList);
                    if (isDateInRange(cDate, dateList, timetable.dateType)) {
                        dateList.forEach((range) => {
                            const start = new window.ZDate(range.start);
                            start.setDate(start.getDate() - 1);
                            const end = new window.ZDate(range.end);
                            end.setDate(end.getDate() + 1);
                            dayClosed = true;
                            closingDays.push({
                                after: start,
                                before: end,
                            });
                        });
                    }
                });
            }
            return null;
        });
        const openEnabledTimetables = schedule.filter((timetable) => {
            return timetable.type !== "CLOSED" && timetable.isEnabled;
        });
        if (!dayClosed && openEnabledTimetables && openEnabledTimetables.length > 0) {
            let thisDayOpen = false;
            schedule.map((timetable) => {
                if (thisDayOpen) {
                    return true;
                }
                if (timetable.isEnabled && timetable.type === "OPEN") {
                    if (timetable.dateType === "SPECIFIC_DAYS") {
                        timetable.timetableDates.forEach((timetableDate) => {
                            const dateList = extractDatesFromRange(timetableDate.dateList);
                            if (isDateInRange(cDate, dateList, timetable.dateType)) {
                                thisDayOpen = true;
                            }
                        });
                    } else {
                        timetable.timetableDates.forEach((timetableDate) => {
                            if (thisDayOpen) {
                                return;
                            }
                            if (!timetableDate.dateList || timetableDate.dateList.length === 0) {
                                //horario habitual, mirar días que aplica
                                if (dateIsInWeekdayList(cDate, timetableDate.timetableDateRanges)) {
                                    thisDayOpen = true;
                                }
                            } else {
                                const dateList = extractDatesFromRange(timetableDate.dateList);
                                if (isDateInRange(cDate, dateList, timetable.dateType)) {
                                    if (dateIsInWeekdayList(cDate, timetableDate.timetableDateRanges)) {
                                        thisDayOpen = true;
                                    }
                                }
                            }
                        });
                    }
                }
                return null;
            });
            if (!thisDayOpen) {
                const start = new window.ZDate(cDate);
                start.setDate(cDate.getDate() - 1);
                const end = new window.ZDate(cDate);
                end.setDate(cDate.getDate() + 1);
                closingDays.push({
                    after: start,
                    before: end,
                });
            }
        }

        //Check next day
        cDate.setDate(cDate.getDate() + 1);
    }
    return closingDays;
};

const isDateInRange = (dateList, dateRanges, dateType = null) => {
    if (!dateRanges || dateRanges.length === 0) {
        return false;
    }
    let inRange = false;
    let dateToCheck = new window.ZDate(dateList);
    dateToCheck.setHours(0, 0, 0, 0);
    dateRanges.forEach((range) => {
        let dStart = new window.ZDate(range.start);
        dStart.setHours(0, 0, 0, 0);
        let dEnd = new window.ZDate(range.end);
        dEnd.setHours(0, 0, 0, 0);

        //si es día específico sólo se comprueba el start dateList
        if (dateType.toUpperCase() === "SPECIFIC_DAYS") {
            if (
                dStart.getFullYear() === dateToCheck.getFullYear() &&
                dStart.getMonth() === dateToCheck.getMonth() &&
                dStart.getDate() === dateToCheck.getDate()
            ) {
                inRange = true;
            }
        } else if (dStart <= dateToCheck && dEnd >= dateToCheck) {
            inRange = true;
        }
    });
    return inRange;
};

const extractDatesFromRange = (ranges) => {
    if (!ranges || ranges.length === 0) {
        return null;
    }
    const resultList = [];
    if (!ranges || ranges.length < 3) {
        return null;
    }
    let rangesList = ranges.split(",");

    rangesList.forEach((range) => {
        const result = range.match(/[\d||/]+-[\d||/]+/);
        const rangeDates = result[0].split("-");
        resultList.push({
            start: new window.ZDate(rangeDates[0]),
            end: new window.ZDate(rangeDates[1]),
        });
    });

    return resultList;
};

const extractHoursFromRange = (range) => {
    let result = range.match(/[\d||:||\-||,]+/);

    const hourRanges = result[0].split(",");
    const timeRangeList = [];

    hourRanges.forEach((hourRange) => {
        const hours = hourRange.split("-");
        timeRangeList.push({
            start: hours[0],
            end: hours[1],
            range: hourRange,
        });
    });

    return timeRangeList;
};

const dateIsInWeekdayList = (dateList, timetableRanges) => {
    if (timetableRanges.length === 0) {
        return true;
    }
    let currentDay = dateList.getDay();
    if (currentDay === 0) {
        currentDay = 7;
    }

    for (let index = 0; index < timetableRanges.length; index++) {
        const ttRange = timetableRanges[index];
        if (!ttRange.weekdayList || ttRange.weekdayList.length === 2 || ttRange.weekdayList.indexOf(currentDay) > -1) {
            return true;
        }
    }
    return false;
};

export const getOpeningTime = (schedule, dayToCheck = new window.ZDate()) => {
    const closeResult = {
        openingHours: null,
        openingTime: null,
        status: "close",
    };
    const open247 = {
        openingHours: null,
        openingTime: null,
        status: "open",
    };
    // - TEMPORARY CLOSE
    /*
    if (!zone.isOpen) {
        return { ...closeResult, tempClose: true };
    }*/
    // - NO timetables => 24/7 OPEN
    if (schedule.length === 0) {
        return open247;
    }
    // - ONLY timetable ENABLED
    const timetablesEnabled = schedule.filter((timetable) => {
        return timetable.isEnabled;
    });
    //PRIORITIES:
    //# 1- close days
    let _closed = false;
    timetablesEnabled.forEach((timetable) => {
        if (timetable.type === "CLOSED") {
            timetable.timetableDates.forEach((timetableDate) => {
                const dateList = extractDatesFromRange(timetableDate.dateList);
                if (isDateInRange(dayToCheck, dateList, timetable.dateType)) {
                    // apply this timetable type closed
                    _closed = true;
                }
            });
        }
    });
    if (_closed) {
        return closeResult;
    }
    //# 2- unique timetables
    const uniqueTimesToApply = checkTimeTableByPeriodicityType(dayToCheck, timetablesEnabled, "UNIQUE");
    if (uniqueTimesToApply) {
        return uniqueTimesToApply;
    }
    //# 3- habitual timetables
    const habitualTimesToApply = checkTimeTableByPeriodicityType(dayToCheck, timetablesEnabled, "HABITUAL");
    if (habitualTimesToApply) {
        return habitualTimesToApply;
    }
    //# 4- no timetable to apply
    return open247;
};

const checkTimeTableByPeriodicityType = (dayToCheck, timetablesEnabled, periodicityType) => {
    let timesToApply = [];
    //just OPEN timetables and periodicityType
    const openTimetablesEnabled = timetablesEnabled.filter((timetable) => {
        return timetable.type === "OPEN" && timetable.periodicityType.toUpperCase() === periodicityType;
    });

    let minimunRange = 99999;
    for (let index = 0; index < openTimetablesEnabled.length; index++) {
        const timetableToValidate = openTimetablesEnabled[index];
        const dateListTTV = extractDatesFromRange(timetableToValidate.dateList);
        // eslint-disable-next-line
        timetableToValidate.timetableDates.forEach((validateTimetableDate) => {
            const dateList = extractDatesFromRange(validateTimetableDate.dateList);
            if (
                validateTimetableDate.dateList &&
                validateTimetableDate.dateList.length > 0 &&
                isDateInRange(dayToCheck, dateList, timetableToValidate.dateType)
            ) {
                if (minutesOfShortestRange(dayToCheck, dateListTTV) < minimunRange) {
                    validateTimetableDate.timetableDateRanges.forEach((validateDateRange) => {
                        if (
                            timetableToValidate.dateType === "SPECIFIC_DAYS" ||
                            dateIsInWeekdayList(dayToCheck, validateDateRange.weekdayList)
                        ) {
                            //save timesRanges to apply
                            timesToApply = [];
                            minimunRange = minutesOfShortestRange(dayToCheck, dateListTTV);
                            const timeRange = extractHoursFromRange(validateDateRange.timeRanges);
                            timeRange.forEach((tR) => timesToApply.push(tR));
                        }
                    });
                }
            } else if (
                minimunRange === 99999 &&
                (!validateTimetableDate.dateList || validateTimetableDate.dateList.length === 0)
            ) {
                validateTimetableDate.timetableDateRanges.forEach((validateDateRange) => {
                    if (dateIsInWeekdayList(dayToCheck, validateDateRange.weekdayList)) {
                        //save times to apply
                        const timeRange = extractHoursFromRange(validateDateRange.timeRanges);
                        timeRange.forEach((tR) => timesToApply.push(tR));
                    }
                });
            }
        });
    }
    if (timesToApply.length > 0) {
        return getTimestoApply(timesToApply);
    } else {
        return null;
    }
};

const getTimestoApply = (timeRange) => {
    if (!Array.isArray(timeRange)) {
        timeRange = extractHoursFromRange(timeRange);
    }
    let openingTime = [];
    if (timeRange.length === 0) {
        openingTime.push({
            start: "00:00",
            end: "23:59",
        });
    } else {
        timeRange.forEach((range) => {
            openingTime = openingTime.concat(range);
        });
    }
    if (openingTime) {
        openingTime.sort(orderOpeningArray);
    }
    return {
        openingTime: openingTime,
        status: openingTime && nowIsBetweenHours(openingTime) ? "open" : "close",
    };
};

const minutesOfShortestRange = (dateList, dateRanges) => {
    if (!dateRanges) {
        return null;
    }
    let minutes = null;
    dateRanges.forEach((range) => {
        if (new window.ZDate(range.start) <= dateList && new window.ZDate(range.end) >= dateList) {
            const cMinutes = diffMinutes(new window.ZDate(range.end), new window.ZDate(range.start));
            if (!minutes || cMinutes < minutes) {
                minutes = cMinutes;
            }
        }
    });
    return minutes;
};

const diffMinutes = (dt2, dt1) => {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.round(diff);
};

function orderOpeningArray(a, b) {
    if (a.start < b.start) {
        return -1;
    }
    if (a.start > b.start) {
        return 1;
    }
    return 0;
}

const nowIsBetweenHours = (hoursRange) => {
    if (!hoursRange) {
        return false;
    }
    const cHour = new window.ZDate().getHours();
    const cMinutes = new window.ZDate().getMinutes();
    let isBetween = false;

    hoursRange.forEach((range) => {
        let timeParts = range.start.split(":");
        const startHour = parseInt(timeParts[0]);

        timeParts = range.end.split(":");
        const endHour = parseInt(timeParts[0]);
        const endMinutes = parseInt(timeParts[1]);
        if ((startHour <= cHour && endHour > cHour) || (endHour === cHour && cMinutes <= endMinutes)) {
            isBetween = true;
        }
    });

    return isBetween;
};

export const createSlots = (
    start,
    end,
    reservationInSlots,
    reservationDate,
    min4Slot,
    slotsPerBooking,
    maxCapacity,
    peopleToReserve
) => {
    const isToday = checkIfIsToday(reservationDate);
    const slots = [];
    while (start < end) {
        if (isToday && start.hours() <= new window.ZDate().getHours()) {
            start.add(min4Slot, "m");
            continue;
        }
        const _reservationTime = `${start.hours()}:${start.minutes() < 10 ? `0${start.minutes()}` : start.minutes()}`;
        let _slotEnabled = true;
        const _slotReserved = getSlotOccupancy(reservationDate, start, reservationInSlots, min4Slot, slotsPerBooking);
        let _nextSlotReserved = getNextSlotsOccupancy(
            reservationDate,
            start,
            reservationInSlots,
            min4Slot,
            slotsPerBooking
        );

        //percent capacity of slot

        //se mira que las reservas de los "slotsPerBooking" anteriores y posteriores
        //a la hora del slot no superen el aforo máximo incluyendo el número de personas
        //indicado para la reserva
        if (
            _slotReserved >= maxCapacity ||
            _slotReserved + parseInt(peopleToReserve) > maxCapacity ||
            _nextSlotReserved >= maxCapacity ||
            _nextSlotReserved + parseInt(peopleToReserve) > maxCapacity
        ) {
            _slotEnabled = false;
        }
        slots.push({
            time: _reservationTime,
            enabled: _slotEnabled,
            percentCapacity: parseInt((_slotReserved * 100) / maxCapacity),
        });
        start.add(min4Slot, "m");
    }
    return slots;
};

const getSlotOccupancy = (reservationDate, start, reservationInSlots, min4Slot, slotsPerBooking) => {
    let _slotReserved = 0;

    const _reservationDate = new window.ZDate(reservationDate.setHours(start.hours(), start.minutes(), 0));
    let timesToCheck = [];
    for (let index = 0; index < slotsPerBooking; index++) {
        timesToCheck.push(new window.ZDate(_reservationDate.getTime() - index * min4Slot * 60000).getTime());
    }
    reservationInSlots.forEach((reservation) => {
        if (reservation.status === 2 || reservation.status === 3) {
            return;
        }
        let reservationDate = new window.ZDate(reservation.bookingDate);
        const reservationHour = new window.ZDate(reservation.bookingDate).toLocaleTimeString("es-ES", {
            hour: "2-digit",
            minute: "2-digit",
        });

        const hourParts = reservationHour.split(":");
        reservationDate.setHours(hourParts[0]);
        reservationDate.setMinutes(hourParts[1]);

        if (timesToCheck.includes(reservationDate.getTime())) {
            _slotReserved += reservation.peopleAmount;
        }
    });
    return _slotReserved;
};

const getNextSlotsOccupancy = (reservationDate, start, reservationInSlots, min4Slot, slotsPerBooking) => {
    let _slotReserved = 0;
    const _reservationDate = new window.ZDate(reservationDate.setHours(start.hours(), start.minutes(), 0));
    let timesToCheck = [];
    for (let index = 0; index < slotsPerBooking; index++) {
        timesToCheck.push(new window.ZDate(_reservationDate.getTime() + index * min4Slot * 60000).getTime());
    }

    reservationInSlots.forEach((reservation) => {
        if (reservation.status === 2 || reservation.status === 3) {
            return;
        }
        let reservationDate = new window.ZDate(reservation.bookingDate);
        const reservationHour = new window.ZDate(reservation.bookingDate).toLocaleTimeString("es-ES", {
            hour: "2-digit",
            minute: "2-digit",
        });

        const hourParts = reservationHour.split(":");
        reservationDate.setHours(hourParts[0]);
        reservationDate.setMinutes(hourParts[1]);
        if (timesToCheck.includes(reservationDate.getTime())) {
            _slotReserved += reservation.peopleAmount;
        }
    });

    return _slotReserved;
};

const checkIfIsToday = (someDate) => {
    const today = new window.ZDate();
    return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
    );
};

export const validatePeopleInSlot = (
    people,
    reservationDate,
    start,
    bookings,
    min4Slot,
    slotsPerBooking,
    maxCapacity
) => {
    if (people > maxCapacity) {
        return false;
    }

    const _slotReserved = getSlotOccupancy(reservationDate, start, bookings, min4Slot, slotsPerBooking);
    let _nextSlotReserved = getNextSlotsOccupancy(reservationDate, start, bookings, min4Slot, slotsPerBooking);

    //percent capacity of slot

    //se mira que las reservas de los "slotsPerBooking" anteriores y posteriores
    //a la hora del slot no superen el aforo máximo incluyendo el número de personas
    //indicado para la reserva
    if (
        _slotReserved >= maxCapacity ||
        _slotReserved + parseInt(people) > maxCapacity ||
        _nextSlotReserved >= maxCapacity ||
        _nextSlotReserved + parseInt(people) > maxCapacity
    ) {
        return false;
    }
    return true;
};
