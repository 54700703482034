import React from "react";
import { DefaultLanguage, DeleteLanguage } from "components/Section/Settings/LanguagesComponents";
import _ from "lodash";

export const arrangeLanguages = (data, languages, setLoadingRefresh) => {
    const tempArray = [];
    let lang = null;
    // eslint-disable-next-line
    data?.projectLangs?.results?.map((language) => {
        lang = languages !== null && languages.length > 0 ? _.find(languages, { id: language.languageRef }) : null;
        tempArray.push(languageStructure(lang, language?.languageRef, language?.isDefault, setLoadingRefresh));
    });
    return tempArray;
};

export const languageStructure = (lang, ref, isDefault, setLoadingRefresh) => {
    return {
        language: <p alt={`${ref} ${lang?.label}`}>{lang?.label}</p>,
        default: (
            <DefaultLanguage
                isDefault={isDefault}
                languageRef={ref}
                name={lang?.label}
                setLoadingRefresh={setLoadingRefresh}
            />
        ),
        delete: (
            <DeleteLanguage
                isDefault={isDefault}
                name={lang?.label}
                languageRef={ref}
                setLoadingRefresh={setLoadingRefresh}
            />
        ),
    };
};

export const getLanguagesList = (list, projectLangs, t) => {
    let langs = [];
    list.map((lang) => {
        langs.push({
            id: lang.ref,
            label: t("language:" + lang.ref),
            value: lang.ref,
            visible:
                projectLangs != null && projectLangs.length > 0 && _.some(projectLangs, { languageRef: lang.ref })
                    ? false
                    : true,
        });
        return lang;
    });
    return langs;
};
