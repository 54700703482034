import React from "react";
import { useSelector } from "react-redux";
//Components
import UseInputCheckboxEditSchedule from "./useInputCheckboxEditSchedule";
//Utils
import CleanUnnecessaryChars from "../../hooks/Utils/CleanUnnecessaryChars";

const UseInputWeekDaysEditSchedule = ({ validation, index }) => {
    const { langStrings } = useSelector((state) => state.ui);
    const { scheduleToUpdate } = useSelector((state) => state.schedules);
    const selectedWeekdays = getSelectedWeekdays(scheduleToUpdate, index);
    const position = index;
    //ISO 8601 standard Monday(1) Sunday (7)
    const weekLetters = [1, 2, 3, 4, 5, 6, 7];

    return (
        <div className="relative overflow-visible pt-4">
            {weekLetters.map((weekLetter, index) => (
                <UseInputCheckboxEditSchedule
                    data={weekLetter}
                    selected={selectedWeekdays.includes(weekLetter.toString())}
                    index={position}
                    key={index}
                />
            ))}
            {!validation ? (
                <p className="absolute schedule-weekday-warning text-red-100">
                    {langStrings["schedule-weekday-error"]}
                </p>
            ) : null}
            <span
                className={`absolute w-full rounded h-10 schedule-weekday-warning ${
                    !validation ? "border border-red-100" : ""
                }`}
            ></span>
        </div>
    );
};

const getSelectedWeekdays = (scheduleToUpdate, index) => {
    return CleanUnnecessaryChars(scheduleToUpdate.timetableDates[0].timetableDateRanges[index].weekdayList).split(",");
};

export default UseInputWeekDaysEditSchedule;
