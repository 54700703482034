import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DEVICE_FUNCTIONS } from "../../hooks/Utils/Hotel/Monitor/ListDevicesUtils";
import { useDispatch, useSelector } from "react-redux";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import UseRadio from "../useRadio";
import uuid from "react-uuid";
import UseLoading from "../Table/useLoading";
import { changeActionValues } from "../../actions/globalActions";

const UseModalChangeDeviceFunction = ({ index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { data } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const [optionsRadio, setOptionsRadio] = useState([]);
    const [optionSelected, setOptionSelected] = useState("");
    const [alertInfo, setAlertInfo] = useState({ showAlert: false, textAlertInfo: "" });

    const roomTvId = data?.params?.roomTVID ? data.params.roomTVID : 0;
    const model = data?.params?.model ? data.params.model : "";
    const kind = data?.params?.kind ? data.params.kind : "";
    const functionDevice = data?.params?.deviceFunctionData ? data.params.deviceFunctionData : {};

    const GET_AVAILABLE_FUNCTIONS = gql`
        query {
            deviceAvailableFunctions(roomTvID: ${roomTvId}, function: ${
        functionDevice && functionDevice.enumValue ? functionDevice.enumValue : DEVICE_FUNCTIONS.cast.enumValue
    }) {
                chromecast
                tv
                tvchromecast
            }
        }
    `;

    const [executeQuery, { data: dataFuncs, loading: loadingFuncs }] = useLazyQuery(GET_AVAILABLE_FUNCTIONS, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (functionDevice && functionDevice.enumValue) {
            setOptionSelected(functionDevice.enumValue);
        }
        // eslint-disable-next-line
    }, [functionDevice]);

    useEffect(() => {
        if (optionSelected) {
            dispatch(
                changeActionValues({
                    function_device: optionSelected,
                })
            );
        }
        // eslint-disable-next-line
    }, [optionSelected]);

    useEffect(() => {
        const optionsRadio = [];

        const availableFunctions = {
            chromecast: {
                value: DEVICE_FUNCTIONS.cast.enumValue,
                label: DEVICE_FUNCTIONS.cast.label,
                available: true,
                disabled: false,
            },
            tv: {
                value: DEVICE_FUNCTIONS.tv.enumValue,
                label: DEVICE_FUNCTIONS.tv.label,
                available: true,
                disabled: false,
            },
            tvchromecast: {
                value: DEVICE_FUNCTIONS.tvAndcast.enumValue,
                label: DEVICE_FUNCTIONS.tvAndcast.label,
                available: true,
                disabled: false,
            },
        };
        if (dataFuncs?.deviceAvailableFunctions) {
            Object.entries(dataFuncs?.deviceAvailableFunctions).forEach(([prop, val]) => {
                if (availableFunctions[prop]) {
                    availableFunctions[prop].available = val;
                    if (
                        (kind === "CHROMECAST" && (prop === "tvchromecast" || prop === "tv") && model === "V3") ||
                        (kind === "TV" && (prop === "chromecast" || prop === "tvchromecast"))
                    ) {
                        availableFunctions[prop].disabled = true;
                    }
                    optionsRadio.push(availableFunctions[prop]);
                }
            });

            const currentFunctionAvailable = optionsRadio.find((opt) => {
                return opt.value === optionSelected && opt.available;
            });
            const firtsCurrentFunctionAvailable = optionsRadio.find((opt) => {
                return opt.available;
            });
            const alertInfo = optionsRadio.filter((opt) => {
                return opt.available === false;
            });
            let textAlertInfo = "";
            alertInfo.forEach((item, index) => {
                textAlertInfo += `${t(getDeviceLabel(item.value))} ${alertInfo.length - 1 === index ? "" : ", "} `;
            });
            setAlertInfo({ showAlert: alertInfo.length > 0 ? true : false, textAlertInfo: textAlertInfo });
            if (currentFunctionAvailable) {
                setOptionSelected(currentFunctionAvailable.value);
            } else if (firtsCurrentFunctionAvailable) {
                setOptionSelected(firtsCurrentFunctionAvailable.value);
            }
        }
        setOptionsRadio(optionsRadio);
        // eslint-disable-next-line
    }, [dataFuncs]);

    const getDeviceLabel = (fun) => {
        if (fun === DEVICE_FUNCTIONS.cast.enumValue) {
            return DEVICE_FUNCTIONS.cast.label;
        } else if (fun === DEVICE_FUNCTIONS.tvAndcast.enumValue) {
            return DEVICE_FUNCTIONS.tvAndcast.label;
        } else if (fun === DEVICE_FUNCTIONS.tv.enumValue) {
            return DEVICE_FUNCTIONS.tv.label;
        }
    };

    return (
        <div className=" relative">
            <span>{`${t("select-device-function")} :`}</span>
            <div className=" mt-4 flex flex-col">
                {optionsRadio.map((el) => {
                    const key = uuid();
                    return (
                        <div key={key} className=" mt-2 inline-block w-auto" id={`container-option-${el.label}`}>
                            <UseRadio
                                id={`option-${el.label}`}
                                selected={optionSelected}
                                value={el.value}
                                handleClick={() => {
                                    if (el.available && !el.disabled) {
                                        setOptionSelected(el.value);
                                    }
                                }}
                                disabled={el.available === false || el.disabled}
                            />
                            <span
                                onClick={() => {
                                    if (el.available && !el.disabled) {
                                        setOptionSelected(el.value);
                                    }
                                }}
                                id={`option-label-${el.label}`}
                                className={` cursor-pointer ml-6 ${
                                    (!el.available && el.value !== optionSelected) || el.disabled
                                        ? " text-gray-500"
                                        : ""
                                } `}
                            >
                                {`${t(el.label)} ${
                                    el.value === DEVICE_FUNCTIONS.tv.enumValue ||
                                    el.value === DEVICE_FUNCTIONS.tvAndcast.enumValue
                                        ? "*"
                                        : ""
                                } `}
                            </span>
                        </div>
                    );
                })}
            </div>
            <p id="text-device-assign-type-function" className=" text-sm block mt-4">
                {t("device-assign-type-function")}{" "}
            </p>
            {alertInfo.showAlert ? (
                <div className=" flex mt-4" id="warning-tv-location-has-other-device">
                    <span className=" block mr-3 icon icon-warning text-orange-100 text-2xl "></span>
                    {t("device-funtion-cannot-be-changed", { device: alertInfo.textAlertInfo })}
                </div>
            ) : null}
            {loadingFuncs ? (
                <div className=" flex items-center justify-center absolute h-full w-full  bg-white top-0 left-0">
                    <UseLoading adjust={"contents"} />
                </div>
            ) : null}
        </div>
    );
};

export default UseModalChangeDeviceFunction;
