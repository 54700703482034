import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useDrag, useDrop } from "react-dnd";
import TopologyItem from "./TopologyItem";

//Actions
import { setExecuteQuery } from "../../../../actions/sectionActions";

const style = {
    float: "left",
    cursor: "move",
};
export const FloorCard = ({ id, floor, index, moveCard, handleClickFloor, activeFloor, parentSectionName }) => {
    //Actions
    const dispatch = useDispatch();
    const sectionName = `${parentSectionName}-floorCard-${floor.name}`;

    //States
    const [floorOver, setFloorOver] = useState();
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: "floorcard",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        drop(item) {
            dispatch(
                setExecuteQuery({
                    action: "topology-move-floor",
                    params: { floorId: item.id, newPos: item.index + 1 },
                })
            );
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: "floorcard",
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    return (
        <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
            <div
                id={`${sectionName}-container`}
                className="inline-flex"
                key={floor.id}
                data-id={floor.id}
                onClick={(e) => handleClickFloor(e)}
                onMouseEnter={(e) => setFloorOver(floor.id)}
                onMouseLeave={(e) => setFloorOver(null)}
            >
                <TopologyItem
                    data={{ ...floor, parentSectionName: sectionName }}
                    active={activeFloor === floor.id ? true : false}
                    showOptions={floorOver === floor.id}
                />
            </div>
        </div>
    );
};
