import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import Modal, { useModal } from "components/Modal";
import { useLanguages } from "hooks/GraphqlCalls/Settings/useLanguages";
import { LanguagesContext } from "components/Section/Settings/Languages";
import { Session } from "hooks/Utils/Session";
import Button from "components/Button";

export const Save = ({ setLoadingRefresh }) => {
    const { t } = useTranslation();
    const { selectedLanguage: languageRef } = useContext(LanguagesContext);
    const { saveLanguage } = useLanguages();
    return (
        <Button
            id="save"
            className="btn btn-blue"
            onClick={() => {
                setLoadingRefresh(true);
                saveLanguage(languageRef);
            }}
        >
            {t("save")}
        </Button>
    );
};

export const DeleteLanguage = ({ isDefault, name, languageRef, setLoadingRefresh }) => {
    const { open, close } = useModal();
    const { t } = useTranslation();
    const { deleteLanguage } = useLanguages();
    const isCorporate = Session.isCorporate();

    const handleDelete = () => {
        open(
            <Modal
                id="delete-room-group"
                title={`${name} - ${t("delete")}`}
                className="py-10 px-12"
                onClose={close}
                footer={
                    <footer className="flex w-full gap-4 mt-10 justify-end">
                        <Button id="cancel" design="white" onClick={close} className="font-bold">
                            {t("cancel")}
                        </Button>
                        <Button
                            id="alert-accept"
                            design="red"
                            onClick={() => {
                                deleteLanguage(languageRef);
                                setLoadingRefresh(true);
                                close();
                            }}
                        >
                            {t("delete")}
                        </Button>
                    </footer>
                }
            >
                <p className="mt-4">{t("language-confirm-deletion")}</p>
            </Modal>
        );
    };

    return (
        !isCorporate && (
            <Button id="delete" disabled={isDefault} onClick={handleDelete}>
                <Icon type="delete" size="2xl" className={isDefault && "text-gray-300"} />
            </Button>
        )
    );
};

export const DefaultLanguage = ({ isDefault, languageRef, name, setLoadingRefresh }) => {
    const { t } = useTranslation();
    const { open, close } = useModal();
    const { updateLanguage } = useLanguages();
    const isCorporate = Session.isCorporate();

    const handleMakeDefault = () => {
        open(
            <Modal
                id="delete-room-group"
                title={`${name} - ${t("make-default")}`}
                className="py-10 px-12 w-2/7"
                onClose={close}
                footer={
                    <footer className="flex w-full gap-4 mt-10 justify-end">
                        <Button id="cancel" design="white" onClick={close} className="font-bold">
                            {t("cancel")}
                        </Button>
                        <Button
                            id="alert-accept"
                            design="blue"
                            onClick={() => {
                                updateLanguage(languageRef);
                                setLoadingRefresh(true);
                                close();
                            }}
                        >
                            {t("accept")}
                        </Button>
                    </footer>
                }
            >
                <p className="mt-4">{t("language-confirm-update")}</p>
            </Modal>
        );
    };

    return isDefault ? (
        <p className="rounded py-1 px-4 m-1 text-white inline-block first-capital bg-zafiro-300">{t("default")}</p>
    ) : (
        !isCorporate && (
            <Button
                id="make-default"
                className="inline-block truncate first-capital cursor-pointer text-zafiro-600"
                onClick={handleMakeDefault}
            >
                {t("make-default")}
            </Button>
        )
    );
};
