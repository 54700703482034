import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gql } from "apollo-boost";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { useMutation } from "@apollo/react-hooks";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { removeApolloCacheKeys } from "../../Utils/Utils";
import { cleanAction } from "../../../actions/globalActions";
import { withApollo } from "@apollo/react-hoc";
import { useParams } from "react-router-dom";

export const DELETE_FONT_STYLE = "delete-font-style-permanently";

const FontsActions = ({ client }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { value, id } = useSelector((state) => state.ui.modalContent);
    const { fontRef } = useParams();
    const { executeQuery } = useSelector((state) => state.sectionContent);

    // mutation
    const [mutation, setMutation] = useState("mutation{checkIn}");
    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    // execute mutation
    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT);

    // Listeners
    useEffect(() => {
        if (executeQuery && executeQuery.action) {
            switch (executeQuery.action) {
                case DELETE_FONT_STYLE:
                    setMutation(`
                        mutation{
                            deleteLibraryFontStyle(
                                ref:["${fontRef}"]
                                style: "${value}"
                            ){ error {code msg} ok }}
                    `);
                    dispatch(showGlobalLoading(true));
                    removeApolloCacheKeys(client.cache, "Font");
                    setTimeout(function () {
                        executeMutation();
                    }, 100);
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (data && executeQuery && data.deleteLibraryFontStyle && data.deleteLibraryFontStyle.ok) {
            dispatch(setModalContent(false));
            dispatch(closeModal());
            dispatch(setExecuteQuery(null));
            dispatch(setRefreshContentData(true));
            dispatch(cleanAction());
            toast.success(t(executeQuery.customSuccessText ? executeQuery.customSuccessText : "operation-successful"));
        } else if (data && data.deleteLibraryFontStyle && !data.deleteLibraryFontStyle.ok) {
            dispatch(setExecuteQuery(null));
            dispatch(setModalContent(false));
            dispatch(closeModal());
            dispatch(showGlobalLoading(false));
            dispatch(cleanAction());
            toast.error(
                data.deleteLibraryFontStyle.error.code === "Conflict"
                    ? t("inuse-cannot-delete")
                    : data.deleteLibraryFontStyle.error.msg
            );
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(FontsActions);
