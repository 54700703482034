import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
//Components
import UseIcon from "../useIcon";

const UseSelectOption = ({ id, filterName, filterValue, icons, itemType, translatable, selected }) => {
    const { t } = useTranslation();

    //Data
    let styleSelected = "";

    //States
    const [isEvaluable, setIsEvaluable] = useState(false);

    //Actions
    if (selected === filterValue) {
        styleSelected = "font-bold text-blue-100";
    }

    let icon = {};
    if (icons) {
        icon = { name: filterName, itemType: itemType, values: filterValue };
    }
    //Listeners
    useEffect(() => {
        if ((filterName === "state" && itemType === "booking") || itemType === "roles") {
            setIsEvaluable(true);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div id={`select-option-${id}`} className={`t-select-option p-2 cursor-pointer ${styleSelected}`}>
                {icon ? <UseIcon icon={icon} type={itemType} adjust="t-icon-select" /> : null}
                <div className="first-capital">
                    {filterValue !== ""
                        ? translatable
                            ? isEvaluable
                                ? t(`${filterName}-${itemType}s-${filterValue.substring(0, 1)}`)
                                : filterValue === "disabled" && filterName === "state"
                                ? t("warning-chromecasts-4")
                                : t(`${filterValue}`)
                            : filterValue
                        : `-- ${t("{{allText, capitalize}}", { allText: t("all") })} --`}
                </div>
            </div>
        </>
    );
};

export default UseSelectOption;
