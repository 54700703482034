const DeleteCategoryModal = (category) => {
    const { id, name } = category;
    return [
        {
            id: id,
            title: ["delete"],
            value: name,
            text: "are-you-sure-you-want-to-delete-this-category",
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "erase",
                    style: "red",
                    action: "delete",
                },
            ],
        },
    ];
};

export default DeleteCategoryModal;
