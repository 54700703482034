import React from "react";
//Components
import UseButton from "./useButton";
import UseSectionTitle from "./useTitle";
import UseBreadcrumbs from "./useBreadcrumbs";
import UseSectionIntroduction from "./useSectionIntroduction";

const useSectionHeader = ({
    title,
    titleIcon = null,
    subTitle,
    buttonName,
    buttonColor,
    action,
    value,
    hideBreadcrumbs,
    customBreadCrumbs,
    breadcrumbsLimit,
    navToPreviousPage,
    navToSection,
    actionOnBack,
    introduction,
    noTranslate = false,
    noCapitalize = false,
    buttonAdjust,
    noParenthesisSubtitle = false,
    adjustSubtitle = "",
}) => {
    return (
        <div id="title-container" className="pb-8">
            {!hideBreadcrumbs ? (
                <UseBreadcrumbs limit={breadcrumbsLimit} customBreadCrumbs={customBreadCrumbs} />
            ) : null}
            <div className="flex justify-between">
                <UseSectionTitle
                    text={title}
                    icon={titleIcon}
                    subTitle={subTitle}
                    navToPreviousPage={navToPreviousPage}
                    navToSection={navToSection}
                    actionOnBack={actionOnBack}
                    noTranslate={noTranslate}
                    noCapitalize={noCapitalize}
                    noParenthesisSubtitle={noParenthesisSubtitle}
                    adjustSubtitle={adjustSubtitle}
                />
                {buttonName ? (
                    <UseButton
                        adjust={buttonAdjust}
                        buttonName={buttonName}
                        buttonColor={buttonColor}
                        action={action}
                        value={value}
                    />
                ) : null}
            </div>
            <div className="flex justify-between">
                {introduction ? <UseSectionIntroduction text={introduction} /> : null}
            </div>
        </div>
    );
};

export default useSectionHeader;
