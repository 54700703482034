import React, { useState, useEffect } from "react";
import UseSectionHeader from "../../../../../components/useSectionHeader";
import SettingsCard from "components/SettingsCard";
import { useTranslation } from "react-i18next";
import { CARDS_SETTINGS, SATISFACTION_QUESTION_TEMPLATE, toggleCardInEdition, UPDATE_SETTINGS } from "../utils";
import SettingsSatisfactionLevel from "./SettingsSatisfactionLevel";
import SettingsCardEmail from "./SettingsCardEmail";
import Translations from "components/Translations";
import { useSelector } from "react-redux";
import SettingsSatisfactionQuestion from "./SettingsSatisfactionQuestion";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { Session } from "hooks/Utils/Session";
import useFeedbackSettings from "./useFeedbackSettings";
import UpdateSettings from "./UpdateSettings";

const Settings = () => {
    const { t } = useTranslation();

    const [cardInEdition, setCardInEdition] = useState(null);
    const [EXECUTE, SET_EXECUTE] = useState({ ACTION: "", PARAMS: {} });

    const { data, refetch } = useFeedbackSettings();

    const [dataModified, setDataModified] = useState({});

    useEffect(() => {
        if (data?.getFeedbackSetting) {
            setDataModified(data.getFeedbackSetting);
        }
    }, [data]);

    const commonProps = {
        cardInEdition,
        setCardInEdition,
        dataModified,
        SET_EXECUTE,
        EXECUTE,
        refetch,
    };

    return (
        <div>
            <UseSectionHeader title="feedback-settings" />

            <div className=" w-full flex space-x-8">
                <div className=" w-1/2">
                    <SettingsCard
                        title={t("satisfaction-question")}
                        edit={{
                            id: "settings-edit-satisfaction-question",
                            onClick: () => {
                                toggleCardInEdition({
                                    card: CARDS_SETTINGS.SATISFACTION_QUESTION,
                                    setCardInEdition: setCardInEdition,
                                    cardInEdition: cardInEdition,
                                });
                                setDataModified(data?.getFeedbackSetting || {});
                            },
                        }}
                    >
                        <SettingsSatisfactionQuestion {...commonProps} />
                    </SettingsCard>
                </div>

                <div className=" w-1/2">
                    <SettingsCard
                        title={t("email-to-receive-user-feedback")}
                        toggle={{
                            id: `settings-allow-edit-send-email`,
                            checked: dataModified?.sendEmail,
                            disabled: false,
                            action: () => {
                                SET_EXECUTE({
                                    ACTION: UPDATE_SETTINGS.EMAIL,
                                    PARAMS: { EMAIL: dataModified?.sendEmail ? false : true },
                                });
                                setDataModified(data?.getFeedbackSetting || {});
                            },
                        }}
                    >
                        <SettingsSatisfactionLevel {...commonProps} />
                        <SettingsCardEmail {...commonProps} />
                    </SettingsCard>
                </div>
            </div>
            <UpdateSettings {...commonProps} />
        </div>
    );
};

export default Settings;
