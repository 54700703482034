import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirst } from "../../hooks/Utils/Utils";
import UseEditableField from "../../components/useEditableField";

import Parser from "hooks/Utils/Parser";
import { changeActionValues } from "../../actions/globalActions";

export const UseInputGroupDetail = ({ index, withSelect }) => {
    const dispatch = useDispatch();

    const { inputGroupData, tvInputs, tvInputID, roomTVID } = useSelector(
        (state) => state.ui.modalContent.inputs[index]
    );
    const actionValues = useSelector((state) => state.action.values);
    const { t } = useTranslation();

    const getDefaultTvInputId = () => {
        return tvInputs ? tvInputs.find((tvInput) => tvInput.isDefault).id : null;
    };

    const [tvGroupData, setTvGroupData] = useState(inputGroupData);
    const [tvInputsOptions, setTvInputsOptions] = useState();

    const getInputGroup = (tvInputId) => {
        if (!tvInputs) return {};

        if (tvInputId) {
            return tvInputs.find((tvInput) => tvInput.id === tvInputId) || {};
        } else {
            return tvInputs.find((tvInput) => tvInput.isDefault) || {};
        }
    };

    useEffect(() => {
        if (typeof tvInputID !== "undefined") {
            dispatch(changeActionValues({ "input-group": tvInputID || getDefaultTvInputId() }));
            dispatch(changeActionValues({ roomTVID: roomTVID }));
            setTvGroupData(getInputGroup(tvInputID));
        }
        if (tvInputs) {
            let options = [];
            tvInputs.map((tvInput) => options.push({ name: tvInput.name, id: tvInput.id }));
            setTvInputsOptions(options);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (actionValues?.["input-group"]?.[0]) {
            setTvGroupData(getInputGroup(actionValues["input-group"][0]));
        }
        // eslint-disable-next-line
    }, [actionValues]);

    const printInput = (input, index) => {
        return (
            <div key={index} className="mx-2 inline-flex border-b border-gray-200 py-2" id={`recipient_${index}`}>
                <div className="w-2/6 pr-2 my-auto">
                    <div className={`py-1  inline-block cursor-default`} style={{ maxWidth: "100%" }}>
                        {input.name}
                    </div>
                </div>
                <div className="w-2/6 pr-2 my-auto">
                    <div className={`rounded py-1 m-1 inline-block `} style={{ maxWidth: "100%" }}>
                        {input.input}
                    </div>
                </div>
                <div className="w-2/6 pr-2 my-auto">
                    <div className={`rounded py-1 px-2 m-1 inline-block`} style={{ maxWidth: "100%" }}>
                        {input.deviceType || "-"}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="">
            {withSelect && tvInputsOptions ? (
                <div className="mt-4 mb-8">
                    <UseEditableField
                        data={{
                            label: "input-group",
                            inEditionMode: true,
                            type: "select-with-search",
                            hideSearch: true,
                            oneSelected: true,
                            optionData: tvInputsOptions,
                            selectedIds: [tvInputID || getDefaultTvInputId()],
                        }}
                    ></UseEditableField>
                </div>
            ) : null}

            <div>{Parser(t("inputs-of", { name: `<strong>${tvGroupData ? tvGroupData.name : ""}</strong>` }))}:</div>
            <div
                id="inputs_header"
                className="grid grid-cols-3 grid-flow-row overflow-y-auto mt-4 pb-4 rounded border-b border-gray-200 font-bold text-lg"
                style={{}}
            >
                <div className="pl-2">{capitalizeFirst(t("name"))}</div>
                <div className="pl-2">{capitalizeFirst(t("inputs"))}</div>
                <div className="pl-2">{capitalizeFirst(t("connected-device"))}</div>
            </div>
            <div
                id="input_list"
                className="grid grid-cols-1 grid-flow-row overflow-y-auto rounded"
                style={{
                    height: "30vh",
                    gridAutoRows: "min-content",
                }}
            >
                {tvGroupData ? tvGroupData.inputs.map((input, index) => printInput(input, index)) : null}
            </div>
        </div>
    );
};
