import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "@apollo/react-hoc";
//Actions
import { showGlobalLoading, closeModal, setModalContent } from "../../../../actions/uiActions";
import { setLoading } from "../../../../actions/tableActions";
import { setRefreshContentData, setExecuteQuery } from "../../../../actions/sectionActions";
import { cleanAction, setActionValidations } from "../../../../actions/globalActions";

//Utils
import { toast } from "react-toastify";
import { escapeSpecialChars, removeApolloCacheKeys } from "../../../Utils/Utils";
import { createBrowserHistory } from "history";
import { Session } from "../../../Utils/Session";
import { useTranslation } from "react-i18next";

const UpdateTopology = ({ client, viewMode }) => {
    const { t } = useTranslation();
    const { executeQuery } = useSelector((state) => state.sectionContent);

    const actionData = useSelector((state) => state.action);

    //State
    const [mutation, setMutation] = useState("mutation{checkIn}");

    //Actions
    const dispatch = useDispatch();

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(err.message);

            let validations = [];
            validations["starter-room"] = false;
            dispatch(setActionValidations(validations));
            // toast.error(t("mutation-error"));
        },
    });

    useEffect(() => {
        let execute = false;
        let floorsQuery;
        let locationsQuery = "";
        if (executeQuery) {
            executeQuery.goBack = false;
            executeQuery.mutationName = "";
            execute = true;
            switch (executeQuery.action) {
                case "topology-add-room":
                    locationsQuery = "";
                    if (actionData.values["locations"]) {
                        for (let index = 1; index <= parseInt(actionData.values["tvs-per-room"]); index++) {
                            const suffix = `_${index}`;
                            locationsQuery += `{name:"${actionData.values[`location${suffix}`]}", CEC: ${
                                actionData.values[`location-cec${suffix}`]
                            }} `;
                        }
                        locationsQuery = `[${locationsQuery}]`;
                    }
                    setMutation(`
                        mutation{
                            roomSetup(
                                numberRooms:  ${actionData.values["number-of-rooms"]}
                                starterRoom:  ${actionData.values["starter-room"]}
                                floorID: ${executeQuery.params.floorId}
                                ${
                                    actionData.values["tvs-per-room"]
                                        ? `TVsPerRoom: ${actionData.values["tvs-per-room"]}`
                                        : ``
                                }
                                ${locationsQuery ? `Locations:${locationsQuery}` : ``}
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "roomSetup";
                    executeQuery.goBack = true;
                    break;
                case "topology-table-add-room":
                    locationsQuery = "";
                    if (actionData.values["locations"]) {
                        for (let index = 1; index <= parseInt(actionData.values["tvs-per-room"]); index++) {
                            const suffix = `_${index}`;
                            locationsQuery += `{name:"${actionData.values[`location${suffix}`]}", CEC: ${
                                actionData.values[`location-cec${suffix}`]
                            }} `;
                        }
                        locationsQuery = `[${locationsQuery}]`;
                    }
                    setMutation(`
                    mutation{
                        createRoom(
                            floorID: ${actionData.values["floor"][0]}
                            number:  ${actionData.values["room-number"]}
                            pmsNumber:  "${escapeSpecialChars(actionData.values["pms-number"])}"
                            name:  "${escapeSpecialChars(actionData.values["room-name"])}"
                            ${
                                actionData.values["tvs-per-room"]
                                    ? `TVsPerRoom: ${actionData.values["tvs-per-room"]}`
                                    : ``
                            }
                            ${locationsQuery ? `Locations:${locationsQuery}` : ``}
                            ){ error ok }}
                `);
                    executeQuery.mutationName = "createRoom";
                    executeQuery.goBack = !executeQuery.params.noGoBack;
                    break;

                case "topology-edit-room":
                    let roomtvsMutations = "";
                    const cRoomTvs =
                        executeQuery.params.roomtvs && executeQuery.params.roomtvs.length > 0
                            ? executeQuery.params.roomtvs
                            : [];
                    if (actionData.values["locations"]) {
                        if (parseInt(actionData.values["tvs-per-room"]) < cRoomTvs.length) {
                            for (
                                let index = cRoomTvs.length;
                                index > parseInt(actionData.values["tvs-per-room"]);
                                index--
                            ) {
                                roomtvsMutations += `deleteRoomTV${index} : deleteRoomTV(roomTVID: ${
                                    cRoomTvs[index - 1].id
                                }){error ok} `;
                            }
                        }
                        for (let index = 1; index <= parseInt(actionData.values["tvs-per-room"]); index++) {
                            const suffix = `_${index}`;
                            if (cRoomTvs.length >= index) {
                                roomtvsMutations += `updateRoomTV${index} : updateRoomTV(roomTvID: ${
                                    cRoomTvs[index - 1].id
                                }, location:{name:"${actionData.values[`location${suffix}`]}", CEC: ${
                                    actionData.values[`location-cec${suffix}`]
                                }}){error ok} `;
                            } else {
                                roomtvsMutations += `createRoomTV${index} : createRoomTV(roomID: ${
                                    executeQuery.params.roomId
                                }, location:{name:"${actionData.values[`location${suffix}`]}", CEC: ${
                                    actionData.values[`location-cec${suffix}`]
                                }}){error ok} `;
                            }
                        }
                    } else {
                        if (cRoomTvs && cRoomTvs.length > 0) {
                            cRoomTvs.map(
                                (cRoomTv, index) =>
                                    (roomtvsMutations += `deleteRoomTV${index} : deleteRoomTV(roomTVID: ${cRoomTv.id}){error ok} `)
                            );
                        }
                    }
                    setMutation(`
                        mutation{
                            updateRoom(
                                id: ${executeQuery.params.roomId}
                               name: "${escapeSpecialChars(actionData.values["room-name"])}"
                               pmsNumber: "${escapeSpecialChars(actionData.values["pms-number"])}"
                               number: ${actionData.values["room-number"]}
                            ){ error ok }

                            ${roomtvsMutations}
                        }
                    `);
                    executeQuery.mutationName = "updateRoom";
                    executeQuery.goBack = !executeQuery.params.noGoBack;
                    break;

                case "topology-delete-room":
                case "delete-topology-room":
                    setMutation(`
                    mutation{
                        deleteRoom(
                            id:  ${actionData.itemsAffected[0]}
                        ){ error ok }}
                `);
                    executeQuery.mutationName = "deleteRoom";
                    break;

                case "topology-delete-floor":
                    setMutation(`
                    mutation{
                        deleteFloor(
                            id:  ${actionData.itemsAffected[0]}
                        ){ error ok }}
                `);
                    executeQuery.mutationName = "deleteFloor";
                    break;

                case "topology-delete-wing":
                    setMutation(`
                    mutation{
                        deleteWing(
                            id:  ${actionData.itemsAffected[0]}
                        ){ error ok }}
                `);
                    executeQuery.mutationName = "deleteWing";
                    break;

                case "topology-edit-floorname":
                    setMutation(`
                    mutation{
                        updateFloor(
                            id:  ${actionData.itemsAffected[0]}
                            name:  "${escapeSpecialChars(actionData.values["topology-edit-floorname"])}"
                        ){ error ok }}
                `);
                    executeQuery.mutationName = "updateFloor";
                    break;

                case "topology-edit-wingname":
                    setMutation(`
                    mutation{
                        updateWing(
                            id:  ${actionData.itemsAffected[0]}
                            name:  "${escapeSpecialChars(actionData.values["topology-edit-wingname"])}"
                        ){ error ok }}
                `);
                    executeQuery.mutationName = "updateWing";
                    break;

                case "topology-edit-pmsnumber":
                    setMutation(`
                        mutation{
                            updateWing(
                                id:  ${actionData.itemsAffected[0]}
                                pmsNumber:  "${actionData.values["topology-edit-pmsnumber"]}"
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "updateWing";
                    break;

                case "topology-add-floor":
                    floorsQuery = "";
                    // eslint-disable-next-line
                    executeQuery.params.floorsData.map((floorData) => {
                        locationsQuery = "";
                        if (floorData.Locations) {
                            floorData.Locations.map(
                                (LocationData) =>
                                    (locationsQuery += `{name:"${LocationData.name}", CEC: ${LocationData.cec}} `)
                            );
                            locationsQuery = `[${locationsQuery}]`;
                        }
                        floorsQuery += `{
                            Locations: ${locationsQuery}
                            order:${floorData.order}
                            name:"${escapeSpecialChars(floorData.name)}"
                            numberRooms:${floorData.numberOfRooms}
                            starterRoom:${floorData.starterRoom}
                            TVsPerRoom:${floorData.Locations.length}
                        }${executeQuery.params.floorsData.length > 1 ? "," : ""}`;
                    });
                    setMutation(`
                        mutation{
                            floorSetup(
                                wingID:  ${executeQuery.params.wingId}
                                floors:[
                                    ${floorsQuery}
                                ]
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "floorSetup";
                    executeQuery.goBack = true;
                    break;

                case "topology-add-wing":
                    floorsQuery = "";
                    // eslint-disable-next-line
                    executeQuery.params.floorsData.map((floorData) => {
                        locationsQuery = "";
                        if (floorData.Locations) {
                            floorData.Locations.map(
                                (LocationData) =>
                                    (locationsQuery += `{name:"${LocationData.name}", CEC: ${LocationData.cec}} `)
                            );
                            locationsQuery = `[${locationsQuery}]`;
                        }
                        floorsQuery += `{
                            Locations: ${locationsQuery}
                            order:${floorData.order}
                            name:"${escapeSpecialChars(floorData.name)}"
                            numberRooms:${floorData.numberOfRooms}
                            starterRoom:${floorData.starterRoom}
                            TVsPerRoom:${floorData.Locations.length}
                        }${executeQuery.params.floorsData.length > 1 ? "," : ""}`;
                    });
                    setMutation(`
                        mutation{
                            wingSetup(
                                ref:  "${Session.getProject()?.ref}"
                                order:  ${actionData.values["topology-order"]}
                                name:  "${escapeSpecialChars(actionData.values["wing-name"])}"
                                pmsNumber:  "${actionData.values["pms-number"] ? actionData.values["pms-number"] : ""}"
                                floors:[
                                    ${floorsQuery}
                                ]
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "wingSetup";
                    executeQuery.goBack = true;
                    break;

                case "topology-move-wing":
                    setMutation(`
                        mutation{
                            moveWing(
                                id:  ${executeQuery.params.wingId}
                                pos:  ${executeQuery.params.newPos}
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "moveWing";
                    break;

                case "topology-move-floor":
                    setMutation(`
                        mutation{
                            moveFloor(
                                id:  ${executeQuery.params.floorId}
                                pos:  ${executeQuery.params.newPos}
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "moveFloor";
                    break;

                default:
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (viewMode === "map") {
                dispatch(showGlobalLoading(true));
            } else if (viewMode === "table") {
                dispatch(setLoading(true));
            }

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].isOk || data[executeQuery.action].ok)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].isOk || data[executeQuery.mutationName].ok)))
        ) {
            toast.success(t("operation-successful"));
            if (executeQuery.goBack) {
                const history = createBrowserHistory();
                history.back();
            }
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }
            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            if (!executeQuery.goBack || viewMode === "table") {
                dispatch(setRefreshContentData(true));
            }

            //remove guest list cache to show updated info
            removeApolloCacheKeys(client.cache, "all");
        } else if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].errorMsg || data[executeQuery.action].error)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].errorMsg || data[executeQuery.mutationName].error)))
        ) {
            const errorMsg =
                data[executeQuery.action] && data[executeQuery.action].error
                    ? data[executeQuery.action].error
                    : data[executeQuery.mutationName] && data[executeQuery.mutationName].error
                    ? data[executeQuery.mutationName].error
                    : null;

            if (errorMsg === "Couldn't delete room because there is at least one stayguest checked-in in it") {
                toast.error(t("cannot delete occupied room"));
            } else {
                toast.error(errorMsg ? errorMsg : t("mutation-error"));
            }
            dispatch(showGlobalLoading(false));
            dispatch(setExecuteQuery(null));
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateTopology);
