import React from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import { useDeleteSignageContents } from "components/Section/Design/Advertising/graphql/useSignages";
import { toast } from "react-toastify";
import Button from "components/Button";

const ModalContent = (props) => {
    const { t } = useTranslation();

    const { name, signageId, id, refetch } = props;

    const { deleteSignageContents, loading } = useDeleteSignageContents({
        onCompleted: () => {
            toast.success(t("operation-successful"));
            props.onClose();
            refetch();
        },
        onError: (error) => {
            toast.success(t(error));
            props.onClose();
            refetch();
        },
    });

    console.log(name);

    return (
        <Modal
            title={`${name} - ${t("delete")}`}
            className={"w-3/12 p-10"}
            footer={
                !loading ? (
                    <>
                        <Button design="blue-outline" onClick={() => props?.close()} id="modal-button-cancel">
                            {t("cancel")}
                        </Button>
                        <Button
                            design="red"
                            onClick={() => {
                                deleteSignageContents({
                                    variables: {
                                        signageID: signageId,
                                        ids: [id],
                                    },
                                });
                            }}
                            id="modal-button-delete"
                        >
                            {t("delete")}
                        </Button>
                    </>
                ) : null
            }
        >
            {loading ? (
                <Loading />
            ) : (
                <div className=" pb-6 ">{t("are-you-sure-you-want-to-delete-this-content-from-the-sequence")}</div>
            )}
        </Modal>
    );
};

export const useDeleteSignageContent = () => {
    const { open, close } = useModal();

    const openModal = (props) => {
        const newProps = {
            close,
            ...props,
        };

        open(<ModalContent {...newProps} />);
    };

    return {
        open: openModal,
        close,
    };
};

export default useDeleteSignageContent;
