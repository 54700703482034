import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { capitalizeFirst } from "hooks/Utils/Utils";
import UseSelectWithSearch from "components/useSelectWithSearch";
import { GENDERS } from "components/Section/Customers/Precheckin/ReservationDetails/utils";
import { GetNationalitiesOptions } from "hooks/Utils/NationalityUtils";
import { GetLanguageOptions } from "hooks/Utils/LanguagesUtils";
import UseInputDate from "components/useInputDate";
import UseCustomPhoneInput from "components/Inputs/UseCustomPhoneInput";

import { changeActionValues } from "actions/globalActions";

import { GlobalContext } from "contexts/Global";
import Icon from "components/Icon";

const DropdownSection = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <div className={`bg-white ${isOpen ? "rounded-t-md" : "rounded-md"} mb-2`}>
                <div className=" flex gap-2 items-center p-4">
                    <div
                        className={`icon-chevron  mt-2 mr-2 cursor-pointer text-gray-800 text-xl animated ${
                            isOpen && "-rotate-180"
                        }`}
                        id="chevron-dropdown"
                        onClick={() => {
                            setIsOpen((val) => !val);
                        }}
                    />
                    <h1 className="font-bold text-base">{capitalizeFirst(t(title))}</h1>
                </div>
                <div className={"border-b border-gray-300"}></div>
            </div>
            {isOpen ? <>{children}</> : null}
        </>
    );
};

const RequiredInfoIcon = ({ field, isRequired }) => {
    const { preCheckInSettingsFields } = useSelector((state) => state.sectionContent);
    const { t } = useTranslation();

    const required =
        isRequired !== undefined
            ? isRequired
            : preCheckInSettingsFields?.[field]?.enabled && preCheckInSettingsFields?.[field]?.required;

    if (required) {
        return (
            <Icon
                className="ml-2 -mt-1"
                type="info"
                tooltip={t("mandatory-information-requested-from-the-guest-on-the-self-check-in-form")}
                size={1.5}
            />
        );
    }

    return null;
};
const UseModalEditCompanion = ({ index }) => {
    const { t } = useTranslation();
    const { lang } = useContext(GlobalContext);
    const { validations } = useSelector((state) => state.action);
    const { data } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { customFields, customFieldsChildren, preCheckInSettingsFields } = useSelector(
        (state) => state.sectionContent
    );
    const [companionData, setCompanionData] = useState({});
    const prefixId = "modal-edit-companion";
    const dispatch = useDispatch();
    const rooms = data?.rooms || null;

    const roomsSelect = data?.rooms?.map((room) => {
        return {
            id: String(room.RoomID),
            name: room.number,
        };
    });

    const changeValuesHandler = (key, val) => {
        const companionDataCopy = { ...companionData };
        dispatch(changeActionValues({ [key]: val }));
        companionDataCopy[key] = val;
        setCompanionData(companionDataCopy);
    };

    useEffect(() => {
        if (rooms) {
            changeValuesHandler("rooms", rooms);
        } else {
            changeValuesHandler("rooms", []);
        }
    }, [rooms]);

    useEffect(() => {
        if (data?.isEdition) {
            const initialCompanionData = {};
            initialCompanionData.id = data.id || null;
            initialCompanionData.name = data.name || null;
            initialCompanionData.surname = data.surname || null;
            initialCompanionData.title = data.title || null;
            initialCompanionData.nationality = data.countryRef || null;
            initialCompanionData.language = data.languageRef || null;
            initialCompanionData.gender = data.gender || null;
            initialCompanionData.birthday = data.birthDate || null;
            initialCompanionData.email = data.email || null;
            initialCompanionData.mobilePhone = data.phone || null;
            initialCompanionData.room = String(data?.currentRooms?.[0]?.roomID) || null;
            if (customFields) {
                customFields.forEach((element) => {
                    initialCompanionData[element.ref] =
                        data?.customFieldsInformation.find((val) => val.Ref === element.ref)?.Value || "";
                });
            }

            dispatch(changeActionValues({ ...initialCompanionData }));
            composeNoMutableInitialData(initialCompanionData);
            setCompanionData(initialCompanionData);
        }
    }, [data]);

    const composeNoMutableInitialData = (initialData) => {
        const newObj = {};
        for (let [key, value] of Object.entries(initialData)) {
            newObj[`initial_${key}`] = value;
        }
        dispatch(changeActionValues({ ...newObj }));
    };

    console.log(preCheckInSettingsFields);

    return (
        <div>
            <div className=" -mt-6 mb-8">
                {t(data?.isEdition ? "edit-the-following-informations" : "add-the-following-informations")}
            </div>
            <div className="grid grid-cols-2 gap-6">
                <div className={`${customFields?.length > 0 ? "" : "col-span-2"}`}>
                    <div className=" col-span-2 mb-3">
                        <p className="font-bold text-base">{t("main-information")}</p>
                    </div>
                    <div className=" flex w-full">
                        <div className=" flex w-1/2 mr-3  flex-col ">
                            <span className=" font-bold text-sm block mb-2">{capitalizeFirst(t("title"))}</span>
                            <input
                                id={`${prefixId}-title-input`}
                                type="text"
                                className=" h-11 px-2 rounded bg-gray-200"
                                placeholder={`${t("ex")} ${"Mr."}`}
                                value={companionData?.title}
                                onChange={(e) => {
                                    changeValuesHandler("title", e.target.value);
                                }}
                            />
                        </div>
                        <div className=" flex w-1/2 ml-3 opacity-0  flex-col ">*</div>
                    </div>
                    <div className=" flex w-full mt-5">
                        <div className=" flex w-1/2 mr-3  flex-col ">
                            <span className=" font-bold text-sm block mb-2">{capitalizeFirst(t("name"))} *</span>
                            <input
                                id={`${prefixId}-name-input`}
                                type="text"
                                className={`h-11 px-2 rounded bg-gray-200 ${
                                    validations["name"] === false
                                        ? "border border-red-100"
                                        : "border border-transparent"
                                }`}
                                value={companionData?.name}
                                placeholder={`${t("ex")} ${"John"}`}
                                onChange={(e) => {
                                    changeValuesHandler("name", e.target.value);
                                }}
                            />
                        </div>
                        <div className=" flex w-1/2 ml-3  flex-col ">
                            <span className=" font-bold text-sm block mb-2">{capitalizeFirst(t("surname"))} *</span>
                            <input
                                id={`${prefixId}-surname-input`}
                                type="text"
                                className={`h-11 px-2 rounded bg-gray-200 ${
                                    validations["surname"] === false
                                        ? "border border-red-100"
                                        : "border border-transparent"
                                }`}
                                value={companionData?.surname}
                                placeholder={`${t("ex")} ${"Scott"}`}
                                onChange={(e) => {
                                    changeValuesHandler("surname", e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className=" flex w-full mt-5">
                        <div className=" flex w-1/2 mr-3  flex-col ">
                            <div className=" flex items-center">
                                <span className=" font-bold text-sm block mb-2">
                                    {capitalizeFirst(t("nationality"))}
                                </span>
                                <RequiredInfoIcon field="nationality" />
                            </div>

                            {/* precheckinFields?.nationality?.required */}
                            <UseSelectWithSearch
                                data={{
                                    id: `${prefixId}-nationality-select`,
                                    name: `${prefixId}-nationality-select`,
                                    optionData: GetNationalitiesOptions(),
                                    selectPlaceHolder: t("select-nationality"),
                                    oneSelected: true,
                                    noSelect: true,
                                    adjust: "h-11",
                                    selectedTextAdjust: "hidden",
                                    optionsAdjust: "mt-11",
                                    selectedIds: companionData.nationality
                                        ? [companionData.nationality.toUpperCase()]
                                        : null,
                                    onChange: (val) => {
                                        changeValuesHandler("nationality", val?.toLowerCase());
                                    },
                                }}
                            />
                        </div>
                        <div className=" flex w-1/2 ml-3  flex-col ">
                            <span className=" font-bold text-sm block mb-2">{capitalizeFirst(t("language"))}</span>
                            <UseSelectWithSearch
                                data={{
                                    id: `${prefixId}-language-select`,
                                    name: `${prefixId}-language-select`,
                                    optionData: GetLanguageOptions(),
                                    selectPlaceHolder: t("select-language"),
                                    oneSelected: true,
                                    noSelect: true,
                                    adjust: "h-11",
                                    selectedTextAdjust: "hidden",
                                    optionsAdjust: "mt-11",
                                    selectedIds: companionData.language ? [companionData.language] : null,
                                    onChange: (val) => {
                                        changeValuesHandler("language", val);
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <div className=" flex w-full mt-5">
                        <div className=" flex w-1/2 mr-3  flex-col ">
                            <div className=" flex items-center">
                                <span className=" font-bold text-sm block mb-2">{capitalizeFirst(t("gender"))}</span>
                                <RequiredInfoIcon field="gender" />
                            </div>
                            <UseSelectWithSearch
                                data={{
                                    id: `${prefixId}-gender-select`,
                                    name: `${prefixId}-gender-select`,
                                    optionData: GENDERS,
                                    selectPlaceHolder: t("select-a-gender"),
                                    oneSelected: true,
                                    noSelect: true,
                                    adjust: "h-11",
                                    hideSearch: true,
                                    selectedTextAdjust: "hidden",
                                    optionsAdjust: "mt-11",
                                    selectedIds: companionData.gender ? [companionData.gender] : null,
                                    onChange: (val) => {
                                        changeValuesHandler("gender", val);
                                    },
                                }}
                            />
                        </div>
                        <div className=" flex w-1/2 ml-3  flex-col ">
                            <div className=" flex items-center">
                                <span className=" font-bold text-sm block mb-2">
                                    {capitalizeFirst(t("date-of-birth"))}
                                </span>
                                <RequiredInfoIcon field="birthdate" />
                            </div>
                            <UseInputDate
                                data={{
                                    name: `${prefixId}-date-of-birth`,
                                    id: `${prefixId}-date-of-birth`,
                                    fullwidth: true,
                                    nonInitialDate: true,
                                    date: companionData.birthday,
                                    placeholder: "dd/mm/yyyy",
                                    noCapitalizePlaceholder: true,
                                    styleAdjust: `h-11`,
                                    // styleAdjust: `h-11 ${!data?.[index]?.birthDate ? " text-gray-600" : ""}`,
                                    // onChange: (date) => {
                                    //     updateField({ field: "birthDate", val: date });
                                    // },
                                    onChange: (val) => {
                                        console.log(val);
                                        changeValuesHandler("birthday", val);
                                    },
                                }}
                            />
                        </div>
                    </div>

                    <div className=" flex w-full mt-5">
                        <div className=" flex w-1/2 mr-3  flex-col  ">
                            <div className=" flex items-center">
                                <span className=" font-bold text-sm block mb-2">
                                    {capitalizeFirst(t("email-address"))}
                                </span>
                                <RequiredInfoIcon field="email" />
                            </div>
                            <input
                                type="text"
                                id={`${prefixId}-email-input`}
                                className={` h-11 px-2 rounded bg-gray-200 ${
                                    validations["email"] === false
                                        ? "border border-red-100"
                                        : "border border-transparent"
                                } `}
                                onChange={(e) => {
                                    changeValuesHandler("email", e.target.value);
                                }}
                                value={companionData.email}
                                placeholder={`smith@gmail.com`}
                            />
                        </div>
                        <div className=" flex w-1/2 ml-3  flex-col ">
                            <div className=" flex items-center">
                                <span className=" font-bold text-sm block mb-2">
                                    {capitalizeFirst(t("mobile-phone"))}
                                </span>
                                <RequiredInfoIcon field="phone" />
                            </div>
                            <UseCustomPhoneInput
                                onChange={(val) => {
                                    changeValuesHandler("mobilePhone", val);
                                }}
                                value={companionData.mobilePhone}
                                id={`${prefixId}-custom-phone-input`}
                                height="2.75rem"
                            />
                        </div>
                    </div>

                    <div className=" flex w-full mt-5">
                        <div className=" flex w-1/2 mr-3  flex-col ">
                            <span className=" font-bold text-sm block mb-2">{capitalizeFirst(t("room"))} *</span>

                            <UseSelectWithSearch
                                data={{
                                    id: `${prefixId}-room-select`,
                                    name: `room-select`,
                                    optionData: roomsSelect.filter((room) => {
                                        return room.id !== companionData.room;
                                    }),
                                    selectPlaceHolder: t("select-room"),
                                    oneSelected: true,
                                    noSelect: true,
                                    adjust: "h-11",
                                    hideSearch: true,
                                    selectedTextAdjust: "hidden",
                                    optionsAdjust: "mt-11",
                                    selectedIds: companionData.room ? [companionData.room] : null,
                                    // onChange: (val) => {
                                    //     updateField({ field: "gender", val: val });
                                    // },
                                    onChange: (val) => {
                                        changeValuesHandler("room", val);
                                    },
                                }}
                            />
                        </div>
                        <div className=" flex w-1/2 ml-3  flex-col opacity-0 ">*</div>
                    </div>
                </div>
                {(customFields?.length > 0 || customFieldsChildren?.length > 0) && (
                    <div>
                        <div className=" grid grid-cols-2 gap-x-4 gap-y-4">
                            <div className=" col-span-2">
                                <p className="font-bold text-base">{t("additional-information")}</p>
                            </div>
                            <div className=" col-span-2">
                                <DropdownSection title={"general"}>
                                    <div className=" grid grid-cols-2 gap-4">
                                        {customFields
                                            ?.sort((a, b) => {
                                                return a.order - b.order;
                                            })
                                            .map((val, index) => {
                                                const title =
                                                    val.names.find((val) => val.lang == lang).name ||
                                                    val.names.find((val) => val.name != "").name;

                                                console.log(val);

                                                return (
                                                    <div>
                                                        <div className=" flex items-center">
                                                            <span className=" font-bold text-sm block mb-2">
                                                                {capitalizeFirst(title)}
                                                            </span>
                                                            <RequiredInfoIcon
                                                                isRequired={val?.enabled && val?.required}
                                                            />
                                                        </div>
                                                        <input
                                                            id={`${prefixId}-custom-input-${index}`}
                                                            type="text"
                                                            className={`h-11 w-full px-2 rounded bg-gray-200 ${
                                                                validations[val.ref] === false
                                                                    ? "border border-red-100"
                                                                    : "border border-transparent"
                                                            }`}
                                                            value={companionData[val.ref]}
                                                            onChange={(e) => {
                                                                changeValuesHandler(val.ref, e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </DropdownSection>
                                <DropdownSection title={"children"}>
                                    <div className=" grid grid-cols-2 gap-4">
                                        {customFieldsChildren
                                            ?.sort((a, b) => {
                                                return a.order - b.order;
                                            })
                                            .map((val, index) => {
                                                const title =
                                                    val.names.find((val) => val.lang == lang).name ||
                                                    val.names.find((val) => val.name != "").name;

                                                return (
                                                    <div>
                                                        <div className=" flex items-center">
                                                            <span className=" font-bold text-sm block mb-2">
                                                                {capitalizeFirst(title)}
                                                            </span>
                                                            <RequiredInfoIcon
                                                                isRequired={val?.enabled && val?.required}
                                                            />
                                                        </div>
                                                        <input
                                                            id={`${prefixId}-custom-input-${index}`}
                                                            type="text"
                                                            className={`h-11 w-full px-2 rounded bg-gray-200 ${
                                                                validations[val.ref] === false
                                                                    ? "border border-red-100"
                                                                    : "border border-transparent"
                                                            }`}
                                                            value={companionData[val.ref]}
                                                            onChange={(e) => {
                                                                changeValuesHandler(val.ref, e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </DropdownSection>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UseModalEditCompanion;
