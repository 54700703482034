import React from "react";
import { useTranslation } from "react-i18next";

function useInputTextArea({
    id,
    name,
    ph,
    value,
    adjust,
    action,
    disabled = false,
    rows,
    changeAction,
    noResize = false,
    noTranslatePh,
}) {
    const { t } = useTranslation();
    const handleValue = (e) => {
        e.preventDefault();
        if (changeAction) {
            changeAction(e.currentTarget.value, id);
        }
    };

    const handleBlur = (e) => {
        e.preventDefault();
        if (action) {
            action(e.currentTarget.value, id);
        }
    };

    return (
        <textarea
            id={id}
            rows={rows}
            className={`w-full bg-gray-200 rounded p-2 ${noResize ? "resize-none" : ""} ${
                name === "name" ? " w-full" : " long"
            } ${adjust} ${disabled ? "text-gray-700 bg-gray-300" : ""}`}
            placeholder={getPh({ ph, name, noTranslatePh, t })}
            disabled={disabled}
            onChange={handleValue}
            onBlur={handleBlur}
        >
            {value}
        </textarea>
    );
}

export default useInputTextArea;

const getPh = (props = {}) => {
    const { ph, name, noTranslatePh, t } = props;
    let response = "";
    if (ph) {
        response = noTranslatePh ? ph : t(ph);
    } else if (name) {
        response = noTranslatePh ? name : t(name);
    }
    return response;
};
