import React from "react";
import { useSelector } from "react-redux";
import uuid from "react-uuid";

const UseTagCellCustomTooltip = ({ cellData }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);

    //order tags to show in same orden
    if (cellData && !cellData.noSorted) {
        cellData.value.sort(function (a, b) {
            if (a[0] > b[0]) {
                return 1;
            }
            if (a[0] < b[0]) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
    }
    return (
        <div key={uuid()}>
            {cellData.value.map((val, index) => (
                <div key={`tag_${val}_${index}`} className="inline">
                    <div
                        className={`rounded py-1 px-4 m-1 text-white inline-block ${
                            cellData.noCapitalice ? "" : "first-capital"
                        } `}
                        style={{ backgroundColor: val[2], whiteSpace: "break-spaces" }}
                        key={index}
                        data-tip={val[3].content}
                        data-for="dangerous-html-tooltip"
                    >
                        {langStrings[val[1]] ? langStrings[val[1]] : val[1]}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default UseTagCellCustomTooltip;
