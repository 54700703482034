import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { GetScheduleMutationDates } from "../../hooks/Utils/GetScheduleMutationDates";
import { validateSchedule, validateWeekdays } from "../../hooks/Utils/ScheduleManagement";
import { cleanUnnecessaryChars } from "../../hooks/Utils/CleanUnnecessaryChars";
//Input components
import UseInputTimeEditSchedule from "./useInputTimeEditSchedule";
import UseInputWeekDaysEditSchedule from "../Schedule/useInputWeekDaysEditSchedule";
import UseButton from "../useButton";
//Actions
import {
    cleanBatchSchedules,
    cleanViewSchedules,
    setScheduleToUpdate,
    cancelScheduleUpdate,
    setMutationRefresh,
} from "../../actions/scheduleActions";
import { cleanAction } from "../../actions/globalActions";
import {
    showEditPunctualSchedule,
    restorePeriodicScheduleBackup,
    setWizardAllowNext,
    showEditCommonSchedule,
} from "../../actions/sectionActions";
//Notifications
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Session } from "../../hooks/Utils/Session";

const UseFormCommonUpdate = ({ setEditCommonSchedule }) => {
    const { t } = useTranslation();
    //Store data
    const { itemsAffected, weekDays } = useSelector((state) => state.action);
    const { scheduleToUpdate } = useSelector((state) => state.schedules);
    const { periodicEventsData } = useSelector((state) => state.sectionContent);
    const currentEvent = getCurrentEvent(itemsAffected[0], periodicEventsData);

    //Validation States
    const [scheduleValidationMS, setScheduleValidationMS] = useState(true);
    const [scheduleValidationME, setScheduleValidationME] = useState(true);
    const [scheduleValidationAS, setScheduleValidationAS] = useState(true);
    const [scheduleValidationAE, setScheduleValidationAE] = useState(true);
    const [weekDaysValidation, setWeekDaysValidation] = useState(true);
    //Mutation states
    const [mutation, setMutation] = useState(
        //Default mutation to prevent gql conversion to throw error
        `mutation {updateTimetable(areaID: 0 name: " " isEnabled:true periodicityType: " " type: " "){ error id ok}}`
    );
    const { mutationRefresh, lastScheduleEdition } = useSelector((state) => state.schedules);
    //CurrentData states
    const [timetableToEdit, setTimetableToEdit] = useState(false);
    //API
    const UPDATE_PUNCTUAL_SCHEDULE = gql`
        ${mutation}
    `;
    const [executeMutation] = useMutation(UPDATE_PUNCTUAL_SCHEDULE);

    //Actions
    const dispatch = useDispatch();

    const handleClickSaveAll = () => {
        //Validation
        const valResult = validateHours(timetableToEdit.timetableDates[0].timetableDateRanges[0].timeRanges);
        setScheduleValidationMS(valResult.morningStart);
        setScheduleValidationME(valResult.morningEnd);
        setScheduleValidationAS(valResult.afternoonStart);
        setScheduleValidationAE(valResult.afternoonEnd);
        setWeekDaysValidation(validateWeekdays(weekDays));
        if (
            !valResult.morningStart ||
            !valResult.morningEnd ||
            !valResult.afternoonStart ||
            !valResult.afternoonEnd ||
            !weekDaysValidation
        ) {
            toast.error(t("check form"));
        } else {
            //Save data
            executeMutation();
            toast.success(t("common schedule updated"));
            setEditCommonSchedule(false);
            dispatch(showEditPunctualSchedule(false));
            dispatch(setWizardAllowNext(true));
            dispatch(showEditCommonSchedule(false));
        }
    };

    const handleClickCancelAll = () => {
        //Cancel all process
        dispatch(restorePeriodicScheduleBackup(JSON.parse(Session.getSessionProp("periodicScheduleBackup"))));
        setEditCommonSchedule(false);
        dispatch(showEditCommonSchedule(false));
        dispatch(cancelScheduleUpdate());
        dispatch(cleanAction());
        dispatch(cleanBatchSchedules());
        dispatch(cleanViewSchedules());
        toast.success(t("exit-wo-save"));
        dispatch(setWizardAllowNext(true));
    };

    //Listeners
    useEffect(() => {
        //Load current event values in schedules store
        let formattedEvent = currentEvent;
        formattedEvent.timetableDates[0].dateList = "";
        dispatch(setScheduleToUpdate(formattedEvent));
        Session.setSessionProp("periodicScheduleBackup", JSON.stringify(periodicEventsData));
        setTimetableToEdit(scheduleToUpdate);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setWeekDaysValidation(validateWeekdays(weekDays));
        // eslint-disable-next-line
    }, [weekDays]);

    useEffect(() => {
        if (scheduleToUpdate && scheduleToUpdate.timetableDates && scheduleToUpdate.timetableDates.length > 0) {
            setTimetableToEdit(scheduleToUpdate);
        }

        if (scheduleToUpdate && (mutationRefresh === undefined || mutationRefresh)) {
            //Set mutation
            setMutation(
                getMutation(
                    scheduleToUpdate.ID,
                    scheduleToUpdate.dateType,
                    GetScheduleMutationDates(scheduleToUpdate.timetableDates),
                    scheduleToUpdate.isEnabled
                )
            );
            dispatch(setMutationRefresh(false));
        }
        // eslint-disable-next-line
    }, [scheduleToUpdate, mutationRefresh]);

    useEffect(() => {
        if (lastScheduleEdition && timetableToEdit.timetableDates) {
            const valResult = validateHours(timetableToEdit.timetableDates[0].timetableDateRanges[0].timeRanges);
            setScheduleValidationMS(valResult.morningStart);
            setScheduleValidationME(valResult.morningEnd);
            setScheduleValidationAS(valResult.afternoonStart);
            setScheduleValidationAE(valResult.afternoonEnd);
            setWeekDaysValidation(validateWeekdays(weekDays));
        }
        // eslint-disable-next-line
    }, [lastScheduleEdition]);

    return (
        <>
            <div className="w-full block bg-white p-8">
                <>
                    <div className="pt-4 pb-8">
                        {timetableToEdit ? (
                            <>
                                <div className="w-full block mb-4">
                                    {timetableToEdit.timetableDates[0].timetableDateRanges.map((_, index) => (
                                        <span className="w-full" key={index}>
                                            <div className="inline-block w-6/12">
                                                <div className="inline-block">
                                                    <UseInputTimeEditSchedule
                                                        dateId="morningStart"
                                                        validation={scheduleValidationMS}
                                                        index={index}
                                                    />{" "}
                                                    -{" "}
                                                    <UseInputTimeEditSchedule
                                                        dateId="morningEnd"
                                                        validation={scheduleValidationME}
                                                        index={index}
                                                    />{" "}
                                                    /{" "}
                                                    <UseInputTimeEditSchedule
                                                        dateId="afternoonStart"
                                                        validation={scheduleValidationAS}
                                                        index={index}
                                                    />{" "}
                                                    -{" "}
                                                    <UseInputTimeEditSchedule
                                                        dateId="afternoonEnd"
                                                        validation={scheduleValidationAE}
                                                        index={index}
                                                    />
                                                </div>
                                            </div>
                                            <div className="inline-block w-6/12">
                                                <UseInputWeekDaysEditSchedule
                                                    validation={weekDaysValidation}
                                                    index={index}
                                                />
                                            </div>
                                        </span>
                                    ))}
                                </div>
                            </>
                        ) : null}
                    </div>
                </>
            </div>
            <div className="w-full flex justify-end h-10 mt-8 mr-4 mb-4">
                <div className="w-auto inline-block mr-4" onClick={handleClickCancelAll}>
                    <UseButton buttonName="exit-unsaved" buttonColor="btn-blue-outline" adjust="px-8" />
                </div>
                <div className="w-auto inline-block" onClick={handleClickSaveAll}>
                    <UseButton buttonName="save-and-close" buttonColor="btn-blue" adjust="px-8" />
                </div>
            </div>
        </>
    );
};

const getCurrentEvent = (itemId, punctualEventsData) => {
    //Returns current event data to update proccess
    let res;
    // eslint-disable-next-line
    punctualEventsData.currentData.map((punctualEventData) => {
        if (punctualEventData.ID === itemId) {
            res = punctualEventData;
        }
    });
    return res;
};

//Mutation functions
const getMutation = (areaID, dateType, timetableDates, status) => {
    //Returns dynamic mutation
    //ISO 8601 standard Monday(1) Sunday (7)
    return `mutation {
      updateTimetable(
        name: ""
        type: "OPEN"
        isEnabled: ${status}
        dateType: "${dateType}"
        timetableDates: ${timetableDates}
        id: ${areaID}
        ){ error id ok}}`;
};

const validateHours = (hours) => {
    const schedules = extractHours(hours);
    return validateSchedule({
        morningStart: schedules[0],
        morningEnd: schedules[1],
        afternoonStart: schedules[2],
        afternoonEnd: schedules[3],
    });
};

const extractHours = (hoursString) => {
    let schedules = cleanUnnecessaryChars(hoursString);
    schedules = schedules.split('"').join("");
    schedules = schedules.split("[").join("");
    schedules = schedules.split("]").join("");
    schedules = schedules.split(",").join("-");
    schedules = schedules.split("-");
    return schedules;
};
export default UseFormCommonUpdate;
