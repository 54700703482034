import React from "react";

import { Header, Examples, getRandomIcon } from "sections/playground";

import Button from "components/Button";
import Icon from "components/Icon";

import DropdownButton from "components/DropdownButton";

const VARIANTS = ["PRIMARY", "SECONDARY", "DANGER", "BASIC", "DARK", "LIGHT"];
const EXAMPLES = ["DEFAULT", "ICON", "ICONS", "DISABLED"];
export const TYPES = [
    { id: "REGULAR", title: "Regular", text: "Button" },
    { id: "NARROW", title: "Narrow", text: "Button" },
    { id: "LINK", title: "Link", variants: ["PRIMARY", "SECONDARY"], text: "Text link" },
    { id: "ACTIONS", title: "Actions", text: "Actions" },
];

const Buttons = ({ section }) => {
    const types = TYPES.map((type) => ({
        ...type,
        variants: type.variants ?? VARIANTS,
        examples: type.examples ?? EXAMPLES,
    }));
    return (
        <>
            <Header title="Buttons">
                Buttons are used to trigger actions and links. Buttons can contain a combination of text and icon.
            </Header>
            <Examples options={types?.filter((s) => !section || s?.id === section)} display={DisplayExample} />
        </>
    );
};

const DisplayExample = ({ type: t, variant, example }) => {
    const type = t?.id;
    const text = t?.text;

    const equivDesign = {
        PRIMARY: type === "LINK" ? "link" : "blue",
        SECONDARY: type === "LINK" ? "classic-link" : "blue-outline",
        DANGER: "red",
        BASIC: "basic",
        DARK: "dark-blue",
        LIGHT: "white",
    };

    const design = equivDesign[variant.split(" ")?.[0]];
    const id = `${type}-${variant}-${example}-${design}`;
    const disabled = example === "DISABLED";

    const hasIcon = example === "ICON" || example === "ICONS";
    const hasIcons = example === "ICONS";

    const actionClickTest = (e) => console.log("Click on action", e);

    return type === "ACTIONS" ? (
        <DropdownButton
            id={id}
            design={design}
            disabled={disabled}
            label={
                hasIcon ? (
                    <div id={`${id}-label`} className="flex items-center space-x-2" onClick={actionClickTest}>
                        <Icon type={getRandomIcon()} />
                        <span>{text}</span>
                        {hasIcons && <Icon type={getRandomIcon()} />}
                    </div>
                ) : (
                    {
                        id: `${id}-label`,
                        label: text,
                        onClick: actionClickTest,
                    }
                )
            }
            options={[
                {
                    id: `${id}-1`,
                    label: "Actions 2",
                    onClick: actionClickTest,
                },
                {
                    id: `${id}-2`,
                    label: "Actions 3",
                    onClick: actionClickTest,
                },
                {
                    id: `${id}-3`,
                    label: "Actions 4",
                    onClick: actionClickTest,
                },
            ]}
        />
    ) : (
        <Button
            id={id}
            design={design}
            narrow={type === "NARROW"}
            disabled={disabled}
            onClick={(e) => console.log("Click on button", e)}
        >
            {hasIcon ? (
                <>
                    <Icon type={getRandomIcon()} />
                    <span>{text}</span>
                    {hasIcons && <Icon type={getRandomIcon()} />}
                </>
            ) : (
                text
            )}
        </Button>
    );
};

export default Buttons;
