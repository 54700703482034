import { getPXDefaultSizeForPreviewMobile } from "hooks/Utils/Design/FontUtils";

export const calculateMarginLeftPreview = () => {
    const elements = ["#containerAl", "#appearancesToolbarContainer", "[data-id='toolbarAppearance']"];
    return elements.reduce((acc, selector) => acc + getElementSize(selector).width, 0);
};

export const calculateMarginTopPreviewMobile = () => {
    const elements = ["#appearancesHeadMenu", "#appearances-toolbar-show-menu"];
    return elements.reduce((acc, selector) => acc + getElementSize(selector).height, 5);
};

export const getElementSize = (queryElem) => {
    const defaultSize = { width: 0, height: 0 };

    if (!queryElem) return defaultSize;

    const element = document.querySelector(queryElem);
    if (!element) return defaultSize;

    const { width, height } = element.getBoundingClientRect();
    return { width, height };
};

export const containerStyles = () => {
    return {
        padding: `0px ${getPXDefaultSizeForPreviewMobile(3)}px`,
    };
};

export const appearancePreviewText = () => {
    return {
        fontSize: `${getPXDefaultSizeForPreviewMobile(1.3)}px`,
        padding: `${getPXDefaultSizeForPreviewMobile(0.4)}px 0`,
    };
};

export const stylesLogo = ({ color = { bg: "bg-gray-200", text: "text-gray-200", hex: "E9EDF0" } } = {}) => {
    return {
        height: `${getPXDefaultSizeForPreviewMobile(9.4)}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: `${getPXDefaultSizeForPreviewMobile(4.4)}px`,
        marginBottom: `${getPXDefaultSizeForPreviewMobile(3.4)}px`,
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23${color.hex}' stroke-width='2' stroke-dasharray='10%2c 11' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
        borderRadius: `${getPXDefaultSizeForPreviewMobile(0.4)}px`,
        fontSize: `${getPXDefaultSizeForPreviewMobile(3.4)}px`,
        fontFamily: `'Roboto', Arial, sans-serif`,
        color: `#${color.hex}`,
    };
};

export const stylesLogoRefContainer = () => {
    return {
        display: "flex",
        justifyContent: "center",
        marginTop: `${getPXDefaultSizeForPreviewMobile(4.4)}px`,
        marginBottom: `${getPXDefaultSizeForPreviewMobile(3.4)}px`,
    };
};

export const stylesLogoRef = () => {
    return { height: `${getPXDefaultSizeForPreviewMobile(9.4)}px`, objectFit: `contain` };
};

export const stylesLogin = ({ type = "container", colorLogin = "#fff" }) => {
    if (type === "container") {
        return {
            padding: `${getPXDefaultSizeForPreviewMobile(1.4)}px ${getPXDefaultSizeForPreviewMobile(1.9)}px `,
            backgroundColor: `rgb(238,238,238)`,
            borderRadius: `${getPXDefaultSizeForPreviewMobile(0.8)}px`,
        };
    } else if (type === "text") {
        return {
            fontSize: `${getPXDefaultSizeForPreviewMobile(1.6)}px`,
            fontFamily: `'Roboto', Arial, sans-serif`,
            color: "#323232",
            marginBottom: `${getPXDefaultSizeForPreviewMobile(2.5)}px`,
            textAlign: "center",
            fontWeight: "500",
        };
    } else if (type === "googleButton") {
        return {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
            padding: `${getPXDefaultSizeForPreviewMobile(0.6)}px 0`,
            fontSize: `${getPXDefaultSizeForPreviewMobile(1.6)}px`,
            fontFamily: `'Roboto', Arial, sans-serif`,
            textAlign: "center",
            fontWeight: "500",
            marginBottom: `${getPXDefaultSizeForPreviewMobile(2.3)}px`,
            borderRadius: `${getPXDefaultSizeForPreviewMobile(0.3)}px`,
            strokeWidth: `1px`,
            stroke: `rgba(50, 50, 50, 0.20)`,
            border: "1px solid #dadce0",
            boxShadow: "0 1px 2px 0 rgba(0,0,0,0.16), 0 1px 2px 0 rgba(0,0,0,0.23)",
        };
    } else if (type === "accessEmail") {
        return {
            fontSize: `${getPXDefaultSizeForPreviewMobile(1.4)}px`,
            fontFamily: `'Roboto', Arial, sans-serif`,
            textAlign: "center",
            marginBottom: `${getPXDefaultSizeForPreviewMobile(1.4)}px`,
        };
    } else if (type === "emailButton") {
        return {
            fontWeight: "500",
            padding: `${getPXDefaultSizeForPreviewMobile(0.6)}px 0`,
            fontSize: `${getPXDefaultSizeForPreviewMobile(1.6)}px`,
            fontFamily: `'Roboto', Arial, sans-serif`,
            textAlign: "center",
            marginBottom: `${getPXDefaultSizeForPreviewMobile(1.6)}px`,
            backgroundColor: colorLogin,
            borderRadius: `${getPXDefaultSizeForPreviewMobile(0.3)}px`,
            boxShadow: `0px 1px 4px 0px rgba(50, 50, 50, 0.20)`,
            color: `#fff`,
        };
    } else if (type === "notAccount") {
        return {
            fontSize: `${getPXDefaultSizeForPreviewMobile(1.4)}px`,
            fontFamily: `'Roboto', Arial, sans-serif`,
            textAlign: "center",
            borderRadius: `${getPXDefaultSizeForPreviewMobile(0.3)}px`,
        };
    } else if (type === "register") {
        return {
            fontSize: `${getPXDefaultSizeForPreviewMobile(1.4)}px`,
            fontFamily: `'Roboto', Arial, sans-serif`,
            textAlign: "center",
            color: colorLogin,
        };
    } else if (type === "googleIcon") {
        return {
            width: `${getPXDefaultSizeForPreviewMobile(1.6)}px`,
            height: `${getPXDefaultSizeForPreviewMobile(1.6)}px`,
            marginRight: `${getPXDefaultSizeForPreviewMobile(1)}px`,
        };
    } else {
        return {};
    }
};

export const enterButtonStyles = () => {
    return {
        margin: `${getPXDefaultSizeForPreviewMobile(3)}px ${getPXDefaultSizeForPreviewMobile(5)}px`,
        padding: `${getPXDefaultSizeForPreviewMobile(0.6)}px 0`,
        fontSize: `${getPXDefaultSizeForPreviewMobile(1.6)}px`,
        fontFamily: `'Roboto', Arial, sans-serif`,
        textAlign: "center",
        marginBottom: `${getPXDefaultSizeForPreviewMobile(1.6)}px`,
        backgroundColor: "#323232",
        borderRadius: `${getPXDefaultSizeForPreviewMobile(0.3)}px`,
        fontWeight: `500`,
        color: `#fff`,
    };
};

export const cDeviceMobile = {
    grid: {
        cols: 45,
        rows: 100,
        __typename: "ContentGridSize",
    },
    name: "Mobile",
    orientation: "PORTRAIT",
    res: {
        h: 640,
        w: 360,
        __typename: "ContentResolution",
    },
    type: "Mobile",
};

export const getLogoColorConstrast = (rgba) => {
    const values = rgba.match(/\d+/g).map(Number);
    const [r, g, b, a] = values;
    const rNorm = r / 255.0;
    const gNorm = g / 255.0;
    const bNorm = b / 255.0;

    const luminance = 0.2126 * rNorm + 0.7152 * gNorm + 0.0722 * bNorm;

    return luminance > 0.5
        ? { bg: "bg-gray-800", text: "text-gray-800", hex: "576575" }
        : { bg: "bg-gray-200", text: "text-gray-200", hex: "E9EDF0" };
};
