import React, { useCallback, useEffect, useRef, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Carousel from "../../../../../../Carousel/Carousel";
import update from "immutability-helper";
import { setExecuteQuery } from "../../../../../../../actions/sectionActions";
import {
    UPDATE_PRODUCT_ASSETS,
    UPDATE_PRODUCT_CREATE_ASSET,
    UPDATE_PRODUCT_DELETE_ASSET,
} from "../../../../../../../hooks/Utils/Integrations/ShopSettingsUtils";
import { ACCEPTEDFORMATS, checkFileType } from "../../../../../../../hooks/Utils/FileUtils";
import { getAssetUrl } from "../../../../../../../hooks/Utils/SalesUtils";
import { toast } from "react-toastify";
import UseButton from "../../../../../../useButton";
import ReactTooltip from "components/ReactTooltip";
import ProductSettingsFileCard from "./ProductSettingsFileCard";
import _ from "lodash";
import { SalesContextDeprecated } from "contexts/Sales";

function ProductSettingsFilesCard({ data, disabled }) {
    const dispatch = useDispatch();

    //Consts&states
    const { id } = data;

    const { t } = useTranslation();

    const { data: dataContext, dataWithVendureToken } = useContext(SalesContextDeprecated);
    const token = dataContext?.channel?.token;

    const [loading, setLoading] = useState(true);
    const [assets, setAssets] = useState(null);
    const sectionName = "product-files";
    const inputFile = useRef(null);

    //arrangeData
    const arrangeData = (data) => {
        return [
            ...(data?.product?.featuredAsset
                ? [
                      {
                          id: data.product.featuredAsset.id,
                          preview: data.product.featuredAsset.preview,
                          featuredAsset: true,
                      },
                  ]
                : []),
            ...(data?.product?.assets
                ? data.product.assets.filter((a) => a?.id !== data?.product?.featuredAsset?.id)
                : []),
        ];
    };

    useEffect(() => {
        if (dataContext && !dataWithVendureToken) {
            let assets = null;
            assets = arrangeData(dataContext);
            setAssets(assets);
            setLoading(false);
        }
    }, [dataContext]);

    const handleAddAsset = (e) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setLoading(true);
            Array.from(files).forEach((file) => {
                const validImage = checkFileType(file, ACCEPTEDFORMATS["image"]);
                if (!validImage) {
                    toast.error(`${file.name} ${t("error-file-not-valid-dataType")}`);
                    setLoading(false);
                    return;
                } else if (!file.size || (validImage && file.size / 1000 / 1000 > 11)) {
                    toast.error(`${file.name} ${t("error-exceeds-allowed-size")}`);
                    return;
                } else {
                    dispatch(
                        setExecuteQuery({
                            action: UPDATE_PRODUCT_CREATE_ASSET,
                            params: {
                                productId: id,
                                token,
                                file,
                            },
                        })
                    );
                }
            });
        }
    };

    const handleDeleteAsset = (assetID, index) => {
        setLoading(true);
        const isFeaturedAsset = index === 0;
        const hasMultipleAssets = assets?.length > 1;
        const newFeaturedAssetId = isFeaturedAsset && hasMultipleAssets ? assets[1].id : null;
        dispatch(
            setExecuteQuery({
                action: UPDATE_PRODUCT_DELETE_ASSET,
                params: {
                    id: assetID,
                    productId: id,
                    newFeaturedAssetId,
                },
            })
        );
    };

    const handleMoveAssets = (index) => {
        let assetsClone = [...assets];
        assetsClone.forEach((object) => {
            delete object["opacity"];
        });
        setAssets(assetsClone);
        setLoading(true);
        let newFeaturedAssetId = assetsClone[0].id;
        dispatch(
            setExecuteQuery({
                action: UPDATE_PRODUCT_ASSETS,
                params: {
                    productId: id,
                    assetIds: _.map(assetsClone, "id"),
                    newFeaturedAssetId: newFeaturedAssetId,
                },
            })
        );
    };

    const moveAssetCard = useCallback(
        (dragIndex, hoverIndex, noAddOpacity = false) => {
            let dragCard = assets[dragIndex];
            dragCard.opacity = !noAddOpacity ? 0 : null;
            setAssets(
                update(assets, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                })
            );
        },
        [assets]
    );

    //renders

    //response
    return (
        <div className="w-full mb-2">
            <div className="flex w-full text-left mb-5 align-middle items-center">
                <span className="font-bold text-gray-900 text-1xl">{t("files")}</span>
                <span
                    className="icon icon-info ml-3 text-1xl text-gray-800"
                    data-tip
                    data-for={`${sectionName}-info`}
                ></span>
                <ReactTooltip id={`${sectionName}-info`}>
                    {t("{{capitalize, capitalize}}", { capitalize: t("image-recommended-resolution") })}
                </ReactTooltip>
            </div>
            <div className="flex w-full">
                <div className="w-48 mr-4">
                    <div className="w-48 h-48 border-8 rounded-md border-zafiro-400 border-opacity-25 relative m-auto">
                        {assets && assets.filter((asset) => asset.featuredAsset === true).length > 0 ? (
                            <div
                                className={`w-full h-full ${loading ? `opacity-50` : ``}`}
                                style={{
                                    backgroundImage: `url('${getAssetUrl(
                                        assets.filter((asset) => asset.featuredAsset === true)[0].preview
                                    )}')`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                }}
                            ></div>
                        ) : (
                            <div className="w-full h-full flex align-middle items-center">
                                <span className="w-full text-center text-gray-600">{t("not-image-yet")}</span>
                            </div>
                        )}
                        <div className="absolute bottom-0 w-full h-8 z-10 bg-white bg-opacity-70">
                            <p className="text-center w-full h-full pt-1">{t("featured-image")}</p>
                        </div>
                    </div>
                </div>
                <div className=" " style={{ width: "calc(100% - 13rem)" }}>
                    <div className="w-full h-full flex items-end">
                        <div className="w-full">
                            <div className="w-full flex items-center justify-between mb-2">
                                <span className={`${loading ? `opacity-50` : ``}`}>
                                    {t("x-images-uploaded", { count: assets ? assets.length : 0 })}
                                </span>
                                {disabled ? null : (
                                    <>
                                        <UseButton
                                            id={"product-settings-add-image-button"}
                                            buttonName="add-image"
                                            buttonColor="btn-blue"
                                            action={(e) => inputFile.current.click()}
                                            disabled={disabled || loading}
                                        />
                                        <input
                                            type="file"
                                            id={`${sectionName}-upload-input`}
                                            className="hidden"
                                            disabled={disabled}
                                            onChange={handleAddAsset}
                                            ref={inputFile}
                                            accept="image/png, image/jpeg, image/webp, image/avif"
                                        />
                                    </>
                                )}
                            </div>
                            <div className="w-full h-28 bg-white rounded items-center align-middle relative">
                                {assets && assets.length > 0 ? (
                                    <DndProvider backend={HTML5Backend}>
                                        <Carousel widthItems={120}>
                                            {assets.map((asset, index) => (
                                                <>
                                                    {index === 0 && (
                                                        <div className="w-28 h-28 bg-zafiro-400 bg-opacity-25 absolute left-0"></div>
                                                    )}
                                                    <ProductSettingsFileCard
                                                        asset={asset}
                                                        index={index}
                                                        disabled={disabled}
                                                        moveCard={moveAssetCard}
                                                        handleDeleteAsset={() => {
                                                            handleDeleteAsset(asset.id, index);
                                                        }}
                                                        handleMoveAssets={(index) => {
                                                            handleMoveAssets(index);
                                                        }}
                                                    />
                                                </>
                                            ))}
                                        </Carousel>
                                    </DndProvider>
                                ) : (
                                    <div className="w-28 h-28 bg-zafiro-400 bg-opacity-25 p-1 inline-block">
                                        <div className="w-full h-full flex align-middle items-center">
                                            <span className="w-full text-center text-gray-600 text-sm">
                                                {t("not-image-yet")}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductSettingsFilesCard;
