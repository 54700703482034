import { useEffect, useState } from "react";
import {
    UPDATE_ACCESS_CONDITIONS,
    UPDATE_COMMERCIAL_POLICY,
    UPDATE_PRIVACY_POLICY,
} from "../../../components/Section/Settings/PolicyAndConditions";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { gql } from "apollo-boost";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const UpdatePrivacyAndConditions = ({
    selectMutation,
    refetchDataConditions,
    setSelectMutation,
    accessConditions,
    currentHtml,
    langTagSelected,
    askMarketing,
    privacyPolicy,
}) => {
    const [execute, setExecute] = useState(false);
    const [mutation, setMutation] = useState("mutation{checkIn}");
    const { actionData } = useSelector((state) => state.action);
    const { t } = useTranslation();

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation] = useMutation(MUTATION_OBJECT, {
        errorPolicy: "all",
        onError(err) {
            toast.error(err);
            console.log(err);
        },
        onCompleted() {
            toast.success(t("operation-successful"));
        },
    });

    const buildObjectByLang = (currentObject, newHTML, lang) => {
        let object = currentObject ? currentObject : {};
        object[lang] = plainHTML(newHTML);
        return JSON.stringify(JSON.stringify(object));
    };

    const plainHTML = (code) => {
        return (code + "").replace("\n", "");
    };

    useEffect(() => {
        if (execute === true) {
            executeMutation();
            setTimeout(() => {
                refetchDataConditions();
            }, 1000);
            setExecute(false);
        }
        //eslint-disable-next-line
    }, [execute]);

    useEffect(() => {
        switch (selectMutation) {
            case UPDATE_ACCESS_CONDITIONS:
                const AC = buildObjectByLang(accessConditions, currentHtml, langTagSelected);
                setMutation(`
                mutation {
                    updateConditions(
                      accessConditions: ${AC}
                    ) {
                      id
                      ok
                      error
                    }
                  }
                `);
                setExecute(true);
                break;
            case UPDATE_PRIVACY_POLICY:
                const PP = buildObjectByLang(privacyPolicy, currentHtml, langTagSelected);
                setMutation(`
                mutation {
                    updateConditions(
                      privacyPolicy: ${PP}
                    ) {
                      id
                      ok
                      error
                    }
                  }
                `);
                setExecute(true);
                break;
            case UPDATE_COMMERCIAL_POLICY:
                const CP =
                    actionData &&
                    (actionData[UPDATE_COMMERCIAL_POLICY] === false || actionData[UPDATE_COMMERCIAL_POLICY] === true)
                        ? actionData[UPDATE_COMMERCIAL_POLICY]
                        : askMarketing;
                setMutation(`
                    mutation {
                        updateConditions(
                            askMarketing: ${CP}
                        ) {
                        id
                        ok
                        error
                        }
                    }

                `);
                setExecute(true);
                break;
            default:
                break;
        }
        setSelectMutation(null);
        //eslint-disable-next-line
    }, [selectMutation]);

    return null;
};

export default UpdatePrivacyAndConditions;
