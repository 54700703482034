//Utils
import { capitalizeFirst } from "../../../Utils/Utils";

const MenuNavNewItem = (t, widgetID, rowContent = {}, lang) => {
    const { data } = rowContent;

    const getItemName = (data, lang) => {
        if (data && data.itemName && data.itemName[lang]) {
            return data.itemName[lang];
        } else if (data && data.itemName && typeof data.itemName === "string") {
            return data.itemName;
        } else if (data && data.itemName && data.itemName.text) {
            return data.itemName.text;
        } else if (data && data.itemName && !data.itemName[lang] && data.itemName["en"]) {
            return data.itemName["en"];
        } else if (data && data.itemName && !data.itemName[lang] && !data.itemName["en"]) {
            return data.itemName[Object.keys(data.itemName)[0]];
        }
    };

    return {
        id: "menu-nav-new-item",
        bodyAdjust: "w-full",
        title: t("add-item"),
        textAdjust: "",
        executeSectionQuery: true,
        twoColumns: true,
        inputs: [
            {
                text: capitalizeFirst(t("name")),
                type: "void",
                style: "col-start-1 font-bold pr-10",
            },
            {
                text: capitalizeFirst(t("write-the-menu-item")),
                name: "menu-nav-item-name",
                value: getItemName(data, lang),
                type: "text",
                style: "col-start-1 pr-10",
            },
            {
                text: "",
                name: "save-nav-item-name",
                value: getItemName(data, lang),
                type: "text",
                cStyle: "hidden",
                style: "hidden",
            },
            {
                text: "",
                name: "save-item-id",
                value: data && data.itemId ? data.itemId : "",
                type: "text",
                cStyle: "hidden",
                style: "hidden",
            },
            {
                text: capitalizeFirst(t("navigation")) + " *",
                type: "void",
                style: "col-start-1 font-bold pr-10",
            },
            {
                text: capitalizeFirst(t("select-navigation-destination")),
                type: "void",
                style: "col-start-1 pr-10",
            },
            {
                text: capitalizeFirst(t("select-navigation-destination")),
                type: "actionsDestinations",
                widgetID,
                style: "col-start-1 pr-10",
                cValue: data && data.itemActions ? data.itemActions : null,
                globalAction: "select-asset-for-modal-actions-item-widget-nav",
            },
            {
                text: capitalizeFirst(t("icon")),
                type: "void",
                style: "col-start-2 row-start-0 row-end-1 font-bold pl-10",
            },
            {
                text: capitalizeFirst(t("select-icon")) + ":",
                type: "selectImageIcon",
                style: "col-start-2 row-start-1 row-end-6 pl-10",
                closeBrowserModalAction: "open-modal-menu-nav-new-item",
                globalAction: "select-image-menu-nav-new-item",
                value: data && data.resourceValue ? data.resourceValue : null,
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: data && data.itemId ? "save" : "add",
                style: "blue",
                action: "return-data-for-nav-items",
            },
        ],
    };
};

export default MenuNavNewItem;
