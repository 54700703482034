import { capitalizeFirst } from "../../../Utils/Utils";

/**
 *
 * @param props {{
 *      orderData: {
 *          id?: string
 *          shopName?: string
 *      },
 *      actionData: {
 *          action: {
 *              name: string
 *              description: string
 *              buttonName: string
 *              buttonStyle: string
 *          },
 *          title?: string
 *          name?: string
 *      },
 *      t: (str: string)=>string
 *  }}
 * @returns {{
 *      useGlobalLoading: boolean,
 *      buttons: [
 *          {name: string, action: string, style: string},
 *          {name: string, action: string, style: string}
 *      ],
 *      executeSectionQuery: boolean,
 *      inputs: [],
 *      noTranslateTitle: boolean,
 *      text,
 *      id,
 *      title: string,
 *      noTranslateText: boolean
 *  }}
 * @constructor
 */
const UpdateOrder = (props = {}) => {
    const { orderData = {}, actionData = {}, t } = props;
    const { id, shopName } = orderData;
    const name = `${shopName} ${id}`;
    return {
        title: `${name} - ${capitalizeFirst(t(actionData?.title))}`,
        noTranslateTitle: true,
        text: t(actionData?.action?.description, { order: id, shop: shopName }),
        noTranslateText: true,
        id: id,
        executeSectionQuery: true,
        useGlobalLoading: true,
        inputs: [],
        buttons: [
            { name: "close", style: "white", action: "close" },
            {
                name: actionData?.action?.buttonName,
                style: actionData?.action?.buttonStyle,
                action: actionData?.action?.name,
            },
        ],
    };
};
export default UpdateOrder;
