import { stringValue } from "components/ZafiroTable";

const CC_USER_NETWORK = "ccs_guest_network";

export const getTableFilters = (type, data, t) => {
    switch (type) {
        case CC_USER_NETWORK:
            return [
                {
                    id: "filter-by-device-model",
                    title: t("device-model"),
                    options: data?.subModels?.map((m) => {
                        return {
                            id: m?.id,
                            value: m?.name,
                            label: m?.name,
                        };
                    }),
                    onFilter: (value, row) => {
                        return String(row?.model) === String(value);
                    },
                },
                {
                    id: "filter-by-ownership",
                    title: t("ownership"),
                    options: data?.ownership?.map((o) => {
                        return {
                            id: o?.id,
                            value: o?.id === "ZAFIRO",
                            label: o?.name,
                        };
                    }),
                    onFilter: (value, row) => {
                        return row?.isZafiroDevice === value;
                    },
                },
            ];
        default:
            return null;
    }
};
