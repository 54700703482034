import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

//Components
import UseSelectWithSearch from "../../../../useSelectWithSearch";

//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";

//Actions
import { setWidgetToolbarVisible } from "../../../../../actions/designActions";
import { setExecuteQuery } from "../../../../../actions/sectionActions";

//Utils
import { translateWeatherLang } from "../../../../../hooks/Utils/DesignUtils";

import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Input from "./CommonOptions/Input";
import Actions from "./CommonOptions/Actions";
import Widget from "../Widget";
import { EditorContext, WidgetContext } from "../../../../../contexts/editor";

const WeatherToolbar = ({ deviceSpecific }) => {
    const { deviceType, itemSelected } = useContext(EditorContext);
    const { id: widgetID } = useContext(WidgetContext);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const widgetSrc = `https://w.bookcdn.com/weather/picture/DESIGN_CITY_ID_FORMAT_LANG_137AE9_580_ffffff_333333_08488D_1_ffffff_333333_0_6.png?scode=124&domid=582&anc_id=77506`;
    const sectionName = `toolbar`;
    const predefinedOptions = [
        {
            id: "celsius",
            name: "Celsius degrees",
        },
        {
            id: "fahrenheit",
            name: "Fahrenheit degrees",
        },
    ];

    //Params
    const { lang } = useParams();

    //States
    const [results, setResults] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [unit, setUnit] = useState("");
    const [weatherSrc, setWeatherSrc] = useState(null);
    const [designWeatherSrc, setDesignWeatherSrc] = useState(null);
    const [showDesigns, setShowDesigns] = useState(null);

    //Store data
    const { gridItems, widgetToolbarVisible } = useSelector((state) => state.design);
    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;

    const GET_LOCATIONS = gql`
        {
            weatherCities(code:"${searchText}") 
        }
    `;
    const [getLocationQuery, { data }] = useLazyQuery(GET_LOCATIONS, {
        fetchPolicy: "network-only",
    });

    //Listeneres

    useEffect(() => {
        if (widgetToolbarVisible === "weather-settings" && itemSelected === widgetID) {
            setData();
            setShowDesigns(false);
        }
        // eslint-disable-next-line
    }, [widgetToolbarVisible]);

    useEffect(() => {
        if (itemSelected === widgetID) {
            setData();
        }
        // eslint-disable-next-line
    }, [gridItems]);

    useEffect(() => {
        if (data && data.weatherCities) {
            const queryResult = JSON.parse(data.weatherCities);
            setResults(queryResult.results);
        }

        // eslint-disable-next-line
    }, [data]);

    //Funtions

    const setData = () => {
        const gridItem = gridItems.filter((gridItem) => gridItem.i === widgetID)[0];
        if (gridItem && gridItem.data) {
            setUnit(gridItem.data.unit || "celsius");
        }
        if (gridItem && gridItem.data && gridItem.data.location) {
            if (document.querySelector(`#input_location_${widgetID}`)) {
                document.querySelector(`#input_location_${widgetID}`).value =
                    `${gridItem.data.location.name}, ${gridItem.data.location.country}` || "";
            }

            const widgetPath = widgetSrc
                .replace("FORMAT", gridItem.data.unit ? (gridItem.data.unit === "celsius" ? "1" : "0") : "1")
                .replace("LANG", translateWeatherLang(lang))
                .replace("CITY_ID", gridItem.data.location.id)
                .replace("WIDTH", `600`);

            setWeatherSrc(widgetPath.replace("DESIGN", gridItem.data.design || 3));
            setDesignWeatherSrc(widgetPath);
        }
    };

    const updateLocation = (location) => {
        setResults(null);

        dispatch(
            setExecuteQuery(
                Widget.UpdateData({
                    widget,
                    property: "location",
                    value: { id: location.id, name: location.n, country: location.c },
                    extraArgs: { noCleanAction: true },
                })
            )
        );
    };

    const updateUnit = (value) => {
        dispatch(
            setExecuteQuery(
                Widget.UpdateData({
                    widget,
                    property: "unit",
                    value,
                    extraArgs: {
                        noCleanAction: true,
                    },
                })
            )
        );
    };

    const updateDesign = (value) => {
        setShowDesigns(false);
        dispatch(
            setExecuteQuery(
                Widget.UpdateData({
                    widget,
                    property: "design",
                    value,
                    extraArgs: {
                        noCleanAction: true,
                    },
                })
            )
        );
    };

    const locationChange = (e) => {
        if (e.target.value && e.target.value.length > 2) {
            setSearchText(e.target.value);
            getLocationQuery();
        }
    };

    return (
        <>
            <div
                id={`${sectionName}-settings`}
                className={`icon icon-settings-editor cursor-pointer p-2 ${
                    widgetToolbarVisible === "weather-settings" ? "toolbarOptionSelected" : ""
                }`}
                onClick={(e) =>
                    dispatch(
                        setWidgetToolbarVisible(widgetToolbarVisible === "weather-settings" ? null : "weather-settings")
                    )
                }
            ></div>
            {widgetToolbarVisible === "weather-settings" ? (
                <div
                    id={`widgetOptionContainer_${widgetID}`}
                    className={`widgetOptionContainer invisible`}
                    style={{ width: "26rem", minHeight: "25rem" }}
                >
                    <div className="first-capital font-bold mb-4">{t("weather-options")}</div>
                    <div className="table w-full pb-6 relative">
                        <div className="first-capital mb-4  cursor-pointer">
                            <div className="text-gray-800 first-capital">{t("location")}</div>
                        </div>
                        <input
                            type="text"
                            id={`input_location_${widgetID}`}
                            className={`w-full bg-gray-200 rounded px-4 py-2 mb-4`}
                            onChange={(e) => locationChange(e)}
                        />
                        {results ? (
                            <div
                                className="absolute overflow-y-scroll z-100 border-gray-200 border rounded bg-white "
                                style={{ maxHeight: "13rem", width: "inherit" }}
                            >
                                {results.map((result) => (
                                    <div
                                        className="sidebar-submenu-link cursor-pointer py-2 px-4"
                                        onClick={(e) => updateLocation(result)}
                                    >
                                        {" "}
                                        {`${result.n}, ${result.c}`}
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </div>
                    {weatherSrc ? (
                        <>
                            <div className="table w-full  pb-6 ">
                                <div className="first-capital mb-4  cursor-pointer">
                                    <div className="text-gray-800 first-capital">{t("unit")}</div>
                                </div>
                                <UseSelectWithSearch
                                    data={{
                                        oneSelected: true,
                                        noSelect: true,
                                        name: `format_${widgetID}`,
                                        optionData: predefinedOptions,
                                        selectedIds: unit ? [unit] : null,
                                        onChange: updateUnit,
                                        hideSearch: true,
                                        optionsAdjust: "mt-11",
                                        fixed: false,
                                    }}
                                />
                            </div>
                            <div className="table w-full">
                                <div className="first-capital mb-4  cursor-pointer">
                                    <div className="text-gray-800 first-capital">{t("design")}</div>
                                </div>
                                <div>
                                    <div
                                        className="flex items-center bg-gray-200 rounded"
                                        onClick={(e) => setShowDesigns(!showDesigns)}
                                    >
                                        <img
                                            alt="preview"
                                            title="preview"
                                            className={`w-6/12 p-3 h-full object-contain mx-auto`}
                                            src={weatherSrc}
                                        ></img>

                                        <div
                                            className={`field-icon icon-chevron ${
                                                showDesigns ? "mr-4 -rotate-180" : ""
                                            } relative flex animated cursor-pointer text-gray-600
                    
                        `}
                                        ></div>
                                    </div>
                                    {showDesigns ? (
                                        <div className="overflow-y-scroll max-h-40  border  mt-2 rounded">
                                            {Array.from([1, 2, 3, 4, 5, 7, 14, 21, 23, 25, 26, 27, 28, 29], (i) => {
                                                return (
                                                    <div
                                                        className="flex items-center py-4 border-b border-gray-200 cursor-pointer hover:bg-zafiro-100"
                                                        onClick={(e) => updateDesign(i)}
                                                    >
                                                        <img
                                                            alt="preview"
                                                            title="preview"
                                                            className={`w-9/12  h-full object-contain mx-auto`}
                                                            src={designWeatherSrc.replace("DESIGN", i)}
                                                            onClick={(e) => updateDesign(i)}
                                                        ></img>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            ) : null}
            <Toolbar type="style">
                <Settings>
                    <Settings.Group type="colors">
                        <Input.Color id="bgColor" title={t("background")} />
                    </Settings.Group>
                </Settings>
                <Settings.Border t={t} />
            </Toolbar>
            <Toolbar type="actions">
                <Actions
                    actions={{
                        url: deviceType !== "TV",
                        section: true,
                        library: true,
                        tvchannel: true,
                    }}
                />
            </Toolbar>
            {/* <Toolbar type="clone" /> */}
            <Toolbar type="delete" />
        </>
    );
};

export default WeatherToolbar;
