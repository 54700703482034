import React, { useState } from "react";

import { Session } from "hooks/Utils/Session";
import { useAuth } from "hooks/Session/auth";

import Button from "components/Button";
import Icon from "components/Icon";

const Logo = () => {
    const { chain } = useAuth();

    const [logoAssetRef, setLogoAssetRef] = useState(chain?.logo);

    const logoID = "topBar-logo-link";
    const logoURL = logoAssetRef ? Session.getDasUrl(logoAssetRef) : null;

    if (!logoURL) {
        return (
            <Button
                id={logoID}
                to="/"
                className="flex flex-row w-full h-full space-x-2 text-2xl items-center text-zafiro-0 hover:text-zafiro-700 justify-center"
            >
                <Icon type="zafiro-solid" size="4xl" />
                <span>ZAFIRO</span>
            </Button>
        );
    }

    return (
        <Button id={logoID} to="/" className="h-full">
            <img onError={() => setLogoAssetRef(null)} className="max-w-full max-h-full m-auto" src={logoURL} />
        </Button>
    );
};

export default Logo;
