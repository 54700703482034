import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseRadio from "../useRadio";
import UseButton from "../useButton";
//Modal
import { openModal } from "../../actions/uiActions";
import { setModalContent } from "../../actions/uiActions";
import BrowserMediaLibraryModal from "../../hooks/GraphqlCalls/MediaLibrary/modals/BrowserMediaLibraryModal";
//Actions
import { changeGlobalAction, changeActionValues } from "../../actions/globalActions";
//Utils
import { Session } from "../../hooks/Utils/Session";
import ReactTooltip from "components/ReactTooltip";

const getFromDAS = (libraryRef, languageRef) => {
    if (libraryRef) {
        return Session.getDasUrl(`${libraryRef}?lang=${languageRef}`);
    }
    return null;
};

const UseSelectImage = ({ index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const OPTIONS = {
        URL: "image-url",
        LIBRARY: "image-library",
    };

    const { text, closeBrowserModalAction, globalAction, value } = useSelector(
        (state) => state.ui.modalContent.inputs[index]
    );
    const actionData = useSelector((state) => state.action);
    const { cLang } = useSelector((state) => state.design);

    const [optionSelected, setOptionSelected] = useState(value?.externalUrl ? OPTIONS.URL : OPTIONS.LIBRARY);

    const [urlSelected, setURLSelected] = useState(value?.externalUrl);
    const [imageSelected, setImageSelected] = useState(value?.libraryRef ? getFromDAS(value?.libraryRef, cLang) : null);
    const [name, setName] = useState(value?.name);

    const openModalLibrary = () => {
        const modal = BrowserMediaLibraryModal(t, {
            title: "choose-an-image",
            text: "images-of-the-media-library",
            bodyAdjust: "w-10/12",
            textAdjust: "",
            executeSectionQuery: true,
            buttonSelectedAssetName: "choose-an-image",
            action: "widget-actions-media",
            closeAction: closeBrowserModalAction,
            dataMediaLibrary: {
                selected_ref: null,
                lang: cLang,
                select_multiple: false,
                type: "asset",
                add_asset: {
                    active: true,
                    return_added: false,
                },
            },
        });
        if (globalAction) {
            setTimeout(() => {
                dispatch(changeGlobalAction({ actionName: globalAction }));
            }, 500);
        }
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    useEffect(() => {
        if (!actionData.values["option-resource-selected"] && !value) {
            setImageSelected(null);
            setName(null);
            setURLSelected(null);
            setOptionSelected(OPTIONS.LIBRARY);
        } else if (actionData.values["option-resource-selected"] === OPTIONS.LIBRARY) {
            const ref = actionData.values["image-selected"];
            setOptionSelected(OPTIONS.LIBRARY);
            setURLSelected(null);
            setImageSelected(ref ? getFromDAS(ref, cLang) : null);
            setName(actionData.values["widget-item-name"]);
        } else if (actionData.values["option-resource-selected"] === OPTIONS.URL) {
            setOptionSelected(OPTIONS.URL);
            setImageSelected(null);
            setURLSelected(actionData.values["url-selected"]);
            setName(actionData.values["widget-item-name"]);
        }
        // eslint-disable-next-line
    }, [actionData]);

    const previewImage = optionSelected === OPTIONS.URL ? urlSelected : imageSelected;

    return (
        <>
            {text ? <div className="block pb-4 first-capital">{text}</div> : null}
            <div className=" flex w-full">
                <div className="relative w-44 h-32 bg-gray-200 border rounded-lg">
                    {previewImage ? (
                        <img alt="" className="h-full w-full object-contain mb-8" src={previewImage}></img>
                    ) : (
                        <div className=" absolute h-full w-full flex items-center justify-center">
                            <span className=" icon icon-image text-gray-700" style={{ fontSize: "4rem" }}></span>
                        </div>
                    )}
                    {previewImage ? (
                        <div
                            className="w-full text-white text-base absolute bottom-0 right-0  text-center rounded "
                            style={{ padding: ".25rem", backgroundColor: "rgba(160,160,160,0.8)" }}
                        >
                            <div className="flex justify-between flex-no-wrap items-center">
                                <span class="icon icon-info invisible"></span>
                                <span
                                    className="icon icon-delete-editor text-2xl cursor-pointer"
                                    id="action-delete-selected-image"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(
                                            changeActionValues({
                                                "image-selected": null,
                                                "widget-item-name": null,
                                                "url-selected": null,
                                                "option-resource-selected": optionSelected,
                                            })
                                        );
                                    }}
                                ></span>
                                <span data-tip={name} class="icon icon-info text-xl"></span>
                                <ReactTooltip
                                    place="bottom"
                                    type="light"
                                    offset={{ top: -8, left: -8 }}
                                    html={true}
                                    border={true}
                                    borderColor="#D3DAE1"
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="w-5/5 pl-11">
                    <div
                        className="cursor-pointer flex items-center"
                        id={`use-select-image-library-option-container${index ? `-${index}` : ""}`}
                        onClick={() => {
                            dispatch(
                                changeActionValues({
                                    "image-selected": imageSelected,
                                    "widget-item-name": name,
                                    "url-selected": null,
                                    "option-resource-selected": OPTIONS.LIBRARY,
                                })
                            );
                        }}
                    >
                        <div style={{ marginTop: "-1em" }}>
                            <UseRadio
                                id={`use-select-image-library-option-radio${index ? `-${index}` : ""}`}
                                selected={optionSelected}
                                value={OPTIONS.LIBRARY}
                            />
                        </div>
                        <div className="ml-6">
                            <UseButton
                                id={`use-select-image-library-option-button${index ? `-${index}` : ""}`}
                                buttonName={t("choose-an-image")}
                                buttonColor={"btn-white"}
                                disabled={optionSelected !== OPTIONS.LIBRARY}
                                action={(e) => {
                                    e.stopPropagation();
                                    openModalLibrary();
                                }}
                            />
                        </div>
                    </div>
                    <div
                        className="cursor-pointer mt-2"
                        id={`use-select-image-url-option-container${index ? `-${index}` : ""}`}
                        onClick={() => {
                            dispatch(
                                changeActionValues({
                                    "image-selected": null,
                                    "widget-item-name": name,
                                    "url-selected": urlSelected,
                                    "option-resource-selected": OPTIONS.URL,
                                })
                            );
                        }}
                    >
                        <UseRadio
                            id={`use-select-image-url-option-radio${index ? `-${index}` : ""}`}
                            selected={optionSelected}
                            value={OPTIONS.URL}
                        />
                        <div className="first-capital mb-2 ml-6">
                            {t("link")} ({t("url")}):
                        </div>
                        <input
                            id="url-link"
                            type="text"
                            min="0"
                            placeholder={t("paste here")}
                            className={`w-3/5 h-10 bg-gray-200 rounded p-2 mr-8 ${
                                optionSelected !== OPTIONS.URL ? "text-gray-400" : "text-gray-900"
                            }`}
                            value={optionSelected !== OPTIONS.URL ? "" : urlSelected}
                            onChange={(e) => {
                                dispatch(
                                    changeActionValues({
                                        "image-selected": null,
                                        "widget-item-name": e?.target?.value
                                            ?.split("/")
                                            .pop()
                                            .split("?")[0]
                                            .split("#")[0],
                                        "url-selected": e?.target?.value,
                                        "option-resource-selected": OPTIONS.URL,
                                    })
                                );
                            }}
                        ></input>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UseSelectImage;
