import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//Components
import UseOptions from "./useOptions";
//Utils
import useComponentVisible from "../useComponentVisible";

const UseSelect = ({ placeholder, validation, id }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { dateType } = useSelector((state) => state.action);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //States
    const [selectActive, setSelectActive] = useState({});
    const [dateTypeError, setDateTypeError] = useState(validation);

    //Listeners
    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({});
        } else {
            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
            });
        }
    }, [isComponentVisible]);

    useEffect(() => {
        setDateTypeError(validation);
    }, [validation]);

    return (
        <>
            <div
                ref={ref}
                className={`w-full h-10 bg-gray-200 rounded relative mr-4 ${selectActive.container} ${
                    !dateTypeError ? "border border-red-100" : ""
                }`}
            >
                <div
                    className={`t-filter-ph first-capital ${selectActive.text}`}
                    id={id ? `${id}-select-button` : null}
                    onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                >
                    {dateType ? langStrings[dateType] : langStrings[placeholder]}
                </div>
                <span
                    id={id ? `${id}-select-arrow` : null}
                    className={`field-icon icon-chevron animated ${selectActive.icon}`}
                    onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                ></span>
                {isComponentVisible ? (
                    <span id={id ? `${id}-main-options-container` : null} onClick={() => setIsComponentVisible(false)}>
                        <div id={id ? `${id}-options-container` : null} onClick={() => setDateTypeError(true)}>
                            <UseOptions />
                        </div>
                    </span>
                ) : null}
            </div>
            {!dateTypeError ? <p className="absolute text-red-100">{langStrings["schedule-datetype-error"]}</p> : null}
        </>
    );
};

export default UseSelect;
