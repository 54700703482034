import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { gql } from "apollo-boost";
import { useTranslation } from "react-i18next";

import { useMSQuery } from "../../../../../hooks/GraphqlCalls/useMSQuery";
import { Session } from "../../../../../hooks/Utils/Session";
import { CORPORATE_CHANNEL } from "../../../../../hooks/Utils/Services/ChannelsUtils";
import UseComponentLoading from "../../../../useComponentLoading";

import { GlobalContext } from "contexts/Global";

const ChannelRewiewInformationCard = (props) => {
    //Consts&states
    const { t } = useTranslation();
    const { lang } = useContext(GlobalContext);
    const { data: dataChannel = {} } = props;
    const { channelData: channelInitialData = null } = dataChannel;
    const [initialLoading, setInitialLoading] = useState(true);
    const [channelData, setChannelData] = useState(null);
    const [classImageData, setClassImageData] = useState(null);
    const [queryChannel, setQueryChannel] = useState("");
    const sectionName = "channel-review-information";

    //queries&mutations
    const GET_DATA = gql`{
        languages: languages {
            results {
                ref
            }
        }
        ${queryChannel}
    }`;

    //useQueries&useMutations
    const [executeQuery, { data, loading: loadingData, error, networkStatus }] = useMSQuery(GET_DATA);

    //effects
    useEffect(() => {
        if (channelInitialData) {
            setQueryChannel(arrangeQueryChannel({ type: channelInitialData.type, id: channelInitialData.id }));
        }
    }, [channelInitialData]);

    useEffect(() => {
        if (queryChannel) {
            setInitialLoading(true);
            executeQuery();
        }
    }, [queryChannel]);

    useEffect(() => {
        if (!loadingData && !error && networkStatus === 7 && data && data.channels) {
            setInitialLoading(false);
            if (data.channels.results && data.channels.results.length > 0) {
                const channelData = arrangeData(data.channels.results[0]);
                setChannelData(channelData);
                setClassImageData(arrangeClassImageData(channelData));
            }
        }
    }, [data, networkStatus]);

    //arrangeData
    const arrangeData = (data) => {
        let response = null;
        if (data) {
            response = { ...data };
        }
        return response;
    };

    const arrangeQueryChannel = (props) => {
        const { type, id } = props;
        let response = null;
        if (type === CORPORATE_CHANNEL.value) {
            response = `
            channels: corporateTunesSummary(filter:{id:${id}}){
                results {
                    id:channelID
                    name
                    type
                    logoRef
                }
            }`;
        } else {
            response = `
            channels: customAndPresetTunesSummary(filter:{id:${id}}){
                results {
                    id:channelID
                    name
                    type
                    logoRef
                    countryRef
                    languageRef
                    isRadio
                }
            }`;
        }
        return response;
    };

    const arrangeClassImageData = (channelData) => {
        const classImageSrc = classNames({
            "ChannelEditionCard-image--src": true,
            hidden: !channelData?.logoRef,
        });

        const classImageOptions = classNames({
            "ChannelEditionCard-image--options": true,
            hidden: !channelData?.logoRef,
        });

        return { classImageSrc: classImageSrc, classImageOptions: classImageOptions };
    };

    //handle&Functions
    console.log(channelData);
    //renders
    return (
        <>
            {initialLoading ? (
                <UseComponentLoading />
            ) : (
                <>
                    <div className="ChannelEditionCard w-full">
                        <div className="flex w-full text-left mb-5">
                            <span className="font-bold text-gray-900 text-1xl">{t("preset_informations_channel")}</span>
                        </div>
                        <div className="flex w-full">
                            <div className="ChannelEditionCard--separation">
                                <div className="ChannelEditionCard-image">
                                    <div className="ChannelEditionCard-image--body">
                                        <div className="ChannelEditionCard-image--background">
                                            {channelData?.logoRef && channelData.logoRef !== "" ? (
                                                <img
                                                    alt={channelData?.name || ""}
                                                    className={classImageData?.classImageSrc}
                                                    src={Session.getDasUrl(channelData?.logoRef + `?lang=${lang}`)}
                                                />
                                            ) : (
                                                <div className="w-full h-full flex">
                                                    <span className="icon icon-channel text-gray-800 text-7xl m-auto"></span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full ChannelEditionCard-properties">
                                <table className="w-full">
                                    <tr>
                                        <td className="align-right pb-2">
                                            <span className="text-gray-900 font-bold pr-1">{t("name")}</span>
                                        </td>
                                        <td className="align-left pb-2">
                                            <span className=" pl-1">{channelData?.name}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-right pb-2">
                                            <span className="text-gray-900 font-bold pr-1">{t("country")}</span>
                                        </td>
                                        <td className="align-left pb-2">
                                            <span className="pl-1">
                                                {channelData?.countryRef
                                                    ? t(`country:${channelData?.countryRef.toLowerCase()}`)
                                                    : ""}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-right pb-2">
                                            <span className="text-gray-900 font-bold pr-1">{t("language")}</span>
                                        </td>
                                        <td className="align-left pb-2">
                                            <span className="pl-1">
                                                {channelData?.languageRef
                                                    ? t(`language:${channelData?.languageRef.toLowerCase()}`)
                                                    : ""}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-right pb-2">
                                            <span className="text-gray-900 font-bold pr-1">{t("type")}</span>
                                        </td>
                                        <td className="align-left pb-2">
                                            <span className="pl-1">{channelData?.isRadio ? t("radio") : t("tv")}</span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ChannelRewiewInformationCard;
