import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
//Actions
import { showGlobalLoading, closeModal, setModalContent, openModal } from "../../../actions/uiActions";

import { setExecuteQuery } from "../../../actions/sectionActions";
import {
    changeActionValues,
    cleanAction,
    setActionItemAffected,
    setActionName,
    setActionValidations,
    setActionValues,
} from "../../../actions/globalActions";

//Utils
import { toast } from "react-toastify";
import { capitalizeFirst, removeApolloCacheKeys } from "../../Utils/Utils";
import { useTranslation } from "react-i18next";
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { Session } from "../../Utils/Session";
import NewInputGroup from "./modals/NewInputGroup";

const UpdateTvInputs = ({ client }) => {
    const { t } = useTranslation();
    const { langStrings } = useSelector((state) => state.ui);
    const { executeQuery, dataToRestore } = useSelector((state) => state.sectionContent);
    const tableResults = useSelector((state) => state.table.results);
    const actionValues = useSelector((state) => state.action.values);

    const actionData = useSelector((state) => state.action);

    //State
    const [mutation, setMutation] = useState("mutation{checkIn}");

    //Actions
    const dispatch = useDispatch();

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, dataMutation] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(err.message);
        },
    });

    const cancelAction = () => {
        let valuesToRestore = Session.getSessionProp("actionDataToRestore");

        if (valuesToRestore) {
            valuesToRestore = JSON.parse(valuesToRestore);
        }
        dispatch(setActionName(dataToRestore.actionName));
        if (dataToRestore?.inputIdInEdition) {
            dispatch(setActionItemAffected([dataToRestore.inputIdInEdition]));
        }

        if (valuesToRestore) {
            dispatch(setActionValues(valuesToRestore?.values));
            dispatch(setModalContent(NewInputGroup(valuesToRestore?.values, null, dataToRestore?.modalValue)));
            dispatch(openModal());
        }
    };

    const openIconSelectionFromLibraryBrowser = (imageSelected) => {
        if (imageSelected) {
            let valuesToRestore = Session.getSessionProp("actionDataToRestore");

            if (valuesToRestore) {
                valuesToRestore = JSON.parse(valuesToRestore);
            }
            valuesToRestore.values[`input-${actionData.itemsAffected[0]}-image`] = imageSelected;
            Session.setSessionProp("actionDataToRestore", valuesToRestore);

            dispatch(
                changeActionValues({
                    "image-selected": imageSelected,
                    "option-resource-selected": "image-library",
                    "browser-media-library-items-selected": null,
                })
            );
        }

        dispatch(setActionName("select-input-icon"));

        dispatch(
            setModalContent({
                title: capitalizeFirst(t("add-icon")),
                bodyAdjust: "w-5/12",
                executeSectionQuery: true,
                inputs: [
                    {
                        text: capitalizeFirst(t("select-icon")) + ":",
                        type: "selectImageIcon",
                        closeBrowserModalAction: "open-modal-add-input-group-icon",
                        style: "",
                        value: null,
                    },
                ],
                buttons: [
                    { name: "cancel", style: "white", actionCallback: () => cancelAction(), action: "none" },
                    {
                        name: "continue",
                        style: "blue",
                        action: "select-input-icon",
                    },
                ],
            })
        );
        //Launch modal
        dispatch(openModal());
    };

    useEffect(() => {
        const imageSelected = actionValues["browser-media-library-items-selected"]?.[0];
        let roomsSelected = 0,
            locationsSelected = 0,
            asignedTvs = 0;

        let execute = false;
        if (executeQuery) {
            let inputsList = [];
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.queryName = "";
            execute = true;
            switch (executeQuery.action) {
                case "make-input-group-default":
                    setMutation(`mutation{
                                    updateTVInput(id:"${actionData.itemsAffected[0]}" isDefault: true)
                                    { error ok }
                                 }`);
                    executeQuery.mutationName = "updateTVInput";
                    executeQuery.closeModal = true;
                    break;
                case "delete-tvInput":
                    setMutation(`mutation{
                        deleteTVInput(id:"${actionData.itemsAffected[0]}")
                                    { error ok }
                                 }`);
                    executeQuery.mutationName = "deleteTVInput";
                    executeQuery.closeModal = true;
                    break;
                case "manage-tvs-assigned":
                    roomsSelected = actionData.values["rooms"]?.length || 0;
                    locationsSelected = actionData.values["locations"]?.length || 0;
                    asignedTvs = actionData.values["assigned-tvs-ids"]?.length || 0;

                    //location not selected
                    if (roomsSelected > 0 && locationsSelected == 0) {
                        toast.error(t("errors-in-red"));
                        dispatch(setActionValidations({ ["locations"]: false }));
                        return;
                    }
                    dispatch(setActionValidations({ ["locations"]: true }));

                    //tvs assigned pending to be added
                    if (roomsSelected > 0 && locationsSelected > 0) {
                        toast.error(t("fields-not-added"));
                        return;
                    }

                    setMutation(`mutation{
                                    assignTVInput(
                                        roomTvIDs: [${actionData.values["assigned-tvs-ids"].join(",")}]
                                        tvInputID: ${actionData.itemsAffected[0]}
                                        replace: true
                                    )
                                    { error ok }
                                }`);
                    executeQuery.mutationName = "assignTVInput";
                    executeQuery.closeModal = true;

                    break;
                case "new-input-group":
                    inputsList = [];
                    for (let index = 0; index < actionData.values["inputs-number"]; index++) {
                        const device = actionData.values[`input-${index}-device`]?.[0];
                        const type = actionData.values[`input-${index}-type`]?.[0];
                        let icon = actionData.values[`input-${index}-icon`];
                        if (icon) {
                            icon = `${icon.lib}-${icon.icon}`;
                        }
                        const image = actionData.values[`input-${index}-image`];
                        inputsList.push(
                            `{name: "${actionData.values[`input-${index}-name`]}",input: ${type}
                                pos:${index + 1}
                                ${device ? `deviceType: ${device}` : ""}
                                ${icon ? `icon: "${icon}"` : ""}
                                ${image ? `image: "${image}"` : ""}
                            }`
                        );
                    }
                    setMutation(`mutation{
                        createTVInput(name:"${actionData.values["input-group-name"]}"
                                        isDefault: ${tableResults && tableResults.length === 0 ? "true" : "false"}
                                        tvInputInputs: [${inputsList.join(",")}]
                        )
                                    { error ok }
                                 }`);

                    executeQuery.mutationName = "createTVInput";
                    executeQuery.closeModal = true;
                    break;

                case "edit-input-group":
                    inputsList = [];
                    for (let index = 0; index < actionData.values["inputs-number"]; index++) {
                        const device = actionData.values[`input-${index}-device`]?.[0];
                        const type = actionData.values[`input-${index}-type`]?.[0];
                        let icon = actionData.values[`input-${index}-icon`];
                        if (icon) {
                            icon = `${icon.lib}-${icon.icon}`;
                        }
                        const image = actionData.values[`input-${index}-image`];
                        inputsList.push(
                            `{name: "${actionData.values[`input-${index}-name`]}",input: ${type}
                                pos:${index + 1}
                                ${device ? `deviceType: ${device}` : ""}
                                ${icon ? `icon: "${icon}"` : ""}
                                ${image ? `image: "${image}"` : ""}
                            }`
                        );
                    }
                    setMutation(`mutation{
                        updateTVInput(id:"${actionData.itemsAffected[0]}"
                                      name:"${actionData.values["input-group-name"]}"
                                      tvInputInputs: [${inputsList.join(",")}]
                        )
                                    { error ok }
                                 }`);

                    executeQuery.mutationName = "updateTVInput";
                    executeQuery.closeModal = true;
                    break;

                //Execute after adding a new image
                case "widget-actions-media":
                case "browser-media-library":
                    openIconSelectionFromLibraryBrowser(imageSelected);
                    return;

                case "select-input-icon":
                    const resourceSelected = actionValues["option-resource-selected"];
                    if (imageSelected) {
                        openIconSelectionFromLibraryBrowser(imageSelected);
                        return;
                    }
                    //return to input group managing
                    dispatch(setActionName(dataToRestore.actionName));
                    if (dataToRestore?.inputIdInEdition) {
                        dispatch(setActionItemAffected([dataToRestore?.inputIdInEdition]));
                    }

                    let actionDataToRestore = Session.getSessionProp("actionDataToRestore");

                    if (actionDataToRestore) {
                        actionDataToRestore = JSON.parse(actionDataToRestore);
                        actionDataToRestore.values[`input-${actionData.itemsAffected[0]}-icon`] = null;
                        actionDataToRestore.values[`input-${actionData.itemsAffected[0]}-image`] = null;
                        if (resourceSelected === "image-library") {
                            actionDataToRestore.values[`input-${actionData.itemsAffected[0]}-image`] =
                                actionData.values["image-selected"];
                        } else if (resourceSelected === "icon-library") {
                            actionDataToRestore.values[`input-${actionData.itemsAffected[0]}-icon`] =
                                actionData.values["icon-selected"];
                        }
                        Session.setSessionProp("actionDataToRestore", actionDataToRestore);
                        dispatch(setActionValues(actionDataToRestore?.values));

                        dispatch(
                            setModalContent(NewInputGroup(actionDataToRestore?.values, null, dataToRestore?.modalValue))
                        );
                        //Launch modal
                        dispatch(openModal());
                    }

                    execute = false;
                    break;

                default:
                    execute = false;
                    break;
            }
        }

        if (execute) {
            dispatch(setLoading(true));

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (
            dataMutation.data &&
            executeQuery &&
            ((dataMutation.data[executeQuery.action] &&
                (dataMutation.data[executeQuery.action].isOk || dataMutation.data[executeQuery.action].ok)) ||
                (dataMutation.data[executeQuery.mutationName] &&
                    (dataMutation.data[executeQuery.mutationName].isOk ||
                        dataMutation.data[executeQuery.mutationName].ok ||
                        dataMutation.data[executeQuery.mutationName].okUpdate)))
        ) {
            toast.success(langStrings[executeQuery.toastMsg ? executeQuery.toastMsg : "operation-successful"]);

            //remove tv inputs cache to show updated info
            removeApolloCacheKeys(client.cache, ["TVInput", "tvInput"]);
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }
            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            // si no ponemos el timeout se devuelven los datos anteriores de la base de datos
            setTimeout(function () {
                dispatch(setRefreshData(true));
            }, 300);
        } else if (
            dataMutation.data &&
            executeQuery &&
            ((dataMutation.data[executeQuery.action] &&
                (dataMutation.data[executeQuery.action].errorMsg || dataMutation.data[executeQuery.action].error)) ||
                (dataMutation.data[executeQuery.mutationName] &&
                    (dataMutation.data[executeQuery.mutationName].errorMsg ||
                        dataMutation.data[executeQuery.mutationName].error)))
        ) {
            const errorMsg =
                dataMutation.data[executeQuery.action] && dataMutation.data[executeQuery.action].error
                    ? dataMutation.data[executeQuery.action].error
                    : dataMutation.data[executeQuery.mutationName] && dataMutation.data[executeQuery.mutationName].error
                    ? dataMutation.data[executeQuery.mutationName].error
                    : null;
            toast.error(errorMsg ? errorMsg : t("mutation-error"));
            dispatch(showGlobalLoading(false));
            dispatch(setExecuteQuery(null));
        }
        // eslint-disable-next-line
    }, [dataMutation.data, dispatch, executeQuery, langStrings]);

    return null;
};

export default withApollo(UpdateTvInputs);
