import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//Utils
import useComponentVisible from "../useComponentVisible";
import UseModalOptions from "./useModalOptions";

const UseInputSelectSearch = ({ index }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { validations } = useSelector((state) => state.action);

    const { name, text, ph, currentResponsible, adjust } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const activeValue = useSelector((state) => state.action.values[name]);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //States
    const [selected, setSelected] = useState();
    const [selectActive, setSelectActive] = useState({});
    const [fullText, setFullText] = useState(false);

    //Actions
    const currentValueDisplay = () => {
        let res;
        if (currentResponsible) {
            res = currentResponsible;
        } else if (selected) {
            res = selected;
        } else if (ph) {
            res = langStrings[ph];
        } else {
            res = langStrings[name];
        }
        return res;
    };

    const [currentPlaceholder, setCurrentPlaceHolder] = useState(currentValueDisplay());

    const changeVisibility = () => {
        setIsComponentVisible(isComponentVisible ? false : true);
    };

    //Listeners
    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({});
        } else {
            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
            });
        }
    }, [isComponentVisible]);

    useEffect(() => {
        if (activeValue) {
            setSelected(activeValue);
        }
        setCurrentPlaceHolder(activeValue);
    }, [activeValue]);

    useEffect(() => {
        setCurrentPlaceHolder(currentValueDisplay());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (langStrings) {
            setFullText(getFullText(text, langStrings));
        }
        // eslint-disable-next-line
    }, [langStrings]);

    return (
        <>
            {text ? <div className="block pb-2 first-capital">{fullText ? fullText : null}:</div> : null}
            <div
                ref={ref}
                className={`w-48 h-10 bg-gray-200 rounded relative ${adjust} ${selectActive.container} ${
                    typeof validations[name] !== "undefined" && !validations[name] ? "border border-red-100" : ""
                }`}
            >
                <div
                    className={`t-filter-ph ${selectActive.text} min-h-2rem`}
                    id="input-select-dropdown"
                    onClick={changeVisibility}
                >
                    {currentPlaceholder}
                </div>
                <span
                    id="input-select-dropdown-icon"
                    className={`field-icon icon-chevron animated ${selectActive.icon}`}
                    onClick={changeVisibility}
                ></span>
                {isComponentVisible ? (
                    <span onClick={changeVisibility}>
                        <UseModalOptions index={index} />
                    </span>
                ) : null}
            </div>
        </>
    );
};

const getFullText = (text, langStrings) => {
    let res = ``;
    if (Array.isArray(text)) {
        // eslint-disable-next-line
        text.forEach((word, index) => {
            if (index !== 0) {
                res += ` `;
            }
            res += langStrings[word];
        });
    } else {
        res = langStrings[text];
    }
    return res;
};

export default UseInputSelectSearch;
