import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";

import { Session } from "hooks/Utils/Session";
import DisplayMenuSidebar from "hooks/Utils/DisplayMenuSidebar";
import { useAuth } from "hooks/Session/auth";

import Button from "components/Button";
import Icon from "components/Icon";

import routes from "routes";

const Sidebar = () => {
    const { lang, isCorporate, hasCorporate, permissions, project, chain, loginV3 } = useAuth();

    const { t } = useTranslation();
    const location = useLocation();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const menusDisplay = DisplayMenuSidebar();

    const visible = !!project?.ref;

    const refSidebar = React.useRef();
    const sidebarOpen = useSelector((state) => state.ui.sidebar);

    const [hideLinks, setHideLinks] = useState(false);

    const hasWifiLegacy = permissions?.legacy?.wifi;
    const isNetworkManagement = permissions?.managerSetup?.networkManagement;
    const isNetworkAdmin = permissions?.managerSetup?.networkAdmin;

    const displayWifiLegacy = chain?.ref && hasWifiLegacy && (hasCorporate || isNetworkManagement || isNetworkAdmin);

    // Wifi Legacy info
    const wmcParams = displayWifiLegacy
        ? [
              lang ? `lang:"${lang}"` : null,
              timeZone ? `timezone:"${timeZone}"` : null,
              `isAdmin:${hasCorporate || isNetworkAdmin}`,
          ]
              .filter((filter) => filter)
              .join(",")
        : null;
    const [getWifiConfig, { data: wifiConfig }] = useLazyQuery(
        gql`{wifiManagerConfig${wmcParams ? `(${wmcParams})` : ""}{ results { enabled url } }}`
    );

    // TV Settings
    const [getTvSettings, { data: tvSettings }] = useLazyQuery(
        gql`
            {
                tvSettings {
                    permanentSocket
                }
            }
        `
    );

    const hasWifi = displayWifiLegacy && wifiConfig?.wifiManagerConfig?.results?.enabled;
    const wifiManagerURL = wifiConfig?.wifiManagerConfig?.results?.url;

    const hasAdvancedConfigLink =
        !isCorporate &&
        (permissions?.legacy?.tv ||
            permissions?.legacy?.mobile ||
            permissions?.legacy?.sales ||
            permissions?.legacy?.guest);

    useEffect(() => {
        if (displayWifiLegacy) {
            getWifiConfig();
        }
        if (project?.ref && !isCorporate) {
            getTvSettings();
        }
    }, [displayWifiLegacy, chain?.ref, project?.ref]);

    useEffect(() => {
        if (tvSettings?.tvSettings) {
            Session.setSessionProp("tvPermanentSocket", tvSettings.tvSettings.permanentSocket);
        }
    }, [tvSettings]);

    useEffect(() => {
        if (location && location.pathname) {
            if (location.pathname) {
                const routeSalesShopPattern = /^\/sales\/shop\/.*$/;
                if (routeSalesShopPattern.test(location.pathname)) {
                    setHideLinks(true);
                } else {
                    setHideLinks(false);
                }
            }
        }
    }, [location]);

    const sidebarClass = classNames({
        "w-1/6 overflow-hidden overflow-y-auto": true,
        "bg-white shadow-xl": true,
        "flex flex-col justify-between flex-no-wrap": true,
        "absolute pt-8 top-0 bottom-0 mt-20 z-10": true,
    });

    const bottomLinks = [
        hasAdvancedConfigLink
            ? {
                  id: "ZAFIROTV_LINK",
                  onClick: loginV3,
                  text: t("LegacyAdmin"),
              }
            : null,
        hasWifi
            ? {
                  id: "WIFIMANAGER_LINK",
                  href: wifiManagerURL,
                  text: "ZAFIRO Wifi",
              }
            : null,
    ]?.filter((link) => link);

    if (!visible || !sidebarOpen || menusDisplay?.hideSidebar) {
        return null;
    }

    return (
        <nav id={`main_sidebar_nav`} ref={refSidebar} className={sidebarClass}>
            <div>
                {routes?.length ? (
                    <Routes>
                        {routes
                            .filter((route) => typeof route.sidebar !== "undefined")
                            .map((route, index) => (
                                <Route
                                    key={index}
                                    {...route}
                                    element={<route.sidebar key={route?.sectionID} sectionID={route?.sectionID} />}
                                />
                            ))}
                    </Routes>
                ) : null}
            </div>
            {!hideLinks && bottomLinks?.length ? (
                <div className="border-t border-gray-200">
                    {bottomLinks.map((link) => (
                        <Button
                            id={link?.id}
                            key={link?.id}
                            className="text-gray-800 border-b border-gray-200 text-lg flex justify-between py-3 px-5 items-center truncate w-full"
                            onClick={link?.onClick}
                            href={link?.href}
                        >
                            <div className="flex items-center space-x-2 ">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/main-logo.png`}
                                    alt="Zafiro"
                                    className="oldManagerLinkIcon inline-block"
                                />
                                <div className="whitespace-no-wrap truncate">{link?.text}</div>
                            </div>
                            <Icon type="external-link" size="3xl" />
                        </Button>
                    ))}
                </div>
            ) : null}
        </nav>
    );
};

export default Sidebar;
