import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//API
import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

//Actions
import { showGlobalLoading } from "../../../actions/uiActions";
import { setSectionName, setSectionContent } from "../../../actions/sectionActions";

import { capitalizeFirst } from "../../Utils/Utils";

const GetAreaDescription = ({ id, close }) => {
    //Store
    const { langStrings } = useSelector((state) => state.ui);

    //Actions
    const dispatch = useDispatch();
    const GET_AREA_INFO = gql`{
        commonzones: commonzones( id :${id}) {
          info { count }
          results {id name descriptionTID currentComment currentCommentTID }
        }
    }`;

    const [getAreaData, areaData] = useLazyQuery(GET_AREA_INFO, {
        fetchPolicy: "network-only",
        errorPolicy: "all",
    });

    useEffect(() => {
        getAreaData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (areaData.data && areaData.data.commonzones && areaData.data.commonzones.results) {
            const aData = areaData.data.commonzones.results[0];
            if (aData) {
                dispatch(
                    setSectionName(
                        `${aData.name} - ${capitalizeFirst(
                            langStrings[close ? "area-temp-close" : "edit-description"]
                        )}`
                    )
                );
                dispatch(
                    setSectionContent({
                        data: {
                            area: {
                                name: aData.name,
                                textId: aData.descriptionTID,
                                commentTextId: aData.currentCommentTID,
                            },
                        },
                    })
                );
            } else {
                dispatch(showGlobalLoading(false));
            }
        }
        // eslint-disable-next-line
    }, [areaData]);
};

export default GetAreaDescription;
