import React, { useState, useEffect, useRef, useCallback } from "react";
import Modal, { useModal } from "components/Modal";
import { toast } from "react-toastify";
import classNames from "classnames";
import { Radiobutton } from "components/Inputs/Radiobuttons";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import Button from "components/Button";
import Loading from "components/Loading";
import Search from "components/TextInput/Search";
import Select from "components/Select";
import ItemSelectionMedia from "components/Section/MediaLibrary/modals/BrowserMediaLibraryModalComponents/ItemSelectionMedia";
import { useDispatch } from "react-redux";
import {
    UseContentTree,
    useCreateSignageContents,
    UseLibraryVideosInfo,
    UseListLibraryContentsAndDesigns,
    useUpdateSignageContent,
} from "components/Section/Design/Advertising/graphql/useSignages";
import _ from "lodash";

import { formatMillisecondsToHMS } from "../utils";
import i18n from "i18n";

export const SELECTION_MODE_MULTIPLE = "MULTIPLE";
export const SELECTION_MODE_SINGLE = "SINGLE";

export const TYPE_OF_CONTENT_MEDIA_FILES = "media-files";
export const TYPE_OF_CONTENT_SCREENS = "screens";

const INITIAL_BREADCRUMBS_MEDIA_FILES = () => {
    return [{ name: i18n.t("media-library"), ref: "root", current: true }];
};

const INITIAL_BREADCRUMBS_SCREENS = (content, name) => {
    return [
        {
            name: name,
            ref: "root",
            current: true,
            contents: content,
        },
    ];
};

const buildTypeFilter = (type = ["asset", "video"]) => {
    return ["folder", ...type].join(",");
};

const getCurrentBreadcrumbRef = (breadcrumbs) => {
    return breadcrumbs.find((breadcrumb) => breadcrumb.current)?.ref;
};

export const handleReplaceVideoSelection = ({ selectedRefs, getLibraryVideosInfo }) => {
    const videoRefs = selectedRefs
        .filter((selectedRef) => selectedRef.type === "video")
        .map((selectedRef) => selectedRef.ref);
    getLibraryVideosInfo(videoRefs);
};

export const handleReplaceNonVideoSelection = ({ selectedRefs, id, defaultDuration, idItem, updateSignageContent }) => {
    const { ref, type } = selectedRefs[0];
    const variables = {
        signageID: id,
        type: type.toUpperCase(),
        ref: ref,
        duration: defaultDuration,
        id: idItem,
    };
    updateSignageContent({ variables });
};

export const handleMultipleNonVideoSelection = ({ selectedRefs, id, defaultDuration, createSignageContents }) => {
    const contentArr = selectedRefs.map((selectedRef) => {
        const { type, ref } = selectedRef || {};
        return {
            signageID: id,
            type: type.toUpperCase(),
            ref: ref,
            duration: defaultDuration,
        };
    });
    createSignageContents(contentArr);
};

export const handleMultipleVideoSelection = ({ selectedRefs, getLibraryVideosInfo }) => {
    const videoRefs = selectedRefs
        .filter((selectedRef) => selectedRef.type === "video")
        .map((selectedRef) => selectedRef.ref);
    getLibraryVideosInfo(videoRefs);
};

export const handleMultipleContentCreation = ({
    selectedRefs,
    id,
    defaultDuration,
    videosInfo,
    createSignageContents,
    defaultLanguage,
}) => {
    const contentArr = selectedRefs.map((selectedRef) => {
        const { type, ref } = selectedRef || {};

        const duration = calculateDuration({ type, ref, videosInfo, defaultDuration, defaultLanguage });

        return {
            signageID: id,
            type: type.toUpperCase(),
            ref: ref,
            duration: duration,
        };
    });

    createSignageContents(contentArr);
};

export const handleSingleContentUpdate = ({
    selectedRefs,
    id,
    defaultDuration,
    videosInfo,
    updateSignageContent,
    idItem,
    defaultLanguage,
}) => {
    const { ref, type } = selectedRefs[0];

    const duration = calculateDuration({ type, ref, videosInfo, defaultDuration, defaultLanguage });

    const variables = {
        signageID: id,
        type: type.toUpperCase(),
        ref: ref,
        duration: duration,
        id: idItem,
    };

    updateSignageContent({ variables });
};

export const calculateDuration = ({ type, ref, videosInfo, defaultDuration, defaultLanguage }) => {
    if (type !== "video") return defaultDuration;

    const videoData = videosInfo.find((video) => video.ref === ref);
    if (videoData) {
        const languageInfo =
            videoData.languages.find((lang) => lang.language === defaultLanguage) ||
            videoData.languages.find((lang) => lang.language === videoData.defaultLanguage);

        return formatMillisecondsToHMS(languageInfo?.duration) || defaultDuration;
    }

    return defaultDuration;
};

const processContentTree = (dir) => {
    const combinedContents = [
        ...(dir.subDirs || []).map((subDir) => ({
            ...subDir,
            type: "folder",
            contents: processContentTree(subDir),
        })),
        ...(dir.contents || []).map((item) => ({
            ...item,
            type: "screen",
            ref: item.id,
        })),
    ];
    return combinedContents;
};

const buildQueryVariables = (filters, breadcrumbs) => {
    const { filterBy, search } = filters;
    return {
        variables: {
            filter: {
                type: filterBy ? buildTypeFilter([filterBy]) : buildTypeFilter(),
                search: search,
            },
            ref: getCurrentBreadcrumbRef(breadcrumbs),
        },
    };
};

const handleSelect = ({ selectedRef, setSelectedRefs, isReplace }) => {
    setSelectedRefs((prevSelectedRefs) => {
        if (isReplace) {
            return [selectedRef];
        } else {
            const isAlreadySelected = prevSelectedRefs.some((refObj) => refObj.ref === selectedRef.ref);
            if (isAlreadySelected) {
                return prevSelectedRefs.filter((refObj) => refObj.ref !== selectedRef.ref);
            } else {
                return [...prevSelectedRefs, selectedRef];
            }
        }
    });
};

const handleClickItemContentTree = ({ el, setBreadcrumbs, setContentTreeState, handleSelect }) => {
    if (el.type === "folder") {
        setBreadcrumbs((prev) => {
            const exists = prev.some((item) => item.ref === el.ref);
            if (exists) {
                return prev;
            }
            return [
                ...prev.map((item) => ({ ...item, current: false })),
                { name: el.name, ref: el.ref, current: true, contents: el.contents },
            ];
        });

        setContentTreeState((prevState) => ({
            ...prevState,
            current: el.contents,
        }));
    } else {
        handleSelect({ ref: el.id, type: el.type });
    }
};

const getVideosRefsFromSelected = (selectedRefs) => {
    return selectedRefs.filter((selectedRef) => selectedRef.type === "video").map((selectedRef) => selectedRef.ref);
};

const stylesMediaContainer = {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fill, minmax(clamp(10rem, 100%, 10rem), 1fr))`,
    gridTemplateRows: "repeat(2, 10rem)",
    gridGap: "1rem",
    alignItems: "center",
    overflow: "hidden",
    padding: "0.5rem",
    minHeight: "40vh",
    maxHeight: "40vh",
    overflowY: "scroll",
};

const handleMutationCallback = ({ close, refetch, t }) => ({
    onCompleted: () => {
        close();
        toast.success(t("operation-successful"));
        refetch();
    },
    onError: (err) => {
        close();
        toast.error(t(err));
    },
});

const handleClickItemMediaLibrary = ({
    el,
    getLibraryContentsAndDesigns,
    filters,
    setFilters,
    setBreadcrumbs,
    handleSelect,
}) => {
    if (el.type === "folder") {
        getLibraryContentsAndDesigns({
            variables: {
                filter: {
                    type: filters.filterBy ? buildTypeFilter([filters.filterBy]) : buildTypeFilter(),
                },
                ref: el.ref,
            },
        });
        setFilters((prevFilters) => ({
            ...prevFilters,
            search: "",
        }));
        setBreadcrumbs((prev) => {
            const exists = prev.some((item) => item.ref === el.ref);
            if (exists) {
                return prev;
            }
            return [
                ...prev.map((item) => ({ ...item, current: false })),
                { name: el.name, ref: el.ref, current: true },
            ];
        });
    } else {
        handleSelect({ ref: el.ref, type: el.type });
    }
};

const ModalContent = (props) => {
    const { t } = useTranslation();

    const { params = {}, refetch = () => console.log("refetch action") } = props;
    const { name, id, defaultDuration, defaultLanguage, isReplace = false, idItem } = params;

    const [dataLibrary, setDataLibrary] = useState([]);
    const [selectedRefs, setSelectedRefs] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState(INITIAL_BREADCRUMBS_MEDIA_FILES());
    const [filters, setFilters] = useState({
        filterBy: "",
        search: "",
    });
    const [contentOption, setContentOption] = useState(TYPE_OF_CONTENT_MEDIA_FILES);
    const [contentTreeState, setContentTreeState] = useState({
        initial: [],
        current: [],
    });
    const [loadingMutation, setLoadingMutation] = useState(false);
    const [dataDesignAssigned, setDataDesignAssigned] = useState(null);

    const { getLibraryContentsAndDesigns, libraryContents, designsLocalAssigned, loading } =
        UseListLibraryContentsAndDesigns();
    const { getContentTree, contentTree: contentTreeData } = UseContentTree();
    const {
        getLibraryVideosInfo,
        videosInfo,
        loading: loadingLibraryVideoInfo,
    } = UseLibraryVideosInfo(getVideosRefsFromSelected(selectedRefs));
    const { createSignageContents, loading: loadingCreateSignageContents } = useCreateSignageContents(
        handleMutationCallback({
            close: props.close,
            refetch,
            t,
        })
    );
    const { updateSignageContent, loading: loadingUpdateSignageContent } = useUpdateSignageContent(
        handleMutationCallback({
            close: props.close,
            refetch,
            t,
        })
    );

    useEffect(() => {
        if (loadingCreateSignageContents || loadingLibraryVideoInfo || loadingUpdateSignageContent) {
            setLoadingMutation(true);
        } else {
            setLoadingMutation(false);
        }
    }, [loadingCreateSignageContents, loadingLibraryVideoInfo, loadingUpdateSignageContent]);

    useEffect(() => {
        setDataLibrary(libraryContents);
        if (designsLocalAssigned?.length > 0) {
            setDataDesignAssigned({ id: designsLocalAssigned?.[0]?.id, name: designsLocalAssigned?.[0]?.name });
        }
    }, [libraryContents, designsLocalAssigned]);

    useEffect(() => {
        if (dataDesignAssigned && dataDesignAssigned?.id !== null) {
            getContentTree({
                variables: { designID: dataDesignAssigned.id },
            });
        }
    }, [dataDesignAssigned]);

    useEffect(() => {
        if (videosInfo?.length > 0 && selectedRefs?.length > 0) {
            const commonParams = {
                selectedRefs,
                id,
                defaultDuration,
                videosInfo,
                createSignageContents,
                updateSignageContent,
                idItem,
                defaultLanguage,
            };

            if (!isReplace) {
                handleMultipleContentCreation(commonParams);
            } else {
                handleSingleContentUpdate(commonParams);
            }
        }
    }, [videosInfo]);
    useEffect(() => {
        if (contentTreeData) {
            const processedTree = processContentTree(contentTreeData?.screens?.dir);
            setContentTreeState({
                initial: processedTree,
                current: processedTree,
            });
        }
    }, [contentTreeData]);

    useEffect(() => {
        const initializeContent = () => {
            if (contentOption === TYPE_OF_CONTENT_MEDIA_FILES) {
                setBreadcrumbs(INITIAL_BREADCRUMBS_MEDIA_FILES());
            } else {
                const initialBreadcrumbs = INITIAL_BREADCRUMBS_SCREENS(
                    contentTreeState.initial,
                    dataDesignAssigned?.name
                );
                setBreadcrumbs(initialBreadcrumbs);
                setContentTreeState((prevState) => ({
                    ...prevState,
                    current: prevState.initial,
                }));
            }
        };
        initializeContent();
    }, [contentOption]);

    useEffect(() => {
        getLibraryContentsAndDesigns(buildQueryVariables(filters, breadcrumbs));
    }, [filters, breadcrumbs]);

    const resetMediaFilesState = () => {
        setSelectedRefs([]);
        setFilters({
            filterBy: "",
            search: "",
        });
        setBreadcrumbs(INITIAL_BREADCRUMBS_MEDIA_FILES());
    };

    const handleSelectWrapper = (selectedRef) => {
        handleSelect({ selectedRef, setSelectedRefs, isReplace });
    };

    const handleClickItemContentTreeWrapper = (el) => {
        handleClickItemContentTree({
            el,
            setBreadcrumbs,
            setContentTreeState,
            handleSelect: handleSelectWrapper,
        });
    };

    const handleClickItemMediaLibraryWrapper = (el) => {
        handleClickItemMediaLibrary({
            el,
            getLibraryContentsAndDesigns,
            filters,
            setFilters,
            setBreadcrumbs,
            handleSelect: handleSelectWrapper,
        });
    };

    const handleBreadcrumbClick = (breadcrumb, index) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            search: "",
        }));
        const newBreadcrumbs = breadcrumbs.slice(0, index + 1).map((item, i) => ({
            ...item,
            current: i === index,
        }));
        setBreadcrumbs(newBreadcrumbs);

        if (contentOption === TYPE_OF_CONTENT_SCREENS) {
            const currentBreadcrumb = newBreadcrumbs.find((breadcrumb) => breadcrumb.current);
            setContentTreeState((prevState) => ({
                ...prevState,
                current: currentBreadcrumb?.contents,
            }));
        } else {
            getLibraryContentsAndDesigns(buildQueryVariables(filters, newBreadcrumbs));
        }
    };

    return (
        <Modal
            title={isReplace ? ` ${name || ""} -  ${t("replace-content")}` : t("add-content")}
            className="p-10 w-11/12"
            footer={
                !loadingMutation ? (
                    <>
                        <Button design="blue-outline" id="modal-button-cancel" onClick={() => props?.close()}>
                            {t("cancel")}
                        </Button>
                        <Button
                            design="blue"
                            id="modal-button-choose"
                            onClick={() => {
                                if (!selectedRefs || selectedRefs.length === 0) return;
                                const commonParams = {
                                    selectedRefs,
                                    id,
                                    defaultDuration,
                                    idItem,
                                    getLibraryVideosInfo,
                                    createSignageContents,
                                    updateSignageContent,
                                };

                                if (isReplace) {
                                    if (selectedRefs.some((item) => item.type === "video")) {
                                        handleReplaceVideoSelection(commonParams);
                                    } else {
                                        handleReplaceNonVideoSelection(commonParams);
                                    }
                                } else {
                                    if (selectedRefs.some((item) => item.type === "video")) {
                                        handleMultipleVideoSelection(commonParams);
                                    } else {
                                        handleMultipleNonVideoSelection(commonParams);
                                    }
                                }
                            }}
                            disabled={selectedRefs?.length === 0}
                        >
                            {t("choose")}
                        </Button>
                    </>
                ) : null
            }
        >
            {!loadingMutation ? (
                <>
                    <div className="">{t("select-type-of-content-to-add-to-sequence")}</div>
                    <TypeOfContent
                        contentOption={contentOption}
                        setContentOption={setContentOption}
                        resetMediaFilesState={resetMediaFilesState}
                    />
                    {contentOption === TYPE_OF_CONTENT_MEDIA_FILES ? (
                        <Controls
                            filters={filters}
                            setFilters={setFilters}
                            breadcrumbs={breadcrumbs}
                            getLibraryContentsAndDesigns={getLibraryContentsAndDesigns}
                        />
                    ) : null}
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <Breadcrumbs breadcrumbs={breadcrumbs} handleClick={handleBreadcrumbClick} />
                            <div
                                className="mt-2"
                                style={{
                                    ...stylesMediaContainer,
                                }}
                            >
                                {contentOption === TYPE_OF_CONTENT_MEDIA_FILES
                                    ? dataLibrary.map((el, index) => {
                                          return (
                                              <ItemSelectionMedia
                                                  key={index}
                                                  el={el}
                                                  index={index}
                                                  isSelected={selectedRefs.some((selected) => selected.ref === el.ref)}
                                                  handleClick={() => handleClickItemMediaLibraryWrapper(el)}
                                                  id={`${index}-media`}
                                              />
                                          );
                                      })
                                    : null}
                                {contentOption === TYPE_OF_CONTENT_SCREENS
                                    ? contentTreeState.current.map((el, index) => {
                                          return (
                                              <ItemSelectionMedia
                                                  key={index}
                                                  el={el}
                                                  index={index}
                                                  isSelected={selectedRefs.some((selected) => selected.ref === el.ref)}
                                                  handleClick={() => handleClickItemContentTreeWrapper(el)}
                                                  id={`${index}-content-tree`}
                                              />
                                          );
                                      })
                                    : null}
                            </div>
                        </>
                    )}
                </>
            ) : (
                <Loading />
            )}
        </Modal>
    );
};

const Controls = ({ filters, setFilters, breadcrumbs }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const queryInfoRef = useRef({ filters, breadcrumbs });

    useEffect(() => {
        queryInfoRef.current = { filters, breadcrumbs };
    }, [filters, breadcrumbs]);

    const debouncedQuery = useCallback(
        _.debounce((searchValue) => {
            setFilters((prevFilters) => ({
                ...prevFilters,
                search: searchValue,
            }));
        }, 1000),
        []
    );

    return (
        <div className="mt-4 flex w-full">
            <div className="w-1/4">
                <Search
                    value={filters.search}
                    onChange={(val) => {
                        debouncedQuery(val);
                    }}
                    className="w-full"
                />
            </div>
            <div className="w-1/4 flex items-center ml-8">
                <div className="mr-2 whitespace-no-wrap">{t("filter-by")}:</div>
                <div className="w-full">
                    <Select
                        id="filter-content-type"
                        placeholder={t("type")}
                        options={[
                            { value: "", label: t("all") },
                            { value: "video", label: t("videos") },
                            { value: "asset", label: t("images") },
                        ]}
                        allowUnselect={false}
                        value={filters.filterBy}
                        onChange={(value) => {
                            setFilters((prevFilters) => ({
                                ...prevFilters,
                                filterBy: value,
                            }));
                        }}
                    />
                </div>
            </div>
            <div className="w-1/4 flex items-center h-full"></div>
            {/* <div className="w-1/4 flex items-center justify-end">
                <Button onClick={addMedia} id="add-media-button" design="blue">
                    <Icon type="add" className="mr-2" />
                    {t("add-media")}
                </Button>
            </div> */}
        </div>
    );
};

const Breadcrumbs = ({ breadcrumbs, handleClick }) => {
    const getClassNames = (isCurrent) =>
        classNames({
            "font-bold text-gray-900": isCurrent,
            "text-zafiro-600 mr-2": !isCurrent,
        });

    return (
        <div className="w-full flex mt-2">
            {breadcrumbs.map((breadcrumb, index) => (
                <Button
                    key={index}
                    className={getClassNames(breadcrumb.current)}
                    id={`breadcrumb-media-${index}`}
                    disabled={breadcrumb.current}
                    onClick={() => handleClick(breadcrumb, index)}
                >
                    <div>{breadcrumb.name}</div>
                    <div>{breadcrumb.current === false ? "/" : ""}</div>
                </Button>
            ))}
        </div>
    );
};

const TypeOfContent = ({ contentOption, setContentOption, resetMediaFilesState }) => {
    const { t } = useTranslation();

    return (
        <div className="w-full mt-4">
            <div className="flex items-center">
                <Radiobutton
                    id={`-option-color`}
                    checked={contentOption === TYPE_OF_CONTENT_MEDIA_FILES}
                    value={TYPE_OF_CONTENT_MEDIA_FILES}
                    onChange={({ checked, value }) => {
                        if (checked) {
                            setContentOption(value);
                            resetMediaFilesState();
                        }
                    }}
                />
                <div className="-mt-1 ml-2">{t(TYPE_OF_CONTENT_MEDIA_FILES)}</div>
            </div>
            <div className="flex items-center mt-2">
                <Radiobutton
                    id={`-option-color`}
                    checked={contentOption === TYPE_OF_CONTENT_SCREENS}
                    value={TYPE_OF_CONTENT_SCREENS}
                    onChange={({ checked, value }) => {
                        if (checked) {
                            setContentOption(value);
                            resetMediaFilesState();
                        }
                    }}
                />
                <div className="ml-2 flex">
                    <div className="-mt-1">{t(TYPE_OF_CONTENT_SCREENS)}</div>
                    <div className="ml-2 -mt-1 text-gray-500">({t("my-screens")})</div>
                    <Icon
                        type="info"
                        size={1.5}
                        className="-mt-1 ml-2"
                        tooltip={t("you-can-add-any-screen-from-my-screens-section-of-the-applied-design")}
                    />
                </div>
            </div>
        </div>
    );
};

const useAddContentReplaceSignage = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalContent {...newProps} />);
        },
    };
};

export default useAddContentReplaceSignage;
