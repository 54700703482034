import React, { useState, useEffect } from "react";
import UseSectionHeader from "../../../../useSectionHeader";
import { useTranslation } from "react-i18next";
import UseInputNumberArrows from "../../../../Table/UseInputNumberArrows";
import { ContainerBulkChannelCard } from "./BulkChannelsCard";
import { Session } from "../../../../../hooks/Utils/Session";
import { useNavigate as useHistory } from "react-router-dom";
import uuid from "react-uuid";
import UpdateBulkChannels from "../../../../../hooks/GraphqlCalls/Services/UpdateBulkChannels";
import UseButton from "../../../../useButton";
import { useDispatch } from "react-redux";
import { setExecuteQuery } from "../../../../../actions/sectionActions";
import { toast } from "react-toastify";
import { isValidIP, isAValidHTTPS } from "../../../../../hooks/Utils/Services/ChannelsUtils";
import { cleanActionValues } from "../../../../../actions/globalActions";

const BulkChannels = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [numberChannels, setNumberChannels] = useState(1);
    const [channels, setChannels] = useState([]);
    const [validations, setValidations] = useState({});
    const [validationsFields, setValidationsFields] = useState({});

    const channelType = Session.getSessionProp("type-bulk-selected");

    useEffect(() => {
        if (!channelType) {
            history("/services/tv-channels/channels/");
        }
        // eslint-disable-next-line
    }, []);

    const handleDeleteChannel = (idToDelete) => {
        setChannels((prevChannels) => {
            const newArr = prevChannels.filter((channel) => channel.id !== idToDelete);
            setNumberChannels(newArr.length);
            return newArr;
        });
    };

    const validateChannels = (channels, channelType) => {
        const channelData =
            channelType === "customised-channel"
                ? validateCustomisedChannels(channels)
                : validatePresetChannels(channels);
        setValidations(channelData.validateObj);
        setValidationsFields(channelData.validateErrorFields);

        const { validateObj, filledChannels, validateErrorFields } = channelData;
        const isEmptyValidation = Object.keys(validateObj).length > 0;
        const isEmptyErrorValid = Object.keys(validateErrorFields).length > 0;
        const isEmptyFilledChannels = filledChannels.length < 1 && !isEmptyValidation;

        if (isEmptyErrorValid) {
            toast.error(t("input error"));
        }

        if (isEmptyValidation) {
            toast.error(t("there-are-empty-fields"));
        }

        if (isEmptyValidation || isEmptyErrorValid) {
            return;
        }

        if (isEmptyFilledChannels) {
            toast.error(t("you-need-to-fill-at-least-one-channel"));
            return;
        }

        dispatchQueryChannels(dispatch, channelType, filledChannels);
    };

    useEffect(() => {
        const numberOfChannels = parseInt(numberChannels);
        if (numberOfChannels < channels.length) {
            setChannels((prevChannels) => {
                return prevChannels.slice(0, numberOfChannels);
            });
        } else if (numberOfChannels > channels.length) {
            setChannels((prevChannels) => {
                return [
                    ...prevChannels,
                    ...new Array(numberOfChannels - prevChannels.length).fill({}).map(() =>
                        channelType === "customised-channel"
                            ? {
                                  id: uuid(),
                                  logoRef: "",
                                  name: "",
                                  countryRef: "",
                                  languageRef: "",
                                  isRadio: false,
                                  hls: "",
                                  ip: "",
                                  port: "",
                                  udpCB: false,
                                  hlsCB: false,
                              }
                            : {
                                  id: uuid(),
                                  hls: "",
                                  ip: "",
                                  port: "",
                                  udpCB: false,
                                  hlsCB: false,
                                  idChannel: "",
                                  logoRef: "",
                              }
                    ),
                ];
            });
        }
        // eslint-disable-next-line
    }, [numberChannels]);

    return (
        <>
            <UseSectionHeader navToPreviousPage={true} title={"add-bulk-channels"} customBreadCrumbs={breadcrumbs} />
            <div className="mt-4 px-8 py-6 bg-white rounded">
                <span className="block mb-5 font-bold text-xl">
                    {channelType === "preset-channel"
                        ? t("add-and-tune-preset-channels")
                        : t("add-and-tune-customised-channels")}
                </span>
                <div className="flex items-center pb-4 border-b border-gray-200 mb-4">
                    <span className="block mr-6">{t("enter-the-number-of-channel-you-want-to-add")}</span>
                    <UseInputNumberArrows
                        minNumber={1}
                        value={numberChannels}
                        validation={true}
                        onChange={(val) => {
                            if (val === "" || (Number(val) > 0 && Number(val) <= 20)) {
                                setNumberChannels(val);
                            }
                        }}
                        inputData={{ name: "number-bulk-channels" }}
                        id={`number-channels`}
                    />
                    <span className="block ml-4">{t("channel")}</span>
                </div>
                <div
                    className="mb-6"
                    id="bulk-channels-container"
                    style={{ minHeight: "40rem", maxHeight: "35rem", overflowY: "scroll" }}
                >
                    {channels.map((channel, index) => (
                        <ContainerBulkChannelCard
                            key={channel.id}
                            idCard={channel.id}
                            number={index}
                            disabledDelete={channels.length <= 1}
                            onDelete={() => handleDeleteChannel(channel.id)}
                            channelType={channelType}
                            setChannelsInfo={setChannels}
                            channelsInfo={channels}
                            validations={validations}
                            validationsErrorFields={validationsFields}
                        />
                    ))}
                </div>
                <div className="border-b border-gray-200 mb-4"></div>
                <div className="flex justify-end">
                    <UseButton
                        id={`cancel-button-${channelType}`}
                        action={() => {
                            dispatch(cleanActionValues());
                            history("/services/tv-channels/channels/");
                        }}
                        adjust={"mr-8"}
                        buttonColor={"btn-white"}
                        buttonName={t("cancel")}
                    />
                    <UseButton
                        id={`add-button-${channelType}`}
                        action={() => validateChannels(channels, channelType)}
                        buttonColor={"btn-blue"}
                        buttonName={t("add")}
                    />
                </div>
            </div>
            <UpdateBulkChannels />
        </>
    );
};

export default BulkChannels;

const breadcrumbs = [
    {
        name: "services",
        translate: true,
        route: "/services",
    },
    {
        name: "tv-channels",
        translate: true,
        route: "/services/tv-channels/",
    },
    {
        name: "channel-list",
        translate: true,
        route: "/services/tv-channels/channels",
    },
];
const validatePresetChannels = (channels) => {
    const validateObj = {};
    const filledChannels = [];
    const validateErrorFields = {};

    channels.forEach((obj) => {
        validateFieldsTunningCard(obj, validateErrorFields);

        if (obj.idChannel !== "") {
            filledChannels.push(obj);
            return;
        }

        if (
            obj.idChannel !== "" ||
            obj.ip !== "" ||
            obj.port !== "" ||
            obj.hls !== "" ||
            obj.hlsCB !== false ||
            obj.udpCB !== false
        ) {
            validateObj[obj.id] = {
                idChannel: obj.idChannel !== "",
            };
        }
    });

    return { validateObj, filledChannels, validateErrorFields };
};

const validateCustomisedChannels = (channels) => {
    const validateObj = {};
    const filledChannels = [];
    const validateErrorFields = {};

    channels.forEach((obj) => {
        validateFieldsTunningCard(obj, validateErrorFields);
        if (obj.name !== "" && obj.countryRef !== "" && obj.languageRef !== "") {
            filledChannels.push(obj);
            return;
        }
        if (
            obj.name !== "" ||
            obj.countryRef !== "" ||
            obj.languageRef !== "" ||
            obj.ip !== "" ||
            obj.port !== "" ||
            obj.hls !== "" ||
            obj.logoRef !== "" ||
            obj.hlsCB !== false ||
            obj.udpCB !== false
        ) {
            validateObj[obj.id] = {
                name: obj.name !== "",
                countryRef: obj.countryRef !== "",
                languageRef: obj.languageRef !== "",
            };
        }
    });

    return { validateObj, filledChannels, validateErrorFields };
};

const validateFieldsTunningCard = (obj, validateErrorFields) => {
    if (obj.ip !== "" || obj.port !== "") {
        if (obj.port !== "" && obj.ip === "") {
            validateErrorFields[obj.id] = {
                ip: true,
                port: false,
            };
        } else if (obj.port === "" && obj.ip !== "") {
            validateErrorFields[obj.id] = {
                ip: isValidIP(obj.ip) === false ? true : false,
                port: true,
            };
        } else if (isValidIP(obj.ip) === false) {
            validateErrorFields[obj.id] = {
                ip: true,
                port: false,
            };
        }
    }

    if (obj.hls !== "") {
        if (validateErrorFields[obj.id]) {
            if (!isAValidHTTPS(obj.hls)) {
                validateErrorFields[obj.id] = {
                    ...validateErrorFields[obj.id],
                    hls: true,
                };
            }
        } else {
            if (!isAValidHTTPS(obj.hls)) {
                validateErrorFields[obj.id] = {
                    hls: true,
                };
            }
        }
    }

    return validateErrorFields;
};

const dispatchQueryChannels = (dispatch, channelType, channels) => {
    dispatch(
        setExecuteQuery({
            action:
                channelType === "customised-channel"
                    ? "create-customised-bulk-channels"
                    : "create-preset-bulk-channels",
            params: {
                channels: channels,
            },
        })
    );
};
