import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SalesContext } from "contexts/Sales";

import { decimalSeparator, formatPriceValueVendure } from "hooks/Utils/SalesUtils";
import { executeVendureQuery, useVendureAPI } from "hooks/Utils/Integrations/useVendure";
import currenciesVendure from "hooks/Utils/Integrations/vendure-currencies.json";
import {
    GET_VENDURE_ORDER,
    GET_VENDURE_ORDER_SHOP_API,
    getDeliveryDetails,
    getOrderProductsInfo,
} from "components/Section/Customers/Guest/GuesOrders.js/GuestOrdersUtils";

import RoomChangeInfo from "components/RoomChangeInfo";
import OrderStatus from "components/OrderStatus";
import { useLocationMapModal } from "components/LocationMap";

import { PMS_STATUS_UNKNOWN } from "constants/charges";
import { getDeliveryMethodIcon, getDeliveryMethodText, useDeliveryLocationInfo } from "constants/sales";

const GuestOrderRow = ({
    dataRow: data,
    setRowInfoId,
    rowInfoId: activeInfoId,
    setDataOrdersVendure,
    dataOrderVendure,
    isModal: isRoomOrdersModal,
    index,
    setShowMap,
}) => {
    const { t } = useTranslation();

    const { permissions, pricesIncludeTax } = useContext(SalesContext);
    const { adminApi, shopApi } = useVendureAPI();

    const { open: openMap } = useLocationMapModal();

    const showMap = isRoomOrdersModal ? setShowMap : openMap;

    const hasOrdersManagement = permissions?.ordersManagement;
    const hasPMSManagement = permissions?.pmsManagement;

    const displayOrderDetails = activeInfoId === data.id;

    const [productsInfo, setProductsInfo] = useState([]);
    const [comments, setComments] = useState("");
    const [totalPrice, setTotalPrice] = useState(null);

    const getDeliveryLocationInfo = useDeliveryLocationInfo();

    const SECTION_NAME = "orders-list";
    const GET_ORDER = GET_VENDURE_ORDER(data.id);
    const GET_ORDER_SHOP_API = GET_VENDURE_ORDER_SHOP_API(data.id);

    const orderStatus = data?.state || "unknown";
    const pmsStatus = data?.pmsStatus || PMS_STATUS_UNKNOWN;

    const getVendureOrderInfo = async () => {
        const response = await executeVendureQuery(
            adminApi,
            { queryBody: GET_ORDER },
            { "vendure-token": data.shop.token ? data.shop.token : "" }
        );

        const responserOrderShopApi = await executeVendureQuery(
            shopApi,
            { queryBody: GET_ORDER_SHOP_API },
            { "vendure-token": data.shop.token ? data.shop.token : "" }
        );

        if (response?.data?.order && !response.errors) {
            const productsInfo = getOrderProductsInfo(response, responserOrderShopApi);
            const comments = response.data.order.history.items
                .filter((i) => i?.data?.note)
                .map((i) => i.data.note)
                .join("\n");
            setDataOrdersVendure((prev) => [
                ...prev,
                {
                    id: data.id,
                    data: response.data.order,
                    productsInfo: productsInfo,
                    comments: response.data.order.modifications,
                },
            ]);
            setComments(comments);
            setProductsInfo(productsInfo);
        }
    };

    useEffect(() => {
        if (!dataOrderVendure.some((obj) => obj.id === data.id)) {
            getVendureOrderInfo();
        } else {
            const order = dataOrderVendure.find((obj) => obj.id === data.id);
            setProductsInfo(order.productsInfo);
            setComments(order.comments.map((mod) => mod.note).join(" "));
        }
    }, []);

    const roomChangeInfo =
        data?.roomName != data?.origRoomName ? (
            <RoomChangeInfo className="text-gray-900 ml-3" current={data?.roomName} previous={data?.origRoomName} />
        ) : null;

    const deliveryText = t(getDeliveryMethodText(data?.deliveryMethod));
    const deliveryIcon = getDeliveryMethodIcon(data?.deliveryMethod);
    const deliveryInfo = getDeliveryLocationInfo(data?.deliveryMethod, data?.deliveryLocation);
    const mapLink = deliveryInfo?.coords ? (
        <button
            id={`${SECTION_NAME}-show-map-${index}`}
            onClick={() => showMap({ latitude: deliveryInfo.coords.lat, longitude: deliveryInfo.coords.lng })}
            className="text-blue-300 hover:text-blue-500 focus:outline-none font-bold px-2"
        >
            {t("Show map")}
        </button>
    ) : null;

    const deliveryDetails = getDeliveryDetails(data);

    useEffect(() => {
        pricesIncludeTax()
            .then((res) => {
                setTotalPrice(res ? data?.totalWithTax : data?.total);
            })
            .catch((e) => {
                console.error("Error getting pricesIncludeTax", e);
            });
    }, []);

    return (
        <div className="w-full flex flex-col">
            <div
                className={` w-full flex h-18  items-center border-b ${
                    !displayOrderDetails ? "border-gray-200" : "border-transparent"
                }`}
            >
                <div className={` flex ${!isRoomOrdersModal ? "w-2/12" : " w-3/12"} `}>
                    <button
                        id={`${index}-icon-extended-info`}
                        onClick={() => {
                            if (displayOrderDetails) {
                                setRowInfoId(null);
                            } else {
                                setRowInfoId(data.id);
                            }
                        }}
                        className={`icon mr-3 focus:outline-none icon-chevron${displayOrderDetails ? "-up" : ""}`}
                    ></button>
                    <div id={`r${index}-c0-${SECTION_NAME}-number-order`} className="flex">
                        {data?.id ? data.id : 0}
                        {isRoomOrdersModal ? roomChangeInfo : null}
                    </div>
                </div>
                {isRoomOrdersModal ? null : (
                    <div id={`text-cell-r${index}-c1`} className="w-2/12 flex">
                        {data?.roomName}
                        {roomChangeInfo}
                    </div>
                )}
                <div id={`date-cell-r${index}-c2`} className="w-2/12">
                    {data?.infoDateHour ? (
                        <>
                            <div id={`date-cell-hour-r${index}-c2`}>{data?.infoDateHour?.hour}</div>
                            <div id={`date-cell-hour-r${index}-c2`}>{data?.infoDateHour?.date}</div>
                        </>
                    ) : null}
                </div>
                <div id={`text-cell-r${index}-c3`} className={` ${!isRoomOrdersModal ? "w-2/12" : " w-3/12"}`}>
                    {data?.shop?.name ? data.shop.name : ""}
                </div>
                <div id={`text-cell-r${index}-c4`} className="w-2/12">
                    {totalPrice
                        ? `${formatPriceValueVendure(
                              totalPrice / 100,
                              data.shop.currencyCode ? currenciesVendure?.currencies?.[data.shop.currencyCode] : null
                          )}`
                        : `0${decimalSeparator}00`}
                </div>
                {hasOrdersManagement || hasPMSManagement ? (
                    <div id={`text-cell-r${index}-c5`} className={`w-1/12`}>
                        <OrderStatus
                            id={`text-cell-subvalue-r${index}-c5`}
                            className="mb-1 font-bold"
                            status={hasOrdersManagement ? orderStatus : null}
                            pmsStatus={hasPMSManagement ? pmsStatus : null}
                        />
                    </div>
                ) : null}
                <div className="w-1/12 text-right">
                    <a
                        id={`icon-cell-subvalue-r${index}-c6`}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/#/services/sales/order-monitor/preview/${data.id}/`}
                        className={"icon-external-link text-2xl text-gray-800 focus:outline-none "}
                    ></a>
                </div>
            </div>
            {displayOrderDetails ? (
                <div className=" w-full pl-8 h-auto border-b border-gray-200">
                    <div className=" w-full h-full py-3 flex border-t border-gray-200 ">
                        <div className=" w-2/5 flex ">
                            <div className="mr-6">{`${t("order-summary")}:`}</div>
                            <div className=" flex flex-col">
                                {productsInfo.map((p, idxProduct) => {
                                    return (
                                        <div key={`${index}-product-${idxProduct}`} className=" flex flex-col">
                                            <div className=" flex">
                                                <span
                                                    id={`product-name-order-${index}-product-${idxProduct}`}
                                                    className=" mr-2 block"
                                                >{`${p.title}`}</span>
                                            </div>
                                            <div className=" pl-6 inline-flex text-gray-700">
                                                <div className=" flex flex-wrap">
                                                    {p.extras.map((e, idx) => {
                                                        return (
                                                            <div className=" inline whitespace-no-wrap">
                                                                {idx === 0 ? (
                                                                    <span className=" mr-1">{`Extra:`}</span>
                                                                ) : null}
                                                                <span
                                                                    id={`extra-quantity-order${index}-product${idxProduct}-extra${idx}`}
                                                                    className=" mr-1"
                                                                >{`${Number(e.quantity)}x`}</span>
                                                                <span
                                                                    id={`extra-name-order${index}-product${idxProduct}-extra${idx}`}
                                                                    className=" mr-1 whitespace-no-wrap"
                                                                >{`${e.name}`}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className=" w-3/5 h-full ">
                            <div className="flex mb-2">
                                <div style={{ minWidth: "12rem", maxWidth: "12rem" }}>{`${t(
                                    "delivery-details"
                                )}:`}</div>
                                <div className=" flex items-center">
                                    <div
                                        id={`${SECTION_NAME}-delivery-method-icon-${index}`}
                                        data-tip={deliveryText}
                                        data-for="default-tooltip"
                                        className={` icon  text-2xl mr-2 text-gray-800 icon-${deliveryIcon}`}
                                    ></div>
                                    <div id={`${SECTION_NAME}-delivery-method-details-${index}`}>
                                        {!deliveryDetails ? (
                                            <p>
                                                {t("as-soon-as-possible")} {mapLink}
                                            </p>
                                        ) : (
                                            <>
                                                <p>
                                                    {deliveryDetails.firstTitle} {mapLink}
                                                </p>
                                                <p>{deliveryDetails.secondTitle}</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex">
                                <div style={{ minWidth: "12rem", maxWidth: "12rem" }} className="capitalize">{`${t(
                                    "comments"
                                )}:`}</div>
                                <div id={`${SECTION_NAME}-comments-order-${index}`}>
                                    <p>{comments}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default GuestOrderRow;
