export const CUSTOMISED_CHANNEL = { name: "channels_", value: "CUSTOMISED", key: "channels" };
export const PRESET_CHANNEL = { name: "preset-channels", value: "PRESET", key: "channels" };
export const CORPORATE_CHANNEL = { name: "corporate-channels", value: "CORPORATE", key: "channels" };

export const getTagInfo = (info) => {
    if (info?.type === "CORPORATE") {
        return {
            color: "bg-gray-800 text-white opacity-75",
            text: "corporate",
        };
    } else if (info.isRadio) {
        return {
            color: "bg-zafiro-200 text-white",
            text: "radio",
        };
    } else {
        return {
            color: "bg-zafiro-600 text-white",
            text: "tv",
        };
    }
};

export const TUNE_TYPES = {
    udp: {
        value: "UDP",
        label: "udp",
        labelColor: "bg-blue-400",
    },
    hls: {
        value: "HLS",
        label: "hls",
        labelColor: "bg-blue-300",
    },
    untuned: {
        value: "Untuned",
        label: "untuned",
    },
    other: {
        labelColor: "bg-blue-100",
    },
};

export const isValidIP = (ip) => {
    // Expresión regular para validar direcciones IP en el rango 224.0.0.0/4
    const regex =
        /^(22[4-9]|23[0-9])\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return regex.test(ip);
};

export const isAValidHTTPS = (url) => {
    const pattern = new RegExp(
        "^(https?:\\/\\/)" +
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
            "((\\d{1,3}\\.){3}\\d{1,3}))" +
            "(\\:\\d+)?(\\/[-a-z\\d%_@.~+]*)*" +
            "(\\?[;&a-z\\d%_.~+=-]*)?" +
            "(\\#[-a-z\\d_]*)?$",
        "i"
    );

    return !!pattern.test(url);
};
