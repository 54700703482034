import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { useState } from "react";
import { Session } from "hooks/Utils/Session";
import { useTranslation } from "react-i18next";
import ReactTooltip from "components/ReactTooltip";

//Components

const TVPreview = () => {
    const { roomtvid, ref } = useParams();
    const { t } = useTranslation();

    const [showHelp, setShowHelp] = useState(false);

    const impersonateTVDevice = `mutation{impersonateZafiroTV(ref:"${ref}" roomTvId:${roomtvid} lang: "en"){ok token stayToken}}`;

    const MUTATION_OBJECT = gql`
        ${impersonateTVDevice}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        errorPolicy: "all",
        onError(err) {},
    });

    useEffect(() => {
        executeMutation();
        let ipUsers = JSON.parse(Session.getSessionProp("interfacePreviewedUsers")) || [];
        if (ipUsers && !ipUsers.includes(Session.getUser()?.email)) {
            setShowHelp(true);
            ipUsers.push(Session.getUser()?.email);
            Session.setSessionProp("interfacePreviewedUsers", JSON.stringify(ipUsers));
        }
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (!showHelp && document.getElementById("tvPreviewFrame")) {
            document.getElementById("tvPreviewFrame").focus();
        }
    }, [showHelp]);

    const toggleHelp = () => {
        showHelp && setShowHelp(false);
    };

    return (
        <div className="w-full h-full bg-gray-100 ">
            <div
                className="mx-auto relative"
                style={{ height: "100%", aspectRatio: "16/9" }}
                onClick={(e) => toggleHelp()}
            >
                {data?.impersonateZafiroTV?.token ? (
                    <iframe
                        id="tvPreviewFrame"
                        height="100%"
                        width="100%"
                        title="tvPreviewFrame"
                        className="overflow-hidden"
                        style={{ aspectRatio: "16/9" }}
                        //src="https://tv-app.testing.zafiro.link?lang=en&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJTZXJ2aWNlTmFtZSI6InNydi1tYW5hZ2VyIiwiRGV2aWNlIjp7IlR5cGUiOiJ0diIsIlJlZiI6IlBDLTg0OjUzOkE0OjQ3OjRCOjExIiwiTGFuZ1JlZiI6ImVuIn0sIkxvY2F0aW9uIjp7IkNoYWluUmVmIjoiY2hhaW4tMDAwMC0wMDAwLTAwMDAtMDAxNCIsIlByb2plY3RSZWYiOiJwcm9qZWN0LTAwMDAtMDAwMC0wMDAwLTAxMTkifSwiSW1wZXJzb25hdGlvbiI6eyJLZXkiOiJyb29tdHZpZCIsIlZhbCI6IjY4NDgifSwiaWF0IjoxNzE0MDYzNTIxfQ.9couUEThFY6Ca3nEGzcS3EBgLveauXdXSiZ_rTEr6ds&stayToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJTZXJ2aWNlTmFtZSI6InNydi1tYW5hZ2VyIiwiU3RheSI6eyJTdGF5R3Vlc3RJRCI6LTEsIlJvb21OdW1iZXIiOi0xfSwiTG9jYXRpb24iOnsiQ2hhaW5SZWYiOiJjaGFpbi0wMDAwLTAwMDAtMDAwMC0wMDE0IiwiUHJvamVjdFJlZiI6InByb2plY3QtMDAwMC0wMDAwLTAwMDAtMDExOSJ9LCJJbXBlcnNvbmF0aW9uIjp7IktleSI6InJvb210dmlkIiwiVmFsIjoiNjg0OCJ9LCJpYXQiOjE3MTQwNjM1MjF9.vC1Knr4YZc8EqAaCbMeHdTA_naQX3rtylhnZ4CU_ZDc"
                        src={`https://tv-app.${document.location.host}?lang=en&token=${data.impersonateZafiroTV.token}&stayToken=${data.impersonateZafiroTV.stayToken}`}
                    ></iframe>
                ) : null}

                {showHelp ? (
                    <div
                        className="block absolute w-full bottom-0 px-5 py-2 bg-gray-900 text-gray-300"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="text-lg flex border-b py-3 border-gray-300">
                            <div className="w-10/12">{t("preview-help-title")}</div>
                            <div className="w-2/12 text-right">
                                <span
                                    className="icon icon-remove text-xl clickable"
                                    onClick={(e) => setShowHelp(false)}
                                ></span>
                            </div>
                        </div>
                        <div className="flex pt-6" style={{ height: "23vh" }}>
                            <div className="w-10/12 flex" style={{ paddingTop: "6.2vh" }}>
                                <div className="w-1/5 text-center">
                                    <div className="icon icon-h mb-6" style={{ fontSize: "3.5rem" }}></div>
                                    <div>{t("go-home")}</div>
                                </div>
                                <div className="w-1/5 text-center">
                                    <div className="icon icon-m mb-6" style={{ fontSize: "3.5rem" }}></div>
                                    <div>{t("go-messages")}</div>
                                </div>
                                <div className="w-1/5 text-center">
                                    <div className="icon icon-l mb-6" style={{ fontSize: "3.5rem" }}></div>
                                    <div>{t("go-languages")}</div>
                                </div>
                                <div className="w-1/5 text-center">
                                    <div
                                        className="icon icon-space mb-6"
                                        style={{ fontSize: "3.5rem" }}
                                        data-tip
                                        data-for={`key-space-tooltip`}
                                    ></div>
                                    <div>{t("go-back")}</div>
                                    <ReactTooltip id={`key-space-tooltip`}>
                                        {" "}
                                        {t("{{capitalize, capitalize}}", { capitalize: t("space") })}
                                    </ReactTooltip>
                                </div>
                                <div className="w-1/5 text-center">
                                    <div
                                        className="icon icon-enter mb-6"
                                        style={{ fontSize: "3.5rem" }}
                                        data-tip
                                        data-for={`key-enter-tooltip`}
                                    ></div>
                                    <div>{t("select-to-confirm")}</div>
                                    <ReactTooltip id={`key-enter-tooltip`}>
                                        {" "}
                                        {t("{{capitalize, capitalize}}", { capitalize: t("intro") })}
                                    </ReactTooltip>
                                </div>
                            </div>
                            <div className="w-2/12 text-center" style={{ paddingTop: "1vh" }}>
                                {" "}
                                <div className="icon icon-arrow-above mb-2" style={{ fontSize: "3.5rem" }}></div>
                                <div className="mb-6 flex mx-auto inline-flex ">
                                    <div className="icon icon-arrow-left mr-2 " style={{ fontSize: "3.5rem" }}></div>
                                    <div className="icon icon-arrow-below mr-2" style={{ fontSize: "3.5rem" }}></div>
                                    <div className="icon icon-arrow-right " style={{ fontSize: "3.5rem" }}></div>
                                </div>
                                <div>{t("navigate-the-screen")}</div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="block absolute bottom-0 m-5 right-0 clickable" onClick={(e) => setShowHelp(true)}>
                        <div className="p-2 bg-gray-900 w-auto table border border-white rounded text-gray-300">
                            <div className="icon icon-Keyboard text-4xl float-left"></div>
                            <div className="float-left ml-2 text-gray-300" style={{ lineHeight: "200%" }}>
                                {t("preview-help-btn")}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TVPreview;
