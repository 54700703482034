import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import UseSectionHeader from "components/useSectionHeader";
import UseSelectMultipleWithSearchOptionsQuery from "components/useSelectMultipleWithSearchOptionsQuery";
import UseInputNumberArrows from "components/Table/UseInputNumberArrows";
import UseRadio from "components/useRadio";
import UseButton from "components/useButton";
import UseSwitch from "components/Inputs/useSwitch";
import UseCheckBox from "components/Inputs/useCheckBox";
import { changeActionValues } from "actions/globalActions";
import { showGlobalLoading } from "actions/uiActions";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { useLazyQuery, useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import { createBrowserHistory } from "history";
import {
    QUERY_PRECKECKIN_INFORMATION_DATA,
    UPDATE_PRECHECKIN_INFORMATION_DATA,
} from "../Precheckin/ReservationDetails/queries";
import CustomFieldsItems from "./CustomFieldsItems";
import Select from "components/Select";

const UseTabs = ({ tabs, currentTab, setCurrentTab }) => {
    const { t } = useTranslation();

    return (
        <div className="flex mb-4">
            {tabs.map((tab) => (
                <div
                    id={`${tab.name}-tab`}
                    key={tab.name}
                    onClick={(e) => setCurrentTab(tab)}
                    className={`first-capital folder-link w-fit-content text-center font-bold py-2 ${
                        currentTab.name === tab.name ? "active" : ""
                    } clickable text-base ${tab.adjust}`}
                >
                    <span className=" px-2">{`${t(tab.name)} `}</span>
                </div>
            ))}
        </div>
    );
};

const UseSection = ({ value, onChange, name, forceBlocked = false }) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-row items-center w-full">
            <div>
                <UseCheckBox
                    id={`pre-check-in-information-${name}-checkbox`}
                    checked={value?.enabled}
                    enabled={forceBlocked ? false : true && !value?.blocked}
                    onClick={() => onChange({ enabled: !value.enabled })}
                    forceUpdateChecked
                    adjust="pb-6"
                />
            </div>
            <span className="flex justify-start flex-2">{capitalizeFirst(t(name))}</span>
            <div className="flex justify-end flex-1">
                <UseSwitch
                    id={`switch-required-${name}`}
                    adjust={"mt-2"}
                    checked={value?.required}
                    disabled={forceBlocked || !value?.enabled || value?.blocked}
                    action={() => onChange({ required: !value.required })}
                />
            </div>
        </div>
    );
};

export const PreCheckInInformation = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const actionData = useSelector((state) => state.action.values);

    const history = createBrowserHistory();

    const tabs = [
        { name: "information-required", id: 0 },
        { name: "children-information", id: 1 },
    ];
    const documentsTypes = [
        { label: t("national_id"), value: "national_id" },
        { label: t("passport"), value: "passport" },
        { label: t("driving-license"), value: "driving-license" },
        { label: t("residence-permit"), value: "residence-permit" },
    ];

    const [fieldSettings, setFieldSettings] = useState({
        name: { enabled: false, required: false, blocked: false },
        surname: { enabled: false, required: false, blocked: false },
        gender: { enabled: false, required: false, blocked: false },
        nationality: { enabled: false, required: false, blocked: false },
        birthdate: { enabled: false, required: false, blocked: false },
        email: { enabled: false, required: false, blocked: false },
        phone: { enabled: false, required: false, blocked: false },
        documentType: { enabled: false, required: false, blocked: false },
        documentNumber: { enabled: false, required: false, blocked: false },
        documentExpeditionDate: { enabled: false, required: false, blocked: false },
        nameChildren: { enabled: false, required: false, blocked: false },
        surnameChildren: { enabled: false, required: false, blocked: false },
        genderChildren: { enabled: false, required: false, blocked: false },
        nationalityChildren: { enabled: false, required: false, blocked: false },
        birthdateChildren: { enabled: false, required: false, blocked: false },
        emailChildren: { enabled: false, required: false, blocked: false },
        phoneChildren: { enabled: false, required: false, blocked: false },
        documentTypeChildren: { enabled: false, required: false, blocked: false },
        documentNumberChildren: { enabled: false, required: false, blocked: false },
        documentExpeditionDateChildren: { enabled: false, required: false, blocked: false },
    });
    const [precheckinCustomFields, setPrecheckinCustomFields] = useState([]);
    const [childrenPrecheckinCustomFields, setChildrenPrecheckinCustomFields] = useState([]);
    const [requestChildrenInformation, setRequestChildrenInformation] = useState(false);
    const [maximumAgeOfChildren, setMaximumAgeOfChildren] = useState(0);
    const [currentTab, setCurrentTab] = useState(tabs[0]);
    const [mutationCustomFields, setMutationCustomFields] = useState("");
    const [onClickSave, setOnClickSave] = useState(false);

    const handleFieldChange = (fieldName, updates) => {
        const isChildrenTab = currentTab.id === 1;
        const targetFieldName = isChildrenTab ? `${fieldName}Children` : fieldName;
        setFieldSettings((prev) => ({
            ...prev,
            [targetFieldName]: { ...prev[targetFieldName], ...updates },
        }));
    };

    const GET_SETTINGS_FIELDS = gql`
        ${QUERY_PRECKECKIN_INFORMATION_DATA()}
    `;

    const MUTATION_UPDATE_SETTING = gql`
        ${UPDATE_PRECHECKIN_INFORMATION_DATA(
            maximumAgeOfChildren,
            requestChildrenInformation,
            fieldSettings,
            actionData,
            mutationCustomFields
        )}
    `;

    const validateNotNullInfo = (values) => {
        if (values) {
            const { enabled, required, blocked } = values;
            return {
                enabled: enabled != null ? enabled : false,
                required: required != null ? required : false,
                blocked: blocked != null ? blocked : false,
            };
        } else {
            return {
                enabled: false,
                required: false,
                blocked: false,
            };
        }
    };
    const setQueryValues = () => {
        const preCheckInSettingsFields = data?.preCheckInSettingsFields;
        const preCheckInSettingsFieldsChildren = data?.preCheckInSettingsFieldsChildren;
        const preCheckInSettings = data?.preCheckInSettings;

        setFieldSettings({
            name: validateNotNullInfo(preCheckInSettingsFields?.name),
            surname: validateNotNullInfo(preCheckInSettingsFields?.surname),
            gender: validateNotNullInfo(preCheckInSettingsFields?.gender),
            nationality: validateNotNullInfo(preCheckInSettingsFields?.nationality),
            birthdate: validateNotNullInfo(preCheckInSettingsFields?.birthdate),
            email: validateNotNullInfo(preCheckInSettingsFields?.email),
            phone: validateNotNullInfo(preCheckInSettingsFields?.phone),
            documentType: {
                ...validateNotNullInfo(preCheckInSettingsFields?.documentType),
                values: preCheckInSettingsFields?.documentType?.values || [],
            },
            documentNumber: validateNotNullInfo(preCheckInSettingsFields?.documentNumber),
            documentExpeditionDate: validateNotNullInfo(preCheckInSettingsFields?.documentExpeditionDate),

            nameChildren: validateNotNullInfo(preCheckInSettingsFieldsChildren?.name),
            surnameChildren: validateNotNullInfo(preCheckInSettingsFieldsChildren?.surname),
            genderChildren: validateNotNullInfo(preCheckInSettingsFieldsChildren?.gender),
            nationalityChildren: validateNotNullInfo(preCheckInSettingsFieldsChildren?.nationality),
            birthdateChildren: validateNotNullInfo(preCheckInSettingsFieldsChildren?.birthdate),
            emailChildren: validateNotNullInfo(preCheckInSettingsFieldsChildren?.email),
            phoneChildren: validateNotNullInfo(preCheckInSettingsFieldsChildren?.phone),
            documentTypeChildren: {
                ...validateNotNullInfo(preCheckInSettingsFieldsChildren?.documentType),
                values: preCheckInSettingsFieldsChildren?.documentType?.values || [],
            },
            documentNumberChildren: validateNotNullInfo(preCheckInSettingsFieldsChildren?.documentNumber),
            documentExpeditionDateChildren: validateNotNullInfo(
                preCheckInSettingsFieldsChildren?.documentExpeditionDate
            ),
        });

        dispatch(
            changeActionValues({
                ["document-types-select"]: preCheckInSettingsFields?.documentType?.values || [],
                ["document-types-select-children"]: preCheckInSettingsFieldsChildren?.documentType?.values || [],
            })
        );

        if (preCheckInSettings) {
            setRequestChildrenInformation(preCheckInSettings?.requestDataFromChildren);
            setMaximumAgeOfChildren(preCheckInSettings?.maximumAgeOfChildren);
        }
    };
    const [executeFilterQuery, { data, called }] = useLazyQuery(GET_SETTINGS_FIELDS, {
        onCompleted: () => {
            dispatch(showGlobalLoading(false));
        },
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
    });
    const [updatePreCheckInSettings] = useMutation(MUTATION_UPDATE_SETTING, {
        onCompleted() {
            executeFilterQuery();
            toast.success(t("operation-successful"));
            history.push("/#/customers/guests/general-settings");
            history.go(0);
        },
        onError(err) {
            toast.error(err.message);
        },
    });

    useEffect(() => {
        dispatch(
            changeActionValues({
                ["document-types-select"]: fieldSettings?.documentType?.values || [],
                ["document-types-select-children"]: fieldSettings?.documentTypeChildren?.values || [],
            })
        );
    }, [currentTab]);

    useEffect(() => {
        dispatch(showGlobalLoading(true));
        executeFilterQuery();
    }, []);

    useEffect(() => {
        if (data?.preCheckInSettingsFields || data?.preCheckInSettingsFieldsChildren) {
            setQueryValues();
        }

        if (data?.preCheckInSettingsCustomFields?.fields && precheckinCustomFields.length === 0) {
            setPrecheckinCustomFields(data?.preCheckInSettingsCustomFields?.fields);
        }

        if (data?.preCheckInSettingsCustomFieldsChildren?.fields && childrenPrecheckinCustomFields.length === 0) {
            setChildrenPrecheckinCustomFields(data?.preCheckInSettingsCustomFieldsChildren?.fields);
        }
    }, [data]);

    return (
        <>
            <UseSectionHeader title={"pre-check-in-information"} buttonColor="btn-blue" navToPreviousPage={true} />

            <div className=" bg-white rounded-sm w-full pb-20 p-8">
                <UseTabs
                    tabs={tabs}
                    currentTab={currentTab}
                    setCurrentTab={(e) => {
                        // setFieldSettings((prev) => ({
                        //     ...prev,
                        //     ["documentType"]: { ...prev["documentType"], values: actionData["document-types-select"] },
                        // }));
                        // setFieldSettings((prev) => ({
                        //     ...prev,
                        //     ["documentTypeChildren"]: {
                        //         ...prev["documentTypeChildren"],
                        //         values: actionData["document-types-select-children"],
                        //     },
                        // }));
                        setCurrentTab(e);
                    }}
                ></UseTabs>
                {currentTab.id === 0 ? (
                    <h1>{t("indicate-guest-data-pre-check-in")}</h1>
                ) : (
                    <div className=" border-b-2 pb-4">
                        <h1 className=" mb-4">{t("indicate-the-children-data-pre-check-in")}</h1>
                        <div className="flex flex-row gap-8">
                            <div className="flex flex-col gap-2">
                                <span>{t("request-children-information")}</span>
                                <div className="flex flex-col gap-2">
                                    <UseRadio
                                        id={"request-children-info-yes"}
                                        handleClick={() => {
                                            setRequestChildrenInformation(true);
                                        }}
                                        text={"yes"}
                                        value={true}
                                        selected={requestChildrenInformation}
                                    />
                                    <UseRadio
                                        id={"request-children-info-no"}
                                        handleClick={() => {
                                            setRequestChildrenInformation(false);
                                        }}
                                        text={"no"}
                                        value={false}
                                        selected={requestChildrenInformation}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <span>{t("maximum-age-of-children")}</span>
                                <div className="flex flex-row gap-2 items-center">
                                    <UseInputNumberArrows
                                        inputData={{
                                            name: "maximum-age-of-children-settings",
                                            id: "maximum-age-of-children-settings",
                                        }}
                                        validation={!(maximumAgeOfChildren === "") && maximumAgeOfChildren >= 0}
                                        value={maximumAgeOfChildren}
                                        onChange={(val) => {
                                            setMaximumAgeOfChildren(() => {
                                                if (typeof val === "string" && val != "") {
                                                    return Number(val);
                                                } else {
                                                    return val;
                                                }
                                            });
                                        }}
                                        id={`maximum-age-of-children`}
                                        disabled={false}
                                    />
                                    <span>{capitalizeFirst(t("years"))}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className=" flex flex-row mt-6">
                    <div className="border-r-2 px-8 w-1/3">
                        <span className=" font-bold"> {t("personal-information")}</span>
                        <div>
                            <div className="flex justify-end w-full">{capitalizeFirst(t("mandatory"))}</div>
                            <UseSection
                                value={currentTab.id === 1 ? fieldSettings.nameChildren : fieldSettings.name}
                                onChange={(updates) => handleFieldChange("name", updates)}
                                name="name"
                            />
                            <UseSection
                                value={currentTab.id === 1 ? fieldSettings.surnameChildren : fieldSettings.surname}
                                onChange={(updates) => handleFieldChange("surname", updates)}
                                name="surname"
                            />
                            <UseSection
                                value={currentTab.id === 1 ? fieldSettings.genderChildren : fieldSettings.gender}
                                onChange={(updates) => handleFieldChange("gender", updates)}
                                name="gender"
                            />
                            <UseSection
                                value={
                                    currentTab.id === 1 ? fieldSettings.nationalityChildren : fieldSettings.nationality
                                }
                                onChange={(updates) => handleFieldChange("nationality", updates)}
                                name="nationality"
                            />
                            <UseSection
                                value={currentTab.id === 1 ? fieldSettings.birthdateChildren : fieldSettings.birthdate}
                                onChange={(updates) => handleFieldChange("birthdate", updates)}
                                name="date-of-birth"
                            />
                            <UseSection
                                value={currentTab.id === 1 ? fieldSettings.emailChildren : fieldSettings.email}
                                onChange={(updates) => handleFieldChange("email", updates)}
                                name="email-address"
                            />
                            <UseSection
                                value={currentTab.id === 1 ? fieldSettings.phoneChildren : fieldSettings.phone}
                                onChange={(updates) => handleFieldChange("phone", updates)}
                                name="phone-number"
                            />
                        </div>
                    </div>
                    <div className="  border-r-2 px-8  w-1/3">
                        <span className=" font-bold">{t("identification-document")}</span>
                        <div>
                            <div className="flex justify-end w-full">{capitalizeFirst(t("mandatory"))}</div>
                            <UseSection
                                value={
                                    currentTab.id === 1
                                        ? fieldSettings.documentTypeChildren
                                        : fieldSettings.documentType
                                }
                                onChange={(updates) => handleFieldChange("documentType", updates)}
                                name="document"
                            />
                            <div>
                                {currentTab.id == 1 ? (
                                    <>
                                        {(() => {
                                            const { documentTypeChildren } = fieldSettings;
                                            const { values, blocked, enabled } = documentTypeChildren;
                                            const isEmpty = (!values || values.length === 0) && enabled;
                                            const validationClass = isEmpty ? "border border-red-100" : "";

                                            return (
                                                <>
                                                    <Select
                                                        id="document-types-select-children"
                                                        value={values}
                                                        allowUnselect={true}
                                                        options={documentsTypes}
                                                        multiple={true}
                                                        onChange={(values) => {
                                                            handleFieldChange("documentType", {
                                                                ...fieldSettings.documentTypeChildren,
                                                                values,
                                                            });
                                                        }}
                                                        designClass={{ validation: validationClass }}
                                                        disabled={blocked || !enabled}
                                                    />
                                                    {isEmpty && (
                                                        <p className=" text-red-100">
                                                            {t("select-at-least-a-document")}
                                                        </p>
                                                    )}
                                                </>
                                            );
                                        })()}
                                    </>
                                ) : (
                                    <>
                                        {(() => {
                                            const { documentType } = fieldSettings;
                                            const { values, blocked, enabled } = documentType;
                                            const isEmpty = (!values || values.length === 0) && enabled;
                                            const validationClass = isEmpty ? "border border-red-100" : "";

                                            return (
                                                <>
                                                    <Select
                                                        id="document-types-select"
                                                        value={values}
                                                        allowUnselect={true}
                                                        options={documentsTypes}
                                                        multiple={true}
                                                        disabled={blocked || !enabled}
                                                        designClass={{ validation: validationClass }}
                                                        onChange={(newValues) => {
                                                            handleFieldChange("documentType", {
                                                                ...documentType,
                                                                values: newValues,
                                                            });
                                                        }}
                                                    />
                                                    {isEmpty && (
                                                        <p className=" text-red-100">
                                                            {t("select-at-least-a-document")}
                                                        </p>
                                                    )}
                                                </>
                                            );
                                        })()}
                                    </>
                                )}
                            </div>
                            <UseSection
                                value={
                                    currentTab.id === 1
                                        ? fieldSettings.documentNumberChildren
                                        : fieldSettings.documentNumber
                                }
                                onChange={(updates) => handleFieldChange("documentNumber", updates)}
                                name="document-number"
                                forceBlocked={
                                    !fieldSettings[currentTab.id === 1 ? "documentTypeChildren" : "documentType"]
                                        .enabled
                                }
                            />
                            <UseSection
                                value={
                                    currentTab.id === 1
                                        ? fieldSettings.documentExpeditionDateChildren
                                        : fieldSettings.documentExpeditionDate
                                }
                                onChange={(updates) => handleFieldChange("documentExpeditionDate", updates)}
                                name="document-expedition-date"
                                forceBlocked={
                                    !fieldSettings[currentTab.id === 1 ? "documentTypeChildren" : "documentType"]
                                        .enabled
                                }
                            />
                        </div>
                    </div>
                    <div className=" pl-8 px-8 w-1/3">
                        <span className=" font-bold">{t("additional-information")}</span>
                        <CustomFieldsItems
                            precheckinCustomFields={precheckinCustomFields}
                            childrenPrecheckinCustomFields={childrenPrecheckinCustomFields}
                            setMutationCustomFields={setMutationCustomFields}
                            currentTab={currentTab}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-row gap-4 w-full justify-end mt-5">
                <UseButton
                    action={() => {
                        // setQueryValues();
                        history.push("/#/customers/guests/general-settings");
                        history.go(0);
                    }}
                    id={`cancel-button`}
                    buttonName={t("cancel")}
                    buttonColor={"btn-white"}
                />
                <UseButton
                    action={() => {
                        let hasError = false;

                        const validateDocumentType = (documentTypeField, tabCondition, errorKey) => {
                            if (
                                (!documentTypeField?.values || documentTypeField.values.length === 0) &&
                                documentTypeField?.enabled
                            ) {
                                if (tabCondition) {
                                    toast.error(t(errorKey));
                                } else {
                                    toast.error(t("select-at-least-a-document"));
                                }
                                return true;
                            }
                            return false;
                        };

                        hasError =
                            validateDocumentType(
                                fieldSettings?.documentType,
                                currentTab.id === 1,
                                t("select-at-least-a-document-in-information-required")
                            ) || hasError;

                        hasError =
                            validateDocumentType(
                                fieldSettings?.documentTypeChildren,
                                currentTab.id !== 1,
                                t("select-at-least-a-document-in-children-information-required")
                            ) || hasError;

                        if (currentTab.id === 1 && (maximumAgeOfChildren === "" || maximumAgeOfChildren < 0)) {
                            toast.error(t("errors-in-red"));
                            hasError = true;
                        }

                        if (hasError) {
                            return;
                        }

                        if (!onClickSave) {
                            updatePreCheckInSettings();
                            setOnClickSave(true);
                        }
                    }}
                    id="save-button"
                    buttonName={t("save")}
                    buttonColor="btn-blue"
                />
            </div>
        </>
    );
};
