import React from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import { useTranslation } from "react-i18next";
import UseButton from "../../useButton";
import UseComponentLoading from "../../useComponentLoading";
import { setSectionContent } from "../../../actions/sectionActions";
import GenerateInstallationCode from "../../../hooks/GraphqlCalls/Hotel/Settings/GenerateInstallationCode";

const ShowInstallationCode = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { installationCode, loadingCode, expiration_date, expiration_hour } = useSelector(
        (state) => state.sectionContent
    );

    GenerateInstallationCode();

    return loadingCode ? (
        <UseComponentLoading />
    ) : (
        <>
            {installationCode && (
                <>
                    <div id="installationCode_code" className="font-black text-gray-800 text-5xl mt-4">
                        {installationCode}
                    </div>
                    <div className="font-normal leading-6 text-orange-100 text-base mt-3">
                        {t("code-expires", { date: expiration_date, hour: expiration_hour })}
                    </div>
                    <div className="font-normal leading-6 text-gray-600 text-base mt-2">
                        {t("code-expires-extended")}
                    </div>
                </>
            )}

            <div id="installationCode_button" className={`mx-auto w-4/12 ${installationCode ? "mt-6" : "mt-12"}`}>
                <UseButton
                    buttonName={installationCode ? "regenerate-code" : "generate-code"}
                    buttonColor="btn-blue"
                    customClass="w-full"
                    action={() => {
                        dispatch(setSectionContent({ loadingCode: true, generateCode: true, installationCode: null }));
                    }}
                />
            </div>
        </>
    );
};
export default ShowInstallationCode;
