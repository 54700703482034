import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLazyQuery, useMutation } from "react-apollo";
import UseSectionHeader from "../../../useSectionHeader";
import UseInputNumberArrows from "components/Table/UseInputNumberArrows";
import { Link } from "react-router-dom";
import SettingsCard from "components/SettingsCard";
import UseSwitch from "components/Inputs/useSwitch";
import { toast } from "react-toastify";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { gql } from "apollo-boost";
import { useSelector } from "react-redux";
import UseLabel from "components/Inputs/useLabel";
import UseSelect from "components/Inputs/useSelect";
import { useGetDailyResetDate } from "hooks/GraphqlCalls/Customers/useGetDailyResetDate";

export const GuestConfig = () => {
    const { t } = useTranslation();
    const [emailDaysBefore, setEmailDaysBefore] = useState(0);
    const [reminderDaysBefore, setReminderDaysBefore] = useState(0);
    const [sendEmail, setSendEmail] = useState(false);
    const [sendReminderEmail, setSendReminderEmail] = useState(false);
    const [sendEmailAssignRoom, setSendEmailAssignRoom] = useState(false);
    const [automaticSendingOfTheTravelersReport, setAutomaticSendingOfTheTravelersReport] = useState(false);
    const [hasFieldsSelected, setHasFieldsSelected] = useState(false);
    const [canChange, setCanChange] = useState(false);
    const [data, setData] = useState(null);
    const { permissions } = useSelector((state) => state.ui);
    const { mutate, credentialsData, called, loading } = useGetDailyResetDate();
    const [isEnabled, setIsEnabled] = useState(credentialsData?.enabled);
    const { actionData } = useSelector((state) => state.action);

    const hasMobileProductPermissions = permissions?.product?.mobile;

    const GET_GENERAL_SETTINGS = gql`
        query {
            preCheckInSettingsEmail {
                sendEmail
                emailDaysBefore
                sendReminderEmail
                reminderDaysBefore
            }
            preCheckInSettings {
                automaticSendingOfTheTravelersReport
                sendEmailAssignRoom
            }
            preCheckInSettingsTravelersReport {
                guestData {
                    fields {
                        name
                        surname
                        gender
                        birthdate
                        email
                        nationality
                        phone
                        documentExpeditionDate
                        documentNumber
                        documentType
                    }
                    customFields
                }
                guestDataChildren {
                    fields {
                        name
                        surname
                        gender
                        birthdate
                        email
                        nationality
                        phone
                        documentExpeditionDate
                        documentNumber
                        documentType
                    }
                    customFields
                }
            }
        }
    `;

    const MUTATION_UPDATE_PRE_CHECKIN_SETTINGS = gql`
    mutation{
        updatePreCheckInSettingsEmail(
            sendEmail: ${sendEmail}
            emailDaysBefore: ${
                !(emailDaysBefore === "") && emailDaysBefore >= 0 ? emailDaysBefore : data?.emailDaysBefore
            }
            sendReminderEmail: ${sendReminderEmail}
            reminderDaysBefore: ${
                !(reminderDaysBefore === "") && reminderDaysBefore >= 0 ? reminderDaysBefore : data?.reminderDaysBefore
            }
        ){
            ok
            error
        }
        updatePreCheckInSettings(
            sendEmailAssignRoom: ${sendEmailAssignRoom}
        ){
            ok
            error
        }
    }
    `;

    const [executeFilterQuery, generalSettingsData] = useLazyQuery(GET_GENERAL_SETTINGS, {
        fetchPolicy: "network-only",
    });

    const [updatePreCheckIn] = useMutation(MUTATION_UPDATE_PRE_CHECKIN_SETTINGS, {
        onError(err) {
            toast.error(err.message);
        },
    });

    useEffect(() => {
        executeFilterQuery();
    }, []);

    useEffect(() => {
        if (generalSettingsData?.data?.preCheckInSettingsEmail) {
            setEmailDaysBefore(generalSettingsData?.data?.preCheckInSettingsEmail?.emailDaysBefore);
            setReminderDaysBefore(generalSettingsData?.data?.preCheckInSettingsEmail?.reminderDaysBefore);
            setSendEmail(generalSettingsData?.data?.preCheckInSettingsEmail?.sendEmail);
            setSendReminderEmail(generalSettingsData?.data?.preCheckInSettingsEmail?.sendReminderEmail);
            setData(generalSettingsData?.data?.preCheckInSettingsEmail);
        }
        if (generalSettingsData?.data?.preCheckInSettings) {
            setSendEmailAssignRoom(generalSettingsData?.data?.preCheckInSettings?.sendEmailAssignRoom);
            setAutomaticSendingOfTheTravelersReport(
                generalSettingsData?.data?.preCheckInSettings?.automaticSendingOfTheTravelersReport
            );
            setCanChange(true);
        }

        if (
            Object.values(generalSettingsData?.data?.preCheckInSettingsTravelersReport?.guestData?.fields ?? {}).some(
                (item) => {
                    if (typeof item == "boolean") {
                        return item;
                    } else {
                        return false;
                    }
                }
            ) ||
            generalSettingsData?.data?.preCheckInSettingsTravelersReport?.guestData?.customFields?.length > 0 ||
            Object.values(
                generalSettingsData?.data?.preCheckInSettingsTravelersReport?.guestDataChildren?.fields ?? {}
            ).some((item) => {
                if (typeof item == "boolean") {
                    return item;
                } else {
                    return false;
                }
            }) ||
            generalSettingsData?.data?.preCheckInSettingsTravelersReport?.guestDataChildren?.customFields?.length > 0
        ) {
            setHasFieldsSelected(true);
        } else {
            setHasFieldsSelected(false);
        }
    }, [generalSettingsData]);

    useEffect(() => {
        if (canChange) {
            updatePreCheckIn();
        }
    }, [sendEmail, sendReminderEmail, sendEmailAssignRoom]);

    useEffect(() => {
        if (emailDaysBefore > 0 && reminderDaysBefore > 0) {
            if (reminderDaysBefore >= emailDaysBefore) {
                setReminderDaysBefore(emailDaysBefore - 1);
            } else {
                updatePreCheckIn();
            }
        }
    }, [emailDaysBefore, reminderDaysBefore]);

    useEffect(() => {
        if (isEnabled !== undefined) {
            mutate(actionData["daily-reset-toggle-button"], `${actionData["daily-reset"]}:00`);
        } else if (actionData && actionData["daily-reset"] !== credentialsData?.activeValue) {
            mutate(actionData["daily-reset-toggle-button"], `${actionData["daily-reset"]}:00`);
        }
    }, [actionData]);

    return (
        <>
            <UseSectionHeader title={"general-settings"} />
            <div>
                <div className="flex w-full flex-row gap-8 mb-6">
                    <div className="flex-1">
                        <SettingsCard title={capitalizeFirst(t("emails"))}>
                            {hasMobileProductPermissions && (
                                <SettingsCard.Section
                                    title={t("allow-pre-check-in-email")}
                                    description={t("guests-will-receive-an-email")}
                                    toggle={{
                                        id: `allow-pre-check-in-email`,
                                        checked: sendEmail,
                                        disabled: false,
                                        action: () => {
                                            setSendEmail((prev) => !prev);
                                        },
                                    }}
                                >
                                    <div className="px-4 flex flex-col gap-4">
                                        <p>{t("select-how-many-days-before-email")}</p>
                                        <div className="flex flex-row gap-2 items-center mt-2">
                                            <UseInputNumberArrows
                                                inputData={{
                                                    name: "email-days-before-settings",
                                                    id: "email-days-before-settings",
                                                }}
                                                validation={!(emailDaysBefore === "") && emailDaysBefore >= 0}
                                                value={emailDaysBefore}
                                                onChange={(val) => {
                                                    const newVal = Number(val);
                                                    if (newVal > reminderDaysBefore) {
                                                        setEmailDaysBefore(newVal);
                                                        if (!(val === "") && newVal) {
                                                            setData((prev) => {
                                                                return { ...prev, emailDaysBefore: newVal };
                                                            });
                                                        }
                                                    }
                                                }}
                                                id={`email-days-before`}
                                                disabled={!sendEmail}
                                            />
                                            <span>{capitalizeFirst(t("days"))}</span>
                                        </div>
                                        <div className=" flex items-center mt-3 mb-2">
                                            <span
                                                className={` text-base font-bold ${
                                                    (!sendReminderEmail || !sendEmail) && " text-gray-500"
                                                }`}
                                            >
                                                {capitalizeFirst(t("allow-reminder-to-sent-complete-pre-check-in"))}
                                            </span>
                                            <UseSwitch
                                                id={`switch-allow-reminder-to-sent-complete-pre-check-in`}
                                                adjust={"mt-2"}
                                                checked={sendReminderEmail}
                                                disabled={!sendEmail}
                                                action={() => {
                                                    setSendReminderEmail((prev) => !prev);
                                                }}
                                            />
                                        </div>
                                        <p className={`${(!sendReminderEmail || !sendEmail) && " text-gray-500"}`}>
                                            {t("select-how-many-days-before-email")}
                                        </p>

                                        <div className="flex flex-row gap-2 items-center mt-2 mb-4">
                                            <UseInputNumberArrows
                                                inputData={{
                                                    name: "reminder-days-before-settings",
                                                    id: "reminder-days-before-settings",
                                                }}
                                                validation={!(reminderDaysBefore === "") && reminderDaysBefore >= 0}
                                                value={reminderDaysBefore}
                                                onChange={(val) => {
                                                    const newVal = Number(val);
                                                    if (newVal < emailDaysBefore) {
                                                        setReminderDaysBefore(newVal);
                                                        if (!(val === "") && newVal >= 0) {
                                                            setData((prev) => {
                                                                return { ...prev, reminderDaysBefore: newVal };
                                                            });
                                                        }
                                                    }
                                                }}
                                                id={`reminder-days-before`}
                                                disabled={!sendReminderEmail || !sendEmail}
                                            />
                                            <span
                                                className={`${(!sendReminderEmail || !sendEmail) && " text-gray-500"}`}
                                            >
                                                {capitalizeFirst(t("days"))}
                                            </span>
                                        </div>
                                    </div>
                                </SettingsCard.Section>
                            )}
                            <SettingsCard.Section
                                title={t("check-in-email")}
                                description={t("guests-will-receive-an-email-when-a-room-is-assigned")}
                                toggle={{
                                    id: `check-in-email`,
                                    checked: sendEmailAssignRoom,
                                    disabled: false,
                                    action: () => {
                                        setSendEmailAssignRoom((prev) => !prev);
                                    },
                                }}
                            ></SettingsCard.Section>
                        </SettingsCard>
                    </div>
                    {hasMobileProductPermissions ? (
                        <div className="flex flex-col flex-1 gap-6">
                            <div className=" bg-white rounded-sm p-6 flex-1">
                                <div className="flex justify-between">
                                    <span className="font-bold text-3xl">{t("pre-check-in-information")}</span>
                                    <Link
                                        id="pre-check-in-informations-link"
                                        to={`/customers/guests/general-settings/pre-check-in-information`}
                                    >
                                        <button
                                            id="pre-check-in-informations-button"
                                            className="text-gray-800 text-3xl focus:outline-none icon icon-edit"
                                        ></button>
                                    </Link>
                                </div>
                                <p className=" mt-3">{t("pre-check-in-informations-description")}</p>
                            </div>
                            <div className=" bg-white rounded-sm p-6 flex-1">
                                <div className="flex justify-between">
                                    <span className="font-bold text-3xl">{t("guest-report")}</span>
                                    <Link
                                        id="automation-of-guest-report-link"
                                        to={"/customers/guests/general-settings/guest-report"}
                                    >
                                        <button
                                            id="automation-of-guest-report-button"
                                            className="text-gray-800 text-3xl focus:outline-none icon icon-edit"
                                        ></button>
                                    </Link>
                                </div>
                                <p className=" mt-3">{t("manage-sending-of-the-guest-report")}</p>
                                {hasFieldsSelected ? (
                                    <p className=" mt-4 text-green-500">{t("fields-selected")}</p>
                                ) : (
                                    <p className=" mt-4 text-gray-500">{t("no-fields-selected-yet")}</p>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="flex-1"></div>
                    )}
                </div>
                {called && !loading && credentialsData ? (
                    <div className="bg-white rounded-sm p-6 w-1/2">
                        <div className="flex justify-between items-center">
                            <span className="font-bold text-gray-900 text-3xl">
                                {t("daily-apps-credentials-reset")}
                            </span>
                            <UseSwitch
                                id={`daily-reset-toggle-button`}
                                checked={credentialsData?.enabled}
                                action={setIsEnabled}
                            />
                        </div>
                        <div className="flex flex-col gap-2 my-4 text-gray-900">
                            <p>{t("reset-applications-credentials")}</p>
                            <p>{t("log-in-daily")}</p>
                        </div>
                        <div className="flex flex-col gap-2 mt-8 sm:w-1/3 w-2/5">
                            <UseLabel
                                label={capitalizeFirst(t("reset-hour"))}
                                adjust={"text-gray-800 font-bold text-sm"}
                            />
                            {credentialsData ? (
                                <UseSelect
                                    enabled={isEnabled === undefined ? credentialsData?.enabled : isEnabled}
                                    selectData={credentialsData}
                                />
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
};
