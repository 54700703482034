const AppearanceReplaceLogo = (title, subTitle, upload, cancelAction) => {
    return {
        title: title,
        bodyAdjust: "w-5/12",
        executeSectionQuery: true,
        inputs: [
            {
                text: subTitle,
                name: "name",
                type: "void",
                cStyle: "w-full",
            },
            {
                name: "logo-asset",
                value: "",
                type: "logo",
                accept: "image/*",
                replace: true,
                upload: upload,
            },
        ],
        buttons: [
            { name: "cancel", style: "white", actionCallback: () => cancelAction(), action: "close" },
            {
                name: upload ? "upload-file" : "replace",
                style: "blue",
                action: upload ? "appearance-upload-logo" : "appearance-replace-logo",
            },
        ],
    };
};

export default AppearanceReplaceLogo;
