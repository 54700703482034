import React from "react";
import {
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    setRefreshData,
    cleanCheckedItems,
    setCountPosition,
    setCount,
} from "actions/tableActions";
import { LIST_MOBILE_APPS } from "../constants";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { GetAllPropertiesTableHeaders } from "hooks/Utils/Table/Headers/GetAllPropertiesTableHeaders";
import { setAvailableFilters, setCheckable, setBatchActive } from "actions/tableActions";
import GetAvailableFilters from "hooks/Utils/GetAvailableFilters";
import BatchActionsAllProperties from "../batchActions/BatchActionsAllProperties";
import MobileApp from "../modelsTable/MobileApp";
import { useLocationMapModal } from "components/LocationMap";
import { openModal } from "actions/uiActions";
import { useAssociatedPropertiesModal } from "../modals/useAssociatedPropertiesModal";
import useDeleteModalApp from "../modals/useDeleteModalApp";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { POLLINTERVAL_15S } from "hooks/Utils/Utils";
import { useSelector } from "react-redux";
import { Session } from "hooks/Utils/Session";

const ListMobileApps = () => {
    const dispatch = useDispatch();

    // const { open } = useLocationMapModal();

    const { currentPage, perPage, sortCriteria, refreshData, activeFilters } = useSelector((state) => state.table);
    const search = activeFilters?.search ? activeFilters.search : "";
    const isMultipropertyFilter = () => {
        if (activeFilters?.isMultiproperty === "property-apps") {
            return "isMultiProperty: false";
        } else if (activeFilters?.isMultiproperty === "global-apps") {
            return "isMultiProperty: true";
        } else {
            return "";
        }
    };

    const { open: openAssociatedPropertiesModal } = useAssociatedPropertiesModal();
    const { open: openDeleteModalApp } = useDeleteModalApp();
    // const {open: open}

    const GET_MOBILE_APPS = gql(`query{
            mobileApps
            (
            page: ${currentPage} , size: ${perPage},
            filter: { 
                search: "${search ? search : ""}"
                ${isMultipropertyFilter()}
            } , 
            ${sortCriteria ? sortCriteria : ""} 
            )
            {
                info{
                count
                page
                size
                }
                results{
                id
                isMultiProperty
                name
                shortName
                url
                projects{
                    id
                    name
                    information{
                        appAvailable
                    }
                    ref
                }
                }
            }
            }`);

    const [executeQuery, { data: dataMobileApps, loading, error, refetch }] = useLazyQuery(GET_MOBILE_APPS, {
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeQuery();
        dispatch(setAvailableFilters(GetAvailableFilters(LIST_MOBILE_APPS)));
        dispatch(setSearchable(true));
        dispatch(setCheckable(true));
        dispatch(setCountPosition("table-header"));
        dispatch(setHeaders(GetAllPropertiesTableHeaders(LIST_MOBILE_APPS)));
        dispatch(setBatchActive(BatchActionsAllProperties(LIST_MOBILE_APPS)));
    }, []);

    useEffect(() => {
        if (refreshData && refetch) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
            dispatch(cleanCheckedItems());
        }
        // eslint-disable-next-line
    }, [refreshData]);

    const arrangeData = (data) => {
        const finalData = [];

        data.forEach((item) => {
            finalData.push(
                MobileApp({
                    data: item,
                    modals: {
                        openAssociatedPropertiesModal,
                        openDeleteModalApp,
                    },
                })
            );
        });

        return finalData;
    };

    useEffect(() => {
        if (!loading && dataMobileApps?.mobileApps?.results) {
            arrangeData(dataMobileApps?.mobileApps?.results);
            const tableResults = arrangeData(dataMobileApps?.mobileApps?.results);
            Session.setSessionProp("mobileApps", JSON.stringify(dataMobileApps?.mobileApps?.results));
            dispatch(setTableResults(tableResults));
            dispatch(setLoading(false));
            dispatch(setCount(dataMobileApps?.mobileApps?.info.count));
        }
        // eslint-disable-next-line
    }, [dataMobileApps]);

    return null;
};

export default ListMobileApps;
