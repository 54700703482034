import {
    SET_SECTION_CONTENT,
    CLEAN_SECTION_CONTENT,
    SET_REFRESH_DATA,
    SET_EXECUTE_QUERY,
    SET_WIZARD_STEP,
    SET_EVENT_TYPE,
    SCHEDULE_PUNCTUAL_FORM,
    SCHEDULE_PERIODIC_FORM,
    SAVE_PUNCTUAL_SCHEDULE,
    SHOW_ROW_FORM,
    REFRESH_PERIODIC_SCHEDULE,
    SCHEDULE_PUNCTUAL_EDIT,
    EDIT_COMMON,
    EDIT_SPECIAL,
    EDIT_FESTIVE,
    SCHEDULE_FILTER_PERIODIC,
    SHOW_HIDDEN_ROWS,
    CHANGE_PUNCTUAL_STATUS,
    CHANGE_PERIODIC_STATUS,
    DELETE_PUNCTUAL_VIEW,
    DELETE_PERIODIC_VIEW,
    RESTORE_PERIODIC_SCHEDULE_BACKUP,
    SET_SECTION_NAME,
    SET_ALLOW_WIZARDNEXT,
    SET_PARAMETERS_SECTION,
    CLEAN_PARAMETERS_SECTION,
    SET_REFRESH_CONTENT_SHOP_DATA,
    SET_SHOP_DATA_HEAD_MENU,
    SET_SHOP_TOKEN,
} from "../actions/sectionActions";

const initialState = false;

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SECTION_CONTENT:
            const periodicFilter = state.periodicFilter ? state.periodicFilter : "common-schedule";
            let newSectionContent = action.payload;
            newSectionContent.periodicFilter = periodicFilter;
            return {
                ...state,
                ...action.payload,
            };
        case CLEAN_SECTION_CONTENT:
            if (state.periodicFilter) return { periodicFilter: state.periodicFilter };
            else return initialState;
        case SET_REFRESH_DATA:
            return {
                ...state,
                refreshData: action.payload,
            };
        case SET_EXECUTE_QUERY:
            return {
                ...state,
                executeQuery: action.payload,
            };
        case SET_WIZARD_STEP:
            return {
                ...state,
                currentStep: action.payload,
            };
        case SET_EVENT_TYPE:
            return {
                ...state,
                eventTypeActive: action.payload,
            };
        case SCHEDULE_PUNCTUAL_FORM:
            return {
                ...state,
                schedulePunctualForm: action.payload,
            };
        case SCHEDULE_PERIODIC_FORM:
            return {
                ...state,
                schedulePeriodicForm: action.payload,
            };
        case SAVE_PUNCTUAL_SCHEDULE:
            return {
                ...state,
                punctualEventsData: {
                    headers: state.punctualEventsData.headers,
                    currentData: [action.payload, ...state.punctualEventsData.currentData],
                },
            };
        case SHOW_ROW_FORM:
            return {
                ...state,
                rowForm: action.payload,
            };
        case REFRESH_PERIODIC_SCHEDULE:
            return {
                ...state,
                periodicEventsData: action.payload,
            };
        case SCHEDULE_PUNCTUAL_EDIT:
            return {
                ...state,
                editPunctualForm: action.payload,
            };
        case EDIT_COMMON:
            return {
                ...state,
                editCommonForm: action.payload,
            };
        case EDIT_SPECIAL:
            return {
                ...state,
                editSpecialForm: action.payload,
            };
        case EDIT_FESTIVE:
            return {
                ...state,
                editFestiveForm: action.payload,
            };
        case SCHEDULE_FILTER_PERIODIC:
            return {
                ...state,
                periodicFilter: action.payload,
            };
        case SHOW_HIDDEN_ROWS:
            return {
                ...state,
                showHiddenRows: action.payload,
            };
        case CHANGE_PUNCTUAL_STATUS:
            let currentPunctualEvents = state.punctualEventsData;
            // eslint-disable-next-line
            currentPunctualEvents.currentData.map((punctualEvent, index) => {
                if (punctualEvent.ID === action.payload.id) {
                    currentPunctualEvents.currentData[index].isEnabled = action.payload.newStatus;
                }
            });
            return {
                ...state,
                punctualEventsData: currentPunctualEvents,
            };
        case CHANGE_PERIODIC_STATUS:
            let currentPeriodicEvents = state.periodicEventsData;
            // eslint-disable-next-line
            currentPeriodicEvents.currentData.map((periodicEvent, index) => {
                if (periodicEvent.ID === action.payload.id) {
                    currentPeriodicEvents.currentData[index].isEnabled = action.payload.newStatus;
                }
            });
            return {
                ...state,
                periodicEventsData: currentPeriodicEvents,
            };
        case DELETE_PUNCTUAL_VIEW:
            return {
                ...state,
                punctualEventsData: {
                    headers: state.punctualEventsData.headers,
                    currentData: state.punctualEventsData.currentData.filter(
                        (punctualSchedule) => punctualSchedule.ID !== action.payload
                    ),
                },
            };
        case DELETE_PERIODIC_VIEW:
            return {
                ...state,
                periodicEventsData: {
                    headers: state.periodicEventsData.headers,
                    currentData: state.periodicEventsData.currentData.filter(
                        (periodicSchedule) => periodicSchedule.ID !== action.payload
                    ),
                },
            };
        case RESTORE_PERIODIC_SCHEDULE_BACKUP:
            return {
                ...state,
                periodicEventsData: action.payload,
            };
        case SET_SECTION_NAME:
            return {
                ...state,
                sectionName: action.payload,
            };
        case SET_ALLOW_WIZARDNEXT:
            return {
                ...state,
                allowWizardNext: action.payload,
            };
        case SET_PARAMETERS_SECTION:
            return {
                ...state,
                parametersSection: action.payload,
            };
        case CLEAN_PARAMETERS_SECTION:
            return { ...state, parametersSection: null };
        case SET_REFRESH_CONTENT_SHOP_DATA:
            return { ...state, refreshContentShopData: action.payload };
        case SET_SHOP_DATA_HEAD_MENU:
            return { ...state, shopHeadMenu: action.payload };
        case SET_SHOP_TOKEN:
            return { ...state, shopToken: action.payload };
        default:
            return state;
    }
}
