import { gql } from "apollo-boost";

export const GET_DHCP_INFO = gql`
    query {
        getMonitorDHCPInfo {
            lastUpdate
            updateTimeMin
            data {
                dhcpLeaseWarning
                ip
                ipsCount
                freeIpsCount
                networkType
                otherServers
                startIp
                endIp
                gw
                dns
                dnsWarning
            }
        }
    }
`;

export const GET_CC_GUESTNETWORK_INFO = gql`
    query {
        getCCsGuestNetworkInfo {
            lastUpdate
            data {
                mac
                ip
                installed
                data {
                    id
                    ip
                    roomTV
                    roomNumber
                    name
                    mac
                    model
                    subModel
                    ref
                    castBuildVersion
                }
            }
        }
    }
`;

export const GET_MDNS_INFO = gql`
    {
        getMonitorCastInfo {
            lastUpdate
            updateTimeMin
            data {
                mdnsData {
                    cgUpTimeSeconds
                    isDiscoverActive
                    discoverPercent
                    discoverCCNumber
                    ccTotalNumber
                    ccsNotDiscoveredList {
                        ip
                        mac
                        roomTV
                        room_number
                        name
                    }
                    ccsNeverDiscoveredNumber
                    ccsNeverDiscoveredList {
                        ip
                        mac
                        room_number
                        roomTV
                        name
                        location
                    }
                    ccsNeverDiscoveredMinorWarning
                    ccsNeverDiscoveredCriticalWarning
                    isMdnsActive
                    isMdnsProxyActive
                    averageLatency
                    averageLatencyWarning
                    isMdnsFloodActive
                    bwDownCast
                    mdnsLowLevelWarning
                    bandwidthWarning
                    serverIPS
                    serverMacs
                    arpMacs
                }
                anomalousTrafficData {
                    isTrafficFromOtherNetwork
                    ipListTrafficFromOtherNetwork
                    isRoutingToGuestNetwork
                    areUsers
                }
                networkPerformanceData {
                    isCCsWifiSignalLow
                    ccsWifiSignalLowNumber
                    isCCsLatencyHigh
                    ccsLatencyHighNumber
                    internetBandwidth
                    recommendedCCs
                    hiredCCs
                    recommendedBandwidth
                    ccsCastingNumber
                    internetBandwidthMinorWarning
                    internetBandwidthCriticalWarning
                }
            }
        }
    }
`;

export const GET_GUEST_NETWORK_INFO = gql`
    {
        getGuestNetworkInfo {
            lastUpdate
            updateTimeMin
            data {
                mdnsData {
                    areUsers
                    isMdnsActive
                    isConnectivityActive
                    serverIPS
                    serverMacs
                    arpMacs
                    areDiscoveryProblems
                    areCastingProblems
                    areDiscoveryProblemsForIos
                }
                anomalousTrafficData {
                    isTrafficFromOtherNetwork
                    ipListTrafficFromOtherNetwork
                    isRoutingToCastNetwork
                    areCCs
                }
            }
        }
    }
`;

export const GET_WARNINGS_INFO = gql`
    {
        getMonitorWarnings {
            castNetworkWarningsLevel
            usersNetworkWarningsLevel
            areCriticalNetworkWarnings
            criticalWarnings {
                castMdnsWarning {
                    alert
                    lastUpdate
                }
                castProxyWarning {
                    alert
                    arpMacs
                    lastUpdate
                    serverIPS
                    serverMacs
                    otherServers
                }
                otherDhcpServersWarning {
                    alert
                    lastUpdate
                    serverIPS
                    serverMacs
                    otherServers
                }
                usersMdnsWarning {
                    alert
                    lastUpdate
                    serverIPS
                    serverMacs
                    otherServers
                }
                usersConnectivityWarning {
                    alert
                    arpMacs
                    lastUpdate
                    serverIPS
                    serverMacs
                    otherServers
                }
                userRoutingToCastWarning {
                    alert
                    lastUpdate
                }
                castRoutingToUserWarning {
                    alert
                    lastUpdate
                }
                internetBandwidthWarning {
                    alert
                    lastUpdate
                    recommendedBandwidth
                }
                castNeverDiscoveredWarning {
                    alert
                    ccsNumber
                    lastUpdate
                }
            }
        }
    }
`;

export const GET_DHCP = gql`
    {
        getMonitorAvailability {
            isAllData
            isDhcpData
            isSection
        }
    }
`;

export const GET_CCS_LOW_WIFI_SIGNAL = gql`
    {
        getCCsLowWifiSignal {
            id
            ip
            location
            mac
            model
            name
            ref
            roomTV
            room_number
            subModel
        }
    }
`;

export const GET_CCS_HIGH_LATENCY = gql`
    {
        getCCsHighLatency {
            id
            ip
            location
            mac
            model
            name
            ref
            roomTV
            room_number
            subModel
        }
    }
`;

export const GET_CCS_NEVER_DISCOVERED = gql`
    {
        getCCsNeverDiscovered {
            id
            ip
            location
            mac
            model
            name
            ref
            roomTV
            room_number
            subModel
        }
    }
`;
