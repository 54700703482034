import { gql } from "apollo-boost";
import { useModal } from "components/Modal";
import { useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const useDeleteRoomGroups = ({ group, refetch, setLoadingRefresh, type }) => {
    const [isDeleted, setIsDeleted] = useState(false);

    const { t } = useTranslation();
    const { close } = useModal();

    const deleteGroupQuery = gql`
        mutation deleteRoomGroup($id: Int64!) {
            deleteRoomGroup(id: $id) {
                error
                ok
            }
        }
    `;

    const deleteGuestGroupQuery = gql`
        mutation deleteGuestGroup($id: Int64!) {
            deleteGuestGroup(id: $id) {
                error
                ok
            }
        }
    `;
    const selectMutation = type === "room" ? deleteGroupQuery : deleteGuestGroupQuery;

    const [executeMutation] = useMutation(selectMutation);

    const deleteGroup = () => {
        if (type === "room") {
            executeMutation({
                variables: {
                    id: group?.id,
                },
            }).then((response) => {
                if (response?.data?.deleteRoomGroup?.ok) {
                    setLoadingRefresh(true);
                    setIsDeleted(response?.data?.deleteRoomGroup?.ok);
                    toast.success(t("operation-successful"));
                }
                close();
            });
        } else if (type === "guest") {
            executeMutation({
                variables: {
                    id: group?.id,
                },
            }).then((response) => {
                if (response?.data?.deleteGuestGroup?.ok) {
                    setLoadingRefresh(true);
                    setIsDeleted(response?.data?.deleteGuestGroup?.ok);
                    toast.success(t("operation-successful"));
                }
                close();
            });
        }
    };

    useEffect(() => {
        if (isDeleted && typeof refetch === "function") {
            refetch();
            setTimeout(() => {
                setLoadingRefresh(false);
            }, 1500);
        }
    }, [isDeleted]);

    return { deleteGroup };
};
