import { useState, useEffect } from "react";

import { Session } from "hooks/Utils/Session";

import { useTokenLanguage } from "./auth";

const useChainAuth = ({ onError }) => {
    const storedChain = Session.getChain();

    const [info, setInfo] = useState(storedChain);

    const token = info?.token;
    const projects = info?.projects;

    const { update: updateToken, token: updatedToken, loading } = useTokenLanguage({ onError });

    useEffect(() => {
        Session.setChain(info);
    }, [info]);

    useEffect(() => {
        if (updatedToken) {
            setInfo((prev) => ({ ...prev, token: updatedToken }));
        }
    }, [updatedToken]);

    return {
        info,
        projects,
        token,
        updateLanguage: (lang) => updateToken({ lang, token }),
        loading,
        login: (data) => {
            setInfo(data?.ref ? data : null);
        },
        logout: () => {
            setInfo(null);
        },
    };
};

export default useChainAuth;
