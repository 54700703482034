import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import translate from "../../lang/es/CommonZones/translate";
//Components
import UseCalendarSpecialSpecific from "./useCalendarSpecialSpecific";
//Utils
import useComponentVisible from "../useComponentVisible";

const UseInputDateSpecialSpecific = ({ validation, index }) => {
    //Store data
    const [selectActive, setSelectActive] = useState({});
    const { specificDays } = useSelector((state) => state.schedules.specialRowDateContainer[index]);

    //Validation states
    const [datesError, setDatesError] = useState(validation);

    //Styles
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const getErrorStyle = (error) => {
        let res = "";
        if (!error) {
            res = "border border-red-100";
        }
        return res;
    };

    //Listeners
    useEffect(() => {
        setDatesError(validation);
    }, [validation]);

    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({});
        } else {
            setSelectActive({
                text: "text-blue-300",
            });
        }
    }, [isComponentVisible]);

    return (
        <div ref={ref} className="relative">
            <div
                className={`w-full h-10 bg-gray-200 rounded relative p-2 ${selectActive.text} ${getErrorStyle(
                    datesError
                )}`}
                onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
            >
                {specificDays ? `${specificDays.length} ${translate("dates-selected")}` : translate("days")}
            </div>
            {!datesError ? <p className="absolute text-red-100">{translate("activity-days-error")}</p> : null}
            {isComponentVisible ? (
                <div className="absolute z-200">
                    <UseCalendarSpecialSpecific index={index} />
                </div>
            ) : null}
        </div>
    );
};

export default UseInputDateSpecialSpecific;
