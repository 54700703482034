const NewCorporateChannel = (t, data) => {
    return {
        title: "add-corporate-channel",
        text: t("write-the-name-of-corporate-channel"),
        executeSectionQuery: true,
        bodyAdjust: "w-1/12",
        inputs: [
            {
                text: "",
                name: "name-new-corporate-channel",
                type: "text",
                focus: true,
                ph: t("name"),
                style: "pb-20",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "add",
                style: "blue",
                action: "add-corporate-channel",
            },
        ],
    };
};

export default NewCorporateChannel;
