import React, { useEffect } from "react";
import { getDocumentElementHeight } from "../../../../../hooks/Utils/Design/EditorUtils";
import UseListDropDown from "./useListDropDown";
import { useSelector } from "react-redux";
import CustomColorPicker from "../../Widgets/WidgetToolbars/OptionsDetails/CustomColorPicker";

const SalesStyleEditor = () => {
    const { salesStyleData } = useSelector((state) => state.sectionContent);

    useEffect(() => {
        return () => {
            if (typeof window.onSelectCallback === "function") {
                window.onSelectCallback = null;
            }
            if (typeof window.onSelectColorCallback === "function") {
                window.onSelectColorCallback = null;
                window.listOption = null;
            }
        };
    }, []);

    if (!salesStyleData) {
        return null;
    }

    // eslint-disable-next-line
    return (
        <div
            className="bg-white"
            style={{ height: `calc(100% - ${getDocumentElementHeight("changesAppliedSales")}px)` }}
        >
            <CustomColorPicker />
            <UseListDropDown list={salesStyleData} />
        </div>
    );
};

export default SalesStyleEditor;
