import React, { useEffect, useState } from "react";
import SelectorItem from "components/SelectorItem";
import { CARDS_SETTINGS, satisfactionLevelItems, UPDATE_SETTINGS } from "../utils";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Icon from "components/Icon";
import { toggleCardInEdition, isInEdition, cancelEdit } from "../utils";

const SettingsSatisfactionLevel = ({ cardInEdition, setCardInEdition, dataModified, SET_EXECUTE }) => {
    const { t } = useTranslation();

    const [satisfactionLevelSelected, setSatisfactionLevelSelected] = useState(null);

    useEffect(() => {
        if (dataModified?.minLevelSendEmail) {
            setSatisfactionLevelSelected(dataModified?.minLevelSendEmail);
        }
    }, [dataModified]);

    return (
        <div className={`${!dataModified?.sendEmail ? " text-gray-700" : ""}`}>
            <div className="mt-4">{t("select-the-guest-satisfaction-level-that-will-propmt")}</div>

            <div className="w-full mt-10 flex ">
                <div className="w-11/12 px-28 flex items-center justify-center">
                    <SelectorItem
                        id="settings-satisfaction-level-selector"
                        type="satisfaction"
                        readOnly={!isInEdition({ card: CARDS_SETTINGS.SATISFACTION_LEVEL, cardInEdition })}
                        onChange={setSatisfactionLevelSelected}
                        disabled={!dataModified?.sendEmail}
                        value={satisfactionLevelSelected}
                    />
                </div>
                <div className="w-1/12 flex justify-end items-center">
                    <Button
                        onClick={() => {
                            toggleCardInEdition({
                                card: CARDS_SETTINGS.SATISFACTION_LEVEL,
                                setCardInEdition: setCardInEdition,
                                cardInEdition: cardInEdition,
                            });
                            setSatisfactionLevelSelected(dataModified?.minLevelSendEmail);
                        }}
                        disabled={!dataModified?.sendEmail}
                        id="settings-satisfaction-level-edit-button"
                    >
                        <Icon className="text-gray-800" type="edit" size={1.3} />
                    </Button>
                </div>
            </div>
            {isInEdition({ card: CARDS_SETTINGS.SATISFACTION_LEVEL, cardInEdition }) && (
                <div className="flex justify-end space-x-2 mt-10">
                    <Button
                        onClick={() => {
                            cancelEdit(setCardInEdition);
                            setSatisfactionLevelSelected(dataModified?.minLevelSendEmail);
                        }}
                        id="settings-satisfaction-level-cancel-button"
                        design="blue-outline"
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        onClick={() => {
                            if (satisfactionLevelSelected !== null) {
                                SET_EXECUTE({
                                    ACTION: UPDATE_SETTINGS.SATISFACTION_LEVEL,
                                    PARAMS: { SATISFACTION_LEVEL_SELECTED: satisfactionLevelSelected },
                                });
                            }
                        }}
                        id="settings-satisfaction-level-save-button"
                        design="blue"
                    >
                        {t("save")}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default SettingsSatisfactionLevel;
