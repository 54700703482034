import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
//Components
import UseSelect from "../Schedule/useSelect";
import UseInputText from "../Schedule/useInputText";
import UseInputDate from "../Schedule/useInputDate";
import UseButton from "../useButton";
//Actions
import { cleanAction, cleanScheduleDates, setActionDateType } from "../../actions/globalActions";
import { setWizardAllowNext } from "../../actions/sectionActions";
import { addBatchPeriodicSchedule, addViewPeriodicSchedule } from "../../actions/scheduleActions";
//Utils
import {
    validateDateType,
    validateDates,
    validateName,
    GetMutationDateType,
    GetMutationDateList,
} from "../../hooks/Utils/ScheduleManagement";

//Notifications
import { toast } from "react-toastify";

const UseScheduleFormFestive = ({ setAddFestiveSchedule }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { itemName, dateType, specificRange, specificDates } = useSelector((state) => state.action);

    //Form states
    const [isFormVisible, setIsFormVisible] = useState(true);
    //Validation States
    const [nameValidation, setNameValidation] = useState(true);
    const [dateTypeValidation, setDateTypeValidation] = useState(true);
    const [datesValidation, setDatesValidation] = useState(true);
    const { id } = useParams();

    //Actions
    const dispatch = useDispatch();
    const handleClickCancel = () => {
        setAddFestiveSchedule(false);
        dispatch(cleanAction());
        dispatch(setWizardAllowNext(true));
    };

    const handleClickSave = () => {
        //Validation
        setNameValidation(validateName(itemName));
        setDateTypeValidation(validateDateType(dateType));
        setDatesValidation(validateDates(dateType, specificRange, specificDates));

        if (isValidated(nameValidation, datesValidation, itemName, dateType, specificRange, specificDates)) {
            //Format data
            const festiveSchedule = {
                areaID: id,
                tempId: Math.random(),
                dateType: GetMutationDateType(dateType),
                isEnabled: true,
                name: itemName,
                periodicityType: "HABITUAL",
                timetableDates: [
                    {
                        dateList: GetMutationDateList(specificRange, specificDates, dateType),
                        timetableDateRanges: [
                            {
                                timeRanges: "",
                                weekdayList: "",
                            },
                        ],
                    },
                ],
                type: "CLOSED",
            };
            //Update BatchToStore
            dispatch(addBatchPeriodicSchedule(festiveSchedule));
            //Add to edit view
            dispatch(addViewPeriodicSchedule(festiveSchedule));
            //Reset view
            setIsFormVisible(false);
            setAddFestiveSchedule(false);
            dispatch(cleanScheduleDates());
            dispatch(setActionDateType(""));
            dispatch(setWizardAllowNext(true));
        } else {
            toast.error(langStrings["check form"]);
        }
    };

    return (
        <>
            {isFormVisible ? (
                <div className="w-full block bg-white p-8">
                    <div className="pt-4 pb-8 flex items-start">
                        <div className="w-1/6 inline-block mr-4">
                            <UseInputText validation={nameValidation} />
                        </div>
                        <div className="w-1/6 inline-block mr-4 ">
                            <UseSelect placeholder="date-type" validation={dateTypeValidation} />
                        </div>
                        {dateType ? (
                            <div className="w-2/6 inline-block mr-4">
                                <UseInputDate validation={datesValidation} />
                            </div>
                        ) : null}
                    </div>

                    <div className="w-full flex justify-end h-10 mt-8 mr-4 mb-4">
                        <div className="w-auto inline-block mr-4" onClick={(e) => handleClickCancel()}>
                            <UseButton buttonName="cancel" buttonColor="btn-blue-outline" />
                        </div>
                        <div className="w-auto inline-block" onClick={(e) => handleClickSave()}>
                            <UseButton buttonName="save" buttonColor="btn-blue" />
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export const isValidated = (nameValidation, datesValidation, itemName, dateType, specificRange, specificDates) => {
    //Returns true if all validations passed
    let res;
    if (nameValidation && datesValidation && itemName && dateType) {
        if ((dateType === "date-range" && specificRange) || (dateType === "date-specific" && specificDates)) {
            res = true;
        }
    } else {
        res = false;
    }
    return res;
};

export default UseScheduleFormFestive;
