import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import ReactTooltip from "components/ReactTooltip";
import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Actions from "./CommonOptions/Actions";
import EmptyWidget from "./CommonOptions/EmptyWidget";
import Input from "./CommonOptions/Input";
import LogoPreview from "../../Appearances/LogoPreview";
import { setExecuteQuery } from "../../../../../actions/sectionActions";
import Widget from "../Widget";
import { EditorContext, WidgetContext } from "../../../../../contexts/editor";
import { Session } from "../../../../../hooks/Utils/Session";
import { SCREEN } from "constants/editor";

const LogoToolbar = ({ deviceSpecific }) => {
    const { deviceType, theme } = useContext(EditorContext);
    const { config, type, id: widgetID } = useContext(WidgetContext);

    const { t } = useTranslation();
    const { type: screenType } = useParams();
    const dispatch = useDispatch();

    const { gridItems } = useSelector((state) => state.design);
    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const data = config?.data || Widget.ParseData(widget ? widget.data : null, deviceType);
    const [logoVersion, setLogoVersion] = useState(data ? data.version : null);
    const [logoType, setLogoType] = useState(data ? data.type : null);

    const [themeLogo, setThemeLogo] = useState(null);
    const [themeLogoImageRef, setThemeLogoImageRef] = useState(null);

    useEffect(() => {
        if (theme && theme.logos) {
            setThemeLogo(theme?.logos?.find((logo) => logo.version === logoVersion && logo.type === logoType));
        }
        // eslint-disable-next-line
    }, [theme, logoVersion, logoType]);

    useEffect(() => {
        setLogoVersion(data.version);
        setLogoType(data.type);
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (themeLogo && themeLogo.themeLogoCustoms.length > 0) {
            setThemeLogoImageRef(themeLogo.themeLogoCustoms[0].imageRef);
        } else if (themeLogo && themeLogo.imageRef !== "") {
            setThemeLogoImageRef(themeLogo.imageRef);
        }
        // eslint-disable-next-line
    }, [themeLogo]);

    const currentLogo = logoVersion ? t(logoVersion.toLowerCase().replaceAll("_", "-")) : null;
    const warning = logoVersion && !themeLogo ? t("logo-warning-unspecified", { type: currentLogo }) : null;

    const removeLogo = () => {
        dispatch(setExecuteQuery(Widget.UpdateData({ widget, value: { version: null, type: null } })));
    };

    return (
        <>
            <Toolbar type="settings">
                <Settings>
                    <Settings.Group title={t("logo")}>
                        <div className="relative mb-8 h-40">
                            {warning ? (
                                <>
                                    <i
                                        className="icon icon-warning absolute ml-2 text-2xl text-orange-100"
                                        style={{ top: ".5rem", right: ".5rem" }}
                                        data-tip
                                        data-for={`logo_warn_${widgetID}`}
                                    ></i>
                                    {warning !== true ? (
                                        <ReactTooltip id={`logo_warn_${widgetID}`}>{warning}</ReactTooltip>
                                    ) : null}
                                </>
                            ) : null}
                            {logoType ? (
                                <>
                                    {themeLogoImageRef ? (
                                        <img
                                            alt="logo"
                                            src={Session.getDasUrl(themeLogoImageRef)}
                                            style={{ margin: "auto", maxWidth: "25rem", maxHeight: "10rem" }}
                                        />
                                    ) : (
                                        <LogoPreview logoType={logoType + "_" + logoVersion} className="h-40" />
                                    )}
                                    <div
                                        className="icon icon-delete-editor rounded  absolute bottom-0 right-0 w-full cursor-pointer p-1  text-center text-base text-white "
                                        style={{ backgroundColor: "rgba(46,56,67,.5)" }}
                                        onClick={removeLogo}
                                    ></div>
                                </>
                            ) : (
                                <EmptyWidget type={type}></EmptyWidget>
                            )}
                        </div>
                        <Input.Logo deviceSpecific={true} title={t("appearance-logo")} />
                    </Settings.Group>
                </Settings>
            </Toolbar>
            <Toolbar type="style">
                <Settings.Border t={t} />
            </Toolbar>
            {screenType !== SCREEN.TYPE.PORTAL ? (
                <Toolbar type="actions">
                    <Actions
                        actions={{
                            content: true,
                            landing: true,
                            section: true,
                            url: deviceType !== "TV",
                            library: true,
                            tvchannel: true,
                        }}
                    />
                </Toolbar>
            ) : null}
            {/* <Toolbar type="clone" /> */}
            <Toolbar type="delete" />
        </>
    );
};

export default LogoToolbar;
