export const Capacity = (areaCapacity, categories, sortCriteria) => {
    const dataType = "area-capacity";

    const {
        id,
        name,
        parentCategoryName,
        parentCategoryID,
        currentCapacity,
        capacityLimit,
        currentCapacityUpdated,
        isPercentage,
    } = areaCapacity;
    //Calc currentCapacity status
    const currentStatus = evaluatecurrentStatus(capacityLimit, currentCapacity, isPercentage);

    return {
        dataType: dataType,
        id: id,
        category: !categories.includes(parentCategoryName) && sortCriteria === "" ? parentCategoryID : false,
        categoryName: !categories.includes(parentCategoryName) && sortCriteria === "" ? parentCategoryName : false,
        withAction: true,
        info: [
            {
                name: "area",
                cellContent: "text",
                value: name,
                subvalue: sortCriteria === "" ? null : parentCategoryName,
                specialWidth: "w-3/12 break-all",
            },
            {
                name: "current-capacity",
                cellContent: "icon-with-text",
                sufix: isPercentage ? "occupation-percent" : "people",
                translateSufix: true,
                value: currentCapacity,
                type: dataType,
                specialWidth: "w-2/12",
                icon: {
                    name: "status-solid",
                    value: currentStatus,
                    itemType: dataType,
                    adjust: `${getIconColor(capacityLimit, currentCapacity, isPercentage)} text-xl`,
                },
            },
            {
                name: "capacity-limit",
                cellContent: "value",
                value: capacityLimit,
                sufix: "people",
                translateSufix: true,
                specialWidth: "w-2/12",
            },
            {
                name: "last-refresh",
                cellContent: "text",
                value: new window.ZDate(currentCapacityUpdated).toLocaleString(),
                specialWidth: "w-3/12",
            },
            {
                name: "edit-current-capacity",
                cellContent: "btn-form-launcher",
                value: id,
                action: "capacity-edit",
                translate: true,
                specialWidth: "w-2/12 pr-4",
                launchForm: true,
                wrap: "whitespace-no-wrap",
                data: {
                    id,
                    name,
                    currentCapacity,
                    capacityLimit,
                    currentCapacityUpdated,
                    isPercentage,
                },
            },
        ],
    };
};

export const evaluatecurrentStatus = (capacityLimit, currentCapacity, isPercentage) => {
    let res;
    const numCurrentCapacity = Number(currentCapacity);
    if (isPercentage) {
        if (numCurrentCapacity >= 100) {
            res = "high";
        } else if (numCurrentCapacity < 100 && numCurrentCapacity >= 75) {
            res = "medium";
        } else {
            res = "low";
        }
    } else {
        if (currentCapacity >= capacityLimit) {
            res = "high";
        } else if (currentCapacity > capacityLimit * 0.75) {
            res = "medium";
        } else {
            res = "low";
        }
    }
    return res;
};

export const getIconColor = (capacityLimit, currentCapacity, isPercentage) => {
    let res;
    const numCurrentCapacity = Number(currentCapacity);
    if (isPercentage) {
        if (numCurrentCapacity > 75) {
            res = "fail";
        } else if (numCurrentCapacity > 50) {
            res = "warning";
        } else {
            res = "success";
        }
    } else {
        if (currentCapacity > capacityLimit * 0.75) {
            res = "fail";
        } else if (currentCapacity > capacityLimit * 0.5) {
            res = "warning";
        } else {
            res = "success";
        }
    }
    if (capacityLimit === 0) {
        res = "";
    }
    return res;
};

export default Capacity;
