import React from "react";
import { GetCellContent } from "../../../hooks/Utils/GetCellContent";

const UseDateCell = ({ cellData }) => {
    const { hidden, adjust } = cellData || {};
    const content = GetCellContent(cellData);
    const dateParts = content.split(" ");
    return (
        <>
            {!hidden ? (
                <div className={`text-left ${adjust}`}>
                    <div>{dateParts[0]}</div>
                    <div>{dateParts[1]}</div>
                </div>
            ) : null}
        </>
    );
};

export default UseDateCell;
