import React, { useEffect, useLayoutEffect } from "react";
import UseTable from "../../../../Table/index";
import ListPredefinedChannels from "../../../../../hooks/GraphqlCalls/Services/ListPredefinedChannels";
import UseButton from "../../../../useButton";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { showRowForm } from "../../../../../actions/sectionActions";
import { cleanAction, setActionName } from "../../../../../actions/globalActions";
import { useTranslation } from "react-i18next";
import { useNavigate as useHistory } from "react-router-dom";
import { cleanTableStore } from "../../../../../actions/tableActions";

const PredefinedChannelsListed = () => {
    const tableLoading = document.querySelector(".t-loading");
    const { rowForm } = useSelector((state) => state.sectionContent);
    const { loading } = useSelector((state) => state.table);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    useLayoutEffect(() => {
        if (tableLoading) {
            tableLoading.style.margin = "0px";
        }
        return () => {
            if (tableLoading) {
                tableLoading.style.margin = null;
            }
        };
    }, [tableLoading, loading]);

    useEffect(() => {
        return () => {
            dispatch(cleanTableStore());
        };
    }, []);

    const addChannel = (e) => {
        if (rowForm) {
            toast.error(t("save-before-continue"));
            return;
        }
        dispatch(showRowForm(true));
        dispatch(setActionName("add-preset-channel"));
    };

    useEffect(() => {
        const containerTable = document.getElementById("table-container");
        const containerTableBody = document.getElementById("table-body-container");
        if (containerTable && containerTableBody) {
            containerTable.style.padding = "0";
            containerTable.style.backgroundColor = "transparent";
            containerTableBody.style.backgroundColor = "white";
            containerTableBody.style.minHeight = "500px";
            containerTableBody.style.maxHeight = "auto";
            containerTableBody.classList.add("rounded", "p-8");
        }

        return () => {
            if (containerTable && containerTableBody) {
                containerTable.style.padding = null;
                containerTable.style.backgroundColor = null;
                containerTableBody.style.backgroundColor = null;
                containerTableBody.style.height = null;
                containerTableBody.classList.remove("rounded", "p-8");
            }
        };
    }, []);

    return (
        <div className=" relative">
            <div className=" absolute z-10 right-0 flex space-x-4 " style={{ top: "3.5rem" }}>
                <UseButton
                    id={"predefined-channels-add-bulk-channels"}
                    action={() => {
                        if (rowForm) {
                            toast.error(t("save-before-continue"));
                            return;
                        }
                        dispatch(cleanAction());
                        history("/services/tv-channels/preset-channels/bulk-channels");
                    }}
                    buttonName={"add-bulk-channels"}
                    buttonColor={"btn-blue-outline"}
                />
                <UseButton
                    id={"predefined-channels-add-channel"}
                    action={() => addChannel()}
                    buttonName={"add-channel"}
                    buttonColor={"btn-blue"}
                />
            </div>
            <UseTable />
            <ListPredefinedChannels />
        </div>
    );
};

export default PredefinedChannelsListed;
