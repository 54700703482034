import { Session } from "../../../Utils/Session";
const NewPropertyGroup = (name, properyGroupId) => {
    let associatedProjects = [];
    if (Session.getProjects() !== null && Session.getProjects() !== undefined) {
        // eslint-disable-next-line
        Session.getProjects().map((project) => {
            if (project.id && project.id > 0) {
                associatedProjects.push(project);
            }
        });
    } else {
        associatedProjects.push({
            id: 0,
            ref: "fakeProject",
            name: "",
        });
    }
    const propertiesQuery = `{
        data: properties(filter:{refs:[${associatedProjects.map(function (a) {
            return `"${a.ref}"`;
        })}]}){
            info{count}
            results{id name hasChainModule}
          }
          ${
              properyGroupId
                  ? `selected: brands(IDs:[${properyGroupId}]){
                      results { projects{id} }
                  }`
                  : ""
          }     
        }
        `;

    return [
        {
            title: properyGroupId ? [name, "-", "edit-group"] : "add-new-group",
            id: properyGroupId ? properyGroupId : 0,
            inputs: [
                {
                    text: ["group-name", ":"],
                    name: "name",
                    value: name,
                    type: "text",
                    ph: "group-name",
                    cStyle: "w-5/12",
                },
                {
                    text: ["select-properties", ":"],
                    name: "properties",
                    type: "selectMultipleWithSearch",
                    optionsQuery: propertiesQuery,
                    labelsColors: "bg-teal-500 text-white",
                    cStyle: "w-9/12",
                    selectedIdsPath: properyGroupId ? "data.selected.results[0].projects" : null,
                    selectPlaceHolder: "properties",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "accept",
                    style: "blue",
                    action: "add-property-group",
                },
            ],
        },
    ];
};

export default NewPropertyGroup;
