import React from "react";
import { useSelector } from "react-redux";
//Components
import UseInputCheckbox from "./useInputCheckbox";

const UseInputWeekDays = ({ validation, weekDaysUsed = null }) => {
    const { langStrings } = useSelector((state) => state.ui);
    //ISO 8601 standard Monday(1) Sunday (7)
    const weekLetters = [1, 2, 3, 4, 5, 6, 7];

    return (
        <span className="relative overflow-visible">
            {weekLetters.map((weekLetter, index) => (
                <UseInputCheckbox data={weekLetter} key={index} disabled={weekDaysUsed.includes(weekLetter)} />
            ))}
            {!validation ? (
                <p className="absolute schedule-weekday-warning text-red-100 w-full border-red-100 border-t ">
                    {langStrings["schedule-weekday-error"]}
                </p>
            ) : null}
        </span>
    );
};

export default UseInputWeekDays;
