import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirst } from "../../hooks/Utils/Utils";
import { changeActionValues } from "../../actions/globalActions";
import { PRESET_CHANNEL, CUSTOMISED_CHANNEL } from "../../hooks/Utils/Services/ChannelsUtils";
import { useLazyQuery } from "react-apollo";
import { Session } from "../../hooks/Utils/Session";
import { gql } from "apollo-boost";

import UseRadio from "../useRadio";
import UseInputText from "../Table/useInputText";
import UseComponentLoading from "../useComponentLoading";
import UseSelectWithSearch from "../useSelectWithSearch";
import uuid from "react-uuid";
import _ from "lodash";

import { GlobalContext } from "contexts/Global";

const UseModalAddChannel = ({ index }) => {
    const { t } = useTranslation();

    const { lang } = useContext(GlobalContext);

    const dispatch = useDispatch();

    //Store
    const { validations, actionData } = useSelector((state) => state.action);
    const sectionName = `add-channel`;
    const channelClasses = [PRESET_CHANNEL.value, CUSTOMISED_CHANNEL.value];
    const imgSrcRegex = Session.getDasUrl("{{libraryRef}}?lang=" + lang + "&w=70&h=70");
    const inputs = [
        `${sectionName}-class`,
        `${sectionName}-preset`,
        `${sectionName}-name`,
        `${sectionName}-country`,
        `${sectionName}-language`,
        `${sectionName}-preset-tuneinfo`,
    ];

    //States
    const [channelClass, setChannelClass] = useState(channelClasses[0]);
    const [countries, setCountries] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [presetChannels, setPresetChannels] = useState([]);

    const GET_DATA = gql`
        {
            channels: predefinedChannels(filter: { onlyUnusedChannels: true }) {
                results {
                    id
                    name
                    logoRef
                    #tuneInfo
                    countryRef
                }
            }
            languages: languages {
                results {
                    ref
                }
            }
            countries: countries {
                results {
                    information {
                        name
                    }
                    ref
                }
            }
        }
    `;

    const [executeQuery, { data, loading }] = useLazyQuery(GET_DATA, { fetchPolicy: "network-only" });

    useEffect(() => {
        executeQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data) {
            Session.setSessionProp("updateSelectOptions", inputs.join(","));
            setLanguages(arrangeLanguages(data.languages ? data.languages.results : []));
            setCountries(arrangeCountries(data.countries ? data.countries.results : []));
            setPresetChannels(arrangeChannels(data.channels?.results ? data.channels.results : []));
        }
        dispatch(changeActionValues({ [inputs[0]]: channelClass }));
        // eslint-disable-next-line
    }, [data, channelClass]);

    //Listeners
    useEffect(() => {
        if (actionData) {
            dispatch(changeActionValues({ [inputs[2]]: actionData[inputs[2]] }));
        }
        // eslint-disable-next-line
    }, [actionData]);

    //Arrange&Functions
    const arrangeLanguages = (list) => {
        let langs = [];
        list.map((lang) => {
            langs.push({
                id: lang.ref,
                name: t("language:" + lang.ref),
            });
        });
        langs = _.orderBy(langs, ["name"], ["asc"]);
        return langs;
    };

    const arrangeCountries = (list) => {
        let countries = [];
        list.map((country) => {
            countries.push({
                id: country.ref,
                name: t("country:" + country.ref.toLowerCase()),
            });
        });
        countries = _.orderBy(countries, ["name"], ["asc"]);
        return countries;
    };

    const arrangeChannels = (list) => {
        let channels = [];
        list.map((channel) => {
            const channelCountryRef = channel?.countryRef?.toLowerCase()
                ? t(`country:${channel?.countryRef?.toLowerCase()}`)
                : null;

            channels.push({
                id: channel.id,
                name: `<p>${channel.name} ${
                    channelCountryRef ? `<span class="text-gray-700" >(${channelCountryRef})</span>` : ""
                }</p>`,
                imgSrc: imgSrcRegex.replace("{{libraryRef}}", channel.logoRef),
                iconError: "icon-channel",
                tuneInfo: channel.tuneInfo ? JSON.parse(channel.tuneInfo) : null,
            });
            return channel;
        });
        channels = _.orderBy(channels, ["name"], ["asc"]);
        return channels;
    };

    //handle functions
    const handleChannelClass = (value) => {
        setLanguages([]);
        setCountries([]);
        setPresetChannels([]);
        setChannelClass(value);
    };

    const handleChannelPreset = (value) => {
        let tuneInfo = "";
        let item = presetChannels.filter((presetChannel) => `${presetChannel.id}` === `${value}`);
        if (item) {
            item = item[0];
            if (item.tuneInfo) {
                tuneInfo = JSON.stringify(item.tuneInfo);
            }
        }
        dispatch(changeActionValues({ [inputs[5]]: tuneInfo }));
    };

    //renders

    return loading ? (
        <UseComponentLoading />
    ) : (
        <div className="w-full">
            <div className="w-full">
                <UseRadio
                    value={channelClasses[0]}
                    selected={channelClass}
                    handleClick={(value) => {
                        handleChannelClass(value);
                    }}
                    id={`${channelClasses[0]}-radio`}
                />
                <span className="ml-6">{t("preset-channel")}</span>
            </div>
            {channelClass === channelClasses[0] && (
                <div className="ml-6 mt-3">
                    <div className="w-full text-left mb-4">
                        <UseSelectWithSearch
                            data={{
                                disabled: false,
                                id: `${inputs[1]}-${uuid()}`,
                                name: inputs[1],
                                optionData: presetChannels,
                                selectPlaceHolder: "select-an-option",
                                noSelect: true,
                                optionsAdjust: "mt-11",
                                oneSelected: true,
                                onChange: (value) => {
                                    handleChannelPreset(value);
                                },
                                useOptionID: false,
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="w-full mt-5">
                <UseRadio
                    value={channelClasses[1]}
                    selected={channelClass}
                    handleClick={(value) => {
                        handleChannelClass(value);
                    }}
                    id={`${channelClasses[1]}-radio`}
                />
                <span className="ml-6">{t("customised-channel")}</span>
            </div>
            {channelClass === channelClasses[1] && (
                <div className="ml-6 mt-3">
                    <div className="w-full mb-4">
                        <span className="block mb-2 first-capital text-sm text-gray-800 font-bold">
                            {capitalizeFirst(t("name"))}*
                        </span>
                        <UseInputText
                            inputData={{ name: inputs[2] }}
                            validation={validations && validations[inputs[2]] === false ? false : true}
                            ph={t("name")}
                            id={inputs[2]}
                            key={inputs[2]}
                        />
                    </div>
                    <div className="w-full text-left mb-4">
                        <span className="block mb-2 first-capital text-sm text-gray-800 font-bold">
                            {t("country")}*
                        </span>
                        <UseSelectWithSearch
                            data={{
                                disabled: false,
                                id: `${inputs[3]}-${uuid()}`,
                                name: inputs[3],
                                optionData: countries,
                                optionsAdjust: "mt-11",
                                selectPlaceHolder: "select-an-option",
                                noSelect: true,
                                oneSelected: true,
                            }}
                        />
                    </div>
                    <div className="w-full text-left mb-4">
                        <span className="block mb-2 first-capital text-sm text-gray-800 font-bold">
                            {t("language")}*
                        </span>
                        <UseSelectWithSearch
                            data={{
                                disabled: false,
                                id: `${inputs[4]}-${uuid()}`,
                                name: inputs[4],
                                optionsAdjust: "mt-11",
                                optionData: languages,
                                selectPlaceHolder: "select-an-option",
                                noSelect: true,
                                oneSelected: true,
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default UseModalAddChannel;
