import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setOnResizeWindowMenu } from "../../../actions/designActions";

const ResizeListener = () => {
    const dispatch = useDispatch();

    let prevWidth = window.innerWidth;
    let prevHeight = window.innerHeight;
    let resizeTimeout;

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth !== prevWidth || window.innerHeight !== prevHeight) {
                dispatch(setOnResizeWindowMenu(true));
                if (resizeTimeout) {
                    clearTimeout(resizeTimeout);
                }
                resizeTimeout = setTimeout(() => {
                    dispatch(setOnResizeWindowMenu(false));
                }, 1000);

                prevWidth = window.innerWidth;
                prevHeight = window.innerHeight;
            }
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            if (resizeTimeout) {
                clearTimeout(resizeTimeout);
            }
        };
    }, []);

    return null;
};

export default ResizeListener;
