import React, { useEffect, useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import Loading from "components/Loading";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { capitalizeFirst } from "hooks/Utils/Utils";
import Radiobuttons from "components/Inputs/Radiobuttons";
import HTMLReactParser from "html-react-parser";
import InputNumber from "components/Inputs/InputNumber";
import Select from "components/Select";
import { getBookingDurationOptions } from "hooks/Utils/Utils";

const ModalEditBookingAllowed = (props) => {
    const {
        name,
        bookingAllowed: bookingAllowedValue,
        autoBooking,
        bookingFreq,
        slotsPerBooking,
        visibleDuration,
        capacityLimit,
        id,
    } = props?.area || {};

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [bookingAllowed, setBookingAllowed] = useState(bookingAllowedValue);
    const [automatic, setAutomatic] = useState(autoBooking);
    const [bookingFrequenci, setBookingFrequenci] = useState(
        bookingFreq != 15 && bookingFreq != 30 && bookingFreq != 60 ? "custom" : bookingFreq
    );
    const [customBookingFreq, setCustoBookingFrequenci] = useState(0);
    const [visibleToCustomer, setVisibleToCustomer] = useState(visibleDuration);
    const [capacity, setCapacity] = useState(capacityLimit);
    const [slots, setSlots] = useState(slotsPerBooking);

    useEffect(() => {
        if (bookingFreq != 15 && bookingFreq != 30 && bookingFreq != 60) {
            setCustoBookingFrequenci(bookingFreq);
        }
    }, [bookingFreq]);

    const UPDATE_COMMON_ZONES = gql`
        mutation {
            updateCommonZone(
                id: ${id}
                capacityLimit: ${capacity}
                bookingAllowed: ${bookingAllowed}
                bookingFreq: ${bookingFrequenci == "custom" ? customBookingFreq : bookingFrequenci}
                slotsPerBooking: ${slots}
                visibleDuration: ${visibleToCustomer}
                autoBooking: ${automatic}
            ) {
                error
                id
                ok
            }
        }
    `;

    const [updateCommonZone] = useMutation(UPDATE_COMMON_ZONES, {
        onCompleted() {
            toast.success(t("operation-successful"));
            props.refetch();
            setLoading(false);
            props.close();
        },
        onError() {
            toast.error(t("mutation-error"));
        },
    });

    const handleSaveClick = () => {
        setLoading(true);
        updateCommonZone();
    };

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={`${name} - ${capitalizeFirst(t("booking-admission"))}`}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-save" onClick={handleSaveClick}>
                        {t("save")}
                    </Button>
                </>
            }
            className={"w-5/12 p-10"}
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <div className=" flex flex-col gap-4">
                    <p>{HTMLReactParser(capitalizeFirst(`${t("select-if")} ${name} ${t("booking-admit")}`))}:</p>
                    <Radiobuttons
                        options={[
                            { label: capitalizeFirst(t("booking-allowed")), value: true },
                            { label: capitalizeFirst(t("booking-notallowed")), value: false },
                        ]}
                        checked={bookingAllowed}
                        onChange={setBookingAllowed}
                    ></Radiobuttons>
                    <p>{HTMLReactParser(capitalizeFirst(t("confirmation-mode")))}:</p>
                    <Radiobuttons
                        options={[
                            { label: capitalizeFirst(t("manual")), value: false },
                            { label: capitalizeFirst(t("automatic")), value: true },
                        ]}
                        checked={automatic}
                        onChange={setAutomatic}
                    ></Radiobuttons>
                    <p>{HTMLReactParser(capitalizeFirst(t("select-booking-freq")))}:</p>
                    <Radiobuttons
                        options={[
                            { label: capitalizeFirst(t("each-15-min")), value: 15 },
                            { label: capitalizeFirst(t("each-30-min")), value: 30 },
                            { label: capitalizeFirst(t("each-hour")), value: 60 },
                            {
                                label: (
                                    <div className=" flex flex-row gap-2 justify-center items-center">
                                        <p>{`${capitalizeFirst(t("each-custom"))} `}</p>
                                        <InputNumber
                                            className={" w-24"}
                                            value={customBookingFreq}
                                            disabled={bookingFrequenci != "custom"}
                                            onChange={setCustoBookingFrequenci}
                                        ></InputNumber>
                                        <p>{t("min")}</p>
                                    </div>
                                ),
                                value: "custom",
                            },
                        ]}
                        onChange={setBookingFrequenci}
                        checked={bookingFrequenci}
                    ></Radiobuttons>
                    <p>{HTMLReactParser(capitalizeFirst(t("select-booking-duration")))}:</p>
                    <div className=" flex flex-row gap-4">
                        <Select
                            id="booking-duration-select"
                            allowUnselect={false}
                            value={slots}
                            optionsContainerClass=" h-32 overflow-y-auto"
                            options={getBookingDurationOptions(
                                bookingFrequenci != "custom" ? bookingFrequenci : customBookingFreq
                            ).map((val, index) => {
                                return { value: index + 1, label: val.value };
                            })}
                            onChange={(e) => {
                                setSlots(e);
                            }}
                        ></Select>
                        <Radiobuttons
                            options={[
                                { label: capitalizeFirst(t("visible-to-customer")), value: true },
                                { label: capitalizeFirst(t("hidden")), value: false },
                            ]}
                            checked={visibleToCustomer}
                            onChange={setVisibleToCustomer}
                        ></Radiobuttons>
                    </div>
                    <p>{HTMLReactParser(capitalizeFirst(t("enter-capacity-limit")))}:</p>
                    <InputNumber placeholder={t("people-number")} value={capacity} onChange={setCapacity}></InputNumber>
                </div>
            )}
        </Modal>
    );
};

export const UseModalEditBookingAllowed = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalEditBookingAllowed {...newProps} />);
        },
    };
};
