import React, { useEffect, useState, useContext } from "react";
import { gql } from "apollo-boost";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "react-apollo";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";

import WidgetElement from "../../../Widget";

const WidgetType = "WIFILOGIN";

const GET_HOTSPOTS = gql`
    {
        hotspots(page: 1, size: 10) {
            results {
                name
            }
        }
    }
`;

const ACCESS_TYPES = (hotspotName) => gql`
        query {
            wifiAccessTypes(hotspot: "${hotspotName ? hotspotName : ""}") {
                results {
                    accesstypes_name
                    enable
                    rrss
                    additionalData {
                        email {
                            enabled
                            mandatory
                        }
                    }
                }
            }
            conditions {
                askMarketing
            }
        }
    `;

const WidgetWifiLogin = () => {
    const { deviceType, editorSize } = useContext(EditorContext);
    const { config: cfg, id: widgetID } = useContext(WidgetContext);
    const { lang } = useParams();

    const [hotspotName, setHotspotName] = useState(null);
    const [getHotspots, { data: hsData }] = useLazyQuery(GET_HOTSPOTS, {
        fetchPolicy: "network-only",
    });
    const [getTermsConfig, { data: wifiConf }] = useLazyQuery(ACCESS_TYPES(hotspotName), {
        fetchPolicy: "network-only",
    });

    const accessTypes = wifiConf?.wifiAccessTypes?.results
        ? wifiConf.wifiAccessTypes.results
              .filter((item) => {
                  if (item?.enable)
                      switch (item.accesstypes_name) {
                          case "email":
                          case "pms":
                          case "code":
                          case "open":
                              return true;
                          default:
                              return false;
                      }
                  return false;
              })
              .sort((a, b) => {
                  const order = ["email", "pms", "code", "open"];
                  const ai = a ? order.indexOf(a.accesstypes_name) : -2;
                  const bi = b ? order.indexOf(b.accesstypes_name) : -2;
                  return ai === bi ? 0 : ai > bi ? 1 : -1;
              })
        : null;
    const hasAccessTypes = accessTypes && accessTypes.length > 0;
    const accessType = hasAccessTypes ? accessTypes[0] : null;

    const showEmail = accessType?.additionalData.email?.enabled;
    const requiredEmail = showEmail && accessType.additionalData.email.mandatory;

    const config = {
        google: accessType && accessType.accesstypes_name === "email" && accessType.rrss,
        askMarketing: wifiConf?.conditions?.askMarketing,
        loginTypes: hasAccessTypes ? accessTypes.map((t) => t.accesstypes_name) : null,
        activeType: accessType ? accessType.accesstypes_name : null,
        form: {
            fields:
                accessType?.accesstypes_name && accessType.accesstypes_name !== "email"
                    ? {
                          [accessType.accesstypes_name]: {
                              email: { enabled: showEmail, required: requiredEmail },
                          },
                      }
                    : null,
        },
    };

    const styles = cfg?.style?.export();

    useEffect(() => {
        getHotspots();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (hsData && hsData.hotspots && hsData.hotspots.results && hsData.hotspots.results.length > 0) {
            setHotspotName(hsData.hotspots.results[0].name);
        }
    }, [hsData]);

    useEffect(() => {
        getTermsConfig();
        // eslint-disable-next-line
    }, [hotspotName]);

    return (
        <WidgetElement
            id={"widget_render_" + widgetID}
            type={WidgetType}
            device={deviceType}
            deviceWidth={editorSize?.width}
            config={config}
            style={styles}
            lang={lang}
        />
    );
};

export default WidgetWifiLogin;
