import { gql } from "apollo-boost";

export const ADD_LANGUAGE = gql`
    mutation addLangToProject($languageRef: String!) {
        addLangToProject(languageRef: $languageRef, isDefault: false) {
            error
            ok
        }
    }
`;

export const GET_LANGUAGES = gql`
    query projectLangs($projectID: Int64!) {
        projectLangs: projectLangs(page: 1, size: 200, filter: { projectID: $projectID }) {
            results {
                chainId
                isDefault
                languageRef
                projectId
                projectRef
            }
        }
        allProjectLangs: projectLangs {
            results {
                chainId
                isDefault
                languageRef
                projectId
                projectRef
            }
        }
        languages: languages(byCustomer: false) {
            results {
                ref
            }
        }
    }
`;

export const DELETE_LANGUAGE = gql`
    mutation DeleteLangFromProject($languageRef: String!) {
        deleteLangFromProject(languageRef: $languageRef) {
            error
            id
            ok
        }
    }
`;

export const UPDATE_LANGUAGE = gql`
    mutation UpdateLangInProject($languageRef: String!) {
        updateLangInProject(languageRef: $languageRef, isDefault: true) {
            error
            ok
        }
    }
`;
