import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { POLLINTERVAL_15S, capitalizeFirst, formatBytes } from "../../hooks/Utils/Utils";
import { convertMsToTime } from "../../hooks/Utils/DesignUtils";
import ReactHlsPlayer from "react-hls-player";
import { useTranslation } from "react-i18next";
import { Session } from "../../hooks/Utils/Session";
import UseLoading from "../Table/useLoading";
import UseVideoPlayerCustom from "../Inputs/UseVideoPlayerCustom";

const UseModalVideoDetails = ({ index }) => {
    const { data: dataModal } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { t } = useTranslation();
    const [videoDetails, setVideoDetails] = useState(null);
    const [playing, setPlaying] = useState(false);
    const playerRef = React.useRef(null);

    const GET_VIDEOS_FROM_CORP_CHANNEL = gql`
        query {
            libraryVideo(
              ref: "${dataModal?.videoRef ? dataModal.videoRef : ""}"
            ) {
              error
              ok
              response {
                ready
                contentType
                defaultLanguage
                items
                languages {
                  language
                  duration
                  resolution
                  size
                }
                lastModified
                name
                size
                ref
              }
            }
          }
          
    `;

    const [getDetailsVideos, { data, loading }] = useLazyQuery(GET_VIDEOS_FROM_CORP_CHANNEL, {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_15S,
    });

    useEffect(() => {
        if (dataModal?.videoRef) {
            getDetailsVideos();
        }
        //eslint-disable-next-line
    }, [dataModal]);

    const playVideo = () => {
        if (!playing) {
            if (playerRef.current) {
                playerRef.current.controls = true;
                playerRef.current.play();
                setPlaying(true);
            }
        }
    };

    useEffect(() => {
        const videoResponse = data?.libraryVideo?.response;
        if (videoResponse) {
            const { defaultLanguage, languages = [] } = videoResponse;
            const videoLangDefaultDetails = defaultLanguage
                ? languages.find((v) => v.language === defaultLanguage)
                : null;

            const languagesText = languages
                .filter((l) => l.language)
                .map((l) => {
                    return l.language === defaultLanguage
                        ? `${t("language:" + l.language)} (${capitalizeFirst(t("default"))})`
                        : ` ${t("language:" + l.language)}`;
                })
                .sort((a, b) => {
                    const isADefault = a.includes(`(${capitalizeFirst(t("default"))})`);
                    const isBDefault = b.includes(`(${capitalizeFirst(t("default"))})`);
                    if (isADefault) return -1;
                    if (isBDefault) return 1;
                    return 0;
                });
            const posterSrc = Session.getDasUrl(`thumb/${videoResponse.ref}-${defaultLanguage}?w=500&h=500`);
            const videoSrc = Session.getDasUrl(`stream/${videoResponse.ref}-${defaultLanguage}_hd.m3u8`);
            setVideoDetails({
                ...videoResponse,
                videoLangDefaultDetails,
                languagesText: languagesText.join(","),
                posterSrc,
                videoSrc,
            });
        }
        //eslint-disable-next-line
    }, [data]);

    return !loading ? (
        <div className=" flex">
            <div className="relative h-96 w-3/5 mr-10" onClick={() => playVideo()}>
                <UseVideoPlayerCustom
                    getSrc={getSrc}
                    videosRef={
                        videoDetails ? [{ ref: videoDetails.ref, defaultLanguage: videoDetails.defaultLanguage }] : []
                    }
                    includesVideoSelector={false}
                    autoplay={false}
                />
            </div>

            <div className=" w-2/5 mt-2">
                <span className=" flex font-bold text-lg">{capitalizeFirst(t("file-information"))}</span>
                <div className=" flex mt-2">
                    <span className=" font-bold mr-2 inline-block">{`${t("name")}:`}</span>
                    <span className=" ">{videoDetails?.name ? videoDetails.name : ""}</span>
                </div>
                <div className=" flex mt-2">
                    <span className=" font-bold mr-2 inline-block">{`${t("type")}:`}</span>
                    <span className="">{getContentTypeVideo(videoDetails)}</span>
                </div>
                <div className=" flex mt-2">
                    <span className=" font-bold mr-2 inline-block">{`${t("size")}:`}</span>
                    <span className="">
                        {videoDetails?.videoLangDefaultDetails?.size
                            ? formatBytes(videoDetails?.videoLangDefaultDetails?.size)
                            : ""}
                    </span>
                    <span>
                        {videoDetails?.videoLangDefaultDetails?.quality
                            ? t(videoDetails.videoLangDefaultDetails.quality).split("-")[0]
                            : ""}
                    </span>
                </div>
                <div className=" flex mt-2">
                    <span className=" font-bold mr-2 inline-block">{`${t("length_duration")}:`}</span>
                    <span className="">
                        {videoDetails?.videoLangDefaultDetails?.duration
                            ? convertMsToTime(videoDetails.videoLangDefaultDetails.duration)
                            : "00:00:00"}
                    </span>
                </div>
                <div className=" flex mt-2">
                    <span className=" font-bold mr-2 inline-block">{`${t("quality")}:`}</span>
                    <span>
                        {videoDetails?.videoLangDefaultDetails?.resolution
                            ? t(videoDetails.videoLangDefaultDetails.resolution).split("-")[0]
                            : ""}
                    </span>
                </div>
                <div className=" flex mt-2">
                    <span className=" font-bold mr-2 inline-block">{`${t("languages")}:`}</span>
                    <span>{videoDetails?.languagesText ? `${videoDetails?.languagesText}.` : ""}</span>
                </div>
            </div>
        </div>
    ) : (
        <div className=" flex items-center justify-center">
            <UseLoading adjust={"contents "} />
        </div>
    );
};

export default UseModalVideoDetails;

const getContentTypeVideo = (details) => {
    if (details && details.contentType) {
        const type = details.contentType.split("/")[1];
        return type ? type.toUpperCase() : "";
    }
    return "";
};

const getSrc = (videosRef, currentIndexRef) => {
    return Session.getDasUrl(
        `stream/${videosRef[currentIndexRef.current].ref}-${videosRef[currentIndexRef.current].defaultLanguage}_hd.m3u8`
    );
};
