import React from "react";
import { useSelector } from "react-redux";

const UseRowGroup = ({ index }) => {
    //Store data
    const { categoryName } = useSelector((state) => state.table.results[index]);

    return (
        <div className="bg-gray-200 text-gray-800 pl-4 py-1 flex items-center justify-start font-bold h-32px">
            {categoryName}
        </div>
    );
};

export default UseRowGroup;
