export const GetSettingsTableHeaders = (section) => {
    let res = [];
    //Sort icon
    const tableSortIcon = { name: "table-sort" };

    //Special width classes
    const w112 = "w-1/12";
    const w212 = "w-2/12";
    const w312 = "w-3/12";
    const w412 = "w-4/12";
    const w512 = "w-5/12";
    const w612 = "w-6/12";
    // const w812 = "w-8/12";
    const w912 = "w-9/12";

    //Header types
    let name = { name: "name", sortName: "fullName" };
    let roleName = { name: "name" };
    let surname = { name: "surname" };
    let email = { name: "email" };
    let accesses = { name: "accesses-text", sortName: "accesses" };
    // let assigned = { name: "assigned", sortName: "users" };
    let roles = { name: "roles" };
    let lang = { name: "lang" };
    let groupName = { name: "group-name", sortName: "name" };
    let properties = { name: "properties" };
    let groups = { name: "groups" };
    let tax = { name: "associated-taxes" };
    let taxZone = { name: "tax-zone" };
    let useTax = { name: "use" };
    let emptyCell = { name: "" };

    switch (section) {
        case "list-users":
            name.specialWidth = w312;
            email.specialWidth = w312;
            properties.specialWidth = w512;
            roles.specialWidth = w512;

            name.icon = tableSortIcon;
            surname.icon = tableSortIcon;
            email.icon = tableSortIcon;

            res.push(name, email, properties, roles);
            break;
        case "list-roles":
            roleName.specialWidth = w312;
            accesses.specialWidth = w412;

            roleName.icon = tableSortIcon;

            res.push(roleName, accesses);
            res.push({
                name: "type",
                sortName: "type",
                specialWidth: w212,
                icon: {
                    name: "table-sort",
                },
            });
            res.push({
                name: "assigned",
                sortName: "users",
                specialWidth: w212,
                icon: {
                    name: "table-sort",
                },
            });
            break;
        case "list-languages":
            lang.specialWidth = w612;
            emptyCell.specialWidth = w512;
            res.push(lang, emptyCell, emptyCell);
            break;
        case "list-propertyGroups":
            groupName.specialWidth = w312;
            groupName.icon = tableSortIcon;
            properties.specialWidth = w912;
            res.push(groupName, properties);
            break;
        case "list-properties":
            properties.specialWidth = w312;
            properties.icon = tableSortIcon;
            groups.specialWidth = w912;
            res.push(properties, groups);
            break;
        case "list-tax-zones":
            taxZone.specialWidth = w312;
            tax.specialWidth = w312;
            useTax.specialWidth = w512;
            emptyCell.specialWidth = w112;
            res.push(taxZone, tax, useTax, emptyCell);
            break;
        default:
            res = [""];
    }
    return res;
};

export default GetSettingsTableHeaders;
