import { useTranslation } from "react-i18next";

import { GET_CCS_GUEST_NETWORK_FILTER_INFO } from "components/Section/Hotel/Services/getTableInformation";
import { getTableFilters } from "hooks/Utils/Table/getTableFilters";
import { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";

export const useGetCCsGuestNetworkFilterInfo = () => {
    const { t } = useTranslation();
    const table_name = "ccs_guest_network";
    const [ccsGuestNetworkFilterInfo, setCCsGuestNetworkFilterInfo] = useState(null);

    const [executeGetFilters, { data }] = useLazyQuery(GET_CCS_GUEST_NETWORK_FILTER_INFO, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeGetFilters();
    }, []);

    useEffect(() => {
        if (data) {
            const filterData = getTableFilters(
                table_name,
                {
                    ownership: createZafiro(data?.getCCsGuestNetworkFilterInfo?.ownership),
                    models: createModel(data?.getCCsGuestNetworkFilterInfo?.models),
                    subModels: createSubModel(data?.getCCsGuestNetworkFilterInfo?.models),
                },
                t
            );
            setCCsGuestNetworkFilterInfo(filterData);
        }
    }, [data]);

    return { ccsGuestNetworkFilterInfo };
};

export const createModel = (data) => {
    return data?.map((item) => ({
        id: item?.type,
        name: item?.type,
        isChecked: false,
    }));
};

export const createSubModel = (data) => {
    return data
        ?.flatMap((item) =>
            item?.models.map((model) => ({
                id: item?.type,
                name: model,
                isChecked: false,
            }))
        )
        .filter((item, index, array) => array.findIndex((elem) => elem.name === item.name) === index);
};

export const createZafiro = (data) => {
    return data?.map((item) => ({
        id: item,
        name: item === "ZAFIRO" ? "Zafiro" : "No Zafiro",
        isChecked: false,
    }));
};
