import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSectionContent } from "../../../../actions/sectionActions";
import {
    cleanActionValues,
    setActionName,
    setActionItemAffected,
    changeActionValues,
} from "../../../../actions/globalActions";
import { setModalContent, closeModal, openModal } from "../../../../actions/uiActions";
import AppearanceReplaceLogo from "./AppearanceReplaceLogo";
import {
    SECTIONCONTENT_NULL_LOGOS,
    LOGOS_TYPES,
    permissionNullLogo,
    THEME_TYPE_MOBILE,
    LOGOS_TYPES_MOBILE,
} from "../../../Utils/AppearancesUtils";
import AppearanceAddLogo from "./AppearanceAddLogo";
import AppearanceDeleteLogo from "./AppearanceDeleteLogo";

import { useAuth } from "hooks/Session/auth";

const AppearanceModalControl = ({ themeType }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { project } = useAuth();
    const projectRef = project?.ref;
    const { appearanceSelected, modals, addLogo } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);

    useEffect(() => {
        if (modals && modals.replace) {
            openReplaceModal(false);
        } else if (modals && modals.upload) {
            openReplaceModal(true);
        } else if (modals && modals.edit) {
            openEditModal();
        } else if (modals && modals.delete) {
            openDeleteModal();
        } else if (modals && modals.add) {
            openAddModal();
        }
        // eslint-disable-next-line
    }, [modals]);

    const cancelButton = () => {
        dispatch(setSectionContent(SECTIONCONTENT_NULL_LOGOS));
        dispatch(cleanActionValues());
        dispatch(setModalContent(false));
        dispatch(closeModal());
    };

    const openAddModal = () => {
        dispatch(
            setSectionContent({
                modalAddLogo: true,
                allowNullLogo: permissionNullLogo(addLogo.type, appearanceSelected.type, projectRef),
                themeType: themeType,
            })
        );
        // console.log("Holaaaa");
        dispatch(setActionName("appearance-add-logo"));
        dispatch(
            setModalContent(
                AppearanceAddLogo(
                    themeType === THEME_TYPE_MOBILE
                        ? t("add-logo")
                        : t("add-type-logo", {
                              type: t("{{capitalize, capitalize}}", { capitalize: t(addLogo.type) }),
                          }),
                    t("give-type-logo-name", {
                        type: t("{{capitalize, capitalize}}", { capitalize: t(addLogo.type) }),
                    }),
                    false,
                    cancelButton
                )
            )
        );
        dispatch(openModal());
    };

    const openReplaceModal = (upload = false) => {
        const actionInModal = upload ? "upload-file" : "replace";
        dispatch(setActionName(`appearance-${actionInModal}-logo`));
        dispatch(setActionItemAffected([addLogo.id]));
        dispatch(
            setSectionContent({
                modalAddLogo: true,
                allowNullLogo: upload ? false : true,
                replaceLogo: true,
                uploadLogo: upload,
                themeType: themeType,
            })
        );

        dispatch(
            setModalContent(
                AppearanceReplaceLogo(
                    `${t(
                        themeType === THEME_TYPE_MOBILE
                            ? LOGOS_TYPES_MOBILE[addLogo.version]
                            : LOGOS_TYPES[addLogo.version]
                    )} - ${t("{{capitalize, capitalize}}", {
                        capitalize: t(actionInModal),
                    })}`,
                    t(`${actionInModal}-type-logo`, {
                        type: t("{{capitalize, capitalize}}", { capitalize: t(addLogo.type) }),
                    }),
                    upload,
                    cancelButton
                )
            )
        );
        //Launch modal
        dispatch(openModal());
    };

    const openEditModal = () => {
        dispatch(setActionName("appearance-edit-logo"));
        dispatch(changeActionValues({ "logo-type": addLogo.version }));
        dispatch(
            setSectionContent({
                modalAddLogo: true,
                allowNullLogo: permissionNullLogo(addLogo.type, appearanceSelected.type, projectRef),
                themeType: themeType,
            })
        );
        dispatch(
            setModalContent(
                AppearanceAddLogo(
                    `${t(
                        themeType === THEME_TYPE_MOBILE
                            ? LOGOS_TYPES_MOBILE[addLogo.version]
                            : LOGOS_TYPES[addLogo.version]
                    )} - ${t("{{capitalize, capitalize}}", {
                        capitalize: t("edit-logo"),
                    })}`,
                    t("give-type-logo-name", {
                        type: t("{{capitalize, capitalize}}", { capitalize: t(addLogo.type) }),
                    }),
                    true,
                    cancelButton
                )
            )
        );
        //Launch modal
        dispatch(openModal());
    };

    const openDeleteModal = () => {
        dispatch(
            setModalContent(
                AppearanceDeleteLogo(
                    t(
                        themeType === THEME_TYPE_MOBILE
                            ? LOGOS_TYPES_MOBILE[actionData.values.version]
                            : LOGOS_TYPES[actionData.values.version]
                    ),
                    t("{{capitalize, capitalize}}", { capitalize: t("delete") }),
                    {
                        logoID: actionData.itemsAffected[0],
                    }
                )
            )
        );
        //Launch modal
        dispatch(openModal());
    };
};

export default AppearanceModalControl;
