import { useSelector } from "react-redux";
export default function useLangStings(string, translatable = true) {
    const { langStrings } = useSelector((state) => state.ui);
    if (Array.isArray(string) && translatable) {
        let _text = "";
        string.map((word) => {
            _text += langStrings[word] ? langStrings[word] : word;
            return null;
        });
        return _text;
    }
    if (translatable && langStrings[string]) {
        return langStrings[string];
    }
    return string;
}
