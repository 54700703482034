import React, { useEffect } from "react";
import { setColorPickerReference, setColorToApplyInTextEditor } from "../../../../../../actions/designActions";
import { useDispatch, useSelector } from "react-redux";
import { toggleCustomInlineStyle, getSelectionCustomInlineStyle } from "draftjs-utils";

const TextEditorColorPicker = ({ editorState, onChange, defaultColor }) => {
    const dispatch = useDispatch();

    const { colorPickerReference, colorToApplyInTextEditor } = useSelector((state) => state.design);

    const customStyle = getSelectionCustomInlineStyle(editorState, ["COLOR"]);
    const currentColor = customStyle && customStyle.COLOR ? customStyle.COLOR.replace("color-", "") : defaultColor;

    //Listeners
    useEffect(() => {
        if (colorToApplyInTextEditor) {
            const newState = toggleCustomInlineStyle(editorState, "color", colorToApplyInTextEditor);
            if (newState) {
                onChange(newState);
            }

            dispatch(setColorToApplyInTextEditor(null));
        }
        // eslint-disable-next-line
    }, [colorToApplyInTextEditor]);

    return (
        <div
            id="textEditor"
            onClick={(e) => {
                colorPickerReference
                    ? dispatch(setColorPickerReference(null))
                    : dispatch(
                          setColorPickerReference({
                              id: "textEditor",
                              property: "borderColor",
                              color: currentColor,
                          })
                      );
                e.stopPropagation();
            }}
            className={`mr-3 w-10 h-10 rounded-md cursor-pointer border border-gray-200 `}
            style={{
                width: "2rem",
                height: "2rem",
                marginTop: "1rem",
                marginLeft: ".75rem",
                marginBottom: "0",
                backgroundColor: currentColor,
            }}
        ></div>
    );
};

export default TextEditorColorPicker;
