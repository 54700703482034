export const CZCapacity = [
    "all",
    "areas",
    "area-sym-activity",
    "available",
    "cancel",
    "capacity",
    "capacityRange",
    "capacity-limit",
    "category",
    "current-capacity",
    "edit-current-capacity",
    "high",
    "input-error",
    "items",
    "last-update",
    "low",
    "medium",
    "monitor",
    "next",
    "occupation-percent",
    "ofo",
    "of-occupation",
    "people",
    "previous",
    "save",
    "search",
    "theres-not",
    "showing-all-results",
    "please",
    "save-before",
    "edit-anothera",
    "capacity",
    "capacity-perc",
];
