import React from "react";
import UseSectionHeader from "../../../../useSectionHeader";
import { useTranslation } from "react-i18next";
import UseTable from "../../../../Table/index";
import ListGrids from "../../../../../hooks/GraphqlCalls/Services/ListGrids";
import UpdateGridChannels from "../../../../../hooks/GraphqlCalls/Services/UpdateGridChannels";

const Grids = () => {
    const { t } = useTranslation();
    return (
        <>
            <UseSectionHeader title={t("grids_")}></UseSectionHeader>
            <UseTable />
            <ListGrids />
            <UpdateGridChannels />
        </>
    );
};

export default Grids;
