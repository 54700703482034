import { useEffect, useState } from "react";
import {
    setHeaderButtons,
    setCountPosition,
    setItemsPerpage,
    setTableResults,
    setBatchActive,
    setRefreshData,
    setCustomError,
    setSearchable,
    setCheckable,
    setLoading,
    setHeaders,
    setCount,
    setError,
    setCustomConfig,
    cleanCheckedItems,
    cleanSortCriteria,
    setTableType,
} from "../../../actions/tableActions";
import { POLLINTERVAL_15S } from "../../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import GetTableHeaders from "../../Utils/Table/Headers/GetServicesTableHeaders";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import { useTranslation } from "react-i18next";
import { getProjectLangs } from "../../Utils/SalesUtils";
import ChannelGroup from "./models/ChannelGroup";
import { changeActionValues } from "../../../actions/globalActions";
import { useAuth } from "hooks/Session/auth";

const ListGroupChannels = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { languages: projectLangs } = useAuth();
    const { currentPage, perPage, sortCriteria, filterQuery, refreshData, checkedItems, results } = useSelector(
        (state) => state.table
    );
    const { values } = useSelector((state) => state.action);
    const SECTION_NAME = "list-group-channels";
    const [langDefault, setLangDefault] = useState("en");

    const GET_GROUPS_CHANNELS = gql`
        query {
            tuneGroupSummary(page:${currentPage} , size:${perPage}
                ${sortCriteria ? sortCriteria : ""}
                ${filterQuery ? filterQuery : ""}
                ) {
              info {
                count
                page
                size
              }
              results {
                channelsNum
                gridsNum
                id
                information
                name
              }
            }
          }
          
    `;

    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useLazyQuery(GET_GROUPS_CHANNELS, {
        pollInterval: POLLINTERVAL_15S,
    });

    useEffect(() => {
        executeQuery();
        dispatch(setItemsPerpage(9));
        dispatch(setLoading(true));
        dispatch(setHeaders(GetTableHeaders(SECTION_NAME)));
        dispatch(setCustomError(t("no-shops-yet")));
        dispatch(setCountPosition("table-header"));
        dispatch(setBatchActive(generateBatchActions()));
        dispatch(setTableType(SECTION_NAME));
        dispatch(setSearchable(true));
        dispatch(setCheckable(true));
        dispatch(setCustomError(t("no-groups-yet")));
        dispatch(
            setCustomConfig({
                pagination: true,
            })
        );
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(setHeaderButtons(GetTableHeaderButton(SECTION_NAME, { lang: langDefault }, null, t)));
        // eslint-disable-next-line
    }, [langDefault]);

    useEffect(() => {
        if (!loading && data?.tuneGroupSummary?.results && networkStatus === 7) {
            const tableResults = arrangeData(data.tuneGroupSummary.results, t);
            dispatch(setTableResults(tableResults));
            dispatch(setLoading(false));
            dispatch(setCount(data.tuneGroupSummary.info.count));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (data?.tuneGroupSummary?.results) {
            setTimeout(() => {
                dispatch(setLoading(false));
            }, 2000);
        }
        //eslint-disable-next-line
    }, [loading, sortCriteria]);

    useEffect(() => {
        if (projectLangs) {
            const { langDefault } = getProjectLangs(projectLangs, t);
            setLangDefault(langDefault);
        }
        // eslint-disable-next-line
    }, [projectLangs]);

    useEffect(() => {
        if (data?.tuneGroupSummary?.results && !values && !values["channelsGroupWithGrids"]) {
            dispatch(
                changeActionValues({ channelsGroupWithGrids: getChannelsWithGrids(data.tuneGroupSummary.results) })
            );
        }
    }, [values, data]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
        }
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        if (sortCriteria === "" && data) {
            setTimeout(() => {
                dispatch(setLoading(false));
            }, 800);
        }
        //eslint-disable-next-line
    }, [sortCriteria]);

    useEffect(() => {
        if (refreshData && refetch) {
            dispatch(setLoading(true));
            dispatch(cleanSortCriteria());
            refetch();
            dispatch(setRefreshData(false));
            dispatch(cleanCheckedItems());
        }
        //eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        dispatch(setBatchActive(generateBatchActions()));
    }, [checkedItems]);

    const generateBatchActions = () => {
        let allCheckedDeletable = true;
        if (results) {
            results.forEach((element) => {
                if (checkedItems.includes(element.id)) {
                    if (!element.canBeDeleted) {
                        allCheckedDeletable = false;
                    }
                }
            });
        }
        if (checkedItems.length === 0) {
            allCheckedDeletable = false;
        }

        const actions = [
            {
                name: "delete",
                action: "delete-groups-channels",
                executeSectionQuery: true,
                noReadyToExecute: !allCheckedDeletable,
                modalInputs: [
                    {
                        type: "void",
                        adjust: "first-capital",
                        text: "delete-groups-confirm",
                    },
                    {
                        type: "deleteBatchChannelsDetail",
                        style: "",
                        elementsType: "group",
                        columnPos: 0,
                    },
                ],
                buttons: [
                    { name: "cancel", style: "white", action: "close" },
                    { name: "delete", style: "red", action: "delete-groups-channels" },
                ],
            },
        ];
        return actions;
    };

    return null;
};

export default ListGroupChannels;

const arrangeData = (data, t) => {
    const channelsGroups = [];
    const newData = data.map((c) => {
        return {
            id: c.id,
            name: c.name,
            gridsNum: c.gridsNum,
            channelsNum: c.channelsNum,
        };
    });
    if (newData) {
        newData.forEach((dataItem) => {
            channelsGroups.push(ChannelGroup(dataItem, t));
        });
    }
    return channelsGroups;
};

const getChannelsWithGrids = (dataGroupChannels) => {
    const channelsIdsWithGrids = [];

    dataGroupChannels.forEach((group) => {
        if (group.gridsNum !== 0) {
            channelsIdsWithGrids.push(group.id);
        }
    });

    return channelsIdsWithGrids;
};
