export const REMOVE_GRID_ITEM = "REMOVE_GRID_ITEM";
export const REMOVE_MENU_IN_CUSTOM_SCREENS = "REMOVE_MENU_IN_CUSTOM_SCREENS";
export const SET_GRID_LAYOUT = "SET_GRID_LAYOUT";
export const SET_ITEM_SELECTED = "SET_ITEM_SELECTED";
export const ADD_GRID_ITEM = "ADD_GRID_ITEM";
export const ADD_CONTAINER_ITEM = "ADD_CONTAINER_ITEM";
export const DUPLICATE_GRID_ITEM = "DUPLICATE_GRID_ITEM";
export const SET_DESIGN_TOOLBAR_VISIBLE = "SET_DESIGN_TOOLBAR_VISIBLE";
export const SET_WIDGET_TOOLBAR_VISIBLE = "SET_WIDGET_TOOLBAR_VISIBLE";
export const UPDATE_GRID_ITEM_PROPERTY = "UPDATE_GRID_ITEM_PROPERTY";
export const UPDATE_GRID_ITEM_STYLE = "UPDATE_GRID_ITEM_STYLE";
export const UPDATE_GRID_ITEM_ACTIONS = "UPDATE_GRID_ITEM_ACTIONS";
export const UPDATE_GRID_ITEM_POS = "UPDATE_GRID_ITEM_POS";
export const CLONING_GRID_ITEM = "CLONING_GRID_ITEM";
export const UPDATE_SCREEN_PROPERTY = "UPDATE_SCREEN_PROPERTY";
export const SET_SCREEN_CONTENTSTYLE = "SET_SCREEN_CONTENTSTYLE";
export const SET_CURRENT_SCREEN_DATA = "SET_CURRENT_SCREEN_DATA";
export const SET_CURRENT_LANG = "SET_CURRENT_LANG";
export const SET_CURRENT_DEVICE = "SET_CURRENT_DEVICE";
export const SET_DROPPING_ITEM = "SET_DROPPING_ITEM";
export const CLEAN_LAYOUT = "CLEAN_LAYOUT";
export const SET_EDITOR_MODE = "SET_EDITOR_MODE";
export const UPDATE_CONTAINER_ITEM_POS = "UPDATE_CONTAINER_ITEM_POS";
export const SET_CONTAINER_IN_EDITION = "SET_CONTAINER_IN_EDITION";
export const SET_COLOR_PICKER_REFERENCE = "SET_COLOR_PICKER_REFERENCE";
export const SET_TEXT_EDITOR_SELECTION_STYLES = "SET_TEXT_EDITOR_SELECTION_STYLES";
export const SET_COLOR_TO_APPLY_IN_TEXT_EDITOR = "SET_COLOR_TO_APPLY_IN_TEXT_EDITOR";
export const SET_WIDGET_TOOLBAR_ENABLED = "SET_WIDGET_TOOLBAR_ENABLED";
export const SET_TOAST_DATA = "SET_TOAST_DATA";
export const EDITING_MENU = "EDITING_MENU";
export const RESIZE_WINDOW_MENU = "RESIZE_WINDOW_MENU";
export const TYPE_MENU = "TYPE_MENU";
export const KEY_EVENT = "KEY_EVENT";
export const HIDDEN_MENU_VISIBLE = "HIDDEN_MENU_VISIBLE";
export const SET_WIDGET_MENU_COLORS_AND_ANIMATIONS = "SET_WIDGET_MENU_COLORS_AND_ANIMATIONS";
export const SET_TV_SCREENS_DATA = "SET_TV_SCREENS_DATA";
export const SET_PREVIEW_MENU_ID_SELECTED = "SET_PREVIEW_MENU_ID_SELECTED";

export function removeGridItem(itemId) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: REMOVE_GRID_ITEM,
            payload: itemId,
        });
    };
}

export function removeMenuInCustomScreens(itemId) {
    return (dispatch) => {
        dispatch({
            type: REMOVE_MENU_IN_CUSTOM_SCREENS,
            payload: itemId,
        });
    };
}

export function setGridLayout(layoutData) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: SET_GRID_LAYOUT,
            payload: layoutData,
        });
    };
}
export function setItemSelected(itemId) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: SET_ITEM_SELECTED,
            payload: itemId,
        });
    };
}
export function addGridItem(itemData, containerID) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: ADD_GRID_ITEM,
            payload: { itemData: itemData, containerID: containerID },
        });
    };
}
export function updateGridItemActions(itemId, property, value) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: UPDATE_GRID_ITEM_ACTIONS,
            payload: { itemId: itemId, property: property, value: value },
        });
    };
}
export function updateGridItemProperty(itemId, property, value) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: UPDATE_GRID_ITEM_PROPERTY,
            payload: { itemId: itemId, property: property, value: value },
        });
    };
}
export function updateGridItemStyle(itemId, property, value) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: UPDATE_GRID_ITEM_STYLE,
            payload: { itemId: itemId, property: property, value: value },
        });
    };
}
export function updateGridItemPos(itemId, pos) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: UPDATE_GRID_ITEM_POS,
            payload: { itemId: itemId, pos: pos },
        });
    };
}
export function updateContainerItemPos(containerId, itemId, pos) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: UPDATE_CONTAINER_ITEM_POS,
            payload: { containerId: containerId, itemId: itemId, pos: pos },
        });
    };
}
export function cloningGridItem(itemId) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: CLONING_GRID_ITEM,
            payload: itemId,
        });
    };
}

export function updateScreenProperty(property, value) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: UPDATE_SCREEN_PROPERTY,
            payload: { property: property, value: value },
        });
    };
}
export function setScreenContentStyle(value) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: SET_SCREEN_CONTENTSTYLE,
            payload: value,
        });
    };
}
export function addContainerItem(itemData) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: ADD_CONTAINER_ITEM,
            payload: itemData,
        });
    };
}
export function duplicateGridItem(itemId, newItemId) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: DUPLICATE_GRID_ITEM,
            payload: { cloneItemId: itemId, newItemId: newItemId },
        });
    };
}
export function setDesignToolbarVisible(itemId) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: SET_DESIGN_TOOLBAR_VISIBLE,
            payload: itemId,
        });
    };
}
export function setWidgetToolbarVisible(toolbarName, tabName = -1) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: SET_WIDGET_TOOLBAR_VISIBLE,
            payload: { name: toolbarName, tab: tabName },
        });
    };
}

export function setCurrentScreenData(data) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_SCREEN_DATA,
            payload: data,
        });
    };
}
export function setCurrentLang(lang) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_LANG,
            payload: lang,
        });
    };
}
export function setCurrentDevice(device) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_DEVICE,
            payload: device,
        });
    };
}
export function cleanLayout() {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: CLEAN_LAYOUT,
        });
    };
}
export function setDroppingItem(dropItem) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: SET_DROPPING_ITEM,
            payload: dropItem,
        });
    };
}
export function setEditorMode(mode) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: SET_EDITOR_MODE,
            payload: mode,
        });
    };
}
export function setContainerInEdition(containerId) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: SET_CONTAINER_IN_EDITION,
            payload: containerId,
        });
    };
}
export function setColorPickerReference(color) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: SET_COLOR_PICKER_REFERENCE,
            payload: color,
        });
    };
}
export function setTextEditorSelectionStyles(data) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: SET_TEXT_EDITOR_SELECTION_STYLES,
            payload: data,
        });
    };
}
export function setColorToApplyInTextEditor(color) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: SET_COLOR_TO_APPLY_IN_TEXT_EDITOR,
            payload: color,
        });
    };
}

export function setWidgetToolbarEnabled(enabled) {
    //Enable or disable widget toolbar in editor
    return (dispatch) => {
        dispatch({
            type: SET_WIDGET_TOOLBAR_ENABLED,
            payload: enabled,
        });
    };
}

export function setToastData(data) {
    return (dispatch) => {
        dispatch({
            type: SET_TOAST_DATA,
            payload: data,
        });
    };
}

export function setEditingMenu(value) {
    return (dispatch) => {
        dispatch({
            type: EDITING_MENU,
            payload: value,
        });
    };
}

export function setOnResizeWindowMenu(value) {
    return (dispatch) => {
        dispatch({
            type: RESIZE_WINDOW_MENU,
            payload: value,
        });
    };
}

export function setTypeMenu(value) {
    return (dispatch) => {
        dispatch({
            type: TYPE_MENU,
            payload: value,
        });
    };
}

export function setHiddenMenuVisible(value) {
    return (dispatch) => {
        dispatch({
            type: HIDDEN_MENU_VISIBLE,
            payload: value,
        });
    };
}

export function setWidgetMenuColorsAndAnimations(value) {
    return (dispatch) => {
        dispatch({
            type: SET_WIDGET_MENU_COLORS_AND_ANIMATIONS,
            payload: value,
        });
    };
}

export function setTvScreensData(value) {
    return (dispatch) => {
        dispatch({
            type: SET_TV_SCREENS_DATA,
            payload: value,
        });
    };
}

export function setPreviewMenuIdSelected(value) {
    return (dispatch) => {
        dispatch({
            type: SET_PREVIEW_MENU_ID_SELECTED,
            payload: value,
        });
    };
}
export function fireKeyEvent(event) {
    return (dispatch) => {
        dispatch({
            type: KEY_EVENT,
            payload: event,
        });
    };
}
