import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Components
import AppearanceHeadMenu from "./AppearancesHeadMenu";
import AppearancesToolbar from "./AppearancesToolbar";
import PreviewAppearance from "./PreviewAppearance";

//Hooks
import AppearancesQueries from "../../../../hooks/GraphqlCalls/Appearances/AppearancesQueries";
import LoadAppearance from "../../../../hooks/GraphqlCalls/Appearances/LoadAppearance";
import ListAppearances from "../../../../hooks/GraphqlCalls/Appearances/ListAppearances";

//Utils
import AppearanceModalControl from "../../../../hooks/GraphqlCalls/Appearances/Modals/AppearanceModalControl";
import { THEME_TYPES_ARR, THEME_TYPE_MOBILE, THEME_TYPE_DESIGN } from "hooks/Utils/AppearancesUtils";
import { setDesignToolbarVisible } from "actions/designActions";
import PreviewMobileAppearance from "./PreviewMobileAppearance";

const Appearances = () => {
    const dispatch = useDispatch();
    const { designToolbarVisible } = useSelector((state) => state.design);

    //states
    const [visibilityAppearancesList, setVisibilityAppareancesList] = useState(false);
    const [themeType, setThemeType] = useState(THEME_TYPES_ARR[0].TYPE);
    const [loadingListAppearances, setLoadingListAppearances] = useState(false);
    const [loadingAppearance, setLoadingAppearance] = useState(false);
    const { appearanceId } = useSelector((state) => state.sectionContent);

    // HOOKs
    ListAppearances({ themeType, setLoadingListAppearances });
    LoadAppearance({ themeType, setLoadingAppearance });
    AppearanceModalControl({ themeType });

    useEffect(() => {
        dispatch(setDesignToolbarVisible("colors"));
    }, []);

    useEffect(() => {
        if (themeType === THEME_TYPE_MOBILE && designToolbarVisible === "typography") {
            dispatch(setDesignToolbarVisible("colors"));
        }
    }, [themeType]);

    return (
        <>
            <AppearanceHeadMenu />
            <div className="flex w-full h-full">
                <AppearancesQueries />
                <AppearancesToolbar
                    setLoadingListAppearances={setLoadingListAppearances}
                    loadingListAppearances={loadingListAppearances}
                    loadingAppearance={loadingAppearance}
                    setVisibilityAL={setVisibilityAppareancesList}
                    visibilityAL={visibilityAppearancesList}
                    setThemeType={setThemeType}
                    themeType={themeType}
                />
                {appearanceId && themeType === THEME_TYPE_DESIGN && (
                    <PreviewAppearance visibilityAL={visibilityAppearancesList} themeType={themeType} />
                )}
                {appearanceId && themeType === THEME_TYPE_MOBILE && (
                    <PreviewMobileAppearance
                        loadingListAppearances={loadingListAppearances}
                        visibilityAL={visibilityAppearancesList}
                        loadingAppearance={loadingAppearance}
                    />
                )}
            </div>
        </>
    );
};

export default Appearances;
