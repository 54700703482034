import React from "react";
import { formatDate } from "./services/formatDate";
import { useTranslation } from "react-i18next";
import { Warning } from "./Warning";
import { NetworkHeader } from "./NetworkHeader";
import Icon from "components/Icon";

export const Traffic = ({ mdnsInfo, called, loading, networkInfo }) => {
    const { t } = useTranslation();
    const { anomalousTrafficData, lastUpdate, updateTimeMin } = mdnsInfo || networkInfo || {};
    const {
        ipListTrafficFromOtherNetwork,
        isRoutingToCastNetwork,
        isRoutingToGuestNetwork,
        isTrafficFromOtherNetwork,
        areUsers,
        areCCs,
    } = anomalousTrafficData || {};
    const lastUpdateDate = formatDate(lastUpdate);

    const criticalWarningText = isRoutingToGuestNetwork ? t("routing-alert-security") : t("routing-alert-guest");
    const [firstNetwork, secondNetwork] = isRoutingToGuestNetwork ? ["cast", "user"] : ["user", "cast"];
    const noUserDataText = areUsers === false && "no-user-data";
    const noCastDataText = areCCs === false && "no-cast-data";

    if (loading || !called) return null;

    return (
        <div className={`bg-white`}>
            <section className={`p-4`}>
                <NetworkHeader
                    title="network-traffic"
                    lastUpdateDate={lastUpdateDate}
                    updateTimeMin={updateTimeMin}
                    adjustLastUpdate={"mr-4"}
                />
                <section className="mb-10">
                    {areCCs || areUsers ? (
                        <>
                            {isTrafficFromOtherNetwork && (isRoutingToCastNetwork || isRoutingToGuestNetwork) ? (
                                <>
                                    <Warning
                                        text={t("routing-alert", {
                                            firstNet: firstNetwork,
                                            secondNet: secondNetwork,
                                        })}
                                        color={"red"}
                                        ml={4}
                                    />
                                    <span className="ml-10 mt-2 text-gray-800 w-2/3 block">{criticalWarningText}</span>
                                </>
                            ) : !isTrafficFromOtherNetwork && !isRoutingToCastNetwork && !isRoutingToGuestNetwork ? (
                                <p className="mx-4 text-gray-700">{t("anomalous-traffic")}</p>
                            ) : isRoutingToCastNetwork || isRoutingToGuestNetwork ? (
                                <>
                                    <Warning
                                        text={t("routing-alert", {
                                            firstNet: firstNetwork,
                                            secondNet: secondNetwork,
                                        })}
                                        color={"red"}
                                        ml={4}
                                    />
                                    <span className="ml-10 mt-2 text-gray-800 w-2/3 block">{criticalWarningText}</span>
                                </>
                            ) : null}
                        </>
                    ) : (
                        <NoTrafficData text={`${noUserDataText || noCastDataText}`} />
                    )}
                    {isTrafficFromOtherNetwork ? (
                        <>
                            <div className="flex mt-4  ml-1 flex-col">
                                <Warning text={t("traffic-detected")} color="orange" />
                                <ul id="another-traffic-detected">
                                    {ipListTrafficFromOtherNetwork?.map((warning) => (
                                        <li className="text-gray-800 ml-10" key={warning}>
                                            - {t("ip")}: {warning}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </>
                    ) : null}
                </section>
            </section>
        </div>
    );
};
const NoTrafficData = ({ text }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="ml-6 mb-4">
                <div className="flex items-center gap-2">
                    <Icon type="info" size="xl" />
                    <span className="text-gray-800">{t(text)}</span>
                </div>
                <p className="ml-8 text-gray-800">{t("monitor-network-properly")}</p>
            </div>
        </>
    );
};
