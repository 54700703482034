import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import LogosSidebar from "./Sidebar/LogosSidebar";
import TypographySidebar from "./Sidebar/TypographySidebar";
import ColourSidebar from "./Sidebar/ColourSidebar";
import {
    LOGOS_TOOLBAR,
    TYPOGRAPHY_TOOLBAR,
    COLOUR_TOOLBAR,
    THEME_TYPE_MOBILE,
} from "../../../../hooks/Utils/AppearancesUtils";
import ReactTooltip from "components/ReactTooltip";

const AppearancesToolbarLoadSidebar = ({ themeType, loadingAppearance }) => {
    const { t } = useTranslation();
    const { designToolbarVisible } = useSelector((state) => state.design);
    const { appearanceSelected } = useSelector((state) => state.sectionContent);
    const [blocked, setBlocked] = useState(false);

    const lockCloseIcon = () => <span className="inline icon-lock-close mr-2 my-auto text-3xl"></span>;

    const loadSideBar = () => {
        switch (designToolbarVisible) {
            case LOGOS_TOOLBAR:
                return <LogosSidebar themeType={themeType} />;
            case TYPOGRAPHY_TOOLBAR:
                return <TypographySidebar />;
            case COLOUR_TOOLBAR:
                return <ColourSidebar themeType={themeType} loadingAppearance={loadingAppearance} />;
            default:
                return <ColourSidebar themeType={themeType} loadingAppearance={loadingAppearance} />;
        }
    };

    useEffect(() => {
        if (appearanceSelected) {
            switch (designToolbarVisible) {
                case LOGOS_TOOLBAR:
                    setBlocked(
                        appearanceSelected && appearanceSelected.locks && appearanceSelected.locks.LOGOS_TOOLBAR
                    );
                    break;
                case TYPOGRAPHY_TOOLBAR:
                    setBlocked(
                        appearanceSelected && appearanceSelected.locks && appearanceSelected.locks.TYPOGRAPHY_TOOLBAR
                    );
                    break;
                case COLOUR_TOOLBAR:
                    setBlocked(
                        appearanceSelected && appearanceSelected.locks && appearanceSelected.locks.COLOUR_TOOLBAR
                    );
                    break;
                default:
                    setBlocked(
                        appearanceSelected && appearanceSelected.locks && appearanceSelected.locks.COLOUR_TOOLBAR
                    );
                    break;
            }
        }
    }, [designToolbarVisible, appearanceSelected]);

    return (
        <div
            data-id="toolbarAppearance"
            id={`${designToolbarVisible}_toolbar`}
            className=" bg-white border border-gray-200 overflow-hidden h-full  float-left absolute collapseAnimation "
            style={{
                height: "90%",
                width: "29rem",
                top: "5.5rem",
                left: "4.65rem",
            }}
        >
            <div className={`contents left-0 z-200 bg-white h-full`}>
                <div className="border-b text-base border-gray-200 py-4 px-8 flex items-center justify-between align-middle">
                    {blocked && lockCloseIcon()}
                    <div className="first-capital flex relative font-bold mr-auto">
                        <span className=" block mr-2 text-gray-800">
                            {t("{{capitalize, capitalize}}", {
                                capitalize: t(designToolbarVisible ? designToolbarVisible : "colors"),
                            })}
                        </span>
                        {themeType === THEME_TYPE_MOBILE && designToolbarVisible === "logos" ? (
                            <i
                                data-tip={t("it-is-recommended-to-add-the-2-types-of-logo")}
                                onMouseOver={ReactTooltip.rebuild}
                                className=" icon icon-info text-2xl "
                            ></i>
                        ) : null}
                    </div>
                </div>
                {loadSideBar()}
            </div>
        </div>
    );
};

export default AppearancesToolbarLoadSidebar;
