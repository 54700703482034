const DuplicateContent = (t) => {
    return {
        inputs: [
            {
                type: "void",
                text: `${t("duplicate-screen-text")}.`,
            },
            {
                type: "duplicateContent",
            },
        ],
    };
};

export default DuplicateContent;
