import { Session } from "../../../Utils/Session";

const NewChannelModalGroup = (t, data) => {
    let name = Session.getSessionProp("groupChannelName");

    if (data && data.nameGroup) {
        name = data.nameGroup;
    }
    return {
        title: "add-channel",
        text: `${t("select-the-channels-in-group", { group: name })}`,
        executeSectionQuery: true,
        bodyAdjust: "w-10/12",
        noTranslateText: true,
        inputs: [
            {
                type: "addChannelToGroupChannel",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "add",
                style: "blue",
                action: "add-channel-to-group-channel",
            },
        ],
    };
};

export default NewChannelModalGroup;
