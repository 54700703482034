import React from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table";
//Utils
import ListContractedProducts from "../../../hooks/GraphqlCalls/Info/ListContractedProducts";

const ContractedProducts = () => {
    ListContractedProducts();
    return (
        <>
            <UseSectionHeader title={"contracted-products"} />
            <UseTable />
        </>
    );
};

export default ContractedProducts;
