import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import { useUpdateSignage } from "../graphql/useSignages";
import { toast } from "react-toastify";
import Button from "components/Button";
import TextInput from "components/TextInput";
import Loading from "components/Loading";

const ModalChangeName = (props) => {
    const { t } = useTranslation();
    const [name, setName] = useState(props.name);
    const [loading, setLoading] = useState(false);
    const { updateSignage } = useUpdateSignage({
        onCompleted: () => {
            setLoading(false);
            props.getSignages();
            props.close();
        },
    });

    const handleSaveClick = () => {
        if (name) {
            setLoading(true);
            updateSignage({ variables: { id: props.id, name } });
        } else {
            toast.error(t("errors-in-red"));
        }
    };

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={`${props.name} - ${t("edit-name")}`}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-save" disabled={loading} onClick={handleSaveClick}>
                        {t("save")}
                    </Button>
                </>
            }
            className={"w-5/12 p-10"}
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <div className="flex flex-col gap-2">
                    <p className=" font-bold">{t("name")}*</p>
                    <p>{t("write-the-name-of-the-sequence")}</p>
                    <TextInput
                        id="sequence-input-name"
                        error={!name}
                        value={name}
                        onChange={(val) => {
                            setName(val);
                        }}
                    ></TextInput>
                </div>
            )}
        </Modal>
    );
};

export const UseModalChangeName = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalChangeName {...newProps} />);
        },
    };
};
