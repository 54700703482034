const BookingCategories = (category, displayedCategories, sortCriteria) => {
    const dataType = "area-booking";

    const { acceptedBookings, pendingBookings, id, ref, name, parentCategoryName, bookingAllowed, currentStatus } =
        category;
    return {
        dataType: dataType,
        id: id,
        category: true,
        categoryName: getCategoryName(sortCriteria, parentCategoryName),
        info: [
            {
                name: "area",
                cellContent: getAreaCellType(bookingAllowed, currentStatus),
                value: name,
                subvalue: sortCriteria === "" ? null : category.parentCategoryName,
                link: `/common-zones/booking/categories/area/${ref}`,
                specialWidth: "w-4/12 break-all",
            },
            {
                name: "booking-confirmed",
                cellContent: "value",
                value: acceptedBookings,
                sufix: "confirmeda",
                translateSufix: true,
                adjust: "success",
                hidden: getConfirmedVisibility(acceptedBookings),
                specialWidth: "w-4/12",
            },
            {
                name: "booking-pending",
                cellContent: "value",
                value: pendingBookings,
                sufix: "pending",
                translateSufix: true,
                hidden: getPendingVisibility(pendingBookings),
                specialWidth: "w-4/12",
            },
            {
                name: "manage-bookings",
                cellContent: "link",
                value: id,
                translate: true,
                link: `/common-zones/booking/categories/area/${ref}`,
                hidden: getManageVisibility(bookingAllowed, currentStatus),
                specialWidth: "w-4/12",
            },
        ],
    };
};

export const getCategoryName = (sortCriteria, parentCategoryName) => {
    //Returns false if sort criteria active to hide row groups
    let res;
    if (sortCriteria === "") {
        res = parentCategoryName;
    } else {
        res = false;
    }
    return res;
};

export const getAreaCellType = (bookingAllowed, currentStatus) => {
    //Returns cell type
    let res;
    if (!bookingAllowed || !currentStatus) {
        res = "text";
    } else {
        res = "link";
    }
    return res;
};

export const getConfirmedVisibility = (acceptedBookings) => {
    //Return true of no bookings accepted
    let res;
    if (acceptedBookings === 0) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const getPendingVisibility = (pendingBookings) => {
    //Return true of no bookings pending
    let res;
    if (pendingBookings === 0) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const getManageVisibility = (bookingAllowed, currentStatus) => {
    let res;
    if (!bookingAllowed || !currentStatus) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export default BookingCategories;
