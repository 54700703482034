// navigationUtils.js
import { createBrowserHistory } from "history";
import { validateSection } from "../../../../hooks/Utils/Validations";

const history = createBrowserHistory();

export const navToPrevious = () => {
    history.back();
};

export const updateCustomize = ({ actionData, sectionName, setCustomize }) => {
    if (actionData && actionData[`${sectionName}-customize-each-floor`]) {
        setCustomize(true);
    } else {
        setCustomize(false);
    }
};

export const addFloorCommon = ({
    actionType,
    customize,
    values,
    t,
    dispatch,
    toast,
    wingLastFloorPos,
    wingPrefix,
    id,
    setActionValidations,
    capitalizeFirst,
    showGlobalLoading,
    setExecuteQuery,
}) => {
    let floorsData = [];
    const validations = validateSection(`${actionType}${customize ? "-customize" : ""}`, values, t, {
        floorsNumber: values.floorsNumber,
    });

    dispatch(setActionValidations(validations));
    const validationsArray = Object.values(validations);

    if (validationsArray.filter((item) => item === false).length > 0) {
        toast.error(t("errors-in-red"));
        return;
    }

    for (let index = 0; index < values.floorsNumber; index++) {
        const suffix = customize ? `_${index + 1}` : "";
        let locationsData = [];

        if (values[`locations${suffix}`]) {
            for (let i = 1; i <= parseInt(values[`tvs-per-room${suffix}`]); i++) {
                const nestedSuffix = `_${i}`;
                locationsData.push({
                    name: values[`location${suffix}${nestedSuffix}`],
                    cec: values[`location-cec${suffix}${nestedSuffix}`],
                });
            }
        }

        floorsData.push({
            name: customize
                ? values[`floor-name${suffix}`]
                : `${capitalizeFirst(t("floor"))} ${parseInt(wingLastFloorPos + index + 1)}`,
            numberOfRooms: customize ? values[`number-of-rooms${suffix}`] : values[`number-of-rooms`],
            starterRoom: customize
                ? values[`starter-room${suffix}`]
                : parseInt(`${wingPrefix ? wingPrefix : ""}${wingLastFloorPos + index + 1}01`),
            Locations: locationsData,
            order: customize ? values[`order${suffix}`] : parseInt(wingLastFloorPos + index + 1),
        });
    }

    dispatch(showGlobalLoading(true));
    dispatch(
        setExecuteQuery({
            action: actionType,
            params: { wingId: id, floorsData: floorsData },
        })
    );
};
