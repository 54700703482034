/*
    Returns the time that has passed from now to given date with format
*/
const DateAgo = (date) => {
    const timeConversion = (millisec) => {
        let minutes = (millisec / (1000 * 60)).toFixed(0);
        let hours = (millisec / (1000 * 60 * 60)).toFixed(0);
        let days = (millisec / (1000 * 60 * 60 * 24)).toFixed(0);
        let res = {};

        if (Number(minutes) < 60) {
            res = { minutes: minutes };
        } else if (Number(hours) < 24) {
            res = { hours: hours };
        } else {
            res = { days: days };
        }

        return res;
    };

    const currentDate = window.ZDate.now();

    return timeConversion(currentDate - date.getTime());
};

export default DateAgo;
