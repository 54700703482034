import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

//Components
import UseButton from "../../../../useButton";
//Actions
import { setExecuteQuery } from "../../../../../actions/sectionActions";
import { setWidgetToolbarVisible } from "../../../../../actions/designActions";
//Utils
import { translate } from "../../../../../hooks/Utils/Utils";
import { EditorContext, WidgetContext } from "../../../../../contexts/editor";

import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Widget from "../Widget";

const HTMLToolbar = ({ deviceSpecific }) => {
    const { itemSelected } = useContext(EditorContext);
    const { id: widgetID } = useContext(WidgetContext);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    //Params
    const { lang } = useParams();

    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { gridItems, widgetToolbarVisible } = useSelector((state) => state.design);
    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const sectionName = `toolbar`;

    //States
    const [cItem, setCItem] = useState();

    useEffect(() => {
        if (widgetToolbarVisible === "html-settings" && itemSelected === widgetID) {
            const gItem = gridItems.filter((gridItem) => gridItem.i === widgetID)[0];
            setCItem(gItem);

            document.querySelector(`#textarea_${widgetID}`).value =
                gItem && gItem.data && gItem.data.code
                    ? gItem.data.code[lang]
                        ? gItem.data.code[lang]
                        : gItem.data.code.text
                        ? gItem.data.code.text
                        : ""
                    : "";
        }
        // eslint-disable-next-line
    }, [widgetToolbarVisible]);

    const updateHtmlCode = () => {
        dispatch(
            setExecuteQuery(
                Widget.UpdateData({
                    widget,
                    property: "code",
                    value: { [lang]: document.querySelector(`#textarea_${widgetID}`).value },
                })
            )
        );
    };

    return (
        <>
            <div
                id={`${sectionName}-settings`}
                className={`icon icon-settings-editor cursor-pointer p-2 ${
                    widgetToolbarVisible === "html-settings" ? "toolbarOptionSelected" : ""
                }`}
                onClick={(e) =>
                    dispatch(setWidgetToolbarVisible(widgetToolbarVisible === "html-settings" ? null : "html-settings"))
                }
            ></div>
            {widgetToolbarVisible === "html-settings" ? (
                <div
                    id={`widgetOptionContainer_${widgetID}`}
                    className={`widgetOptionContainer invisible`}
                    style={{ width: "30rem" }}
                >
                    <div className="first-capital font-bold mb-8">{translate("add-code", langStrings)}</div>
                    <div className="first-capital mb-4">{translate("add-code-here", langStrings)}:</div>
                    <textarea id={`textarea_${widgetID}`} className="w-full bg-gray-200 rounded mb-4 p-4 " rows="10" />

                    <UseButton
                        buttonName={cItem && cItem.data && cItem.data.code ? "update" : "apply"}
                        buttonColor={"btn-blue"}
                        adjust="inline-flex float-right"
                        action={updateHtmlCode}
                    />
                </div>
            ) : null}
            <Toolbar type="style">
                <Settings.Border t={t} />
            </Toolbar>
            {/* <Toolbar type="clone" /> */}
            <Toolbar type="delete" />
        </>
    );
};

export default HTMLToolbar;
