import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
//Components
import UseButton from "../useButton";
import UseInputCheckboxes from "./useInputCheckboxes";
import UseInputText from "./useInputText";
import UseInputMail from "./useInputMail";
//Actions
import { setLoading, setRefreshData } from "../../actions/tableActions";
import { showRowForm } from "../../actions/sectionActions";
import { cleanAction } from "../../actions/globalActions";
import { openModal, setModalContent } from "../../actions/uiActions";
//Utils
import { toast } from "react-toastify";
import { Session } from "../../hooks/Utils/Session";
import { escapeSpecialChars } from "../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";

const useCZFormStaffCreate = () => {
    const { t } = useTranslation();
    //Store data
    const { rowFormContent } = useSelector((state) => state.table);
    const { actionData } = useSelector((state) => state.action);
    const { langStrings } = useSelector((state) => state.ui);

    //Validation States
    const [validationName, setValidationName] = useState(true);
    const [validationEmail, setValidationEmail] = useState(true);
    const [validationRoles, setValidationRoles] = useState(true);

    //Data
    const getProjects = () => {
        return Session.getProject()?.ref;
    };
    const projects = getProjects();

    //API
    const MUTATION = gql`
  mutation {
    createStaffUser(
      accountName: "${getMutationEmail(actionData)}",
      fullName: "${escapeSpecialChars(getMutationFullName(actionData))}",
      projects: "${getMutationProjects(projects)}",
      roles: [${getMutationRoles(actionData)}]
      )
    {error id ok}}`;

    const [createStaffUser, { data }] = useMutation(MUTATION, {
        onError({ graphQLErrors }) {
            let is409Error = false;
            dispatch(setLoading(false));
            if (graphQLErrors) {
                // eslint-disable-next-line
                graphQLErrors.map((gError) => {
                    if (gError.message.includes("409")) {
                        is409Error = true;
                    }
                });
            }
            toast.error(langStrings[is409Error ? "this email is already registered in the system" : "mutation-error"]);
        },
    });

    //Actions
    const dispatch = useDispatch();

    const rowFormClickHandler = (e) => {
        const actionName = e.currentTarget.dataset.action;

        //Validation
        let validatedName = true;
        let validatedEmail = true;
        let validatedRoles = true;

        if (actionName !== "save") {
            dispatch(showRowForm(false));
            dispatch(cleanAction());
        } else {
            if (!actionData) {
                setValidationName(false);
                setValidationEmail(false);
                setValidationRoles(false);
                validatedName = false;
                validatedEmail = false;
                validatedRoles = false;
            } else {
                //Validate Name
                validatedName = getValidationName(actionData);
                setValidationName(validatedName);
                //Validate Email (already controlled by html input)
                validatedEmail = getValidationEmail(actionData);
                setValidationEmail(validatedEmail);

                validatedRoles = getValidationRoles(actionData);
                setValidationRoles(validatedRoles);
            }

            if (isAllValidated(validatedName, validatedEmail, validatedRoles)) {
                dispatch(setLoading(true));
                createStaffUser();
            } else {
                toast.error(t("error-staff-create"));
            }
        }
    };

    //Listeners

    useEffect(() => {
        if (isCreatedOk(data)) {
            toast.success(langStrings["success-create-staff"].replace("%0", actionData.name));
            //Add to view
            dispatch(setRefreshData(true));
            dispatch(showRowForm(false));
            dispatch(cleanAction());
            dispatch(setModalContent(getConfirmationModal(actionData, langStrings)));
            dispatch(openModal(true));
        } else if (isCreatedError(data)) {
            toast.error(langStrings["error-mutation"]);
        }
        // eslint-disable-next-line
    }, [data]);

    console.log(rowFormContent.inputs);

    return (
        <>
            {rowFormContent ? (
                <div className="w-full bg-white border-b border-gray-200 h-16 flex items-center">
                    {rowFormContent.inputs.map((input, index) =>
                        input.name === "name" ? (
                            <div className="inline-block w-3/12 pl-4 pr-4" key={index}>
                                <UseInputText
                                    id={"form-staff-create-input-text"}
                                    inputData={input}
                                    validation={validationName}
                                />
                            </div>
                        ) : input.name === "email" ? (
                            <div className="inline-block w-3/12 pr-4" key={index}>
                                <UseInputMail
                                    id={"form-staff-create-input-email"}
                                    inputData={input}
                                    validation={validationEmail}
                                />
                            </div>
                        ) : input.name === "role" ? (
                            <div className="inline-block w-3/12 pr-4" key={index}>
                                <UseInputCheckboxes
                                    id={"form-staff-create-input-select-role"}
                                    validation={validationRoles}
                                />
                            </div>
                        ) : null
                    )}
                    <div className="w-3/12 text-right inline-block whitespace-no-wrap">
                        {rowFormContent.buttons.map((button, index) => (
                            <div
                                className={`inline-block ${index === 0 ? "pr-2" : ""} whitespace-normal`}
                                data-action={button.name}
                                key={index}
                                onClick={rowFormClickHandler}
                            >
                                <UseButton
                                    buttonName={button.name}
                                    id={`form-staff-create-${button.name}`}
                                    buttonColor={button.color}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="t-actions-space"></div>
                </div>
            ) : null}
        </>
    );
};

export const getConfirmationModal = (actionData, langStrings) => {
    let roles = actionData.roles.includes("1") ? langStrings["cleaning"] : "";

    if (actionData.roles.includes("2")) {
        if (actionData.roles.includes("1")) {
            roles += ` ${langStrings["and"]} ${langStrings["capacity"]}`;
        } else {
            roles += langStrings["capacity"];
        }
    }

    //Returns confirmation modal content
    return {
        title: actionData.name,
        titleSufix: `- ${langStrings ? langStrings["new-user-created"] : "new-user-created"}`,
        inputs: [
            {
                name: actionData.name,
                type: "void",
                text: langStrings["success-staff-roles"].replace("%0", actionData.name).replace("%1", roles),
            },
            {
                name: actionData.name,
                type: "void",
                text: langStrings["email-sent-asap"].replace("%0", actionData.email),
            },
        ],
        buttons: [
            {
                name: "accept",
                style: "blue",
                action: "close",
            },
        ],
    };
};

export const getCreatedRoles = (createdRoles) => {
    //Returns preloaded existing roles
    let res = [];
    // eslint-disable-next-line
    createdRoles.map((role) => {
        res.push({ id: Number(role) });
    });
    return res;
};

export const getValidationName = (actionData) => {
    let res;
    if (actionData && actionData.name && actionData.name !== "") {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const getValidationEmail = (actionData) => {
    let res;

    if (actionData && actionData.email && actionData.email.indexOf("@") > -1 && actionData.email.indexOf(".") > -1) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const getValidationRoles = (actionData) => {
    let res;
    if (actionData && actionData.roles && actionData.roles.length > 0) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const isCreatedOk = (data) => {
    let res;
    if (data && data.createStaffUser && data.createStaffUser.error === "") {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const isCreatedError = (data) => {
    let res;
    if (data && data.createStaffUser && data.createStaffUser.error !== "") {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const isAllValidated = (validatedName, validatedEmail, validatedRoles) => {
    let res;
    if (validatedName && validatedEmail && validatedRoles) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const getMutationRoles = (actionData) => {
    let res;
    if (actionData && actionData.roles) {
        res = actionData.roles.join(",");
    } else {
        res = "";
    }
    return res;
};

export const getMutationProjects = (projects) => {
    let res;
    if (projects) {
        res = projects;
    } else {
        res = "";
    }
    return res;
};

export const getMutationFullName = (actionData) => {
    let res;
    if (actionData && actionData.name) {
        res = actionData.name.replace('"', '\\"');
    } else {
        res = "";
    }
    return res;
};

export const getMutationEmail = (actionData) => {
    let res;
    if (actionData && actionData.email) {
        res = actionData.email.replace('"', '\\"');
    } else {
        res = "";
    }
    return res;
};

export default useCZFormStaffCreate;
