import { THEME_TYPE_MOBILE } from "hooks/Utils/AppearancesUtils";

export const getMobileAppearancesQuery = (themeID) => {
    return `{
        data: themes(filter:{themeType:${THEME_TYPE_MOBILE}})  {
            info {
                count
            }
            results {
                id
                name
                category:type
            }
        }
        ${
            themeID
                ? `selected: themes(filter:{id:${themeID}}){
            results {
                id
            }
        }`
                : ""
        }
         
    }`;
};
