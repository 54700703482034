import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate as useHistory } from "react-router-dom";

//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { motion, AnimatePresence } from "framer-motion";
import { setRefreshData, setActiveFilters, getFilterQuery, setPage, setFolder } from "../../actions/tableActions";
import { useTranslation } from "react-i18next";
import { POLLINTERVAL_15S } from "../../hooks/Utils/Utils";
import { useLocation } from "react-router-dom";
import _ from "lodash";

import { useLibraryParams } from "../Section/MediaLibrary/MediaLibrary";

export default function MediaLibrarySidebar() {
    //Params
    const { parentRef, sideBarRef } = useLibraryParams();
    const location = useLocation();
    const locationPath = location?.pathname ? location.pathname : null;
    const history = useHistory();

    //States
    const [menuItems, setMenuItems] = useState(null);
    const [selectedItem, setItemSelected] = useState(null);
    let sideBar = useRef(sideBarRef);
    const activeFilters = useSelector((state) => state.table.activeFilters);
    const defaultFolder = { name: "all", value: "", key: "type" };

    const [disableTooltip, setDisableTooltip] = useState(false);

    //Store data
    const { refreshData, folder } = useSelector((state) => state.table);
    const { t } = useTranslation();

    const GET_TREE = gql`
        {
            libraryTree(ref: "root") {
                response {
                    fullPath
                    name
                    ref
                    tree {
                        fullPath
                        name
                        ref
                        tree {
                            fullPath
                            name
                            ref
                            tree {
                                fullPath
                                name
                                ref
                                tree {
                                    fullPath
                                    name
                                    ref
                                }
                            }
                        }
                    }
                }
            }
        }
    `;

    const [executeQuery, { data }] = useLazyQuery(GET_TREE, {
        pollInterval: POLLINTERVAL_15S,
    });

    //Actions
    const dispatch = useDispatch();

    //Listeners
    useEffect(() => {
        const wifiLink = document.getElementById("WIFIMANAGER_LINK");
        const zafiroTvLink = document.getElementById("ZAFIROTV_LINK");

        const mainSideBar = document.querySelector("#main_sidebar_nav");
        if (mainSideBar) {
            mainSideBar.classList.add("library-sidebar");
        }

        if (wifiLink) {
            wifiLink.style.display = "none";
        }
        if (zafiroTvLink) {
            zafiroTvLink.style.display = "none";
        }
        executeQuery();
        return () => {
            if (mainSideBar) {
                mainSideBar.classList.remove("library-sidebar");
            }
            const wifiLink = document.getElementById("WIFIMANAGER_LINK");
            const zafiroTvLink = document.getElementById("ZAFIROTV_LINK");
            if (wifiLink) {
                wifiLink.style.display = "block";
            }
            if (zafiroTvLink) {
                zafiroTvLink.style.display = "block";
            }
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data && data.libraryTree && data.libraryTree.response) {
            let menuItemsArray = [];
            sortFoldersByName(data.libraryTree.response).map((content) =>
                menuItemsArray.push({
                    name: content.name,
                    ref: content.ref,
                    collapsed: false,
                    tree: content.tree,
                })
            );
            menuItemsArray = [
                {
                    name: t("{{capitalize, capitalize}}", { capitalize: t("fonts") }),
                    ref: "fonts",
                    collapsed: false,
                    tree: [],
                },
                {
                    name: t("{{capitalize, capitalize}}", { capitalize: t("media") }),
                    ref: "root",
                    collapsed: false,
                    tree: menuItemsArray,
                },
            ];
            setMenuItems(menuItemsArray);
        }

        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (folder) {
            dispatch(setPage(Number(1)));
        }
        // eslint-disable-next-line
    }, [folder]);

    useEffect(() => {
        if (locationPath === "/design/library/root/media" || locationPath === "/design/library") {
            setItemSelected("root");
        } else if (locationPath === "/design/library/fonts/fonts") {
            setItemSelected("fonts");
        } else {
            setItemSelected(null);
        }
    }, [locationPath]);

    useEffect(() => {
        if (refreshData) {
            executeQuery();
            //dispatch(setRefreshContentData(false));
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    const sortFoldersByName = (folders) => {
        return folders.sort((a, b) => a.name.localeCompare(b.name));
    };

    const navigateTo = (ref, newSideBar = null) => {
        if (ref === "fonts") {
            history(`/design/library/fonts/fonts`);
            return;
        }
        sideBar.current = newSideBar !== "fonts" ? "media" : "fonts";
        history(`/design/library/${ref !== "" ? ref + "/" : ""}${sideBar.current}`);
    };

    const showTooltip = (e) => {
        if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) {
            setDisableTooltip(false);
        } else {
            setDisableTooltip(true);
        }
    };

    async function getObject(theObject, keyToFind, valueToFind, actualPath) {
        let result = null;
        if (theObject instanceof Array) {
            for (let i = 0; i < theObject.length; i++) {
                result = await getObject(theObject[i], keyToFind, valueToFind, actualPath);
                if (result) {
                    actualPath = `[${i}]${actualPath}`;
                    result.path = actualPath;
                    break;
                }
            }
        } else {
            for (let prop in theObject) {
                if (prop === keyToFind) {
                    if (theObject[prop] === valueToFind) {
                        return { object: theObject, path: actualPath };
                    }
                }
                if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                    actualPath += `.${prop}`;
                    result = await getObject(theObject[prop], keyToFind, valueToFind, actualPath);
                    if (result) {
                        break;
                    }
                }
            }
        }
        return result;
    }

    const clearSearch = () => {
        dispatch(setFolder(defaultFolder));
        dispatch(setPage(Number(1)));
        dispatch(setActiveFilters("search", ""));
        dispatch(
            getFilterQuery({
                ...activeFilters,
                search: "",
            })
        );
    };

    const handleCollapsible = async (ref) => {
        let items = [...menuItems];
        let elementFound = await getObject(items, "ref", ref, "");
        if (elementFound) {
            let element = elementFound.object;
            if (typeof _.get(items, elementFound.path) != "undefined") {
                element.collapsed = !element.collapsed;
                setMenuItems(items);
            }
        }
    };

    const _renderFolders = () => {
        let response = [];

        if (menuItems != null && menuItems.length > 0) {
            let folders = [];
            folders.push(
                <Fragment key="list-media-library-back-link">
                    <li key={"/design"}>
                        <NavLink
                            className="sidebar-menu-link flex items-center first-capital"
                            key={"/design"}
                            to={`/design/library/menu`}
                        >
                            <div className="flex items-center w-full justify-between relative">
                                <div className="flex items-center">
                                    <i className={`w-2/12 icon-chevron-left inline-block hover:text-blue-200 pl-4`}></i>
                                    <span className="inline-block ml-5 first-capital">{t("back-to-menu")}</span>
                                </div>
                            </div>
                        </NavLink>
                    </li>
                    <div className="text-gray-900 font-bold px-10 py-4 ">
                        {t("{{capitalize, capitalize}}", { capitalize: t("folders") })}
                    </div>
                </Fragment>
            );

            // eslint-disable-next-line
            menuItems.map((item, index) => {
                let nested_folders = [];
                if (item.tree !== null && item.tree.length > 0) {
                    nested_folders = _renderNested(nested_folders, item.tree);
                }
                folders.push(
                    <Fragment key={item.ref}>
                        <div className={`sidebar-menu-link cursor-pointer flex items-center py-0 my-0`}>
                            <div
                                className={`flex w-full items-center pr-5 pl-3 justify-between relative pb-2 pt-2 ${
                                    item.ref === "fonts" ? "mb-4" : "mb-2"
                                } truncate ${
                                    item.ref === selectedItem ? "text-blue-300 font-black rounded bg-gray-300" : null
                                }`}
                                id={`folder-${item.ref}`}
                                onClick={() => {
                                    clearSearch();
                                    setItemSelected(item.ref);
                                    navigateTo(`${item.ref}`, item.name);
                                }}
                            >
                                <div className="flex w-full items-center whitespace-nowrap truncate">
                                    <div
                                        className="flex w-full items-center whitespace-nowrap truncate"
                                        onClick={(e) => {
                                            navigateTo(`${item.ref}`, item.name);
                                            clearSearch();
                                        }}
                                    >
                                        <i className={`icon-folder text-35xl text-gray-600`}></i>
                                        <span
                                            className="inline-block ml-3 truncate w-full"
                                            onMouseOver={(e) => showTooltip(e)}
                                            data-tip-disable={disableTooltip}
                                            data-tip={item.name}
                                        >
                                            {item.name}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!item.collapsed ? (
                            <motion.ul
                                id={`ul-${index}`}
                                initial="hidden"
                                animate="visible"
                                exit="close"
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        //height: "auto",
                                        display: "block",
                                        transition: {
                                            when: "beforeChildren",
                                            staggerChildren: 0.03,
                                        },
                                    },
                                    hidden: {
                                        opacity: 0,
                                        //height: 0,
                                        display: "hidden",
                                    },
                                    close: {
                                        opacity: 0,
                                        //height: 0,
                                        display: "hidden",
                                        transition: {
                                            when: "afterChildren",
                                            delay: 0.3,
                                        },
                                    },
                                }}
                                className={`ml-8 ${item.collapsed ? "hidden" : ""}`}
                            >
                                {nested_folders}
                            </motion.ul>
                        ) : null}
                    </Fragment>
                );
            });

            response = (
                <AnimatePresence>
                    <ul className="list-none min-w-full flex-col">{folders}</ul>
                </AnimatePresence>
            );
        }

        return response;
    };

    function _renderNested(sections, nesteds) {
        sections = sections != null && sections.length > 0 ? sections : [];
        if (nesteds != null && nesteds.length > 0) {
            // eslint-disable-next-line
            nesteds
                .sort((a, b) => {
                    if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
                        return -1;
                    }
                    if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                })
                .map((item, index) => {
                    let nested_folders = [];
                    if (item.tree != null && item.tree.length > 0) {
                        nested_folders = _renderNested(nested_folders, item.tree);
                    }
                    sections.push(
                        <motion.li
                            key={`folder-${item.ref}`}
                            initial="hidden"
                            animate="visible"
                            exit="close"
                            variants={{
                                visible: {
                                    opacity: 1,
                                    //height: "auto",
                                    transition: {
                                        delay: 0.15,
                                    },
                                },
                                hidden: {
                                    opacity: 0,
                                },
                                close: {
                                    opacity: 0,
                                    //height: 0,
                                    transition: {
                                        delay: 0.05,
                                    },
                                },
                            }}
                        >
                            <div className={` flex items-center py-0 my-0`}>
                                <div
                                    className={`flex items-center pr-5 pl-3 justify-between relative pb-2 pt-2 mb-2 truncate ${
                                        item.ref === parentRef ? "text-blue-300 font-black rounded bg-gray-300" : null
                                    }`}
                                    id={item.ref}
                                    onClick={() => {
                                        setItemSelected(null);
                                        clearSearch();
                                        navigateTo(`${item.ref}`);
                                    }}
                                >
                                    <div className="flex items-center whitespace-nowrap truncate">
                                        <i
                                            id={`${item.ref}-icon`}
                                            className={`icon-chevron${item.collapsed ? "-right" : ""}`}
                                            onClick={(e) => handleCollapsible(`${item.ref}`)}
                                        ></i>
                                        <div
                                            className="flex items-center whitespace-nowrap truncate cursor-pointer hover:text-zafiro-400"
                                            onClick={(e) => navigateTo(`${item.ref}`)}
                                        >
                                            <i className={`icon-folder text-35xl text-gray-600 ml-2`}></i>
                                            <span
                                                className="inline-block ml-2 truncate"
                                                onMouseOver={(e) => showTooltip(e)}
                                                data-tip-disable={disableTooltip}
                                                data-tip={item.name}
                                            >
                                                {item.name}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!item.collapsed ? (
                                <motion.ul
                                    id={`ul-${index}`}
                                    initial="hidden"
                                    animate="visible"
                                    exit="close"
                                    variants={{
                                        visible: {
                                            opacity: 1,
                                            //height: "auto",
                                            display: "block",
                                            transition: {
                                                when: "beforeChildren",
                                                staggerChildren: 0.03,
                                            },
                                        },
                                        hidden: {
                                            opacity: 0,
                                            //height: 0,
                                            display: "hidden",
                                        },
                                        close: {
                                            opacity: 0,
                                            //height: 0,
                                            display: "hidden",
                                            transition: {
                                                when: "afterChildren",
                                                delay: 0.3,
                                            },
                                        },
                                    }}
                                    className={`ml-8 ${item.collapsed ? "hidden" : ""}`}
                                >
                                    {nested_folders}
                                </motion.ul>
                            ) : null}
                        </motion.li>
                    );
                });
        }
        return sections;
    }

    return <div className="bg-white absolute w-full top-0 bottom-0 overflow-auto">{_renderFolders()}</div>;
}
