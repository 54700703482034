import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import DayPicker, { DateUtils } from "react-day-picker";
import UseCalendarNavbar from "./useCalendarNavbar";
//Actions
import { setScheduleToUpdate } from "../../actions/scheduleActions";
//Utils
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/es";
import CleanUnnecessaryChars from "../../hooks/Utils/CleanUnnecessaryChars";

const UseCalendarRangeEditSchedule = ({ datesConverted, index, setDatesConverted }) => {
    //Store data
    const { scheduleToUpdate } = useSelector((state) => state.schedules);

    //Data
    const numberOfMonths = 1;
    const getInitialState = () => {
        return {
            from: datesConverted[0],
            to: datesConverted[1],
        };
    };

    //States
    const [rangeState, setRangeState] = useState(getInitialState());
    const { from, to } = rangeState;
    const modifiers = { start: from, end: to };

    //Actions
    const dispatch = useDispatch();

    const handleDayClick = (day) => {
        const range = DateUtils.addDayToRange(day, rangeState);
        setRangeState(range);
        const newDateList = getNewDateList(range);
        //Create new scheduleToUpdateInfo
        const newScheduleInfo = scheduleToUpdate;
        newScheduleInfo.timetableDates[index].dateList = `${newDateList}`;
        //Update current store
        dispatch(setScheduleToUpdate(newScheduleInfo));
        setDatesConverted(getDatesConverted(newScheduleInfo.timetableDates[index]));
    };

    return (
        <div className="RangeExample">
            {/*ISO 8601 standard Monday(1) Sunday (7)*/}
            <DayPicker
                firstDayOfWeek={1}
                navbarElement={<UseCalendarNavbar />}
                className="Selectable"
                numberOfMonths={numberOfMonths}
                selectedDays={[from, { from, to }]}
                modifiers={modifiers}
                onDayClick={handleDayClick}
                localeUtils={MomentLocaleUtils}
                locale="es"
            />
        </div>
    );
};

const getNewDateList = (range) => {
    let res = `[{`;
    res += `${formatDate(range.from)}`;
    res += `-`;
    if (range.to) {
        res += `${formatDate(range.to)}`;
    } else {
        res += `2050/01/01`;
    }
    res += `}]`;
    return res;
};

const formatDate = (date) => {
    if (!date) {
        return null;
    }
    let month = `${date.getMonth() + 1}`;
    let day = `${date.getDate()}`;
    const year = `${date.getFullYear()}`;

    if (month.length < 2) {
        month = `0${month}`;
    }
    if (day.length < 2) {
        day = `0${day}`;
    }

    return `${year}/${month}/${day}`;
};

const getDatesConverted = (timetableDate) => {
    let res = [];
    const cleanDate = CleanUnnecessaryChars(timetableDate.dateList);
    const cleandateStrings = cleanDate.split("-");
    // eslint-disable-next-line
    cleandateStrings.map((cleandateString) => {
        res.push(new window.ZDate(cleandateString));
    });
    return res;
};

export default UseCalendarRangeEditSchedule;
