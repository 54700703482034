export const CZBookingCategories = [
    "allas",
    "all",
    "area-sym-activity",
    "cancel-booking-title",
    "cancel-booking-comment",
    "error-booking-cancel-comment",
    "available",
    "booking",
    "bookings",
    "booking-categories-error",
    "category",
    "categories",
    "confirmeda",
    "items",
    "manage",
    "manage-bookings",
    "next",
    "pending",
    "previous",
    "search",
    "all bookings",
    "theres-not",
    "modify-ok",
    "showing-all-results",
    "no-booking-for-name-email",
];
