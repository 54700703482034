import { gql } from "apollo-boost";
import React, { useState, useEffect } from "react";
import { useLazyQuery } from "react-apollo";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showGlobalLoading } from "../../../actions/uiActions";
import { capitalizeFirst, POLLINTERVAL_15S } from "../../../hooks/Utils/Utils";
import { cleanAction } from "../../../actions/globalActions";
import { useNavigate as useHistory } from "react-router-dom";
import UseButton from "../../useButton";
import UseSectionHeader from "../../useSectionHeader";
import AssetDetail from "./AssetDetail";
import VideoDetail from "./VideoDetail";
import { useLibraryParams } from "./MediaLibrary";
import { TreeDataParser } from "./utils";

const FileDetail = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { parentRef, ref, sideBarRef } = useLibraryParams();
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [previousAsset, setPreviousAsset] = useState(null);
    const [projectLangs, setProjectLangs] = useState(null);
    const [nextAsset, setNextAsset] = useState(null);
    const [assetName, setAssetName] = useState(null);

    const GET_TREE = gql`
        {
            libraryTree(ref: "root") {
                response {
                    name
                    ref
                    tree {
                        name
                        ref
                        tree {
                            name
                            ref
                            tree {
                                name
                                ref
                                tree {
                                    name
                                    ref
                                }
                            }
                        }
                    }
                }
            }
        }
    `;
    const GET_INFO = gql`
        {
            projectLangs: distinctProjectLangs{
                results
            },
            libraryContents: libraryContents(ref:"${parentRef}",orderBy:{field: "lower(name)", criteria: "asc"}){
                response{
                    results{
                        ref,
                        type
                    }
                }
            }
        }
    `;

    const [loadTree, { data: treeData }] = useLazyQuery(GET_TREE, {
        pollInterval: POLLINTERVAL_15S,
    });
    const [loadInfo, { data: info }] = useLazyQuery(GET_INFO, {
        fetchPolicy: "network-only",
        errorPolicy: "all",
        onError(err) {
            console.log(err);
            dispatch(showGlobalLoading(false));
            toast.error(capitalizeFirst(t("mutation-error")));
        },
    });

    //Effects
    useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, []);

    const refetch = () => {
        loadTree();
        loadInfo();
        dispatch(showGlobalLoading(true));
        dispatch(cleanAction());
    };

    useEffect(() => {
        let breadcrumbsData = [
            {
                name: "library",
                route: "/design/library/" + sideBarRef,
            },
        ];
        if (treeData && treeData.libraryTree && treeData.libraryTree.response) {
            treeData.libraryTree.response.forEach((content) => {
                TreeDataParser(content, parentRef, sideBarRef, breadcrumbsData);
            });
        }
        setBreadcrumbs(breadcrumbsData);
    }, [treeData, parentRef, ref, sideBarRef]);

    useEffect(() => {
        if (info) {
            const projectLangs =
                (info.projectLangs && info.projectLangs.results ? info.projectLangs.results : null) || [];
            dispatch(showGlobalLoading(false));
            setProjectLangs(projectLangs);
            if (info.libraryContents && info.libraryContents.response && info.libraryContents.response.results)
                arrangeNavigationButtons(info.libraryContents.response.results);
        }
        // eslint-disable-next-line
    }, [info]);

    const arrangeNavigationButtons = (libraryContent) => {
        let previousAsset = null;
        let nextAsset = null;
        let currentIndex = null;
        libraryContent = libraryContent.filter((item) => item.type !== "folder");
        if (libraryContent && libraryContent.length > 0) {
            currentIndex = libraryContent.findIndex((item) => item.ref === ref);
            if (currentIndex > -1) {
                if (currentIndex > 0) previousAsset = libraryContent[currentIndex - 1].ref;
                if (currentIndex < libraryContent.length - 1) nextAsset = libraryContent[currentIndex + 1].ref;
            }
        }
        setPreviousAsset(previousAsset);
        setNextAsset(nextAsset);
    };

    const navigateToFile = (action) => {
        let link = `/design/library/${parentRef}/${nextAsset}/${sideBarRef}`;
        if (action === "prev") {
            link = `/design/library/${parentRef}/${previousAsset}/${sideBarRef}`;
        }
        history(link);
        refetch();
    };

    return (
        <>
            <UseSectionHeader
                title={assetName}
                navToSection={`/design/library/${parentRef}/${sideBarRef}`}
                customBreadCrumbs={breadcrumbs}
            />
            <div>
                <div className="main-container">
                    <div className="inline-flex absolute right-0 z-20 -mt-20">
                        {previousAsset ? (
                            <div className="">
                                <UseButton
                                    icon="chevron-left px-2 bg-white py-2 mr-2"
                                    buttonName={""}
                                    action={() => {
                                        navigateToFile("prev");
                                    }}
                                    buttonColor={`hover:text-blue-100 cursor-pointer p-0`}
                                    adjust=""
                                    noPaddingDefault={true}
                                />
                            </div>
                        ) : null}
                        {nextAsset ? (
                            <div className="">
                                <UseButton
                                    icon="chevron-right px-2 bg-white py-2"
                                    buttonName={""}
                                    action={() => {
                                        navigateToFile("next");
                                    }}
                                    buttonColor={`hover:text-blue-100 cursor-pointer p-0`}
                                    adjust=""
                                    noPaddingDefault={true}
                                />
                            </div>
                        ) : null}
                    </div>
                    {ref && projectLangs ? (
                        <>
                            {ref.split("-")[0] === "video" ? (
                                <VideoDetail
                                    key={`video-render`}
                                    fileRef={ref}
                                    projectLangs={projectLangs}
                                    assetName={assetName}
                                    parentRef={parentRef}
                                    sideBarRef={sideBarRef}
                                    setAssetName={setAssetName}
                                />
                            ) : (
                                <AssetDetail
                                    key={`asset-render`}
                                    fileRef={ref}
                                    projectLangs={projectLangs}
                                    assetName={assetName}
                                    parentRef={parentRef}
                                    sideBarRef={sideBarRef}
                                    setAssetName={setAssetName}
                                />
                            )}
                        </>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default FileDetail;
