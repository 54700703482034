import React from "react";
//Components
import UseTable from "../../../Table";
//Utils
import ListPropertyTagValues from "../../../../hooks/GraphqlCalls/Design/ListPropertyTagValues";

const TagPropertyValues = () => {
    ListPropertyTagValues();
    return (
        <>
            <UseTable />
        </>
    );
};

export default TagPropertyValues;
