import { capitalizeFirst } from "../../../../hooks/Utils/Utils";

const ScreenName = (langStrings, title, screenData) => {
    return {
        titlePrefix: title,
        title: ["-", capitalizeFirst(langStrings["edit-name"])],
        executeSectionQuery: true,
        bodyAdjust: "w-3/12",
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "rename",
                style: "blue",
                action: "screen-name",
            },
        ],
        inputs: [
            {
                text: ["write-screenname", ":"],
                name: "screen-name",
                type: "text",
                value: title,
                focus: true,
            },
            {
                value: screenData,
                type: "data",
            },
        ],
    };
};

export default ScreenName;
