import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Session } from "../hooks/Utils/Session";
//Components
import { Link, useParams, useLocation } from "react-router-dom";
//Actions
import { reloadBreadCrumbs } from "../actions/uiActions";
import { useTranslation } from "react-i18next";

const useBreadcrumbs = ({ limit, customBreadCrumbs }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { reloadBreadcrumbs } = useSelector((state) => state.ui);

    //States
    const [breadData, setBreadData] = useState([]);
    const [forceBreads, setForceBreads] = useState(null);

    //Actions
    const dispatch = useDispatch();

    //Data
    const currentPath = location?.pathname?.split("/")?.filter((path) => path !== "");
    const { id } = useParams();

    //Listeners
    useEffect(() => {
        if (reloadBreadcrumbs) {
            setBreadData(getBreadRoutes());
            dispatch(reloadBreadCrumbs(false));
        }
        // eslint-disable-next-line
    }, [reloadBreadcrumbs]);

    useEffect(() => {
        setBreadData(getBreadRoutes());
    }, [location?.pathname]);

    useEffect(() => {
        setBreadData(getBreadRoutes());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setForceBreads(customBreadCrumbs);
    }, [customBreadCrumbs]);

    useEffect(() => {
        setBreadData(getBreadRoutes());
        // eslint-disable-next-line
    }, [forceBreads]);

    const getBreadRoutes = () => {
        if (forceBreads != null) {
            return forceBreads;
        }

        let breadConfig = [];
        let routeSum = "";

        const BreakException = {};
        try {
            currentPath.forEach(function (path, index) {
                if (limit && index >= limit + 1) {
                    throw BreakException;
                }
                if (index === currentPath.length - 1) {
                    // Last element never shown
                    throw BreakException;
                }
                if (path !== "") {
                    routeSum += `/${path}`;

                    let cookieRoute = Session.getSessionProp(`breadscrumb_${routeSum}`);
                    if (cookieRoute) {
                        cookieRoute = JSON.parse(cookieRoute);
                    }
                    const crumb = {
                        name: cookieRoute ? cookieRoute.name : path,
                        route: cookieRoute ? cookieRoute.path : routeSum,
                        cookieRoute: cookieRoute,
                    };
                    if (Number(crumb.name)) {
                        // Numbers not shown and break chain
                        throw BreakException;
                    }
                    if (crumb.name.match(/(folder|asset|ref|chain|room|user)-.+/)) {
                        // References not shown and break chain
                        throw BreakException;
                    }
                    breadConfig.push(crumb);
                }
            });
        } catch (e) {
            if (e !== BreakException) throw e;
        }

        return breadConfig;
    };
    return (
        <div id="breadcrumb-container" className="mb-2">
            {breadData.length > 0
                ? breadData.map((crumb, index) => (
                      <span id={`breadcrumb-${index}`} key={index}>
                          {index > 0 ? <span>{` / `}</span> : null}
                          <Link
                              id={`breadcrumb-${index}-link`}
                              to={`${crumb.route}${
                                  currentPath &&
                                  currentPath.length - 2 === index + 1 &&
                                  id &&
                                  !crumb.cookieRoute &&
                                  !crumb.respectRoute
                                      ? `/${id}`
                                      : ""
                              }`}
                              key={index}
                              className="text-sm text-gray-800"
                          >
                              <div
                                  id={`breadcrumb-${index}-text`}
                                  className={"inline-block" + (crumb.translate === false ? "" : " first-capital")}
                              >
                                  {crumb.translate === false ? crumb.name : t(crumb.name)}
                              </div>
                          </Link>
                      </span>
                  ))
                : null}
        </div>
    );
};

export default useBreadcrumbs;
