import { useState, useEffect } from "react";
import { toast } from "react-toastify";

const useSecrets = ({ enabled = true }) => {
    // State to highlight the table when F2 is pressed (for QA / Testing purposes)
    const [highlight, setHighlight] = useState(null);

    useEffect(() => {
        if (highlight !== null) {
            toast.info("Highlighting components is now " + (highlight ? "ON" : "OFF"));
        }
    }, [highlight]);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === "F2") {
                setHighlight((prev) => !prev);
            }
        };
        if (enabled) {
            document.addEventListener("keydown", handleKeyPress);
        }
        return () => {
            if (enabled) {
                document.removeEventListener("keydown", handleKeyPress);
            }
        };
    }, []);

    return {
        highlightComponent: highlight,
    };
};

export default useSecrets;
