import React, { useEffect, useState } from "react";
import EmptyWidget from "../Section/Design/Widgets/WidgetToolbars/CommonOptions/EmptyWidget";
import { useTranslation } from "react-i18next";
import UseRadio from "../useRadio";
import UseButton from "../useButton";
import BrowserMediaLibraryModal from "../../hooks/GraphqlCalls/MediaLibrary/modals/BrowserMediaLibraryModal";
import { useDispatch, useSelector } from "react-redux";
import { openModal, setModalContent } from "../../actions/uiActions";
import { changeActionValues } from "../../actions/globalActions";
import { setSectionContent } from "../../actions/sectionActions";
import { Session } from "../../hooks/Utils/Session";
import { capitalizeT } from "../../hooks/Utils/Utils";
import { BACKGROUND } from "../../hooks/Utils/Design/SalesUtils";

const UseAddImage = ({ lang = "es" }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageInputSelected, setImageInputSelected] = useState();
    const [isValidUrlImage, setIsValidUrlImage] = useState(true);
    const { background } = useSelector((state) => state.sectionContent);

    const openModalLibrary = () => {
        let modal = BrowserMediaLibraryModal(t, {
            title: "choose-an-image",
            text: "images-of-the-media-library",
            bodyAdjust: "w-10/12",
            textAdjust: "",
            executeSectionQuery: true,
            buttonSelectedAssetName: "choose-an-image",
            dataMediaLibrary: {
                selected_ref: null,
                select_multiple: false,
                type: "asset",
                lang: lang,
                add_asset: {
                    active: true,
                    return_added: false,
                },
            },
            buttonAceptAction: "accept",
        });
        modal.preventCleanOnClose = true;
        modal.acceptCallback = "modalAcceptCallback"; // function in window created in useEffect

        dispatch(changeActionValues({ "browser-media-library-items-selected": null }));
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    const validateImageUrl = (url) => {
        const regex = /(http(s?):\/\/)([^\s(["<,>/]*)(\/)[^\s[",><]*(.png|.jpg|.jpeg|.gif|.webp)(\?[^\s[",><]*)?/g;
        const isAValidUrl = regex.test(url);
        return !!isAValidUrl;
    };
    useEffect(() => {
        setImageInputSelected(background?.type ? background.type : BACKGROUND.IMAGE_TYPE.LIBRARY);
        if (!background?.type) {
            dispatch(setSectionContent({ background: { ...background, type: BACKGROUND.IMAGE_TYPE.LIBRARY } }));
        }

        // accept callback to use in modal, will pass action values to the function
        window.modalAcceptCallback = (actionValues) => {
            actionValues["browser-media-library-items-selected"]?.[0] &&
                dispatch(
                    setSectionContent({
                        background: {
                            ...background,
                            type: BACKGROUND.IMAGE_TYPE.LIBRARY,
                            libraryRef: actionValues["browser-media-library-items-selected"][0],
                        },
                    })
                );
        };
        return () => {
            // delete function declared in window to aviod trash
            delete window.modalAcceptCallback;
        };
        // eslint-disable-next-line
    }, []);

    const getAssetSrc = () => {
        if ((background?.libraryRef || background?.externalUrl) && background?.type) {
            return background.type === BACKGROUND.IMAGE_TYPE.LIBRARY
                ? Session.getDasUrl(background.libraryRef + "?lang=" + lang)
                : background.externalUrl;
        }
    };

    const showEmptyImage =
        (imageInputSelected === BACKGROUND.IMAGE_TYPE.LIBRARY && !background?.libraryRef) ||
        (imageInputSelected === BACKGROUND.IMAGE_TYPE.EXTERNAL &&
            (!background?.externalUrl || !validateImageUrl(background?.externalUrl)));

    return (
        <div>
            {(imageInputSelected === BACKGROUND.IMAGE_TYPE.LIBRARY && background?.libraryRef) ||
            (background?.externalUrl && imageInputSelected === BACKGROUND.IMAGE_TYPE.EXTERNAL) ? (
                <img
                    alt=""
                    className="h-36 w-full object-contain "
                    style={{ display: `${imageLoaded ? "block" : "none"}` }}
                    src={getAssetSrc()}
                    onError={() => {
                        setImageLoaded(false);
                    }}
                    onLoad={() => {
                        setImageLoaded(true);
                    }}
                ></img>
            ) : null}

            <div className="h-36 mb-8" style={{ display: showEmptyImage ? "block" : "none" }}>
                <EmptyWidget type="IMAGE"></EmptyWidget>
            </div>

            <div
                className="first-capital mb-4 cursor-pointer"
                id={"add-image-library-radio-container"}
                onClick={(e) => {
                    setImageInputSelected(BACKGROUND.IMAGE_TYPE.LIBRARY);
                    dispatch(setSectionContent({ background: { ...background, type: BACKGROUND.IMAGE_TYPE.LIBRARY } }));
                }}
            >
                <UseRadio
                    id={"add-image-library-radio"}
                    selected={imageInputSelected}
                    value={BACKGROUND.IMAGE_TYPE.LIBRARY}
                />
                <div className="ml-8">{capitalizeT("add", t)}:</div>
                <UseButton
                    id={"add-image-library-button"}
                    buttonName={t("choose-an-image")}
                    disabled={imageInputSelected !== BACKGROUND.IMAGE_TYPE.LIBRARY}
                    buttonColor={imageInputSelected === BACKGROUND.IMAGE_TYPE.LIBRARY ? "btn-white" : "btn-disabled"}
                    adjust={"mt-2"}
                    action={() => openModalLibrary()}
                />
            </div>
            <div className="table w-full">
                <div
                    id={"add-image-external-radio-container"}
                    className="first-capital mb-4  cursor-pointer"
                    onClick={(e) => {
                        setImageInputSelected(BACKGROUND.IMAGE_TYPE.EXTERNAL);
                        dispatch(
                            setSectionContent({ background: { ...background, type: BACKGROUND.IMAGE_TYPE.EXTERNAL } })
                        );
                    }}
                >
                    <UseRadio
                        id={"add-image-external-radio"}
                        selected={imageInputSelected}
                        value={BACKGROUND.IMAGE_TYPE.EXTERNAL}
                    />
                    <div className="ml-8">
                        {t("link")} (URL - {t("https-only")}):
                    </div>
                </div>
                <input
                    type="text"
                    id={`externalImageUrl`}
                    value={
                        imageInputSelected === BACKGROUND.IMAGE_TYPE.EXTERNAL && background?.externalUrl
                            ? background?.externalUrl
                            : null
                    }
                    onChange={(e) => {
                        setIsValidUrlImage(validateImageUrl(e.target.value));
                        dispatch(
                            setSectionContent({
                                background: { ...background, externalUrl: e.target.value },
                            })
                        );
                    }}
                    disabled={imageInputSelected === BACKGROUND.IMAGE_TYPE.EXTERNAL ? null : "disabled"}
                    className={`rounded mb-4 w-full bg-gray-200 px-4 py-2 ${
                        imageInputSelected !== BACKGROUND.IMAGE_TYPE.EXTERNAL ? "text-gray-600" : ""
                    } ${!isValidUrlImage ? "border border-red-100" : ""}`}
                    placeholder={`${t("paste here")}...`}
                />
            </div>
        </div>
    );
};

export default UseAddImage;
