import React, { useState, useEffect, forwardRef, useImperativeHandle, useContext } from "react";
import ReactInput from "react-currency-input-field";
import classNames from "classnames";

import { currenciesSymbolPlaceBefore, getSymbolCurrency, nonDecimalCurrencies } from "hooks/Utils/SalesUtils";

import { GlobalContext } from "contexts/Global";

const DECIMAL_PLACES = 2;

/**
 * @description
 * @component
 * @param {string} id - ID for the input
 * @param {number} value - Initial value
 * @param {boolean} disabled - Disable the input
 * @param {boolean} required - Required field
 * @param {string} currencyCode - Currency code
 * @param {string} customSymbol - Custom symbol instead of the currency code
 * @param {string} className - Additional classes
 * @param {number} minValue - Minimum value
 * @param {number} maxValue - Maximum value
 * @param {boolean} allowDecimals - Force to allow or disallow decimals
 * @param {function} onChange - Function to call when the value changes
 * @returns {JSX.Element} - Currency input component
 * @example
 * <CurrencyInput id="input-id" value={0} disabled={false} currencyCode="USD" className="w-1/2" minValue={0} maxValue={1000} onChange={(value) => console.log(value)} />
 *
 * @property {function} isValid - Check if the value is valid (return boolean)
 * @property {function} getValue - Get the value programmatically (return float)
 * @property {function} setValue - Set the value programmatically (param float)
 */
const CurrencyInput = forwardRef(
    (
        {
            id,
            value: initialValue,
            disabled,
            required,
            currencyCode,
            customSymbol,
            className,
            minValue,
            maxValue,
            allowDecimals: forceAllowDecimals,
            onChange,
        },
        ref
    ) => {
        const { highlightComponent } = useContext(GlobalContext);

        const [value, setValue] = useState(null);
        const [floatValue, setFloatValue] = useState(null);
        const [isValid, setIsValid] = useState(true);

        const currencySymbol = customSymbol || getSymbolCurrency({ currency_code: currencyCode });
        const isSuffix = currencyIsSuffix(currencyCode);
        const allowDecimals = forceAllowDecimals ?? currencyAllowDecimals(currencyCode);
        const stepValue = allowDecimals ? 0.1 : 1;

        const validate = (value) => {
            const isEmpty = !value && value !== 0;
            const hasMinValue = minValue || minValue === 0;
            const hasMaxValue = maxValue || maxValue === 0;
            if (required && isEmpty) {
                // Value is required but empty
                return false;
            }
            if (!isEmpty && hasMinValue && value < minValue) {
                // Value is less than the minimum
                return false;
            }
            if (!isEmpty && hasMaxValue && value > maxValue) {
                // Value is greater than the maximum
                return false;
            }
            return true;
        };

        useEffect(() => {
            if (initialValue !== floatValue) {
                setValue(formatValue(initialValue ?? null));
                setFloatValue(initialValue ?? null);
            }
        }, [initialValue]);

        useEffect(() => {
            setIsValid(validate(floatValue));
        }, [floatValue, minValue, maxValue, required]);

        const handleChange = (newVal) => {
            if (typeof onChange === "function") {
                onChange(newVal, validate(newVal));
            }
        };

        const sumValue = (newValue) => {
            const newVal = Math.round(((floatValue || 0) + newValue) * 100) / 100;
            const hasMaxValue = maxValue || maxValue === 0;
            const hasMinValue = minValue || minValue === 0;
            if ((hasMinValue && newVal < minValue) || (hasMaxValue && newVal > maxValue)) {
                return;
            }
            setFloatValue(newVal);
            setValue(formatValue(newVal));
            handleChange(newVal);
        };

        const fgStyle = `${disabled ? "text-gray-700" : "text-gray-900"}`;
        const bgStyle = `${disabled ? "bg-gray-300" : "bg-gray-200"}`;

        useImperativeHandle(ref, () => ({
            isValid: () => isValid,
            getValue: () => floatValue,
            setValue: (v) => {
                setFloatValue(v);
                setValue(formatValue(v));
            },
        }));

        const globalClass = classNames({
            "flex items-center": true,
            "highlight-component": highlightComponent,
            [className]: className,
        });

        const inputClass = classNames({
            [fgStyle]: !!fgStyle,
            [bgStyle]: !!bgStyle,
            "py-1 w-full rounded disable-browser-buttons-numbers": true,
            "px-3": isSuffix,
            "pl-11 pr-3": !isSuffix,
            "border border-red-500": !isValid,
        });

        return (
            <div className={globalClass}>
                <div className="relative w-full">
                    <ReactInput
                        id={id ? `${id}-input` : null}
                        className={inputClass}
                        disabled={disabled}
                        required={required}
                        value={value || 0}
                        defaultValue={initialValue || 0}
                        allowNegativeValue={false}
                        allowDecimals={allowDecimals}
                        decimalsLimit={allowDecimals ? DECIMAL_PLACES : 0}
                        decimalScale={allowDecimals ? DECIMAL_PLACES : 0}
                        disableGroupSeparators={true}
                        onValueChange={(_value, _name, _values) => {
                            setValue(_value);
                            setFloatValue(_values?.float);
                            handleChange(_values?.float);
                        }}
                    />
                    <div
                        id={id ? `${id}-symbol` : null}
                        className={`${fgStyle} absolute h-full top-0 ${isSuffix ? "right-0 pr-2" : "left-0 pl-2"}`}
                    >
                        <span className="flex h-full items-center">{currencySymbol}</span>
                    </div>
                </div>
                <div
                    className={`flex flex-col ml-2 items-center justify-items-center ${
                        disabled ? "text-gray-400" : "text-gray-900"
                    }`}
                >
                    <span
                        id={id ? `${id}-inc` : null}
                        onClick={() => {
                            if (disabled) {
                                return;
                            }
                            sumValue(stepValue);
                        }}
                        className={`icon text-sm mb-1 icon-chevron-up ${disabled ? "" : "cursor-pointer"}`}
                    ></span>
                    <span
                        id={id ? `${id}-dec` : null}
                        onClick={() => {
                            if (disabled) {
                                return;
                            }
                            sumValue(stepValue * -1);
                        }}
                        className={`icon text-sm mt-1 icon-chevron ${disabled ? "" : "cursor-pointer"}`}
                    ></span>
                </div>
            </div>
        );
    }
);
CurrencyInput.displayName = "CurrencyInput";

const formatValue = (v) => (v && !isNaN(v) ? Number(v).toFixed(DECIMAL_PLACES) : null);

const currencyAllowDecimals = (currencyCode) =>
    nonDecimalCurrencies ? !nonDecimalCurrencies.includes(currencyCode) : true;

const currencyIsSuffix = (currencyCode) =>
    currenciesSymbolPlaceBefore ? !currenciesSymbolPlaceBefore.includes(currencyCode) : true;

export default CurrencyInput;
