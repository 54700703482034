const DeleteLabel = (id, name) => {
    return [
        {
            title: ["delete", name],
            text: "delete-label-description",
            id: id,
            executeSectionQuery: true,
            inputs: [],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "delete",
                    style: "red",
                    action: "delete-label",
                },
            ],
        },
    ];
};

export default DeleteLabel;
