import React from "react";
//Components
import UseTable from "../../../Table/";
//Utils
import ListBookings from "../../../../hooks/GraphqlCalls/Customers/ListBookings";

const GuestBookings = ({ data }) => {
    ListBookings({ data: data });
    return (
        <>
            <UseTable />
        </>
    );
};

export default GuestBookings;
