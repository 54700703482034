import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

//Components
import UseEditableField from "../../../../components/useEditableField";
import UseButton from "../../../../components/useButton";
import UseItemActions from "../../../../components/useItemActions";

//Actions
import { setActionItemAffected, setActionName, setActionValidations } from "../../../../actions/globalActions";

import { validateSection } from "../../../../hooks/Utils/Validations";
import { showGlobalLoading, openModal, setModalContent } from "../../../../actions/uiActions";
import { setExecuteQuery } from "../../../../actions/sectionActions";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import { useQuery } from "react-apollo";
import { useNavigate as useHistory } from "react-router-dom";
import { useAuth } from "hooks/Session/auth";

const StayInformation = ({ guestInfo }) => {
    const { t } = useTranslation();
    const { project } = useAuth();

    //Actions
    const dispatch = useDispatch();
    const prefixId = "stay-information-";
    const history = useHistory();

    //State
    const [inEditionMode, setInEditionMode] = useState(false);

    //Store data
    const { permissions } = useSelector((state) => state.ui);
    const { values } = useSelector((state) => state.action);

    const GET_STAY_BOOKING_CODE = gql(`query{
        stayBookings(filter: {id: ${guestInfo?.stayBookingID}}) {
          results {
            code
          }
        }
      }
      `);

    const { data: dataStayBooking } = useQuery(GET_STAY_BOOKING_CODE, {
        skip: !guestInfo?.stayBookingID && permissions?.guests?.preCheckin,
    });

    const precheckinAvailable = permissions?.guests?.preCheckin ? true : false;

    const { rooms } = guestInfo;
    const shouldRenderBookingInfo =
        guestInfo?.stayBookingID &&
        !inEditionMode &&
        dataStayBooking?.stayBookings?.results[0]?.code &&
        permissions?.guests?.preCheckin;

    let from,
        to = null;
    rooms.map((room) =>
        room.stayFrom && (!from || new window.ZDate(room.stayFrom) < from)
            ? (from = new window.ZDate(room.stayFrom))
            : null
    );
    rooms.map((room) =>
        room.stayTo && (!to || new window.ZDate(room.stayTo) > to) ? (to = new window.ZDate(room.stayTo)) : null
    );

    //Actions
    const ensureValue = (value) => {
        return value ? value : "-";
    };
    const startEdition = () => {
        dispatch(setActionItemAffected(guestInfo.guestID));
        dispatch(setActionName("edit-stay-data"));
        setInEditionMode(!inEditionMode);
    };

    const updateStayData = () => {
        //Validate fields
        const validations = validateSection("stay-data", values, t);

        dispatch(setActionValidations(validations));
        const validationsArray = Object.values(validations);

        if (validationsArray.filter((item) => item === false).length > 0) {
            toast.error(t("errors-in-red"));
            return;
        }
        dispatch(showGlobalLoading(true));
        setInEditionMode(false);
        dispatch(
            setExecuteQuery({
                action: "updateStay",
                params: { id: guestInfo.guestID },
            })
        );
    };

    const addRoom = () => {
        dispatch(setActionItemAffected(guestInfo.guestID));
        dispatch(setActionName("add-room"));
        //Modal content
        const modalContent = {
            id: guestInfo.guestID,
            useGlobalLoading: true,
            title: ["add", "room"],
            value: `${guestInfo.guestName} ${guestInfo.guestSurname}`,
            inputs: [
                {
                    text: ["choose-new-room"],
                    name: `room`,
                    optionsQuery: `{
                            data: rooms(filter: {free:true}){
                              info { count }
                              results { id name  }
                            }       
                        }`,
                    oneSelected: true,
                    ph: "room",
                    cStyle: "w-full",
                    sort: true,
                    selectPlaceHolder: "select-room",
                    type: "selectMultipleWithSearch",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "save",
                    style: "blue",
                    action: "add-room",
                },
            ],
        };
        dispatch(setModalContent(modalContent));
        //Launch modal
        dispatch(openModal());
    };

    return (
        <div className="p-8" style={{ display: "flow-root" }}>
            <div className="border-b border-gray-200 text-lg pb-2 flex justify-between">
                <div className="first-capital font-bold">{t("stay-information")}</div>
                {!inEditionMode && !project.blockGuestManagement ? (
                    <div
                        id="stay-information-start-edition-button"
                        className="icon-edit text-xl text-gray-600 cursor-pointer"
                        onClick={startEdition}
                    ></div>
                ) : null}
            </div>

            <div className="m-auto pt-8">
                <div className="pb-6">
                    <UseEditableField
                        data={{
                            label: "arrival",
                            value: ensureValue(
                                from
                                    ? `${from.toLocaleDateString([], {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                      })} ${from.toLocaleTimeString("es-ES", {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                      })}`
                                    : `-`
                            ),
                            date:
                                rooms && rooms[0] && rooms[0].stayFrom
                                    ? new window.ZDate(rooms[0].stayFrom).toISOString().split("T")[0]
                                    : null,
                            hour: from
                                ? `${from.toLocaleTimeString("es-ES", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                  })}`
                                : null,
                            inEditionMode: inEditionMode,
                            // disabled: inEditionMode,
                            type: "date&time",
                            id: `${prefixId}arrival`,
                        }}
                    ></UseEditableField>
                </div>
                <div className="pb-6">
                    <UseEditableField
                        data={{
                            label: "departure",
                            value: ensureValue(
                                to
                                    ? `${to.toLocaleDateString([], {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                      })} ${to.toLocaleTimeString("es-ES", {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                      })}`
                                    : `-`
                            ),
                            date:
                                rooms && rooms[0] && rooms[0].stayTo
                                    ? new window.ZDate(rooms[0].stayTo).toISOString().split("T")[0]
                                    : null,
                            hour: to
                                ? `${to.toLocaleTimeString("es-ES", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                  })}`
                                : null,
                            inEditionMode: inEditionMode,
                            type: "date&time",
                            id: `${prefixId}departure`,
                        }}
                    ></UseEditableField>
                </div>

                {!inEditionMode ? (
                    <>
                        <div className="pb-6">
                            <div className="text-gray-800 text-sm font-bold pb-2 first-capital">{`${t("rooms")} ${
                                rooms ? `(${rooms.length})` : ""
                            }`}</div>
                        </div>

                        <div className=" roomsMaxHeight overflow-y-scroll scrollReference">
                            {rooms.map((room, index) => (
                                <div key={room.number} className="rounded bg-gray-100 h-auto p-6 pl-8 pr-8 mb-3 ">
                                    <div className="flex justify-between mb-2">
                                        <Link id={`room-info-${index}`} to={`/hotel/rooms/room/detail/${room.RoomID}`}>
                                            <div className={`bg-zafiro-200 w-10 h-10 m-auto rounded-full float-left`}>
                                                <i
                                                    className={`icon-room text-center pt-3 block text-lg m-auto text-white`}
                                                ></i>
                                            </div>
                                            <div className="ml-4 float-left -mt-2">
                                                <div className="text-gray-800 text-sm font-bold first-capital">
                                                    {room.number}
                                                </div>
                                                <div className="text-2xl font-bold text-zafiro-600">{room.name}</div>
                                            </div>
                                        </Link>

                                        <div className="-mr-6">
                                            <UseItemActions
                                                dataType="guest-currents"
                                                id={String(index)}
                                                indexParent={String(index)}
                                                actions={[
                                                    ...(permissions?.hotel?.roomList
                                                        ? [
                                                              {
                                                                  name: "view-room",
                                                                  link: `/hotel/rooms/room/detail/${room.RoomID}`,
                                                              },
                                                          ]
                                                        : []),
                                                    !project.blockGuestManagement
                                                        ? {
                                                              name: "change-room",
                                                              guestID: guestInfo.guestID,
                                                              rooms: [
                                                                  {
                                                                      StayGuestID: guestInfo.guestID,
                                                                      roomID: room.RoomID,
                                                                      number: room.number,
                                                                  },
                                                              ],
                                                          }
                                                        : {},

                                                    {
                                                        name: t("disable-parental"),
                                                        disabled: !room.parentalcode,
                                                        executeSectionQuery: true,
                                                        mutation: "disable-parental-code",
                                                        mutationParams: {
                                                            stayGuestRoomID: room.stayGuestRoomID,
                                                        },
                                                    },
                                                    !project.blockGuestManagement
                                                        ? {
                                                              name: "checkout",
                                                              rooms: rooms,
                                                              guestID: guestInfo.guestID,
                                                              checked: room.number,
                                                          }
                                                        : {},
                                                ]}
                                                containerAdjust="w-48"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex justify-between pb-2 border-b border-gray-300">
                                        {" "}
                                        <div className="text-gray-700 text-sm font-bold first-capital pt-1">
                                            {t("room-code") || "-"}
                                        </div>
                                        <div className="text-lg text-gray-900">{room.roomcode}</div>
                                    </div>
                                    <div className="flex justify-between pt-2 pb-2">
                                        {" "}
                                        <div className="text-gray-700 text-sm font-bold first-capital pt-1">
                                            {t("room-parental-code")}
                                        </div>
                                        <div
                                            className={`text-sm  first-capital ${
                                                room.parentalcode ? "text-gray-900" : "pt-1 text-gray-600"
                                            }`}
                                        >
                                            {room.parentalcode ? "*****" : t("disabled")}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {rooms && rooms.length === 0 ? "-" : null}
                        </div>
                        {!project.blockGuestManagement ? (
                            <div
                                id="stay-information-add-room-button"
                                onClick={(e) => addRoom()}
                                className="font-bold text-zafiro-400 text-lg float-right mt-4 cursor-pointer"
                            >
                                <span className="icon icon-add pr-2"></span>{" "}
                                <span className="first-capital inline-block">{`${t("add")} ${t("room")}`}</span>
                            </div>
                        ) : null}
                        {shouldRenderBookingInfo && precheckinAvailable ? (
                            <div
                                className={`${
                                    project.blockGuestManagement ? "mt-8" : "mt-20"
                                }  w-full flex justify-center `}
                            >
                                <div>{t("booking-information")}:</div>
                                <button
                                    id={"stay-information-link-to-booking-information-details"}
                                    onClick={() => {
                                        history(
                                            `/customers/guests/guest-list/reservation-information/${guestInfo.stayBookingID}`
                                        );
                                    }}
                                    className=" ml-2 focus:outline-none font-bold text-zafiro-600"
                                >
                                    {dataStayBooking?.stayBookings?.results[0]?.code}
                                </button>
                            </div>
                        ) : null}
                    </>
                ) : null}
            </div>
            {inEditionMode ? (
                <div className="mt-6 pt-6 border-t border-gray-200 flex  justify-end">
                    <div onClick={() => setInEditionMode(false)}>
                        <UseButton
                            id="stay-information-cancel-button"
                            buttonName={"cancel"}
                            buttonColor={"btn-white"}
                            adjust="w-24 min-w-0 mr-2 float-left "
                        />
                    </div>
                    <div onClick={() => updateStayData()}>
                        <UseButton
                            id="stay-information-save-button"
                            buttonName={"save"}
                            buttonColor={"btn-blue"}
                            adjust="w-24 min-w-0"
                        />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default StayInformation;
