import ReactParser from "html-react-parser";
import DOMPurify from "dompurify";

export const CleanHTML = (html) => {
    // Custom URI scheme to prevent javascript: URLs
    const customUriScheme = /^((https?|\/|#|data):)/i;
    const sanitizedHtml = DOMPurify.sanitize(html, {
        ALLOWED_TAGS: [
            "a",
            "img",
            "p",
            "br",
            "ul",
            "ol",
            "li",
            "strong",
            "em",
            "div",
            "table",
            "section",
            "th",
            "tr",
            "td",
            "thead",
            "tbody",
            "h1",
            "h2",
            "h3",
            "hr",
            "span",
            "pre",
        ],
        ALLOWED_ATTR: ["id", "href", "src", "alt", "class", "target", "rel", "title", "style"],
        ALLOWED_URI_REGEXP: customUriScheme,
    });
    return sanitizedHtml;
};

const Parser = (html, options) => {
    return ReactParser(CleanHTML(html), options);
};

export default Parser;
