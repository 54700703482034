import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import UseSectionHeader from "../../useSectionHeader";
import useListProperties from "hooks/GraphqlCalls/Settings/useListProperties";

import Table from "components/ZafiroTable";

const Properties = () => {
    const { t } = useTranslation();

    const { properties, propertiesGroups } = useListProperties();

    const cols = ["property", "group", "edit"];
    const [header, setHeader] = useState({});

    const filters = [
        {
            id: "properties-group",
            title: t("manageBrands"),
            multiple: true,
            options: propertiesGroups,
            onFilter: (values, row) => {
                const isIncluded = values.every((val) => row.groups.includes(val));
                return isIncluded ? row : null;
            },
        },
    ];

    const propertiesHeader = {
        property: { title: t("property"), sortable: true, width: "40%" },
        group: { title: t("group"), sortable: false, width: "50%" },
        edit: { title: "", sortable: false, with: "10%" },
    };

    useEffect(() => {
        if (propertiesHeader) {
            setHeader(propertiesHeader);
        }
    }, []);

    return (
        <>
            <UseSectionHeader title={"manager-properties"} />
            <main className=" main-container">
                <Table
                    id="properties-table"
                    header={header}
                    search={true}
                    cols={cols}
                    showCount={true}
                    rows={properties}
                    filters={filters}
                    paginate={true}
                />
            </main>
        </>
    );
};

export default Properties;
