import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseInputSelectOptions from "./useInputSelectOptions";
//Actions
import { setActionInput } from "../../actions/globalActions";
//Utils
import useComponentVisible from "../useComponentVisible";

const UseInputSelect = ({ inputData, validation, currentValue, name }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { hour } = useSelector((state) => state.action.actionData);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //Data
    const [activeValue, setActiveValue] = useState(currentValue);
    const ph = "hours";

    //States
    const [selectActive, setSelectActive] = useState({});

    //Actions
    const dispatch = useDispatch();

    //Listeners
    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({});
        } else {
            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
            });
        }
    }, [isComponentVisible]);

    useEffect(() => {
        setActiveValue(hour);
    }, [hour]);

    useEffect(() => {
        if (currentValue) {
            const dataToAction = { [name]: currentValue };
            dispatch(setActionInput(dataToAction));
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div
                ref={ref}
                className={`h-10 bg-gray-200 rounded relative ${selectActive.container}${
                    name === "hour" ? " w-full " : "long "
                }${!validation ? "border border-red-100" : ""}`}
            >
                <div
                    className={`t-filter-ph px-2 first-capital ${selectActive.text}`}
                    onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                >
                    {!activeValue ? (currentValue ? currentValue : langStrings[ph]) : activeValue}
                </div>
                <span
                    className={`field-icon icon-chevron animated ${selectActive.icon}`}
                    onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                ></span>
                {isComponentVisible ? (
                    <span onClick={() => setIsComponentVisible(false)}>
                        <UseInputSelectOptions inputData={inputData} />
                    </span>
                ) : null}
            </div>
        </>
    );
};

export default UseInputSelect;
