import React from "react";
import { SentMessageDetail } from "./SentMessageDetail";
import UseEditableField from "../../../useEditableField";
import { useTranslation } from "react-i18next";
import { SENT_MSG, DRAFTS_MSG, getDateFormated } from "../../../../hooks/Utils/Customers/MessagesUtils";
import { useDispatch } from "react-redux";
import { setExecuteQuery } from "../../../../actions/sectionActions";

export const DeleteMessageDetail = ({ msgData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const DELETED = t("{{capitalize, capitalize}}", { capitalize: t("deleted") });
    const EXPIRED = t("{{capitalize, capitalize}}", { capitalize: t("expired") });
    const previusSection = msgData.sentTime ? SENT_MSG.value : DRAFTS_MSG.value;

    const recoverAsDraftOnclick = () => {
        dispatch(
            setExecuteQuery({
                action: "recover-as-draft",
                messageId: msgData.idMessage,
            })
        );
    };
    const recoverDiv = () => {
        if (msgData.sentTime) {
            return null;
        }
        return (
            <div className="text-blue-600 cursor-pointer inline ml-4" onClick={recoverAsDraftOnclick}>
                {t("{{capitalize, capitalize}}", {
                    capitalize: t("recover-as-draft"),
                })}
            </div>
        );
    };
    return (
        <div className="col-span-3 grid col-gap-2 grid-cols-2 bg-white rounded">
            <div>
                <SentMessageDetail msgData={msgData} previusSection={previusSection} />
            </div>
            <div className="p-6">
                {/* //puede ser expirado */}
                <UseEditableField
                    data={{
                        label: "status",
                        labelAdjust: "font-bold text-gray-900",
                        value: msgData && msgData.state ? DELETED : EXPIRED,
                        date: null,
                        hour: null,
                        inEditionMode: false,
                        noEditionAdjust: `text-lg pb-6 ${msgData && msgData.deletedBy ? "" : "text-red-100"}`,
                        type: "text",
                    }}
                />
                <UseEditableField
                    data={{
                        label: msgData.sentTime ? "deleted date" : "moved-to-bin",
                        labelAdjust: "font-bold text-gray-900",
                        value: msgData.sentTime
                            ? getDateFormated(msgData.deletedTime)
                            : getDateFormated(msgData.deletedTime) +
                              ` <span class="text-gray-600">- ${t("{{capitalize, capitalize}}", {
                                  capitalize: t("deleted-by", {
                                      name: msgData && msgData.deletedBy ? msgData.deletedBy.fullName : " ",
                                  }),
                              })} -</span>`,
                        date: null,
                        hour: null,
                        inEditionMode: false,
                        noEditionAdjust: "text-lg pb-6",
                        type: "text",
                    }}
                />
                {/* si tiene sentTime => SENT // sino DRAFTS */}
                <div className="relative">
                    <div className="fieldLabel font-bold text-gray-900">
                        {t("{{capitalize, capitalize}}", { capitalize: t("previous-section") })}
                    </div>
                    <div className="text-gray-900 font-normal text-lg pb-6">
                        <span>
                            {msgData.sentTime
                                ? t("{{capitalize, capitalize}}", { capitalize: t(SENT_MSG.name) })
                                : t("{{capitalize, capitalize}}", { capitalize: t(DRAFTS_MSG.name) })}
                        </span>
                        {recoverDiv()}
                    </div>
                </div>
            </div>
        </div>
    );
};
// TODO: poner el fullName de deleted by
