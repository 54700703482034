import { capitalizeFirst } from "../../../Utils/Utils";

const DeleteTagModal = (name, id, t) => {
    return [
        {
            title: [name, "-", capitalizeFirst(t("delete")), "tag"],
            text: ["tag-delete-subtitle", "."],
            executeSectionQuery: true,
            id: id,

            inputs: [
                {
                    text: t("{{capitalize, capitalize}}", {
                        capitalize: t("delete-confirma"),
                    }),
                    type: "void",
                },
                {
                    value: { name: name, id: id },
                    type: "data",
                },
            ],

            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "delete",
                    style: "red",
                },
            ],
        },
    ];
};

export default DeleteTagModal;
