import React, { Children } from "react";

import InputColor from "./InputColor";
import InputNumber from "./InputNumber";
import InputText from "./InputText";
import InputCheck from "./InputCheck";
import InputAlignment from "./InputAlignment";
import InputOrientation from "./InputOrientation";
import InputLogo from "./InputLogo";
import InputIcon from "./InputIcon";

const Input = ({ children, toolbarID }) => {
    return (
        <>
            {Children.toArray(children).map((ch) => {
                const isReactElement = ch && typeof ch.type === "function";
                return React.cloneElement(ch, !isReactElement ? ch.props : { toolbarID: toolbarID });
            })}
        </>
    );
};

Input.Color = InputColor;
Input.Number = InputNumber;
Input.Text = InputText;
Input.Check = InputCheck;
Input.Alignment = InputAlignment;
Input.Orientation = InputOrientation;
Input.Logo = InputLogo;
Input.Icon = InputIcon;

export default Input;
