import { capitalizeFirst, uncapitalizeFirst } from "../../../Utils/Utils";
import DeleteGroupChannel from "../modals/DeleteGroupChannel";
const ChannelGroup = (channelGroup, t) => {
    const { id, name, gridsNum, channelsNum } = channelGroup;
    const dataType = "channelGroup";
    return {
        dataType: dataType,
        id: id,
        adjust: "h-auto",
        actions: [
            {
                type: "shop",
                value: id,
            },
        ],
        name: name,
        t: t,
        canBeDeleted: gridsNum > 0 ? false : true,
        deleteWarning: channelsNum > 0 ? t("x-channels-added", { x: channelsNum }) : null,
        actionComponentStyle: { left: "-160px" },
        info: [
            {
                value: name,
                link: `/services/tv-channels/groups/${id}`,
                cellContent: "link",
                specialWidth: "w-3/12 ",
            },
            {
                cellContent: "text",
                value: `${channelsNum} ${uncapitalizeFirst(t("channels_"))}`,
                specialWidth: "w-3/12 pl-1",
                wrap: "truncate",
            },
            {
                cellContent: "text",
                value: `${gridsNum} ${uncapitalizeFirst(t("grids"))}`,
                specialWidth: "w-5/12 pl-1",
                wrap: "truncate",
            },
            {
                value: capitalizeFirst(t("edit")),
                link: `/services/tv-channels/groups/${id}`,
                cellContent: "link",
                specialWidth: "w-0.5/12 ",
                noFontBold: true,
            },
            {
                cellContent: "icon",
                value: id,
                evaluate: true,
                specialWidth: "w-0.5/12",
                isAction: true,
                modal: [DeleteGroupChannel({ id, name, channelsNum }, t)],
                name: "group-channel",
                disabledIcon: gridsNum > 0 ? true : false,
                tooltip: gridsNum > 0 ? t("this-group-use-in-some-grid") : "",
                icon: {
                    name: "delete",
                    values: id,
                    itemType: dataType,
                },
            },
        ],
    };
};

export default ChannelGroup;
