import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BIN_MSG } from "../../../../hooks/Utils/Customers/MessagesUtils";

const MessagesBinWarning = () => {
    const { t } = useTranslation();
    const { folder } = useSelector((state) => state.table);

    if (!folder || (folder && folder.name !== BIN_MSG.name)) {
        return null;
    }
    return <div className="text-gray-800 font-normal -mt-6 pb-14">{t("warning-bin-messages")}</div>;
};

export default MessagesBinWarning;
