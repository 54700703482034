import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Actions
import { changeActionValues } from "../actions/globalActions";
//Utils
import { useTranslation } from "react-i18next";
import useUpload from "../hooks/useUpload";
import { toast } from "react-toastify";

const UseFile = ({ data }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { name, ph, contentTypes, triggerAction, ref } = data;
    const actionData = useSelector((state) => state.action);

    const { view } = useUpload({
        placeholder: t(ph),
        contentTypes,
        onSuccess: (files) => {
            dispatch(changeActionValues({ [name]: files }));
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    useEffect(() => {
        if (triggerAction) {
            if (actionData.values && actionData.values[name] && actionData.values[name].length > 0) {
                document.querySelector("[data-action='" + triggerAction + "']").click();
                setTimeout(function () {
                    if (ref) {
                        let dataRef = document.querySelector("[data-ref='" + ref + "']");
                        if (dataRef) {
                            dataRef.value = null;
                        }
                    }
                }, 200);
            }
        }
        // eslint-disable-next-line
    }, [actionData.values[name]]);

    return view;
};
export default UseFile;
