const translate = (term, value) => {
    const viewDetail = "Ver detalle";
    const translations = {
        //Table
        "filter-by": "Filtrar por: ",
        loading: "Cargando",
        "no-results": "Lo sentimos, no hemos encontrado ningún resultado.",
        "showing-all": "Está visualizando todos los resultados disponibles.",
        "showing-all-projects": "Estos son todos los proyectos disponibles.",

        //Table search
        search: "Buscar",
        "search-hotel": "Buscar hotel",

        //Table filter select checkboxes
        "smart-tv": "Smart TV",
        stb: "STB",
        chromecast: "Chromecast",
        "access-point": "P. de acceso",
        cleaning: "Limpieza",
        capacityRange: "Aforo",
        capacity: "Aforo",

        //Table filter select search
        room: "Habitación",
        wing: "Ala",
        floor: "Planta",
        "": "Todo",
        all: "Todo",
        "capacityRange-area-capacity-low": "Bajo",
        "capacityRange-area-capacity-medium": "Medio",
        "capacityRange-area-capacity-high": "Alto",

        //Table filter select
        warning: "Aviso",
        state: "Estado",
        status: "Estado",

        //Device warnings
        "warning-device-0": "Offline",
        "warning-device-1": "Online",
        "warning-device-2": "No asignado",
        "warning-device-3": "No autorizado",
        "warning-device-4": "Deshabilitado",
        "warning-device-": "Todos",

        //chromecast
        "warning-chromecast-0": "Offline",
        "warning-chromecast-1": "Online",
        "warning-chromecast-2": "No asignado",
        "warning-chromecast-3": "No autorizado",
        "warning-chromecast-4": "Deshabilitado",
        "warning-chromecast-": "Todos",

        //TV
        "warning-tv-0": "Offline",
        "warning-tv-1": "Online",
        "warning-tv-2": "Deshabilitada",
        "warning-tv-": "Todos",

        //STB
        "warning-stb-0": "Offline",
        "warning-stb-1": "Online",
        "warning-stb-2": "Deshabilitado",
        "warning-stb-": "Todos",

        //access-point
        "warning-access-point-0": "Offline",
        "warning-access-point-1": "Online",
        "warning-access-point-2": "Desasignado",
        "warning-access-point-3": "Deshabilitado",
        "warning-access-point-": "Todos",

        //room
        "warning-room-0": "Offline",
        "warning-room-1": "Online",
        "warning-room-": "Todos",

        //room status
        "status-room-0": "Vacía",
        "status-room-1": "Ocupada",
        "status-room-": "Todas",

        "guest-room": value === "0" ? "Sin huéspedes" : "",

        //Fake actions
        "batch-title": "Acciones en lote",
        "no-batch": "Los elementos seleccionados no tienen acciones en lote posibles",
        assign: "Asignar",
        unassign: "Desasignar",
        "move-room": "Mover de habitación",
        "change-name": "Cambiar de nombre",
        "chromecast-change-name": "Cambiar de nombre",
        "change-pairing": "Cambiar código pareo",
        reboot: "Reiniciar",
        "factory-reset": "Restaurar de fábrica",
        disable: "Deshabilitar",
        enable: "Habilitar",
        "no-actions": "No hay acciones disponibles",

        //Table total count
        "theres-not": "No hay",
        elements: "elementos",

        //Table headers
        device: "Dispositivo",
        brand: "Marca",
        model: "Modelo",
        name: "Nombre",
        "last-activity": "Última visualización",
        ip: "IP",
        guest: "Huésped",
        stay: "Estancia",
        surname: "Apellidos",
        country: "País",
        lang: "Idioma",
        language: "Idioma",
        signupDate: "Registro",
        lastAccess: "Último acceso",
        roomAmount: "Nº Hab.",
        roomPopulation: "Nº Huésp.",
        checkInOut: "Llegada / Salida",
        bookingStatus: "Estado",

        "staff-roles": "Rol staff",
        "booking-confirmed": "Reservas confirmadas",
        "booking-pending": "Reservas pendientes",
        confirmed: "confirmadas",
        pending: "pendientes",

        //Table pagination
        prev: "Anterior",
        next: "Siguiente",

        //Table Rows
        mac: "MAC",
        "wifi-signal": "Señal WiFi",
        assignedTv: "TV Asignada",
        location: "Ubicación",
        "pairing-code": "Código de pareo",
        version: "Versión",
        "license-profile": "Perfil de licencia",

        //Time Ago
        days: "días",
        minutes: "min.",
        hours: "h.",
        timeAgo: "Hace ",

        //Device activity
        home: "Pantalla de inicio",
        apps: "Aplicaciones",
        "room-service": "Room service",
        instructions: "Instrucciones",
        casting: "Casteando",

        //Sections
        "monitor-of": "Monitorización de ",
        "list-of": "Listado de ",
        "map-of": "Mapa de ",
        chromecasts: "Chromecasts",
        hotel: "Hotel",
        monitoring: "Monitorización",
        devices: "Dispositivos",
        tvs: "TVs",
        stbs: "STBs",
        "access-points": "Puntos de acceso",
        rooms: "Habitaciones",

        //Main menu
        design: "Diseño",
        tv: "TV",
        advertising: "Publicidad",
        mobile: "Móvil",

        //Sidebar
        installation: "Instalación",
        map: "Mapa",
        "wifi-networks": "Redes WiFi",
        "service-config": "Configuración servicios",

        //For buttons
        export: "Exportar",
        "see-all-days": "Ver todos los días",
        "finish-save": "Finalizar",

        //Dashboard Cards
        warnings: "Avisos",
        floors: "Plantas",
        "hotel-wings": "Alas del hotel",

        //Extended row info
        "signal-level": "Nivel de señal",
        "activity-time": "Tiempo de actividad",
        "living-room": "Salón",

        //Hidden headers of table
        preview: "Previsualizar",
        detail: viewDetail,
        "detail-link": viewDetail,
        "view-detail": viewDetail,
        "booking-management": "Gestionar reservas",
        "last-stay": "Última estancia",
        "preview-link": "Previsualizar",
        "batch-execute": "Aplicar",

        //Hotel map
        wings: "Alas",
        "no-hotel-map": "Lo sentimos, no hay datos del mapa de intalación del hotel",

        /* Modal buttons */
        cancel: "Cancelar",
        confirm: "Confirmar",
        "save-all": "Guardar",
        save: "Guardar",
        accept: "Aceptar",
        edit: "Editar",

        /* Common zone translation terms */
        area: "Área",
        areas: "areas",
        number: "Número",
        "booking-number": "Nº de reservas",
        booking: "reservas",
        view: "Ver",
        delete: "Borrar",
        "booking-allowed": "Admite reservas",
        schedule: "Horario",
        "capacity-limit": "Límite aforo",
        people: "personas",
        "%": "%",
        add: "Añadir",
        newo: "nuevo",
        newa: "nueva",
        "booking-allowed-area-true": "Si",
        "booking-allowed-area-0": "No",
        open: "Abrir",
        "ommit-step": "Omitir paso",
        "add-booking": "Añadir reserva",
        "booking-modify": "Modificar",
        "select-state": "Por favor, cambie el estado para modificar la reserva.",
        "booking-updated": "La reserva se ha modificado con éxito",
        "cancel-booking": "Cancelar reserva",

        //Wiz<ard button
        "exit-unsaved": "Salir sin guardar",
        "save-and-close": "Guardar y cerrar",

        //COVID to relocate from tablerow actions
        "edit-description": "Editar descripción",
        "area-edit-description": "Editar descripción",
        "temp-close": "Cerrar temporalmente",
        "area-temp-close": "Cerrar temporalmente",
        "area-open": "Abrir área",
        duplicate: "Duplicar",
        "area-duplicate": "Duplicar",
        "area-erase": "Eliminar",
        erase: "Eliminar",
        description: "Descripción",
        "temp-closed-0": "Cerrado temporalmente",
        "temp-closed": "Cerrado temporalmente",
        "temp-closed-1": "Abierto",
        categories: "Categorías",
        quantity: "Cantidad",
        "area-activity": "Área / Actividad",
        "last-cleaning": "Última limpieza",
        responsible: "Responsable",
        category: "Categoría",
        "view-history": "Ver Histórico",
        refresh: "Actualizar",
        "refresh-cleaning": "Actualizar",
        unspecified: "Sin especificar",
        "cat-must-empty": "Para poder eliminar una categoría debe estar vacía",
        "move-category": "Mover de categoría",
        "area-move-category": "Mover de categoría",
        "staff-generate-password": "Generar contraseña",
        "staff-edit": "Editar usuario",
        "staff-delete": "Eliminar usuario",

        //Schedule
        "skip-step": "Omitir paso",
        "continue-2": "Continuar al paso 2",
        "continue-3": "Continuar al paso 3",

        //Cleanin status
        "status-area-cleaning-0": "Sin limpiar",
        "status-area-cleaning-1": "Limpio",

        //Capacity monitor
        "current-capacity": "Aforo actual",
        "last-refresh": "Última actualización",
        "edit-current-capacity": "Editar aforo actual",

        //Booking main
        hour: "Hora",
        "people-header": "Personas",
        phone: "Teléfono",
        email: "Email",
        modify: "Modificar",
        "new-booking": "Nueva reserva",
        "error-mutation": "Lo sentimos, ha habido un problema con la operación.",
        "new-edit-error": "Por favor, guarde o cancele los cambios antes de empezar a crear otro registro.",
        "multistaff-edit-error":
            "Por favor, guarde o cancele los cambios en el miembro del staff antes de empezar a editar otro miembro.",

        //Booking status
        "state-booking-0": "Pendiente",
        "state-booking-1": "Confirmada",
        "state-booking-2": "Cancelada",
        "state-booking-3": "Cancelada",
        "state-booking-2,3": "Cancelada",
        "manage-bookings": "Gestionar reservas",
        "error-book-past": "Lo sentimos, no es posible reservar en una fecha pasada",
        "booking-status-changed": "El estado de la reserva se ha cambiado con éxito.",
        "booking-status-error": "Lo sentimos, ha habido un error al actualizar el estado de la reserva.",
        "cancel-booking-title": "Cancelar reserva",
        "cancel-booking-text": "¿Está seguro de que desea cancelar esta reserva? Esta acción no se puede deshacer.",

        Cleaning: "Limpieza",
        "Occupation Control": "Aforo",

        //Password reminder
        "send-mail": "Enviar correo",

        //Cell titles
        //customers
        "title-treatment": "Tratamiento",
        "title-birthDate": "Nacimiento",
        "title-phone": "Teléfono",
        //Schedule result messages
        "schedule-enable-ok": "El horario se ha activado correctamente.",
        "schedule-disable-ok": "El horario se ha desactivado correctamente.",
        "schedule-delete-ok": "El horario se ha eliminado correctamente.",
        "schedule-update-ok": "El horario se ha actualizado correctamente.",
    };
    return translations[term];
};

export default translate;
