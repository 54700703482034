import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//Components
import UseSectionHeader from "../../useSectionHeader";
import UseRadioDispatcher from "../../useRadioDispatcher";
//Utils
import SetActivitySchedule from "../../../hooks/GraphqlCalls/CommonZones/SetActivitySchedule";
import { useParams } from "react-router-dom";

const AreaSchedule = () => {
    //Store data
    const { sectionContent } = useSelector((state) => state);

    //Data
    const { id } = useParams();

    //States
    const [showContent, setShowContent] = useState(false);
    SetActivitySchedule();
    useEffect(() => {
        if (sectionContent.eventTypes && sectionContent.areaID === id) {
            setShowContent(true);
        }
        // eslint-disable-next-line
    }, [sectionContent]);
    return (
        <>
            {showContent ? (
                <>
                    <UseSectionHeader title={["schedules", "of"]} breadcrumbsLimit={2} />
                    <UseRadioDispatcher />
                </>
            ) : null}
        </>
    );
};

export default AreaSchedule;
