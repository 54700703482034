import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import { TRANSLATE_SCREEN_TYPE_TITLE } from "hooks/Utils/Design/EditorUtils";

const PublishContent = (t, type, title, unpublish, noFilters) => {
    let titleText = `- ${capitalizeFirst(t(unpublish ? "unpublish" : "publish"))}`;
    let description = `${t(unpublish ? "unpublish-confirm" : "publish-confirm").replace(
        "%0",
        t(TRANSLATE_SCREEN_TYPE_TITLE[type])
    )}.`;
    if (noFilters && !unpublish) {
        titleText += ` ${t("and")} ${t("make-default")}`;
        description = `${t("publish-and-make-default-confirm")
            .replace("%0", title)
            .replace("%1", t(TRANSLATE_SCREEN_TYPE_TITLE[type]))}.`;
    }
    return {
        titlePrefix: title,
        title: titleText,
        executeSectionQuery: true,
        bodyAdjust: "w-3/12",
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: unpublish ? "unpublish" : "publish",
                style: unpublish ? "red" : "blue",
                action: unpublish ? "unpublish-content" : "publish-content",
            },
        ],
        inputs: [
            {
                text: description,
                type: "void",
            },
        ],
    };
};

export default PublishContent;
