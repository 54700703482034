const Cleaning = (category, areaCleaning, index, sortCriteria) => {
    const dataType = "area-cleaning";
    const { categoryID, categoryName } = category;

    const { id, lastCleaning, name, responsibleName, responsibleID } = areaCleaning;
    const lastCleaningDate = new window.ZDate(lastCleaning);

    return {
        dataType: dataType,
        id: id,
        category: getCategoryID(index, sortCriteria, categoryID),
        categoryName: getCategoryName(index, sortCriteria, categoryName),

        info: [
            {
                name: "area",
                cellContent: "link",
                value: name,
                subvalue: sortCriteria === "" ? null : categoryName,
                link: `/common-zones/zones-configuration/areas`,
                parametersSection: categoryID,
                specialWidth: "w-3/12 break-all",
            },
            {
                name: "last-cleaning",
                cellContent: "text",
                value: `${lastCleaningDate.toLocaleDateString()} ${lastCleaningDate.toLocaleString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                })}`,
                specialWidth: "w-3/12",
            },
            {
                name: "responsible",
                cellContent: "text",
                value: responsibleName,
                specialWidth: "w-4/12",
            },
            {
                name: "refresh-cleaning",
                cellContent: "button",
                areaName: name,
                value: id,
                translate: true,
                isButton: true,
                buttonColor: "blue",
                specialWidth: "w-2/12 pr-4",
                currentResponsibleName: responsibleName,
                currentResponsibleID: responsibleID,
            },
        ],
    };
};

export const getCategoryID = (index, sortCriteria, categoryID) => {
    let res;
    if (index === 0 && sortCriteria === "") {
        res = categoryID;
    } else {
        res = false;
    }
    return res;
};

export const getCategoryName = (index, sortCriteria, categoryName) => {
    let res;
    if (index === 0 && sortCriteria === "") {
        res = categoryName;
    } else {
        res = false;
    }
    return res;
};

export default Cleaning;
