import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Input from "./CommonOptions/Input";
import { EditorContext } from "../../../../../contexts/editor";

const TopBarToolbar = () => {
    const { screenType } = useContext(EditorContext);
    const { t } = useTranslation();
    const isLanding = screenType === "MOBILE";

    return (
        <>
            {!isLanding ? (
                <Toolbar type="settings">
                    <Settings>
                        <Settings.Group title={t("configuration")}>
                            <Input.Text id="title" title={t("title")} />
                        </Settings.Group>
                    </Settings>
                </Toolbar>
            ) : null}
            <Toolbar type="style">
                <Settings>
                    <Settings.Group type="colors">
                        <Input.Color id="fgColor" title={t("foreground")} />
                        <Input.Color id="bgColor" title={t("background")} />
                        <Input.Color id="fgActiveColor" title={t("arrow")} />
                    </Settings.Group>
                </Settings>
            </Toolbar>
        </>
    );
};

export default TopBarToolbar;
