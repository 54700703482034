export const SET_SECTION_CONTENT = "SET_SECTION_CONTENT";
export const CLEAN_SECTION_CONTENT = "CLEAN_SECTION_CONTENT";
export const SET_REFRESH_DATA = "SET_REFRESH_DATA";
export const SET_WIZARD_STEP = "SET_WIZARD_STEP";
export const SET_EVENT_TYPE = "SET_EVENT_TYPE";
export const SET_EXECUTE_QUERY = "SET_EXECUTE_QUERY";
export const SCHEDULE_PUNCTUAL_FORM = "SCHEDULE_PUNCTUAL_FORM";
export const SCHEDULE_PERIODIC_FORM = "SCHEDULE_PERIODIC_FORM";
export const ADD_COMMON_SCHEDULE = "ADD_COMMON_SCHEDULE";
export const SAVE_PUNCTUAL_SCHEDULE = "SAVE_PUNCTUAL_SCHEDULE";
export const SHOW_ROW_FORM = "SHOW_ROW_FORM";
export const REFRESH_PERIODIC_SCHEDULE = "REFRESH_PERIODIC_SCHEDULE";
export const SCHEDULE_PUNCTUAL_EDIT = "SCHEDULE_PUNCTUAL_EDIT";
export const SCHEDULE_PERIODIC_EDIT = "SCHEDULE_PERIODIC_EDIT";
export const SCHEDULE_FILTER_PERIODIC = "SCHEDULE_FILTER_PERIODIC";
export const SHOW_HIDDEN_ROWS = "SHOW_HIDDEN_ROWS";
export const CHANGE_PUNCTUAL_STATUS = "CHANGE_PUNCTUAL_STATUS";
export const CHANGE_PERIODIC_STATUS = "CHANGE_PERIODIC_STATUS";
export const DELETE_PUNCTUAL_VIEW = "DELETE_PUNCTUAL_VIEW";
export const DELETE_PERIODIC_VIEW = "DELETE_PERIODIC_VIEW";
export const RESTORE_PERIODIC_SCHEDULE_BACKUP = "RESTORE_PERIODIC_SCHEDULE_BACKUP";
export const EDIT_COMMON = "EDIT_COMMON";
export const EDIT_SPECIAL = "EDIT_SPECIAL";
export const EDIT_FESTIVE = "EDIT_FESTIVE";
export const SET_SECTION_NAME = "SET_SECTION_NAME";
export const SET_ALLOW_WIZARDNEXT = "SET_ALLOW_WIZARDNEXT";
export const SET_PARAMETERS_SECTION = "SET_PARAMETERS_SECTION";
export const CLEAN_PARAMETERS_SECTION = "CLEAN_PARAMETERS_SECTION";
export const SET_REFRESH_CONTENT_SHOP_DATA = "SET_REFRESH_CONTENT_SHOP_DATA";
export const SET_SHOP_DATA_HEAD_MENU = "SET_SHOP_DATA_HEAD_MENU";
export const SET_SHOP_TOKEN = "SET_SHOP_TOKEN";

export function setSectionContent(content) {
    //Loads obj in store with data & config for entire section
    return (dispatch) => {
        dispatch({
            type: SET_SECTION_CONTENT,
            payload: content,
        });
    };
}

export function cleanSectionContent() {
    //Reset to initial state the SectionContent
    return (dispatch) => {
        dispatch({
            type: CLEAN_SECTION_CONTENT,
        });
    };
}

export function setRefreshContentData(content) {
    //Loads obj in store with data & config for entire section
    return (dispatch) => {
        dispatch({
            type: SET_REFRESH_DATA,
            payload: content,
        });
    };
}
export function setRefreshContentShopData(content) {
    //Loads obj in store with data & config for entire section
    return (dispatch) => {
        dispatch({
            type: SET_REFRESH_CONTENT_SHOP_DATA,
            payload: content,
        });
    };
}

export function setSaveHeadMenu(content) {
    //Loads obj in store with data & config for entire section
    return (dispatch) => {
        dispatch({
            type: SET_SHOP_DATA_HEAD_MENU,
            payload: content,
        });
    };
}

export function setShopToken(content) {
    return (dispatch) => {
        dispatch({
            type: SET_SHOP_TOKEN,
            payload: content,
        });
    };
}

export function setExecuteQuery(content) {
    //Loads obj in store with data & config for entire section
    return (dispatch) => {
        dispatch({
            type: SET_EXECUTE_QUERY,
            payload: content,
        });
    };
}

export function setWizardStep(step) {
    //Loads number in store.currentStep for schedule wizard process
    return (dispatch) => {
        dispatch({
            type: SET_WIZARD_STEP,
            payload: step,
        });
    };
}

export function setActiveEventType(eventType) {
    //Loads string in store.eventTypeActive with data & config for entire section
    return (dispatch) => {
        dispatch({
            type: SET_EVENT_TYPE,
            payload: eventType,
        });
    };
}

export function showAddPunctualSchedule(config) {
    //Loads bool in store.schedulePunctualForm to show or hide create form
    return (dispatch) => {
        dispatch({
            type: SCHEDULE_PUNCTUAL_FORM,
            payload: config,
        });
    };
}

export function showEditPunctualSchedule(config) {
    //Loads bool in store.schedulePunctualForm to show or hide edit form
    return (dispatch) => {
        dispatch({
            type: SCHEDULE_PUNCTUAL_EDIT,
            payload: config,
        });
    };
}

export function showEditCommonSchedule(config) {
    //Loads bool in store.editCommonForm to show or hide edit form
    return (dispatch) => {
        dispatch({
            type: EDIT_COMMON,
            payload: config,
        });
    };
}

export function showEditSpecialSchedule(config) {
    //Loads bool in store.editSpecialForm to show or hide edit form
    return (dispatch) => {
        dispatch({
            type: EDIT_SPECIAL,
            payload: config,
        });
    };
}

export function showEditFestiveSchedule(config) {
    //Loads bool in store.editFestiveForm to show or hide edit form
    return (dispatch) => {
        dispatch({
            type: EDIT_FESTIVE,
            payload: config,
        });
    };
}

export function showAddPeriodicSchedule(config) {
    //Loads bool in store.schedulePeriodicForm to show or hide create form
    return (dispatch) => {
        dispatch({
            type: SCHEDULE_PERIODIC_FORM,
            payload: config,
        });
    };
}

export function addSectionPunctualSchedule(punctualSchedule) {
    //Loads object in store.punctualEventsData to show schedule in view
    return (dispatch) => {
        dispatch({
            type: SAVE_PUNCTUAL_SCHEDULE,
            payload: punctualSchedule,
        });
    };
}

export function showRowForm(config) {
    //Uses given bool to show or hide row form
    return (dispatch) => {
        dispatch({
            type: SHOW_ROW_FORM,
            payload: config,
        });
    };
}

export function refreshPeriodicSchedule(newPeriodicEventData) {
    //Uses given timetable to update proper periodic schedule store.periodicEventsData
    return (dispatch) => {
        dispatch({
            type: REFRESH_PERIODIC_SCHEDULE,
            payload: newPeriodicEventData,
        });
    };
}

export function filterPeriodicSchedules(filter) {
    //Loads string in store.periodicFilter to filter schedule display y 3 steps wizard
    return (dispatch) => {
        dispatch({
            type: SCHEDULE_FILTER_PERIODIC,
            payload: filter,
        });
    };
}

export function setHiddenRows(congif) {
    //Uses given bool to change visibility of hidden row due to prior edit process
    return (dispatch) => {
        dispatch({
            type: SHOW_HIDDEN_ROWS,
            payload: congif,
        });
    };
}

export function setPunctualViewStatus(congif) {
    //Uses given config to update proper periodic schedule status from store.punctualEventsData
    return (dispatch) => {
        dispatch({
            type: CHANGE_PUNCTUAL_STATUS,
            payload: congif,
        });
    };
}

export function setPeriodicViewStatus(congif) {
    //Uses given config to update proper periodic schedule status from store.periodicEventsData
    return (dispatch) => {
        dispatch({
            type: CHANGE_PERIODIC_STATUS,
            payload: congif,
        });
    };
}

export function deletePunctualView(id) {
    //Uses given id to remove object from store.punctualEventsData
    return (dispatch) => {
        dispatch({
            type: DELETE_PUNCTUAL_VIEW,
            payload: id,
        });
    };
}

export function deletePeriodicView(id) {
    //Uses given id to remove object from store.periodicEventsData
    return (dispatch) => {
        dispatch({
            type: DELETE_PERIODIC_VIEW,
            payload: id,
        });
    };
}

export function restorePeriodicScheduleBackup(periodicScheduleBackup) {
    //loads schedule backup into view when editing process is canceled
    return (dispatch) => {
        dispatch({
            type: RESTORE_PERIODIC_SCHEDULE_BACKUP,
            payload: periodicScheduleBackup,
        });
    };
}

export function setSectionName(sectionName) {
    //Sets string in store.sectionName of section name
    return (dispatch) => {
        dispatch({
            type: SET_SECTION_NAME,
            payload: sectionName,
        });
    };
}

export function setWizardAllowNext(config) {
    //Sets bool in store.allowWizardNext to allow use of next button in schedules wizard
    return (dispatch) => {
        dispatch({
            type: SET_ALLOW_WIZARDNEXT,
            payload: config,
        });
    };
}

export function setParametersSection(parameters) {
    //Sets parameters for section componet
    return (dispatch) => {
        dispatch({
            type: SET_PARAMETERS_SECTION,
            payload: parameters,
        });
    };
}

export function cleanParametesSection() {
    //Clean parametes of section loaded
    return (dispatch) => {
        dispatch({
            type: CLEAN_PARAMETERS_SECTION,
        });
    };
}
