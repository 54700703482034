import React from "react";

const defaultContext = {
    lang: null, // en, fr...
    theme: null, // {...}
    screenType: null, // TV, MOBILE, GENERAL...
    deviceType: null, // TV, Mobile
    editorSize: null, // { width, height, my, mx, cols, rows, isBounded }
    itemSelected: null, // Selected item id
};

export const EditorContext = React.createContext(defaultContext);
export const EditorProvider = ({ children, value }) => {
    return (
        <EditorContext.Provider
            value={{
                ...defaultContext,
                ...value,
            }}
        >
            {children}
        </EditorContext.Provider>
    );
};

const defaultWidgetContext = {
    type: null, // TEXT, LOGO, IMAGE...
    id: 0, // widget ID
    container: null, // Widget container {}
    style: null, // Widget style data
    config: {
        style: null,
        data: null,
    },
};
export const WidgetContext = React.createContext(defaultWidgetContext);

export const WidgetProvider = ({ children, value }) => {
    return (
        <WidgetContext.Provider
            value={{
                ...defaultWidgetContext,
                ...value,
            }}
        >
            {children}
        </WidgetContext.Provider>
    );
};
