import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useComponentVisible from "../useComponentVisible";
import UseSelectOptionWithPreview from "./useSelectOptionWithPreview";
import ReactTooltip from "components/ReactTooltip";
import { useDispatch, useSelector } from "react-redux";
import { changeActionValues } from "../../actions/globalActions";

const useSelectWithPreview = ({ selectData, onSelect }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    //select data
    const { validations } = useSelector((state) => state.action);
    const { name, ph, options, optionSelected, disabled, adjust, cHeight, fixed } = selectData;
    //states
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [activeValue, setActiveValue] = useState(optionSelected);
    const [selectActive, setSelectActive] = useState({});
    const [selectOptions, setSelectOptions] = useState(options);
    const [fixedStyle, setFixedStyle] = useState({ position: "sticky", top: "0px", zIndex: "501", width: "25rem" });
    let lastOptGroup = null;

    //Listeners
    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({});
        } else {
            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
            });
        }
        if (fixed) {
            const base = document.getElementById(name);
            if (base) {
                setFixedStyle({
                    ...fixedStyle,
                    top: base.getBoundingClientRect().top + 35,
                });
            }
        }
        // eslint-disable-next-line
    }, [isComponentVisible]);

    const saveOptionSelected = (option) => {
        setActiveValue(option);
        if (optionSelected) {
            setSelectOptions(
                selectOptions.map((_optionSel) => {
                    if (option.id === _optionSel.id) {
                        return { ...option, disabled: true };
                    } else if (_optionSel.id === optionSelected.id) {
                        return { ..._optionSel, disabled: false };
                    } else {
                        return { ..._optionSel };
                    }
                })
            );
        }
        if (onSelect && typeof onSelect === "function") {
            onSelect(option);
        } else {
            dispatch(changeActionValues({ [name]: option.value }));
        }
    };

    const addOptGroup = (optGroup) => {
        const response = renderOptGroup({ optGroup, lastOptGroup, t });
        if (optGroup) {
            lastOptGroup = optGroup;
        }
        return response;
    };

    return (
        <div
            id={name}
            ref={ref}
            className={`w-full mt-1 h-10 bg-gray-200 rounded relative ${adjust} ${selectActive.container} ${
                typeof validations[name] !== "undefined" && !validations[name] ? "border border-red-100" : ""
            }`}
        >
            <div
                className={`t-filter-ph ${selectActive.text} ${activeValue && "text-gray-900"}`}
                onClick={!disabled ? () => setIsComponentVisible(!isComponentVisible ? true : false) : undefined}
            >
                {renderSelectedLabel({ activeValue, ph, name, t })}
            </div>
            <span
                className={`field-icon icon-chevron animated ${selectActive.icon} ${
                    disabled ? "disabled" : "text-gray-900"
                }`}
                onClick={!disabled ? () => setIsComponentVisible(!isComponentVisible ? true : false) : undefined}
            ></span>
            {isComponentVisible ? (
                <>
                    <div
                        // className={`${fixed ? "w-104 fixed contents z-100" : ""}`}
                        style={fixed ? fixedStyle : {}}
                        onClick={() => setIsComponentVisible(false)}
                    >
                        <div
                            className={`bg-white rounded shadow-lg ${
                                cHeight ? cHeight : " max-h-64"
                            } border border-gray-200 overflow-y-auto p-2`}
                        >
                            {selectOptions
                                .sort((optionA, optionB) => optionA.optGroup.localeCompare(optionB.optGroup))
                                .map((option, index) => (
                                    <Fragment key={option.value}>
                                        {addOptGroup(option.optGroup)}
                                        <UseSelectOptionWithPreview
                                            option={option}
                                            index={index}
                                            selected={activeValue}
                                            disabled={option.disabled}
                                            select={() => saveOptionSelected(option)}
                                        />
                                    </Fragment>
                                ))}
                        </div>
                    </div>
                    <ReactTooltip
                        place="bottom"
                        type="light"
                        offset={{ top: -8, left: -8 }}
                        html={true}
                        border={true}
                        borderColor="#D3DAE1"
                    />
                </>
            ) : null}
        </div>
    );
};

const renderSelectedLabel = (props) => {
    const { activeValue, ph, name, t } = props;
    let response = null;
    if (!activeValue) {
        response = ph ? t(ph) : t(name);
    } else {
        response = activeValue.display || activeValue.value;
    }
    return response;
};

const renderOptGroup = (props) => {
    const { optGroup, lastOptGroup, t } = props;
    let response = [];
    if (optGroup) {
        if (optGroup !== lastOptGroup) {
            if (lastOptGroup) {
                response.push(
                    <Fragment key={optGroup}>
                        <div className="h-0 border-gray-200 border-b my-4"></div>
                        <p className="font-bold text-gray-900 first-capital mt-1 mb-4">{t(optGroup)}</p>
                    </Fragment>
                );
            } else {
                response.push(
                    <p key={optGroup} className="font-bold text-gray-900 first-capital mt-1 mb-4">
                        {t(optGroup)}
                    </p>
                );
            }
        }
    }
    return response;
};

export default useSelectWithPreview;
