export const formatDate = (lastUpdate) => {
    if (!lastUpdate) return null;
    const lastUpdateFormat = new Date(lastUpdate);
    const lastUpdateDate = lastUpdateFormat.toLocaleString({
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    });

    return lastUpdateDate;
};
