import React from "react";
import { useDispatch, useSelector } from "react-redux";
//Actions
import { setSpecialDateValue, setLastScheduleEdition } from "../../actions/scheduleActions";

const UseInputTimeSpecialSpecific = ({ dateId, validation, index }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);

    //Actions
    const dispatch = useDispatch();

    const changeScheduleHandler = (e) => {
        dispatch(
            setSpecialDateValue({
                valueKey: dateId,
                value: e.target.value,
                index: index,
            })
        );
        dispatch(setLastScheduleEdition());
    };

    return (
        <>
            <input
                type="time"
                className={`t-filter-search w-24 mx-0 my-0 ${!validation ? "border border-red-100" : ""}`}
                placeholder="hh:mm"
                onBlur={changeScheduleHandler}
            ></input>
            {!validation ? (
                <p className="absolute text-red-100 first-capital">{langStrings["schedule-error"]}</p>
            ) : null}
        </>
    );
};

export default UseInputTimeSpecialSpecific;
