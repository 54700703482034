import React, { useEffect, useRef, useState } from "react";
import UseInputText from "../../../../../Inputs/useInputText";
import UseInputPercent from "../../../../../Inputs/useInputPercent";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeT } from "../../../../../../hooks/Utils/Utils";
import { getRGBA, hexToRgbA, rgbaToHex } from "../../../../../../hooks/Utils/DesignUtils";
import { setColorPickerReference } from "../../../../../../actions/designActions";
import { setSectionContent } from "../../../../../../actions/sectionActions";
import { decodeID, ID, SALES_OPTIONS_KEYS } from "../../../../../../hooks/Utils/Design/SalesUtils";

const UseColors = ({ data, group, showTitle }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { styleInputData } = useSelector((state) => state.sectionContent);
    const [inputHexFgError, setInputHexFgError] = useState(false);
    const [inputHexBgError, setInputHexBgError] = useState(false);
    const [inputHexIconError, setInputHexIconError] = useState(false);

    const _key = useRef();
    _key.current = styleInputData?.key;

    const createPickerCallBack = () => {
        window.listOption = styleInputData?.key?.listOption;
        window.onSelectColorCallback = (params) => {
            const { color, property, group } = params;
            onBlurAction(color, ID(property, group));
        };
    };

    useEffect(() => {
        if (window?.listOption || typeof window?.onSelectColorCallback !== "function") {
            createPickerCallBack();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (window.listOption !== _key.current?.listOption) {
            createPickerCallBack();
        }
        // eslint-disable-next-line
    }, [styleInputData]);

    const changeInputsFields = (value, id) => {
        setInputHexBgError(false);
        setInputHexFgError(false);
        setInputHexIconError(false);

        const { field, group } = decodeID(id);
        const colorID = ID(field, group);
        const opacityID = ID(`${field}-opacity`, group);

        const _hex = document.getElementById(colorID).value;
        const _alpha = document.getElementById(opacityID).value.replace(" %", "");
        if (!_hex || _hex !== "") {
            try {
                const _rgbaColor = hexToRgbA(_hex, _alpha);
                onBlurAction(_rgbaColor, id);
            } catch (error) {
                handleError(field);
            }
        }
    };

    const handleChangeAlpha = (value, id) => {
        setInputHexBgError(false);
        setInputHexFgError(false);
        setInputHexIconError(false);

        const idField = id.replace("-opacity", "");
        const _hex = document.getElementById(idField).value;
        if (!_hex || _hex !== "") {
            try {
                const _rgbaColor = hexToRgbA(_hex, value);
                onBlurAction(_rgbaColor, idField);
            } catch (error) {
                handleError(idField);
            }
        }
    };

    const handleError = (field) => {
        switch (field) {
            case "fgColor":
                setInputHexFgError(true);
                break;
            case "bgColor":
                setInputHexBgError(true);
                break;
            case "iconColor":
                setInputHexIconError(true);
                break;
        }
    };

    const onBlurAction = (color, id) => {
        const keyOption = { ..._key.current };
        if (keyOption.input === SALES_OPTIONS_KEYS.STATUS) {
            keyOption.option = SALES_OPTIONS_KEYS.COLORS;
        } else {
            keyOption.input = SALES_OPTIONS_KEYS.COLORS;
        }
        const { field, group } = decodeID(id);
        keyOption.field = field;
        keyOption.group = group;
        dispatch(
            setSectionContent({
                styleInputData: { ...styleInputData, key: { ...keyOption }, value: color },
            })
        );
    };

    if (!_key?.current?.listOption) {
        return null;
    }

    const bgID = ID("bgColor", group);
    const fgID = ID("fgColor", group);
    const iconID = ID("iconColor", group);

    const bgOpacityID = ID("bgColor-opacity", group);
    const fgOpacityID = ID("fgColor-opacity", group);
    const iconOpacityID = ID("iconColor-opacity", group);

    const hasBG = data?.hasOwnProperty("bgColor");
    const hasFG = data?.hasOwnProperty("fgColor");
    const hasIcon = data?.hasOwnProperty("iconColor");

    return hasBG || hasFG || hasIcon ? (
        <div className="mt-4 w-full px-8">
            {showTitle ? <div className="mb-2 w-full font-bold">{capitalizeT("colors", t)}</div> : null}
            {hasFG ? (
                <>
                    <div className="mb-1 mt-2 w-full">{capitalizeT("foreground", t)}</div>
                    <div className="inline-flex">
                        <div className="w-36 my-auto inline-flex">
                            <div
                                id={`sales-colors-foreground-picker`}
                                className={`border h-8 w-8 rounded-lg ${
                                    !data?.fgColor && "icon-no-color text-35xl p-px text-red-100"
                                }`}
                                style={{ background: data?.fgColor ? data?.fgColor : null }}
                                onClick={(e) => {
                                    dispatch(
                                        setColorPickerReference({
                                            id: `sales-colors-foreground-picker`,
                                            property: `fgColor`,
                                            color: data?.fgColor ? data?.fgColor : null,
                                            group,
                                            action: "onSelectColorCallback",
                                        })
                                    );
                                    e.stopPropagation();
                                }}
                            ></div>
                            <div className="m-auto w-24">
                                <UseInputText
                                    id={fgID}
                                    name={"fgColor"}
                                    ph={"#"}
                                    adjust={`h-8 mx-1 ${inputHexFgError ? "border border-red-100 " : ""}`}
                                    value={data?.fgColor ? rgbaToHex(data.fgColor) : null}
                                    action={changeInputsFields}
                                />
                            </div>
                        </div>
                        <div className="w-24">
                            <UseInputPercent
                                id={fgOpacityID}
                                value={data?.fgColor ? getRGBA(data.fgColor).a * 100 : 100}
                                adjust={"h-8"}
                                action={handleChangeAlpha}
                            />
                        </div>
                    </div>
                </>
            ) : null}
            {hasBG ? (
                <>
                    <div className="mb-1 w-full">{capitalizeT("background", t)}</div>
                    <div className="inline-flex">
                        <div className="w-36 my-auto inline-flex">
                            <div
                                id={`sales-colors-background-picker`}
                                className={`border h-8 w-8 rounded-lg ${
                                    !data?.bgColor && "icon-no-color text-35xl p-px text-red-100"
                                }`}
                                style={{ background: data?.bgColor ? data?.bgColor : null }}
                                onClick={(e) => {
                                    dispatch(
                                        setColorPickerReference({
                                            id: `sales-colors-background-picker`,
                                            property: `bgColor`,
                                            color: data?.bgColor ? data?.bgColor : null,
                                            group,
                                            action: "onSelectColorCallback",
                                        })
                                    );
                                    e.stopPropagation();
                                }}
                            ></div>
                            <div className="m-auto w-24">
                                <UseInputText
                                    id={bgID}
                                    name={"bgColor"}
                                    ph={"#"}
                                    adjust={`h-8 mx-1 ${inputHexBgError ? "border border-red-100 " : ""}`}
                                    value={data?.bgColor ? rgbaToHex(data.bgColor) : null}
                                    action={changeInputsFields}
                                />
                            </div>
                        </div>
                        <div className="w-24">
                            <UseInputPercent
                                id={bgOpacityID}
                                value={data?.bgColor ? getRGBA(data.bgColor).a * 100 : 100}
                                adjust={"h-8"}
                                action={handleChangeAlpha}
                            />
                        </div>
                    </div>{" "}
                </>
            ) : null}
            {hasIcon ? (
                <>
                    <div className="mb-1 mt-2 w-full">{capitalizeT("icon", t)}</div>
                    <div className="inline-flex">
                        <div className="w-36 my-auto inline-flex">
                            <div
                                id={`sales-colors-foreground-picker`}
                                className={`border h-8 w-8 rounded-lg ${
                                    !data.iconColor && "icon-no-color text-35xl p-px text-red-100"
                                }`}
                                style={{ background: data.iconColor }}
                                onClick={(e) => {
                                    dispatch(
                                        setColorPickerReference({
                                            id: `sales-colors-foreground-picker`,
                                            property: `iconColor`,
                                            color: data.iconColor,
                                            group,
                                            action: "onSelectColorCallback",
                                        })
                                    );
                                    e.stopPropagation();
                                }}
                            ></div>
                            <div className="m-auto w-24">
                                <UseInputText
                                    id={iconID}
                                    name={"iconColor"}
                                    ph={"#"}
                                    adjust={`h-8 mx-1 ${inputHexIconError ? "border border-red-100 " : ""}`}
                                    value={data.iconColor ? rgbaToHex(data.iconColor) : null}
                                    action={changeInputsFields}
                                />
                            </div>
                        </div>
                        <div className="w-24">
                            <UseInputPercent
                                id={iconOpacityID}
                                value={data.iconColor ? getRGBA(data.iconColor).a * 100 : 100}
                                adjust={"h-8"}
                                action={handleChangeAlpha}
                            />
                        </div>
                    </div>{" "}
                </>
            ) : null}
        </div>
    ) : null;
};

export default UseColors;
