import { gql } from "apollo-boost";
import { useModal } from "components/Modal";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const useDeletePasscodes = ({ id }) => {
    const { t } = useTranslation();
    const { close } = useModal();

    const deletePassCodes = gql`
        mutation deleteWifiAccessCode($ID: [Int64]!) {
            deleteWifiAccessCode(ID: $ID) {
                error
                ok
            }
        }
    `;
    const [executeMutation] = useMutation(deletePassCodes);

    const deletePasscodes = () => {
        executeMutation({
            variables: {
                ID: id,
            },
        }).then((response) => {
            if (response?.data?.deleteWifiAccessCode?.ok) {
                toast.success(t("operation-successful"));
            }
            close();
        });
    };

    return { deletePasscodes };
};
