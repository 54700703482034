import React, { useState } from "react";
import classNames from "classnames";

import { Session } from "hooks/Utils/Session";
import { useAuth } from "hooks/Session/auth";

// TODO revisar por que a veces se usa Session.getDriveUrl("logo%20Zafiro%20by%20ES.png")
const LOGO_URL = process.env.PUBLIC_URL + "/assets/images/logotipo-ZAFIROCLOUD.svg";

const ZafiroLogo = () => {
    const { chain } = useAuth();

    const [error, setError] = useState(false);

    const title = chain?.logo ? chain?.name : "ZAFIRO";
    const url = chain?.logo ? Session.getDasUrl(chain?.logo) : LOGO_URL;

    const logoClass = classNames({
        "text-center m-auto max-w-full max-h-full": true,
        "pr-5": !chain?.logo,
    });
    const logoContainerClass = "w-80 h-40 max-w-full max-h-full m-auto";

    const titleClass = classNames({
        [logoClass]: !!logoClass,
        "bg-white text-gray-900 text-4xl font-bold rounded-lg p-5": true,
    });

    return (
        <>
            {error ? (
                <h1 className={titleClass}>{title}</h1>
            ) : (
                <div className={logoContainerClass}>
                    <img src={url} alt={title} title={title} onError={() => setError(true)} className={logoClass} />
                </div>
            )}
        </>
    );
};

export default ZafiroLogo;
