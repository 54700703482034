import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
//Components
import UseInputTime from "../Schedule/useInputTime";
import UseInputWeekDays from "../Schedule/useInputWeekDays";
import UseButton from "../useButton";
//Actions
import { cleanAction, cleanScheduleDates } from "../../actions/globalActions";
import { setWizardAllowNext } from "../../actions/sectionActions";
import { addBatchPeriodicSchedule, addViewPeriodicSchedule } from "../../actions/scheduleActions";
//Utils
import {
    GetMutationWeekDays,
    GetMutationSchedules,
    validateSchedule,
    validateWeekdays,
} from "../../hooks/Utils/ScheduleManagement";

//Notifications
import { toast } from "react-toastify";

const UseScheduleFormCommon = ({ setAddCommonSchedule }) => {
    //Store data
    //ISO 8601 standard Monday(1) Sunday (7)
    const { langStrings } = useSelector((state) => state.ui);
    const { schedule, weekDays } = useSelector((state) => state.action);
    const { periodicEventsData } = useSelector((state) => state.sectionContent);
    //Form states
    const [isFormVisible, setIsFormVisible] = useState(true);
    const [weekDaysUsed, setWeekDaysUsed] = useState([]);
    //Validation States
    const [scheduleValidationMS, setScheduleValidationMS] = useState(true);
    const [scheduleValidationME, setScheduleValidationME] = useState(true);
    const [scheduleValidationAS, setScheduleValidationAS] = useState(true);
    const [scheduleValidationAE, setScheduleValidationAE] = useState(true);
    const [weekDaysValidation, setWeekDaysValidation] = useState(true);
    const { id } = useParams();

    //Actions
    const dispatch = useDispatch();

    const handleClickCancel = () => {
        setAddCommonSchedule(true);
        dispatch(cleanAction());
        dispatch(setWizardAllowNext(true));
    };

    const handleClickSave = () => {
        //Validation
        const valResult = validateSchedule(schedule);
        setScheduleValidationMS(valResult.morningStart);
        setScheduleValidationME(valResult.morningEnd);
        setScheduleValidationAS(valResult.afternoonStart);
        setScheduleValidationAE(valResult.afternoonEnd);
        setWeekDaysValidation(validateWeekdays(weekDays));

        if (isValidated(valResult, weekDaysValidation, schedule, weekDays)) {
            //Format data
            const commonSchedule = {
                areaID: id,
                tempId: Math.random(),
                dateType: "DATE_RANGE",
                isEnabled: true,
                name: "",
                periodicityType: "HABITUAL",
                timetableDates: [
                    {
                        dateList: "",
                        timetableDateRanges: [
                            {
                                timeRanges: GetMutationSchedules(schedule),
                                weekdayList: GetMutationWeekDays(weekDays),
                            },
                        ],
                    },
                ],
                type: "OPEN",
            };
            //Update BatchToStore
            dispatch(addBatchPeriodicSchedule(commonSchedule));
            //Add to edit view
            dispatch(addViewPeriodicSchedule(commonSchedule));

            setIsFormVisible(false);
            setAddCommonSchedule(false);
            dispatch(cleanScheduleDates());
            dispatch(setWizardAllowNext(true));
        } else {
            toast.error(langStrings["check form"]);
        }
    };

    //Listeners
    useEffect(() => {
        if (isWeekdaysValidated(weekDays, weekDaysValidation)) {
            setWeekDaysValidation(validateWeekdays(weekDays));
        }
        // eslint-disable-next-line
    }, [weekDays]);

    useEffect(() => {
        let weekUsed = [];
        if (periodicEventsData && periodicEventsData.currentData) {
            periodicEventsData.currentData.forEach((_schedule) => {
                if (_schedule.periodicityType === "HABITUAL") {
                    _schedule.timetableDates.forEach((_ttDates) => {
                        if (_ttDates.timetableDateRanges && _ttDates.dateList === null) {
                            _ttDates.timetableDateRanges.forEach((_ttDRanges) => {
                                const _weekday = JSON.parse(_ttDRanges.weekdayList);
                                _weekday.forEach((day) => {
                                    if (!weekUsed.find((a) => a === day)) {
                                        weekUsed.push(day);
                                    }
                                });
                            });
                        }
                    });
                }
            });
        }
        setWeekDaysUsed(weekUsed);
        // eslint-disable-next-line
    }, []);
    return (
        <>
            {isFormVisible ? (
                <div className="bg-white p-8">
                    <div className="flex justify-between">
                        <span className="flex items-center">
                            <div className="inline-block mr-6">
                                <UseInputTime dateId="morningStart" validation={scheduleValidationMS} /> -{" "}
                                <UseInputTime dateId="morningEnd" validation={scheduleValidationME} /> /{" "}
                                <UseInputTime dateId="afternoonStart" validation={scheduleValidationAS} /> -{" "}
                                <UseInputTime dateId="afternoonEnd" validation={scheduleValidationAE} />
                            </div>

                            <div className="inline-block">
                                <UseInputWeekDays validation={weekDaysValidation} weekDaysUsed={weekDaysUsed} />
                            </div>
                        </span>
                    </div>
                    <div className="w-full flex justify-end h-10 mt-8 mr-4 mb-4">
                        <div className="w-auto inline-block mr-4" onClick={(e) => handleClickCancel()}>
                            <UseButton buttonName="cancel" buttonColor="btn-blue-outline" />
                        </div>
                        <div className="w-auto inline-block" onClick={(e) => handleClickSave()}>
                            <UseButton buttonName="save" buttonColor="btn-blue" />
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};
//Validation check functions
//All validated
export const isValidated = (scheduleValidation, weekDaysValidation, schedule, weekDays) => {
    //Returns true is all validations passed
    let res;
    if (
        scheduleValidation.morningStart &&
        scheduleValidation.morningEnd &&
        scheduleValidation.afternoonStart &&
        scheduleValidation.afternoonEnd &&
        weekDaysValidation &&
        schedule &&
        weekDays
    ) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const isWeekdaysValidated = (weekDays, weekDaysValidation) => {
    //Returns true if weekdays are validated
    let res;
    if (weekDays && !weekDaysValidation) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export default UseScheduleFormCommon;
