import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withApollo } from "@apollo/react-hoc";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
//Components
import UseButton from "../useButton";
import UseEditableField from "../useEditableField";
//Actions
import { setRefreshData, setTableResults } from "../../actions/tableActions";
import { showRowForm } from "../../actions/sectionActions";
import { cleanAction, setActionValidations } from "../../actions/globalActions";
import { validateSection } from "../../hooks/Utils/Validations";
//Utils
import { removeApolloCacheKeys } from "../../hooks/Utils/Utils";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
//Model

const UseLanguageFormCreate = ({ client }) => {
    const { t } = useTranslation();
    //Store data

    const { rowFormContent } = useSelector((state) => state.table);
    const { actionData, values } = useSelector((state) => state.action);
    const dataItems = useSelector((state) => state.table.results);
    const sectionContentData = useSelector((state) => state.sectionContent);
    //Data
    const isDefault =
        sectionContentData != null && sectionContentData.count != null && sectionContentData.count > 0 ? false : true;
    //const isDefault = false;
    //API
    const MUTATION = gql`
    mutation{
        addLangToProject(languageRef:"${values["input_lang"]}",isDefault:${isDefault}){
            error,
            ok
        }
    }
    `;
    const [createLanguage, { data }] = useMutation(MUTATION, {
        onError(err) {
            console.log(err);
            toast.error(t("lang x created error", { name: actionData.name }));
        },
    });

    //Actions
    const dispatch = useDispatch();

    const languagesSortedAlphabetically = sectionContentData.languages.sort((a, b) => a.name.localeCompare(b.name));

    const rowFormClickHandler = (e) => {
        const actionName = e.currentTarget.dataset.action;

        if (actionName !== "save") {
            dispatch(showRowForm(false));
            dispatch(cleanAction());
        } else {
            //Validate fields
            const validations = validateSection("add-new-language", values, t);
            dispatch(setActionValidations(validations));
            const validationsArray = Object.values(validations);

            if (validationsArray.filter((item) => item === false).length > 0) {
                toast.error(t("errors-in-red"));
                return;
            }
            createLanguage();
        }
    };

    useEffect(() => {
        if (checkMutationSuccess(data)) {
            //this is important to get data categories updated next time you access to that section
            removeApolloCacheKeys(client.cache, ["projectLangs", "languages"]);

            toast.success(t("lang x created", { name: actionData ? actionData.name : "" }));
            dispatch(setTableResults(dataItems));
            dispatch(setRefreshData(true));
            dispatch(showRowForm(false));
            dispatch(cleanAction());
        } else if (checkMutationErrors(data)) {
            toast.error(t("lang x created error", { name: actionData.name }));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        const item = document.querySelector("#input_lang");
        if (item) {
            item.focus();
        }
    }, []);

    return (
        <>
            {rowFormContent ? (
                <div className="w-full bg-white border-b border-gray-200 h-16 flex items-center">
                    {rowFormContent.inputs.map((input, index) =>
                        input.name === "lang" ? (
                            <div className="inline-block w-3/12 " key={index}>
                                <UseEditableField
                                    data={{
                                        id: "input_lang",
                                        noLabel: true,
                                        label: "input_lang",
                                        placeholder: "lang",
                                        inEditionMode: true,
                                        type: "select-with-search",
                                        optionData: languagesSortedAlphabetically,
                                        oneSelected: true,
                                        noMaxHeight: false,
                                        styleAdjust: "pl-2 mt-1 h-11",
                                    }}
                                />
                            </div>
                        ) : null
                    )}

                    <div className="inline-block w-1/12">
                        <div className="w-full"></div>
                    </div>
                    <div className="inline-block w-2/12">
                        <div className="w-full"></div>
                    </div>
                    {rowFormContent && rowFormContent.buttons ? (
                        <div className="w-6/12 text-right whitespace-no-wrap">
                            {rowFormContent.buttons.map((button, index) => (
                                <div
                                    className={`inline-block ${index === 0 ? "pr-2" : "pl-2"} whitespace-normal`}
                                    data-action={button.name}
                                    key={index}
                                    onClick={rowFormClickHandler}
                                    id={button.name ? `form-language-create-button-${button.name}` : null}
                                >
                                    <UseButton buttonName={button.name} buttonColor={button.color} />
                                </div>
                            ))}
                        </div>
                    ) : null}
                    <div className="t-actions-space"></div>
                </div>
            ) : null}
        </>
    );
};

export const checkMutationErrors = (data) => {
    //returns true if mutation error
    let res = false;
    if (data && data.addLangToProject && data.addLangToProject.error === "") {
        res = true;
    }
    return res;
};

export const checkMutationSuccess = (data) => {
    //returns true if mutation success
    let res = false;
    if (data && data.addLangToProject && data.addLangToProject.error !== "") {
        res = true;
    }
    return res;
};

export const validationProcess = (rowFormContent, actionData, setValidationName) => {
    //returns true if all passes validation
    let validatedName = true;
    // eslint-disable-next-line
    rowFormContent.inputs.map((input) => {
        if (actionData[input.name] === "") {
            validatedName = false;
        } else {
            validatedName = true;
        }
        setValidationName(validatedName);
    });
    return validatedName;
};

export default withApollo(UseLanguageFormCreate);
