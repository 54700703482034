import React from "react";
import { useTranslation } from "react-i18next";
import useAddModalApp from "components/Section/Customers/allProperties/modals/useAddModalApp";
import Button from "components/Button";

export const MobileSettingsAddMobileCard = () => {
    const { open: openModalApp } = useAddModalApp();

    const { t } = useTranslation();

    return (
        <div className="py-4 flex flex-col items-center bg-white rounded-md">
            <h1 className="pb-2 font-bold text-3xl mt-3">{t("add-mobile-app-title")}</h1>
            <span className=" pb-6">{t("add-mobile-app-description")}</span>

            <Button
                onClick={() => {
                    openModalApp({ monoApp: true });
                }}
                id="add-app-button"
                design="blue"
            >
                {t("add-app")}
            </Button>
        </div>
    );
};
