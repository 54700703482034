import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { withDeprecationWarning } from "dev";

/**
 * @param props {{
 *      href?: string
 *      id: string
 *      icon?: string
 *      className?: string
 *      onClick?: (e: SyntheticMouseEvent)=>void
 *      children?: React.Children
 * }}
 * @returns JSX.Element
 */
const Link = ({ href, id, onClick, icon, children = null, className = "" }) => {
    return (
        <RouterLink to={href} id={id} onClick={onClick}>
            <div className={`rounded cursor-pointer ${className}`}>
                {icon ? <div className={`icon-${icon}`}></div> : null}
                {children}
            </div>
        </RouterLink>
    );
};

/**
 * @param props {{
 *      id: string
 *      icon?: string
 *      className?: string
 *      onClick?: (e: SyntheticMouseEvent)=>void
 *      children?: React.Children
 * }}
 * @returns JSX.Element
 */
const Button = ({ id, onClick, icon, children, className }) => {
    return (
        <div id={id} className={`${className} py-1 rounded first-capital`} onClick={onClick}>
            <div className="flex items-center cursor-pointer">
                {icon ? <div className={`pr-2 icon-${icon}`}></div> : null}
                {children ? <div className="first-capital">{children}</div> : null}
            </div>
        </div>
    );
};

/**
 * @typedef {{
 *     isVisible?: boolean,
 *     buttons?: {disabled?: boolean, className: string, children: React.Children, id: string, href?: string, onClick?: (e: SyntheticMouseEvent)=>void}[],
 *     close: ()=>void
 *     title: React.Children
 *     description?: React.Children
 * } | {isVisible: false}} NewModalProps
 */

/**
 * @param props {NewModalProps}
 * @returns JSX.Element
 */
export default withDeprecationWarning(
    { name: "NewModal", alt: "useModal" },
    ({ isVisible = false, close, buttons = [], title, description = null }) => {
        return isVisible ? (
            <div
                className={
                    "fixed w-full h-full top-0 left-0 items-center flex flex-wrap justify-center overflow-y-scroll z-203"
                }
            >
                <div id="modal-background" className="modal-background" onClick={close}></div>

                <div className={`modal-body mt-4 mb-4 relative`}>
                    <div className="p-10">
                        {title ? (
                            <h1
                                dangerouslySetInnerHTML={{ __html: title }}
                                id="section-title"
                                className={`section-title first-capital mb-4`}
                            ></h1>
                        ) : null}
                        <span dangerouslySetInnerHTML={{ __html: description }}></span>
                        {buttons ? (
                            <div className={"pt-8 text-center flex"}>
                                {buttons.map((button, index) =>
                                    button.href ? (
                                        <Link {...button} key={button.id} />
                                    ) : (
                                        <Button {...button} key={button.id} />
                                    )
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        ) : null;
    }
);
