import React from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import DynamicSimpleTable from "components/Modal/DynamicSimpleTable";
import { useTranslation } from "react-i18next";

export const useAssociatedPropertiesModal = () => {
    const { t } = useTranslation();
    const { open, close } = useModal();

    return {
        open: (props) => {
            open(
                <Modal
                    title={props?.title || ""}
                    footer={
                        <Button design="blue" id="modal-button-close" onClick={close}>
                            {t("close")}
                        </Button>
                    }
                    minWidth="35vw"
                >
                    <DynamicSimpleTable data={props?.data} headers={[{ header: t("properties"), width: "w-6/12" }]} />
                </Modal>
            );
        },
        close,
    };
};
