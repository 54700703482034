import React from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";

const ServicesConfig = () => (
    <>
        <UseSectionHeader title={["service-config"]} />
    </>
);

export default ServicesConfig;
