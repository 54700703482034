export function rgba2hex(rgba) {
    if (!rgba) {
        return null;
    }
    rgba = rgba.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    return rgba && rgba.length === 4
        ? "#" +
              ("0" + parseInt(rgba[1], 10).toString(16)).slice(-2) +
              ("0" + parseInt(rgba[2], 10).toString(16)).slice(-2) +
              ("0" + parseInt(rgba[3], 10).toString(16)).slice(-2)
        : "";
}

export function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
}

export const getNewDataGroupColor = (oldGroupColor, newData, type) => {
    let _rgba = null;
    if (newData !== null) {
        _rgba = newData.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    }
    let newGroupColor;
    if (type.includes("bg")) {
        newGroupColor = {
            ...oldGroupColor,
            bg: rgba2hex(newData),
            bgAlpha: _rgba ? _rgba[4] * 100 : 100,
            rgbaBG: newData,
        };
    } else {
        newGroupColor = {
            ...oldGroupColor,
            fg: rgba2hex(newData),
            fgAlpha: _rgba ? _rgba[4] * 100 : 100,
            rgbaFG: newData,
        };
    }
    return newGroupColor;
};
