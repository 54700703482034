const NewGroupToGrid = (data) => {
    return {
        title: "add-group",
        text: "select-one-or-more-groups-to-add-to-the-grid",
        executeSectionQuery: true,
        bodyAdjust: "w-1/12",
        inputs: [
            {
                text: "",
                name: "name-group-selected",
                type: "addGroupChannelToGroup",
                data: data ? data : {},
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "add",
                style: "blue",
                action: "add-group-to-grid",
            },
        ],
    };
};

export default NewGroupToGrid;
