import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { changeActionValues } from "../../actions/globalActions";
import { escapeSpecialChars, multipleTranslate } from "../../hooks/Utils/Utils";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Utils
import _ from "lodash";
import Parser from "hooks/Utils/Parser";
import { useTranslation } from "react-i18next";

const UseModalText = ({ index }) => {
    const { t } = useTranslation();
    //Store data
    const {
        name,
        text,
        focus,
        value,
        cStyle,
        ph,
        instructions,
        textStyle,
        validationsQuery,
        toLowerCase,
        disabled,
        composeTagId,
        ref,
        extra,
        extraValue,
    } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { validations } = useSelector((state) => state.action);
    const actionData = useSelector((state) => state.action.values);

    const { title, id } = useSelector((state) => state.ui.modalContent);

    //state
    const [val, setVal] = useState(value);
    const [edited, setEdited] = useState(value);
    const [footnote, setFootnote] = useState("");
    const [queryValidation, setQueryValidation] = useState(`{fake{ error id ok}}`);
    const DYNAMIC_QUERY = gql`
        ${queryValidation}
    `;
    const [executeQuery, { data }] = useLazyQuery(DYNAMIC_QUERY, {
        onError(err) {
            console.log(err);
        },
    });

    //Listeners
    useEffect(() => {
        if (value) {
            dispatch(changeActionValues({ [name]: value }));
        }
        if (extra) {
            dispatch(changeActionValues({ [extra]: extraValue }));
        }
        // eslint-disable-next-line
    }, []);

    ///////////
    //Specific for tags management
    useEffect(() => {
        if (composeTagId && !edited && actionData && actionData[composeTagId]) {
            setVal(actionData[composeTagId].toLowerCase().replaceAll(" ", "_"));
        } else if (composeTagId && !edited) {
            setVal("");
        }
        // eslint-disable-next-line
    }, [actionData]);
    useEffect(() => {
        if (composeTagId) {
            dispatch(changeActionValues({ [name]: val }));
        }
        // eslint-disable-next-line
    }, [val]);
    ///////////

    useEffect(() => {
        setFootnote("");
        let containerModal = document.querySelector(".modal-body");
        if (
            validationsQuery &&
            validationsQuery.validationElementsEdit &&
            validationsQuery.validationElementsEdit.length > 0
        ) {
            // eslint-disable-next-line
            validationsQuery.validationElementsEdit.map((element) => {
                if (containerModal) {
                    let items = containerModal.querySelectorAll(`[${element.attr}=${element.value}]`);
                    if (items && items.length > 0 && element.class) {
                        items.forEach(async (item) => {
                            item.classList.remove(element.class);
                        });
                    }
                }
            });
        }
        if (data && data.validationInfo) {
            if (validationsQuery && validationsQuery.validationAttribute) {
                if (
                    handlerValidateQueryInformation(
                        data.validationInfo,
                        validationsQuery.validationAttribute,
                        validationsQuery.validationType,
                        validationsQuery.validationIgnoreValue
                    )
                ) {
                    setFootnote(t(validationsQuery.validationMessage));
                    if (validationsQuery.validationElementsEdit && validationsQuery.validationElementsEdit.length > 0) {
                        // eslint-disable-next-line
                        validationsQuery.validationElementsEdit.map((element) => {
                            if (containerModal) {
                                let items = containerModal.querySelectorAll(`[${element.attr}=${element.value}]`);
                                if (items && items.length > 0 && element.class) {
                                    items.forEach(async (item) => {
                                        item.classList.add(element.class);
                                    });
                                }
                            }
                        });
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [data]);

    //Actions
    const dispatch = useDispatch();

    const handleChange = (e) => {
        let value = e.target.value;
        if (toLowerCase) value = value.toLowerCase();
        dispatch(changeActionValues({ [name]: value }));
        setVal(value);

        setEdited(true);
        if (validationsQuery && validationsQuery.validateOnKeyUp && validationsQuery.query && value) {
            setQueryValidation(validationsQuery.query.replace("%0", escapeSpecialChars(value)));
            executeQuery();
        }
    };

    const handleBlur = (e) => {
        let value = e.target.value;

        /////
        //Specific for tags management
        if (!value && composeTagId && actionData && actionData[composeTagId]) {
            setVal(actionData[composeTagId].toLowerCase().replaceAll(" ", "_"));
            setEdited(false);
        }
        /////

        if (toLowerCase) value = value.toLowerCase();
        if (validationsQuery && validationsQuery.validateOnBlur && validationsQuery.query && value) {
            setQueryValidation(validationsQuery.query.replace("%0", escapeSpecialChars(value)));
            executeQuery();
        }
    };

    return (
        <div className={`${cStyle}`} id={ref}>
            {text ? (
                <span className={`block pb-2 first-capital ${textStyle}`}>{multipleTranslate(text, t)}</span>
            ) : null}
            {instructions ? (
                <span className="block pb-2 first-capital">{Parser(multipleTranslate(instructions, t))}</span>
            ) : null}
            <input
                id={idAssigner({ focus, title, index })}
                type="text"
                min="0"
                required
                placeholder={ph ? t(ph) : ""}
                value={val}
                className={`w-full h-10 bg-gray-200 rounded p-2 ${!disabled && "hover:text-zafiro-400"}  ${
                    typeof validations[name] !== "undefined" && !validations[name] ? "border border-red-100" : ""
                } ${disabled ? "text-gray-700" : ""}`}
                disabled={disabled}
                onChange={(e) => handleChange(e)}
                onBlur={(e) => handleBlur(e)}
            ></input>
            {footnote ? <span className="block pt-2 first-capital">{Parser(String(footnote))}</span> : null}
        </div>
    );
};

const idAssigner = ({ focus, title, id, index }) => {
    if (focus) {
        return "modal_focus";
    } else if (id) {
        return `${id}-modal-input-text-${index}`;
    } else if (title) {
        return `${title}-modal-input-text-${index}`;
    } else {
        return null;
    }
};

const handlerValidateQueryInformation = (
    validationInfo,
    validationAttribute,
    validationType,
    validationIgnoreValue
) => {
    let response = false;
    switch (validationType) {
        case "exist_data":
            let value = _.get(validationInfo, validationAttribute);
            if (value) {
                if (typeof value === "string" && value !== "" && value !== "undefined") {
                    if (validationIgnoreValue) {
                        if (validationIgnoreValue !== value) {
                            response = true;
                        }
                    } else {
                        response = true;
                    }
                } else if (typeof value === "number" && value > 0) {
                    response = true;
                }
            }
            break;
        default:
            break;
    }
    return response;
};

export default UseModalText;
