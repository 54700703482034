/**
 * Parse environment variables to their correct types
 * @param {Object} data - Environment variables object
 * @returns {Object} - Environment variables object with correct types
 */
const parseVars = (data) => {
    if (!data) {
        return data;
    }
    if (typeof data !== "object") {
        throw new Error("Environment variables must be an object");
    }
    return Object.keys(data).reduce((acc, key) => {
        const value = data[key];
        if (!isNaN(value)) {
            acc[key] = parseFloat(value);
        } else if (value === "true" || value === "false") {
            acc[key] = value === "true";
        } else {
            acc[key] = value;
        }
        return acc;
    }, {});
};

// React environment is the environment mode (development, production, test)
const ENV_MODE = process.env.NODE_ENV;
// Environment variables are defined in the .env file
export const ENVIRONMENT_VARS = parseVars(process.env.REACT_APP);
// Development mode is defined by the DEVELOPMENT variable in the .env file
const DEVEL_MODE = ENVIRONMENT_VARS.DEVELOPMENT;
// The final environment is the one that will be used in the app (development, production, testing)
const ENVIRONMENT = ENV_MODE === "development" || !DEVEL_MODE ? ENV_MODE : "testing";

export const Context = {
    environment: ENVIRONMENT,
    features: {
        userguiding: ENVIRONMENT_VARS.USERGUIDING,
        notifications: ENVIRONMENT_VARS.NOTIFICATIONS,
        selenium: ENVIRONMENT !== "production",
    },
};
