import { capitalizeFirst } from "../../../Utils/Utils";

const AppearanceDeleteLogo = (titlePrefix, title, screenData) => {
    return {
        titlePrefix: titlePrefix,
        title: ["-", capitalizeFirst(title)],
        executeSectionQuery: true,
        bodyAdjust: "w-3/12",
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "delete",
                style: "red",
                action: "appearance-delete-logo",
            },
        ],
        inputs: [
            {
                text: ["delete-appearance-logo"],
                name: "appearance-delete-logo",
                type: "void",
            },
            {
                value: screenData,
                type: "data",
            },
        ],
    };
};

export default AppearanceDeleteLogo;
