import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "@apollo/react-hoc";

//ACTIONS
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";

//UTILS
import { toast } from "react-toastify";
import { removeApolloCacheKeys } from "../../Utils/Utils";
import { useTranslation } from "react-i18next";

const UpdateHotspot = ({ client }) => {
    const { langStrings } = useSelector((state) => state.ui);
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { t } = useTranslation();
    const actionData = useSelector((state) => state.action);
    const [mutation, setMutation] = useState(`
    mutation {
        createHotspot(name: "test") {
          ok
          error
          id
        }
      }`);

    const dispatch = useDispatch();

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(arrangeErrorMessage(err.message));
        },
    });

    const arrangeErrorMessage = (message) => {
        let response = message;
        if(message && message.includes("409")){
            response = t("name-already-exist");
        }
        return response;
    }

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.mutationName = "";
            execute = true;
            switch (executeQuery.action) {
                case "add-hotspot":
                    setMutation(`mutation{
                        createHotspot(name:"${actionData.values["hotspot-name"]}" , link: "${
                        actionData.values["hotspot-link"] ? actionData.values["hotspot-link"] : ""
                    }"){
                          ok
                          error
                          id
                        }
                      }`);
                    executeQuery.mutationName = "createHotspot";
                    executeQuery.cacheKeyToDelete = "hotspots";
                    break;
                case "delete-hotspot":
                    setMutation(`mutation {
                        deleteHotspot(name: "${actionData.itemsAffected[0]}") {
                          id
                          ok
                          error
                        }
                      }`);
                    executeQuery.mutationName = "deleteHotspot";
                    executeQuery.cacheKeyToDelete = "hotspots";
                    break;
                case "edit-hotspot-name":
                    setMutation(`mutation {
                        updateHotspot(name: "${actionData.itemsAffected[0]}", newName: "${
                        actionData.values["hotspot-name"]
                    }", link: "${actionData.values["hotspot-link"] ? actionData.values["hotspot-link"] : ""}") {
                          id
                          ok
                          error
                        }
                      }
                      `);
                    executeQuery.mutationName = "updateHotspot";
                    executeQuery.cacheKeyToDelete = "hotspots";
                    break;
                case "edit-hotspot-link":
                    let link = actionData.values["hotspot-link"] ? actionData.values["hotspot-link"] : "";
                    if (actionData.values["redirect-type"] && actionData.values["redirect-type"] === "pwa-name") {
                        link = "pwa";
                    } else if (actionData.values["redirect-type"] && actionData.values["redirect-type"] === "none") {
                        link = "";
                    }
                    setMutation(`mutation {
                        updateHotspot(name: "${actionData.itemsAffected[0]}", newName: "${actionData.values["hotspot-name"]}", link: "${link}") {
                          id
                          ok
                          error
                        }
                      }
                      `);
                    executeQuery.mutationName = "updateHotspot";
                    executeQuery.cacheKeyToDelete = "hotspots";
                    break;
                default:
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (!showGlobalLoading) dispatch(setLoading(true));

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (data && executeQuery && data[executeQuery.mutationName] && data[executeQuery.mutationName].ok === true) {
            toast.success(langStrings["operation-successful"]);

            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }
            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            dispatch(showGlobalLoading(false));
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));

            if (executeQuery.cacheKeyToDelete) {
                removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
            }
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateHotspot);
