import React, { useEffect, useContext } from "react";
import SalesToolbar from "./Sales/SalesToolbar";
import { remToPixel } from "../../../hooks/Utils/Utils";
import SalesConfigPanel from "./Sales/SalesConfigPanel";
import { DEVICE } from "constants/editor";
import { getDocumentElementHeight } from "../../../hooks/Utils/Design/EditorUtils";
import { SALES_SEQUENCE } from "../../../hooks/Utils/Design/SalesUtils";
import SalesScreensSequence from "./Sales/SalesScreensSequence";
import SalesPreview from "./Sales/SalesPreview";
import { useDispatch, useSelector } from "react-redux";
import { cleanSectionContent } from "../../../actions/sectionActions";
import UseSelectPreviewScreen from "../../Inputs/useSelectPreviewScreen";
import { useTranslation } from "react-i18next";
import { EditorContext } from "../../../contexts/editor";

const SalesEditor = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { currentScreen, background } = useSelector((state) => state.sectionContent);
    const { screenType, deviceType } = useContext(EditorContext);

    const sequence = screenType ? SALES_SEQUENCE()[screenType] : null;

    const isTV = deviceType === DEVICE.TYPE.TV;
    const isMobile = deviceType === DEVICE.TYPE.MOBILE;

    useEffect(() => {
        return () => {
            dispatch(cleanSectionContent());
        };
        // eslint-disable-next-line
    }, []);

    if (!background) {
        return null;
    }

    return (
        <div
            className="relative inline-flex w-full"
            style={{
                height: `calc(100% - ${getDocumentElementHeight("designSubmenu") + remToPixel(5.2)}px)`,
            }}
        >
            <div
                className="pl-10 h-full overflow-auto"
                style={{
                    width: isMobile ? "35%" : "28%",
                }}
            >
                <SalesToolbar />
                <SalesConfigPanel />
            </div>
            {deviceType ? (
                <div
                    className="ml-2"
                    style={{
                        width: isMobile ? "65%" : "72%",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            width: isMobile ? "35%" : "36%",
                        }}
                    >
                        {isTV && background.useHomeBg ? (
                            <div className="absolute top-0 mr-2 w-auto right-0 inline-flex">
                                <div className="mt-2 mr-2 ">{t("preview-home-background")}</div>
                                <div className="w-64">
                                    <UseSelectPreviewScreen
                                        enabled={true}
                                        selectData={{
                                            id: "backgorund-option-selected",
                                            adjustActiveValueContainer: "-mt-1",
                                            inputActiveTextColor: "text-zafiro-600",
                                            containerOptionsStyles: { maxHeight: "11rem" },
                                            arrowIconSize: "text-xs",
                                            inputActiveBgColor: "bg-white",
                                            inputBgColor: "bg-white",
                                            inputHeight: "h-8",
                                            containerOptionsAdjust: "z-500 overflow-y-scroll",
                                            placeHolder: "select",
                                            containerSelecOptionStyle: "pl-6",
                                        }}
                                        menuPreview={false}
                                    />
                                </div>
                            </div>
                        ) : null}
                        <SalesScreensSequence />
                    </div>

                    <div className="float-left h-full" style={{ width: isMobile ? "45%" : "100%" }}>
                        <SalesPreview />
                    </div>

                    {currentScreen === sequence?.SHOPS ? (
                        <div
                            className={
                                "py-2 px-4 bottom-0 rounded bg-orange-100 text-gray-900 shadow " +
                                (isMobile ? "float-left mt-2 mx-4 w-1/2 " : "absolute ml-3 mb-1")
                            }
                        >
                            <span
                                className={
                                    "icon-warning text-white " +
                                    (isMobile
                                        ? "align-top mr-3 text-2xl float-left pt-2 pb-3"
                                        : "align-middle mr-2 text-xl")
                                }
                            ></span>
                            <span className="align-middle mr-2">{t("sales-roomshops-warning")}</span>
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default SalesEditor;
