export const GetAccountUsersTableHeaders = (section) => {
    let res = [];
    //Sort icon
    const tableSortIcon = { name: "table-sort" };

    //Special width classes
    // const w112 = "w-1/12";
    const w212 = "w-2/12";
    const w312 = "w-3/12";
    // const w512 = "w-5/12";
    // const w612 = "w-6/12";
    // const w912 = "w-9/12";

    //Header types
    let name = { name: "name", sortName: "fullName" };
    let email = { name: "email" };
    let account = { name: "account" };
    let properties = { name: "properties" };

    let emptyCell = { name: "" };

    name.specialWidth = w212;
    email.specialWidth = w312;
    properties.specialWidth = w312;
    account.specialWidth = w212;
    emptyCell.specialWidth = w212;

    name.icon = tableSortIcon;
    email.icon = tableSortIcon;

    res.push(name, email, account, properties, emptyCell);

    return res;
};

export default GetAccountUsersTableHeaders;
