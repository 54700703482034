function ApplyDesign(id, property, designName) {
    return [
        {
            title: ["apply", designName],
            text: "design-apply-instructions",
            id: id,
            useGlobalLoading: true,
            actionName: "applyDesign",
            inputs: [
                {
                    text: "",
                    name: "properties",
                    value: `[project-${property}]`,
                    type: "text",
                    style: "hidden",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "apply",
                    style: "blue",
                    action: "apply-design",
                },
            ],
        },
    ];
}

export default ApplyDesign;
