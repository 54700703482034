import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
//Components
import UseButton from "../useButton";
import UseInputCheckboxes from "./useInputCheckboxes";
import UseInputText from "./useInputText";
import UseInputMail from "./useInputMail";
//Actions
import { setActionInput, cleanAction } from "../../actions/globalActions";
import { showRowForm, setHiddenRows } from "../../actions/sectionActions";
import { updateResultItem } from "../../actions/tableActions";
//Utils
import { toast } from "react-toastify";
import { GetUpdatedPosition } from "../../hooks/Utils/GetUpdatedPosition";
import { useTranslation } from "react-i18next";
import { escapeSpecialChars, removeApolloCacheKeys } from "../../hooks/Utils/Utils";
import { withApollo } from "react-apollo";

const useCZFormStaffUpdate = ({ client }) => {
    const { t } = useTranslation();
    //Store data
    const { rowFormContent, results } = useSelector((state) => state.table);
    const { actionData, values, itemsAffected } = useSelector((state) => state.action);

    //Validation States
    const [validationName, setValidationName] = useState(true);
    const [validationEmail, setValidationEmail] = useState(true);
    const [validationRoles, setValidationRoles] = useState(true);

    //API
    const MUTATION = gql`
  mutation {
    updateStaffUser(
        id: ${Number(itemsAffected[0])}
        fullName: "${actionData ? escapeSpecialChars(actionData.name) : ""}",
        accountName: "${actionData ? escapeSpecialChars(actionData.email) : ""}",
        roles: "${actionData && actionData.roles ? actionData.roles.join(",") : ""}"
        ) { error id ok }}`;

    const [updateStaffUser, { data }] = useMutation(MUTATION, {
        onError({ graphQLErrors }) {
            if (graphQLErrors) {
                graphQLErrors.map((gError) => toast.error(gError.message));
            }
            toast.error(t("mutation-error"));
        },
    });

    //Actions
    const dispatch = useDispatch();

    //Set initial existing values
    const setInitialData = () => {
        let currentRoles = [];
        if (values.roles) {
            const rolesinfo = values.roles.split(",");
            currentRoles.push(rolesinfo[0]);
            if (rolesinfo.length > 3) {
                currentRoles.push(rolesinfo[3]);
            }
        }
        dispatch(setActionInput({ name: values.name }));
        dispatch(setActionInput({ email: values.email }));
        dispatch(setActionInput({ roles: currentRoles }));
    };

    const rowFormClickHandler = (e) => {
        const actionName = e.currentTarget.dataset.action;

        if (actionName !== "save") {
            dispatch(showRowForm(false));
            dispatch(cleanAction());
            dispatch(setHiddenRows(true));
        } else {
            //Validation
            let validatedName = true;
            let validatedEmail = true;
            let validatedRoles = true;

            const validationProcess = () => {
                let res = false;
                //Validate Name
                validatedName = !actionData.name || actionData.name === "" ? false : true;
                setValidationName(validatedName);

                //Validate Email (already controlled by html input)
                validatedEmail = getValidationEmail(actionData);
                setValidationEmail(validatedEmail);

                //Validate Roles
                validatedRoles = actionData.roles && actionData.roles.length > 0 ? true : false;
                setValidationRoles(validatedRoles);

                if (validatedName && validatedEmail && validatedRoles) {
                    res = true;
                }
                return res;
            };

            if (validationProcess()) {
                updateStaffUser();
            } else {
                toast.error(t("error-staff-create"));
            }
        }
    };

    //Listeners
    useEffect(() => {
        if (actionData && actionData.name && actionData.name !== "") {
            setValidationName(true);
        }
        if (actionData && actionData.email) {
            setValidationEmail(true);
        }
        if (actionData && actionData.roles && actionData.roles.length > 0) {
            setValidationRoles(true);
        }
    }, [actionData]);

    useEffect(() => {
        if (data && data.updateStaffUser && data.updateStaffUser.error === "") {
            toast.success(t("staff member x updated", { name: actionData.name }));
            dispatch(showRowForm(false));
            //Recarga content actualizado
            const updatedPosition = GetUpdatedPosition(itemsAffected, results);
            let updatedItem = results[updatedPosition];
            //updateData
            updatedItem.info[0].value = actionData.name;
            updatedItem.info[1].value = actionData.email;
            updatedItem.info[2].value = getRolesInfo(actionData.roles);

            removeApolloCacheKeys(client.cache, "staffUsers");

            dispatch(updateResultItem({ index: updatedPosition, data: updatedItem }));
            dispatch(setHiddenRows(true));
            dispatch(cleanAction());
        } else if (data && data.updateStaffUser && data.updateStaffUser.error !== "") {
            toast.error(t("mutation-error"));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        setInitialData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {rowFormContent ? (
                <div className="w-full bg-white border-b border-gray-200 h-16 flex items-center">
                    {rowFormContent.inputs.map((input, index) =>
                        input.name === "name" ? (
                            <div className="inline-block w-3/12 pl-4 pr-4" key={index}>
                                <UseInputText
                                    id={"form-staff-update-input-name"}
                                    ph={values.name}
                                    currentValue={values.name}
                                    inputData={input}
                                    validation={validationName}
                                />
                            </div>
                        ) : input.name === "email" ? (
                            <div className="inline-block w-3/12 pr-4" key={index}>
                                <UseInputMail
                                    id={"form-staff-update-input-email"}
                                    ph={values.email}
                                    currentValue={values.email}
                                    inputData={input}
                                    validation={validationEmail}
                                />
                            </div>
                        ) : input.name === "role" ? (
                            <div className="inline-block w-3/12 pr-4" key={index}>
                                <UseInputCheckboxes validation={validationRoles} />
                            </div>
                        ) : null
                    )}
                    <div className="w-3/12 text-right inline-block whitespace-no-wrap">
                        {rowFormContent.buttons.map((button, index) => (
                            <div
                                className={`inline-block ${index === 0 ? "pr-2" : ""} whitespace-normal`}
                                data-action={button.name}
                                key={index}
                                onClick={rowFormClickHandler}
                            >
                                <UseButton
                                    id={`form-staff-button-${button.name}`}
                                    adjust="min-w-24"
                                    buttonName={button.name}
                                    buttonColor={button.color}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="t-actions-space"></div>
                </div>
            ) : null}
        </>
    );
};

export const getValidationEmail = (actionData) => {
    let res;

    if (actionData && actionData.email && actionData.email.indexOf("@") > -1 && actionData.email.indexOf(".") > -1) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const getRolesInfo = (roles) => {
    //Returns roles info (value, text, tag color)
    let res = [];
    // eslint-disable-next-line
    roles.map((role) => {
        if (role === "1") {
            res.push([1, "cleaning", "#26BCAD"]);
        } else {
            res.push([2, "capacity", "#6554C0"]);
        }
    });

    return res;
};

export default withApollo(useCZFormStaffUpdate);
