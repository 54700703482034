import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
//Components
import UseButton from "../useButton";
import UseInputText from "./useInputText";
//Actions
import { showRowForm, setHiddenRows } from "../../actions/sectionActions";
import { cleanAction } from "../../actions/globalActions";
import { updateResultItem } from "../../actions/tableActions";
//Utils
import { toast } from "react-toastify";
import { GetUpdatedPosition } from "../../hooks/Utils/GetUpdatedPosition";
import { useTranslation } from "react-i18next";
import { escapeSpecialChars } from "../../hooks/Utils/Utils";

const UseCZFormAreaUpdate = () => {
    const { t } = useTranslation();
    //Store data
    const { rowFormContent, results } = useSelector((state) => state.table);
    const { actionData, itemsAffected } = useSelector((state) => state.action);
    const currentValue = actionData.name;

    //States
    const [validationName, setValidationName] = useState(true);

    //API
    const MUTATION = gql`
  mutation {
    updateCommonZone(id: ${Number(itemsAffected)} name: "${escapeSpecialChars(actionData.name)}") {
      error
      id
      ok
    }
  }
  `;
    const [updateCategory, { data }] = useMutation(MUTATION);

    //Actions
    const dispatch = useDispatch();

    const rowFormClickHandler = (e) => {
        const actionName = e.currentTarget.dataset.action;

        if (actionName !== "save") {
            dispatch(showRowForm(false));
            dispatch(cleanAction());
            dispatch(setHiddenRows(true));
        } else {
            //Validation

            const validationProcess = () => {
                let validatedName = true;
                // eslint-disable-next-line
                rowFormContent.inputs.map((input) => {
                    if (actionData[input.name] === "") {
                        validatedName = false;
                    } else {
                        validatedName = true;
                    }
                    setValidationName(validatedName);
                });
                return validatedName;
            };

            if (validationProcess()) {
                updateCategory();
            } else {
                toast.error(t("enter area name"));
            }
        }
    };

    //Listeners
    useEffect(() => {
        if (actionData && actionData.name && actionData.name !== "") {
            setValidationName(true);
        }
    }, [actionData]);

    useEffect(() => {
        const item = document.querySelector("#input_areaName");
        if (item) {
            item.focus();
        }
    }, []);

    useEffect(() => {
        if (data && data.updateCommonZone && data.updateCommonZone.error === "") {
            toast.success(t("area x updated", { name: actionData.name }));
            dispatch(showRowForm(false));
            //Recarga content actualizado
            const updatedPosition = GetUpdatedPosition(itemsAffected, results);
            let updatedItem = results[updatedPosition];
            updatedItem.info[0].value = actionData.name;
            dispatch(updateResultItem({ index: updatedPosition, data: updatedItem }));
            dispatch(setHiddenRows(true));
            dispatch(cleanAction());
        } else if (data && data.updateCommonZone && data.updateCommonZone.error !== "") {
            toast.error(t("area x updated error", { name: actionData.name }));
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <div className="w-full bg-white border-b border-gray-200 h-16 flex items-center">
            <div className="t-chevron-space"></div>
            {rowFormContent && rowFormContent.inputs
                ? rowFormContent.inputs.map((input, index) => (
                      <div className="inline-block w-3/12 " key={index}>
                          {input.type === "text" ? (
                              <UseInputText
                                  id="input_areaName"
                                  inputData={input}
                                  validation={validationName}
                                  setValidation={setValidationName}
                                  currentValue={currentValue}
                              />
                          ) : null}
                      </div>
                  ))
                : null}
            <div className="inline-block w-1/6 pr-4">
                <div className="w-full"></div>
            </div>
            <div className="inline-block w-1/6">
                <div className="w-full"></div>
            </div>
            <div className="inline-block w-1/6">
                <div className="w-full"></div>
            </div>

            {rowFormContent && rowFormContent.buttons ? (
                <div className="w-2/6 text-right whitespace-no-wrap">
                    {rowFormContent.buttons.map((button, index) => (
                        <div
                            className={`inline-block ${index === 0 ? "pr-2" : "pl-2"} whitespace-normal`}
                            data-action={button.name}
                            key={index}
                            onClick={rowFormClickHandler}
                        >
                            <UseButton buttonName={button.name} buttonColor={button.color} />
                        </div>
                    ))}
                </div>
            ) : null}
            <div className="t-actions-space"></div>
        </div>
    );
};

export default UseCZFormAreaUpdate;
