import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const NotFound = ({ code = 404, title: customTitle, desc: customDesc }) => {
    const { t } = useTranslation();

    const [visible, setVisible] = useState(false);

    const defaulTitle = code ? t(`error-${code}-title`) : null;
    const defaultDesc = code ? t(`error-${code}-desc`) : null;

    const title = customTitle === undefined ? defaulTitle : customTitle;
    const desc = customDesc === undefined ? defaultDesc : customDesc;

    const color = code >= 300 ? "text-red-100" : "text-black";

    useEffect(() => {
        // This is a hack to avoid showing the error message on page load inmediately
        // sometimes the available paths are not yet loaded so show this default 404 for one second and then refresh
        setTimeout(() => {
            setVisible(true);
        }, 1000);
    }, []);

    if (!visible) {
        return null;
    }

    return (
        <div className="p-10">
            {title ? <h1 className={`text-xl ${color} font-bold mb-2`}>{title}</h1> : null}
            {desc ? <p>{desc}</p> : null}
        </div>
    );
};

export default NotFound;
