import React, { createContext, useMemo, useState } from "react";

export const NetworkAlertsContext = createContext({
    dhcpCriticalWarning: false,
    dhcpLowWarning: false,
    setDhcpCriticalWarning: () => {},
    setDhcpLowWarning: () => {},
    mdnsCriticalWarning: false,
    mdnsLowWarning: false,
    setMdnsCriticalWarning: () => {},
    setMdnsLowWarning: () => {},
    networkCriticalWarning: false,
    setNetworkCriticalWarning: () => {},
    networkLowWarning: false,
    setNetworkLowWarning: () => {},
});

export const NetworkAlertsProvider = ({ children }) => {
    const [dhcpCriticalWarning, setDhcpCriticalWarning] = useState(false);
    const [dhcpLowWarning, setDhcpLowWarning] = useState(false);
    const [mdnsCriticalWarning, setMdnsCriticalWarning] = useState(false);
    const [mdnsLowWarning, setMdnsLowWarning] = useState(false);
    const [networkCriticalWarning, setNetworkCriticalWarning] = useState(false);
    const [networkLowWarning, setNetworkLowWarning] = useState(false);

    const contextValue = useMemo(
        () => ({
            dhcpCriticalWarning,
            dhcpLowWarning,
            setDhcpCriticalWarning,
            setDhcpLowWarning,
            mdnsCriticalWarning,
            mdnsLowWarning,
            setMdnsCriticalWarning,
            setMdnsLowWarning,
            networkCriticalWarning,
            setNetworkCriticalWarning,
            networkLowWarning,
            setNetworkLowWarning,
        }),
        [
            dhcpCriticalWarning,
            dhcpLowWarning,
            mdnsCriticalWarning,
            mdnsLowWarning,
            networkCriticalWarning,
            networkLowWarning,
        ]
    );

    return <NetworkAlertsContext.Provider value={contextValue}>{children}</NetworkAlertsContext.Provider>;
};
