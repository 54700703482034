import React from "react";

export const LocationsItem = ({ locations }) => {
    return (
        <div className="flex flex-wrap gap-1">
            {locations?.map((location, index) => {
                return (
                    <span
                        key={index}
                        alt={location?.name}
                        className="rounded py-1 px-4 m-1 text-white inline-block bg-zafiro-300"
                    >
                        {location.name}
                    </span>
                );
            })}
        </div>
    );
};
