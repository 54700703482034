import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setHeaderButtons, setBatchActive, setSearchable, setLoading } from "../../../actions/tableActions";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import { useTranslation } from "react-i18next";

const ListGroupEditChannels = ({ nameGroup }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const SECTION_NAME = "list-group-channels-edit";
    const main_button_add_channel = document.querySelector("#add-channel-to-group-channel-button");

    useEffect(() => {
        dispatch(setHeaderButtons(GetTableHeaderButton(SECTION_NAME, {}, null, t)));
        dispatch(setSearchable(true));
        dispatch(setBatchActive(BatchActions()));
        dispatch(setLoading(false));
    }, []);

    useEffect(() => {
        if (main_button_add_channel) {
            main_button_add_channel.style.marginTop = "3.6rem";
        }
    }, [main_button_add_channel]);

    useEffect(() => {
        if (nameGroup) {
            dispatch(setHeaderButtons(GetTableHeaderButton(SECTION_NAME, { data: { nameGroup } }, null, t)));
        }
    }, [nameGroup]);

    return null;
};

export default ListGroupEditChannels;

const BatchActions = () => {
    const actions = [
        {
            name: "delete",
            action: "delete-groups-channels",
            executeSectionQuery: true,
            modalInputs: [
                {
                    type: "void",
                    adjust: "first-capital",
                    text: "delete-groups-confirm",
                },
                {
                    type: "batchSelection",
                    style: "mt-0 table",
                    columnPos: 0,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                { name: "delete", style: "red", action: "delete-groups-channels" },
            ],
        },
    ];
    return actions;
};
