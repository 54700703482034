import React from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMutation } from "react-apollo";
import { gql } from "apollo-boost";
import { useDispatch } from "react-redux";
import { setRefreshData, cleanCheckedItems } from "actions/tableActions";

const ModalDeleteScheduleMessages = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const DELETE_SCHEDULE_MUTATION = gql`mutation{
        deleteMessageSchedulingBulk(
            ids: [${props.ids}]
        ){
            error
            ok
        }
    }`;

    const [executeMutation] = useMutation(DELETE_SCHEDULE_MUTATION, {
        onCompleted() {
            if (props.batch) {
                dispatch(cleanCheckedItems());
            }
            if (props.navigate) {
                props.navigate(`/customers/messages/draft/${props.ids}`);
            }
            dispatch(setRefreshData(true));
            props.close();
        },
        onError() {
            toast.error(t("mutation-error"));
        },
    });

    const handleSaveClick = () => {
        executeMutation();
    };

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={
                props.batch
                    ? `${capitalizeFirst(t("delete-schedule"))}`
                    : `${props?.name} - ${capitalizeFirst(t("delete-schedule"))}`
            }
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="red" id="modal-button-delete" onClick={handleSaveClick}>
                        {capitalizeFirst(t("delete"))}
                    </Button>
                </>
            }
            className={"w-5/12 p-10"}
        >
            <div>
                <p>{capitalizeFirst(t("are-you-sure-you-want-to-delete-the-send-schedule"))}</p>
            </div>
        </Modal>
    );
};

export const UseModalDeleteScheduleMessages = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalDeleteScheduleMessages {...newProps} />);
        },
    };
};
