import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { changeActionValues } from "../../actions/globalActions";
import { setLoadingModalContent } from "../../actions/uiActions";
//API
import { gql } from "apollo-boost";

//Utils
import useComponentVisible from "../useComponentVisible";
import UseLoading from "../../components/Table/useLoading";
import UseCheckOptions from "./useCheckOptions";
import { removeDiacritics, multipleTranslate, updateDataOptions } from "./../../hooks/Utils/Utils";
import Parser from "hooks/Utils/Parser";
import parseOptionsSelect from "../../hooks/Utils/Modal/parseOptionsSelect";
import { capitalizeFirst, escapeSpecialChars } from "../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import { useMSQuery } from "../../hooks/GraphqlCalls/useMSQuery";
import _ from "lodash";
import { useLazyQuery } from "react-apollo";

import ProjectName from "components/ProjectName";

const UseSelectMultipleWithSearch = ({ index }) => {
    const { t } = useTranslation();
    //Store data
    const { langStrings, permissions } = useSelector((state) => state.ui);
    const {
        optionData,
        onChange,
        oneSelected,
        name,
        optionsQuery,
        selectPlaceHolder,
        noSelect,
        text,
        cStyle,
        optionsAdjust,
        idLabel,
        sort,
        nameLabel,
        selectedIdsPath,
        noSearch,
        selectedIdsForOQ,
        translateOptions,
        capitalizeOptions,
        roleOptions,
        labelsColors,
        instructions,
        textStyle,
        groupsNames,
        orderOptions,
        forceSelection,
        validationsQuery,
    } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const parseInfo = { actionName: name, idLabel, nameLabel, langStrings, t, translateOptions };
    const { values, validations } = useSelector((state) => state.action);
    const { title } = useSelector((state) => state.ui.modalContent);

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //States
    const [selectActive, setSelectActive] = useState({});
    const [disabled] = useState(false);
    const [dataOptions, setDataOptions] = useState(optionData ? cloneObjectArray(optionData) : []);
    const [searching, setSearching] = useState(false);
    const [queryValidation, setQueryValidation] = useState(`{fake{ error id ok}}`);

    //API
    const GET_OPTIONS = optionsQuery
        ? gql`
              ${optionsQuery}
          `
        : null;
    const DYNAMIC_QUERY = gql`
        ${queryValidation}
    `;

    const [getOptionsQuery, { data, loading, networkStatus }] = useMSQuery(GET_OPTIONS, {
        fetchPolicy: "network-only",
    });
    const [executeValidationQuery, { data: dataValidations }] = useLazyQuery(DYNAMIC_QUERY, {
        fetchPolicy: "network-only",
    });

    //Actions
    const dispatch = useDispatch();

    //Listeners

    useEffect(() => {
        const selectedIds = dataOptions.map((item) => (item.selected ? item.id : null));
        dispatch(
            changeActionValues({
                [name]: selectedIds.filter((item) => item !== null),
            })
        );

        //get options from query
        if (optionsQuery) {
            dispatch(setLoadingModalContent(true));
            getOptionsQuery();
        }

        return () => {
            dispatch(setLoadingModalContent(false));
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({});
        } else {
            //ensure visibility of all elements
            const temCopy = cloneObjectArray(dataOptions);
            temCopy.map((item) => (item.visible = true));
            setDataOptions(temCopy);

            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
            });
        }
        // eslint-disable-next-line
    }, [isComponentVisible]);

    useEffect(() => {
        if (onChange) {
            onChange(
                values?.[name]?.length ? values[name]?.map((id) => dataOptions.find((item) => item.id === id)) : []
            );
        }
        updateDataOptions({
            dataOptions,
            values,
            name,
            setDataOptions,
            oneSelected,
            setIsComponentVisible,
        });
        // eslint-disable-next-line
    }, [values]);

    useEffect(() => {
        let _results = null;

        if (data && data.data && data.data.results && networkStatus === 7) {
            _results = data.data.results;
        }
        if (data && data.data && !data.data.results && networkStatus === 7) {
            _results = data.data;
        }

        if (_results) {
            dispatch(setLoadingModalContent(false));

            let idsToSelect = data.selected && data.selected.results ? data.selected.results : null;
            if (selectedIdsForOQ) {
                idsToSelect = selectedIdsForOQ;
            }
            if (selectedIdsPath) {
                // eslint-disable-next-line
                idsToSelect = eval(selectedIdsPath);
            }

            let tmpOptions = [];
            _results.map((item) =>
                //EXCEPCION PARA GESTIÖN DE ROLES DE MANAGER
                !roleOptions ||
                (roleOptions &&
                    (data.projectAccesses.includes(item.name) ||
                        item?.category === "General Settings" ||
                        (item?.category === "Legacy" && permissions?.product?.legacy)))
                    ? tmpOptions.push({
                          ...parseOptionsSelect(item, {
                              ...parseInfo,
                              idsToSelect,
                          }),
                          category: t(item?.type?.toLowerCase() ?? item?.category),
                          type: t(item?.type?.toLowerCase() ?? item?.category),
                      })
                    : null
            );
            if (!idsToSelect && forceSelection && _results[0]) {
                idsToSelect = [];
                idsToSelect.push(_results[0]);
            }
            if (idsToSelect) {
                let idsArray = [];
                idsToSelect.map((item) => (!idsArray.includes(item.id) ? idsArray.push(item.id) : null));
                dispatch(changeActionValues({ [name]: idsArray }));
            }
            if (sort) {
                tmpOptions.sort((a, b) => {
                    if (a.category !== b.category) {
                        return String(t(a.category)).localeCompare(String(t(b.category)));
                    } else {
                        return a.name ? String(t(a.name)).localeCompare(String(t(b.name))) : 0;
                    }
                });
            } else {
                //Always sort by category to avoid splitting categories
                tmpOptions.sort((a, b) =>
                    a.category ? String(t(a.category)).localeCompare(String(t(b.category))) : 0
                );
            }
            setDataOptions(tmpOptions);
        }
        // eslint-disable-next-line
    }, [data, networkStatus]);

    useEffect(() => {
        let containerModal = document.querySelector(".modal-body");
        if (
            validationsQuery &&
            validationsQuery.validationElementsEdit &&
            validationsQuery.validationElementsEdit.length > 0
        ) {
            // eslint-disable-next-line
            validationsQuery.validationElementsEdit.map((element) => {
                if (containerModal) {
                    let items = containerModal.querySelectorAll(`[${element.attr}=${element.value}]`);
                    if (items && items.length > 0 && element.class) {
                        items.forEach(async (item) => {
                            if (element.invert) {
                                item.classList.add(element.class);
                            } else {
                                item.classList.remove(element.class);
                            }
                        });
                    }
                }
            });
        }
        if (dataValidations && dataValidations.validationInfo) {
            if (validationsQuery && validationsQuery.validationAttribute) {
                if (
                    handlerValidateQueryInformation(
                        dataValidations.validationInfo,
                        validationsQuery.validationAttribute,
                        validationsQuery.validationType,
                        validationsQuery.validationIgnoreValue
                    )
                ) {
                    if (validationsQuery.validationElementsEdit && validationsQuery.validationElementsEdit.length > 0) {
                        // eslint-disable-next-line
                        validationsQuery.validationElementsEdit.map((element) => {
                            if (containerModal) {
                                let items = containerModal.querySelectorAll(`[${element.attr}=${element.value}]`);
                                if (items && items.length > 0 && element.class) {
                                    items.forEach(async (item) => {
                                        if (element.invert) {
                                            item.classList.remove(element.class);
                                        } else {
                                            item.classList.add(element.class);
                                        }
                                    });
                                }
                            }
                        });
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [dataValidations]);

    const removeOption = (e) => {
        e.stopPropagation();
        let unselectUnique = false;
        const temCopy = cloneObjectArray(dataOptions);
        temCopy.map((item) =>
            String(item.id) === String(e.currentTarget.dataset.id)
                ? (item.selected && item.selectUnique ? (unselectUnique = true) : null, (item.selected = false))
                : null
        );

        if (unselectUnique) {
            temCopy.map((item) => (!item.selectUnique ? ((item.selected = false), (item.disabled = false)) : null));
        }

        setDataOptions(temCopy);

        const selectedIds = temCopy.map((item) => (item.selected ? item.id : null));
        dispatch(
            changeActionValues({
                [name]: selectedIds.filter((item) => item !== null),
            })
        );
    };

    const filterZones = (e) => {
        e.stopPropagation();
        const temCopy = cloneObjectArray(dataOptions);
        if (e.currentTarget.value && e.currentTarget.value.length > 0) {
            setSearching(true);
            temCopy.map((item) => {
                item.visible = false;
                if (
                    removeDiacritics((translateOptions ? t(item.name) : item.name).toLowerCase()).indexOf(
                        removeDiacritics(e.currentTarget.value.toLowerCase())
                    ) > -1 ||
                    (item.category &&
                        removeDiacritics(item.category)
                            .toLowerCase()
                            .indexOf(removeDiacritics(e.currentTarget.value.toLowerCase())) > -1)
                ) {
                    item.visible = true;
                }
            });
        } else {
            setSearching(false);
            temCopy.map((item) => (item.visible = true));
        }
        setDataOptions(temCopy);
    };

    const changeVisibility = () => {
        if (disabled) {
            return;
        }
        if (isComponentVisible) {
            const temCopy = cloneObjectArray(dataOptions);
            temCopy.map((item) => (item.visible = true));
            setDataOptions(temCopy);
        }

        if (!isComponentVisible) {
            setTimeout(function () {
                const inputSearch = document.querySelector(`#search_input`);
                if (inputSearch) {
                    inputSearch.focus();
                }
            }, 150);
        }
        setIsComponentVisible(isComponentVisible ? false : true);
    };

    const onChangeValue = (optionSelected) => {
        if (validationsQuery && validationsQuery.validateOnChange && validationsQuery.query && optionSelected) {
            optionSelected = JSON.parse(optionSelected);
            let valueFromSelectedOption = _.get(
                optionSelected,
                validationsQuery.validateSelectedOptionAttribute
                    ? validationsQuery.validateSelectedOptionAttribute
                    : "id"
            );
            if (valueFromSelectedOption) {
                setQueryValidation(
                    validationsQuery.query.replace("%0", escapeSpecialChars(valueFromSelectedOption.toString()))
                );
                executeValidationQuery();
            }
        }
    };

    const handlerValidateQueryInformation = (
        validationInfo,
        validationAttribute,
        validationType,
        validationIgnoreValue
    ) => {
        let response = false;
        switch (validationType) {
            case "greater_than":
                let value = _.get(validationInfo, validationAttribute);
                if (value) {
                    if (typeof value === "string" && value !== "" && value !== "undefined") {
                        if (validationIgnoreValue) {
                            if (validationIgnoreValue !== value) {
                                response = true;
                            }
                        } else {
                            response = true;
                        }
                    } else if (typeof value === "number" && value > 0) {
                        response = true;
                    }
                }
                break;
            default:
                break;
        }
        return response;
    };

    return (
        <>
            {text ? (
                <span className={`block pb-2 first-capital ${textStyle}`}>{multipleTranslate(text, t)}</span>
            ) : null}
            {instructions ? (
                <span className="block pb-2 first-capital">{multipleTranslate(instructions, t)}</span>
            ) : null}
            <div
                ref={ref}
                className={`h-32px bg-gray-200 rounded relative ${cStyle ? cStyle : "w-full"} ${
                    selectActive.container
                } } ${typeof validations[name] !== "undefined" && !validations[name] ? "border border-red-100" : ""}`}
            >
                <div
                    className={`${disabled ? "text-gray-300" : ""} t-filter-ph z-10 min-h-2rem pr-12`}
                    onClick={changeVisibility}
                    id={title ? `${title}-modal-select-multiple-with-search-${index}` : null}
                >
                    {dataOptions.map((item) =>
                        item.selected && !noSelect ? (
                            <div
                                key={item.id}
                                className={`float-left z-20 pl-2  mr-4 p-1 mb-2 rounded ${
                                    item.disabled ? "px-2" : null
                                } ${capitalizeOptions ? "first-capital" : ""} ${
                                    item.selectedLabelColor
                                        ? item.selectedLabelColor
                                        : labelsColors
                                        ? labelsColors
                                        : "bg-blue-200 text-white"
                                }`}
                            >
                                <div className="flex">
                                    {displayItem(item, translateOptions ? t : null)}
                                    {!item.disabled ? (
                                        <div
                                            className="icon-remove inline-block ml-8 cursor-pointer"
                                            data-index={index}
                                            data-id={item.id}
                                            onClick={removeOption}
                                        ></div>
                                    ) : null}
                                </div>
                            </div>
                        ) : null
                    )}
                    <div className="text-gray-800">
                        {noSelect && dataOptions.filter((item) => item.selected === true).length > 0 ? (
                            dataOptions.filter((item) => item.selected === true).length === 1 && oneSelected ? (
                                displayItem(
                                    dataOptions.filter((item) => item.selected === true)?.[0],
                                    translateOptions ? t : null
                                )
                            ) : (
                                <span className={"rounded py-1 px-2 text-white bg-zafiro-400 text-sm"}>
                                    {t("x items selected", {
                                        count: dataOptions.filter((item) => item.selected === true).length,
                                    })}
                                </span>
                            )
                        ) : null}
                    </div>
                    {dataOptions.filter((item) => item.selected === true).length === 0
                        ? capitalizeFirst(t(selectPlaceHolder))
                        : null}
                </div>
                <div className="w-full flex">
                    <span
                        className={`${
                            disabled ? "text-gray-300" : "cursor-pointer"
                        } field-icon icon-chevron animated  ${selectActive.icon}`}
                        onClick={changeVisibility}
                    ></span>
                    {isComponentVisible ? (
                        <div className={`t-options-container mt-2 ${optionsAdjust}`}>
                            {!noSearch ? (
                                <div>
                                    <div>
                                        <input
                                            id={`search_input`}
                                            type="text"
                                            className="t-filter-search"
                                            style={{ width: "calc(100% - 1rem)" }}
                                            placeholder={t("search") + "..."}
                                            onChange={filterZones}
                                        ></input>
                                        <span
                                            className="field-icon icon-search table mt-6"
                                            style={{ height: "2rem" }}
                                        ></span>
                                    </div>
                                </div>
                            ) : null}
                            <span>
                                {loading ? (
                                    <UseLoading />
                                ) : (
                                    <>
                                        {!dataOptions || (dataOptions && dataOptions.length === 0) ? (
                                            <div className="text-gray-800 p-2">
                                                {t("{{capitalize, capitalize}}", {
                                                    capitalize: t("no options available"),
                                                })}
                                            </div>
                                        ) : (
                                            <UseCheckOptions
                                                dataOptions={dataOptions}
                                                translateOptions={translateOptions}
                                                capitalizeOptions={capitalizeOptions}
                                                setDataOptions={setDataOptions}
                                                searching={searching}
                                                index={index}
                                                groupsNames={groupsNames}
                                                orderOptions={orderOptions}
                                                onChangeValue={onChangeValue}
                                            />
                                        )}
                                    </>
                                )}
                            </span>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export const displayItem = (item, t) => {
    if (item.display) {
        if (item.display.type === "ProjectName") {
            return <ProjectName {...item.display.props} />;
        }
        return React.createElement(item.display.type, item.display.props);
    }
    if (t) {
        return Parser(t(item.name));
    }
    return Parser(item.name);
};

const cloneObjectArray = (array) => {
    return array.map((item) => (typeof item === "object" ? { ...item } : item));
};

export default UseSelectMultipleWithSearch;
