import Users from "components/Section/Settings/Users";
import Roles from "components/Section/Settings/Roles";
import Languages from "components/Section/Settings/Languages";
import PredefinedRoles from "components/Section/Settings/PredefinedRoles.jsx";
import PolicyAndConditions from "components/Section/Settings/PolicyAndConditions";
import TaxZones from "components/Section/Settings/TaxZones";
import { newRoute, newRedirect } from "routes/utils";
import Staff from "components/Section/CommonZones/Staff";

const sectionID = "general-settings";

const route = newRoute(sectionID);
const redirect = newRedirect(sectionID);

const checkSettings = ({ permissions }) => permissions?.managerSetup;
const checkUsers = ({ permissions }) => permissions?.managerSetup?.manageUsers;
const checkRoles = ({ permissions }) => permissions?.managerSetup?.manageRoles;
const checkPredefinedRoles = ({ permissions, isSuperUser }) => isSuperUser && permissions?.managerSetup?.manageRoles;
const checkStaff = ({ permissions }) => permissions?.managerSetup?.staff;
const checkTaxZones = ({ permissions }) => permissions?.managerSetup?.taxManagement;
const checkLanguages = ({ permissions }) => permissions?.managerSetup?.manageLanguages;
const checkCorporate = ({ isCorporate }) => isCorporate;

const routes = [
    route("/", { initial: true, redirect: redirect("/user-management"), check: checkSettings }),

    // User management
    route("/user-management", { redirect: redirect("/user-management/users") }),
    // Manager users
    route("/user-management/users", { section: Users, check: checkUsers }),
    // Manager roles
    route("/user-management/roles", { section: Roles, check: checkRoles }),
    // Predefined roles
    route("/user-management/predefined-roles", {
        section: PredefinedRoles,
        check: checkPredefinedRoles,
        fullscreen: true,
    }),
    // App staff users
    route("/user-management/app-staff-users", { section: Staff, check: checkStaff }),

    // Language management
    // Content languages
    route("/language-management", { redirect: redirect("/language-management/languages") }),
    route("/language-management/languages", { section: Languages, check: checkLanguages }),

    // Policy and conditions management
    route("/policy-and-conditions-management", {
        redirect: redirect("/policy-and-conditions-management/policy-and-conditions"),
    }),
    route("/policy-and-conditions-management/policy-and-conditions", {
        section: PolicyAndConditions,
        check: checkCorporate,
    }),

    // Finance
    route("/finance", { redirect: redirect("/finance/tax-zones") }),
    // Tax zones
    route("/finance/tax-zones", { section: TaxZones, check: checkTaxZones }),
];

export default routes;
