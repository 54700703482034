import React from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "components/ReactTooltip";

export const LastUpdateToolTip = ({ time, isDhcp = false }) => {
    const { t } = useTranslation();

    return (
        <>
            {isDhcp ? (
                <ReactTooltip id={`last-update-tooltip-${time}`} delayShow={500} type="light">
                    {t(`update-dhcp`, {
                        day: time,
                    })}
                </ReactTooltip>
            ) : (
                <ReactTooltip id={`last-update-tooltip-${time}`} delayShow={500} type="light">
                    {t(`update-every`, {
                        minutes: time,
                    })}
                </ReactTooltip>
            )}
        </>
    );
};
