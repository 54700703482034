import React from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseIcon from "../../useIcon";
//Actions
import { setActionName, setActionItemAffected, setActionData } from "../../../actions/globalActions";
import { showRowForm, setParametersSection } from "../../../actions/sectionActions";
import { setRowInEdition } from "../../../actions/tableActions";

//Utils
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import translate from "../../../lang/es/CommonZones/translate";

const UseEditableFormCell = ({ cellData, setHideRow, rowIndex }) => {
    const { hidden, value, specialWidth, icon, currentValue, link, parametersSection } = cellData || {};
    const { rowForm } = useSelector((state) => state.sectionContent);
    const tCellEdit = " t-cell-edit";
    const editIconStyle = " ml-2 text-2xl text-gray-800";
    const iconStyle = !currentValue ? tCellEdit : editIconStyle;

    //Actions
    const dispatch = useDispatch();
    const iconClicHandler = (e) => {
        if (rowForm) {
            toast.error(translate("multiple-edit-error"));
        } else {
            const actionChoosen = e.currentTarget.dataset.action;
            const affectedId = e.currentTarget.dataset.id;
            setHideRow(true);
            dispatch(setRowInEdition(rowIndex));
            dispatch(setActionName(actionChoosen));
            dispatch(setActionItemAffected(affectedId));
            dispatch(setActionData({ name: e.currentTarget.dataset.value }));
            dispatch(showRowForm(true));
        }
    };
    const linkClickHandler = () => {
        if (parametersSection) dispatch(setParametersSection(parametersSection));
    };
    return (
        <>
            {!hidden ? (
                <div className={`w-full text-left flex items-center ${specialWidth}`}>
                    {link ? (
                        <Link className={`${icon ? "w-auto max-w-80" : "w-full"}`} to={link} onClick={linkClickHandler}>
                            <div className={`contents text-blue-300 font-bold ${icon ? "w-9/12" : "w-full"}`}>
                                {value}
                            </div>
                        </Link>
                    ) : (
                        <div className={`inline-block ${icon ? "w-auto max-w-80" : "w-full"}`}>{value}</div>
                    )}
                    <div data-action={icon.action} data-id={icon.id} data-value={icon.value} onClick={iconClicHandler}>
                        <UseIcon icon={icon} adjust={iconStyle} />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default UseEditableFormCell;
