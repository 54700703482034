import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
//Actions

const UseSearch = ({ handleChangeSearch, onEnter, onChange, value, placeholder, id }) => {
    const { t } = useTranslation();

    //Store data
    const [searching, setSearching] = useState(value);
    //Actions

    useEffect(() => {
        if (handleChangeSearch) {
            //TODO quitar eso del e.target.value de los callbacks
            handleChangeSearch({ target: { value: searching } });
        }
        if (onChange) {
            onChange(searching);
        }
        // eslint-disable-next-line
    }, [searching, handleChangeSearch]);

    const _handleKeyDown = (e) => {
        if (e.key === "Enter") {
            const value = e.target.value;
            setSearching(value);
            if (onEnter) {
                onEnter(value);
            }
        }
    };
    const _handleLeave = (e) => {
        if (onEnter) {
            onEnter(searching);
        }
    };
    return (
        <div className="w-full mr-8 relative">
            <input
                id={id ? id : "search"}
                type="text"
                value={searching}
                className="w-full bg-gray-200 rounded hover:text-zafiro-400  text-gray-900 p-2 pl-4"
                placeholder={`${
                    placeholder ? (t(placeholder).length > 1 ? t(placeholder) : placeholder) : t("search")
                }...`}
                onChange={(e) => {
                    setSearching(e.target.value);
                }}
                onKeyDown={_handleKeyDown}
                onMouseLeave={_handleLeave}
            ></input>
            <span className="field-icon icon-search"></span>
        </div>
    );
};

export default UseSearch;
