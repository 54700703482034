const NewGuestGroupModal = (name, ggId) => {
    const guestQuery = `{
        data: guests(filter:{checkedOut:false}, mainGuest: true){
          info { count }
          results { id name surname rooms{name number } }
        }
        ${
            ggId
                ? `selected: guests(filter: {guestGroup:${ggId}}){
                        results { id }
                    }`
                : ""
        }
        }`;

    return [
        {
            title: "new-group-of-guests",
            bodyAdjust: "w-10/12",
            twoColumns: true,
            id: ggId,
            inputs: [
                {
                    text: ["give-group-name", ":"],
                    name: "name",
                    value: name,
                    type: "text",
                    ph: "group-name",
                    cStyle: "w-6/12",
                },
                {
                    text: ["guests-added", ":"],
                    name: "guests-of-group",
                    ph: "guests",
                    type: "selectedResume",
                    optionsQuery: guestQuery,
                    style: "row-span-2",
                    cStyle: "w-full",
                },
                {
                    text: ["select-group-guest", ":"],
                    name: "guests-of-group",
                    type: "selectMultipleWithSearch",
                    sort: true,
                    optionsQuery: guestQuery,
                    noSelect: true,
                    cStyle: "w-9/12",
                    selectPlaceHolder: "guests",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "accept",
                    style: "blue",
                    action: "add-guest-group",
                },
            ],
        },
    ];
};

export default NewGuestGroupModal;
