import React from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table/index";
//Utils
import ListCleaning from "../../../hooks/GraphqlCalls/CommonZones/ListCleaning";

const Cleaning = () => {
    ListCleaning();
    return (
        <>
            <UseSectionHeader title={"cleaning"} />
            <UseTable />
        </>
    );
};

export default Cleaning;
