import React from "react";
import { DEVICE_FUNCTIONS, DEVICE_KINDS } from "../../../../Utils/Hotel/Monitor/ListDevicesUtils";
import { LogOutUsers } from "../modals/LogOutUsers";

export const DevicesCards = (data, permissions, dataWifiLicenses, open) => {
    const wifiLicensesAvailable = dataWifiLicenses?.available ? Number(dataWifiLicenses.available) : 0;
    const wifiLicensesUsed = dataWifiLicenses?.used ? Number(dataWifiLicenses.used) : 0;
    const percentLicenses = Math.floor(wifiLicensesAvailable * 0.95);
    const tvWarnigns = countWarnings({
        kind: DEVICE_KINDS.tv.value,
        devices: data,
    });
    const chromecastWarnings = countWarnings({
        kind: DEVICE_KINDS.chromecast.value,
        devices: data,
    });

    const makeCards = () => {
        let _cards = [];
        if (permissions?.product.tv) {
            _cards.push({
                icon: "tv",
                count: tvWarnigns,
                title: "warnings",
                subtitle: "tv-devices",
                textColor: tvWarnigns >= 1 ? "text-red-100" : null,
                noShadow: true,
            });
        }
        if (permissions?.product.cast) {
            _cards.push({
                icon: "chromecast",
                count: chromecastWarnings,
                title: "warnings",
                subtitle: "chromecasts",
                textColor: chromecastWarnings >= 1 ? "text-red-100" : null,
                noShadow: true,
            });
        }

        if (permissions?.product.wifi) {
            _cards.push({
                icon: "Wifi-access",
                count: `${wifiLicensesUsed} `,
                title: `/ ${wifiLicensesAvailable}`,
                textColor: wifiLicensesUsed >= percentLicenses ? "text-red-100" : null,
                subtitle: "WiFi Users",
                actions: [
                    {
                        label: "reset-wifi-licenses",
                        onClick: () => open(<LogOutUsers />),
                    },
                ],
                noShadow: true,
            });
        }

        return _cards;
    };

    return makeCards();
};

const countWarnings = (props) => {
    const { kind, devices } = props;
    let count = 0;
    if (kind && devices && devices.length > 0) {
        let validatedFunctions = [];
        if (kind === DEVICE_KINDS.tv.value) {
            validatedFunctions.push(DEVICE_FUNCTIONS.tv.value, DEVICE_FUNCTIONS.tvAndcast.value);
        } else if (kind === DEVICE_KINDS.chromecast.value) {
            validatedFunctions.push(DEVICE_FUNCTIONS.cast.value, DEVICE_FUNCTIONS.tvAndcast.value);
        }
        devices.forEach((device) => {
            let increment = false;
            let level = device.warning ? device.warning.level : 0;
            if (level < 2 && validatedFunctions.includes(device.function)) {
                increment = true;
            }
            if (increment) {
                count++;
            }
        });
    }
    return count;
};
