import React, { useEffect } from "react";
import LibTooltip from "react-tooltip";

/// Wrap ReactTooltip with default values and html always to false
const ReactTooltip = (props) => {
    const config = {
        delayShow: 250,
        type: "light",
        backgroundColor: "#fff",
        borderColor: "#BDC8D3", // text-gray-400
        className: "font-normal",
        multiline: true,
        border: true,
        wrapper: "span",
        offset: { top: -8, left: -8 },
        ...props,
        arrowColor: props?.arrowColor || props.backgroundColor || "#fff",
        html: props?.dangerousHtml, // Inject raw HTML? (This is a security risk)
    };
    return <LibTooltip {...config} />;
};

ReactTooltip.rebuild = LibTooltip.rebuild;
ReactTooltip.show = LibTooltip.show;
ReactTooltip.hide = LibTooltip.hide;

/**
 * This is a workaround to rebuild tooltips when new ones are added to the DOM (unnecessary in v5 of react-tooltip)
 */
export const useRefresh = () => {
    useEffect(() => {
        const foundNewTooltip = (node) => {
            if (!node) return false;
            if (node.nodeType === 1 && node.hasAttribute("data-tip")) {
                return true;
            }
            if (node.childNodes.length === 0) return false;
            for (let child of node.childNodes) {
                if (foundNewTooltip(child)) {
                    return true;
                }
            }
            return false;
        };
        const foundOpenTooltip = (node) => {
            if (!node) return false;
            // if has class __react_component_tooltip
            if (node.nodeType === 1 && node.classList.contains("__react_component_tooltip")) {
                return true;
            }
            if (node.childNodes.length === 0) return false;
            for (let child of node.childNodes) {
                if (foundOpenTooltip(child)) {
                    return true;
                }
            }
            return false;
        };
        const observer = new MutationObserver((mutationsList) => {
            for (let mutation of mutationsList) {
                if (mutation.type === "childList") {
                    for (let node of mutation.addedNodes) {
                        if (foundOpenTooltip(node)) {
                            ReactTooltip.hide();
                            return;
                        }
                        if (foundNewTooltip(node)) {
                            ReactTooltip.rebuild();
                            return;
                        }
                    }
                }
            }
        });
        observer.observe(document.getElementById("root"), { attributes: false, childList: true, subtree: true });

        return () => {
            observer.disconnect();
        };
    }, []);
};

export default ReactTooltip;
