import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Switch from "components/Switch";
import { gql } from "apollo-boost";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";

const GuestInfoMovies = () => {
    const { t } = useTranslation();
    const { data } = useSelector((state) => state.sectionContent);

    const MUTATIONS = {
        UPDATE_ADULT_ACCESS: gql`
            mutation UpdateAdultMoviesAccess($stayGuestRoomIDs: [Int64]!, $adultEnable: Boolean!) {
                updateAdultMoviesAccess(stayGuestRoomIDs: $stayGuestRoomIDs, adultEnable: $adultEnable) {
                    id
                    ok
                    error
                }
            }
        `,
    };

    const [updateAdultMoviesAccess, { loading, data: updateData }] = useMutation(MUTATIONS["UPDATE_ADULT_ACCESS"], {
        onCompleted: (data) => {},
        onError: (err) => {
            toast.error(t("error-mutation"));
            console.log(err);
        },
    });

    const handleChecked = (checked) => {
        updateAdultMoviesAccess({
            variables: {
                stayGuestRoomIDs: data?.rooms?.map((room) => room.stayGuestRoomID),
                adultEnable: checked,
            },
        });
    };

    return (
        <div className={`bg-white px-8  py-5 mb-8`}>
            <div className="border-b border-gray-200 text-lg py-2 flex justify-between">
                <div className="capitalize font-bold">{`${t("movies")}`}</div>
            </div>
            <div>
                <div className=" mt-4 font-bold">{t("adult-movies")}</div>
                <div className="flex justify-between mt-4">
                    <div>{t("show-adult-movies")}</div>
                    <Switch
                        checked={data?.rooms?.[0].isAdultEnabled}
                        onChange={({ checked }) => handleChecked(checked)}
                    />
                </div>
            </div>
        </div>
    );
};

export default GuestInfoMovies;
