import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { execute, gql } from "apollo-boost";
import { useMutation } from "react-apollo";
import { useLoading } from "components/Loading";
import { toast } from "react-toastify";

const UpdateAssociatedProperties = ({ execute }) => {
    const { id } = useParams();
    const { setLoading } = useLoading();
    const navigate = useNavigate();

    const fakeMutation = gql(`mutation{
        fakeMutation{
            error
            ok
        }
    }`);

    const redirectTo = () => {
        setLoading(false);
        navigate(`/all-properties/mobile-apps/${id}/`);
    };

    const catchError = (error) => {
        toast.error(error.message);
        redirectTo();
    };

    const [mutation, setMutation] = useState(fakeMutation);

    const [secondaryMutation, setSecondaryMutation] = useState(fakeMutation);

    const [executeMutation, { data: dataMutation }] = useMutation(mutation, {
        onError: (error) => {
            catchError(error);
        },
    });
    const [executeSecondaryMutation, { data: dataSecondaryMutation }] = useMutation(secondaryMutation, {
        onError: (error) => {
            catchError(error);
        },
    });

    useEffect(() => {
        if (execute) {
            setLoading(true);
            let mutation = ` mutation{`;
            if (execute && execute.find((exe) => exe.action === "updateProjects")) {
                mutation += BuildMutationUpdateProjects({ execute, mutation });
            }
            if (execute && execute.find((exe) => exe.action === "addProjects")) {
                mutation += BuildMutationAddProjects({ execute, mutation, id });
            }
            mutation += `}`;

            setMutation(gql(mutation));
            setTimeout(() => {
                executeMutation();
            }, 400);
        }
    }, [execute]);

    useEffect(() => {
        if (dataSecondaryMutation?.updateProjects?.ok) {
            redirectTo();
        }
        console.log(dataSecondaryMutation);
    }, [dataSecondaryMutation]);

    useEffect(() => {
        if (dataMutation) {
            if (dataMutation?.updateMobileApp?.ok) {
                const params = execute?.find((exe) => exe.action === "addProjects")?.params;
                if (params) {
                    const newProjects = params.filter((project) => project.isNew);

                    if (newProjects.length > 0) {
                        let mutation = BuildMutationPostAddProjects({ newProjects });
                        setSecondaryMutation(gql(mutation));
                        setTimeout(() => {
                            executeSecondaryMutation();
                        }, 400);
                    } else {
                        redirectTo();
                    }
                }
            }

            if (dataMutation?.updateProjects?.ok && !dataMutation?.updateMobileApp) {
                redirectTo();
            }

            if (dataMutation?.updateProjects?.ok === false) {
                toast.error("");

                console.log(dataMutation);
            }
        }

        console.log(dataMutation);
    }, [dataMutation]);

    return null;
};

export default UpdateAssociatedProperties;

const BuildMutationUpdateProjects = ({ execute }) => {
    const params = execute?.find((exe) => exe.action === "updateProjects")?.params;
    const idsPropertieWithInfo = Object.entries(params).map(([id, info]) => ({ id, info }));
    let mutation = "";

    const asssignFieldToUpdateMutation = ({ field, type }) => {
        if (type === "appAvailable") {
            console.log(field);
            console.log(type);
        }

        if (type === "picture" && (field || field === null)) {
            return `picture: "${field}"`;
        } else if (type === "phone" && (field?.trim() === "" || field)) {
            return `chatNumberLada: "${field.split(" ")?.[0] || ""}",
                    chatNumber: "${field.split(" ")?.[1] || ""}"`;
        } else if (type === "appAvailable" && (field === true || field === false)) {
            return `appAvailable: ${field}`;
        } else {
            return "";
        }
    };

    if (idsPropertieWithInfo?.length > 0) {
        mutation += ` updateProjects( projects:[`;
        idsPropertieWithInfo.forEach((p) => {
            console.log(p);

            let text = `
                {
                    id: ${p?.info?.id},
                    information:{
                        ${asssignFieldToUpdateMutation({ field: p?.info?.changes?.picture, type: "picture" })}
                        ${asssignFieldToUpdateMutation({ field: p?.info?.changes?.phone, type: "phone" })}
                        ${asssignFieldToUpdateMutation({
                            field: p?.info?.changes?.appAvailable,
                            type: "appAvailable",
                        })}
                    }
                },
            `;
            mutation += text;
        });
        mutation += `]
            ){
                error
                ok
            }
        `;
    }

    return mutation;
};

const BuildMutationAddProjects = ({ execute, id }) => {
    const params = execute?.find((exe) => exe.action === "addProjects")?.params;

    let mutation = "";
    const projectRefs = params.map((project) => project.projectRef);
    mutation += `updateMobileApp( id: ${id}, projectRefs: [`;
    projectRefs.forEach((project) => {
        mutation += `"${project}",`;
    });
    mutation += `] ) { error ok }`;
    return mutation;
};

const BuildMutationPostAddProjects = ({ newProjects }) => {
    let mutation = ` mutation{`;
    mutation += ` updateProjects( projects:[`;
    newProjects.forEach((p) => {
        let text = `
            {
                id: ${p.id},
                information:{
                    picture: "${p.picture || ""}",
                    chatNumberLada: "${p.phone?.trim()?.split(" ")?.[0] || ""}",
                    chatNumber: "${p.phone?.trim()?.split(" ")?.[1] || ""}",
                    appAvailable: ${p.appAvailable ? true : false}
                }
            },

        `;

        mutation += text;
    });
    mutation += `] ){ error ok } }`;

    return mutation;
};
