import React from "react";

import ListAccountsUsers from "hooks/GraphqlCalls/Auth/ListAccountsUsers";

import UseSectionHeader from "components/useSectionHeader";
import UseTable from "components/Table";
import UserMenu from "components/UserMenu";
import ZafiroLogo from "components/ZafiroLogo";

const ImpersonateList = () => {
    ListAccountsUsers();
    return (
        <>
            <div className="absolute top-0 right-0 flex flex-row space-x-5 p-5">
                <UserMenu />
            </div>
            <div className="bg-white h-24 shadow">
                <div className="p-3 h-full m-auto">
                    <ZafiroLogo />
                </div>
            </div>
            <div className="p-8">
                <UseSectionHeader title={"impersonate user"} navToSection={"/select-profile"} />
                <UseTable />
            </div>
        </>
    );
};

export default ImpersonateList;
