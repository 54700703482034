import React, { useEffect, useState } from "react";
import CustomFieldItem from "./CustomFieldItem";
import { sortableContainer, sortableElement, arrayMove } from "react-sortable-hoc";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { cleanActionValues, setActionValidations } from "actions/globalActions";
import { EN_CUSTOM_FIELD, ES_CUSTOM_FIELD } from "../Precheckin/ReservationDetails/utils";
import { closeModal, setModalContent } from "actions/uiActions";
import AddCustomItem from "../Precheckin/ReservationDetails/modals/AddCustomItem";
import { openModal } from "actions/uiActions";
import { toast } from "react-toastify";
import uuid from "react-uuid";
import { capitalizeFirst } from "hooks/Utils/Utils";

const SortableContainer = sortableContainer(({ children, fields }) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col relative pt-3 ">
            {fields?.length > 0 ? (
                <div className=" absolute right-8  -mt-4  ">{capitalizeFirst(t("mandatory"))}</div>
            ) : null}
            {children}
        </div>
    );
});

const CustomFieldsItems = ({
    precheckinCustomFields: initialPrecheckinCustomFields,
    childrenPrecheckinCustomFields: initialChildrenPrecheckinCustomFields,
    updateCustomFields,
    setMutationCustomFields,
    currentTab,
}) => {
    const { t } = useTranslation();
    const [actionToTrigger, setActionToTrigger] = useState(null);
    const dispatch = useDispatch();

    const [fields, setFields] = useState([]);
    const [initialFields, setInitialFields] = useState([]);
    const [childrenFields, setChildrenFields] = useState([]);
    const [childrenInitialFields, setChildrenInitialFields] = useState([]);

    useEffect(() => {
        if (initialPrecheckinCustomFields?.length > 0) {
            const sortedFields = [...initialPrecheckinCustomFields].sort((a, b) => a.order - b.order);
            setFields(sortedFields);
            if (initialFields.length === 0) {
                setInitialFields(sortedFields);
            }
        }

        if (initialChildrenPrecheckinCustomFields?.length > 0) {
            const sortedFields = [...initialChildrenPrecheckinCustomFields].sort((a, b) => a.order - b.order);
            setChildrenFields(sortedFields);
            if (childrenInitialFields.length === 0) {
                setChildrenInitialFields(sortedFields);
            }
        }
    }, [initialPrecheckinCustomFields, initialChildrenPrecheckinCustomFields]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let newFields = currentTab.id === 1 ? [...childrenFields] : [...fields];

        const oldOrder = newFields[oldIndex].order;
        const newOrder = newFields[newIndex].order;

        newFields[oldIndex].order = newOrder;
        newFields[newIndex].order = oldOrder;

        newFields = arrayMove(newFields, oldIndex, newIndex);

        if (currentTab?.id === 1) {
            setChildrenFields(newFields);
        } else {
            setFields(newFields);
        }
    };

    const fieldsToRender = () => {
        return currentTab?.id === 1 ? childrenFields : fields;
    };

    return (
        <>
            <div
                style={{ minHeight: "20rem", maxHeight: "20rem", overflowY: "scroll" }}
                id="custom-fields-items-container"
            >
                <SortableContainer onSortEnd={onSortEnd} fields={fields} distance={30} helperClass="dragging" axis="y">
                    {fieldsToRender().map((item, index) => {
                        return (
                            <SortableItem
                                key={`item-${item.ref || item.temporaryRef}`}
                                index={index}
                                info={item}
                                setActionToTrigger={setActionToTrigger}
                                setFields={currentTab?.id === 1 ? setChildrenFields : setFields}
                                fields={currentTab?.id === 1 ? childrenFields : fields}
                                itemIndex={index}
                            />
                        );
                    })}
                </SortableContainer>
            </div>
            <button
                onClick={() => {
                    dispatch(
                        setModalContent(
                            AddCustomItem({
                                action: () => {
                                    setActionToTrigger({ action: "add-custom-precheckin-field" });
                                },
                            })
                        )
                    );
                    dispatch(openModal());
                }}
                className=" mt-4 flex text-zafiro-600 font-bold items-center mr-2"
                id="add-custom-item"
            >
                <i className=" icon icon-add"></i>
                <div className=" ml-2">{t("add-custom-item")}</div>
            </button>
            <InternalUpdateCustomFields
                setFields={currentTab?.id === 1 ? setChildrenFields : setFields}
                fields={currentTab?.id === 1 ? childrenFields : fields}
                actionToTrigger={actionToTrigger}
                setActionToTrigger={setActionToTrigger}
            />
            <UpdateCustomFields
                updateCustomFields={updateCustomFields}
                initialFields={initialFields}
                fields={fields}
                childrenFields={childrenFields}
                childrenInitialFields={childrenInitialFields}
                setMutationCustomFields={setMutationCustomFields}
                currentTab={currentTab}
            />
        </>
    );
};

const SortableItem = sortableElement(({ info, setActionToTrigger, setFields, fields, itemIndex }) => {
    return (
        <div className="sortable-item-wrapper">
            <CustomFieldItem
                data={info}
                setActionToTrigger={setActionToTrigger}
                setFields={setFields}
                fields={fields}
                itemIndex={itemIndex}
            />
        </div>
    );
});

const InternalUpdateCustomFields = ({ fields, setFields, actionToTrigger, setActionToTrigger }) => {
    const values = useSelector((state) => state.action.values);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function validateFields(values) {
        if (
            (values?.[EN_CUSTOM_FIELD]?.trim() === "" || values?.[EN_CUSTOM_FIELD] == null) &&
            (values?.[ES_CUSTOM_FIELD]?.trim() === "" || values?.[ES_CUSTOM_FIELD] == null)
        ) {
            dispatch(setActionValidations({ [EN_CUSTOM_FIELD]: false, [ES_CUSTOM_FIELD]: false }));
            toast.error(t("you-need-to-fill-at-least-one-field"));
            setActionToTrigger(null);
            return false;
        }
        return true;
    }

    function reset() {
        dispatch(cleanActionValues());
        dispatch(setModalContent(false));
        dispatch(closeModal());
        setActionToTrigger(null);
    }

    function handleAddField(values, fields) {
        const NewField = {
            blocked: false,
            enabled: true,
            id: null,
            required: false,
            temporaryRef: uuid(),
            order: fields.length,
            names: [
                { lang: "en", name: values?.[EN_CUSTOM_FIELD] || "" },
                { lang: "es", name: values?.[ES_CUSTOM_FIELD] || "" },
            ],
        };

        const newFields = [...fields];
        newFields.push(NewField);

        setFields(newFields);
        reset();
    }

    function handleDeleteField(fields, actionToTrigger) {
        const isTemporary = !!actionToTrigger?.data?.temporaryRef;
        const ref = actionToTrigger?.data?.temporaryRef || actionToTrigger?.data?.ref;

        const newFieldsAfterDelete = fields.filter((field) =>
            isTemporary ? field.temporaryRef !== ref : field.ref !== ref
        );

        setFields(newFieldsAfterDelete);
        reset();
    }

    function handleEditField(values, fields, actionToTrigger) {
        const isTemporary = !!actionToTrigger?.data?.temporaryRef;
        const ref = isTemporary ? actionToTrigger?.data?.temporaryRef : actionToTrigger?.data?.ref;

        let index = fields.findIndex((field) => (isTemporary ? field.temporaryRef === ref : field.ref === ref));

        console.log(index);

        if (index !== -1) {
            fields[index] = {
                ...fields[index],
                names: [
                    { lang: "en", name: values?.[EN_CUSTOM_FIELD] || "" },
                    { lang: "es", name: values?.[ES_CUSTOM_FIELD] || "" },
                ],
            };

            setFields(fields);
        }

        reset();
    }

    useEffect(() => {
        if (actionToTrigger?.action) {
            switch (actionToTrigger.action) {
                case "add-custom-precheckin-field":
                    if (validateFields(values)) {
                        handleAddField(values, fields);
                    }
                    break;
                case "delete-custom-item":
                    handleDeleteField(fields, actionToTrigger);
                    break;
                case "edit-custom-item":
                    if (validateFields(values)) {
                        handleEditField(values, fields, actionToTrigger);
                    }
                    break;
                default:
                    break;
            }
        }
    }, [actionToTrigger]);

    return null;
};

const UpdateCustomFields = ({
    fields,
    initialFields,
    setMutationCustomFields,
    currentTab,
    childrenFields,
    childrenInitialFields,
}) => {
    function getMissingRefs(fields, initialFields) {
        const fieldRefs = new Set(fields.map((field) => field.ref));
        const missingRefs = initialFields.filter((initialField) => !fieldRefs.has(initialField.ref));
        return missingRefs.map((field) => field.ref);
    }

    function buildDeleteMutation(refs, isChild = false) {
        return refs
            .map(
                (ref, index) => `
        deletePreCheckInSettingsCustomField${isChild ? "Children" : ""}${index}: deletePreCheckInSettingsCustomField${
                    isChild ? "Children" : ""
                }(
            ref:"${ref}"
        ){
            ok
            error
        }
    `
            )
            .join("");
    }
    function buildMutation(element, index, isChild = false, isAdd = false) {
        const enName = element?.names?.find((el) => el.lang === "en")?.name || "";
        const esName = element?.names?.find((el) => el.lang === "es")?.name || "";
        const action = isAdd ? "add" : "update";
        const suffix = isChild ? "Children" : "";

        return `
        ${action}PreCheckInSettingsCustomField${
            isChild ? "Children" : ""
        }${index}: ${action}PreCheckInSettingsCustomField${suffix}(
            ${isAdd ? "" : `ref:"${element.ref}"`}
            enabled: ${element?.enabled ? true : false}
            required: ${element?.required ? true : false}
            order: ${index}
            names:[
                { lang:"en", name:"${enName}" },
                { lang:"es", name:"${esName}" }
            ]
        ){
            ${isAdd ? "ref" : "ok"}
            ok
            error
        }
    `;
    }

    useEffect(
        () => {
            let mutation = "";
            const missingRefs = getMissingRefs(fields, initialFields);
            const missingRefsChildren = getMissingRefs(childrenFields, childrenInitialFields);

            mutation += buildDeleteMutation(missingRefs);
            mutation += buildDeleteMutation(missingRefsChildren, true);

            fields.forEach((element, index) => {
                if (element.ref) {
                    mutation += buildMutation(element, index);
                }
                if (element.temporaryRef) {
                    mutation += buildMutation(element, index, false, true);
                }
            });

            childrenFields.forEach((element, index) => {
                if (element.ref) {
                    mutation += buildMutation(element, index, true);
                }
                if (element.temporaryRef) {
                    mutation += buildMutation(element, index, true, true);
                }
            });

            setMutationCustomFields(mutation);
        },
        [childrenInitialFields, childrenFields, fields, initialFields].map((fields) => JSON.stringify(fields))
    );

    return null;
};

export default CustomFieldsItems;
