import React, { useContext, useEffect, useState } from "react";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";
import { useSelector } from "react-redux";

import "../../../../assets/fonts/fontAwesome/css/all.min.css";
import "../../../../assets/fonts/fontAwesome/css/brands.min.css";
import "../../../../assets/fonts/fontAwesome/css/fontawesome.min.css";
import "../../../../assets/fonts/fontAwesome/css/regular.min.css";
import "../../../../assets/fonts/fontAwesome/css/solid.min.css";

//Utils

const WidgetTVMenuNav = ({ insideMenu, menuStatusStyle }) => {
    const { itemSelected } = useContext(EditorContext);
    const { id: widgetID } = useContext(WidgetContext);
    const [render, setRender] = useState(false);
    const [statusStyle, setStatusStyle] = useState(null);
    const { widgetToolbarVisibleTab: tab, gridItems } = useSelector((state) => state.design);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;

    if (widget && widgetID) {
        widget.type = "TVMENUNAV";
        widget.ID = widgetID;
        widget.insideMenu = insideMenu === true ? true : false;
    }

    useEffect(() => {
        //Timeout to wait widget_zone exists
        setTimeout(function () {
            setRender(true);
            setStatusStyle("defaultColor");
        }, 200);
    }, []);

    const getStatusStyle = (tab) => {
        let statusStyle = "";
        switch (tab) {
            case "regular":
            case "defaultColor":
                statusStyle = "defaultColor";
                break;
            case "activeColor":
                statusStyle = "activeColor";
                break;
            case "focusColor":
                statusStyle = "focusColor";
                break;
            default:
                break;
        }
        return statusStyle;
    };

    useEffect(() => {
        if (widgetID === itemSelected) {
            setStatusStyle(getStatusStyle(tab));
        }
        // eslint-disable-next-line
    }, [tab]);

    return (
        <>
            {render ? (
                <div
                    dangerouslySetInnerHTML={{
                        width: "100%",
                        height: "100%",
                        __html: widget
                            ? window.Render.widget(widget, null, {
                                  statusStyle: menuStatusStyle ? menuStatusStyle : statusStyle ? statusStyle : null,
                                  insideMenu: insideMenu === true ? true : false,
                                  fromManager: true,
                              }).outerHTML
                            : null,
                    }}
                ></div>
            ) : null}
        </>
    );
};

export default WidgetTVMenuNav;
