import { formatDate } from "../../../../../hooks/Utils/Services/OrdersUtils";
import { normalizeToSearch } from "../../../../../hooks/Utils/Utils";

export const getExtrasIdsAsString = (lines) => {
    const allExtras = new Set();
    lines.forEach((line) => {
        const customFields = JSON.parse(line.customFields.extras);
        const extraIds = Object.keys(customFields);
        extraIds.forEach((id) => {
            allExtras.add(`"${id}"`);
        });
    });
    const idsString = Array.from(allExtras).join(", ");
    return idsString;
};

export const arrangeProducts = (dataProducts) => {
    const transformedData = dataProducts.map((item) => {
        const transformedItem = {
            name: item.productVariant.name,
            quantity: item.quantity,
        };
        const customFields = JSON.parse(item.customFields.extras);
        const extrasArray = Object.entries(customFields).map(([key, value]) => ({
            id: key,
            quantity: value,
        }));

        transformedItem.extras = extrasArray;
        return transformedItem;
    });

    return transformedData;
};

export const getDeliveryDetails = (data) => {
    if (!data?.schedule?.end) {
        return null;
    }
    let arrHours = [];
    let arrDates = [];
    let firstTitle = "";
    let secondTitle = "";
    if (data.schedule.start) {
        let date = new window.ZDate(data.schedule.start);
        if (date) {
            arrHours.push(formatDate(date, "time"));
            arrDates.push(formatDate(date, "date"));
        }
    }
    if (data.schedule.end) {
        let date = new window.ZDate(data.schedule.end);
        if (date) {
            let parsedInfo = formatDate(date, "time");
            if (!arrHours.includes(parsedInfo)) {
                arrHours.push(parsedInfo);
            }
            parsedInfo = formatDate(date, "date");
            if (!arrDates.includes(parsedInfo)) {
                arrDates.push(parsedInfo);
            }
        }
    }
    firstTitle = arrHours.join(" - ");
    secondTitle = arrDates.join(" - ");
    return {
        firstTitle,
        secondTitle,
    };
};

export const GET_VENDURE_PRODUCT_VARIANTS = (extrasIds) => {
    return `query{
        productVariants(options: { filter: { id: { in: [${extrasIds ? extrasIds : ""}] } } }) {
            items {
                id
                name
            }
        }
    }`;
};

export const GET_VENDURE_ORDER_SHOP_API = (id) => {
    return `
      query{
          orderInfo(orderIds:[${id}]){
              id
              customFields {
                  pickUp{
                      location
                  }
                  items {
                      name
                      quantity
                      extras {
                          ... on OrderInfoNormalAndNumericExtras {
                              name
                              quantity
                          }
                          ... on OrderInfoComboboxAndMulticomboboxExtras {
                              name
                              options {
                                  name
                                  quantity
                              }
                          }
                      }
                  }
              }
          }
      }
  `;
};

export const GET_VENDURE_ORDER = (orderId) => {
    return `query{
        order(id:${orderId}){
            id
            totalWithTax
            currencyCode
            modifications{
                note
            }
            history{
                items{
                  data
                  createdAt
                  updatedAt
                }
              }          
            shippingLines{
                shippingMethod{
                    code
                }
            }
            lines{
                id
                quantity
                productVariant{
                    name
                    id
                }
                customFields{
                    extras
                }
            }
            customFields{
                deliveryLocation
            }
        }
    }
`;
};

export const getOrderProductsInfo = (responseOrder, responseOrderShopApi) => {
    if (responseOrder.data.order.lines) {
        const orderDetails = [];
        for (const line of responseOrder.data.order.lines) {
            let id = line?.id;
            let title = `${line.quantity}x ${line.productVariant?.name}`;
            let extras = [];

            if (responseOrderShopApi?.data?.orderInfo?.[0]) {
                let orderInfoItem = null;

                responseOrderShopApi.data.orderInfo[0].customFields.items.forEach((item) => {
                    if (
                        normalizeToSearch(item.name).includes(normalizeToSearch(line.productVariant?.name)) &&
                        item.quantity === line.quantity
                    ) {
                        orderInfoItem = item;
                    }
                });

                if (orderInfoItem?.extras) {
                    orderInfoItem.extras.forEach((extraData) => {
                        if (extraData) {
                            if (extraData.options && extraData.options.length > 0) {
                                extraData.options.forEach((optionData) => {
                                    extras.push({
                                        name: optionData?.name,
                                        quantity: optionData?.quantity,
                                    });
                                });
                            } else {
                                extras.push({
                                    name: extraData?.name,
                                    quantity: extraData?.quantity,
                                });
                            }
                        }
                    });
                }
            }

            orderDetails.push({
                id,
                title,
                extras,
            });
        }
        return orderDetails;
    }
    return [];
};
