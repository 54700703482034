import React, { useContext, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";

import { SalesContext } from "contexts/Sales";
import { ShopContext } from "contexts/Sales/Shop";

import { useMSQuery } from "hooks/GraphqlCalls/useMSQuery";
import { cleanAction } from "actions/globalActions";
import { Session } from "hooks/Utils/Session";

import ErrorInfo from "components/ErrorInfo";

// monitor should be updated every 30 seconds
const UPDATE_INTERVAL = 30000;

export const MonitorContext = React.createContext({
    ref: null, // Provider ref

    title: "", // Monitor title
    lang: "en", // Monitor language

    refresh: () => {}, // Refresh monitor data

    called: false, // Monitor data has been fetched
    loading: false, // Monitor data is being fetched
    updating: false, // Monitor data is being updated
    error: null, // Monitor data fetch error
    ready: false, // Monitor data is ready

    orders: null, // Monitor orders []

    counts: {
        received: {
            total: 0, // Total received orders
            delayed: 0, // Delayed received orders
        },
        ready: {
            total: 0, // Total ready orders
            delayed: 0, // Delayed ready orders
        },
    },

    autoAccept: false, // Auto accept orders
    shops: [], // Monitor shops []
    setFiltersQuery: () => {}, // Set filters query

    currentType: "received", // Current monitor type
    setCurrentType: () => {}, // Set current monitor type
});

const MonitorProvider = ({ children }) => {
    const { t } = useTranslation();

    const { permissions } = useContext(SalesContext);
    const {
        loading: loadingShop,
        error: shopError,
        ready,
        id: shopId,
        name: shopName,
        autoAcceptOrders: autoAccept,
    } = useContext(ShopContext);

    const shopReady = ready || !shopId;

    const hasOrdersManagement = permissions?.ordersManagement;

    const updateTimeout = useRef(null);

    const dispatch = useDispatch();

    const { refreshData } = useSelector((state) => state.table);
    const [currentType, setCurrentType] = useState(null);
    const [filtersQuery, setFiltersQuery] = useState(null);
    const [error, setError] = useState(null);
    const [shops, setShops] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);

    const monitorQuery = gql(QUERY_SALES_ORDER_MONITOR_DATA({ filterQuery: filtersQuery }));
    const [load, { data, called, loading, refetch, networkStatus }] = useMSQuery(monitorQuery, {
        fetchPolicy: "no-cache",
        onError(err) {
            setError(err || "Network status: " + networkStatus);
        },
        onCompleted(data) {
            setError(null);
        },
    });

    const loadOrders = () => {
        if (!hasOrdersManagement) {
            return;
        }
        if (shopReady) {
            if (updateTimeout.current) {
                clearTimeout(updateTimeout.current);
            }
            updateTimeout.current = setTimeout(() => {
                loadOrders();
            }, UPDATE_INTERVAL);

            if (called && refetch) {
                refetch();
            } else {
                load();
            }
        }
    };

    const orders = data?.monitor?.items;
    const totals = data?.monitor?.totals;

    useEffect(() => {
        return () => {
            if (updateTimeout.current) {
                clearTimeout(updateTimeout.current);
            }
            dispatch(cleanAction());
        };
    }, [hasOrdersManagement]);

    useEffect(() => {
        if (refreshData) {
            loadOrders();
        }
    }, [refreshData]);

    useEffect(() => {
        if (shopReady && filtersQuery) {
            loadOrders();
        }
    }, [shopReady, filtersQuery]);

    useEffect(() => {
        setFirstLoad(true);
    }, [currentType]);

    useEffect(() => {
        if (shops === null && orders?.length > 0) {
            // only needed when monitoring all orders, used in "shops filter"
            setShops(
                orders.reduce((shop, order) => {
                    if (!shop.some((item) => item.token === order.shop.token)) {
                        shop.push(order.shop);
                    }
                    return shop;
                }, [])
            );
        }
    }, [orders]);

    useEffect(() => {
        if (firstLoad && called && !loading) {
            setFirstLoad(false);
        }
    }, [loading]);

    return (
        <MonitorContext.Provider
            value={{
                title: shopId ? shopName : t("general-monitoring"),
                lang: Session.getLang() || "en",

                refresh: loadOrders, // Refresh shop data

                called, // Monitor data has been fetched
                loading: loadingShop || loading, // Monitor data is being fetched
                updating: !firstLoad && loading, // Monitor data is being updated
                error: error || shopError, // Monitor data fetch error
                ready: shopReady && called && !loading && !error && !shopError, // Monitor data is ready

                orders,

                counts: {
                    received: {
                        total: totals?.pending + totals?.inProgress,
                        delayed: totals?.delayedReceived,
                    },
                    ready: {
                        total: totals?.ready,
                        delayed: totals?.delayedReady,
                    },
                },
                autoAccept,
                shops,
                setFiltersQuery,

                currentType,
                setCurrentType,
            }}
        >
            {hasOrdersManagement ? (
                children
            ) : (
                <ErrorInfo useDefault={false}>You do not have permission to view this page</ErrorInfo>
            )}
        </MonitorContext.Provider>
    );
};

const QUERY_SALES_ORDER_MONITOR_DATA = ({ filterQuery }) => {
    return `query{
    monitor: monitoringOrders${filterQuery || ""}{
      items {
        id
        status:state
        prevStatus: previousState
        isDelayed
        customerName
        roomName
        origRoomName
        date
        deliveryMethod
        deliveryLocation
        updatedAt
        schedule{
            end
            start
        }
        shop{
            autoAcceptOrders
            name
            token
            currencyCode
        }
      }
      totals {
        pending
        inProgress
        ready
        delayedReceived
        delayedReady
      }
    }
}`;
};

export default MonitorProvider;
