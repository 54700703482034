import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

//Components
import AddImage from "./CommonOptions/AddImage";
import UseSelectWithSearch from "../../../../useSelectWithSearch";

//Actions
import { setWidgetToolbarVisible } from "../../../../../actions/designActions";
import { setExecuteQuery } from "../../../../../actions/sectionActions";

//Utils
import { translate, capitalizeFirst } from "../../../../../hooks/Utils/Utils";

import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Actions from "./CommonOptions/Actions";
import Widget from "../Widget";
import { EditorContext, WidgetContext } from "../../../../../contexts/editor";
import { SCREEN } from "constants/editor";
import Input from "./CommonOptions/Input";

const ImageToolbar = ({ deviceSpecific }) => {
    const { deviceType, itemSelected } = useContext(EditorContext);
    const { id: widgetID } = useContext(WidgetContext);
    const { type: screenType } = useParams();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { langStrings } = useSelector((state) => state.ui);
    const { gridItems, widgetToolbarVisible } = useSelector((state) => state.design);
    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const sectionName = `toolbar`;

    const adjustPredefinedOptions = [
        {
            id: "cover",
            name: capitalizeFirst(t("fill container")),
        },
        {
            id: "cover-adjust",
            name: capitalizeFirst(t("stretch image")),
        },
        {
            id: "contain",
            name: capitalizeFirst(t("fit into container")),
        },
    ];

    //States
    const [adjust, setAdjust] = useState(null);

    //Listeners
    useEffect(() => {
        if (widgetToolbarVisible === "image-settings" && widgetID === itemSelected) {
            reloadFields();
        }
        // eslint-disable-next-line
    }, [widgetToolbarVisible]);

    useEffect(() => {
        if (widgetID === itemSelected) {
            reloadFields();
        }
        // eslint-disable-next-line
    }, [gridItems]);

    const reloadFields = () => {
        const gItem = gridItems.filter((gridItem) => gridItem.i === widgetID)[0];
        if (gItem && gItem.data && gItem.data.adjust) {
            setAdjust(gItem.data.adjust);
        } else {
            setAdjust("cover");
        }
    };

    //Actions
    const updateImageOptions = (value) => {
        dispatch(setExecuteQuery(Widget.UpdateData({ widget, property: "adjust", value })));
    };

    return (
        <>
            <div
                id={`${sectionName}-settings`}
                className={`icon icon-settings-editor cursor-pointer p-2 ${
                    widgetToolbarVisible === "image-settings" ? "toolbarOptionSelected" : ""
                }`}
                onClick={(e) =>
                    dispatch(
                        setWidgetToolbarVisible(widgetToolbarVisible === "image-settings" ? null : "image-settings")
                    )
                }
            ></div>
            {widgetToolbarVisible === "image-settings" ? (
                <div
                    id={`widgetOptionContainer_${widgetID}`}
                    className={`widgetOptionContainer min-w-120 invisible p-0`}
                    // style={{ width: "26rem" }}
                >
                    <AddImage />
                    <div className="p-8 ">
                        <div className="first-capital mb-8 font-bold">{translate("image-options", langStrings)}</div>
                        <UseSelectWithSearch
                            data={{
                                oneSelected: true,
                                noSelect: true,
                                name: `image_select_${widgetID}`,
                                optionData: adjustPredefinedOptions,
                                selectedIds: adjust ? [adjust] : null,
                                onChange: updateImageOptions,
                                hideSearch: true,
                                fixed: true,
                                optionsAdjust: "mt-11",
                                parentName: `widgetOptionContainer_${widgetID}`,
                            }}
                        />
                    </div>
                </div>
            ) : null}
            <Toolbar type="style">
                <Settings.Group title={t("color-and-opacity")}>
                    <Input.Number
                        id={"imageOpacity"}
                        deviceSpecific={false}
                        title={t("image-opacity")}
                        unit="%"
                        min="0"
                        max="100"
                    />
                    <Input.Color id={"bgColor"} title={t("background")} />
                </Settings.Group>

                {/* <div className="border-b border-gray-200 p-8">
                    <div className="first-capital font-bold mb-6">{translate("color-and-opacity", langStrings)}</div>

                    <div className="flex ">
                        <div className="w-4/12 mt-2 first-capital">{translate("background-opacity", langStrings)}</div>
                        <div className="w-4/12 mb-2 ">
                            <input
                                type="number"
                                step="1"
                                min="0"
                                max="100"
                                id={`input_opacity`}
                                className={`h-10 bg-gray-200 rounded px-4 py-2 `}
                                // onBlur={(e) => updateOpacity()}
                                // onKeyUp={(e) => (e.keyCode === 13 ? updateOpacity() : null)} 
                            />
                            <span className="mt-2 ml-1">%</span>
                        </div>
                    </div>
                    <div className="flex ">
                        <div className="w-4/12 mt-2 first-capital">{t("background-color")}</div>
                        <div className="w-8/12 flex">
                            <div
                                id="screenBgColor"
                                onClick={(e) => {
                                    dispatch(
                                        setColorPickerReference({
                                            id: "screenBgColor",
                                            color:
                                                widget && widget.widgetStyle && widget.widgetStyle.bgColor
                                                    ? widget.widgetStyle.bgColor
                                                    : "",
                                        })
                                    );
                                    e.stopPropagation();
                                }}
                                className={`mr-3 w-10 h-10 cursor-pointer rounded-md border border-gray-200${
                                    widget && widget.widgetStyle && widget.widgetStyle.bgColor
                                        ? ""
                                        : "icon icon-no-color"
                                }`}
                                style={{
                                    backgroundColor: `${
                                        widget && widget.widgetStyle && widget.widgetStyle.bgColor
                                            ? widget.widgetStyle.bgColor
                                            : ""
                                    }`,
                                    fontSize: `${
                                        widget && widget.widgetStyle && widget.widgetStyle.bgColor ? "" : "2.3rem"
                                    }`,
                                }}
                            ></div>
                            <div className="w-5/12 mr-3 ">
                                <input
                                    type="text"
                                    id={`input_color`}
                                    className={`h-10 bg-gray-200 rounded px-4 py-2 w-full `}
                                    // onBlur={(e) => updateColor()}
                                    placeholder="#000000"
                                    // onKeyUp={(e) => (e.keyCode === 13 ? updateColor() : null)}
                                />
                            </div>
                            <div className="w-5/12  ">
                                <input
                                    type="number"
                                    step="1"
                                    min="0"
                                    max="100"
                                    id={`input_color-opacity`}
                                    className={`h-10 bg-gray-200 rounded px-4 py-2 `}
                                    disabled={widget && widget.widgetStyle && widget.widgetStyle.bgColor ? false : true}
                                    // onBlur={(e) => updateColorOpacity()}
                                    // onKeyUp={(e) => (e.keyCode === 13 ? updateColorOpacity() : null)}
                                />
                                <span className="mt-2 ml-1">%</span>
                            </div>
                        </div>
                    </div>
                </div> */}
                <Settings.Border t={t} />
            </Toolbar>
            {screenType !== SCREEN.TYPE.PORTAL ? (
                <Toolbar type="actions">
                    <Actions
                        actions={{
                            content: true,
                            landing: true,
                            section: true,
                            url: deviceType !== "TV",
                            library: true,
                            tvchannel: true,
                        }}
                    />
                </Toolbar>
            ) : null}
            {/* <Toolbar type="clone" /> */}
            <Toolbar type="delete" />
        </>
    );
};

export default ImageToolbar;
