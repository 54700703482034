import React from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
//Utils
import GetHotelMap from "../../../hooks/GraphqlCalls/Hotel/GetHotelMap";

const InstallationMap = () => (
    <>
        <UseSectionHeader
            title={["map-of-installation"]}
            //buttonName={["export", "map"]}
            buttonColor="btn-blue"
            action={"function"}
            value={"demo"}
        />
        <GetHotelMap></GetHotelMap>
    </>
);

export default InstallationMap;
