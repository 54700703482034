import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { GetScheduleMutationDates } from "../../hooks/Utils/GetScheduleMutationDates";
//Input components
import UseInputNameEditSchedule from "./useInputNameEditSchedule";
import UseInputTimeEditSchedule from "./useInputTimeEditSchedule";
import UseInputDateEditSchedule from "./useInputDateEditSchedule";
import UseInputWeekDaysEditSchedule from "./useInputWeekDaysEditSchedule";
import UseButton from "../useButton";
//Actions
import {
    cleanBatchSchedules,
    cleanViewSchedules,
    setScheduleToUpdate,
    cancelScheduleUpdate,
    setMutationRefresh,
    addSpecialEditRow,
} from "../../actions/scheduleActions";
import { cleanAction } from "../../actions/globalActions";
import {
    showEditSpecialSchedule,
    restorePeriodicScheduleBackup,
    setWizardAllowNext,
} from "../../actions/sectionActions";
//Utils
import { toast } from "react-toastify";
import { cleanUnnecessaryChars } from "../../hooks/Utils/CleanUnnecessaryChars";
import { getDateType } from "../../hooks/Utils/ScheduleDisplayTools";
import { validateName, validateDates, validateSchedule, validateWeekdays } from "../../hooks/Utils/ScheduleManagement";
import { useTranslation } from "react-i18next";
import { Session } from "../../hooks/Utils/Session";

const UseFormSpecialUpdate = ({ setEditSpecialSchedule }) => {
    const { t } = useTranslation();
    //Store data
    const { itemsAffected } = useSelector((state) => state.action);
    const { scheduleToUpdate, lastScheduleEdition } = useSelector((state) => state.schedules);
    const { periodicEventsData } = useSelector((state) => state.sectionContent);
    const currentEvent = getCurrentEvent(itemsAffected[0], periodicEventsData);

    //Validation States
    const [nameValidation, setNameValidation] = useState(true);
    const [datesValidation, setDatesValidation] = useState(true);
    //Mutation states
    const [mutation, setMutation] = useState(
        //Default mutation to prevent gql conversion to throw error
        `mutation {updateTimetable(areaID: 0 name: " " isEnabled:true periodicityType: " " type: " "){ error id ok}}`
    );
    const { mutationRefresh } = useSelector((state) => state.schedules);
    //CurrentData states
    const [timetableToEdit, setTimetableToEdit] = useState(false);
    //API
    const UPDATE_SPECIAL_SCHEDULE = gql`
        ${mutation}
    `;
    const [executeMutation] = useMutation(UPDATE_SPECIAL_SCHEDULE);

    //Actions
    const dispatch = useDispatch();

    const handleClickSaveAll = () => {
        //Validation
        //Name
        const nameValidated = validateName(scheduleToUpdate.name);
        setNameValidation(nameValidated);

        //Dates
        const datesValid = validateDates(
            scheduleToUpdate.dateType,
            scheduleToUpdate.timetableDates[0].dateList,
            scheduleToUpdate.timetableDates[0].dateList
        );
        setDatesValidation(datesValid);

        const scheduleErrors = getTimetableValidation(dispatch, scheduleToUpdate);

        if (nameValidated && datesValid && !scheduleErrors) {
            //Save data
            executeMutation();
            toast.success(t("special schedule updated"));
            setEditSpecialSchedule(false);
            dispatch(showEditSpecialSchedule(false));
            dispatch(setWizardAllowNext(true));
        } else {
            toast.error(t("check form"));
        }
    };

    const handleClickCancelAll = () => {
        //Cancel all process
        dispatch(restorePeriodicScheduleBackup(JSON.parse(Session.getSessionProp("periodicScheduleBackup"))));
        setEditSpecialSchedule(false);
        dispatch(showEditSpecialSchedule(false));
        dispatch(cancelScheduleUpdate());
        dispatch(cleanAction());
        dispatch(cleanBatchSchedules());
        dispatch(cleanViewSchedules());
        toast.success(t("exit-wo-save"));
        dispatch(setWizardAllowNext(true));
    };

    const handleAddSchedulesClick = () => {
        //Add new schedule form to existing one
        //Check if last one added is complete
        const scheduleErrors = getTimetableValidation(dispatch, scheduleToUpdate);
        if (!scheduleErrors) {
            const newTimetableDateRange = {
                timeRanges: "",
                weekdayList: "",
            };

            dispatch(addSpecialEditRow(newTimetableDateRange));
            dispatch(setWizardAllowNext(false));
        } else {
            toast.error(t("check form"));
        }
    };

    const removeRow = (e) => {
        let timetablePosition;
        if (e.currentTarget.dataset.timetable) {
            timetablePosition = e.currentTarget.dataset.timetable;
        } else {
            timetablePosition = "0";
        }
        //Check if it´s the last one schedule in timetable
        if (scheduleToUpdate.timetableDates[Number(timetablePosition)].timetableDateRanges.length === 1) {
            toast.error(t("cant-delete-last"));
        } else {
            //Create new scheduleToUpdateInfo
            const newScheduleInfo = scheduleToUpdate;
            let newTimetableDateRanges = [];
            // eslint-disable-next-line
            newScheduleInfo.timetableDates[Number(timetablePosition)].timetableDateRanges.map(
                (timetableDateRange, index) => {
                    if (index !== Number(e.currentTarget.dataset.index)) {
                        newTimetableDateRanges.push(timetableDateRange);
                    }
                    return null;
                }
            );
            newScheduleInfo.timetableDates[timetablePosition].timetableDateRanges = newTimetableDateRanges;
            //Update current store
            dispatch(setScheduleToUpdate(newScheduleInfo));
            dispatch(setMutationRefresh(true));
        }
    };

    //Listeners
    useEffect(() => {
        //Load current event values in schedules store
        dispatch(setScheduleToUpdate(currentEvent));
        Session.setSessionProp("periodicScheduleBackup", JSON.stringify(periodicEventsData));
        setTimetableToEdit(scheduleToUpdate);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (scheduleToUpdate) {
            getTimetableValidation(dispatch, scheduleToUpdate);
        }

        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (scheduleToUpdate) {
            getTimetableValidation(dispatch, scheduleToUpdate);
        }

        // eslint-disable-next-line
    }, [lastScheduleEdition]);

    useEffect(() => {
        if (scheduleToUpdate && scheduleToUpdate.timetableDates && scheduleToUpdate.timetableDates.length > 0) {
            setTimetableToEdit(scheduleToUpdate);
        }

        if (mutationRefresh) {
            if (getTimetableValidation(dispatch, scheduleToUpdate)) {
                dispatch(setMutationRefresh(false));
                return;
            }
            //Set mutation
            setMutation(
                getMutation(
                    scheduleToUpdate.name,
                    scheduleToUpdate.ID,
                    scheduleToUpdate.dateType,
                    GetScheduleMutationDates(cleanValidationValues(scheduleToUpdate.timetableDates)),
                    scheduleToUpdate.isEnabled
                )
            );
            dispatch(setMutationRefresh(false));
        }
        // eslint-disable-next-line
    }, [scheduleToUpdate, mutationRefresh]);

    return (
        <>
            <div className="w-full block bg-white p-8">
                <>
                    <div className="pb-4">
                        {timetableToEdit ? (
                            <>
                                <div className="w-full block border-b border-gray-200 pb-4">
                                    <div className="inline-block w-3/12 pr-8">
                                        <UseInputNameEditSchedule validation={nameValidation} />
                                    </div>
                                </div>
                                <div className="w-full block mb-4">
                                    {timetableToEdit.timetableDates.map((timetableDate, index2) => (
                                        <div className="w-full block mt-4 mb-8" key={index2}>
                                            <div className="w-full block">
                                                <div className="w-3/12 inline-block pr-8">
                                                    {t(getDateType(timetableToEdit.dateType))}
                                                </div>
                                                <div className="w-3/12 inline-block pr-8 mb-6 z-200">
                                                    {
                                                        <UseInputDateEditSchedule
                                                            validation={datesValidation}
                                                            index={index2}
                                                        />
                                                    }
                                                </div>
                                                <div className="w-6/12 inline-block pr-8"></div>
                                            </div>
                                            <div className="w-full block">
                                                {timetableDate.timetableDateRanges.map((timetableDateRange, index) => (
                                                    <div className="w-full block" key={index}>
                                                        <div className="w-full inline-block">
                                                            <div
                                                                className={`inline-block ${
                                                                    timetableToEdit.dateType !== "DATE_RANGE"
                                                                        ? "w-full"
                                                                        : "w-5/12"
                                                                }`}
                                                            >
                                                                <UseInputTimeEditSchedule
                                                                    dateId="morningStart"
                                                                    validation={
                                                                        typeof timetableDateRange.MSValidation !==
                                                                        "undefined"
                                                                            ? timetableDateRange.MSValidation
                                                                            : true
                                                                    }
                                                                    index={index}
                                                                    timetableIndex={index2}
                                                                />{" "}
                                                                -{" "}
                                                                <UseInputTimeEditSchedule
                                                                    dateId="morningEnd"
                                                                    validation={
                                                                        typeof timetableDateRange.MEValidation !==
                                                                        "undefined"
                                                                            ? timetableDateRange.MEValidation
                                                                            : true
                                                                    }
                                                                    index={index}
                                                                    timetableIndex={index2}
                                                                />{" "}
                                                                /{" "}
                                                                <UseInputTimeEditSchedule
                                                                    dateId="afternoonStart"
                                                                    validation={
                                                                        typeof timetableDateRange.ASValidation !==
                                                                        "undefined"
                                                                            ? timetableDateRange.ASValidation
                                                                            : true
                                                                    }
                                                                    index={index}
                                                                    timetableIndex={index2}
                                                                />{" "}
                                                                -{" "}
                                                                <UseInputTimeEditSchedule
                                                                    dateId="afternoonEnd"
                                                                    validation={
                                                                        typeof timetableDateRange.AEValidation !==
                                                                        "undefined"
                                                                            ? timetableDateRange.AEValidation
                                                                            : true
                                                                    }
                                                                    index={index}
                                                                    timetableIndex={index2}
                                                                />
                                                                {timetableToEdit.dateType !== "DATE_RANGE" ? (
                                                                    <div
                                                                        className="inline-block w-1/12 ml-4 icon-remove hover:text-blue-200 cursor-pointer"
                                                                        data-index={index}
                                                                        data-timetable={index2}
                                                                        onClick={removeRow}
                                                                    ></div>
                                                                ) : null}
                                                            </div>
                                                            {timetableToEdit.dateType === "DATE_RANGE" ? (
                                                                <div className="inline-block w-7/12">
                                                                    <div className="inline-block">
                                                                        <UseInputWeekDaysEditSchedule
                                                                            validation={
                                                                                typeof timetableDateRange.WDValidation !==
                                                                                "undefined"
                                                                                    ? timetableDateRange.WDValidation
                                                                                    : true
                                                                            }
                                                                            index={index}
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className="inline-block icon-remove hover:text-blue-200 cursor-pointer"
                                                                        data-index={index}
                                                                        onClick={removeRow}
                                                                    ></div>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div
                                                className="w-3/12 inline-block text-blue-300 font-bold cursor-pointer hover:text-blue-200 mt-4"
                                                onClick={handleAddSchedulesClick}
                                            >
                                                <span className="icon-add mr-2"></span>
                                                {t("add-schedules")}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : null}
                    </div>
                </>
            </div>
            <div className="w-full flex justify-end h-10 mt-8 mr-4 mb-4">
                <div className="w-auto inline-block mr-4" onClick={handleClickCancelAll}>
                    <UseButton buttonName="exit-unsaved" buttonColor="btn-blue-outline" adjust="px-8" />
                </div>
                <div className="w-auto inline-block" onClick={handleClickSaveAll}>
                    <UseButton buttonName="save-and-close" buttonColor="btn-blue" adjust="px-8" />
                </div>
            </div>
        </>
    );
};

const getCurrentEvent = (itemId, punctualEventsData) => {
    //Returns current event data to update proccess
    let res;
    // eslint-disable-next-linegetTimetableValidation
    punctualEventsData.currentData.map((punctualEventData) => {
        if (punctualEventData.ID === itemId) {
            res = punctualEventData;
        }
        return null;
    });
    return res;
};

const extractHours = (hoursString) => {
    let schedules = cleanUnnecessaryChars(hoursString);
    schedules = schedules.split('"').join("");
    schedules = schedules.split("[").join("");
    schedules = schedules.split("]").join("");
    schedules = schedules.split(",").join("-");
    schedules = schedules.split("-");
    return schedules;
};

const cleanValidationValues = (timetableDates) => {
    timetableDates[0].timetableDateRanges.map((timetableDate) => {
        delete timetableDate.passValidation;
        delete timetableDate.MSValidation;
        delete timetableDate.MEValidation;
        delete timetableDate.ASValidation;
        delete timetableDate.AEValidation;
        delete timetableDate.WDValidation;
        return true;
    });
    return timetableDates;
};

const getTimetableValidation = (dispatch, inscheduleToUpdate) => {
    let errors = false;
    const scheduleToUpdate = inscheduleToUpdate;
    scheduleToUpdate.timetableDates[0].timetableDateRanges.map((timetableDate, index) => {
        //Validate dates
        if (timetableDate.timeRanges === "" || timetableDate.timeRanges === "[-]") {
            errors = true;
        }
        //if (timetableDate.timetableDateRanges[0])
        const schedules = extractHours(timetableDate.timeRanges);

        const validationResult = validateSchedule({
            morningStart: schedules[0],
            morningEnd: schedules[1],
            afternoonStart: schedules[2],
            afternoonEnd: schedules[3],
        });
        if (
            !validationResult.morningStart ||
            !validationResult.morningEnd ||
            !validationResult.afternoonStart ||
            !validationResult.afternoonEnd
        ) {
            errors = true;
        }
        timetableDate.MSValidation = validationResult.morningStart;
        timetableDate.MEValidation = validationResult.morningEnd;
        timetableDate.ASValidation = validationResult.afternoonStart;
        timetableDate.AEValidation = validationResult.afternoonEnd;

        if (scheduleToUpdate.dateType !== "SPECIFIC_DAYS") {
            const wdValidation = validateWeekdays(timetableDate.weekdayList);
            timetableDate.WDValidation = wdValidation;
            if (!wdValidation) {
                errors = true;
            }
        }

        return true;
    });
    dispatch(setScheduleToUpdate(scheduleToUpdate));
    return errors;
};

//Mutation functions
const getMutation = (name, areaID, dateType, timetableDates, status) => {
    //Returns dynamic mutation
    //ISO 8601 standard Monday(1) Sunday (7)

    return `mutation {
      updateTimetable(
        name: "${name}"
        type: "OPEN"
        isEnabled: ${status}
        dateType: "${dateType}"
        timetableDates: ${timetableDates}
        id: ${areaID}
        ){ error id ok}}`;
};

export default UseFormSpecialUpdate;
