import React, { useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import ErrorInfo from "components/ErrorInfo";

import { GlobalContext } from "contexts/Global";

/**
 * This component is a custom location map with coordinates
 * @param {number} latitude The latitude of the location
 * @param {number} longitude The longitude of the location
 * @returns {JSX.Element} The UI of the component
 * @example <LocationMap latitude={12.3456} longitude={78.9012} />
 */
const LocationMap = ({ latitude, longitude }) => {
    const { t } = useTranslation();

    const { highlightComponent } = useContext(GlobalContext);

    if (!latitude || !longitude) {
        const err = new Error("Location coordinates are not provided");
        console.warn(err, { latitude, longitude });
        return <ErrorInfo useDefault={false}>{err}</ErrorInfo>;
    }

    const iframeProps = {
        width: "100%",
        height: 472,
        output: "embed",
        q: `${latitude},${longitude}`,
        t: "h", // terrain view (options are "m" map, "k" satellite, "h" hybrid)
        z: 19, // zoom level
    };
    const linkProps = {
        q: `${latitude},${longitude}`,
        ll: `${latitude},${longitude}`,
        z: 19,
        t: "h",
        mapclient: "embed",
    };

    const googleMapsSrc = `https://maps.google.com/maps?${new URLSearchParams(iframeProps)}`;
    const googleMapsLink = `https://www.google.com/maps?${new URLSearchParams(linkProps)}`;

    const globalClass = classNames({
        "w-full": true,
        "highlight-component": highlightComponent,
    });

    return (
        <div className={globalClass}>
            <div>{t("View and check the delivery location")}</div>
            <div className="w-full flex items-top my-5 space-x-5 overflow-auto">
                <iframe className="rounded border" title="map" width="100%" height="472" src={googleMapsSrc} />
                <div className="max-w-40">
                    <div className="font-bold mb-1">{t("Coordinates")}:</div>
                    <a
                        id="location-coordinates-link"
                        href={googleMapsLink}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-300 hover:text-blue-500 focus:outline-none whitespace-nowrap"
                    >
                        {latitude}, {longitude}
                    </a>
                </div>
            </div>
        </div>
    );
};
LocationMap.displayName = "LocationMap";

export const useLocationMapModal = () => {
    const { t } = useTranslation();
    const { open, close } = useModal();

    return {
        open: (props) => {
            open(
                <Modal
                    id="location-map"
                    title={t("custom-location")}
                    footer={
                        <Button design="blue" id="modal-button-close" onClick={close}>
                            {t("close")}
                        </Button>
                    }
                    minWidth="50vw"
                >
                    <LocationMap {...props} />
                </Modal>
            );
        },
        close,
    };
};

export default LocationMap;
