import { useEffect, useState } from "react";
import { useApolloClient } from "react-apollo";

import { ManagerService } from "App";

const useGQLQuery = (query, options) => {
    const onSuccess = options?.onSuccess;
    const onError = options?.onError;
    const customToken = options?.token;
    const customService = options?.service;

    const service = customService || ManagerService;

    const defaultClient = useApolloClient();
    const client = customToken ? service(customToken) : defaultClient;

    const [{ data, loading, error, called }, setQueryState] = useState({});

    const ready = called && !loading && !error;

    const load = (variables) => {
        const props = { variables };
        setQueryState({
            data: null,
            loading: true,
            error: null,
            called: true,
        });
        client
            .query({
                query,
                fetchPolicy: options?.fetchPolicy ?? "no-cache",
                errorPolicy: options?.errorPolicy ?? "all",
                ...props,
            })
            .then((response) => {
                setQueryState((prev) => ({ ...prev, data: response?.data, loading: response?.loading }));
            })
            .catch((err) => {
                setQueryState((prev) => ({ ...prev, error: err }));
            });
    };

    useEffect(() => {
        if (ready && onSuccess) {
            onSuccess(data);
        }
    }, [ready]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error]);

    return { load, data, loading, error, called, ready };
};

export default useGQLQuery;
