import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//Components
import UseSelectWithSearch from "../../../../useSelectWithSearch";

//Actions
import { setWidgetToolbarVisible } from "../../../../../actions/designActions";
import { setExecuteQuery } from "../../../../../actions/sectionActions";

import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Input from "./CommonOptions/Input";
import Actions from "./CommonOptions/Actions";
import Widget from "../Widget";
import { EditorContext, WidgetContext } from "../../../../../contexts/editor";
import { capitalizeFirst } from "../../../../../hooks/Utils/Utils";
import { getOptionsFontList } from "../../../../../hooks/Utils/Design/FontUtils";
import { GetThemeDefault } from "../../../../../hooks/Utils/DesignUtils";
import { SCREEN } from "constants/editor";

const ClockToolbar = ({ deviceSpecific }) => {
    const { deviceType, itemSelected, theme, screenType } = useContext(EditorContext);
    const { id: widgetID } = useContext(WidgetContext);
    const { fonts } = useSelector((state) => state.ui);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sectionName = `toolbar`;
    const formatOptions = [
        {
            id: "digital",
            name: capitalizeFirst(t("digital")),
        },
        {
            id: "analogic",
            name: capitalizeFirst(t("analogic")),
        },
    ];
    const hoursOptions = [
        {
            id: "12",
            name: capitalizeFirst(`12 ${t("hours")}`),
        },
        {
            id: "24",
            name: capitalizeFirst(`24 ${t("hours")}`),
        },
    ];

    const fontList = getOptionsFontList(fonts?.fontList);
    const typographyOptions = fontList?.length ? fontList.map((font) => ({ id: font.value, name: font.value })) : [];

    //States
    const [format, setFormat] = useState(null);
    const [hours, setHours] = useState(null);
    const [typography, setTypography] = useState(null);
    const [alarmNotice, setAlarmNotice] = useState(null);

    //Store data
    const { gridItems, widgetToolbarVisible, widgetMenuColorsAndAnimations } = useSelector((state) => state.design);
    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const widgetInsideMenu = widget && widget.insideMenu === true ? true : false;

    //Listeneres

    useEffect(() => {
        if (widgetToolbarVisible === "clock-settings" && itemSelected === widgetID) {
            setData();
        }
        // eslint-disable-next-line
    }, [widgetToolbarVisible]);

    useEffect(() => {
        if (itemSelected === widgetID) {
            setData();
        }
        // eslint-disable-next-line
    }, [gridItems]);

    //Funtions

    const setData = () => {
        const gridItem = gridItems.filter((gridItem) => gridItem.i === widgetID)[0];
        if (gridItem && gridItem.data) {
            setFormat(gridItem.data.format);
            setHours(gridItem.data.hours);
            setTypography(gridItem.data.typography);
            setAlarmNotice(gridItem.data.showAlarm);
        }
    };

    const updateFormat = (value) => {
        dispatch(
            setExecuteQuery(
                Widget.UpdateData({
                    widget,
                    property: "format",
                    value,
                    extraArgs: {
                        noCleanAction: true,
                    },
                })
            )
        );
    };
    const updateHours = (value) => {
        dispatch(
            setExecuteQuery(
                Widget.UpdateData({
                    widget,
                    property: "hours",
                    value,
                    extraArgs: {
                        noCleanAction: true,
                    },
                })
            )
        );
    };
    const updateTypography = (value) => {
        dispatch(
            setExecuteQuery(
                Widget.UpdateData({
                    widget,
                    property: "typography",
                    value: fontList.find((font) => font.value === value),
                    extraArgs: {
                        noCleanAction: true,
                    },
                })
            )
        );
    };

    const updateShowAlarm = (value) => {
        dispatch(
            setExecuteQuery(
                Widget.UpdateData({
                    widget,
                    property: "showAlarm",
                    value,
                    extraArgs: {
                        noCleanAction: true,
                    },
                })
            )
        );
    };

    return (
        <>
            <div
                id={`${sectionName}-settings`}
                className={`icon icon-settings-editor cursor-pointer p-2 ${
                    widgetToolbarVisible === "clock-settings" ? "toolbarOptionSelected" : ""
                }`}
                onClick={(e) =>
                    dispatch(
                        setWidgetToolbarVisible(widgetToolbarVisible === "clock-settings" ? null : "clock-settings")
                    )
                }
            ></div>
            {widgetToolbarVisible === "clock-settings" ? (
                <div
                    id={`widgetOptionContainer_${widgetID}`}
                    className={`widgetOptionContainer invisible`}
                    style={{ width: "26rem" }}
                >
                    <div className="first-capital mb-6 font-bold">{t("clock options")}</div>
                    <div className="relative table w-full pb-6">
                        <div className="first-capital mb-2  cursor-pointer">
                            <div className="first-capital text-gray-800">{t("format")}</div>
                        </div>
                        <UseSelectWithSearch
                            data={{
                                oneSelected: true,
                                noSelect: true,
                                name: `format_${widgetID}`,
                                optionData: formatOptions,
                                selectedIds: format ? [format] : null,
                                onChange: updateFormat,
                                hideSearch: true,
                                fixed: true,
                                parentName: `widgetOptionContainer_${widgetID}`,
                                optionsAdjust: "mt-11",
                            }}
                        />
                        {format === "digital" ? (
                            <>
                                <div className="first-capital mb-2 mt-4  cursor-pointer">
                                    <div className="first-capital text-gray-800">{`12/24 ${t("hours")}`}</div>
                                </div>
                                <UseSelectWithSearch
                                    data={{
                                        oneSelected: true,
                                        noSelect: true,
                                        name: `hours_${widgetID}`,
                                        optionData: hoursOptions,
                                        selectedIds: hours ? [hours] : null,
                                        onChange: updateHours,
                                        hideSearch: true,
                                        optionsAdjust: "mt-11",
                                    }}
                                />

                                <div className="first-capital mb-2 mt-4  cursor-pointer">
                                    <div className="first-capital text-gray-800">{t("typography")}</div>
                                </div>
                                <UseSelectWithSearch
                                    data={{
                                        oneSelected: true,
                                        noSelect: true,
                                        name: `typography_${widgetID}`,
                                        optionData: typographyOptions,
                                        selectedIds: typography ? [typography.name] : null,
                                        onChange: updateTypography,
                                        hideSearch: true,
                                        fixed: true,
                                        parentName: `widgetOptionContainer_${widgetID}`,
                                        optionsAdjust: "mt-11",
                                    }}
                                />
                            </>
                        ) : null}
                        {screenType === SCREEN.TYPE.CCPAIRINGSUCCESSFUL ||
                        screenType === SCREEN.TYPE.CCINSTRUCTION ||
                        format === "analogic" ? null : (
                            <div className="first-capital mb-2 mt-8  cursor-pointer">
                                <div className="first-capital mr-4 inline-block text-gray-800">{t("alarm-notice")}</div>
                                <label className="switch ml-4 cursor-pointer">
                                    <input
                                        type="checkbox"
                                        checked={alarmNotice}
                                        onChange={() => updateShowAlarm(!alarmNotice)}
                                    ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
            <Toolbar type="style">
                <Settings>
                    <Settings.Group type="colors">
                        <Input.Color
                            id="fgColor"
                            title={t("foreground")}
                            defaultColor={
                                widgetInsideMenu && widgetMenuColorsAndAnimations
                                    ? widgetMenuColorsAndAnimations.fgColor
                                    : GetThemeDefault({ theme, id: "fgColor" })
                            }
                        />
                        <Input.Color
                            id="bgColor"
                            title={t("background")}
                            defaultColor={
                                widgetInsideMenu && widgetMenuColorsAndAnimations
                                    ? widgetMenuColorsAndAnimations.bgColor
                                    : GetThemeDefault({ theme, id: "bgColor" })
                            }
                        />
                        {screenType !== SCREEN.TYPE.CCPAIRINGSUCCESSFUL && screenType !== SCREEN.TYPE.CCINSTRUCTION ? (
                            <Input.Color
                                id="iconColor"
                                title={t("alarm-notice")}
                                defaultColor={
                                    widgetInsideMenu && widgetMenuColorsAndAnimations
                                        ? widgetMenuColorsAndAnimations.iconColor
                                        : GetThemeDefault({ theme, id: "iconColor" })
                                }
                            />
                        ) : null}
                    </Settings.Group>
                </Settings>
                <Settings.Border t={t} />
            </Toolbar>
            <Toolbar type="actions">
                <Actions
                    actions={{
                        content: true,
                        landing: true,
                        section: true,
                        url: deviceType !== "TV",
                        library: true,
                        tvchannel: true,
                    }}
                />
            </Toolbar>
            {/* <Toolbar type="clone" /> */}
            <Toolbar type="delete" />
        </>
    );
};

export default ClockToolbar;
