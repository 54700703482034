import React from "react";

//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table";

//Utils
import ListRoles from "../../../hooks/GraphqlCalls/Settings/ListRoles";
import UpdateSettings from "../../../hooks/GraphqlCalls/Settings/UpdateSettings";

const Roles = () => {
    ListRoles();

    return (
        <>
            <UpdateSettings />
            <UseSectionHeader title={"manager-roles"} />
            <UseTable />
        </>
    );
};

export default Roles;
