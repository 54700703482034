import React from "react";
import { useTranslation } from "react-i18next";

import SelectFilter from "../../SelectFilter";

const useFilterReport = ({ defaultValue, data }) => {
    const { t } = useTranslation();

    const getSelectItems = () => {
        let options = [];
        if (data)
            // eslint-disable-next-line
            data.map((option, index) => {
                options.push({
                    value: option,
                    name: option.default ? t(option.name) : option.name,
                    icon: option.icon,
                    iconColor: option.iconColor,
                    vip: index === 0,
                });
            });
        return [{ items: options }];
    };

    const { Component, value, setValue } = SelectFilter({
        getItems: getSelectItems,
        value: defaultValue,
        disabled: !data || data.length <= 0,
    });

    return {
        value,
        setValue,
        Component: (props) => (
            <div className={"inline-block " + (props.className || "")}>
                <div className="inline-block mr-3">{t("{{text, capitalize}}", { text: t("report") })}:</div>
                <Component style={{ minWidth: "12rem" }} />
            </div>
        ),
    };
};

export default useFilterReport;
