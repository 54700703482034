import React, { useContext, useState } from "react";
import { useNavigate as useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { MonitorContext } from "contexts/Sales/Monitor";

export default function OrdersHeadMenu() {
    const { t } = useTranslation();

    const { title } = useContext(MonitorContext);
    const [showTooltip, setShowToltip] = useState(false);

    const history = useHistory();

    const showTooltipFunc = (e) => {
        if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) {
            setShowToltip(true);
        } else {
            setShowToltip(false);
        }
    };

    //TODO remove this hack when the layout is done correctly
    const top =
        document.getElementById("warnings")?.getBoundingClientRect().bottom ||
        document.getElementById("impersonated")?.getBoundingClientRect().bottom ||
        0;

    return (
        <div
            id="order-monitor-head-menu-mainContainer"
            className=" fixed left-0 top-0 flex w-full h-16 bg-white z-100 "
            style={{ top: (top || 0) + "px" }}
        >
            <div
                id="sales-back-to-shop-list"
                className=" absolute border-r top-0 left-0  flex h-full items-center text-gray-800 hover:text-zafiro-400 mt justify-center border-b  cursor-pointer "
                style={{ width: "13.7rem" }}
                onClick={() => {
                    history("/services/sales/order-summary");
                }}
            >
                <span className=" icon icon-chevron-left mr-3 mt-1 text-lg"></span>
                <span className=" text-lg ">{t("back-to-shop-list")}</span>
            </div>
            <div className=" flex w-full pl-64 h-full items-center justify-between px-4 border ">
                <div className=" flex items-center text-gray-800 justify-center">
                    <div
                        id="sales-name-shop"
                        data-tooltip-id="name-shop-tooltip"
                        onMouseOver={(e) => showTooltipFunc(e)}
                        className=" font-bold mr-4 text-lg truncate"
                        style={{ maxWidth: "16rem" }}
                        data-tip={showTooltip ? title : ""}
                    >
                        {title}
                    </div>
                </div>
            </div>
        </div>
    );
}
