import React from "react";

function InfoToast({ msg }) {
    return (
        <div className="inline-grid grid-cols-4 gap-x-2 w-1/1">
            <div className="sk-chase">
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
            </div>
            <div className="col-span-3 first-capital">{msg}</div>
        </div>
    );
}

export default InfoToast;
