import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseRadio from "../useRadio";
import UseButton from "../useButton";
//Modal
import { openModal } from "../../actions/uiActions";
import { setModalContent } from "../../actions/uiActions";
import BrowserMediaLibraryModal from "../../hooks/GraphqlCalls/MediaLibrary/modals/BrowserMediaLibraryModal";
//Actions
import { changeGlobalAction, changeActionValues } from "../../actions/globalActions";
//Utils
import { Session } from "../../hooks/Utils/Session";
import { cloneObject } from "../../hooks/Utils/Utils";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UseSelectImageIcon = ({ index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const OPTIONS = {
        ICON: "icon-library",
        IMAGE: "image-library",
    };

    let show = 0;
    const RESULTS_INCREMENT = 50;

    library.add(fas);
    library.add(fab);
    let fasArray = Object.keys(library.definitions.fas);
    fasArray.splice(fasArray.indexOf("font-awesome-logo-full"), 1);
    let fabArray = Object.keys(library.definitions.fab);
    fabArray.splice(fabArray.indexOf("font-awesome-logo-full"), 1);
    fabArray.splice(fabArray.indexOf("angry"), 1);

    const allIcons = [
        { lib: "fa", icons: fasArray },
        { lib: "fab", icons: fabArray },
    ];
    const allIconsLength = allIcons[0].icons.length + allIcons[1].icons.length;

    const { text, closeBrowserModalAction, globalAction, value } = useSelector(
        (state) => state.ui.modalContent.inputs[index]
    );
    const actionData = useSelector((state) => state.action);
    const { cLang } = useSelector((state) => state.design);

    const [optionSelected, setOptionSelected] = useState(OPTIONS.ICON);
    const [iconsShowing, setIconsShowing] = useState(RESULTS_INCREMENT);
    const [iconSelected, setIconSelected] = useState(null);
    const [imageSelected, setImageSelected] = useState(null);
    const [icons, setIcons] = useState(allIcons);
    const [search, setSearch] = useState(null);

    const openModalLibrary = () => {
        const modal = BrowserMediaLibraryModal(t, {
            title: "choose-an-image",
            text: "images-of-the-media-library",
            bodyAdjust: "w-10/12",
            textAdjust: "",
            executeSectionQuery: true,
            buttonSelectedAssetName: "choose-an-image",
            action: "widget-actions-media",
            closeAction: closeBrowserModalAction ? closeBrowserModalAction : null,
            dataMediaLibrary: {
                selected_ref: null,
                lang: cLang,
                select_multiple: false,
                type: "asset",
                add_asset: {
                    active: true,
                    return_added: false,
                },
            },
        });
        if (globalAction) {
            setTimeout(() => {
                dispatch(changeGlobalAction({ actionName: globalAction }));
            }, 500);
        }
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    const onScrollCallback = (e) => {
        const maxScroll = e.target.scrollHeight - e.target.clientHeight;
        const currenteScroll = e.target.scrollTop;
        const bottom = 25;

        if (currenteScroll + bottom >= maxScroll && iconsShowing < allIconsLength) {
            setIconsShowing(iconsShowing + RESULTS_INCREMENT);
        }
    };

    const updateIcon = (icon, lib) => {
        setIconSelected({ icon: icon, lib: lib });
        dispatch(changeActionValues({ "icon-selected": { icon: icon, lib: lib } }));
    };

    const deleteIcon = () => {
        setIconSelected(null);
        dispatch(changeActionValues({ "icon-selected": null }));
    };

    const deleteImage = () => {
        setImageSelected(null);
        dispatch(changeActionValues({ "image-selected": null }));
    };

    const filterIcons = (e) => {
        if (!e.target.value) {
            setIcons(allIcons);
            setSearch(null);
            return;
        }
        let tmpIcons = cloneObject(search && search.length > e.target.value.length ? allIcons : icons);

        tmpIcons.map(
            (set) => (set.icons = set.icons.filter((icon) => icon.toLowerCase().includes(e.target.value.toLowerCase())))
        );

        setIcons(tmpIcons);
        setSearch(e.target.value);
    };

    const getFromDAS = (libraryRef, languageRef) => {
        if (libraryRef) {
            return Session.getDasUrl(`${libraryRef}?lang=${languageRef}`);
        }
        return null;
    };

    useEffect(() => {
        if (value) {
            if (value.icon) {
                dispatch(changeActionValues({ "option-resource-selected": OPTIONS.ICON }));
                dispatch(changeActionValues({ "icon-selected": { icon: value.icon, lib: value.lib } }));
                setOptionSelected(OPTIONS.ICON);
            } else {
                dispatch(changeActionValues({ "option-resource-selected": OPTIONS.IMAGE }));
                dispatch(changeActionValues({ "image-selected": [value] }));
                setOptionSelected(OPTIONS.IMAGE);
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!actionData.values["option-resource-selected"] && !value) {
            setOptionSelected(OPTIONS.ICON);
            dispatch(changeActionValues({ "option-resource-selected": OPTIONS.ICON }));
        } else if (actionData.values["option-resource-selected"] === OPTIONS.IMAGE) {
            setOptionSelected(OPTIONS.IMAGE);
            if (actionData.values["image-selected"]) {
                const libraryRef = actionData.values["image-selected"];
                const imageSrc = getFromDAS(libraryRef, cLang);
                setImageSelected(imageSrc);
            }
        } else if (actionData.values["option-resource-selected"] === OPTIONS.ICON) {
            setOptionSelected(OPTIONS.ICON);
            if (actionData.values["icon-selected"]) {
                setIconSelected(actionData.values["icon-selected"]);
            }
        }
        // eslint-disable-next-line
    }, [actionData]);

    return (
        <>
            {text ? <div className="block pb-4 first-capital">{text}</div> : null}
            <div className=" flex w-full">
                <div className="relative  w-32 h-32  bg-gray-200">
                    {iconSelected && optionSelected === OPTIONS.ICON ? (
                        <div className=" absolute h-full w-full flex items-center justify-center">
                            <FontAwesomeIcon icon={[iconSelected.lib, iconSelected.icon]} size="5x" />
                        </div>
                    ) : optionSelected === OPTIONS.ICON ? (
                        <div className=" absolute h-full w-full flex items-center justify-center">
                            <span className=" icon icon-image text-gray-700" style={{ fontSize: "4rem" }}></span>
                        </div>
                    ) : null}
                    {imageSelected && optionSelected === OPTIONS.IMAGE ? (
                        <img alt="" className="h-full w-full object-contain mb-8" src={imageSelected}></img>
                    ) : optionSelected === OPTIONS.IMAGE ? (
                        <div className=" absolute h-full w-full flex items-center justify-center">
                            <span className=" icon icon-image text-gray-700" style={{ fontSize: "4rem" }}></span>
                        </div>
                    ) : null}
                    {(iconSelected && optionSelected === OPTIONS.ICON) ||
                    (imageSelected && optionSelected === OPTIONS.IMAGE) ? (
                        <div
                            className="icon icon-delete-editor w-full  text-white text-base absolute bottom-0 p-1 right-0  text-center rounded cursor-pointer "
                            style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (optionSelected === OPTIONS.ICON) {
                                    deleteIcon();
                                } else if (optionSelected === OPTIONS.IMAGE) {
                                    deleteImage();
                                }
                            }}
                        ></div>
                    ) : null}
                </div>
                <div className=" w-5/5 pl-11 ">
                    <div
                        id={"option-add-1"}
                        className="cursor-pointer"
                        onClick={() => {
                            setOptionSelected(OPTIONS.IMAGE);
                            dispatch(changeActionValues({ "option-resource-selected": OPTIONS.IMAGE }));
                        }}
                    >
                        <UseRadio id={"option-add-2"} selected={optionSelected} value={OPTIONS.IMAGE} />
                        <div id={"option-add-text"} className="first-capital mb-2 font-bold ml-6">
                            {t("add")}:
                        </div>
                        <UseButton
                            id={"choose-an-image"}
                            buttonName={t("choose-an-image")}
                            buttonColor={"btn-white"}
                            disabled={optionSelected !== OPTIONS.IMAGE}
                            action={(e) => {
                                e.stopPropagation();
                                openModalLibrary();
                            }}
                        />
                    </div>
                    <div
                        id={"option-icon-library-1"}
                        className="cursor-pointer mt-6"
                        onClick={() => {
                            setOptionSelected(OPTIONS.ICON);
                            dispatch(changeActionValues({ "option-resource-selected": OPTIONS.ICON }));
                        }}
                    >
                        <UseRadio id={"option-icon-library-2"} selected={optionSelected} value={OPTIONS.ICON} />
                        <div id={"option-icon-library-text"} className="first-capital mb-2 font-bold ml-6">
                            {t("select-icon-library")}:
                        </div>
                    </div>
                </div>
            </div>
            {optionSelected === OPTIONS.ICON ? (
                <div className=" mt-11 w-full">
                    <div className="relative">
                        <input
                            id={`icon_search_input`}
                            type="text"
                            className="t-filter-search  m-0 w-full"
                            placeholder={`${t("search")}...`}
                            onChange={filterIcons}
                        ></input>
                        <span className="field-icon icon-search table mt-4" style={{ height: "2rem" }}></span>
                    </div>
                    <div className={`mt-3 h-64 w-full overflow-y-scroll overflow-x-hidden`} onScroll={onScrollCallback}>
                        {icons.map((set) =>
                            set.icons.map((icon) =>
                                search || (!search && show++ && show < iconsShowing) ? (
                                    <div
                                        id={icon}
                                        key={`${set.lib}-${icon} `}
                                        className={`p-3 w-16 float-left flex justify-center items-center mb-4 rounded cursor-pointer text-center ${
                                            iconSelected && icon === iconSelected.icon ? " bg-gray-200" : ""
                                        }`}
                                        onClick={(e) => updateIcon(icon, set.lib)}
                                    >
                                        <FontAwesomeIcon icon={[set.lib, icon]} size="2x" />
                                    </div>
                                ) : null
                            )
                        )}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default UseSelectImageIcon;
