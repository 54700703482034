import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getProjectLangs } from "../../hooks/Utils/SalesUtils";
import UseInputText from "../Inputs/useInputText";
import { capitalizeFirst } from "../../hooks/Utils/Utils";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import UseLoading from "../Table/useLoading";
import { changeActionValues } from "../../actions/globalActions";
import { useAuth } from "hooks/Session/auth";

const UseModalEditName = ({ index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [langDefault, setLangDefault] = useState("");
    const [langsArray, setLangsArray] = useState([]);
    const [langsValues, setLangsValues] = useState({});
    const [getDataQuery, setGetDataQuery] = useState(`{
    fakeQuery{
        data
    }
}`);
    const GET_DATA_QUERY = gql(`${getDataQuery}`);
    const [executeQuery, { data: dataQuery, loading }] = useLazyQuery(GET_DATA_QUERY, { fetchPolicy: "network-only" });
    const { languages: projectLangs } = useAuth();
    const { validations } = useSelector((state) => state.action);
    const { data } = useSelector((state) => state.ui.modalContent.inputs[index]);

    useEffect(() => {
        if (projectLangs) {
            const { langDefault: defaultLang, langs } = getProjectLangs(projectLangs, t);
            setLangDefault(defaultLang);
            dispatch(changeActionValues({ langDefault: defaultLang }));
            const sortedLangs = langs.sort((a, b) => a.name.localeCompare(b.name));
            setLangsArray(sortedLangs);
        }
    }, [projectLangs]);

    useEffect(() => {
        if (data?.query) {
            setGetDataQuery(data.query);
            executeQuery();
            dispatch(changeActionValues({ TID: data?.TID, parentType: data?.parentType }));
        }
    }, [data]);

    useEffect(() => {
        if (dataQuery?.translations) {
            const transformedObject = dataQuery.translations.reduce((acc, item) => {
                acc[item.lang] = { text: item.text };
                return acc;
            }, {});
            setLangsValues(transformedObject);
        }
    }, [dataQuery]);

    useEffect(() => {
        dispatch(
            changeActionValues({ langsValues, "mutation-langs-string": convertObjectToStringToMutation(langsValues) })
        );
    }, [langsValues]);

    const handleInputChange = (key, val) => {
        let updatedLangsValues = { ...langsValues };

        if (val) {
            updatedLangsValues[key] = { text: val };
        } else {
            delete updatedLangsValues[key];
        }

        setLangsValues(updatedLangsValues);
    };

    return !loading ? (
        <div>
            <span className=" block mb-4 ">{t("enter-name-and-translations")}</span>
            <div>
                <span className=" block mb-4">{`${t("default-language")}:`}</span>
                <div className=" flex items-center w-full ml-2  ">
                    <span className=" font-bold flex w-1/6">{t("language:" + langDefault)}</span>
                    <div className=" w-5/6 -ml-1 pr-4">
                        <UseInputText
                            value={langsValues?.[langDefault]?.text || ""}
                            id={`modal-edit-name-${langDefault}`}
                            adjust={
                                validations[`modal-edit-name-${langDefault}`] === false ? " border border-red-100" : ""
                            }
                            inputData={{ name: `modal-edit-name-${langDefault}` }}
                            changeAction={(val) => handleInputChange(langDefault, val)}
                        />
                    </div>
                </div>
            </div>
            <span className=" block mb-4 mt-4">{`${capitalizeFirst(t("translations"))}:`}</span>
            <div className=" ml-2 pt-1 pr-1" style={{ height: "10rem", overflowY: "scroll" }}>
                {langsArray.map((lang) => {
                    return (
                        <div key={lang.languageRef} className=" font-bold flex items-center w-full mb-4 ">
                            <span className=" block w-1/6">{lang.name}</span>
                            <div className=" w-5/6">
                                <UseInputText
                                    id={`modal-edit-name-${lang.languageRef}`}
                                    validation={true}
                                    value={langsValues?.[lang.languageRef]?.text || ""}
                                    inputData={{ name: `modal-edit-name-${lang.name}` }}
                                    ph={
                                        !langsValues?.[lang.languageRef]?.text?.trim() &&
                                        langsValues?.[langDefault]?.text
                                            ? langsValues?.[langDefault]?.text
                                            : ""
                                    }
                                    noTranslatePh={true}
                                    changeAction={(val) => handleInputChange(lang.languageRef, val)}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    ) : (
        <div className=" w-full flex items-center justify-center">
            <UseLoading adjust={"contents"} />
        </div>
    );
};

export default UseModalEditName;

const convertObjectToStringToMutation = (inputObj) => {
    const array = Object.entries(inputObj).map(([key, value]) => {
        return {
            lang: key,
            ...value,
        };
    });
    const jsonString = JSON.stringify(array);
    const modifiedString = jsonString.replace(/"lang":/g, "lang:").replace(/"text":/g, "text:");
    return modifiedString;
};
