import React, { useEffect, useState } from "react";
import UseSectionHeader from "components/useSectionHeader";
import Table from "components/ZafiroTable";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { UseListSignages } from "./graphql/useSignages";
import { UseModalChangeName } from "./modals/useModalChangeName";
import { UseModalDuplicateSequence } from "./modals/useModalDuplicateSequence";
import { UseModalDeleteSequence } from "./modals/useModalDeleteSequence";
import { UseModalChangeAvailability } from "./modals/useModalChangeAvailability";
import { UseModalAddSequence } from "./modals/useModalAddSequence";
import { UseModalMakeDefaultSequence } from "./modals/useModalMakeDefaultSequence";
import { useLoading } from "components/Loading";
import { useNavigate } from "react-router-dom";
import { ScheduledDateColumn } from "./utils";

const DefaultColumn = (props) => {
    return props.isDefault ? (
        <p className=" bg-zafiro-500 rounded-md px-2 py-1 text-white">{props.t("default")}</p>
    ) : (
        <Button
            design={"link"}
            id={"make-as-default"}
            onClick={() => {
                props.openModal({ name: props.name, id: props.id, getSignages: props.getSignages });
            }}
        >
            {props.t("make-default")}
        </Button>
    );
};

const StatusColumn = (props) => {
    const statusStyles = {
        AVAILABLE: "text-green-100",
        UNAVAILABLE: "text-red-100",
    };
    const classname = props.available ? statusStyles.AVAILABLE : statusStyles.UNAVAILABLE;
    const status = props.available ? props.t("available") : props.t("unavailable");
    return (
        <div className=" flex flex-col">
            <p className={classname}>{status}</p>
            {props.secondStatus && props.available && <p className=" text-gray-500">{props.t("expired")}</p>}
        </div>
    );
};

const FiltersColumn = (props) => {
    const numberOfFilters = props.numberOfFilters;
    return !props.isDefault && numberOfFilters > 0 ? (
        <Button design={"link"} id={"filters-button"}>
            {props.t("filters-x", { count: numberOfFilters })}
        </Button>
    ) : !props.isDefault && numberOfFilters == 0 ? (
        <p>{props.t("filters-x", { count: numberOfFilters })}</p>
    ) : (
        "-"
    );
};

const NameColumn = (props) => {
    return (
        <Button
            design={"link"}
            id={`button-signage-name-${props.id}`}
            onClick={() => {
                props.navigate(`/design/advertising/signage/details/${props.id}`);
            }}
        >
            {props.name}
        </Button>
    );
};

const MapRows = (props) => {
    const t = props.t;
    const { name, isDefault, scheduleStart, scheduleEnd, id, contents, scheduleDailyType, available } = props.data;
    const {
        openModalChangeAvailabity,
        openModalChangeName,
        openModalMakeDefault,
        openModalDuplicateSequence,
        openModalDeleteSequence,
    } = props.modals;

    const commonZones = props?.destinationInfo?.commonZones ?? [];
    const groupCommonZones = props?.destinationInfo?.groupCommonZones ?? [];
    const tvLocations = props?.destinationInfo?.tvLocations ?? [];
    const numberOfFilters = commonZones.length + groupCommonZones.length + tvLocations.length;
    const couldBeAvailable = contents?.length > 0 && scheduleDailyType && numberOfFilters > 0;

    return {
        id: id,
        name: NameColumn({ t, name: name, id: id, navigate: props.navigate }),
        scheduledDate: ScheduledDateColumn({ scheduleStart, scheduleEnd }),
        filters: FiltersColumn({ t, filters: props.destinationInfo, numberOfFilters, isDefault }),
        status: StatusColumn({ t, available }),
        default: DefaultColumn({
            id,
            isDefault,
            t,
            name,
            openModal: openModalMakeDefault,
            getSignages: props.getSignages,
        }),
        rowConfig: {
            actions: [
                {
                    id: "edit-name",
                    label: t("edit-name"),
                    onClick: () => {
                        openModalChangeName({ name, id, getSignages: props.getSignages });
                    },
                },
                {
                    id: "edit-sequence",
                    label: t("edit-sequence"),
                    onClick: () => {
                        props.navigate(`/design/advertising/signage/details/${id}`);
                    },
                },
                ...(!isDefault && available
                    ? [
                          {
                              id: "set-as-unavailable",
                              label: t("set-as-unavailable"),
                              onClick: () => {
                                  openModalChangeAvailabity({ name, available, id, getSignages: props.getSignages });
                              },
                          },
                      ]
                    : !isDefault && !available
                    ? [
                          {
                              id: "set-as-available",
                              label: t("set-as-available"),
                              disabled: !couldBeAvailable,
                              tooltip: !couldBeAvailable ? t("the-sequence-will-be-able-to-set-as-available") : null,
                              onClick: () => {
                                  openModalChangeAvailabity({ name, available, id, getSignages: props.getSignages });
                              },
                          },
                      ]
                    : []),

                {
                    id: "duplicate",
                    label: t("duplicate"),
                    onClick: () => {
                        openModalDuplicateSequence({ name });
                    },
                },
                {
                    id: "delete",
                    disabled: isDefault,
                    tooltip: isDefault ? t("default-sequence-cannot-be-deleted") : null,
                    label: t("delete"),
                    onClick: () => {
                        openModalDeleteSequence({ name, id, getSignages: props.getSignages });
                    },
                },
            ],
        },
    };
};

const Signage = () => {
    const { t } = useTranslation();
    const { setLoading } = useLoading();
    const navigate = useNavigate();
    const { getSignages: listSignages, signages, loading } = UseListSignages();
    const [customSort, setCustomSort] = useState({ field: "is_default", criteria: "desc" });
    const [stateFilter, setStateFilter] = useState(null);
    const [customSearch, setCustomSearch] = useState(null);
    const [customDateRangeFilter, setCustomDateRangeFilter] = useState({ from: null, to: null });

    const { open: openModalDuplicateSequence } = UseModalDuplicateSequence();
    const { open: openModalDeleteSequence } = UseModalDeleteSequence();
    const { open: openModalChangeAvailabity } = UseModalChangeAvailability();
    const { open: openModalAddSequence } = UseModalAddSequence();
    const { open: openModalChangeName } = UseModalChangeName();
    const { open: openModalMakeDefault } = UseModalMakeDefaultSequence();

    const stateFilterValues = {
        availableOnGoing: {
            available: true,
            state: "ONGOING",
        },
        available: {
            available: true,
        },
        availableExpired: {
            available: true,
            state: "EXPIRED",
        },
        unavailable: {
            available: false,
        },
    };

    const batchActions = ["duplicate", "set-as-available", "set-as-unavailable", "delete"];
    const stateFilterOptions = [
        {
            id: "color-filter-available-ongoing-option-id",
            value: "availableOnGoing",
            label: (
                <span>
                    {t("available")}
                    <span className="ml-1 text-gray-500">({t("on-going")})</span>
                </span>
            ),
        },
        {
            id: "color-filter-available-option-id",
            value: "available",
            label: t("available"),
        },
        {
            id: "color-filter-available-expired-option-id",
            value: "availableExpired",
            label: (
                <span>
                    {t("available")}
                    <span className="ml-1 text-gray-500">({t("expired")})</span>
                </span>
            ),
        },
        {
            id: "color-filter-unavailable-option-id",
            value: "unavailable",
            label: t("unavailable"),
        },
    ];

    function getSignages() {
        listSignages({
            variables: {
                orderBy: customSort,
                state: stateFilter?.state,
                available: stateFilter?.available,
                search: customSearch,
                scheduleFrom: customDateRangeFilter.from ? customDateRangeFilter.from.split("T")?.[0] : null,
                scheduleTo: customDateRangeFilter.to ? customDateRangeFilter.to.split("T")?.[0] : null,
            },
        });
    }

    useEffect(() => {
        getSignages();
    }, []);

    useEffect(() => {
        if (!customSort) {
            setCustomSort({ field: "is_default", criteria: "desc" });
        }
        getSignages();
    }, [customSort, stateFilter, customSearch, customDateRangeFilter]);

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    return (
        <>
            <UseSectionHeader title={["signage"]} value={"demo"} />
            <Table
                id={"signage-table"}
                cols={["name", "scheduledDate", "filters", "status", "default"]}
                search={true}
                paginate={true}
                showCount={true}
                perPage={8}
                className={"main-container"}
                batchActions={{
                    options: batchActions.map((val) => {
                        return { id: `${val}-batch-id`, value: val, label: t(val) };
                    }),
                    onChange: (action, rows) => {
                        if (action == batchActions[0]) {
                            openModalDuplicateSequence({
                                batch: true,
                                batchList: rows.map((val) => {
                                    return { name: val.name.props.children, id: val.id };
                                }),
                            });
                        }
                        if (action == batchActions[3]) {
                            openModalDeleteSequence({
                                getSignages,
                                batch: true,
                                batchList: rows.map((val) => {
                                    return { name: val.name.props.children, id: val.id };
                                }),
                            });
                        }
                        if (action == batchActions[1]) {
                            openModalChangeAvailabity({
                                batch: true,
                                getSignages,
                                available: false,
                                batchList: rows.map((val) => {
                                    return { name: val.name.props.children, id: val.id };
                                }),
                            });
                        }
                        if (action == batchActions[2]) {
                            openModalChangeAvailabity({
                                batch: true,
                                getSignages,
                                available: true,
                                batchList: rows.map((val) => {
                                    return { name: val.name.props.children, id: val.id };
                                }),
                            });
                        }
                    },
                }}
                topRightCorner={
                    <Button
                        design={"blue"}
                        id={"add-sequence-button"}
                        onClick={() => {
                            openModalAddSequence({ getSignages, firstSequence: signages.length == 0 });
                        }}
                    >
                        {t("add-sequence")}
                    </Button>
                }
                header={{
                    name: { title: t("name"), sortable: true },
                    scheduledDate: { title: t("scheduled-date"), sortable: true },
                    filters: { title: t("filters"), sortable: true },
                    status: { title: t("status"), sortable: true },
                }}
                rows={
                    signages?.map((val) => {
                        return MapRows({
                            t,
                            data: val,
                            modals: {
                                openModalChangeAvailabity,
                                openModalDeleteSequence,
                                openModalDuplicateSequence,
                                openModalChangeName,
                                openModalMakeDefault,
                            },
                            navigate,
                            getSignages,
                        });
                    }) || []
                }
                filters={[
                    {
                        id: "filter-by-color",
                        title: t("state"),
                        multiple: true,
                        options: stateFilterOptions,
                        onChange: (val) => {
                            setStateFilter(stateFilterValues[val]);
                        },
                    },
                ]}
                customSort={{
                    value: customSort,
                    onChange: setCustomSort,
                }}
                customRangeSelector={{
                    value: customDateRangeFilter,
                    onChange: setCustomDateRangeFilter,
                    hideTimePicker: true,
                }}
                customSearch={{
                    value: customSearch,
                    onChange: setCustomSearch,
                }}
            ></Table>
        </>
    );
};

export default Signage;
