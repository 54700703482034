import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseButton from "../useButton";
import UseImageCell from "../Table/cells/useImageCell";
//Actions
import { showRowForm, setExecuteQuery, setHiddenRows } from "../../actions/sectionActions";
import { cleanAction, setActionValidations } from "../../actions/globalActions";
//Utils
import { toast } from "react-toastify";
import { Session } from "../../hooks/Utils/Session";
import UseEditableField from "../useEditableField";
import { validateSection } from "../../hooks/Utils/Validations";
import { useTranslation } from "react-i18next";

const UseFormMediaLibraryFolder = () => {
    const { t } = useTranslation();
    const { results } = useSelector((state) => state.table);
    const { viewMode } = useSelector((state) => state.sectionContent);

    const { values, actionName, itemsAffected } = useSelector((state) => state.action);
    const isCard = viewMode && viewMode === "map" ? true : false;
    const inputName = useRef(null);

    //CONSTANT
    const EDITING = actionName === "edit-media-library-folder" || actionName === "edit-media-library-media";

    //Actions
    const dispatch = useDispatch();

    const cancelAction = () => {
        dispatch(showRowForm(false));
        dispatch(cleanAction());
        dispatch(setHiddenRows(true));
    };

    useEffect(() => {
        inputName.current = document.querySelector("#media-library-name-id-input");
        if (inputName.current) {
            inputName.current.focus();
            if (isCard) inputName.current.onblur = handlerTriggerBlur;
        }
        const listener = (event) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                //event.preventDefault();
                if (inputName.current && isCard) {
                    inputName.current.blur();
                }
            } else if (event.code === "Escape") {
                cancelAction();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
        // eslint-disable-next-line
    }, []);

    const saveAction = () => {
        //Validate fields
        const validations = validateSection(
            EDITING
                ? actionName === "edit-media-library-folder"
                    ? "media-library-edit-folder"
                    : "media-library-edit-media"
                : "media-library-add-folder",
            values,
            t
        );
        dispatch(setActionValidations(validations));
        const validationsArray = Object.values(validations);

        if (validationsArray.filter((item) => item === false).length > 0) {
            toast.error(t("errors-in-red"));
            return;
        }
        if (EDITING) {
            dispatch(
                setExecuteQuery({
                    action:
                        actionName === "edit-media-library-folder"
                            ? "media-library-edit-folder"
                            : "media-library-edit-media",
                    params: {
                        noGoBack: true,
                    },
                })
            );
        } else {
            dispatch(
                setExecuteQuery({
                    action: "media-library-add-folder",
                    params: {
                        noGoBack: true,
                    },
                })
            );
        }
        dispatch(showRowForm(false));
        dispatch(setHiddenRows(true));
    };

    const setValue = (name) => {
        let initVal = "";
        if (actionName === "edit-media-library-folder" || actionName === "edit-media-library-media") {
            results.filter((result) =>
                result.id === itemsAffected[0]
                    ? result.info.map((cellData) => (cellData.name === name ? (initVal = cellData.valueToEdit) : null))
                    : null
            );
        }
        return initVal;
    };

    function handlerTriggerBlur() {
        if (inputName.current && inputName.current.value) {
            document.querySelector(".media-library-save-button").click();
        } else {
            cancelAction();
        }
    }

    return (
        <>
            <div
                className={`${
                    isCard && isCard === true
                        ? "inline-block mr-5 ml-5 mb-5 card-item-container"
                        : "border-b border-gray-200"
                }`}
            >
                <div
                    className={`${
                        isCard && isCard === true
                            ? "w-full bg-white  h-auto  items-center "
                            : "w-full bg-white  h-auto flex items-center pl-5 ml-5"
                    }`}
                    style={{ minHeight: "4rem" }}
                >
                    <div
                        className={`${
                            isCard && isCard === true
                                ? "w-12/12 items-center flex h-40 card-item-container-image mb-3"
                                : "w-1/12 h-20 pl-5"
                        } text-center`}
                    >
                        <UseImageCell
                            cellData={{
                                isFromUrl: actionName === "edit-media-library-media",
                                urlPath:
                                    actionName === "edit-media-library-media"
                                        ? Session.getDasUrl(itemsAffected[0] + "?w=152&h=140")
                                        : "",
                                hidden: false,
                                path: "icons/",
                                name: actionName === "edit-media-library-media" ? "image" : "folder",
                                type: "svg",
                                adjust: `text-center ${
                                    isCard && isCard === true
                                        ? actionName === "edit-media-library-media"
                                            ? "max-w-full max-h-full m-auto"
                                            : "w-1/2 h-full mb-5 m-auto"
                                        : actionName === "edit-media-library-media"
                                        ? "max-w-full max-h-full m-auto py-2 pr-5"
                                        : "w-18 h-18"
                                }`,
                            }}
                        />
                    </div>
                    <UseEditableField
                        data={{
                            id: `media-library-name-id-input`,
                            label: "media-library-name",
                            placeholder: "name",
                            inEditionMode: true,
                            type: "text",
                            styleAdjust: `${isCard && isCard === true ? "w-12/12" : "w-2/12"}`,
                            value: setValue("name"),
                            noLabel: true,
                        }}
                    />{" "}
                    <div className="w-6/12"></div>
                    <div
                        className={`w-1/12 text-center ${isCard && isCard === true ? "hidden" : ""}`}
                        onClick={(e) => cancelAction()}
                    >
                        <UseButton
                            id={"form-media-library-cancel-add-folder"}
                            buttonName={"cancel"}
                            buttonColor={"btn-blue-outline"}
                            adjust={`p-0`}
                        />
                    </div>
                    <div className="w-0/12 mr-5"></div>
                    <div
                        className={`w-1/12 text-center ${isCard && isCard === true ? "hidden" : ""}`}
                        onClick={(e) => saveAction()}
                    >
                        <UseButton
                            id={"form-media-library-add-folder"}
                            buttonName={"save"}
                            buttonColor={"btn-blue"}
                            adjust={`p-0 media-library-save-button`}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default UseFormMediaLibraryFolder;
