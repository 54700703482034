import i18next from "i18next";
const channelUsedInProperties = (data) => {
    return {
        title: "use",
        value: data.name ? data.name : "",
        text: data.predefindeChannel
            ? i18next.t("the-predefined-channel-is-use-in-the-next-properties", { name: data.name })
            : "the-channel-is-tuned-to-the-following-properties",
        noCapitalizeTitle: true,
        bodyAdjust: "w-1/12",
        // executeSectionQuery: true,
        inputs: [
            {
                type: "channelUseInProperties",
                data: data ? data : {},
            },
        ],
        buttons: [{ name: "close", style: "blue", action: data.predefindeChannel ? "close&KeepActions" : "close" }],
    };
};

export default channelUsedInProperties;
