import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    setCountPosition,
    setHeaders,
    setLoading,
    setTableResults,
    setCustomError,
    setCustomConfig,
    setTableType,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetCustomersTableHeaders";
//Model
import Booking from "./models/Booking";
import { useTranslation } from "react-i18next";

const ListBookings = ({ data }) => {
    const { t } = useTranslation();
    const { commonzones } = useSelector((state) => state.sectionContent);

    //Data
    const sectionName = "list-bookings";

    //Actions
    const dispatch = useDispatch();

    //Listeners
    useEffect(() => {
        //Get data
        const dataItems = arrangeData(data, commonzones, t);
        const tableHeaders = GetTableHeaders(sectionName);
        //Add to store
        dispatch(setLoading(true));
        setTimeout(function () {
            dispatch(setHeaders(tableHeaders));
        }, 50);

        dispatch(setTableType(sectionName));
        dispatch(setTableResults(dataItems));
        dispatch(setCountPosition("table-header"));
        dispatch(
            setCustomConfig({
                pagination: false,
                tableContainerAdjust: "min-h-0	",
            })
        );
        dispatch(showRowForm(false));
        dispatch(setCustomError(t("no bookings available")));
        dispatch(setLoading(false));

        return () => {
            dispatch(setCustomConfig(null));
        };
        // eslint-disable-next-line
    }, []);
};

const arrangeData = (data, commonzones, t) => {
    const bookingsData = [];
    // eslint-disable-next-line
    if (data) {
        data.map((booking) => {
            bookingsData.push(Booking(booking, commonzones, t));
        });
    }
    return bookingsData;
};

export default ListBookings;
