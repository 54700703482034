import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { capitalizeFirst } from "hooks/Utils/Utils";
export const UseModalGuestReport = () => {
    const { t } = useTranslation();
    const { values } = useSelector((state) => state.action);
    const { inputs } = useSelector((state) => state.ui.modalContent);
    const { lang } = useSelector((state) => state.ui);
    const { hasChildrenCustomFields, showTwoColumns } = inputs[1];
    const generalList = values["guest-report-select"] || [];
    const childList = values["children-guest-report-select"] || [];

    const customFields = inputs[1].customFields
        .sort((a, b) => {
            return a.order - b.order;
        })
        .map((val) => {
            const title =
                val.names.find((val) => val.lang == lang).name || val.names.find((val) => val.name != "").name;
            return { ref: val.ref, name: title };
        });
    const customFieldsChildren = inputs[1].customFieldsChildren
        .sort((a, b) => {
            return a.order - b.order;
        })
        .map((val) => {
            const title =
                val.names.find((val) => val.lang == lang).name || val.names.find((val) => val.name != "").name;
            return { ref: val.ref, name: title };
        });

    return (
        <>
            <p className=" mb-5">{t("are-you-sure-you-want-to-continue")}</p>
            {hasChildrenCustomFields && showTwoColumns ? (
                <div className=" grid grid-cols-2 gap-4">
                    <div>
                        <p className="flex justify-between items-center px-4 py-1 bg-gray-100 mb-2 rounded font-bold">
                            {capitalizeFirst(t("general"))}
                        </p>
                        <div className=" ml-4">
                            {generalList.map((list) => (
                                <p>{customFields.find((val) => val.ref == list)?.name || t(list)}</p>
                            ))}
                        </div>
                    </div>
                    <div>
                        <p className="flex justify-between items-center px-4 py-1 bg-gray-100 mb-2 rounded font-bold">
                            {capitalizeFirst(t("children"))}
                        </p>
                        <div className=" ml-4">
                            {childList.map((list) => (
                                <p>{customFieldsChildren.find((val) => val.ref == list)?.name || t(list)}</p>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {generalList.map((list) => (
                        <p className=" font-bold">{customFields.find((val) => val.ref == list)?.name || t(list)}</p>
                    ))}
                </div>
            )}
        </>
    );
};
