import React, { useEffect, useState } from "react";
import UseSectionHeader from "../../useSectionHeader";
import Table, { stringValue } from "components/ZafiroTable";
import { useTranslation } from "react-i18next";
import { useListPassCodes } from "hooks/GraphqlCalls/Customers/useListPassCodes";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useDeletePasscodes } from "hooks/GraphqlCalls/Customers/useDeletePasscodes";
import Loading from "components/Loading";
import { AddNewPassCode } from "./AddNewPassCode/AddNewPassCode";
import { capitalizeFirst } from "hooks/Utils/Utils";

const PasscodesConfiguration = () => {
    const [newOptions, setNewOptions] = useState([
        {
            id: "active",
            label: "active",
            value: "active",
            active: true,
        },
        {
            id: "expired",
            label: "expired",
            value: "expired",
            active: false,
        },
    ]);
    const [cols, setCols] = useState([]);
    const [header, setHeader] = useState({});
    const [filters, setFilters] = useState([]);

    const { t } = useTranslation();
    const { open, close } = useModal();
    const { passCodes, filter, refetch, ready, getExpiredData, getActiveData, loadingRefresh, setLoadingRefresh } =
        useListPassCodes();

    const handleClicked = (id) => {
        const updatedOptions = newOptions.map((option) => {
            if (option.id === id) {
                if (id === "expired") {
                    getExpiredData();
                } else if (id === "active") {
                    getActiveData();
                }
                return { ...option, active: true };
            } else {
                return { ...option, active: false };
            }
        });
        setNewOptions(updatedOptions);
    };

    useEffect(() => {
        const passcodeHeader = {
            passcode: { title: capitalizeFirst(t("passcode")), sortable: true },
            "access-profile": { title: t("access-profile"), sortable: true },
            "limit-of-devices": { title: capitalizeFirst(t("limit-of-devices")), sortable: true },
            duration: { title: capitalizeFirst(t("duration")), sortable: true },
        };

        const activeHeader = {
            ...passcodeHeader,
            status: { title: capitalizeFirst(t("status")), sortable: true },
            preview: { title: "", width: "10%" },
            delete: { title: "", width: "10%" },
        };

        const expiredHeader = {
            ...passcodeHeader,
            delete: { title: "", width: "5%" },
        };

        const passcodeCols = ["passcode", "access-profile", "limit-of-devices", "duration"];
        const activeCols = [...passcodeCols, "status", "preview", "delete"];
        const expiredCols = [...passcodeCols, "delete"];

        const activeFilters = [
            {
                id: "duration",
                title: t("duration"),
                options: [
                    {
                        id: "all",
                        value: null,
                        label: `-- ${t("all")} --`,
                    },
                    {
                        id: "unlimited",
                        label: t("unlimited"),
                        value: "unlimited",
                    },
                ],
                onFilter: (values, row) => (values?.includes(stringValue(row?.duration)) ? row : null),
            },
            {
                id: "access-profile",
                title: t("access-profile"),
                multiple: true,
                options: filter,
                onFilter: (values, row) => (values?.includes(stringValue(row["access-profile"])) ? row : null),
            },
            {
                id: "status",
                title: t("status"),
                options: [
                    {
                        id: "started",
                        label: t("started"),
                        value: "started",
                    },
                    {
                        id: "not-began",
                        label: t("not-started"),
                        value: "not-began",
                    },
                ],
                onFilter: (values, row) => (values?.includes(stringValue(row?.status)) ? row : null),
            },
        ];

        const expiredFilters = [
            {
                id: "access-profile",
                title: t("access-profile"),
                multiple: true,
                options: filter,
                onFilter: (values, row) => (values?.includes(stringValue(row["access-profile"])) ? row : null),
            },
        ];

        const header = newOptions[0]?.active ? activeHeader : expiredHeader;
        const cols = newOptions[0]?.active ? activeCols : expiredCols;
        const filters = newOptions[0]?.active ? activeFilters : expiredFilters;

        setHeader(header);
        setCols(cols);
        setFilters(filters);
    }, [newOptions, passCodes]);

    return (
        <>
            <UseSectionHeader title={["passcodes-configuration"]} />
            <main className="main-container">
                {ready && !loadingRefresh ? (
                    <Table
                        id="passcodes-configuration"
                        paginate={true}
                        folders={
                            <div className="flex items-center">
                                {newOptions?.map((folder, index) => (
                                    <Button
                                        id={`${folder.id}-button`}
                                        key={index}
                                        onClick={() => handleClicked(folder.id)}
                                        className={`border-b-4 ${
                                            folder?.active
                                                ? "text-blue-800 border-blue-700 font-black"
                                                : "border-gray-400 text-gray-800"
                                        } px-6 py-2 text-md w-40 justify-center font-bold hover:text-zafiro-600`}
                                    >
                                        {capitalizeFirst(t(folder?.label))}
                                    </Button>
                                ))}
                            </div>
                        }
                        cols={cols}
                        header={header}
                        rows={passCodes}
                        search={true}
                        showCount={true}
                        topRightCorner={
                            <Button
                                id="add-passcode"
                                className="btn btn-blue"
                                onClick={() =>
                                    open(
                                        <Modal
                                            id="add-passcode"
                                            title={capitalizeFirst(t("new-passcode"))}
                                            className="w-1/3 px-12 py-10 overflow-hidden"
                                            onKeyPress={{ Enter: close }}
                                        >
                                            <AddNewPassCode refetch={refetch} />
                                        </Modal>
                                    )
                                }
                            >
                                {capitalizeFirst(t("add-passcode"))}
                            </Button>
                        }
                        filters={filters}
                        batchActions={{
                            options: [
                                {
                                    id: "delete",
                                    value: "delete",
                                    label: t("delete"),
                                },
                            ],
                            onChange: (action, rows) => {
                                if (action === "delete") {
                                    open(
                                        <Modal
                                            id="delete-passcode"
                                            title={capitalizeFirst(t("delete"))}
                                            className="w-1/2 p-10"
                                            onKeyPress={{ Enter: close }}
                                        >
                                            <DeleteAction rows={rows} setLoadingRefresh={setLoadingRefresh} />
                                        </Modal>
                                    );
                                }
                            },
                        }}
                    />
                ) : (
                    <Loading adjust="section-loading" style={{ height: "50vh" }} />
                )}
            </main>
        </>
    );
};

export default PasscodesConfiguration;

const DeleteAction = ({ rows, setLoadingRefresh }) => {
    const [id] = useState(rows?.map((room) => room.id));
    const { t } = useTranslation();
    const { close } = useModal();
    const { deletePasscodes } = useDeletePasscodes({ id });

    const deletePasscode = () => {
        deletePasscodes();
        close();
        setLoadingRefresh(true);
    };

    return (
        <div>
            <p>{t("delete-passcodes-confirm")}</p>
            <div className="flex gap-2 items-center mt-4">
                {rows.map((row, index) => (
                    <p key={index} className="bg-gray-200 px-5 py-1 rounded">
                        {row?.passcode}
                    </p>
                ))}
            </div>
            <div className="flex justify-end gap-4 mt-8">
                <Button id="cancel" onClick={close} className="btn btn-white">
                    {t("cancel")}
                </Button>
                <Button id="delete" onClick={deletePasscode} className="btn btn-red">
                    {t("delete")}
                </Button>
            </div>
        </div>
    );
};
