import Parser from "hooks/Utils/Parser";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

//Utils

const UseDeleteBatchChannelsDetail = ({ index }) => {
    const { t } = useTranslation();
    //Store data
    const { results, checkedItems } = useSelector((state) => state.table);

    const { elementsType } = useSelector((state) => state.ui.modalContent.inputs[index]);

    return (
        <>
            <div className="flex mb-3">
                <div className="mr-auto font-bold">
                    {t("{{capitalize, capitalize}}", { capitalize: t(pluralizeType(elementsType)) })}
                </div>
                <div>
                    {checkedItems.length} {t(checkedItems.length === 1 ? elementsType : pluralizeType(elementsType))}
                </div>
            </div>
            <div className="border border-gray-200 px-8">
                {checkedItems.map((idItem, index) => {
                    const elementData = results.find((item) => item.id === idItem);
                    return (
                        <div className={`flex py-4 ${index > 0 ? "border-t border-gray-200" : ""}`}>
                            <div className="mr-auto">{elementData.name}</div>
                            <div className="table">
                                {elementData.deleteWarning ? (
                                    <>
                                        <span className="table-cell align-middle">
                                            {Parser(elementData.deleteWarning)}
                                        </span>
                                        <span className="icon-warning text-orange-100 text-3xl ml-2"></span>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
const pluralizeType = (type) => {
    return type + "s";
};

export default UseDeleteBatchChannelsDetail;
