import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactTooltip from "components/ReactTooltip";
import { withDeprecationWarning } from "dev";

import { setActionInput } from "../../actions/globalActions";

const UseSwitch = withDeprecationWarning(
    { name: "UseSwitch", alt: "Switch" },
    ({
        checked,
        action,
        id,
        icon,
        hidden,
        disabled,
        tooltip,
        tooltipDelay,
        showLabel,
        label,
        adjust,
        iconLeftDisabled,
    }) => {
        const dispatch = useDispatch();
        const [isActive, setChecked] = useState(checked);
        const switchHandler = () => {
            if (disabled) {
                return;
            }
            const switchValue = isActive ? false : true;
            setChecked(switchValue);
            if (action) {
                action(switchValue);
            }
        };

        //Actions
        useEffect(() => {
            const dataToAction = { [id]: isActive };
            dispatch(setActionInput(dataToAction));
            // eslint-disable-next-line
        }, [isActive]);

        useEffect(() => {
            setChecked(checked);
        }, [checked]);

        return hidden ? null : (
            <div
                className={`${disabled ? "cursor-not-allowed" : ""} align-middle ${
                    icon ? "inline-block" : ""
                } ${adjust}`}
            >
                {label ? (
                    <span className={disabled || !isActive ? "text-gray-500" : null}>{label}</span>
                ) : (
                    renderLabelOnOff({ showLabel, isActive })
                )}
                <label className={`${icon ? "" : "switch ml-2"}`} data-tip data-for={`${id}_switch_tooltip`}>
                    <input
                        id={id}
                        type="checkbox"
                        className={`${disabled ? "cursor-not-allowed" : "cursor-pointer"} ${icon ? "hidden" : ""}`}
                        disabled={disabled}
                        checked={isActive}
                        onChange={() => switchHandler()}
                    ></input>
                    {icon ? (
                        <i
                            className={`inline-block p-2 icon ${icon} ${isActive ? "bg-gray-200" : ""} ${
                                disabled ? "text-gray-500" : ""
                            }`}
                        ></i>
                    ) : (
                        <span
                            id={`${id}_span_action`}
                            className={`slider  ${disabled ? "bg-gray" : "bg-gray-5"} round ${
                                iconLeftDisabled ? "left-disabled" : ""
                            }`}
                        ></span>
                    )}
                </label>
                {tooltip ? (
                    <ReactTooltip
                        id={`${id}_switch_tooltip`}
                        delayShow={tooltipDelay || 300}
                        type={"light"}
                        borderColor={"#D3DAE1"}
                        border={true}
                        multiline={true}
                    >
                        {tooltip}
                    </ReactTooltip>
                ) : null}
            </div>
        );
    }
);

const renderLabelOnOff = (props) => {
    const { showLabel, isActive } = props;
    let response = null;
    if (showLabel) {
        response = isActive ? "ON" : "OFF";
    }
    return response;
};

export default UseSwitch;
