const EditGridName = (t, data) => {
    return {
        title: "edit-name",
        text: t("edit-the-name-of-the-grid"),
        executeSectionQuery: true,
        bodyAdjust: "w-1/12",
        value: data.name ? data.name : "",
        inputs: [
            {
                text: "",
                name: "edit-the-name-of-the-grid",
                type: "text",
                focus: true,
                ph: t("name"),
                value: data.name ? data.name : "",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: "edit-grid-name",
            },
        ],
    };
};

export default EditGridName;
