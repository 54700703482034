import React from "react";
import { GetCellContent } from "../../../hooks/Utils/GetCellContent";

const UseValueCell = ({ cellData }) => {
    const { hidden, adjust } = cellData || {};
    const content = GetCellContent(cellData);
    const style = getCellStyle(adjust);
    return <>{!hidden ? <div className={`text-left ${style}`}>{content}</div> : null}</>;
};

export const getCellStyle = (adjust) => {
    let res = ``;
    if (adjust) {
        res += `${adjust}`;
    }
    return res;
};

export default UseValueCell;
