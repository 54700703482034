import React, { useState, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
//Actions
import { setScheduleToUpdate, setMutationRefresh, setLastScheduleEdition } from "../../actions/scheduleActions";
import DatePicker from "react-datepicker";

const UseInputTimeEditSchedule = ({ dateId, validation, index, timetableIndex }) => {
    //Store data
    const { t } = useTranslation();
    const { langStrings } = useSelector((state) => state.ui);
    const { scheduleToUpdate } = useSelector((state) => state.schedules);
    const { actionName } = useSelector((state) => state.action);

    //Validation styles
    const [defaultTime, setDefaultTime] = useState(false);

    //Actions
    const dispatch = useDispatch();

    const padFix = (n) => {
        return ("00" + n).match(/\d{2}$/);
    };

    const formatDate = (date, format) => {
        switch (format) {
            case "date":
                return date.getFullYear() + "-" + padFix(date.getMonth() + 1) + "-" + padFix(date.getDate());
            case "time":
                return padFix(date.getHours()) + ":" + padFix(date.getMinutes());
            default:
                return date;
        }
    };

    const changeScheduleHandler = (dateC) => {
        //Get new string timeRange
        let date = "";
        if (dateC) {
            date = formatDate(dateC, "time");
        }
        const newTimeRanges = getUpdatedTimeInfo(dateId, date, scheduleToUpdate, index, actionName, timetableIndex);
        //Create new scheduleToUpdateInfo
        const newScheduleInfo = scheduleToUpdate;
        if (actionName !== "edit-special-schedule") {
            newScheduleInfo.timetableDates[index].timetableDateRanges[0].timeRanges = `${newTimeRanges}`;
        } else {
            newScheduleInfo.timetableDates[timetableIndex].timetableDateRanges[index].timeRanges = `${newTimeRanges}`;
        }
        //Update current store
        dispatch(setScheduleToUpdate(newScheduleInfo));
        refreshSchedule();
        //Force mutation refresh
        dispatch(setMutationRefresh(true));
        dispatch(setLastScheduleEdition());
    };

    const refreshSchedule = () => {
        if (isCurrentData(scheduleToUpdate, index, actionName)) {
            const defaultValue = getDefaultValue(scheduleToUpdate, index, dateId, actionName, timetableIndex);
            setDefaultTime(defaultValue);
        }
    };

    //Listeners

    useEffect(() => {
        //Loads current input data
        refreshSchedule();
        // eslint-disable-next-line
    }, [scheduleToUpdate]);

    const HourInput = forwardRef(({ value, onClick }, ref) => (
        <div
            className={`t-filter-input relative inline-block py-2 px-4 mt-2 mr-4 ${
                validation !== undefined && !validation ? "border border-red-100" : ""
            }`}
            onClick={onClick}
            onKeyPress={onClick}
            ref={ref}
            tabIndex={0}
        >
            <i className="icon icon-clock mr-2 text-gray-700"></i>
            {value ? value : t("Select hour")}
        </div>
    ));

    return (
        <>
            <div className="inline-block">
                <DatePicker
                    selected={defaultTime ? new Date(new window.ZDate("2000-01-01T" + defaultTime).toDate()) : null}
                    onChange={changeScheduleHandler}
                    dateFormat="HH:mm"
                    timeCaption={t("Hour")}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    disabled={false}
                    name="hour"
                    isClearable={false}
                    customInput={<HourInput />}
                />
            </div>
            {!validation ? <p className="absolute text-red-100">{langStrings["schedule-error"]}</p> : null}
        </>
    );
};

const isCurrentData = (scheduleToUpdate, index, actionName) => {
    //Returns true if there´s current data
    let res;
    if (actionName !== "edit-special-schedule") {
        if (scheduleToUpdate && scheduleToUpdate.timetableDates && scheduleToUpdate.timetableDates[index]) {
            res = true;
        } else {
            res = false;
        }
    } else {
        if (scheduleToUpdate && scheduleToUpdate.timetableDates && scheduleToUpdate.timetableDates[0]) {
            res = true;
        } else {
            res = false;
        }
    }

    return res;
};

const getCleanSchedules = (scheduleToUpdate, index, actionName, timetableIndex) => {
    //Returns current string data without unnecessary chars
    let schedules;
    if (actionName !== "edit-special-schedule") {
        schedules = scheduleToUpdate.timetableDates[index].timetableDateRanges[0].timeRanges;
    } else {
        schedules = scheduleToUpdate.timetableDates[timetableIndex].timetableDateRanges[index].timeRanges;
    }
    schedules = schedules.split('"').join("");
    schedules = schedules.split("[").join("");
    schedules = schedules.split("]").join("");
    schedules = schedules.split(",").join("-");
    schedules = schedules.split("-");
    return schedules;
};

const getDefaultValue = (scheduleToUpdate, index, timeType, actionName, timetableIndex) => {
    //Returns current value of input
    let res;
    const schedules = getCleanSchedules(scheduleToUpdate, index, actionName, timetableIndex);
    const timePosition = getCurrentTimePosition(timeType, schedules);

    if (timePosition !== 100) {
        res = schedules[timePosition];
    } else {
        res = "";
    }
    return res;
};

const getCurrentTimePosition = (timeType, schedules) => {
    //Returns current array position of desired time
    let res;
    switch (timeType) {
        case "morningStart":
            res = 0;
            break;
        case "morningEnd":
            res = 1;
            break;
        case "afternoonStart":
            if (schedules.length > 2) {
                res = 2;
            } else {
                res = 100;
            }
            break;
        default:
            res = 3;
    }
    return res;
};

const getUpdatedTimeInfo = (timeType, value, scheduleToUpdate, index, actionName, timetableIndex) => {
    //Returns updated timeRange string
    const cleanSchedules = getCleanSchedules(scheduleToUpdate, index, actionName, timetableIndex);
    const position = getCurrentTimePosition(timeType, cleanSchedules);
    cleanSchedules[position] = value;
    return getTimeRangeString(cleanSchedules);
};

const getTimeRangeString = (cleanSchedules) => {
    let res = `[`;
    // eslint-disable-next-line
    cleanSchedules.map((cleanSchedule, index) => {
        if (cleanSchedule) {
            if (index !== 0 && index % 2 !== 0) {
                res += `-`;
            } else if (index !== 0 && index % 2 === 0) {
                res += `,`;
            }
            res += `${cleanSchedule}`;
        }
        return cleanSchedule;
    });
    res += `]`;
    return res;
};

export default UseInputTimeEditSchedule;
