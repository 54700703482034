import i18next from "i18next";
import { Session } from "../../../hooks/Utils/Session";
import { SENT_MSG, DRAFTS_MSG, TEMPLATES_MSG, SCHEDULED_MSG, PERIODIC_SEND } from "../Customers/MessagesUtils";
import { UPCOMING_GUESTS_STATUS } from "../Customers/GuestsUtils";
import { capitalizeFirst } from "../Utils";
import { deviceAction } from "./Actions/deviceAction";
import {
    verifyCheckInAvailableArrivalDate,
    formatAvalibaleOnCheckInDate,
} from "components/Section/Customers/Precheckin/ReservationDetails/utils";
import { dateBeforNow } from "../Validations";

const scheduleType = (res, value) => {
    res.push({ name: "edit", noModal: true });
    res.push({ name: "delete" });
    if (value) {
        res.push({ name: "disable", directAction: true, noModal: true });
    } else {
        res.push({ name: "enable", directAction: true, noModal: true });
    }
    return res;
};

const scheduleWizard = (res, value) => {
    res.push({ name: "delete" });
    if (value) {
        res.push({ name: "disable", directAction: true, noModal: true });
    } else {
        res.push({ name: "enable", directAction: true, noModal: true });
    }
    return res;
};

const gridChannel = (res, resultsItem) => {
    res.push({ name: "edit-grid", noModal: true, link: `/services/tv-channels/grids/${resultsItem.id}` });
    if (resultsItem.multipleGrids) {
        res.push({
            name: "add-filter",
            modal_title: capitalizeFirst(i18next.t("add-filter")),
            action: "add-filter",
            disable: resultsItem.isDefault ? true : false,
        });
    }
    res.push({
        name: "delete",
        modal_title: capitalizeFirst(i18next.t("delete")),
        action: "delete-grid",
        disable: resultsItem.isDefault ? true : false,
    });
    return res;
};

const corporateChannel = (res, resultsItem) => {
    res.push(
        {
            name: "set-up-channel",
            noModal: true,
            link: `/services/tv-channels/corporate-channels/${resultsItem.id}`,
        },
        {
            name: "delete",
            modal_title: capitalizeFirst(resultsItem.t("delete-channel")),
            action: "delete-corporate-channel",
        }
    );
    return res;
};

const area = (res, resultsItem, value) => {
    res.push({
        name: "area-edit-description",
        noModal: true,
        link: `/common-zones/zones-configuration/area/description/${value}`,
    });
    if (resultsItem && !resultsItem.currentStatus) {
        if (
            resultsItem.bookingAllowed ||
            resultsItem.schedule.length > 0 ||
            resultsItem.capacityLimit > 0 ||
            resultsItem.description !== ""
        ) {
            res.push({
                name: "area-open",
                noModal: true,
                directAction: true,
            });
        } else {
            res.push({
                name: "area-open",
                directAction: true,
            });
        }
    } else {
        res.push({
            name: "area-temp-close",
            noModal: true,
            link: `/common-zones/zones-configuration/area/close/${value}`,
        });
    }
    res.push({
        name: "email-booking-notification",
    });
    res.push({
        name: "area-see-link",
    });
    res.push({
        name: "area-move-category",
    });
    res.push({
        name: "area-erase",
    });
    return res;
};

const staffMember = (res) => {
    res.push({
        name: "edit-staff-user",
    });
    res.push({
        name: "staff-generate-password",
    });
    res.push({
        name: "staff-delete",
        text: "staff-confirm-deletion",
    });
    return res;
};

const guestCurrent = (res, resultsItem, value) => {
    const blockGuestManagement = Session.getProject()?.blockGuestManagement;

    res.push({
        name: "view-guest",
        noModal: true,
        link: `/customers/guests/guest-list/guest/${value}`,
    });

    if (resultsItem.rooms && resultsItem.rooms.length > 0 && !blockGuestManagement) {
        res.push({
            name: "change-room",
        });
    }
    if (!blockGuestManagement) {
        res.push({
            name: "assign-to-group",
        });
    }

    if (resultsItem?.hasGuestSatisfaction) {
        res.push({
            name: "feedback-history",
            noModal: true,
            disable: resultsItem.lastSatisfactionLevel === null ? true : false,
            onClickCallback: () => {
                resultsItem.openModalFeedbackHistory({ guestID: resultsItem.guestID || null });
            },
        });
    }

    if (!blockGuestManagement) {
        res.push({
            name: "checkout",
        });
    }

    return res;
};

const guestFuture = (res, value, resultsItem, t) => {
    const status = resultsItem.info[5].value.value;

    switch (status) {
        case UPCOMING_GUESTS_STATUS.PENDING_COMPLETION.value:
            res.push({
                name: "check-stay-verify-information",
                noModal: true,
                link: `/customers/guests/guest-list/reservation-details/${value}`,
            });
            res.push({ name: "resend-pre-check-in", action: "resend-pre-check-in" });
            res.push({ name: "delete", action: "delete-pre-check-in" });
            break;
        case UPCOMING_GUESTS_STATUS.PENDING_MODIFICATION.value:
        case UPCOMING_GUESTS_STATUS.PENDING_VALIDATION.value:
            res.push({
                name: "check-stay-verify-information",
                noModal: true,
                link: `/customers/guests/guest-list/reservation-details/${value}`,
            });
            res.push({ name: "delete", action: "delete-pre-check-in" });
            break;
        case UPCOMING_GUESTS_STATUS.VALIDATED.value:
            res.push({
                name: "check-stay-verify-information",
                noModal: true,
                link: `/customers/guests/guest-list/reservation-details/${value}`,
            });
            const arrivalDate = resultsItem.info[7].value.arrivalDate;
            res.push({
                name: "Check-in",
                disable: verifyCheckInAvailableArrivalDate(arrivalDate),
                tooltip: verifyCheckInAvailableArrivalDate(arrivalDate)
                    ? t("available-on", { date: formatAvalibaleOnCheckInDate(arrivalDate) })
                    : "",
                action: "execute-checkin",
            });

            res.push({ name: "delete", action: "delete-pre-check-in" });
            break;
    }

    return res;
};

const user = (res, resultsItem) => {
    const editEnabled = resultsItem?.isEditable && (!resultsItem?.hasCorporate || Session.isCorporate());
    const deleteEnabled = editEnabled && (resultsItem?.countProperties <= 1 || Session.isCorporate());
    const sendPassEnabled = resultsItem?.sendPassword;
    if (!editEnabled && !deleteEnabled && !sendPassEnabled) {
        return res;
    }
    res.push(
        {
            name: "generate-password",
            disable: !sendPassEnabled,
        },
        {
            name: "edit-user",
            disable: !editEnabled,
        },
        {
            name: "delete-user",
            disable: !deleteEnabled,
            tooltip: resultsItem?.countProperties > 1 ? "This user has more associated properties" : null,
        }
    );
    return res;
};

const role = (res, resultsItem) => {
    if (resultsItem.type !== "CUSTOMISED") return undefined;
    res.push({
        name: "edit-role",
    });
    res.push({
        name: "duplicate",
        noModal: true,
        executeSectionQuery: "duplicate-role",
    });
    res.push({
        name: "delete-role",
        tooltip: "role-assigned",
        disable: resultsItem?.users?.length !== 0,
    });
    return res;
};

const companion = (res, resultsItem) => {
    if (!resultsItem?.blockGuestManagement) {
        res.push({ name: "edit-companion", modal_title: "edit-companion" });
        res.push({ name: "delete-companion", action_values: resultsItem.actionValues });
    }
    return res;
};

const room = (res, value) => {
    res.push({
        name: "view-detail",
        noModal: true,
        link: `/hotel/rooms/room/detail/${value}`,
    });
    res.push({
        name: "room-group-assign",
    });
    return res;
};

const fontLibrary = (res) => {
    // TODO: zip file
    // res.push({ name: "media-library-download" });
    res.push({ name: "font-library-delete-permanently" });
    return res;
};

const mediaLibrary = (res, resultsItem) => {
    if (resultsItem) {
        if (resultsItem.type === "folder") {
            res.push({
                name: "rename",
                noModal: true,
                action: "edit-media-library-folder",
            });
            res.push({
                name: "media-library-move-content",
                action: "media-library-move-content",
            });
            res.push({ name: "media-library-delete-permanently" });
        } else {
            res.push({
                name: "rename",
                noModal: true,
                action: "edit-media-library-media",
            });
            res.push({
                name: "media-library-move-content",
                action: "media-library-move-content",
            });
            res.push({ name: "media-library-download" });
            res.push({ name: "media-library-delete-permanently" });
        }
    }
    return res;
};

const propertyGroup = (res, resultsItem) => {
    res.push({ name: "edit-group", action: "edit-property-group" });
    res.push({
        name: "duplicate-group",
        action: "duplicate-property-group",
    });
    res.push({
        name: "delete-group",
        action: "delete-property-group",
        disable: resultsItem.hasUsers,
        tooltip: resultsItem.hasUsers ? "group-assigned-user" : null,
    });
    return res;
};

const template = (res) => {
    res.push({ name: "edit-name", action: "edit-template-name", modal_title: "edit-name" });

    res.push({
        name: "delete-template",
        action: "delete-template",
    });
    return res;
};

const message = (res, value, resultItem, t) => {
    switch (value) {
        case SENT_MSG.value:
        case DRAFTS_MSG.value:
            if (!resultItem?.information?.orderID) {
                res.push({
                    name: "save-as-template",
                    action: "save-as-template",
                    modal_title: "save-as-template",
                    executeSectionQuery: "save-as-template",
                });
            }
            break;
        // res.push({ name: "edit-message", action: "edit-message", modal_title: "edit-message" });
        // break;
        case SCHEDULED_MSG.value:
            const hasAvailableRecipent = resultItem?.recipients?.length > 0;
            res.push({
                name: "edit-message",
                noModal: true,
                link: `/customers/messages/scheduled/${resultItem.id}`,
            });
            res.push({ name: "edit-schedule", noModal: true, customModal: true, data: resultItem });
            res.push({
                name: "send-now-schedule-message",
                disable:
                    (resultItem.scheduleType == PERIODIC_SEND &&
                        resultItem?.expirationTime &&
                        dateBeforNow(new window.ZDate(resultItem?.expirationTime))) ||
                    !hasAvailableRecipent,
                noModal: true,
                customModal: true,
                data: resultItem,
            });
            res.push({ name: "delete-scheduled-message", noModal: true, customModal: true, data: resultItem });
            break;
        case TEMPLATES_MSG.value:
            res.push({
                name: "delete-permanently",
                modal_title: "delete-permanently",
                action: "delete-permanently",
            });
            break;
        default:
            break;
    }
    if (value !== TEMPLATES_MSG.value && value != SCHEDULED_MSG.value) {
        res.push({
            name: "delete-message",
            modal_title: "delete-message",
            action: "delete-message",
        });
    }
    return res;
};

const design = (res, resultsItem, value) => {
    const hasChainModule = Session.isCorporate();
    if (resultsItem.isCorporateAccess) {
        res.push({
            name: "duplicate-design",
        });
        if (resultsItem.type === "CORPORATE") {
            res.push({
                name: "edit-design",
            });
        }
        res.push({
            name: "delete-design",
        });
        if (resultsItem.type === "CORPORATE") {
            res.push({
                name: "edit-screen",
                noModal: true,
                link: `/design/${value}`,
            });
        }
    } else {
        if (resultsItem.type && resultsItem.type === "LOCAL") {
            res.push({
                name: "edit-design",
            });
            res.push({
                name: "delete-design",
            });
            if (resultsItem.isAssigned && resultsItem.isAssigned === true) {
                res.push({
                    name: "unapply-design",
                });
            }
        }
        res.push({
            disable: !hasChainModule && resultsItem.type === "CORPORATE",
            name: "duplicate-design",
        });
        res.push({
            name: resultsItem.type === "CORPORATE" ? "see screens" : "edit-screens",
            noModal: true,
            link: `/design/${value}`,
        });
    }
    return res;
};

const shop = (res, resultsItem) => {
    if (resultsItem?.syncId) {
        return res;
    }
    res.push(
        {
            name: "edit-shop",
            action: "edit-shop",
            link: `/services/sales/shop/products/${resultsItem.id}`,
        },
        {
            name: "edit-name",
            noModal: true,
            onClickCallback: () => {
                resultsItem.openModalEditName();
            },
        }
    );
    if (resultsItem.available) {
        res.push({
            name: "set-as-unavailable",
            noModal: true,
            onClickCallback: () => {
                resultsItem.openModalModifyState();
            },
        });
    } else {
        res.push({
            name: "set-as-available",
            noModal: true,
            onClickCallback: () => {
                resultsItem.openModalModifyState();
            },
        });
    }
    res.push({
        name: "delete",
        noModal: true,
        onClickCallback: () => {
            resultsItem.openModalDeleteShop();
        },
    });
    return res;
};

const categorySales = (res, resultsItem) => {
    res.push({
        name:
            resultsItem.mainLevelCategory === 0 && resultsItem.levelCategory === 1
                ? "edit-category"
                : "edit-subcategory",
        action: "edit-category",
        link: `/services/sales/shop/categories/${resultsItem.idShop}/edit-category/${resultsItem.id}`,
    });
    if (resultsItem.available) {
        res.push({
            name: "set-as-unavailable",
            noModal: true,
            onClickCallback: () => {
                resultsItem.openModalModifyCategoryState();
            },
        });
    } else {
        res.push({
            name: "set-as-available",
            noModal: true,
            onClickCallback: () => {
                resultsItem.openModalModifyCategoryState();
            },
        });
    }
    res.push({
        name: "delete",
        noModal: true,
        onClickCallback: () => {
            resultsItem.openModalDeleteCategory();
        },
    });
    return res;
};

const products = (res, resultsItem) => {
    res.push({
        name: "edit-product",
        action: "edit-product",
        link: `/services/sales/shop/${resultsItem.shopId}/product/${resultsItem.id}`,
    });
    if (resultsItem.available) {
        res.push({
            name: "set-as-unavailable",
            noModal: true,
            onClickCallback: () => {
                resultsItem.openModalModifyStateProduct();
            },
        });
    } else {
        res.push({
            name: "set-as-available",
            noModal: true,
            onClickCallback: () => {
                resultsItem.openModalModifyStateProduct();
            },
        });
    }
    res.push({
        name: "delete",
        action: "delete-product",
        noModal: true,
        onClickCallback: () => {
            resultsItem.openModalDeleteProduct();
        },
    });
    return res;
};

const inputGroup = (res, resultsItem) => {
    res.push({
        name: "edit",
        action: "edit-input-group",
        modal_title: "edit",
    });
    if (!resultsItem.isDefault) {
        res.push({
            name: "manage-tvs-assigned",
            action: "manage-tvs-assigned",
            modal_title: "manage-tvs-assigned",
            modal_value: resultsItem.name,
        });
        res.push({
            name: "delete",
            action: "delete-tvInput",
            executeSectionQuery: "delete-tvInput",
            modal_title: "delete",
            modal_value: resultsItem.name,
        });
    }
    return res;
};

export const GetAvailableActions = (type, value, resultsItem, t) => {
    //Returns available options for this result type & value
    let res = [];
    const superUser = Session.isSuperUser();
    if (type === "schedule") {
        res = scheduleType(res, value);
    } else if (type === "schedule-wizard") {
        res = scheduleWizard(res, value);
    } else if (type === "gridChannel") {
        res = gridChannel(res, resultsItem);
    } else if (type === "corporateChannel") {
        res = corporateChannel(res, resultsItem);
    } else if (type === "area") {
        res = area(res, resultsItem, value);
    } else if (type === "staff-member") {
        res = staffMember(res);
    } else if (type === "guest-currents") {
        res = guestCurrent(res, resultsItem, value);
    } else if (type === "guest-upcoming-check-ins") {
        res = guestFuture(res, value, resultsItem, t);
    } else if (type === "user") {
        res = user(res, resultsItem);
    } else if (type === "role") {
        res = role(res, resultsItem);
    } else if (type === "room") {
        res = room(res, value);
    } else if (type === "font-library") {
        res = fontLibrary(res);
    } else if (type === "media-library") {
        res = mediaLibrary(res, resultsItem);
    } else if (type === "propertyGroup") {
        res = propertyGroup(res, resultsItem);
    } else if (type === "template") {
        res = template(res);
    } else if (type === "message") {
        res = message(res, value, resultsItem, t);
    } else if (type === "design") {
        res = design(res, resultsItem, value);
    } else if (type === "alarm") {
        res.push(
            { name: "edit", action: "edit-alarm" },
            { name: "delete", action: "delete-alarm", executeSectionQuery: "delete-alarm" }
        );
    } else if (type === "shop") {
        res = shop(res, resultsItem);
    } else if (type === "categorySales") {
        res = categorySales(res, resultsItem);
    } else if (type === "products") {
        res = products(res, resultsItem);
    } else if (type === "inputGroup") {
        res = inputGroup(res, resultsItem);
    } else if (type === "companion") {
        res = companion(res, resultsItem);
    } else if (type === "channel") {
        res.push(
            {
                name: "set-up-channel",
                action: "edit-channel",
                link: `/services/tv-channels/channel/${resultsItem.id}`,
            },
            {
                name: "delete",
                action: "delete-channel",
                modal_title: capitalizeFirst(i18next.t("delete")),
                modal_value: resultsItem.name,
            }
        );
    } else if (type === "device") {
        res = deviceAction(res, resultsItem, superUser);
    }
    return res;
};

export default GetAvailableActions;
