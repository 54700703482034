import React from "react";
import { useTranslation } from "react-i18next";

export const UseUpcomingCheckInsStatus = ({ cellData, colIndex, rowIndex }) => {
    const { t } = useTranslation();
    const { style, value } = cellData?.value || {};
    return (
        <p id={`r${rowIndex}-c${colIndex}-status`} className={style}>
            {t(value)}
        </p>
    );
};
