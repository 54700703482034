import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWizardStep } from "actions/sectionActions";

const WizardSteps = () => {
    //Store data
    const dispatch = useDispatch();
    const { langStrings } = useSelector((state) => state.ui);
    const { currentStep, stepsData, periodicFilter } = useSelector((state) => state.sectionContent);
    //Data
    let progressBarState = "";
    if (stepsData) {
        progressBarState = `${currentStep - 1}/${stepsData.length - 1}`;
    }

    useEffect(() => {
        if (periodicFilter === "special-schedule") {
            dispatch(setWizardStep(2));
        } else if (periodicFilter === "common-schedule") {
            dispatch(setWizardStep(1));
        } else if (periodicFilter === "closed-days") {
            dispatch(setWizardStep(3));
        }
    }, [periodicFilter]);

    return (
        <>
            {stepsData.length > 0 ? (
                <div className="w-8/12 mx-auto pt-16 mb-12">
                    <div className="wizard-steps-container">
                        <div className="wizard-progress-container">
                            <div className="relative">
                                <div className="wizard-progress-bg"></div>
                                <div className={`wizard-progress-blue w-${progressBarState}`}></div>
                            </div>
                        </div>
                        {stepsData.map((stepData, index) => (
                            <div className="relative" key={index}>
                                <div
                                    className={`wizard-step-name ${
                                        currentStep === index + 1
                                            ? "text-blue-200"
                                            : currentStep < index + 1
                                            ? "text-gray-600"
                                            : "text-gray-900"
                                    } first-capital`}
                                >
                                    {langStrings[stepData.title]}
                                </div>
                                <div className="block relative -ml-6">
                                    <div
                                        className={`wizard-step-number ${
                                            currentStep >= index + 1 ? "bg-blue-200" : "bg-gray-400"
                                        }`}
                                    >
                                        {index + 1}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                langStrings["wizard-error"]
            )}
        </>
    );
};

export default WizardSteps;
