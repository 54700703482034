import React from "react";

const KibanaDashboard = React.forwardRef((props, ref) => {
    // Kibana share options
    const config = {
        url: null,
        filterTime: false,
        filterBar: false,
        filterQuery: false,
        menu: false,
        ...props,
    };

    const iframeURL = config.url
        ? config.url +
          "?embed=true" +
          (config.filterBar ? "" : "&hide-filter-bar=true") +
          (config.filterTime ? "&show-time-filter=true" : "") +
          (config.filterQuery ? "&show-query-input=true" : "") +
          (config.menu ? "&show-top-menu=true" : "")
        : null;

    return iframeURL ? (
        <iframe
            title="kibanaFrame"
            ref={ref}
            src={iframeURL}
            height="100%"
            width="100%"
            style={props.style}
            className={props.className}
        ></iframe>
    ) : null;
});

export default KibanaDashboard;
