import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { STATUS, printOptions } from "../../../../../../hooks/Utils/Design/SalesUtils";
import { useDispatch, useSelector } from "react-redux";
import { setSectionContent } from "../../../../../../actions/sectionActions";
import { capitalizeT } from "../../../../../../hooks/Utils/Utils";

const orderKeys = { colors: 1, border: 2 };
const selectedUnderLine = "text-blue-300 border-b-2 border-blue-300 font-bold";

const UseStatuses = ({ sectionData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const statusOptions = Object.keys(sectionData);
    const defaultSelected = statusOptions && statusOptions.includes(STATUS.DEFAULT) ? STATUS.DEFAULT : statusOptions[0];

    const { styleInputData } = useSelector((state) => state.sectionContent);
    const { cDevice } = useSelector((state) => state.design);
    const [selected, setSelected] = useState(defaultSelected);

    const selectedData = sectionData[selected];

    useEffect(() => {
        setStatusesInStore();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setStatusesInStore();
        // eslint-disable-next-line
    }, [selected, sectionData]);

    const setStatusesInStore = () => {
        dispatch(
            setSectionContent({
                styleInputData: {
                    ...styleInputData,
                    key: { ...styleInputData?.key, input: "status", status: selected },
                    value: null,
                },
            })
        );
    };

    const printStatus = (state, enable) => {
        if (cDevice.type === "Mobile" && !enable) {
            return null;
        }
        return (
            <div
                id={`status-${state}`}
                key={`status-${state}`}
                className={`w-1/3 py-4 ${!enable ? "text-gray-500" : "cursor-pointer"}  ${
                    selected === state ? selectedUnderLine : ""
                }`}
                onClick={() => {
                    if (enable) {
                        setSelected(state);
                    }
                }}
            >
                {capitalizeT(state, t)}
            </div>
        );
    };

    if (!selected) {
        return null;
    }

    return (
        <>
            <div className="inline-flex w-full text-center text-gray-800">
                {Object.keys(STATUS).map((keyState) => {
                    if (cDevice.type === "TV" && keyState === "DISABLED") {
                        return null;
                    }

                    return printStatus(STATUS[keyState], statusOptions && statusOptions.includes(STATUS[keyState]));
                })}
            </div>
            <div className="">
                {Object.keys(selectedData)
                    .sort((a, b) => orderKeys[a] - orderKeys[b])
                    .map((objectKey) => {
                        const key = objectKey;
                        const value = selectedData[objectKey];
                        return printOptions(key, value, key);
                    })}
            </div>
        </>
    );
};

export default UseStatuses;
