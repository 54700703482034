import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseInputTimeSpecialRange from "../Schedule/useInputTimeSpecialRange";
import UseInputWeekDaysSpecialSchedule from "../Schedule/useInputWeekDaysSpecialSchedule";
//Actions
import { addContainerScheduleRow } from "../../actions/scheduleActions";

const UseSpecialSRangeRow = () => {
    //Store data
    const { specialScheduleRows, specialRowScheduleContainer } = useSelector((state) => state.schedules);

    //States
    const [rowAmount, setRowAmount] = useState(specialScheduleRows);
    const [rowLooper, setRowLooper] = useState([""]);
    //Validation states

    //Actions
    const dispatch = useDispatch();

    //Listeners
    useEffect(() => {
        //Validation
        setRowAmount(specialScheduleRows);
        if (specialScheduleRows > 1) {
            dispatch(
                addContainerScheduleRow({
                    morningStart: false,
                    morningEnd: false,
                    afternoonStart: false,
                    afternoonEnd: false,
                    MSvalidated: true,
                    MEvalidated: true,
                    ASvalidated: true,
                    AEvalidated: true,
                    WDvalidated: true,
                    weekdays: [],
                })
            );
        }
        // eslint-disable-next-line
    }, [specialScheduleRows]);

    useEffect(() => {
        setRowLooper(getRowLooper(rowAmount));
    }, [rowAmount]);

    return (
        <>
            {rowLooper.map((emptyString, index) => (
                <div className="w-full flex justify-start" key={index}>
                    <div className="inline-block py-4 w-6/12">
                        <UseInputTimeSpecialRange
                            dateId="morningStart"
                            validation={specialRowScheduleContainer[index].MSvalidated}
                            index={index}
                        />{" "}
                        -{" "}
                        <UseInputTimeSpecialRange
                            dateId="morningEnd"
                            validation={specialRowScheduleContainer[index].MEvalidated}
                            index={index}
                        />{" "}
                        /{" "}
                        <UseInputTimeSpecialRange
                            dateId="afternoonStart"
                            validation={specialRowScheduleContainer[index].ASvalidated}
                            index={index}
                        />{" "}
                        -{" "}
                        <UseInputTimeSpecialRange
                            dateId="afternoonEnd"
                            validation={specialRowScheduleContainer[index].AEvalidated}
                            index={index}
                        />
                    </div>
                    <div className="inline-block flex items-center w-6/12">
                        <UseInputWeekDaysSpecialSchedule
                            validation={specialRowScheduleContainer[index].WDvalidated}
                            index={index}
                        />
                    </div>
                </div>
            ))}
        </>
    );
};

export const getRowLooper = (rowAmount) => {
    //Returns object to iterate through and add form rows
    let res = [];
    for (let i = 1; i <= rowAmount; i++) {
        res.push("");
    }
    return res;
};

export const isValidated = (scheduleFromValidation, scheduleToValidation, weekDaysValidation) => {
    let res;
    if (scheduleFromValidation && scheduleToValidation && weekDaysValidation) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export default UseSpecialSRangeRow;
