import InputGroupAssignedTvs from "../modals/InputGroupAssignedTvs";
import InputGroupMakeDefault from "../modals/InputGroupMakeDefault";

const InputGroup = (inputGroup, t) => {
    const { id, name, inputs, tvsAssigned, isDefault } = inputGroup;
    const dataType = "inputGroup";
    const inputLabels = [];

    const getColorInput = (input) => {
        if (input?.includes("HDMI")) {
            return "bg-blue-300";
        } else if (input?.includes("USB")) {
            return "bg-blue-100";
        }
        //bluetooth
        return "bg-blue-400";
    };

    inputs.map((input, index) => {
        if (input.input) {
            inputLabels.push([
                index,
                input.input === "BLUETOOTH" ? "Bluetooth" : input.input,
                getColorInput(input.input),
            ]);
        }
    });

    return {
        id: id,
        dataType: dataType,
        adjust: "h-auto",
        isDefault: isDefault,
        name: name,
        tvsAssigned: tvsAssigned,
        executeSectionQuery: true,
        t: t,
        actions: [{ type: dataType, value: null }],
        // mutationParams: { id: id, name: name },
        info: [
            {
                cellContent: "text",
                value: name,
                specialWidth: "w-3/12",
            },

            {
                cellContent: "labelsTruncate",
                value: inputLabels,
                specialWidth: "w-4/12",
                cellAdjust: "truncate labels",
            },
            tvsAssigned > 0
                ? {
                      cellContent: "edit-modal",
                      value: tvsAssigned,
                      contentAdjust: "cursor-pointer text-zafiro-600",
                      specialWidth: "w-2/12",
                      isAction: true,
                      modal: InputGroupAssignedTvs(t, name, id, isDefault, tvsAssigned),
                  }
                : {
                      cellContent: "text",
                      value: 0,
                      specialWidth: "w-2/12",
                  },
            isDefault
                ? {
                      name: "isDefault",
                      cellContent: "labels",
                      value: [[1, "default", "#47A1FF"]],
                      isAction: false,
                      specialWidth: "w-3/12",
                  }
                : {
                      name: "make-input-group-default",
                      cellContent: "edit-modal",
                      contentAdjust: "cursor-pointer text-zafiro-600",
                      value: "make-default",
                      translate: true,
                      type: dataType,
                      evaluate: true,
                      isAction: true,
                      modal: InputGroupMakeDefault(t, name, id),
                      specialWidth: "w-3/12",
                  },
            {
                cellContent: "data",
                inputGroup: inputGroup,
            },
        ],
    };
};

export default InputGroup;
