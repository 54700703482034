import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import md5 from "md5";

import { clearProjectRef } from "actions/hotelActions";
import { useLogin, useImpersonate } from "hooks/Data/useUser";

import { useLoading } from "components/Loading";

import { GlobalContext } from "contexts/Global";

/**
 * This hook involves the global authentication process
 */
export const useAuth = (props) => {
    const {
        token,
        lang,
        setLang,
        user,
        chain,
        projects,
        isLogged,
        isImpersonating,
        isChainLogged,
        isProjectLogged,
        loginUser,
        logoutUser,
        impersonateUser,
        stopImpersonation,
        loginChain,
        logoutChain,
        loginProject,
        logoutProject,
        isVersionUpdated,
        loginV3,
        permissions,
        languages,
        defaultLanguage,
        paths,
        project,
        warnings,
        setReleaseNotesAsRead,
        userAdvicedUpdate,
    } = useContext(GlobalContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { setLoading } = useLoading();
    const [loginCallbacks, setLoginCallbacks] = useState(null);
    const [impersonateCallbacks, setImpersonateCallbacks] = useState(null);
    const [credentials, setCredentials] = useState(null);
    const [impersonate, setImpersonate] = useState(null);

    const userInitials =
        user?.fullname
            .split(" ")
            .map((n) => n?.[0])
            .slice(0, 2) // Get the first two initials only
            .join("") || "?";

    const { load: getImpersonate, loading: impersonateLoading } = useImpersonate({
        lang,
        onSuccess: (data) => {
            const loginData = {
                user: {
                    id: data?.userID,
                    ref: data?.userRef,
                    fullname: data?.userFullname,
                    superUser: data?.isSuperuser,
                    email: impersonate?.email,
                    role: data?.userManagerRole,
                    corporate: data?.hasCorporateUser,
                    tos: data?.userTOSAccepted,
                    userAdvicedUpdate: data?.userAdvicedUpdate,
                    warnings: data?.warnings,
                },
                chain: {
                    id: data?.chainId,
                    name: data?.chainName,
                    ref: data?.chainRef,
                    app: data?.chainAppURL,
                    manager: data?.cloudManagerURL,
                    logo: data?.managerLogo,
                },
                projects: getProjects(data?.projects, data?.hasCorporateUser, t),
            };
            impersonateUser(loginData, data?.token);
            if (impersonateCallbacks?.onSuccess) {
                impersonateCallbacks.onSuccess();
            }
        },
        onError: (error) => {
            if (error) {
                console.warn(error);
                if (impersonateCallbacks?.onError) {
                    impersonateCallbacks.onError(error);
                }
            }
        },
    });

    const { login, loading: logging } = useLogin({
        lang,
        onSuccess: (data) => {
            const loginData = {
                user: {
                    id: data?.userID,
                    ref: data?.userRef,
                    pass: credentials?.pass, //TODO remove this when all works correctly
                    fullname: data?.userFullname,
                    superUser: data?.isSuperuser,
                    email: credentials?.email,
                    role: data?.userManagerRole,
                    corporate: data?.hasCorporateUser,
                    tos: data?.userTOSAccepted,
                    userAdvicedUpdate: data?.userAdvicedUpdate,
                    warnings: data?.warnings,
                },
                chain: {
                    id: data?.chainId,
                    name: data?.chainName,
                    ref: data?.chainRef,
                    app: data?.chainAppURL,
                    manager: data?.cloudManagerURL,
                    logo: data?.managerLogo,
                },
                projects: getProjects(data?.projects, data?.hasCorporateUser, t),
            };
            loginUser(loginData, data?.token);
        },
        onError: (error) => {
            if (error) {
                console.error(error);
                if (loginCallbacks?.onError) {
                    loginCallbacks.onError(error);
                }
            }
        },
    });

    useEffect(() => {
        setLoading(logging || impersonateLoading);
    }, [logging, impersonateLoading]);

    useEffect(() => {
        if (credentials) {
            login({ user: credentials?.email, pass: credentials?.pass });
        }
    }, [credentials]);

    useEffect(() => {
        if (impersonate) {
            getImpersonate(impersonate?.ref);
        }
    }, [impersonate]);

    return {
        token,
        lang,
        setLang,
        user,
        chain,
        project,
        projects,
        isCorporate: project?.ref === "CORPORATE",
        hasChainModule: project?.hasChainModule,
        hasCorporate: user?.corporate,
        isSuperUser: user?.superUser,
        userInitials,
        isLogged,
        isImpersonating,
        isChainLogged,
        isProjectLogged,
        loginChain,
        logoutChain,
        loginProject,
        logoutProject,
        loginV3,
        permissions,
        languages,
        defaultLanguage,
        isVersionUpdated,
        paths,
        warnings,
        setReleaseNotesAsRead,
        userAdvicedUpdate,
        logout: () => {
            logoutUser();
            dispatch(clearProjectRef());
        },
        login: ({ user, pass, onError }) => {
            setCredentials({ email: user, pass: md5(pass) });
            setLoginCallbacks({ onError });
        },
        impersonate: ({ ref, email, onSuccess, onError }) => {
            setImpersonate({ ref, email });
            setImpersonateCallbacks({ onSuccess, onError });
        },
        stopImpersonation: () => {
            stopImpersonation();
            dispatch(clearProjectRef());
        },
    };
};

const getProjects = (projects, hasCorporateUser, t) => {
    projects.sort((a, b) => String(a.name).localeCompare(String(b.name)));
    //CORPORATE PROJECT
    if (hasCorporateUser) {
        projects.unshift({
            name: t("global-management"),
            id: 0,
            location: t("global-management-desc"),
            ref: "CORPORATE",
            code: "CORPORATE",
            supportEmail: "zafiro@zafiro.tv",
            supportPhone: "1234",
            timeZone: "UTC",
            global: true,
        });
    }
    return projects;
};
