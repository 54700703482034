import React from "react";
import Button from "components/Button";
import { useModal } from "components/Modal";
import { useDeleteRoomGroups } from "hooks/GraphqlCalls/Hotel/Rooms/useDeleteRoomGroups";
import { useTranslation } from "react-i18next";

export const DeleteGroupModal = ({ group, refetch, setLoadingRefresh, type }) => {
    const { deleteGroup } = useDeleteRoomGroups({ group, refetch, setLoadingRefresh, type });
    const { t } = useTranslation();
    const { close } = useModal();

    return (
        <>
            <div className="mt-10">
                <p>{t("room-group-delete")}</p>
                <footer className="flex w-full gap-4 mt-10 justify-end">
                    <Button id="cancel" design="white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button id="alert-accept" design="red" onClick={deleteGroup}>
                        {t("delete")}
                    </Button>
                </footer>
            </div>
        </>
    );
};
