import { Session } from "../../../Utils/Session";
import { timer } from "../utils/timer";

const Casts = (data) => {
    const isSuperUser = Session.isSuperUser();
    let networks = [
        {
            id: "castwifi",
            title: "wifi-cast",
            icon: "wificast",
            adjust: `${isSuperUser ? "col-span-2" : "col-span-3"}`,
            fields: [
                {
                    id: "castwifiname",
                    type: "text",
                    name: "wifi-name",
                    value: data.castwifi.name,
                },
                {
                    id: "castwifipass",
                    type: "text",
                    name: "password",
                    value: data.castwifi.password,
                },
                {
                    id: "castwifihidden",
                    type: "checkbox",
                    enabled: false,
                    name: "hidden",
                    value: data.castwifi.hidden,
                },
                {
                    id: "castwifiauth",
                    type: "select",
                    name: "authentication",
                    enabled: false,
                    useActionInput: true,
                    data: [
                        { label: "WEP", value: "WEP" },
                        { label: "WPA", value: "WPA" },
                        { label: "WPA2", value: "WPA2" },
                    ],
                    activeValue: data.castwifi.auth,
                },
            ],
            save: false,
        },
        {
            id: "ccdailyreboot",
            title: "chromecast-daily-reboot",
            icon: "refresh-cw",
            adjust: `${isSuperUser ? "col-span-2" : "col-span-3"}`,
            rebootEnabled: data.ccdailyreboot.enable,
            fields: [
                {
                    id: "ccdailyrebootstatus",
                    type: "checkbox",
                    name: "automatic-device-restart",
                    value: data.ccdailyreboot.enable,
                },
                {
                    id: "ccdailyrebootstime",
                    type: "select",
                    name: "reset-hour",
                    containerOptionsAdjust: "overflow-y-auto max-h-64",
                    enabled: data.ccdailyreboot.enable,
                    data: timer(),
                    noTranslateAV: true,
                    useActionInput: true,
                    activeValue: arrangeCcDailyRebootsTime({ data }),
                },
            ],
            save: true,
        },
        {
            id: "reportccstatus",
            title: "chromecast-report-status",
            icon: "messaging-widget",
            adjust: `col-span-2`,
            fields: [
                {
                    id: "castreportstatus",
                    type: "checkbox",
                    enabled: true,
                    name: "send-report-cc",
                    value: data.ccemailnotify.emailNotify,
                },
            ],
            save: true,
        },
        {
            id: "permanentsocket",
            title: "permanent-socket",
            description: "permanent-socket-tooltip",
            icon: "chromecast",
            adjust: `col-span-2`,
            fields: [
                {
                    id: "castpermanentsocket",
                    type: "checkbox",
                    enabled: true,
                    name: "permanent-socket-text",
                    value: data.ccpairing.socket,
                },
            ],
            save: true,
        },
        {
            id: "cclowsignal",
            title: "low-signal-warning",
            icon: "warning-outline",
            adjust: `col-span-2`,
            fields: [
                {
                    id: "lowSignalMessage",
                    type: "checkbox",
                    enabled: true,
                    name: "enable-low-signal-warning",
                    value: data.cclowsignal.lowSignalMessage,
                },
            ],
            save: true,
        },
    ];
    return networks;
};

const arrangeCcDailyRebootsTime = (props) => {
    const { data } = props;
    let response = null;
    if (data.ccdailyreboot && data.ccdailyreboot.rebootTime) {
        if (data.ccdailyreboot.rebootTime.length >= 5) {
            response = data.ccdailyreboot.rebootTime.substring(0, 5);
        } else {
            response = data.ccdailyreboot.rebootTime;
        }
    }
    return response;
};

export default Casts;
