import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const UseCurrentFilterTag = ({ name, color, value, valueText, translatable }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { checkboxDropdownNames } = useSelector((state) => state.ui);

    //States
    const [isActive, setIsActive] = useState(false);

    //Listeners
    useEffect(() => {
        if (
            checkboxDropdownNames &&
            checkboxDropdownNames[name] &&
            checkboxDropdownNames[name].length > 0 &&
            checkboxDropdownNames[name].includes(value)
        ) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
        // eslint-disable-next-line
    }, [checkboxDropdownNames]);
    const getTranslations = () => {
        let _translation = "";
        if (Array.isArray(valueText)) {
            valueText.forEach((_word) => (_translation += langStrings[_word] ? langStrings[_word] : _word));
        } else _translation = langStrings[valueText] ? langStrings[valueText] : valueText;
        return _translation;
    };
    return (
        <div
            className={`h-8 text-white p-2 py-1 rounded z-50 inline-block whitespace-no-wrap ${
                isActive ? "block" : "hidden"
            }`}
            style={{ backgroundColor: color }}
        >
            <div className="px-2 mb-2 inline-block cursor-pointer first-capital whitespace-normal">{`${
                translatable ? getTranslations() : valueText
            } x`}</div>
        </div>
    );
};

export default UseCurrentFilterTag;
