const Hotspot = (data, macData) => {
    const { link, name } = data;

    const macsNumber = macData.length + " MAC";
    let macTooltip = "";

    macData.forEach((mac) => {
        macTooltip += `${mac.mac} <br> `;
    });

    const dataType = "hotspot";

    return {
        dataType: dataType,
        adjust: "h-auto",
        id: name,
        actions: [{ type: dataType, value: name }],
        totalMacs: macData.length,
        executeSectionQuery: true,
        info: [
            {
                name: "name",
                value: name,
                cellContent: "text",
                specialWidth: "w-4/12",
            },
            {
                name: "link",
                value: link,
                cellContent: "text",
                specialWidth: "w-4/12 ml-5",
            },
            {
                name: "macException",
                value: macsNumber,
                forceShowTooltip: true,
                adjust: "text-zafiro-600 font-bold",
                tooltipData: macTooltip,
                cellContent: "textWithTooltip",
                specialWidth: "w-1/12 ml-5",
            },
            {
                name: "empty",
                value: "",
                cellContent: "text",
                specialWidth: "w-3/12",
            },
        ],
    };
};

export default Hotspot;
