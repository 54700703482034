import React from "react";

//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table";

import ListTags from "../../../hooks/GraphqlCalls/Design/ListTags";
import UpdateTag from "../../../hooks/GraphqlCalls/Design/UpdateTag";

const Tags = () => {
    //Actions

    ListTags();
    return (
        <>
            <UpdateTag />
            <UseSectionHeader title={["list-of", "tags"]} />
            <UseTable />
        </>
    );
};

export default Tags;
