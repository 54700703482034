import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-apollo";
import { gql } from "apollo-boost";
import Switch from "components/Switch";
import TextInput from "components/TextInput";
import Button from "components/Button";
import Modal, { useModal } from "components/Modal";
import Loading from "components/Loading";
import ErrorInfo from "components/ErrorInfo";
import { toast } from "react-toastify";

const UPDATE_PMS_CHARGES_SETTINGS = gql`
    mutation updatePMSChargesSettings($enableCharges: Boolean!, $chargeId: String!, $chargeDescription: String!) {
        updatePMSChargesSettings(
            enableCharges: $enableCharges
            chargeId: $chargeId
            chargeDescription: $chargeDescription
        ) {
            ok
            error
        }
    }
`;

const usePMSConfigUpdate = () => {
    const [updatePMSChargesSettings, { data, loading, error }] = useMutation(UPDATE_PMS_CHARGES_SETTINGS);

    const update = ({ id, enabled, description }) => {
        updatePMSChargesSettings({
            variables: {
                enableCharges: enabled,
                chargeId: id,
                chargeDescription: description,
            },
        });
    };

    return {
        update,
        loading,
        error,
        success: data?.updatePMSChargesSettings?.ok,
    };
};

const MoviesPMSModal = forwardRef(({ id, values, onSuccess, onLoading, onSave }, ref) => {
    const { t } = useTranslation();

    const { close } = useModal();

    const inputIDRef = useRef(null);

    const { update, loading, error, success } = usePMSConfigUpdate();

    const [inputValues, setInputValues] = useState({
        enabled: values?.enabled || false,
        id: values?.id || "",
        description: values?.description || "",
    });

    const updateSettings = () => {
        if (!loading) {
            const isValid = !inputValues?.enabled || (inputValues?.id && inputValues?.description);
            if (isValid) {
                update({
                    id: inputValues.id,
                    enabled: inputValues.enabled,
                    description: inputValues.description,
                });
            }
        }
    };

    useImperativeHandle(ref, () => ({
        submit: () => {
            updateSettings();
        },
    }));

    useEffect(() => {
        if (inputIDRef.current && inputValues?.enabled) {
            inputIDRef.current.focus();
        }
    }, [inputValues?.enabled]);

    useEffect(() => {
        if (onLoading) {
            onLoading(loading);
        }
    }, [loading]);

    useEffect(() => {
        if (success && onSuccess) {
            onSuccess();
        }
    }, [success]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <ErrorInfo>{error}</ErrorInfo>;
    }

    const handleSave = () => {
        if (inputValues?.enabled && (!inputValues?.id || !inputValues?.description)) {
            toast.error(t("input error"));
            return;
        }

        if (onSave) {
            onSave(inputValues);
        }

        updateSettings();

        close();
    };

    return (
        <div className="text-justify px-4">
            <div className="px-4 py-4">
                <div className="mb-5 flex items-center space-x-2">
                    <span>{t("pms-charges-enable-switch")}</span>
                    <Switch
                        id="pms-charges-enable"
                        checked={inputValues.enabled}
                        className="my-2"
                        onChange={({ checked }) => {
                            setInputValues({ ...inputValues, enabled: checked });
                        }}
                    />
                </div>

                <div className="mb-5">{t("pms-charges-edit-description-movies")}</div>

                <div className="mb-3">
                    <div className="font-bold">{t("PMS charge ID")}*</div>
                    <div className="mt-1">
                        <TextInput
                            ref={inputIDRef}
                            id="pms-charge-id"
                            required
                            placeholder={t("example something", { something: "111" })}
                            disabled={!inputValues.enabled}
                            value={inputValues.id}
                            onChange={(value) => setInputValues({ ...inputValues, id: value })}
                        />
                    </div>
                </div>
                <div>
                    <div className="font-bold">{t("PMS charge description")}*</div>
                    <div className="mt-1">
                        <TextInput
                            id="pms-charge-description"
                            required
                            placeholder={t("example something", { something: t("room-service") })}
                            disabled={!inputValues.enabled}
                            value={inputValues.description}
                            onChange={(value) => setInputValues({ ...inputValues, description: value })}
                        />
                    </div>
                </div>
                <div id="modal-buttons" className="pt-8 text-center space-x-3 flex justify-end">
                    <Button design="black" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-save" onClick={handleSave}>
                        {t("save")}
                    </Button>
                </div>
            </div>
        </div>
    );
});

export default MoviesPMSModal;
