import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";

import SelectFilter from "../../SelectFilter";

import NotFound from "../NotFound";
import translate from "../../../lang/es/hotel/translate";

const GET_PROPERTY_GROUPS = gql`
    {
        brands(page: 1, size: 100) {
            results {
                ref
                name
                projects {
                    ref
                    name
                    hasChainModule
                }
            }
        }
        properties {
            results {
                ref
                name
                hasChainModule
            }
        }
    }
`;

const FilterProject = ({ onChange }) => {
    const { t } = useTranslation();

    const [executeQuery, { data, loading, error }] = useLazyQuery(GET_PROPERTY_GROUPS);
    const hasGroups = data && data.brands && data.brands.results && data.brands.results.length > 0;
    const hasProperties =
        data &&
        data.properties &&
        data.properties.results &&
        data.properties.results?.filter((p) => p?.hasChainModule)?.length > 0;
    const [hasChanges, setHasChanges] = useState(false);
    const [references, setReferences] = useState([]);

    const getSelectItems = () => {
        let groups = [];
        if (hasGroups) {
            groups.push({
                name: t("property-groups"),
                items: data.brands.results.map((option) => {
                    return {
                        value: {
                            type: "group",
                            ref: option.ref,
                            name: option.name,
                            projects: option.projects?.filter((p) => p?.hasChainModule),
                        },
                        name: option.name,
                        color: "orange-100",
                    };
                }),
            });
        }
        if (hasProperties) {
            groups.push({
                name: t("properties"),
                items: data.properties.results
                    ?.filter((p) => p?.hasChainModule)
                    ?.map((option) => {
                        return {
                            value: {
                                type: "project",
                                ref: option.ref,
                                name: option.name,
                            },
                            name: option.name,
                            color: "teal-500",
                        };
                    }),
            });
        }
        return groups;
    };

    const Select = SelectFilter({
        multiple: true,
        search: true,
        placeHolder: t("global-management-desc"),
        getItems: getSelectItems,
    });

    useEffect(() => {
        executeQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (onChange) {
            onChange(references);
        }
        // eslint-disable-next-line
    }, [references]);

    useEffect(() => {
        if (!Select.isOpen && hasChanges) {
            setHasChanges(false);
            let references = [];
            if (Select.value) {
                // eslint-disable-next-line
                Select.value.map((i) => {
                    const item = i.value;
                    if (item.type === "project") {
                        references.push(item.ref);
                    } else if (item.projects && item.projects.length > 0) {
                        // eslint-disable-next-line
                        item.projects.map((p) => {
                            references.push(p.ref);
                        });
                    }
                });
            }
            setReferences(references);
        }
        // eslint-disable-next-line
    }, [Select.isOpen, hasChanges]);

    useEffect(() => {
        setHasChanges(true);
    }, [Select.value]);

    return loading ? null : error ? (
        <NotFound title={translate("error-mutation")} desc="" />
    ) : (
        <>
            <div className="inline-block mr-3">{t("{{text, capitalize}}", { text: t("filter-by") })}:</div>
            <Select.Component style={{ minWidth: "18rem" }} />
        </>
    );
};

export default FilterProject;
