import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    cleanTableStore,
    setCount,
    setCountPosition,
    setError,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    setCustomError,
    setRefreshData,
    setActions,
    setHeaderButtons,
    setCheckable,
    setAvailableFilters,
    setActiveFilters,
    getFilterQuery,
    setTargetId,
    setPage,
    setTableType,
} from "../../../actions/tableActions";
//Utils
import { Session } from "../../Utils/Session";
import GetTableHeaders from "../../Utils/Table/Headers/GetDesignTableHeaders";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
//Model
import Design from "./models/Design";
import { useTranslation } from "react-i18next";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import { useMSQuery } from "../useMSQuery";
import { useAuth } from "hooks/Session/auth";

const ListDesigns = () => {
    const { t } = useTranslation();

    const { chain } = useAuth();

    const { currentPage, perPage, filterQuery, sortCriteria, refreshData, targetId, searchString } = useSelector(
        (state) => state.table
    );
    const { langStrings } = useSelector((state) => state.ui);

    //Data
    const sectionName = "list-designs";

    //States
    const [sorting] = useState(`,orderBy:{field:"name",criteria:"asc"}`);
    const [chainName, setChainName] = useState(chain?.name);

    //Actions
    const dispatch = useDispatch();

    //Variables.
    const isCorporateAccess = Session.isCorporate();
    const currentUserRef = Session.getUser()?.ref;

    //API
    const GET_DESIGNS_INFO = gql`
        {designs(page: ${currentPage} ${
        targetId ? `targetColumn:{column:"id", value:"${targetId}"}` : ""
    }, size: ${perPage}${sortCriteria || sorting}${filterQuery}){
                info {
                    page
                    count
                }
                results {
                    visibleAll
                    assignedAll
                    assignedAllProject{
                        name
                        ref
                        type
                    }
                    visibleAllProject{
                        name
                        ref
                        type
                    }
                    lastUpdatedUserInfo{
                        name
                        ref
                    }
                    creatorProject{
                        name
                    }
                    isAssignedFromCorporate
                    assignedDate
                    author
                    id
                    isAssigned
                    isEnabled
                    lastUpdatedTime
                    name
                    parentDesignID
                    status
                    type
                    themeID
                }
            }
            brands{
                results{
                    id
                    name
                    ref
                    projects {
                        hasChainModule
                    }
                }
            }
            ${
                !chainName
                    ? `
            chain{
                name, ref                
            }
              `
                    : ``
            }
            designsLocalAssigned: designs(filter:{isAssigned:true}) {
                results {
                    id
                    isAssigned
                    isAssignedFromCorporate
                    lastUpdatedTime
                    type
                    themeID
                }
            }
        }
    `;

    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_DESIGNS_INFO, {
        pollInterval: 60000,
    });

    //Listeners
    useEffect(() => {
        dispatch(cleanTableStore());
        Session.removeSessionProp("brands");
        //set initial filter
        if (isCorporateAccess) {
            dispatch(setActiveFilters("isCorporate", "true"));
            dispatch(
                getFilterQuery({
                    isCorporate: "true",
                })
            );
        }
        executeQuery();
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData || targetId) {
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData, targetId]);

    useEffect(() => {
        if (!loading && !error && data && data.designs && networkStatus === 7) {
            if (data?.brands?.results) {
                Session.setSessionProp("brands", JSON.stringify(data.brands.results));
            }
            if (data && data.chain && data.chain && data.chain.name) {
                setChainName(data.chain.name);
            }
            const dataItems = arrangeData(data, langStrings);
            const tableHeaders = GetTableHeaders(sectionName, isCorporateAccess);

            if (isCorporateAccess) {
                const availableFilters = GetAvailableFilters(sectionName);
                dispatch(setAvailableFilters(availableFilters));
            }
            // dispatch(showGlobalLoading(false));
            if (targetId && data.designs.info.page) {
                dispatch(setPage(data.designs.info.page));
                dispatch(setTargetId(null));
            }
            Session.setSessionProp("designList", JSON.stringify(data.designs.results));
            dispatch(setSearchable(true));
            dispatch(setHeaders(tableHeaders));
            dispatch(setCheckable(false));
            dispatch(setTableType(sectionName));
            dispatch(setHeaderButtons(GetTableHeaderButton(sectionName, undefined, isCorporateAccess, t)));
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.designs.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(showRowForm(false));
            dispatch(setActions());
            dispatch(setCustomError(searchString !== null ? t("no-results-found") : t("no designs available")));
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data, networkStatus, sortCriteria]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
        }
        // eslint-disable-next-line
    }, [error]);
    useEffect(() => {
        dispatch(setLoading(loading));
        // eslint-disable-next-line
    }, [loading]);
};

const arrangeData = (data, langStrings) => {
    const designData = [];
    let designsLocalAssigned =
        data.designsLocalAssigned && data.designsLocalAssigned.results && data.designsLocalAssigned.results.length > 0
            ? data.designsLocalAssigned.results
            : null;
    // eslint-disable-next-line
    data.designs.results.map((design) => {
        designData.push(Design(design, designsLocalAssigned, langStrings));
    });
    return designData;
};

export default ListDesigns;
