import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "components/ReactTooltip";
import UseSearch from "../../../useSearch";
import { mapReasonToDestination } from "../../../../hooks/Utils/Customers/MessagesUtils";
import { TYPENAME_GRAPHQL } from "../../../../hooks/Utils/Utils";

const MAIL_OPEN = "icon-mail-open";
const MAIL_UNREAD = "icon-mail-unread";

export const Recipients = ({ recipients, destinations, guests, inEdition }) => {
    const { t } = useTranslation();
    const [recipientsFiltered, setRecipientsFiltered] = useState([]);
    const [search, setSearch] = useState(null);
    const [destinationNameTooltip, setDestinationNameTooltip] = useState("");
    const checkRead = {
        read: recipients.reduce((previousValue, currentValue) => {
            if (currentValue.isRead) {
                previousValue++;
            }
            return previousValue;
        }, 0),
        total: recipients.length,
    };

    const hasString = (text, search) => {
        return text.toLowerCase().trim().includes(search.toLowerCase().trim());
    };

    useEffect(() => {
        if (search) {
            setRecipientsFiltered(
                recipients.filter(
                    (recipient) =>
                        hasString(`${recipient.stayGuestName} ${recipient.stayGuestSurname}`, search) ||
                        hasString(recipient.roomName, search)
                )
            );
        } else {
            setRecipientsFiltered(recipients);
        }
        ReactTooltip.rebuild();
        // eslint-disable-next-line
    }, [search]);

    useEffect(() => {
        setRecipientsFiltered(recipients);
        ReactTooltip.rebuild();
    }, [recipients]);

    // search handler
    const inputSearchChange = (change) => {
        if (change.target.value && change.target.value !== undefined) {
            setSearch(change.target.value);
        } else if (change.target.value === undefined || change.target.value === "") {
            setSearch(null);
        }
    };

    const getDestinationNameTooltip = (roomName, reason) => {
        let _arrayReasons = [];
        const guestsIDs = guests.map((val) => val.id);
        const reasonKeys = Object.keys(reason);
        //remove __typename from array
        reasonKeys.forEach((key) => {
            if (key === "all" && reason[key]) {
                _arrayReasons.push(key);
            } else if (key !== "all" && key !== "countryRefs" && reason[key].length > 0 && key !== TYPENAME_GRAPHQL) {
                reason[key].forEach((reasonElement) => {
                    if (key == "stayGuestIDs" && guestsIDs.includes(reasonElement)) {
                        _arrayReasons.push(`${t("guest")}(${t("guest-checked-out")})`);
                    } else {
                        _arrayReasons.push(
                            destinations[mapReasonToDestination(key)].filter(
                                (destination) => destination.id === reasonElement || destination.ref === reasonElement
                            )[0].name
                        );
                    }
                });
            } else if (key === "countryRefs" && reason[key].length > 0) {
                reason[key].forEach((reasonElement) => {
                    _arrayReasons.push(
                        t(
                            "country:" +
                                destinations[mapReasonToDestination(key)]
                                    .filter((destination) => destination.ref === reasonElement)[0]
                                    .ref.toLowerCase()
                        )
                    );
                });
            }
        });
        //setDestinationNameTooltip(_arrayReasons.join(", "));
        setDestinationNameTooltip(
            `<div>
                <div class="first-capital font-bold">${t("filter")}</div>
                ${_arrayReasons.map((reason) => `<div>${reason}</div>`)}

            </div>`
        );
        ReactTooltip.rebuild();
    };

    const printRecipient = (recipient, index) => {
        return (
            <div key={index} className="mx-2 inline-flex border-b border-gray-200 py-2" id={`recipient_${index}`}>
                <div className="w-4/12 pr-2 my-auto">
                    <div
                        className={`rounded py-1 px-4 m-1 inline-block text-white  bg-blue-300 truncate cursor-default`}
                        style={{ maxWidth: "100%" }}
                        data-tip={destinationNameTooltip}
                        onMouseOver={() => getDestinationNameTooltip(recipient.roomName, recipient.reason)}
                    >
                        {recipient.roomName}
                    </div>
                </div>
                <div
                    className={`${!inEdition ? "w-7/12" : "w-8/12"} my-auto ${
                        recipient.checkedout ? "text-gray-500" : ""
                    }`}
                >
                    <div className="leading-10 truncate">
                        {!recipient.checkedout
                            ? `${recipient.stayGuestName} ${recipient.stayGuestSurname}`
                            : `${t("{{capitalize, capitalize}}", { capitalize: t("checkout") })}`}
                    </div>
                </div>
                {!inEdition ? (
                    <div className="w-1/12 text-3xl my-auto">
                        <span
                            className={`${recipient.isRead ? MAIL_OPEN : MAIL_UNREAD}`}
                            data-tip={t("{{capitalize, capitalize}}", {
                                capitalize: t(recipient.isRead ? "readed-message" : "unreaded-message"),
                            })}
                        ></span>
                    </div>
                ) : null}
            </div>
        );
    };

    return (
        <>
            <div className="mt-8 flex">
                <div className="w-1/3 leading-10">
                    {inEdition
                        ? `${checkRead.total} ${t("{{capitalize, capitalize}}", { capitalize: t("selected") })}`
                        : `${checkRead.read}/${checkRead.total} ${t("{{capitalize, capitalize}}", {
                              capitalize: t("read"),
                          })}`}
                </div>
                <div className="w-2/3 ml-10">
                    <UseSearch handleChangeSearch={inputSearchChange} />
                </div>
            </div>
            <div
                id="recipients_show"
                className="grid grid-cols-1 grid-flow-row overflow-y-auto mt-4 rounded border border-gray-200"
                style={{
                    height: "50vh",
                    gridAutoRows: "min-content",
                }}
            >
                {recipientsFiltered.map((recipient, index) => printRecipient(recipient, index))}
            </div>
            <ReactTooltip
                place="bottom"
                type="light"
                offset={{ top: -8, left: -8 }}
                border={true}
                borderColor="#D3DAE1"
                dangerousHtml={true}
            />
        </>
    );
};
