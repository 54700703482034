import React, { useState, useEffect } from "react";
import { NavLink, matchPath, useLocation } from "react-router-dom";
import { useShowPrivacyPolicy, useShowReleaseNotes } from "./../Header/IconInfo";
import { useTranslation } from "react-i18next";

const MenuSideBar = ({ menuItems }) => {
    const location = useLocation();
    const { t } = useTranslation();

    // isCurrentPath checks if the current path is the same as the path or any of the altPaths
    const isCurrentPath = (path, altPaths) => {
        const firstMatch = path
            ? matchPath(
                  {
                      path,
                      end: false,
                      strict: false,
                  },
                  location.pathname
              )
            : false;
        if (firstMatch) {
            return true;
        }
        if (altPaths?.length > 0) {
            for (const altPath of altPaths) {
                if (altPath && matchPath({ path: altPath, end: false, strict: false }, location.pathname)) {
                    return true;
                }
            }
        }
        return false;
    };

    // activeItem is the first menu item that is active relative to the current pathname
    const activeItem =
        menuItems?.find((item) => {
            if (isCurrentPath(item.to, item.redirectOptions)) {
                return true;
            }
            if (item?.menu?.length > 0) {
                return item.menu.some((subitem) => isCurrentPath(subitem.to, subitem.redirectOptions));
            }
            return false;
        })?.name || null;

    // openedDropdown is the dropdown menu that is open (not necessarily active)
    const [openedDropdown, setOpenedDropdown] = useState(activeItem);

    useEffect(() => {
        // If the active item changes, reset the opened dropdown
        setOpenedDropdown(activeItem);
    }, [menuItems]);

    if (!menuItems?.length > 0) {
        return null;
    }
    return (
        <ul className="sidebar-menu">
            {menuItems.map((item) => {
                if (!item) return null;
                const hasSubmenu = item.menu?.length > 0;
                return (
                    <li
                        key={item.id}
                        className={`${hasSubmenu ? "submenu" : ""} ${item.icon ? "with-icon" : ""} ${
                            item.division ? "divider" : ""
                        }`}
                    >
                        {hasSubmenu ? (
                            <Dropdown
                                {...{
                                    id: item.id,
                                    name: t(item.name),
                                    icon: item.icon,
                                    rightIcon: item.rightIcon,
                                    redirectOptions: item.redirectOptions,
                                    isActive: activeItem === item.name,
                                    isOpen: openedDropdown === item.name,
                                    toggle: () => {
                                        setOpenedDropdown(openedDropdown === item.name ? null : item.name);
                                    },
                                }}
                            >
                                <ul
                                    id={`${item.name}-submenu`}
                                    className={openedDropdown === item.name ? "" : "hidden"}
                                >
                                    {item.menu.map((subitem) => {
                                        return subitem ? (
                                            <li key={subitem.id}>
                                                <Link
                                                    {...{
                                                        id: `submenu-link-${subitem.id}`,
                                                        name: t(subitem.name),
                                                        to: subitem.to,
                                                        href: subitem.external,
                                                        action: subitem.action,
                                                        icon: subitem.icon,
                                                        rightIcon:
                                                            subitem.rightIcon ||
                                                            (subitem.external ? "icon-external-link text-xl" : null),
                                                        redirectOptions: subitem.redirectOptions,
                                                    }}
                                                />
                                            </li>
                                        ) : null;
                                    })}
                                </ul>
                            </Dropdown>
                        ) : (
                            <Link
                                {...{
                                    id: item.id,
                                    name: t(item.name),
                                    to: item.to,
                                    href: item.external,
                                    action: item.action,
                                    icon: item.icon,
                                    rightIcon: item.rightIcon,
                                    redirectOptions: item.redirectOptions,
                                }}
                            />
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

const Dropdown = ({ children, id, name, icon, rightIcon, isActive, toggle, isOpen }) => {
    return (
        <>
            <button id={`${id}-item-with-submenus`} className={isActive ? " active" : null} onClick={toggle}>
                <Item
                    icon={icon}
                    rightIcon={rightIcon || `icon-chevron animated ${isOpen ? "-rotate-180" : ""} text-gray-900`}
                >
                    {name}
                </Item>
            </button>
            {children}
        </>
    );
};

const Link = ({ id, to, action, href, name, icon, rightIcon, redirectOptions, className }) => {
    const location = useLocation();

    const { show: showPrivacyPolicy } = useShowPrivacyPolicy();
    const { showReleaseNotes } = useShowReleaseNotes();

    const runAction = (name) => {
        switch (name) {
            case "show-privacy-modal":
                showPrivacyPolicy();
                break;
            case "show-release-notes-modal":
                showReleaseNotes();
                break;
            default:
                break;
        }
    };

    if (action) {
        return (
            <button id={id} className={className || ""} onClick={() => runAction(action)}>
                <Item icon={icon} rightIcon={rightIcon}>
                    {name}
                </Item>
            </button>
        );
    }

    if (href) {
        return (
            <a id={id} className={className || ""} href={href} target="_blank" rel="noopener noreferrer">
                <Item icon={icon} rightIcon={rightIcon}>
                    {name}
                </Item>
            </a>
        );
    }

    return (
        <NavLink
            to={to}
            id={id}
            className={({ isActive }) =>
                `${className || ""} ${
                    redirectOptions?.includes(location.pathname) || location?.pathname?.includes(to) || isActive
                        ? "active"
                        : ""
                }`
            }
        >
            <Item icon={icon} rightIcon={rightIcon}>
                {name}
            </Item>
        </NavLink>
    );
};

const Item = ({ children, icon, rightIcon }) => {
    return (
        <>
            <div className="flex items-center w-full justify-between">
                <div className="flex items-center">
                    {icon ? <i className={`left-icon ${icon}`}></i> : null}
                    <span className="inline-block first-capital">{children}</span>
                </div>
            </div>
            {rightIcon ? <i className={`right-icon ${rightIcon}`}></i> : null}
        </>
    );
};

export default MenuSideBar;
