import React from "react";
import { useParams } from "react-router-dom";

import SalesProvider from "contexts/Sales";
import ShopProvider from "contexts/Sales/Shop";
import MonitorProvider from "contexts/Sales/Monitor";

import OrderMonitor from "./OrderMonitor";

const OrderMonitorContainer = () => {
    const { shopId } = useParams();
    const isGeneralMonitor = shopId === "all";
    return (
        <SalesProvider>
            {isGeneralMonitor ? (
                <MonitorProvider>
                    <OrderMonitor />
                </MonitorProvider>
            ) : (
                <ShopProvider id={shopId}>
                    <MonitorProvider>
                        <OrderMonitor />
                    </MonitorProvider>
                </ShopProvider>
            )}
        </SalesProvider>
    );
};

export default OrderMonitorContainer;
