import { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import { showGlobalLoading } from "../../../actions/uiActions";
import {
    cleanTableStore,
    setCount,
    setCountPosition,
    setError,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    cleanActiveFilters,
    setCustomError,
    setRefreshData,
    setActions,
    setHeaderButtons,
    setCheckable,
    setAvailableFilters,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetDesignTableHeaders";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import { useMSQuery } from "../useMSQuery";

//Model
import Template from "./models/Template";
import { useTranslation } from "react-i18next";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";

import { GlobalContext } from "contexts/Global";

const ListTemplates = () => {
    const { t } = useTranslation();

    const { lang } = useContext(GlobalContext);

    const { currentPage, perPage, filterQuery, sortCriteria, refreshData } = useSelector((state) => state.table);
    const { langStrings } = useSelector((state) => state.ui);

    //Data
    const sectionName = "list-templates";

    //States
    const [sorting] = useState(`,orderBy:{field:"name",criteria:"asc"}`);

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_TEMPLATES = gql`
        {
            templates( page: ${currentPage}, size: ${perPage}${sortCriteria || sorting}${filterQuery} 
                lang:"${lang}", render:{audio:false, autoplay:false}
                ) {
                info{
                    count
                }
                results{
                    name
                    nameTID
                    id
                    type
                    isEnabled
                    devices {
                        name
                        orientation
                        type
                    }
                }
            }
        }
    `;

    const GET_FILTERS_DATA = gql`
        {
            templates {
                results {
                    type
                }
            }
        }
    `;

    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_TEMPLATES, {
        pollInterval: 60000,
    });
    const [executeFiltersQuery, filtersData] = useLazyQuery(GET_FILTERS_DATA);

    //Listeners
    useEffect(() => {
        dispatch(setLoading(false));
        dispatch(showGlobalLoading(true));
        dispatch(cleanTableStore());
        executeQuery();
        executeFiltersQuery();
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(showGlobalLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        dispatch(setLoading(false));
        if (!loading && !error && data && data.templates && networkStatus === 7) {
            const dataItems = arrangeData(data, langStrings);
            const tableHeaders = GetTableHeaders(sectionName);
            dispatch(showGlobalLoading(false));
            dispatch(setSearchable(true));
            dispatch(setHeaders(tableHeaders));
            dispatch(setCheckable(false));
            dispatch(setHeaderButtons(GetTableHeaderButton(sectionName)));
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.templates.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(showRowForm(false));
            dispatch(setActions());
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            dispatch(setCustomError(t("no available(general)", { name: t("templates") })));
        }
        // eslint-disable-next-line
    }, [data, networkStatus, sortCriteria]);

    useEffect(() => {
        if (!filtersData.error && !filtersData.loading && filtersData.data && filtersData.data.templates) {
            if (filtersData.data.templates) {
                const availableFilters = GetAvailableFilters(sectionName, extractTypesList(filtersData.data.templates));
                dispatch(setAvailableFilters(availableFilters));
            }
        }
        //eslint-disable-next-line
    }, [filtersData]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
        }
        if (filtersData.error) {
            dispatch(setError(filtersData.error));
        }
        // eslint-disable-next-line
    }, [error, filtersData]);
};

const arrangeData = (data, langStrings) => {
    const templates = [];

    // eslint-disable-next-line
    data.templates.results.map((template) => {
        templates.push(Template(template, langStrings));
    });
    return templates;
};

const extractTypesList = (data) => {
    let typesArray = [];
    data.results.map((template) =>
        typesArray.filter((type) => type.value === template.type).length === 0
            ? typesArray.push({
                  value: template.type,
                  id: template.type,
                  visible: true,
              })
            : null
    );

    typesArray.sort(function (a, b) {
        return a[0] < b[0];
    });

    return typesArray;
};

export default ListTemplates;
