import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import UseSelectOptionCell from "./useSelectOptionCell";
import useComponentVisible from "../../useComponentVisible";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { setModalContent, openModal } from "../../../actions/uiActions";
import { cleanAction, setActionName, setActionItemAffected, setActionInput } from "../../../actions/globalActions";
//Actions
import { setLoading } from "../../../actions/tableActions";
import { useTranslation } from "react-i18next";

const UseSelectCell = ({ cellData }) => {
    const { t } = useTranslation();
    //Store data
    const { itemsAffected, actionData, actionName } = useSelector((state) => state.action);

    //Data
    const { id, name, selected, values } = cellData || {};

    //Change style due to state
    const [selectActive, setSelectActive] = useState({});
    let activeValue = `${values[0].name}-${values[0].itemType}-${selected}`;
    const [newActiveValue, setNewActiveValue] = useState();
    const [valueChanged, setValueChanged] = useState(false);
    const [canSelect, setCanSelect] = useState(actionName !== "new-area-booking");
    const actionType = "cancel-booking";
    const bookingModify = "booking-modify";

    //Get starting style
    const initialStyle = () => {
        // eslint-disable-next-line
        const selOption = values.filter((option) => parseInt(option.value) === parseInt(selected));
        return selOption ? selOption.style : null;
    };

    const [currentStyle, setCurrentStyle] = useState(initialStyle());
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const selectClickHandler = (e) => {
        e.stopPropagation();
        const dataToAction = {
            [e.currentTarget.dataset.name]: e.currentTarget.dataset.value,
        };
        dispatch(setActionInput(dataToAction));
        dispatch(setActionItemAffected([id.toString()]));
        dispatch(setActionName(bookingModify));
    };

    const dispatch = useDispatch();

    useEffect(() => {
        setCanSelect(actionName !== "new-area-booking");
    }, [actionName]);

    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({});
        } else {
            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold",
            });
        }
    }, [isComponentVisible]);

    useEffect(() => {
        if (activeValue === "state-bookings-2" || activeValue === "state-bookings-3") {
            setCurrentStyle("fail");
        } else if (activeValue === "state-bookings-1") {
            setCurrentStyle("success");
        } else {
            setCurrentStyle("");
        }
    }, [activeValue]);

    useEffect(() => {
        setNewActiveValue(activeValue);
        // eslint-disable-next-line
    }, []);

    const CHANGE_BOOKING_STATUS = gql`
        ${getMutationString(itemsAffected, actionData)}
    `;

    const [executeMutation, { data }] = useMutation(CHANGE_BOOKING_STATUS);

    //Actions
    const clickHandler = (e) => {
        e.stopPropagation();
        setIsComponentVisible(false);
        setCurrentStyle(e.currentTarget.dataset.style);
        activeValue = `${values[0].name}-${values[0].itemType}-${e.currentTarget.dataset.value}`;
        setNewActiveValue(activeValue);
        activeValue = null;
        setValueChanged(true);
        selectClickHandler(e);
    };

    useEffect(() => {
        if (
            actionData &&
            actionData.state &&
            itemsAffected &&
            itemsAffected[0] &&
            parseInt(itemsAffected[0]) === parseInt(cellData?.id)
        ) {
            if (actionData.state !== "2") {
                dispatch(setLoading(true));
                executeMutation();
                dispatch(cleanAction());
            } else {
                dispatch(setActionName(actionType));
                dispatch(setActionItemAffected([String(id)]));
                dispatch(setModalContent(getUpdateStatusModal(actionData, itemsAffected, actionType)));
                dispatch(openModal(true));
            }
        }
        // eslint-disable-next-line
    }, [actionData]);

    useEffect(() => {
        dispatch(setLoading(false));
        if (data && data.updateBooking && data.updateBooking.error === "") {
            toast.success(t("status of booking modified"));
        } else if (data && data.updateBooking && data.updateBooking.error !== "") {
            toast.error(t("mutation-error"));
        }
        // eslint-disable-next-line
    }, [data]);
    return (
        <div onClick={(e) => e.stopPropagation()} className="flex items-top">
            <div
                ref={ref}
                className={`t-filter-input short ${selectActive.container}${
                    currentStyle === "fail" ? " bg-transparent " : ""
                }`}
            >
                {currentStyle !== "fail" ? (
                    <div
                        className={`t-filter-ph ${selectActive.text}`}
                        onClick={() => setIsComponentVisible(!isComponentVisible && canSelect ? true : false)}
                    >
                        <div className={`first-capital ${currentStyle}`}>
                            {!activeValue && !newActiveValue ? t(name) : null}
                            {valueChanged ? t(newActiveValue) : t(activeValue)}
                        </div>
                    </div>
                ) : (
                    <div className={`${currentStyle} pl-4 cursor-default first-capital`}>{t("state-bookings-2")}</div>
                )}
                {currentStyle !== "fail" ? (
                    <span
                        className={`field-icon icon-chevron animated ${selectActive.icon}`}
                        onClick={() => setIsComponentVisible(!isComponentVisible && canSelect ? true : false)}
                    ></span>
                ) : null}
                {isComponentVisible ? (
                    <div className="t-options-container p-2">
                        {values.map((option, index) => (
                            <div
                                className="h-10 hover:bg-gray-100"
                                onClick={clickHandler}
                                id={option}
                                key={index}
                                data-style={option.style}
                                data-name={option.name}
                                data-value={option.value}
                            >
                                <UseSelectOptionCell data={option} selected={selected} setSelectActive={clickHandler} />
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export const getMutationString = (itemsAffected, actionData) => {
    let res = `mutation {updateBooking(id:`;
    if (itemsAffected) {
        res += `${Number(itemsAffected[0])} `;
    } else {
        res += `0`;
    }
    res += ` status:`;
    if (actionData && actionData.state) {
        res += `${Number(actionData.state)}`;
    } else {
        res += `100`;
    }
    res += `){ error id okBooking okEmail}}`;
    return res;
};

export const getUpdateStatusModal = (actionData, itemsAffected, actionType) => {
    return {
        title: "cancel-booking-title",
        id: Number(itemsAffected[0]),
        inputs: [
            {
                name: "cancel-booking-comment",
                type: "text-area",
                text: "cancel-booking-comment",
                charLimit: 250,
                selected: null,
            },
        ],
        buttons: [
            {
                name: "cancel",
                style: "white",
                action: "close&reload",
            },
            {
                name: "save",
                style: "blue",
                action: actionType,
            },
        ],
    };
};

export default UseSelectCell;
