import React from "react";

import Input from "./Input";
import Settings from "./Settings";

const SettingsBorder = ({ t, tab, deviceSpecific = true, toolbarID, title, onlyRadius, noRadius }) => {
    if (!t) t = (label) => label;
    const isRegular = !tab || tab === "regular";
    const borderColor = isRegular ? "borderColor" : tab + "BorderColor";
    const borderWidth = isRegular ? "borderWidth" : tab + "BorderWidth";
    const radius = !noRadius && isRegular ? "radius" : null;
    return (
        <Settings toolbarID={toolbarID}>
            {onlyRadius ? null : (
                <Settings.Group title={t("border")}>
                    <Input.Color id={borderColor} title={t("color")} />
                    <Input.Number
                        id={borderWidth}
                        deviceSpecific={deviceSpecific}
                        title={t("width")}
                        min="0"
                        max="30"
                    />
                </Settings.Group>
            )}
            {radius ? (
                <Settings.Group title={title ? t(title) : t("corners")}>
                    <Input.Number
                        id={radius}
                        deviceSpecific={deviceSpecific}
                        title={t("radius")}
                        min="0"
                        max="5"
                        step="0.1"
                        placeholder="1.0"
                    />
                </Settings.Group>
            ) : null}
        </Settings>
    );
};

export default SettingsBorder;
