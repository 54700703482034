import React from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { capitalizeFirst } from "hooks/Utils/Utils";
import HTMLReactParser from "html-react-parser";

const ModalLinkedApps = (props) => {
    const { t } = useTranslation();

    const { data: dataApps = [], appearanceName } = props;

    const globalApps = dataApps.filter((app) => app.isMultiProperty).map((app) => app.name);
    const propertyApps = dataApps
        .filter((app) => !app.isMultiProperty)
        .map((app) => ({
            appName: app.name,
            projectNames: app.projects.map((project) => project.name),
        }));

    return (
        <Modal
            id="modal-linked-apps"
            title={t("linked-apps")}
            footer={
                <>
                    <Button design="blue" id="modal-button-accept" onClick={() => props?.close()}>
                        {t("accept")}
                    </Button>
                </>
            }
            className={"w-4/12 p-10"}
        >
            <div>
                <p>
                    {HTMLReactParser(
                        t("the-appearance-is-applied-in-the-following-apps", { appearance: appearanceName })
                    )}
                </p>
                <div style={{ minHeight: "20rem", maxHeight: "20rem", overflowY: "scroll" }}>
                    {globalApps?.length > 0 ? (
                        <div className=" w-full flex pb-5 mt-4">
                            <div className=" w-1/2 font-bold py-2 ">{t("global-apps")}</div>
                            <div className=" w-1/2 ">
                                {globalApps.map((app) => {
                                    return <div className=" py-2">{app}</div>;
                                })}
                            </div>
                        </div>
                    ) : null}
                    {propertyApps?.length > 0 ? (
                        <>
                            <div className=" w-full py-2 font-bold ">{t("property-apps")}</div>
                            <div className=" w-full flex py-2 border-b border-gray-200 ">
                                <div className=" w-1/2 font-bold ">{t("property")}</div>
                                <div className=" w-1/2 font-bold ">{capitalizeFirst(t("app"))}</div>
                            </div>
                            {propertyApps.map((app) => {
                                return (
                                    <div className=" w-full flex border-b py-2 border-gray-200 ">
                                        <div className=" w-1/2  ">
                                            {app.projectNames.map((project) => {
                                                return <div>{project}</div>;
                                            })}
                                        </div>
                                        <div className=" w-1/2 ">{app?.appName}</div>
                                    </div>
                                );
                            })}
                        </>
                    ) : null}
                </div>
            </div>
        </Modal>
    );
};

const useShowLinkedApps = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalLinkedApps {...newProps} />);
        },
    };
};

export default useShowLinkedApps;
