import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showGlobalLoading } from "../../../actions/uiActions";
import { setSectionContent, setRefreshContentData } from "../../../actions/sectionActions";
import { gql } from "apollo-boost";
import { useMSQuery } from "../useMSQuery";
import { useLibraryParams } from "../../../components/Section/MediaLibrary/MediaLibrary";

const GetFont = () => {
    const dispatch = useDispatch();
    const { listFonts, refreshData } = useSelector((state) => state.sectionContent);

    const { fontRef } = useLibraryParams();

    // mutation
    const LOAD_FONT = gql`
        query Fonts($ref: String!) {
            libraryFont(ref: $ref) {
                response {
                    contentType
                    items
                    lastModified
                    name
                    ref
                    size
                    styles {
                        contentType
                        lastModified
                        name
                        size
                        style
                    }
                }
            }
        }
    `;

    // execute mutation
    const [getFont, { data }] = useMSQuery(LOAD_FONT, {
        fetchPolicy: "network-only",
    });

    // Listeners
    useEffect(() => {
        dispatch(showGlobalLoading(true));
        let _index = null;
        let _fontSelected = null;
        if (listFonts && listFonts.length > 0) {
            // eslint-disable-next-line
            _fontSelected = listFonts.find((font, index) => {
                if (font.ref === fontRef) {
                    _index = index;
                    return font;
                }
            });
        }
        dispatch(setSectionContent({ fontSelected: _fontSelected, fontIndex: _index }));
        dispatch(showGlobalLoading(false));
        // eslint-disable-next-line
    }, [fontRef]);

    useEffect(() => {
        if (refreshData) {
            getFont({ variables: { ref: fontRef } });
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (data && data.libraryFont && data.libraryFont && data.libraryFont.response) {
            dispatch(setRefreshContentData(false));
            dispatch(showGlobalLoading(false));
            dispatch(setSectionContent({ fontSelected: { ...data.libraryFont.response } }));
        }
        // eslint-disable-next-line
    }, [data]);
};

export default GetFont;
