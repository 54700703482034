import Button from "components/Button";
import Icon from "components/Icon";
import Modal, { useModal } from "components/Modal";
import { AccessTypesOptions } from "components/Section/Hotel/AccessTypesOptions";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDeleteAccessProfile } from "../Monitoring/useUpdateAccessType";
import { AccessTypesContext } from "contexts/AccessTypes";

export const AccessProfiles = (
    accessProfile,
    wifiAccessTypes,
    t,
    refetch,
    setLoadingRefresh,
    passCodes,
    checkboxStates
) => {
    const { downloadbwmin, uploadbwmin, name, uploadbwmax, downloadbwmax, id } = accessProfile;

    return {
        id,
        uploadbwminForEdit: uploadbwmin,
        downloadbwminForEdit: downloadbwmin,
        uploadbwmaxForEdit: uploadbwmax,
        downloadbwmaxForEdit: downloadbwmax,
        nameForEdit: name,
        name: <p alt={name}>{name}</p>,
        bwMin: (
            <div className="flex flex-col">
                <div className="flex items-center gap-1">
                    <Icon type="maximum" size="2xl" />
                    <p>
                        {t("x mb/s", {
                            number: uploadbwmin > 0.0 ? uploadbwmin : "-",
                        })}
                    </p>
                </div>
                <div className="flex items-center gap-1">
                    <Icon type="minimum" size="2xl" />
                    <p>
                        {t("x mb/s", {
                            number: downloadbwmin > 0.0 ? downloadbwmin : "-",
                        })}
                    </p>
                </div>
            </div>
        ),
        bwMax: (
            <div className="flex flex-col">
                <div className="flex items-center gap-1">
                    <Icon type="maximum" size="2xl" />
                    <p>
                        {t("x mb/s", {
                            number: uploadbwmax > 0.0 ? uploadbwmax : "-",
                        })}
                    </p>
                </div>
                <div className="flex items-center gap-1">
                    <Icon type="minimum" size="2xl" />
                    <p>
                        {t("x mb/s", {
                            number: downloadbwmax > 0.0 ? downloadbwmax : "-",
                        })}
                    </p>
                </div>
            </div>
        ),
        accessTypes: (
            <div className="flex flex-col gap-1">
                <AccessTypesOptions
                    wifiAccessTypes={wifiAccessTypes}
                    accessProfile={accessProfile}
                    refetch={refetch}
                    setLoadingRefresh={setLoadingRefresh}
                    checkboxStates={checkboxStates}
                />
            </div>
        ),
        edit: <EditAccessProfile id={id} />,
        delete: <DeleteAccessProfile id={id} name={name} passCodes={passCodes} />,
    };
};

const EditAccessProfile = ({ id }) => {
    const { setEditing, creating } = useContext(AccessTypesContext);
    const { t } = useTranslation();

    return (
        <Button
            id={`edit-btn-${id}`}
            onClick={() => {
                if (!creating) {
                    setEditing({ editing: true, id });
                } else {
                    toast.error(t("save-before-continue"));
                }
            }}
        >
            <Icon type="edit" size="xl" />
        </Button>
    );
};

const DeleteAccessProfile = ({ id, name, passCodes }) => {
    const { t } = useTranslation();
    const { open, close } = useModal();
    const { deleteAccessProfile } = useDeleteAccessProfile();
    return (
        <Button
            id={`delete-btn-${id}`}
            onClick={() => {
                open(
                    <Modal
                        title={`${t("delete")} ${name}`}
                        className="w-2/7 p-10"
                        onCancel={close}
                        footer={
                            <footer className="flex w-full gap-4 mt-10 justify-end">
                                <Button id="cancel" className="btn-white" onClick={close}>
                                    {t("cancel")}
                                </Button>
                                <Button
                                    id="alert-accept"
                                    design="red"
                                    onClick={() => {
                                        deleteAccessProfile(id);
                                        close();
                                    }}
                                >
                                    {t("delete")}
                                </Button>
                            </footer>
                        }
                    >
                        <p className="my-8">{t("delete-access-profile-description")}</p>
                        {passCodes?.length > 0 && (
                            <>
                                <div className="flex gap-2 mb-6">
                                    <Icon type="warning" size="3xl" />
                                    <p>{t("delete-access-profile-warning")}:</p>
                                </div>
                                <div className="w-full border border-1 border-gray-200 rounded">
                                    <ul>
                                        {passCodes?.map((p, index) => (
                                            <li
                                                key={p}
                                                className={`p-4 mx-4 ${
                                                    index !== passCodes.length - 1 ? "border-b" : ""
                                                } border-gray-200`}
                                            >
                                                {p?.code}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        )}
                    </Modal>
                );
            }}
        >
            <Icon type="delete" size="xl" />
        </Button>
    );
};
