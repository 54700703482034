import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    ACTIONS_MOBILE_SETTINGS,
    MOBILE_INFORMATION_CARD,
    initialStateValidationsInformationCard,
    validateMobileInformationCard,
} from "./MobileSettingsUtils";
import ImageChannelSelector from "components/Section/Services/tvChannels/channels/ImageChannelSelector";
import UseButton from "components/useButton";

import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setExecuteQuery } from "actions/sectionActions";
import ReactTooltip from "components/ReactTooltip";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { getFromDAS } from "hooks/Utils/Utils";
import { Session } from "hooks/Utils/Session";

import { useAuth } from "hooks/Session/auth";

const MobileSettingsInformationCard = ({ setCardInEdition, cardInEdition, data }) => {
    const { t } = useTranslation();
    const { lang, isCorporate } = useAuth();
    const dispatch = useDispatch();
    const prefixId = "mobile-settings-information-card-";
    const [informationData, setInformationData] = useState({
        logoRef: "",
        domain: "",
        name: "",
        url: "",
        urlAlias: "",
    });
    const [validations, setValidations] = useState({ ...initialStateValidationsInformationCard });
    const [reset, setReset] = useState(false);
    const regexGetDomain = /^([^\.]+)/;
    const regexGetSubdomain = /^[^\.]*\.(.*)/;
    const domain = data?.url?.match(regexGetDomain)?.[0];
    const subDomain = informationData?.url?.match(regexGetSubdomain)?.[1];

    useEffect(() => {
        setInformationData({
            logoRef: data?.config?.iconAssetRef ? data?.config.iconAssetRef : "",
            name: data?.name ? data.name : "",
            url: data?.url ? data?.url : "",
            urlAlias: data?.config.urlAlias ? data?.config.urlAlias : "",
            domain: domain ? domain : "",
            id: data?.id,
            subDomain: subDomain,
        });
    }, [data, reset]);

    return (
        <div className={` rounded px-5 pb-5 pt-5 bg-white `}>
            <div className=" flex justify-between p-1 text-xl font-bold border-b border-gray-200">
                <span>{capitalizeFirst(t("information"))}</span>
                {((isCorporate && data?.isMultiProperty) || (!isCorporate && !data.isMultiProperty)) &&
                cardInEdition !== MOBILE_INFORMATION_CARD ? (
                    <button
                        id={`${prefixId}-edit-button`}
                        onClick={() => setCardInEdition(MOBILE_INFORMATION_CARD)}
                        className=" text-gray-800 icon icon-edit focus:outline-none"
                    ></button>
                ) : null}
            </div>
            {cardInEdition === MOBILE_INFORMATION_CARD ? (
                <div className="">
                    <div className=" pt-4">
                        {t(isCorporate ? "manage-the-global-app" : "manage-the-pwa-mobile-of-the-property")}
                    </div>
                    <div className=" pt-4 flex items-center ">
                        <span className=" font-bold">{t("icon-and-name")}</span>
                        <i
                            onMouseOver={() => {
                                ReactTooltip.rebuild();
                            }}
                            data-tip={t("size-recommended-1024")}
                            className=" ml-2 icon icon-info text-2xl text-gray-700"
                        ></i>
                    </div>
                    <div className=" pt-4 flex ">
                        <div>
                            <ImageChannelSelector
                                action="add-image-to-corporate-channel"
                                dataComponent={informationData}
                                setDataComponent={setInformationData}
                                adjustButtonImage={`h-10 `}
                                idCard="mobile-settings-information-card"
                                widthImage="8.9rem"
                                heightImage="8.9rem"
                                headerLogoAdjust=" hidden"
                                headerLogoText=" hidden"
                                headerIconAdjust=" hidden"
                                textButton="choose-icon"
                                containerImageAdjust=" bg-gray-100 border-gray-300 border"
                                defaultIcon="icon-no-image text-gray-400 "
                                // adjustButtonDeleteImage={`${!dataChannel?.isCorporateTvChannelLocal ? "hidden" : ""}`}
                                dispatchActionDeleteImage="delete-image-corporate-channel"
                                type="mobile"
                                number="mobile-app"
                            />
                        </div>

                        <div className=" flex flex-col w-full ml-8 justify-start">
                            <span className=" block pt-2 text-gray-800 font-bold text-sm">{t("name")}</span>
                            <input
                                className={` h-8 rounded w-full bg-gray-200 px-2 border ${
                                    validations && validations.name ? "border-transparent" : "border-red-500"
                                }`}
                                value={informationData.name}
                                onChange={(e) => {
                                    setInformationData({ ...informationData, name: e.target.value });
                                }}
                                id={`${prefixId}-input-edit-name`}
                                type="text"
                            ></input>
                        </div>
                    </div>
                    <div className=" pt-8">
                        <div>
                            <span className=" block  font-bold">{t("original-url")}</span>
                            <div className=" flex items-center mt-2">
                                <div className=" mr-2 text-gray-600">{domain && subDomain ? "https://" : ""}</div>
                                <input
                                    className={` h-8 rounded w-48 bg-gray-200 px-2 border  ${
                                        validations && validations.domain ? "border-transparent" : "border-red-500"
                                    }`}
                                    value={informationData?.domain ? informationData.domain : ""}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const domainRegex = /^[^\s!@#$%^&*()+=<>?:"{}|,.;'`~\\\/\[\]]*$/;
                                        if (domainRegex.test(value) || value === "") {
                                            setInformationData({ ...informationData, domain: value });
                                        }
                                    }}
                                    id={`${prefixId}-input-edit-url`}
                                    type="text"
                                ></input>
                                <div className=" ml-2 text-gray-600">{domain && subDomain ? subDomain : ""}</div>
                            </div>
                        </div>
                        <div>
                            <div className=" pt-4 flex items-center ">
                                <span className=" font-bold">{t("alias-url-redirection")}</span>
                                <i
                                    onMouseOver={() => {
                                        ReactTooltip.rebuild();
                                    }}
                                    data-tip={t("modify-the-url-redirected-to-your-own-url")}
                                    className=" ml-2 icon icon-info text-2xl text-gray-700"
                                ></i>
                            </div>
                            <input
                                className={` h-8 mt-2 rounded w-full bg-gray-200 px-2 border ${
                                    validations && validations.urlAlias ? "border-transparent" : "border-red-500"
                                }`}
                                id={`${prefixId}-input-edit-url-alias`}
                                value={informationData.urlAlias}
                                onChange={(e) => {
                                    setInformationData({ ...informationData, urlAlias: e.target.value });
                                }}
                                type="text"
                            ></input>
                        </div>
                    </div>
                    <div className=" flex justify-end mt-8">
                        <UseButton
                            action={() => {
                                setCardInEdition(null);
                                setValidations({ ...initialStateValidationsInformationCard });
                                setReset(true);
                            }}
                            id={`${prefixId}-cancel-edit`}
                            adjust="mr-2"
                            buttonColor="btn-white"
                            buttonName={t("cancel")}
                        />
                        <UseButton
                            action={() => {
                                const { validations, error } = validateMobileInformationCard(informationData);

                                setValidations({ ...validations });

                                if (error) {
                                    toast.error(t("input error"));

                                    if (validations["urlAlias"] === false) {
                                        toast.error(t("valid-url"));
                                    }
                                } else {
                                    dispatch(
                                        setExecuteQuery({
                                            action: ACTIONS_MOBILE_SETTINGS.UPDATE_SETTINGS_INFORMATION_CARD,
                                            params: { ...informationData, subDomain },
                                        })
                                    );
                                }
                            }}
                            id={`${prefixId}-save-edit`}
                            buttonColor="btn-blue"
                            buttonName={t("save")}
                        />
                    </div>
                </div>
            ) : (
                <div className="">
                    {isCorporate && data?.isMultiProperty && <div className=" pt-4">{t("manage-the-global-app")}</div>}
                    <div className=" pt-4 flex items-center ">
                        <span className=" font-bold">{t("icon-and-name")}</span>
                        <i
                            onMouseOver={() => {
                                ReactTooltip.rebuild();
                            }}
                            data-tip={t("size-recommended-1024")}
                            className=" ml-2 icon icon-info text-2xl text-gray-700"
                        ></i>
                    </div>
                    <div className=" pt-3 flex items-center">
                        <div className=" w-14 h-14 bg-gray-100 border border-gray-300 flex items-center justify-center rounded">
                            {!informationData.logoRef ? (
                                <i
                                    id={`${prefixId}-no-image`}
                                    className=" icon icon-no-image text-1xl text-gray-400"
                                ></i>
                            ) : (
                                <img
                                    className="h-full w-full object-scale-down"
                                    src={getFromDAS(informationData.logoRef, lang)}
                                    id={`${prefixId}-image`}
                                ></img>
                            )}
                        </div>
                        <span className=" block ml-6">{informationData.name}</span>
                    </div>
                    <div className=" pt-4 font-bold">{t("pwa-url-and-preview")}</div>
                    <div className=" flex">
                        <div className=" relative pt-3" style={{ width: "9.5rem", height: "9.5rem" }}>
                            <img
                                id={`${prefixId}-qr-image`}
                                style={{ position: "absolute", left: "1rem", top: ".4rem" }}
                                className=" object-fill h-full p-3 w-full -ml-4"
                                src={Session.getDasUrl(`qr?w=150&data=${`https://${informationData.url}`}`)}
                            ></img>
                        </div>

                        <div className=" pt-3 pl-8">
                            <div>
                                <span className=" block  font-bold">{t("original-url")}</span>
                                <div id={`${prefixId}-url-container`} className=" pt-2">
                                    <span id={`${prefixId}-url-container-https`} className=" text-gray-600">{`${
                                        domain && subDomain ? "https://" : ""
                                    }`}</span>
                                    <span id={`${prefixId}-url-domain`}>{`${domain && subDomain ? domain : ""}`}</span>
                                    <span id={`${prefixId}-url-subdomain`} className=" text-gray-600">
                                        {domain && subDomain ? `.${subDomain}` : ""}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div className=" pt-2 flex items-center ">
                                    <span className=" font-bold">{t("alias-url-redirection")}</span>
                                    <i
                                        onMouseOver={() => {
                                            ReactTooltip.rebuild();
                                        }}
                                        data-tip={t("modify-the-url-redirected-to-your-own-url")}
                                        className=" ml-2 icon icon-info text-2xl text-gray-700"
                                    ></i>
                                </div>
                                <div
                                    id={`${prefixId}-url-alias`}
                                    className=" pt-2"
                                >{`${informationData.urlAlias}`}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MobileSettingsInformationCard;
