import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getTranslationName } from "../../../../../../../../hooks/Utils/SalesUtils";
import { useTranslation } from "react-i18next";
import UseSwitch from "../../../../../../../Inputs/useSwitch";
import ExtraSingleItemEditCard from "./ExtraSingleItemEditCard";
import ExtraGroupItemEditCard from "./ExtraGroupItemEditCard";
import ReactTooltip from "components/ReactTooltip";

function ExtraItemCard({
    item,
    index,
    moveCard,
    handleDeleteItem,
    handleMoveItems,
    handleAvailableItem,
    handleEditItem,
    langDefault,
    extraData,
}) {
    //Consts&states
    const { id, opacity } = item;
    const { editingItemId, setEditingItemId } = extraData;
    const { t } = useTranslation();
    const style = { float: "left" };
    const h24 = "h-24";
    const isGroupItemsEmpty =
        item?.typeOfExtra === "group-items" &&
        (!item?.options?.choices ||
            item?.options?.choices.length === 0 ||
            item?.options?.choices.every((opt) => opt.available === false));
    const [showTooltip, setShowTooltip] = useState(false);
    //queries&mutations

    //effects

    //arrangeData

    //handle&functions
    const [{ handlerId }, drop] = useDrop({
        accept: "extraItemCard",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        drop(item) {
            setEditingItemId(null);
            handleMoveItems(item.index);
        },
        hover(item, monitor) {
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
    });
    const [, drag, preview] = useDrag({
        type: "extraItemCard",
        canDrag: !editingItemId,

        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            const didDrop = monitor.didDrop();
            if (!didDrop) {
                const dragIndex = item.index;
                const hoverIndex = index;
                moveCard(dragIndex, hoverIndex, true);
                item.index = hoverIndex;
            }
        },
    });

    //renders

    const renderEditCard = () => {
        return item.typeOfExtra === "single-item" ? (
            <ExtraSingleItemEditCard item={item} langDefault={langDefault} extraData={extraData} index={index} />
        ) : (
            <ExtraGroupItemEditCard item={item} langDefault={langDefault} extraData={extraData} index={index} />
        );
    };

    //response
    return (
        <div
            className={`w-full bg-white rounded shadow p-2 mb-4 ${editingItemId !== item.id ? h24 : null}`}
            style={{ ...style, opacity }}
            ref={preview}
            data-handler-id={handlerId}
        >
            {editingItemId !== item.id ? (
                <div className="w-full h-full flex" ref={drop}>
                    <div className="w-3/12">
                        <div className="flex items-center align-middle text-center w-full h-full border-r-2 border-gray-200 pl-3">
                            <span
                                ref={drag}
                                className={`icon icon-drag-and-drop text-gray-800 text-3xl cursor-move ${
                                    editingItemId ? "hidden" : ""
                                }`}
                            ></span>
                            <span className="text-md ml-1 truncate">
                                {getTranslationName(item.translations, langDefault)}
                            </span>
                        </div>
                    </div>
                    <div className="w-9/12">
                        <div className="flex items-center align-middle w-full h-full justify-between pl-3 pr-2">
                            <div className="">
                                <span className="font-bold block">{t(item.typeOfExtra)}</span>
                                <span className="block">
                                    {t("type-of-selection")}: {t(item.typeOfSelection)}
                                </span>
                            </div>
                            <div className="">
                                <div className="flex">
                                    <span className="flex">
                                        <span
                                            className={`${isGroupItemsEmpty ? "text-gray-600" : ""}`}
                                            data-tip={t(
                                                isGroupItemsEmpty ? "add-at-least-an-item-to-make-it-available" : ""
                                            )}
                                            onMouseEnter={() => setShowTooltip(true)}
                                            onMouseLeave={() => setShowTooltip(false)}
                                        >
                                            {t("{{capitalize, capitalize}}", { capitalize: t("available") })}
                                        </span>
                                        <UseSwitch
                                            adjust={""}
                                            id={`extra-product-make-available-switch-r${index}`}
                                            checked={item.available}
                                            action={(value) => {
                                                handleAvailableItem(value);
                                            }}
                                            disabled={item.loading || isGroupItemsEmpty}
                                            iconLeftDisabled={true}
                                        />
                                        {showTooltip ? (
                                            <ReactTooltip
                                                delayShow={0}
                                                type={"light"}
                                                borderColor={"#D3DAE1"}
                                                border={true}
                                                multiline={true}
                                            />
                                        ) : null}
                                    </span>
                                    <span
                                        className="icon icon-edit text-xl text-gray-800 mx-5 cursor-pointer z-10"
                                        id={`extra-product-edit-button-r${index}`}
                                        onClick={() => {
                                            handleEditItem();
                                        }}
                                    ></span>
                                    <span
                                        className="icon icon-delete text-xl text-gray-800 cursor-pointer z-10"
                                        id={`extra-product-delete-button-r${index}`}
                                        onClick={() => {
                                            handleDeleteItem();
                                        }}
                                    ></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                renderEditCard()
            )}
        </div>
    );
}

export default ExtraItemCard;
