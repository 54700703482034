import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Actions
import { changeActionValues } from "../../actions/globalActions";
//Utils
import { useTranslation } from "react-i18next";
import useUpload from "../../hooks/useUpload";
import { toast } from "react-toastify";

const UseModalImportChannels = ({ index }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { id, name, ph, contentTypes, triggerAction, allowAllTypes, filesResume } = useSelector(
        (state) => state.ui.modalContent.inputs[index]
    );

    const actionData = useSelector((state) => state.action);

    const { view } = useUpload({
        id,
        placeholder: t(ph),
        contentTypes,
        allowAllTypes,
        filesResume,
        onSuccess: (files) => {
            dispatch(changeActionValues({ [name]: files }));
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    useEffect(() => {
        if (triggerAction) {
            if (actionData.values && actionData.values[name] && actionData.values[name].length > 0) {
                document.querySelector("[data-action='" + triggerAction + "']").click();
            }
        }
        // eslint-disable-next-line
    }, [actionData.values[name]]);

    return (
        <>
            <div className="mb-4">{t("import-dvb-subtitle")}</div>
            {view}
        </>
    );
};
export default UseModalImportChannels;
