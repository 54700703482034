import React, { useState, useEffect } from "react";

const CountUp = ({ number }) => {
    //Creates number effect from zero to given number in dashboard cards
    const [counter, setCounter] = useState(0);
    //Count up on load
    const speed = Math.ceil(500 / number);
    const step = Math.ceil(number / 150);
    useEffect(() => {
        const interval = setInterval(() => {
            setCounter((counter) => {
                if (counter < number) {
                    return counter + step;
                } else {
                    clearInterval(interval);
                    return number;
                }
            });
        }, speed);
        // eslint-disable-next-line
    }, []);

    return <>{counter}</>;
};

export default CountUp;
