import React from "react";

const AccessTypesSwitch = ({ data, setState, forceDisabled }) => {
    const { dataField, keyField, id } = data;
    let disabled = forceDisabled || !dataField[keyField]["enabled"];

    const active = dataField[keyField] && dataField[keyField]["mandatory"] ? true : false;
    return (
        <div className=" flex items-center h-10 ">
            <label className="cursor-pointer switch ml-auto -mt-4">
                <input
                    type="checkbox"
                    id={id}
                    checked={active}
                    onChange={(e) => {
                        if (disabled) {
                            return;
                        }
                        setState({
                            ...dataField,
                            [keyField]: { ...dataField[keyField], mandatory: e.currentTarget.checked },
                        });
                    }}
                ></input>
                <span
                    id={`${id}-span-action`}
                    className={`slider ${disabled ? "bg-gray" : "bg-gray-5"} round left-disabled`}
                ></span>
            </label>
        </div>
    );
};

export default AccessTypesSwitch;
