const Template = (template, langStrings) => {
    const dataType = "template";

    const { id, type, name, nameTID, devices } = template;

    const getTypeIcon = (type) => {
        switch (type) {
            case "Desktop":
            case "TV":
                return "interface";
            case "Mobile":
            case "Mobile med":
                return "mobile";
            default:
                return null;
        }
    };

    return {
        dataType: dataType,
        id: id,
        adjust: "h-auto",
        executeSectionQuery: true,
        actions: [{ type: dataType, value: id }],
        mutationParams: {
            id: id,
            name: name,
            nameTID: nameTID,
            type: type,
        },
        info: [
            {
                name: "name",
                cellContent: "link",
                value: name,
                specialWidth: "w-5/12",
                link: `/design/interface/template/preview/${id}`,
                external: true,
                target: "_blank",
            },
            {
                name: "type",
                cellContent: "text",
                value: type,
                specialWidth: "w-4/12",
                adjust: "truncate",
            },
            {
                cellContent: "icon",
                evaluate: true,
                icon: {
                    adjust: "text-gray-800",
                    name: getTypeIcon(devices[0].type),
                },
                isAction: false,
                modal: false,
                specialWidth: "w-1/12",
                translatePopover: true,
            },
            {
                name: "name",
                cellContent: "link",
                value: langStrings["preview"],
                specialWidth: "w-2/12 text-center",
                link: `/design/interface/template/preview/${id}`,
                external: true,
                target: "_blank",
            },
        ],
    };
};

export default Template;
