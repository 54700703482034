import React from "react";
import { useSelector } from "react-redux";
//Components
import UseIcon from "../../useIcon";

const UseSelectOptionCell = ({ data }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);

    //Data
    const { name, value, translate, style, icons, itemType, setSelectActive } = data;

    let icon = {};
    if (icons) {
        icon = { name: name, itemType: itemType, values: value };
    }

    const getCellContent = () => {
        let res;
        if (value !== "") {
            if (translate) {
                res = langStrings[`${name}-${itemType}-${value}`];
            } else {
                res = value;
            }
        } else {
            res = langStrings["all"];
        }
        return res;
    };
    const content = getCellContent();

    return (
        <div className={`t-select-option p-2 ${style}`} onClick={setSelectActive}>
            {icon ? <UseIcon icon={icon} type={itemType} adjust="t-icon-select" /> : null}
            <div className="first-capital">{content}</div>
        </div>
    );
};

export default UseSelectOptionCell;
