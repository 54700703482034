export const Services = [
    "services",
    "tv-channels",
    "channels_",
    "channel-list",
    "tune",
    "add-bulk-channels",
    "delete-channels",
    "add-channel",
    "corporate-channels",
    "set-up-channel",
    "select-channel-class",
    "preset-channel",
    "select-channel_",
    "customised-channel",
    "select-channel-type",
    "tv",
    "radio",
    "set-tuning",
    "udp-multicast",
    "port",
    "hls",
    "udp",
    "untuned",
    "add-logo",
    "not-channels-yet",
    "not-corporate-channels-yet",
    "delete-channel-confirm",
    "delete-channels-confirm",
    "type",
    "name",
    "country",
    "languge",
    "ip",
    "url",
    "choose-an-image",
    "information",
    "country",
    "select-an-option",
    "hls-channel",
    "add-corporate-channels-upgrade-text",
    "remember-last-channel",
    "remember-last-channel-text",
    "general-settings",
    "ip-example",
    "port-example",
    "incorrect-url",
    "incorrect-ip",
    "incorrect-port",
    "language",
    "delete",
];
