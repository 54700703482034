import { capitalizeFirst } from "hooks/Utils/Utils";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "./services/formatDate";
import { Warning } from "./Warning";
import ReactTooltip from "components/ReactTooltip";
import { NetworkHeader } from "./NetworkHeader";
import Button from "components/Button";
import { useModal } from "components/Modal";
import { ShowCCsList } from "./ShowCCsList";

export const ChromeCastStatus = ({ mdnsInfo, called, loading }) => {
    const { t } = useTranslation();
    const { open, close } = useModal();

    const { networkPerformanceData, lastUpdate, updateTimeMin, ccTotalNumber } = mdnsInfo || {};

    const lastUpdateDate = formatDate(lastUpdate);

    const internetBandwidthSectionData = Object?.keys(networkPerformanceData).reduce((acc, key) => {
        if (key.includes("share-content-simultaneasly")) {
            acc[key] = networkPerformanceData[key];
        }
        return acc;
    }, {});

    const wirelessSectionData = Object?.keys(networkPerformanceData).reduce((acc, key) => {
        if (key.includes("cc")) {
            acc[key] = networkPerformanceData[key];
        }
        return acc;
    }, {});

    if (loading || !called) return null;

    return (
        <main className="bg-white">
            <div className="border-t border-gray-300 mr-8"></div>
            <section className="mb-6 p-4">
                <NetworkHeader
                    title="internet-bandwidth"
                    lastUpdateDate={lastUpdateDate}
                    updateTimeMin={updateTimeMin}
                />
                {ccTotalNumber === 0 ? (
                    <div className="mb-8 text-gray-800">
                        <div className="flex items-center">
                            <span className="icon-info text-xl ml-3"></span>
                            <span className="ml-2">{t("no-cast-data")}</span>
                        </div>
                        <span className="ml-10 ">{t("not-possible-to-monitor")}</span>
                    </div>
                ) : null}
                {networkPerformanceData && internetBandwidthSectionData ? (
                    <>
                        <ul className="flex flex-col gap-4 list-disc ml-10">
                            <li>
                                {internetBandwidthSectionData["share-content-simultaneasly"]?.value ? (
                                    <div className="mt-2">
                                        {t("share-content-simultaneasly")}:{" "}
                                        <span className="font-bold" data-tip data-for={"share-content"}>
                                            {internetBandwidthSectionData["share-content-simultaneasly"]?.value}
                                        </span>
                                    </div>
                                ) : null}
                            </li>
                        </ul>
                        <p className="ml-10 my-2">
                            {t("internet-bandwidth")}{" "}
                            <strong>{internetBandwidthSectionData["share-content-simultaneasly"]?.bandwidth}</strong>
                        </p>
                    </>
                ) : null}
                {ccTotalNumber !== 0 ? (
                    <>
                        {internetBandwidthSectionData["share-content-simultaneasly"].warning?.minor ? (
                            <Warning text={t("cc-not-reach-percent-of-hired")} color={"orange"} ml={14} />
                        ) : null}
                        {internetBandwidthSectionData["share-content-simultaneasly"]?.warning?.critical ? (
                            <>
                                <Warning text={t("more-than-recommended")} ml={14} />
                                <p className="ml-20 my-1 text-gray-800 w-4/5">{t("user-sharing")}</p>
                                <p className="ml-20 text-gray-800">
                                    {t("ensure-optimal-experience", {
                                        mbps: internetBandwidthSectionData["share-content-simultaneasly"]?.warning
                                            ?.textValue,
                                    })}
                                </p>
                            </>
                        ) : null}
                    </>
                ) : null}
                <ReactTooltip id={`share-content`} place="right" effect="solid">
                    <span>{internetBandwidthSectionData["share-content-simultaneasly"]?.tooltip}</span>
                </ReactTooltip>
            </section>
            <div className="border-t mr-8 border-gray-300"></div>
            <section className="mb-6 p-4">
                <NetworkHeader title="wireless" lastUpdateDate={lastUpdateDate} updateTimeMin={updateTimeMin} />
                {ccTotalNumber === 0 ? (
                    <div className="mb-8 text-gray-800">
                        <div className="flex items-center">
                            <span className="icon-info text-xl ml-3"></span>
                            <span className="ml-2">{t("no-cast-data")}</span>
                        </div>
                        <span className="ml-10 ">{t("not-possible-to-monitor")}</span>
                    </div>
                ) : null}
                <ul className="flex flex-col gap-4 list-disc ml-10">
                    {networkPerformanceData &&
                        typeof networkPerformanceData === "object" &&
                        Object?.keys(wirelessSectionData)?.map((key) => {
                            return (
                                <Fragment key={key}>
                                    <li>
                                        {capitalizeFirst(t(key))}:{" "}
                                        <span className="font-bold" id={`ccs-${key}`}>
                                            {typeof networkPerformanceData[key] === "object" ? (
                                                networkPerformanceData[key]?.empty ? (
                                                    "-"
                                                ) : (
                                                    <span data-tip data-for={`ccs-${key}`}>
                                                        {networkPerformanceData[key]?.value}
                                                        {networkPerformanceData[key]?.tooltip && (
                                                            <ReactTooltip
                                                                id={`ccs-${key}`}
                                                                place="right"
                                                                effect="solid"
                                                            >
                                                                <span>{networkPerformanceData[key]?.tooltip}</span>
                                                            </ReactTooltip>
                                                        )}
                                                    </span>
                                                )
                                            ) : (
                                                networkPerformanceData[key]
                                            )}
                                        </span>
                                    </li>
                                    {ccTotalNumber !== 0 ? (
                                        <>
                                            {networkPerformanceData[key]?.warning &&
                                            (key === "cc-wifi-signal" || key === "ccs-latency") ? (
                                                <div className="flex items-center gap-8">
                                                    <div>
                                                        <div className="flex items-center gap-4">
                                                            <Warning
                                                                text={t(
                                                                    key === "cc-wifi-signal"
                                                                        ? "cc-signal-values"
                                                                        : "cc-latency-values",
                                                                    {
                                                                        ccNumber:
                                                                            networkPerformanceData[key]?.textValue,
                                                                    }
                                                                )}
                                                                color={"orange"}
                                                                ml={4}
                                                            />
                                                            <Button
                                                                id={`show-ccs-${key}`}
                                                                className="text-blue-300 font-bold"
                                                                onClick={() => {
                                                                    key === "cc-wifi-signal"
                                                                        ? open({
                                                                              title: t("ccs-wifi-signal-title"),
                                                                              children: (
                                                                                  <ShowCCsList
                                                                                      query={"lowSignal"}
                                                                                      text={"cc-signal-values"}
                                                                                  />
                                                                              ),
                                                                              className: "w-1/3 p-10",
                                                                              footer: (
                                                                                  <footer className="flex items-center gap-8">
                                                                                      <Button
                                                                                          id="btn-custom"
                                                                                          className="font-bold btn-blue"
                                                                                          onClick={close}
                                                                                      >
                                                                                          {t("accept")}
                                                                                      </Button>
                                                                                  </footer>
                                                                              ),
                                                                          })
                                                                        : open({
                                                                              title: t("ccs-latency-title"),
                                                                              children: (
                                                                                  <ShowCCsList
                                                                                      query={"latency"}
                                                                                      text={"cc-latency-values"}
                                                                                  />
                                                                              ),
                                                                              className: "w-1/3 p-10",
                                                                              footer: (
                                                                                  <footer className="flex items-center gap-8">
                                                                                      <Button
                                                                                          id="btn-custom"
                                                                                          className="font-bold btn-blue"
                                                                                          onClick={close}
                                                                                      >
                                                                                          {t("accept")}
                                                                                      </Button>
                                                                                  </footer>
                                                                              ),
                                                                          });
                                                                }}
                                                            >
                                                                {t("show-ccs")}
                                                            </Button>
                                                        </div>
                                                        <span className="ml-10 text-gray-800">
                                                            {t("network-not-optimal")}
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </>
                                    ) : null}
                                </Fragment>
                            );
                        })}
                </ul>
            </section>
        </main>
    );
};
