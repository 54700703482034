const Booking = (booking, commonzones, t) => {
    const { ID, AreaRef, peopleAmount, Status, Datetime } = booking;
    const AreaName = commonzones.filter((area) => area.ref === AreaRef)[0].name;
    const dataType = "booking";
    const bDate = new window.ZDate(Datetime);
    let statusColor = "";
    switch (Status) {
        case 1:
            statusColor = "success";
            break;
        case 2:
            statusColor = "fail";
            break;
        case 3:
            statusColor = "fail";
            break;
        default:
            break;
    }

    return {
        dataType: dataType,
        id: ID,
        info: [
            {
                name: "area",
                cellContent: "text",
                value: AreaName,
                specialWidth: "w-3/12",
            },
            {
                name: "date",
                cellContent: "text",
                value: `${bDate.toLocaleDateString([], {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                })} ${bDate.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                })}`,
                specialWidth: "w-3/12",
            },
            {
                name: "people",
                cellContent: "text",
                value: peopleAmount,
                specialWidth: "w-2/12",
            },
            {
                name: "status",
                cellContent: "text",
                value: t(`state-bookings-${Status}`),
                specialWidth: `w-2/12`,
                adjust: `truncate first-capital ${statusColor}`,
            },
            {
                name: "roomnumber",
                cellContent: "link",
                value: "Manage",
                specialWidth: "w-2/12",
                cellAdjust: `text-center`,
                link: `/common-zones/booking/categories/area/${AreaRef}/${Datetime}`,
            },
        ],
    };
};

export default Booking;
