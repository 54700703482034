import React from "react";
import { useTranslation } from "react-i18next";
import UseInputPercent from "../../../../../Inputs/useInputPercent";
import { useDispatch, useSelector } from "react-redux";
import { setSectionContent } from "../../../../../../actions/sectionActions";
import { ID, decodeID } from "../../../../../../hooks/Utils/Design/SalesUtils";

const UseOpacity = ({ data, group, showTitle }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { styleInputData } = useSelector((state) => state.sectionContent);

    const onBlurCallback = (value, id) => {
        const { field, group } = decodeID(id);
        dispatch(
            setSectionContent({
                styleInputData: {
                    ...styleInputData,
                    key: { ...styleInputData?.key, input: field, group },
                    value: value,
                },
            })
        );
    };

    return (
        <div className="mt-4 inline-flex px-8">
            {showTitle ? <div className="w-36 my-auto font-bold">{t("background-opacity")}</div> : null}
            <div className="w-24">
                <UseInputPercent id={ID("opacity", group)} value={data} adjust={"h-8"} action={onBlurCallback} />
            </div>
        </div>
    );
};

export default UseOpacity;
