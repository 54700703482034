import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import pkg from "../../../package.json";

import { useShowReleaseNotes } from "../Header/IconInfo";
import Warning from "components/Warning";

import { GlobalContext } from "contexts/Global";
import { useAuth } from "hooks/Session/auth";

export const WARNING_TYPES = {
    SYSTEM_UPDATE: {
        type: "SYSTEM_UPDATE",
        title: "system update",
        message: null, // message is defined in database
        deadlineDate: null,
        check: null,
        icon: "alert-orange",
        collapsible: true,
        closeable: false,
    },
    UPDATE_INFO: {
        type: "UPDATE_INFO",
        title: "UPDATE_INFO_TITLE",
        message: "UPDATE_INFO_MESSAGE",
        deadlineDate: pkg?.updateInfo?.deadlineDate ? new Date(pkg?.updateInfo?.deadlineDate) : null,
        check: (permissions) => {
            const packageID = pkg?.updateInfo?.package; // (optional)
            const attribute = pkg?.updateInfo?.attribute; // (optional)
            if (packageID && attribute) {
                // Check if the user has the necessary permission for the package and attribute
                return permissions?.[packageID]?.[attribute];
            } else if (packageID) {
                // If the attribute is not defined, check if the user has any permission for the package
                return permissions?.[packageID]?.some((attribute) => attribute);
            }
            return true;
        },
        icon: "alert",
        collapsible: false,
        closeable: true,
    },
};

const Warnings = () => {
    const { t } = useTranslation();

    const { showReleaseNotes } = useShowReleaseNotes();
    const { isProjectLogged, warnings, setReleaseNotesAsRead, userAdvicedUpdate } = useAuth();

    const showingSystemUpdate = warnings?.some((w) => w.type === WARNING_TYPES.SYSTEM_UPDATE.type);

    useEffect(() => {
        if (showingSystemUpdate && !userAdvicedUpdate) {
            setReleaseNotesAsRead(true);
            showReleaseNotes();
        }
    }, [showingSystemUpdate, userAdvicedUpdate]);

    if (!isProjectLogged || !warnings?.length) {
        return null;
    }

    return (
        <>
            {warnings?.map((warning) => {
                const type = warning?.type;
                const title = t(WARNING_TYPES[type]?.title);
                const icon = WARNING_TYPES[type]?.icon || "alert";
                const readMore = type === WARNING_TYPES.SYSTEM_UPDATE.type ? showReleaseNotes : null;
                const collapsible = WARNING_TYPES[type]?.collapsible;
                const closeable = WARNING_TYPES[type]?.closeable;
                const message = warning?.message || t(WARNING_TYPES[type]?.message);
                return (
                    <Warning
                        id={warning?.id}
                        key={warning?.id}
                        title={title}
                        icon={icon}
                        readMore={readMore}
                        collapsible={collapsible}
                        closeable={closeable}
                        collapsed={warning?.collapsed}
                        design="blue"
                    >
                        {message}
                    </Warning>
                );
            })}
        </>
    );
};

export default Warnings;
