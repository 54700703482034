import React, { useContext, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { parseVendureTranslation } from "hooks/Utils/SalesUtils";

import Button from "components/Button";

import { GlobalContext } from "contexts/Global";

const Translations = forwardRef(
    (
        {
            id,
            languages: langs,
            translations: translationsValue,
            defaultLang,
            onChange,
            className,
            maxLength,
            maxHeight,
            multiline,
            template,
            placeholder: customPlaceholder,
            includeDefault = true,
            design,
            displayCount,
            templateContainerClassName,
            templateButtonText,
        },
        ref
    ) => {
        const { t } = useTranslation();

        const { highlightComponent } = useContext(GlobalContext);

        const placeholder = customPlaceholder ?? t("enter-translation-here");
        const defaultLanguage = defaultLang || "en";
        const inputLanguages = includeDefault && langs?.length ? langs.filter((l) => l !== defaultLanguage) : langs;
        const translationsLanguages = translationsValue
            ? translationsValue
                  .map((t) => t.languageCode)
                  .filter((l) => !inputLanguages.includes(l) && l !== defaultLanguage)
            : [];
        // Aggregate input languages with translations languages, excluding default language
        const languages = inputLanguages
            ? inputLanguages.concat(translationsLanguages ? translationsLanguages : [])
            : translationsLanguages;

        const [translations, setTranslations] = useState(translationsValue);

        const onChangeTranslations = (translations) => {
            if (typeof onChange === "function") {
                onChange(translations);
            }
            setTranslations(translations);
        };

        // get best translation by language code
        const getName = (lang) => parseVendureTranslation(translations, lang, { defaultLang: defaultLanguage });
        // get stored translation by language code
        const getNameTranslation = (lang) =>
            parseVendureTranslation(translations, lang, { exact: true, defaultLang: defaultLanguage });

        const usingTemplate = template && JSON.stringify(translations) === JSON.stringify(template);

        const useTemplate = template
            ? () => {
                  onChangeTranslations(template);
              }
            : null;

        const onChangeTranslation = (value, lang) => {
            onChangeTranslations(changeTranslation(translations, lang, value));
        };

        useImperativeHandle(ref, () => ({
            changeTranslation: (value, lang) => {
                onChangeTranslation(value, lang);
            },
        }));

        const containerClass = classNames({
            "highlight-component": highlightComponent,
            [className]: className,
            "grid grid-cols-2 gap-5": design === "cols",
        });

        const titleClass = classNames({
            "py-1": design !== "modal" && design !== "cols",
            "py-3": design === "modal" || design === "cols",
            "font-bold": design === "cols",
        });

        const defaultTranslationClass = classNames({
            "flex items-start pt-2 space-x-5": true,
            "px-5": design !== "modal" && design !== "cols",
        });

        const languagesContainerClass = classNames({
            "py-5 space-y-3 overflow-auto": true,
            "px-5 border rounded": design !== "modal",
        });

        return (
            <div className={containerClass}>
                {includeDefault ? (
                    <div>
                        <div>
                            <div className={titleClass}>{t("default-language")}</div>
                            {useTemplate ? (
                                <div
                                    className={` px-5 
                                ${templateContainerClassName ? templateContainerClassName : "float-right"} `}
                                >
                                    <Button
                                        id={`${id}-template`}
                                        onClick={useTemplate}
                                        disabled={usingTemplate}
                                        design="link"
                                        className={` font-bold ${usingTemplate ? "text-gray-700" : "text-zafiro-600"}`}
                                    >
                                        <span className="first-capital">
                                            {t(templateButtonText ? templateButtonText : "use-template")}
                                        </span>
                                    </Button>
                                </div>
                            ) : null}
                        </div>
                        <div className={defaultTranslationClass}>
                            <div className="min-w-24 text-base font-bold">{t(`language:${defaultLanguage}`)}</div>
                            <TextInput
                                id={`${id}-default`}
                                className="w-full"
                                value={getNameTranslation(defaultLanguage)}
                                placeholder={getName(defaultLanguage) || placeholder}
                                maxLength={maxLength}
                                multiline={multiline}
                                displayCount={true}
                                onChange={(name) => {
                                    onChangeTranslation(name, defaultLanguage);
                                }}
                            />
                        </div>
                    </div>
                ) : null}
                {languages?.length ? (
                    <div>
                        <div className={titleClass}>{t("translations")}</div>
                        <div className={languagesContainerClass} style={{ maxHeight }}>
                            {languages.map((lang) => (
                                <div key={lang} className="flex items-start space-x-5">
                                    <div className="min-w-24 text-base font-bold">{t(`language:${lang}`)}</div>
                                    <TextInput
                                        id={`${id}-${lang}`}
                                        className="w-full"
                                        value={getNameTranslation(lang)}
                                        placeholder={getName(lang) || placeholder}
                                        maxLength={maxLength}
                                        multiline={multiline}
                                        displayCount={displayCount}
                                        onChange={(name) => {
                                            onChangeTranslation(name, lang);
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
);
Translations.displayName = "Translations";

const TextInput = ({
    id,
    value: initialName,
    placeholder,
    maxLength,
    multiline,
    displayCount,
    onChange,
    className,
}) => {
    const { t } = useTranslation();
    const [name, setName] = useState(initialName);

    useEffect(() => {
        setName(initialName);
    }, [initialName]);

    const inputProps = {
        id,
        value: name ?? "",
        placeholder,
        maxLength,
        onChange: (e) => setName(e.target.value),
        className: "bg-gray-200 text-gray-900 py-1 px-3 w-full rounded",
        onBlur: () => {
            if (onChange) {
                onChange(name);
            }
        },
    };

    return (
        <div className={className}>
            {multiline ? <textarea {...inputProps} rows={multiline} /> : <input {...inputProps} />}
            {displayCount && maxLength !== undefined ? (
                <span className="float-right text-gray-800 text-sm">
                    {t("x/y characters", { current: name?.length || 0, count: maxLength })}
                </span>
            ) : null}
        </div>
    );
};

const changeTranslation = (translations, lang, value) => {
    let newTranslations = [...(translations || []), { languageCode: lang, name: value }];
    // remove duplicated translations by languageCode, leaving the last one
    newTranslations = [
        ...new Map(
            newTranslations.map((translation) =>
                translation?.languageCode ? [translation.languageCode, translation] : null
            )
        ).values(),
    ];
    // remove empty translations
    newTranslations = newTranslations.filter((translation) => translation?.name?.length > 0);
    return newTranslations;
};

export default Translations;
