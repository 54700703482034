import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import UseSearch from "../useSearch";
import { useMSQuery } from "../../hooks/GraphqlCalls/useMSQuery";
import { gql } from "apollo-boost";
import { setLoadingModalContent } from "../../actions/uiActions";
import { capitalizeFirst, cloneObject } from "../../hooks/Utils/Utils";
import { changeActionValues } from "../../actions/globalActions";
import UseSelectWithSearch from "../useSelectWithSearch";
import UseButton from "../useButton";
import UseLoading from "../Table/useLoading";

export const UseModalAssignedTvsManagement = ({ index }) => {
    const dispatch = useDispatch();

    const { isDefault, tvsAssigned } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const inputTvId = useSelector((state) => state.ui.modalContent.id);
    const actionValues = useSelector((state) => state.action.values);
    const { t } = useTranslation();
    const [tvsFiltered, setTvsFiltered] = useState();
    const [allTvs, setAllTvs] = useState();
    const [search, setSearch] = useState(null);
    const [numTvsAssigned, setNumTvsAssigned] = useState(tvsAssigned);
    const [selectedTvs, setSelectedTvs] = useState([]);
    const [rooms, setRooms] = useState();
    const [addingRooms, setAddingRooms] = useState(false);
    const [locations, setLocations] = useState();
    const [loadingLocations, setLoadingLocations] = useState(false);

    const GET_ASSIGNED_TVS = gql`
        query Rooms($id: Int64!, $isDefault: Boolean!) {
            assignedRooms: rooms(filter: { tvinput: { id: $id, isDefault: $isDefault } }) {
                results {
                    id
                    name
                    number
                    roomTVs {
                        id
                        tvInputID
                        name
                    }
                }
            }
            allRooms: rooms {
                results {
                    id
                    name
                    number
                    roomTVs {
                        id
                        tvInputID
                        name
                    }
                }
            }
        }
    `;

    const [getAssignedTvsQuery, { data, networkStatus }] = useMSQuery(GET_ASSIGNED_TVS, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        dispatch(setLoadingModalContent(true));
        getAssignedTvsQuery({ variables: { id: String(inputTvId), isDefault: isDefault } });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data && data.assignedRooms && data.assignedRooms.results && networkStatus === 7) {
            setAllTvs(data.assignedRooms.results);
            setTvsFiltered(data.assignedRooms.results);
            dispatch(setLoadingModalContent(false));

            setRooms(data.allRooms.results);
        }
        // eslint-disable-next-line
    }, [data, networkStatus]);

    useEffect(() => {
        if (search) {
            setTvsFiltered(
                allTvs.filter(
                    (tv) => tv.name.toLowerCase().includes(search.toLowerCase()) || String(tv.number).includes(search)
                )
            );
        } else {
            setTvsFiltered(allTvs);
        }
        // eslint-disable-next-line
    }, [search]);

    useEffect(() => {
        updateTvsAssignedNum();
        setAddingRooms(false);
        // eslint-disable-next-line
    }, [allTvs]);

    useEffect(() => {
        if (loadingLocations) {
            let locationList = [];

            rooms.map((room) =>
                room.roomTVs.map((rtv) =>
                    actionValues.rooms.includes(room.id) && !locationList.find((loca) => loca.name === rtv.name)
                        ? locationList.push({ id: rtv.name, name: rtv.name })
                        : null
                )
            );

            setLocations(locationList);
            setLoadingLocations(false);
        }
        // eslint-disable-next-line
    }, [actionValues]);

    const checkSelectedTVs = (roomTvId) => {
        let selectedTvsTmp = [];
        //marcar como seleccionadas todas las TVs filtered
        if (!roomTvId) {
            //all filtered selected => unselect all tvs
            if (selectedTvs.length === numTvsAssigned) {
                setSelectedTvs(selectedTvsTmp);
                //mark all filtered tvs as selected
            } else {
                tvsFiltered.forEach((room) => {
                    room.roomTVs.forEach((rtv) => {
                        if (rtv.tvInputID === inputTvId) {
                            selectedTvsTmp.push(rtv.id);
                        }
                    });
                });
            }

            //añadir roomTvId a la lista de tvs seleccionadas
        } else {
            selectedTvsTmp = cloneObject(selectedTvs);
            if (selectedTvsTmp.filter((item) => item === roomTvId).length === 0) {
                selectedTvsTmp.push(roomTvId);
            } else {
                selectedTvsTmp = selectedTvsTmp.filter((elem) => elem !== roomTvId);
            }
        }
        setSelectedTvs(selectedTvsTmp);
    };

    const onRoomSelected = (e) => {
        setLoadingLocations(true);
    };

    const updateTvsAssignedNum = () => {
        let total = 0;
        let assignedRoomTvIds = [];
        if (!allTvs) return;

        allTvs.forEach((room) =>
            room.roomTVs.forEach((rtv) => {
                if (
                    (isDefault && (rtv.tvInputID === inputTvId || rtv.tvInputID === 0)) ||
                    (!isDefault && rtv.tvInputID === inputTvId)
                ) {
                    total++;
                    assignedRoomTvIds.push(rtv.id);
                }
            })
        );
        setNumTvsAssigned(total);
        dispatch(changeActionValues({ "assigned-tvs-ids": assignedRoomTvIds }));
    };

    const addRooms = () => {
        setAddingRooms(true);
        let tvsFilteredTmp = cloneObject(tvsFiltered);

        actionValues["rooms"].forEach((roomId) => {
            //si la habitación ya está en el listado, cambio los tvINputId de las locations seleccionadas
            let roomTmp = tvsFilteredTmp.find((item) => item.id === roomId);
            if (roomTmp) {
                roomTmp.roomTVs.forEach((rtv) => {
                    if (actionValues["locations"].includes(rtv.name)) {
                        rtv.tvInputID = inputTvId;
                    }
                });
            } else {
                let newRoom = cloneObject(rooms.find((room) => room.id === roomId));

                newRoom.roomTVs.forEach((rtv) => {
                    if (actionValues["locations"].includes(rtv.name)) {
                        rtv.tvInputID = inputTvId;
                    }
                });
                tvsFilteredTmp.push(newRoom);
            }
        });

        //si la habitación esta filtrada
        setTvsFiltered(tvsFilteredTmp.sort((a, b) => (a.name ? String(a.name).localeCompare(String(b.name)) : 0)));

        //misma operación para mantene el listado de todas las TVs actualizado
        let allTvsTmp = cloneObject(allTvs);

        actionValues["rooms"].forEach((roomId) => {
            //si la habitación ya está en el listado, cambio los tvINputId de las locations seleccionadas
            let roomTmp = allTvsTmp.find((item) => item.id === roomId);
            if (roomTmp) {
                roomTmp.roomTVs.forEach((rtv) => {
                    if (actionValues["locations"].includes(rtv.name)) {
                        rtv.tvInputID = inputTvId;
                    }
                });
            } else {
                let newRoom = cloneObject(rooms.find((room) => room.id === roomId));

                newRoom.roomTVs.forEach((rtv) => {
                    if (actionValues["locations"].includes(rtv.name)) {
                        rtv.tvInputID = inputTvId;
                    }
                });
                allTvsTmp.push(newRoom);
            }
        });

        setAllTvs(allTvsTmp.sort((a, b) => (a.name ? String(a.name).localeCompare(String(b.name)) : 0)));

        dispatch(changeActionValues({ rooms: [], locations: [] }));
    };

    const printRoomTv = (props) => {
        const { room, index, isDefault, inputTvId, selectedTvs } = props;
        return room.roomTVs.map((roomTV, index) => {
            if (
                (isDefault && (roomTV.tvInputID === inputTvId || roomTV.tvInputID === 0)) ||
                (!isDefault && roomTV.tvInputID === inputTvId)
            ) {
                return (
                    <div
                        key={roomTV.id}
                        className="mx-2 inline-flex border-b border-gray-200 py-2"
                        id={`recipient_${room.name}_${roomTV.id}`}
                    >
                        <div className="w-0.5/12 my-auto t-checkbox-container">
                            <input
                                id={`checkbox-${room.name}_${roomTV.id}`}
                                type="checkbox"
                                className={`t-checkbox-checked`}
                                checked={!!selectedTvs && selectedTvs.includes(roomTV.id)}
                                onChange={() => {
                                    checkSelectedTVs(roomTV.id);
                                }}
                            />
                            <span className="t-checkbox-unchecked"></span>
                        </div>
                        <div className="w-3/6 pr-2 my-auto">
                            <div className={`py-1  inline-block cursor-default`} style={{ maxWidth: "100%" }}>
                                {room.name}
                            </div>
                            <div className="text-gray-300">{room.number}</div>
                        </div>
                        <div className="w-2/6 pr-2 my-auto">
                            <div
                                className={`rounded py-1 px-4 m-1 inline-block text-white  bg-blue-300 truncate cursor-default`}
                                style={{ maxWidth: "100%" }}
                            >
                                {roomTV.name}
                            </div>
                        </div>
                        <div className="w-0.5/12 pr-2 my-auto">
                            <span
                                id={`${room.name}_${roomTV.id}-delete`}
                                className="icon-delete px-1 text-2xl clickable text-gray-800"
                                onClick={(e) =>
                                    removeTV({ roomTvId: roomTV.id, tvsFiltered, allTvs, setTvsFiltered, setAllTvs })
                                }
                            ></span>
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        });
    };

    return (
        <>
            {tvsFiltered ? (
                <div className="w-full flex">
                    <div className="w-3/6 mt-6">
                        <div>{t("assing-tvs-to-input")}</div>
                        {addingRooms ? (
                            <div className="w-full relative table mt-6" style={{ height: "25rem" }}>
                                <UseLoading></UseLoading>
                            </div>
                        ) : (
                            <div className="w-10/12 mt-8">
                                <div className="mb-10">
                                    <div className="font-bold first-capital mb-2">{t("rooms")}</div>
                                    <UseSelectWithSearch
                                        data={{
                                            optionData: rooms,
                                            //selectedIds: selectedIds,
                                            name: `rooms`,
                                            onChange: onRoomSelected,
                                            selectPlaceHolder: capitalizeFirst(t("select-rooms")),
                                            optionsAdjust: "mt-11",
                                        }}
                                    ></UseSelectWithSearch>

                                    <div className="font-bold first-capital mt-6 mb-2">{t("location")}</div>

                                    {loadingLocations ? (
                                        <div className="w-full relative table mt-6">
                                            <UseLoading></UseLoading>
                                        </div>
                                    ) : (
                                        <UseSelectWithSearch
                                            data={{
                                                optionData: locations,
                                                name: `locations`,
                                                hideSearch: true,
                                                selectedIds:
                                                    actionValues && actionValues["locations"]
                                                        ? actionValues["locations"]
                                                        : null,
                                                disabled:
                                                    actionValues &&
                                                    (!actionValues["rooms"] || actionValues["rooms"].length === 0),
                                                // onChange: onTypeChange,
                                                selectPlaceHolder: capitalizeFirst(t("select-locations")),
                                                optionsAdjust: "mt-11",
                                            }}
                                        ></UseSelectWithSearch>
                                    )}
                                </div>
                                <UseButton
                                    id="btn-add"
                                    buttonName={t("add")}
                                    iconPos={"RIGTH"}
                                    icon={"arrow-show"}
                                    disabled={
                                        actionValues &&
                                        (!actionValues["rooms"] ||
                                            actionValues["rooms"].length === 0 ||
                                            !actionValues["locations"] ||
                                            actionValues["locations"].length === 0)
                                    }
                                    buttonColor="btn-blue"
                                    action={function () {
                                        addRooms();
                                    }}
                                ></UseButton>
                            </div>
                        )}
                    </div>
                    <div className="w-3/6">
                        <div className="flex">
                            <div className="w-3/6">
                                <UseSearch
                                    handleChangeSearch={(change) => {
                                        inputSearchChange({ change, setSearch });
                                    }}
                                    placeholder={capitalizeFirst(t("search"))}
                                />
                            </div>
                            <div id="numTvsAssigned" className="w-3/6 pt-2 text-right">
                                {numTvsAssigned} {t("assigned-tvs")}
                            </div>
                        </div>
                        <div
                            id="tvs_show"
                            className="flex overflow-y-auto py-4 rounded border-b border-gray-200 font-bold text-lg"
                            style={{}}
                        >
                            <div className="w-0.5/12 ml-2 my-auto t-checkbox-container">
                                <input
                                    id="checkbox-all"
                                    type="checkbox"
                                    className="t-checkbox-checked opacity-100"
                                    checked={selectedTvs.length === numTvsAssigned}
                                    onChange={() => {
                                        checkSelectedTVs();
                                    }}
                                />
                                <span className="t-checkbox-unchecked"></span>
                            </div>
                            <div className="w-6/12">{capitalizeFirst(t("room"))}</div>
                            <div className="w-3/12">{capitalizeFirst(t("locations"))}</div>
                        </div>
                        <div
                            id="tvs_show"
                            className="grid grid-cols-1 grid-flow-row overflow-y-auto rounded"
                            style={{
                                height: "50vh",
                                gridAutoRows: "min-content",
                            }}
                        >
                            {tvsFiltered.map((room, index) =>
                                printRoomTv({ room, index, isDefault, inputTvId, selectedTvs })
                            )}
                        </div>
                        <div
                            id="delete-tvs-selected"
                            className={`flex mt-2 font-medium items-center text-zafiro-200 sidebar-submenu-link cursor-pointer
                    `}
                            onClick={(e) =>
                                deleteSelectedTvs({
                                    tvsFiltered,
                                    allTvs,
                                    selectedTvs,
                                    setTvsFiltered,
                                    setAllTvs,
                                    setSelectedTvs,
                                })
                            }
                        >
                            <div className="first-capital mt-1"> {t("delete-tvs-selected")}</div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

const inputSearchChange = (props) => {
    const { change, setSearch } = props;
    if (change.target.value && change.target.value !== undefined) {
        setSearch(change.target.value);
    } else if (change.target.value === undefined || change.target.value === "") {
        setSearch(null);
    }
};

const removeTV = (props) => {
    const { roomTvId, tvsFiltered, allTvs, setTvsFiltered, setAllTvs } = props;
    let tvsFilteredTmp = cloneObject(tvsFiltered);
    let allTvsTmp = cloneObject(allTvs);

    tvsFilteredTmp.map((room) => {
        room.roomTVs.map((roomTv) => {
            if (roomTv.id === roomTvId) {
                roomTv.tvInputID = 0;
            }
        });
    });

    allTvsTmp.map((room) => {
        room.roomTVs.map((roomTv) => {
            if (roomTv.id === roomTvId) {
                roomTv.tvInputID = 0;
            }
        });
    });

    setTvsFiltered(tvsFilteredTmp);
    setAllTvs(allTvsTmp);
};

const deleteSelectedTvs = (props) => {
    const { tvsFiltered, allTvs, selectedTvs, setTvsFiltered, setAllTvs, setSelectedTvs } = props;

    let tvsFilteredTmp = cloneObject(tvsFiltered);
    let allTvsTmp = cloneObject(allTvs);

    tvsFilteredTmp.forEach((room) => {
        room.roomTVs.forEach((roomTv) => {
            if (selectedTvs.includes(roomTv.id)) {
                roomTv.tvInputID = 0;
            }
        });
    });

    allTvsTmp.forEach((room) => {
        room.roomTVs.forEach((roomTv) => {
            if (selectedTvs.includes(roomTv.id)) {
                roomTv.tvInputID = 0;
            }
        });
    });

    setTvsFiltered(tvsFilteredTmp);
    setAllTvs(allTvsTmp);
    setSelectedTvs([]);
};
