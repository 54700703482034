import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseButton from "../useButton";
import UseScheduleFormCommon from "./useScheduleFormCommon";
import UseScheduleFormSpecial from "./useScheduleFormSpecial";
import UseScheduleFormFestive from "./useScheduleFormFestive";
import UseSchedule from "../Schedule/useSchedule";
//Actions
import { setActionName, cleanScheduleHours, removeWeekDay } from "../../actions/globalActions";
import { setWizardAllowNext } from "../../actions/sectionActions";
import { resetScheduleSpecialRows, resetDateSpecialRows } from "../../actions/scheduleActions";

const WizardStepContent = () => {
    //Get config
    const { actionName } = useSelector((state) => state.action);
    const { periodicSchedulesView } = useSelector((state) => state.schedules);
    //Step management
    const { currentStep } = useSelector((state) => state.sectionContent);

    //States
    const [addCommonSchedule, setAddCommonSchedule] = useState(false);
    const [addSpecialSchedule, setAddSpecialSchedule] = useState(false);
    const [addFestiveSchedule, setAddFestiveSchedule] = useState(false);

    //Actions
    const dispatch = useDispatch();

    const clickAddHandler = () => {
        switch (currentStep) {
            case 1:
                dispatch(setActionName("add-periodic-common"));
                dispatch(cleanScheduleHours());
                dispatch(removeWeekDay());
                setAddCommonSchedule(true);
                setAddSpecialSchedule(false);
                setAddFestiveSchedule(false);
                break;
            case 2:
                dispatch(setActionName("add-periodic-special"));
                dispatch(resetScheduleSpecialRows());
                dispatch(resetDateSpecialRows());
                setAddCommonSchedule(false);
                setAddSpecialSchedule(true);
                setAddFestiveSchedule(false);
                break;
            default:
                dispatch(setActionName("add-periodic-festive"));
                setAddCommonSchedule(false);
                setAddSpecialSchedule(false);
                setAddFestiveSchedule(true);
        }
    };

    //Listeners
    useEffect(() => {
        setAddCommonSchedule(false);
        setAddSpecialSchedule(false);
        setAddFestiveSchedule(false);
        //Set a flag in store to allow next step on wizard nav
        dispatch(setWizardAllowNext(true));
        // eslint-disable-next-line
    }, [currentStep]);

    useEffect(() => {
        if (!actionName) {
            setAddCommonSchedule(false);
            setAddSpecialSchedule(false);
            setAddFestiveSchedule(false);
        }
        // eslint-disable-next-line
    }, [actionName]);

    useEffect(() => {
        //Set a flag in store to allow next step on wizard nav
        if (addCommonSchedule || addSpecialSchedule || addFestiveSchedule) {
            dispatch(setWizardAllowNext(false));
        }
        // eslint-disable-next-line
    }, [addCommonSchedule, addSpecialSchedule, addFestiveSchedule]);

    return (
        <>
            {periodicSchedulesView && periodicSchedulesView.length > 0 ? (
                <>
                    {periodicSchedulesView.map((currentEvent, index) =>
                        (currentEvent.timetableDates[0].dateList === "" && currentStep === 1) ||
                        (currentEvent.timetableDates[0].dateList !== "" && currentStep === 2) ||
                        (currentEvent.type === "CLOSED" && currentStep === 3) ? (
                            <UseSchedule currentEvent={currentEvent} key={index} />
                        ) : null
                    )}
                </>
            ) : null}
            {addCommonSchedule ? (
                <>
                    <UseScheduleFormCommon setAddCommonSchedule={setAddCommonSchedule} />
                </>
            ) : null}
            {addSpecialSchedule ? (
                <>
                    <UseScheduleFormSpecial setAddSpecialSchedule={setAddSpecialSchedule} />
                </>
            ) : null}
            {addFestiveSchedule ? (
                <>
                    <UseScheduleFormFestive setAddFestiveSchedule={setAddFestiveSchedule} />
                </>
            ) : null}
            {showAddNew(addCommonSchedule, addSpecialSchedule, addFestiveSchedule) ? (
                <div className="w-full flex justify-start h-10 my-8">
                    <div className="w-auto" onClick={clickAddHandler}>
                        <UseButton buttonName="add" buttonColor="btn-blue-outline" icon="add" />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export const showAddNew = (
    //Returns true if no form active to show add button
    addCommonSchedule,
    addSpecialSchedule,
    addFestiveSchedule
) => {
    return !addCommonSchedule && !addSpecialSchedule && !addFestiveSchedule;
};

export default WizardStepContent;
