import React from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "hooks/Session/auth";

import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table";

import ListDesigns from "../../../hooks/GraphqlCalls/Design/ListDesigns";
import UpdateDesign from "../../../hooks/GraphqlCalls/Design/UpdateDesign";

import Warning from "components/Warning";

const Designs = () => {
    const { t } = useTranslation();
    const { hasChainModule, isCorporate } = useAuth();

    ListDesigns();
    return (
        <>
            <UpdateDesign />
            <UseSectionHeader title={["list-of", "designs"]} value={"demo"} />
            {!hasChainModule && !isCorporate ? (
                <Warning id="warning-chain-designs">
                    {t("You will not be able to perform any actions on Global management designs")}
                    <br />
                    {t(
                        "If you wish to make full use of all the options available please request a quotation for Zafiro Chain from our Account Service department"
                    )}
                </Warning>
            ) : null}
            <UseTable />
        </>
    );
};

export default Designs;
