import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ReactTooltip from "components/ReactTooltip";
import { changeActionValues, setActionName } from "../../../../actions/globalActions";
import { setExecuteQuery, showRowForm } from "../../../../actions/sectionActions";
import { setDisabled } from "../../../../actions/tableActions";
import { openModal, setModalContent } from "../../../../actions/uiActions";
import NewTag from "../../../../hooks/GraphqlCalls/Design/modals/NewTag";
import UseCheckBox from "../../../Inputs/useCheckBox";
import { toast } from "react-toastify";
import UseButton from "components/useButton";

//Components
import UseEditableField from "../../../useEditableField";
import TagPropertyValues from "./TagPropertyValues";

const TagInfo = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //Store
    const { tagData } = useSelector((state) => state.sectionContent);
    const typeURL = tagData && tagData.target === "URL";
    const [tagValues, setTagValues] = useState({ nameEn: "", nameEs: "", description: "" });
    const [tagInEdition, setTagInEdition] = useState(false);
    const [editingTagValues, setEditingTagValues] = useState({ nameEn: "", nameEs: "", description: "" });
    const [error, setError] = useState(false);

    //State
    const [singleValue, setSingleValue] = useState(false);

    //listeners
    useEffect(() => {
        let tagValues = { nameEn: "", nameEs: "", description: "" };

        if (tagData?.name) {
            const { en, es } = JSON.parse(tagData.name);
            tagValues.nameEs = es || "";
            tagValues.nameEn = en || "";
        }

        if (tagData?.description) {
            tagValues.description = tagData.description;
        }

        setTagValues(tagValues);
        setEditingTagValues(tagValues);

        if (tagData?.assignedAll) {
            setSingleValue(true);
            dispatch(setDisabled(true));
        }

        // eslint-disable-next-line
    }, [tagData]);

    //Functions

    // console.log(tagInEdition);

    const singleValueHandler = (e) => {
        if (!singleValue) {
            //if single value checheck ensure disable row edition
            dispatch(showRowForm(false));
        }
        setSingleValue(!singleValue);
        // eslint-disable-next-line
        dispatch(changeActionValues({ ["single-value"]: !singleValue }));
        dispatch(setDisabled(!singleValue));

        dispatch(
            setExecuteQuery({
                action: "update-tag-check",
                params: { id: tagData.id, value: !singleValue },
            })
        );
    };

    // const editTag = (e) => {
    //     dispatch(setActionName("edit-tag"));
    //     dispatch(setModalContent(NewTag(tagData, t)[0]));
    //     dispatch(openModal());
    // };
    const getTargetColor = (target) => {
        return target === "TEXT" ? "#2286EF" : "#06376B";
    };

    return (
        <>
            {tagData && tagData.id ? (
                <>
                    {/* TAG INFORMATION */}
                    <div className="w-full bg-white p-6 ">
                        <div className="pb-4 border-b border-gray-200 flex justify-between">
                            <div className="font-bold text-lg">
                                {t("{{capitalize, capitalize}}", { capitalize: t("tag information") })}
                            </div>
                            {!tagInEdition && (
                                <div
                                    className="flex icon icon-edit text-2xl cursor-pointer "
                                    onClick={() => {
                                        setTagInEdition((val) => !val);
                                    }}
                                ></div>
                            )}
                        </div>

                        <div className="grid grid-cols-2 gap-8 mt-5">
                            <div className="">
                                <div className="font-bold pb-3 flex">
                                    {t("{{capitalize, capitalize}}", { capitalize: t("name") })}
                                    <span className=" block ml-2">*</span>
                                </div>
                                <div className="grid gap-4">
                                    <div className=" h-10 flex items-center">
                                        <div className="w-2/12">{`${t("{{capitalize, capitalize}}", {
                                            capitalize: t("english"),
                                        })} `}</div>
                                        {tagInEdition ? (
                                            <input
                                                value={editingTagValues.nameEn}
                                                type="text"
                                                className={` ${
                                                    error ? " border border-red-500 " : ""
                                                } h-10 px-2 bg-gray-200 rounded`}
                                                onChange={(e) => {
                                                    setEditingTagValues({
                                                        ...editingTagValues,
                                                        nameEn: e.target.value,
                                                    });
                                                }}
                                            ></input>
                                        ) : (
                                            <p>{editingTagValues.nameEn}</p>
                                        )}
                                    </div>
                                    <div className=" h-10 flex items-center">
                                        <div className="w-2/12">{`${t("{{capitalize, capitalize}}", {
                                            capitalize: t("spanish"),
                                        })} `}</div>
                                        {tagInEdition ? (
                                            <input
                                                value={editingTagValues.nameEs}
                                                type="text"
                                                className=" h-10 px-2 bg-gray-200 rounded"
                                                onChange={(e) => {
                                                    setEditingTagValues({
                                                        ...editingTagValues,
                                                        nameEs: e.target.value,
                                                    });
                                                }}
                                            ></input>
                                        ) : (
                                            <p>{editingTagValues.nameEs}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <div className="font-bold pb-3">
                                        {t("{{capitalize, capitalize}}", { capitalize: t("tag-id") })}
                                    </div>
                                    <div className="cursor-help" data-tip data-for={`tag-id-tooltip`}>
                                        {tagData.tag}
                                    </div>
                                </div>
                                <ReactTooltip
                                    id={`tag-id-tooltip`}
                                    delayShow={500}
                                    type={"light"}
                                    borderColor={"#D3DAE1"}
                                    border={true}
                                >
                                    {t("tag id cannot change")}
                                </ReactTooltip>
                                <ReactTooltip
                                    id={`tag-target-tooltip`}
                                    delayShow={100}
                                    type={"light"}
                                    borderColor={"#D3DAE1"}
                                    border={true}
                                >
                                    {t("tag type cannot change")}
                                </ReactTooltip>
                            </div>
                            <div className="">
                                <div className="font-bold pb-3">
                                    {t("{{capitalize, capitalize}}", { capitalize: t("type") })}
                                </div>
                                <div className="cursor-help">
                                    <div
                                        data-tip
                                        data-for={`tag-target-tooltip`}
                                        className="rounded py-1 px-4 text-white inline-block first-capital "
                                        style={{
                                            backgroundColor: getTargetColor(tagData.target),
                                            whiteSpace: "break-spaces",
                                        }}
                                    >
                                        {(t(tagData.target.toLowerCase()) || tagData.target).toUpperCase()}
                                    </div>
                                </div>
                                <div className="font-bold mt-6 pb-3">
                                    {t("{{capitalize, capitalize}}", { capitalize: t("description") })}
                                </div>
                                {tagInEdition ? (
                                    <textarea
                                        value={editingTagValues.description}
                                        id="tag-description"
                                        // type="textare"

                                        className=" w-4/4 h-16 resize-none px-2 bg-gray-200 rounded"
                                        onChange={(e) => {
                                            setEditingTagValues({
                                                ...editingTagValues,
                                                description: e.target.value,
                                            });
                                        }}
                                    ></textarea>
                                ) : (
                                    <p>{editingTagValues.description}</p>
                                )}
                            </div>
                        </div>
                        {tagInEdition && (
                            <div className="  border-t mt-2 border-gray-200 w-full flex justify-end ">
                                <div className=" flex mt-6 ">
                                    <UseButton
                                        action={() => {
                                            setTagInEdition(false);
                                            setEditingTagValues(tagValues);
                                        }}
                                        adjust={"mr-2"}
                                        buttonName={"cancel"}
                                        buttonColor={"btn-white"}
                                        id={"tag-information-cancel-button"}
                                    />
                                    <UseButton
                                        action={() => {
                                            if (editingTagValues.nameEn.trim() === "") {
                                                setError(true);
                                                toast.error(t("input error"));
                                            } else {
                                                setError(false);
                                                dispatch(
                                                    setExecuteQuery({
                                                        action: "edit-tag",
                                                        params: { id: tagData.id, values: editingTagValues },
                                                    })
                                                );
                                                setTagInEdition(false);
                                            }
                                        }}
                                        buttonName={"save"}
                                        buttonColor={"btn-blue"}
                                        id={"tag-information-save-button"}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    {/* PROPERTIES VALUES */}
                    <div className="w-full bg-white p-6 mt-6 ">
                        <div className="font-bold text-lg">
                            {t("{{capitalize, capitalize}}", { capitalize: t("values") })}
                        </div>
                        <div className="flex w-full mt-5 mb-2 align-center">
                            <div className="">
                                <div className="block relative cursor-pointer">
                                    <UseCheckBox
                                        checked={tagData && tagData.assignedAll}
                                        id={"checkbox-tag-single-value"}
                                        name={t("single-value")}
                                        onClick={singleValueHandler}
                                    />
                                </div>
                            </div>
                            <div
                                className="icon icon-info cursor-help text-xl mx-4"
                                data-tip
                                data-for={`single-value-tooltip`}
                            ></div>
                            <UseEditableField
                                data={{
                                    label: "single-value-text",
                                    id: "tag-info-single-value-text",
                                    noLabel: true,
                                    placeholder: [typeURL ? t("valid-url") : "type-the-value", "..."],
                                    value: tagData.value,
                                    onBlurAction: function (e) {
                                        if (typeURL) {
                                            const urlPattern =
                                                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm;
                                            const isAValidUrl = urlPattern.test(e.target.value);

                                            if (isAValidUrl === false) {
                                                toast.error(t("type a valid URL"));
                                                return;
                                            }
                                        }
                                        dispatch(
                                            setExecuteQuery({
                                                action: "update-tag-value",
                                                params: { id: tagData.id },
                                            })
                                        );
                                    },
                                    inEditionMode: true,
                                    type: "text",
                                    disabled: !singleValue,
                                    styleAdjust: "w-4/12",
                                }}
                            ></UseEditableField>
                            <ReactTooltip
                                id={`single-value-tooltip`}
                                delayShow={1000}
                                type={"light"}
                                borderColor={"#D3DAE1"}
                                border={true}
                            >
                                {t("single-value-tooltip")}
                            </ReactTooltip>
                        </div>
                        <TagPropertyValues></TagPropertyValues>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default TagInfo;
