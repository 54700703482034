import React from "react";

const SalesLibrabyMOCKShop = () => {
    return (
        <div className="w-full h-full bg-white pl-8 pt-4">
            <div className="text-2xl text-gray-800">SALES SEQUENCE</div>
            <div className="font-bold">Shops</div>
        </div>
    );
};

export default SalesLibrabyMOCKShop;
