const DeleteGroupChannel = (data, t) => {
    return {
        title: "delete-group",
        executeSectionQuery: true,
        id: data?.id ? data.id : 0,
        inputs: [
            {
                text: t("confirmation-delete-group", { group: data?.name ? data.name : "" }),
                type: "void",
                style: "pb-8",
            },
            {
                text:
                    data?.channelsNum > 0
                        ? t("alert-group-has-channels-added", { number: data?.channelsNum ? data.channelsNum : 0 })
                        : "",
                type: "void",
                style: "pb-10",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "delete",
                style: "red",
                action: "delete-group-channel",
            },
        ],
    };
};

export default DeleteGroupChannel;
