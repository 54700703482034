import React from "react";
import { gql } from "apollo-boost";

import WidgetAlarm from "components/Section/Design/Widgets/WidgetAlarm";
import WidgetButton from "components/Section/Design/Widgets/WidgetButton";
import WidgetCalendar from "components/Section/Design/Widgets/WidgetCalendar";
import WidgetCarousel from "components/Section/Design/Widgets/WidgetCarousel";
import WidgetChatButton from "components/Section/Design/Widgets/WidgetChatButton";
import WidgetClock from "components/Section/Design/Widgets/WidgetClock";
import WidgetContainer from "components/Section/Design/Widgets/WidgetContainer";
import WidgetHTML from "components/Section/Design/Widgets/WidgetHTML";
import WidgetIcon from "components/Section/Design/Widgets/WidgetIcon";
import WidgetIframe from "components/Section/Design/Widgets/WidgetIframe";
import WidgetImage from "components/Section/Design/Widgets/WidgetImage";
import WidgetLangSelection from "components/Section/Design/Widgets/WidgetLangSelection";
import WidgetLogo from "components/Section/Design/Widgets/WidgetLogo";
import WidgetMessages from "components/Section/Design/Widgets/WidgetMessages";
import WidgetQR from "components/Section/Design/Widgets/WidgetQR";
import WidgetRooms from "components/Section/Design/Widgets/WidgetRooms";
import WidgetSlider from "components/Section/Design/Widgets/WidgetSlider";
import WidgetText from "components/Section/Design/Widgets/WidgetText";
import WidgetTopBar from "components/Section/Design/Widgets/WidgetTopBar";
import WidgetVideo from "components/Section/Design/Widgets/WidgetVideo";
import WidgetWeather from "components/Section/Design/Widgets/WidgetWeather";
import WidgetWifiLogin from "components/Section/Design/Widgets/WidgetWifiLogin";
import { SCREEN } from "constants/editor";

import { contentData, destinationData, normalizeToSearch, capitalizeFirst } from "hooks/Utils/Utils";

export const CONTENT_CATEGORY = {
    GENERAL: "GENERAL",
    WIFI: "WIFI",
    CAST: "CAST",
    MOBILE: "MOBILE",
    TV: "TV",
};

export const EDITOR_MODE = {
    EDITION: "EDITION",
    PREVIEW: "PREVIEW",
};
export const TRANSLATE_SCREEN_TYPE_TITLE = {
    TV: "tv-home",
    WELCOME: "welcome-sequences",
    SALES: "sales-sequences",
    SALESMOBILE: "sales-sequences",
    CCINSTRUCTION: "CCINSTRUCTION",
    CCPAIRINGSUCCESSFUL: "CCPAIRINGSUCCESSFUL",
    PORTAL: "portal-wifi",
    MOBILE: "tv-home",
    GENERAL: "SCREEN-TYPE-GENERAL",
    CAST: "SCREEN-TYPE-CAST",
    EXPRESSCHECKOUT: "express-checkout",
    WIFI: "WiFi",
};

export const WIDGETS_WIFI_SCREEN = ["LOGO", "TEXT", "IMAGE", "CONTAINER", "ICON", "CAROUSEL"];

export const getScreenItemActions = (screen, designID, dirID, contentTreeOptions, landings, t) => {
    let itemActions = [];
    if (screen.type === SCREEN.TYPE.SALES || screen.type === SCREEN.TYPE.SALESMOBILE) {
        itemActions.push({
            name: "edit-name",
            action: "content-edit-name",
            modal_title: "edit-name",
            modal_value: screen.name,
            modal_btn_name: "rename",
            mutationParams: {
                designID: designID,
                contentID: screen.id,
                contentType: screen.type,
                landingID: screen.landingId,
                isPublished: screen.isPublished,
                isEnabled: screen.isEnabled,
            },
        });

        itemActions.push({
            name: t("delete screen"),
            action: "content-delete",
            modal_confirm: screen.isPublished,
            modal_title: "delete",
            disabled: screen.isPublished,
            disabledTooltip: t("{{text, capitalize}}", {
                text: t("screen-cannot-delete"),
            }),
            modal_value: screen.name,
            isDefault: screen.isPublished,
            mutationParams: {
                designID: designID,
                contentID: screen.id,
                contentType: screen.type,
                landingID: screen.landingId,
            },
        });

        return itemActions;
    }
    itemActions.push({
        name: "edit-name",
        action: "content-edit-name",
        modal_title: "edit-name",
        modal_value: screen.name,
        modal_btn_name: "rename",
        mutationParams: {
            designID: designID,
            contentID: screen.id,
            contentType: screen.type,
            landingID: screen.landingId,
            isPublished: screen.isPublished,
            isEnabled: screen.isEnabled,
        },
    });
    itemActions.push({
        name: "duplicate-screen",
        mutation: "duplicate-content",
        mutationParams: {
            designID: designID,
            contentID: screen.id,
            contentType: screen.type,
            landingID: screen.landingId,
        },
    });

    if (!landings) {
        itemActions.push({
            id: dirID,
            name: "move-to",
            action: "content-move-to",
            modal_title: "move-to",
            modal_value: screen.name,
            modal_btn_name: "move-to",
            mutationParams: {
                designID: designID,
                contentID: screen.id,
                landingID: screen.landingId,
            },
            contentTree: contentTreeOptions,
        });
    }

    itemActions.push({
        name: t("delete screen"),
        action: "content-delete",
        modal_confirm: screen.isDefault,
        modal_title: "delete",
        disabled: screen.isDefault || (screen.linkedFrom && screen.linkedFrom.length > 0) || screen.isPublished,
        disabledTooltip: t("{{text, capitalize}}", {
            text: t(screen.linkedFrom && screen.linkedFrom.length > 0 ? "linked-designs" : "screen-cannot-delete"),
        }),
        modal_value: screen.name,
        isDefault: screen.isDefault,
        mutationParams: {
            designID: designID,
            contentID: screen.id,
            contentType: screen.type,
            landingID: screen.landingId,
        },
    });
    return itemActions;
};

export const getWelcomeItemActions = (screen, designId, t) => {
    let itemActions = [];
    itemActions.push({
        name: "edit-name",
        action: "welcome-edit-name",
        executeSectionQuery: true,
        modal_title: "edit-name",
        modal_value: screen.name,
        modal_btn_name: "rename",
        mutationParams: {
            designId: designId,
            welcomeId: screen.id,
            contentType: screen.type,
        },
    });
    itemActions.push({
        name: t("publish-as-default"),
        mutation: "publish-welcome-as-default",
        disabled: screen.isAssigned,
        executeSectionQuery: true,
        mutationParams: {
            designId: designId,
            welcomeId: screen.id,
            contentType: screen.type,
        },
    });

    itemActions.push({
        name: "duplicate-screen",
        mutation: "duplicate-welcome-content",
        executeSectionQuery: true,
        mutationParams: {
            designId: designId,
            welcomeId: screen.id,
            contentType: screen.type,
        },
    });

    itemActions.push({
        name: t("delete screen"),
        action: "welcome-delete",
        executeSectionQuery: true,
        modal_title: "delete",
        // modal_confirm: screen.isDefault,
        // disabled: screen.isDefault || (screen.linkedFrom && screen.linkedFrom.length > 0),
        // disabledTooltip: t("{{text, capitalize}}", {
        //     text: t(screen.linkedFrom && screen.linkedFrom.length > 0 ? "linked-designs" : "screen-cannot-delete"),
        // }),
        // isDefault: screen.isDefault,
        modal_value: screen.name,
        mutationParams: {
            designId: designId,
            welcomeId: screen.id,
            contentType: screen.type,
        },
    });
    return itemActions;
};

export const updateWidgetsFromStore = (widgets, gridItems) => {
    // eslint-disable-next-line
    widgets.map((widget) => {
        const _newData = gridItems.find((item) => parseInt(item.i) === widget.ID);
        widget.data = JSON.stringify(_newData.data);
        widget.actions = JSON.stringify({ actions: _newData.actions });
        widget.widgetStyle = JSON.stringify(_newData.widgetStyle);
    });
    return widgets;
};

export const updateSequenceFromStore = (cScreenData, gridItems) => {
    const _sequenceUpdated = cScreenData.welcome.sequence.map((screen) => {
        return {
            ...screen,
            content: {
                ...screen.content,
                pos: screen.contentId === cScreenData.data.contentId ? cScreenData.data.pos : screen.content.pos,
                contentStyle: JSON.stringify(cScreenData.contentStyle),
                contentWidgets:
                    screen.contentId === cScreenData.data.contentId
                        ? updateWidgetsFromStore(cScreenData.data.contentWidgets, gridItems)
                        : screen.content.contentWidgets,
            },
        };
    });

    return _sequenceUpdated;
};

export const STYLE_ATTRIBUTE = {
    BOLD: "bold",
    UNDERLINE: "underline",
    ITALIC: "italic",
};

export const ifStyleAttributeExist = (style, attribute) => {
    return !!(style && Object.keys(style).includes(attribute));
};

export const getNewNameByType = (screenType, t) => {
    let _newName = "new-home-screen";
    switch (screenType) {
        case SCREEN.TYPE.TV:
            _newName = "new-home-screen";
            break;
        case SCREEN.TYPE.CCINSTRUCTION:
            _newName = "new-instructions-screen";
            break;
        case SCREEN.TYPE.CCPAIRINGSUCCESSFUL:
            _newName = "new-pairing-screen";
            break;
        case SCREEN.TYPE.GENERAL:
            _newName = "new-screen";
            break;
        case SCREEN.TYPE.WELCOME:
            _newName = "new-welcome-sequence";
            break;
        case SCREEN.TYPE.PORTAL:
            _newName = "new-wifi-screen";
            break;
        case SCREEN.TYPE.SALES:
        case SCREEN.TYPE.SALESMOBILE:
            _newName = "new-sales-sequence";
            break;
        default:
            _newName = "new-home-screen";
            break;
    }
    return t("{{capitalize, capitalize}}", { capitalize: t(_newName) });
};

export const getMenuColorsAndAnimations = (widgetMenuStyles, theme, getThemeDefaultFn, widgetMenuData) => {
    return {
        bgColor: widgetMenuStyles?.bgColor ? widgetMenuStyles.bgColor : getThemeDefaultFn({ theme, id: "bgColor" }),
        fgColor: widgetMenuStyles?.fgColor ? widgetMenuStyles.fgColor : getThemeDefaultFn({ theme, id: "fgColor" }),
        iconColor: widgetMenuStyles?.iconColor
            ? widgetMenuStyles.iconColor
            : getThemeDefaultFn({ theme, id: "iconColor" }),
        bgActiveColor: widgetMenuStyles?.bgActiveColor
            ? widgetMenuStyles.bgActiveColor
            : getThemeDefaultFn({ theme, id: "bgActiveColor" }),
        fgActiveColor: widgetMenuStyles?.fgActiveColor
            ? widgetMenuStyles.fgActiveColor
            : getThemeDefaultFn({ theme, id: "fgActiveColor" }),
        iconActiveColor: widgetMenuStyles?.iconActiveColor
            ? widgetMenuStyles.iconActiveColor
            : getThemeDefaultFn({ theme, id: "iconActiveColor" }),
        bgFocusColor: widgetMenuStyles?.focusBgColor
            ? widgetMenuStyles.focusBgColor
            : getThemeDefaultFn({ theme, id: "bgFocusColor" }),
        fgFocusColor: widgetMenuStyles?.fgFocusColor
            ? widgetMenuStyles.fgFocusColor
            : getThemeDefaultFn({ theme, id: "fgFocusColor" }),
        iconFocusColor: widgetMenuStyles?.iconFocusColor
            ? widgetMenuStyles.iconFocusColor
            : getThemeDefaultFn({ theme, id: "iconFocusColor" }),
        zoomInOutAnimation: widgetMenuData?.zoomInOutAnimation ? true : false,
    };
};

export const calculateTopMobileCoverPos = (isGeneralMobile, isMobile, impersonated, uiWarnings, warningsMinimized) => {
    if (isGeneralMobile && impersonated && !uiWarnings) {
        return "14.1rem";
    } else if (isGeneralMobile && impersonated && uiWarnings && warningsMinimized) {
        return warningsMinimized ? "16.1rem" : "19.1rem";
    } else if (isGeneralMobile && uiWarnings) {
        return warningsMinimized ? "13.1rem" : "16.1rem";
    } else if (isGeneralMobile) {
        return "11.1rem";
    } else if (isMobile) {
        return impersonated ? "10.4rem" : "7.4rem";
    } else {
        return "7.4rem";
    }
};

export const arrangeGridItemToAdd = ({
    unparsedPos,
    idItem,
    itemType,
    unparsedDataItem,
    unparsedStyleItem,
    deviceType,
}) => {
    const parsePositions = JSON.parse(unparsedPos);
    const positionsByDevice = parsePositions[deviceType] ? parsePositions[deviceType] : parsePositions;
    const positionsItem = positionsByDevice
        ? positionsByDevice.filter((pos) => String(pos.ID) === String(idItem))[0]
        : null;
    let posObject = null;
    if (positionsItem && deviceType) {
        posObject = {
            [deviceType]: {
                x: positionsItem.X,
                y: positionsItem.Y,
                w: positionsItem.W,
                h: positionsItem.H,
            },
        };
    } else if (positionsItem) {
        posObject = {
            x: positionsItem.X,
            y: positionsItem.Y,
            w: positionsItem.W,
            h: positionsItem.H,
        };
    }
    const arrangePositionsItem = posObject;

    if (!arrangePositionsItem) {
        return;
    }

    const itemToAdd = {
        i: String(idItem),
        pos: arrangePositionsItem,
        deviceType: deviceType,
        type: itemType,
        data: JSON.parse(unparsedDataItem),
        style: JSON.parse(unparsedStyleItem),
        isDraggable: true,
        isResizable: true,
        resizeHandles: ["se"],
    };

    return itemToAdd;
};

export const filterBySearch = (type, value) => {
    let _return = false;

    switch (type.type) {
        case SCREEN.TYPE.TV:
        case SCREEN.TYPE.MOBILE:
        case SCREEN.TYPE.CCINSTRUCTION:
        case SCREEN.TYPE.CCPAIRINGSUCCESSFUL:
        case SCREEN.TYPE.PORTAL:
            type.landings =
                type?.landings?.filter((landing) =>
                    normalizeToSearch(landing.name).includes(normalizeToSearch(value))
                ) || [];
            if (type.landings.length > 0) {
                _return = true;
            }
            break;
        case SCREEN.TYPE.WELCOME:
            type.welcomes =
                type?.welcomes?.filter((welcome) =>
                    normalizeToSearch(welcome.name).includes(normalizeToSearch(value))
                ) || [];
            if (type.welcomes.length > 0) {
                _return = true;
            }
            break;
        case SCREEN.TYPE.SALES:
        case SCREEN.TYPE.SALESMOBILE:
            type.predefinedScreens =
                type?.predefinedScreens?.filter((sale) =>
                    normalizeToSearch(sale.name).includes(normalizeToSearch(value))
                ) || [];
            if (type.predefinedScreens.length > 0) {
                _return = true;
            }
            break;

        default:
            break;
    }
    if (_return) {
        return type;
    } else {
        return null;
    }
};

export const getDocumentElementHeight = (id) => {
    const toolbar = document.getElementById(id);
    if (toolbar) {
        return toolbar.getBoundingClientRect().height;
    } else {
        return 0;
    }
};

export const returnWidgetByType = (type, params) => {
    const GET_WIDGET = {
        TEXT: (params) => {
            return <WidgetText {...params} />;
        },
        LOGO: (params) => {
            return <WidgetLogo {...params} />;
        },
        IMAGE: (params) => {
            return <WidgetImage {...params} />;
        },
        CONTAINER: (params) => {
            return <WidgetContainer {...params} />;
        },
        ICON: (params) => {
            return <WidgetIcon {...params} />;
        },
        CAROUSEL: (params) => {
            return <WidgetCarousel {...params} />;
        },
        IFRAME: (params) => {
            return <WidgetIframe {...params} />;
        },
        HTML: (params) => {
            return <WidgetHTML {...params} />;
        },
        VIDEO: (params) => {
            return <WidgetVideo {...params} />;
        },
        QR: (params) => {
            return <WidgetQR {...params} />;
        },
        WEATHER: (params) => {
            return <WidgetWeather {...params} />;
        },
        TOPBAR: (params) => {
            return <WidgetTopBar {...params} />;
        },
        ROOMS: (params) => {
            return <WidgetRooms {...params} />;
        },
        BUTTON: (params) => {
            return <WidgetButton {...params} />;
        },
        MESSAGES: (params) => {
            return <WidgetMessages {...params} />;
        },
        CALENDAR: (params) => {
            return <WidgetCalendar {...params} />;
        },
        CLOCK: (params) => {
            return <WidgetClock {...params} />;
        },
        CHATBUTTON: (params) => {
            return <WidgetChatButton {...params} />;
        },
        LANGSELECTION: (params) => {
            return <WidgetLangSelection {...params} />;
        },
        ALARM: (params) => {
            return <WidgetAlarm {...params} />;
        },
        WIFILOGIN: (params) => {
            return <WidgetWifiLogin {...params} />;
        },
        SLIDER: (params) => {
            return <WidgetSlider {...params} />;
        },
    };
    return GET_WIDGET[type] ? GET_WIDGET[type](params) : null;
};

export const getWelcomeContentQuery = (params) => {
    const { welcomeId, designID, lang } = params;
    return `content: welcomeScreens(filter:{id:${welcomeId || 0}} designId:${designID || 0} lang:"${lang || "en"}"){
                results{
                    chainId
                    designId
                    id
                    isEnabled
                    name          
                    ptargetId
                    ptarget{
                        all
                        direct{
                            ${destinationData("welcome")}   
                        }
                    }
                    welcomeContents{
                        chainID
                        contentId
                        contentType: welcomeType
                        designId
                        pos
                        welcomeId 
                        content{
                            ${contentData()}                    
                        }
                    }
                }
            }`;
};
export const getSalesContentQuery = (params) => {
    const { salesId, designID, lang } = params;
    return `
        content: predefinedScreen(designID: ${designID || 0}, id: ${salesId || 0}, lang:"${lang || "en"}") {
          id
          designID
          isPublished
          name
          type
          data
        }
      `;
};

export const buildQueryMenusAndHomeData = (dataScreens, designId, includeMenuData = true) => {
    let query = "";
    // eslint-disable-next-line
    dataScreens.map((screen, index) => {
        if (index === 0) {
            query = query + "{\n";
        }
        query = query + "menuHomeBg" + screen.id + ": ";
        const contentInterface = `contentInterface(designID: ${Number(designId)}, id: ${Number(screen.id)}) { \n`;
        query = query + contentInterface;
        const fields = includeMenuData
            ? `menu {\n
            data
        }\n`
            : "";
        query = query + fields;
        query = query + "style";
        query = query + "}";
        if (index === dataScreens.length - 1) {
            query = query + "}";
        }
    });
    return query;
};

export const getTvScreensLandings = (screensData, TV, linkedScreens = []) => {
    let screens = [];
    screensData.contentTree.screens.categories.forEach((category) => {
        if (category && category.types && category.category === TV) {
            const screensTV = category.types.find((type) => {
                return type["landings"];
            });
            if (screensTV && screensTV.landings) {
                screensTV.landings.forEach((landing) => {
                    landing["text"] = landing && landing.name ? landing.name : "";
                    landing["value"] = landing && landing.id ? String(landing.id) : "";
                    landing["isLinked"] = linkedScreens ? linkedScreens.includes(Number(landing.id)) : false;
                });
                screens = screensTV.landings;
            }
        }
    });
    return screens;
};

export const getSectionName = (action, t) => {
    let name;
    switch (action) {
        case "tv":
            name = "channels";
            break;
        case "checkout":
            name = "express-checkout";
            break;
        case "languageSelection":
            name = "welcomeScreen-lang";
            break;
        case "roomshops":
            name = "shop-list";
            break;
        case "myorders":
            name = "my-orders-history";
            break;
        default:
            if (action.startsWith("roomshop:")) {
                name = "shop"; // TODO get Shop name (token is action.split(":")[1])
            } else {
                name = action;
            }
    }
    return capitalizeFirst(t(name) || action);
};

export const getScreens = (designId) => gql`
        {
            contentTree(designID: ${designId}) {
                screens {                   
                    dir {
                        contents {
                            id
                            name
                            isEnabled                     
                            isLinked
                            isPublished    
                            type
                        }
                        subDirs {
                            id
                            name
                            contents {
                                id
                                name
                                isEnabled
                                isLinked
                                isPublished
                                type
                            }
                        }
                    }
                }                
            }
        }
    `;

export const getScreensContentTree = (id) => {
    return `{
        contentTree(designID: ${id}) {
            screens: landings {
                categories {
                    category
                    types {
                        __typename
                        ... on ltTypeLanding {
                            type
                            landings {
                                id: contentID
                                isAssigned
                                isDefault
                                isEnabled
                                name
                                type
                                landingId: id
                            }
                        }
                    }
                }
            }
        }
    }`;
};

export const getLandingContentQuery = (params) => {
    const { landingid, designID, lang } = params;
    return `content:landing(id:"${landingid || 0}", designID:"${designID || 0}" , lang:"${lang || "en"}"){
        results{
            id
            type
            name
            isEnabled
            content{
                ${contentData()}                    
            }
            ptarget{
                    all
                    direct{
                        ${destinationData("landing")}   
                    }
            }
        }
    }`;
};
