import { capitalizeFirst } from "hooks/Utils/Utils";
import i18n from "i18n";

const NewTaxZone = (taxZoneData, taxRates) => {
    const taxZone = taxZoneData ? taxZoneData : {};
    const isNew = !taxZone.id;
    return {
        title: isNew ? "add-zone" : `${taxZone.name ? taxZone.name : ""} ${"-"} ${capitalizeFirst(i18n.t("edit"))}`,
        text: "add-tax-zone-description",
        bodyAdjust: "w-4/12",
        executeSectionQuery: true,
        id: taxZone.id,
        noTranslateTitle: true,
        inputs: [
            {
                text: ["zone-name"],
                name: "name",
                value: taxZone.name ? taxZone.name : "",
                type: "text",
                focus: true,
                style: "mb-10 w-8/12 pr-5",
            },
            {
                name: "taxes",
                type: "tax-zone-associated-vat",
                style: "",
                data: { taxRates: taxRates },
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: isNew ? "add-tax-zone" : "edit-tax-zone",
            },
        ],
    };
};

export default NewTaxZone;
