import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActionName, setActionItemAffected, setActionData } from "../../../actions/globalActions";
import { showRowForm } from "../../../actions/sectionActions";
import { setRowInEdition } from "../../../actions/tableActions";
import { toast } from "react-toastify";

const UseButtonFormLauncherCell = ({ cellData, setHideRow, rowIndex }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { rowForm } = useSelector((state) => state.sectionContent);

    //Data
    const { hidden, buttonColor, data, action, name } = cellData || {};
    const buttonType = buttonColor ? buttonColor : "blue";
    const wrapStyle = cellData?.adjust === "whitespace-no-wrap" ? "whitespace-normal" : "";

    //Actions
    const dispatch = useDispatch();

    //get data and launch form in table row
    const launchRowForm = (e) => {
        e.stopPropagation();
        if (rowForm) {
            toast.error(
                `${langStrings["please"]}, ${langStrings["save-before"]} ${langStrings["edit-anothera"]} ${langStrings["capacity"]}`
            );
        } else {
            const actionChoosen = e.currentTarget.dataset.action;
            const affectedId = e.currentTarget.dataset.id;
            setHideRow(true);
            dispatch(setRowInEdition(rowIndex));
            dispatch(setActionName(actionChoosen));
            dispatch(setActionItemAffected([affectedId]));
            dispatch(
                setActionData({
                    name: e.currentTarget.dataset.name,
                    currentCapacity: e.currentTarget.dataset.currentcapacity,
                    capacityLimit: e.currentTarget.dataset.capacitylimit,
                    currentCapacityUpdated: e.currentTarget.dataset.currentcapacityupdated,
                    isPercentage: e.currentTarget.dataset.ispercentage,
                })
            );
            dispatch(showRowForm(true));
        }
    };

    return (
        <>
            {!hidden ? (
                <div
                    className={`btn-${buttonType} w-full px-4 ${wrapStyle}`}
                    data-id={data.id}
                    data-action={action}
                    data-name={data.name}
                    data-currentcapacity={data.currentCapacity}
                    data-capacitylimit={data.capacityLimit}
                    data-currentcapacityupdated={data.currentCapacityUpdated}
                    data-ispercentage={data.isPercentage}
                    onClick={launchRowForm}
                >
                    <div className="first-capital">{langStrings[name]}</div>
                </div>
            ) : null}
        </>
    );
};

export default UseButtonFormLauncherCell;
