import React from "react";
import { useTranslation } from "react-i18next";

import { useNavigate as useHistory } from "react-router-dom";

import { useAuth } from "hooks/Session/auth";

const Impersonated = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const { user, isImpersonating } = useAuth();

    if (!isImpersonating) {
        return null;
    }

    return (
        <div id="impersonated" className="relative z-100  top-0 w-full bg-orange-100 h-12">
            <div className=" h-12 z-10 top-0 items-center flex justify-center w-full">
                <div className="first-capital">{t("impersonated user")}:</div>
                <div className="ml-2 font-bold">{user?.fullname}</div>
            </div>
            <div className="absolute h-12 z-10 top-0 right-0 items-center flex mr-4 clickable underline font-bold">
                <div
                    onClick={() => {
                        history("/logout");
                    }}
                    className="first-capital"
                >
                    {t("stop impersonating")}
                </div>
            </div>
        </div>
    );
};

export default Impersonated;
