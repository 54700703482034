import { LIST_MOBILE_APPS } from "../constants";

export const BatchActionsAllProperties = (sectionName) => {
    switch (sectionName) {
        case LIST_MOBILE_APPS:
            return [
                {
                    name: "delete",
                    action: "delete-mobile-apps",
                    executeSectionQuery: true,
                    modalInputs: [
                        {
                            type: "void",
                            adjust: "first-capital",
                            text: "delete-apps-confirm",
                        },
                        {
                            type: "batchSelection",
                            style: "mt-0 table",
                            columnPos: 0,
                        },
                    ],
                    buttons: [
                        { name: "cancel", style: "white", action: "close" },
                        { name: "delete", style: "red", action: "delete-shops-batch" },
                    ],
                },
            ];

        default:
            return [];
    }
};

export default BatchActionsAllProperties;
