import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { addWeekDay, removeWeekDay } from "../../actions/globalActions";
import { setScheduleToUpdate, setMutationRefresh, setLastScheduleEdition } from "../../actions/scheduleActions";

const UseInputCheckboxEditSchedule = ({ data, selected, index }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { scheduleToUpdate } = useSelector((state) => state.schedules);
    //States
    const [checked, setChecked] = useState(selected);
    const [checkboxStyle, setCheckboxStyle] = useState("opacity-100");
    const content = getContent(data, langStrings);

    //Actions
    const dispatch = useDispatch();

    const handleClick = (e) => {
        setChecked(checked ? false : true);
        //Get new string timeRange
        const newWeekdayList = getUpdatedWeekdaysInfo(e.currentTarget.dataset.day, scheduleToUpdate, index);

        //Create new scheduleToUpdateInfo
        const newScheduleInfo = scheduleToUpdate;
        newScheduleInfo.timetableDates[0].timetableDateRanges[index].weekdayList = `${newWeekdayList}`;
        //Update current store
        dispatch(setScheduleToUpdate(newScheduleInfo));
        //Force mutation refresh
        dispatch(setMutationRefresh(true));
        dispatch(setLastScheduleEdition());
    };

    //Listeners
    useEffect(() => {
        //ISO 8601 standard Monday(1) Sunday (7)
        if (checked) {
            setCheckboxStyle("opacity-0");
            dispatch(addWeekDay(data));
        } else {
            setCheckboxStyle("opacity-100");
            dispatch(removeWeekDay(data));
        }
        // eslint-disable-next-line
    }, [checked]);

    return (
        <div className="inline-block">
            <div className="h-10 flex items-center" onClick={handleClick} data-day={data}>
                <div className="relative w-6 h-6 mr-2">
                    <input
                        type="checkbox"
                        className="absolute w-6 h-6 z-10"
                        value={data}
                        checked={checked}
                        onChange={() => {}}
                    ></input>
                    <div className={`absolute w-6 h-6 bg-gray-200 z-100 ${checkboxStyle}`}></div>
                </div>
                <div className="h-6 flex items-center mr-6">{content}</div>
            </div>
        </div>
    );
};

const getContent = (data, langStrings) => {
    //Returns "label" content
    let res;
    if (data === "repeat-yearly") {
        res = langStrings["repeat-yearly"];
    } else {
        res = langStrings[`day-${data}-short`];
    }
    return res;
};

const getUpdatedWeekdaysInfo = (value, scheduleToUpdate, index) => {
    //Returns updated timeRange string
    let cleanWeekdays = getCleanWeekdays(scheduleToUpdate, index);
    if (cleanWeekdays.includes(value)) {
        cleanWeekdays = cleanWeekdays.filter((weekDay) => weekDay !== value);
    } else {
        cleanWeekdays.push(value);
    }
    cleanWeekdays.sort();
    if (cleanWeekdays.includes("")) {
        let indexToDelete = cleanWeekdays.indexOf("");
        cleanWeekdays.splice(indexToDelete, 1);
    }
    //Convert data to API format
    let res = `[`;

    // eslint-disable-next-line
    cleanWeekdays.map((cleanWeekday, index) => {
        if (index !== 0) {
            res += `,`;
        }
        res += `${cleanWeekday}`;
    });

    res += `]`;
    return res;
};

const getCleanWeekdays = (scheduleToUpdate, index) => {
    //Returns current string data without unnecessary chars
    let weekdays = scheduleToUpdate.timetableDates[0].timetableDateRanges[index].weekdayList;
    weekdays = weekdays.split("[").join("");
    weekdays = weekdays.split("]").join("");
    return weekdays.split(",");
};

export default UseInputCheckboxEditSchedule;
