import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { changeActionValues } from "../../actions/globalActions";
//Componets
import UseIcon from "../useIcon";
import ReactTooltip from "components/ReactTooltip";
//Utils
import sanitizeHtml from "sanitize-html";
import { multipleTranslate } from "./../../hooks/Utils/Utils";

const UseModalSwitch = ({ index }) => {
    //Store data
    const { checked, name, text, info, textAdjust, elementsUpdateDisabled } = useSelector(
        (state) => state.ui.modalContent.inputs[index]
    );

    const { langStrings } = useSelector((state) => state.ui);

    const { title } = useSelector((state) => state.ui.modalContent);

    //Actions
    const dispatch = useDispatch();

    //State
    const [isActive, setIsActive] = useState(checked);

    const switchHandler = () => {
        const switchValue = isActive ? false : true;
        dispatch(changeActionValues({ [name]: switchValue }));
        setIsActive(switchValue);
        if (elementsUpdateDisabled && elementsUpdateDisabled.length > 0) {
            let containerModal = document.querySelector(".modal-body");
            if (containerModal) {
                elementsUpdateDisabled.forEach((element) => {
                    let items = containerModal.querySelectorAll(`[${element.attr}=${element.value}]`);
                    if (items && items.length > 0) {
                        items.forEach(async (item) => {
                            item.disabled = !switchValue;
                        });
                    }
                });
            }
        }
    };
    //Listeners

    useEffect(() => {
        dispatch(changeActionValues({ [name]: checked }));
        // eslint-disable-next-line
    }, []);

    return (
        <div className="align-middle">
            <span className={textAdjust}>{multipleTranslate(text, langStrings)}</span>
            <label className="cursor-pointer switch ml-2">
                <input
                    type="checkbox"
                    id={`use-modal-switch-${title}-${index}`}
                    checked={isActive}
                    onChange={() => switchHandler()}
                ></input>
                <span className="slider round"></span>
            </label>
            {info ? (
                <div
                    className="inline-block text-lg pl-2"
                    data-tip={sanitizeHtml(multipleTranslate(info, langStrings))}
                >
                    <UseIcon icon={{ name: "info" }}></UseIcon>
                    <ReactTooltip
                        place="bottom"
                        type="light"
                        offset={{ top: -8, left: -8 }}
                        html={true}
                        border={true}
                        borderColor="#D3DAE1"
                    />
                </div>
            ) : null}
        </div>
    );
};

export default UseModalSwitch;
