import React from "react";

//Components
import UseSectionHeader from "../../../../useSectionHeader";
import UseTable from "../../../../Table/index";
import ListChannels from "../../../../../hooks/GraphqlCalls/Services/ListChannels";
import UpdateChannels from "../../../../../hooks/GraphqlCalls/Services/UpdateChannels";
import UpdateBulkChannels from "hooks/GraphqlCalls/Services/UpdateBulkChannels";

const Channels = () => {
    ListChannels();

    return (
        <>
            <UseSectionHeader title={["channel-list"]} />
            <UpdateChannels />
            <UpdateBulkChannels />
            <div>
                <UseTable />
            </div>
        </>
    );
};
export default Channels;
