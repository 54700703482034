import React, { useRef } from "react";
import { useParams } from "react-router-dom";

import SalesProvider from "contexts/Sales";
import ShopProvider from "contexts/Sales/Shop";
import { SalesCategoriesProvider } from "./SalesContext";

import EditShopCategories from "./EditShopCategories";

const EditShopCategoriesParent = () => {
    const salesRef = useRef(null);
    const contextRef = useRef(null);
    const { id: shopId } = useParams();

    return (
        <SalesProvider ref={salesRef}>
            <ShopProvider ref={contextRef} id={shopId}>
                <SalesCategoriesProvider>
                    <EditShopCategories />
                </SalesCategoriesProvider>
            </ShopProvider>
        </SalesProvider>
    );
};

export default EditShopCategoriesParent;
