import React from "react";
import { getDocumentElementHeight } from "../../../../hooks/Utils/Design/EditorUtils";
import { remToPixel } from "../../../../hooks/Utils/Utils";
import { useSelector } from "react-redux";
import { SALES_TOOLBAR } from "../../../../hooks/Utils/Design/SalesUtils";
import SalesGeneralSettings from "./SalesGeneralSettings";
import SalesStyleEditor from "./stylesEditor/SalesStyleEditor";
import { useTranslation } from "react-i18next";
import UpdateSalesStyle from "../../../../hooks/GraphqlCalls/Sales/UpdateSalesStyle";

const SalesConfigPanel = () => {
    const { t } = useTranslation();

    const { salesToolbar } = useSelector((state) => state.sectionContent);

    if (!salesToolbar) {
        return null;
    }

    return (
        <div id="salesConfigPanel" className="float-right mt-2 w-full bg-white">
            <UpdateSalesStyle />
            <div id="changesAppliedSales" className="border-b border-gray-300 p-4">
                {t("changes-shall-applied")}
            </div>
            {salesToolbar === SALES_TOOLBAR.SETTINGS ? <SalesGeneralSettings /> : <SalesStyleEditor />}
        </div>
    );
};

export default SalesConfigPanel;
