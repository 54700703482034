import { useEffect, useState } from "react";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { POLLINTERVAL_15S } from "../../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import {
    setLoading,
    setItemsPerpage,
    setHeaders,
    setHeaderButtons,
    setCustomError,
    setSearchable,
    setCheckable,
    setActions,
    setTableResults,
    setCount,
    setActiveFilters,
    setAvailableFilters,
    setBatchActive,
    setRefreshData,
    setCountPosition,
    cleanCheckedItems,
} from "../../../actions/tableActions";
import GetTableHeaders from "../../Utils/Table/Headers/GetServicesTableHeaders";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import { useTranslation } from "react-i18next";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import { GetCountriesOptions } from "../../Utils/CountriesUtils";
import { GetLanguageOptions } from "../../Utils/LanguagesUtils";
import PredefinedChannel from "./models/PredefinedChannel";
import { showRowForm, setHiddenRows } from "../../../actions/sectionActions";
import { cleanAction } from "../../../actions/globalActions";

const ListPredefinedChannels = () => {
    const dispatch = useDispatch();
    const SECTION_NAME = "list-predefined-channels";
    const { t } = useTranslation();
    const { permissions } = useSelector((state) => state.ui);
    const [filterQuery, setFilterQuery] = useState("");

    const { refreshData, currentPage, perPage, sortCriteria, activeFilters, rowInEdition } = useSelector(
        (state) => state.table
    );

    const GET_DATA = gql(`
        query{
            predefinedChannelsSummary(page:${currentPage} , size:${perPage}, 
            ${sortCriteria ? sortCriteria : `orderBy: { field: "name", criteria: "asc" }`}
            ${filterQuery ? filterQuery : ""}
                ){
                info {
                count
                page
                size
                }
                results {
                id
                name
                isRadio
                usedInProjects
                usedInProjectsNum
                countryRef
                logoRef
                languageRef
                }
            }
        }
      `);

    const [executeQuery, { data, refetch, loading }] = useLazyQuery(GET_DATA, {
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeQuery();
        dispatch(setItemsPerpage(9));
        dispatch(setLoading(true));
        dispatch(setHeaders(GetTableHeaders(SECTION_NAME)));
        dispatch(setCustomError(t("no-channels-yet")));
        dispatch(setSearchable(true));
        dispatch(setBatchActive(BatchActions()));
        dispatch(setHeaderButtons(GetTableHeaderButton(SECTION_NAME, {}, null, t)));
        dispatch(setCheckable(true));
        const availableFilters = GetAvailableFilters(
            SECTION_NAME,
            arrangeFiltersDataOptions(GetCountriesOptions(), GetLanguageOptions())
        );
        dispatch(setAvailableFilters(availableFilters));
        dispatch(setHeaders(GetTableHeaders(SECTION_NAME)));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (activeFilters.language || activeFilters.country || activeFilters.isRadio || activeFilters.search) {
            setFilterQuery(buildFilterQuery(activeFilters));
        } else {
            setFilterQuery("");
        }
        // eslint-disable-next-line
    }, [activeFilters]);

    useEffect(() => {
        if (refreshData && refetch) {
            refetch();
            dispatch(cleanCheckedItems());
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (data?.predefinedChannelsSummary?.results) {
            setTimeout(() => {
                dispatch(setLoading(false));
            }, 2000);
        }
        //eslint-disable-next-line
    }, [loading, sortCriteria]);

    useEffect(() => {
        if (data?.predefinedChannelsSummary?.results) {
            const arr = data.predefinedChannelsSummary.results;
            const arrangedData = arrangeData(arr, t);
            dispatch(setTableResults(arrangedData));
            dispatch(setLoading(false));
            dispatch(setCount(data.predefinedChannelsSummary.info.count));
            dispatch(setCountPosition("table-header"));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        dispatch(showRowForm(false));
        dispatch(cleanAction());
        dispatch(setHiddenRows(true));
        // eslint-disable-next-line
    }, [currentPage]);

    const arrangeData = (data) => {
        return data.map((c) => PredefinedChannel(c, t));
    };

    return null;
};

export default ListPredefinedChannels;

const BatchActions = () => {
    const actions = [
        {
            name: "delete-channels",
            action: "delete-predefined-channels",
            executeSectionQuery: true,
            bodyAdjust: "w-3/12",
            modalInputs: [
                {
                    type: "void",
                    adjust: "first-capital",
                    text: "delete-predefined-channels-confirm",
                },
                {
                    type: "batchSelectionTable",
                    style: "mt-0",
                    columnPos: 0,
                    columnName: "channels_",
                    columnItems: "channels_",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                { name: "delete", style: "red", action: "delete-predefined-channels" },
            ],
        },
    ];
    return actions;
};

const arrangeFiltersDataOptions = (countriesData, languagesData) => {
    const countriesOptions = countriesData.map((c) => ({ id: c.id, value: c.name, visible: true }));
    const languagesOptions = languagesData.map((l) => ({ id: l.id, value: l.name, visible: true }));

    return { countriesOptions, languagesOptions };
};

const buildFilterQuery = (activeFilters) => {
    const filter = [];

    if (activeFilters.language) {
        filter.push(`languageRefs:${activeFilters.language}`);
    }

    if (activeFilters.country) {
        filter.push(`countryRefs:${activeFilters.country}`);
    }

    if (activeFilters.isRadio) {
        const isRadioValue = activeFilters.isRadio === "tv" ? "false" : "true";
        filter.push(`isRadio:${isRadioValue}`);
    }

    if (activeFilters.search) {
        filter.push(`search:"${activeFilters.search}"`);
    }

    return `filter:{${filter.join(",")}}`;
};
