import React from "react";

//Components
import UseIcon from "../../../useIcon";
import UseItemActions from "../../../useItemActions";

//Utils
import ReactTooltip from "components/ReactTooltip";

const TopologyItem = ({ data, active, showOptions, res }) => {
    //Data
    const { name, number, numRoomTV, isRoom, isFloor, isWing, parentSectionName, recentGuests = false } = data;
    const sectionName = `${parentSectionName}-item`;

    return (
        <div className={`map-item-container ${isRoom ? "py-2" : ""} table map-item-${active ? "active" : ""}`}>
            <div
                className={`block overflow-hidden whitespace-no-wrap overflow-ellipsis ${
                    isRoom ? "w-9/12 leading-4" : "w-full"
                }`}
            >
                <a data-tip data-for={`${name}_complete_tooltip`} href={() => false}>
                    <div>{isRoom ? number : name}</div>
                    {isRoom ? <div className="text-sm inline-block">{name}</div> : null}
                </a>
                {name.length > 9 ? (
                    <ReactTooltip id={`${name}_complete_tooltip`} delayShow={1000} type="light">
                        {name}
                    </ReactTooltip>
                ) : null}
            </div>
            {isWing ? (
                <div
                    id={`${sectionName}-actions-container`}
                    className={`field-icon-map ${!showOptions ? "hidden" : ""}`}
                >
                    <div className="float-left text-gray-900 text-base">
                        <UseItemActions
                            dataType="topology"
                            id={data.id}
                            executeSectionQuery={true}
                            actions={[
                                {
                                    name: "edit-name",
                                    action: "topology-edit-wingname",
                                    modal_title: "edit name",
                                    modal_value: data.name,
                                    modal_btn_name: "rename",
                                    id: `${sectionName}-action-edit-name`,
                                    modal_action_values: { data: res, id: data?.id },
                                },
                                {
                                    name: "edit-pmsnumber",
                                    action: "topology-edit-pmsnumber",
                                    modal_title: "edit name",
                                    modal_value: data.defaultPmsFormat,
                                    modal_btn_name: "rename",
                                    id: `${sectionName}-action-rename`,
                                },
                                {
                                    name: "delete",
                                    action: "topology-delete-wing",
                                    modal_title: "delete",
                                    modal_value: data.name,
                                    id: `${sectionName}-action-delete`,
                                },
                            ]}
                            containerAdjust="w-48"
                        />
                    </div>
                </div>
            ) : null}
            {isFloor ? (
                <div
                    id={`${sectionName}-actions-container`}
                    className={`field-icon-map ${!showOptions ? "hidden" : ""}`}
                >
                    <div className="float-left text-gray-900 text-base">
                        <UseItemActions
                            dataType="topology"
                            id={data.id}
                            executeSectionQuery={true}
                            actions={[
                                {
                                    name: "edit-name",
                                    action: "topology-edit-floorname",
                                    modal_title: "edit name",
                                    modal_value: data.name,
                                    modal_btn_name: "rename",
                                    id: `${sectionName}-action-rename`,
                                },
                                {
                                    name: "delete",
                                    action: "topology-delete-floor",
                                    modal_title: "delete",
                                    modal_value: data.name,
                                    id: `${sectionName}-action-delete`,
                                },
                            ]}
                            containerAdjust="w-48"
                        />
                    </div>
                </div>
            ) : null}
            {isRoom ? (
                <div id={`${sectionName}-actions-container`} className="field-icon-map">
                    {showOptions ? (
                        <div className="float-left text-gray-900 text-base">
                            <UseItemActions
                                dataType="topology"
                                id={data.id}
                                executeSectionQuery={true}
                                actions={[
                                    {
                                        name: "edit-room",
                                        link: `/hotel/property-settings/topology/edit-room/${data.id}`,
                                        id: `${sectionName}-action-edit-room`,
                                    },
                                    {
                                        name: "delete",
                                        action: "topology-delete-room",
                                        modal_title: "delete",
                                        modal_value: data.name,
                                        recentGuests: recentGuests,
                                        id: `${sectionName}-action-delete`,
                                    },
                                ]}
                                containerAdjust="w-48"
                            />
                        </div>
                    ) : (
                        <>
                            <div className="float-left text-xl">
                                <UseIcon icon={{ name: "tv-solid" }} />
                            </div>
                            <div className="pl-1">{numRoomTV}</div>
                        </>
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default TopologyItem;
