const EditRoomGroupModal = (roomName, roomId) => {
    let modalInputs = [];
    modalInputs.push({
        name: "room-groups",
        optionsQuery: `{
                data: 
                roomGroups{
                  info { count }
                  results { id name  }
                }  selected: rooms(id:${roomId}){results{roomGroups{id}}}
            }`,
        selectedIdsPath: "data.selected.results[0].roomGroups",
        selectPlaceHolder: "select-group",
        cStyle: "w-full",
        sort: true,
        type: "selectMultipleWithSearch",
    });
    let modal = {
        title: "assign-to-group",
        value: roomName,
        inputs: modalInputs,
        buttons: [{ name: "cancel", style: "white", action: "close" }],
    };
    modal.buttons.push({
        name: "assign-to-group",
        style: "blue",
        action: "room-group-assign",
    });
    return modal;
};

export default EditRoomGroupModal;
