import React from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseButton from "../../components/useButton";

//Actions
import { showRowForm, setExecuteQuery, setHiddenRows } from "../../actions/sectionActions";
import { cleanAction, setActionValidations } from "../../actions/globalActions";
//Utils
import { toast } from "react-toastify";
import UseEditableField from "../useEditableField";
import { validateSection } from "../../hooks/Utils/Validations";
import { useTranslation } from "react-i18next";

const UseFormPropertyTag = () => {
    const { t } = useTranslation();
    const { rowFormContent, results } = useSelector((state) => state.table);

    const { values, actionName, itemsAffected } = useSelector((state) => state.action);
    const { tagData } = useSelector((state) => state.sectionContent);

    const typeURL = tagData && tagData.target === "URL";

    //Actions
    const dispatch = useDispatch();

    const cancelAction = (e) => {
        dispatch(showRowForm(false));
        dispatch(cleanAction());
        dispatch(setHiddenRows(true));
    };

    const saveAction = (e) => {
        //Validate fields
        if (typeURL) {
            const validations = validateSection(actionName, values, t);
            dispatch(setActionValidations(validations));
            const validationsArray = Object.values(validations);

            if (validationsArray.filter((item) => item === false).length > 0) {
                toast.error(t("type a valid URL"));
                return;
            }

            const urlPattern =
                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm;
            const isAValidUrl = urlPattern.test(values["property-value"]);

            if (isAValidUrl === false) {
                toast.error(t("type a valid URL"));
                return;
            }
        }
        dispatch(
            setExecuteQuery({
                action: actionName,
                params: {
                    projectId: itemsAffected,
                    cValue: setValue("value"),
                },
            })
        );

        dispatch(showRowForm(false));
        dispatch(setHiddenRows(true));
    };

    const setValue = (name) => {
        let initVal = "";
        results.filter((result) =>
            result.id === itemsAffected
                ? result.info.map((cellData) =>
                      cellData.name === name && cellData.value !== t("Unspecified") ? (initVal = cellData.value) : null
                  )
                : null
        );

        return initVal;
    };

    return (
        <>
            {rowFormContent ? (
                <div className="border-b border-gray-200">
                    <div
                        className="w-full bg-white  h-auto flex items-center "
                        style={{ minHeight: typeURL ? "5rem" : "4rem" }}
                    >
                        <UseEditableField
                            data={{
                                label: "property-name",
                                placeholder: "name",
                                inEditionMode: false,
                                type: "text",
                                styleAdjust: `pr-2 pl-4 w-3/12`,
                                value: setValue("property"),
                                noLabel: true,
                            }}
                        />
                        <div className="pr-2 w-5/12 pt-3">
                            <UseEditableField
                                data={{
                                    id: `property-value-id`,
                                    label: "property-value",
                                    placeholder: typeURL ? "www.newurl.com" : "type-the-value",
                                    focusInEdition: true,
                                    inEditionMode: true,
                                    type: "text",
                                    styleAdjust: ``,
                                    value: setValue("value"),
                                    noLabel: true,
                                    noCapitalizedPH: true,
                                }}
                            />
                            {typeURL ? <div className="mt-1 text-sm text-gray-700">*{t("valid-url")}</div> : null}
                        </div>

                        <div className="w-4/12 text-right inline-block whitespace-no-wrap pl-2  r-0 pr-2">
                            <div className={`inline-block whitespace-normal pr-2`} onClick={(e) => cancelAction(e)}>
                                <UseButton
                                    id={"cancel-button-property-values"}
                                    buttonName={t("cancel")}
                                    buttonColor={"btn-white"}
                                />
                            </div>
                            <div className={`inline-block  whitespace-normal`} onClick={(e) => saveAction(e)}>
                                <UseButton
                                    id={"save-button-property-values"}
                                    buttonName={t("save")}
                                    buttonColor={"btn-blue"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default UseFormPropertyTag;
