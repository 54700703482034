import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//Components
import ScreenMargins from "./OptionsDetails/ScreenMargins";

//Actions
import { setWidgetToolbarVisible } from "../../../../../actions/designActions";
import AddImage from "./CommonOptions/AddImage";

//Utils
import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Input from "./CommonOptions/Input";
import Actions from "./CommonOptions/Actions";
import { EditorContext, WidgetContext } from "../../../../../contexts/editor";
import { SCREEN } from "constants/editor";

const ContainerToolbar = () => {
    const { deviceType, itemSelected } = useContext(EditorContext);
    const { id: widgetID } = useContext(WidgetContext);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { type: screenType } = useParams();

    const { gridItems, widgetToolbarVisible } = useSelector((state) => state.design);
    const sectionName = `toolbar`;

    useEffect(() => {
        if (widgetToolbarVisible === "container-settings" && itemSelected === widgetID) {
            //TODO
        }
        // eslint-disable-next-line
    }, [widgetToolbarVisible]);

    useEffect(() => {
        if (itemSelected === widgetID) {
            //TODO
        }
        // eslint-disable-next-line
    }, [gridItems]);

    return (
        <>
            <div
                id={`${sectionName}-settings`}
                className={`icon icon-settings-editor cursor-pointer p-2 ${
                    widgetToolbarVisible === "container-settings" ? "toolbarOptionSelected" : ""
                }`}
                onClick={(e) =>
                    dispatch(
                        setWidgetToolbarVisible(
                            widgetToolbarVisible === "container-settings" ? null : "container-settings"
                        )
                    )
                }
            ></div>
            {widgetToolbarVisible === "container-settings" && itemSelected === widgetID ? (
                <div
                    id={`widgetOptionContainer_${widgetID}`}
                    className={`widgetOptionContainer invisible p-0`}
                    style={{ width: "28rem" }}
                >
                    <ScreenMargins deviceType={deviceType} />
                    <AddImage multipleImages={true} />
                </div>
            ) : null}
            <Toolbar type="style">
                <Settings>
                    <Settings.Group type="colors">
                        <Input.Color id="bgColor" title={t("background")} />
                    </Settings.Group>
                    <Settings.Border t={t} />
                </Settings>
            </Toolbar>
            {screenType !== SCREEN.TYPE.PORTAL ? (
                <Toolbar type="actions">
                    <Actions
                        actions={{
                            content: true,
                            landing: true,
                            section: true,
                            url: deviceType !== "TV",
                            library: true,
                            tvchannel: true,
                        }}
                    />
                </Toolbar>
            ) : null}
            {/* <Toolbar type="clone" /> */}
            <Toolbar type="delete" />
        </>
    );
};

export default ContainerToolbar;
