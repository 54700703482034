import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import Table from "components/ZafiroTable";
import { useDeleteSignages } from "../graphql/useSignages";
import Loading from "components/Loading";

const ModalDeleteSequence = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { deleteSignages } = useDeleteSignages({
        onCompleted: () => {
            setLoading(false);
            props.getSignages();
            props.close();
        },
    });

    const handleSaveClick = () => {
        setLoading(true);
        deleteSignages({ variables: { ids: props.batch ? props.batchList.map((val) => val.id) : [props.id] } });
    };

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={props.batch ? t("delete") : `${props.name} - ${t("delete")}`}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="red" id="modal-button-delete" disabled={loading} onClick={handleSaveClick}>
                        {t("delete")}
                    </Button>
                </>
            }
            className={"w-5/12 p-10"}
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <div>
                    <p>
                        {props.batch
                            ? t("are-you-sure-you-want-to-delete-the-following-sequences")
                            : t("are-you-sure-you-want-to-delete-this-sequence")}
                    </p>
                    {props.batch && (
                        <Table
                            id={"signage-duplicate-batch-table"}
                            cols={["name"]}
                            className={" border border-gray-100 p-2 max-h"}
                            maxHeight={"max-h-30"}
                            header={{
                                name: { title: "" },
                            }}
                            rows={props.batchList}
                        ></Table>
                    )}
                    {props.isOnGoing && (
                        <div className="flex gap-2 mt-2">
                            <Icon type={"warning"} size={"2xl"}></Icon>
                            <p>{t("it-is-possible-that-this-sequence-is-being-displayed-on-some-tv")}</p>
                        </div>
                    )}
                </div>
            )}
        </Modal>
    );
};

export const UseModalDeleteSequence = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalDeleteSequence {...newProps} />);
        },
    };
};
