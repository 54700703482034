import React from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../../../../hooks/Utils/Utils";

import { useNavigate as useHistory, useLocation } from "react-router-dom";
import { Session } from "../../../../../hooks/Utils/Session";

const HeaderPredefinedChannels = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    return (
        <div style={{ height: "72px" }} className="w-full flex h-48 shadow-sm fixed z-100 bg-white">
            <button
                onClick={() => {
                    if (location.pathname === "/services/tv-channels/preset-channels") {
                        if (Session.isCorporate()) {
                            history("/services/tv-channels/corporate-channels");
                        } else {
                            history("/services/tv-channels/channels");
                        }
                    } else {
                        history("/services/tv-channels/preset-channels");
                    }
                }}
                style={{ width: "10%" }}
                className=" focus:outline-none flex items-center justify-center border-r border-gray-200 text-gray-800  "
            >
                <span className=" icon icon-chevron-left mt-1 pr-2 text-xl "></span>
                <span className=" text-lg ">{capitalizeFirst(t("back"))}</span>
            </button>
            <div style={{ width: "90%" }} className=" px-6 h-full font-bold text-lg flex items-center">
                {t("manage-of-preset-channels-database")}
            </div>
        </div>
    );
};

export default HeaderPredefinedChannels;
