import { Session } from "hooks/Utils/Session";
import DeleteTagModal from "../modals/DeleteTagModal";

const Tag = (tag, t) => {
    const dataType = "tag";

    const { id, target, name, description } = tag;

    let tagName = name;

    //este tryCatch es porque el name viene como string o json si accedo al detalle de un tag
    try {
        const namesList = JSON.parse(name);
        if (typeof namesList === "object") {
            const lang = Session.getLang() || "en";
            tagName = namesList && namesList[lang] ? namesList[lang] : "unnamed";
        }
    } catch (error) {}

    const getTargetColor = (target) => {
        return target === "TEXT" ? "#2286EF" : "#06376B";
    };

    return {
        dataType: dataType,
        id: id,
        adjust: "h-auto",
        executeSectionQuery: true,
        actions: [{ type: dataType, value: id }],
        mutationParams: {
            id: id,
        },
        info: [
            {
                name: "name",
                cellContent: "link",
                value: tagName,
                link: `/design/tags/detail/${id}`,
                specialWidth: "w-3/12",
            },
            {
                name: "type",
                cellContent: "labels",
                value: [[0, (t(target.toLowerCase()) || target).toUpperCase(), getTargetColor(target)]],
                specialWidth: "w-2/12",
                adjust: "truncate",
            },
            {
                name: "description",
                cellContent: "text",
                value: description,
                specialWidth: "w-6/12",
            },
            {
                name: "tag",
                cellContent: "icon",
                isAction: true,
                modal: DeleteTagModal(name, id, t),
                icon: {
                    name: "delete",
                },

                specialWidth: "w-0.5/12 justify-center",
            },
        ],
    };
};

export default Tag;
