import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    generateCardsInfo,
    getFieldsCodeData,
    getFieldsEmailData,
    getFieldsPmsData,
    getFieldsOpenData,
    getValueFromData,
    UPDATE_EMAIL,
    UPDATE_PASSCODE,
    UPDATE_OPEN,
    UPDATE_PMS,
    RenderSecondStep,
    RenderFirstStep,
    generateCardsIDs,
    isFieldEnabled,
    isFieldMandatory,
    preventNegativeValues,
} from "./Utils";
import { useDispatch } from "react-redux";
import { showGlobalLoading } from "../../../../actions/uiActions";
import { useNavigate as useHistory } from "react-router-dom";
import UseSectionHeader from "../../../useSectionHeader";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import { Session } from "../../../../hooks/Utils/Session";
import AccessTypesCard from "./AccessTypesCard";
import UpdateAccessTypes from "./UpdateAccessTypes";
import Widget from "../../../Widget";
import { DEVICE } from "constants/editor";
import { GetCountriesOptions } from "../../../../hooks/Utils/CountriesUtils";

const AccessTypes = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [cardsIDs, setCardsIds] = useState(null);
    const [cardsInfo, setCardsInfo] = useState(null);
    const [cardEdition, setCardEdition] = useState(null);
    const [hotspotName, setHotspotName] = useState(null);
    const [mainSwitches, setMainSwitches] = useState(null);
    const [updateSwitch, setUpdateSwitch] = useState(false);
    const [pmsData, setPmsData] = useState(null);
    const [emailData, setEmailData] = useState(null);
    const [codeData, setCodeData] = useState(null);
    const [openData, setOpenData] = useState(null);
    const [emailFields, setEmailFields] = useState({});
    const [emailFieldsBackup, setEmailFieldsBackup] = useState({});
    const [pmsFields, setPmsFields] = useState({});
    const [pmsFieldsBackup, setPmsFieldsBackup] = useState({});
    const [codeFields, setPasscodeFields] = useState({});
    const [openFields, setOpenFields] = useState({});
    const [codeFieldsBackup, setPasscodeFieldsBackup] = useState({});
    const [openFieldsBackup, setOpenFieldsBackup] = useState({});
    const [previewSelected, setPreviewSelected] = useState(null);
    const [stepPreview, setStepPreview] = useState(false);
    const [keepCurrentPreview, setKeepCurrentPreview] = useState(false);
    const [askMarketing, setAskMarketing] = useState(false);
    const [selectMutation, setSelectMutation] = useState(null);
    const previewContainer = useRef(null);
    const { permissions } = useSelector((state) => state.ui);
    const hasGuest = permissions?.product?.guest;

    const pmsUnlimitedDevices = pmsFields ? pmsFields["pms-devices-option"] : null;

    const ACCESS_TYPES = gql`
        ${getQuery(hotspotName)}
    `;

    const [executeQueryAT, { data: dataAT, refetch }] = useLazyQuery(ACCESS_TYPES, {
        fetchPolicy: "network-only",
    });

    const [previewContainerWidth, setPreviewContainerWidth] = useState(400);
    useLayoutEffect(() => {
        function updateSize() {
            if (previewContainer.current) {
                setPreviewContainerWidth(previewContainer.current.offsetWidth);
            }
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, [previewContainer.current]);

    //LISTENERS
    useEffect(() => {
        setHotspotName(Session.getSessionProp("accesstype-hotspot-name"));
        setCardsIds(generateCardsIDs(hasGuest));
        executeQueryAT();
        dispatch(showGlobalLoading(true));
        return () => {
            Session.removeSessionProp("accesstype-hotspot-name");
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (cardsIDs) {
            const cards = generateCardsInfo(cardsIDs);
            if (hasGuest) {
                setCardsInfo(cards);
            } else {
                setCardsInfo(cards.filter((card, index) => index !== 1));
            }
        }
        // eslint-disable-next-line
    }, [cardsIDs]);

    useEffect(() => {
        const results = dataAT?.wifiAccessTypes?.results;
        if (results) {
            if (results.length > 0) {
                setPmsData(results.find((result) => result.accesstypes_name === "pms"));
                setEmailData(results.find((result) => result.accesstypes_name === "email"));
                setCodeData(results.find((result) => result.accesstypes_name === "code"));
                setOpenData(results.find((result) => result.accesstypes_name === "open"));
                dispatch(showGlobalLoading(false));
                if (dataAT.conditions) {
                    setAskMarketing(dataAT.conditions.askMarketing);
                }
            } else {
                dispatch(showGlobalLoading(false));
                history("/hotel/property-settings/wifi/manager");
            }
        }
        // eslint-disable-next-line
    }, [dataAT]);

    useEffect(() => {
        if (emailData) {
            const fields = getFieldsEmailData(emailData);
            setEmailFields({
                ...emailFields,
                ...fields,
            });
            setEmailFieldsBackup({ ...fields });
        }
        // eslint-disable-next-line
    }, [emailData]);

    useEffect(() => {
        if (pmsData) {
            const fields = getFieldsPmsData(pmsData);
            setPmsFields({
                ...pmsFields,
                ...fields,
            });
            setPmsFieldsBackup({ ...fields });
        }
        // eslint-disable-next-line
    }, [pmsData]);

    useEffect(() => {
        if (codeData) {
            const fields = getFieldsCodeData(codeData);
            setPasscodeFields({
                ...codeFields,
                ...fields,
            });
            setPasscodeFieldsBackup({ ...fields });
        }
        // eslint-disable-next-line
    }, [codeData]);

    useEffect(() => {
        if (openData) {
            const fields = getFieldsOpenData(openData);
            setOpenFields({
                ...openFields,
                ...fields,
            });
            setOpenFieldsBackup({ ...fields });
        }
        // eslint-disable-next-line
    }, [openData]);

    useEffect(() => {
        if (pmsData && codeData && emailData) {
            setMainSwitches({
                email: getValueFromData(false, emailData, "enable", true),
                pms: getValueFromData(false, pmsData, "enable", true),
                code: getValueFromData(false, codeData, "enable", true),
                open: getValueFromData(false, openData, "enable", true),
            });
        }
        // eslint-disable-next-line
    }, [pmsData, codeData, emailData]);

    const storeSwitch = () => {
        switch (updateSwitch.atToUpdate) {
            case "email":
                setSelectMutation(UPDATE_EMAIL);
                break;
            case "pms":
                setSelectMutation(UPDATE_PMS);
                break;
            case "code":
                setSelectMutation(UPDATE_PASSCODE);
                break;
            case "open":
                setSelectMutation(UPDATE_OPEN);
                break;
            default:
                break;
        }
        setUpdateSwitch(false);
    };

    const storeCurrentPreview = () => {
        let resetStep = false;
        let preview;

        if (mainSwitches?.email) {
            resetStep = cardEdition !== cardsIDs[0] && previewSelected !== "email";
            preview = "email";
        } else if (mainSwitches?.pms) {
            resetStep = cardEdition !== cardsIDs[1] && previewSelected !== "pms";
            preview = "pms";
        } else if (mainSwitches?.code) {
            resetStep = cardEdition !== cardsIDs[2] && previewSelected !== "code";
            preview = "code";
        } else if (mainSwitches?.open) {
            resetStep = cardEdition !== cardsIDs[2] && previewSelected !== "open";
            preview = "open";
        }

        if (resetStep) {
            setStepPreview(null);
        }
        setPreviewSelected(preview);
        setKeepCurrentPreview(false);
    };

    useEffect(() => {
        if (mainSwitches) {
            if (updateSwitch?.update) {
                storeSwitch();
            }
            if (keepCurrentPreview === false) {
                storeCurrentPreview();
            }
        }
        // eslint-disable-next-line
    }, [mainSwitches]);

    useEffect(() => {
        const pmsDevicesInput = document.getElementById("pms-devices-per-room");

        if (pmsUnlimitedDevices === "unlimited" && pmsDevicesInput) {
            pmsDevicesInput.value = "";
        } else if (pmsUnlimitedDevices === "custom" && pmsDevicesInput) {
            pmsDevicesInput.value = 1;
        }
        // eslint-disable-next-line
    }, [pmsUnlimitedDevices]);

    const renderEmptyPreview = () => {
        return (
            <div className=" bg-white rounded px-6 pt-6" style={{ paddingBottom: "35rem" }}>
                {t("no-access-types-activated-yet")}
            </div>
        );
    };

    const evalFields = () => {
        switch (previewSelected) {
            case "email":
                return emailFields;
            case "pms":
                return pmsFields;
            case "code":
                return codeFields;
            case "open":
                return openFields;
            default:
                return null;
        }
    };

    useEffect(() => {
        switch (previewSelected) {
            case "email":
                setEmailFields({ ...emailFieldsBackup });
                break;
            case "pms":
                setPmsFields({ ...pmsFieldsBackup });
                break;
            case "code":
                setPasscodeFields({ ...codeFieldsBackup });
                break;
            case "open":
                setOpenFields({ ...openFieldsBackup });
                break;
            default:
                break;
        }
    }, [previewSelected]);

    const widgetField = (field) => {
        return activeType && field
            ? {
                  enabled: isFieldEnabled(activeFields, `${activeType}-${field}`),
                  required: isFieldMandatory(activeFields, `${activeType}-${field}`),
              }
            : null;
    };

    const loginTypes = mainSwitches ? Object.keys(mainSwitches).filter((v) => mainSwitches[v]) : null;
    const activeType = previewSelected;
    const activeFields = evalFields();
    const defaultField = { enabled: true, required: true };
    const signupFields = {
        name: widgetField("name"),
        surname: widgetField("surname"),
        birth: widgetField("date-of-birth"),
        country: widgetField("country"),
    };
    const signupEnabled =
        (activeType === "email" || widgetField("email")?.enabled) &&
        Object.values(signupFields).filter((v) => v?.enabled).length > 0;

    const loginEvents = {
        back: () => {
            setStepPreview(1);
        },
        updateType: (type) => {
            setPreviewSelected(type);
        },
    };

    const loginStyle = {
        bgColor: "white",
        fgColor: "rgb(46, 56, 67)",
        radius: 0,
        status: {
            active: {
                fgColor: "rgb(15, 99, 189)",
            },
        },
        items: {
            button: {
                bgColor: "rgb(15, 99, 189)",
                fgColor: "white",
                radius: 0.5,
                font: { name: "Lato" },
                size: 1,
            },
        },
    };

    useEffect(() => {
        if (stepPreview === 2 && !signupEnabled) {
            setStepPreview(1);
        }
    }, [signupEnabled]);

    return (
        <>
            <UseSectionHeader title={["manage-access-types"]} navToSection="/hotel/property-settings/wifi/manager" />
            <div className=" flex w-full">
                <div className=" w-2/3 text-center mr-6 ">
                    <span className=" block text-xl font-bold mb-6">{capitalizeFirst(t("access-types"))}</span>
                    {cardsInfo?.map((card) => (
                        <AccessTypesCard
                            key={card.name}
                            info={card}
                            cardEdition={cardEdition}
                            setCardEdition={setCardEdition}
                            mainSwitches={mainSwitches}
                            setMainSwitches={setMainSwitches}
                            setPreviewSelected={setPreviewSelected}
                            setStepPreview={setStepPreview}
                            setKeepCurrentPreview={setKeepCurrentPreview}
                            setUpdateSwitch={setUpdateSwitch}
                            cardsInfo={cardsInfo}
                            setSelectMutation={setSelectMutation}
                            backupFields={{
                                emailFieldsBackup: emailFieldsBackup,
                                pmsFieldsBackup: pmsFieldsBackup,
                                codeFieldsBackup: codeFieldsBackup,
                                openFieldsBackup: openFieldsBackup,
                            }}
                            accessTypesData={{
                                pmsData: pmsData,
                                emailData: emailData,
                                codeData: codeData,
                                openData: openData,
                            }}
                            fields={{
                                emailFields: emailFields,
                                pmsFields: pmsFields,
                                codeFields: codeFields,
                                openFields: openFields,
                            }}
                            setFields={{
                                setEmailFields: setEmailFields,
                                setPmsFields: setPmsFields,
                                setCodeFields: setPasscodeFields,
                                setOpenFields: setOpenFields,
                            }}
                        />
                    ))}
                </div>
                <div ref={previewContainer} className=" w-1/3 text-center ">
                    <span className=" text-xl font-bold block mb-6">{capitalizeFirst(t("preview"))}</span>
                    <div>
                        {!loginTypes || loginTypes.length == 0 ? (
                            renderEmptyPreview()
                        ) : (
                            <>
                                <Widget
                                    {...{
                                        type: "WIFILOGIN",
                                        device: DEVICE.TYPE.MOBILE,
                                        deviceWidth: previewContainerWidth,
                                        config: {
                                            google: activeFields
                                                ? activeFields[`${activeType}-social-networks`]
                                                : false,
                                            askMarketing: askMarketing,
                                            loginTypes: loginTypes,
                                            static: false,
                                            mode: stepPreview === 2 ? "signup" : "signin",
                                            activeType: activeType,
                                            countries: GetCountriesOptions().map((c) => {
                                                return { value: c.id, name: c.name };
                                            }),
                                            form: {
                                                values: {
                                                    terms: true,
                                                },
                                                fields: {
                                                    email: {
                                                        email: defaultField,
                                                    },
                                                    pms: {
                                                        room: defaultField,
                                                        surname: defaultField,
                                                        email: widgetField("email"),
                                                    },
                                                    code: {
                                                        passcode: defaultField,
                                                        email: widgetField("email"),
                                                    },
                                                    open: null,
                                                    signup: signupFields,
                                                },
                                            },
                                            events: loginEvents,
                                        },
                                        style: loginStyle,
                                    }}
                                />
                                {activeType !== "open" ? (
                                    <div className="bg-white flex shadow-inner justify-between p-4">
                                        {<RenderFirstStep stepPreview={stepPreview} setStepPreview={setStepPreview} />}
                                        {
                                            <RenderSecondStep
                                                stepPreview={stepPreview}
                                                setStepPreview={setStepPreview}
                                                enabled={signupEnabled}
                                            />
                                        }
                                    </div>
                                ) : null}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <UpdateAccessTypes
                selectMutation={selectMutation}
                emailFields={emailFields}
                pmsFields={pmsFields}
                codeFields={codeFields}
                openFields={openFields}
                mainSwitches={mainSwitches}
                refetch={refetch}
                setSelectMutation={setSelectMutation}
                cardEdition={cardEdition}
                cardsIDs={cardsIDs}
                setCardEdition={setCardEdition}
            />
        </>
    );
};
export default AccessTypes;

const getQuery = (hotspotName) => {
    return `query {
        wifiAccessTypes(hotspot: "${hotspotName ? hotspotName : ""}") {
            results {
                accessprofiles_id
                accesstypes_name
                additionalAccessProfileData {
                    name
                    uploadBWMax
                    uploadBWMin
                    downloadBWMax
                    downloadBWMin
                }
                additionalData {
                    age {
                        enabled
                        mandatory
                    }
                    email {
                        enabled
                        mandatory
                    }
                    name {
                        enabled
                        mandatory
                    }
                    surname {
                        enabled
                        mandatory
                    }
                    country {
                        enabled
                        mandatory
                    }
                }
                breakconn
                enable
                id
                renovation
                rrss
                usersNumber
            }
        }
        conditions {
            askMarketing
        }
    }`;
};
