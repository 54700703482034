import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseTagCheckbox from "./useTagCheckbox";
import UseCurrentTag from "./useCurrentTag";
//Actions
import { setActionInput } from "../../actions/globalActions";
//Utils
import useComponentVisible from "../../components/useComponentVisible";
import { useTranslation } from "react-i18next";

const UseInputCheckboxes = ({ validation, id }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { t } = useTranslation();
    const { actionName, values } = useSelector((state) => state.action);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //Data
    const name = "staff-roles";
    const data = [
        { id: 1, type: "roles", name: "cleaning", color: "#26BCAD" },
        { id: 2, type: "roles", name: "capacity", color: "#6554C0" },
    ];

    if (data && name === "staff-roles") {
        data.sort((a, b) => {
            return t(a.name).localeCompare(t(b.name));
        });
    }

    const translatable = true;

    //States
    const [selectActive, setSelectActive] = useState({
        icon: "",
        container: "",
        text: "",
    });
    const [activeSelection, setActiveSelection] = useState([]);
    const [activeFilters, setActiveFilters] = useState([]);

    //Actions
    const dispatch = useDispatch();

    //Listeners
    useEffect(() => {
        if (values && values.roles) {
            let newState = [];
            let newSelected = [];
            const tempArray = values.roles.split(",");
            newState.push(tempArray[1]);
            newSelected.push(tempArray[0]);
            if (tempArray.length > 4) {
                newState.push(tempArray[4]);
                newSelected.push(tempArray[3]);
            }
            setActiveFilters(newState);
            setActiveSelection(newSelected);
        }
        // eslint-disable-next-line
    }, [values]);

    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({
                icon: "",
                container: "",
                text: "",
            });
        } else {
            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
            });
        }
    }, [isComponentVisible]);

    useEffect(() => {
        if (actionName && (actionName === "new-staff-member" || actionName === "staff-edit")) {
            const dataToAction = { roles: activeSelection };
            dispatch(setActionInput(dataToAction));
        }
        // eslint-disable-next-line
    }, [activeSelection]);

    return (
        <div className="inline-block w-filter-checkbox " ref={ref}>
            <div
                className={`w-full h-10 bg-gray-200 rounded relative flex items-center pr-12 ${selectActive.container}${
                    !validation ? "border border-red-100 " : ""
                }`}
            >
                {activeFilters.length > 0 ? (
                    activeFilters.map((activeFilter, index) => (
                        <div className="my-2 ml-1 inline" key={index}>
                            <UseCurrentTag
                                activeSelection={activeSelection}
                                setActiveSelection={setActiveSelection}
                                activeFilters={activeFilters}
                                setActiveFilters={setActiveFilters}
                                activeFilter={activeFilter}
                                className="z-50"
                                color={getColor(activeFilter)}
                                idValue={activeFilter === "cleaning" ? "1" : "2"}
                            />
                        </div>
                    ))
                ) : (
                    <div
                        className={`bg-transparent w-full pl-4 first-capital ${selectActive.text}`}
                        onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                        id={id ? id : null}
                    >
                        {langStrings[name]}
                    </div>
                )}
                <span
                    className={`field-icon icon-chevron animated ${selectActive.icon}`}
                    onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                ></span>
                {isComponentVisible ? (
                    <div className="absolute left-0 w-48 bg-white shadow-lg rounded border border-gray-200 px-4 pt-4 z-100 t-checkboxes-container">
                        {data.map((option, index) => (
                            <div
                                id={option}
                                key={index}
                                name={name}
                                value={option}
                                data-id={option.id}
                                data-type={option.type}
                                data-name={option.name}
                                data-color={option.color}
                            >
                                <UseTagCheckbox
                                    filter={option}
                                    setActiveFilters={setActiveFilters}
                                    activeSelection={activeSelection}
                                    setActiveSelection={setActiveSelection}
                                    activeFilters={activeFilters}
                                    active={activeSelection.includes(option.id.toString()) ? true : false}
                                    translatable={translatable}
                                />
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export const getColor = (activeFilter) => {
    let res;
    if (activeFilter === "cleaning") {
        res = "#26BCAD";
    } else if (activeFilter === "capacity") {
        res = "#6554C0";
    } else {
        res = "#0F63BD";
    }
    return res;
};

export default UseInputCheckboxes;
