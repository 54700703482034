import React, { useState, useContext, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, { formatDate, parseDate } from "react-day-picker/moment";

import ReactTooltip from "components/ReactTooltip";
import Icon from "components/Icon";

import { GlobalContext } from "contexts/Global";

const DateInput = forwardRef(
    (
        {
            id,
            value: initialValue,
            placeholder = "dd/mm/yyyy",
            onChange,
            autoFocus,
            disabled,
            className,
            tooltip,
            required,
            disabledDays,
            selectedDays,
            modifiers,
        },
        ref
    ) => {
        const inputRef = useRef(null);
        const { lang } = useContext(GlobalContext);
        const [valid, setValid] = useState(true);
        const [date, setDate] = useState(ensureDate(initialValue));

        const isValid = valid && (!required || date);

        useImperativeHandle(ref, () => ({
            focus: () => {
                setTimeout(() => {
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }, 200);
            },
            blur: () => {
                if (inputRef.current) {
                    inputRef.current.blur();
                }
            },
            getValue: () => {
                return date;
            },
            isValid: () => {
                return isValid;
            },
        }));

        useEffect(() => {
            if (autoFocus) {
                setTimeout(() => {
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }, 100);
            }
        }, [inputRef.current]);

        useEffect(() => {
            if (initialValue) {
                setDate(ensureDate(initialValue));
            }
        }, [initialValue]);

        const newInputClass = classNames({
            "zafiro-date-input": true,
            invalid: !isValid,
            disabled: disabled,
            empty: !date,
            [className]: className,
        });

        return (
            <div>
                <div className={newInputClass}>
                    {disabled ? (
                        <div>
                            <input id={id} disabled={true} value={formatDate(date)} />
                        </div>
                    ) : (
                        <DayPickerInput
                            id={id}
                            inputRef={inputRef}
                            classNames={{ overlayWrapper: "fixed z-100" }}
                            value={date}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            placeholder={placeholder || `${formatDate(new window.ZDate())}`}
                            onDayPickerShow={() => {
                                if (tooltip) {
                                    const disabledDays = document.getElementsByClassName("DayPicker-Day--disabled");
                                    if (disabledDays?.length) {
                                        Array.from(disabledDays).forEach((element) => {
                                            element.setAttribute("data-for", "default-tooltip");
                                            element.setAttribute("data-tip", tooltip);
                                        });
                                    }
                                    ReactTooltip.rebuild();
                                }
                            }}
                            onDayChange={(selectedDay, modifiers) => {
                                setDate(selectedDay);
                                setValid(!modifiers?.disabled);
                                if (onChange) {
                                    onChange(selectedDay);
                                }
                            }}
                            dayPickerProps={{
                                firstDayOfWeek: 1,
                                fixedWeeks: true,
                                className: "Selectable",
                                numberOfMonths: 1,
                                locale: lang,
                                localeUtils: MomentLocaleUtils,
                                selectedDays: selectedDays || date,
                                modifiers: modifiers,
                                month: date,
                                disabledDays: disabledDays,
                            }}
                            tabIndex={0}
                        />
                    )}
                    <div className="px-3 absolute top-0 bottom-0 left-0 flex items-center text-gray-700">
                        <Icon type="booking" size={1.3} />
                    </div>
                </div>
            </div>
        );
    }
);

const ensureDate = (v) => {
    if (v) {
        if (v instanceof Date) {
            return v;
        } else {
            const date = new window.ZDate(v);
            if (date instanceof Date && !isNaN(date)) {
                return date;
            } else {
                console.warn("DateInput invalid date", v);
            }
        }
    }
    return null;
};
DateInput.displayName = "DateInput";

export default DateInput;
