import { changeActionValues } from "actions/globalActions";
import { DOCUMENTS } from "components/Section/Customers/Precheckin/ReservationDetails/utils";
import UseInputDate from "components/useInputDate";
import UseSelectWithSearch from "components/useSelectWithSearch";
import { useSelector } from "react-redux";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const UseModalEditDocumentation = ({ index }) => {
    const prefixId = "modal-edit-documentation";
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { validations } = useSelector((state) => state.action);
    const [documentationData, setDocumentationData] = useState({
        documentType: null,
        documentNumber: null,
        dateOfIssue: null,
        id: null,
        name: null,
    });

    useEffect(() => {
        const actionValues = {};
        const newDocumentationData = { ...documentationData };

        if (data?.id) {
            actionValues.id = data.id;
        }
        if (data?.documentType) {
            actionValues.documentType = data.documentType;
            newDocumentationData.documentType = data.documentType;
        }
        if (data?.documentNumber) {
            actionValues.documentNumber = data.documentNumber;
            newDocumentationData.documentNumber = data.documentNumber;
        }
        if (data?.name) {
            actionValues.name = data.name;
            newDocumentationData.name = data.name;
        }
        if (data?.documentExtraInformation?.expeditionDate) {
            actionValues.dateOfIssue = data.documentExtraInformation.expeditionDate;
            newDocumentationData.dateOfIssue = data.documentExtraInformation.expeditionDate;
        }
        if (Object.keys(actionValues).length > 0) {
            dispatch(changeActionValues(actionValues));
        }
        if (JSON.stringify(documentationData) !== JSON.stringify(newDocumentationData)) {
            setDocumentationData(newDocumentationData);
        }
    }, [data]);

    const changeValuesHandler = (key, val) => {
        dispatch(changeActionValues({ [key]: val }));
        setDocumentationData({ ...documentationData, [key]: val });
    };

    return (
        <div className=" w-full mt-6">
            <div className=" w-1/2 ">
                <div className=" mr-2">
                    <span className=" font-bold text-sm block mb-2">{t("type-of-document")}</span>
                    <UseSelectWithSearch
                        data={{
                            id: `${prefixId}-document-type-companion-select`,
                            name: `documentTypeSelect`,
                            optionData: DOCUMENTS,
                            selectPlaceHolder: t("select-an-option"),
                            oneSelected: true,
                            noSelect: true,
                            adjust: "h-11",
                            hideSearch: true,
                            selectedTextAdjust: "hidden",
                            optionsAdjust: "mt-11",
                            selectedIds: documentationData?.documentType ? [documentationData?.documentType] : null,
                            onChange: (val) => {
                                changeValuesHandler("documentType", val);
                            },
                        }}
                    />
                </div>
            </div>
            <div className=" w-full flex mt-6 ">
                <div className=" w-1/2 mr-2">
                    <span className=" font-bold text-sm block mb-2">{t("document-no")}</span>
                    <input
                        id={`${prefixId}-document-number`}
                        type="text"
                        placeholder={`${t("ex")} 98765432M`}
                        className={`h-11 px-2 w-full rounded bg-gray-200 ${
                            validations["documentNumber"] === false
                                ? "border border-red-100"
                                : "border border-transparent"
                        }`}
                        value={documentationData.documentNumber}
                        onChange={(e) => {
                            const val = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
                            changeValuesHandler("documentNumber", val);
                        }}
                    />
                </div>
                <div className=" w-1/2 ml-2 ">
                    <span className=" font-bold text-sm block mb-2">{t("date-of-issue")}</span>
                    <UseInputDate
                        data={{
                            name: `dateOfIssue`,
                            id: `${prefixId}-date-of-issue`,
                            fullwidth: true,
                            nonInitialDate: true,
                            date: documentationData?.dateOfIssue,
                            placeholder: "dd/mm/yyyy",
                            noCapitalizePlaceholder: true,
                            styleAdjust: `h-11`,
                            // styleAdjust: `h-11 ${!data?.[index]?.birthDate ? " text-gray-600" : ""}`,
                            // onChange: (date) => {
                            //     updateField({ field: "birthDate", val: date });
                            // },
                            onChange: (val) => {
                                console.log(val);
                                changeValuesHandler("dateOfIssue", val);
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default UseModalEditDocumentation;
