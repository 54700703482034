export const MonitorDevices = [
    "chromecasts-monitoring",
    "activities",
    "add",
    "and",
    "apply",
    "areas",
    "all",
    "are-you-sure",
    "available",
    "bookings",
    "booking-number",
    "cancel",
    "cant-undo",
    "categories",
    "category",
    "cat-must-empty",
    "common-zones",
    "delete",
    "erase",
    "hotel",
    "items",
    "ip",
    "name",
    "newa",
    "newo",
    "next",
    "ofo",
    "please",
    "previous",
    "quantity",
    "last-activity",
    "room",
    "save",
    "search",
    "status",
    "showing-all",
    "showing-all-results",
    "theres-not",
    "this",
    "view-detail",
    "write-name",
    "warning",
    "you-want",
    "error-select-category",
    "error-capacity-limit",
    "unassign",
    "move-room",
    "change-name",
    "change-pairing",
    "reboot",
    "factory-reset",
    "disable",
    "enable",
    "assign",
    "no-actions",
    "offline",
    "online",
    "disabled",
    "unauthorized",
    "unassigned",
    "filter-by",
    "export",
    "monitoring",
    "chromecasts",
    "installation",
    "monitor-of",
    "batch-execute",
    "warning-chromecasts-0",
    "warning-chromecasts-1",
    "warning-chromecasts-2",
    "warning-chromecasts-3",
    "warning-chromecasts-4",
    "pairing-code",
    "wifi-signal",
    "activity-time",
    "location",
    "change-chormecast-name-text",
    "chromecast-name",
    "chromecast-change-name",
    "rename",
    "chromecast-enable",
    "chromecast-disable",
    "chromecast-enable-text",
    "chromecast-disable-text",
    "chromecast-change-room",
    "chromecast-unassign",
    "chromecast-move-room",
    "chromecast-change-pairing",
    "chromecast-reboot",
    "chromecast-factory-reset",
    "chromecast-assign",
    "device",
    "select-roomtv-text",
    "chromecast-change-pairing-text",
    "chromecast-change-pairing-text-extended",
    "chromecast-reboot-text",
    "chromecast-factory-reset-text",
    "chromecast-factory-reset-text-extended",
    "restore",
    "chromecast-unassign-text",
    "select-room-assign-text",
    "running-task",
    "chromecast-unassign-text-extended",
    "doing-task",
    "task-reboot",
    "task-factory-reset",
    "task-unassign",
    "task-assign",
    "task-disable",
    "task-enable",
    "change",
    "low-signal-level",
    "assigned-tv",
    "never",
    "minutes",
    "days",
    "hours",
    "time-ago",
    "state",
    "batch-title",
    "chromacast-batch-unassign-text",
    "chromacast-batch-unassign-text-extended",
    "chromacast-batch-change-pairing-text",
    "chromacast-batch-change-pairing-text-extended",
    "chromacast-batch-reboot-text",
    "chromacast-batch-reboot-text-extended",
    "chromacast-batch-factory-reset-text",
    "chromacast-batch-factory-reset-text-extended",
    "chromacast-batch-enable-text",
    "chromacast-batch-disable-text",
    "preview",
    "non-response-server",
    "server-unresponsive",
    "server-unresponsive-check",
    "server-unresponsive-last-updated",
    "server-unresponsive-contact",
    "model",
    "room-preview",
    "select-device",
    "chromecast-authorize",
    "chromecast-unauthorize",
    "chromacast-batch-unauthorize-text",
    "chromacast-batch-unauthorize-text-extended",
    "chromacast-batch-authorize-text",
    "chromacast-batch-authorize-text-extended",
    "chromecast-authorize-assign",
    "select-room-authorize-assign-text",
    "chromecast-authorize-all",
    "chromecast-authorize-all-text",
    "chromacast-unauthorize-text",
    "chromacast-authorize-text",
    "chromecast-hide",
    "chromacast-hide-text",
    "chromacast-batch-hide-text",
    "chromacast-batch-hide-text-extended",
    "mac",
    "last-connection",
    "yes",
    "no",
    "authorized",
    "chromecast-reboot-text-extended",
    "chromecast-assign-stb-text",
    "select-device-function",
    "chromecast",
    "chromecast-and-stb",
    "chromecast-assign-stb-tooltip",
    "chromecast-assign-stb-to-stb",
    "device-function",
    "tv-also-assigned-cc",
    "tv-unassign-cc",
    "cc-also-assigned-tv",
    "cc-uninstall-tv",
    "cc-all-assigned",
    "tv-device",
    "devices-uninstall-tv",
    "devices-unassign-tv",
    "hidden",
    "standby",
    "no-devices-to-authorize"
];
