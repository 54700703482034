import React, { useState, useEffect } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMutation } from "react-apollo";
import { gql } from "apollo-boost";
import { useDispatch } from "react-redux";
import { setRefreshData } from "actions/tableActions";

const ModalDeleteBatchAssignedUsers = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [usersToDelete, setUsersToDelete] = useState([]);

    useEffect(() => {
        setUsersToDelete(props.results.filter((val) => props.checkedItems.includes(val.id)));
    }, []);

    const DELETE_USER_MUTATION = gql`mutation {
        updateShop(
            id: ${props.id}
            users:[${props.results
                .map((val) => val.id)
                .filter((val) => {
                    return !props.checkedItems.includes(val);
                })}]
        ) {
            id
            ok
        }
        }`;

    const [executeMutation] = useMutation(DELETE_USER_MUTATION, {
        onCompleted() {
            dispatch(setRefreshData(true));
            toast.success(t("operation-successful"));
            props.close();
        },
        onError() {
            toast.error(t("mutation-error"));
        },
    });

    const handleSaveClick = () => {
        executeMutation();
    };

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={`${capitalizeFirst(t("delete"))}`}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="red" id="modal-button-delete" onClick={handleSaveClick}>
                        {capitalizeFirst(t("delete"))}
                    </Button>
                </>
            }
            className={"w-5/12 p-10"}
        >
            <div>
                <p className="mb-2">{capitalizeFirst(t("delete-users-confirm"))}</p>
                {usersToDelete.map((user) => (
                    <div
                        key={`user-assigned-${user.id}`}
                        className="float-left z-20  bg-gray-200 text-gray-900 px-6 p-1 mr-2 mb-2 rounded"
                    >
                        {user.name}
                    </div>
                ))}
            </div>
        </Modal>
    );
};

export const UseModalDeleteBatchAssignedUsers = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalDeleteBatchAssignedUsers {...newProps} />);
        },
    };
};
