import Es from "./es/general.json";
import En from "./en/general.json";
import { General } from "./General";
import { CZCategories } from "./sections/CZCategories";
import { CZStaff } from "./sections/CZStaff";
import { AuthLogin } from "./sections/AuthLogin";
import { Policy } from "./sections/Policy";
import { AuthSelectProject } from "./sections/AuthSelectProject";
import { CZSchedules } from "./sections/CZSchedules";
import { CZCleaning } from "./sections/CZCleaning";
import { CZCapacity } from "./sections/CZCapacity";
import { CZBookingCategories } from "./sections/CZBookingCategories";
import { CZBookingAreas } from "./sections/CZBookingAreas";
import { CZAreasActivities } from "./sections/CZAreasActivities";
import { Customers } from "./sections/Customers";
import { Header } from "./sections/Header";
import { CZSidebar } from "./sections/CZSidebar";
import { HotelSidebar } from "./sections/HotelSidebar";
import { MonitorDevices } from "./sections/MonitorDevices";
import { CustomersSidebar } from "./sections/CustomersSidebar";
import { NetworkSettings } from "./sections/NetworkSettings";
import { Hotel } from "./sections/Hotel";
import { Stats } from "./sections/Stats";
import { Design } from "./sections/Design";
import { Rooms } from "./sections/Rooms";
import { RoomGroups } from "./sections/RoomGroups";
import { Info } from "./sections/Info";
import { Settings } from "./sections/Settings";
import { Sales } from "./sections/Sales";
import { Services } from "./sections/Services";

const GetLangStrings = (lang, location) => {
    let res = {};
    //Get all from lang
    let currentTranslateSet;
    switch (lang) {
        case "es":
            currentTranslateSet = Es;
            break;
        case "en":
            currentTranslateSet = En;
            break;
        default:
            currentTranslateSet = En;
    }

    //Get section strings
    let sectionKeys = [];
    //Clean params
    const lastChar = location.slice(-1);
    if (Number(lastChar) || lastChar === "0") {
        location = location.slice(0, location.lastIndexOf("/"));
    }

    //lang string for sections
    if (location.includes("/hotel/")) {
        sectionKeys = HotelSidebar;
    } else if (location.includes("/common-zones/")) {
        sectionKeys = CZSidebar;
    }

    //lang string for pages
    switch (location) {
        case "/login":
            sectionKeys = AuthLogin;
            break;
        case "/policy":
            sectionKeys = Policy;
            break;
        case "/select-project":
            sectionKeys = AuthSelectProject;
            break;
        case "/hotel/installation/devices":
            sectionKeys = sectionKeys.concat(MonitorDevices);
            break;
        case "/hotel/property-settings/wifi":
            sectionKeys = sectionKeys.concat(NetworkSettings);
            break;
        case "/hotel/property-settings/cast":
            sectionKeys = sectionKeys.concat(NetworkSettings);
            break;
        case "/hotel/property-settings/portal":
            sectionKeys = sectionKeys.concat(NetworkSettings);
            break;
        case (location.match(/roomgroups/) || {}).input:
            sectionKeys = sectionKeys.concat(RoomGroups);
            break;
        case (location.match(/rooms/) || {}).input:
            sectionKeys = sectionKeys.concat(Rooms, MonitorDevices);
            break;
        case "/common-zones/categories":
        case "common-zones/zones-configuration/areas":
        case "/common-zones/zones-configuration/categories":
        case "/common-zones/categories/category":
        case "/common-zones/zones-configuration/areas":
            sectionKeys = CZAreasActivities.concat(CZSidebar);
            sectionKeys = sectionKeys.concat(CZCategories);
            break;
        case "/common-zones/staff":
            sectionKeys = sectionKeys.concat(CZStaff);
            break;
        case "/common-zones/cleaning":
            sectionKeys = CZCleaning.concat(CZSidebar);
            break;
        case "/common-zones/capacity":
            sectionKeys = CZCapacity.concat(CZSidebar);
            break;
        case "/common-zones/booking/categories":
            sectionKeys = CZBookingCategories.concat(CZSidebar);
            break;
        case (location.match(/common-zones\/booking\/categories\/area/) || {}).input:
            sectionKeys = CZBookingAreas.concat(CZSidebar);
            break;
        case (location.match(/information/) || {}).input:
            sectionKeys = sectionKeys.concat(Info);
            break;
        case (location.match(/services\/sales/) || {}).input:
            sectionKeys = sectionKeys.concat(Sales);
            break;
        case (location.match(/general-settings/) || {}).input:
            sectionKeys = sectionKeys.concat(Settings);
            break;
        case "/common-zones/zones-configuration/areas/schedule":
        case "/common-zones/zones-configuration/categories/category/schedule":
            sectionKeys = CZSchedules.concat(CZSidebar);
            break;
        case (location.match(/customers/) || {}).input:
            sectionKeys = Customers.concat(CustomersSidebar);
            break;
        case (location.match(/design/) || {}).input:
            sectionKeys = sectionKeys.concat(Design);
            break;
        case (location.match(/stats/) || {}).input:
            sectionKeys = sectionKeys.concat(Stats);
            break;
        case (location.match(/services/) || {}).input:
            sectionKeys = sectionKeys.concat(Services);
            break;
        default:
            break;
    }

    if ((location.match(/hotel/) || {}).input) {
        sectionKeys = sectionKeys.concat(Hotel);
    }

    //Add general lang strings
    // eslint-disable-next-line
    General.forEach((general) => {
        res[general] = currentTranslateSet[general];
    });

    //Add common header lang strings
    // eslint-disable-next-line
    Header.forEach((header) => {
        res[header] = currentTranslateSet[header];
    });

    //Add section specific lang strings
    // eslint-disable-next-line
    sectionKeys.forEach((sectionKey) => {
        res[sectionKey] = currentTranslateSet[sectionKey];
    });

    //Add accesses translations
    if (location.match(/general-settings/)) {
        res["manageBrands"] = res["settings-manageBrands"];
        res["manageProperties"] = res["settings-manageProperties"];
    }
    return res;
};

export default GetLangStrings;
