import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { setExecuteQuery } from "../../../../../../actions/sectionActions";
import Widget from "../../Widget";
import { getLogoTypeName, getLogoOriginName } from "../../../../../../hooks/Utils/AppearancesUtils";
import UseSelectWithPreview from "../../../../../../components/Inputs/useSelectWithPreview";
import { EditorContext, WidgetContext } from "../../../../../../contexts/editor";
import { Session } from "../../../../../../hooks/Utils/Session";

const InputLogo = ({ title, deviceSpecific }) => {
    const { deviceType, theme } = useContext(EditorContext);
    const { config, id: widgetID } = useContext(WidgetContext);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { gridItems } = useSelector((state) => state.design);

    const logoVariants = ((info) => {
        let _logosType = [];
        if (info) {
            info.map((data, index) => {
                _logosType.push({
                    id: index,
                    optGroup: getLogoOriginName(data.type, t),
                    value: data.type + "_" + data.version,
                    asset: Session.getDasUrl(data.imageRef),
                    display: getLogoTypeName(data.version, t),
                });
                return data;
            });
        }
        return _logosType;
    })(theme ? theme.logos : null);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const data = config?.data || Widget.ParseData(widget ? widget.data : null, deviceType);
    const logoVersion = data ? data.version : null;
    const logoType = logoVersion ? data.type : null;

    const updateValue = (value) => {
        const parts = value ? value.split("_") : null;
        const type = parts && parts.length > 3 ? parts[0] : null;
        const version = parts && parts.length > 3 ? parts.shift() && parts.join("_") : value;
        dispatch(
            setExecuteQuery(
                Widget.UpdateData({
                    widget,
                    value: {
                        type: type,
                        version: version,
                    },
                })
            )
        );
    };

    return (
        <div className={"mb-2 full-h"}>
            {title ? <div className="first-capital mt-2">{title}</div> : null}
            <div className="mt-1">
                <UseSelectWithPreview
                    selectData={{
                        name: "logo-type",
                        options: logoVariants,
                        optionSelected: logoType
                            ? logoVariants.find((v) => v.value === logoType + "_" + logoVersion)
                            : null,
                        ph: "select-logo-type",
                        fixed: true,
                        parentName: `widgetOptionContainer_${widgetID}`,
                    }}
                    onSelect={(data) => {
                        updateValue(data ? data.value : null);
                    }}
                />
            </div>
        </div>
    );
};

export default InputLogo;
