import React, { useEffect, useContext } from "react";
import Icon from "components/Icon";
import Select from "components/Select";
import Switch from "components/Switch";
import { TopologyContext } from "contexts/Topology";
import { useTranslation } from "react-i18next";

export const Location = ({ locationFilter, locations, type }) => {
    const { t } = useTranslation();
    const { tvs, setLocation, locationValidation } = useContext(TopologyContext);

    useEffect(() => {
        if (type === "edit") {
            setLocation(locations.map((location) => ({ ...location, CEC: location.cec })));
        } else {
            if (tvs > 0) {
                setLocation((prev) => [
                    ...prev,
                    ...Array.from({ length: tvs }, (_, i) => ({ id: i, name: "", CEC: false })),
                ]);
            } else {
                setLocation([]);
            }
        }
    }, [tvs]);

    return (
        tvs && (
            <div className="flex flex-col gap-1">
                {Array.from({ length: tvs }, (_, i) => {
                    const valueProp = type === "edit" ? { value: locations[i]?.name } : {};
                    const switchProp = type === "edit" ? { checked: locations[i]?.cec } : {};
                    return (
                        <div className="flex items-center gap-2 w-full">
                            <Select
                                key={i}
                                id={`new-location-${i}`}
                                options={locationFilter}
                                allowUnselect={false}
                                {...valueProp}
                                placeholder={t("location")}
                                designClass={{ validation: locationValidation[i]?.validation }}
                                onChange={(value) => {
                                    setLocation((prev) => {
                                        const updatedLocation = [...prev];
                                        updatedLocation[i] = { ...updatedLocation[i], name: value };
                                        return updatedLocation;
                                    });
                                }}
                            />
                            <Icon
                                type="info"
                                size="2xl"
                                className="text-gray-900"
                                tooltip={`<div>${t("select-tv-locations-and-cec")}</div><div>${t(
                                    "cec-enabled-description"
                                )}</div>`}
                                tooltipType={"dangerous-html-tooltip"}
                            />
                            <p className="text-gray-900 w-full">{t("cast-inst")}</p>
                            <Switch
                                id="cast-inst"
                                onChange={(value) => {
                                    setLocation((prev) => {
                                        const updatedLocation = [...prev];
                                        updatedLocation[i] = { ...updatedLocation[i], CEC: value?.checked };
                                        return updatedLocation;
                                    });
                                }}
                                {...switchProp}
                            />
                        </div>
                    );
                })}
            </div>
        )
    );
};
