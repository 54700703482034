import React from "react";

//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table";

//Utils
import ListPropertyGroups from "../../../hooks/GraphqlCalls/Settings/ListPropertyGroups";

const PropertyGroups = () => {
    ListPropertyGroups();
    return (
        <>
            <UseSectionHeader title={"manager-propertyGroups"} />
            <UseTable />
        </>
    );
};

export default PropertyGroups;
