import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import DayPicker, { DateUtils } from "react-day-picker";
import UseCalendarNavbar from "./useCalendarNavbar";
//Actions
import { setSpecificRange } from "../../actions/globalActions";
//Utils
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/es";

import { GlobalContext } from "contexts/Global";

const UseCalendarRange = () => {
    //Store data
    const { lang } = useContext(GlobalContext);
    const { specificRange } = useSelector((state) => state.action);
    //Data
    const numberOfMonths = 1;
    const getInitialState = () => {
        return {
            from: undefined,
            to: undefined,
        };
    };
    //States
    const [rangeState, setRangeState] = useState(getInitialState());
    const { from, to } = rangeState;
    const modifiers = { start: from, end: to };

    //Actions
    const dispatch = useDispatch();

    const handleDayClick = (day) => {
        const range = DateUtils.addDayToRange(day, rangeState);
        setRangeState(range);
        dispatch(setSpecificRange(range));
    };

    useEffect(() => {
        setRangeState({
            from: specificRange ? specificRange.from : undefined,
            to: specificRange ? specificRange.to : undefined,
        });
        // eslint-disable-next-line
    }, []);

    return (
        <div className="RangeExample">
            {/*ISO 8601 standard Monday(1) Sunday (7)*/}
            <DayPicker
                firstDayOfWeek={1}
                navbarElement={<UseCalendarNavbar />}
                className="Selectable"
                numberOfMonths={numberOfMonths}
                selectedDays={[from, { from, to }]}
                modifiers={modifiers}
                onDayClick={handleDayClick}
                localeUtils={MomentLocaleUtils}
                locale={lang}
            />
        </div>
    );
};

export default UseCalendarRange;
