import React, { useContext, useEffect, useLayoutEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirst, getFromDAS } from "hooks/Utils/Utils";
import UseRadio from "../useRadio";
import UseSelectWithSearch from "../useSelectWithSearch";
import { GetCountriesOptions } from "hooks/Utils/CountriesUtils";
import { GetLanguageOptions } from "hooks/Utils/LanguagesUtils";
import { Session } from "hooks/Utils/Session";
import { useDispatch, useSelector } from "react-redux";
import { showRowForm, setHiddenRows, setExecuteQuery } from "actions/sectionActions";
import { changeActionValues, cleanAction } from "actions/globalActions";
import { toast } from "react-toastify";
import UseCheckBox from "../Inputs/useCheckBox";
import { addCheckedItem, removeCheckedItem } from "actions/tableActions";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { arrangeToastMessagesUploadingFiles } from "hooks/Utils/DesignUtils";
import { useUploadFileContext } from "contexts/UploadFile/uploadFileContext";
import FileHandler from "components/FileHandler";

import { GlobalContext } from "contexts/Global";

const UseEditPredefinedChannel = ({
    action = "edit",
    bulkDeleteAction = null,
    bulkDeleteDisabled = false,
    numberBulkCard = 0,
    updatePredefinedChannel = null,
    bulkValidations = null,
    idCard = null,
    data = null,
}) => {
    const fileInputRef = useRef(null);
    const { t } = useTranslation();
    const { lang } = useContext(GlobalContext);
    const dispatch = useDispatch();
    const { results, checkedItems } = useSelector((state) => state.table);
    const { itemsAffected } = useSelector((state) => state.action);
    const [validations, setValidations] = useState({});
    const [checkedItem, setChekedItem] = useState(false);
    const [bulkTypeChannel, setBulkTypeChannel] = useState("tv");
    const [srcLogo, setSrcLogo] = useState(null);
    const isBulkAction = action === "create-bulk";
    const isEditAction = action === "edit";
    const [values, setValues] = useState({
        [`${action}-predefined-channel-name`]: "",
        [`${action}-predefined-channel-type`]: "tv",
        [`${action}-predefined-channel-country-ref`]: "",
        [`${action}-predefined-channel-lang-ref`]: "",
        [`${action}-predefined-channel-logo`]: "",
        [`${action}-predefined-channel-number-used-in-projects`]: 0,
    });
    const { generateFilesUploading, uploadFilesRequest, filesToUpload, executeChange } = useUploadFileContext();

    useEffect(() => {
        if (checkedItems.includes(itemsAffected)) {
            setChekedItem(true);
        } else {
            setChekedItem(false);
        }
    }, [JSON.stringify(checkedItems)]);

    useEffect(() => {
        if (executeChange && filesToUpload.length > 0) {
            // setSrcLogo(filesToUpload[0]?.ref);
            if (!isBulkAction) {
                setValues({ ...values, [`${action}-predefined-channel-logo`]: filesToUpload[0]?.ref });
            } else if (Session.getSessionProp("addBulkImageId")) {
                console.log({ id: Session.getSessionProp("addBulkImageId"), logo: filesToUpload[0]?.ref });
                updatePredefinedChannel(Session.getSessionProp("addBulkImageId"), { logo: filesToUpload[0]?.ref });
                Session.removeSessionProp("addBulkImageId");
            }
        }
    }, [executeChange]);

    useEffect(() => {
        if (data?.logo) {
            setSrcLogo(data.logo);
        }
    }, [data]);

    const handleFileChange = async (files) => {
        Session.setSessionProp("addBulkImageId", files.idCard);
        await handleFilesUpload(files, t, generateFilesUploading, uploadFilesRequest);
        // setSrcLogo(filesToUpload[0]?.ref);
        // if (!isBulkAction && filesToUpload[0]?.ref) {
        //     setValues({ ...values, [`${action}-predefined-channel-logo`]: filesToUpload[0]?.ref });
        // }
        // } else if (filesToUpload[0]?.ref) {
        //     updatePredefinedChannel(idCard, { logo: filesToUpload[0]?.ref });
        // }
    };

    useLayoutEffect(() => {
        if (Array.isArray(results) && typeof itemsAffected === "number") {
            const filteredResults = results.filter(({ id }) => Number(id) === Number(itemsAffected));
            if (filteredResults.length > 0) {
                updateValues(action, filteredResults, values, setValues);
                if (filteredResults?.[0]?.logoRef) {
                    setSrcLogo(filteredResults?.[0]?.logoRef);
                }
            }
        }
    }, [results, itemsAffected]);

    useEffect(() => {
        if (!isBulkAction) {
            dispatch(changeActionValues(values));
        }
        if (values?.[`${action}-predefined-channel-logo`] !== "") {
            setSrcLogo(values?.[`${action}-predefined-channel-logo`]);
        }
    }, [values]);

    return (
        <div className={`w-full flex border-b border-gray-200 ${!isBulkAction ? "px-2" : ""}`}>
            {!isBulkAction ? <span className=" mr-4"></span> : null}
            {!isBulkAction ? (
                <div className=" t-checkbox-container flex items-center">
                    <div className=" h-16 mt-18 -ml-2 ">
                        {action === "edit" ? (
                            <UseCheckBox
                                onClick={() => {
                                    if (!checkedItems.includes(itemsAffected)) {
                                        dispatch(addCheckedItem(itemsAffected));
                                    } else {
                                        dispatch(removeCheckedItem(itemsAffected));
                                    }
                                }}
                                id={"edit-predefined-channel-checkbox"}
                                checked={checkedItem}
                            />
                        ) : null}
                    </div>
                </div>
            ) : null}
            <div className={`t-cell ${!isBulkAction ? "w-1/12" : "w-2/12"} `}>
                {srcLogo ? (
                    <div className={` rounded  relative border border-transparent ${!isBulkAction ? "-ml-2" : ""}`}>
                        <img
                            id={`${action}-predefined-channel-image-${isBulkAction ? `-card-${numberBulkCard}` : ""}`}
                            className=" rounded w-24 h-20 bg-gray-900  object-contain"
                            alt="something"
                            src={getFromDAS(srcLogo, lang)}
                        />
                        <DeleteButtonLogo
                            src={srcLogo}
                            action={() => {
                                if (isBulkAction) {
                                    updatePredefinedChannel(idCard, { logo: "" });
                                } else if (isEditAction) {
                                    setValues({ ...values, [`${action}-predefined-channel-logo`]: null });
                                }
                                setSrcLogo(null);
                            }}
                            id={`${action}-predefined-channel-delete-image-button-${
                                isBulkAction ? `-card-${numberBulkCard}` : ""
                            }`}
                        />
                    </div>
                ) : (
                    <>
                        <FileHandler
                            ref={fileInputRef}
                            id={`${action}-predefined-channel-image`}
                            options={{ contentTypes: ["image"] }}
                            onSuccess={(files) => {
                                files.idCard = idCard;
                                handleFileChange(files);
                            }}
                            onError={(error) => {
                                toast.error(`${t(error)}`);
                            }}
                        >
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (fileInputRef.current) {
                                        fileInputRef.current.click();
                                    }
                                }}
                                className={` w-24 h-20 ${
                                    !isBulkAction ? "-ml-2" : ""
                                } bg-gray-900 flex items-center focus:outline-none justify-center rounded border border-gray-200 cursor-pointer`}
                            >
                                <span className=" text-white text-sm text-center">
                                    {capitalizeFirst(t("choose-an-image"))}{" "}
                                </span>
                            </button>
                        </FileHandler>
                    </>
                )}
            </div>
            <div className={` t-cell flex items-center ${!isBulkAction ? "w-3/12" : "w-2/12"}`}>
                <div className=" h-16 flex items-center w-full">
                    <input
                        type="text"
                        id={`${action}-predefined-channel-name${isBulkAction ? `-card-${numberBulkCard}` : ""}`}
                        onChange={(e) => {
                            if (!isBulkAction) {
                                setValues({ ...values, [`${action}-predefined-channel-name`]: e.currentTarget.value });
                            } else {
                                updatePredefinedChannel(idCard, { name: e.currentTarget.value });
                            }
                        }}
                        value={!isBulkAction ? values[`${action}-predefined-channel-name`] : null}
                        // className="h-10 bg-gray-300"
                        placeholder={t("enter-name")}
                        className={`h-10 bg-gray-200 ${!isBulkAction ? "w-2/4" : "w-3/4"} px-2  rounded ${
                            validations[`${action}-predefined-channel-name`] || bulkValidations?.[idCard]?.name
                                ? "border border-red-100"
                                : " border border-transparent"
                        } `}
                    />
                </div>
            </div>
            <div className={` t-cell flex items-center ${!isBulkAction ? "w-1/12" : "w-2/12"}`}>
                <div
                    className={` h-16 ${!isBulkAction ? "-ml-2" : ""} ${
                        isEditAction ? "pl-1" : ""
                    } flex flex-col justify-center`}
                >
                    <UseRadio
                        id={`${action}-predefined-channel-type-tv${isBulkAction ? `-card-${numberBulkCard}` : ""}`}
                        handleClick={() => {
                            if (!isBulkAction) {
                                setValues({ ...values, [`${action}-predefined-channel-type`]: "tv" });
                            } else {
                                updatePredefinedChannel(idCard, { type: "tv" });
                                setBulkTypeChannel("tv");
                            }
                        }}
                        value={"tv"}
                        text={"tv"}
                        selected={!isBulkAction ? values[`${action}-predefined-channel-type`] : bulkTypeChannel}
                    />
                    <UseRadio
                        id={`${action}-predefined-channel-type-radio${isBulkAction ? `-card-${numberBulkCard}` : ""}`}
                        handleClick={() => {
                            if (!isBulkAction) {
                                setValues({ ...values, [`${action}-predefined-channel-type`]: "radio" });
                            } else {
                                updatePredefinedChannel(idCard, { type: "radio" });
                                setBulkTypeChannel("radio");
                            }
                        }}
                        text={"radio"}
                        value={"radio"}
                        selected={!isBulkAction ? values[`${action}-predefined-channel-type`] : bulkTypeChannel}
                    />
                </div>
            </div>
            <div className={` t-cell flex items-center ${!isBulkAction ? "w-2/12" : "w-3/12"} `}>
                <div className={` h-16 flex ${!isBulkAction ? "w-3/4" : "w-2/4"} flex-col justify-center`}>
                    <div
                        className={`${
                            validations[[`${action}-predefined-channel-country-ref`]] ||
                            bulkValidations?.[idCard]?.country
                                ? "border border-red-100 rounded"
                                : ""
                        } ${isEditAction ? "pl-1" : ""}`}
                    >
                        <UseSelectWithSearch
                            key={idCard + "select-country"}
                            data={{
                                disabled: false,
                                id: `${action}-predefined-channel-country-ref${
                                    isBulkAction ? `-card-${numberBulkCard}` : ""
                                }`,
                                name: `${action}-predefined-channel-country-ref-select${
                                    isBulkAction ? `-card-${numberBulkCard}` : ""
                                }`,
                                onChange: (ref) => {
                                    if (!isBulkAction) {
                                        setValues({ ...values, [`${action}-predefined-channel-country-ref`]: ref });
                                    } else {
                                        updatePredefinedChannel(idCard, { country: ref });
                                    }
                                },
                                optionsAdjust: "mt-11",
                                optionData: GetCountriesOptions(),
                                selectPlaceHolder: capitalizeFirst(t("country")),
                                noSelect: true,
                                selectedIds:
                                    !isBulkAction && values[`${action}-predefined-channel-country-ref`]
                                        ? [values[`${action}-predefined-channel-country-ref`]]
                                        : null,
                                oneSelected: true,
                                // hideSearch: true,
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={` t-cell flex items-center w-2/12 `}>
                <div className={` h-16 flex w-3/4 flex-col justify-center `}>
                    <div
                        className={`${
                            validations[`${action}-predefined-channel-lang-ref`] || bulkValidations?.[idCard]?.language
                                ? "border border-red-100 rounded"
                                : ""
                        }${isEditAction ? "pl-1" : ""} `}
                    >
                        <UseSelectWithSearch
                            key={idCard + "select-lang"}
                            data={{
                                isabled: false,
                                id: `${action}-predefined-channel-lang-ref${
                                    isBulkAction ? `-card-${numberBulkCard}` : ""
                                }`,
                                name: `${action}-predefined-channel-lang-ref-select${
                                    isBulkAction ? `-card-${numberBulkCard}` : ""
                                }`,
                                onChange: (ref) => {
                                    if (!isBulkAction) {
                                        setValues({ ...values, [`${action}-predefined-channel-lang-ref`]: ref });
                                    } else {
                                        updatePredefinedChannel(idCard, { language: ref });
                                    }
                                },
                                optionsAdjust: "mt-11",
                                selectedIds:
                                    !isBulkAction && values[`${action}-predefined-channel-lang-ref`]
                                        ? [values[`${action}-predefined-channel-lang-ref`]]
                                        : null,
                                optionData: GetLanguageOptions(),
                                selectPlaceHolder: capitalizeFirst(t("language")),
                                noSelect: true,
                                oneSelected: true,
                                // hideSearch: true,
                            }}
                        />
                    </div>
                </div>
            </div>
            <RenderButtons
                action={action}
                checkedItem={checkedItem}
                bulkDeleteAction={bulkDeleteAction}
                bulkDeleteDisabled={bulkDeleteDisabled}
                numberBulkCard={numberBulkCard}
                setValidations={setValidations}
                values={values}
                id={itemsAffected}
            />
        </div>
    );
};

export default UseEditPredefinedChannel;

const CreatePredefinedChannel = ({ action, onAcceptButton, onCancelButton }) => {
    const { t } = useTranslation();

    return (
        <div className=" w-3/12 flex items-center justify-end relative ">
            <div style={{ right: "0rem" }} className=" w-full absolute flex flex-grow justify-end pr-10">
                <button
                    id={`${action}-predefined-channel-cancel-button`}
                    onClick={() => {
                        onCancelButton();
                    }}
                    style={{ minWidth: "5rem" }}
                    className="focus:outline-none px-2 text-blue-600 border border-blue-600 py-1   font-bold   rounded mr-4 "
                >
                    {capitalizeFirst(t("cancel"))}
                </button>
                <button
                    onClick={() => {
                        onAcceptButton("create-predefined-channel");
                    }}
                    style={{ minWidth: "5rem" }}
                    id={`${action}-predefined-channel-add-button`}
                    className="focus:outline-none px-2 text-white border border-blue-600 bg-blue-600  py-1 font-bold  rounded "
                >
                    {capitalizeFirst(t("add"))}
                </button>
            </div>
        </div>
    );
};

const EditPredefinedChannel = ({ action, checkedItem, onAcceptButton, onCancelButton, values }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { itemsAffected } = useSelector((state) => state.action);

    return (
        <>
            <div className=" t-cell flex items-center  w-2/12">
                <div className={"h-16 -ml-1 -pl-1 flex w-3/4 flex-col justify-center pl-3 text-base"}>
                    {t("used-in-n-projects", {
                        number: values["edit-predefined-channel-number-used-in-projects"] || 0,
                    })}
                </div>
            </div>
            <div className=" t-cell flex items-center pl-2  w-0.5/12">
                <button
                    id={`${action}-predefined-channel-cancel-button`}
                    onClick={() => {
                        onCancelButton();
                        if (checkedItem) {
                            dispatch(changeActionValues({ "checked-item": itemsAffected }));
                        }
                    }}
                    className="focus:outline-none h-8 -ml-4 w-8 font-bold  icon-remove rounded mr-4 bg-red-100 text-white"
                ></button>
            </div>
            <div className=" t-cell flex pl-2 items-center w-0.5/12">
                <button
                    onClick={() => {
                        onAcceptButton("edit-predefined-channel");
                    }}
                    id={`${action}-predefined-channel-accept-button`}
                    className="focus:outline-none font-bold h-8 w-8 -ml-3  rounded icon-tick-right bg-green-100 text-white"
                ></button>
            </div>
        </>
    );
};

const CreateBulkPredefinedChannels = ({ action, bulkDeleteAction, bulkDeleteDisabled, numberBulkCard }) => {
    return (
        <>
            <div className=" t-cell flex items-center w-1/12">
                <div className=" h-16 w-full flex items-center pr-2 justify-end">
                    <button
                        onClick={() => {
                            if (typeof bulkDeleteAction === "function") {
                                bulkDeleteAction();
                            }
                        }}
                        disabled={bulkDeleteDisabled}
                        id={`${action}-predefined-channel-delete-button${`-card-${numberBulkCard}`}`}
                        className={`focus:outline-none   rounded icon-delete text-2xl  ${
                            bulkDeleteDisabled ? "text-gray-600" : "text-gray-800"
                        }`}
                    ></button>
                </div>
            </div>
        </>
    );
};

const RenderButtons = ({
    action,
    checkedItem,
    bulkDeleteAction,
    bulkDeleteDisabled,
    numberBulkCard,
    setValidations,
    values,
    id,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onCancelButton = () => {
        dispatch(showRowForm(false));
        dispatch(cleanAction());
        dispatch(setHiddenRows(true));
    };

    const onAcceptButton = (actionQuery = "", params = {}) => {
        const { noErrors, validations } = checkValuesNotEmpty(values, action);
        setValidations(validations);
        if (noErrors) {
            dispatch(
                setExecuteQuery({
                    action: actionQuery,
                    params: {
                        fields: generateFieldsMutation(action, values, id ? id : null),
                    },
                })
            );
            dispatch(showRowForm(false));
            dispatch(cleanAction());
            dispatch(setHiddenRows(true));
            // dispatch(setRowInEdition(null));
        } else {
            toast.error(t("input error"));
        }
    };

    if (action === "edit") {
        return (
            <EditPredefinedChannel
                action={action}
                checkedItem={checkedItem}
                onAcceptButton={onAcceptButton}
                onCancelButton={onCancelButton}
                values={values}
            />
        );
    } else if (action === "create") {
        return (
            <CreatePredefinedChannel action={action} onAcceptButton={onAcceptButton} onCancelButton={onCancelButton} />
        );
    } else if (action === "create-bulk") {
        return (
            <CreateBulkPredefinedChannels
                action={action}
                bulkDeleteAction={bulkDeleteAction}
                bulkDeleteDisabled={bulkDeleteDisabled}
                numberBulkCard={numberBulkCard}
            />
        );
    }

    return null;
};

const DeleteButtonLogo = ({ src, action, id }) => {
    const GET_INFO_LOGO = gql`
    query{
        systemLibraryAsset(
          ref: "${src}"
        ) {
          response {
            name
          }
        }
      }
    `;

    const [tooltipName, setTooltipName] = useState("");

    const [getInfoLogo, { data }] = useLazyQuery(GET_INFO_LOGO, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        getInfoLogo();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data?.systemLibraryAsset?.response?.name) {
            setTooltipName(data.systemLibraryAsset.response.name);
        }
    }, [data]);

    return (
        <div className=" bg-gray-800 bg-opacity-75 w-full px-1 flex justify-between items-center h-6 absolute bottom-0 left-0 rounded-bl rounded-br ">
            <span></span>
            <i
                onClick={() => {
                    if (typeof action === "function") {
                        action();
                    }
                }}
                id={id ? id : null}
                className=" icon icon-delete-editor cursor-pointer text-white text-xl "
            ></i>
            <i className="icon icon-info text-white text-base" data-tip={tooltipName} data-for="default-tooltip"></i>
        </div>
    );
};

const checkValuesNotEmpty = (values, action) => {
    const validations = {};
    if (values[`${action}-predefined-channel-name`].trim() === "") {
        validations[`${action}-predefined-channel-name`] = true;
    }
    if (values[`${action}-predefined-channel-country-ref`].trim() === "") {
        validations[`${action}-predefined-channel-country-ref`] = true;
    }

    if (values[`${action}-predefined-channel-lang-ref`].trim() === "") {
        validations[`${action}-predefined-channel-lang-ref`] = true;
    }

    return { validations, noErrors: Object.keys(validations).length === 0 };
};

const updateValues = (action, filteredResults, values, setValues) => {
    setValues({
        ...values,
        [`${action}-predefined-channel-name`]: filteredResults[0]?.name || "",
        [`${action}-predefined-channel-type`]: filteredResults[0]?.isRadio ? "radio" : "tv",
        [`${action}-predefined-channel-country-ref`]: filteredResults[0]?.countryRef || "",
        [`${action}-predefined-channel-lang-ref`]: filteredResults[0]?.languageRef?.toLowerCase() || "",
        [`${action}-predefined-channel-logo`]: filteredResults[0]?.logoRef || "",
        [`${action}-predefined-channel-number-used-in-projects`]: filteredResults[0]?.usedInProjectsNum || 0,
    });
};

const generateFieldsMutation = (action, obj, id) => {
    const result = [];

    if (id) {
        result.push(`id: ${id}`);
    }

    for (const key in obj) {
        if (key === `${action}-predefined-channel-name`) {
            result.push(`name: "${obj[key]}"`);
        } else if (key === `${action}-predefined-channel-type`) {
            result.push(`isRadio: ${obj[key] === "tv" ? "false" : "true"}`);
        } else if (key === `${action}-predefined-channel-country-ref`) {
            result.push(`country: "${obj[key]}"`);
        } else if (key === `${action}-predefined-channel-lang-ref`) {
            result.push(`language: "${obj[key]}"`);
        } else if (key === `${action}-predefined-channel-logo` && obj[key]) {
            result.push(action === "create" ? `logoRef: "${obj[key]}"` : `logo: "${obj[key]}"`);
        }
    }

    if (action === "edit" && !obj["edit-predefined-channel-logo"]) {
        result.push(`logo: ""`);
    }

    return result.join(", ");
};

const handleFilesUpload = async (files, t, generateFilesUploading, uploadFilesRequest) => {
    generateFilesUploading({ files, t, arrangeToastMessagesUploadingFiles });

    try {
        uploadFilesRequest({
            files,
            url: "system",
            folder: "root",
            fileRef: false,
            t,
            arrangeToastMessagesUploadingFiles,
            parseName: (name) => {
                const randomString = generateRandomString(); // Function to generate a random string
                const modifiedFileName = `${name.replace(/\.[^/.]+$/, "")}_${randomString}${name.substr(
                    name.lastIndexOf(".")
                )}`;
                return modifiedFileName;
            },
        });
    } catch (err) {
        return { fileRef: null };
    }
};

// Function to generate a random string
const generateRandomString = () => {
    const length = 3;
    const characters = "0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};
