import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

//Utils
import { WidgetContext } from "../../../../contexts/editor";

const WidgetClock = ({ insideMenu, menuStatusStyle }) => {
    //State
    const [render, setRender] = useState(false);
    //Store data
    const { id: widgetID } = useContext(WidgetContext);
    const { gridItems } = useSelector((state) => state.design);
    let widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    if (widget) {
        widget.type = "CLOCK";
        widget.insideMenu = insideMenu === true ? true : false;
    }
    useEffect(() => {
        //Timeout to wait widget_zone exists
        setTimeout(function () {
            setRender(true);
        }, 200);
    }, []);

    return (
        <>
            {render ? (
                <div
                    dangerouslySetInnerHTML={{
                        width: "100%",
                        height: "100%",
                        __html: widget
                            ? window.Render.widget(widget, null, {
                                  insideMenu: insideMenu === true ? true : false,
                                  statusStyle: menuStatusStyle ? menuStatusStyle : null,
                              }).outerHTML
                            : null,
                    }}
                ></div>
            ) : null}
        </>
    );
};

export default WidgetClock;
