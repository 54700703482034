import React from "react";
import translate from "../lang/es/hotel/translate";
//Components
import { Link } from "react-router-dom";

const useIcon = ({ icon, adjust, onclickCallback, id }) => {
    if (!icon || !icon.name) {
        return null;
    }
    const isIcon = getIsIcon(icon.name);

    if (isIcon) {
        icon = getIconColor(icon);
    }

    //Conversion due to API choosen names...
    if (icon && icon.name === "capacityRange") {
        icon.name = "status";
    }

    return (
        <>
            {" "}
            {icon && icon.name === "warning" ? (
                <div id={id} className={`inline-block ${icon.adjust} text-1xl mr-3 cursor-default`}>
                    <span className={`icon icon-${icon.name} warning left-0`}></span>
                    <span className={`icon icon icon-warning1 -ml-1em text-orange-100 left-0`}></span>
                    <span className={`icon icon icon-warning2 -ml-1em text-orange-100 left-0`}></span>
                </div>
            ) : icon && isIcon ? (
                <>
                    {icon.link ? (
                        <Link id={id} to={icon.link}>
                            <div
                                className={`icon-${icon.name} ${icon.color} ${adjust} ${
                                    onclickCallback ? "cursor-pointer" : ""
                                }`}
                                onClick={onclickCallback}
                            ></div>
                        </Link>
                    ) : (
                        <div
                            id={id}
                            className={`icon-${icon.name} ${icon.color} ${adjust} ${
                                onclickCallback ? "cursor-pointer" : ""
                            }`}
                            onClick={onclickCallback}
                        ></div>
                    )}
                </>
            ) : null}
            {icon && !isIcon ? (
                <img
                    id={id}
                    src={require(`../assets/images/icons/${icon.name}-${getVariation(icon)}.svg`)}
                    className={`w-5 mr-3 inline ${onclickCallback ? "cursor-pointer" : ""}`}
                    alt={translate(icon.name)}
                    onClick={onclickCallback}
                ></img>
            ) : null}
        </>
    );
};

export const getIsIcon = (name) => {
    //Detect if icon is a multicolor svg not included in icons font
    let res;
    const imageIcons = ["wifi-signal", "save-circle", "delete-circle"];

    if (imageIcons.includes(name)) {
        res = false;
    } else {
        res = true;
    }
    return res;
};

export const getVariation = (icon) => {
    //Returns variation for image icon
    let res = "";
    if (icon.name === "wifi-signal") {
        if (parseInt(icon.values) >= -50) {
            res = "high";
        } else if (parseInt(icon.values) < -50 && parseInt(icon.values) >= -60) {
            res = "good";
        } else if (parseInt(icon.values) < -60 && parseInt(icon.values) >= -70) {
            res = "medium";
        } else if (parseInt(icon.values) < -70) {
            res = "low";
        }
    } else if (icon.name === "save-circle") {
        if (icon.values === "ok") {
            res = "green";
        } else {
            res = "gray";
        }
    } else if (icon.name === "delete-circle") {
        if (icon.values === "ok") {
            res = "red";
        } else {
            res = "gray";
        }
    }
    return res;
};

export const getIconColor = (icon) => {
    //Return icon CSS class for value color
    let res = icon;

    if (
        icon.itemType === "chromecast" ||
        icon.itemType === "tv" ||
        icon.itemType === "stb" ||
        icon.itemType === "room" ||
        icon.itemType === "device" ||
        icon.itemType === "area-cleaning" ||
        icon.itemType === "area"
    ) {
        switch (icon.values) {
            case "0":
                res.color = "fail";
                break;
            case "1":
                res.color = "success";
                break;
            case "2":
                res.color = "warning";
                break;
            default:
                res.color = "";
                break;
        }
        if (icon.itemType === "chromecast" || icon.itemType === "device") {
            if (icon.values === "2" || icon.values === "3") {
                res.color = "warning";
            }
        }
    }
    if (icon.itemType === "map") {
        res.color = "white";
    }

    if (icon.itemType === "area-capacity") {
        switch (icon.values) {
            case "low":
                res.color = "success";
                break;
            case "medium":
                res.color = "warning";
                break;
            case "high":
                res.color = "fail";
                break;
            default:
                res.color = "";
                break;
        }
    }

    return res;
};

export default useIcon;
