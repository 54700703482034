import Parser from "hooks/Utils/Parser";
import React from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

const UseBatchSelectionTable = ({ index }) => {
    const { results, checkedItems } = useSelector((state) => state.table);
    const { t } = useTranslation();

    const { columnPos, columnName, columnItems } = useSelector((state) => state.ui.modalContent.inputs[index]);

    const channelsInUse = results.filter(
        (result) => checkedItems.filter((item) => item === result.id).length > 0 && result.usedInProjectsNum > 0
    )?.length;

    return (
        <>
            <div id="batch-selection-table-container" className=" w-full">
                <div className=" w-full">
                    <div className=" flex justify-between mb-2">
                        <span className=" font-bold">{columnName ? t(columnName) : ""}</span>
                        <span>
                            {columnItems
                                ? ` ${checkedItems?.length ? checkedItems.length : "0"} ${t(columnItems)}`
                                : ""}
                        </span>
                    </div>
                </div>
                <div
                    className=" border-gray-200 border rounded w-full px-6 pb-3 "
                    style={{ minHeight: "20.3rem", maxHeight: "20.3rem", overflowY: "scroll" }}
                >
                    {results.map((result) =>
                        checkedItems.filter((item) => item === result.id).length > 0 ? (
                            <div className=" border-b py-3 border-gray-200">{renderInfoValue(result, columnPos)}</div>
                        ) : null
                    )}
                </div>
            </div>
            {channelsInUse ? (
                <div className="relative mt-6">
                    <span className="icon icon-warning  mr-3 text-lg text-orange-100"></span>
                    <span>
                        {Parser(t(`channels-in-use${channelsInUse > 1 ? "_plural" : ""}`, { num: channelsInUse }))}
                    </span>
                </div>
            ) : null}
        </>
    );
};

export default UseBatchSelectionTable;

const renderInfoValue = (result, columnPos) => {
    let response = "";
    if (result.info[columnPos].value) {
        response = result.info[columnPos].value;
    }
    return response;
};
