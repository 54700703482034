import React from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table/index";
//Utils
import ListCategories from "../../../hooks/GraphqlCalls/CommonZones/ListCategories";

const Categories = () => {
    ListCategories();

    return (
        <>
            <UseSectionHeader title={"categories-list"} />
            <UseTable />
        </>
    );
};

export default Categories;
