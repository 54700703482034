import React from "react";

const UseCalendarNavbar = ({ onPreviousClick, onNextClick }) => {
    //NavBar arrows styles
    const styleLeft = {
        float: "left",
    };
    const styleRight = {
        float: "right",
    };
    return (
        <div className="absolute h-10 w-full">
            <div
                style={styleLeft}
                className="px-2 pt-6 icon-chevron-left cursor-pointer hover:text-blue-200 text-2xl text-gray-600"
                onClick={() => onPreviousClick()}
            ></div>
            <div
                style={styleRight}
                className="px-2 pt-6 icon-chevron-right cursor-pointer hover:text-blue-200 text-2xl text-gray-600"
                onClick={() => onNextClick()}
            ></div>
        </div>
    );
};

export default UseCalendarNavbar;
