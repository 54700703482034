import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getTranslationName,
    handleTaxRateChange,
    handleValueAfterTaxChange,
    handleValueBeforeTaxChange,
    updatePrice,
} from "../../../../../../../../hooks/Utils/SalesUtils";
import { setExecuteQuery } from "../../../../../../../../actions/sectionActions";
import { setActionValidations } from "../../../../../../../../actions/globalActions";
import { UPDATE_PRODUCT_EXTRA } from "../../../../../../../../hooks/Utils/Integrations/ShopSettingsUtils";
import { validateSection } from "../../../../../../../../hooks/Utils/Validations";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import UseRadio from "../../../../../../../useRadio";
import UseButton from "../../../../../../../useButton";
import UseInputText from "../../../../../../../Inputs/useInputText";
import UseSwitch from "../../../../../../../Inputs/useSwitch";
import UseSelect from "../../../../../../../Inputs/useSelect";
import UseCurrencyInput from "../../../../../../../Table/UseCurrencyInput";
import ReactTooltip from "components/ReactTooltip";
import _ from "lodash";

function ExtraSingleItemEditCard({ item, langDefault, extraData, index }) {
    const dispatch = useDispatch();

    //Consts&states
    const { id } = item;
    const { channel, taxCategories, setEditingItemId, setLoading } = extraData;
    const { t } = useTranslation();
    const { values, validations } = useSelector((state) => state.action);
    const [taxRate, setTaxRate] = useState(null);
    const [name, setName] = useState(getTranslationName(item.translations, langDefault));
    const [available, setAvailable] = useState(item.available);
    const [priceWithoutTax, setPriceWithoutTax] = useState(item.options ? item.options.priceWithoutTax : null);
    const [taxCategoryId, setTaxCategoryId] = useState(item.taxCategory);
    const [priceWithTax, setPriceWithTax] = useState(null);
    const [typeOfSelection, setTypeOfSelection] = useState(item.typeOfSelection);
    const typeOfSelectionOptions = [`single-unit`, `several-units-per-item`];
    const decimalSeparator = (1.1).toLocaleString().substring(1, 2);
    const sectionName = "product-edit-extra";
    const inputsIds = [
        `${sectionName}-name`,
        `${sectionName}-available`,
        `${sectionName}-without-tax`,
        `${sectionName}-tax-r${index}`,
        `${sectionName}-total`,
        `${sectionName}-type-of-selection`,
        `${sectionName}-type-of-selection-${typeOfSelectionOptions[0]}`,
        `${sectionName}-type-of-selection-${typeOfSelectionOptions[1]}`,
        `${sectionName}-cancel-changes`,
        `${sectionName}-save-changes`,
    ];

    //queries&mutations

    //effects
    useEffect(() => {
        let taxRate = null;
        if (taxCategoryId && taxCategories && taxCategories.length > 0) {
            const taxCategoriData = _.find(taxCategories, { value: taxCategoryId });
            if (taxCategoriData) {
                taxRate = taxCategoriData.taxRateValue;
            }
        }
        if (priceWithoutTax > 0 && taxRate === null) {
            updatePrice(priceWithoutTax, setPriceWithTax, setPriceWithoutTax);
        } else if (priceWithoutTax > 0 && taxRate) {
            updatePrice(priceWithoutTax, null, setPriceWithoutTax);
            handleTaxRateChange(taxRate, priceWithoutTax, decimalSeparator, setPriceWithTax);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (values) {
            if (values[inputsIds[3]]) {
                const taxCategoriData = _.find(taxCategories, { value: values[inputsIds[3]] });
                if (taxCategoriData) {
                    setTaxCategoryId(taxCategoriData.value);
                    setTaxRate(taxCategoriData.taxRateValue);
                }
            }
        }
        // eslint-disable-next-line
    }, [values]);

    useEffect(() => {
        if (taxRate > 0) {
            handleTaxRateChange(taxRate, priceWithoutTax, decimalSeparator, setPriceWithTax);
        }
        // eslint-disable-next-line
    }, [taxRate]);

    //arrangeData

    //handle&functions
    const handleCancel = () => {
        setEditingItemId(null);
    };

    const handleSave = () => {
        let values = {};
        values[inputsIds[0]] = name;
        const validations = validateSection(UPDATE_PRODUCT_EXTRA, values, t);
        dispatch(setActionValidations(validations));
        const validationsArray = Object.values(validations);
        if (validationsArray.filter((item) => item === false).length > 0) {
            toast.error(t("errors-in-red"));
        } else {
            let translations = [...item.translations];
            let translationIndex = translations.findIndex((translation) => translation.languageCode === langDefault);
            let type = null;
            let options = { ...item.options };
            let minimumUnitPriceWthoutTax =
                (parseFloat(String(priceWithoutTax).replace(decimalSeparator, ".")) || 0) * 100;
            minimumUnitPriceWthoutTax = Math.round(minimumUnitPriceWthoutTax);
            options.priceWithoutTax = minimumUnitPriceWthoutTax;
            if (translationIndex !== -1) translations[translationIndex].name = name;
            if (typeOfSelection) {
                switch (typeOfSelection) {
                    case typeOfSelectionOptions[0]:
                        type = "normal";
                        break;
                    case typeOfSelectionOptions[1]:
                        type = "numeric";
                        break;
                    default:
                        break;
                }
            }
            setLoading(true);
            dispatch(
                setExecuteQuery({
                    action: UPDATE_PRODUCT_EXTRA,
                    params: {
                        id: id,
                        type: type,
                        available: available,
                        order: item.order,
                        appliesTo: item.appliesTo,
                        taxCategory: taxCategoryId,
                        options: options,
                        translations: translations,
                    },
                })
            );
        }
    };

    //renders
    const renderTypeOfSelectionToolTip = (type) => {
        return (
            <div className="flex items-center w-full justify-between">
                {type === typeOfSelectionOptions[0] && (
                    <span className="h-6 w-6 border rounded border-gray-600 mr-2"></span>
                )}
                <span className="mr-4 max-w-xs truncate">{name && name !== "" ? name : t("extra-name")}</span>
                {type === typeOfSelectionOptions[1] && (
                    <div className="flex items-center">
                        <span className="text-3xl">-</span>
                        <div className="h-8 w-8 flex items-center justify-center mx-1 border rounded-md border-gray-600">
                            <span className="text-base text-gray-500">01</span>
                        </div>
                        <span className="text-3xl">+</span>
                    </div>
                )}
            </div>
        );
    };

    //response
    return (
        <div className="w-full pl-3 pr-2">
            <div className="flex items-center align-middle w-full justify-between pb-4 pt-3 border-b-2 border-gray-200">
                <div className="flex items-center">
                    <span className="icon icon-drag-and-drop text-gray-800 text-3xl mr-4 hidden"></span>
                    <div className="w-96">
                        <UseInputText
                            id={inputsIds[0]}
                            ph="extra-name"
                            value={name}
                            changeAction={(value) => {
                                setName(value);
                            }}
                            adjust={`${
                                typeof validations[inputsIds[0]] !== "undefined" && !validations[inputsIds[0]]
                                    ? "border border-red-100"
                                    : ""
                            }`}
                        />
                    </div>
                    <span className="font-bold ml-5">{t(item.typeOfExtra)}</span>
                </div>
                <span className="flex">
                    <span className="">{t("{{capitalize, capitalize}}", { capitalize: t("available") })}</span>
                    <UseSwitch
                        id={inputsIds[1]}
                        checked={available}
                        action={(value) => {
                            setAvailable(value);
                        }}
                    />
                </span>
            </div>
            <div className="w-full flex my-2 ">
                <div className="w-8/12 border-r-2 border-gray-200 pl-16">
                    <span className="font-bold text-lg block my-3">
                        {t("{{capitalize, capitalize}}", { capitalize: t("price") })}
                    </span>
                    <div className="w-full flex space-x-6">
                        <div className="">
                            <span className="block pb-3">
                                {t("{{capitalize, capitalize}}", { capitalize: t("without-tax") })}
                            </span>
                            <div className="w-36 z-10">
                                <UseCurrencyInput
                                    inputData={{ name: inputsIds[2] }}
                                    id={inputsIds[2]}
                                    validation={true}
                                    value={priceWithoutTax}
                                    onChange={(values) =>
                                        handleValueBeforeTaxChange(
                                            values,
                                            taxRate,
                                            decimalSeparator,
                                            setPriceWithoutTax,
                                            setPriceWithTax
                                        )
                                    }
                                    currencyCode={channel?.currencyCode}
                                />
                            </div>
                        </div>
                        <div className="">
                            <span className="block pb-2">
                                {t("{{capitalize, capitalize}}", { capitalize: t("tax") })}
                            </span>
                            <div className="w-52 z-10 cursor-pointer">
                                <UseSelect
                                    selectData={{
                                        id: inputsIds[3],
                                        name: "tax",
                                        placeHolder: "tax",
                                        data: taxCategories ? taxCategories : [],
                                        noTranslateAV: true,
                                        containerOptionsAdjust: "overflow-y-auto max-h-20",
                                        activeValue: taxCategoryId,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="">
                            <span className="block pb-3">
                                {t("{{capitalize, capitalize}}", { capitalize: t("total") })}
                            </span>
                            <div className="w-36 z-10 cursor-pointer">
                                <UseCurrencyInput
                                    inputData={{ name: inputsIds[4] }}
                                    id={inputsIds[4]}
                                    validation={true}
                                    value={priceWithTax}
                                    onChange={(values) =>
                                        handleValueAfterTaxChange(
                                            values,
                                            taxRate,
                                            decimalSeparator,
                                            setPriceWithoutTax,
                                            setPriceWithTax
                                        )
                                    }
                                    currencyCode={channel?.currencyCode}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-4/12 pl-5">
                    <div className="w-full">
                        <span className="font-bold text-lg block">
                            {t("{{capitalize, capitalize}}", { capitalize: t("settings") })}:
                        </span>
                        <span className="font-bold text-lg block">
                            {t("{{capitalize, capitalize}}", { capitalize: t("type-of-selection") })}
                        </span>
                    </div>
                    <div className="w-full my-5">
                        <div className="w-full mb-4">
                            <UseRadio
                                id={inputsIds[7]}
                                value={typeOfSelectionOptions[1]}
                                selected={typeOfSelection}
                                handleClick={(value) => {
                                    setTypeOfSelection(value);
                                }}
                            />
                            <span className="ml-6" data-tip data-for={`${typeOfSelectionOptions[0]}Tooltip`}>
                                {t("several-units")}
                            </span>
                            <ReactTooltip
                                id={`${typeOfSelectionOptions[0]}Tooltip`}
                                type="light"
                                borderColor={"#D3DAE1"}
                                border={true}
                                multiline={true}
                                place="right"
                            >
                                {renderTypeOfSelectionToolTip(typeOfSelectionOptions[1])}
                            </ReactTooltip>
                        </div>
                        <div className="w-full">
                            <UseRadio
                                id={inputsIds[6]}
                                value={typeOfSelectionOptions[0]}
                                selected={typeOfSelection}
                                handleClick={(value) => {
                                    setTypeOfSelection(value);
                                }}
                            />
                            <span className="ml-6" data-tip data-for={`${typeOfSelectionOptions[1]}Tooltip`}>
                                {t("single-unit")}
                            </span>
                            <ReactTooltip
                                id={`${typeOfSelectionOptions[1]}Tooltip`}
                                type="light"
                                borderColor={"#D3DAE1"}
                                border={true}
                                multiline={true}
                                place="right"
                            >
                                {renderTypeOfSelectionToolTip(typeOfSelectionOptions[0])}
                            </ReactTooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-end h-10 mt-8 mr-4 mb-4">
                <div className="w-auto inline-block mr-4" onClick={handleCancel}>
                    <UseButton id={inputsIds[8]} buttonName="cancel" buttonColor="btn-blue-outline" adjust="px-8" />
                </div>
                <div className="w-auto inline-block" onClick={handleSave}>
                    <UseButton id={inputsIds[9]} buttonName="save" buttonColor="btn-blue" adjust="px-8" />
                </div>
            </div>
        </div>
    );
}

export default ExtraSingleItemEditCard;
