import { capitalizeFirst } from "hooks/Utils/Utils";
import i18n from "i18n";
const EditDocumentation = (data) => {
    return {
        title: `${data.name} ${data.surname} - ${capitalizeFirst(i18n.t("edit-documentation"))}`,
        bodyAdjust: "w-3/12",
        text: "edit-the-following-informations",
        executeSectionQuery: true,
        inputs: [
            {
                text: "new-alarm",
                type: "editDocumentation",
                data: data,
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: "edit-documentation",
            },
        ],
    };
};

export default EditDocumentation;
