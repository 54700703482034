const ApplyMakeDefaultGrid = (t, { id, name }) => {
    return {
        title: `${t("apply")} ${name}`,
        text: t("the-grid-will-be-applied-to-the-property"),
        executeSectionQuery: true,
        bodyAdjust: "w-1/12",
        // value: data.name ? data.name : "",
        inputs: [
            {
                text: "",
                name: "id-grid",
                type: "text",
                focus: true,
                ph: t("name"),
                value: id ? id : "",
                style: "hidden",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "apply",
                style: "blue",
                action: "make-grid-enabled",
            },
        ],
    };
};

export default ApplyMakeDefaultGrid;
