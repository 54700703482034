export const TYPE_BATCH_ACTION = {
    DELETE_SHOP: "delete_shop",
    AVAILABLE_SHOP: "available_shop",
    UNAVAILABLE_SHOP: "unavailable_shop",
    DELETE_PRODUCT: "delete_product",
    AVAILABLE_PRODUCT: "available_product",
    UNAVAILABLE_PRODUCT: "unavailable_product",
    AVAILABLE_CATEGORY: "available_category",
    UNAVAILABLE_CATEGORY: "unavailable_category",
    DELETE_CATEGORY: "delete_category",
};

export const ButtonDesign = (type) => {
    if (
        type === TYPE_BATCH_ACTION.DELETE_SHOP ||
        type === TYPE_BATCH_ACTION.DELETE_CATEGORY ||
        type === TYPE_BATCH_ACTION.DELETE_PRODUCT
    ) {
        return { textButton: "delete", colorButton: "red" };
    } else {
        return { textButton: "accept", colorButton: "blue" };
    }
};

const MUTATION_TEMPLATES = {
    [TYPE_BATCH_ACTION.DELETE_SHOP]: {
        mutation: `
        mutation deleteShop($id: Int64!) {
          deleteShop(id: $id) {
            ok
            id
            error
          }
        }
      `,
        getVariables: (item) => ({
            id: item.id,
        }),
    },
    [TYPE_BATCH_ACTION.AVAILABLE_SHOP]: {
        mutation: `
        mutation updateChannel($id: ID!, $isActive: Boolean!) {
          updateChannel(input: {
            id: $id,
            customFields: {
              is_active: $isActive
            }
          }) {
            ... on Channel {
              __typename
              id
            }
            ... on LanguageNotAvailableError {
              __typename
              message
              errorCode
              languageCode
            }
          }
        }
      `,
        getVariables: (item) => ({
            id: item.id,
            isActive: true,
        }),
    },
    [TYPE_BATCH_ACTION.UNAVAILABLE_SHOP]: {
        mutation: `
        mutation updateChannel($id: ID!, $isActive: Boolean!) {
          updateChannel(input: {
            id: $id,
            customFields: {
              is_active: $isActive
            }
          }) {
            ... on Channel {
              __typename
              id
            }
            ... on LanguageNotAvailableError {
              __typename
              message
              errorCode
              languageCode
            }
          }
        }
      `,
        getVariables: (item) => ({
            id: item.id,
            isActive: false,
        }),
    },
    [TYPE_BATCH_ACTION.DELETE_PRODUCT]: {
        mutation: `
        mutation deleteProduct($id: ID!) {
          deleteProduct(id: $id) {
            result
            message
          }
        }
      `,
        getVariables: (item) => ({
            id: item.id,
        }),
    },
    [TYPE_BATCH_ACTION.AVAILABLE_PRODUCT]: {
        mutation: `
        mutation updateProduct($id: ID!, $enabled: Boolean!) {
          updateProduct(input: {
            id: $id,
            enabled: $enabled
          }) {
            id
            name
            enabled
          }
        }
      `,
        getVariables: (item) => ({
            id: item.id,
            enabled: true,
        }),
    },
    [TYPE_BATCH_ACTION.UNAVAILABLE_PRODUCT]: {
        mutation: `
        mutation updateProduct($id: ID!, $enabled: Boolean!) {
          updateProduct(input: {
            id: $id,
            enabled: $enabled
          }) {
            id
            name
            enabled
          }
        }
      `,
        getVariables: (item) => ({
            id: item.id,
            enabled: false,
        }),
    },
    [TYPE_BATCH_ACTION.AVAILABLE_CATEGORY]: {
        mutation: `
          mutation updateCollection($id: ID!, $enabled: Boolean!) {
            updateCollection(input: {
              id: $id,
              customFields: {
                enabled: $enabled
              }
            }) {
              id
              name
              customFields {
                enabled
              }
            }
          }
        `,
        getVariables: (item) => ({
            id: item.id,
            enabled: true,
        }),
    },
    [TYPE_BATCH_ACTION.UNAVAILABLE_CATEGORY]: {
        mutation: `
          mutation updateCollection($id: ID!, $enabled: Boolean!) {
            updateCollection(input: {
              id: $id,
              customFields: {
                enabled: $enabled
              }
            }) {
              id
              name
              customFields {
                enabled
              }
            }
          }
        `,
        getVariables: (item) => ({
            id: item.id,
            enabled: false,
        }),
    },
    [TYPE_BATCH_ACTION.DELETE_CATEGORY]: {
        mutation: `
          mutation DeleteCollectionAndFacetValues($collectionId: ID!, $facetValuesIds: [ID!]!, $force: Boolean) {
            deleteCollection(id: $collectionId) {
                result
                message
            }
            deleteFacetValues(ids: $facetValuesIds, force: $force) {
                result
                message
            }
        }
        `,
        getVariables: (item) => ({
            collectionId: item.id,
            facetValuesIds: item?.extraParams?.facetValuesIds?.length > 0 ? item.extraParams.facetValuesIds : [],
            force: true,
        }),
    },
};
export const MUTATIONS = (type, item) => {
    const mutationTemplate = MUTATION_TEMPLATES[type];

    if (!mutationTemplate) {
        console.log("Unsupported type.");
        return null;
    }

    return {
        mutation: mutationTemplate.mutation,
        variables: mutationTemplate.getVariables(item),
    };
};
