export const ExportGuestRegistrationForm = (t) => {
    return {
        title: "guest-report",
        text: t("the-guest-report-will-be-exported-from-the-check-ins-made-in"),
        executeSectionQuery: true,
        bodyAdjust: "w-4/12",
        inputs: [{ type: "dateRange", closingDays: { after: new Date() } }],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                action: "export-guest-registration-form",
                disabled: true,
                name: "export",
                style: "blue",
            },
        ],
    };
};
