const NewPasscode = () => {
    return {
        title: "new-passcode",
        bodyAdjust: "w-3/12",
        executeSectionQuery: true,
        inputs: [
            {
                text: "new-alarm",
                type: "newPasscode",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: "new-passcode",
            },
        ],
    };
};

export default NewPasscode;
