import {
    SET_ACTION,
    CHANGE_ACTION_VALUES,
    SET_ACTION_VALUES,
    CHANGE_ACTION_VALIDATIONS,
    SET_ACTION_VALIDATIONS,
    CLEAN_ACTION_VALIDATIONS,
    CLEAN_ACTION_VALUES,
    SET_ACTION_NAME,
    SET_ITEM_NAME,
    SET_DATETYPE,
    SET_SPECIFIC_DAYS,
    SET_SPECIFIC_RANGE,
    SET_SPECIFIC_SCHEDULE,
    WEEKDAY_ADD,
    WEEKDAY_REMOVE,
    SET_ITEM_AFFECTED,
    CLEAN_SCHEDULE_HOURS,
    ADD_ACTION_INPUT,
    SET_ACTION_DATA,
    CLEAN_ACTION,
    CLEAN_SCHEDULE_DATES,
    PREPARE_UPDATES,
    SET_ACTION_BACKUP,
} from "../actions/globalActions";

const initialState = {
    actionName: false,
    actionNameBackup: false,
    itemsAffected: false,
    values: false,
    validations: {},
    preparedUpdates: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ACTION:
            return {
                ...state,
                ...action.payload,
            };
        case CHANGE_ACTION_VALIDATIONS:
            return {
                ...state,
                validations: { ...state.validations, ...action.payload },
            };
        case SET_ACTION_VALIDATIONS:
            return {
                ...state,
                validations: action.payload,
            };
        case CLEAN_ACTION_VALIDATIONS:
            return {
                ...state,
                validations: {},
            };
        case CHANGE_ACTION_VALUES:
            return {
                ...state,
                values: { ...state.values, ...action.payload },
            };
        case SET_ACTION_VALUES:
            return {
                ...state,
                values: { ...action.payload },
            };
        case CLEAN_ACTION_VALUES:
            return {
                ...state,
                values: {},
            };
        case SET_ACTION_NAME:
            return {
                ...state,
                actionName: action.payload,
            };
        case SET_ACTION_BACKUP:
            return {
                ...state,
                actionBackup: action.payload,
            };
        case SET_ITEM_NAME:
            return {
                ...state,
                itemName: action.payload,
            };
        case SET_DATETYPE:
            return {
                ...state,
                dateType: action.payload,
            };
        case SET_SPECIFIC_DAYS:
            return {
                ...state,
                specificDates: action.payload,
            };
        case SET_SPECIFIC_RANGE:
            return {
                ...state,
                specificRange: action.payload,
            };
        case SET_SPECIFIC_SCHEDULE:
            let newScheduleState = state.schedule ? state.schedule : {};
            const key = action.payload[0];
            const value = action.payload[1];
            newScheduleState[key] = value;
            return {
                ...state,
                schedule: newScheduleState,
            };
        case WEEKDAY_ADD:
            //ISO 8601 standard Monday(1) Sunday (7)
            if (state.weekDays) {
                return {
                    ...state,
                    weekDays: [...state.weekDays, action.payload],
                };
            } else {
                return {
                    ...state,
                    weekDays: [action.payload],
                };
            }
        case WEEKDAY_REMOVE:
            //ISO 8601 standard Monday(1) Sunday (7)
            const newWeekDays = state.weekDays ? state.weekDays.filter((item) => item !== action.payload) : null;
            return {
                ...state,
                weekDays: newWeekDays,
            };
        case SET_ITEM_AFFECTED:
            return {
                ...state,
                itemsAffected: action.payload,
            };
        case CLEAN_SCHEDULE_HOURS:
            return {
                ...state,
                schedule: false,
            };
        case ADD_ACTION_INPUT:
            return {
                ...state,
                actionData: {
                    ...state.actionData,
                    ...action.payload,
                },
            };
        case SET_ACTION_DATA:
            return {
                ...state,
                actionData: action.payload,
            };
        case CLEAN_ACTION:
            return initialState;
        case CLEAN_SCHEDULE_DATES:
            return {
                ...state,
                specificRange: false,
                specificDates: false,
            };
        case PREPARE_UPDATES:
            return {
                ...state,
                preparedUpdates: { ...state.preparedUpdates, ...action.payload },
            };
        default:
            return state;
    }
}
