import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLazyQuery, useMutation } from "react-apollo";
import { gql } from "apollo-boost";
import { Radiobutton } from "components/Inputs/Radiobuttons";
import Select from "components/Select";
import Switch from "components/Switch";
import Modal, { useModal } from "components/Modal";
import Icon from "components/Icon";
import Button from "components/Button";

const UPDATE_MOVIES_TAXES = gql`
    mutation UpdateMoviesTaxes($pricesWithTaxes: Boolean!, $taxZone: String!, $defaultTax: String!) {
        updateMoviesTaxes(pricesWithTaxes: $pricesWithTaxes, taxZone: $taxZone, defaultTax: $defaultTax) {
            error
            ok
        }
    }
`;

const TaxRatesModal = ({ onUpdate, onSave, savedTaxData, fetchTaxZones, taxesData }) => {
    const { t } = useTranslation();
    const { close } = useModal();
    const [filteredTaxRates, setFilteredTaxRates] = useState([]);
    const [localFilteredTaxRates, setLocalFilteredTaxRates] = useState([]);
    const [selectedTaxRateId, setSelectedTaxRateId] = useState(null);
    const [selectedZone, setSelectedZone] = useState(null);
    const [localSelectedZone, setLocalSelectedZone] = useState(null);
    const [pricesWithTaxes, setPricesWithTaxes] = useState(true);
    const [selectedTaxRate, setSelectedTaxRate] = useState(null);

    useEffect(() => {
        if (taxesData?.taxRates?.items && selectedZone) {
            const filteredRates = taxesData.taxRates.items.filter((tax) => {
                return String(tax.zone.id) === String(selectedZone?.id);
            });

            setFilteredTaxRates(filteredRates || []);
        } else {
            setFilteredTaxRates([]);
        }
    }, [selectedZone, taxesData]);

    useEffect(() => {
        const enabledTaxRates = filteredTaxRates.filter((rate) => rate.enabled);
        setLocalFilteredTaxRates(enabledTaxRates);

        if (
            enabledTaxRates.length > 0 &&
            (!selectedTaxRateId || !enabledTaxRates.find((rate) => rate.id === selectedTaxRateId))
        ) {
            setSelectedTaxRateId(enabledTaxRates[0].id);
        }
    }, [filteredTaxRates]);

    useEffect(() => {
        const selectedTaxRateData = localFilteredTaxRates.find((rate) => rate.id === selectedTaxRateId);
        setSelectedTaxRate(selectedTaxRateData || null);
    }, [selectedTaxRateId, localFilteredTaxRates]);

    const [updateMoviesTaxes] = useMutation(UPDATE_MOVIES_TAXES, {
        onCompleted: () => {
            onSave(localSelectedZone, selectedTaxRate, pricesWithTaxes);
            close();
        },
        onError: (error) => {
            console.error("Error updating tax rates:", error);
        },
    });

    useEffect(() => {
        if (savedTaxData) {
            const zone = savedTaxData.zone || null;
            const taxRateId = savedTaxData.taxRate?.id || null;

            if (!zone || !taxRateId) {
                setSelectedZone(null);
                setSelectedTaxRateId(null);
            } else {
                setSelectedZone(zone);
                setSelectedTaxRateId(taxRateId);
            }

            setPricesWithTaxes(savedTaxData.pricesWithTaxes !== undefined ? savedTaxData.pricesWithTaxes : true);
        }
    }, [savedTaxData]);

    const handleSwitchChange = (event) => {
        if (event && event.checked !== undefined) {
            setPricesWithTaxes(event.checked);
        }
    };

    useEffect(() => {
        setLocalSelectedZone(selectedZone);
    }, [selectedZone]);

    const handleSave = () => {
        const taxZone = String(localSelectedZone.id);
        const defaultTax = String(selectedTaxRate.id);

        updateMoviesTaxes({
            variables: {
                pricesWithTaxes: pricesWithTaxes,
                taxZone: taxZone,
                defaultTax: defaultTax,
            },
        }).then((response) => {
            if (response.data.updateMoviesTaxes.ok) {
                fetchTaxZones();
            }
        });

        onUpdate(localSelectedZone, selectedTaxRate, pricesWithTaxes);
        onSave(localSelectedZone, selectedTaxRate, pricesWithTaxes);
        close();
    };

    return (
        <div className="text-justify px-4">
            <div className="text-xl font-bold px-4 py-1">{t("select-a-tax-zone")}</div>
            <div className="px-4 py-4">
                <div className="flex flex-col space-y-4 mb-4">
                    <Select
                        id={"dropdown-tax-rates"}
                        width="100%"
                        placeholder={selectedZone?.name ? selectedZone.name : t("select-a-tax-zone")}
                        options={
                            taxesData?.zones.map((zone) => ({
                                value: zone.id,
                                label: zone.name,
                                key: zone.id,
                            })) || [
                                {
                                    value: "",
                                    label: t("No tax zones available"),
                                    disabled: true,
                                    style: { color: "black" },
                                },
                            ]
                        }
                        onChange={(selectedValue) => {
                            const selectedZoneData = taxesData?.zones.find((zone) => zone.id === selectedValue);
                            setSelectedZone(selectedZoneData || null);
                        }}
                        value={selectedZone?.id || ""}
                        disabled={false}
                        style={{ color: selectedZone?.name ? "black" : "gray" }}
                    />

                    {localFilteredTaxRates.length > 0 ? (
                        <ul className="mt-4">
                            <div className="text-l mb-4">{t("available-taxes-select-the-default-taxes")}</div>
                            {localFilteredTaxRates.map((rate) => (
                                <li key={rate.id} className="flex items-center mb-2">
                                    <Radiobutton
                                        id={`tax-rate-${rate.id}`}
                                        label={`${rate.name} : ${rate.value}%`}
                                        checked={selectedTaxRateId === rate.id}
                                        onChange={() => {
                                            setSelectedTaxRateId(rate.id);
                                        }}
                                    />
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="flex flex-row items-center space-x-3 mb-4">
                            <Icon
                                className="icon icon-warning inline-block text-xl lg:text-2xl text-orange-100 hover:text-orange-400 cursor-help"
                                data-tip="This is a warning tooltip"
                                data-for="dangerous-html-tooltip"
                                currentitem="false"
                                style={{ fontSize: "1.667rem" }}
                            />
                            <p className="text-justify">{t("tax-not-selected-tv-movies")}</p>
                        </div>
                    )}
                </div>
                <div className="mb-6">
                    <p className="text-justify">{t("select-whether-movies-price")}</p>
                </div>
                <div className="mt-4 flex items-center space-x-2">
                    <label className={`${selectedTaxRateId ? "text-black" : "text-gray-500"}`}>
                        {t("display-with-tax")}
                    </label>
                    <Switch
                        id="switch-movies-taxes"
                        checked={pricesWithTaxes}
                        disabled={!selectedTaxRateId}
                        onChange={handleSwitchChange}
                        design="switch"
                    />
                </div>
            </div>
            <div id="modal-buttons" className="pt-8 text-center space-x-3 flex justify-end">
                <Button design="black" id="modal-button-cancel" onClick={close}>
                    {t("cancel")}
                </Button>
                <Button design="blue" id="modal-button-save" onClick={handleSave}>
                    {t("save")}
                </Button>
            </div>
        </div>
    );
};

export default TaxRatesModal;
