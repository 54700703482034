import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
//API
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
//Actions
import { showGlobalLoading } from "../../../../actions/uiActions";

const RoomPreview = (roomId, lang) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const previewFrame = document.getElementById("roomPreviewFrame");

    const GET_HTML_PREVIEW = gql`{
        preview: htmlPreviewPageRoom( roomTvID :"${roomId}" lang:"${lang}")
    }`;

    const [executeQuery, { data, error, loading }] = useLazyQuery(GET_HTML_PREVIEW);

    useEffect(() => {
        dispatch(showGlobalLoading(true));
        executeQuery();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!loading && !error && data && data.preview) {
            previewFrame.srcdoc = data.preview;
            dispatch(showGlobalLoading(false));
        }
        //eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (error) {
            previewFrame.srcdoc = `<div style="width:100%; min-height:100vh; display: flex; align-items: center; justify-content: center">${t(
                "technical-error"
            )}</div>`;
            dispatch(showGlobalLoading(false));
        }
        //eslint-disable-next-line
    }, [error]);
};

export default RoomPreview;
