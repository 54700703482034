import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//Components
import UseButton from "../../../../useButton";
//Actions
import { setWidgetToolbarVisible } from "../../../../../actions/designActions";
import { setExecuteQuery } from "../../../../../actions/sectionActions";
import { EditorContext, WidgetContext } from "../../../../../contexts/editor";

//Utils
import { toast } from "react-toastify";

import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Widget from "../Widget";

const IframeToolbar = ({ deviceSpecific }) => {
    const { itemSelected } = useContext(EditorContext);
    const { id: widgetID } = useContext(WidgetContext);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    //State
    const [error, setError] = useState(false);
    const [cItem, setCItem] = useState();

    //Store data
    const { gridItems, widgetToolbarVisible } = useSelector((state) => state.design);
    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const sectionName = `toolbar`;

    useEffect(() => {
        if (widgetToolbarVisible === "iframe-settings" && itemSelected === widgetID) {
            const gItem = gridItems.filter((gridItem) => gridItem.i === widgetID)[0];
            setCItem(gItem);
            document.querySelector(`#input_${widgetID}`).value =
                gItem && gItem.data && gItem.data.url
                    ? gridItems.filter((gridItem) => gridItem.i === widgetID)[0].data.url
                    : "";
        }
        // eslint-disable-next-line
    }, [widgetToolbarVisible]);

    const updateIframeURL = () => {
        const value = document.querySelector(`#input_${widgetID}`).value;
        if (value.indexOf("https") === -1) {
            setError(true);
            toast.error(t("website address (https only)"));
            return;
        } else {
            setError(false);
        }

        dispatch(
            setExecuteQuery(
                Widget.UpdateData({
                    widget,
                    property: "url",
                    value,
                })
            )
        );
    };

    return (
        <>
            <div
                id={`${sectionName}-settings`}
                className={`icon icon-settings-editor cursor-pointer p-2 ${
                    widgetToolbarVisible === "iframe-settings" ? "toolbarOptionSelected" : ""
                }`}
                onClick={(e) =>
                    dispatch(
                        setWidgetToolbarVisible(widgetToolbarVisible === "iframe-settings" ? null : "iframe-settings")
                    )
                }
            ></div>
            {widgetToolbarVisible === "iframe-settings" ? (
                <div
                    id={`widgetOptionContainer_${widgetID}`}
                    className={`widgetOptionContainer invisible`}
                    style={{ width: "26rem" }}
                >
                    <div className="first-capital font-bold mb-8">{t("add-iframe")}</div>
                    <div className="first-capital mb-4">{t("website address (https only)")}:</div>
                    <input
                        type="text"
                        id={`input_${widgetID}`}
                        className={`w-full bg-gray-200 rounded mb-4 px-4 py-2 ${error ? " border border-red-100" : ""}
                        `}
                    />

                    <UseButton
                        id={"add-url-image-"}
                        buttonName={cItem && cItem.data && cItem.data.url ? "update " : "apply"}
                        buttonColor={"btn-blue"}
                        adjust="inline-flex float-right"
                        action={updateIframeURL}
                    />
                </div>
            ) : null}
            <Toolbar type="style">
                <Settings.Border t={t} />
            </Toolbar>
            {/* <Toolbar type="clone" /> */}
            <Toolbar type="delete" />
        </>
    );
};

export default IframeToolbar;
