import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import UseInputText from "../../../../../Inputs/useInputText";
import UseInputPercent from "../../../../../Inputs/useInputPercent";
import { setColorPickerReference } from "../../../../../../actions/designActions";
import { useDispatch, useSelector } from "react-redux";
import { setSectionContent } from "../../../../../../actions/sectionActions";
import { getRGBA, hexToRgbA, rgbaToHex } from "../../../../../../hooks/Utils/DesignUtils";
import { capitalizeT } from "../../../../../../hooks/Utils/Utils";

const UseColor = ({ showTitle }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { styleInputData, salesStyleData } = useSelector((state) => state.sectionContent);
    const [inputHexError, setInputHexError] = useState(false);

    const ID_INPUT_DATA = "color";

    const _key = useRef();
    _key.current = styleInputData?.key;

    const ID_TEXT_INPUT = _key?.current?.listOption ? `${_key.current?.listOption}-color` : "color";
    const ID_TEXT_INPUT_OPACITY = _key?.current?.listOption
        ? `${_key.current?.listOption}-color-opacity`
        : "color-opacity";

    const createPickerCallBack = () => {
        window.onSelectCallback = (params) => {
            const { color } = params;
            onBlurAction(color, ID_INPUT_DATA);
        };
    };

    useEffect(() => {
        if (!window?.onSelectCallback) {
            createPickerCallBack();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        createPickerCallBack();
        // eslint-disable-next-line
    }, [styleInputData]);

    const changeInputsFields = () => {
        setInputHexError(false);
        const _hex = document.getElementById(ID_TEXT_INPUT).value;
        const _alpha = document.getElementById(ID_TEXT_INPUT_OPACITY).value.replace(" %", "");
        if (!_hex || _hex !== "") {
            try {
                const _rgbaColor = hexToRgbA(_hex, _alpha);
                onBlurAction(_rgbaColor, ID_INPUT_DATA);
            } catch (error) {
                setInputHexError(true);
            }
        }
    };

    const onBlurAction = (color, id) => {
        const keyOption = { ..._key.current, input: id };
        dispatch(
            setSectionContent({
                styleInputData: { ...styleInputData, key: { ...keyOption }, value: color },
            })
        );
    };

    if (!_key?.current?.listOption) {
        return null;
    }

    return (
        <div className="mt-4 w-full px-8">
            {showTitle ? <div className="mb-2 w-full font-bold">{capitalizeT("color", t)}</div> : null}
            <div className="inline-flex">
                <div className="w-36 my-auto inline-flex">
                    <div
                        id={`sales-color-background-picker`}
                        className={`border h-8 w-8 rounded-lg ${
                            !salesStyleData[_key.current?.listOption].color &&
                            "icon-no-color text-35xl p-px text-red-100"
                        }`}
                        style={{
                            background: salesStyleData[_key.current?.listOption].color
                                ? salesStyleData[_key.current?.listOption].color
                                : null,
                        }}
                        onClick={(e) => {
                            dispatch(
                                setColorPickerReference({
                                    id: `sales-color-background-picker`,
                                    property: `bgColor`,
                                    color: salesStyleData[_key.current?.listOption].color,
                                    action: "onSelectCallback",
                                })
                            );
                            e.stopPropagation();
                        }}
                    ></div>
                    <div className="m-auto w-24">
                        <UseInputText
                            id={ID_TEXT_INPUT}
                            name={ID_TEXT_INPUT}
                            ph={"#"}
                            adjust={`h-8 mx-1 ${inputHexError ? "border border-red-100 " : ""}`}
                            value={
                                salesStyleData[_key.current?.listOption].color
                                    ? rgbaToHex(salesStyleData[_key.current?.listOption].color)
                                    : null
                            }
                            action={changeInputsFields}
                        />
                    </div>
                </div>
                <div className="w-24">
                    <UseInputPercent
                        id={ID_TEXT_INPUT_OPACITY}
                        value={
                            salesStyleData[_key.current?.listOption].color
                                ? getRGBA(salesStyleData[_key.current?.listOption].color).a * 100
                                : 100
                        }
                        adjust={"h-8"}
                        action={changeInputsFields}
                    />
                </div>
            </div>
        </div>
    );
};

export default UseColor;
