import React from "react";
import { useNavigate as useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseSectionHeader from "../../../useSectionHeader";
import NewGuestData from "./NewGuest/NewGuestData";
import NewStayData from "./NewGuest/NewStayData";
import NewDigitalKey from "./NewGuest/NewDigitalKey";
import UseButton from "../../../useButton";
//Actions
import AddNewGuest from "../../../../hooks/GraphqlCalls/Customers/AddNewGuest";
import { changeActionValues, cleanActionValues } from "../../../../actions/globalActions";

const AddGuest = ({ backToRoomDetail, breadcrumbsLimit, roomId }) => {
    const history = useHistory();
    const { permissions } = useSelector((state) => state.ui);

    //Constantes
    const hasDigitalKeys = permissions?.guests?.digitalKey;

    //state
    const dispatch = useDispatch();
    return (
        <>
            <AddNewGuest backToRoomDetail={backToRoomDetail} />
            <UseSectionHeader
                title={"check-in"}
                navToPreviousPage={roomId ? true : false}
                actionOnBack={
                    roomId
                        ? function () {
                              backToRoomDetail();
                          }
                        : null
                }
                breadcrumbsLimit={breadcrumbsLimit || 0}
            />
            <div className="grid grid-cols-3 gap-8 mb-14 overflow-y-scroll">
                <div className="bg-white row-span-2 rounded pb-8">
                    <NewGuestData />
                </div>
                <div className={`bg-white col-span-2 rounded ${!hasDigitalKeys ? "row-span-2" : ""}`}>
                    <NewStayData roomId={roomId} />
                </div>
                {hasDigitalKeys ? (
                    <div className="bg-white col-span-2 rounded min-h-20">
                        <NewDigitalKey />
                    </div>
                ) : null}
            </div>
            <div className="w-full fixed bottom-0 right-0 mr-16 pb-10 pt-8 bg-gray-100">
                <div className="float-right ml-5">
                    <UseButton
                        buttonName="check-in"
                        buttonColor="btn-blue"
                        id={"new-guest-form-check-in-button"}
                        action={() => {
                            dispatch(changeActionValues({ save: true }));
                        }}
                    />
                </div>
                <div className="float-right">
                    <UseButton
                        id={"new-guest-form-cancel-button"}
                        buttonName="cancel"
                        buttonColor="btn-white"
                        action={() => {
                            dispatch(cleanActionValues());
                            if (roomId) {
                                backToRoomDetail();
                            } else {
                                history(-1);
                            }
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default AddGuest;
