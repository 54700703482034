import React, { Fragment, useEffect, useState } from "react";
import { EditorState, Modifier } from "draft-js";
import { useTranslation } from "react-i18next";
import { capitalizeFirst, predefinedTags } from "../../../../../../hooks/Utils/Utils";
import { GetCustomTags } from "../../../../../../hooks/GraphqlCalls/Design/ListTags";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getElementMaxHeight } from "../../../../../../hooks/Utils/DesignUtils";

const SpecialTags = ({ editorState, onChange, modalHandler, deviceType, screenType }) => {
    const { t } = useTranslation();
    const { customTags } = useSelector((state) => state.sectionContent);
    const { permissions } = useSelector((state) => state.ui);
    const { type } = useParams();
    let lastCategory = null;
    GetCustomTags();

    //States
    const [open, setOpen] = useState(false);

    const close = () => {
        setOpen(false);
    };

    const addPlaceholder = (placeholder) => {
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            placeholder,
            editorState.getCurrentInlineStyle()
        );
        onChange(EditorState.push(editorState, contentState, "insert-characters"));
    };

    const placeholderOptions = predefinedTags(t, permissions?.tags, deviceType, screenType);
    const [allTags, setallTags] = useState([]);
    const [elementStyle, setElementStyle] = useState({ maxHeight: "10rem" });

    useEffect(() => {
        if (open) {
            setElementStyle({ maxHeight: `${getElementMaxHeight("tags-dropdown")}rem` });
        }
    }, [open]);

    useEffect(() => {
        let _allTags = [];
        if (customTags && customTags.length > 0) {
            customTags.forEach((tag) => {
                _allTags.push({ key: tag.id, value: `{{.${tag.tag}}}`, text: tag.name, category: "custom-tags" });
            });
        }
        placeholderOptions.forEach((staticTag) => {
            _allTags.push(staticTag);
        });
        setallTags(_allTags);
        // eslint-disable-next-line
    }, [customTags]);

    useEffect(() => {
        if (modalHandler) modalHandler.registerCallBack(close);
        return () => {
            if (modalHandler) modalHandler.deregisterCallBack(close);
        };
    }, [modalHandler]);

    return (
        <div
            onClick={() => {
                if (!open) setTimeout(() => setOpen(true), 100);
            }}
            className="rdw-block-wrapper special-tags"
            aria-label="rdw-block-control"
        >
            <div
                id="tags-dropdown"
                className="rdw-dropdown-wrapper rdw-block-dropdown w-full"
                aria-label="rdw-dropdown"
            >
                <div className="rdw-dropdown-selectedtext">
                    <div className="first-capital pr-8">{t("special tags")}</div>
                    <div className={`rdw-dropdown-caretto${open ? "close" : "open"}`}></div>
                </div>
                <ul className={`rdw-dropdown-optionwrapper ${open ? "" : "hidden"}`} style={elementStyle}>
                    {allTags.length > 0 &&
                        allTags.map((item, index) => {
                            let showCategoryName = false;
                            if (item.category !== lastCategory) {
                                showCategoryName = true;
                                lastCategory = item.category;
                            }
                            return (
                                <Fragment key={index}>
                                    {showCategoryName && (
                                        <div className="font-bold mt-2 mb-1 pt-1 border-t">
                                            {capitalizeFirst(t(item.category))}
                                        </div>
                                    )}
                                    {item.key === "CCName" && type === "CCPAIRINGSUCCESSFUL" ? null : (
                                        <li
                                            onClick={addPlaceholder.bind(this, item.value)}
                                            key={item.key}
                                            className="rdw-dropdownoption-default placeholder-li text-gray-900"
                                        >
                                            {item.text}
                                        </li>
                                    )}
                                </Fragment>
                            );
                        })}
                </ul>
            </div>
        </div>
    );
};

export default SpecialTags;
