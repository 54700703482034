import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";

//Components
import UseInputCalendar from "../Wizard/useInputCalendar";
import UseButton from "../useButton";
//Actions
import { setDateFilter, setPage } from "../../actions/tableActions";
import { setCurrentZoneSchedule } from "../../actions/scheduleActions";
import { openModal, setModalContent, showGlobalLoading } from "../../actions/uiActions";
import { setActionName, setActionItemAffected, setActionData } from "../../actions/globalActions";
//Utils
import useComponentVisible from "../useComponentVisible";
import { getClosingDays } from "../../hooks/Utils/ScheduleManagement";

import { GlobalContext } from "contexts/Global";

const UseDateHeader = () => {
    //Store data
    const { lang } = useContext(GlobalContext);
    const { langStrings } = useSelector((state) => state.ui);
    const { actionName } = useSelector((state) => state.action);
    const { headerButtons, dateHeader, dateFilter, title } = useSelector((state) => state.table);
    const { rowForm } = useSelector((state) => state.sectionContent);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //Data
    const { id } = useParams();
    const currentDate = formatDate(dateFilter, langStrings, lang);

    //State
    const [selectActive, setSelectActive] = useState({});
    const [showAddNew, setShowAddNew] = useState(false);
    const [chevronState, setChevronState] = useState("hover:text-blue-200 cursor-pointer");

    const [canChangeDay, setCanChangeDate] = useState(actionName !== "new-area-booking");

    //Actions
    const dispatch = useDispatch();

    //All days
    const handleAllClick = () => {
        setShowAddNew(true);
        dispatch(setDateFilter(""));
        setIsComponentVisible(false);
        setChevronState("text-gray-300 cursor-default");
    };

    const GET_AREA_SCHEDULE = gql`{
      commonzones(page: 1, size: 1000,  filter:{ref:"${id}"}) {           
        results {
          bookingAllowed
          capacityLimit
          schedule{
            ID dateType isEnabled
            name periodicityType type
            isActive
            timetableDates{
              dateList isActive
              timetableDateRanges{
                  timeRanges weekdayList isActive
              }}}}}}`;

    const [getAreaSchedule, { data }] = useLazyQuery(GET_AREA_SCHEDULE, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        setCanChangeDate(actionName !== "new-area-booking");
    }, [actionName]);

    useEffect(() => {
        if (data) {
            dispatch(showGlobalLoading(false));
            if (data.commonzones.results[0].capacityLimit === 0 || data.commonzones.results[0].schedule.length === 0) {
                dispatch(
                    setModalContent({
                        title: "bookings",
                        value: title,
                        inputs: [
                            {
                                text:
                                    data.commonzones.results[0].capacityLimit === 0 &&
                                    data.commonzones.results[0].schedule.length !== 0
                                        ? "configure-capacity-before-booking"
                                        : data.commonzones.results[0].capacityLimit !== 0 &&
                                          data.commonzones.results[0].schedule.length === 0
                                        ? "configure-schedule-before-booking"
                                        : "configure-area-before-booking",
                                type: "void",
                            },
                        ],
                        buttons: [
                            {
                                name: "accept",
                                style: "blue",
                                action: "close",
                            },
                        ],
                    })
                );
            } else {
                dispatch(setCurrentZoneSchedule(data.commonzones.results[0].schedule));
                dispatch(
                    setModalContent({
                        title: "bookings",
                        value: title,
                        text: ["select booking date"],
                        inputs: [
                            {
                                name: "booking-date",
                                type: "date-specific",
                                closingDays: getClosingDays(data.commonzones.results[0].schedule),
                            },
                        ],
                        buttons: [
                            { name: "cancel", style: "white", action: "close" },
                            {
                                name: "accept",
                                style: "blue",
                                action: "booking-date-save",
                            },
                        ],
                    })
                );
            }

            //Launch modal
            dispatch(openModal());
        }
        // eslint-disable-next-line
    }, [data]);

    //Add new
    const clickNewHandler = async (e) => {
        if (rowForm) {
            toast.error(
                `${langStrings["please"]}, ${langStrings["save-before"]} ${langStrings["create-anothera"]} ${langStrings["booking"]}`
            );
        } else {
            dispatch(setActionName(e.currentTarget.dataset.action));
            if (id) {
                dispatch(setActionItemAffected([id]));
            }
            if (e.currentTarget.dataset.action === "new-area-booking") {
                dispatch(setActionData({ name: "" }));
                dispatch(showGlobalLoading(true));
                getAreaSchedule();
            }
        }
    };

    //Next day
    const nextDayClick = () => {
        if (dateFilter) {
            dispatch(setDateFilter(dateFilter.setDate(dateFilter.getDate() + 1)));
        }
    };
    //Previous day
    const previousDayClick = () => {
        if (dateFilter) {
            dispatch(setDateFilter(dateFilter.setDate(dateFilter.getDate() - 1)));
        }
    };

    //Listeners
    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({});
        } else {
            setSelectActive({
                text: "text-blue-300",
            });
        }
    }, [isComponentVisible]);

    useEffect(() => {
        if (dateFilter) {
            getDateFilterStatus(dateFilter, setShowAddNew, setChevronState);
            dispatch(setPage(1));
        }
        // eslint-disable-next-line
    }, [dateFilter && dateFilter.datetime]);

    return (
        <>
            {dateHeader ? (
                <div className="t-dateHeader-container w-full">
                    {canChangeDay ? (
                        <div className={`pr-2 icon-chevron-left ${chevronState}`} onClick={previousDayClick}></div>
                    ) : null}
                    <div ref={ref} className="cursor-pointer overflow-visible h-10 text-center z-100">
                        <div
                            onClick={() => setIsComponentVisible(!isComponentVisible && canChangeDay ? true : false)}
                            className={`inline-block hover:text-blue-200 font-bold dateHeader-current ${selectActive.text}`}
                        >
                            <div className="first-capital">{currentDate}</div>
                        </div>
                        {isComponentVisible ? (
                            <div className="mt-6 border border-gray-200 rounded p-4 bg-white shadow-lg">
                                <UseInputCalendar setIsComponentVisible={setIsComponentVisible} />
                            </div>
                        ) : null}
                    </div>

                    {canChangeDay ? (
                        <>
                            <div className={`pl-2 icon-chevron-right ${chevronState}`} onClick={nextDayClick}></div>

                            <div className="inline-block ml-8 text-base" onClick={handleAllClick}>
                                <UseButton
                                    buttonName={"view all days"}
                                    buttonColor={"btn-blue-outline"}
                                    adjust="w-152px"
                                />
                            </div>

                            {showAddNew && headerButtons ? (
                                <div className={`inline-block ml-8 text-base font-normal absolute right-0`}>
                                    {headerButtons.map((headerButton, index) => (
                                        <span
                                            onClick={clickNewHandler}
                                            data-action={headerButton.value}
                                            key={index}
                                            data-modal={headerButton.modal ? true : false}
                                        >
                                            <UseButton
                                                buttonName={headerButton.name}
                                                buttonColor={headerButton.color}
                                                adjust={"ml-4"}
                                            />
                                        </span>
                                    ))}
                                </div>
                            ) : null}
                        </>
                    ) : null}
                </div>
            ) : null}
        </>
    );
};

const formatDate = (dateFilter, langStrings, lang) => {
    if (dateFilter instanceof window.ZDate) {
        dateFilter = new Date(dateFilter.toDate());
    }
    //Returns to view formatted date
    if (dateFilter !== "") {
        const dateTimeFormat = new Intl.DateTimeFormat(lang, {
            year: "numeric",
            month: "long",
            day: "2-digit",
        });
        const [{ value: day }, , { value: month }, , { value: year }] = dateTimeFormat.formatToParts(dateFilter);
        let res = "";
        if (day === new window.ZDate().getDate().toString()) {
            res += `${langStrings["today"]} `;
        }
        if (lang === "es") {
            res += `${day} ${langStrings["of"]} ${month} ${langStrings["of"]} ${year}`;
        } else if (lang === "en") {
            res += `${month} ${day}, ${year}`;
        }

        return res;
    } else {
        return `${langStrings["select"]} ${langStrings["date"]}`;
    }
};

const getDateFilterStatus = (dateFilter, setShowAddNew, setChevronState) => {
    //Sets current date header display
    if (dateFilter !== "") {
        const currentDate = dateFilter;
        const today = new window.ZDate();

        if (currentDate >= today || currentDate.toLocaleDateString() === today.toLocaleDateString()) {
            setShowAddNew(true);
        } else {
            setShowAddNew(false);
        }
        setChevronState("hover:text-blue-200 cursor-pointer");
    }
};
export default UseDateHeader;
