import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Session } from "../../../../../hooks/Utils/Session";
import {
    LOGOS_TYPES,
    CORPORATE_LOGOS,
    PROPERTY_LOGOS,
    CORPORATE_REF,
    CORPORATE_APPEARANCE_TYPE,
    PROPERTY_APPEARANCE_TYPE,
    modalsOff,
    THEME_TYPE_MOBILE,
    LOGOS_TYPES_MOBILE,
    THEME_TYPE_DESIGN,
} from "../../../../../hooks/Utils/AppearancesUtils";
import { useDispatch, useSelector } from "react-redux";
import { setSectionContent } from "../../../../../actions/sectionActions";
import LogoInfo from "./LogoInfo";
import { setActionName, setActionItemAffected, changeActionValues } from "../../../../../actions/globalActions";

import { useAuth } from "hooks/Session/auth";

export default function Logo({ logo, groupType, index, themeType }) {
    const { t } = useTranslation();
    const { project } = useAuth();
    const { id, imageRef, themeLogoCustoms, version } = logo;

    const projectRef = project?.ref;
    const { appearanceSelected } = useSelector((state) => state.sectionContent);
    const [editAction, setEdit] = useState(false);
    const [repalceAction, setReplace] = useState(false);
    const [deleteAction, setDelete] = useState(false);
    const logoAsset = themeLogoCustoms && themeLogoCustoms.length > 0 ? themeLogoCustoms[0].imageRef : imageRef;

    const dispatch = useDispatch();

    useEffect(() => {
        setPremisionsLogo();
        // eslint-disable-next-line
    }, []);

    const setPremisionsLogo = () => {
        if (projectRef === CORPORATE_REF) {
            //dashborad GLOBAL
            if (appearanceSelected.type === CORPORATE_APPEARANCE_TYPE) {
                //Appearance global
                setEdit(true);
                setDelete(true);
                setReplace(false);
            } else if (appearanceSelected.type === PROPERTY_APPEARANCE_TYPE) {
                //Appearance propery
                setEdit(false);
                setDelete(false);
                setReplace(false);
            }
        } else {
            //dashborad LOCAL
            if (appearanceSelected.type === CORPORATE_APPEARANCE_TYPE) {
                //Appearance global
                if (groupType === CORPORATE_LOGOS) {
                    //global logo
                    setEdit(false);
                    setDelete(false);
                    setReplace(false);
                } else if (groupType === PROPERTY_LOGOS) {
                    //property logo
                    setEdit(false);
                    setDelete(false);
                    setReplace(true);
                }
            } else if (appearanceSelected.type === PROPERTY_APPEARANCE_TYPE) {
                //Appearance porperty
                setEdit(true);
                setDelete(true);
                setReplace(false);
            }
        }
    };

    const selectLogo = () => {
        dispatch(
            setSectionContent({
                appearanceSelected: { ...appearanceSelected, logoSelected: logoAsset, logoSelectedId: id },
            })
        );
    };

    const openEditModal = () => {
        dispatch(
            setSectionContent({
                addLogo: {
                    id: id,
                    type: groupType,
                    version: version,
                    asset: logoAsset,
                },
                modals: { ...modalsOff, edit: true },
            })
        );
    };

    const editIcon = () => {
        return editAction && <span className="icon-edit z-100 cursor-pointer" onClick={() => openEditModal()}></span>;
    };

    const deleteReplaceIcon = () => {
        return deleteAction ? (
            <span
                className="icon-delete-editor block z-100 cursor-pointer"
                style={{ fontSize: "2rem", paddingTop: "0.1rem" }}
                onClick={() => {
                    openDeleteModal();
                }}
            ></span>
        ) : repalceAction ? (
            logoAsset || (themeLogoCustoms && themeLogoCustoms.length > 0) ? (
                <span
                    className="icon-replace text-center z-100 cursor-pointer"
                    onClick={() => {
                        openReplaceModal(false);
                    }}
                ></span>
            ) : (
                <span
                    className="icon-upload
                     text-center z-100 cursor-pointer"
                    onClick={() => {
                        openReplaceModal(true);
                    }}
                ></span>
            )
        ) : (
            ""
        );
    };

    const infoIcon = () => {
        return logoAsset ? <LogoInfo imageRef={logoAsset} /> : "";
    };

    const openDeleteModal = () => {
        dispatch(setActionName("appearance-delete-logo"));
        dispatch(setActionItemAffected([id]));
        dispatch(changeActionValues({ version: version }));
        dispatch(setSectionContent({ modals: { ...modalsOff, delete: true } }));
    };

    const openReplaceModal = (upload = false) => {
        dispatch(
            setSectionContent({
                addLogo: {
                    id: id,
                    type: groupType,
                    version: version,
                    asset: logoAsset,
                    themeLogoCustoms: themeLogoCustoms,
                },
                modals: { ...modalsOff, replace: upload ? false : true, upload: upload ? true : false },
            })
        );
    };

    return (
        <div id="logo" className="">
            <div
                className={`relative rounded-lg  w-48 h-28 mx-auto items-center align-middle ${
                    appearanceSelected?.logoSelectedId === id ? "border-2 border-blue-100" : ""
                }`}
            >
                <div
                    className={`flex h-full w-full text-gray-600  mx-auto items-center text-center align-bottom justify-center rounded-lg  ${
                        logoAsset ? "cursor-pointer bg-gray-200" : "border border-dashed"
                    }`}
                    onClick={selectLogo}
                >
                    {logoAsset ? (
                        <img
                            alt={t("logo")}
                            src={logoAsset.includes("http") ? logoAsset : Session.getDasUrl(logoAsset + "?w=124&h=76")}
                            className="flex"
                            style={{ maxWidth: "124px", maxHeight: "76px" }}
                        />
                    ) : (
                        t("{{capitalize, capitalize}}", { capitalize: t("unspecified") })
                    )}
                </div>
                <div
                    className="absolute rounded-b-md  bottom-0 h-10 w-full"
                    style={{ backgroundColor: "rgba(46,56,67,0.5)" }}
                >
                    <div
                        className="grid grid-flow-col grid-cols-3  text-1xl font-black text-white  h-full items-center"
                        data=""
                    >
                        <div id={`logo-${groupType}-${index}-button-edit`} className="ml-2">
                            {editIcon()}
                        </div>
                        <div
                            id={`logo-${groupType}-${index}-button-delete`}
                            className="text-center items-center align-middle"
                        >
                            {deleteReplaceIcon()}
                        </div>
                        <div id={`logo-${groupType}-${index}-button-info`} className="mr-2 text-right items-end">
                            {infoIcon()}
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center mb-2">
                {t(themeType === THEME_TYPE_MOBILE ? LOGOS_TYPES_MOBILE[version] : LOGOS_TYPES[version])}
            </div>
        </div>
    );
}
