import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSectionContent } from "../../../../actions/sectionActions";
import { useTranslation } from "react-i18next";
import { changeActionValues } from "../../../../actions/globalActions";

import { toast } from "react-toastify";

const ModalLogoData = () => {
    const { t } = useTranslation();
    const actionData = useSelector((state) => state.action);
    const { modalAddLogo, addLogo, modals, noValidations } = useSelector((state) => state.sectionContent);
    const dispatch = useDispatch();

    useEffect(() => {
        if (modalAddLogo && actionData && actionData.values) {
            // LOGO VERSION
            if (actionData.values["logo-type"] && actionData.values["logo-type"] !== addLogo.version) {
                dispatch(
                    setSectionContent({
                        addLogo: { ...addLogo, version: actionData.values["logo-type"] },
                    })
                );
            }
            // LOGO ASSET TEMP
            if (
                actionData.values["browser-media-library-items-selected"] &&
                actionData.values["browser-media-library-items-selected"][0] !== addLogo.asset_tmp
            ) {
                dispatch(
                    setSectionContent({
                        addLogo: {
                            ...addLogo,
                            asset_tmp: actionData.values["browser-media-library-items-selected"][0],
                        },
                    })
                );
            }
            // LOGO ASSET SELECTED
            if (actionData.values["select-asset"]) {
                dispatch(
                    changeActionValues({
                        "select-asset": false,
                    })
                );
                if (
                    actionData.values["browser-media-library-items-selected"] &&
                    actionData.values["browser-media-library-items-selected"].length > 0
                ) {
                    dispatch(
                        setSectionContent({
                            addLogo: {
                                ...addLogo,
                                asset_tmp_selected: true,
                                asset: actionData.values["browser-media-library-items-selected"][0],
                            },
                            modals: { ...modals, openLast: true },
                        })
                    );
                } else if (!noValidations) {
                    toast.error(t("error-select-at-least-one"));
                } else {
                    dispatch(setSectionContent({ noValidations: false }));
                }
            }
        }
        // eslint-disable-next-line
    }, [actionData]);
};

export default ModalLogoData;
