import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { vw2px } from "../../../../hooks/Utils/DesignUtils";
import { EditorContext } from "../../../../contexts/editor";

const TabBar = () => {
    const { editorSize } = useContext(EditorContext);
    const { t } = useTranslation();

    const { permissions } = useSelector((state) => state.ui);

    const size = (s) => {
        return vw2px(s, editorSize ? editorSize.width : null);
    };

    const hasDigitalKeys = permissions?.guests?.digitalKey;

    //TODO rellenar estos colores a partir de la apariencia de la app
    const bgColor = "rgb(251, 251, 251)";
    const fgColor = "rgba(50,50,50,0.6)";
    const fgActiveColor = "rgb(94, 120, 154)";
    const bgAccent = "rgb(94, 120, 154)";
    const fcAccentContrast = "white";

    return (
        <>
            {hasDigitalKeys ? (
                <div className="relative w-full">
                    <div
                        className="absolute rounded-full shadow-md box-content leading-none z-200"
                        style={{
                            padding: size(4) + "px",
                            width: size(8) + "px",
                            height: size(8) + "px",
                            fontSize: size(8) + "px",
                            color: fcAccentContrast,
                            background: bgAccent,
                            top: "-7rem",
                            right: "1rem",
                        }}
                    >
                        <i className="icon icon-key"></i>
                    </div>
                </div>
            ) : null}
            <div
                className="text-center w-full h-26 p-2 border-t border-gray-200"
                style={{
                    backgroundColor: bgColor,
                    color: fgColor,
                }}
            >
                <div className="grid grid-cols-3 place-content-center h-full" style={{ fontSize: size(4) + "px" }}>
                    <div style={{ color: fgActiveColor }}>
                        <i className="icon icon-hotel-solid block py-1" style={{ fontSize: size(6) + "px" }}></i>
                        <div className="first-capital">{t("property")}</div>
                    </div>
                    <div>
                        <i className="icon icon-notifications block py-1" style={{ fontSize: size(6) + "px" }}></i>

                        <div className="first-capital">{t("notifications")}</div>
                    </div>
                    <div>
                        <i className="icon icon-user-regular block py-1" style={{ fontSize: size(6) + "px" }}></i>
                        <div className="first-capital">{t("profile")}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TabBar;
