import { useContext } from "react";
import { useLocation, matchPath } from "react-router-dom";

import routes from "routes";
import { GlobalContext } from "contexts/Global";

const DisplayMenuSidebar = () => {
    const location = useLocation();

    const { paths } = useContext(GlobalContext);

    let menuDisplay = {
        hideHeader: false,
        hideSidebar: false,
        style: { x: "px-14 w-5/6", y: "py-10 section-top" },
    };

    const availableRoutes = routes ? routes?.filter((route) => paths?.includes(route?.path)) : null;

    if (availableRoutes?.length) {
        const route = availableRoutes
            .filter((r) => r?.section !== undefined)
            .find((route) => matchPath({ path: route.path, end: true }, location.pathname)) || {
            hideHeader: true,
            hideSidebar: true,
        };
        if (route) {
            menuDisplay.hideHeader = !!route.fullscreen;
            menuDisplay.hideSidebar = !!route.fullscreen;
            if (menuDisplay.hideSidebar) {
                menuDisplay.style.x = `px-0 w-full ${route.hideHeader ? "h-full" : ""}`;
            }
            if (menuDisplay.hideHeader) {
                menuDisplay.style.y = "py-0 top-0";
            }
        }
    }

    return menuDisplay;
};

export default DisplayMenuSidebar;
