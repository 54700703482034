const HighAvailabilityTexts = () => {
    return {
        title: "high-availability",
        text: "high-availability-modal-text",
        executeSectionQuery: true,
        action: "update-high-availability-text",
        inputs: [
            {
                name: "translate-texts-tabs",
                type: "translateTextsTabs",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: "update-high-availability-text",
            },
        ],
    };
};

export default HighAvailabilityTexts;
