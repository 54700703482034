import React, { useState, useContext, useEffect } from "react";
import { SALES_SEQUENCE } from "../../../../hooks/Utils/Design/SalesUtils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSectionContent } from "../../../../actions/sectionActions";
import { capitalizeT } from "../../../../hooks/Utils/Utils";
import { EditorContext } from "../../../../contexts/editor";

const SalesScreensSequence = () => {
    const { screenType } = useContext(EditorContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { permissions } = useSelector((state) => state.ui);
    const { cLang } = useSelector((state) => state.design);

    const hasMyOrders = permissions?.services?.myOrders;

    const sequence = screenType ? SALES_SEQUENCE()[screenType] : null;

    if (sequence && !hasMyOrders) {
        delete sequence.ORDERS;
    }
    const [salesScreen, setSalesScreen] = useState(sequence ? sequence.SHOPS : null);

    const prevtSaleSequence = () => {
        if (SALES_SEQUENCE().prevScreen(screenType, salesScreen)) {
            changeScreen(SALES_SEQUENCE().prevScreen(screenType, salesScreen));
        }
    };

    const nextSaleSequence = () => {
        if (SALES_SEQUENCE().nextScreen(screenType, salesScreen)) {
            changeScreen(SALES_SEQUENCE().nextScreen(screenType, salesScreen));
        }
    };

    const changeScreen = (screen) => {
        setSalesScreen(screen);
        dispatch(setSectionContent({ currentScreen: screen }));
    };

    useEffect(() => {
        changeScreen(sequence.SHOPS);
    }, [cLang]);

    return (
        <div
            id="salesToolbar"
            className="w-full rounded shadow mr-auto inline-flex bg-blue-200  p-1 text-3xl text-white"
        >
            <div
                className="icon-chevron-left border-r m-auto mr-1 cursor-pointer border-white p-4"
                onClick={prevtSaleSequence}
                id={"sales-screen-secuence-prev"}
            ></div>
            <div className="w-full flex flex-col text-lg">
                <span className="mx-auto" id={"sales-screen-secuence-pos"}>
                    {capitalizeT("screen", t)} {salesScreen.pos} / {SALES_SEQUENCE()?.length(screenType)}
                </span>
                <span id={"sales-screen-secuence-name"} className="mx-auto">
                    {capitalizeT(salesScreen.name, t)}
                </span>
            </div>
            <div
                id={"sales-screen-secuence-next"}
                className="icon-chevron-right border-l m-auto cursor-pointer border-white p-4"
                onClick={nextSaleSequence}
            ></div>
        </div>
    );
};

export default SalesScreensSequence;
