import { LIST_MOBILE_APPS } from "components/Section/Customers/allProperties/constants";

export const GetAllPropertiesTableHeaders = (section, props) => {
    let res = [];
    //Sort icon
    const tableSortIcon = { name: "table-sort" };

    //Special width classes
    // const w112 = "w-1/12";
    const w212 = "w-2/12";
    const w312 = "w-3/12";
    // const w412 = "w-4/12";
    const w512 = "w-5/12";
    // const w612  "w-6/12";
    //const w712  "w-7/12";
    //const w812  "w-8/12";
    // const w912 = "w-9/12";
    //const w1012  "w-10/12";
    // const w1112 = "w-11/12";

    //Header types
    const name = { name: "name", sortName: "name" };
    const creator = { name: "creator", sortName: "creator" };
    const url = { name: "url", sortName: "url" };
    const properties = { name: "properties", sortName: "projects.id" };

    switch (section) {
        case LIST_MOBILE_APPS:
            name.icon = tableSortIcon;
            properties.icon = tableSortIcon;

            name.specialWidth = w212;
            creator.specialWidth = w212;
            url.specialWidth = w512;
            properties.specialWidth = w312;

            res.push(name, creator, url, properties);

            break;
        default:
            res = [""];
    }
    return res;
};

export default GetAllPropertiesTableHeaders;
