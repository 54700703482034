const TagValue = (pValue, t) => {
    const { designTagId, projectId, projectName, value } = pValue;

    const dataType = "tagValue";

    return {
        dataType: dataType,
        id: projectId,
        info: [
            {
                name: "property",
                cellContent: "text",
                value: projectName,
                specialWidth: "w-3/12",
            },
            {
                name: "value",
                cellContent: "text",
                value: value || t("{{capitalize, capitalize}}", { capitalize: t("Unspecified") }),
                specialWidth: "w-8/12",
            },
            {
                cellContent: "icon",
                value: designTagId,
                evaluate: true,
                isAction: true,
                icon: {
                    name: "edit",
                    id: projectId,
                    itemType: dataType,
                    action: "edit-tag-values",
                },
                specialWidth: "w-1/12 justify-center",
            },
        ],
    };
};

export default TagValue;
