import React, { useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { EditorContext } from "../../../../../contexts/editor";
import { capitalizeFirst } from "../../../../../hooks/Utils/Utils";
import { vw2px } from "../../../../../hooks/Utils/DesignUtils";
import TopBar from "./topbar";
import { coverColor, coverElement, highlightElement } from "./utils";
import Color from "zrender/color";

const ShopCart = ({ device: forceDeviceType, width: forceWidth, templating }) => {
    const { t: tr } = useTranslation();
    const { lang, deviceType, editorSize } = useContext(EditorContext);

    const t = (label, data) => {
        return tr(label, { lng: lang, ...(data || {}) });
    };

    const deviceProps = {
        device: forceDeviceType || deviceType,
        deviceWidth: forceWidth || editorSize?.width,
    };

    const size = (s) => vw2px(s, deviceProps.deviceWidth, 3);

    const css = templating?.css;

    const highlightActive = css?.highlight;
    const hlBackground = highlightActive === "general";
    const hlDetails = highlightActive === "details";
    const hlFooter = highlightActive === "section2";

    const details = css?.items?.details;
    const footer = css?.items?.general;
    const footerButton = css?.items?.button;

    const styles = {
        global: {
            fontSize: size(1.25),
            fontFamily: templating?.textFont?.name,
            color: css?.fgColor,
            backgroundColor: highlightActive && !hlBackground ? coverColor(css?.bgColor) : css?.bgColor,
            textAlign: "left",
            width: "100%",
            minHeight: "100%",
            cursor: "default",
        },
        footer: {
            backgroundColor: footer?.bgColor,
            color: footer?.fgColor,
            padding: size(1.5),
            textAlign: "center",
        },
        orderButton: {
            display: "inline-block",
            fontSize: size(1.5),
            fontWeight: "bold",
            padding: size(0.75),
            minWidth: "80%",
            color: footerButton?.fgColor,
            background: footerButton?.bgColor,
            borderWidth: footerButton?.borderWidth + "px",
            borderRadius: footerButton?.radius + "rem",
            borderColor: footerButton?.borderColor,
        },
        box: {
            background: "#FBFBFB",
            color: "#2E3843",
            padding: "1em",
        },
        boxCovered: {
            background: coverColor("#FBFBFB"),
            color: coverColor("#2E3843"),
        },
        boxTitle: {
            fontSize: "1.2em",
            fontWeight: "bold",
            padding: "0 .5em",
            marginBottom: ".5em",
        },
        divider: {
            borderColor: Color("#2E3843").alpha(0.1).toString(),
            borderStyle: "solid",
            borderBottomWidth: "1px",
        },
        boxContent: {
            padding: ".5em",
        },
        summaryRow: {
            display: "flex",
            justifyContent: "space-between",
        },
        comment: {
            marginTop: ".5em",
            padding: ".5em 1em",
            minHeight: "10em",
            color: "#BDC8D3",
            borderColor: "#BDC8D3",
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: ".25rem",
            background: "#FBFBFB",
        },
        option: {
            padding: ".5em 0",
        },
        radioButton: {
            verticalAlign: "middle",
            display: "inline-block",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#BDC8D3",
            width: size(1.3),
            height: size(1.3),
            lineHeight: size(1.3),
            borderRadius: "100%",
            marginRight: size(0.5),
        },
        radioButtonSelected: {
            borderColor: "#2E3843",
            borderWidth: "5px",
        },

        product: {
            fontSize: size(1.25),
            padding: size(1.5),
            color: "#323232",
        },
        quantity: {
            padding: ".25em .5em",
            color: details?.fgColor,
            background: details?.bgColor,
            fontSize: size(1.25),
            borderRadius: "25%",
        },
        description: {
            color: "rgb(118,136,155)",
        },
        iconQty: {
            fontSize: size(1.5),
            paddingTop: size(0.75),
            paddingBottom: size(0.75),
            paddingLeft: size(1),
            paddingRight: size(1),
            textAlign: "center",
            borderWidth: "1px",
            borderRadius: "0.5rem",
            borderColor: "#D3DAE1",
            marginBottom: ".5em",
            minHeight: size(3),
            maxHeight: size(3),
            minWidth: size(3.5),
            maxWidth: size(3.5),
            overflow: "hidden",
        },
    };

    const product = (index) => (
        <div style={index !== 2 ? styles.divider : null}>
            <div
                style={{
                    ...styles.product,
                }}
            >
                <div className="flex justify-between" style={{ paddingTop: size(0.5) }}>
                    <div className="flex">
                        <div className="text-center">
                            <div
                                style={{
                                    ...styles.quantity,
                                    ...(highlightActive && hlDetails ? highlightElement() : null),
                                }}
                            >
                                {index === 1 ? "x2" : "x1"}
                            </div>
                        </div>
                        <div
                            className="flex justify-between flex-col"
                            style={{
                                padding: size(1),
                                paddingTop: 0,
                                ...(highlightActive && hlDetails ? coverElement() : null),
                            }}
                        >
                            <div>{capitalizeFirst(t("product"))}</div>
                            <div style={styles.description}>
                                <div>{capitalizeFirst(t("extras"))}:</div>
                            </div>
                            <div className="font-bold mt-3">00,00 €</div>
                        </div>
                    </div>
                    <div style={highlightActive && hlDetails ? coverElement() : null}>
                        <div
                            style={styles.iconQty}
                            className="icon flex items-center justify-center icon-editor-add-small"
                        >
                            <div className=" path1 text-5xl font-bold  "></div>
                        </div>
                        <div
                            style={styles.iconQty}
                            className="icon flex items-center justify-center icon-editor-remove"
                        >
                            <div className=" path2 text-5xl font-bold  "></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    useEffect(() => {
        if (highlightActive) {
            const element = document.querySelector(`[target=${highlightActive}]`);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }, [highlightActive]);

    return (
        <div style={styles.global}>
            <div
                style={{
                    position: "sticky",
                    zIndex: 10,
                    top: 0,
                    ...(highlightActive && hlBackground ? highlightElement("bottom") : null),
                }}
            >
                <TopBar
                    title={capitalizeFirst(t("cart"))}
                    device={deviceType}
                    width={forceWidth}
                    templating={templating}
                />
            </div>

            <div
                target="general"
                style={{ margin: ".5em 0", ...(highlightActive && hlBackground ? highlightElement() : null) }}
            >
                <div
                    style={{
                        ...styles.box,
                        ...(highlightActive ? coverElement() : null),
                    }}
                >
                    <div style={{ ...styles.boxTitle, ...styles.divider }}>{capitalizeFirst(t("delivery"))}</div>

                    <div style={styles.boxContent}>
                        {capitalizeFirst(t("select delivery method"))}:
                        <div style={styles.option}>
                            <div style={{ ...styles.radioButton, ...styles.radioButtonSelected }}></div>{" "}
                            {capitalizeFirst(t("room-service"))}
                        </div>
                        <div style={styles.option}>
                            <div style={styles.radioButton}></div>{" "}
                            {capitalizeFirst(
                                t("pick-up at x", {
                                    location: capitalizeFirst(t("reception")),
                                })
                            )}
                        </div>
                    </div>

                    <div style={styles.boxContent}>
                        <strong>{t("delivery-time")}:</strong>
                        <div style={styles.option}>
                            <div style={{ ...styles.radioButton, ...styles.radioButtonSelected }}></div>{" "}
                            {capitalizeFirst(t("as soon as posible"))}
                        </div>
                        <div style={styles.option}>
                            <div style={styles.radioButton}></div> {capitalizeFirst(t("schedule delivery"))}
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ margin: ".5em 0", ...(highlightActive && hlBackground ? highlightElement() : null) }}>
                <div
                    style={{
                        ...styles.box,
                        ...(highlightActive ? coverElement() : null),
                    }}
                >
                    <div style={{ ...styles.boxTitle, ...styles.divider }}>{capitalizeFirst(t("comment"))}</div>
                    <div style={styles.boxContent}>
                        {capitalizeFirst(t("do you have any comments regarding the order?"))}
                        <div style={styles.comment}>{capitalizeFirst(t("write your comment..."))}</div>
                    </div>
                </div>
            </div>

            <div
                target="details"
                style={{ margin: ".5em 0", ...(highlightActive && hlBackground ? highlightElement() : null) }}
            >
                <div
                    style={{
                        ...styles.box,
                        ...(highlightActive && hlDetails ? styles.boxCovered : null),
                        ...(highlightActive && !hlDetails ? coverElement() : null),
                    }}
                >
                    <div
                        style={{
                            ...styles.boxTitle,
                            ...styles.divider,
                            ...(highlightActive && hlDetails ? coverElement() : null),
                        }}
                    >
                        {capitalizeFirst(t("your order"))}
                    </div>
                    <div style={{ ...styles.boxContent }}>
                        {capitalizeFirst(t("x products in your order", { amount: 3 }))}
                    </div>
                    {[1, 2].map((n) => product(n))}
                </div>
            </div>

            <div style={{ margin: ".5em 0", ...(highlightActive && hlBackground ? highlightElement() : null) }}>
                <div
                    style={{
                        ...styles.box,
                        ...(highlightActive ? coverElement() : null),
                    }}
                >
                    <div style={{ ...styles.boxTitle, ...styles.divider }}>{capitalizeFirst(t("summary"))}</div>
                    <div style={{ ...styles.boxContent, ...styles.summaryRow }}>
                        <div>{capitalizeFirst(t("products"))}</div>
                        <div>
                            <strong>00,00 €</strong>
                        </div>
                    </div>
                    <div style={{ ...styles.boxContent, ...styles.summaryRow }}>
                        <div>{capitalizeFirst(t("delivery"))}</div>
                        <div>
                            <strong>00,00 €</strong>
                        </div>
                    </div>
                    <div style={styles.divider}></div>
                    <div style={{ ...styles.boxContent, ...styles.summaryRow }}>
                        <div>
                            <strong>{capitalizeFirst(t("total"))}</strong>
                        </div>
                        <div>
                            <strong>00,00 €</strong>
                        </div>
                    </div>
                    <div style={{ ...styles.boxContent, textAlign: "right" }}>{capitalizeFirst(t("tax included"))}</div>
                </div>
            </div>

            <div
                style={{
                    position: "sticky",
                    zIndex: 10,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: "100%",
                    ...(highlightActive && (hlBackground || hlFooter)
                        ? highlightElement(hlBackground ? "top" : null)
                        : null),
                }}
            >
                <div style={{ ...styles.footer, ...(highlightActive && !hlFooter ? coverElement() : null) }}>
                    <div style={styles.orderButton}>{capitalizeFirst(t("order now"))}</div>
                </div>
            </div>
        </div>
    );
};

export default ShopCart;
