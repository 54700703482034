import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { GetScheduleMutationDates } from "../../hooks/Utils/GetScheduleMutationDates";
//Input components
import UseSelect from "../Schedule/useSelect";
import UseInputTime from "../Schedule/useInputTime";
import UseInputDate from "../Schedule/useInputDate";
import UseButton from "../useButton";
//Actions
import {
    GetMutationDateType,
    GetMutationDateList,
    GetMutationSchedules,
    validateDateType,
    validateDates,
    validateSchedule,
    getFrontSchedules,
    getFrontDateList,
} from "../../hooks/Utils/ScheduleManagement";
import {
    addBatchPunctualSchedule,
    addViewPunctualSchedule,
    cleanBatchSchedules,
    cleanViewSchedules,
} from "../../actions/scheduleActions";
import { cleanAction, cleanScheduleDates, cleanScheduleHours, setActionDateType } from "../../actions/globalActions";
import { addSectionPunctualSchedule } from "../../actions/sectionActions";
//Notifications
import { toast } from "react-toastify";
//Data
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UseFormPunctual = ({ setAddPunctualSchedule }) => {
    const { t } = useTranslation();
    //Store data
    const { dateType, specificRange, specificDates, schedule } = useSelector((state) => state.action);
    const { punctualSchedulesBatch, punctualSchedulesView, lastScheduleEdition } = useSelector(
        (state) => state.schedules
    );

    //Data
    const { title } = useSelector((state) => state.table);
    const { id } = useParams();

    //Validation States
    const [dateTypeValidation, setDateTypeValidation] = useState(true);
    const [datesValidation, setDatesValidation] = useState(true);
    const [scheduleValidationMS, setScheduleValidationMS] = useState(true);
    const [scheduleValidationME, setScheduleValidationME] = useState(true);
    const [scheduleValidationAS, setScheduleValidationAS] = useState(true);
    const [scheduleValidationAE, setScheduleValidationAE] = useState(true);
    const [saveAllState, setSaveAllState] = useState("cursor-not-allowed bg-gray-500 hover:bg-gray-500");

    //Form states
    const [isFormVisible, setIsFormVisible] = useState(true);
    const [processSchedule, setProcessSchedule] = useState(false);

    //Mutation states
    const [mutation, setMutation] = useState(
        //Default mutation to prevent gql conversion to throw error
        `mutation {createTimetable(areaID: 0 name: " " isEnabled:true periodicityType: " " type: " "){ error id ok}}`
    );

    //API
    const ADD_PUNCTUAL_SCHEDULE = gql`
        ${mutation}
    `;
    const [executeMutation, { data }] = useMutation(ADD_PUNCTUAL_SCHEDULE);

    //Actions
    const dispatch = useDispatch();

    const handleAddDatesClick = () => {
        //Add new data form to existing schedule
        setIsFormVisible(true);
        setAddPunctualSchedule(true);
        dispatch(setActionDateType(punctualSchedulesView[0].values.dateType));
        setSaveAllState("cursor-not-allowed bg-gray-500 hover:bg-gray-500");
    };

    //Add actions
    const handleClickSave = () => {
        //Validation
        setDateTypeValidation(validateDateType(dateType));
        setDatesValidation(validateDates(dateType, specificRange, specificDates));
        const scheduleRes = validateSchedule(schedule);
        setScheduleValidationMS(scheduleRes.morningStart);
        setScheduleValidationME(scheduleRes.morningEnd);
        setScheduleValidationAS(scheduleRes.afternoonStart);
        setScheduleValidationAE(scheduleRes.afternoonEnd);
        if (
            validateDateType(dateType) &&
            validateDates(dateType, specificRange, specificDates) &&
            scheduleRes.morningStart &&
            scheduleRes.morningEnd &&
            scheduleRes.afternoonStart &&
            scheduleRes.afternoonEnd
        ) {
            setProcessSchedule(true);
            setSaveAllState("");
        } else {
            toast.error(t("input-error"));
        }
    };

    const handleClickSaveAll = () => {
        //Save data
        if (saveAllState === "") {
            executeMutation();
        } else {
            toast.error(t("check and save form"));
        }
    };

    //Cancel actions
    const handleClickCancel = () => {
        //Cancel adding new data to existing schedule
        if (isFormHidden(punctualSchedulesView)) {
            setIsFormVisible(false);
        } else {
            setAddPunctualSchedule(false);
        }
        if (punctualSchedulesView && punctualSchedulesView.length > 0) {
            setSaveAllState("");
        }
        dispatch(cleanAction());
    };

    const handleClickCancelAll = () => {
        //Cancel all process
        dispatch(cleanAction());
        dispatch(cleanBatchSchedules());
        dispatch(cleanViewSchedules());
        setAddPunctualSchedule(false);
        toast.success(t("exit-wo-save"));
    };

    useEffect(() => {
        if (
            (dateTypeValidation,
            datesValidation,
            scheduleValidationMS,
            scheduleValidationME,
            scheduleValidationAS,
            scheduleValidationAE,
            dateType,
            specificRange || specificDates,
            schedule)
        ) {
            //Add to store for batch proccess
            //Format data
            const dataToBatchStore = getDataToBatchStore(
                punctualSchedulesBatch,
                id,
                dateType,
                specificRange,
                specificDates,
                schedule
            );
            //Update BatchToStore
            dispatch(addBatchPunctualSchedule(dataToBatchStore));

            //Set mutation
            setMutation(
                getMutation(
                    id,
                    GetMutationDateType(dateType),
                    GetScheduleMutationDates(dataToBatchStore[0].timetableDates)
                )
            );
            //Add to edit view
            const punctualSchedule = {
                values: {
                    dateType: dateType,
                    fullSchedule: [
                        {
                            dates: getFormattedDates(specificRange, specificDates, dateType),
                            schedule: getFormattedSchedules(schedule),
                        },
                    ],
                    isEnabled: true,
                    name: "",
                    type: "punctual-schedule",
                },
            };
            dispatch(addViewPunctualSchedule(punctualSchedule));
            setIsFormVisible(false);
            dispatch(cleanScheduleDates());
            dispatch(cleanScheduleHours());
        }
        setProcessSchedule(false);
        // eslint-disable-next-line
    }, [processSchedule]);

    useEffect(() => {
        if (data && data.createTimetable && data.createTimetable.ok) {
            const newID = data.createTimetable.IDs[0];
            const createdTimetable = punctualSchedulesBatch[0];
            createdTimetable.ID = newID;
            delete createdTimetable.areaID;
            toast.success(t("punctual schedule created"));
            //Add to view
            dispatch(addSectionPunctualSchedule(createdTimetable));
            //Reset view
            dispatch(cleanAction());
            dispatch(cleanBatchSchedules());
            dispatch(cleanViewSchedules());
            setAddPunctualSchedule(false);
        } else if (data && data.createTimetable && data.createTimetable.error !== "") {
            toast.error(t("schedule x updated error", { name: title }));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        //Changes dateType validation in real time
        if (dateType) {
            setDateTypeValidation(validateDateType(dateType));
        }
        // eslint-disable-next-line
    }, [dateType]);

    useEffect(() => {
        //Changes dateType validation in real time
        if (dateType && (specificRange || specificDates)) {
            setDatesValidation(validateDates(dateType, specificRange, specificDates));
        }
        // eslint-disable-next-line
    }, [specificRange, specificDates]);

    useEffect(() => {
        //Changes schedule validation in real time
        if (schedule || lastScheduleEdition) {
            const scheduleRes = validateSchedule(schedule);
            setScheduleValidationMS(scheduleRes.morningStart);
            setScheduleValidationME(scheduleRes.morningEnd);
            setScheduleValidationAS(scheduleRes.afternoonStart);
            setScheduleValidationAE(scheduleRes.afternoonEnd);
        }
        // eslint-disable-next-line
    }, [schedule, lastScheduleEdition]);

    return (
        <>
            <div className="w-full block bg-white p-8">
                {punctualSchedulesView ? (
                    <>
                        {punctualSchedulesView.map((punctualSchedule, index) => (
                            <div className="w-full pb-8 flex items-start" key={index}>
                                <div className="w-3/12 inline-block">
                                    {index === 0 ? t(punctualSchedule.values.dateType) : null}
                                </div>
                                {punctualSchedule.values.fullSchedule.map((scheduleData, index) => (
                                    <span className="flex items-start w-9/12" key={index}>
                                        <div className="w-4/12 inline-block">{scheduleData.dates}</div>
                                        <div className="w-5/12 inline-block">{scheduleData.schedule}</div>
                                    </span>
                                ))}
                            </div>
                        ))}
                        {!isFormVisible ? (
                            <>
                                <div className="w-3/12 inline-block"></div>
                                <div
                                    className="w-3/12 inline-block text-blue-300 font-bold cursor-pointer hover:text-blue-200"
                                    onClick={handleAddDatesClick}
                                >
                                    <span className="icon-add mr-2"></span>
                                    {t("add-dates")}
                                </div>
                            </>
                        ) : null}
                    </>
                ) : null}
                {isFormVisible ? (
                    <>
                        <div className="pt-4 pb-8 flex items-start">
                            <div className="w-3/12 inline-block pr-8">
                                {punctualSchedulesBatch && punctualSchedulesBatch.length === 0 ? (
                                    <UseSelect
                                        id={"form-punctual"}
                                        placeholder="date-type"
                                        validation={dateTypeValidation}
                                    />
                                ) : null}
                            </div>
                            {dateType ? (
                                <div className="w-3/12 inline-block pr-8 z-200">
                                    <UseInputDate validation={datesValidation} />
                                </div>
                            ) : null}
                            {specificDates || (specificRange && specificRange.to) ? (
                                <>
                                    <div className="w-6/12 flex justify-between">
                                        <div className="inline-block w-full">
                                            <UseInputTime
                                                dateId="morningStart"
                                                validation={scheduleValidationMS}
                                                adjustWidth={"w-36"}
                                            />{" "}
                                            -{" "}
                                            <UseInputTime
                                                dateId="morningEnd"
                                                validation={scheduleValidationME}
                                                adjustWidth={"w-36"}
                                            />{" "}
                                            /{" "}
                                            <UseInputTime
                                                dateId="afternoonStart"
                                                validation={scheduleValidationAS}
                                                adjustWidth={"w-36"}
                                            />{" "}
                                            -{" "}
                                            <UseInputTime
                                                dateId="afternoonEnd"
                                                validation={scheduleValidationAE}
                                                adjustWidth={"w-36"}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>

                        <div className="w-full flex justify-end h-10 mt-8 mr-4 mb-4">
                            <div
                                className="w-auto inline-block mr-4"
                                id="form-punctual-cancel-trigger-action"
                                onClick={handleClickCancel}
                            >
                                <UseButton
                                    id={"form-punctual-cancel-container"}
                                    buttonName="cancel"
                                    buttonColor="btn-blue-outline"
                                />
                            </div>
                            <div
                                className="w-auto inline-block"
                                id="form-punctual-save-trigger-action"
                                onClick={handleClickSave}
                            >
                                <UseButton
                                    id={"form-punctual-save-container"}
                                    buttonName="save"
                                    buttonColor="btn-blue"
                                />
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
            <div className="w-full flex justify-end h-10 mt-8 mr-4 mb-4">
                <div
                    className="w-auto inline-block mr-4"
                    id="form-punctual-exit-unsaved-trigger-action"
                    onClick={handleClickCancelAll}
                >
                    <UseButton
                        id="form-punctual-exit-unsaved-trigger-action"
                        buttonName="exit-unsaved"
                        buttonColor="btn-blue-outline"
                        adjust="px-8"
                    />
                </div>
                <div
                    id="form-punctual-exit-save-and-close-trigger-action"
                    className="w-auto inline-block"
                    onClick={handleClickSaveAll}
                >
                    <UseButton
                        id="form-punctual-save-and-close-trigger-action"
                        buttonName="save-and-close"
                        buttonColor="btn-blue"
                        adjust={`px-8 ${saveAllState}`}
                    />
                </div>
            </div>
        </>
    );
};

const isFormHidden = (punctualSchedulesView) => {
    //Checks if forms must be shown by default
    let res;
    if (punctualSchedulesView && punctualSchedulesView.length > 0) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

const getFormattedDates = (specificRange, specificDates, dateType) => {
    //Format dates to display correctly
    let res = "";
    const dates = getFrontDateList(specificRange, specificDates, dateType);
    // eslint-disable-next-line
    dates.forEach((date, index) => {
        if (index !== 0) {
            res += dateType === "date-range" ? " - " : ", ";
        }
        res += date;
    });
    return res;
};

const getFormattedSchedules = (schedule) => {
    //Format hours to display correctly
    let res = "";
    const schedules = getFrontSchedules(schedule);
    // eslint-disable-next-line
    schedules.forEach((schedule, index) => {
        if (index !== 0) {
            res += " / ";
        }
        res += schedule;
    });
    return res;
};

const getDataToBatchStore = (punctualSchedulesBatch, id, dateType, specificRange, specificDates, schedule) => {
    //Adds schedule to Redux store
    let res;
    if (punctualSchedulesBatch.length === 0) {
        //First data of this schedule need common data
        res = [
            {
                areaID: id,
                dateType: GetMutationDateType(dateType),
                isEnabled: true,
                name: "",
                periodicityType: "UNIQUE",
                timetableDates: [],
                type: "OPEN",
            },
        ];
    } else {
        //Already has common data
        res = punctualSchedulesBatch;
    }
    //Format timetableDate
    const timetableDate = {
        dateList: GetMutationDateList(specificRange, specificDates, dateType),
        timetableDateRanges: [
            {
                timeRanges: GetMutationSchedules(schedule),
                weekdayList: "",
            },
        ],
    };
    res[0].timetableDates.push(timetableDate);
    return res;
};

//Mutation functions
const getMutation = (areaID, dateType, timetableDates) => {
    //Returns dynamic mutation
    //ISO 8601 standard Monday(1) Sunday (7)
    return `mutation {
    createTimetable(
      timetables: [
        {
          areaID: ${areaID}
          dateType: "${dateType}"
          isEnabled: true
          name: ""
          periodicityType: "UNIQUE"
          timetableDates: ${timetableDates}
          type: "OPEN"
        }
      ]
      ){ error IDs ok}}`;
};

export default UseFormPunctual;
