import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../hooks/Utils/Utils";
import ReactTooltip from "components/ReactTooltip";
import { withDeprecationWarning } from "dev";

const UseRadio = withDeprecationWarning(
    { name: "UseRadio", alt: "Radiobuttons" },
    ({
        disabled = false,
        value,
        selected,
        text,
        textAdjust,
        tooltip,
        handleClick,
        handleClickData,
        noTriggerClickSelected,
        isCheckboxType,
        noTranslateText,
        id,
    }) => {
        //Store data
        const { t } = useTranslation();

        //States
        const [currentStyle, setCurrentStyle] = useState({
            background: "bg-gray-200 hover:bg-gray-300",
            mark: "hidden",
        });

        //Listeners
        useEffect(() => {
            if (selected === value) {
                setCurrentStyle({
                    background: "bg-blue-300",
                    mark: "block",
                });
            } else {
                setCurrentStyle({
                    background: "bg-gray-200 hover:bg-gray-300",
                    mark: "hidden",
                });
            }
            // eslint-disable-next-line
        }, [selected]);

        const handleClickRadio = () => {
            if (handleClick && typeof handleClick === "function") {
                if (!noTriggerClickSelected) {
                    handleClick(handleClickData ? handleClickData : value);
                } else if (selected !== value) {
                    handleClick(handleClickData ? handleClickData : value);
                }
            }
        };

        return (
            <div className="flex items-center">
                <label
                    id={id ? `radio-container-${id}` : isCheckboxType ? "container-checkbox" : ""}
                    className="radio-container relative"
                >
                    <input
                        id={`${id ? id : isCheckboxType ? "checkbox" : ""}`}
                        style={{ marginTop: "0.05rem" }}
                        type={isCheckboxType ? "checkbox" : "radio"}
                        value={value}
                        defaultChecked={selected === value ? true : false}
                        checked={currentStyle.mark === "block" ? true : false}
                        required
                        disabled={disabled}
                        onClick={handleClickRadio}
                        className={`${
                            isCheckboxType
                                ? `absolute top-0 left-0 w-5 h-5 checkbox cursor-pointer z-10 ${
                                      selected !== value ? "hidden" : ""
                                  }`
                                : ""
                        }`}
                    />
                    {isCheckboxType ? (
                        <span
                            id={id ? `${id}-span` : "checkbox-background"}
                            className={`absolute top-0 left-0 w-5 h-5 bg-gray-200 hover:bg-gray-300 cursor-pointer`}
                            style={{ top: "0.05rem" }}
                            onClick={handleClickRadio}
                        ></span>
                    ) : null}
                    {text ? (
                        <div className={`ml-10 first-capital flex ${textAdjust}`}>
                            {capitalizeFirst(!noTranslateText ? t(text) : text)}
                            {tooltip && (
                                <>
                                    <span
                                        className="icon icon-info ml-2 text-xl lg:text-2xl"
                                        data-tip
                                        data-for={`radio${value}Tooltip`}
                                    ></span>
                                    <ReactTooltip id={`radio${value}Tooltip`}>
                                        {t("{{capitalize, capitalize}}", { capitalize: t(tooltip) })}
                                    </ReactTooltip>
                                </>
                            )}
                        </div>
                    ) : null}
                </label>
            </div>
        );
    }
);

export default UseRadio;
