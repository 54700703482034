import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import UseItemActions from "../../../useItemActions";
import { printAlarm, roomHasAlarm, getTVChannelName } from "../../../../hooks/Utils/Customers/AlarmUtils";
import NewAlarm from "../../../../hooks/GraphqlCalls/Customers/modals/NewAlarm";
import { openModal, setModalContent } from "../../../../actions/uiActions";
import { changeActionValues, setActionName } from "../../../../actions/globalActions";
import UpdateAlarm from "../../../../hooks/GraphqlCalls/Customers/UpdateAlarm";
import GetTVChannels from "../../../../hooks/GraphqlCalls/Customers/GetTVChannels";

const RoomDetailAlarms = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { room, guest, channels } = useSelector((state) => state.sectionContent);
    const alarms = useRef([]);

    useEffect(() => {
        if (guest && guest.alarms && guest.alarms.length > 0 && roomHasAlarm(guest.alarms, room ? room.name : null)) {
            alarms.current = guest.alarms; //[getLastModifiedAlarm(guest.alarms)];
        } else {
            alarms.current = [];
        }
        // eslint-disable-next-line
    }, [guest, room]);

    const setAllAlarms = () => {
        const newAlarmModalSetAll = {
            ...NewAlarm(),
            title: "set-alarms4room",
            value: room.name,
            text: "",
            inputs: [
                {
                    text: "new-alarm",
                    type: "newAlarm",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "set",
                    style: "blue",
                    action: "new-alarm",
                },
            ],
        };

        dispatch(setModalContent(newAlarmModalSetAll));
        dispatch(
            changeActionValues({
                isFromRoomDetail: true,
                stayGuestRoom: guest.rooms.find((nroom) => nroom.RoomID === room.id).stayGuestRoomID,
            })
        );
        dispatch(setActionName("new-alarm"));
        dispatch(openModal());
    };

    GetTVChannels();

    return (
        <>
            <UpdateAlarm />
            {alarms ? (
                <div className="h-56 mt-8 bg-white rounded px-10 py-5">
                    <div className="flex w-full">
                        <div className="text-gray-900 font-bold text-lg">
                            {t("{{capitalize, capitalize}}", { capitalize: t("alarms") })}
                        </div>
                        <div className="ml-auto">
                            {alarms.current.length > 0 ? (
                                <UseItemActions
                                    dataType="alarm"
                                    executeSectionQuery={true}
                                    // id={alarms.current.map((alarm) => alarm.id)}
                                    id={guest.rooms.find((nroom) => nroom.RoomID === room.id).stayGuestRoomID}
                                    // or ROOM.ID depends in what mutation of updateRoomAlarm need
                                    actions={[
                                        {
                                            name: "edit",
                                            action: "edit-room-alarm",
                                            executeSectionQuery: true,
                                            modal_btn_name: "save",
                                            modal_title: "edit",
                                            modal_value: room ? room.name : null,
                                            alarms: alarms.current,
                                            filterRoom: room.name,
                                        },
                                        {
                                            name: "delete",
                                            action: "delete-room-alarm",
                                            executeSectionQuery: true,
                                            modal_btn_name: "delete",
                                            modal_title: `${room ? room.name : ""} - ${t("{{capitalize, capitalize}}", {
                                                capitalize: t("delete-alarms"),
                                            })}`,
                                            roomName: room ? room.name : null,
                                        },
                                    ]}
                                />
                            ) : (
                                <span
                                    className="icon-edit text-2xl text-zafiro-600 cursor-pointer"
                                    onClick={setAllAlarms}
                                ></span>
                            )}
                        </div>
                    </div>
                    <hr className="h-1 my-2" />
                    <div className="h-32 overflow-y-scroll">
                        {alarms.current.length > 0 ? (
                            alarms.current.map((alarm) =>
                                printAlarm(alarm, room.name, getTVChannelName(channels, alarm.tvChannelId))
                            )
                        ) : (
                            <div className="text-gray-800 text-base">
                                {t("{{capitalize, capitalize}}", { capitalize: t("no-alarmas-set") })}
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default RoomDetailAlarms;
