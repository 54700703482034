export const SET_ACTION = "SET_ACTION";
export const CHANGE_ACTION_VALUES = "CHANGE_ACTION_VALUES";
export const SET_ACTION_VALUES = "SET_ACTION_VALUES";
export const CHANGE_ACTION_VALIDATIONS = "CHANGE_ACTION_VALIDATIONS";
export const CLEAN_ACTION_VALIDATIONS = "CLEAN_ACTION_VALIDATIONS";
export const SET_ACTION_VALIDATIONS = "SET_ACTION_VALIDATIONS";
export const CLEAN_ACTION_VALUES = "CLEAN_ACTION_VALUES";
export const PREPARE_UPDATES = "PREPARE_UPDATES";
export const CLEAN_ACTION = "CLEAN_ACTION";
export const SET_ACTION_NAME = "SET_ACTION_NAME";
export const SET_ACTION_BACKUP = "SET_ACTION_BACKUP";
export const SET_ITEM_NAME = "SET_ITEM_NAME";
export const SET_ITEM_AFFECTED = "SET_ITEM_AFFECTED";
export const SET_SPECIFIC_DAYS = "SET_SPECIFIC_DAYS";
export const SET_SPECIFIC_RANGE = "SET_SPECIFIC_RANGE";
export const SET_SPECIFIC_SCHEDULE = "SET_SPECIFIC_SCHEDULE";
//ISO 8601 standard Monday(1) Sunday (7)
export const WEEKDAY_ADD = "WEEKDAY_ADD";
export const WEEKDAY_REMOVE = "WEEKDAY_REMOVE";
export const ADD_ACTION_INPUT = "ADD_ACTION_INPUT";
export const SET_ACTION_DATA = "SET_ACTION_DATA";
export const CLEAN_SCHEDULE_DATES = "CLEAN_SCHEDULE_DATES";
export const CLEAN_SCHEDULE_HOURS = "CLEAN_SCHEDULE_HOURS";
export const SET_DATETYPE = "SET_DATETYPE";

export function changeGlobalAction(actionData) {
    //Action to load in action store object with action config (actionName,itemsAffected,values...)
    return (dispatch) => {
        dispatch({
            type: SET_ACTION,
            payload: actionData,
        });
    };
}

export function changeActionValidations(newValues) {
    //Action to load in action store action values
    return (dispatch) => {
        dispatch({
            type: CHANGE_ACTION_VALIDATIONS,
            payload: newValues,
        });
    };
}

export function setActionValidations(newValues) {
    //Action to load in action store action values
    return (dispatch) => {
        dispatch({
            type: SET_ACTION_VALIDATIONS,
            payload: newValues,
        });
    };
}

export function cleanActionValidations() {
    //Action to load in action store action values
    return (dispatch) => {
        dispatch({
            type: CLEAN_ACTION_VALIDATIONS,
        });
    };
}

export function changeActionValues(newValues) {
    //Action to load in action store action values
    return (dispatch) => {
        dispatch({
            type: CHANGE_ACTION_VALUES,
            payload: newValues,
        });
    };
}

export function setActionValues(newValues) {
    //Action to load in action store action values
    return (dispatch) => {
        dispatch({
            type: SET_ACTION_VALUES,
            payload: newValues,
        });
    };
}

export function cleanActionValues() {
    //Action to load in action store action values
    return (dispatch) => {
        dispatch({
            type: CLEAN_ACTION_VALUES,
        });
    };
}

export function cleanAction() {
    //Action to clean action store
    return (dispatch) => {
        dispatch({
            type: CLEAN_ACTION,
        });
    };
}

export function prepareUpdates(data) {
    return (dispatch) => {
        dispatch({
            type: PREPARE_UPDATES,
            payload: data,
        });
    };
}

export function setActionName(actionName) {
    //Action to load action name in action store
    return (dispatch) => {
        dispatch({
            type: SET_ACTION_NAME,
            payload: actionName,
        });
    };
}

export function setActionBackup(actionBackup) {
    return (dispatch) => {
        dispatch({
            type: SET_ACTION_BACKUP,
            payload: actionBackup,
        });
    };
}

export function setActionItemAffected(id) {
    //Action to load item affected (id) in action store
    return (dispatch) => {
        dispatch({
            type: SET_ITEM_AFFECTED,
            payload: id,
        });
    };
}

export function setItemName(itemName) {
    //Action to load itemName in action store
    return (dispatch) => {
        dispatch({
            type: SET_ITEM_NAME,
            payload: itemName,
        });
    };
}

export function setSpecificDays(dates) {
    //Action to load in action store schedule specific dates
    return (dispatch) => {
        dispatch({
            type: SET_SPECIFIC_DAYS,
            payload: dates,
        });
    };
}

export function setSpecificRange(range) {
    //Action to load in action store schedule date ranges
    return (dispatch) => {
        dispatch({
            type: SET_SPECIFIC_RANGE,
            payload: range,
        });
    };
}

export function setNewSchedules(keyValue) {
    //Action to load in action store schedule time inputs {morningStart,morningEnd,afternoonStart,afternoonEnd}
    return (dispatch) => {
        dispatch({
            type: SET_SPECIFIC_SCHEDULE,
            payload: keyValue,
        });
    };
}
//ISO 8601 standard Monday(1) Sunday (7)
export function addWeekDay(weekDay) {
    //Action to add in action store a schedule weekday
    return (dispatch) => {
        dispatch({
            type: WEEKDAY_ADD,
            payload: weekDay,
        });
    };
}
//ISO 8601 standard Monday(1) Sunday (7)
export function removeWeekDay(weekDay) {
    //Action to remove in action store a schedule weekday
    return (dispatch) => {
        dispatch({
            type: WEEKDAY_REMOVE,
            payload: weekDay,
        });
    };
}

export function setActionInput(inputData) {
    //Action to change in action store a specific value using value key
    return (dispatch) => {
        dispatch({
            type: ADD_ACTION_INPUT,
            payload: inputData,
        });
    };
}

export function setActionData(actionData) {
    //Action to set in action store actionData attr obj
    return (dispatch) => {
        dispatch({
            type: SET_ACTION_DATA,
            payload: actionData,
        });
    };
}

export function cleanScheduleDates() {
    //Action to remove all schedule dates in action store
    return (dispatch) => {
        dispatch({
            type: CLEAN_SCHEDULE_DATES,
        });
    };
}

export function cleanScheduleHours() {
    //Action to remove all schedule times in action store
    return (dispatch) => {
        dispatch({
            type: CLEAN_SCHEDULE_HOURS,
        });
    };
}

export function setActionDateType(dateType) {
    //Action to load a schedule dateTpe in action store
    return (dispatch) => {
        dispatch({
            type: SET_DATETYPE,
            payload: dateType,
        });
    };
}
