import React, { useContext } from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { TopologyContext } from "contexts/Topology";
import { useCreateAccessType, useEditAccessType } from "hooks/GraphqlCalls/Hotel/Monitoring/useUpdateAccessType";
import { AccessTypesContext } from "contexts/AccessTypes";

export const SaveAccessProfile = ({ type, id }) => {
    const { t } = useTranslation();
    const { createAccessTypeMutation } = useCreateAccessType();
    const { editAccessTypeMutation } = useEditAccessType();
    const {
        setCreating,
        name,
        setNameValidation,
        uploadMinBandwidth,
        downloadMinBandwidth,
        uploadMaxBandwidth,
        downloadMaxBandwidth,
        setUploadMinBandwidthValidation,
        setDownloadMinBandwidthValidation,
        setUploadMinBandwidth,
        setUploadMaxBandwidth,
        setDownloadMinBandwidth,
        setDownloadMaxBandwidth,
        setName,
        setEditing,
    } = useContext(AccessTypesContext);

    const uploadDifference = Number(uploadMinBandwidth) <= Number(uploadMaxBandwidth);
    const downloadDifference = Number(downloadMinBandwidth) <= Number(downloadMaxBandwidth);

    const addAccessType = () => {
        if (!name) {
            setNameValidation("border border-red-100");
            toast.error(t("errors-in-red"));
        } else {
            setNameValidation("");
        }
        if (!uploadDifference) {
            setUploadMinBandwidthValidation("border border-red-100");
            toast.error(t("errors-in-red"));
        }
        if (!downloadDifference) {
            setDownloadMinBandwidthValidation("border border-red-100");
            toast.error(t("errors-in-red"));
        }
        if (name && uploadDifference && downloadDifference) {
            createAccessTypeMutation(
                name,
                downloadMinBandwidth,
                uploadMaxBandwidth,
                uploadMinBandwidth,
                downloadMaxBandwidth
            );
            setCreating(false);
            setEditing((prev) => ({ ...prev, editing: false }));
            setName("");
            setUploadMinBandwidth("");
            setUploadMaxBandwidth("");
            setDownloadMinBandwidth("");
            setDownloadMaxBandwidth("");
        }
    };

    const editAccessType = () => {
        if (!name) {
            setNameValidation("border border-red-100");
            toast.error(t("errors-in-red"));
        } else {
            setNameValidation("");
        }
        if (!uploadDifference) {
            setUploadMinBandwidthValidation("border border-red-100");
            toast.error(t("errors-in-red"));
        }
        if (!downloadDifference) {
            setDownloadMinBandwidthValidation("border border-red-100");
            toast.error(t("errors-in-red"));
        }

        if (name && uploadDifference && downloadDifference) {
            editAccessTypeMutation(
                id,
                name,
                downloadMinBandwidth,
                uploadMaxBandwidth,
                uploadMinBandwidth,
                downloadMaxBandwidth
            );
            setCreating(false);
            setEditing((prev) => ({ ...prev, editing: false }));
            setName("");
            setUploadMinBandwidth("");
            setUploadMaxBandwidth("");
            setDownloadMinBandwidth("");
            setDownloadMaxBandwidth("");
        }
    };

    return (
        <Button
            id="save"
            className="btn-blue"
            onClick={() => (type === "addAccessType" ? addAccessType() : editAccessType())}
        >
            {t("save")}
        </Button>
    );
};
