import { useEffect } from "react";
import { useMSQuery } from "../useMSQuery";
import { gql } from "apollo-boost";
import { useDispatch, useSelector } from "react-redux";
import { getNewDataGroupColor } from "../../Utils/ColorUtils";
import { setSectionContent } from "../../../actions/sectionActions";
import {
    CORPORATE_REF,
    CORPORATE_APPEARANCE_TYPE,
    PROPERTY_APPEARANCE_TYPE,
    CORPORATE_LOGOS_TYPE,
    PROPERTY_LOGOS_TYPE,
    THEME_TYPE_DESIGN,
    THEME_TYPE_MOBILE,
} from "../../Utils/AppearancesUtils";

import { useAuth } from "hooks/Session/auth";

const LoadAppearance = ({ themeType, setLoadingAppearance }) => {
    const dispatch = useDispatch();
    const { project } = useAuth();

    const projectRef = project?.ref;
    const { appearanceSelected, appearanceId, refreshData } = useSelector((state) => state.sectionContent);

    //API Call
    const LOAD_THEME = gql`
        query Themes($id: Int64!) {
            themes(filter: { id: $id }) {
                info {
                    count
                }
                results {
                    id
                    name
                    type
                    config {
                        colors {
                            defaultBackground
                            defaultForeground
                            activeBackground
                            activeForeground
                            focusBackground
                            focusForeground
                            focusBorderSize
                            accent
                            accentContrast
                            login
                        }
                        fontStyles {
                            heading {
                                alignment
                                bold
                                italic
                                name
                                size
                                underline
                                font {
                                    libraryRef
                                    externalUrl
                                }
                            }
                            paragraph {
                                alignment
                                bold
                                italic
                                name
                                size
                                underline
                                font {
                                    libraryRef
                                    externalUrl
                                }
                            }
                        }
                    }
                    themeLogos {
                        id
                        imageRef
                        type
                        version
                        themeLogoCustoms {
                            imageRef
                        }
                    }
                }
            }
            mobileAppsOnTheme(ThemeID: $id) {
                info {
                    count
                }
                results {
                    id
                    isMultiProperty
                    name
                    projects {
                        id
                        name
                    }
                }
            }
            designsOnTheme(ThemeID: $id) {
                info {
                    count
                }
                results {
                    ID
                    name
                    projectID
                    projectName
                    type
                }
            }
        }
    `;

    const [executeLoadThemesQuery, { loading, error, data, refetch }] = useMSQuery(LOAD_THEME, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (
            !loading &&
            !error &&
            data &&
            data.themes.results &&
            data.themes.results.length > 0 &&
            themeType === THEME_TYPE_DESIGN
        ) {
            // load
            const _defaultBG = getNewDataGroupColor(null, data.themes.results[0].config.colors.defaultBackground, "bg");
            const _default = getNewDataGroupColor(
                _defaultBG,
                data.themes.results[0].config.colors.defaultForeground,
                "fg"
            );
            const _activeBG = getNewDataGroupColor(null, data.themes.results[0].config.colors.activeBackground, "bg");
            const _active = getNewDataGroupColor(
                _activeBG,
                data.themes.results[0].config.colors.activeForeground,
                "fg"
            );
            const _focusBG = getNewDataGroupColor(null, data.themes.results[0].config.colors.focusBackground, "bg");
            const _focus = getNewDataGroupColor(_focusBG, data.themes.results[0].config.colors.focusForeground, "fg");
            let headingFontLink = {};
            if (
                data?.themes?.results?.[0]?.config?.fontStyles?.heading &&
                data?.themes?.results?.[0]?.config?.fontStyles?.heading.font &&
                data?.themes?.results?.[0]?.config?.fontStyles?.heading.font.externalUrl
            ) {
                headingFontLink.externalUrl = data?.themes?.results?.[0]?.config?.fontStyles?.heading.font.externalUrl;
            }
            if (
                data?.themes?.results?.[0]?.config?.fontStyles?.heading &&
                data?.themes?.results?.[0]?.config?.fontStyles?.heading.font &&
                data?.themes?.results?.[0]?.config?.fontStyles?.heading.font.libraryRef
            ) {
                headingFontLink.libraryRef = data?.themes?.results?.[0]?.config?.fontStyles?.heading.font.libraryRef;
            }
            const _heading = {
                fontName: data?.themes?.results?.[0]?.config?.fontStyles?.heading.name,
                size: data?.themes?.results?.[0]?.config?.fontStyles?.heading.size,
                textAlign: data?.themes?.results?.[0]?.config?.fontStyles?.heading.alignment,
                bold: data?.themes?.results?.[0]?.config?.fontStyles?.heading.bold,
                italic: data?.themes?.results?.[0]?.config?.fontStyles?.heading.italic,
                underline: data?.themes?.results?.[0]?.config?.fontStyles?.heading.underline,
                fontLink: headingFontLink,
            };
            let paragraphFontLink = {};
            if (
                data?.themes?.results?.[0]?.config?.fontStyles?.paragraph &&
                data?.themes?.results?.[0]?.config?.fontStyles?.paragraph.font &&
                data?.themes?.results?.[0]?.config?.fontStyles?.paragraph.font.externalUrl
            ) {
                paragraphFontLink.externalUrl =
                    data?.themes?.results?.[0]?.config?.fontStyles?.paragraph.font.externalUrl;
            }
            if (
                data?.themes?.results?.[0]?.config?.fontStyles?.paragraph &&
                data?.themes?.results?.[0]?.config?.fontStyles?.paragraph.font &&
                data?.themes?.results?.[0]?.config?.fontStyles?.paragraph.font.libraryRef
            ) {
                paragraphFontLink.libraryRef =
                    data?.themes?.results?.[0]?.config?.fontStyles?.paragraph.font.libraryRef;
            }
            const _paragraph = {
                fontName: data?.themes?.results?.[0]?.config?.fontStyles?.paragraph.name,
                size: data?.themes?.results?.[0]?.config?.fontStyles?.paragraph.size,
                textAlign: data?.themes?.results?.[0]?.config?.fontStyles?.paragraph.alignment,
                bold: data?.themes?.results?.[0]?.config?.fontStyles?.paragraph.bold,
                italic: data?.themes?.results?.[0]?.config?.fontStyles?.paragraph.italic,
                underline: data?.themes?.results?.[0]?.config?.fontStyles?.paragraph.underline,
                fontLink: paragraphFontLink,
            };
            const logoToPreview = selectLogoToPreview(data.themes.results[0].themeLogos);
            const logoAssetToPreview = logoToPreview
                ? logoToPreview.themeLogoCustoms.length > 0
                    ? logoToPreview.themeLogoCustoms[0].imageRef
                    : logoToPreview.imageRef
                : null;
            dispatch(
                setSectionContent({
                    appearanceSelected: {
                        ...appearanceSelected,
                        name: data.themes.results[0].name,
                        appliedIn: data.designsOnTheme.info.count,
                        designsTree: data.designsOnTheme.results,
                        default: { ..._default },
                        active: { ..._active },
                        focus: { ..._focus },
                        focusBorderSize: data.themes.results[0].config.colors.focusBorderSize,
                        headerStyle: { ..._heading },
                        paragraphStyle: { ..._paragraph },
                        logos:
                            data.themes.results[0].themeLogos.length > 0
                                ? [...data.themes.results[0].themeLogos]
                                : null,
                        logoSelected: logoAssetToPreview,
                        logoSelectedId: logoToPreview ? logoToPreview.id : null,
                        themeType: themeType,
                    },
                })
            );
        } else if (
            !loading &&
            !error &&
            data &&
            data.themes.results &&
            data.themes.results.length > 0 &&
            themeType === THEME_TYPE_MOBILE
        ) {
            const _login = getNewDataGroupColor(null, data.themes.results[0].config.colors.login, "bg");
            const _accent = getNewDataGroupColor(null, data.themes.results[0].config.colors.accent, "bg");

            dispatch(
                setSectionContent({
                    appearanceSelected: {
                        ...appearanceSelected,
                        accent: {
                            ..._accent,
                        },
                        login: {
                            ..._login,
                        },
                        id: data.themes.results[0].id,
                        themeType: themeType,
                        appsData: data?.mobileAppsOnTheme?.results,
                        appliedIn: data.mobileAppsOnTheme.info.count,
                        name: data.themes.results[0].name,
                        type: data.themes.results[0].type,
                        logos:
                            data.themes.results[0].themeLogos.length > 0
                                ? [...data.themes.results[0].themeLogos]
                                : null,
                    },
                })
            );
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (typeof setLoadingAppearance === "function") {
            setLoadingAppearance(loading);
        }
    }, [loading]);

    useEffect(() => {
        if (appearanceId) {
            setLocksForAppearance(appearanceSelected);
            executeLoadThemesQuery({ variables: { id: `${appearanceSelected.id}` } });
        }
        // eslint-disable-next-line
    }, [appearanceId]);

    const setLocksForAppearance = (appearance) => {
        if (projectRef !== CORPORATE_REF && appearance.type === CORPORATE_APPEARANCE_TYPE) {
            dispatch(
                setSectionContent({
                    appearanceSelected: {
                        id: appearance.id,
                        name: appearance.name,
                        type: appearance.type,
                        locks: { LOGOS_TOOLBAR: false, TYPOGRAPHY_TOOLBAR: true, COLOUR_TOOLBAR: true },
                    },
                    appearanceId: appearance.id,
                })
            );
        } else if (projectRef === CORPORATE_REF && appearance.type === PROPERTY_APPEARANCE_TYPE) {
            dispatch(
                setSectionContent({
                    appearanceSelected: {
                        id: appearance.id,
                        name: appearance.name,
                        type: appearance.type,
                        locks: { LOGOS_TOOLBAR: true, TYPOGRAPHY_TOOLBAR: true, COLOUR_TOOLBAR: true },
                    },
                    appearanceId: appearance.id,
                })
            );
        } else {
            dispatch(
                setSectionContent({
                    appearanceSelected: {
                        id: appearance.id,
                        name: appearance.name,
                        type: appearance.type,
                        locks: { LOGOS_TOOLBAR: false, TYPOGRAPHY_TOOLBAR: false, COLOUR_TOOLBAR: false },
                    },
                    appearanceId: appearance.id,
                })
            );
        }
    };

    const selectLogoToPreview = (logos) => {
        const _genericLogos = logos.filter((logo) => logo.type === CORPORATE_LOGOS_TYPE);
        const _porpertyLogos = logos.filter((logo) => logo.type === PROPERTY_LOGOS_TYPE);
        if (_genericLogos.length > 0) {
            return _genericLogos[0];
        }
        if (_porpertyLogos.length > 0) {
            return _porpertyLogos[0];
        }
        return null;
    };

    useEffect(() => {
        if (refreshData && refetch && appearanceSelected?.id) {
            refetch();
        }
        // eslint-disable-next-line
    }, [refreshData]);
};

export default LoadAppearance;
