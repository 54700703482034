import React from "react";
import { useSelector } from "react-redux";

//Utils

const UseBatchSelection = ({ index }) => {
    //Store data
    const { results, checkedItems } = useSelector((state) => state.table);

    const { columnPos } = useSelector((state) => state.ui.modalContent.inputs[index]);

    return (
        <>
            {results.map((result) =>
                checkedItems.filter((item) => item === result.id).length > 0 ? (
                    <div
                        key={result.id}
                        className="float-left z-20  bg-gray-200 text-gray-900 px-6 p-1 mr-2 mb-2 rounded"
                    >
                        {renderInfoValue({result,columnPos})}
                    </div>
                ) : null
            )}
        </>
    );
};

const renderInfoValue = (props) => {
    const {result, columnPos} = props;
    let response = "";
    if(result.info[columnPos].value){
        response = result.info[columnPos].value;
    }else if(result.info[columnPos].subvalue){
        response = result.info[columnPos].subvalue;
    }
    return response;
}

export default UseBatchSelection;
