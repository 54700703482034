export const GetTableForm = (type) => {
    let res;
    switch (type) {
        case "areas":
        case "areas-category":
        case "categories":
            res = {
                inputs: [
                    {
                        name: "name",
                        type: "text",
                        validate: "not-empty",
                    },
                ],
            };
            break;
        case "booking-category":
            res = {
                inputs: [
                    {
                        name: "hour",
                        type: "select",
                    },
                    {
                        name: "people",
                        type: "number",
                    },
                    {
                        name: "name",
                        type: "text",
                    },
                    {
                        name: "phone",
                        type: "phone",
                    },
                    {
                        name: "mail",
                        type: "mail",
                    },
                ],
            };
            break;
        case "capacity-edit":
            res = {
                inputs: [
                    {
                        name: "capacity",
                        type: "text",
                    },
                ],
            };
            break;
        case "list-staff":
            res = {
                inputs: [
                    {
                        name: "name",
                        type: "text",
                    },
                    {
                        name: "email",
                        type: "email",
                    },
                    {
                        name: "role",
                        type: "selectWithCheckboxes",
                    },
                ],
            };
            break;

        case "list-companions":
            res = {
                inputs: [
                    {
                        name: "title",
                        type: "text",
                        style: "w-1/12",
                    },
                    {
                        name: "name",
                        type: "text",
                        style: "w-2/12",
                    },
                    {
                        name: "surname",
                        type: "text",
                        style: "w-2/12",
                    },
                    {
                        name: "email",
                        type: "text",
                        style: "w-3/12",
                    },
                    {
                        name: "room",
                        type: "select-with-search",
                        style: "w-2/12",
                        oneSelected: true,
                    },
                    {
                        name: "birthday",
                        type: "date",
                        style: "w-2/12",
                    },
                    {
                        name: "lang",
                        type: "select-with-search",
                        style: "w-2/12",
                        oneSelected: true,
                    },
                ],
            };
            break;
        case "list-languages":
            res = {
                inputs: [
                    {
                        name: "lang",
                        type: "select-with-search",
                        style: "w-6/12",
                        oneSelected: true,
                    },
                ],
            };
            break;
        default:
            res = {};
            break;
    }
    res.buttons = [
        {
            name: "cancel",
            color: "btn-blue-outline",
        },
        {
            name: "save",
            color: "btn-blue",
        },
    ];

    return res;
};

export default GetTableForm;
