import React, { useEffect, useState } from "react";
//API
import { gql } from "apollo-boost";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useMSQuery } from "../../../../../hooks/GraphqlCalls/useMSQuery";
import { toast } from "react-toastify";
import UseSectionHeader from "../../../../useSectionHeader";
import UseCard from "../../../../useCard";
import { CORPORATE_CHANNEL, PRESET_CHANNEL } from "../../../../../hooks/Utils/Services/ChannelsUtils";
import UpdateChannels from "../../../../../hooks/GraphqlCalls/Services/UpdateChannels";
import { useLocation } from "react-router-dom";
import CorporateChannelsVideos from "./CorporateChannelsVideos";
import UpdateCorporateChannel from "../../../../../hooks/GraphqlCalls/Services/UpdateCorporateChannel";

const ChannelEdition = () => {
    //Consts&states
    const { t } = useTranslation();
    const { id: channelId } = useParams();
    const [channelData, setChannelData] = useState(null);
    const [name, setName] = useState(null);
    const location = useLocation();
    const [viewShowInfoChannel, setViewShowInfoChannel] = useState(null);
    const [breadcrumbs, setBreadCrumbs] = useState([
        {
            name: "services",
            translate: true,
            route: `/services`,
        },
        {
            name: "tv-channels",
            translate: true,
            route: `/services/tv-channels`,
        },
        {
            name: "channel-list",
            translate: true,
            route: `/services/tv-channels`,
            respectRoute: true,
        },
    ]);

    //queries&mutations
    const GET_DATA = gql`
        {
            channels: tunes
                (filter:{id:${channelId}})
                {
                results {
                    id
                    channelID
                    name
                    type
                    isCorporateTvChannelLocal
                    logoRef
                }
            }
        }
    `;

    //useQueries&useMutations
    const [executeQuery, { data, loading, error, networkStatus, refetch }] = useMSQuery(GET_DATA);

    //effects
    useEffect(() => {
        if (channelId) {
            executeQuery();
        }
    }, [channelId]);

    useEffect(() => {
        if (!loading && !error && networkStatus === 7 && data && data.channels) {
            if (data.channels.results && data.channels.results.length > 0) {
                const channel = data.channels.results[0];
                const viewShowInfoChannel =
                    channel?.type === PRESET_CHANNEL.value
                        ? "channel-review-information"
                        : channel?.type === CORPORATE_CHANNEL.value
                        ? "channel-corporate-information"
                        : "channel-edition-information";
                setName(channel?.name);
                setChannelData(channel);
                setViewShowInfoChannel(viewShowInfoChannel);
            } else {
                toast.error(t("no-results-found"));
            }
        }
    }, [data, networkStatus]);

    useEffect(() => {
        if (error) {
            toast.error(t("error-mutation"));
        }
    }, [error]);

    useEffect(() => {
        if (location?.pathname?.includes("groups")) {
            setBreadCrumbs(getBreadcrumbGroups(location.pathname));
        }
    }, [location]);

    //handle&Functions
    console.log(breadcrumbs);

    //renders
    return (
        <>
            <UpdateChannels />
            <UseSectionHeader
                title={name}
                customBreadCrumbs={breadcrumbs}
                navToPreviousPage={true}
                noCapitalize={true}
                noTranslate={true}
            />
            <div className=" w-full flex ">
                <div className="w-7/12 pr-5 flex">
                    <div className="w-full flex">
                        {viewShowInfoChannel ? (
                            <UseCard
                                adjust="ChannelEdition"
                                data={{ channelData: channelData, setName }}
                                key={channelId}
                                noDefaultAdjust={true}
                                type={viewShowInfoChannel}
                            />
                        ) : null}
                    </div>
                </div>

                <div className="w-5/12 flex">
                    <div className="w-full flex">
                        {viewShowInfoChannel ? (
                            <UseCard
                                adjust="ChannelEdition"
                                data={{ channelData: channelData }}
                                key={channelId}
                                noDefaultAdjust={true}
                                type="channel-edition-tuning"
                            />
                        ) : null}
                    </div>
                </div>
            </div>
            {channelData?.type === CORPORATE_CHANNEL.value && channelData?.isCorporateTvChannelLocal ? (
                <div className="  mt-8 bg-white shadow-md rounded w-full">
                    <CorporateChannelsVideos id={channelData.channelID} nameChannel={name} internalLoading={true} />
                    <UpdateCorporateChannel id={channelData.channelID} />
                </div>
            ) : null}
        </>
    );
};

export default ChannelEdition;

const getBreadcrumbGroups = (path) => {
    const matchIDGroup = path.match(/\d+/);
    const idGroup = matchIDGroup ? matchIDGroup[0] : null;
    return [
        {
            name: "services",
            translate: true,
            route: `/services`,
        },
        {
            name: "tv-channels",
            translate: true,
            route: `/services/tv-channels`,
        },
        {
            name: "groups",
            translate: true,
            route: `/services/tv-channels/groups/${idGroup}`,
        },
    ];
};
