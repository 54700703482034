import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import routes from "routes";

import GetLangStrings from "lang/getLangStrings";
import { setLangStrings } from "actions/uiActions";
import DisplayMenuSidebar from "hooks/Utils/DisplayMenuSidebar";
import { Session } from "hooks/Utils/Session";
import { useAuth } from "hooks/Session/auth";

import { GlobalContext } from "contexts/Global";

const Section = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const locationPath = location?.pathname;

    const { isProjectLogged, paths } = useContext(GlobalContext);

    const currentMenuConfig = DisplayMenuSidebar();
    const {
        lang,
        sidebar: sidebarOpen,
        sectionStyle: customContentClass,
        warnings: hasWarnings,
        warningsMinimized: hasWarningsMinimized,
    } = useSelector((state) => state.ui);
    const { isImpersonating } = useAuth();

    const topPx = currentMenuConfig?.hideHeader ? 0 : 72;
    const topRem = (hasWarnings ? 5 : 0) + (hasWarningsMinimized ? -3 : 0) + (isImpersonating ? 2 : 0);
    const contentStyle = !isProjectLogged
        ? null
        : {
              height: `calc(100vh - ${topPx}px - ${topRem}rem)`,
          };

    const currentMenuClass = !isProjectLogged
        ? null
        : `right-0 bg-gray-100 ${currentMenuConfig?.style?.x} ${currentMenuConfig?.style?.y}`;
    const contentClass = `overflow-auto ${customContentClass || currentMenuClass}`;

    useEffect(() => {
        //TODO langStrings is deprecated and should be removed
        const selectedLang = lang || "en";
        const langStrings = GetLangStrings(selectedLang, locationPath);
        dispatch(setLangStrings(langStrings));
    }, [lang, locationPath]);

    useEffect(() => {
        Session.setCurrentPath(location);
    }, [locationPath]);

    const availableRoutes = routes ? routes?.filter((route) => paths?.includes(route?.path)) : null;

    const finalRoutes = availableRoutes?.length
        ? availableRoutes
              .filter(
                  (route) =>
                      typeof route.section !== "undefined" ||
                      typeof route.redirect !== "undefined" ||
                      route.reload === true
              )
              .map((route) => {
                  const reload = route?.reload === true;
                  const redirect = route?.redirect ? defaultRedirect({ route, routes: availableRoutes }) : null;
                  const section = reload || redirect ? null : route?.section;
                  const element = (() => {
                      if (reload) {
                          return <Reload />;
                      } else if (redirect) {
                          return <Navigate to={redirect} />;
                      }
                      return null;
                  })();
                  return {
                      ...route,
                      element,
                      Component: section,
                  };
              })
        : null;

    //TODO remove this hack when the layout is done correctly
    const top = document.getElementById("sectionContent")?.getBoundingClientRect().top || 0;

    return (
        <div
            id="sectionContent"
            className={contentClass}
            style={{
                ...contentStyle,
                ...(!sidebarOpen || currentMenuConfig?.hideSidebar || !isProjectLogged
                    ? {
                          width: "100%",
                          left: 0,
                      }
                    : null),
                height: `calc( 100vh - ${top}px )`,
            }}
        >
            {finalRoutes?.length ? (
                <Routes>
                    {finalRoutes?.map((route) => (
                        <Route key={route?.path} {...route} />
                    ))}
                </Routes>
            ) : null}
        </div>
    );
};

const Reload = () => {
    useEffect(() => {
        window.location.reload();
    }, []);
    return null;
};

const defaultRedirect = ({ route, routes }) => {
    if (typeof route?.redirect === "function") {
        return route?.redirect({ route, routes });
    }
    if (route?.redirect) {
        if (routes?.length && routes?.map((r) => r.path).includes(route?.redirect)) {
            // Wanted redirect is available
            return route?.redirect;
        }
        // Redirect to the first available route in base of the current path
        const found = getFirstRoute(routes, route.path);
        if (found) {
            return found;
        }
    }
    return null;
};

const getFirstRoute = (routes, basePath) => {
    const found = basePath
        ? routes?.find((r) => r.path !== basePath && String(r.path).startsWith(basePath) && !r.path.includes(":"))
        : routes?.[0];
    if (found) {
        return String(found.path).replace("/*", "");
    }
    if (basePath?.includes("/")) {
        const newPath = basePath.split("/").slice(0, -1).join("/");
        return getFirstRoute(routes, newPath);
    }
    return null;
};

export default Section;
