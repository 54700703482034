const GetFilterFormattedDate = (dateFilter) => {
    let res = "";
    if (dateFilter && dateFilter !== "" && dateFilter !== false) {
        const year = dateFilter.getFullYear().toString();
        const tempMonth = (dateFilter.getMonth() + 1).toString();
        const tempDay = dateFilter.getDate().toString();
        let month = ``;
        let day = ``;
        if (tempMonth.length < 2) {
            month += `0`;
        }
        month += `${tempMonth}`;

        if (tempDay.length < 2) {
            day += `0`;
        }
        day += `${tempDay}`;
        res = `${year}-${month}-${day}`;
    }
    return res;
};

export default GetFilterFormattedDate;
