import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Icon from "components/Icon";
import Button from "components/Button";
import useScheduleSequence from "components/Section/MediaLibrary/modals/useScheduleSequence";
import moment from "moment";
import { getDaysNamesByValues, getTimesRangesFormatted, isSchedulling } from "./utils";
import classNames from "classnames";
import { toast } from "react-toastify";

const ScheduleCard = ({ data, loading, getData }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { open: openModalScheduleSequence } = useScheduleSequence();

    const {
        scheduleDaysOfWeek = [],
        scheduleDailyType,
        scheduleDaysType,
        scheduleEnd,
        scheduleStart,
        timeRanges,
        isDefault,
    } = data?.[0] || {};

    return (
        <div className=" w-full bg-white  p-4 rounded">
            <div className=" flex justify-between items-center mb-5">
                <div className=" font-bold text-2xl">{t("schedule-secuence")}</div>
                <div className=" ">
                    <Button
                        disabled={loading || isDefault}
                        id="signage-details-open-modal-schedule-sequence"
                        onClick={() => {
                            openModalScheduleSequence({
                                data: {
                                    scheduleDaysOfWeek,
                                    scheduleDailyType,
                                    scheduleDaysType,
                                    scheduleEnd,
                                    scheduleStart,
                                    id,
                                    timeRanges,
                                },
                                onCompleted: () => {
                                    getData();
                                    toast.success(t("operation-successful"));
                                },
                            });
                        }}
                    >
                        <Icon className={classNames({ "text-gray-700": isDefault })} size={1.2} type="edit" />
                    </Button>
                </div>
            </div>
            <div>
                <div className={classNames({ "text-gray-700": isDefault })}>
                    {t("set-up-the-sequence-schedule-to-be-displayed-in-the-tv-interface")}
                </div>
                {isSchedulling(data?.[0]) ? (
                    <>
                        <div className=" inline-flex mb-5">
                            {scheduleStart ? (
                                <div>
                                    <strong className=" mr-1">{t("start-date")}:</strong>
                                    {moment(scheduleStart).format("MM/DD/YY")}
                                </div>
                            ) : null}
                            {scheduleEnd ? (
                                <div className=" ml-8">
                                    <strong className=" mr-1">{t("end-date")}:</strong>
                                    {moment(scheduleEnd).format("MM/DD/YY")}
                                </div>
                            ) : null}
                        </div>
                        {scheduleDaysType ? (
                            <div className=" mb-5">
                                <strong className=" mr-1">{t("sequence-display")}:</strong>
                                {scheduleDaysType === "ALL_DAYS"
                                    ? t("all-days-of-the-week")
                                    : getDaysNamesByValues(scheduleDaysOfWeek)}
                            </div>
                        ) : null}
                        {scheduleDailyType ? (
                            <div>
                                <strong className=" mr-1">{t("time-range")}:</strong>
                                {scheduleDailyType === "TIME_RANGE"
                                    ? getTimesRangesFormatted(timeRanges || [])
                                    : t("all-day")}
                            </div>
                        ) : null}
                    </>
                ) : (
                    <div className=" text-gray-700 pb-10 mt-4">{t("no-scheduling-yet")}</div>
                )}
            </div>
        </div>
    );
};

export default ScheduleCard;
