import React, { useContext } from "react";

import TextToolbar from "./TextToolbar";
import IframeToolbar from "./IframeToolbar";
import QRToolbar from "./QRToolbar";
import HTMLToolbar from "./HTMLToolbar";
import VideoToolbar from "./VideoToolbar";
import ImageToolbar from "./ImageToolbar";
import LogoToolbar from "./LogoToolbar";
import WeatherToolbar from "./WeatherToolbar";
import ContainerToolbar from "./ContainerToolbar";
import CarouselToolbar from "./CarouselToolbar";
import IconToolbar from "./IconToolbar";
import TopBarToolbar from "./TopBarToolbar";
import RoomsToolbar from "./RoomsToolbar";
import ButtonToolbar from "./ButtonToolbar";
import MessagesToolbar from "./MessagesToolbar";
import ChatButtonToolbar from "./ChatButtonToolbar";
import { EditorContext, WidgetContext } from "../../../../../contexts/editor";
import LangSelectionToolbar from "./LangSelectionToolbar";
import CalendarToolbar from "./CalendarToolbar";
import ClockToolbar from "./ClockToolbar";
import TVMenuToolbar from "./TVMenuToolbar";
import AlarmToolbar from "./AlarmToolbar";
import WifiLoginToolbar from "./WifiLoginToolbar";
import TVMenuNavToolbar from "./TVMenuNavToolbar";
import SliderToolbar from "./SliderToolbar";

const GridItemToolbar = () => {
    const { type, id } = useContext(WidgetContext);
    const { itemSelected } = useContext(EditorContext);
    return (
        <div
            id={`toolbar_${id}`}
            onClick={(e) => e.stopPropagation()}
            onDoubleClick={(e) => e.stopPropagation()}
            className={`${itemSelected === id ? "" : "hidden"} widgetToolbarContainer invisible`}
        >
            <div className="flex">
                {type === "TOPBAR" ? (
                    <TopBarToolbar />
                ) : type === "ROOMS" ? (
                    <RoomsToolbar />
                ) : type === "BUTTON" ? (
                    <ButtonToolbar />
                ) : type === "MESSAGES" ? (
                    <MessagesToolbar />
                ) : type === "CHATBUTTON" ? (
                    <ChatButtonToolbar />
                ) : type === "TEXT" ? (
                    <TextToolbar />
                ) : type === "LOGO" ? (
                    <LogoToolbar />
                ) : type === "IMAGE" ? (
                    <ImageToolbar />
                ) : type === "IFRAME" ? (
                    <IframeToolbar />
                ) : type === "HTML" ? (
                    <HTMLToolbar />
                ) : type === "VIDEO" ? (
                    <VideoToolbar />
                ) : type === "QR" ? (
                    <QRToolbar />
                ) : type === "WEATHER" ? (
                    <WeatherToolbar />
                ) : type === "CONTAINER" ? (
                    <ContainerToolbar />
                ) : type === "CAROUSEL" ? (
                    <CarouselToolbar />
                ) : type === "ICON" ? (
                    <IconToolbar />
                ) : type === "LANGSELECTION" ? (
                    <LangSelectionToolbar />
                ) : type === "CALENDAR" ? (
                    <CalendarToolbar />
                ) : type === "CLOCK" ? (
                    <ClockToolbar />
                ) : type === "TVMENU" ? (
                    <TVMenuToolbar />
                ) : type === "ALARM" ? (
                    <AlarmToolbar />
                ) : type === "WIFILOGIN" ? (
                    <WifiLoginToolbar />
                ) : type === "TVMENUNAV" ? (
                    <TVMenuNavToolbar />
                ) : type === "SLIDER" ? (
                    <SliderToolbar />
                ) : null}
            </div>
        </div>
    );
};

export default GridItemToolbar;
