const AddScreenFromTemplate = (id, screenType, types, t, isLanding) => {
    // const optionData = [];
    // const TV_TYPES_TEXT = { TV: "tv-home", WELCOME: "welcome-sequences" };

    // if (types) {
    //     types.map((type) =>
    //         optionData.push({
    //             id: type.type,
    //             name: t("{{capitalize, capitalize}}", {
    //                 capitalize: t(screenType === SCREEN.TYPE.TV ? TV_TYPES_TEXT[type.type] : type.type),
    //             }),
    //         })
    //     );
    // }
    //Returns dele schedule modal content
    return {
        id: id,
        title: `add-new-${(screenType + "").toLowerCase()}-screen`,
        bodyAdjust: "w-9/12 max-h-80 min-h-30  overflow-y-scroll",
        buttonsStyle: " right-0 bottom-0 p-8 ",
        executeSectionQuery: true,
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "select-infinitive",
                style: "blue",
                action: "create-content",
            },
        ],
        inputs: [
            // screenType === SCREEN.TYPE.CAST || screenType === SCREEN.TYPE.TV
            //     ? {
            //           name: "content-type",
            //           type: "selectMultipleWithSearch",
            //           selectPlaceHolder: "select-screen-type",
            //           optionData: optionData,
            //           oneSelected: true,
            //           noSelect: true,
            //           noSearch: true,
            //           cStyle: "w-4/12",
            //       }
            //     : {},
            {
                style: "py-5 grid",
                type: "templates",
            },
            {
                landingType: isLanding ? screenType : null,
                type: "data",
            },
        ],
    };
};

export default AddScreenFromTemplate;
