import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { changeActionValues } from "../../actions/globalActions";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../hooks/Utils/Utils";
import ReactTooltip from "components/ReactTooltip";

const UseModalRadio = ({ value, currentSelected, name, currentValue, tooltip, label, titleModal }) => {
    const { t } = useTranslation();
    //Store data
    const { langStrings } = useSelector((state) => state.ui);

    //States
    const [currentStyle, setCurrentStyle] = useState({
        background: "bg-gray-200 hover:bg-gray-300",
        mark: "hidden",
    });

    //Actions
    const dispatch = useDispatch();

    const handleChange = (e) => {
        dispatch(changeActionValues({ [name]: e.target.value }));
    };

    //Listeners
    useEffect(() => {
        if (currentSelected === value) {
            setCurrentStyle({
                background: "bg-blue-300",
                mark: "block",
            });
        } else {
            setCurrentStyle({
                background: "bg-gray-200 hover:bg-gray-300",
                mark: "hidden",
            });
        }
        // eslint-disable-next-line
    }, [currentSelected]);

    useEffect(() => {
        if (value.includes("each-custom")) {
            dispatch(changeActionValues({ [name]: currentValue }));
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className="flex items-center cursor-pointer">
            <label className="radio-container flex items-center cursor-pointer relative  py-2">
                <input
                    id={`${titleModal}-${name}-${value}-${"radio"}`}
                    style={{ marginTop: "0.05rem" }}
                    type="radio"
                    name={name}
                    value={value}
                    defaultChecked={currentSelected === value ? true : false}
                    checked={currentStyle.mark === "block" ? true : false}
                    required
                />
                <div className="ml-10 first-capital flex">
                    {label ? label : langStrings[value] ? capitalizeFirst(langStrings[value]) : value}
                    {tooltip && (
                        <>
                            <span
                                className="icon icon-info ml-2 text-xl lg:text-2xl"
                                data-tip
                                data-for={`radio${name}Tooltip`}
                            ></span>
                            <ReactTooltip id={`radio${name}Tooltip`}>
                                {t("{{capitalize, capitalize}}", { capitalize: t(tooltip) })}
                            </ReactTooltip>
                        </>
                    )}
                </div>
            </label>
            {value.includes("each-custom") ? (
                <>
                    <input
                        id="each-custom-input"
                        name={name}
                        onChange={handleChange}
                        data-value={value}
                        type="number"
                        min="0"
                        placeholder={currentSelected === value ? currentValue : null}
                        className="t-filter-input ml-4 p-2 w-20 mr-3"
                    ></input>
                    <p className="inline">{langStrings["minutes"]}</p>
                </>
            ) : null}
        </div>
    );
};

export default UseModalRadio;
