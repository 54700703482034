import React, { useContext, Children } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { EditorContext, WidgetContext } from "../../contexts/editor";
import { setExecuteQuery } from "../../actions/sectionActions";
import Widget from "../../components/Section/Design/Widgets/Widget";
import InputColor from "../../components/Section/Design/Widgets/WidgetToolbars/CommonOptions/InputColor";

const UseSettingsGroup = ({ type, toolbarID, children, title, defaults, adjust }) => {
    const { theme } = useContext(EditorContext);
    const { config, type: widgetType, id: widgetID } = useContext(WidgetContext);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const sectionName = `toolbar-${type}`;

    const { gridItems } = useSelector((state) => state.design);
    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const style = widget ? widget.widgetStyle : null;

    const implementNewStyles = Widget.ImplementNewLibrary(widgetType);

    let defaultText = "";
    switch (type) {
        case "colors":
            if (!title) title = t("colors");
            if (defaults === undefined) defaults = true;
            if (widgetType === "TVMENUNAV") {
                defaultText = t("Use menu colors");
            } else {
                defaultText = t("Use appearance colors");
            }
            break;
        default:
            defaultText = t("Use appearance");
    }

    let customized = false;
    Children.toArray(children).map((ch) => {
        if (React.isValidElement(ch) && ch.type === InputColor) {
            if (!customized) {
                const id = ch.props.id;
                customized = implementNewStyles ? config.style.isCustomizedById(id) : style && style[id];
            }
        }
        return ch;
    });

    const restoreDefaults = () => {
        let resetValues = { ...style };
        Children.toArray(children).map((ch) => {
            if (React.isValidElement(ch) && ch.type === InputColor) {
                const id = ch.props.id;
                if (implementNewStyles) {
                    resetValues = Widget.SetPropValue(resetValues, id, null, null);
                } else {
                    resetValues[id] = null;
                }
            }
            return ch;
        });
        dispatch(setExecuteQuery(Widget.UpdateStyle({ widget, value: resetValues })));
    };

    return (
        <>
            {title ? (
                <div className="first-capital font-bold px-8 pt-8 pb-2 border-t border-gray-300">
                    {title}
                    {theme && defaults && customized ? (
                        <span
                            id={`${sectionName}-restore-defaults`}
                            onClick={restoreDefaults}
                            className="float-right cursor-pointer font-normal text-zafiro-400"
                        >
                            {defaultText}
                        </span>
                    ) : null}
                </div>
            ) : null}
            <div className={`table w-full break-normal ${adjust ? adjust : "px-8 pt-4 pb-8"} `}>
                {Children.toArray(children).map((ch) => {
                    const isReactElement = ch && typeof ch.type === "function";
                    return React.cloneElement(
                        ch,
                        !isReactElement
                            ? ch.props
                            : {
                                  toolbarID: toolbarID,
                                  ...ch.props,
                              }
                    );
                })}
            </div>
        </>
    );
};

export default UseSettingsGroup;
