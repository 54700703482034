import React from "react";
import { ReservationDetailsProvider } from "./ReservationDetailsContext";
import ReservationDetails from "./ReservationDetails";

const ReservationDetailsContainer = () => {
    return (
        <ReservationDetailsProvider>
            <ReservationDetails />
        </ReservationDetailsProvider>
    );
};

export default ReservationDetailsContainer;
