import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import WizardSteps from "./Wizard/WizardSteps";
import WizardStepContent from "./Wizard/WizardStepContent";
import WizardNav from "./Wizard/WizardNav";
import UseScheduleHeader from "./../components/Schedule/useScheduleHeader";
//Actions
import { setWizardStep } from "../actions/sectionActions";

const UseWizard = () => {
    //Store data
    const { currentStep, stepsData } = useSelector((state) => state.sectionContent);
    //Actions
    const dispatch = useDispatch();
    //Listeners
    useEffect(() => {
        dispatch(setWizardStep(1));
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {currentStep ? (
                <div className="bg-transparent">
                    <WizardSteps />
                    <div className="schedule-existing-row px-8 bg-transparent h-5 my-4">
                        <div className="w-full grid grid-flow-col grid-cols-12 gap-2">
                            {stepsData[currentStep - 1].headers.map((header, index) => (
                                <UseScheduleHeader header={header} key={index} />
                            ))}
                        </div>
                    </div>
                    <WizardStepContent />
                    <WizardNav />
                </div>
            ) : null}
        </>
    );
};

export default UseWizard;
