import { useEffect } from "react";

import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { Session } from "./Session";

//TODO mover todo esto a otro sitio

const Permissions = ({ token }) => {
    const setPolicyToAccepted = Session.getSessionProp("setPolicyToAccepted");

    const POLICY_MUTATION = gql`
        mutation {
            updateUser(
                ref: "${Session.getUser()?.ref}"
                TOSAccepted: true,
                TOSAcceptedTime: "${new window.ZDate().toString()}"
            ) {
                error
                id
                ok
            }
        }
    `;
    const [setPolicyAccepted, policyData] = useMutation(POLICY_MUTATION);

    useEffect(() => {
        if (token && setPolicyToAccepted) {
            setPolicyAccepted();
        }
    }, []);

    useEffect(() => {
        if (policyData.data && policyData.data.updateUser.ok) {
            Session.removeTOSAnswer();
        }
    }, [policyData.data]);

    return null;
};

export default Permissions;
