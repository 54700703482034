import React from "react";
import Parser from "hooks/Utils/Parser";
import { useTranslation } from "react-i18next";

const RdwReviewTranslations = () => {
    const { t } = useTranslation();

    return (
        <div className="rdw-block-wrapper rdw-review-translations mb-6 ml-2 mr-2" aria-label="rdw-block-control">
            {Parser(t("rdw-review-translations"))}
        </div>
    );
};

export default RdwReviewTranslations;
