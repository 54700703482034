import React from "react";
import UseIconMultipleIconCell from "./useIconMultipleIconCell";

const UseIconMultipleCell = ({ cellData }) => {
    const { hidden, link, values, iconName } = cellData || {};

    return (
        <>
            {!hidden ? (
                <div className="flex justify-start">
                    {values.map((value, index) => (
                        <UseIconMultipleIconCell
                            link={link}
                            item={value}
                            classNames={`${iconName} text-xl mr-2 inline`}
                            key={index}
                        />
                    ))}
                </div>
            ) : null}
        </>
    );
};

export default UseIconMultipleCell;
