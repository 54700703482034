const Transcode = ({ ref, name, secondName, projectLangs, quality, actionName, transcode, hiddeProjectLang }) => {
    const extraInputs = [];
    if (!hiddeProjectLang) {
        extraInputs.push({
            text: ["*", "select-lang-video-details"],
            adjust: "first-capital text-gray-700",
            type: "void",
        });
    }
    let modalInputs = [
        {
            text: hiddeProjectLang ? "" : ["select-lang-video", "*"],
            name: "lang",
            type: "selectMultipleWithSearch",
            sort: true,
            optionData: projectLangs,
            oneSelected: true,
            noSelect: true,
            noSearch: true,
            forceSelection: true,
            selectPlaceHolder: "select-lang",
            cStyle: hiddeProjectLang ? "hidden" : "",
        },
        ...extraInputs,
        {
            name: "quality",
            type: "radio",
            text: "what-qualities",
            translatable: ["text"],
            values: ["4k", "fullhd", "hd"],
            selected: quality ? quality : "fullhd",
        },
        {
            text: "",
            name: "transcode",
            value: transcode,
            type: "text",
            cStyle: "hidden",
        },
    ];
    let modal = {
        title: [name, "-", "transcode-file", secondName ? " - " + secondName : ""],
        value: "",
        id: ref,
        inputs: modalInputs,
        useGlobalLoading: true,
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            { name: "save", style: "blue", action: actionName ? actionName : "action" },
        ],
    };
    return modal;
};

export default Transcode;
