import React from "react";
import { useSelector } from "react-redux";

const UseTotalResults = () => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const count = useSelector((state) => state.table.count);

    //Actions
    const calcTotalCount = () => {
        let res = ``;
        if (count > 0) {
            res += count;
        } else {
            res += langStrings["theres-not"];
        }
        res += ` ${langStrings["items"]}`;
        return res;
    };

    return (
        <>{count ? <div className="text-sm text-gray-800 whitespace-normal -mt-8">{calcTotalCount()}</div> : null}</>
    );
};

export default UseTotalResults;
