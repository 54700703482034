import React from "react";

//Components
import IconInfo from "./IconInfo";
import IconSettings from "./IconSettings";

const IconsBar = () => {
    return (
        <div className="flex text-gray-700">
            <IconInfo />
            <IconSettings />
        </div>
    );
};

export default IconsBar;
