import React, { useEffect } from "react";

//Components
import UseSectionHeader from "../../../useSectionHeader";
import UseTable from "../../../Table/";
import ListInputGroups from "../../../../hooks/GraphqlCalls/Hotel/Settings/ListInputGroups";
import UpdateTvInputs from "../../../../hooks/GraphqlCalls/Hotel/UpdateTvInputs";
import { Session } from "../../../../hooks/Utils/Session";
import { useDispatch } from "react-redux";
import { closeModal, setModalContent } from "../../../../actions/uiActions";

const InputGroups = () => {
    ListInputGroups();
    const dispatch = useDispatch();

    useEffect(() => {
        Session.removeSessionProp("actionDataToRestore");
        dispatch(closeModal());
        dispatch(setModalContent(false));

        return () => {
            Session.removeSessionProp("actionDataToRestore");
        };
    }, []);

    return (
        <>
            <UpdateTvInputs />
            <UseSectionHeader title={["manage-inputs-groups"]} breadcrumbsLimit={3} />
            <UseTable />
        </>
    );
};

export default InputGroups;
