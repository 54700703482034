const AppearanceAddLogo = (title, subTitle, edit, cancelAction) => {
    return {
        title: title,
        bodyAdjust: "w-10/12",
        executeSectionQuery: true,
        twoColumns: true,
        inputs: [
            {
                text: subTitle,
                name: "name",
                type: "void",
                style: "col-span-2",
                cStyle: "w-full",
            },
            {
                name: "logo-asset",
                value: "",
                type: "logo",
                accept: "image/*",
                replace: false,
                style: "col-span-2",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close", actionCallback: () => cancelAction() },
            {
                name: edit ? "save" : "add",
                style: "blue",
                action: edit ? "appearance-edit-logo" : "appearance-add-logo",
            },
        ],
    };
};

export default AppearanceAddLogo;
