import React from "react";

import UpdateGeneralSettings from "../../../../hooks/GraphqlCalls/Sales/UpdateGeneralSettings";
import UseCard from "../../../useCard";

import UseSectionHeader from "../../../useSectionHeader";

import { useAuth } from "hooks/Session/auth";

const GeneralSettings = () => {
    const { isCorporate, hasChainModule } = useAuth();

    const breadcrumbs = [
        {
            name: "services",
            translate: true,
            route: "/services/",
        },
        {
            name: "sales",
            translate: true,
            route: "/services/",
        },
        {
            id: "sales-general-settings",
            name: "general-settings",
            translate: true,
            route: "/services/sales/general-settings",
        },
    ];
    return (
        <>
            <UseSectionHeader title={["general-settings"]} customBreadCrumbs={breadcrumbs} />
            <UpdateGeneralSettings />
            <div className="flex w-full">
                {isCorporate ? (
                    <div className="flex w-full">
                        <div className="w-6/12">
                            <div className="w-full">
                                <UseCard type="sales-allergens-card" />
                            </div>
                        </div>
                    </div>
                ) : hasChainModule ? (
                    <div className="flex w-full">
                        <div className="w-6/12">
                            <div className="w-full mb-10 pr-2">
                                <UseCard type="sales-tax-card" />
                            </div>
                        </div>
                        <div className="w-6/12">
                            <div className="w-full pl-2">
                                <UseCard type={"automatic-messages"} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="w-6/12">
                            <div className="w-full mb-10 pr-2">
                                <UseCard type="sales-tax-card" />
                            </div>
                            <div className="w-full pr-2">
                                <UseCard type={"automatic-messages"} />
                            </div>
                        </div>
                        <div className="w-6/12">
                            <div className="w-full pl-2">
                                <UseCard type="sales-allergens-card" />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default GeneralSettings;
