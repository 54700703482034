export const Info = [
    "product",
    "option",
    "duration",
    "license-number",
    "contact-support-service",
    "phone",
    "email",
    "project-code",
    "support-until",
];
