import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import ReactInputMask from "react-input-mask";
import { parse } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { GlobalContext } from "contexts/Global";
import useComponentVisible from "components/useComponentVisible";
import { capitalizeFirst } from "hooks/Utils/Utils";
import DayPicker, { DateUtils } from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import { setActionValues } from "actions/globalActions";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { setModalContent } from "actions/uiActions";
import ReactTooltip from "components/ReactTooltip";
import { showGlobalLoading } from "actions/uiActions";

const formatDateToDisplay = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

export const UseModalDateRange = () => {
    //Data
    const { t } = useTranslation();
    const { lang } = useContext(GlobalContext);
    const modalContent = useSelector((state) => state.ui.modalContent);
    const { values } = useSelector((state) => state.action);
    const numberOfMonths = 1;
    const getInitialState = () => {
        return {
            from: undefined,
            to: undefined,
        };
    };
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const actualDate = new Date();
    let aMonthAgo = new Date();
    aMonthAgo.setMonth(actualDate.getMonth() - 1);
    //States
    const [rangeState, setRangeState] = useState(getInitialState());
    const { from, to } = rangeState;
    const modifiers = { start: from, end: to };
    const [selectActive, setSelectActive] = useState({
        icon: "",
        container: "",
        text: "",
    });
    const [disabledDates, setDisabledDates] = useState([]);
    const [hasConfiguration, setHasConfiguration] = useState(false);
    const parseDate = (date) => {
        const splitDate = date.toISOString().split("T");
        return splitDate[0];
    };
    const Query = gql`
        {
            getStayTravelerPartReport(endDate: "${parseDate(actualDate)}", startDate: "${parseDate(
        aMonthAgo
    )}", extendedTravelerPart: false) {
                results {
                    date
                }
            }
            preCheckInSettingsTravelersReport {
                guestData {
                    fields {
                        name
                        surname
                        gender
                        birthdate
                        email
                        nationality
                        phone
                        documentExpeditionDate
                        documentNumber
                        documentType
                    }
                    customFields
                }
                guestDataChildren {
                    fields {
                        name
                        surname
                        gender
                        birthdate
                        email
                        nationality
                        phone
                        documentExpeditionDate
                        documentNumber
                        documentType
                    }
                    customFields
                }
            }
        }
    `;
    const [getStayTravelerPartReport, { data, loading }] = useLazyQuery(Query, {
        fetchPolicy: "network-only",
        onCompleted() {
            dispatch(showGlobalLoading(false));
        },
        onError() {
            dispatch(showGlobalLoading(false));
            setHasConfiguration(false);
            setDisabledDates([]);
        },
    });

    //Actions
    const dispatch = useDispatch();

    const handleDayClick = (day, modifiers) => {
        if (!modifiers?.disabled) {
            const range = DateUtils.addDayToRange(day, rangeState);
            setRangeState(range);
        }
    };

    const handleCleanInput = (type) => {
        setRangeState((prevRange) => {
            return type === "start"
                ? {
                      from: prevRange?.to,
                      to: null,
                  }
                : {
                      from: prevRange?.from,
                      to: null,
                  };
        });
    };

    //Listeners
    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({
                icon: "",
                container: "",
                text: "",
            });
        } else {
            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-500",
            });
            const getDisabledElements = document.getElementsByClassName("DayPicker-Day--disabled");
            for (let i = 0; i < getDisabledElements.length; i++) {
                getDisabledElements[i].setAttribute("data-for", "file-not-found-tooltip");
                getDisabledElements[i].setAttribute("data-tip", "file-not-found-tooltip");
            }
            ReactTooltip.rebuild();
        }
    }, [isComponentVisible]);

    useEffect(() => {
        if (disabledDates.length > 0 && !loading && data && values["startDate"]) {
            dispatch(
                setModalContent({
                    ...modalContent,
                    buttons: [
                        { name: "cancel", style: "white", action: "close" },
                        {
                            action: "export-guest-registration-form",
                            disabled: false,
                            name: "export",
                            style: "blue",
                        },
                    ],
                })
            );
        }
        if (!values["startDate"]) {
            dispatch(
                setModalContent({
                    ...modalContent,
                    buttons: [
                        { name: "cancel", style: "white", action: "close" },
                        {
                            action: "export-guest-registration-form",
                            disabled: true,
                            name: "export",
                            style: "blue",
                        },
                    ],
                })
            );
        }
    }, [values]);

    useEffect(() => {
        dispatch(
            setActionValues({
                startDate: rangeState?.from ? getFormattedDate(new window.ZDate(rangeState.from)) : null,
                endDate: rangeState?.to
                    ? getFormattedDate(new window.ZDate(rangeState.to))
                    : rangeState?.from
                    ? getFormattedDate(new window.ZDate(rangeState.from))
                    : null,
            })
        );
    }, [rangeState]);

    useEffect(() => {
        getStayTravelerPartReport();
        dispatch(showGlobalLoading(true));
    }, []);

    useEffect(() => {
        if (!loading && data) {
            setDisabledDates(
                data?.getStayTravelerPartReport.results.map((val) => {
                    let date = new Date(`${val.date}T00:00:00`);
                    return date;
                })
            );
            if (
                Object.values(data?.preCheckInSettingsTravelersReport?.guestData?.fields ?? {}).some((item) => {
                    if (typeof item == "boolean") {
                        return item;
                    } else {
                        return false;
                    }
                }) ||
                data?.preCheckInSettingsTravelersReport?.guestData?.customFields?.length > 0 ||
                Object.values(data?.preCheckInSettingsTravelersReport?.guestDataChildren?.fields ?? {}).some((item) => {
                    if (typeof item == "boolean") {
                        return item;
                    } else {
                        return false;
                    }
                }) ||
                data?.preCheckInSettingsTravelersReport?.guestDataChildren?.customFields?.length > 0
            ) {
                setHasConfiguration(true);
            }
        }
    }, [data]);

    //Funtions

    function checkDisabledDay(day) {
        const check = disabledDates.reduce((acc, curr) => {
            if (
                curr.getDate() == day.getDate() &&
                curr.getMonth() == day.getMonth() &&
                curr.getFullYear() == day.getFullYear()
            ) {
                acc = false;
            }
            return acc;
        }, true);
        return check;
    }

    function getFormattedDate(date) {
        const year = date.getFullYear();
        const month = (1 + date.getMonth()).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");

        return year + "-" + month + "-" + day;
    }

    return (
        <div>
            <div className={`inline-block w-filter-checkbox`} ref={ref}>
                <div
                    className={`w-full h-10 bg-gray-200 rounded relative flex items-center disabled pr-12 ${selectActive.container}`}
                >
                    <ReactTooltip
                        id={`file-not-found-tooltip`}
                        type="light"
                        border={true}
                        borderColor="text-gray-200"
                        arrowColor="text-gray-200"
                        className="font-normal"
                        place="bottom"
                    >
                        <p>{t("file-not-found")}</p>
                    </ReactTooltip>
                    <div
                        id={`day-picker`}
                        className={`bg-transparent w-full pl-4 first-capital overflow-hidden ${
                            disabledDates.length > 0 ? selectActive.text : "disabled"
                        }`}
                        onClick={() => {
                            if (disabledDates.length > 0) {
                                setIsComponentVisible(!isComponentVisible ? true : false);
                            }
                        }}
                    >
                        {rangeState && rangeState.from ? (
                            `${new window.ZDate(rangeState.from)
                                .toLocaleDateString([], {
                                    month: "2-digit",
                                    day: "2-digit",
                                })
                                .padStart(2, "0")} - ${
                                rangeState.to
                                    ? new window.ZDate(rangeState.to)
                                          .toLocaleDateString([], {
                                              month: "2-digit",
                                              day: "2-digit",
                                          })
                                          .padStart(2, "0")
                                    : ""
                            }`
                        ) : (
                            <span>{t("select-date")}</span>
                        )}
                    </div>
                    <span
                        id={`day-picker-icon`}
                        className={`field-icon icon-chevron animated ${selectActive.icon}`}
                        onClick={() => {
                            if (disabledDates.length > 0) {
                                setIsComponentVisible(!isComponentVisible ? true : false);
                            }
                        }}
                    ></span>

                    {isComponentVisible ? (
                        <div className="absolute left-0 w-auto bg-white shadow-lg rounded border border-gray-200 px-1 pt-2 z-100 t-checkboxes-container filterdate">
                            <div className="flex flex-col mb-2 gap-2">
                                <div className="flex flex-row items-center justify-center gap-4">
                                    <span className="flex-1 text-center block font-bold">
                                        {capitalizeFirst(t("start_"))}
                                    </span>
                                    <DateInputComponent
                                        date={from}
                                        range={rangeState}
                                        type={"start"}
                                        onDateChange={(v) => {
                                            if (v["from"]) {
                                                handleDayClick(v["from"]);
                                            }
                                        }}
                                        removeButtonAction={() => {
                                            handleCleanInput("start");
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row items-center justify-center gap-4">
                                    <span className="flex-1 text-center block font-bold">
                                        {capitalizeFirst(t("end_"))}
                                    </span>
                                    <DateInputComponent
                                        date={to}
                                        range={rangeState}
                                        type={"end"}
                                        onDateChange={(v) => {
                                            if (v["to"]) {
                                                handleDayClick(v["to"]);
                                            }
                                        }}
                                        removeButtonAction={() => {
                                            handleCleanInput("end");
                                        }}
                                    />
                                </div>
                            </div>

                            <DayPicker
                                firstDayOfWeek={1}
                                className="Selectable"
                                numberOfMonths={numberOfMonths}
                                selectedDays={[from, { from, to }]}
                                modifiers={modifiers}
                                onDayClick={handleDayClick}
                                localeUtils={MomentLocaleUtils}
                                locale={lang}
                                disabledDays={checkDisabledDay}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            {(!hasConfiguration || disabledDates?.length == 0) && !loading && (
                <div className="flex felx-col gap-4 mt-2" style={{ width: "fit-content" }}>
                    <i className="icon icon-warning text-2xl warning"></i>
                    <div>
                        <p>
                            {t("no-data-is-set-yet")}
                            {disabledDates?.length == 0 ? (
                                <span>,{t("therefore-no-document-can-be-exported")}</span>
                            ) : (
                                "."
                            )}
                        </p>
                        <p>
                            {t("select-the-data-that-should-be-included")}
                            <strong>{t("configuration-guest-report-path")}</strong>
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

const DateInputComponent = ({ date, range, onDateChange, type, removeButtonAction }) => {
    const [inputValue, setInputValue] = useState(date ? formatDateToDisplay(date) : "");

    const { t } = useTranslation();

    const handleInputChange = (event) => {
        const newInputValue = event.target.value;
        setInputValue(newInputValue);
    };

    const handleInputBlur = () => {
        const parsedDate = parse(inputValue, "dd/MM/yyyy", new Date());
        if (isValidDate(parsedDate)) {
            if (type === "start") {
                onDateChange({ from: parsedDate }, "start");
            } else if (type === "end") {
                onDateChange({ to: parsedDate }, "end");
            }
            setInputValue(formatDateToDisplay(parsedDate));
        }
    };

    const handleRemoveClick = () => {
        if (removeButtonAction) {
            removeButtonAction();
        }
    };

    useEffect(() => {
        setInputValue(date ? formatDateToDisplay(date) : "");
    }, [date]);

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    return (
        <div
            id={`date-input-container-${type}`}
            className=" date-input-container overflow-hidden h-10 relative flex rounded bg-gray-100"
        >
            <i style={{ top: "0.6rem", left: "0.5rem" }} className=" absolute icon icon-booking "></i>
            {date ? (
                <button
                    id={`date-input-container-${type}-remove`}
                    onClick={handleRemoveClick}
                    style={{ top: "0.6rem", right: "0.5rem" }}
                    className=" absolute h-4 w-4 flex items-center focus:outline-none justify-center bg-zafiro-600 rounded-full"
                >
                    <i className="icon icon-remove text-white text-xxs "></i>
                </button>
            ) : null}
            <ReactInputMask
                id={`date-input-container-${type}-input`}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className=" pl-10 bg-gray-100 rounded w-full "
                mask={"99/99/9999"}
                value={inputValue}
                placeholder={t("date-abbreviation")}
            />
        </div>
    );
};
