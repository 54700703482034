import React from "react";
import { closeModal } from "actions/uiActions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "hooks/Utils/Utils";
import Button from "components/Button";

export const modal = (component) => {
    return {
        id: "1",
        useGlobalLoading: true,
        bodyAdjust: "w-4/12 m-0",
        executeSectionQuery: true,
        inputs: [
            {
                type: "component",
                component: component,
            },
        ],
    };
};

export const AreYouSure = ({ updateNetwork }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleClick = () => {
        updateNetwork();
        dispatch(closeModal());
    };

    return (
        <div className="">
            <h2 className="text-2xl font-bold">{capitalizeFirst(t("update"))}</h2>
            <p className="mt-3">{t("update-cast-and-user-data")}</p>
            <p className="">{t("to-validate-networks")}</p>
            <p className="text-gray-800 mt-12 mb-8">*{t("process-will-take-few-minutes")}</p>
            <div className="flex gap-6 justify-end">
                <Button
                    id="cancel-validate-network-button"
                    className="font-bold"
                    onClick={() => dispatch(closeModal())}
                >
                    {capitalizeFirst(t("cancel"))}
                </Button>
                <Button
                    id="update-validate-network-button"
                    className="btn-blue p-4 rounded first-capital"
                    onClick={() => handleClick()}
                >
                    {capitalizeFirst(t("update"))}
                </Button>
            </div>
        </div>
    );
};
