import React, { Fragment } from "react";

//Utils
import useComponentVisible from "../../../../useComponentVisible";
import { useTranslation } from "react-i18next";
import LogoAssetName from "../../../../../hooks/GraphqlCalls/Appearances/LogoAssetName";
import { useSelector, useDispatch } from "react-redux";
import { setSectionContent } from "../../../../../actions/sectionActions";
import { copyToClipboard } from "../../../../../hooks/Utils/Utils";
import UseComponentLoading from "../../../../useComponentLoading";

const LogoInfo = ({ imageRef }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    //States
    const { appearanceSelected } = useSelector((state) => state.sectionContent);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    LogoAssetName(imageRef, isComponentVisible);
    //Listeners

    return (
        <Fragment>
            <span
                ref={ref}
                className="icon-info opacity-100 z-100 cursor-pointer"
                id="logo-info-button"
                onClick={(e) => {
                    dispatch(
                        setSectionContent({
                            appearanceSelected: {
                                ...appearanceSelected,
                                assetName: null,
                            },
                        })
                    );
                    setIsComponentVisible(!isComponentVisible);
                }}
            ></span>
            <div
                className={`fixed top-auto bg-white text-gray-900 font-normal text-left border-gray-300 border shadow mt-2 p-3 text-base ${
                    !isComponentVisible ? "hidden" : "z-200"
                }`}
                id="image-name-container"
            >
                {imageRef.includes("asset") ? (
                    <>
                        <div className="font-bold" id="logo-type">
                            {t("image-from")} {t("library")}
                        </div>
                        <div className="my-2" id="logo-name">
                            {appearanceSelected?.assetName ? (
                                appearanceSelected.assetName
                            ) : (
                                <UseComponentLoading adjust="text-center" />
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="font-bold" id="logo-type">
                            {t("image-from")} {t("URL")}
                        </div>
                        <div className="max-w-xs overflow-hidden truncate my-2" id="logo-info-url">
                            {imageRef}
                        </div>
                        <div
                            className="py-3 sidebar-menu-link pl-0 cursor-pointer"
                            onClick={() => copyToClipboard(imageRef)}
                            id="logo-copy-to-clipboard-url"
                        >
                            <span className="icon-clone text-1xl mr-3"></span>
                            {t("{{capitalize, capitalize}}", { capitalize: t("copy") })} {t("URL")}
                        </div>
                        <div
                            className="py-3 sidebar-menu-link pl-0 cursor-pointer"
                            onClick={() => {
                                window.open(imageRef, "_blank");
                            }}
                            id="logo-go-to-link"
                        >
                            <span className="icon-external-link text-1xl mr-3"></span>
                            {t("{{capitalize, capitalize}}", { capitalize: t("go-to") })} {t("URL")}
                        </div>
                    </>
                )}
            </div>
        </Fragment>
    );
};

export default LogoInfo;
