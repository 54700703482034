import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLazyQuery, useMutation } from "react-apollo";
import { gql } from "apollo-boost";

import { parseTranslation, getProjectLangs } from "hooks/Utils/SalesUtils";
import { executeVendureQuery, useVendureAPI } from "hooks/Utils/Integrations/useVendure";
import { useAuth } from "hooks/Session/auth";

const queryShops = gql`
    query shops($token: String!) {
        shops(token: $token) {
            results {
                defaultLanguageCode
                nameTranslations {
                    lang
                    text
                }
                id
                destinationEmails
                destinationUsers
                pmsConfig {
                    id
                    description
                    enabled
                }
                customFields {
                    syncId
                }
            }
        }
    }
`;

const mutationUpdatePMSConfig = gql`
    mutation updateShop($id: Int64!, $pmsConfig: PMSConfigInput) {
        updateShop(id: $id, pmsConfig: $pmsConfig) {
            id
            error
            ok
        }
    }
`;

const formatFields = (input) => {
    if (!input) return null;
    return Object.entries(input)
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => `${key}: ${key === "currencyCode" ? value : JSON.stringify(value)}`)
        .join(", ");
};

const mutationUpdateShopConfig = (input) => {
    let fields = formatFields({
        ...input,
        customFields: undefined,
    });
    const customFields = input?.customFields ? `{ ${formatFields(input.customFields)} }` : null;
    if (fields && customFields) {
        fields += ",";
    }
    return `
    mutation {
        updateChannel(input: {${fields ? `${fields}` : ""} ${customFields ? `customFields: ${customFields}` : ""}}) {
            __typename
        }
    }
    `;
};

const mutationUpdateCategory = (input) => {
    if (!input?.id) {
        throw new Error("Category ID not provided");
    }
    return `
    mutation {
        updateCollection(
            input: {
                id: ${input.id}
                customFields: { 
                    image_url: "${input?.imageURL || ""}"
                }
            }
        ) {
            id
        }
    }
`;
};

const mutationUpdateShippingConfig = (input) => {
    const id = input?.id;
    const config = JSON.stringify(input?.shipping_config);
    return `
    mutation {
        updateChannel(input: {id: ${id},customFields:{ shipping_config: ${config}}}) {
            __typename
        }
    }
`;
};

const mutationUpdateGeolocationTranslations = (translations) => {
    if (!translations?.length) {
        return null;
    }
    return `
    mutation {
        updateGeolocationTranslations(input: [${translations
            .map((t) => {
                return `{languageCode: ${t?.languageCode}, message: "${t?.name?.replace(/"/g, '\\"')}"}`;
            })
            .join(",")}]) {
            languageCode
            name: message
        }
    }
`;
};

const mutationUpdatePickupLocation = (input) => {
    const id = input?.id;
    const available = input?.available;
    const order = input?.order;
    const price = input?.price;
    const translations = `[${
        input?.translations
            ? input.translations
                  .map((t) => `{languageCode: ${t.languageCode}, name: "${(t.name || "").replace(/"/g, '\\"')}"}`)
                  .join(",")
            : null
    }]`;
    const taxCategoryId = input?.taxCategory;
    return `
    mutation {
        updatePickupLocation(input: {id: ${id}, available: ${available}, order: ${order}, price: ${price}, translations: ${translations}, taxCategory: ${taxCategoryId}}) {
            id
        }
    }
`;
};

const mutationDeletePickupLocation = (id) => {
    return `
    mutation {
        deletePickupLocation(id: ${id}) {
            result
            message
        }
    }
`;
};

const mutationCreatePickupLocation = (input) => {
    const available = input?.available;
    const translations = `[${input?.translations
        ?.map((t) => `{languageCode: ${t.languageCode}, name: "${t.name}"}`)
        .join(",")}]`;
    const taxCategoryId = input?.taxCategory;
    const price = input?.price || 0;
    const order = input?.order;
    return `
    mutation {
        createPickupLocation(input: {available: ${available}, order: ${order}, price: ${price}, translations: ${translations}, taxCategory: ${taxCategoryId}}) {
            id
            available
            order
            price{
                withTax
                withoutTax
            }
            translations{
                name
                languageCode
            }
            taxCategory{
                id
                name
            }
        }
    }
`;
};

// TODO query taxRates in another query with vendure-token
const queryChannel = (id) => `query{
        channel: channel(id:${id}){
          currencyCode
          defaultTaxZone{
            id
            name
          }
          code
          token
          pricesIncludeTax
          geolocationTranslations {
            id
            languageCode
            name: message
          }
          pickupLocations {
            id
            available
            order
            price{
                withTax
                withoutTax
            }
            translations{
                name
                languageCode
            }
            taxCategory{
                id
                name
            }
          }
          customFields{
            cover_url
            sync_id
            price_field
            delivery_schedule
            is_active
            delayed_delivery_threshold
            default_tax{
              id
              name
              value
            }
            default_tax_category{
              id
              name
            }
            max_products_per_order
            is_order_cancellation_allowed
            allow_scheduled_delivery
            is_max_product_per_order_active
            shipping_config
            is_auto_accept_enabled
          }
		    }
        taxRates: taxRates{
          items{
            id
            name
            value
            enabled
            category{
                id
            }
            zone{
                id
                name
            }
          }
        }
	}`;

const useShopConfig = ({ id }) => {
    const { t } = useTranslation();
    const { languages: projectLangs } = useAuth();

    const { adminApi } = useVendureAPI();

    const [config, setConfig] = useState(null);
    const [token, setToken] = useState(null);
    const [channelCalled, setChannelCalled] = useState(false);
    const [channelData, setChannelData] = useState(null);
    const [loadingChannel, setLoadingChannel] = useState(false);
    const [channelError, setChannelError] = useState(null);

    const [loadShop, { data: shopData, loading: loadingShop, error: shopError }] = useLazyQuery(queryShops, {
        fetchPolicy: "network-only",
    });

    const loadChannel = () => {
        setChannelCalled(true);
        setChannelData(null);
        setToken(null);
        setLoadingChannel(id ? true : false);
        setChannelError(id ? null : "Channel ID not provided");

        if (id) {
            const response = executeVendureQuery(adminApi, { queryBody: queryChannel(id) });
            response
                .then((response) => {
                    if (response?.error) {
                        throw new Error(response.error);
                    }
                    if (!response?.data?.channel?.token) {
                        throw new Error("Channel not found");
                    }
                    setLoadingChannel(false);
                    addPickupLocationsTaxRates(response?.data?.channel);
                    setChannelData(response?.data);
                    setToken(response?.data?.channel?.token);
                })
                .catch((error) => {
                    setChannelError(error);
                    setLoadingChannel(false);
                });
        }
    };

    useEffect(() => {
        if (token) {
            loadShop({ variables: { token } });
        }
    }, [token]);

    useEffect(() => {
        if (shopData) {
            const cfg = shopData.shops?.results?.[0];
            if (cfg?.nameTranslations) {
                cfg.name = parseTranslation(cfg.nameTranslations, getProjectLangs(projectLangs, t)?.langDefault);
            }
            cfg.syncId = channelData?.channel?.customFields?.sync_id;
            setConfig({ ...cfg, ...channelData });
        }
    }, [shopData]);

    return {
        called: channelCalled,
        load: loadChannel,
        loading: loadingChannel || loadingShop,
        error: channelError || shopError,
        ready: channelCalled && !(loadingChannel || loadingShop) && !(channelError || shopError),
        data: config,
        refetch: loadChannel,
    };
};

const addPickupLocationsTaxRates = (channel) => {
    if (channel?.pickupLocations && channel?.taxRates) {
        channel.pickupLocations = channel.pickupLocations.map((loc) => {
            loc.taxRate = channel.taxRates.items.find((tax) => tax.id === loc.taxCategory.id);
            return loc;
        });
    }
};

export const usePMSConfigUpdate = () => {
    const [update, { data, loading, error }] = useMutation(mutationUpdatePMSConfig);
    return {
        update: (vars) => {
            return update({ variables: vars });
        },
        loading,
        error: error || data?.updateShop?.error,
        success: data?.updateShop?.ok,
        data: data?.updateShop,
    };
};

export const useGeolocationTranslationsUpdate = ({ token }) => {
    const { adminApi } = useVendureAPI();

    return (translations) => {
        return new Promise((resolve, reject) => {
            if (!translations?.length) {
                reject(new Error("No translations provided"));
                return;
            }
            const response = executeVendureQuery(
                adminApi,
                {
                    queryBody: mutationUpdateGeolocationTranslations(translations),
                },
                { "vendure-token": token }
            );
            response
                .then((response) => {
                    if (response?.error) {
                        throw new Error(response.error);
                    }
                    resolve(response?.data?.updateGeolocationTranslations);
                })
                .catch((error) => {
                    reject(error instanceof Error ? error : new Error(error));
                });
        });
    };
};

export const useCategoryUpdate = () => {
    const { adminApi } = useVendureAPI();
    return (input) => {
        return new Promise((resolve, reject) => {
            const response = executeVendureQuery(adminApi, {
                queryBody: mutationUpdateCategory(input),
            });
            response
                .then((response) => {
                    if (response?.error) {
                        throw new Error(response.error);
                    }
                    resolve(response?.data?.updateCollection);
                })
                .catch((error) => {
                    reject(error instanceof Error ? error : new Error(error));
                });
        });
    };
};

export const useConfigUpdate = () => {
    const { adminApi } = useVendureAPI();
    return (id, config) => {
        return new Promise((resolve, reject) => {
            if (config?.customFields?.delivery_schedule && Array.isArray(config?.customFields?.delivery_schedule)) {
                config.customFields.delivery_schedule = JSON.stringify(config.customFields.delivery_schedule);
            }
            const response = executeVendureQuery(adminApi, {
                queryBody: mutationUpdateShopConfig({
                    id: id,
                    ...config,
                }),
            });
            response
                .then((response) => {
                    if (response?.error) {
                        throw new Error(response.error);
                    }
                    resolve(response?.data?.updateChannel);
                })
                .catch((error) => {
                    reject(error instanceof Error ? error : new Error(error));
                });
        });
    };
};

export const useShippingConfigUpdate = () => {
    const { adminApi } = useVendureAPI();
    return (id, shippingConfig) => {
        return new Promise((resolve, reject) => {
            const response = executeVendureQuery(adminApi, {
                queryBody: mutationUpdateShippingConfig({
                    id: id,
                    shipping_config: JSON.stringify(shippingConfig),
                }),
            });
            response
                .then((response) => {
                    if (response?.error) {
                        throw new Error(response.error);
                    }
                    resolve(response?.data?.updateChannel);
                })
                .catch((error) => {
                    reject(error instanceof Error ? error : new Error(error));
                });
        });
    };
};

export const usePickupLocationsUpdate = ({ token }) => {
    const { adminApi } = useVendureAPI();
    return (input) => {
        return new Promise((resolve, reject) => {
            if (input?.length > 0) {
                const promises = input.map((loc) => {
                    return new Promise((resolve, reject) => {
                        const response = executeVendureQuery(
                            adminApi,
                            {
                                queryBody: mutationUpdatePickupLocation({
                                    id: loc.id,
                                    available: loc.available,
                                    order: loc.order,
                                    price: loc.price?.withoutTax,
                                    translations: loc.translations,
                                    taxCategory: loc.taxCategory?.id,
                                }),
                            },
                            { "vendure-token": token }
                        );
                        response
                            .then((response) => {
                                if (response?.error) {
                                    throw new Error(response.error);
                                }
                                resolve(response?.data?.updatePickupLocation);
                            })
                            .catch((error) => {
                                reject(error instanceof Error ? error : new Error(error));
                            });
                    });
                });
                Promise.all(promises)
                    .then((values) => {
                        resolve(values);
                    })
                    .catch((error) => {
                        reject(error instanceof Error ? error : new Error(error));
                    });
            } else {
                resolve([]);
            }
        });
    };
};

export const usePickupLocationsDelete = ({ token }) => {
    const { adminApi } = useVendureAPI();
    return (ids) => {
        return new Promise((resolve, reject) => {
            if (ids?.length > 0) {
                const promises = ids.map((id) => {
                    return new Promise((resolve, reject) => {
                        const response = executeVendureQuery(
                            adminApi,
                            {
                                queryBody: mutationDeletePickupLocation(id),
                            },
                            { "vendure-token": token }
                        );
                        response
                            .then((response) => {
                                if (response?.error) {
                                    throw new Error(response.error);
                                }
                                resolve(response?.data?.deletePickupLocation);
                            })
                            .catch((error) => {
                                reject(error instanceof Error ? error : new Error(error));
                            });
                    });
                });
                Promise.all(promises)
                    .then((values) => {
                        resolve(values);
                    })
                    .catch((error) => {
                        reject(error instanceof Error ? error : new Error(error));
                    });
            } else {
                resolve([]);
            }
        });
    };
};

export const usePickupLocationsCreate = ({ token }) => {
    const { adminApi } = useVendureAPI();
    return (input) => {
        return new Promise((resolve, reject) => {
            if (!token) {
                reject(new Error("Token not provided"));
                return;
            }
            if (input?.length > 0) {
                const promises = input.map((loc) => {
                    return new Promise((resolve, reject) => {
                        const response = executeVendureQuery(
                            adminApi,
                            {
                                queryBody: mutationCreatePickupLocation({
                                    available: loc.available,
                                    translations: loc.translations,
                                    taxCategory: loc.taxRate?.category?.id,
                                    price: loc.price?.withoutTax,
                                    order: loc.order,
                                }),
                            },
                            { "vendure-token": token }
                        );
                        response
                            .then((response) => {
                                if (response?.error) {
                                    throw new Error(response.error);
                                }
                                resolve(response?.data?.createPickupLocation);
                            })
                            .catch((error) => {
                                reject(error instanceof Error ? error : new Error(error));
                            });
                    });
                });
                Promise.all(promises)
                    .then((values) => {
                        resolve(values);
                    })
                    .catch((error) => {
                        reject(error instanceof Error ? error : new Error(error));
                    });
            } else {
                resolve([]);
            }
        });
    };
};

export default useShopConfig;
