import React from "react";
import { useCCsList } from "./hooks/useCCsList";
import Loading from "components/Loading";
import { useTranslation } from "react-i18next";

export const ShowCCsList = ({ query, text }) => {
    const { listData, loading } = useCCsList(query);
    const { t } = useTranslation();
    const ccList = listData?.getCCsLowWifiSignal || listData?.getCCsHighLatency || listData?.getCCsNeverDiscovered;

    return loading ? (
        <Loading />
    ) : (
        <>
            <p>
                {t(text, {
                    ccNumber: ccList?.length,
                })}
            </p>
            <ul className="mt-6">
                {ccList?.map((cc) => (
                    <li key={cc.ip} className="text-gray-700 mt-2">
                        - {t("ip")}: {cc.ip} ({cc.room_number} - {cc.location})
                    </li>
                ))}
            </ul>
        </>
    );
};
