import React, { useState, useEffect } from "react";
import useLangString from "../../lang/useLangStrings";

function UseLabel({ label, zoneStyles, translatable, adjust }) {
    const [styles, setStyles] = useState("");
    useEffect(() => {
        if (zoneStyles === "networkSettings") {
            setStyles("text-left text-sm text-gray-800 capitalize ");
        } else if (zoneStyles === "networkSettingsNoCapitalize") {
            setStyles("text-left text-sm text-gray-800 first-capital ");
        }
    }, [zoneStyles]);

    return <div className={`${styles} ${adjust}`}>{useLangString(label, translatable)}</div>;
}

export default UseLabel;
