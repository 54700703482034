const TranslateTextModal = (title, nameTID, langStrings, parentType) => {
    return [
        {
            id: nameTID,
            title: title,
            bodyAdjust: "pb-8",
            text: `${langStrings["enter-name-and-translations"]}:`,
            buttonsStyle: "float-right",
            inputs: [
                {
                    type: "translations",
                    textId: nameTID,
                    parentType: parentType,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "save",
                    style: "blue",
                    action: "save-translation",
                },
            ],
        },
    ];
};

export default TranslateTextModal;
