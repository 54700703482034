import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSectionContent } from "../../../../../actions/sectionActions";

const AppearanceSearcher = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { searchAppearance } = useSelector((state) => state.sectionContent);

    useEffect(() => {
        if (searchAppearance) {
            document.getElementById("appearence_search_input").value = searchAppearance;
        }
        // eslint-disable-next-line
    }, []);

    const setSearchAppearance = (e) => {
        e.preventDefault();
        if (
            (e.currentTarget.value && e.currentTarget.value.length > 1) ||
            e.currentTarget.value === "" ||
            e.currentTarget.value.length === 0
        ) {
            dispatch(setSectionContent({ searchAppearance: e.currentTarget.value }));
        }
    };

    return (
        <div className="">
            <div className="flex m-4">
                <input
                    id={`appearence_search_input`}
                    type="text"
                    className="t-filter-search w-full"
                    placeholder={`${t("search")}...`}
                    onChange={(e) => setSearchAppearance(e)}
                ></input>
                <span className="absolute icon-search  mt-5 right-0 mr-10 text-xl text-gray-700"></span>
            </div>
        </div>
    );
};

export default AppearanceSearcher;
