import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import UseRadio from "../useRadio";
import UseInputText from "../Table/useInputText";
import { capitalizeFirst } from "../../hooks/Utils/Utils";
import ReactTooltip from "components/ReactTooltip";
import { changeActionValues } from "../../actions/globalActions";

const UseModalProductAddExtra = ({ index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //Store
    const { data } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { validations, actionData } = useSelector((state) => state.action);
    const { langDefault } = data;
    const defaultLanguage = "en";
    const sectionName = `add-product-extra`;
    const inputs = [`${sectionName}-name`, `${sectionName}-type`];
    const typeOfExtrasOptions = [`single-item`, `group-items`];
    const typeOfSelectionOptions = [`single-unit`, `group-unit`];

    //States
    const [name, setName] = useState(null);
    const [typeOfExtra, setTypeOfExtra] = useState(typeOfExtrasOptions[0]);
    const [typeOfSelection, setTypeOfSelection] = useState(typeOfSelectionOptions[1]);

    //Listeners
    useEffect(() => {
        if (actionData) {
            setName(actionData[inputs[0]]);
            dispatch(changeActionValues({ [inputs[0]]: actionData[inputs[0]] }));
        }
        // eslint-disable-next-line
    }, [actionData]);

    useEffect(() => {
        let type = null;
        if (typeOfExtra && typeOfSelection) {
            switch (typeOfExtra) {
                case typeOfExtrasOptions[0]:
                    switch (typeOfSelection) {
                        case typeOfSelectionOptions[0]:
                            type = "normal";
                            break;
                        case typeOfSelectionOptions[1]:
                            type = "numeric";
                            break;
                        default:
                            break;
                    }
                    break;
                case typeOfExtrasOptions[1]:
                    switch (typeOfSelection) {
                        case typeOfSelectionOptions[0]:
                            type = "combobox";
                            break;
                        case typeOfSelectionOptions[1]:
                            type = "multicombobox";
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
        dispatch(changeActionValues({ [inputs[1]]: type }));
        // eslint-disable-next-line
    }, [typeOfExtra, typeOfSelection]);

    //Arrange&Functions

    //handle functions
    const handleTypeOfExtra = (value) => {
        setTypeOfExtra(value);
        setTypeOfSelection(typeOfSelectionOptions[0]);
    };

    const handleTypeOfSelection = (value) => {
        setTypeOfSelection(value);
    };

    //renders
    const renderTypeOfSelectionToolTip = (type) => {
        return (
            <div className="flex items-center w-full justify-between">
                {type === typeOfSelectionOptions[0] && (
                    <span className="h-6 w-6 border rounded border-gray-600 mr-2"></span>
                )}
                <span className="mr-4 max-w-xs truncate">{name && name !== "" ? name : t("extra-name")}</span>
                {type === typeOfSelectionOptions[1] && (
                    <div className="flex items-center">
                        <span className="text-3xl">-</span>
                        <div className="h-8 w-8 flex items-center justify-center mx-1 border rounded-md border-gray-600">
                            <span className="text-base text-gray-500">01</span>
                        </div>
                        <span className="text-3xl">+</span>
                    </div>
                )}
            </div>
        );
    };

    const renderTypeOfSelections = () => {
        return (
            <div className="w-full pl-6 mt-3">
                <span className="font-bold block mb-3">{capitalizeFirst(t("type-of-selection"))}</span>
                <div className="w-full mb-3">
                    <UseRadio
                        value={typeOfSelectionOptions[1]}
                        selected={typeOfSelection}
                        handleClick={(value) => {
                            handleTypeOfSelection(value);
                        }}
                        id={`several-units-radio`}
                    />
                    <span className="ml-6" data-tip data-for={`${typeOfExtrasOptions[0]}Tooltip`}>
                        {t("several-units")}
                    </span>
                    <ReactTooltip
                        id={`${typeOfExtrasOptions[0]}Tooltip`}
                        type="light"
                        borderColor={"#D3DAE1"}
                        border={true}
                        multiline={true}
                        place="right"
                    >
                        {renderTypeOfSelectionToolTip(typeOfSelectionOptions[1])}
                    </ReactTooltip>
                </div>
                <div className="w-full">
                    <UseRadio
                        value={typeOfSelectionOptions[0]}
                        selected={typeOfSelection}
                        handleClick={(value) => {
                            handleTypeOfSelection(value);
                        }}
                        id={`single-unit-radio`}
                    />
                    <span className="ml-6" data-tip data-for={`${typeOfExtrasOptions[1]}Tooltip`}>
                        {t("single-unit")}
                    </span>
                    <ReactTooltip
                        id={`${typeOfExtrasOptions[1]}Tooltip`}
                        type="light"
                        borderColor={"#D3DAE1"}
                        border={true}
                        multiline={true}
                        place="right"
                    >
                        {renderTypeOfSelectionToolTip(typeOfSelectionOptions[0])}
                    </ReactTooltip>
                </div>
            </div>
        );
    };

    return (
        <div className="w-full">
            <div className="w-full mb-6">
                <span className="font-bold block">{capitalizeFirst(t("name"))} *</span>
                <div className="flex w-full items-center">
                    <div className="whitespace-no-wrap mr-3 ">
                        {`${t(`language:${langDefault ? langDefault : defaultLanguage}`)} (${t("default-language")})`}
                    </div>
                    <div className="w-full">
                        <UseInputText
                            inputData={{ name: inputs[0] }}
                            validation={validations && validations[inputs[0]] === false ? false : true}
                            currentValue={name}
                            ph={t("extra-name")}
                            id={inputs[0]}
                            key={inputs[0]}
                        />
                    </div>
                </div>
            </div>
            <div className="w-full mb-3">
                <span className="font-bold block mb-1">{capitalizeFirst(t("type-of-extra"))}</span>
                <span className="block mb-3">{capitalizeFirst(t("select-type-of-extra"))}:</span>
                <div className="w-full">
                    <UseRadio
                        value={typeOfExtrasOptions[0]}
                        selected={typeOfExtra}
                        handleClick={(value) => {
                            handleTypeOfExtra(value);
                        }}
                        id={`single-item-radio`}
                    />
                    <span className="ml-6">{t("single-item")}</span>
                </div>
                {typeOfExtra === typeOfExtrasOptions[0] && renderTypeOfSelections()}
                <div className="w-full mt-5">
                    <UseRadio
                        value={typeOfExtrasOptions[1]}
                        selected={typeOfExtra}
                        handleClick={(value) => {
                            handleTypeOfExtra(value);
                        }}
                        id={`group-items-radio`}
                    />
                    <span className="ml-6">{t("group-items")}</span>
                </div>
                {typeOfExtra === typeOfExtrasOptions[1] && renderTypeOfSelections()}
            </div>
        </div>
    );
};

export default UseModalProductAddExtra;
