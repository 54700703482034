import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLoadingModalContent } from "../../actions/uiActions";

import { gql } from "apollo-boost";

//Utils
import Parser from "hooks/Utils/Parser";
import { useMSQuery } from "../../hooks/GraphqlCalls/useMSQuery";
import { useTranslation } from "react-i18next";
import ReactTooltip from "components/ReactTooltip";

const UseModalHtmlText = ({ index }) => {
    //Store data
    const { t } = useTranslation();
    const { langStrings } = useSelector((state) => state.ui);
    const { text, cStyle, replacewords, validationShow, ref, parentRef, tooltip, noTranslate } = useSelector(
        (state) => state.ui.modalContent.inputs[index]
    );
    //States
    const [fullText, setFullText] = useState("");
    const [disaplay, setDisplay] = useState(true);

    //API
    const GET_SHOW_QUERY =
        validationShow && validationShow.query
            ? gql`
                  ${validationShow.query}
              `
            : null;
    const [getShowQuery, { data, networkStatus }] = useMSQuery(GET_SHOW_QUERY, {
        fetchPolicy: "network-only",
    });

    //Actions
    const dispatch = useDispatch();

    useEffect(() => {
        if (langStrings) {
            let fullText = getFullText(text, langStrings, replacewords);
            if (Array.isArray(text)) {
                fullText = text.map((label) => (langStrings[label] ? langStrings[label] : label)).join(" ");
            }
            setFullText(fullText);
        }
        if (noTranslate) {
            setFullText(text);
        }
        //get options from query
        if (validationShow && validationShow.query) {
            dispatch(setLoadingModalContent(true));
            getShowQuery();
        }
        // eslint-disable-next-line
    }, [langStrings, text]);

    useEffect(() => {
        if (networkStatus === 7 && data && data.data) {
            dispatch(setLoadingModalContent(false));
            if (!data.data.results || data.data.results.length < 1) {
                setDisplay(false);
            }
        }
        // eslint-disable-next-line
    }, [data, networkStatus]);

    return (
        disaplay && (
            <div id={parentRef}>
                <div className={cStyle} id={ref}>
                    {Parser(String(fullText))}
                    {tooltip && (
                        <>
                            <span
                                className="icon icon-info text-xl ml-2 lg:text-2xl"
                                data-tip
                                data-for={`htmlTextTooltip`}
                            ></span>
                            <ReactTooltip id={`htmlTextTooltip`}>
                                {t("{{capitalize, capitalize}}", { capitalize: t(tooltip) })}
                            </ReactTooltip>
                        </>
                    )}
                </div>
            </div>
        )
    );
};

const getFullText = (text, langStrings, replacewords) => {
    if (replacewords && Array.isArray(replacewords)) {
        let res = langStrings[text] ? langStrings[text] : text;
        // eslint-disable-next-line
        replacewords.forEach((word, index) => {
            res = res.replace(`%${index}`, word);
        });
        return res;
    } else if (replacewords && !Array.isArray(replacewords)) {
        return langStrings[text] ? [langStrings[text].replace("%0", replacewords)] : [text];
    }
    return langStrings[text] ? langStrings[text] : text;
};

export default UseModalHtmlText;
