import React, { useState } from "react";
import { useSelector } from "react-redux";
//Components
import UseListCell from "./cells/useListCell";
import UseSelectCell from "./cells/useSelectCell";
import UseTagCell from "./cells/useTagCell";
import UseTagTruncateCell from "./cells/useTagTruncateCell";
import UseTagCellCustomTooltip from "./cells/UseTagCellCustomTooltip";
import UseTextCell from "./cells/useTextCell";
import UseDateCell from "./cells/useDateCell";
import UseValueCell from "./cells/useValueCell";
import UseLinkCell from "./cells/useLinkCell";
import UseButtonFormLauncherCell from "./cells/useButtonFormLauncherCell";
import UseIconWithTextCell from "./cells/useIconWithTextCell";
import UseButtonCell from "./cells/useButtonCell";
import UseEditableModalCell from "./cells/useEditableModalCell";
import UseEditableFormCell from "./cells/useEditableFormCell";
import UseIconCell from "./cells/useIconCell";
import UseValueCellExtended from "./cells/useValueCellExtended";
import UseIconMultipleCell from "./cells/useIconMultipleCell";
import UseTagWithTooltipCell from "./cells/useTagWithTooltipCell";
import UseTextWithToolTipCell from "./cells/useTextWithTooltipCell";
import UseImageCell from "./cells/useImageCell";
import UseEditImage from "./cells/useEditImageCell";
import UseIconGuestCell from "./cells/useIconGuestCell";
import { UseBookingCell } from "./cells/useBookingCell";
import { UseUpcomingCheckInsStatus } from "./cells/useUpcomingCheckInsStatus";
import uuid from "react-uuid";
import UseValueCellInfoExteded from "./cells/UseValueCellInfoExteded";
import UseTextWithActionCell from "./cells/UseTextWithActionCell";
import UseEditPhoneNumber from "./cells/useEditPhoneNumber";
import UseSchedulingCell from "./cells/useSchedulingCell";

const UseCell = ({ expandHandler, cellData, setHideRow, rowIndex, colIndex, depth }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const divId = uuid();

    //Data
    const { specialWidth, popover, translatePopover, cellContent, cellAdjust, noWidthFullCell, componentStyle } =
        cellData || {};
    const popoverContent = getPopoverContent(popover, translatePopover, langStrings);

    //States
    const [popoverState, setPopoverState] = useState("hidden");
    const handleMouseOver = () => {
        setPopoverState("block");
    };
    const handleMouseOut = () => {
        setPopoverState("hidden");
    };
    let truncate = false;
    //Actions
    const loadProperCell = (row, column) => {
        let res;
        switch (cellContent) {
            case "text":
                truncate = true;
                res = <UseTextCell cellData={cellData} rowIndex={rowIndex} colIndex={colIndex} />;
                break;
            case "textWithTooltip":
                truncate = true;
                res = <UseTextWithToolTipCell cellData={cellData} />;
                break;
            case "date":
                res = <UseDateCell cellData={cellData} />;
                break;
            case "value":
                res = <UseValueCell cellData={cellData} />;
                break;
            case "list":
                res = <UseListCell cellData={{ ...cellData, row: row, column: column }} />;
                break;
            case "labels":
                res = <UseTagCell cellData={cellData} rowIndex={rowIndex} colIndex={colIndex} />;
                break;
            case "text-action-cell":
                res = <UseTextWithActionCell cellData={cellData} rowIndex={rowIndex} colIndex={colIndex} />;
                break;
            case "labelsTruncate":
                res = (
                    <UseTagTruncateCell
                        cellData={{
                            ...cellData,
                            rowIndex: rowIndex,
                            colIndex: colIndex,
                        }}
                        rowIndex={rowIndex}
                        colIndex={colIndex}
                    />
                );
                break;
            case "labelsCustomTooltip":
                res = <UseTagCellCustomTooltip cellData={cellData} />;
                break;
            case "link":
                res = <UseLinkCell cellData={cellData} rowIndex={rowIndex} colIndex={colIndex} />;
                break;
            case "btn-form-launcher":
                res = <UseButtonFormLauncherCell cellData={cellData} setHideRow={setHideRow} rowIndex={rowIndex} />;
                break;
            case "icon-with-text":
                truncate = true;
                res = <UseIconWithTextCell cellData={cellData} />;
                break;
            case "button":
                res = <UseButtonCell cellData={cellData} rowIndex={rowIndex} colIndex={colIndex} />;
                break;
            case "edit-modal":
                res = <UseEditableModalCell cellData={cellData} rowIndex={rowIndex} colIndex={colIndex} />;
                break;
            case "edit-form":
                res = <UseEditableFormCell cellData={cellData} setHideRow={setHideRow} rowIndex={rowIndex} />;
                break;
            case "select":
                res = <UseSelectCell cellData={cellData} setHideRow={setHideRow} />;
                break;
            case "icon":
                res = (
                    <UseIconCell cellData={cellData} setHideRow={setHideRow} rowIndex={rowIndex} colIndex={colIndex} />
                );
                break;
            case "value-extended":
                res = <UseValueCellExtended cellData={cellData} rowIndex={rowIndex} colIndex={colIndex} />;
                break;
            case "value-extended-info":
                res = <UseValueCellInfoExteded cellData={cellData} />;
                break;
            case "icon-multiple":
                res = <UseIconMultipleCell cellData={cellData} />;
                break;
            case "icon-guest":
                res = <UseIconGuestCell cellData={cellData} />;
                break;
            case "tagWithTooltip":
                res = <UseTagWithTooltipCell cellData={cellData} />;
                break;
            case "image":
                res = <UseImageCell cellData={cellData} rowIndex={rowIndex} colIndex={colIndex} depth={depth} />;
                break;
            case "booking":
                res = <UseBookingCell cellData={cellData} rowIndex={rowIndex} colIndex={colIndex} />;
                break;
            case "upcomingCheckInsStatus":
                res = <UseUpcomingCheckInsStatus cellData={cellData} rowIndex={rowIndex} colIndex={colIndex} />;
                break;
            case "editImage":
                res = <UseEditImage cellData={cellData} rowIndex={rowIndex} colIndex={colIndex} depth={depth} />;
                break;
            case "editPhoneNumber":
                res = (
                    <UseEditPhoneNumber
                        cellData={cellData}
                        rowIndex={rowIndex}
                        colIndex={colIndex}
                    ></UseEditPhoneNumber>
                );
                break;
            case "scheduling":
                res = <UseSchedulingCell cellData={cellData.value}></UseSchedulingCell>;
                break;
            default:
                res = ``;
        }
        return res;
    };

    const cellId = () => {
        if (rowIndex >= 0 && colIndex >= 0) {
            return `r${rowIndex}_c${colIndex}`;
        } else if (rowIndex >= 0) {
            return `cell-card-${rowIndex}`;
        } else {
            return null;
        }
    };

    return (
        <div
            onClick={expandHandler}
            id={cellId()}
            className={`t-cell mr-2 ${specialWidth} ${cellContent === "text" ? "overflow-hidden" : ""}`}
            style={typeof componentStyle === "object" ? componentStyle : {}}
        >
            {popover ? (
                <div className={`popover-container popover-cell-icon text-sm ${popoverState}`}>{popoverContent}</div>
            ) : null}
            <div
                id={divId}
                className={`${!noWidthFullCell ? "w-full" : ""} ${cellAdjust}`}
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleMouseOut}
            >
                {loadProperCell(rowIndex, colIndex)}
            </div>
        </div>
    );
};

/**
 * @param popover {string}
 * @param translatePopover {boolean}
 * @param langStrings {Record<string, string>}
 * @returns {string}
 */
export const getPopoverContent = (popover, translatePopover, langStrings) => {
    //Returns popover content
    let res;
    if (translatePopover) {
        res = `${langStrings[popover]}.`;
    } else {
        res = `${popover}.`;
    }
    return res;
};

export default UseCell;
