import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanSectionContent, setSectionContent } from "../../../../actions/sectionActions";
import { SALES_TOOLBAR } from "../../../../hooks/Utils/Design/SalesUtils";

const SalesToolbar = () => {
    const dispatch = useDispatch();
    const { salesToolbar } = useSelector((state) => state.sectionContent);

    const options = [SALES_TOOLBAR.SETTINGS, SALES_TOOLBAR.STYLES];

    const changeSelection = (option) => {
        dispatch(setSectionContent({ salesToolbar: option }));
    };

    useEffect(() => {
        if (!salesToolbar) {
            dispatch(setSectionContent({ salesToolbar: SALES_TOOLBAR.SETTINGS }));
        }
        return () => {
            dispatch(cleanSectionContent());
        };
        // eslint-disable-next-line
    }, []);

    const printIconOption = (option) => {
        return (
            <div
                id={`icon-option-${option}`}
                key={option}
                className={`icon-${option} rounded m-auto p-4 ${option === salesToolbar ? "bg-gray-200" : ""}`}
                onClick={() => {
                    changeSelection(option);
                }}
            ></div>
        );
    };

    return (
        <div
            id="salesToolbar"
            className="rounded shadow float-right inline-flex cursor-pointer bg-white p-1 text-2xl text-gray-800"
        >
            {options.map((option) => printIconOption(option))}
        </div>
    );
};

export default SalesToolbar;
