import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
//Components
import UseSelect from "../Schedule/useSelect";
import UseInputText from "../Schedule/useInputText";
import UseInputDate from "../Schedule/useInputDate";
import UseButton from "../useButton";
import UseSpecialSRangeRow from "./useSpecialSRangeRow";
import UseSpecialSSpecificRow from "./useSpecialSSpecificRow";
//Actions
import { cleanAction, cleanScheduleDates } from "../../actions/globalActions";
import { setWizardAllowNext } from "../../actions/sectionActions";
import {
    setScheduleSpecialRows,
    setDatesSpecialRows,
    addBatchPeriodicSchedule,
    addViewPeriodicSchedule,
    resetScheduleSpecialRows,
    setSpecialScheduleValue,
    setSpecialDateValue,
    resetDateSpecialRows,
} from "../../actions/scheduleActions";
//Utils
import {
    validateName,
    validateDateType,
    validateDates,
    validateSchedule,
    validateWeekdays,
    GetMutationDateType,
    GetMutationDateList,
    GetMutationSchedules,
    GetMutationWeekDays,
} from "../../hooks/Utils/ScheduleManagement";

//Notifications
import { toast } from "react-toastify";

const UseScheduleFormSpecial = ({ setAddSpecialSchedule }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    //ISO 8601 standard Monday(1) Sunday (7)
    const { itemName, dateType, specificRange, specificDates } = useSelector((state) => state.action);
    const { specialScheduleRows, specialRowScheduleContainer, specialDateRows, specialRowDateContainer } = useSelector(
        (state) => state.schedules
    );
    const { id } = useParams();

    //Validation States
    const [nameValidation, setNameValidation] = useState(true);
    const [dateTypeValidation, setDateTypeValidation] = useState(true);
    const [datesValidation, setDatesValidation] = useState(true);
    //Form states
    const [isFormVisible, setIsFormVisible] = useState(true);

    //Actions
    const dispatch = useDispatch();

    const handleClickCancel = () => {
        setAddSpecialSchedule(false);
        dispatch(cleanAction());
        dispatch(setWizardAllowNext(true));
        dispatch(resetScheduleSpecialRows());
        dispatch(resetDateSpecialRows());
    };

    const handleAddSchedulesClick = () => {
        //Schedule and Weeklist
        const scheduleHasErrors = validateSchedules();

        if (!scheduleHasErrors) {
            //Add new schedule form to existing one
            dispatch(setScheduleSpecialRows(specialScheduleRows + 1));
            dispatch(setWizardAllowNext(false));
        } else {
            toast.error(langStrings["check form"]);
        }
    };

    const handleAddDatesClick = () => {
        //Add new date form to existing one
        dispatch(setDatesSpecialRows(specialDateRows + 1));
        dispatch(setWizardAllowNext(false));
    };

    const handleClickSave = () => {
        //Validation
        //Name
        const nameValidated = validateName(itemName);
        setNameValidation(nameValidated);
        //DateType
        const dateTypeValidated = validateDateType(dateType);
        setDateTypeValidation(dateTypeValidated);
        //Dates
        const currentDate = specialRowDateContainer[specialRowDateContainer.length - 1];
        const datesValidated = validateDates(dateType, specificRange, currentDate.specificDays);
        setDatesValidation(datesValidated);

        //Schedule and Weeklist
        const scheduleHasErrors = validateSchedules();

        if (nameValidated && dateTypeValidated && datesValidated && !scheduleHasErrors) {
            //Data conversion to timetable
            const specialSchedule = {
                areaID: id,
                tempId: Math.random(),
                dateType: GetMutationDateType(dateType),
                isEnabled: true,
                name: itemName,
                periodicityType: "HABITUAL",
                type: "OPEN",
            };
            //Date Range case
            if (dateType === "date-range") {
                specialSchedule.timetableDates = [
                    {
                        dateList: GetMutationDateList(specificRange, null, dateType),
                        timetableDateRanges: [],
                    },
                ];
                // eslint-disable-next-line
                specialRowScheduleContainer.map((specialRowSchedule) => {
                    specialSchedule.timetableDates[0].timetableDateRanges.push({
                        timeRanges: GetMutationSchedules(specialRowSchedule),
                        weekdayList: GetMutationWeekDays(specialRowSchedule.weekdays),
                    });
                });
            } else {
                let timetableDates = [];
                // eslint-disable-next-line
                specialRowDateContainer.map((specialRowDate) => {
                    const timetableDate = {
                        dateList: GetMutationDateList(null, specialRowDate.specificDays, dateType),
                        timetableDateRanges: [
                            {
                                timeRanges: GetMutationSchedules(specialRowDate),
                                weekdayList: "[]",
                            },
                        ],
                    };
                    timetableDates.push(timetableDate);
                });
                specialSchedule.timetableDates = timetableDates;
            }
            //Update BatchToStore
            dispatch(addBatchPeriodicSchedule(specialSchedule));
            //Add to edit view
            dispatch(addViewPeriodicSchedule(specialSchedule));

            setIsFormVisible(false);
            setAddSpecialSchedule(false);
            dispatch(cleanScheduleDates());
            dispatch(resetScheduleSpecialRows());
            dispatch(setWizardAllowNext(true));
        } else {
            toast.error(langStrings["check form"]);
        }
    };

    //Listeners

    useEffect(() => {
        //Start with one schedule rows when form starts
        dispatch(setScheduleSpecialRows(1));
        dispatch(setDatesSpecialRows(1));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if ((dateType === "date-range" && specificRange) || (dateType !== "date-range" && specificDates)) {
            setDatesValidation(true);
        }
        // eslint-disable-next-line
    }, [specificDates, specificRange]);

    //Validation functions
    const validateSchedules = () => {
        let scheduleHasErrors = false;
        if (dateType === "date-range") {
            specialRowScheduleContainer.forEach((row, index) => {
                const valResult = validateSchedule(row);
                scheduleHasErrors =
                    !valResult.morningStart ||
                    !valResult.morningEnd ||
                    !valResult.afternoonStart ||
                    !valResult.afternoonEnd;

                //mark errors
                dispatch(
                    setSpecialScheduleValue({
                        valueKey: "MSvalidated",
                        value: valResult.morningStart,
                        index: index,
                    })
                );
                dispatch(
                    setSpecialScheduleValue({
                        valueKey: "MEvalidated",
                        value: valResult.morningEnd,
                        index: index,
                    })
                );
                dispatch(
                    setSpecialScheduleValue({
                        valueKey: "ASvalidated",
                        value: valResult.afternoonStart,
                        index: index,
                    })
                );
                dispatch(
                    setSpecialScheduleValue({
                        valueKey: "AEvalidated",
                        value: valResult.afternoonEnd,
                        index: index,
                    })
                );

                const wdValidation = validateWeekdays(row.weekdays);
                if (!scheduleHasErrors && !wdValidation) {
                    scheduleHasErrors = true;
                }

                dispatch(
                    setSpecialScheduleValue({
                        valueKey: "WDvalidated",
                        value: wdValidation,
                        index: index,
                    })
                );

                if (scheduleHasErrors) {
                    return;
                }
            });
        } else {
            specialRowDateContainer.forEach((row, index) => {
                const valResult = validateSchedule(row);
                scheduleHasErrors =
                    !valResult.morningStart ||
                    !valResult.morningEnd ||
                    !valResult.afternoonStart ||
                    !valResult.afternoonEnd;

                //mark errors
                dispatch(
                    setSpecialDateValue({
                        valueKey: "MSvalidated",
                        value: valResult.morningStart,
                        index: index,
                    })
                );
                dispatch(
                    setSpecialDateValue({
                        valueKey: "MEvalidated",
                        value: valResult.morningEnd,
                        index: index,
                    })
                );
                dispatch(
                    setSpecialDateValue({
                        valueKey: "ASvalidated",
                        value: valResult.afternoonStart,
                        index: index,
                    })
                );
                dispatch(
                    setSpecialDateValue({
                        valueKey: "AEvalidated",
                        value: valResult.afternoonEnd,
                        index: index,
                    })
                );

                if (scheduleHasErrors) {
                    return;
                }
            });
        }

        return scheduleHasErrors;
    };

    return (
        <>
            {isFormVisible ? (
                <div className="w-full block bg-white p-8">
                    <div className="w-full border-b border-gray-200 font-bold pb-4">
                        <div className="w-1/6 pr-4">
                            <UseInputText validation={nameValidation} />
                        </div>
                    </div>
                    <div className="pt-4 pb-8 flex items-start">
                        <div className="w-1/6 inline-block pr-4">
                            <UseSelect placeholder="date-type" validation={dateTypeValidation} />
                        </div>
                        {dateType && dateType === "date-range" ? (
                            <div className="w-2/6 inline-block pr-4">
                                <UseInputDate validation={datesValidation} />
                            </div>
                        ) : null}
                        {dateType && dateType === "date-specific" ? (
                            <>
                                <div className="w-5/6 block relative">
                                    <UseSpecialSSpecificRow datesValidation={datesValidation} />
                                    <div
                                        className="absolute ml-30 mt-4 text-blue-300 font-bold cursor-pointer hover:text-blue-200 z-0"
                                        onClick={handleAddDatesClick}
                                    >
                                        <span className="icon-add mr-2"></span>
                                        {langStrings["add-dates"]}
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                    {specificRange && specificRange.to ? (
                        <>
                            {dateType && dateType === "date-range" ? (
                                <>
                                    <UseSpecialSRangeRow />
                                    <div
                                        className="w-3/12 inline-block text-blue-300 font-bold cursor-pointer hover:text-blue-200"
                                        onClick={handleAddSchedulesClick}
                                    >
                                        <span className="icon-add mr-2"></span>
                                        {langStrings["add-schedules"]}
                                    </div>
                                </>
                            ) : null}
                        </>
                    ) : null}
                    <div className="w-full flex justify-end h-10 mt-8 mr-4 mb-4">
                        <div className="w-auto inline-block mr-4" onClick={handleClickCancel}>
                            <UseButton buttonName="cancel" buttonColor="btn-blue-outline" />
                        </div>
                        <div className="w-auto inline-block" onClick={handleClickSave}>
                            <UseButton buttonName="save" buttonColor="btn-blue" />
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default UseScheduleFormSpecial;
