import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import { useEffect, useState } from "react";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { toast } from "react-toastify";

import { gql } from "apollo-boost";
import { removeApolloCacheKeys, dateString, capitalizeFirst } from "../../Utils/Utils";
import { ALARM_RECIPIENTS_TYPE, ALARM_TYPE } from "../../Utils/Customers/AlarmUtils";
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { cleanAction } from "../../../actions/globalActions";
import { withApollo } from "@apollo/react-hoc";

const UpdateAlarm = ({ client }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { executeQuery } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);

    //State
    const [mutation, setMutation] = useState("mutation{checkIn}");

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        errorPolicy: "all",
        onError(err) {
            //this control is in this onError, because errorPolicy =all is not working like in queryes
            //Custom errors
            // let validations = {};
            const errorMsg = err.message;

            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(errorMsg);
        },
    });

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            executeQuery.alarmMutationName = "";
            switch (executeQuery.action) {
                case "edit-alarm":
                    execute = true;
                    setMutation(`
                        mutation{
                            updateAlarm(
                                groupingIds: ${
                                    actionData.itemsAffected.lenght === 1
                                        ? actionData.itemsAffected[0]
                                        : `[${actionData.itemsAffected.join(",")}]`
                                }                            
                                startTime: "${dateString(
                                    `${actionData.values["-date"]} ${
                                        actionData.values["-hour"] ? actionData.values["-hour"] : "00:00:00"
                                    }`
                                )}"
                                type: ${actionData.values[ALARM_TYPE.ID]}
                                ${
                                    actionData.values[ALARM_TYPE.ID] === ALARM_TYPE.TVCHANNEL
                                        ? `tvChannelId:${actionData.values[ALARM_TYPE.TVCHANNEL][0]}`
                                        : ""
                                }
                            ){ error ok }}
                    `);
                    executeQuery.alarmMutationName = "updateAlarm";
                    executeQuery.customToast = t("toast-alarm", { action: t("edited", { context: "female" }) });
                    removeApolloCacheKeys(client.cache, "alarm");
                    break;
                case "edit-room-alarm":
                    execute = true;
                    setMutation(`
                        mutation{
                            createAlarm(
                                    ${`stayGuestRoomId: ${actionData.itemsAffected[0]}`}
                                    startTime: "${dateString(
                                        `${actionData.values["-date"]} ${
                                            actionData.values["-hour"] ? actionData.values["-hour"] : "00:00:00"
                                        }`
                                    )}"
                                    type: ${actionData.values[ALARM_TYPE.ID]}
                                    ${
                                        actionData.values[ALARM_TYPE.ID] === ALARM_TYPE.TVCHANNEL
                                            ? `tvChannelId: ${actionData.values.TVCHANNEL[0]}`
                                            : ""
                                    }
                                ){ error ok }}
                    `);
                    executeQuery.alarmMutationName = "createAlarm";
                    executeQuery.customToast = t("toast-alarm", { action: t("edited", { context: "female" }) });
                    removeApolloCacheKeys(client.cache, "alarm");
                    break;
                case "new-alarm":
                    execute = true;
                    setMutation(`
                        mutation{
                            createAlarm(
                                    ${
                                        actionData.values[ALARM_RECIPIENTS_TYPE.ID] === ALARM_RECIPIENTS_TYPE.ROOM
                                            ? `stayGuestRoomId: ${actionData.values[ALARM_RECIPIENTS_TYPE.ROOM]}`
                                            : `stayGuestId: ${actionData.values[ALARM_RECIPIENTS_TYPE.GUEST]}`
                                    }
                                    startTime: "${dateString(
                                        `${actionData.values["-date"]} ${
                                            actionData.values["-hour"] ? actionData.values["-hour"] : "00:00:00"
                                        }`
                                    )}"
                                    type: ${actionData.values[ALARM_TYPE.ID]}
                                    ${
                                        actionData.values[ALARM_TYPE.ID] === ALARM_TYPE.TVCHANNEL
                                            ? `tvChannelId: ${actionData.values.TVCHANNEL[0]}`
                                            : ""
                                    }
                                ){ error ok }}
                    `);
                    executeQuery.alarmMutationName = "createAlarm";
                    executeQuery.customToast = t("toast-alarm", { action: t("created", { context: "female" }) });
                    removeApolloCacheKeys(client.cache, "alarm");
                    break;
                case "delete-alarm":
                    execute = true;
                    setMutation(`
                    mutation{    
                        deleteAlarm(
                            groupingIds: ${
                                actionData.itemsAffected.lenght === 1
                                    ? actionData.itemsAffected[0]
                                    : `[${actionData.itemsAffected.join(",")}]`
                            }
                        ){ error ok }}
                    `);
                    executeQuery.alarmMutationName = "deleteAlarm";
                    executeQuery.customToast = t("toast-alarm", { action: t("deleted", { context: "female" }) });
                    removeApolloCacheKeys(client.cache, "alarm");
                    break;
                case "delete-room-alarm":
                    execute = true;
                    setMutation(`
                    mutation{    
                        deleteAlarm(
                            stayGuestRoomId: ${actionData.itemsAffected[0]}
                        ){ error ok }}
                    `);
                    executeQuery.alarmMutationName = "deleteAlarm";
                    executeQuery.customToast = t("toast-alarm", { action: t("deleted", { context: "female" }) });
                    removeApolloCacheKeys(client.cache, "alarm");
                    break;
                case "disable-parental-code":
                    execute = true;
                    setMutation(`
                    mutation{
                        updateParentalCode(stayGuestRoomID:${executeQuery?.params?.stayGuestRoomID}){
                        id
                        ok
                        error
                        }
                    }
                    `);
                    executeQuery.alarmMutationName = "updateParentalCode";
                    break;
                default:
                    break;
            }
        }
        if (execute) {
            if (!showGlobalLoading) dispatch(setLoading(true));
            if (!executeQuery.hideLoading) {
                dispatch(showGlobalLoading(true));
            }

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].isOk || data[executeQuery.action].ok)) ||
                (data[executeQuery.alarmMutationName] &&
                    (data[executeQuery.alarmMutationName].isOk || data[executeQuery.alarmMutationName].ok)))
        ) {
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }
            if (!executeQuery.params || (executeQuery.params && !executeQuery.params.noCleanAction)) {
                dispatch(cleanAction());
            }

            if (!executeQuery.preventToast) {
                toast.success(capitalizeFirst(t(executeQuery.customToast || "operation-successful")));
            }
            dispatch(setExecuteQuery(null));
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));
        } else if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].errorMsg || data[executeQuery.action].error)) ||
                (data[executeQuery.alarmMutationName] &&
                    (data[executeQuery.alarmMutationName].errorMsg || data[executeQuery.alarmMutationName].error)))
        ) {
            const errorMsg =
                data[executeQuery.action] && data[executeQuery.action].error
                    ? data[executeQuery.action].error.msg
                        ? data[executeQuery.action].error.msg
                        : data[executeQuery.action].error
                    : data[executeQuery.alarmMutationName] && data[executeQuery.alarmMutationName].error
                    ? data[executeQuery.alarmMutationName].error
                    : null;

            toast.error(errorMsg ? errorMsg : t("mutation-error"));
            dispatch(showGlobalLoading(false));
            dispatch(setExecuteQuery(null));
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateAlarm);
