export const Design = [
    "cast",
    "screens",
    "design",
    "designs",
    "published",
    "publish",
    "add-new-cast-screen",
    "add-new-tv-screen",
    "add-new-mobile-screen",
    "add-new-general-screen",
    "select-infinitive",
    "type",
    "edit-name",
    "delete",
    "write-screenname",
    "screen-delete",
    "select-template",
    "screen-cannot-delete",
    "select-screen-type",
    "CCINSTRUCTION",
    "TV",
    "MOBILE",
    "CCPAIRINGSUCCESSFUL",
    "HOME",
    "INFO",
    "publish-confirm",
    "media-library",
    "add-folder",
    "add-media",
    "table",
    "map",
    "name",
    "last-activity",
    "size",
    "information",
    "data",
    "gallery",
    "images",
    "audios",
    "videos",
    "documents",
    "corporate-identity",
    "interface",
    "back-to-menu",
    "design",
    "designs",
    "rename",
    "replace",
    "move-to",
    "download",
    "delete-permanently",
    "thumbnail",
    "media-library-move-content",
    "media-library-download",
    "media-library-delete-permanently",
    "search",
    "error-exceeds-allowed-size",
    "mutation-error",
    "root",
    "operation-successful",
    "folder",
    "media-library-batch-delete-permanently-text",
    "media-library-batch-download-text",
    "media-library-update-media",
    "media-library-batch-move-content-text",
    "file",
    "media-library-details",
    "file-information",
    "file-type",
    "last-modified",
    "file-size",
    "dimensions",
    "other-lang",
    "file-language",
    "convert-to-default",
    "multi-language",
    "media-library-make-to-default-text",
    "media-library-delete-permanently-text",
    "media-library-delete-all-permanently-text",
    "media-library-download-text",
    "media-library-move-content-text",
    "move-here",
    "default-file",
    "add",
    "add-code",
    "add-code-here",
    "add-iframe",
    "add-iframe-url",
    "add-video",
    "add-image",
    "add-music",
    "link",
    "video-options",
    "image-options",
    "choose-image",
    "adjustment",
    "full-screen",
    "play-automatic",
    "continuos-repetition",
    "choose-an-image",
    "choose-images",
    "images-of-the-media-library",
    "insert",
    "update",
    "author",
    "creator",
    "last-update",
    "create-new",
    "new-screen",
    "screen-options",
    "background-image",
    "margin-between-items",
    "margins",
    "color-and-opacity",
    "background-opacity",
    "background-color",
    "unpublish",
    "unpublish-confirm",
    "add-qr",
    "predefined",
    "back-to-list",
    "apply-to",
    "add-filter",
    "edit-filter",
    "default-screen",
    "not-applied",
    "apply-to-view",
    "x-filters-applied",
    "one-filter-applied",
    "define-apply-screen",
    "filtering-result",
    "add-design",
    "edit-design",
    "delete-design",
    "duplicate-design",
    "delete-design-confirm",
    "duplicate-design-confirm",
    "edit-screen",
    "make-default",
    "make-default-fem",
    "make-default-text",
    "duplicate-screen",
    "unpublish-text",
    "design-name-instructions",
    "design-properties-instructions",
    "select-properties",
    "applied",
    "apply",
    "design-apply-instructions",
    "apply-design",
    "filter",
    "global-default",
    "design-global-apply-instructions",
    "design-global-apply-active",
    "design-global-apply-inactive",
    "applied-on",
    "applied-from",
    "unapply-design",
    "unapply-design-confirm",
    "see-screen",
    "see-screens",
    "by-default",
    "unpublished",
    "image",
    "text",
    "qr code",
    "icon",
    "weather",
    "container",
    "gallery options",
    "default-design",
    "design-global-default-design-tooltip",
    "design-global-default-design-tooltip-long",
    "edit-screens",
    "https-only",
    "new-template-text",
    "write the name of the templates",
    "templates",
    "screen-type",
    "viewport",
    "delete-template",
    "delete-template-confirm",
    "write the name of the templates",
    "chain",
    "select chain",
    "select design",
    "duplicate-screen-text",
    "corporate",
    "local",
    "design type",
    "see screens",
    "manage-appearances",
    "appearances",
    "write-appearance-name",
    "delete-appearance-confirm",
    "select-logo-type",
    "logo-version",
    "delete-appearance-logo",
    "bw",
    "library",
    "fonts",
    "media",
    "folder-delete",
    "and",
    "publish-and-make-default-confirm",
    "delete-filters",
    "delete-filters-confirm",
    "user_without_room",
    "all-customers",
    "font-library-batch-delete-permanently-text",
    "font-library-batch-download-text",
    "media-library-delete-file-permanently-text",
    "media-library-delete-video-permanently-text",
    "media-library-delete-asset-permanently-text",
    "select-a-video",
    "videos-of-media-library",
    "choose",
    "search-for",
    "what-qualities",
    "4k",
    "fullhd",
    "hd",
    "transcode-pending",
    "edit-menu",
    "editing-menu",
    "finalize-changes",
    "preview-menu-and-background",
    "select-home",
    "show-menu",
    "hide-menu",
    "use-home-background",
    "show-home-menu",
    "modified-background",
    "disabled-menu",
    "type-of-menu",
    "none",
    "fixed",
    "hidden",
    "menu",
    "preview-background",
    "download-confirm-ublocked-pop-ups",
    "values",
    "value",
    "tag information",
];
