import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toggleCustomInlineStyle, getSelectionCustomInlineStyle, extractInlineStyle } from "draftjs-utils";
import { getElementMaxHeight } from "../../../../../../hooks/Utils/DesignUtils";
import { getFontSizes } from "../../../../../../hooks/Utils/Design/FontUtils";

const CustomFontSizes = ({ type, editorState, onChange, modalHandler, toolbar, defaultSize = 19 }) => {
    const { t } = useTranslation();
    const textSizes = getFontSizes(1280, "TV");

    const [open, setOpen] = useState(false);

    const setFontSize = (size) => {
        const newState = toggleCustomInlineStyle(editorState, "fontSize", size);
        if (newState) {
            onChange(newState);
        }
    };
    const [elementStyle, setElementStyle] = useState({ maxHeight: "10rem" });

    useEffect(() => {
        if (open) {
            setElementStyle({ maxHeight: `${getElementMaxHeight("tags-dropdown")}rem` });
        }
    }, [open]);

    const close = () => {
        setOpen(false);
    };

    const currentSize =
        parseInt((getSelectionCustomInlineStyle(editorState, ["FONTSIZE"]).FONTSIZE + "").replace("fontsize-", "")) ||
        defaultSize;

    const incFontSize = (inc) => {
        const newSize = inc > 0 ? currentSize + 4 : currentSize - 4;
        setFontSize(newSize);
        extractInlineStyle(editorState); // Used to fix bug updating getSelectionCustomInlineStyle
    };

    const defaultSizes = [
        { value: 14, text: t("Small") },
        { value: 19, text: t("Medium") },
        { value: 40, text: t("Large") },
    ];

    const controlIncrease = (
        <div className="rdw-inline-wrapper">
            <div className="rdw-option-wrapper icon icon-font-size text-xs" onClick={() => incFontSize(-1)}></div>
            <div className="rdw-option-wrapper icon icon-font-size" onClick={() => incFontSize(1)}></div>
        </div>
    );

    const sizes = toolbar && toolbar.sizes ? toolbar.sizes : defaultSizes;

    const getSizeName = (size, type) => {
        let found = false;
        let textToRender = "";
        if (sizes) {
            sizes.map((s) => {
                if (s.px === size) {
                    textToRender = s.text;
                    found = true;
                }
                return s;
            });
        }

        if (!found && type && type.style && type.style.size) {
            if (textSizes && textSizes instanceof Array && textSizes.length > 0)
                textSizes.forEach((textSize) => {
                    if (textSize.value === type.style.size) {
                        textToRender = textSize.text;
                        found = true;
                    }
                });
        }

        if (!found) {
            // check for closer size in px, with maths operations sometimes chages 1 or 2 px
            let indexfound = -1;
            for (let index = 0; index < sizes.length; index++) {
                const prevElement = index > 0 ? sizes[index - 1] : sizes[0];
                const nextElement = index === sizes.length - 1 ? sizes[index] : sizes[index + 1];

                if (
                    (prevElement.px < size && size < nextElement.px) ||
                    (index === sizes.length - 1 && size > nextElement.px)
                ) {
                    indexfound = index;
                    found = true;
                }
            }
            if (indexfound > -1 && found) {
                textToRender = sizes[indexfound].text;
            }
        }
        // eslint-disable-next-line
        return found ? textToRender : size + "px";
    };
    const controlSizes = (
        <div
            key={useRef()}
            onClick={() => {
                if (!open) setTimeout(() => setOpen(true), 100);
            }}
            className="rdw-block-wrapper custom-font-sizes"
            aria-label="rdw-block-control"
        >
            <div
                id="fontSize-dropdown"
                className="rdw-dropdown-wrapper rdw-block-dropdown w-full"
                aria-label="rdw-dropdown"
            >
                <div className="rdw-dropdown-selectedtext w-full">
                    <div className="first-capital pr-8">
                        {currentSize ? getSizeName(currentSize, type) : t("font size")}
                    </div>
                    <div className={`rdw-dropdown-caretto${open ? "close" : "open"}`}></div>
                </div>
                <ul className={`rdw-dropdown-optionwrapper ${open ? "" : "hidden"}`} style={elementStyle}>
                    {sizes &&
                        sizes.map((item) => (
                            <li
                                onClick={() => setFontSize(item.px)}
                                key={"cfs-" + item.value}
                                className="rdw-dropdownoption-default placeholder-li"
                            >
                                {item.text}
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );

    useEffect(() => {
        if (modalHandler) modalHandler.registerCallBack(close);
        return () => {
            if (modalHandler) modalHandler.deregisterCallBack(close);
        };
    }, [modalHandler]);

    return (
        <>
            {toolbar && toolbar.options
                ? toolbar.options.map((option) => {
                      switch (option) {
                          case "increase":
                              return controlIncrease;
                          case "sizes":
                              return controlSizes;
                          default:
                              return null;
                      }
                  })
                : null}
        </>
    );
};

export default CustomFontSizes;
