import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gql } from "apollo-boost";
import { setExecuteQuery, setRefreshContentData, setSectionContent } from "../../../actions/sectionActions";
import { showGlobalLoading, closeModal, setModalContent } from "../../../actions/uiActions";
import { useMutation } from "@apollo/react-hooks";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { removeApolloCacheKeys } from "../../Utils/Utils";
import { setLoading } from "../../../actions/tableActions";
import { changeActionValidations, changeActionValues } from "../../../actions/globalActions";
import {
    CORPORATE_LOGOS,
    PROPERTY_LOGOS_TYPE,
    CORPORATE_LOGOS_TYPE,
    APPEARANCE_DEFAULT,
    THEME_TYPE_DESIGN,
    THEME_TYPE_MOBILE,
} from "../../Utils/AppearancesUtils";
import { withApollo } from "@apollo/react-hoc";

const AppearancesQueries = ({ client }) => {
    const { t } = useTranslation();

    const { executeQuery, appearanceSelected, addLogo, allowNullLogo } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);

    const theme = { default: {}, active: {}, focus: {}, ...appearanceSelected };

    //State
    const [mutation, setMutation] = useState("mutation{checkIn}");
    const [refresh, setRefresh] = useState(true);

    //Actions
    const dispatch = useDispatch();

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(err.message);
        },
    });
    let validations = {};
    const validateLogo = (inputs = { version: true, image: true }) => {
        validations = {};
        if (inputs.version && !addLogo.version) {
            validations["logo-type"] = false;
        }
        if (inputs.image && !addLogo.asset && !allowNullLogo) {
            validations["browser-media-library-items-selected"] = false;
        }
    };

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            execute = true;
            switch (executeQuery.action) {
                case "appearance-edit-name":
                    console.log(executeQuery);
                    setMutation(`
                        mutation{
                            updateTheme(
                                name: "${actionData.values["appearance-name"]}"
                                id: ${parseInt(executeQuery.params.value.appearanceID)}
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "updateTheme";
                    removeApolloCacheKeys(client.cache, "themes");
                    break;
                case "add-new-appearance":
                    setMutation(`mutation {
                        createTheme(
                          name: "${executeQuery.name}"
                          themeType: ${executeQuery.themeType}
                          type: ${executeQuery.type}

                            colors: {
                                defaultBackground: "${APPEARANCE_DEFAULT.colors.defaultBackground}"
                                defaultForeground: "${APPEARANCE_DEFAULT.colors.defaultForeground}"
                                activeBackground: "${APPEARANCE_DEFAULT.colors.activeBackground}"
                                activeForeground: "${APPEARANCE_DEFAULT.colors.activeForeground}"
                                focusBackground: "${APPEARANCE_DEFAULT.colors.focusBackground}"
                                focusForeground: "${APPEARANCE_DEFAULT.colors.focusForeground}"
                                focusBorderSize: ${APPEARANCE_DEFAULT.colors.focusBorderSize}
                                accent: "${APPEARANCE_DEFAULT.colors.accent}"
                                accentContrast: "${APPEARANCE_DEFAULT.colors.accentContrast}"
                                login: "${APPEARANCE_DEFAULT.colors.login}"
                            }
                            fontStyles: {
                                heading: {
                                    name: "${APPEARANCE_DEFAULT.fontStyles.heading.name}"
                                    size: ${APPEARANCE_DEFAULT.fontStyles.heading.size}
                                    bold: ${APPEARANCE_DEFAULT.fontStyles.heading.bold}
                                    italic: ${APPEARANCE_DEFAULT.fontStyles.heading.italic}
                                    underline: ${APPEARANCE_DEFAULT.fontStyles.heading.underline}
                                    alignment: "${APPEARANCE_DEFAULT.fontStyles.heading.alignment}"
                                    font: {externalUrl:"${APPEARANCE_DEFAULT.fontStyles.heading.font.externalUrl}"}
                                }
                                paragraph: {
                                    name: "${APPEARANCE_DEFAULT.fontStyles.paragraph.name}"
                                    size: ${APPEARANCE_DEFAULT.fontStyles.paragraph.size}
                                    bold: ${APPEARANCE_DEFAULT.fontStyles.paragraph.bold}
                                    italic: ${APPEARANCE_DEFAULT.fontStyles.paragraph.italic}
                                    underline: ${APPEARANCE_DEFAULT.fontStyles.paragraph.underline}
                                    alignment: "${APPEARANCE_DEFAULT.fontStyles.paragraph.alignment}"
                                    font: {externalUrl:"${APPEARANCE_DEFAULT.fontStyles.paragraph.font.externalUrl}"}
                                }
                          }
                        ) {
                          error
                          id
                          ok
                        }
                      }`);
                    executeQuery.mutationName = "createTheme";
                    break;
                case "update-appearance-colors":
                    let mutation = "";

                    if (theme.themeType === THEME_TYPE_DESIGN) {
                        mutation = `mutation{
                            updateTheme(
                                id: ${theme.id}
                                colors: {
                                    defaultBackground: "${
                                        executeQuery.params.property.includes("default") &&
                                        executeQuery.params.property.includes("bg")
                                            ? executeQuery.params.value
                                            : theme?.default?.rgbaBG
                                    }" 
                                    defaultForeground: "${
                                        executeQuery.params.property.includes("default") &&
                                        executeQuery.params.property.includes("fg")
                                            ? executeQuery.params.value
                                            : theme?.default?.rgbaFG
                                    }"
                                    activeBackground: "${
                                        executeQuery.params.property.includes("active") &&
                                        executeQuery.params.property.includes("bg")
                                            ? executeQuery.params.value
                                            : theme?.active?.rgbaBG
                                    }"
                                    activeForeground: "${
                                        executeQuery.params.property.includes("active") &&
                                        executeQuery.params.property.includes("fg")
                                            ? executeQuery.params.value
                                            : theme?.active?.rgbaFG
                                    }"
                                    focusBackground: "${
                                        executeQuery.params.property.includes("focus") &&
                                        executeQuery.params.property.includes("bg")
                                            ? executeQuery.params.value
                                            : theme?.focus?.rgbaBG
                                    }"
                                    focusForeground: "${
                                        executeQuery.params.property.includes("focus") &&
                                        executeQuery.params.property.includes("fg")
                                            ? executeQuery.params.value
                                            : theme?.focus?.rgbaFG
                                    }"
                                    focusBorderSize: ${
                                        executeQuery.params.property.includes("focus") &&
                                        executeQuery.params.property.includes("border")
                                            ? executeQuery.params.value
                                            : theme.focusBorderSize
                                    }
                                  }
                            ){ error ok }}`;
                    } else if (theme.themeType === THEME_TYPE_MOBILE) {
                        // console.log(theme);
                        // console.log(executeQuery.params);

                        mutation = `mutation{
                            updateTheme(
                                id: ${theme.id}
                                colors: {
                                    accent: "${
                                        executeQuery.params.property.includes("mainColour") &&
                                        executeQuery.params.property.includes("bg")
                                            ? executeQuery.params.value
                                            : theme?.accent?.rgbaBG
                                    }" 
                                    login: "${
                                        executeQuery.params.property.includes("loginScreen") &&
                                        executeQuery.params.property.includes("bg")
                                            ? executeQuery.params.value
                                            : theme?.login?.rgbaBG
                                    }" 
                                    
                                  }
                            ){ error ok }}`;
                    }

                    setMutation(mutation);

                    executeQuery.hideLoading = true;
                    executeQuery.hideToast = true;
                    setRefresh(false);
                    break;
                case "update-appearance-font":
                    setMutation(`
                    mutation{
                        updateTheme(
                            id: ${executeQuery.appearanceId}
                            fontStyles: {
                                heading: {
                                  name: "${executeQuery.heading.fontName}"
                                  size: ${executeQuery.heading.size}
                                  bold: ${executeQuery.heading.bold}
                                  italic: ${executeQuery.heading.italic}
                                  underline: ${executeQuery.heading.underline}
                                  alignment: "${executeQuery.heading.textAlign}"
                                  ${
                                      executeQuery.heading.fontLink
                                          ? `font:{${
                                                executeQuery.heading.fontLink.externalUrl
                                                    ? `externalUrl: "${executeQuery.heading.fontLink.externalUrl}"`
                                                    : ""
                                            } ${
                                                executeQuery.heading.fontLink.libraryRef
                                                    ? `libraryRef:"${executeQuery.heading.fontLink.libraryRef}"`
                                                    : ""
                                            }}`
                                          : ""
                                  }
                                }
                                paragraph: {
                                    name: "${executeQuery.paragraph.fontName}"
                                    size: ${executeQuery.paragraph.size}
                                    bold: ${executeQuery.paragraph.bold}
                                    italic: ${executeQuery.paragraph.italic}
                                    underline: ${executeQuery.paragraph.underline}
                                    alignment: "${executeQuery.paragraph.textAlign}"
                                    ${
                                        executeQuery.paragraph.fontLink
                                            ? `font:{${
                                                  executeQuery.paragraph.fontLink.externalUrl
                                                      ? `externalUrl: "${executeQuery.paragraph.fontLink.externalUrl}"`
                                                      : ""
                                              } ${
                                                  executeQuery.paragraph.fontLink.libraryRef
                                                      ? `libraryRef:"${executeQuery.paragraph.fontLink.libraryRef}"`
                                                      : ""
                                              }}`
                                            : ""
                                    }
                                  }
                            }
                        ){ error ok }}
                `);
                    break;
                case "duplicate-appearance":
                    setMutation(`
                        mutation{
                            duplicateTheme(
                                themeID: "${executeQuery.params.appearanceID}"
                            ){ error ok }}
                    `);
                    removeApolloCacheKeys(client.cache, "themes");
                    break;
                case "delete-appearance":
                    setMutation(`
                        mutation{
                            deleteTheme(
                                id: "${parseInt(executeQuery.params.value.appearanceID)}"
                            ){ error ok }}
                    `);
                    dispatch(setSectionContent({ appearanceId: null }));
                    if (theme.id === parseInt(executeQuery.params.value.appearanceID)) {
                        dispatch(setSectionContent({ appearanceSelected: null }));
                    }
                    removeApolloCacheKeys(client.cache, "themes");
                    break;
                case "appearance-delete-logo":
                    setMutation(`
                        mutation{
                            deleteThemeLogo(
                                id: "${parseInt(actionData.itemsAffected[0])}"
                            ){ error ok }}
                    `);
                    removeApolloCacheKeys(client.cache, "themes");
                    execute = true;
                    break;
                case "appearance-add-logo":
                    // VALIDATIONS
                    validateLogo();
                    if (Object.keys(validations).length > 0) {
                        // needed some fields required show toast
                        dispatch(changeActionValidations(validations));
                        toast.error(t("errors-in-red"));
                        execute = false;
                    } else {
                        setMutation(`
                            mutation{
                                createThemeLogo(
                                    themeID: ${parseInt(theme.id)}
                                    type: ${
                                        addLogo.type === CORPORATE_LOGOS ? CORPORATE_LOGOS_TYPE : PROPERTY_LOGOS_TYPE
                                    }
                                    version: ${addLogo.version}
                                    ${addLogo.asset ? `imageRef:"${addLogo.asset}"` : ``}
                                ){ error ok }}
                        `);
                        execute = true;
                        removeApolloCacheKeys(client.cache, "themes");
                    }
                    break;
                case "appearance-edit-logo":
                    // VALIDATIONS
                    validateLogo();
                    if (Object.keys(validations).length > 0) {
                        // needed some fields required show toast
                        dispatch(changeActionValidations(validations));
                        toast.error(t("errors-in-red"));
                        execute = false;
                    } else {
                        setMutation(`
                        mutation{
                            updateThemeLogo(
                                themeLogoID: ${parseInt(addLogo.id)}
                                imageRef: "${addLogo.asset}"
                                version: ${addLogo.version}
                            ){ error ok }}
                        `);
                        execute = true;
                        removeApolloCacheKeys(client.cache, "themes");
                    }
                    break;
                case "appearance-replace-logo":
                    if (addLogo.themeLogoCustoms && addLogo.themeLogoCustoms.length > 0) {
                        // usar mutacion updateThemeLogoCustom
                        setMutation(`
                            mutation{
                                updateThemeLogoCustom(
                                    themeLogoID: ${parseInt(addLogo.id)}
                                    imageRef:"${addLogo.asset ? addLogo.asset : ""}"
                                ){ error ok }}
                        `);
                    } else {
                        setMutation(`
                            mutation{
                                createThemeLogoCustom(
                                    themeLogoID: ${parseInt(addLogo.id)}
                                    type: ${
                                        addLogo.type === CORPORATE_LOGOS ? CORPORATE_LOGOS_TYPE : PROPERTY_LOGOS_TYPE
                                    }
                                    imageRef:"${addLogo.asset ? addLogo.asset : ""}"
                                ){ error ok }}
                        `);
                    }
                    removeApolloCacheKeys(client.cache, "themes");
                    execute = true;
                    break;
                case "appearance-upload-logo":
                    validateLogo({ version: false, image: true });
                    if (Object.keys(validations).length > 0) {
                        // needed some fields required show toast
                        dispatch(changeActionValidations(validations));
                        toast.error(t("errors-in-red"));
                        execute = false;
                    } else {
                        setMutation(`
                            mutation{
                                createThemeLogoCustom(
                                    themeLogoID: ${parseInt(addLogo.id)}
                                    type: ${
                                        addLogo.type === CORPORATE_LOGOS ? CORPORATE_LOGOS_TYPE : PROPERTY_LOGOS_TYPE
                                    }
                                    imageRef:"${addLogo.asset ? addLogo.asset : ""}"
                                ){ error ok }}
                        `);
                        removeApolloCacheKeys(client.cache, "themes");
                        execute = true;
                    }
                    break;
                case "browser-media-library":
                    dispatch(
                        changeActionValues({
                            "select-asset": true,
                        })
                    );
                    execute = false;
                    break;
                default:
                    execute = false;
                    break;
            }
            if (execute) {
                if (!showGlobalLoading) dispatch(setLoading(true));
                if (!executeQuery.hideLoading) {
                    dispatch(showGlobalLoading(true));
                }
                setTimeout(function () {
                    executeMutation();
                }, 100);
            }
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (data && executeQuery) {
            dispatch(showGlobalLoading(false));
            dispatch(setModalContent(false));
            dispatch(closeModal());
            dispatch(setExecuteQuery(null));
            if (!executeQuery.hideLoading) {
                toast.success(
                    t(executeQuery.customSuccessText ? executeQuery.customSuccessText : "operation-successful")
                );
            }

            if (refresh) {
                dispatch(setRefreshContentData(true));
            }
            setRefresh(true);
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};
export default withApollo(AppearancesQueries);
