import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gql } from "apollo-boost";
import { useMSQuery } from "../useMSQuery";
import { setSectionContent } from "../../../actions/sectionActions";

const LogoAssetName = (_assetRef, show) => {
    const dispatch = useDispatch();
    const { appearanceSelected } = useSelector((state) => state.sectionContent);

    //API Call
    const ASSET_NAME = gql`
        query libraryAsset($assetRef: String!) {
            libraryAsset(ref: $assetRef) {
                response {
                    name
                }
            }
        }
    `;

    const [executeAssetNameQuery, { loading, error, data }] = useMSQuery(ASSET_NAME, {});

    useEffect(() => {
        if (!loading && !error && data) {
            dispatch(
                setSectionContent({
                    appearanceSelected: {
                        ...appearanceSelected,
                        assetName: data?.libraryAsset?.response?.name || null,
                    },
                })
            );
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (_assetRef && _assetRef.includes("asset") && show) {
            executeAssetNameQuery({ variables: { assetRef: _assetRef } });
        } else if (data) {
            dispatch(
                setSectionContent({
                    appearanceSelected: {
                        ...appearanceSelected,
                        assetName: data?.libraryAsset?.response?.name || null,
                    },
                })
            );
        } else {
            dispatch(
                setSectionContent({
                    appearanceSelected: {
                        ...appearanceSelected,
                        assetName: null,
                    },
                })
            );
        }
        // eslint-disable-next-line
    }, [_assetRef, show]);
};
export default LogoAssetName;
