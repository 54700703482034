const GetIconSatisfaction = () => {};

export const CARDS_SETTINGS = {
    SATISFACTION_LEVEL: "satisfaction_level",
    SATISFACTION_QUESTION: "satisfaction_question",
};

export const satisfactionLevelValues = {
    5: { icon: "Face-very-happy", style: { color: "#2A8A61", fontSize: "2rem" } },
    4: { icon: "Face-happy", style: { color: "#36B37E", fontSize: "2rem" } },
    3: { icon: "Face-neutral", style: { color: "#EBC500", fontSize: "2rem" } },
    2: { icon: "Face-sad", style: { color: "#FF991F", fontSize: "2rem" } },
    1: { icon: "Face-angry", style: { color: "#DE350B", fontSize: "2rem" } },
    null: { icon: "Face-neutral", style: { opacity: 0, fontSize: "2rem" } },
};

export const SATISFACTION_QUESTION_TEMPLATE = [
    {
        languageCode: "en",
        name: `How would you rate your stay with us?`,
    },
    {
        languageCode: "es",
        name: `¿Cómo valoras tu estancia con nosotros?`,
    },
];

export const UPDATE_SETTINGS = {
    EMAIL: "update_settings_email",
    SATISFACTION_QUESTION: "update_settings_satisfaction_question",
    SATISFACTION_LEVEL: "update_settings_satisfaction_level",
    USERS: "update_settings_users",
};

export const toggleCardInEdition = ({ card, setCardInEdition, cardInEdition }) => {
    if (typeof setCardInEdition === "function" && card === CARDS_SETTINGS.SATISFACTION_LEVEL) {
        setCardInEdition(
            cardInEdition === CARDS_SETTINGS.SATISFACTION_LEVEL ? null : CARDS_SETTINGS.SATISFACTION_LEVEL
        );
    }

    if (typeof setCardInEdition === "function" && card === CARDS_SETTINGS.SATISFACTION_QUESTION) {
        setCardInEdition(
            cardInEdition === CARDS_SETTINGS.SATISFACTION_QUESTION ? null : CARDS_SETTINGS.SATISFACTION_QUESTION
        );
    }
};

export const isInEdition = ({ card, cardInEdition }) => {
    if (card === CARDS_SETTINGS.SATISFACTION_QUESTION && cardInEdition === CARDS_SETTINGS.SATISFACTION_QUESTION) {
        return true;
    } else if (card === CARDS_SETTINGS.SATISFACTION_LEVEL && cardInEdition === CARDS_SETTINGS.SATISFACTION_LEVEL) {
        return true;
    } else {
        return false;
    }
};

export const cancelEdit = (setCardInEdition) => {
    if (typeof setCardInEdition === "function") {
        setCardInEdition(null);
    }
};
