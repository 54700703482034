import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setConnectionError } from "../../actions/tableActions";
import CheckConnectionError from "../../hooks/Utils/Table/CheckConnectionError";
import { withDeprecationWarning } from "dev";

const NETWORK_ERROR = "Network error";

const UseError = withDeprecationWarning({ name: "UseError", alt: "ErrorInfo" }, () => {
    const dispatch = useDispatch();
    const { error, connectionError } = useSelector((state) => state.table);
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState(t("content-issue-display"));

    CheckConnectionError();

    useEffect(() => {
        if ((error && error.message && error.message.includes(NETWORK_ERROR)) || connectionError) {
            setErrorMessage(t("connection-error"));
            dispatch(setConnectionError(true));
        } else if (error && !connectionError) {
            setErrorMessage(t("content-issue-display"));
        }
        // eslint-disable-next-line
    }, [error, connectionError]);

    return <div className="t-loading">{errorMessage}</div>;
});

export default UseError;
