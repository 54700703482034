const EditNameChannel = (data) => {
    return {
        title: "edit-name",
        value: data?.name ? data.name : "",
        bodyAdjust: "w-3/12",
        executeSectionQuery: true,
        inputs: [
            {
                type: "editName",
                data: data ? data : null,
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: "edit-name-channel",
            },
        ],
    };
};

export default EditNameChannel;
