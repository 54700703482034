import { THEME_TYPE_MOBILE } from "hooks/Utils/AppearancesUtils";
import { calculateByRatio } from "hooks/Utils/DesignUtils";
import { getFromDAS } from "hooks/Utils/Utils";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { MOBILE_RATIO } from "hooks/Utils/Widgets";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
    cDeviceMobile,
    calculateMarginLeftPreview,
    calculateMarginTopPreviewMobile,
    appearancePreviewText,
    containerStyles,
    stylesLogo,
    stylesLogoRef,
    stylesLogoRefContainer,
    stylesLogin,
    enterButtonStyles,
    getLogoColorConstrast,
} from "./PreviewMobileAppearanceUtils/utils";
import useSetAppearanceLoaded from "./PreviewMobileAppearanceUtils/useSetAppearanceLoaded";
import { rgbaToRgb } from "hooks/Utils/AppearancesUtils";
import { isLight } from "../../../../hooks/Utils/AppearancesUtils";

const PreviewMobileAppearance = ({
    visibilityAL = false,
    appearanceWithMenu = false,
    loadingListAppearances = false,
    loadingAppearance = false,
}) => {
    const { t } = useTranslation();

    const { appearanceSelected } = useSelector((state) => state.sectionContent);
    const [previewContainerStyles, setPreviewContainerStyles] = useState({ width: 0, height: 0, marginTop: 0 });
    const [appearanceLoaded, setAppearanceLoaded] = useState();
    const [appearanceStyles, setAppearanceStyles] = useState({
        accent: "rgba(0, 0, 0, 1)",
        login: "rgba(0, 0, 0, 1)",
        logo: null,
    });

    const PreviewContainer = document.querySelector("#preview-mobile-appearance-container");

    useEffect(() => {
        const { height, width } = calculateByRatio(cDeviceMobile, MOBILE_RATIO);
        setPreviewContainerStyles({ height, width, marginTop: 0 });
    }, []);

    useEffect(() => {
        if (PreviewContainer) {
            let previewDiv = document.getElementById("preview-mobile-appearance-container");
            const _h = (previewDiv?.clientHeight / 100) * 70;
            const _w = _h * (9 / 16);
            const _mt = calculateMarginTopPreviewMobile();

            setPreviewContainerStyles({
                width: _w,
                height: _h + 50,
                marginTop: appearanceWithMenu ? "-10rem" : _mt,
                marginLeft: "3rem",
            });

            const marginLeft = calculateMarginLeftPreview();
            PreviewContainer.style.marginLeft = marginLeft + "px";
        }
    }, [PreviewContainer]);

    useEffect(() => {
        if (PreviewContainer) {
            const marginLeft = calculateMarginLeftPreview();
            PreviewContainer.style.marginLeft = marginLeft + "px";
        }
    }, [visibilityAL]);

    useEffect(() => {
        if (!appearanceSelected) return;

        const { accent, login, logos } = appearanceSelected;
        let logoSelected = null;

        if (login?.rgbaBG) {
            const rgbaColorToRgb = rgbaToRgb(login.rgbaBG);
            const isALightColor = rgbaColorToRgb ? isLight(rgbaColorToRgb) : false;

            const logoSelectedObject = logos?.find((logo) => {
                const correctVersion = isALightColor ? "OTHERS_LIGHT_ICON" : "OTHERS_DARK_ICON";
                return logo.version === correctVersion;
            });

            logoSelected = logoSelectedObject?.themeLogoCustoms?.[0]?.imageRef || logoSelectedObject?.imageRef || null;
        }

        const appearanceStyles = {
            accent: accent?.rgbaBG || "rgba(255, 255, 255, 1)",
            login: login?.rgbaBG || "rgba(255, 255, 255, 1)",
            logo: logoSelected,
        };

        setAppearanceStyles(appearanceStyles);
    }, [appearanceSelected]);

    useSetAppearanceLoaded({
        appearanceSelected,
        loadingAppearance,
        loadingListAppearances,
        setAppearanceLoaded,
    });

    return (
        <div
            className={appearanceWithMenu ? "flex justify-center items-center" : ""}
            style={{ width: "100%", height: "100%", userSelect: "none" }}
            id="preview-mobile-appearance-container"
        >
            <div
                id="preview-mobile-appearance"
                style={{ ...previewContainerStyles, backgroundColor: appearanceStyles.login }}
                className={` pointer-events-none ${
                    appearanceLoaded && appearanceSelected?.id && appearanceSelected.themeType === THEME_TYPE_MOBILE
                        ? " opacity-100"
                        : "opacity-0"
                } `}
            >
                <div
                    style={appearancePreviewText()}
                    className=" w-full bg-gr flex justify-center text-white text-lg bg-zafiro-200"
                >
                    {capitalizeFirst(t("appearance-preview"))}
                </div>
                <div style={containerStyles()}>
                    {!appearanceStyles.logo || appearanceSelected.themeType !== THEME_TYPE_MOBILE ? (
                        <div style={stylesLogo({ color: getLogoColorConstrast(appearanceStyles.login) })}>
                            <div>{capitalizeFirst(t("logo"))}</div>
                        </div>
                    ) : (
                        <div style={stylesLogoRefContainer()}>
                            <img style={stylesLogoRef()} src={getFromDAS(appearanceStyles.logo)}></img>
                        </div>
                    )}
                </div>
                <div style={containerStyles()}>
                    <div className=" shadow-md" style={stylesLogin({ type: "container" })}>
                        <div style={stylesLogin({ type: "text" })}>{t("sign-in-into-your-account")}</div>
                        <div style={stylesLogin({ type: "googleButton" })}>
                            <img
                                style={stylesLogin({ type: "googleIcon" })}
                                src={require("../../../../assets/images/icons/google.svg")}
                            ></img>
                            <div>{t("sign-in-with-google")}</div>
                        </div>
                        <div style={stylesLogin({ type: "accessEmail" })}>{t("or-access-with-your-email")}</div>
                        <div style={stylesLogin({ type: "emailButton", colorLogin: appearanceStyles.accent })}>
                            {t("sign-in-with-my-email")}
                        </div>
                        <div style={stylesLogin({ type: "notAccount" })}>{t("dont-have-an-account-yet")}</div>
                        <div style={stylesLogin({ type: "register", colorLogin: appearanceStyles.accent })}>
                            {t("sign-up")}
                        </div>
                    </div>
                </div>
                <div style={enterButtonStyles()}>{t("enter-without-identifying")}</div>
            </div>
        </div>
    );
};

export default PreviewMobileAppearance;
