import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UseInputText from "../Inputs/useInputText";
import { useTranslation } from "react-i18next";
import { changeActionValues } from "../../actions/globalActions";
import HTMLReactParser from "html-react-parser";

export const UseModalDeleteMobileApp = () => {
    function onChange(value) {
        dispatch(changeActionValues({ "delete-app": value }));
    }
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { values, validations } = useSelector((state) => state.action);
    const { permissions } = useSelector((state) => state.ui);
    const nativeApps = permissions?.hotel?.nativeApps || permissions?.allProperties?.nativeApps;

    return (
        <>
            <p>{t("delete-mobile-app-description-modal")}</p>
            <p className="mb-4">{t("delete-mobile-app-instructions-modal")}</p>
            <UseInputText
                id={"delete-mobile-app-input"}
                adjust={validations?.["delete-app"] === false ? "border border-red-100" : ""}
                ph="write-delete"
                value={values?.["delete-app"]}
                changeAction={(value) => {
                    onChange(value);
                }}
            />
            {nativeApps && (
                <div className="mt-2 flex">
                    <i className="icon icon-warning text-orange-100 text-xl pr-2 pt-1" />
                    <span>{HTMLReactParser(t("delete-native-app-message"))}</span>
                </div>
            )}
        </>
    );
};
