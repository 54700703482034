import { useState } from "react";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";

const useGuestOrders = ({ commonVars }) => {
    const query = gql`
        query guestOrders(
            $lang: LanguageCode!
            $guestID: Int64
            $clientID: ID
            $guestRoomID: Int64
            $search: String
            $page: Int
            $size: Int
            $orderBy: String
            $shopsTokens: [String]
            $orderDir: OrderCriteria
        ) {
            charges(filter: { type: SHOP, clientType: GUEST, clientId: $clientID, stayGuestRoomId: $guestRoomID }) {
                results {
                    extId
                    roomId
                    canceledAt
                    pmsStatus
                }
            }
            orders: orderHistory(
                filter: { stayGuestID: $guestID, stayGuestRoomID: $guestRoomID, search: $search, shop: $shopsTokens }
                page: $page
                size: $size
                orderBy: { field: $orderBy, criteria: $orderDir }
                languageRef: $lang
            ) {
                info {
                    count
                    page
                    size
                }
                results {
                    id
                    roomName
                    origRoomName
                    roomNumber
                    origRoomNumber
                    customerName
                    date
                    total
                    totalWithTax
                    deliveryMethod
                    deliveryLocation
                    schedule {
                        end
                        start
                    }
                    state
                    isDelayed
                    shop {
                        token
                        name
                        currencyCode
                    }
                }
            }
        }
    `;

    const [error, setError] = useState(null);
    const [called, setCalled] = useState(false);

    const [load, { data, loading, error: queryError }] = useLazyQuery(query, {
        fetchPolicy: "cache-first",
        errorPolicy: "all",
    });

    return {
        called,
        load: (vars) => {
            if (load) {
                setCalled(true);
                const variables = {
                    guestID: null,
                    guestRoomID: null,
                    search: null,
                    page: null,
                    size: null,
                    orderBy: null,
                    orderDir: null,
                    shopsTokens: null,
                    ...commonVars,
                    ...vars,
                    lang: vars?.lang || commonVars?.lang || "es",
                    clientID: (vars?.guestID || commonVars?.guestID)?.toString() || null,
                };
                const error =
                    !variables.guestID && !variables.guestRoomID
                        ? new Error("guestID or guestRoomID is required")
                        : null;
                setError(error);
                if (!error) {
                    load({ variables });
                }
            }
        },
        loading,
        error: error || queryError,
        ready: called && !loading && !queryError && !error,
        data,
    };
};

export default useGuestOrders;
