import React, { createContext, useEffect, useState } from "react";

//Components
import UseSectionHeader from "../../useSectionHeader";

//Utils
import Table from "components/ZafiroTable";
import { useTranslation } from "react-i18next";
import { useLanguages } from "hooks/GraphqlCalls/Settings/useLanguages";
import Button from "components/Button";
import Loading from "components/Loading";
import { Session } from "hooks/Utils/Session";

const WithContextLanguages = () => {
    const chain = Session.getChain();
    const projectsByChain = chain?.projects?.filter((project) => project.id !== 0);
    const isCorporate = Session.isCorporate();
    const currentProjectId = Session?.getProject()?.id;

    const projectsOptions = projectsByChain?.map((project) => {
        return {
            id: project.id,
            value: project.id,
            label: project.name,
        };
    });

    const [projectID, setProjectID] = useState(null);

    const { t } = useTranslation();
    const { tableResults, addLanguage, loading, executeQuery, load } = useLanguages();

    useEffect(() => {
        if (!isCorporate) {
            setProjectID(currentProjectId);
        } else {
            setProjectID(projectsByChain?.[0]?.id);
        }
    }, [currentProjectId, isCorporate]);

    useEffect(() => {
        if (projectID) {
            executeQuery({
                variables: {
                    projectID: Number(projectID),
                },
            });
        }
    }, [projectID]);

    return (
        <>
            <UseSectionHeader title={"language-list"} introduction={"language-descriptive-text"} />
            <main className="main-container">
                {!load ? (
                    <Table
                        search={true}
                        paginate={true}
                        id="languages"
                        cols={["language", "default", "delete"]}
                        showCount={true}
                        header={{
                            language: { title: t("language"), width: "20%" },
                            default: { title: "", width: "20%", align: "center" },
                            delete: { title: "", width: "10%", align: "center" },
                        }}
                        rows={tableResults}
                        {...(isCorporate && {
                            filters: [
                                {
                                    id: "filter-by-project",
                                    title: t("project"),
                                    options: projectsOptions,
                                    allowUnselect: false,
                                    value: projectID,
                                    onChange: (value) => setProjectID(value),
                                },
                            ],
                        })}
                        {...(!isCorporate && {
                            topRightCorner: (
                                <Button id="add-language" className="btn btn-blue" onClick={addLanguage}>
                                    {t("add-new")}
                                </Button>
                            ),
                        })}
                    />
                ) : (
                    <Loading adjust="section-loading" style={{ height: "50vh" }} />
                )}
            </main>
        </>
    );
};

const Languages = () => {
    return (
        <LanguagesProvider>
            <WithContextLanguages />
        </LanguagesProvider>
    );
};

export default Languages;

export const LanguagesContext = createContext({
    selectedLanguage: null,
    setSelectedLanguage: () => {},
});

const LanguagesProvider = ({ children }) => {
    const [selectedLanguage, setSelectedLanguage] = useState(null);

    return (
        <LanguagesContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>
            {children}
        </LanguagesContext.Provider>
    );
};
