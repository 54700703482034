import { capitalizeFirst } from "../../../Utils/Utils";

const AppearanceChangeName = (titlePrefix, title, screenData) => {
    // const { t } = useTranslation();
    return {
        titlePrefix: titlePrefix,
        title: ["-", capitalizeFirst(title)],
        executeSectionQuery: true,
        bodyAdjust: "w-3/12",
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "rename",
                style: "blue",
                action: "appearance-name",
            },
        ],
        inputs: [
            {
                text: ["write-appearance-name", ":"],
                name: "appearance-name",
                type: "text",
                value: titlePrefix,
                focus: true,
            },
            {
                value: screenData,
                type: "data",
            },
        ],
    };
};

export default AppearanceChangeName;
