import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import ScreensSidebar from "../../../components/Sidebar/ScreensSidebar";

const ScreensToolbar = ({ landings, setVisible, prefixId }) => {
    const { t } = useTranslation();
    const { designToolbarVisible } = useSelector((state) => state.design);

    const toolbarID = landings ? "landings" : "screens";
    const title = landings ? t("landing screens") : t("my screens");

    //Listeners
    useEffect(() => {
        if (designToolbarVisible === toolbarID) {
            const contentsList = document.querySelector("#contentsList");
            const contentsListSizes = contentsList ? contentsList.getClientRects()[0] : null;
            if (contentsList) {
                contentsList.style.maxHeight = `${window.innerHeight - contentsListSizes.y}px`;
            }
        }
        // eslint-disable-next-line
    }, [designToolbarVisible]);

    return (
        <div className={`contents left-0 z-200 bg-white h-full`}>
            <div className="border-b text-base border-gray-200 py-4 px-8 mb-2 flex items-center justify-between align-middle">
                <div className="first-capital font-bold  ">{title}</div>
                <div
                    className="text-3xl text-gray-800 icon icon-remove cursor-pointer sidebar-submenu-link"
                    onClick={(e) => setVisible(null)}
                />
            </div>
            <ScreensSidebar {...{ landings, toolbarID, prefixId }} />
        </div>
    );
};

export default ScreensToolbar;
