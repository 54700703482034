import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//Actions
import { setScheduleToUpdate, setMutationRefresh } from "../../actions/scheduleActions";

const UseInputNameEditSchedule = ({ validation }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { scheduleToUpdate } = useSelector((state) => state.schedules);
    //States
    const [itemNameError, setItemNameError] = useState(validation);

    //Actions
    const dispatch = useDispatch();
    const changeNameHandler = (e) => {
        if (!e.target.value || e.target.value === "") {
            setItemNameError(false);
            const newScheduleInfo = scheduleToUpdate;
            newScheduleInfo.name = null;
            //Update current store
            dispatch(setScheduleToUpdate(newScheduleInfo));
            dispatch(setMutationRefresh(true));
        } else {
            setItemNameError(true);
            //Create new scheduleToUpdateInfo
            const newScheduleInfo = scheduleToUpdate;
            newScheduleInfo.name = e.target.value;
            //Update current store
            dispatch(setScheduleToUpdate(newScheduleInfo));
            dispatch(setMutationRefresh(true));
        }
    };

    //Listeners
    useEffect(() => {
        setItemNameError(validation);
    }, [validation]);

    return (
        <>
            <input
                type="text"
                defaultValue={scheduleToUpdate.name}
                className={`w-full h-10 bg-gray-200 rounded relative mr-4 p-2 ${
                    !itemNameError ? "border border-red-100" : ""
                }`}
                onChange={changeNameHandler}
            ></input>
            {!itemNameError ? (
                <p className="absolute font-normal text-red-100">
                    {
                        langStrings[
                            `${langStrings["please"]}, ${langStrings["write-name"]} ${langStrings["of"]} ${langStrings["schedule"]}.`
                        ]
                    }
                </p>
            ) : null}
        </>
    );
};

export default UseInputNameEditSchedule;
