import React from "react";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import UseSwitch from "../../../Inputs/useSwitch";
import AccessTypesEmail from "./AccessTypesEmail";
import AccessTypesPMS from "./AccessTypesPMS";
import AccessTypesCode from "./AccessTypesCode";
import AccessTypesOpen from "./AccessTypesOpen";

const AccessTypesCard = ({
    info,
    cardEdition,
    setCardEdition,
    mainSwitches,
    setMainSwitches,
    setPreviewSelected,
    setStepPreview,
    setKeepCurrentPreview,
    setUpdateSwitch,
    cardsInfo,
    accessTypesData,
    fields,
    setFields,
    backupFields,
    setSelectMutation,
}) => {
    const { t } = useTranslation();

    const checkInfoByCard = (cardInfo, position) => {
        return cardInfo?.id === cardsInfo[position]?.id && cardEdition === cardInfo?.id;
    };

    return (
        <div className=" py-2 px-10 mb-4 bg-white rounded shadow-sm" key={info.id}>
            <div style={{ minHeight: "72px" }} className=" w-full flex items-center justify-between ">
                <div
                    className=" icon icon-edit text-gray-800 text-xl cursor-pointer "
                    id={`access-type-card-${info.name}-edit`}
                    style={{ opacity: `${cardEdition === info.id ? "0" : "1"}` }}
                    onClick={() => {
                        setCardEdition(info.id);
                        if (mainSwitches[info.access_type]) {
                            setPreviewSelected(info.access_type);
                        }
                        setStepPreview(null);
                    }}
                ></div>
                <div className=" flex items-center h-full text-lg font-bold">{capitalizeFirst(t(info.name))}</div>
                <div className=" mt-1">
                    <UseSwitch
                        id={`access-type-card-${info.name}-enable`}
                        checked={mainSwitches && mainSwitches[info.access_type]}
                        action={() => {
                            setMainSwitches({
                                ...mainSwitches,
                                [info.access_type]: !mainSwitches[info.access_type],
                            });
                            setUpdateSwitch({ update: true, atToUpdate: info.access_type });

                            if (!mainSwitches[info.access_type] === true) {
                                setPreviewSelected(info.access_type);
                                setKeepCurrentPreview(true);
                            } else {
                                setKeepCurrentPreview(false);
                            }
                        }}
                    />
                </div>
            </div>
            {checkInfoByCard(info, 0) ? (
                <AccessTypesEmail
                    emailData={accessTypesData.emailData}
                    setCardEdition={setCardEdition}
                    setEmailFields={setFields.setEmailFields}
                    emailFields={fields.emailFields}
                    emailFieldsBackup={backupFields.emailFieldsBackup}
                    setKeepCurrentPreview={setKeepCurrentPreview}
                    setSelectMutation={setSelectMutation}
                />
            ) : null}
            {checkInfoByCard(info, 1) ? (
                <AccessTypesPMS
                    pmsData={accessTypesData.pmsData}
                    setCardEdition={setCardEdition}
                    setPmsFields={setFields.setPmsFields}
                    pmsFields={fields.pmsFields}
                    pmsFieldsBackup={backupFields.pmsFieldsBackup}
                    setKeepCurrentPreview={setKeepCurrentPreview}
                    setSelectMutation={setSelectMutation}
                />
            ) : null}
            {checkInfoByCard(info, 2) ? (
                <AccessTypesCode
                    codeData={accessTypesData.codeData}
                    setCardEdition={setCardEdition}
                    setCodeFields={setFields.setCodeFields}
                    codeFields={fields.codeFields}
                    codeFieldsBackup={backupFields.codeFieldsBackup}
                    setKeepCurrentPreview={setKeepCurrentPreview}
                    setSelectMutation={setSelectMutation}
                />
            ) : null}
            {checkInfoByCard(info, 3) ? (
                <AccessTypesOpen
                    openData={accessTypesData.openData}
                    setCardEdition={setCardEdition}
                    setOpenFields={setFields.setOpenFields}
                    openFields={fields.openFields}
                    openFieldsBackup={backupFields.openFieldsBackup}
                    setKeepCurrentPreview={setKeepCurrentPreview}
                    setSelectMutation={setSelectMutation}
                />
            ) : null}
        </div>
    );
};

export default AccessTypesCard;
