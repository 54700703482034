import React from "react";
import { ALARM_TYPE } from "hooks/Utils/Customers/AlarmUtils";
import _ from "lodash";
import Icon from "components/Icon";

export const createRoomNumbersLabels = (rooms) => {
    let _rooms = JSON.parse(rooms);
    if (_rooms) {
        _rooms = _.pick(
            _rooms,
            Object.keys(_rooms).sort((a, b) => a.localeCompare(b))
        );
    }
    const newLabel = (index, text) => {
        return (
            <span className="bg-blue-300 text-white px-4 py-1 rounded" key={index} alt={text}>
                {text}
            </span>
        );
    };

    const labels = Object.keys(_rooms).map((roomName, index) => {
        return newLabel(index, roomName);
    });

    return labels;
};

export const getHour = (startTime) => {
    const _starTime = new window.ZDate(startTime);
    return (
        _starTime.toLocaleTimeString("es-ES", {
            hour: "2-digit",
            minute: "2-digit",
        }) || ""
    );
};

export const getDate = (startTime) => {
    const _starTime = new window.ZDate(startTime);
    return (
        _starTime.toLocaleTimeString([], {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        }) || ""
    );
};

export const parseDateString = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
};

export const getLastUpdate = (lastUpdate) => {
    const _lastUpdate = new window.ZDate(lastUpdate);
    return (
        `${
            _lastUpdate
                ? _lastUpdate.toLocaleTimeString([], {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                  })
                : ""
        } ${
            _lastUpdate
                ? _lastUpdate.toLocaleTimeString("es-ES", {
                      hour: "2-digit",
                      minute: "2-digit",
                  })
                : ""
        }` || ""
    );
};

export const getExpandedRow = (rooms, type, t, channelName) => {
    let _rooms = JSON.parse(rooms);
    if (_rooms) {
        _rooms = _.pick(
            _rooms,
            Object.keys(_rooms).sort((a, b) => a.localeCompare(b))
        );
    }

    const expandedInfoData = (index, roomName) => {
        return (
            <div className=" px-4 transition-all duration-300 ease-in-out py-4" style={{ marginLeft: "4.5rem" }}>
                <div className="text-sm text-gray-800 leading-6 grid grid-cols-2 w-7/12" key={index}>
                    <div>
                        <h1 className="font-bold">{t("locations")}</h1>
                        <ul>
                            {_rooms[roomName]?.map((roomTv, index) => {
                                return <li key={index}>{`${roomName}: ${roomTv.location}`}</li>;
                            })}
                        </ul>
                    </div>
                    <div>
                        <h1 className="font-bold">{t("alarm-type")}</h1>
                        <span>
                            {type === ALARM_TYPE.BEEP ? (
                                `Beep`
                            ) : (
                                <>
                                    `{t("channels")} <br /> - {channelName} -`
                                </>
                            )}
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const expandedInfo = Object.keys(_rooms).map((roomName, index) => {
        return expandedInfoData(index, roomName);
    });
    return expandedInfo;
};

export const getEditedBy = (isFromGuest, t) => {
    return isFromGuest ? (
        <span alt="by-guest">
            <Icon type="user-regular" size="xl" tooltip={t("edited-by-guest")} />
        </span>
    ) : (
        <span alt="by-staff"></span>
    );
};

export const extractRoomsList = (roomData) => {
    let roomArray = [];
    // eslint-disable-next-line
    roomData?.roomsData?.results?.map((alarm) => {
        const rooms = Object.keys(JSON.parse(alarm.rooms));
        rooms.forEach((roomName) =>
            roomArray.filter((r) => r.id === roomName).length === 0
                ? roomArray.push({
                      id: roomName,
                      value: roomName,
                      label: roomName,
                      visible: true,
                  })
                : null
        );
    });

    roomArray.sort((a, b) => a.value.localeCompare(b.value));

    return roomArray;
};

export const subtractHours = (timeString, hoursToSubtract) => {
    const [hours, minutes] = timeString.split(":");
    const date = new Date();
    date.setHours(parseInt(hours));
    date.setMinutes(parseInt(minutes));

    date.setHours(date.getHours() - hoursToSubtract);

    // Formatear de nuevo a "HH:mm"
    const newHours = date.getHours().toString().padStart(2, "0");
    const newMinutes = date.getMinutes().toString().padStart(2, "0");

    return `${newHours}:${newMinutes}`;
};

export const getCurrentHour = () => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours().toString().padStart(2, "0");
    const currentMinute = currentDate.getMinutes().toString().padStart(2, "0");
    return `${currentHour}:${currentMinute}`;
};

export const formatDateToISO = (dateString) => {
    const [day, month, year, time] = dateString.split(/[-T]/);
    const formattedDate = new Date(`${year}-${month}-${day}T${time}`);
    return formattedDate.toISOString();
};

export const arrangeAlarmRoomsData = (data) => {
    return data?.map((room) => {
        return {
            value: room.name,
            label: room.name,
            rooms: room.stayGuestsRoom.map((guest) => {
                return {
                    id: guest.id,
                    label: `${guest.stayGuest.name} ${guest.stayGuest.surname}`,
                };
            }),
        };
    });
};

export const arrangeAlarmGuestData = (data) => {
    return data?.results.map((guest) => {
        return {
            value: guest.id,
            label: `${guest.name} ${guest.surname}`,
        };
    });
};

export const getTheCorrectDate = (date) => {
    let dateArray;
    if (date.includes("-")) {
        dateArray = date.split("-");
    } else {
        dateArray = date.split("/");
    }
    return new Date(`${dateArray[1]}-${dateArray[0]}-${dateArray[2]}`);
};
