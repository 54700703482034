import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import UseInputText from "../../../../../Inputs/useInputText";
import UseInputPercent from "../../../../../Inputs/useInputPercent";
import { capitalizeT } from "../../../../../../hooks/Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { setSectionContent } from "../../../../../../actions/sectionActions";
import { getRGBA, hexToRgbA, rgbaToHex } from "../../../../../../hooks/Utils/DesignUtils";
import { setColorPickerReference } from "../../../../../../actions/designActions";
import { ID, decodeID, SALES_OPTIONS_KEYS } from "../../../../../../hooks/Utils/Design/SalesUtils";

const UseBorder = ({ group, data, showTitle }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { styleInputData } = useSelector((state) => state.sectionContent);
    const [inputHexError, setInputHexError] = useState(false);

    const _key = useRef();
    _key.current = styleInputData?.key;

    const onBlurInputWidthCallback = (value, id) => {
        onBlurAction(value, id);
    };
    const ID_TEXT_INPUT = _key?.current?.listOption ? `${_key.current?.listOption}-color` : "color";
    const ID_TEXT_INPUT_OPACITY = _key?.current?.listOption
        ? `${_key.current?.listOption}-color-opacity`
        : "color-opacity";

    const createPickerCallBack = () => {
        window.onSelectBorderCallback = (params) => {
            const { color, group } = params;
            onBlurAction(color, ID("color", group));
        };
    };

    useEffect(() => {
        if (!window?.onSelectBorderCallback) {
            createPickerCallBack();
        }
        return () => {
            window.onSelectBorderCallback = null;
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!window?.onSelectBorderCallback) {
            createPickerCallBack();
        }
        // eslint-disable-next-line
    }, [styleInputData]);

    const changeColorInputsFields = (value, id) => {
        const { group } = decodeID(id);
        const colorID = ID(ID_TEXT_INPUT, group);
        const opacityID = ID(ID_TEXT_INPUT_OPACITY, group);

        setInputHexError(false);
        const _hex = document.getElementById(colorID).value;
        const _alpha = document.getElementById(opacityID).value.replace(" %", "");

        if (!_hex || _hex !== "") {
            try {
                const _rgbaColor = hexToRgbA(_hex, _alpha);
                onBlurAction(_rgbaColor, ID(SALES_OPTIONS_KEYS.COLOR, group));
            } catch (error) {
                setInputHexError(true);
            }
        }
    };

    const onBlurAction = (value, id) => {
        const { field, group } = decodeID(id);
        const keyOption = { ..._key.current, option: "border", field, group };

        dispatch(
            setSectionContent({
                styleInputData: { ...styleInputData, key: { ...keyOption }, value },
            })
        );
    };

    if (!_key?.current?.listOption) {
        return null;
    }

    const hasColor = data?.hasOwnProperty("color");
    const hasWidth = data?.hasOwnProperty("width");
    const hasRadius = data?.hasOwnProperty("radius");

    const color = data?.color;
    const colorHex = rgbaToHex(data?.color);
    const colorAlpha = getRGBA(data?.color).a;
    const colorTitle = capitalizeT(showTitle ? "color" : "border-color", t);

    const inputColor = (
        <>
            <div className="mb-2 w-full">{colorTitle}</div>
            <div className="inline-flex">
                <div className="w-36 my-auto inline-flex">
                    <div
                        id={`sales-color-background-picker`}
                        className={`border h-8 w-8 rounded-lg ${!color && "icon-no-color text-35xl p-px text-red-100"}`}
                        style={{
                            background: color,
                        }}
                        onClick={(e) => {
                            dispatch(
                                setColorPickerReference({
                                    id: `sales-color-background-picker`,
                                    property: `color`,
                                    color: color,
                                    group,
                                    action: "onSelectBorderCallback",
                                })
                            );
                            e.stopPropagation();
                        }}
                    ></div>
                    <div className="m-auto w-24">
                        <UseInputText
                            id={ID(ID_TEXT_INPUT, group)}
                            name={ID_TEXT_INPUT}
                            ph={"#"}
                            adjust={`h-8 mx-1 ${inputHexError ? "border border-red-100 " : ""}`}
                            value={colorHex}
                            action={changeColorInputsFields}
                        />
                    </div>
                </div>
                <div className="w-24">
                    <UseInputPercent
                        id={ID(ID_TEXT_INPUT_OPACITY, group)}
                        value={color ? colorAlpha * 100 : 100}
                        adjust={"h-8"}
                        action={changeColorInputsFields}
                    />
                </div>
            </div>
        </>
    );

    const inputWidth = (
        <div>
            <div className="mt-2 inline-flex justify-between min-w-60">
                <div className=" my-auto mr-4">{capitalizeT(showTitle ? "width" : "border-width", t)}</div>
                <div className="w-16">
                    <UseInputPercent
                        id={ID("width", group)}
                        value={data?.width ? data.width : 0}
                        adjust={"h-8"}
                        percentDisplay={""}
                        action={onBlurInputWidthCallback}
                    />
                </div>
            </div>
        </div>
    );

    const inputRadius = (
        <div>
            <div className="mt-2 inline-flex justify-between min-w-60">
                <div className=" my-auto mr-4">{capitalizeT("radius", t)}</div>
                <div className="w-16">
                    <UseInputPercent
                        id={ID("radius", group)}
                        value={data?.radius ? data.radius : 0}
                        adjust={"h-8"}
                        percentDisplay={""}
                        action={onBlurInputWidthCallback}
                    />
                </div>
            </div>
        </div>
    );

    const inputs = [];

    if (hasColor) {
        inputs.push(inputColor);
    }
    if (hasWidth) {
        inputs.push(inputWidth);
    }
    if (hasRadius) {
        inputs.push(inputRadius);
    }

    return inputs.length > 0 ? (
        <div className="mt-4 w-full px-8">
            {showTitle ? <div className="mb-2 w-full font-bold">{capitalizeT("border", t)}</div> : null}
            {inputs}
        </div>
    ) : null;
};

export default UseBorder;
