import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { changeActionValues } from "../../actions/globalActions";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Utils
import { multipleTranslate } from "../../hooks/Utils/Utils";

const UseModalFiltersResults = ({ index }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { text, name, charLimit, optionData, optionsQuery } = useSelector(
        (state) => state.ui.modalContent.inputs[index]
    );
    const { values } = useSelector((state) => state.action);
    //States
    const [currentLength] = useState(0);
    const [dataOptions, setDataOptions] = useState(
        optionData ? JSON.parse(JSON.stringify(optionData)) : [{ selected: true, id: 1, name: "eee" }]
    );
    const [initialAllOptions, setInitialAllOptions] = useState(optionData);

    //API
    const GET_OPTIONS = optionsQuery
        ? gql`
              ${optionsQuery}
          `
        : null;
    const [getOptionsQuery, { data }] = useLazyQuery(GET_OPTIONS);

    //Actions
    const dispatch = useDispatch();

    useEffect(() => {
        if (!dataOptions) {
            return;
        }
        if (values && values[name] && Object.keys(values[name]).length > 0 && initialAllOptions) {
            const temCopy = JSON.parse(JSON.stringify(initialAllOptions));
            temCopy.map((item) =>
                Object.values(values[name]).includes(parseInt(item.id))
                    ? (item.selected = true)
                    : (item.selected = false)
            );
            //   setDataOptions(temCopy);
        } else {
            // setDataOptions([]);
        }
        // eslint-disable-next-line
    }, [values]);

    useEffect(() => {
        //get options from query
        if (optionsQuery) {
            getOptionsQuery();
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (data && data.data && data.data.results) {
            let tmpOptions = [];
            const idsToSelect = data.selected && data.selected.results ? data.selected.results : null;
            data.data.results.map((item) =>
                tmpOptions.push({
                    id: item.id,
                    name: `${item.name} ${item.surname ? item.surname : ""}`,
                    selected: idsToSelect ? (idsToSelect.find((aItem) => aItem.id === item.id) ? true : false) : false,
                    visible: true,
                })
            );
            setInitialAllOptions(tmpOptions);
            setDataOptions(tmpOptions);
        }
    }, [data]);

    const removeOption = (e) => {
        e.stopPropagation();
        const temCopy = JSON.parse(JSON.stringify(dataOptions));
        temCopy.map((item) =>
            parseInt(item.id) === parseInt(e.currentTarget.dataset.id) ? (item.selected = false) : null
        );

        setDataOptions(temCopy);

        const selectedIds = temCopy.map((item) => (item.selected ? item.id : null));
        dispatch(
            changeActionValues({
                [name]: selectedIds.filter((item) => item !== null),
            })
        );
    };

    return (
        <>
            {text ? <span className="block pb-2 first-capital">{multipleTranslate(text, langStrings)}</span> : null}
            <div className="w-full overflow-y-auto bg-gray-200 rounded p-5 " style={{ height: "40vh" }}>
                <div className="w-full mb-4">
                    <span className="font-bold">10 {langStrings["rooms"]}</span>
                </div>
                {dataOptions.map((item) =>
                    item.selected ? (
                        <div
                            key={item.id}
                            className="float-left z-20  bg-blue-200 text-white pl-2 mr-4 p-1 mb-2 rounded whitespace-no-wrap"
                        >
                            {item.name}
                            <div
                                className="icon-remove inline-block ml-8 cursor-pointer"
                                data-index={index}
                                data-id={item.id}
                                onClick={removeOption}
                            ></div>
                        </div>
                    ) : null
                )}
            </div>
            {charLimit ? (
                <p className="text-sm text-right">{`${currentLength} / ${charLimit} ${langStrings["chars"]}`}</p>
            ) : null}
        </>
    );
};

export default UseModalFiltersResults;
