import React from "react";
import { useSelector } from "react-redux";

const UseScheduleHeader = ({ header }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    //Data
    const { name, translate, specialWidth } = header;
    const content = translate ? langStrings[name] : name;
    return (
        <div className={`font-bold text-left inline-block text-gray-900 first-capital ${specialWidth}`}>{content}</div>
    );
};

export default UseScheduleHeader;
