import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import translate from "../../lang/es/Global/ModalContent";
//Components
import UseIcon from "../useIcon";
import UseSelectOption from "../Table/useSelectOption";
//Actions
import { changeActionValues, setActionInput } from "../../actions/globalActions";

const UseModalOptions = ({ index, dynamicValues }) => {
    //Store data
    const { name, values, optionData, cHeight } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { CZList, roomTvsList } = useSelector((state) => state.ui);
    const { actionName } = useSelector((state) => state.action);
    const selected = useSelector((state) => state.action.values[name]);
    const [optionValues] = useState(dynamicValues ? dynamicValues : values);

    //Actions
    const dispatch = useDispatch();

    const handleClick = (e) => {
        if (e.currentTarget.dataset.actionid) {
            dispatch(changeActionValues({ [name]: e.currentTarget.dataset.actionid }));
        } else {
            dispatch(changeActionValues({ [name]: e.currentTarget.dataset.id }));
        }
    };

    const handleCategoryClick = (e) => {
        dispatch(
            changeActionValues({
                "area-move-category": e.currentTarget.dataset.name,
                "area-move-category-id": e.currentTarget.dataset.id,
            })
        );
    };

    const handleClickMixedData = (e) => {
        const dataToAction = {
            responsible:
                e.currentTarget.dataset.type === "int"
                    ? Number(e.currentTarget.dataset.id)
                    : e.currentTarget.dataset.id,
        };
        dispatch(setActionInput(dataToAction));
        dispatch(changeActionValues({ responsible: e.currentTarget.dataset.name }));
    };

    const getSelectedStyles = (currentData) => {
        let res = "";
        if (selected === currentData) {
            res = "font-bold text-blue-100";
        }
        return res;
    };
    return (
        <div
            className={`bg-white rounded shadow-lg ${
                cHeight ? cHeight : "max-h-64"
            } border border-gray-200 overflow-auto p-2`}
        >
            {actionName !== "area-move-category" && optionValues
                ? optionValues.map((value, index) => (
                      <div
                          className="h-10 hover:bg-gray-200 w-56 cursor-pointer"
                          onClick={handleClick}
                          data-id={value.value}
                          key={index}
                          value={value.value}
                          data-actionid={value.id}
                      >
                          <div
                              className={`t-select-option p-2 ${
                                  selected === value.value ? "font-bold text-blue-100" : ""
                              }`}
                              name={value.value}
                              value={value.value}
                          >
                              {value.icon ? (
                                  <UseIcon icon={value.icon} type={value.itemType} adjust="t-icon-select" />
                              ) : null}
                              {value.prefix
                                  ? `${translate(value.prefix).charAt(0).toUpperCase()} ${translate(value.prefix).slice(
                                        1
                                    )} `
                                  : null}
                              {value.value ? translate(value.value) : null}
                              {value.sufix ? ` ${translate(value.sufix)}` : null}
                          </div>
                      </div>
                  ))
                : null}
            {optionData
                ? optionData.map((value, index) =>
                      index > 0 ? (
                          <div
                              className="h-10 hover:bg-gray-100 w-56 cursor-pointer"
                              onClick={handleClickMixedData}
                              data-id={value[1]}
                              data-name={value[0]}
                              data-type={value[2]}
                              key={index}
                          >
                              <div className={`t-select-option p-2 ${getSelectedStyles(value[1].toString())}`}>
                                  {index !== 0 ? value[0] : null}
                              </div>
                          </div>
                      ) : null
                  )
                : null}
            {actionName === "area-move-category" ? (
                <>
                    {CZList.map((option, index) => (
                        <span key={index}>
                            <div
                                className="h-10 hover:bg-gray-100 w-56"
                                onClick={handleCategoryClick}
                                data-id={option.id}
                                data-name={option.name}
                            >
                                <UseSelectOption filterName={option.name} filterValue={option.name} id={index} />
                            </div>
                        </span>
                    ))}
                </>
            ) : null}
            {actionName === "chromecast-move-room" ? (
                <>
                    {roomTvsList.map((option, index) => (
                        <span key={index}>
                            <div
                                className="h-10 hover:bg-gray-100 w-56"
                                onClick={handleCategoryClick}
                                data-id={option.id}
                                data-name={option.name}
                            >
                                <UseSelectOption filterName={option.name} filterValue={option.name} />
                            </div>
                        </span>
                    ))}
                </>
            ) : null}
        </div>
    );
};

export default UseModalOptions;
