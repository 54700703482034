import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseSchedule from "./useSchedule";
import UseButton from "../useButton";
import UseFormPeriodic from "./useFormPeriodic";
import UseFormCommonUpdate from "./useFormCommonUpdate";
import UseFormSpecialUpdate from "./useFormSpecialUpdate";
import UseFormFestiveUpdate from "./useFormFestiveUpdate";
import WizardFilter from "../Wizard/WizardFilter";
import UseScheduleHeader from "./useScheduleHeader";
//Actions
import { setWizardAllowNext, showAddPeriodicSchedule } from "../../actions/sectionActions";
import { setActionName, setActionItemAffected } from "../../actions/globalActions";
//Utils
import { useParams } from "react-router-dom";
import { classifyPeriodicEvent } from "../../hooks/Utils/ScheduleDisplayTools";

const UsePeriodicEvent = () => {
    //Store data
    const {
        schedulePeriodicForm,
        periodicFilter,
        stepsData,
        editCommonForm,
        editSpecialForm,
        editFestiveForm,
        currentStep,
    } = useSelector((state) => state.sectionContent);
    const { currentData } = useSelector((state) => state.sectionContent.periodicEventsData);
    const { id } = useParams();

    //States
    const [filterActive, setFilterActive] = useState();
    const [editCommonSchedule, setEditCommonSchedule] = useState(false);
    const [editSpecialSchedule, setEditSpecialSchedule] = useState(false);
    const [editFestiveSchedule, setEditFestiveSchedule] = useState(false);

    //Data
    const periodicEventsClassified = classifyPeriodicEvent(currentData);
    const dataToDisplay = getDataToDisplay(periodicEventsClassified, filterActive);
    const headers = getHeaders(stepsData, filterActive, editFestiveSchedule, editSpecialSchedule);

    //Actions
    const dispatch = useDispatch();

    const handleClickNew = () => {
        dispatch(showAddPeriodicSchedule(true));
        dispatch(setActionName("add-periodic"));
        dispatch(setActionItemAffected([id]));
    };

    //Listeners
    useEffect(() => {
        if (periodicFilter) {
            setFilterActive(periodicFilter);
        }
    }, [periodicFilter]);

    useEffect(() => {
        let commonFormState = false;
        let specialFormState = false;
        let festiveFormState = false;
        if (editCommonForm) {
            commonFormState = true;
        }
        if (editSpecialForm) {
            specialFormState = true;
        }
        if (editFestiveForm) {
            festiveFormState = true;
        }
        setEditCommonSchedule(commonFormState);
        setEditSpecialSchedule(specialFormState);
        setEditFestiveSchedule(festiveFormState);
        if (editCommonForm || editSpecialForm || editFestiveForm) {
            dispatch(setWizardAllowNext(false));
        }
        // eslint-disable-next-line
    }, [editCommonForm, editSpecialForm, editFestiveForm]);

    return (
        <>
            <WizardFilter />
            {dataToDisplay && !currentStep ? (
                <div className="schedule-existing-row px-8 bg-transparent h-5 my-4">
                    <div className="w-full grid grid-flow-col grid-cols-12 gap-2">
                        {headers.map((header, index) => (
                            <UseScheduleHeader header={header} key={index} />
                        ))}
                    </div>
                </div>
            ) : null}
            {editCommonSchedule ? <UseFormCommonUpdate setEditCommonSchedule={setEditCommonSchedule} /> : null}
            {editSpecialSchedule ? <UseFormSpecialUpdate setEditSpecialSchedule={setEditSpecialSchedule} /> : null}
            {editFestiveSchedule ? <UseFormFestiveUpdate setEditFestiveSchedule={setEditFestiveSchedule} /> : null}
            <>
                {dataToDisplay &&
                !editCommonSchedule &&
                !editSpecialSchedule &&
                !editFestiveSchedule &&
                !schedulePeriodicForm
                    ? dataToDisplay.map((currentEvent, index) => (
                          <span key={index}>
                              <UseSchedule currentEvent={currentEvent} filterActive={filterActive} />
                          </span>
                      ))
                    : null}
                {!editCommonSchedule && !editSpecialSchedule && !editFestiveSchedule && !schedulePeriodicForm ? (
                    <div id="button-row" className="w-full flex justify-end h-10 pt-4">
                        <div className="w-auto inline-block" onClick={handleClickNew}>
                            <UseButton buttonName="add" buttonColor="btn-blue" icon="add" />
                        </div>
                    </div>
                ) : null}
                {schedulePeriodicForm || !currentData ? <UseFormPeriodic /> : null}
            </>
        </>
    );
};

const getDataToDisplay = (periodicEventsClassified, filterActive) => {
    //Returns array of schedules dependig of filterActive
    let res;
    if (!filterActive || filterActive === "common-schedule") {
        res = periodicEventsClassified.habitual;
    } else if (filterActive === "special-schedule") {
        res = periodicEventsClassified.special;
    } else {
        res = periodicEventsClassified.festive;
    }
    return res;
};

const getHeaders = (stepsData, filterActive, editFestiveSchedule, editSpecialSchedule) => {
    let res;
    switch (filterActive) {
        case "common-schedule":
            res = stepsData[0].headers;
            break;
        case "special-schedule":
            if (editSpecialSchedule) {
                res = [];
            } else {
                res = stepsData[1].headers;
            }
            break;
        case "closed-days":
            if (editFestiveSchedule) {
                res = [
                    {
                        name: "name",
                        translate: true,
                        specialWidth: "col-span-3 pr-4",
                    },
                    {
                        name: "date-type",
                        translate: true,
                        specialWidth: "col-span-3 pr-4",
                    },
                    {
                        name: "dates",
                        translate: true,
                        specialWidth: "col-span-3 pr-4",
                    },
                ];
            } else {
                res = stepsData[2].headers;
            }

            break;
        default:
            res = [];
    }
    return res;
};
export default UsePeriodicEvent;
