import React, { useEffect } from "react";
import {
    DRAFTS_MSG,
    getDateFormated,
    PriorityOptions,
    SCHEDULED_MSG,
    SENT_MSG,
    SPECIFIC_DAY,
} from "../../../../hooks/Utils/Customers/MessagesUtils";
import UseEditableField from "../../../useEditableField";
import { useTranslation } from "react-i18next";
import { SessionDateToNew, timeFromUTC } from "../../../../date";
import { useParams, useNavigate } from "react-router-dom";
import Button from "components/Button";
import Icon from "components/Icon";
import { UseModalDeleteScheduleMessages } from "./modals/useModalDeleteScheduleMessage";
import { UseModalScheduleMessages } from "./modals/useModalScheduleMessages";

export const SentMessageDetail = ({ msgData, inEdition, headTitle, previusSection = SENT_MSG.value }) => {
    const { t } = useTranslation();
    const { msgFolder, id } = useParams();
    const navigate = useNavigate();
    const { open: openModalDeleteSchedule } = UseModalDeleteScheduleMessages();
    const { open: openScheduleMessage } = UseModalScheduleMessages();
    const scheduleDate = new Date(msgData?.scheduleDate);

    const printDays = (days) => {
        if (days) {
            const values = {
                1: "sunday-short-version",
                2: "monday-short-version",
                3: "tuesday-short-version",
                4: "wednesday-short-version",
                5: "thursday-short-version",
                6: "friday-short-version",
                7: "saturday-short-version",
            };
            const parseNumberDaysToDays = days?.map((val) => values[val]);

            return (
                <>
                    {parseNumberDaysToDays.map((val, index) => {
                        return (
                            <p key={`day-${val}`}>
                                {index > 0 ? "-" : ""}
                                {t(val)}
                            </p>
                        );
                    })}
                </>
            );
        } else {
            return null;
        }
    };

    const sentDate = msgData?.sentTime
        ? getDateFormated(msgData.sentTime)
        : t("{{capitalize, capitalize}}", { capitalize: t("unspecified") });
    const _expirationDay = msgData?.expirationTime
        ? new window.ZDate(msgData.expirationTime).toLocaleDateString("en-Gb", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
          })
        : null;
    const _expirationHour = msgData?.expirationTime
        ? new window.ZDate(msgData.expirationTime).toLocaleTimeString("es-ES", {
              hour: "2-digit",
              minute: "2-digit",
          })
        : null;

    const expirationDate = msgData?.expirationTime
        ? getDateFormated(msgData.expirationTime)
        : t("{{capitalize, capitalize}}", { capitalize: t("unspecified") });

    const _sentBy = msgData?.sentBy?.fullName || "";
    const spanSentBy = msgData?.sentBy
        ? ` <span class="text-gray-600">- ${t("{{capitalize, capitalize}}", {
              capitalize: t("sent-by", { name: _sentBy }),
          })} -</span>`
        : "";
    return (
        <div className="bg-white col-span-3 rounded p-6">
            <div className="pb-6 w-4/6">
                <UseEditableField
                    data={{
                        label: "type",
                        labelAdjust: "font-bold text-gray-900",
                        required: inEdition,
                        inEditionMode: inEdition,
                        hideSearch: true,
                        type: "select-with-search",
                        placeholder: "select type",
                        oneSelected: true,
                        optionData: PriorityOptions(t),
                        selectedIds: msgData?.priority ? [msgData.priority.toLowerCase()] : null,
                        noEditionAdjust: "text-lg",
                        adjustContainerOption: "flex-col items-start justify-start",
                        value: msgData?.priority
                            ? [t("{{capitalize, capitalize}}", { capitalize: t(msgData.priority.toLowerCase()) })]
                            : null,
                    }}
                ></UseEditableField>
            </div>
            <UseEditableField
                data={{
                    label: "expiration",
                    labelAdjust: "font-bold text-gray-900",
                    value: expirationDate,
                    date: _expirationDay,
                    hour: _expirationHour,
                    inEditionMode: inEdition,
                    noEditionAdjust: "text-lg pb-6",
                    type: "date&time",
                    minDate: SessionDateToNew(),
                    id: "message-section-expiration",
                }}
            ></UseEditableField>
            {!inEdition && previusSection === SENT_MSG.value && (
                <UseEditableField
                    data={{
                        label: "sent date",
                        labelAdjust: "font-bold text-gray-900",
                        value: sentDate ? sentDate + spanSentBy : null,
                        date: null,
                        hour: null,
                        inEditionMode: inEdition,
                        noEditionAdjust: "text-lg",
                        type: "date&time",
                        id: "message-section-sent-date",
                    }}
                ></UseEditableField>
            )}
            {msgFolder == SCHEDULED_MSG.name && (
                <div className=" mt-6">
                    <p className="fieldLabel font-bold text-gray-900">{t("scheduling")}</p>
                    <div className="flex gap-4">
                        {msgData?.scheduleType === SPECIFIC_DAY ? (
                            <div className="flex gap-4">
                                <p>{new window.ZDate(scheduleDate).toLocaleDateString()}</p>
                                <p>{new window.ZDate(scheduleDate).toLocaleTimeString()}</p>
                            </div>
                        ) : (
                            <div className="flex gap-4">
                                <div className="flex">{printDays(msgData?.scheduleDaysOfWeek)}</div>
                                <p>{timeFromUTC(msgData?.scheduleTime)}</p>
                            </div>
                        )}
                        <Button
                            id="btn-edit-scheduling"
                            onClick={() => {
                                openScheduleMessage({
                                    id: msgData?.idMessage,
                                    name: headTitle,
                                    edit: true,
                                    scheduleDaysOfWeek: msgData?.scheduleDaysOfWeek,
                                    scheduleDate: msgData?.scheduleDate,
                                    scheduleTime: msgData?.scheduleTime,
                                    scheduleType: msgData?.scheduleType,
                                });
                            }}
                        >
                            <Icon type="edit" />
                        </Button>
                        <Button
                            id="btn-delete-scheduling"
                            onClick={() => {
                                openModalDeleteSchedule({ ids: [id], name: headTitle, navigate });
                            }}
                        >
                            <Icon type="delete" />
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};
