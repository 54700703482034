import React, { useEffect } from "react";
import UseSectionHeader from "../../useSectionHeader";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { useDispatch, useSelector } from "react-redux";
import { getFromDAS } from "hooks/Utils/Utils";
import { getMobileAppQuery, getMobileAppDesign } from "../Hotel/MobileSettings/MobileSettingsUtils";
import { showGlobalLoading as setShowGlobalLoading } from "actions/uiActions";
import UpdateMobileAppearance from "hooks/GraphqlCalls/Design/UpdateMobileAppearance";
import { setRefreshContentData } from "actions/sectionActions";
import useChooseAppearance from "./Modals/useChooseAppearanceModal";

import { useAuth } from "hooks/Session/auth";

export const MobileApparences = () => {
    const { t } = useTranslation();
    const { refreshData } = useSelector((state) => state.sectionContent);
    const { lang, isCorporate } = useAuth();
    const GET_DATA_MOBILE_APP = gql(getMobileAppQuery(isCorporate ? `(filter:{isMultiProperty:true})` : ""));
    const dispatch = useDispatch();
    const { open: openModalChooseAppearance } = useChooseAppearance();

    const [executeQueryData, { data: app }] = useLazyQuery(GET_DATA_MOBILE_APP, {
        fetchPolicy: "network-only",
        onCompleted: () => {
            executeQueryDesigns();
        },
    });
    const GET_DESIGN_MOBILE_APP = gql(getMobileAppDesign());
    const [executeQueryDesigns, { data: design }] = useLazyQuery(GET_DESIGN_MOBILE_APP, {
        fetchPolicy: "network-only",
        onCompleted: () => {
            dispatch(setShowGlobalLoading(false));
            dispatch(setRefreshContentData(false));
        },
    });

    useEffect(() => {
        if (executeQueryData) {
            executeQueryData();
            dispatch(setShowGlobalLoading(true));
        }
        return () => {};
    }, []);

    useEffect(() => {
        if (refreshData) {
            executeQueryDesigns();
            dispatch(setShowGlobalLoading(true));
        }
        return () => {};
    }, [refreshData]);

    return (
        <>
            <UpdateMobileAppearance></UpdateMobileAppearance>
            <UseSectionHeader title={["general-mobile-app"]} value={"demo"} />
            <div className=" pb-8">
                {!isCorporate && app?.mobileApps?.results?.some((app) => app?.isMultiProperty) ? (
                    <div className="flex gap-2">
                        <i className="icon icon-warning warning text-1xl"></i>
                        <p>{t("app-created-from-global")}</p>
                    </div>
                ) : (
                    <>
                        <p>{t("general-mobile-app-description-1")}</p>
                        <p>{t("general-mobile-app-description-2")}</p>
                    </>
                )}
            </div>
            {app?.mobileApps?.results.length > 0 &&
                app?.mobileApps.results.map((application, index) => {
                    const canEdit = isCorporate || !application.isMultiProperty;
                    const theme = design?.mobileAppThemes.find((theme) => {
                        const themeHasApp = theme?.mobileApps?.reduce((acc, curr) => {
                            if (curr.mobileAppId == application.id) {
                                acc = true;
                            }
                            return acc;
                        }, false);
                        return themeHasApp;
                    });
                    return (
                        <div
                            key={application?.id}
                            className="w-full bg-white p-2 rounded-md min-h-10 flex items-center justify-center mb-4"
                        >
                            <div className="px-8 flex flex-row w-full justify-between">
                                <div className="flex gap-3 justify-center items-center">
                                    {application?.config?.iconAssetRef ? (
                                        <div className=" w-12 h-12 bg-gray-100 border border-gray-300 flex items-center justify-center rounded">
                                            <img
                                                className="h-full w-full object-scale-down"
                                                src={getFromDAS(application?.config?.iconAssetRef, lang)}
                                            ></img>
                                        </div>
                                    ) : (
                                        <i className="icon icon-no-image mt-1"></i>
                                    )}
                                    <span className=" font-bold">{application?.name}</span>
                                </div>
                                <div className="flex w-1/2 gap-2 items-center">
                                    <span className=" font-bold capitalize">{t("appearance")}:</span>
                                    <span id="edit-mobile-appearance-name-selected">{theme?.name}</span>
                                    {canEdit ? (
                                        <button
                                            id="edit-mobile-appearance-button"
                                            className="icon icon-edit mt-1 cursor-pointer"
                                            onClick={() => {
                                                openModalChooseAppearance({
                                                    name: application?.name,
                                                    mobileAppID: application?.id,
                                                    designID: theme?.themeId,
                                                });
                                            }}
                                        ></button>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    );
                })}
            {app?.mobileApps?.results.length == 0 && (
                <div className="w-full bg-white p-2 rounded-md min-h-10 flex items-center justify-center mb-4">
                    <span>{t("no-mobile-added-yet")}</span>
                </div>
            )}
        </>
    );
};
