import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
//Components
import UseIcon from "../../useIcon";
import { Link } from "react-router-dom";

//Actions
import { openModal, setModalContent } from "../../../actions/uiActions";
import { changeGlobalAction } from "../../../actions/globalActions";
import { useTranslation } from "react-i18next";

const UseValueCellExtended = ({ cellData, rowIndex, colIndex }) => {
    const { t } = useTranslation();

    //State
    const [cellValue, setCellValue] = useState();

    //Data
    const { hidden, title, translateTitle, value, translateValue, icon, link, name, modal, separator, sufix } =
        cellData;
    const titleContent = translateTitle ? t(title) : title;
    const editIconStyle = " ml-2 text-2xl text-gray-800";
    const separatorStyle = separator ? "border-r border-gray-200" : "";
    const iconStyle = getIconStyle(value, editIconStyle);
    const prefixId = `use-value-cell-extender`;
    //Listener

    useEffect(() => {
        calculateCellValue();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        calculateCellValue();
        // eslint-disable-next-line
    }, [cellData]);

    const calculateCellValue = () => {
        setCellValue(value ? value : t("unspecified"));
        if (translateValue) {
            if (Array.isArray(value)) {
                let _stringValue = "";
                // eslint-disable-next-line
                value.forEach((item) => {
                    _stringValue = _stringValue + (t(item).length > 1 ? t(item) : item);
                });
                setCellValue(_stringValue);
            } else {
                setCellValue(t(value).length > 1 ? t(value) : value);
            }
        }
    };

    //Actions
    const dispatch = useDispatch();
    const showModal = (e) => {
        e.stopPropagation();
        dispatch(setModalContent(modal[0]));

        const initialInputData = () => {
            let selectedValues = {};
            // eslint-disable-next-line
            if (modal[0].inputs) {
                // eslint-disable-next-line
                modal[0].inputs.map((input) => {
                    selectedValues[input.name] = input.selected;
                });
            }
            return selectedValues;
        };

        dispatch(
            changeGlobalAction({
                actionName: e.currentTarget.dataset.action_name,
                itemsAffected: [e.currentTarget.dataset.id],
                values: initialInputData(),
            })
        );
        dispatch(openModal());
    };

    return (
        <>
            {!hidden ? (
                <div className="w-full pr-4 flex items-start">
                    <div className={`w-full py-4 pr-4 mr-4 ${separatorStyle}`}>
                        <div className="w-full inline-flex items-center">
                            {title ? (
                                <div className="w-full pb-4 flex items-center">
                                    <div
                                        id={`${prefixId}-${`r${rowIndex + 1}-c${colIndex + 1}`}-title-content`}
                                        className="inline-block font-bold text-base mr-3 first-capital"
                                    >
                                        {titleContent}:
                                    </div>
                                    {icon ? (
                                        <Link id={`${prefixId}-${`r${rowIndex + 1}-c${colIndex + 1}`}-link`} to={link}>
                                            <div
                                                className="w-10 h-5 inline-block"
                                                onClick={!link ? showModal : null}
                                                data-id={modal ? modal[0].id : null}
                                                data-action_name={`${icon.name}-${name}`}
                                                id={`${prefixId}-${`r${rowIndex + 1}-rc${
                                                    colIndex + 1
                                                }`}-icon-container`}
                                            >
                                                <UseIcon
                                                    id={`${prefixId}-${`r${rowIndex + 1}-c${colIndex + 1}`}-icon`}
                                                    icon={icon}
                                                    adjust={`cursor-pointer ${iconStyle}`}
                                                />
                                            </div>
                                        </Link>
                                    ) : null}
                                </div>
                            ) : (
                                icon && (
                                    <UseIcon
                                        id={`${prefixId}-${`r${rowIndex + 1}-c${colIndex + 1}`}-icon`}
                                        icon={icon}
                                        adjust={`cursor-pointer ${iconStyle}`}
                                    />
                                )
                            )}
                            <div
                                className="block w-full first-capital"
                                id={`${prefixId}-${`r${rowIndex + 1}-c${colIndex + 1}`}-value`}
                            >
                                {sufix ? cellValue + " " + sufix : cellValue}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export const getIconStyle = (value, editIconStyle) => {
    let res;
    if (!value) {
        res = " t-cell-edit";
    } else {
        res = editIconStyle;
    }
    return res;
};

export default UseValueCellExtended;
