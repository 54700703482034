import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useTranslation } from "react-i18next";
import { useMSQuery } from "../../../../../hooks/GraphqlCalls/useMSQuery";
import { Session } from "../../../../../hooks/Utils/Session";
import { changeActionValues, changeGlobalAction } from "../../../../../actions/globalActions";
import { openModal, setModalContent } from "../../../../../actions/uiActions";
import { setExecuteQuery } from "../../../../../actions/sectionActions";
import { setRefreshData } from "../../../../../actions/tableActions";
import { CORPORATE_CHANNEL } from "../../../../../hooks/Utils/Services/ChannelsUtils";

import UseComponentLoading from "../../../../useComponentLoading";
import UseButton from "../../../../useButton";
import UseInputText from "../../../../Table/useInputText";
import UseRadio from "../../../../useRadio";
import UseSelectWithSearch from "../../../../useSelectWithSearch";
import BrowserMediaLibraryModal from "../../../../../hooks/GraphqlCalls/MediaLibrary/modals/BrowserMediaLibraryModal";

import ReactTooltip from "components/ReactTooltip";
import Parser from "hooks/Utils/Parser";
import classNames from "classnames";
import _ from "lodash";

import { GlobalContext } from "contexts/Global";

const ChannelEditionInformationCard = (props) => {
    //Consts&states
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { lang } = useContext(GlobalContext);
    const { data: dataChannel = {} } = props;
    const { channelData: channelInitialData = null, setName } = dataChannel;
    const { values: valuesChannel = {} } = useSelector((state) => state.action);
    const { refreshData } = useSelector((state) => state.sectionContent);
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [channelData, setChannelData] = useState(null);
    const [assetData, setAssetData] = useState(null);
    const [countries, setCountries] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [classImageData, setClassImageData] = useState(null);
    const [queryChannel, setQueryChannel] = useState("");
    const [runUpdate, setRunUpdate] = useState(false);
    const actionData = useSelector((state) => state.action);
    const sectionName = "channel-informations";
    const inputsName = [
        `${sectionName}-logo`,
        `${sectionName}-name`,
        `${sectionName}-country`,
        `${sectionName}-language`,
        `${sectionName}-isRadio`,
    ];
    //queries&mutations
    const GET_DATA = gql`{
        languages: languages {
            results {
                ref
            }
        }
        countries: countries {
            results {
                information {
                    name
                }
                ref
            }
        }
        ${queryChannel}
    }`;
    const GET_ASSET = gql`{
        asset: libraryAsset(ref:"${channelData?.logoRef}"){
            response{
                name
            }
        }
    }`;

    //useQueries&useMutations
    const [executeQuery, { data, loading: loadingData, error, refetch, networkStatus }] = useMSQuery(GET_DATA, {
        fetchPolicy: "network-only",
    });
    const [
        executeQueryAsset,
        { data: dataAsset, loading: loadingAsset, error: errorAsset, networkStatus: networkStatusAsset },
    ] = useMSQuery(GET_ASSET);

    //effects
    useEffect(() => {
        if (channelInitialData) {
            setQueryChannel(arrangeQueryChannel({ type: channelInitialData.type, id: channelInitialData.id }));
        }
    }, [channelInitialData]);

    useEffect(() => {
        if (channelData && channelData.logoRef) {
            executeQueryAsset();
        }
    }, [channelData]);

    useEffect(() => {
        if (queryChannel) {
            setLoading(true);
            executeQuery();
        }
    }, [queryChannel]);

    useEffect(() => {
        if (!loadingAsset && !errorAsset && networkStatusAsset === 7 && dataAsset && dataAsset.asset) {
            setAssetData(dataAsset.asset.response);
        }
    }, [dataAsset, networkStatusAsset]);

    useEffect(() => {
        if (!loadingData && !error && networkStatus === 7 && data && data.channels) {
            setLoading(false);
            setInitialLoading(false);
            if (data.channels.results && data.channels.results.length > 0) {
                const channelData = arrangeData(data.channels.results[0]);
                const { languages, countries } = arrangeCtls({ data, t });
                setChannelData(channelData);
                setLanguages(languages);
                setCountries(countries);
                setClassImageData(arrangeClassImageData(channelData));
                handleClickInput(inputsName[2], [channelData?.countryRef]);
                handleClickInput(inputsName[3], [channelData?.languageRef]);
                handleClickInput(inputsName[4], channelData.isRadio);
            }
        }
    }, [data, networkStatus]);

    useEffect(() => {
        if (refreshData) {
            setLoading(false);
            dispatch(setRefreshData(false));
            refetch && refetch();
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (runUpdate) {
            setRunUpdate(false);
            setLoading(true);
            dispatch(
                setExecuteQuery({
                    action: "update-channel",
                    params: {
                        id: channelData.id,
                        type: channelData.type,
                        name: actionData.values[inputsName[1]],
                        countryRef: actionData.values[inputsName[2]][0],
                        languageRef: actionData.values[inputsName[3]][0],
                        isRadio: actionData.values[inputsName[4]],
                    },
                })
            );
        }
    }, [runUpdate]);

    //handle&Functions
    const handleClickInput = (name, value) => {
        dispatch(changeActionValues({ [name]: value }));
    };

    const handleUpdateChannel = () => {
        setRunUpdate(true);
    };

    const openModalLibrary = () => {
        const modal = BrowserMediaLibraryModal(t, {
            dataMediaLibrary: {
                add_asset: {
                    active: true,
                    return_added: false,
                },
                selected_ref: null,
                select_multiple: false,
                type: "asset",
            },
            bodyAdjust: "w-10/12",
            buttonSelectedAssetName: "choose-an-image",
            executeSectionQuery: true,
            closeAction: null,
            action: "update-channel-image",
            text: "images-of-the-media-library",
            textAdjust: "",
            title: "choose-an-image",
        });
        setTimeout(() => {
            dispatch(
                changeGlobalAction({
                    actionName: "update-channel-image",
                    values: { id: channelData.id, type: channelData.type },
                })
            );
        }, 1000);
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    const removeImageChannel = () => {
        dispatch(
            changeGlobalAction({
                actionName: "update-channel-image",
                values: { id: channelData.id, type: channelData.type },
            })
        );
        setTimeout(() => {
            setLoading(true);
            dispatch(
                setExecuteQuery({
                    action: "update-channel-image",
                })
            );
        }, 200);
    };

    const onImageError = (e, conteinerErrorIconId) => {
        e.target.classList.add("hidden");
        if (conteinerErrorIconId) {
            const conteinerErrorIcon = document.querySelector(`#${conteinerErrorIconId}`);
            if (conteinerErrorIcon) {
                conteinerErrorIcon.classList.remove("hidden");
                conteinerErrorIcon.innerHTML = `<span class="icon icon-channel text-gray-600 text-7xl"></span>`;
            }
        }
    };
    const onImageLoad = (conteinerErrorIconId) => {
        if (conteinerErrorIconId) {
            const conteinerErrorIcon = document.querySelector(`#${conteinerErrorIconId}`);
            if (conteinerErrorIcon) {
                conteinerErrorIcon.classList.add("hidden");
                conteinerErrorIcon.innerHTML = ``;
            }
        }
    };

    //renders
    return (
        <>
            {initialLoading ? (
                <UseComponentLoading />
            ) : (
                <>
                    <div className="ChannelEditionCard w-full">
                        <div className="flex w-full text-left mb-5">
                            <span className="font-bold text-gray-900 text-1xl">{t("informations")}</span>
                        </div>
                        <div className="flex w-full">
                            <div className="ChannelEditionCard--separation">
                                <div className="flex w-full text-left mb-2 align-middle items-center">
                                    <span className="font-bold text-gray-900">{t("add-logo")}</span>
                                    <span
                                        className="icon icon-info ml-3 text-1xl text-gray-800"
                                        data-for={`${sectionName}-info`}
                                        data-tip
                                    />
                                    <ReactTooltip
                                        arrowColor="text-gray-200"
                                        borderColor="text-gray-200"
                                        border={true}
                                        className="font-normal"
                                        delayShow={250}
                                        id={`${sectionName}-info`}
                                        type="light"
                                    >
                                        {Parser(t("image-recommended-channel"))}
                                    </ReactTooltip>
                                </div>
                                <div className="ChannelEditionCard-image">
                                    <div className="ChannelEditionCard-image--body">
                                        <div className="ChannelEditionCard-image--background">
                                            {channelData?.logoRef && channelData.logoRef !== "" ? (
                                                <img
                                                    alt={channelData?.name || ""}
                                                    className={classImageData?.classImageSrc}
                                                    src={Session.getDasUrl(channelData?.logoRef + `?lang=${lang}`)}
                                                    onError={(e) => {
                                                        onImageError(e, `${sectionName}-image-not-found`);
                                                    }}
                                                    onLoad={(e) => {
                                                        onImageLoad(`${sectionName}-image-not-found`);
                                                    }}
                                                />
                                            ) : (
                                                <div className="w-full h-full flex">
                                                    <span className="icon icon-channel text-gray-800 text-7xl m-auto"></span>
                                                </div>
                                            )}
                                            <div
                                                className={`hidden w-full h-full flex items-center justify-center`}
                                                id={`${sectionName}-image-not-found`}
                                            ></div>
                                        </div>
                                        <div className={classImageData?.classImageOptions}>
                                            <i className="icon icon-delete-editor text-transparent" />
                                            <i
                                                className="icon icon-delete-editor cursor-pointer text-2xl"
                                                onClick={() => removeImageChannel()}
                                            />
                                            <span
                                                className="icon icon-info cursor-pointer text-xl"
                                                data-for={`${sectionName}-info-asset`}
                                                data-tip={renderTooltip({ assetData, t })}
                                            />
                                            <ReactTooltip
                                                arrowColor="text-gray-200"
                                                borderColor="text-gray-200"
                                                border={true}
                                                delayShow={250}
                                                id={`${sectionName}-info-asset`}
                                                type="light"
                                                html={true}
                                                dangerousHtml={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="ChannelEditionCard-image--footer">
                                        <UseButton
                                            action={() => openModalLibrary()}
                                            buttonColor="btn-blue"
                                            buttonName="choose-an-image"
                                            id={"add-channel-choose-an-image"}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="w-full ChannelEditionCard-properties">
                                <div className="text-left mb-4">
                                    <label className="ChannelEditionCard-properties--item">{t("name")}</label>
                                    <UseInputText
                                        disabled={loading}
                                        currentValue={channelData?.name}
                                        id={inputsName[1]}
                                        inputData={{ name: inputsName[1] }}
                                        key={inputsName[1]}
                                        onChangeAction={(value) => setName(value)}
                                        onBlurAction={(value) => {
                                            handleClickInput(inputsName[1], value);
                                            handleUpdateChannel();
                                        }}
                                        ph={t("name")}
                                        validation={true}
                                    />
                                </div>
                                <div className="w-full text-left mb-4">
                                    <label className="ChannelEditionCard-properties--item">{t("country")}</label>
                                    <UseSelectWithSearch
                                        data={{
                                            disabled: loading,
                                            id: inputsName[2],
                                            name: inputsName[2],
                                            optionData: countries,
                                            selectedIds: [channelData?.countryRef],
                                            selectPlaceHolder: "select-an-option",
                                            noSelect: true,
                                            onChange: () => handleUpdateChannel(),
                                            oneSelected: true,
                                            noShowSelectedOptionsInDropDownSection: true,
                                        }}
                                    />
                                </div>

                                <div className="w-full text-left mb-6">
                                    <label className="ChannelEditionCard-properties--item">{t("languages")}</label>
                                    <UseSelectWithSearch
                                        data={{
                                            disabled: loading,
                                            id: inputsName[3],
                                            name: inputsName[3],
                                            optionData: languages,
                                            selectedIds: [channelData?.languageRef],
                                            selectPlaceHolder: "select-an-option",
                                            noSelect: true,
                                            onChange: () => handleUpdateChannel(),
                                            oneSelected: true,
                                            noShowSelectedOptionsInDropDownSection: true,
                                        }}
                                    />
                                </div>

                                <div className="text-left mb-4 mt-1">
                                    <label className="w-full text-left mb-2 block font-bold">
                                        {t("select-channel-type")}
                                    </label>
                                    <div className="w-full mb-2">
                                        <UseRadio
                                            disabled={loading}
                                            handleClick={(value) => {
                                                handleClickInput(inputsName[4], value);
                                                handleUpdateChannel();
                                            }}
                                            id={`${inputsName[4]}-tv`}
                                            selected={valuesChannel[inputsName[4]]}
                                            value={false}
                                        />
                                        <span className="ml-6">{t("tv")}</span>
                                    </div>

                                    <div className="w-full">
                                        <UseRadio
                                            disabled={loading}
                                            handleClick={(value) => {
                                                handleClickInput(inputsName[4], value);
                                                handleUpdateChannel();
                                            }}
                                            id={`${inputsName[4]}-radio`}
                                            selected={valuesChannel[inputsName[4]]}
                                            value={true}
                                        />
                                        <span className="ml-6">{t("radio")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

//arrangeData
const arrangeData = (data) => {
    let response = null;
    if (data) {
        response = { ...data };
    }
    return response;
};

const arrangeCtls = (props) => {
    const { data, t } = props;
    let response = {
        languages: [],
        countries: [],
    };
    if (data) {
        if (data.languages) {
            data.languages.results.forEach((option) => {
                response.languages.push({
                    id: option.ref,
                    name: t("language:" + option.ref),
                });
            });
            response.languages = _.orderBy(response.languages, ["name"], ["asc"]);
        }
        if (data.countries) {
            data.countries.results.forEach((option) => {
                response.countries.push({
                    id: option.ref,
                    name: t("country:" + option.ref.toLowerCase()),
                });
            });
            response.countries = _.orderBy(response.countries, ["name"], ["asc"]);
        }
    }
    return response;
};

const arrangeQueryChannel = (props) => {
    const { type, id } = props;
    let response = null;
    if (type === CORPORATE_CHANNEL.value) {
        response = `
        channels: corporateTunesSummary(filter:{id:${id}}){
            results {
                id:channelID
                name
                type
                logoRef
            }
        }`;
    } else {
        response = `
        channels: customAndPresetTunesSummary(filter:{id:${id}}){
            results {
                id:channelID
                name
                type
                logoRef
                countryRef
                languageRef
                isRadio
            }
        }`;
    }
    return response;
};

const arrangeClassImageData = (channelData) => {
    const classImageSrc = classNames({
        "ChannelEditionCard-image--src": true,
        hidden: !channelData?.logoRef,
    });

    const classImageOptions = classNames({
        "ChannelEditionCard-image--options": true,
        hidden: !channelData?.logoRef,
    });

    return { classImageSrc: classImageSrc, classImageOptions: classImageOptions };
};

const renderTooltip = (props) => {
    const { assetData, t } = props;
    let response = `
        <div>
            <div className="font-bold">
                ${t("image-from")} ${t("library")}
            </div>
            <div className="my-2">
                ${assetData?.name}
            </div>
        </div>
    `;
    return response;
};

export default ChannelEditionInformationCard;
