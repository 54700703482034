import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setLoadingModalContent } from "../../actions/uiActions";

//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";

const UseModalSelectedView = ({ index }) => {
    const { optionsQuery, optionData } = useSelector((state) => state.ui.modalContent.inputs[index]);

    //States
    const [dataOptions, setDataOptions] = useState(optionData ? JSON.parse(JSON.stringify(optionData)) : []);

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_OPTIONS = optionsQuery
        ? gql`
              ${optionsQuery}
          `
        : null;
    const [getOptionsQuery, { data }] = useLazyQuery(GET_OPTIONS, {
        fetchPolicy: "network-only",
    });

    //Listeners
    useEffect(() => {
        //get options from query
        if (optionsQuery) {
            dispatch(setLoadingModalContent(true));
            getOptionsQuery();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data && data.data && data.data.results) {
            dispatch(setLoadingModalContent(false));

            let tmpOptions = [];
            data.data.results.map((item, index) =>
                tmpOptions.push({
                    id: index,
                    name: `${item.name} ${item.surname ? item.surname : ""} `,
                })
            );

            setDataOptions(tmpOptions);
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <>
            <div className="w-full  max-h-80 overflow-scroll rounded  ">
                {dataOptions.map((item) => (
                    <div key={item.id} className="float-left z-20  bg-gray-200   mr-4 p-1 pl-2 pr-2 mb-2 rounded">
                        {item.name}
                    </div>
                ))}
            </div>
        </>
    );
};

export default UseModalSelectedView;
