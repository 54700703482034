// This file returns translations of terms in section headers

const translate = (term, value) => {
    const options = {
        "change-name-text": 'Por favor, escriba los nombres nuevos y después pulse en "Confirmar"',
        "change-name": "Cambiar nombre",
        "chromecast-change-name": "Cambiar nombre",
        confirm: "Confirmar",
        cancel: "Cancelar",
        "new-name": "Escriba un nuevo nombre",
        "current-name": "Nombre actual",
        "booking-admission": "Admisión de reservas: ",

        //COVID
        "booking-allowed": "Si se admiten reservas",
        "booking-notallowed": "No se admiten reservas",
        each: "cada",
        minutes: "minutos",
        hour: "hora",
        "booking-freq": "Frecuencia de reservas",
        "enter-area-capacity": "Introduce el número de personas para definir el límite de aforo de este espacio:",
        people: "personas",
        "people-number": "Nº de personas",
        "close-comment": "Escriba el motivo del cierre (opcional)",
        chars: "caracteres",
        "confirm-category-deletion": "¿Seguro que quiere eliminar esta categoría? No podrá recuperarla.",
        "confirm-area-deletion": "Confirme la acción para eliminar el área. No podrá recuperarla",
        "enter-area-description": "Introduzca la descipción que podrá verse como información para sus clientes",
        "select-if": "Seleccione si",
        "booking-admit": "admitirá o no reservas",
        "select-booking-freq": "Seleccione la frecuencia del horario de las reservas",
        "each-15-min": "Cada 15 minutos",
        "each-30-min": "Cada 30 minutos",
        "each-hour": "Cada hora",
        "each-custom": "Personalizar: Cada",
        "select-move-category": "Seleccione la categoría a la que quiera mover este área",
        "choose-category": "Elija una categoría",
        "cancel-booking-comment": "Introduzca el motivo de la cancelación de la reserva.",
        "cleaning-ok": "La limpieza se ha realizado correctamente.",
        responsible: "Responsable",
        "choose-cleaning-responsible": "Introduzca el nombre del responsable que ha realizado la limpieza",
        "remind-password-text":
            "Para solicitar una contraseña nueva póngase en contacto con ZAFIRO. Indique su correo de acceso al manager enviándolo a la siguiente dirección:",
        "confirm-staff-deletion": "¿Seguro que desea eliminar al usuario? Esta acción no se puede deshacer.",
        "confirm-staff-pass-reset":
            "¿Seguro que quieres generar una nueva contraseña para este nuevo usuario? La nueva contraseña reemplazará a la anterior y se enviará a la dirección de email del miembro del staff.",
        "select-booking-newdate": "Seleccione el día en el que quiera hacer la nueva reserva:",
        "%capacity": "% de aforo",
        "delete-schedule-text":
            "Por favor, asegúrese de que quiere eliminar este horario. Esta acción no se puede deshacer.",

        //Form errors
        "error-capacity-limit": "Por favor, indique un límite de aforo.",
        "error-booking-allow": "Por favor, complete los datos de admisión de reservas.",
        "error-description": "Por favor, escriba un texto de descripción.",
        "error-mutation": "Lo sentimos, ha habido un problema con la operación solicitada",
        "error-category-name": "Por favor, escriba un nombre para la categoría.",
        "error-area-name": "Por favor, escriba un nombre para el área.",
        "error-booking-schedule": "Por favor, compruebe el horario de la nueva reserva.",
        "error-booking-people": "Por favor, compruebe la cantidad de personas a reservar.",
        "error-booking-name": "Por favor, compruebe el nombre al que crear la nueva reserva.",
        "error-booking-phone": "Por favor, compruebe el teléfono del titular de la reserva.",
        "error-booking-email": "Por favor, compruebe el email del titular de la reserva.",
        "error-booking-create": "Se ha producido un error. Por favor revise los datos.",
        "error-select-category": "Por favor, seleccione la categoría a la que desea mover la actividad o zona.",
        "error-booking-cancel-comment": "Por favor, escriba un comentario sobre la cancelación de la reserva.",
        "error-responsible": "Por favor, seleccione un responsable para esta actualización de la limpieza.",
        "error-staff-create": "Por favor, revise los campos marcados en rojo.",
        "error-capacity-update": "Lo sentimos, no ha sido posible actualizar el aforo.",

        //Mutation ok
        "success-mutation": "Operación realizada correctamente.",
        "success-create-category": `La categoría ${value} se ha creado correctamente.`,
        "success-updating-category": `La categoría ${value} se ha actualizado correctamente.`,
        "success-create-area": `El área o actividad ${value} se ha creado correctamente.`,
        "success-updating-area": `El área o actividad ${value} se ha modificado correctamente.`,
        "success-create-staff": `El miembro del staff ${value} se ha creado correctamente.`,
        "success-updating-staff": `El miembro del staff ${value} se ha modificado correctamente.`,
        "success-create-booking": `La reserva se ha creado correctamente.`,
        "schedule-delete-successful": "El horario se ha borrado correctamente.",
        "booking-canceled": "La reserva se ha cancelado correctamente.",
        "success-updating-capacity": `El aforo de ${value} se ha actualizado correctamente.`,
        //Schedule Modal
        "periodic-event": "Evento periódico",
        "punctual-event": "Evento puntual",
    };

    return options[term] ?? term;
};

export default translate;
