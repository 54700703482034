import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import ReactTooltip from "components/ReactTooltip";
import RGL, { WidthProvider } from "react-grid-layout";

//Components
import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";
import WidgetText from "../Widgets/WidgetText";
import WidgetIframe from "../Widgets/WidgetIframe";
import WidgetHTML from "../Widgets/WidgetHTML";
import WidgetVideo from "../Widgets/WidgetVideo";
import WidgetQR from "../Widgets/WidgetQR";
import WidgetImage from "../Widgets/WidgetImage";
import WidgetLogo from "../Widgets/WidgetLogo";
import WidgetWeather from "../Widgets/WidgetWeather";
import WidgetIcon from "../Widgets/WidgetIcon";
import WidgetCarousel from "../Widgets/WidgetCarousel";
import GridItemToolbar from "../Widgets/WidgetToolbars/GridItemToolbar";
import WidgetButton from "../Widgets/WidgetButton";
import WidgetChatButton from "../Widgets/WidgetChatButton";
import WidgetSlider from "../Widgets/WidgetSlider";
import { WidgetProvider } from "../../../../contexts/editor";
import WidgetStyle from "zrender/widgetStyle";

//Actions
import {
    setItemSelected,
    setWidgetToolbarVisible,
    setDesignToolbarVisible,
    updateContainerItemPos,
    setContainerInEdition,
    setColorPickerReference,
} from "../../../../actions/designActions";

import { setExecuteQuery } from "../../../../actions/sectionActions";

//Utils
import {
    ensureToolbarPos,
    calculateContainerSizes,
    ensureWidgetOptionsPos,
    showAndHideResizableHandleWidget,
} from "../../../../hooks/Utils/DesignUtils";
import { Session } from "../../../../hooks/Utils/Session";

//API
import { toast } from "react-toastify";
import WidgetMessages from "./WidgetMessages";
import WidgetCalendar from "./WidgetCalendar";
import WidgetClock from "./WidgetClock";
import WidgetAlarm from "./WidgetAlarm";

const ReactGridLayout = WidthProvider(RGL);

const WidgetContainer = ({ widgets }) => {
    const { theme, deviceType, editorSize, itemSelected } = useContext(EditorContext);
    const { config, id: widgetID } = useContext(WidgetContext);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { lang } = useParams();

    const { gridLayout, widgetToolbarVisible, gridItems, editorMode, containersLayouts, cScreenData } = useSelector(
        (state) => state.design
    );

    //const defaultBgColor = GetThemeDefault({ theme, id: "bgColor" });

    const screenPos = cScreenData && cScreenData.data ? cScreenData.data.pos : null;
    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const style = config?.style || Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);
    const data = config?.data || Widget.ParseData(widget ? widget.data : null, deviceType);
    const borderWidth = style ? style.borderWidth : 0;
    const bgColor = "transparent"; //style?.bgColor || defaultBgColor;
    const widgetType = Session.getSessionProp("widgetTypeDrag");
    const bgImage =
        style && style.images && style.images.length > 0
            ? style.images[0].externalUrl
                ? style.images[0].externalUrl
                : style.images[0].libraryRef
                ? Session.getDasUrl(style.images[0].libraryRef + "?lang=" + lang)
                : ""
            : "";

    const margin = data && data.margin ? data.margin : 10;
    const itemsMargin = data && data.itemsMargin ? data.itemsMargin : 10;

    const [containerSize, setContainerSize] = useState(null);
    const [layoutCopy, setLayoutCopy] = useState(null);
    const [warnings, setWarnings] = useState({});
    const [cDroppingItem, setCDroppingItem] = useState({});

    const gItemRef = useRef(widget);
    gItemRef.current = widget;

    const ensureToolbarPositions = () => {
        if (itemSelected) {
            ensureToolbarPos(itemSelected);
            ensureWidgetOptionsPos(itemSelected, editorSize);
        }
    };

    const hideTools = (contanier) => {
        dispatch(setDesignToolbarVisible(null));
        dispatch(setContainerInEdition(contanier));
        dispatch(setColorPickerReference(null));
        if (widgetToolbarVisible !== "text-settings") {
            dispatch(setWidgetToolbarVisible(null));
        }
    };

    useEffect(() => {
        ensureToolbarPositions();
        // eslint-disable-next-line
    }, [widgetToolbarVisible]);

    useEffect(() => {
        reCalcutateSizes();
        // eslint-disable-next-line
    }, [gridLayout, margin, borderWidth, itemsMargin]);

    useEffect(() => {
        const w = layoutCopy ? layoutCopy.filter((item) => item.i === itemSelected)[0] : null;
        dispatch(setWidgetToolbarVisible(w && w.data && w.type === "TEXT" ? "text-settings" : null));

        if (!w) {
            const itemContainer = document.querySelector(`.widget-container`);
            if (itemContainer) {
                const buttonsResizable = itemContainer.querySelectorAll(".react-resizable-handle");
                for (let index = 0; index < buttonsResizable.length; index++) {
                    const element = buttonsResizable[index];
                    element.style.visibility = "visible";
                }
            }
        }
        // eslint-disable-next-line
    }, [itemSelected]);

    useEffect(() => {
        reCalcutateSizes();
        // eslint-disable-next-line
    }, [editorSize]);

    useEffect(() => {
        //Al cambiar el tamaño del container hay que volver calcular tamaños y refrescar
        reCalcutateSizes();
        // eslint-disable-next-line
    }, [screenPos]);

    useEffect(() => {
        if (containerSize) {
            let factor = 3;
            let h = Math.max(1, parseInt(containerSize.rows / factor));
            let w = Math.max(1, parseInt(containerSize.cols / factor));
            switch (deviceType) {
                case "Desktop":
                case "TV":
                    if (widgetType === "QR") {
                        factor = 4;
                        h = Math.max(1, parseInt(containerSize.rows / factor / 1.75));
                        w = Math.max(1, parseInt(containerSize.cols / factor - 40));
                    } else if (widgetType === "BUTTON") {
                        factor = 3;
                        h = Math.max(1, parseInt(containerSize.rows / factor / 2));
                        w = Math.max(1, parseInt(containerSize.cols / factor - 30));
                    } else if (widgetType === "ICON") {
                        factor = 4;
                        h = Math.max(1, parseInt(containerSize.rows / factor / 2.2));
                        w = Math.max(1, parseInt(containerSize.cols / factor - 45));
                    } else if (widgetType === "LOGO") {
                        factor = 3;
                        h = Math.max(1, parseInt(containerSize.rows / factor / 2));
                        w = Math.max(1, parseInt(containerSize.cols / factor - 40));
                    }
                    break;

                default:
                    break;
            }

            setCDroppingItem({ i: "-1", type: "undefined", w, h });
        }
        // eslint-disable-next-line
    }, [containerSize, widgetType]);

    useEffect(() => {
        //Se utiliza esto en lugar de gridLayout directamente porque
        //sino la librería no renderiza bien los items cuando hay cambios en gridlayout
        if (containersLayouts) {
            const layout = JSON.parse(
                JSON.stringify(
                    containersLayouts.filter((layout) => parseInt(layout.i) === parseInt(widgetID))[0]
                        ? containersLayouts.filter((layout) => parseInt(layout.i) === parseInt(widgetID))[0].layout
                        : []
                )
            ).filter((copy) => {
                if (
                    (deviceType === "Mobile" && ["QR", "CALENDAR", "CLOCK"].includes(copy.type)) ||
                    (deviceType === "TV" && ["CHATBUTTON", "SLIDER"].includes(copy.type))
                ) {
                    return null;
                }
                copy.isDraggable = editorMode === "EDITION";
                copy.isDroppable = copy.isDraggable;
                copy.isResizable = copy.isDraggable;
                copy.resizeHandles = copy.isResizable ? ["se"] : null;
                return copy;
            });
            setLayoutCopy(layout);
        }
        // eslint-disable-next-line
    }, [containersLayouts]);

    const reCalcutateSizes = () => {
        setTimeout(() => {
            calculateContainerSizes(widgetID, gItemRef.current, setContainerSize);
            const evt = document.createEvent("UIEvents");
            evt.initUIEvent("resize", true, false, window, 0);
            window.dispatchEvent(evt);
        }, 10);
    };

    const layoutChange = (layout) => {
        //mientras hago drag de elementos externos no actualizo layout
        if (layout.filter((item) => item.i === "__dropping-elem__").length > 0) {
            return;
        }
    };

    const onDragStart = (e) => {
        if (itemSelected) {
            const toolbar = document.querySelector(`#toolbar_${itemSelected}`);
            const editorToolbar = document.querySelector(`#widget_${itemSelected} .rdw-editor-toolbar`);
            if (toolbar) toolbar.classList.add("invisible");
            if (editorToolbar) editorToolbar.style.visibility = "hidden";
        }
        //  setItemSelected(null);
    };

    const onDragStop = (layout, oldDragItem, newDragItem) => {
        setTimeout(() => {
            if (itemSelected) {
                const toolbar = document.querySelector(`#toolbar_${itemSelected}`);
                const editorToolbar = document.querySelector(`#widget_${itemSelected} .rdw-editor-toolbar`);
                if (toolbar) toolbar.classList.remove("invisible");
                if (editorToolbar) editorToolbar.style.visibility = "visible";
                ensureToolbarPositions();
            }
        }, 500);
        updateLayout(layout, oldDragItem, newDragItem);
    };
    const updateLayout = (layout, oldDragItem, newDragItem) => {
        if (
            oldDragItem.x !== newDragItem.x ||
            oldDragItem.y !== newDragItem.y ||
            oldDragItem.w !== newDragItem.w ||
            oldDragItem.h !== newDragItem.h
        ) {
            //check if new posics are inside the edition area
            let editorContainer = document.querySelector(`#container_${widgetID}`);
            let widget = document.querySelector(`#widget_${newDragItem.i}`);
            const editorPos = editorContainer.getClientRects()[0];
            const widgetPos = widget.getClientRects()[0];

            const prevPos = {
                x: oldDragItem.x,
                y: oldDragItem.y,
                w: oldDragItem.w,
                h: oldDragItem.h,
            };
            const newPos = {
                x: newDragItem.x,
                y: newDragItem.y,
                w: newDragItem.w,
                h: newDragItem.h,
            };

            //Si al hacer el resize el widget queda fuera del editor, se cancela
            if (widgetPos.y + widgetPos.height > editorPos.y + editorPos.height) {
                dispatch(updateContainerItemPos(widgetID, newDragItem.i, newPos));

                setTimeout(() => {
                    dispatch(updateContainerItemPos(widgetID, newDragItem.i, prevPos));
                    toast.error(t("place-into-container-area"));
                }, 100);
                return;
            }

            ensureToolbarPositions();
            dispatch(
                setExecuteQuery({
                    action: "updateContainerWidgetPos",
                    params: {
                        containerId: widgetID,
                        widgetID: newDragItem.i,
                        widgetPos: newPos,
                        prevWidgetPos: prevPos,
                    },
                })
            );
        }
    };

    const onDrop = (layout, dropLayoutItem, _event) => {
        _event.stopPropagation();
        if (!Session.getSessionProp("widgetTypeDrag")) {
            return;
        }
        if (Session.getSessionProp("widgetTypeDrag") === "CONTAINER") {
            toast.error(t("can not add container"));
            return;
        }
        if (!dropLayoutItem) return;
        dispatch(
            setExecuteQuery({
                action: "createWidget",
                params: {
                    containerID: widgetID,
                    widgetType: Session.getSessionProp("widgetTypeDrag"),
                    widgetPos: {
                        x: dropLayoutItem.x,
                        y: dropLayoutItem.y,
                        w: dropLayoutItem.w < containerSize.cols ? dropLayoutItem.w : containerSize.cols,
                        h: dropLayoutItem.h < containerSize.rows ? dropLayoutItem.h : containerSize.rows,
                    },
                    style: widgetValidForTransparentBg(widgetType) ? { bgColor: "rgba(255,255,255,0.0)" } : null,
                },
            })
        );
    };

    const widgetValidForTransparentBg = (widget) => {
        if (
            widget !== "LOGO" ||
            widget !== "IMAGE" ||
            widget !== "VIDEO" ||
            widget !== "CAROUSEL" ||
            widget !== "QR" ||
            widget !== "IFRAME" ||
            widget !== "HTML"
        ) {
            return true;
        } else {
            return false;
        }
    };

    const showItemOptions = (id) => {
        if (editorMode === "PREVIEW") {
            return;
        }
        dispatch(setItemSelected(id));
    };

    const selectItemResized = () => {
        const items = document.querySelectorAll(":hover");
        if (!items) {
            return;
        }
        Array.from(items).map((item) =>
            item.id.indexOf("widget_") > -1 ? dispatch(setItemSelected(item.id.replace("widget_", ""))) : null
        );
    };

    return (
        <>
            {widget && layoutCopy && containerSize && containerSize.height ? (
                <>
                    <div
                        id={`container_${widgetID}`}
                        className="relative bg-cover bg-center bg-no-repeat"
                        style={{
                            height: containerSize.height,
                            width: containerSize.width,
                            opacity: style && style.opacity ? style.opacity / 100 : 1,
                            backgroundColor: bgColor,
                            backgroundImage: `url("${bgImage}")`,
                            borderRadius: style && style.radius ? `${style.radius}rem` : "",
                        }}
                    ></div>
                    <div
                        className="relative"
                        style={{
                            height: containerSize.height,
                            width: containerSize.width,
                            marginTop: `-${containerSize.height}px`,
                        }}
                        onClick={(e) => {
                            showItemOptions(widgetID);
                            hideTools(null);
                            showAndHideResizableHandleWidget(widgetID, "visible", ".widget-container");
                            e.stopPropagation();
                        }}
                    >
                        <ReactGridLayout
                            style={{
                                height: containerSize.height,
                                width: containerSize.width,
                                outline:
                                    itemSelected !== widgetID &&
                                    (!borderWidth || (borderWidth && style && !style.borderColor))
                                        ? "1px dashed #ccc"
                                        : "",
                                backgroundColor:
                                    bgColor || bgImage || (layoutCopy && layoutCopy.length > 0)
                                        ? "transparent"
                                        : "#E9EDF0",
                            }}
                            layout={layoutCopy}
                            cols={containerSize.cols}
                            rowHeight={containerSize.rowHeight}
                            useCSSTransforms={false}
                            isBounded={true}
                            onResizeStart={(e) => selectItemResized()}
                            preventCollision={true}
                            onLayoutChange={layoutChange}
                            onDrop={onDrop}
                            isDroppable={editorMode === "EDITION"}
                            droppingItem={cDroppingItem}
                            draggableHandle={".container-item-label"}
                            onDragStart={onDragStart}
                            onDragStop={onDragStop}
                            onResize={ensureToolbarPositions}
                            onResizeStop={updateLayout}
                            margin={[itemsMargin.w, itemsMargin.h]}
                            containerPadding={[margin.w, margin.h]}
                            compactType={null}
                        >
                            {layoutCopy.map((copy) => {
                                const copyID = copy.i;
                                const type = copy.type;
                                const curType = widgets.filter((widget) => widget.type === type);
                                const cWidget = gridItems.find((item) => item.i === copyID);
                                const cStyle = Widget.ParseStyle(cWidget ? cWidget.widgetStyle : null, deviceType);

                                const style = new WidgetStyle(type, theme);
                                style.fill(cStyle);

                                const draggable = copy.isDraggable;
                                const setWarn = (widgetID, warn) => {
                                    setWarnings({ ...warnings, [widgetID]: warn });
                                };
                                return (
                                    <div
                                        id={`widget_${copyID}`}
                                        key={`${copyID}`}
                                        onClick={(e) => {
                                            hideTools(widgetID);
                                            showItemOptions(copyID);
                                            showAndHideResizableHandleWidget(widgetID, "hidden", ".widget-container");

                                            e.stopPropagation();
                                        }}
                                        onMouseDown={(e) => e.stopPropagation()}
                                        className={`react-grid-layout mb-40  ${
                                            itemSelected === copyID ? " widgetSelected" : ""
                                        } ${editorMode === "PREVIEW" ? "previewMode" : ""}`}
                                    >
                                        <WidgetProvider
                                            value={{
                                                id: copyID || 0,
                                                type,
                                                container: widget,
                                                style: cStyle,
                                                config: {
                                                    style,
                                                    data: null,
                                                },
                                            }}
                                        >
                                            <Widget.Zone
                                                style={{
                                                    background:
                                                        curType && curType.usingDriveRender
                                                            ? "transparent"
                                                            : cStyle && cStyle.bgColor
                                                            ? cStyle.bgColor
                                                            : "#E9EDF0",
                                                    borderWidth:
                                                        cStyle && cStyle.borderWidth ? `${cStyle.borderWidth}px` : "",
                                                    borderColor: cStyle && cStyle.borderColor ? cStyle.borderColor : "",
                                                    borderRadius: cStyle && cStyle.radius ? `${cStyle.radius}rem` : "",
                                                }}
                                            >
                                                {type === "TEXT" ? (
                                                    <WidgetText />
                                                ) : type === "LOGO" ? (
                                                    <WidgetLogo />
                                                ) : type === "IMAGE" ? (
                                                    <WidgetImage />
                                                ) : type === "IFRAME" ? (
                                                    <WidgetIframe />
                                                ) : type === "ICON" ? (
                                                    <WidgetIcon />
                                                ) : type === "CAROUSEL" ? (
                                                    <WidgetCarousel />
                                                ) : type === "HTML" ? (
                                                    <WidgetHTML />
                                                ) : type === "VIDEO" ? (
                                                    <WidgetVideo />
                                                ) : type === "QR" ? (
                                                    <WidgetQR />
                                                ) : type === "WEATHER" ? (
                                                    <WidgetWeather />
                                                ) : type === "BUTTON" ? (
                                                    <WidgetButton />
                                                ) : type === "MESSAGES" ? (
                                                    <WidgetMessages />
                                                ) : type === "CALENDAR" ? (
                                                    <WidgetCalendar />
                                                ) : type === "CLOCK" ? (
                                                    <WidgetClock />
                                                ) : type === "ALARM" ? (
                                                    <WidgetAlarm />
                                                ) : type === "CHATBUTTON" ? (
                                                    <WidgetChatButton setWarning={setWarn} />
                                                ) : type === "SLIDER" ? (
                                                    <WidgetSlider />
                                                ) : null}
                                            </Widget.Zone>
                                            <GridItemToolbar />

                                            <div
                                                id={`item_label_${copyID}`}
                                                className={`${
                                                    draggable ? "container-item-label cursor-move " : "cursor-default"
                                                }${
                                                    itemSelected === copyID ? "" : "hidden"
                                                }  z-500 invisible absolute top-0`}
                                            >
                                                <div className="flex items-center">
                                                    <div className="rounded bg-zafiro-400 inline-block px-2 text-base text-white">
                                                        <span className="capitalize">
                                                            {curType.length > 0 ? t(curType[0].name) : "-"}
                                                        </span>
                                                    </div>
                                                    {warnings && warnings[copyID] ? (
                                                        <>
                                                            <i
                                                                className="icon icon-warning ml-2 text-2xl text-orange-100"
                                                                data-tip
                                                                data-for={`widget_warn_tooltip_${copyID}`}
                                                            ></i>
                                                            {warnings[copyID] !== true ? (
                                                                <ReactTooltip
                                                                    id={`widget_warn_tooltip_${copyID}`}
                                                                    delayShow={250}
                                                                    type="light"
                                                                    border={true}
                                                                    borderColor="text-gray-200"
                                                                    arrowColor="text-gray-200"
                                                                    className="font-normal"
                                                                >
                                                                    {warnings[copyID]}
                                                                </ReactTooltip>
                                                            ) : null}
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </WidgetProvider>
                                    </div>
                                );
                            })}
                        </ReactGridLayout>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default WidgetContainer;
