import React from "react";

//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table/index";

//Utils
import ListTaxZones from "../../../hooks/GraphqlCalls/Settings/ListTaxZones";
import UpdateTaxZones from "../../../hooks/GraphqlCalls/Settings/UpdateTaxZones";

const TaxZones = () => {
    ListTaxZones();

    return (
        <>
            <UseSectionHeader title={["tax-zones"]} />
            <UpdateTaxZones />
            <div>
                <UseTable />
            </div>
        </>
    );
};
export default TaxZones;
