import { useMutation } from "react-apollo";
import { EDIT_ALARM } from "./utils/alarmsQueries";

export const useEditAlarm = () => {
    const [executeEditMutation] = useMutation(EDIT_ALARM);

    const editAlarm = (values) => {
        const { groupingID, startTime, type, tvChannelId } = values || {};
        executeEditMutation({
            variables: {
                groupingID,
                startTime,
                type,
                ...(type === "TVCHANNEL" && tvChannelId ? { tvChannelId } : {}),
            },
        });
    };

    return { editAlarm };
};
