import React from "react";
import { useEditGroups } from "hooks/GraphqlCalls/Hotel/Rooms/useEditGroups";
import Loading from "components/Loading";
import { AddAndEditComponent } from "./AddAndEditComponent";
import { useTranslation } from "react-i18next";

export const GroupViewModal = ({ group, refetch, setLoadingRefresh, type }) => {
    const { t } = useTranslation();
    const { selectData, handleSelect, handleDelete, editGroupName, queryLoading, called, updateRoomGroup } =
        useEditGroups({ group, refetch, setLoadingRefresh, type });

    const inputTextPlaceholder = t("group-name");
    const inputSelectPlaceholder = type === "room" ? t("rooms") : t("guests");
    const labelGroupSelect = type === "room" ? t("select-room-group") : t("select-group-guest");
    const labelGroupAdded = type === "room" ? t("rooms-added") : t("guests-added");

    return (
        <>
            {!queryLoading && called ? (
                <AddAndEditComponent
                    addRoomGroupOrEdit={updateRoomGroup}
                    selectData={selectData}
                    handleSelect={handleSelect}
                    handleDelete={handleDelete}
                    editGroupName={editGroupName}
                    inputTextPlaceholder={inputTextPlaceholder}
                    inputSelectPlaceholder={inputSelectPlaceholder}
                    labelGroupSelect={labelGroupSelect}
                    labelGroupAdded={labelGroupAdded}
                    type="edit"
                />
            ) : (
                <Loading adjust="section-loading" />
            )}
        </>
    );
};
