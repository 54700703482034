const InputGroupDetail = (t, tvInputId, inputGroupData) => {
    return [
        {
            title: inputGroupData.name,
            executeSectionQuery: true,
            bodyAdjust: "w-5/12",
            buttons: [{ name: "close", style: "blue", action: "close" }],
            inputs: [
                {
                    text: [`${t("inputs-of", { name: `<strong>${inputGroupData.name}</strong>` })}`, ":"],
                    type: "inputGroupDetail",
                    inputGroupData: inputGroupData,
                },
            ],
        },
    ];
};

export default InputGroupDetail;
