import React, { useEffect } from "react";
import UseInputText from "components/Table/useInputText";
import UseInputNumber from "components/Table/useInputNumber";
import UseInputDate from "components/useInputDate";
import { changeActionValues } from "actions/globalActions";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirst } from "hooks/Utils/Utils";

export const UseModalAddBooking = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { validations, actionData } = useSelector((state) => state.action);

    useEffect(() => {
        if (actionData) {
            dispatch(changeActionValues({ ["pms-id"]: actionData["pms-id"] }));
            dispatch(changeActionValues({ ["booking-code"]: actionData["booking-code"] }));
            dispatch(changeActionValues({ ["name"]: actionData["name"] }));
            dispatch(changeActionValues({ ["surname"]: actionData["surname"] }));
            dispatch(changeActionValues({ ["email"]: actionData["email"] }));
            dispatch(changeActionValues({ ["rooms-reserved"]: actionData["rooms-reserved"] }));
            dispatch(changeActionValues({ ["number-of-guests"]: actionData["number-of-guests"] }));
        }
        // eslint-disable-next-line
    }, [actionData]);
    return (
        <div className="grid grid-cols-2 gap-6">
            <div>
                <p className=" font-bold">{t("pms-id")}</p>
                <UseInputText
                    inputData={{ name: "pms-id" }}
                    ph={t("pms-example")}
                    validation={validations["pms-id"] === false ? false : true}
                    id={"add-booking-pms-id"}
                />
            </div>
            <div>
                <p className=" font-bold">{t("booking-code")}*</p>
                <UseInputText
                    inputData={{ name: "booking-code" }}
                    ph={t("booking-example")}
                    validation={validations["booking-code"] === false ? false : true}
                    id={"add-booking-code"}
                />
            </div>
            <div>
                <p className=" font-bold">{t("name")}*</p>
                <UseInputText
                    inputData={{ name: "name" }}
                    validation={validations["name"] === false ? false : true}
                    id={"add-booking-name"}
                />
            </div>
            <div>
                <p className=" font-bold">{capitalizeFirst(t("surname"))}*</p>
                <UseInputText
                    inputData={{ name: "surname" }}
                    ph={capitalizeFirst(t("surname"))}
                    validation={validations["surname"] === false ? false : true}
                    id={"add-booking-surname"}
                />
            </div>
            <div className=" col-span-2">
                <p className=" font-bold">{t("email-address")}*</p>
                <UseInputText
                    inputData={{ name: "email" }}
                    ph={t("email-example")}
                    validation={validations["email"] === false ? false : true}
                    id={"add-booking-email"}
                />
            </div>
            <div>
                <p className=" font-bold">{t("rooms-reserved")}*</p>
                <div className="flex flex-row items-center">
                    <UseInputNumber
                        id={`add-booking-rooms-reserved`}
                        inputData={{ name: "rooms-reserved" }}
                        adjust={" flex-1"}
                        maxNumber={9999}
                        currentValue={1}
                        valueHandler={(e) => (e.target.value = Math.abs(e.target.value))}
                        validation={validations["rooms-reserved"] === false ? false : true}
                    />
                    <p className=" flex-1">{t("rooms")}</p>
                </div>
            </div>
            <div>
                <p className=" font-bold">{capitalizeFirst(t("number-of-guests"))}*</p>
                <div className=" flex flex-row items-center">
                    <UseInputNumber
                        id={`add-booking-number-of-guests`}
                        inputData={{ name: "number-of-guests" }}
                        maxNumber={9999}
                        adjust={"flex-1"}
                        currentValue={1}
                        valueHandler={(e) => (e.target.value = Math.abs(e.target.value))}
                        validation={validations["number-of-guests"] === false ? false : true}
                    />
                    <p className="flex-1">{t("guests")}</p>
                </div>
            </div>
            <div>
                <p className=" font-bold">{t("arrival-date")}*</p>
                <UseInputDate
                    data={{
                        name: "arrival-date",
                        fullwidth: true,
                        id: "arrival-date-picker",
                    }}
                ></UseInputDate>
            </div>
            <div>
                <p className=" font-bold">{t("departure-date")}</p>
                <UseInputDate
                    data={{
                        name: "departure-date",
                        fullwidth: true,
                        id: "departure-date-picker",
                    }}
                ></UseInputDate>
            </div>
        </div>
    );
};
