import React, { useEffect } from "react";
import { useNavigate as useHistory } from "react-router-dom";

import { useAuth } from "hooks/Session/auth";

import Loading from "components/Loading";

const Logout = () => {
    const history = useHistory();

    const { isLogged, isImpersonating, logout, stopImpersonation } = useAuth();

    useEffect(() => {
        if (isImpersonating) {
            stopImpersonation();
        } else {
            logout();
        }
    }, []);

    useEffect(() => {
        if (!isLogged) {
            // Go to login page
            history("/login");
        } else {
            history("/");
        }
    }, [isLogged, isImpersonating]);

    return <Loading adjust="full" />;
};

export default Logout;
