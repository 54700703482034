import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getFilterQuery, setActiveFilters, setFolder, cleanActiveFilters } from "../../actions/tableActions";

const UseFolders = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //Store data
    const { customConfig, folder, activeFilters } = useSelector((state) => state.table);

    useEffect(() => {
        if (!folder) {
            dispatch(cleanActiveFilters());
            dispatch(setFolder(null));
            dispatch(getFilterQuery(""));
            return;
        }
        dispatch(setActiveFilters(folder.key, folder.value));
        dispatch(
            getFilterQuery({
                ...activeFilters,
                [folder.key]: `${folder.value}`,
            })
        );
        // eslint-disable-next-line
    }, [folder]);

    return (
        <>
            {folder && customConfig && customConfig.folders && (
                <div className="flex mb-4">
                    {customConfig.folders.map((cfolder) => (
                        <div
                            id={cfolder.key && cfolder.name ? `${cfolder.key}-${cfolder.name}-folder` : null}
                            key={cfolder.name}
                            onClick={(e) => dispatch(setFolder(cfolder))}
                            className={`first-capital folder-link w-36 text-center font-bold py-2 ${
                                folder.name === cfolder.name ? "active" : ""
                            } clickable text-base ${cfolder.adjust}`}
                        >
                            {`${t(cfolder.name)} ${cfolder.total ? ` (${cfolder.total})` : ""}`}
                        </div>
                    ))}
                </div>
            )}{" "}
        </>
    );
};

export default UseFolders;
