import { useEffect, useState } from "react";
import { useMSQuery } from "./useMSQuery";
import { gql } from "apollo-boost";
import { getNewDataGroupColor } from "../Utils/ColorUtils";
import { CORPORATE_LOGOS_TYPE, PROPERTY_LOGOS_TYPE, APPEARANCE_DEFAULT } from "../Utils/AppearancesUtils";

const useTheme = ({ id, designID }) => {
    const [themeData, setThemeData] = useState(null);

    const LOAD_THEME = gql`
        query Themes($id: Int64, $designID: Int64) {
            themes(filter: { id: $id, designID: $designID }) {
                results {
                    id
                    name
                    type
                    config {
                        colors {
                            defaultBackground
                            defaultForeground
                            activeBackground
                            activeForeground
                            focusBackground
                            focusForeground
                            focusBorderSize
                            accent
                            accentContrast
                            login
                        }
                        fontStyles {
                            heading {
                                alignment
                                bold
                                italic
                                name
                                size
                                underline
                            }
                            paragraph {
                                alignment
                                bold
                                italic
                                name
                                size
                                underline
                            }
                        }
                    }
                    themeLogos {
                        id
                        imageRef
                        type
                        version
                        themeLogoCustoms {
                            imageRef
                        }
                    }
                }
            }
        }
    `;
    const [queryExec, { loading, error, data, refetch }] = useMSQuery(LOAD_THEME, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (!loading && !error) {
            const theme = data && data.themes.results && data.themes.results.length > 0 ? data.themes.results[0] : null;
            if (theme) {
                const config = theme.config || {};
                const fonts = config.fontStyles || {};

                const logos = theme.themeLogos || [];
                const colors = config.colors || {};
                const fontHeading = fonts.heading || {};
                const fontParagraph = fonts.paragraph || {};

                const heading = {
                    font: fontHeading.name,
                    size: fontHeading.size,
                    textAlign: fontHeading.alignment,
                    bold: fontHeading.bold,
                    italic: fontHeading.italic,
                    underline: fontHeading.underline,
                };
                const paragraph = {
                    font: fontParagraph.name,
                    size: fontParagraph.size,
                    textAlign: fontParagraph.alignment,
                    bold: fontParagraph.bold,
                    italic: fontParagraph.italic,
                    underline: fontParagraph.underline,
                };

                const background = getNewDataGroupColor(null, colors.defaultBackground, "bg");
                const foreground = getNewDataGroupColor(background, colors.defaultForeground, "fg");
                const activeBg = getNewDataGroupColor(null, colors.activeBackground, "bg");
                const activeFg = getNewDataGroupColor(activeBg, colors.activeForeground, "fg");
                const focusBg = getNewDataGroupColor(null, colors.focusBackground, "bg");
                const focusFg = getNewDataGroupColor(focusBg, colors.focusForeground, "fg");
                const logoToPreview = selectLogoToPreview(logos);
                const logoAssetToPreview = logoToPreview
                    ? logoToPreview.themeLogoCustoms.length > 0
                        ? logoToPreview.themeLogoCustoms[0].imageRef
                        : logoToPreview.imageRef
                    : null;
                setThemeData(
                    theme && theme.id
                        ? {
                              themeReady: true,
                              name: theme.name,
                              default: foreground,
                              active: activeFg,
                              focus: focusFg,
                              focusBorderSize: colors.focusBorderSize,
                              headerStyle: heading,
                              paragraphStyle: paragraph,
                              logos: logos,
                              logoSelected: logoAssetToPreview,
                              logoSelectedId: logoToPreview ? logoToPreview.id : null,
                          }
                        : null
                );
            } else {
                //set APPEARANCE_DEFAULT as theme
                const background = getNewDataGroupColor(null, APPEARANCE_DEFAULT.colors.defaultBackground, "bg");
                const foreground = getNewDataGroupColor(background, APPEARANCE_DEFAULT.colors.defaultForeground, "fg");
                const activeBg = getNewDataGroupColor(null, APPEARANCE_DEFAULT.colors.activeBackground, "bg");
                const activeFg = getNewDataGroupColor(activeBg, APPEARANCE_DEFAULT.colors.activeForeground, "fg");
                const focusBg = getNewDataGroupColor(null, APPEARANCE_DEFAULT.colors.focusBackground, "bg");
                const focusFg = getNewDataGroupColor(focusBg, APPEARANCE_DEFAULT.colors.focusForeground, "fg");
                setThemeData({
                    name: data?.themes?.results?.[0]?.name || "ZAFIRO_DEFAULT",
                    default: foreground,
                    active: activeFg,
                    focus: focusFg,
                    focusBorderSize: APPEARANCE_DEFAULT.colors.focusBorderSize,
                    headerStyle: {
                        ...APPEARANCE_DEFAULT.fontStyles.heading,
                        font: APPEARANCE_DEFAULT.fontStyles.heading.name,
                        textAlign: APPEARANCE_DEFAULT.fontStyles.heading.alignment,
                    },
                    paragraphStyle: {
                        ...APPEARANCE_DEFAULT.fontStyles.paragraph,
                        font: APPEARANCE_DEFAULT.fontStyles.paragraph.name,
                        textAlign: APPEARANCE_DEFAULT.fontStyles.paragraph.alignment,
                    },
                    logos: null,
                    logoSelected: null,
                    logoSelectedId: null,
                });
            }
        }
    }, [loading, error, data]);

    const exec = () => {
        if (id) {
            queryExec({ variables: { id } });
        } else if (designID) {
            queryExec({ variables: { designID } });
        } else {
            setThemeData(null);
        }
    };

    return {
        exec,
        reload: () => {
            if (typeof refetch === "function") refetch();
        },
        loading,
        error,
        data: themeData,
    };
};

const selectLogoToPreview = (logos) => {
    const corporate = logos.filter((logo) => logo.type === CORPORATE_LOGOS_TYPE);
    const property = logos.filter((logo) => logo.type === PROPERTY_LOGOS_TYPE);
    if (corporate.length > 0) {
        return corporate[0];
    }
    if (property.length > 0) {
        return property[0];
    }
    return null;
};

export default useTheme;
