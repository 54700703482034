const NewProduct = (data) => {
    let taxCategoryId = (data && (data.shopData && (data.shopData.customFields && data.shopData.customFields.default_tax_category && (data.shopData.customFields.default_tax_category.id)))) ? data.shopData.customFields.default_tax_category.id : null;
    return {
        title: "new-product",
        bodyAdjust: "w-3/12",
        executeSectionQuery: true,
        inputs: [
            {
                type: "data",
                value: {taxCategoryId:taxCategoryId},
            },
            {
                type: "newProduct",
                name: "new-product-name",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: "add-product",
            },
        ],
    };
};

export default NewProduct;
