import React from "react";

//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table";

//Utils
import ListUsers from "../../../hooks/GraphqlCalls/Settings/ListUsers";
import UpdateSettings from "../../../hooks/GraphqlCalls/Settings/UpdateSettings";

const Users = () => {
    ListUsers();

    return (
        <>
            <UpdateSettings />
            <UseSectionHeader title={"manager-users"} />
            <UseTable />
        </>
    );
};

export default Users;
