import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//API
import { gql } from "apollo-boost";
//Actions
import { showGlobalLoading } from "../../../actions/uiActions";
import { setSectionName, setSectionContent, setRefreshContentData } from "../../../actions/sectionActions";
import { useNavigate as useHistory } from "react-router-dom";
import { useMSQuery } from "../useMSQuery";
import { GlobalContext } from "contexts/Global";

const TagInfoRequest = ({ id }) => {
    const history = useHistory();

    const { lang } = useContext(GlobalContext);

    //Store
    const { refreshData } = useSelector((state) => state.sectionContent);
    const { currentPage, perPage, filterQuery, sortCriteria, targetId } = useSelector((state) => state.table);
    //Actions
    const dispatch = useDispatch();
    const GET_TAG_INFO = gql`
    {
        designTags(lang:"all" filter:{id:"${id}"} ){                
            info{
                count
            }
            results{
                id
                tag
                name
                assignedAll
                value
                target
                description                        
            }
        }
        
        designTagsCustom(designTagID:"${id}" page: ${currentPage} ${
        targetId ? `targetID:${targetId}` : ""
    } size: ${perPage}${sortCriteria}${filterQuery} 
            ){
                info{
                    count 
                    page
                    size
                }
            results{
            projectId
            projectName
            value
            designTagId
            }
        }            
    }`;

    const [getTagInfo, { data, refetch, networkStatus }] = useMSQuery(GET_TAG_INFO, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
        errorPolicy: "all",
        onError({ graphQLErrors }) {
            if (!data.designTags) {
                history(`/design/tags`);
                dispatch(showGlobalLoading(false));
            }
            if (graphQLErrors) {
                graphQLErrors.map((gError) => console.log(gError.message));
            }
        },
    });

    useEffect(() => {
        if (refreshData) {
            refetch();
            dispatch(setRefreshContentData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        getTagInfo();
        // eslint-disable-next-line
    }, []);

    const getTagName = (names) => {
        const namesList = JSON.parse(names);
        return namesList && namesList[lang] ? namesList[lang] : "unnamed";
    };

    useEffect(() => {
        if (
            data &&
            data.designTags &&
            data.designTags.results &&
            data.designTagsCustom &&
            data.designTagsCustom.results &&
            networkStatus === 7
        ) {
            dispatch(showGlobalLoading(false));
            const tData = data.designTags.results[0];
            const propertiesValues = data.designTagsCustom;
            if (tData) {
                dispatch(setSectionName(getTagName(tData.name)));
                dispatch(
                    setSectionContent({
                        tagData: tData,
                        propertiesValues: propertiesValues,
                    })
                );
            } else {
                history(`/design/tags`);
                dispatch(showGlobalLoading(false));
            }
        }
        // eslint-disable-next-line
    }, [data, networkStatus, sortCriteria]);
};

export default TagInfoRequest;
