export const GetScheduleMutationDates = (timetableDates) => {
    //Returns timetableDates as mutation valid string
    const stringTTDates = JSON.stringify(timetableDates);
    const formattedSTTDates = stringTTDates.split('"').join("");
    const recoverOne = formattedSTTDates.split("}],").join('}]",');
    const recoverTwo = recoverOne.split("dateList:[{").join('dateList:"[{');
    const recoverThree = recoverTwo.split("timeRanges:[").join('timeRanges:"[');
    const recoverFour = recoverThree.split("],weekdayList:[").join(']",weekdayList:"[');
    const recoverFive = recoverFour.split("],__typename:timetableRangesType").join(']"');
    const recoverSix = recoverFive.split(']",__typename:timetableDatesType').join("]");
    const recoverSeven = recoverSix.split("dateList:null").join('dateList:""');
    const recoverEight = recoverSeven.split('weekdayList:"[]"').join('weekdayList:""');
    //Add punctual row recovers
    const recoverNine = recoverEight.split("dateList:,").join('dateList:"",');
    const recoverTen = recoverNine.split(",weekdayList:}").join('",weekdayList: ""}');
    const recoverEleven = recoverTen.split('timeRanges:"[]"').join('timeRanges:""');
    const recoverTwelve = recoverEleven.split("timeRanges:,").join('timeRanges:"",');
    const recoverThirteen = recoverTwelve.split('{timeRanges:",weekday').join('{timeRanges:"",weekday');
    const recoverFourteen = recoverThirteen.split(",weekdayList:[").join(',weekdayList:"[');
    const recoverFiveteen = recoverFourteen.split("]}]}]").join(']"}]}]');
    return recoverFiveteen.split("],weekdayList:").join(']",weekdayList:');
};
