import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import TableContext from "./Context";

import Button from "components/Button";
import Icon from "components/Icon";

const Pages = () => {
    const { t } = useTranslation();
    const { page: currentPage, setPage, prevPage, nextPage, paginate, perPage, total } = useContext(TableContext);

    const totalPages = total > 0 ? Math.ceil(total / perPage) : 0;

    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    const pages = useMemo(() => {
        const pages = [];

        const ellipsis = "...";
        const ellipsisWhen = 15;

        if (totalPages < ellipsisWhen) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            let startPage = Math.max(1, currentPage - 2);
            let maxEnd = startPage + 4;

            if (startPage > 1) {
                pages.push(1);
                if (startPage > 2) {
                    pages.push(ellipsis);
                }
            }

            let endPage = Math.min(totalPages - 1, maxEnd);
            if (endPage > totalPages - 3) {
                startPage = Math.max(2, totalPages - 4);
                endPage = totalPages - 1;
            }

            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            if (endPage < totalPages - 2) {
                pages.push(ellipsis);
            }

            pages.push(totalPages);
        }

        return pages;
    }, [totalPages, currentPage]);

    if (!paginate || paginate === "lazy" || totalPages === 0) {
        return null;
    }

    const enabledPrevious = !isFirstPage && paginate !== "lazy";

    const prevClass = (enabled) =>
        classNames({
            "px-3": true,
            "text-gray-800": enabled,
            "text-gray-500": !enabled,
        });

    const pagesClass = "flex justify-center items-center";

    return (
        totalPages !== 1 && (
            <div className="flex justify-center items-center py-2 text-gray-700 mt-5">
                <Button
                    id={"prev_page"}
                    className={prevClass(enabledPrevious)}
                    onClick={prevPage}
                    disabled={!enabledPrevious}
                >
                    <Icon type="chevron-left" className="mr-2" />
                    {t("previous")}
                </Button>
                <div className={pagesClass}>
                    {pages.length > 1 &&
                        pages.map((page, index) => {
                            if (page === "...") {
                                return (
                                    <span key={index} className="px-3">
                                        {page}
                                    </span>
                                );
                            } else {
                                const id = `page_${page}`;
                                const isActive = paginate === "lazy" ? page <= currentPage : page === currentPage;
                                const btnClass = classNames({
                                    "px-3": true,
                                    "text-zafiro-400 text-xl font-bold": isActive,
                                });
                                return (
                                    <Button key={id} id={id} className={btnClass} onClick={() => setPage(page)}>
                                        {page}
                                    </Button>
                                );
                            }
                        })}
                </div>
                <Button id={"next_page"} className={prevClass(!isLastPage)} onClick={nextPage} disabled={isLastPage}>
                    {t("next")}
                    <Icon type="chevron-right" className="ml-2" />
                </Button>
            </div>
        )
    );
};

export default Pages;
