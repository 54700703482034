import React from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseButton from "../../components/useButton";
//Actions
import { showRowForm, setExecuteQuery, setHiddenRows } from "../../actions/sectionActions";
import { cleanAction, setActionValidations } from "../../actions/globalActions";
//Utils
import { toast } from "react-toastify";
import UseEditableField from "../useEditableField";
import { validateSection } from "../../hooks/Utils/Validations";
import { useTranslation } from "react-i18next";
import { setLoading, setRowInEdition } from "../../actions/tableActions";
import _ from "lodash";

const useFormAccessProfile = () => {
    const { t } = useTranslation();
    const { rowFormContent, results } = useSelector((state) => state.table);

    const { values, actionName, itemsAffected } = useSelector((state) => state.action);

    //State

    //CONSTANT
    const EDITING = actionName === "edit-access-profile";
    const sectionName = `edit-access-profile`;
    const inputs = [`${sectionName}-name`, `${sectionName}-uploadbwmin`, `${sectionName}-downloadbwmin`, `${sectionName}-uploadbwmax`, `${sectionName}-downloadbwmax`];

    //Actions
    const dispatch = useDispatch();

    const cancelAction = (e) => {
        dispatch(showRowForm(false));
        dispatch(cleanAction());
        dispatch(setHiddenRows(true));
        dispatch(setRowInEdition(null));
    };

    const saveAction = (e) => {
        //Validate fields
        let namesAlreadyUsed = null;
        if (results && results.length > 0) {
            namesAlreadyUsed = _.map(
                results.filter((wifiAccessProfile) => wifiAccessProfile.id !== itemsAffected),
                "name"
            );
        } else {
            namesAlreadyUsed = null;
        }

        const validations = validateSection(EDITING ? "edit-access-profile" : "add-access-profile", values, t, {
            namesAlreadyUsed: namesAlreadyUsed,
        });
        dispatch(setActionValidations(validations));
        const validationsArray = Object.values(validations);

        if (validationsArray.filter((item) => item === false).length > 0) {
            toast.error(t("errors-in-red"));
            return;
        }
        dispatch(setLoading(true));
        if (EDITING) {
            dispatch(
                setExecuteQuery({
                    action: "edit-access-profile",
                    params: {
                        id: itemsAffected,
                        noGoBack: true,
                    },
                })
            );
        } else {
            dispatch(
                setExecuteQuery({
                    action: "add-access-profile",
                    params: {
                        noGoBack: true,
                    },
                })
            );
        }
        dispatch(showRowForm(false));
        dispatch(setHiddenRows(true));
        dispatch(setRowInEdition(null));
    };

    const setValue = (name) => {
        let initVal = "";
        if (actionName === "edit-access-profile") {
            let itemData = results.filter((result) => result.id === itemsAffected);
            if (itemData && itemData.length > 0) {
                itemData = itemData[0];
                initVal = itemData[name];
                if (typeof initVal === "number") {
                    if (initVal <= 0) {
                        initVal = "";
                    } else {
                        initVal += "";
                    }
                }
            }
        }
        return initVal;
    };

    return (
        <>
            {rowFormContent ? (
                <div className="border-b border-gray-200">
                    <div className="w-full bg-white  h-auto flex items-center " style={{ minHeight: "4rem" }}>
                        <UseEditableField
                            data={{
                                label: "name",
                                placeholder: "name",
                                inEditionMode: true,
                                type: "text",
                                styleAdjust: "w-2/12",
                                value: setValue("name"),
                                noLabel: true,
                                id:inputs[0]
                            }}
                        />
                        <div className="w-1/12"></div>
                        <div className="w-2/12">
                            <div className="flex justify-start w-full my-2">
                                <span className="icon icon-back1 animated rotate-90 text-xl mr-3 -ml-1"></span>
                                <UseEditableField
                                    data={{
                                        label: "uploadbwmin",
                                        placeholder: "upload-mb",
                                        inEditionMode: true,
                                        type: "number",
                                        value: setValue("uploadbwmin"),
                                        noLabel: true,
                                        numberMin: 0,
                                        styleAdjust: "w-32",
                                        id:inputs[1]
                                    }}
                                />
                            </div>
                            <div className="flex justify-start w-full my-2">
                                <span className="icon icon-back1 animated -rotate-90 text-xl ml-4 -mr-2"></span>
                                <UseEditableField
                                    data={{
                                        label: "downloadbwmin",
                                        placeholder: "download-mb",
                                        inEditionMode: true,
                                        type: "number",
                                        value: setValue("downloadbwmin"),
                                        noLabel: true,
                                        numberMin: 0,
                                        styleAdjust: "w-32",
                                        id:inputs[2]
                                    }}
                                />
                            </div>
                        </div>
                        <div className="w-2/12">
                            <div className="flex justify-start w-full my-2">
                                <span className="icon icon-back1 animated rotate-90 text-xl mr-3 -ml-1"></span>
                                <UseEditableField
                                    data={{
                                        label: "uploadbwmax",
                                        placeholder: "upload-mb",
                                        inEditionMode: true,
                                        type: "number",
                                        value: setValue("uploadbwmax"),
                                        noLabel: true,
                                        numberMin: 0,
                                        styleAdjust: "w-32",
                                        id:inputs[3]
                                    }}
                                />
                            </div>
                            <div className="flex justify-start w-full my-2">
                                <span className="icon icon-back1 animated -rotate-90 text-xl ml-4 -mr-2"></span>
                                <UseEditableField
                                    data={{
                                        label: "downloadbwmax",
                                        placeholder: "download-mb",
                                        inEditionMode: true,
                                        type: "number",
                                        value: setValue("downloadbwmax"),
                                        noLabel: true,
                                        numberMin: 0,
                                        styleAdjust: "w-32",
                                        id:inputs[4]
                                    }}
                                />
                            </div>
                        </div>
                        <div className="w-1/12"></div>
                        <div className="w-4/12">
                            <div className="flex justify-center w-full">
                                <div className={`whitespace-normal pr-2`} onClick={(e) => cancelAction(e)}>
                                    <UseButton buttonName={t("cancel")} buttonColor={"btn-white"} id={`${sectionName}-cancel`} />
                                </div>
                                <div className={`whitespace-normal`} onClick={(e) => saveAction(e)}>
                                    <UseButton buttonName={t("save")} buttonColor={"btn-blue"} id={`${sectionName}-save`} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default useFormAccessProfile;
