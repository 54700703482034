export const AuthSelectProject = [
    "available",
    "are-allo",
    "cant obtain your projects",
    "hotel",
    "next",
    "previous",
    "projects",
    "search",
    "select",
    "showing-all",
    "showing-all-results",
    "your",
    "yours",
];
