import React from "react";
import UseSectionHeader from "../../../../useSectionHeader";
import UseTable from "../../../../Table/index";
import ListGroupChannels from "../../../../../hooks/GraphqlCalls/Services/ListGroupChannels";
import UpdateGroupChannels from "../../../../../hooks/GraphqlCalls/Services/UpdateGroupChannels";

const GroupChannels = () => {
    return (
        <>
            <UseSectionHeader title={"groups"} customBreadCrumbs={breadcrumbs} />
            <UseTable />
            <ListGroupChannels />
            <UpdateGroupChannels />
        </>
    );
};

export default GroupChannels;

const breadcrumbs = [
    {
        name: "services",
        translate: true,
        route: "/services",
    },
    {
        name: "tv-channels",
        translate: true,
        route: "/services/tv-channels/",
    },
];
