import { Widget } from "zrender/widgets.definitions";
//import { WIDGETS } from "hooks/Utils/Widgets";

// Device is the type of device that displays the screen
export const DEVICE = {
    TYPE: {
        TV: "TV",
        MOBILE: "Mobile",
        DESKTOP: "Desktop",
    },
};

// Screen is the type of screen that is presented to the user
// Note: some screens can be configured to multiple devices, but not all screens are available for all devices
export const SCREEN = {
    TYPE: {
        TV: "TV",
        WELCOME: "WELCOME",
        SALES: "SALES",
        SALESMOBILE: "SALESMOBILE",
        EXPRESSCHECKOUT: "EXPRESSCHECKOUT",
        MOBILE: "MOBILE",
        CCINSTRUCTION: "CCINSTRUCTION",
        CCPAIRINGSUCCESSFUL: "CCPAIRINGSUCCESSFUL",
        GENERAL: "GENERAL",
        CAST: "CAST",
        PORTAL: "PORTAL",
        WIFI: "WIFI",
    },
    // TODO - Review this LTYPE data (evaluatin __typename from graphql response)
    LTYPE: {
        LANDING: "ltLanding",
        WELCOME: "ltWelcome",
        SIMPLECONTENT: "SimpleContent",
        SUBDIR: "stSubDir",
        WELCOME_CONTENT: "WelcomeScreenContentGET",
    },
};

// Welcome sequences are the different types of content that can be displayed in the welcome screen
export const WELCOME_SEQUENCE = {
    LANGSELECTION: "LANGSELECTION",
    VIDEO: "VIDEO",
    WIDGETS: "WIDGETS",
};
export const WELCOME_SEQUENCES = Object.values(WELCOME_SEQUENCE).map((type) => ({
    type,
    icon: (() => {
        switch (type) {
            case WELCOME_SEQUENCE.LANGSELECTION:
                return "icon-information";
            case WELCOME_SEQUENCE.VIDEO:
                return "icon-play2";
            case WELCOME_SEQUENCE.WIDGETS:
                return "icon-areas-activities";
            default:
                return "icon-warning";
        }
    })(),
    translate: (() => {
        switch (type) {
            case WELCOME_SEQUENCE.LANGSELECTION:
                return "welcomeScreen-lang";
            case WELCOME_SEQUENCE.VIDEO:
                return "welcomeScreen-video";
            case WELCOME_SEQUENCE.WIDGETS:
                return "welcomeScreen-widgets";
            default:
                return type;
        }
    })(),
}));

export const WIDGET = {
    TYPE: {
        // Implemented in zrender
        TOPBAR: Widget.TopBar,
        WIFILOGIN: Widget.WiFiLogin,
        IMAGE: Widget.Image,
        LOGO: Widget.Logo,
        SHOPITEM: Widget.ShopItem,
        SHOPORDERITEM: Widget.ShopOrderItem,
        SLIDER: Widget.Slider,
        // Not implemented in zrender yet
        ROOMS: Widget.Rooms,
        TVMENU: Widget.TVMenu,
        TVMENUNAV: Widget.TVMenuNav,
        VIDEO: Widget.Video,
        WEATHER: Widget.Weather,
        ALARM: Widget.Alarm,
        BUTTON: Widget.Button,
        CHATBUTTON: Widget.ChatButton,
        CALENDAR: Widget.Calendar,
        CAROUSEL: Widget.Carousel,
        CLOCK: Widget.Clock,
        CONTAINER: Widget.Container,
        HTML: Widget.HTML,
        ICON: Widget.Icon,
        IFRAME: Widget.iFrame,
        LANGSELECTION: Widget.LangSelection,
        MESSAGES: Widget.Messages,
        QR: Widget.QR,
        TEXT: Widget.Text,
    },
};

export const WIDGETS = {
    ALARM: {
        name: "alarm",
        icon: "alarm",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: true,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 10, h: 6 },
        },
        availability: {
            screenTypes: [SCREEN.TYPE.GENERAL, SCREEN.TYPE.TV],
            deviceTypes: [DEVICE.TYPE.TV],
            tvMenu: true,
        },
    },
    BUTTON: {
        name: "button",
        icon: "button-widget",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 6 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 3 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter(
                (t) =>
                    ![
                        SCREEN.TYPE.WELCOME,
                        SCREEN.TYPE.CCINSTRUCTION,
                        SCREEN.TYPE.CCPAIRINGSUCCESSFUL,
                        SCREEN.TYPE.PORTAL,
                    ].includes(t)
            ),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    CALENDAR: {
        name: "calendar",
        icon: "booking",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: true,
        usingZRender: false,
        layout: { [DEVICE.TYPE.TV]: { w: 16, h: 13 } },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter(
                (t) => ![SCREEN.TYPE.PORTAL, SCREEN.TYPE.MOBILE, SCREEN.TYPE.CCPAIRINGSUCCESSFUL].includes(t)
            ),
            deviceTypes: [DEVICE.TYPE.TV],
            tvMenu: false,
        },
    },
    CAROUSEL: {
        name: "carousel",
        icon: "images",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 9 },
            [DEVICE.TYPE.DESKTOP]: { w: 16, h: 9 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 6 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    CHATBUTTON: {
        name: "chat",
        icon: "language",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 3 },
        },
        availability: {
            screenTypes: [SCREEN.TYPE.GENERAL, SCREEN.TYPE.MOBILE],
            deviceTypes: [DEVICE.TYPE.MOBILE],
            tvMenu: false,
        },
    },
    CLOCK: {
        name: "clock",
        icon: "clock",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: true,
        usingZRender: false,
        layout: { [DEVICE.TYPE.TV]: { w: 10, h: 6 } },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter(
                (t) => ![SCREEN.TYPE.PORTAL, SCREEN.TYPE.MOBILE, SCREEN.TYPE.CCPAIRINGSUCCESSFUL].includes(t)
            ),
            deviceTypes: [DEVICE.TYPE.TV],
            tvMenu: true,
        },
    },
    CONTAINER: {
        name: "container",
        icon: "checkbox-unchecked",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 13 },
            [DEVICE.TYPE.DESKTOP]: { w: 16, h: 13 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 8 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    HTML: {
        name: "HTML",
        icon: "embed2",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 6 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 3 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter((t) => ![SCREEN.TYPE.PORTAL].includes(t)),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    ICON: {
        name: "icon",
        icon: "add_circle",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 4, h: 4 },
            [DEVICE.TYPE.DESKTOP]: { w: 4, h: 4 },
            [DEVICE.TYPE.MOBILE]: { w: 2, h: 2 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    IFRAME: {
        name: "iframe",
        icon: "embed",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 6 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 3 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter((t) => ![SCREEN.TYPE.PORTAL].includes(t)),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    IMAGE: {
        name: "image",
        icon: "image",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: true,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 9 },
            [DEVICE.TYPE.DESKTOP]: { w: 16, h: 9 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 6 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: true,
        },
    },
    LANGSELECTION: {
        name: "langselector",
        icon: "language",
        hidden: true,
        isResizable: false,
        isDraggable: false,
        usingDriveRender: false,
        usingZRender: false,
        availability: {
            screenTypes: [],
            deviceTypes: [],
            tvMenu: false,
        },
    },
    LOGO: {
        name: "logo",
        icon: "logo",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: true,
        usingZRender: true,
        layout: {
            [DEVICE.TYPE.TV]: { w: 10, h: 6 },
            [DEVICE.TYPE.DESKTOP]: { w: 10, h: 6 },
            [DEVICE.TYPE.MOBILE]: { w: 7, h: 4 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: true,
        },
    },
    MESSAGES: {
        name: "messages",
        icon: "messaging-widget",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 10, h: 6 },
            [DEVICE.TYPE.MOBILE]: { w: 7, h: 4 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter(
                (t) =>
                    ![
                        SCREEN.TYPE.WELCOME,
                        SCREEN.TYPE.CCINSTRUCTION,
                        SCREEN.TYPE.CCPAIRINGSUCCESSFUL,
                        SCREEN.TYPE.PORTAL,
                    ].includes(t)
            ),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: true,
        },
    },
    QR: {
        name: "qr code",
        icon: "qrcode",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 7, h: 7 },
            [DEVICE.TYPE.DESKTOP]: { w: 7, h: 7 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter(
                (t) => ![SCREEN.TYPE.PORTAL, SCREEN.TYPE.MOBILE, SCREEN.TYPE.CCPAIRINGSUCCESSFUL].includes(t)
            ),
            deviceTypes: [DEVICE.TYPE.TV, DEVICE.TYPE.DESKTOP],
            tvMenu: false,
        },
    },
    ROOMS: {
        name: "add room",
        icon: "rooms",
        hidden: true,
        isResizable: false,
        isDraggable: false,
        usingDriveRender: false,
        usingZRender: false,
        layout: { [DEVICE.TYPE.MOBILE]: { w: 2, h: 12 } },
        availability: {
            screenTypes: [],
            deviceTypes: [],
            tvMenu: false,
        },
    },
    SHOPITEM: {
        name: null,
        icon: null,
        hidden: true,
        isResizable: false,
        isDraggable: false,
        usingDriveRender: false,
        usingZRender: true,
        availability: {
            screenTypes: [],
            deviceTypes: [],
            tvMenu: false,
        },
    },
    SHOPORDERITEM: {
        name: null,
        icon: null,
        hidden: true,
        isResizable: false,
        isDraggable: false,
        usingDriveRender: false,
        usingZRender: true,
        availability: {
            screenTypes: [],
            deviceTypes: [],
            tvMenu: false,
        },
    },
    SLIDER: {
        name: "slider",
        icon: "widget-slider",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: true,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 9 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 6 },
        },
        availability: {
            screenTypes: [SCREEN.TYPE.GENERAL, SCREEN.TYPE.MOBILE],
            deviceTypes: [DEVICE.TYPE.MOBILE],
            tvMenu: false,
        },
    },
    TEXT: {
        name: "text",
        icon: "font-size",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 6 },
            [DEVICE.TYPE.DESKTOP]: { w: 16, h: 6 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 3 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    TOPBAR: {
        name: "screen title",
        icon: "font-size",
        hidden: true,
        isResizable: false,
        isDraggable: false,
        usingDriveRender: false,
        usingZRender: true,
        layout: {
            [DEVICE.TYPE.MOBILE]: { w: 2, h: 12 },
        },
        availability: {
            screenTypes: [],
            deviceTypes: [],
            tvMenu: false,
        },
    },
    TVMENUNAV: {
        name: "tvmenunav",
        icon: "navigation-horizontal",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 24, h: 6 },
        },
        availability: {
            screenTypes: [],
            deviceTypes: [],
            tvMenu: true,
        },
    },
    VIDEO: {
        name: "video",
        icon: "play2",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 9 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 6 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter((t) => ![SCREEN.TYPE.PORTAL].includes(t)),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    WEATHER: {
        name: "weather",
        icon: "sun",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 24, h: 9 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 4 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter((t) => ![SCREEN.TYPE.PORTAL].includes(t)),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    WIFILOGIN: {
        name: "loginsection",
        icon: "Wifi-access",
        hidden: true,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: true,
        availability: {
            screenTypes: [],
            deviceTypes: [],
            tvMenu: false,
        },
    },
};

export const getWidgets = ({ deviceType, screenType }) => {
    return WIDGETS
        ? Object.keys(WIDGETS)
              .map((type) => {
                  const widget = WIDGETS[type];
                  return {
                      type: WIDGET.TYPE[type],
                      ...widget,
                  };
              })
              .filter((w) => {
                  const requireDevice = w?.availability?.deviceTypes;
                  const requireScreen = w?.availability?.screenTypes;

                  const checkHidden = !w?.hidden;
                  const checkDevice = !requireDevice || !deviceType || requireDevice.includes(deviceType);
                  const checkScreen = !requireScreen || !screenType || requireScreen.includes(screenType);

                  return checkHidden && checkDevice && checkScreen;
              })
        : [];
};
