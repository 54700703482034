import React, { useState } from "react";
import { GetCellContent } from "../../../hooks/Utils/GetCellContent";
import useLangStings from "../../../lang/useLangStrings";

const UseTextCell = ({ cellData }) => {
    const { hidden, adjust, subvalue, icon, tooltipData, forceShowTooltip } = cellData || {};
    const content = GetCellContent(cellData);
    const tooltipText = useLangStings(tooltipData);
    const [disableTooltip, setDisableTooltip] = useState(false);
    const showTooltip = (e) => {
        if (forceShowTooltip) {
            setDisableTooltip(false);
            return;
        }

        if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) {
            setDisableTooltip(false);
        } else {
            setDisableTooltip(true);
        }
    };

    return (
        <>
            {!hidden ? (
                <>
                    <div
                        className={`text-left ${adjust}`}
                        data-tip={!icon ? tooltipText : null}
                        data-for="default-tooltip"
                        onMouseOver={(e) => showTooltip(e)}
                        data-tip-disable={disableTooltip}
                    >
                        {content}
                        {icon ? (
                            <div
                                className={`inline-block mr-3 icon-${icon.name} ${icon.adjust}`}
                                data-tip={tooltipText}
                                data-for="default-tooltip"
                            ></div>
                        ) : null}
                    </div>
                    {subvalue ? <div className={`text-left text-gray-500`}>{subvalue}</div> : null}
                </>
            ) : null}
        </>
    );
};

export default UseTextCell;
