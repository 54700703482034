import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const FilterSelector = ({ type, options, onSelect, lastSync, closeAll, setCloseAll }) => {
    const { t } = useTranslation();

    const [showOptions, setShowOptions] = useState(false);
    const [filter, setFilter] = useState();
    const selectOption = (opt) => {
        setFilter(opt?.id === "all" ? null : opt);
    };

    useEffect(() => {
        onSelect && onSelect(filter?.id);
    }, [filter]);

    useEffect(() => {
        lastSync && setFilter(null);
    }, [lastSync]);

    useEffect(() => {
        if (closeAll) {
            setShowOptions(false);
        }
    }, [closeAll]);

    return (
        <>
            <div
                id={`input-${type}-selector`}
                className={`t-filter-input w-11/12 bg-gray-200 block`}
                onClick={(e) => {
                    setCloseAll(new Date().getMilliseconds());
                    setTimeout(function () {
                        setShowOptions(!showOptions);
                    }, 1);
                    e.stopPropagation();
                }}
            >
                <div className={`t-filter-ph`}>
                    <div className={`first-capital ${filter && "text-gray-900"} `}>{filter?.text || t(type)}</div>
                </div>
                <span className={`field-icon icon-chevron animated text-gray-900`}></span>
                {showOptions ? (
                    <div className="absolute w-full mt-1 py-2 z-100 bg-white  rounded shadow-lg  border border-gray-200  ">
                        <div className="mx-5 max-h-64 overflow-y-auto overflow-x-hidden">
                            {options?.map((opt, index) => {
                                return index < 1000 ? (
                                    <div
                                        id={`btn-${type}-opt-${index}`}
                                        key={`btn-${type}-opt-${index}`}
                                        onClick={(e) => selectOption(opt)}
                                        className="py-2 clickable"
                                    >
                                        {opt.text}
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default FilterSelector;
