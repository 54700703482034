import React, { useEffect, useState } from "react";
import UseRadio from "../useRadio";
import UseSelectMultipleWithSearchOptionsQuery from "../useSelectMultipleWithSearchOptionsQuery";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import UseInputText from "../Table/useInputText";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changeActionValues } from "../../actions/globalActions";
import { useSelector } from "react-redux";

const UseModalAddGroupChannelToGrid = ({ index }) => {
    const [optionSelected, setOptionSelected] = useState("group-list");
    const [optionsGroupData, setOptionsGroupData] = useState([]);
    const [nameGroup, setNameGroup] = useState("");
    const { validations } = useSelector((state) => state.action);
    const { data: dataModal } = useSelector((state) => state.ui.modalContent.inputs[index]);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const GET_TUNES = gql`
        {
            tuneGroups(filter: { notInGrid: ${dataModal.gridId ? dataModal.gridId : ""} }) {
                id
                name
            }
        }
    `;

    const [executeQuery, { data }] = useLazyQuery(GET_TUNES, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (data?.tuneGroups) {
            setOptionsGroupData(data?.tuneGroups);
        }
    }, [data]);

    useEffect(() => {
        dispatch(changeActionValues({ "option-selected": optionSelected }));
        setNameGroup("");
    }, [optionSelected]);

    useEffect(() => {
        dispatch(changeActionValues({ "name-group": nameGroup }));
    }, [nameGroup]);

    return (
        <div>
            <UseRadio
                id={"add-group-to-grid-radio-group-list"}
                value={"group-list"}
                selected={optionSelected}
                handleClick={() => {
                    setOptionSelected("group-list");
                }}
                text={"group-list"}
            />
            {optionSelected === "group-list" ? (
                <div className=" ml-10 mt-5 mb-4">
                    <UseSelectMultipleWithSearchOptionsQuery
                        dataSelect={{
                            name: "select-group-list-channels",
                            selectPlaceHolder: "select-groups",
                            optionData: optionsGroupData,
                            noSelect: true,

                            type: "selectMultipleWithSearch",
                        }}
                        key={optionsGroupData}
                    />
                </div>
            ) : null}
            <UseRadio
                id={"add-group-to-grid-radio-new-group"}
                selected={optionSelected}
                value={"new-group"}
                handleClick={() => {
                    setOptionSelected("new-group");
                }}
                text={"new-group"}
            />
            {optionSelected !== "group-list" ? (
                <div className=" ml-10 mt-2 mb-4">
                    <div className=" text-sm font-bold text-gray-800 mb-2">{t("name")}</div>
                    <UseInputText
                        inputData={{ name: "new-group-channels-name" }}
                        ph={t("name")}
                        currentValue={nameGroup}
                        validation={validations["name-group"] === false ? false : true}
                        id={"add-group-to-grid-radio-new-group-name-input"}
                        onChangeAction={(val) => {
                            setNameGroup(val);
                        }}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default UseModalAddGroupChannelToGrid;
