import BookingAllowedModal from "../modals/areasActivities/BookingAllowedModal";
import ShowScheduleModal from "../modals/areasActivities/ShowScheduleModal";
import EditCapacityModal from "../modals/areasActivities/EditCapacityModal";
import TranslateTextModal from "../../Translate/Modals/TranslateTextModal";

export const AreaCategory = ({
    area,
    langStrings,
    refetch,
    openModalEditNameCommonZones,
    openModalEditBookingAllowed,
}) => {
    //Data
    const {
        id,
        name,
        nameTID,
        ref,
        bookingAllowed,
        bookingNumber,
        schedule,
        capacityLimit,
        description,
        currentStatus,
        currentComment,
        parentCategoryName,
        notificationEmail,
    } = area;
    const dataType = "area";
    const tempClosed = "temp-closed";
    const editModal = "edit-modal";
    return {
        ...area,
        dataType: dataType,
        currentStatus: currentStatus,
        id: id,
        ref: ref,
        actions: [{ type: dataType, value: id }],
        parentCategory: parentCategoryName,
        notificationEmail: notificationEmail,
        specialBackground: !currentStatus,
        info: [
            {
                name: "text",
                cellContent: "edit-modal",
                value: name,
                link: getLinkContent(bookingAllowed, ref),
                linkComplete: true,
                modal: TranslateTextModal("edit-name", nameTID, langStrings, "COMMONZONES"),
                icon: {
                    id: id,
                    name: "edit",
                    color: "text-gray-800",
                    itemType: dataType,
                    position: "right",
                    action: "edit-area-name",
                    value: name,
                },
                /*onClickAction: () => {
                    openModalEditNameCommonZones({ id: nameTID, name, refetch });
                },*/
                specialWidth: "w-3/12",
                contentAdjust: "truncate",
                componentStyle: { maxWidth: "17%" },
            },
            {
                name: "category",
                cellContent: "text",
                value: parentCategoryName,
                specialWidth: "w-3/12",
            },
            {
                name: "booking-allowed",
                cellContent: editModal,
                value: getBookingAllowedValue(bookingAllowed, langStrings),
                type: dataType,
                evaluate: true,
                icon: { name: "edit", itemType: dataType, position: "right" },
                modal: BookingAllowedModal(area),
                /*onClickAction: () => {
                    openModalEditBookingAllowed({ area, refetch });
                },*/
                specialWidth: "w-1/5",
            },
            {
                name: "booking-number",
                cellContent: "value",
                value: getBookingNumberValue(bookingNumber),
                sufix: getBookingNumberSufix(bookingNumber),
                translateSufix: getBNTransSufix(bookingNumber),
                specialWidth: "w-1/5",
            },
            {
                name: "schedule",
                cellContent: editModal,
                value: getScheduleContent(schedule, langStrings["unspecified"], langStrings["view-schedules"]),
                icon: { name: "edit", itemType: dataType, position: "right" },
                modal: ShowScheduleModal(area, langStrings),
                fallBackLink: getFallBackLink(schedule, id),
                specialWidth: "w-1/5",
                alert: !currentStatus ? { name: "warning", value: "2", itemType: dataType } : null,
                // tooltip for alert icon
                textTooltip: !currentStatus ? "temp-closed" : "",
                colorTextTooltip: !currentStatus ? "#DE350B" : "",
            },
            {
                name: "capacity-limit",
                cellContent: editModal,
                value: getCapacityLimitContent(capacityLimit, langStrings["unspecified"]),
                sufix: getCapacityLimitSufix(capacityLimit),
                translateSufix: true,
                icon: { name: "edit", itemType: dataType, position: "right" },
                modal: EditCapacityModal(area),
                specialWidth: "w-1/5",
            },
        ],
        extendedInfo: [
            {
                name: "description",
                cellContent: "value-extended",
                value: description,
                title: "description",
                translateTitle: true,
                separator: true,
                link: `/common-zones/zones-configuration/area/description/${id}`,
                icon: {
                    name: "edit",
                    itemType: "activity-description",
                    position: "right",
                },
                // modal: EditDescriptionModal(area),
                specialWidth: "w-6/12",
            },
            {
                name: tempClosed,
                cellContent: "value-extended",
                value: getCloseContent(currentStatus, currentComment, langStrings["unspecified"]),
                title: tempClosed,
                translateTitle: true,
                currentStatus: currentStatus,
                hidden: getTempClosedVisibility(currentStatus),
                icon: {
                    name: "edit",
                    itemType: "activity-status-desc",
                    position: "right",
                },
                link: `/common-zones/zones-configuration/area/close/${id}`,
                specialWidth: "w-5/12",
            },
        ],
    };
};

export const getCloseContent = (currentStatus, currentComment, unspecified) => {
    let res;
    if (!currentStatus) {
        if (currentComment) {
            res = currentComment;
        } else {
            res = unspecified;
        }
    } else {
        res = "";
    }
    return res;
};

export const getScheduleContent = (schedule, newSchedule, viewSchedules) => {
    let res;
    if (schedule.length === 0) {
        res = [newSchedule];
    } else {
        res = [viewSchedules];
    }
    return res;
};

export const getLinkContent = (bookingAllowed, id) => {
    let res;
    if (!bookingAllowed) {
        res = false;
    } else {
        res = `/common-zones/booking/categories/area/${id}`;
    }
    return res;
};

export const getCapacityLimitContent = (capacityLimit, unspecified) => {
    let res;
    if (!capacityLimit || capacityLimit === 0) {
        res = unspecified;
    } else {
        res = capacityLimit;
    }
    return res;
};

export const getBookingManagementContent = (currentStatus, tempClosedString, manageBookings) => {
    let res;
    if (!currentStatus) {
        res = tempClosedString;
    } else {
        res = manageBookings;
    }
    return res;
};

export const getBookingAllowedValue = (bookingAllowed, langStrings) => {
    let res;
    if (bookingAllowed) {
        res = langStrings["yes"];
    } else {
        res = langStrings["no"];
    }
    return res;
};

export const getBookingNumberValue = (bookingNumber) => {
    let res;
    if (bookingNumber > 0) {
        res = bookingNumber;
    } else {
        res = "";
    }
    return res;
};

export const getBookingNumberSufix = (bookingNumber) => {
    let res;
    if (bookingNumber > 0) {
        res = "bookings";
    } else {
        res = "";
    }
    return res;
};

export const getBNTransSufix = (bookingNumber) => {
    let res;
    if (bookingNumber > 0) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const getFallBackLink = (schedule, id) => {
    let res;
    if (schedule.length === 0) {
        res = `#/common-zones/zones-configuration/areas/schedule/${id}`;
    } else {
        res = false;
    }
    return res;
};

export const getCapacityLimitSufix = (capacityLimit) => {
    let res;
    if (!capacityLimit || capacityLimit === 0) {
        res = false;
    } else {
        res = "people";
    }
    return res;
};

export const getManagementCellContent = (bookingAllowed, currentStatus) => {
    let res;
    if (bookingAllowed && currentStatus) {
        res = "link";
    } else {
        res = "text";
    }
    return res;
};

export const getManagementVisibility = (bookingAllowed, currentStatus) => {
    let res;
    if (!bookingAllowed && currentStatus) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const getManagementAdjust = (currentStatus) => {
    let res;
    if (!currentStatus) {
        res = "font-bold fail first-capital";
    } else {
        res = "";
    }
    return res;
};

export const getTempClosedVisibility = (currentStatus) => {
    let res;
    if (!currentStatus) {
        res = false;
    } else {
        res = true;
    }
    return res;
};

export default AreaCategory;
