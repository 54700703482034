import React, { useState, useEffect } from "react";
// import { useTranslation } from "react-i18next";

const UseModalReleaseNotes = () => {
    // const { t } = useTranslation();

    //states
    const [show] = useState("release");

    const releaseURL = `https://docs.google.com/document/d/e/2PACX-1vQiDYaDY4RiTEkWr9WmiJ4SvMMYNJiod6wig19YQIbhLJSx0ZVY_q-iHbO7fGmyKw/pub?embedded=true`;
    const featuresURL = `https://docs.google.com/document/d/e/2PACX-1vTh6Jx1Ujb9WcLTgFob0xYLOuhc-1sJQRqH-dB1w9-Ly5MnZ7A5qq2iA-f6MbNxwg/pub?embedded=true`;
    const issuesURL = `https://docs.google.com/document/d/e/2PACX-1vR1mfn5n4SfNNTqpn5hjKP989i54us-TAlEsJls1TGsqjEQgcnUY6pkE1R1oN0U3w/pub?embedded=true`;

    //listeners

    useEffect(() => {
        const iframe = document.querySelector("#releaseNotesIframe");
        switch (show) {
            case "release":
                iframe.src = releaseURL;
                break;
            case "issues":
                iframe.src = issuesURL;
                break;
            case "features":
                iframe.src = featuresURL;
                break;
            default:
                break;
        }
        // eslint-disable-next-line
    }, [show]);

    return (
        <>
            <div className="mx-auto table float-right" style={{ marginTop: "-6.5rem" }}>
                {/* <div
                    className={`${
                        show === "release" ? "bg-zafiro-400 text-white" : "bg-gray-200"
                    } p-4 m-2 float-left cursor-pointer rounded`}
                    onClick={(e) => setShow("release")}
                >
                    {t("{{capitalize, capitalize}}", {
                        capitalize: t("Release Notes"),
                    })}
                </div>
               <div
                    className={`${
                        show === "features" ? "bg-zafiro-400 text-white" : "bg-gray-200"
                    }  p-4 m-2 float-left cursor-pointer rounded`}
                    onClick={(e) => setShow("features")}
                >
                    {t("{{capitalize, capitalize}}", {
                        capitalize: t("New Features"),
                    })}
                </div>*/}
                {/*
                <div
                    className={`${
                        show === "issues" ? "bg-zafiro-400 text-white" : "bg-gray-200"
                    }  p-4 m-2 float-left cursor-pointer rounded`}
                    onClick={(e) => setShow("issues")}
                >
                    {t("{{capitalize, capitalize}}", {
                        capitalize: t("Known Issues"),
                    })}
                </div>*/}
            </div>
            <iframe
                id="releaseNotesIframe"
                height="45vh"
                width="100%"
                title="release notes"
                style={{
                    minHeight: "calc(66vh - 75px - 7.2vmax)",
                }}
            ></iframe>
        </>
    );
};

export default UseModalReleaseNotes;
