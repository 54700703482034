import React, { useEffect, useRef, useState } from "react";
import "./carousel.css";

const Carousel = (props) => {
    const { children, defaultShow, widthItems } = props;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [length, setLength] = useState(children.length);
    const [show, setShow] = useState(defaultShow);
    const containerRef = useRef();

    const [touchPosition, setTouchPosition] = useState(null);

    // Set the length to match current children from props

    useEffect(() => {
        let itemsShow = defaultShow ?? null;
        if (!itemsShow && widthItems) {
            itemsShow = parseInt(containerRef.current.clientWidth / widthItems, 10);
        }
        setShow(itemsShow);
    }, [defaultShow, widthItems]);

    useEffect(() => {
        setLength(children.length);
    }, [children]);

    const next = () => {
        if (currentIndex < length - show) {
            setCurrentIndex((prevState) => prevState + 1);
        }
    };

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevState) => prevState - 1);
        }
    };

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX;
        setTouchPosition(touchDown);
    };

    const handleTouchMove = (e) => {
        const touchDown = touchPosition;

        if (touchDown === null) {
            return;
        }

        const currentTouch = e.touches[0].clientX;
        const diff = touchDown - currentTouch;

        if (diff > 5) {
            next();
        }

        if (diff < -5) {
            prev();
        }

        setTouchPosition(null);
    };

    return (
        <div className="carousel-container" id="carousel-container" ref={containerRef}>
            <div className="carousel-wrapper">
                {/* You can alwas change the content of the button to other things */}
                {currentIndex > 0 && (
                    <div className="left-arrow flex items-center align-center bg-gray-500 opacity-50 cursor-pointer">
                        <span onClick={prev} className=" icon icon-arrow-left text-4xl text-black px-1"></span>
                    </div>
                )}
                <div className="carousel-content-wrapper" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
                    <div
                        className={`carousel-content ` /*show-${show}*/}
                        style={{
                            transform: `translateX(-${
                                widthItems ? `${currentIndex * widthItems}px` : `${currentIndex * (100 / show)}%`
                            })`,
                        }}
                    >
                        {children}
                    </div>
                </div>
                {/* You can alwas change the content of the button to other things */}
                {renderNextButton({ widthItems, currentIndex, length, show, next })}
            </div>
        </div>
    );
};

const renderNextButton = (props) => {
    const { widthItems, currentIndex, length, show, next } = props;
    let response = null;
    if (widthItems) {
        if (currentIndex * widthItems < length * widthItems - (show + 1) * widthItems) {
            response = (
                <div className="right-arrow flex items-center align-center bg-gray-500 opacity-50 cursor-pointer">
                    <span
                        onClick={next}
                        onKeyDown={next}
                        className=" icon icon-arrow-right text-4xl text-black px-1"
                    ></span>
                </div>
            );
        }
    } else if (currentIndex < length - show) {
        response = (
            <div className="right-arrow flex items-center align-center bg-gray-500 opacity-50 cursor-pointer">
                <span
                    onClick={next}
                    onKeyDown={next}
                    className=" icon icon-arrow-right text-4xl text-black px-1"
                ></span>
            </div>
        );
    }
    return response;
};

export default Carousel;
