import React, { useEffect, useState } from "react";

//Components
import UpdateBulkChannels from "hooks/GraphqlCalls/Services/UpdateBulkChannels";
import UseButton from "../../../../../components/useButton";
import { useNavigate as useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactTooltip from "components/ReactTooltip";
import DvbChannelTable from "./DvbChannelTable";
import { Session } from "hooks/Utils/Session";
import { openModal, setModalContent } from "actions/uiActions";
import { toast } from "react-toastify";
import { setActionName } from "actions/globalActions";

import { useAuth } from "hooks/Session/auth";

const ChannelsImport = () => {
    const sectionName = "import-channel";

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { project } = useAuth();

    const parsedFileName = useSelector((state) => state.sectionContent.parsedFileName);
    const propertyName = project?.name;
    const { parsedChannels, predefinedChannels, tunedChannels } = useSelector((state) => state.sectionContent);

    const [channels, setChannels] = useState([]);
    const [checkedChannels, setCheckedChannels] = useState([]);
    const [closeAll, setCloseAll] = useState();

    const getChannels = () => {
        let channels = parsedChannels;
        if (!channels) {
            let backup = Session.getSessionProp("importChannelsBackup");
            backup = JSON.parse(backup);
            channels = backup.parsedChannels;
        } else {
            Session.setSessionProp(
                "importChannelsBackup",
                JSON.stringify({ parsedChannels, predefinedChannels, tunedChannels })
            );
        }
        return channels?.sort((a, b) => (a.Name > b.Name ? 1 : -1));
    };
    const getTunedChannels = () => {
        let channels = tunedChannels;
        if (!channels) {
            let backup = Session.getSessionProp("importChannelsBackup");
            backup = JSON.parse(backup);
            channels = backup.tunedChannels;
        }
        return channels;
    };

    useEffect(() => {
        setChannels(getChannels());
    }, []);

    function openSummaryModal(channelsToAdd) {
        //get tuned channels not present in new parse
        let tunesToDelete = null;
        const tuneType = channelsToAdd?.[0]?.TuneType;
        const existingTunes = getTunedChannels().filter((tCh) => tCh.TuneInfo?.[tuneType]);
        tunesToDelete = existingTunes.filter((eCh) => !channelsToAdd.find((ch) => ch?.Tune?.id === eCh.id));

        dispatch(setActionName("import-dvb-channels"));
        dispatch(
            setModalContent({
                title: ["add selected", "-", "parsing summary"],
                bodyAdjust: "text-justify max-h-80 overflow-y-scroll",
                inputs: [
                    {
                        type: "dvbImportResume",
                        channelsToAdd: channelsToAdd,
                        tunesToDelete: tunesToDelete,
                    },
                    { type: "data", channels: channelsToAdd, tunesToDelete: tunesToDelete },
                ],
                executeSectionQuery: true,
                buttons: [
                    { name: "cancel", style: "white", action: "close" },
                    {
                        action: "import-dvb-channels",
                        name: "continue",
                        style: "blue",
                    },
                ],
            })
        );
        dispatch(openModal());
    }
    function openUnlinkedModal(unlinkedChannels) {
        dispatch(
            setModalContent({
                title: ["tune-failed", "-", "unlinked channels"],
                bodyAdjust: "w-3/6 text-justify max-h-80 overflow-y-scroll",
                inputs: [
                    { type: "void", text: "unlinked-channelt-text" },
                    {
                        type: "itemsResume",
                        title: t("channels_"),
                        total: `${unlinkedChannels?.length} ${t("channels_")}`,
                        list: unlinkedChannels,
                    },
                ],
                buttons: [{ name: "close", style: "blue", action: "close" }],
            })
        );
        dispatch(openModal());
    }

    const handleCancel = () => {
        history("/services/tv-channels/channels");
    };

    const handleAdd = () => {
        if (checkedChannels?.length === 0) {
            toast.error(t("error-select-at-least-one"));
            return;
        }

        let unlinkedChannels = [];
        checkedChannels.map((checkedCh) =>
            channels.find(
                (ch) =>
                    checkedCh === ch.Name && !(ch.Tune || ch.PredefinedTvChannel) && unlinkedChannels.push(checkedCh)
            )
        );

        unlinkedChannels?.length > 0
            ? openUnlinkedModal(unlinkedChannels)
            : openSummaryModal(channels.filter((ch) => checkedChannels.find((checkedCh) => checkedCh === ch.Name)));
    };

    return (
        <div className="w-full h-full" onClick={(e) => setCloseAll(new Date().getMilliseconds())}>
            <UpdateBulkChannels />
            <div className="w-full bg-white h-auto flex py-6 px-10">
                <div className="float-left">{t("Add tune by parsing a file")}</div>
                <div
                    id="btn-screen_info_tooltip"
                    data-tip
                    data-for={`screen_info_tooltip`}
                    className="icon icon-info cursor-help float-left text-2xl ml-3"
                ></div>
            </div>
            <div className="m-10">
                <DvbChannelTable
                    channels={channels}
                    checkedChannels={checkedChannels}
                    setCheckedChannels={setCheckedChannels}
                    setChannels={setChannels}
                    closeAll={closeAll}
                    setCloseAll={setCloseAll}
                />

                <div className="inline-flex w-full justify-end mt-4">
                    <UseButton
                        action={(e) => handleCancel()}
                        buttonName={"cancel"}
                        buttonColor="btn-white"
                        adjust="float-left mr-6 "
                        id={`${sectionName}-cancel`}
                    />
                    <UseButton
                        action={(e) => handleAdd()}
                        buttonName={"add selected"}
                        buttonColor="btn-blue"
                        adjust="float-left "
                        id={`${sectionName}-save`}
                    />
                </div>
            </div>

            {/* TOOLTIPS*/}
            <ReactTooltip
                id={`screen_info_tooltip`}
                delayShow={250}
                type="light"
                border={true}
                borderColor="text-gray-200"
                arrowColor="text-gray-200"
                className="font-normal"
            >
                <div className="">
                    <div className="first-capital font-bold"> {t("file")}</div>
                    <div className="mb-4">{parsedFileName}</div>
                    <div className="first-capital font-bold"> {t("property")}</div>
                    <div className="mb-4">{propertyName}</div>
                </div>
            </ReactTooltip>
        </div>
    );
};
export default ChannelsImport;
