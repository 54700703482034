import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import moment from "moment";
//Components
import UseButton from "../useButton";
import UseInputSelect from "./useInputSelect";
import UseInputText from "./useInputText";
import UseInputNumber from "./useInputNumber";
import UseInputPhone from "./useInputPhone";
import UseInputMail from "./useInputMail";
//Actions
import { showRowForm, setHiddenRows } from "../../actions/sectionActions";
import { cleanAction } from "../../actions/globalActions";
import { setLoading, setRefreshData } from "../../actions/tableActions";
//Utils
import GetProperPageBooking from "../../hooks/Utils/GetProperPageBooking";
import { dateString, escapeSpecialChars } from "../../hooks/Utils/Utils";
import { validatePeopleInSlot } from "../../hooks/Utils/ScheduleManagement";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const UseCZFormBookingUpdate = () => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { rowFormContent, dateFilter, results } = useSelector((state) => state.table);
    const { actionData, itemsAffected } = useSelector((state) => state.action);

    //Data
    const { id } = useParams();
    const itemData = getItemData(results, itemsAffected);

    //Validation States
    const [validationHour, setValidationHour] = useState(true);
    const [validationPeople, setValidationPeople] = useState(true);
    const [validationName, setValidationName] = useState(true);
    const [validationPhone, setValidationPhone] = useState(true);
    const [validationEmail, setValidationEmail] = useState(true);

    let bookingDate = new window.ZDate(itemData?.bookingDate);
    if (actionData.hour) {
        const hourParts = actionData.hour.split(":");
        bookingDate.setHours(hourParts[0], hourParts[1]);
    }
    //API
    const MUTATION = gql`
  mutation {
    updateBooking(
        id: ${itemData?.id}
        commonzone: "${id}",
        name: "${escapeSpecialChars(actionData.name)}",
        bookingDate: "${dateString(bookingDate)}",
        members: ${bookingAmount(actionData) > 0 ? bookingAmount(actionData) : itemData?.info[1]?.value},
        phone: "${actionData.phone ? actionData.phone : ""}",
        email: "${actionData.mail}") { error id okBooking }}`;

    const [updateBooking, { data }] = useMutation(MUTATION);

    //get booking and area info
    GetProperPageBooking(actionData.hour, true, itemData?.bookingDate);

    //Actions
    const dispatch = useDispatch();

    const rowFormClickHandler = (e) => {
        const actionName = e.currentTarget.dataset.action;

        if (actionName !== "save") {
            dispatch(showRowForm(false));
            dispatch(cleanAction());
            dispatch(setHiddenRows(true));
        } else {
            //Validation
            let validatedSchedule = true;
            let validatedPeople = true;
            let validatedName = true;
            let validatedPhone = true;
            let validatedEmail = true;
            let peopleValidated = true;

            const validationProcess = () => {
                let res = false;
                //Validate hour
                validatedSchedule = !actionData.hour || actionData.hour === "" ? false : true;
                setValidationHour(validatedSchedule);

                //Validate people
                validatedPeople = getPeopleValidation(actionData);
                setValidationPeople(validatedPeople);

                //Validate Name
                validatedName = !actionData.name || actionData.name === "" ? false : true;
                setValidationName(validatedName);

                //Validate Phone
                validatedPhone = true;

                setValidationPhone(true);

                //Validate Email (already controlled by html input)
                validatedEmail = () => {
                    let res;
                    // eslint-disable-next-line
                    const re =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (!actionData.mail || actionData.mail === "") {
                        res = false;
                    } else if (re.test(actionData.mail)) {
                        res = true;
                    }
                    return res;
                };
                setValidationEmail(validatedEmail());

                peopleValidated = validatePeople();

                if (
                    validatedSchedule &&
                    validatedPeople &&
                    validatedName &&
                    validatedPhone &&
                    validatedEmail() &&
                    peopleValidated
                ) {
                    res = true;
                }
                return res;
            };

            if (validationProcess()) {
                dispatch(setLoading(true));
                updateBooking();
            } else {
                dispatch(setLoading(false));
                toast.error(langStrings["errors-in-red"]);
            }
        }
    };

    const validatePeople = () => {
        if (
            actionData.hour &&
            getPeopleValidation(actionData) &&
            actionData.bookingData &&
            !validatePeopleInSlot(
                actionData.people,
                new window.ZDate(dateFilter),
                moment(actionData.hour, "hh:mm:ss"),
                actionData.bookingData.bookings,
                30,
                2,
                actionData.bookingData.areaInfo.capacityLimit
            )
        ) {
            toast.error(langStrings["hour-not-available-to-this-people"]);
            setValidationPeople(false);
            return false;
        }
        return true;
    };

    //Listeners
    useEffect(() => {
        if (actionData && actionData.people && actionData.people !== "" && actionData.people !== "0") {
            setValidationPeople(true);
        }

        if (actionData && actionData.name && actionData.name !== "") {
            setValidationName(true);
        }
        if (actionData && actionData.phone && actionData.phone.length >= 6) {
            setValidationPhone(true);
        }
        if (actionData && actionData.mail) {
            setValidationEmail(true);
        }

        validatePeople();
        // eslint-disable-next-line
    }, [actionData]);

    useEffect(() => {
        if (data && data.updateBooking && data.updateBooking.error === "") {
            toast.success(langStrings["operation-successful"]);

            dispatch(showRowForm(false));
            dispatch(cleanAction());
            dispatch(setHiddenRows(true));

            dispatch(setRefreshData(true));
        } else if (data && data.updateBooking && data.updateBooking.error !== "") {
            toast.error(langStrings["error-mutation"]);
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        return () => {
            dispatch(showRowForm(false));
            dispatch(cleanAction());
            dispatch(setHiddenRows(true));
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {rowFormContent ? (
                <div className="w-full bg-white border-b border-gray-200 h-16 flex items-center">
                    <div className="t-chevron-space"></div>
                    {rowFormContent.inputs.map((input, index) =>
                        input.name === "hour" ? (
                            <div className="inline-block w-1/12 pr-4" key={index}>
                                <div style={{ marginLeft: "-2rem" }}>
                                    <UseInputSelect
                                        inputData={actionData.bookingData ? actionData.bookingData.availableHours : []}
                                        name="hour"
                                        validation={validationHour}
                                        currentValue={itemData?.info[0]?.value}
                                    />
                                </div>
                            </div>
                        ) : input.name === "people" ? (
                            <div className="inline-block w-1/12 pr-4" key={index}>
                                <div style={{ width: "60px" }}>
                                    <UseInputNumber
                                        inputData={input}
                                        validation={validationPeople}
                                        currentValue={itemData?.info[1]?.value}
                                    />
                                </div>
                            </div>
                        ) : input.name === "name" ? (
                            <div className="inline-block w-2/12 pr-4" key={index}>
                                <div style={{ width: "144px" }}>
                                    <UseInputText
                                        inputData={input}
                                        validation={validationName}
                                        currentValue={itemData?.info[2]?.value}
                                    />
                                </div>
                            </div>
                        ) : input.name === "phone" ? (
                            <div className="inline-block w-2/12 pr-4" key={index}>
                                <div style={{ width: "144px" }}>
                                    <UseInputPhone
                                        inputData={input}
                                        validation={validationPhone}
                                        currentValue={itemData?.info[3]?.value}
                                    />
                                </div>
                            </div>
                        ) : input.name === "mail" ? (
                            <div className="inline-block w-2/12 pr-4" key={index}>
                                <div style={{ width: "144px" }}>
                                    <UseInputMail
                                        inputData={input}
                                        validation={validationEmail}
                                        currentValue={itemData?.info[4]?.value}
                                    />
                                </div>
                            </div>
                        ) : null
                    )}
                    <div className="text-right w-4/12">
                        {rowFormContent.buttons.map((button, index) => (
                            <div
                                className={`inline-block w-6/12 ${index === 0 ? "pr-2" : ""}`}
                                data-action={button.name}
                                key={index}
                                onClick={rowFormClickHandler}
                            >
                                <UseButton buttonName={button.name} buttonColor={button.color} />
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export const formattedDate = (dateFilter) => {
    //Returns formatted filter dates
    if (dateFilter && dateFilter !== "") {
        dateFilter = new window.ZDate(dateFilter);
        const year = dateFilter.getFullYear().toString();
        const tempMonth = (dateFilter.getMonth() + 1).toString();
        const tempDay = dateFilter.getDate().toString();
        let day, month;

        if (tempMonth.length === 1) {
            month = `0${tempMonth}`;
        } else {
            month = tempMonth;
        }
        if (tempDay.length === 1) {
            day = `0${tempDay}`;
        } else {
            day = tempDay;
        }
        return `"${year}-${month}-${day}"`;
    } else {
        return ``;
    }
};

export const getItemData = (results, itemsAffected) => {
    //Returns booking data
    let res;
    // eslint-disable-next-line
    results.map((result) => {
        if (result.id === Number(itemsAffected[0])) {
            res = result;
        }
    });
    return res;
};

export const bookingAmount = (actionData) => {
    //Returns 0 or number to default mutation (to avoid error on render)
    let res;
    if (
        actionData.people === "" ||
        Number.isNaN(actionData.people) ||
        (actionData.people && String(actionData.people).includes("-"))
    ) {
        res = 0;
    } else {
        res = Number(actionData.people);
    }
    return res;
};

export const getPeopleValidation = (actionData) => {
    //Returns true if people amount passes validation
    let res;
    if (
        !actionData.people ||
        actionData.people === "" ||
        actionData.people === "0" ||
        (actionData.people && String(actionData.people).includes("-")) ||
        Number.isNaN(actionData.people)
    ) {
        res = false;
    } else {
        res = true;
    }
    return res;
};

export default UseCZFormBookingUpdate;
