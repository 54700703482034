import React from "react";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";

const AccessTypesCB = ({ data, setState, disabled }) => {
    const { dataField, keyField, name, singleValue, id } = data;

    const { t } = useTranslation();

    let active;

    if (singleValue) {
        if (dataField[keyField]) {
            active = true;
        } else {
            active = false;
        }
    } else {
        if (dataField[keyField] && dataField[keyField]["enabled"]) {
            active = true;
        } else {
            active = false;
        }
    }

    let content;

    if (disabled && active) {
        content = (
            <div className="cursor-not-allowed rounded-sm w-5 h-5  absolute top-0 bg-gray-300 ">
                <span
                    style={{
                        display: "inline-block",
                        width: "1.25rem",
                        height: "1.25rem",
                        position: "absolute",
                        top: "-3px",
                        left: "-2px",
                        transform: "rotate(40deg)",
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            width: "3px",
                            height: "13px",
                            backgroundColor: "white",
                            left: "11px",
                            top: "2px",
                        }}
                    ></div>
                    <div
                        style={{
                            position: "absolute",
                            width: "4px",
                            height: "3px",
                            backgroundColor: "white",
                            left: "8px",
                            top: "12px",
                        }}
                    ></div>
                </span>
            </div>
        );
    } else if (disabled) {
        content = <div className="cursor-not-allowed rounded-sm w-5 h-5  absolute top-0 bg-gray-200 "></div>;
    } else {
        content = null;
    }

    return (
        <div className=" w-auto h-10">
            <div className=" relative mb-4 flex cursor-pointer">
                {active === true ? (
                    <input
                        id={id}
                        className="absolute top-0 left-0 w-5 h-5 checkbox "
                        type={"checkbox"}
                        onClick={() =>
                            setState(
                                singleValue
                                    ? { ...dataField, [keyField]: false }
                                    : { ...dataField, [keyField]: { ...dataField[keyField], enabled: false } }
                            )
                        }
                        checked={active}
                    ></input>
                ) : null}
                <span
                    onClick={() => {
                        if (disabled) {
                            return;
                        }
                        setState(
                            singleValue
                                ? { ...dataField, [keyField]: !dataField[keyField] }
                                : {
                                      ...dataField,
                                      [keyField]: {
                                          ...dataField[keyField],
                                          enabled: !dataField[keyField]["enabled"],
                                      },
                                  }
                        );
                    }}
                    className={`ml-8 ${disabled ? " text-gray-500" : "hover:text-zafiro-400 text-gray-900"}`}
                    style={{ marginTop: "-0.12rem" }}
                >
                    {capitalizeFirst(t(name))}
                </span>
                {active === false ? (
                    <span
                        id={id}
                        className={`absolute top-0 left-0 w-5 h-5 hover:bg-gray-300 bg-gray-300 `}
                        onClick={() => {
                            setState(
                                singleValue
                                    ? { ...dataField, [keyField]: true }
                                    : { ...dataField, [keyField]: { ...dataField[keyField], enabled: true } }
                            );
                        }}
                    ></span>
                ) : null}
                {content}
            </div>
        </div>
    );
};

export default AccessTypesCB;
