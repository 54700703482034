import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";

const IsLoyaltyEmail = () => {
    const { email } = useSelector((state) => state.action.values);
    const { email: emailValidation } = useSelector((state) => state.action.validations);
    const [isLoyal, setisLoyal] = useState(false);

    //API
    const ISLOYALTY = gql`{isLoyalty: isLoyalty(email: "${email}")}`;

    const [executeQuery, { data, error }] = useLazyQuery(ISLOYALTY);

    //Listeners
    useEffect(() => {
        setisLoyal(false);
        if (email && email !== "" && emailValidation) executeQuery();
        // eslint-disable-next-line
    }, [email, emailValidation]);

    useEffect(() => {
        if (data && data.isLoyalty && !error) setisLoyal(data.isLoyalty);
    }, [data, error]);

    return isLoyal;
};

export default IsLoyaltyEmail;
