import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { setActionInput } from "../../actions/globalActions";
import { parseBoolean } from "../../hooks/Utils/Utils";

const UseCapacitySetter = ({ validationPeopleNumber, validationPeoplePercentaje }) => {
    //Store data
    const { actionData } = useSelector((state) => state.action);
    const { langStrings } = useSelector((state) => state.ui);

    //States
    const [checkPercentage, setCheckPercentage] = useState(parseBoolean(actionData.isPercentage));
    const [amount, setAmount] = useState();
    const [percentage, setPercentage] = useState();

    //Actions
    const dispatch = useDispatch();

    const radioClickHandler = (e) => {
        let idLabel = "int_capacity";
        if (parseBoolean(e.currentTarget.dataset.ispercentage)) {
            idLabel = "percen_capacity";
            setCheckPercentage(true);
            dispatch(setActionInput({ isPercentage: "true" }));
        } else {
            setCheckPercentage(false);
            dispatch(setActionInput({ isPercentage: "false" }));
        }
        dispatch(setActionInput({ currentCapacity: document.querySelector(`#${idLabel}`).value }));
    };

    const changeHandler = (e) => {
        dispatch(setActionInput({ currentCapacity: e.target.value }));
        if (e.target.name === "capacity-amount") {
            setAmount(e.target.value);
        } else {
            setPercentage(e.target.value);
        }
    };

    //Listeners
    useEffect(() => {
        if (parseBoolean(actionData.isPercentage)) {
            setPercentage(actionData.currentCapacity);
        } else {
            setAmount(actionData.currentCapacity);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCheckPercentage(parseBoolean(actionData.isPercentage));
    }, [actionData]);

    useEffect(() => {
        if (amount && !parseBoolean(actionData.isPercentage)) {
            const calcPercentage = Math.ceil((amount / actionData.capacityLimit) * 100);
            setPercentage(calcPercentage);
        }
        // eslint-disable-next-line
    }, [amount]);

    useEffect(() => {
        if (percentage && parseBoolean(actionData.isPercentage)) {
            const calcAmount = Math.ceil((percentage / 100) * actionData.capacityLimit);
            setAmount(calcAmount);
        }
        // eslint-disable-next-line
    }, [percentage]);

    return (
        <>
            <div className="flex items-center mt-4 relative">
                <input
                    id="radio-amount-current-capacity"
                    type="radio"
                    name="capacity-current"
                    className="inline-block mr-4"
                    onChange={radioClickHandler}
                    data-ispercentage="false"
                    checked={!checkPercentage}
                ></input>
                <div className="w-full relative">
                    <div className="absolute right-0 mr-6 mt-2 text-gray-700 w-20 text-left">
                        {langStrings["people"]}
                    </div>
                    <input
                        id="int_capacity"
                        type="number"
                        value={amount}
                        onChange={changeHandler}
                        min="0"
                        name="capacity-amount"
                        className={`inline-block rounded w-full p-2 ${
                            !checkPercentage
                                ? `${!validationPeopleNumber ? "borderError" : "border-blue-200"}  bg-white border`
                                : "bg-gray-200"
                        }`}
                        onClick={radioClickHandler}
                        data-ispercentage="false"
                        max={actionData.capacityLimit}
                    ></input>
                </div>
            </div>
            <div className="flex items-center mt-2 mb-4 relative">
                <input
                    id="radio-current-capacity"
                    type="radio"
                    name="capacity-current"
                    className="inline-block mr-4"
                    onChange={radioClickHandler}
                    data-ispercentage="true"
                    checked={checkPercentage}
                ></input>
                <div className="w-full relative">
                    <div className="absolute right-0 mr-6 mt-2 text-gray-700 w-20 text-left">
                        {`${langStrings["capacity-perc"]}`}
                    </div>
                    <input
                        id="percen_capacity"
                        type="number"
                        value={percentage}
                        onChange={changeHandler}
                        min="0"
                        max="100"
                        name="capacity-percentage"
                        className={`inline-block rounded w-full p-2 ${
                            checkPercentage
                                ? `${!validationPeoplePercentaje ? "borderError" : "border-blue-200"}  bg-white border`
                                : "bg-gray-200"
                        }`}
                        onClick={radioClickHandler}
                        data-ispercentage="true"
                    ></input>
                </div>
            </div>
        </>
    );
};

export default UseCapacitySetter;
