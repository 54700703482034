import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "@apollo/react-hoc";
//Actions
import { showGlobalLoading, closeModal, setModalContent } from "../../../actions/uiActions";

import { setRefreshContentData, setExecuteQuery } from "../../../actions/sectionActions";
import { cleanAction, setActionValidations } from "../../../actions/globalActions";
import { setLoading, setTargetId } from "../../../actions/tableActions";
import { removeApolloCacheKeys } from "../../../hooks/Utils/Utils";

//Utils
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { validateSection } from "../../Utils/Validations";

const UpdateTag = ({ client }) => {
    const { id } = useParams();
    const { t } = useTranslation();

    //Store data
    const { executeQuery, tagData } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);

    const typeURL = tagData && tagData.target === "URL";

    //State
    const [mutation, setMutation] = useState("mutation{checkIn}");

    //Actions
    const dispatch = useDispatch();

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        errorPolicy: "all",
        onError(err) {
            //this control is in this onError, because errorPolicy =all is not working like in queryes
            //Custom errors
            let validations = {};
            let errorMsg = err.message;

            if (executeQuery.mutationName === "createDesignTag" && errorMsg.includes("409")) {
                errorMsg = t("tag id duplicated");
                validations["tag-id"] = false;
                dispatch(setActionValidations(validations));
            }

            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(errorMsg);
        },
    });

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            executeQuery.mutationName = "";
            execute = true;
            switch (executeQuery.action) {
                case "delete-tag":
                    setMutation(`
                        mutation{
                            deleteDesignTag(
                                id: "${executeQuery.params.value.id}"
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "deleteDesignTag";
                    removeApolloCacheKeys(client.cache, "Tag");
                    dispatch(showGlobalLoading(true));
                    break;

                case "update-tag-value":
                    if (typeURL) {
                        const validations = validateSection("tag-single-value", actionData.values, t);
                        dispatch(setActionValidations(validations));
                        const validationsArray = Object.values(validations);

                        if (validationsArray.filter((item) => item === false).length > 0) {
                            toast.error(t("type a valid URL"));
                            return;
                        }
                    }

                    setMutation(`
                        mutation{
                            updateDesignTag(
                                id: "${executeQuery.params.id}"
                                value: "${actionData.values["single-value-text"] || ""}"
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "updateDesignTag";
                    executeQuery.preventToast = true;
                    executeQuery.hideLoading = true;
                    removeApolloCacheKeys(client.cache, "Tag");
                    break;

                case "update-tag-check":
                    setMutation(`
                        mutation{
                            updateDesignTag(
                                id: "${executeQuery.params.id}"
                                assignedAll: ${executeQuery.params.value}
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "updateDesignTag";
                    executeQuery.preventToast = true;
                    removeApolloCacheKeys(client.cache, "Tag");
                    dispatch(showGlobalLoading(true));
                    break;

                case "edit-tag-values":
                    if (!actionData.values["property-value"]) {
                        if (!executeQuery.params.cValue) {
                            dispatch(setExecuteQuery(null));
                            return;
                        }
                        setMutation(`
                            mutation{
                                deleteDesignTagCustom(
                                    projectId: "${executeQuery.params.projectId}"
                                    designTagId: "${id}"
                                ){ error ok }}
                        `);
                        executeQuery.mutationName = "deleteDesignTagCustom";
                    } else {
                        setMutation(`
                            mutation{
                                createDesignTagCustom(
                                    projectId: "${executeQuery.params.projectId}"
                                    designTagId: "${id}"
                                    value: "${actionData.values["property-value"] || ""}"
                                ){ error { code msg } ok }}
                        `);
                        executeQuery.mutationName = "createDesignTagCustom";
                    }

                    executeQuery.preventToast = true;
                    removeApolloCacheKeys(client.cache, "Tag");
                    dispatch(showGlobalLoading(true));
                    break;

                case "new-tag":
                    setMutation(`
                        mutation{
                            createDesignTag(
                                ${
                                    actionData.values["tag-description"]
                                        ? `description: "${actionData.values["tag-description"]}"`
                                        : ""
                                }
                            tag: "${actionData.values["tag-id"]}"
                            target: ${actionData.values["tag-type"][0]}
                            name:  [{lang: "es" , text: "${
                                actionData.values["es"] || actionData.values["en"]
                            }"}, {lang: "en" , text: "${actionData.values["en"]}"}]
                            type: CORPORATE
                            ){ error { code msg } id ok }}
                    `);
                    executeQuery.mutationName = "createDesignTag";
                    removeApolloCacheKeys(client.cache, "Tag");
                    dispatch(showGlobalLoading(true));
                    break;

                case "edit-tag":
                    const { nameEn, nameEs, description } = executeQuery.params.values;

                    setMutation(`
                        mutation{
                            updateDesignTag(
                                id:"${executeQuery.params.id}"
                                description: "${description}"
                                name:  [{lang: "es" , text: "${
                                    nameEs.trim() || nameEn
                                }"}, {lang: "en" , text: "${nameEn}"}]
                            ){ error id ok }}
                    `);
                    executeQuery.mutationName = "updateDesignTag";
                    removeApolloCacheKeys(client.cache, "Tag");
                    dispatch(showGlobalLoading(true));
                    break;

                default:
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (!showGlobalLoading) dispatch(setLoading(true));
            if (!executeQuery.hideLoading) {
                dispatch(showGlobalLoading(true));
            }

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].isOk || data[executeQuery.action].ok)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].isOk || data[executeQuery.mutationName].ok)))
        ) {
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }
            if (!executeQuery.params || (executeQuery.params && !executeQuery.params.noCleanAction)) {
                dispatch(cleanAction());
            }

            if (executeQuery.mutationName === "createDesignTag") {
                dispatch(setTargetId(data.createDesignTag.id));
            }

            if (!executeQuery.preventToast) {
                toast.success(t("operation-successful"));
            }
            dispatch(setExecuteQuery(null));
            dispatch(setRefreshContentData(true));
        } else if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].errorMsg || data[executeQuery.action].error)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].errorMsg || data[executeQuery.mutationName].error)))
        ) {
            const errorMsg =
                data[executeQuery.action] && data[executeQuery.action].error
                    ? data[executeQuery.action].error.msg
                        ? data[executeQuery.action].error.msg
                        : data[executeQuery.action].error
                    : data[executeQuery.mutationName] && data[executeQuery.mutationName].error
                    ? data[executeQuery.mutationName].error
                    : null;

            toast.error(errorMsg ? errorMsg : t("mutation-error"));
            dispatch(showGlobalLoading(false));
            dispatch(setExecuteQuery(null));
            dispatch(setRefreshContentData(true));
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateTag);
