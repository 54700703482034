import { useState, useEffect, useRef } from "react";

export default function useComponentVisible(initialIsVisible = false) {
    const [showing, setShowing] = useState(initialIsVisible);
    const ref = useRef(null);

    const toggle = () => {
        setShowing(!showing);
    };

    const show = () => {
        if (!showing) {
            toggle();
        }
    };

    const hide = () => {
        if (showing) {
            toggle();
        }
    };

    const handleKeyboard = (event) => {
        if (event?.key === "Escape") {
            hide();
        }
    };

    const handleClicks = (event) => {
        if (ref?.current && !ref.current.contains(event?.target)) {
            hide();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKeyboard, true);
        document.addEventListener("click", handleClicks, true);
        return () => {
            document.removeEventListener("keydown", handleKeyboard, true);
            document.removeEventListener("click", handleClicks, true);
        };
    }, [handleKeyboard, handleClicks]);

    return {
        ref,
        showing,
        toggle,
        show,
        hide,
        isComponentVisible: showing, // deprecated, use showing instead
        setIsComponentVisible: setShowing, // deprecated, use hide/show/toggle instead
    };
}
