import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseCell from "./useCell";
import UseRowActions from "./useRowActions";
//Actions
import { setHiddenRows } from "../../actions/sectionActions";
import { addCheckedItem, removeCheckedItem } from "../../actions/tableActions";

const UseCard = ({ index }) => {
    //Store data
    const { checkable, actions, columns, allChecked, results, checkedItems } = useSelector((state) => state.table);
    const dataItem = useSelector((state) => state.table.results[index]);
    const { showHiddenRows } = useSelector((state) => state.sectionContent);
    //States
    const [moreInfo, setMoreInfo] = useState(false);
    const [iconChange, setIconChange] = useState("");
    const [active, setActive] = useState(checkedItems.includes(dataItem.id));
    const [hideRow, setHideRow] = useState(false);

    //State styles
    const expandHandler = (e) => {
        setMoreInfo(moreInfo ? false : true);
        setIconChange(moreInfo ? "" : "mr-4 -rotate-180");
    };

    //Actions
    const dispatch = useDispatch();
    const clickHandler = (e) => {
        e.stopPropagation();
        if (active) {
            dispatch(removeCheckedItem(dataItem.id));
        } else {
            dispatch(addCheckedItem(dataItem.id));
        }
        setActive(active ? false : true);
    };

    useEffect(() => {
        if (allChecked) {
            dispatch(addCheckedItem(dataItem.id));
        } else {
            dispatch(removeCheckedItem(dataItem.id));
        }
        setActive(allChecked ? true : false);
        // eslint-disable-next-line
    }, [allChecked]);

    useEffect(() => {
        if (!checkedItems || checkedItems.length === 0) {
            setActive(false);
        }
    }, [checkedItems]);

    useEffect(() => {
        setMoreInfo(false);
    }, [dataItem]);

    useEffect(() => {
        setMoreInfo(false);
        setIconChange("");
    }, [results]);

    //Listeners
    useEffect(() => {
        if (showHiddenRows) {
            setHideRow(false);
            dispatch(setHiddenRows(false));
        }
        // eslint-disable-next-line
    }, [showHiddenRows]);

    function showOptions(e) {
        e.stopPropagation();
        document
            .querySelector(`[data-container-options='card-item-container-options-${e.currentTarget.dataset.infoid}']`)
            .classList.remove("hidden");
    }

    function hideOptions(e) {
        e.stopPropagation();
        if (checkedItems && checkedItems.includes(e.currentTarget.dataset.infoid)) {
            document
                .querySelector(
                    `[data-container-options='card-item-container-options-${e.currentTarget.dataset.infoid}']`
                )
                .classList.remove("hidden");
        } else {
            document
                .querySelector(
                    `[data-container-options='card-item-container-options-${e.currentTarget.dataset.infoid}']`
                )
                .classList.add("hidden");
        }
    }
    return (
        <>
            <div
                data-infoid={dataItem.id}
                className={`${hideRow ? "hidden" : ""} ${dataItem.specialBackground ? "bg-gray-300" : ""} ${
                    dataItem.extendedInfo ? " cursor-pointer" : ""
                }  ${dataItem.adjust ? dataItem.adjust : ""}`}
                onMouseOver={showOptions}
                onMouseLeave={hideOptions}
            >
                {/* chevron */}
                {dataItem.extendedInfo ? (
                    <span className={`icon-chevron animated mr-4 ${iconChange} ml-6`} onClick={expandHandler}></span>
                ) : (
                    <span className="mr-0"></span>
                )}
                <div data-container-options={`card-item-container-options-${dataItem.id}`} className="hidden">
                    {/* checkbox */}
                    {checkable ? (
                        <div className="absolute left-0 top-0 ml-1 mt-1 z-10">
                            <input
                                data-id={dataItem.id}
                                type="checkbox"
                                onClick={(e) => clickHandler(e)}
                                className={`t-checkbox-checked opacity-${active ? "100" : "50"}`}
                                checked={active}
                                onChange={(e) => {}}
                            ></input>
                            <span className="t-checkbox-unchecked"></span>
                        </div>
                    ) : null}
                    {/* Row actions */}
                    {actions ? (
                        <div className="absolute right-0 top-0 ml-1 mt-1 mr-3 z-10 bg-gray-100">
                            <UseRowActions
                                actions={dataItem.actions}
                                dataItem={dataItem}
                                id={dataItem.id}
                                setHideRow={setHideRow}
                                resultsIndex={index}
                                adjust="mr-0"
                            />
                        </div>
                    ) : null}
                </div>
                {/*cells*/}
                {dataItem.info.map((property, cellIndex) => (
                    <UseCell
                        key={cellIndex}
                        rowIndex={index}
                        columns={columns}
                        expandHandler={expandHandler}
                        cellData={property}
                        setHideRow={setHideRow}
                    />
                ))}
            </div>
            {/* Expandable info */}
            {dataItem.extendedInfo && moreInfo ? (
                <div className="t-extended-container items-baseline">
                    <div className="t-chevron-space"></div>
                    {checkable ? <div className="t-checkbox-margin"></div> : null}
                    {dataItem.extendedInfo.map((property, cellIndex) => (
                        <UseCell key={cellIndex} columns={columns} cellData={property} />
                    ))}
                    {actions ? <div className="t-actions-space" /> : null}
                </div>
            ) : null}
        </>
    );
};

export default UseCard;
