import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import { useAuth } from "hooks/Session/auth";
import { useAddSignage } from "../graphql/useSignages";
import { toast } from "react-toastify";
import Button from "components/Button";
import TextInput from "components/TextInput";
import CustomTimePicker from "components/CustomTimePicker";
import Loading from "components/Loading";
import Icon from "components/Icon";

const ModalAddSequence = (props) => {
    const { t } = useTranslation();
    const [name, setName] = useState(props.name);
    const [loading, setLoading] = useState(false);
    const { addSignage } = useAddSignage({
        onCompleted: () => {
            setLoading(false);
            props.getSignages();
            props.close();
        },
    });
    const { defaultLanguage } = useAuth();
    const [fileDuration, setFileDuration] = useState("00:00:10");
    const [validations, setValidations] = useState({ name: true, time: true });

    function validateTime(error, fileDuration) {
        const times = fileDuration.split(":").map((val) => parseInt(val));
        if (!times.some((el) => el > 0)) {
            setValidations((val) => ({ ...val, time: false }));
            error = true;
        } else {
            setValidations((val) => ({ ...val, time: true }));
        }
        return error;
    }

    function validateName(error, name) {
        if (name) {
            setValidations((val) => ({ ...val, name: true }));
        } else {
            setValidations((val) => ({ ...val, name: false }));
            error = true;
        }
        return error;
    }

    const handleSaveClick = () => {
        let error = false;
        error = validateTime(error, fileDuration);
        error = validateName(error, name);

        if (!error) {
            addSignage({ variables: { name: name, language: defaultLanguage, duration: fileDuration } });
            setLoading(true);
        } else {
            toast.error(t("errors-in-red"));
        }
    };

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={t("add-sequence")}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-add" disabled={loading} onClick={handleSaveClick}>
                        {t("add")}
                    </Button>
                </>
            }
            className={"w-5/12 p-10"}
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <div className="flex flex-col gap-2">
                    <p className=" font-bold">{t("name")}*</p>
                    <p>{t("write-the-name-of-the-sequence")}:</p>
                    <TextInput
                        id="sequence-input-name"
                        error={!validations.name}
                        value={name}
                        onChange={(val) => {
                            setName(val);
                            validateName(null, val);
                        }}
                    ></TextInput>
                    <p className="font-bold">{t("files-duration")}*</p>
                    <p>{t("select-the-default-duration-for-each-file")}:</p>
                    <CustomTimePicker
                        id="file-duration-picker"
                        value={fileDuration}
                        error={!validations.time}
                        units={["hours", "minutes", "seconds"]}
                        disabled={false}
                        onChange={(time) => {
                            setFileDuration(time);
                            validateTime(null, time);
                        }}
                    ></CustomTimePicker>
                    {props.firstSequence && (
                        <div className=" flex gap-2 mt-2">
                            <Icon type={"info"} size={"2xl"}></Icon>
                            <p>{t("first-sequence-will-be-default")}</p>
                        </div>
                    )}
                </div>
            )}
        </Modal>
    );
};

export const UseModalAddSequence = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalAddSequence {...newProps} />);
        },
    };
};
