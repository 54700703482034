export const ADD_BATCH_PUNCTUAL = "ADD_BATCH_PUNCTUAL";
export const ADD_BATCH_PERIODIC = "ADD_BATCH_PERIODIC";
export const SET_BATCH_PERIODIC = "SET_BATCH_PERIODIC";
export const ADD_VIEW_PUNCTUAL = "ADD_VIEW_PUNCTUAL";
export const ADD_VIEW_PERIODIC = "ADD_VIEW_PERIODIC";
export const SET_VIEW_PERIODIC = "SET_VIEW_PERIODIC";
export const CLEAN_VIEW_SCHEDULES = "CLEAN_VIEW_SCHEDULES";
export const CLEAN_BATCH_SCHEDULES = "CLEAN_BATCH_SCHEDULES";
export const SET_SCHEDULE_ROWS = "SET_SCHEDULE_ROWS";
export const ADD_SCHEDULE_ROW = "ADD_SCHEDULE_ROW";
export const SET_SSCHEDULE_VALUE = "SET_SSCHEDULE_VALUE";
export const ADD_SSCHEDULE_WEEKDAY = "ADD_SSCHEDULE_WEEKDAY";
export const REMOVE_SSCHEDULE_WEEKDAY = "REMOVE_SSCHEDULE_WEEKDAY";
export const RESET_SSCHEDULE_ROWS = "RESET_SSCHEDULE_ROWS";
export const SET_DATE_ROWS = "SET_DATE_ROWS";
export const ADD_DATE_ROW = "ADD_DATE_ROW";
export const SET_SDATE_VALUE = "SET_SDATE_VALUE";
export const ADD_SDATE_DAYS = "ADD_SDATE_DAYS";
export const REMOVE_SDATE_DAYS = "REMOVE_SDATE_DAYS";
export const RESET_SDATE_ROWS = "RESET_SDATE_ROWS";
export const SET_SCHEDULE_2UPDATE = "SET_SCHEDULE_2UPDATE";
export const SET_SSCHEDULE_STATUS = "SET_SSCHEDULE_STATUS";
export const SET_MUTATION_REFRESH = "SET_MUTATION_REFRESH";
export const DELETE_SSCHEDULE_EDITOR = "DELETE_SSCHEDULE_EDITOR";
export const UPDATE_PERIODIC_SCHEDULE = "UPDATE_PERIODIC_SCHEDULE";
export const CANCEL_SCHEDULE_UPDATE = "CANCEL_SCHEDULE_UPDATE";
export const PSCHEDULE_BACKUP = "PSCHEDULE_BACKUP";
export const PERIODIC_SCHEDULE_BACKUP = "PERIODIC_SCHEDULE_BACKUP";
export const PSCHEDULE_ADD_ROW = "PSCHEDULE_ADD_ROW";
export const SSCHEDULE_ADD_ROW = "SSCHEDULE_ADD_ROW";
export const SET_CURRENT_ZONE_SCHEDULE = "SET_CURRENT_ZONE_SCHEDULE";
export const SET_LAST_SCHEDULE_EDITION = "SET_LAST_SCHEDULE_EDITION";

export function addBatchPunctualSchedule(scheduleBatchData) {
    //Add punctual schedule timetable to temp batch schedules store
    return (dispatch) => {
        dispatch({
            type: ADD_BATCH_PUNCTUAL,
            payload: scheduleBatchData,
        });
    };
}

export function addBatchPeriodicSchedule(scheduleBatchData) {
    //Add periodic schedule timetable to temp batch schedules store
    return (dispatch) => {
        dispatch({
            type: ADD_BATCH_PERIODIC,
            payload: scheduleBatchData,
        });
    };
}

export function setBatchPeriodicSchedule(scheduleBatchData) {
    //Add periodic schedule timetable to temp batch schedules store
    return (dispatch) => {
        dispatch({
            type: SET_BATCH_PERIODIC,
            payload: scheduleBatchData,
        });
    };
}

export function addViewPunctualSchedule(scheduleDisplayData) {
    //Add punctual schedule timetable to temp view schedules store
    return (dispatch) => {
        dispatch({
            type: ADD_VIEW_PUNCTUAL,
            payload: scheduleDisplayData,
        });
    };
}

export function addViewPeriodicSchedule(scheduleDisplayData) {
    //Add periodic schedule timetable to temp view schedules store
    return (dispatch) => {
        dispatch({
            type: ADD_VIEW_PERIODIC,
            payload: scheduleDisplayData,
        });
    };
}

export function setViewPeriodicSchedule(scheduleDisplayData) {
    //Add periodic schedule timetable to temp view schedules store
    return (dispatch) => {
        dispatch({
            type: SET_VIEW_PERIODIC,
            payload: scheduleDisplayData,
        });
    };
}

export function cleanBatchSchedules() {
    //Cleans temp batch schedules store
    return (dispatch) => {
        dispatch({
            type: CLEAN_BATCH_SCHEDULES,
        });
    };
}

export function cleanViewSchedules() {
    //Cleans temp view schedules store
    return (dispatch) => {
        dispatch({
            type: CLEAN_VIEW_SCHEDULES,
        });
    };
}

export function setScheduleSpecialRows(amount) {
    //sets amount of optional form rows must be shown for date-ranges in special schedules
    return (dispatch) => {
        dispatch({
            type: SET_SCHEDULE_ROWS,
            payload: amount,
        });
    };
}

export function setDatesSpecialRows(amount) {
    //sets amount of optional form rows must be shown for specific days in special schedules
    return (dispatch) => {
        dispatch({
            type: SET_DATE_ROWS,
            payload: amount,
        });
    };
}

export function addContainerScheduleRow(defaultData) {
    //adds new row with default data
    return (dispatch) => {
        dispatch({
            type: ADD_SCHEDULE_ROW,
            payload: defaultData,
        });
    };
}

export function setSpecialScheduleValue(data) {
    //sets row specific value by his key
    return (dispatch) => {
        dispatch({
            type: SET_SSCHEDULE_VALUE,
            payload: data,
        });
    };
}

export function addSpecialWeekDay(data) {
    //sets weekday in specific row
    return (dispatch) => {
        dispatch({
            type: ADD_SSCHEDULE_WEEKDAY,
            payload: data,
        });
    };
}

export function removeSpecialWeekDay(data) {
    //remove weekday in specific row
    return (dispatch) => {
        dispatch({
            type: REMOVE_SSCHEDULE_WEEKDAY,
            payload: data,
        });
    };
}

export function resetScheduleSpecialRows() {
    //Cleans optional form rows info from store
    return (dispatch) => {
        dispatch({
            type: RESET_SSCHEDULE_ROWS,
        });
    };
}

export function addContainerDateRow(defaultData) {
    //Adds row to form in which user can add new rows to add new dates
    return (dispatch) => {
        dispatch({
            type: ADD_DATE_ROW,
            payload: defaultData,
        });
    };
}

export function setSpecialDateValue(data) {
    //Sets date range for special schedule
    return (dispatch) => {
        dispatch({
            type: SET_SDATE_VALUE,
            payload: data,
        });
    };
}

export function addSpecialSpecificDays(data) {
    //Sets specific days for special schedule
    return (dispatch) => {
        dispatch({
            type: ADD_SDATE_DAYS,
            payload: data,
        });
    };
}

export function resetDateSpecialRows() {
    //Cleans dates for special schedule
    return (dispatch) => {
        dispatch({
            type: RESET_SDATE_ROWS,
        });
    };
}

export function setScheduleToUpdate(event) {
    //Loads selected date to update in store
    delete event["__typename"];
    return (dispatch) => {
        dispatch({
            type: SET_SCHEDULE_2UPDATE,
            payload: event,
        });
    };
}

export function updateSScheduleStatus(config) {
    //Sets state (Active or Disabled)
    return (dispatch) => {
        dispatch({
            type: SET_SSCHEDULE_STATUS,
            payload: config,
        });
    };
}

export function setMutationRefresh(config) {
    //Sets boolean to make graphql hook refetch
    return (dispatch) => {
        dispatch({
            type: SET_MUTATION_REFRESH,
            payload: config,
        });
    };
}

export function deleteEditorSSchedule(eventData) {
    //Removes from store a schedule during 3 steps periodic schedule wizard
    return (dispatch) => {
        dispatch({
            type: DELETE_SSCHEDULE_EDITOR,
            payload: eventData,
        });
    };
}

export function cancelScheduleUpdate() {
    //Reset the store to initial state when someone hits Cancel button
    return (dispatch) => {
        dispatch({
            type: CANCEL_SCHEDULE_UPDATE,
        });
    };
}

export function backupPunctualSchedule(eventsBackup) {
    //Adds current schedule to store with original data to use in case someones cancel edit form
    return (dispatch) => {
        dispatch({
            type: PSCHEDULE_BACKUP,
            payload: eventsBackup,
        });
    };
}

export function backupPeriodicSchedule(eventsBackup) {
    //Adds current schedule to store with original data to use in case someones cancel edit form
    return (dispatch) => {
        dispatch({
            type: PERIODIC_SCHEDULE_BACKUP,
            payload: eventsBackup,
        });
    };
}

export function addPunctualEditRow(emptyRow) {
    //Adds new row in update punctual schedule form
    return (dispatch) => {
        dispatch({
            type: PSCHEDULE_ADD_ROW,
            payload: emptyRow,
        });
    };
}

export function addSpecialEditRow(emptyRow) {
    //Adds new row in update special schedule form
    return (dispatch) => {
        dispatch({
            type: SSCHEDULE_ADD_ROW,
            payload: emptyRow,
        });
    };
}

export function setCurrentZoneSchedule(schedule) {
    //Adds new row in update special schedule form
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_ZONE_SCHEDULE,
            payload: schedule,
        });
    };
}

export function setLastScheduleEdition() {
    //Sets bool in store.allowWizardNext to allow use of next button in schedules wizard
    return (dispatch) => {
        dispatch({
            type: SET_LAST_SCHEDULE_EDITION,
        });
    };
}
