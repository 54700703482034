import React from "react";
import { useTranslation, Trans } from "react-i18next";

import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Input from "./CommonOptions/Input";

const RoomsToolbar = () => {
    const { t } = useTranslation();

    //TODO rellenar estos colores a partir de la apariencia de la app
    // const  = "rgb(0,0,0)";
    // const defaultFgColor = "rgb(0,0,0)";
    // const defaultFgActiveColor = "rgb(0,0,0)";

    // console.log();

    return (
        <>
            <Toolbar type="style">
                <Settings>
                    <Settings.Group type="colors">
                        <Input.Color defaultColor={null} id="bgColor" title={t("background")} />
                        <Input.Color defaultColor={null} id="fgActiveColor" title={t("room")} />
                        <Input.Color defaultColor={null} id="fgColor" title={t("color")} />
                    </Settings.Group>
                </Settings>
            </Toolbar>
            <Toolbar
                type="info"
                title={t("fixed item")}
                description={<Trans i18nKey="widget-rooms-info" components={{ 1: <span className="font-bold" /> }} />}
            />
        </>
    );
};

export default RoomsToolbar;
