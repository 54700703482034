import { useState, useEffect, useContext } from "react";
import { useLazyQuery } from "react-apollo";
import { GET_MDNS_INFO } from "../services/getMonitorInfo";
import { NetworkAlertsContext } from "contexts/NetworkWarnings/NetworkAlerts";
import { useTranslation } from "react-i18next";

export const useMdnsInfo = () => {
    const { t } = useTranslation();
    const [mdnsInfo, setMdnsInfo] = useState();
    const { setMdnsCriticalWarning, setMdnsLowWarning } = useContext(NetworkAlertsContext);

    const [executeGetInfoQuery, { data, called, loading, networkStatus, refetch }] = useLazyQuery(GET_MDNS_INFO, {
        pollInterval: 200000,
    });

    useEffect(() => {
        executeGetInfoQuery();
    }, []);

    useEffect(() => {
        if (!loading && networkStatus === 8) {
            setMdnsInfo((prevNetworkInfo) => prevNetworkInfo);
        }
    }, [loading, networkStatus]);

    useEffect(() => {
        const info = data?.getMonitorCastInfo;
        const { mdnsData, anomalousTrafficData, networkPerformanceData } = info?.data || {};

        if (!loading && info && networkStatus === 7) {
            setMdnsInfo(mdnsModel(info, t));
            setMdnsCriticalWarning(
                !mdnsData?.isMdnsActive ||
                    mdnsData?.isMdnsProxyActive ||
                    mdnsData?.ccsNeverDiscoveredCriticalWarning ||
                    anomalousTrafficData?.isRoutingToGuestNetwork ||
                    networkPerformanceData?.internetBandwidthCriticalWarning
            );
            setMdnsLowWarning(
                mdnsData?.mdnsLowLevelWarning ||
                    mdnsData?.bandwidthWarning ||
                    mdnsData?.averageLatencyWarning ||
                    mdnsData?.ccsNeverDiscoveredMinorWarning ||
                    anomalousTrafficData?.isTrafficFromOtherNetwork ||
                    networkPerformanceData?.internetBandwidthMinorWarning ||
                    networkPerformanceData?.isCCsLatencyHigh ||
                    networkPerformanceData?.isCCsWifiSignalLow
            );
        }
    }, [data]);

    return { mdnsInfo, called, loading, refetch };
};

const mdnsModel = (info, t) => {
    const { lastUpdate, updateTimeMin, data } = info || {};

    const { mdnsData, anomalousTrafficData, networkPerformanceData } = data || {};

    const {
        isMdnsActive,
        discoverCCNumber,
        ccTotalNumber,
        discoverPercent,
        isMdnsProxyActive,
        mdnsLowLevelWarning,
        averageLatency,
        averageLatencyWarning,
        bwDownCast,
        bandwidthWarning,
        ccsNeverDiscoveredMinorWarning,
        ccsNeverDiscoveredCriticalWarning,
        ccsNeverDiscoveredNumber,
        serverMacs,
    } = mdnsData || {};

    const { ipListTrafficFromOtherNetwork, isRoutingToGuestNetwork, isTrafficFromOtherNetwork, areUsers } =
        anomalousTrafficData || {};

    const {
        isCCsWifiSignalLow,
        isCCsLatencyHigh,
        recommendedCCs,
        hiredCCs,
        internetBandwidth,
        internetBandwidthMinorWarning,
        internetBandwidthCriticalWarning,
        ccsWifiSignalLowNumber,
        ccsLatencyHighNumber,
        recommendedBandwidth,
    } = networkPerformanceData || {};

    const ccPercent = ccTotalNumber ? Math.round((ccTotalNumber / hiredCCs) * 100) : 0;

    return {
        mdnsData: {
            mdns: {
                value: isMdnsActive ? t("active") : t("inactive"),
                warning: !isMdnsActive,
                empty: ccTotalNumber === 0,
            },
            "ccs-online": {
                value: `${ccTotalNumber} / ${hiredCCs} (${ccPercent}%)`,
                tooltip: `${t("online")} / ${t("installed")}`,
                information: ccPercent >= 80 || ccPercent === 0 ? null : t("ccs-online-information"),
            },
            "ccs-discovered": {
                value: `${discoverCCNumber} / ${ccTotalNumber} (${Math.round(discoverPercent)}%)`,
                tooltip: `${t("ccs-discovered-tooltip")} / ${t("online")}`,
                warning: {
                    minor: mdnsLowLevelWarning,
                    critical: isMdnsProxyActive,
                    minorOrCritical: ccsNeverDiscoveredCriticalWarning || ccsNeverDiscoveredMinorWarning,
                    textValue: ccsNeverDiscoveredNumber,
                    color: ccsNeverDiscoveredCriticalWarning ? "red" : ccsNeverDiscoveredMinorWarning ? "orange" : "",
                },
                serverMacs: serverMacs,
                information:
                    Math.round(discoverPercent) === 100 || Math.round(discoverPercent) === 0
                        ? null
                        : t("protocol-limitations"),
            },
            "average-latency": {
                value: `${averageLatency} ms`,
                warning: averageLatencyWarning,
                empty: ccTotalNumber === 0,
            },
            bandwidth: {
                value: `${bwDownCast} kBps`,
                warning: bandwidthWarning,
                empty: ccTotalNumber === 0,
            },
        },
        anomalousTrafficData: {
            ipListTrafficFromOtherNetwork,
            isRoutingToGuestNetwork,
            isTrafficFromOtherNetwork,
            areUsers,
        },
        networkPerformanceData: {
            "cc-wifi-signal": {
                value: !isCCsWifiSignalLow ? t("acceptable") : t("low"),
                warning: isCCsWifiSignalLow,
                textValue: ccsWifiSignalLowNumber,
                empty: ccTotalNumber === 0,
            },
            "ccs-latency": {
                value: !isCCsLatencyHigh ? t("acceptable") : t("high"),
                warning: isCCsLatencyHigh,
                textValue: ccsLatencyHighNumber,
                empty: ccTotalNumber === 0,
            },
            "share-content-simultaneasly": {
                value: `${recommendedCCs} / ${hiredCCs}`,
                tooltip: `${t("ccs-recommended")} / ${t("ccs-hired")}`,
                bandwidth: `${internetBandwidth} Mbps`,
                warning: {
                    minor: internetBandwidthMinorWarning,
                    critical: internetBandwidthCriticalWarning,
                    textValue: recommendedBandwidth,
                    color: internetBandwidthCriticalWarning ? "red" : internetBandwidthMinorWarning ? "orange" : "",
                },
            },
        },
        lastUpdate,
        updateTimeMin,
        ccTotalNumber,
        notDiscoveredList: mdnsData?.ccsNotDiscoveredList,
    };
};
