export const CZBookingAreas = [
    "accept",
    "add",
    "all",
    "allos",
    "area",
    "available",
    "chars",
    "booking",
    "booking-data",
    "booking-done",
    "booking-modify",
    "cancel-booking-title",
    "cancel-booking-comment",
    "error-booking-cancel-comment",
    "cancel",
    "categories",
    "client-data",
    "create-anothera",
    "date",
    "days",
    "day-hour",
    "email",
    "hour",
    "hours",
    "items",
    "name",
    "newa",
    "of",
    "ofa",
    "onea",
    "people",
    "phone",
    "please",
    "save",
    "save-before",
    "search",
    "select",
    "state",
    "state-bookings-0",
    "state-bookings-1",
    "state-bookings-2",
    "state-bookings-3",
    "state-bookings-2,3",
    "status",
    "view all days",
    "theres-not",
    "today",
    "to-book",
    "view",
    "modify-ok",
    "mutation-error",
    "showing-all-results",
    "reason-cancelled",
    "errors-in-red",
    "operation-successful",
    "hour-not-available-to-this-people",
    "duration",
    "no-booking-for-name-email",
];
