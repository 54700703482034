export const CZCategories = [
    "activities",
    "add",
    "and",
    "areas",
    "are-you-sure",
    "available",
    "bookings",
    "booking-number",
    "cancel",
    "cant-undo",
    "categories",
    "category",
    "cat-must-empty",
    "common-zones",
    "delete",
    "erase",
    "hotel",
    "items",
    "categories",
    "name",
    "newa",
    "newo",
    "next",
    "ofo",
    "please",
    "previous",
    "quantity",
    "save",
    "search",
    "showing-all",
    "showing-all-results",
    "theres-not",
    "this",
    "view-a&a",
    "write-name",
    "you-want",
    "error-select-category",
    "error-capacity-limit",
    "categories",
    "categories-list",
    "areas-list",
    "schedule",
    "capacity-limit",
    "booking-allowed",
    "yes",
    "no",
    "edit-category-name",
];
