import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UseComponentLoading from "../../../useComponentLoading";
import { executeVendureQuery, useVendureAPI } from "../../../../hooks/Utils/Integrations/useVendure";
import UseSwitch from "../../../Inputs/useSwitch";
import { toast } from "react-toastify";
import { setExecuteQuery, setRefreshContentData } from "../../../../actions/sectionActions";

function GeneralSettingsTaxCard() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { executeQuery, refreshData } = useSelector((state) => state.sectionContent);
    const [loading, setLoading] = useState(true);
    const [showProductsWithTaxes, setShowProductsWithTaxes] = useState(false);
    const [shops, setShops] = useState(null);
    const action = "shops-update-price-include-tax";
    const { adminApi } = useVendureAPI();
    const GET_CHANNELS = `{
        channels{
            id
            customFields { price_field }
        }
    }`;

    useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (shops && shops.length > 0) {
            setShowProductsWithTaxes(shops[0].customFields.price_field === "WithTax");
        }
    }, [shops]);

    useEffect(() => {
        if (refreshData && executeQuery && executeQuery.action === action) {
            dispatch(setRefreshContentData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    const handleChangeShowProductsWithTax = (value) => {
        dispatch(
            setExecuteQuery({
                action: action,
                params: {
                    shops: shops,
                    value: value,
                },
            })
        );
    };

    const refetch = async () => {
        setLoading(true);
        const response = await executeVendureQuery(adminApi, { queryBody: GET_CHANNELS });
        if (response?.data) {
            setShops(response.data.channels);
        } else {
            toast.error(t("mutation-error"));
        }
        setLoading(false);
    };

    return (
        <div className="w-full">
            {loading ? (
                <UseComponentLoading />
            ) : (
                <>
                    <div className="text-left mb-4">
                        <span className="font-bold text-gray-900 text-2xl">{t("taxes")}</span>
                    </div>
                    <div className="text-left mb-4">
                        <span className="block">{t("show-products-with-taxes-description")}.</span>
                    </div>
                    <div className="w-full text-left flex items-center">
                        <span className="">{t("show-products-with-taxes")}</span>
                        <UseSwitch
                            id={"general-settings-switch-show-product-with-taxes"}
                            checked={showProductsWithTaxes}
                            action={(value) => handleChangeShowProductsWithTax(value)}
                            adjust={"flex"}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export default GeneralSettingsTaxCard;
