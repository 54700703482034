import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSectionContent } from "../../../../../actions/sectionActions";
import { isDescendant } from "../../../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import UseTagSelector from "../../../../useTagSelector";

const SalesTextOption = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { salesTitleData } = useSelector((state) => state.sectionContent);

    const titleData = useRef();
    titleData.current = salesTitleData;

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        titleData.current = salesTitleData;
    }, [salesTitleData]);

    const handleClickOutside = (e) => {
        const salesInputText = document.querySelector("#salesInputText");
        const textOptionsIcon = document.querySelector("#textOptionsIcon");
        const textOptions = document.querySelector("#textOptions");
        if (
            !(
                isDescendant(salesInputText, e.target) ||
                isDescendant(textOptions, e.target) ||
                isDescendant(textOptionsIcon, e.target) ||
                e.target.id.includes("textOptions") ||
                e.target.id.includes("salesInputText")
            )
        ) {
            dispatch(setSectionContent({ salesTitleData: { ...titleData.current, showOptions: false } }));
        }
    };

    const changeTextAlignment = (align) => {
        dispatch(
            setSectionContent({
                salesTitleData: { ...titleData.current, alignment: align },
                styleInputData: { generalSalesSave: true, value: align },
            })
        );

        titleData.current.alignment = align;
    };

    const currentSelectedStyle = (align) => {
        if (titleData.current.alignment === align) {
            return "border-gray-800 bg-gray-200";
        }
        return "border-gray-200";
    };
    return (
        <div className="float-right flex flex-col">
            <div>
                <div
                    id="textOptionsIcon"
                    className="rounded shadow float-right mt-2 flex bg-white p-1 text-2xl text-gray-800"
                >
                    <div className={`icon-settings rounded m-auto bg-gray-200 p-4 text-gray-800`} />
                </div>
            </div>
            <div id="textOptions" className="w-120 rounded shadow float-right h-40 bg-white  p-1 ">
                <div className="border-b flex border-gray-200 p-6 font-sans text-base font-bold">
                    {t("Text options")}
                </div>
                <div className="flex flex-row p-6 text-lg">
                    <div
                        id="align-left"
                        className={`icon-alignLeft text-1xl mr-6 cursor-pointer text-gray-800 rounded p-2 border ${currentSelectedStyle(
                            "left"
                        )}`}
                        onClick={() => changeTextAlignment("left")}
                    />
                    <div
                        id="align-center"
                        className={`icon-align-center text-1xl mr-6 cursor-pointer text-gray-800 rounded p-2 border ${currentSelectedStyle(
                            "center"
                        )}`}
                        onClick={() => changeTextAlignment("center")}
                    />
                    <div
                        id="align-right"
                        className={`icon-alignRight text-1xl cursor-pointer text-gray-800 rounded p-2 border ${currentSelectedStyle(
                            "right"
                        )}`}
                        onClick={() => changeTextAlignment("right")}
                    />
                    <div className="ml-auto relative w-18 p-2">
                        <UseTagSelector
                            itemLabel={"tags"}
                            cValue={null}
                            setCValue={(tag) => {
                                dispatch(
                                    setSectionContent({
                                        salesTitleData: {
                                            ...titleData.current,
                                            text: `${titleData.current.text} ${tag}`,
                                        },
                                        styleInputData: {
                                            generalSalesSave: true,
                                            value: `${titleData.current.text} ${tag}`,
                                        },
                                    })
                                );
                            }}
                            customTop="0.1rem"
                            customRight="0px"
                            customPadding="py-0"
                            customDropDownWith="w-56"
                            deviceType={"TV"}
                            screenType={"TV"}
                            id={"sales-text-option-tag-selector"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SalesTextOption;
