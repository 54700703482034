import { capitalizeFirst } from "hooks/Utils/Utils";

export const secondsToDate = (s, t, format = false) => {
    let text = "";
    const seconds = Math.round(s % 0x3c);
    const hours = Math.floor(s / 0xe10);
    const minutes = Math.floor(s / 0x3c) % 0x3c;
    const days = Math.floor(s / 86400);
    if (s < 60) {
        text = `${seconds} ${format ? capitalizeFirst(t("seconds")) : "s"}`;
    } else if (s >= 60 && s < 3600) {
        text = `${minutes} ${format ? capitalizeFirst(t("minutes")) : "m"}`;
    } else if (s >= 3600 && s < 86400) {
        if (minutes === 0) {
            text = `${hours} ${format ? capitalizeFirst(t(hours === 1 ? "hour" : "hours")) : "h"}`;
        } else {
            text = `${hours} ${format ? capitalizeFirst(t(hours === 1 ? "hour" : "hours")) : "h"} ${minutes} ${
                format ? capitalizeFirst(t("minutes")) : "m"
            }`;
        }
    } else if (s >= 86400) {
        const hoursDays = Math.floor((s % 86400) / 0xe10);
        if (hoursDays === 0) {
            text = `${days} ${format ? capitalizeFirst(t(days === 1 ? "day" : "days")) : "d"}`;
        } else {
            text = `${days} ${format ? capitalizeFirst(t(days === 1 ? "day" : "days")) : "d"}  ${hoursDays} ${
                format ? capitalizeFirst(t(hoursDays === 1 ? "hour" : "hours")) : "h"
            }`;
        }
    }
    return text;
};

export const getInfoAP = (accessProfiles, id, type) => {
    if ((accessProfiles[id] && accessProfiles[id][type]) || (accessProfiles[id] && accessProfiles[id][type] === 0)) {
        if (type === "ubwmin" || type === "ubwmax" || type === "dbwmin" || type === "dbwmax") {
            if (String(accessProfiles[id][type]) === "0") {
                return "-";
            }
        }

        return accessProfiles[id][type];
    } else {
        return null;
    }
};

export const tooltipInfoAP = (accessProfiles, accessprofileID, t) => {
    return `
    <div class=" flex flex-col text-gray-900 items-start "> 
        <div class="font-bold mb-2" >${capitalizeFirst(t("min-bandwidth"))}</div> 
        <div class=" mb-2 flex items-center justify-center w-full">
        <span class=" icon icon-maximum text-3xl" ></span> 
        ${getInfoAP(accessProfiles, accessprofileID, "ubwmin")} 
        Mb/s
        </div>
        <div class=" mb-2 flex items-center justify-center w-full">
        <span class=" icon icon-minimum text-3xl" ></span> 
        ${getInfoAP(accessProfiles, accessprofileID, "dbwmin")} 
        Mb/s
        </div>
        <div class="font-bold mb-2" >${capitalizeFirst(t("max-bandwidth"))}</div> 
        <div class=" mb-2 flex items-center justify-center w-full">
        <span class=" icon icon-maximum text-3xl" ></span> 
        ${getInfoAP(accessProfiles, accessprofileID, "ubwmax")} 
        Mb/s
        </div>
        <div class=" mb-2 flex items-center justify-center w-full">
        <span class=" icon icon-minimum text-3xl" ></span> 
        ${getInfoAP(accessProfiles, accessprofileID, "dbwmax")} 
        Mb/s
        </div>
    </div>
    `;
};
