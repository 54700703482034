const ParseChannelsFile = () => {
    const INPUT_ID = "channelMapInput";
    const FILE_TYPE = "channelMap";

    return {
        title: "import file",
        executeSectionQuery: true,
        bodyAdjust: "w-6/12",
        inputs: [
            {
                id: INPUT_ID,
                type: "channelsFile",
                style: "pb-10",
                contentTypes: [
                    {
                        type: FILE_TYPE,
                        multiple: false,
                    },
                ],
                allowAllTypes: true,
                filesResume: true,
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "import",
                style: "blue",
                disabledFunc: function () {
                    return (
                        !document.getElementById(INPUT_ID)?.files?.[0] ||
                        document.getElementById(INPUT_ID)?.files?.length === 0
                    );
                },
                action: "import-channels",
            },
        ],
    };
};

export default ParseChannelsFile;
