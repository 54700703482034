import React, { useContext, useState, useRef, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";
import { Session } from "hooks/Utils/Session";
import { useSwipeable } from "react-swipeable";

import Widget from "./Widget";
import EmptyWidget from "./WidgetToolbars/CommonOptions/EmptyWidget";
import Render from "zrender/render";

const WidgetType = "SLIDER";

const WidgetSlider = () => {
    const { deviceType, editorSize } = useContext(EditorContext);

    const { config: cfg, id: widgetID } = useContext(WidgetContext);
    const { gridItems } = useSelector((state) => state.design);
    const { lang } = useParams();

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const deviceWidth = editorSize?.width ? editorSize.width : 0;

    const globalData = widget?.data;

    const widgetZone = document.querySelector(`#widget_zone_${widgetID}`);
    const radius = cfg.style.radius;

    useEffect(() => {
        if (widgetZone) {
            widgetZone.style.borderRadius = "0px";
        }
    }, [widgetZone, radius]);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState(false);
    const [isReel, setIsReel] = useState(true);
    const renderRef = useRef(new Render(WidgetType));
    const render = renderRef.current;
    render.DAS = () => Session.getDasUrl();
    const deviceConfig = cfg?.data || Widget.ParseData(globalData, deviceType);

    const stylesWidget = useMemo(() => {
        return cfg?.style?.export() ? cfg?.style?.export() : {};
    }, [cfg?.style]);

    const items = useMemo(() => {
        return arrangeItems({
            items: deviceConfig.items || [],
            lang: lang,
        });
    }, [deviceConfig.items]);

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            if (isReel !== true) {
                if (eventData.dir === "Left") {
                    updateIndex(currentIndex + 1);
                } else if (eventData.dir === "Right") {
                    updateIndex(currentIndex - 1);
                }
            } else {
                const directionMultiplier = eventData.dir === "Left" ? 1 : -1;
                const swipeVelocity = Math.abs(eventData.velocity);
                const scrollDistance = Math.min(100 + swipeVelocity * 100, 500);
                setHorizontalScrollPosition(widgetID, directionMultiplier * scrollDistance);
            }
        },
        trackMouse: true,
    });

    const updateIndex = (newIndex) => {
        if (newIndex < 0) {
            newIndex = 0;
        } else if (newIndex >= items.length) {
            newIndex = items.length - 1;
        }
        setCurrentIndex(newIndex);
        setCurrentImage(items[newIndex]);
    };

    useEffect(() => {
        setIsReel(deviceConfig?.mode === "simple" ? false : true);
    }, [deviceConfig]);

    useEffect(() => {
        render.setDevice(deviceType, deviceWidth);
    }, [deviceWidth]);

    useEffect(() => {
        if (!currentImage && items.length > 0) {
            setCurrentImage(items[0]);
        }
        if (currentImage && items.length > 0) {
            setCurrentImage(items[currentIndex]);
        }
    }, [items]);

    useEffect(() => {
        if (render) {
            render.updateConfig({
                static: false,
                lang: lang,
                items: items,
                mode: deviceConfig?.mode || "simple",
                reelSize: deviceConfig?.reelSize || 27,
                id: `widget-slider-${widgetID}`,
                imageSimple: {
                    currentImage: currentImage,
                    currentIndex: currentIndex,
                    currentImage,
                },
                swipeEvents: true,
                events: {
                    click: (e) => {
                        const indexBullet = e?.target?.dataset?.indexBullet || 0;
                        setCurrentIndex(Number(indexBullet));
                        setCurrentImage(items[indexBullet]);
                    },
                },
            });
            render.setStyle(stylesWidget);
            render.update();
        }
    }, [deviceConfig, items, currentImage, stylesWidget]);

    const setHorizontalScrollPosition = (containerId, deltaScrollX) => {
        const container = document.getElementById(containerId);

        if (container) {
            container.scrollLeft += deltaScrollX;
        }
    };

    useEffect(() => {
        const renderNode = document.getElementById(`${widgetID}`);

        if (renderNode && items.length > 0) {
            while (renderNode.firstChild) {
                renderNode.removeChild(renderNode.firstChild);
            }
            if (render) {
                renderNode.appendChild(render.run());
            }
        }
    }, [widgetID, items]);

    return items.length <= 0 ? (
        <EmptyWidget type="SLIDER"></EmptyWidget>
    ) : (
        <div
            {...handlers}
            id={`${widgetID}`}
            style={{
                scrollBehavior: "smooth",
            }}
            className="w-full widget-slider-container overflow-x-scroll overflow-y-hidden h-full"
        ></div>
    );
};

export default WidgetSlider;

export const arrangeItems = ({ items, lang }) => {
    const finalItems = items
        .filter((item) => item?.visibility)
        .map((item) => {
            return {
                text: item?.text
                    ? item.text[lang]
                        ? item.text[lang]
                        : item.text && item.text.text
                        ? item.text.text
                        : ""
                    : "",
                events: {
                    click: () => {
                        alert("click!");
                    },
                },
                src:
                    item?.libraryRef || item?.externalUrl
                        ? { externalUrl: item.externalUrl, libraryRef: item.libraryRef }
                        : null,
            };
        });

    return finalItems;
};
