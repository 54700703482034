const DeleteScheduleModal = (id, currentEvent) => {
    //Returns dele schedule modal content
    return {
        id: id,
        title: "delete-schedule-title",
        text: "delete-schedule-text",
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "erase",
                style: "red",
                action: "delete",
            },
        ],
        currentEvent: currentEvent,
    };
};

export default DeleteScheduleModal;
