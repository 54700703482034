import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseTotalResults from "./useTotalResults";
import UseHeader from "./useHeader";
//Actions
import { setAllChecked } from "../../actions/tableActions";

const UseHeaders = () => {
    //Store data
    const { checkable, headers, actions, extendedInfo, allChecked, countPosition } = useSelector(
        (state) => state.table
    );

    //States
    const [active, setActive] = useState(false);

    //ACtions
    const dispatch = useDispatch();

    //Checkbox behaviour
    const clickHandler = (e) => {
        e.stopPropagation();
        if (!active) {
            setActive(true);
            dispatch(setAllChecked(true));
        } else {
            setActive(false);
            dispatch(setAllChecked(false));
        }
    };

    //Listeners
    useEffect(() => {
        setActive(allChecked);
    }, [allChecked]);

    return (
        <div id="table-head" className="t-head-container">
            <div className="t-row">
                {extendedInfo ? <div className="t-chevron-space"></div> : <span className="mr-4"></span>}
                {checkable ? (
                    <div className="t-checkbox-container" id="checkbox-all-rows-container">
                        <input
                            data-id={"all"}
                            type="checkbox"
                            className={`t-checkbox-checked opacity-${active ? "100" : "0"}`}
                            checked={active}
                            onClick={clickHandler}
                            onChange={(e) => {}}
                            id="checkbox-all-rows"
                        ></input>
                        <span className="t-checkbox-unchecked" id="checkbox-all-rows-span"></span>
                    </div>
                ) : null}
                {headers
                    ? headers.map((header, index) => <UseHeader header={header} key={index} index={index} />)
                    : null}
                {actions ? <div className="t-actions-space"></div> : null}
                {countPosition ? (
                    <span className="absolute right-0">
                        <UseTotalResults />
                    </span>
                ) : null}
            </div>
        </div>
    );
};

export default UseHeaders;
