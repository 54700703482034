import React from "react";
import Loading from "components/Loading";
import { useAddGroups } from "hooks/GraphqlCalls/Hotel/Rooms/useAddGroups";
import { AddAndEditComponent } from "./AddAndEditComponent";
import { useTranslation } from "react-i18next";

export const AddGroup = ({ refetch, setLoadingRefresh, type }) => {
    const { t } = useTranslation();
    const { addRoomGroup, selectData, handleSelect, handleDelete, editGroupName, queryLoading, called } = useAddGroups({
        refetch,
        setLoadingRefresh,
        type,
    });

    const inputTextPlaceholder = t("group-name");
    const inputSelectPlaceholder = type === "room" ? t("rooms") : t("guests");
    const labelGroupSelect = type === "room" ? t("select-room-group") : t("select-group-guest");
    const labelGroupAdded = type === "room" ? t("rooms-added") : t("guests-added");

    return (
        <>
            {!queryLoading && called ? (
                <AddAndEditComponent
                    addRoomGroupOrEdit={addRoomGroup}
                    selectData={selectData}
                    handleSelect={handleSelect}
                    handleDelete={handleDelete}
                    editGroupName={editGroupName}
                    inputTextPlaceholder={inputTextPlaceholder}
                    inputSelectPlaceholder={inputSelectPlaceholder}
                    labelGroupSelect={labelGroupSelect}
                    labelGroupAdded={labelGroupAdded}
                    type="add"
                />
            ) : (
                <Loading adjust="section-loading" />
            )}
        </>
    );
};
