import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useParams, useNavigate as useHistory } from "react-router-dom";

//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

//Components
import UseSectionHeader from "../../useSectionHeader";
import UseButton from "../../../components/useButton";
import UseTranslationTextArea from "../../useTranslationTextArea";

//Actions
import { cleanAction } from "../../../actions/globalActions";
import { showGlobalLoading } from "../../../actions/uiActions";
import { setSectionContent } from "../../../actions/sectionActions";

//Utils
import GetAreaDescription from "../../../hooks/GraphqlCalls/CommonZones/GetAreaDescription";
import { toast } from "react-toastify";
import { escapeSpecialChars } from "../../../hooks/Utils/Utils";

const AreaDescription = () => {
    const history = useHistory();

    //Params
    const { id } = useParams();

    //Store data
    const sectionData = useSelector((state) => state.sectionContent.data);
    const { sectionName } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);
    const { langStrings } = useSelector((state) => state.ui);
    const [translationArray, setTranslationArray] = useState();
    //Actions
    const dispatch = useDispatch();
    const MUTATION_OBJECT = gql`mutation{updateCommonZone(id:${id} description:[${translationArray}]){error ok}}`;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            console.log(err);
            dispatch(showGlobalLoading(false));
            toast.error(langStrings["mutation-error"]);
        },
    });

    //listeners
    useEffect(() => {
        dispatch(showGlobalLoading(true));
        return () => {
            dispatch(cleanAction());
            dispatch(
                setSectionContent({
                    data: null,
                })
            );
        };
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        if (data && data.updateCommonZone && data.updateCommonZone.ok) {
            dispatch(showGlobalLoading(false));
            toast.success(langStrings["operation-successful"]);
        }
    }, [dispatch, data, langStrings]);

    useEffect(() => {
        let translations = "";

        Object.entries(actionData.values).map((item) =>
            item[0] !== "undefined"
                ? (translations += `{lang:"${item[0]}" text:"${escapeSpecialChars(item[1])}"}`)
                : null
        );
        setTranslationArray(translations);
    }, [actionData.values]);

    //Functions
    const saveDescription = () => {
        dispatch(showGlobalLoading(true));
        executeMutation();

        return null;
    };
    const goBack = () => {
        history(-1);
    };

    GetAreaDescription({ id: id });

    return (
        <>
            <UseSectionHeader title={sectionName} navToPreviousPage={true} />
            {sectionData && sectionData.area ? (
                <div className="bg-white p-8">
                    <div className="pb-8 first-capital">{langStrings["enter-area-description"]}</div>
                    <UseTranslationTextArea textId={sectionData.area.textId} parentType="COMMONZONES" />

                    <div className="mt-6 pt-6 border-gray-200 flex  justify-end">
                        <div onClick={() => goBack()}>
                            <UseButton
                                buttonName={"cancel"}
                                buttonColor={"btn-white"}
                                adjust="w-24 min-w-0 mr-2 float-left "
                            />
                        </div>
                        <div onClick={() => saveDescription()}>
                            <UseButton
                                id={"save-area-description"}
                                buttonName={"save"}
                                buttonColor={"btn-blue"}
                                adjust="w-24 min-w-0"
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default AreaDescription;
