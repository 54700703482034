import { getFromDAS } from "hooks/Utils/Utils";

export const AssociatedProperties = (
    property,
    projects,
    corporateAccess,
    isMultiProperty,
    index,
    t,
    propertyRef,
    setIsEditable,
    isEdited,
    updatePropertyData
) => {
    const location = projects.find((val) => val.ref == property.ref)?.location;
    const phoneNumber =
        property.information.chatNumberLada && property.information.chatNumber
            ? `${property.information.chatNumberLada} ${property.information.chatNumber}`
            : "-";
    const available = property.information.appAvailable;
    const phoneCells =
        (!isEdited && property.ref == propertyRef) || property.ref != propertyRef
            ? [
                  {
                      name: "phone-number",
                      value: phoneNumber,
                      cellContent: "text",
                      specialWidth: "w-1/4",
                  },
              ]
            : [
                  {
                      cellContent: "editPhoneNumber",
                      value: phoneNumber,
                      specialWidth: "w-1/4",
                  },
              ];
    let result = {
        dataType: "associated-properties",
        info: [
            {
                cellContent: "editImage",
                isFromUrl: true,
                linkText: property.name,
                urlPath: property.information.picture ? property.information.picture : "/",
                type: "svg",
                name: property.name,
                editable: isMultiProperty && property.ref == propertyRef && !corporateAccess && isEdited,
                noImgText: true,
                specialWidth: "w-1/4 ",
                adjust: "w-40 h-12 object-cover rounded mr-4 bg-gray-200 border border-gray-300",
                notImgFontSize: "0.65rem",
                adjustNotImageIcon: "icon icon-image text-gray-400 text-xl",
                iconError: "icon-image text-gray-400 text-xl",
                adjustNotImage:
                    "border border-gray-300 flex items-center justify-center bg-gray-200 w-18 h-12 rounded mr-4",
                value: property.name,
            },
            {
                name: "location",
                value: location ? location : "-",
                icon: "location",
                cellContent: "text",
                specialWidth: "w-1/4",
            },
            ...phoneCells,
            ...(isEdited && property.ref == propertyRef
                ? [
                      {
                          specialWidth: "w-1/12",
                      },
                      {
                          cellContent: "button",
                          specialWidth: "w-1/12 flex justify-center",
                          isButton: true,
                          buttonColor: "red",
                          action: () => {
                              setIsEditable(false);
                          },
                          noWidthFullCell: true,
                          adjust: " icon-remove w-10 min-w-0",
                      },
                      {
                          cellContent: "button",
                          specialWidth: "w-1/12",
                          isButton: true,
                          noWidthFullCell: true,
                          buttonColor: "green",
                          action: () => {
                              updatePropertyData(property.id, property.information.picture);
                              setIsEditable(false);
                          },
                          adjust: "icon-tick-right min-w-0 w-10",
                      },
                  ]
                : []),
            ...((!isEdited && property.ref == propertyRef) || property.ref != propertyRef
                ? [
                      {
                          value: t(available ? "available" : "unavailable"),
                          specialWidth: `w-1/8 ${available ? "text-green-100" : "text-red-100"}`,
                          cellContent: "text",
                      },
                  ]
                : []),
            ...(isMultiProperty && property.ref == propertyRef && !corporateAccess && !isEdited
                ? [
                      {
                          specialWidth: "w-1/8",
                          isButton: true,
                          adjust: "icon-edit cursor-pointer",
                          cellContent: "button",
                          action: () => {
                              setIsEditable(true);
                          },
                          actionParams: {},
                      },
                  ]
                : property.ref != propertyRef
                ? [{ specialWidth: "w-1/8" }]
                : []),
        ],
    };

    return result;
};
